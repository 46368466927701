import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { formatQuery, RuleGroupTypeIC } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createNotificationsDefinitions,
  fetchNotificationsDefinitionsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateNotificationsDefinitions,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  NotificationsDefinitionsList,
  CreateNotificationsDefinitionsPayload,
  FetchNotificationsDefinitionsByIdPayload,
  UpdateNotificationsDefinitionsPayload,
} from "../../../../../redux/NotificationsDefinitions/types";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  NotificationsDefinitionsForm,
  useNotificationsDefinitionsControllerProps,
  useNotificationsDefinitionsControllerReturnType,
} from "../../../../../types/models/NotificationsDefinitions";
import { collections, userFilterFields } from "../../../../../utils";
import { useAcademyACHList } from "../../AcademyACH/hooks";
import { useAcademyBatchList } from "../../AcademyBatch/hooks/useAcademyBatchList";
import { useAcademyCandidateEnrollmentsList } from "../../AcademyCandidateEnrollments/hooks";
import { useAcademyCandidateLeadsList } from "../../AcademyCandidateLeads/hooks";
import { useAcademyCandidatesList } from "../../AcademyCandidates/hooks/useAcademyCandidatesList";
import { useAcademyCoursesList } from "../../AcademyCourses/hooks";
import { useAcademyMentorFeedbackList } from "../../AcademyMentorFeedback/hooks";
import { useAcademyStudentProfileList } from "../../AcademyStudentProfile/hooks";
import { useAddressHistoryList } from "../../AddressHistory/hooks";
import { useAnnouncementsList } from "../../Announcements/hooks/useAnnouncementsList";
import { useBankDetailsList } from "../../BankDetails/hooks";
import { useBenefitsList } from "../../Benefits/hooks/useBenefitsList";
import { useEducationDetailsList } from "../../EducationDetails/hooks";
import { useEmployeesList } from "../../Employees/hooks/useEmployeesList";
import { useForm16List } from "../../Form16/hooks";
import { usePIIDetailsList } from "../../PIIDetails/hooks/usePIIDetailsList";
import { useSPSAccountContactList } from "../../SPSAccountContact/hooks";
import { useSPSAccountsList } from "../../SPSAccounts/hooks";
import { useSPSBGCheckList } from "../../SPSBGCheck/hooks";
import { useSPSDealsList } from "../../SPSDeals/hooks";
import { useSPSInsuranceList } from "../../SPSInsurance/hooks";
import { useSPSPaperworkList } from "../../SPSPaperwork/hooks";
import { useSPSProjectsList } from "../../SPSProjects/hooks";
import { useSPSSalesProfileList } from "../../SPSSalesProfile/hooks";
// ================ Contstants ================

const addFormSteps = ["Overview", "When To Send", "Who Will Recieve"];
const editFormSteps = [
  "Overview",
  "When To Send",
  "Who Will Recieve",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = ["name", "active", "description"];
const whenToSendFields = ["table", "triggered", "emailSchedular", "insert", "update"];
const whoWillRecieve = ["watchListGroup", "watchListUser"];
export const useNotificationsDefinitionsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useNotificationsDefinitionsControllerProps): useNotificationsDefinitionsControllerReturnType => {
  const { employeesFilterFields } = useEmployeesList({});
  const { academyCandidatesFilterFields } = useAcademyCandidatesList({});
  const { academyBatchFilterFields } = useAcademyBatchList({});
  const { academyCandidateEnrollmentsFilterFields } = useAcademyCandidateEnrollmentsList({});
  const { academyCoursesFilterFields } = useAcademyCoursesList({});
  const { studentProfileFilterFields } = useAcademyStudentProfileList({});
  const { academyMentorFeedbackFilterFields } = useAcademyMentorFeedbackList({});
  const { academyACHFilterFields } = useAcademyACHList({});
  const { SPSAccountsFilterFields } = useSPSAccountsList({});
  const { academyCandidateLeadsFilterFields } = useAcademyCandidateLeadsList({});
  const { spsAccountContactFilterFields } = useSPSAccountContactList({});
  const { spsSalesProfileFilterFields } = useSPSSalesProfileList({});
  const { spsInsuranceFilterFields } = useSPSInsuranceList({});
  const { spsDealsFilterFields } = useSPSDealsList({});
  const { spsProjectFilterFields } = useSPSProjectsList({});
  const { spsBGCheckFilterFields } = useSPSBGCheckList({});
  const { spsPaperWorkFilterFields } = useSPSPaperworkList({});
  const { piiDetailsFilterFields } = usePIIDetailsList({});
  const { bankDetailsFilterFields } = useBankDetailsList({});
  const { benefitsFilterFields } = useBenefitsList({});
  const { form16FilterFields } = useForm16List({});
  const { addressHistoryFilterFields } = useAddressHistoryList({});
  const { announcementsFilterFields } = useAnnouncementsList({});
  const { educationDetailsFilterFields } = useEducationDetailsList({});

  const defaultFormData: NotificationsDefinitionsForm = {
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    // Overview
    name: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: false,
      error: "",
      required: false,
    },
    // What to Send
    table: {
      value: "",
      required: true,
      error: "",
    },
    triggered: {
      value: "add",
      required: true,
      error: "",
    },
    emailSchedular: {
      value: [],
      error: "",
      required: false,
    },
    insert: {
      value: false,
      error: "",
      required: false,
    },
    update: {
      value: false,
      error: "",
      required: false,
    },
    // Who Will Recieve
    watchListGroup: {
      value: [],
      error: "",
      required: true,
    },
    watchListUser: {
      value: [],
      error: "",
      required: true,
    },
  };

  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<NotificationsDefinitionsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<NotificationsDefinitionsList>({});
  const initialQuery: RuleGroupTypeIC = useMemo(() => {
    return { rules: [] };
  }, []);
  const [query, setQuery] = useState<RuleGroupTypeIC>(initialQuery);

  const handleQuerychange = useCallback((query: RuleGroupTypeIC) => {
    setQuery(query);
  }, []);
  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        case 1:
          whenToSendFields.forEach((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        case 2:
          whoWillRecieve.forEach((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
      }
      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = { ...formData, [name]: { ...formData[name], value, error: "" } };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "table") {
        // Set the initial value for the 'fields' Select component
        const queryId = query.id;
        if (queryId) {
          const newQuery: RuleGroupTypeIC = {
            id: queryId,
            rules: [],
          };
          setQuery(newQuery);
        }
        // setQuery(initialQuery);
      }

      setFormData(tempFormData);
    },
    [formData, query.id],
  );

  const rehydrateFormData = useCallback(
    (notificationsDefinations: NotificationsDefinitionsList) => {
      const item = notificationsDefinations;
      if (item?.filter) {
        setQuery(item.filter);
      }
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).forEach((field) => {
        let value = item?.[field];
        if (["emailSchedular", "watchListGroup", "watchListUser"].includes(field)) {
          const tempValue = value?.map((item) => {
            return { label: item?.name, value: item?._id };
          });
          value = tempValue;
          data[field].value = value;
        }
        if (["tables"].includes(field)) {
          const tempValue = item[field]?.map((table) => {
            return { label: collections?.[table]?.label, value: table };
          });
          value = tempValue;
          data[field].value = value;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchNotificationsDefinitions = useCallback(() => {
    setLoading(true);
    const payload: FetchNotificationsDefinitionsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchNotificationsDefinitionsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      name,
      active,
      description,
      emailSchedular,
      insert,
      table,
      triggered,
      update,
      watchListGroup,
      watchListUser,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const mongoQuery = formatQuery(query, "mongodb");
      const payload: CreateNotificationsDefinitionsPayload = {
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          active: active.value,
          table: table.value,
          emailSchedular: emailSchedular.value?.map((item) => item.value),
          insert: insert.value,
          update: update.value,
          triggered: triggered.value,
          watchListUser: watchListUser.value?.map((item) => item.value),
          watchListGroup: watchListGroup.value?.map((item) => item.value),
          filter: query,
          filterMongoQuery: mongoQuery,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NotificationsDefinitions created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createNotificationsDefinitions(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, query, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      name,
      active,
      description,
      emailSchedular,
      insert,
      table,
      triggered,
      update,
      watchListGroup,
      watchListUser,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const mongoQuery = formatQuery(query, "mongodb");
      const payload: UpdateNotificationsDefinitionsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          active: active.value,
          table: table.value,
          emailSchedular: emailSchedular.value?.map((item) => item.value),
          insert: insert.value,
          update: update.value,
          triggered: triggered.value,
          watchListUser: watchListUser.value?.map((item) => item.value),
          watchListGroup: watchListGroup.value?.map((item) => item.value),
          filter: query,
          filterMongoQuery: mongoQuery,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NotificationsDefinitions updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateNotificationsDefinitions(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, query, id]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateNotificationsDefinitionsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateNotificationsDefinitions(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.notificationsDefinations, title];
  }, [type, data.name]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (
          table &&
          "notificationsDefinintionsSupported" in table &&
          table.notificationsDefinintionsSupported
        ) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null; // or undefined, depending on your preference
      })
      .filter(Boolean); // to remove any null or undefined entries
  }, []);
  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        console.log(name, formData[name]?.value, value);
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );
  const getTableFilters = useMemo(() => {
    switch (formData.table.value) {
      case collections.users.name:
        return userFilterFields;
      case collections.employees.name:
        return employeesFilterFields;
      case collections.academyCourses.name:
        return academyCoursesFilterFields;
      case collections.academyCandidates.name:
        return academyCandidatesFilterFields;
      case collections.academyBatch.name:
        return academyBatchFilterFields;
      case collections.academyCandidateEnrollments.name:
        return academyCandidateEnrollmentsFilterFields;
      case collections.academyStudentProfile.name:
        return studentProfileFilterFields;
      case collections.academyMentorFeedback.name:
        return academyMentorFeedbackFilterFields;
      case collections.spsAccounts.name:
        return SPSAccountsFilterFields;
      case collections.academyACH.name:
        return academyACHFilterFields;
      case collections.academyCandidateLeads.name:
        return academyCandidateLeadsFilterFields;
      case collections.spsAccountContact.name:
        return spsAccountContactFilterFields;
      case collections.spsSalesProfile.name:
        return spsSalesProfileFilterFields;
      case collections.spsInsurance.name:
        return spsInsuranceFilterFields;
      case collections.spsDeals.name:
        return spsDealsFilterFields;
      case collections.spsProjects.name:
        return spsProjectFilterFields;
      case collections.spsBGCheck.name:
        return spsBGCheckFilterFields;
      case collections.spsPaperwork.name:
        return spsPaperWorkFilterFields;
      case collections.piiDetails.name:
        return piiDetailsFilterFields;
      case collections.employeeBankDetails.name:
        return bankDetailsFilterFields;
      case collections.companyBankDetails.name:
        return bankDetailsFilterFields;
      case collections.benefits.name:
        return benefitsFilterFields;
      case collections.form16.name:
        return form16FilterFields;
      case collections.addressHistory.name:
        return addressHistoryFilterFields;
      case collections.announcements.name:
        return announcementsFilterFields;
      case collections.educationDetails.name:
        return educationDetailsFilterFields;
      case "ACADEMYCANDIDATELEADS":
        return academyCandidateLeadsFilterFields;
      default:
        return [];
    }
  }, [
    formData.table.value,
    employeesFilterFields,
    academyCoursesFilterFields,
    academyCandidatesFilterFields,
    academyBatchFilterFields,
    academyCandidateEnrollmentsFilterFields,
    studentProfileFilterFields,
    academyMentorFeedbackFilterFields,
    SPSAccountsFilterFields,
    academyACHFilterFields,
    academyCandidateLeadsFilterFields,
    spsAccountContactFilterFields,
    spsSalesProfileFilterFields,
    spsInsuranceFilterFields,
    spsDealsFilterFields,
    spsProjectFilterFields,
    spsBGCheckFilterFields,
    spsPaperWorkFilterFields,
    piiDetailsFilterFields,
    bankDetailsFilterFields,
    benefitsFilterFields,
    form16FilterFields,
    addressHistoryFilterFields,
    announcementsFilterFields,
    educationDetailsFilterFields,
  ]);
  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchNotificationsDefinitions,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    TableList,
    handleCheckbox,
    getTableFilters,
    query,
    handleQuerychange,
  };
};
