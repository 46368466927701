import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  getProfile,
  removeAppNotification,
  updateTableConfigs,
} from "../../../../../redux";
import {
  fetchBugReportingList,
  updateBugReporting,
} from "../../../../../redux/BugReporting/actions";
import {
  BugReportingListWithMetaData,
  FetchBugReportingParams,
  BugReportingList,
} from "../../../../../redux/BugReporting/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  sortType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

import { getAttributValues } from "./../../../../../services/methods";
import {
  fetchBugReportingProps,
  useBugReportingListProps,
  useBugReportingListReturnType,
} from "./../../../../../types/models/BugReporting";

const { add, edit } = AppRouteLinks.bugReporting;
const defaultFilters: QueryBuilderType[] = [];

export const useBugReportingList = ({
  relatedList,
}: useBugReportingListProps): useBugReportingListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const metaData = useAppSelector((state) => state.BugReportingReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const bugReporting = useAppSelector((state) => state.BugReportingReducer.data.list);
  const { usersOptions, employeesOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: BugReportingList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.bugReporting?.fields || tableListDefaultSelectFields.bugReporting;
      records = records?.map((item) => {
        const bugReportingtem = { ...item };
        if (userPersonalizedFields?.includes("assignedTo") && typeof item.assignedTo !== "string") {
          bugReportingtem.assignedTo = item?.assignedTo?.name;
        }
        if (userPersonalizedFields?.includes("status") && typeof item.status !== "string") {
          bugReportingtem.status = `${item?.status?.value}`;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          bugReportingtem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          bugReportingtem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          bugReportingtem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          bugReportingtem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete bugReportingtem?._id;
        return bugReportingtem;
      });
      const bugReportingFields = tablePublicFields.bugReporting;
      const columnHeaders = bugReportingFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "bugReporting");
    },
    [tableConfiguration?.bugReporting?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const bugReportingFetchSuccess = useCallback(
    (data: BugReportingListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        exportRecords(data.list);
      }
    },
    [exportRecords],
  );

  const bugReportingFetchError = useCallback(
    (error: string) => {
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchBugReporting = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchBugReportingProps) => {
      setLoading(true);

      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.bugReporting?.limit || 10;

      const params: FetchBugReportingParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["title"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: BugReportingListWithMetaData) =>
          bugReportingFetchSuccess(data, exportAll),
        onError: bugReportingFetchError,
      };
      dispatch(fetchBugReportingList(payload));
    },
    [
      dispatch,
      bugReportingFetchError,
      bugReportingFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = bugReporting?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, bugReporting, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchBugReporting({ exportAll: true });
  }, [fetchBugReporting]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchBugReporting({ page });
  }, [fetchBugReporting, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchBugReporting({ page: parseInt(page), search });
    },
    [fetchBugReporting, search],
  );

  const onClickBugReporting = useCallback(
    (id: string) => {
      navigate(edit(id));
    },
    [navigate],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchBugReporting({ page: 1, search });
  }, [fetchBugReporting, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchBugReporting({ page: 1 });
  }, [fetchBugReporting]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchBugReporting({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchBugReporting],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchBugReporting({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchBugReporting],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = bugReporting?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, bugReporting]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            bugReporting?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, bugReporting, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      setLoading(true);
      const bugReportingConfigLimit = tableConfiguration?.bugReporting?.limit || 10;
      const bugReportingConfigs = {
        table: "bugReporting",
        fields: fields,
        limit: bugReportingConfigLimit,
      };
      const payload = {
        tableConfiguration: bugReportingConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchBugReporting({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bug Report table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.bugReporting?.limit, dispatch, fetchBugReporting, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target?.value;
      const bugReportingConfigFields = tableConfiguration?.bugReporting?.fields;
      setLoading(true);
      const bugReportingConfigs = {
        table: "bugReporting",
        fields: bugReportingConfigFields,
        limit: Number(value),
      };
      const payload = {
        tableConfiguration: bugReportingConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchBugReporting({ limit: Number(value), page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bug Report table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchBugReporting],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = bugReporting?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchBugReporting({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bug Report updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Bug Report",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateBugReporting(payload));
    },
    [selectedRows, bugReporting, dispatch, notificationsId, fetchBugReporting],
  );
  // ================ Table Header Buttons ================

  const bugReportingHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [
      {
        title: strings.common.export,
        onClick: exportSelectedRecords,
        visible: true,
      },
      {
        title: strings.common.exportAll,
        onClick: exportAllRecords,
        visible: true,
      },
      {
        title: strings.common.add,
        onClick: onClickAdd,
        visible: true,
      },
    ];
    return buttons;
  }, [onClickAdd, exportSelectedRecords, exportAllRecords]);

  const listActionOptions: ListActions[] = [
    {
      name: "assignedTo",
      label: "Assigned To",
      options: employeesOptions,
    },
    {
      name: "status",
      label: "Status",
      options: getAttributValues("bugReporting"),
    },
  ];

  // ================ Filter Fields Configuration ================

  const bugReportingFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const assignedToFilterOptions = getFilterOptions(employeesOptions, "Assigned To");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const bugReportingOptions = getFilterOptions(
      getAttributValues("bugReporting"),
      "Bug Reporting Status",
    );

    const fields: Field[] = [
      {
        name: "title",
        label: "Title",
        inputType: "string",
        validator,
      },
      {
        name: "assignedTo",
        label: "Assigned To",
        valueEditorType: "select",
        values: assignedToFilterOptions,
        defaultValue: assignedToFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "status",
        label: "Status",
        valueEditorType: "select",
        values: bugReportingOptions,
        defaultValue: bugReportingOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [employeesOptions, usersOptions]);

  return {
    listActionOptions,
    bugReportingFilterFields,
    onClickBugReporting,
    fetchBugReporting,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    bugReportingHeaderButtons,
  };
};
