import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { SPSProjectControllerProps } from "../../../../types/models/SPSProject";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSDealsList } from "../SPSDeals";
import { SPSInsuranceList } from "../SPSInsurance";

import { useSPSProjectsController } from "./hooks";

const Controller = ({ type }: SPSProjectControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsDealsOptions, employeesOptions, modulesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    onDateChange,
    loading,
    headerTitle,
    fetchSPSProject,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSProjectsController({ type, id, attachmentsPickerRef });
  const currentSPSProject = data;

  const {
    workLogs,
    attachments,
    projectName,
    projectType,
    projectCode,
    associatedDeal,
    platform,
    legalName,
    legalNameCode,
    projectGivenTo,
    rate,
    billingCycle,
    startDate,
    expetectedLength,
    expetectedEndDate,
    projectStatus,
    modules,
    projectDescription,
    projectRole,
    haltReason,
    haltStartDate,
    haltEndDate,
    terminationReason,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSProject();
    }
    // Do not include fetchSPSProject to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Project Name'
            type={"text"}
            width='48%'
            error={projectName.error}
            inputProps={{
              name: "projectName",
              value: projectName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: projectName.required,
            }}
          />
          <TextInput
            label='Project Code'
            type={"text"}
            width='48%'
            error={projectCode.error}
            inputProps={{
              name: "projectCode",
              value: projectCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: projectCode.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"projectType"}
            label={"Project Type"}
            options={getAttributValues("projectType")}
            value={projectType.value}
            onChange={onTextChange}
            mandatory={projectType.required}
            error={projectType.error}
          />
          <Select
            width={"48%"}
            name={"projectStatus"}
            label={"Project Status"}
            options={getAttributValues("projectStatus")}
            value={projectStatus.value}
            onChange={onTextChange}
            mandatory={projectStatus.required}
            error={projectStatus.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Project Description'
            type={"textarea"}
            width='100%'
            error={projectDescription.error}
            textAreaProps={{
              name: "projectDescription",
              rows: 8,
              value: projectDescription.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: projectDescription.required,
            }}
          />
        </Row>

        <Row>
          <TextInput
            label='Legal Name'
            type={"text"}
            width='48%'
            error={legalName.error}
            inputProps={{
              name: "legalName",
              value: legalName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: legalName.required,
            }}
          />
          <TextInput
            label='Legal Name Code'
            type={"text"}
            width='48%'
            error={legalNameCode.error}
            inputProps={{
              name: "legalNameCode",
              value: legalNameCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: legalNameCode.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Rate'
            type={"text"}
            width='48%'
            error={rate.error}
            inputProps={{
              name: "rate",
              value: rate.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: rate.required,
            }}
          />
          <TextInput
            label='Expected Length'
            type={"text"}
            width='48%'
            error={expetectedLength.error}
            inputProps={{
              name: "expetectedLength",
              value: expetectedLength.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: expetectedLength.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"projectGivenTo"}
            label={"Project Given To"}
            options={employeesOptions}
            value={projectGivenTo.value}
            onChange={onTextChange}
            mandatory={projectGivenTo.required}
            error={projectGivenTo.error}
          />
          <TextInput
            label='Termination Reason'
            type={"text"}
            width='48%'
            error={terminationReason.error}
            inputProps={{
              name: "terminationReason",
              value: terminationReason.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: terminationReason.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"100%"}
            name={"modules"}
            label={"Modules"}
            isMulti
            options={modulesOptions}
            value={modules.value}
            onChange={onTextChange}
            mandatory={modules.required}
            error={modules.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Halt Start Date'
            type={"date"}
            width='48%'
            error={haltStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "haltStartDate",
              value: haltStartDate.value,
              required: haltStartDate.required,
            }}
          />
          <TextInput
            label='Halt End Date'
            type={"date"}
            width='48%'
            error={haltEndDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "haltEndDate",
              minDate: haltStartDate.value ? new Date(haltStartDate.value) : undefined,
              value: haltEndDate.value,
              required: haltEndDate.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Halt Reason'
            type={"textarea"}
            width='100%'
            error={haltReason.error}
            textAreaProps={{
              name: "haltReason",
              rows: 3,
              value: haltReason.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: haltReason.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Project Role'
            type={"textarea"}
            width='100%'
            error={projectRole.error}
            textAreaProps={{
              name: "projectRole",
              rows: 3,
              value: projectRole.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: projectRole.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"billingCycle"}
            label={"Billing Cycle"}
            options={getAttributValues("spsBillingCycle")}
            value={billingCycle.value}
            onChange={onTextChange}
            mandatory={billingCycle.required}
            error={billingCycle.error}
          />
          <Select
            width={"48%"}
            name={"platform"}
            label={"Platform"}
            options={getAttributValues("spsProjectPlatform")}
            value={platform.value}
            onChange={onTextChange}
            mandatory={platform.required}
            error={platform.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Start Date'
            type={"date"}
            width='48%'
            error={startDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "startDate",
              value: startDate.value,
              required: startDate.required,
            }}
          />
          <TextInput
            label='Expected End Date'
            type={"date"}
            width='48%'
            error={expetectedEndDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "expetectedEndDate",
              minDate: startDate.value ? new Date(startDate.value) : undefined,
              value: expetectedEndDate.value,
              required: expetectedEndDate.required,
            }}
          />
        </Row>
        <Select
          width={"48%"}
          name={"associatedDeal"}
          label={"Associated Deal"}
          options={spsDealsOptions}
          value={associatedDeal.value}
          onChange={onTextChange}
          mandatory={associatedDeal.required}
          error={associatedDeal.error}
        />
      </React.Fragment>
    );
  }, [
    associatedDeal.error,
    associatedDeal.required,
    associatedDeal.value,
    billingCycle.error,
    billingCycle.required,
    billingCycle.value,
    employeesOptions,
    expetectedEndDate.error,
    expetectedEndDate.required,
    expetectedEndDate.value,
    expetectedLength.error,
    expetectedLength.required,
    expetectedLength.value,
    haltEndDate.error,
    haltEndDate.required,
    haltEndDate.value,
    haltReason.error,
    haltReason.required,
    haltReason.value,
    haltStartDate.error,
    haltStartDate.required,
    haltStartDate.value,
    legalName.error,
    legalName.required,
    legalName.value,
    legalNameCode.error,
    legalNameCode.required,
    legalNameCode.value,
    modules.error,
    modules.required,
    modules.value,
    modulesOptions,
    onDateChange,
    onTextChange,
    platform.error,
    platform.required,
    platform.value,
    projectCode.error,
    projectCode.required,
    projectCode.value,
    projectDescription.error,
    projectDescription.required,
    projectDescription.value,
    projectGivenTo.error,
    projectGivenTo.required,
    projectGivenTo.value,
    projectName.error,
    projectName.required,
    projectName.value,
    projectRole.error,
    projectRole.required,
    projectRole.value,
    projectStatus.error,
    projectStatus.required,
    projectStatus.value,
    projectType.error,
    projectType.required,
    projectType.value,
    rate.error,
    rate.required,
    rate.value,
    spsDealsOptions,
    startDate.error,
    startDate.required,
    startDate.value,
    terminationReason.error,
    terminationReason.required,
    terminationReason.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSProject?.createdBy !== "string" ? currentSPSProject?.createdBy?.name : "";
    const updatedBy =
      typeof currentSPSProject?.updatedBy !== "string" ? currentSPSProject?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentSPSProject?.createdAt}
        updatedAt={currentSPSProject?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSProject?.createdAt,
    currentSPSProject?.createdBy,
    currentSPSProject?.updatedAt,
    currentSPSProject?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.spsProjects.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsProjects.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  // Related Lists
  const renderSPSInsurance = useMemo(
    () => (
      <SPSInsuranceList
        relatedList={{
          type: "associatedProject",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderSPSDeals = useMemo(
    () => (
      <SPSDealsList
        relatedList={{
          type: "_id",
          value:
            typeof currentSPSProject.associatedDeal !== "string"
              ? currentSPSProject.associatedDeal?._id
              : currentSPSProject.associatedDeal,
        }}
      />
    ),
    [currentSPSProject.associatedDeal],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderSPSInsurance;
      case 6:
        return renderSPSDeals;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderSPSDeals,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    renderSPSInsurance,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSProjectsController = memo(Controller);
