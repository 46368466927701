import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  JobsPostingList,
  AddJobsPosting,
  UpdateJobsPosting,
  FindJobsPosting,
  DeleteJobsPosting,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchJobsPostingListSuccess, fetchJobsPostingError } from "./actions";
import Types from "./actionType";
import {
  CreateJobsPostinguccess,
  CreateJobsPosting as CreateJobsPostingType,
  JobsPostingSuccess,
  UpdateJobsPosting as UpdateJobsPostingType,
  FetchJobsPosting,
  FetchJobsPostingById,
  JobsPostingByIdSuccess,
  DeleteJobsPosting as DeleteJobsPostingType,
} from "./types";

function* createWorker(action: CreateJobsPostingType) {
  try {
    const options = {
      method: "POST",
      url: AddJobsPosting,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateJobsPostinguccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateJobsPostingType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateJobsPosting(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateJobsPostinguccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchJobsPosting) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: JobsPostingList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<JobsPostingSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchJobsPostingListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchJobsPostingError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching JobsPosting");
    }
  }
}

function* fetchJobsPostingById(action: FetchJobsPostingById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindJobsPosting(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<JobsPostingByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching JobsPosting");
    }
  }
}

function* deleteWorker(action: DeleteJobsPostingType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteJobsPosting(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateJobsPostinguccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_JOBS_POSTING, createWorker);
  yield takeLatest(Types.UPDATE_JOBS_POSTING, updateWorker);
  yield takeLatest(Types.DELETE_JOBS_POSTING, deleteWorker);
  yield takeEvery(Types.FETCH_JOBS_POSTING, fetchAllWorker);
  yield takeEvery(Types.FETCH_JOBS_POSTING_BY_ID, fetchJobsPostingById);
}
