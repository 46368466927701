import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";
// import styles from "../EmployeesController.styles";
import { generateArrayAttributes } from "../../../services/methods";

export const Tab5 = ({ formData, readOnly, onTextChange, formValidation }) => {
  return (
    <>
      <Input
        readOnly={readOnly}
        label={"National ID"}
        type={"text"}
        name='nationalId'
        value={formData.nationalId}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={formValidation?.nationalId?.error}
      />
      <Select
        readOnly={readOnly}
        name={"nationalIdType"}
        label={"National ID Type"}
        options={generateArrayAttributes("nationalIdType").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.nationalIdType}
        onChange={onTextChange}
        mandatory={true}
        error={formValidation?.nationalIdType?.error}
      />
      <Select
        readOnly={readOnly}
        name={"personalGender"}
        label={"Gender"}
        options={generateArrayAttributes("gender").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.personalGender}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"personalMartial"}
        label={"Maritial Status"}
        options={generateArrayAttributes("maritialStatus").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.personalMartial}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Date of birth"}
        type={"date"}
        name='personalDOB'
        value={formData.personalDOB}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Family Contact Name"}
        type={"text"}
        name='personalRelationshipName'
        value={formData.personalRelationshipName}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"personalRelationship"}
        label={"Family Contact Relation"}
        options={generateArrayAttributes("relation").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.personalRelationship}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Family Contact Mobile"}
        type={"text"}
        name='personalRelationshipContact'
        value={formData.personalRelationshipContact}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"personalCitizenship"}
        label={"Citizenship"}
        options={generateArrayAttributes("citizenship").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.personalCitizenship}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Address"}
        type={"text"}
        name='personalAddress'
        value={formData.personalAddress}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"City"}
        type={"text"}
        name='personalCity'
        value={formData.personalCity}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"State"}
        type={"text"}
        name='personalState'
        value={formData.personalState}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Zip"}
        type={"text"}
        name='personalZip'
        value={formData.personalZip}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"personalCountry"}
        label={"Country"}
        options={generateArrayAttributes("employeeCountry").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.personalCountry}
        onChange={onTextChange}
        error={formValidation?.nationalId?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Comment"}
        type={"text"}
        name='personalComment'
        value={formData.personalComment}
        autoComplete={"off"}
        onChange={onTextChange}
      />
    </>
  );
};
