import React from "react";

import { connect } from "react-redux";

import Select from "../../components/Select/select";
import { filterPayrolls } from "../../redux/employees/actions";

import styles from "./PayrollsFilters.styles";

const sortKeys = [{ label: "Date", key: "date", isDate: true }];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const PayrollsFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.filterPayrolls({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.EmployeesReducer.payrollsFilters,
});

const mapDispatchToProps = {
  filterPayrolls,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsFilters);
