import Types from "./actionType";
import {
  FetchEducationDetailsPayload,
  CreateEducationDetails,
  CreateEducationDetailsPayload,
  EducationDetailsList,
  FetchEducationDetails,
  FetchEducationDetailsError,
  FetchEducationDetailsSuccess,
  UpdateEducationDetails,
  UpdateEducationDetailsPayload,
  EducationDetailsListWithMetaData,
  FetchEducationDetailsByIdPayload,
  FetchEducationDetailsById,
  DeleteEducationDetailsPayload,
  DeleteEducationDetails,
} from "./types";

export const fetchEducationDetailsList = ({
  params,
  onSuccess,
  onError,
}: FetchEducationDetailsPayload): FetchEducationDetails => ({
  type: Types.FETCH_EDUCATION_DETAILS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchEducationDetailsById = ({
  id,
  onSuccess,
  onError,
}: FetchEducationDetailsByIdPayload): FetchEducationDetailsById => ({
  type: Types.FETCH_EDUCATION_DETAILS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchEducationDetailsListSuccess = (
  data: EducationDetailsListWithMetaData,
): FetchEducationDetailsSuccess => ({
  type: Types.SUCCESS_FETCH_EDUCATION_DETAILS,
  payload: {
    data,
  },
});

export const fetchEducationDetailsError = (error?: string): FetchEducationDetailsError => ({
  type: Types.ERROR_FETCH_EDUCATION_DETAILS,
  payload: { error },
});

export const createEducationDetails = ({
  data,
  onSuccess,
  onError,
}: CreateEducationDetailsPayload): CreateEducationDetails => ({
  type: Types.CREATE_EDUCATION_DETAILS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateEducationDetails = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateEducationDetailsPayload): UpdateEducationDetails => ({
  type: Types.UPDATE_EDUCATION_DETAILS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteEducationDetails = ({
  id,
  onSuccess,
  onError,
}: DeleteEducationDetailsPayload): DeleteEducationDetails => ({
  type: Types.DELETE_EDUCATION_DETAILS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
