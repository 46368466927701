import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  NotificationsDefinitionsList,
  AddNotificationsDefinitions,
  UpdateNotificationsDefinitions,
  FindNotificationsDefinitionsById,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchNotificationsDefinitionsListSuccess } from "./action";
import Types from "./actionType";
import {
  CreateNotificationsDefinitionsuccess,
  CreateNotificationsDefinitions as CreateNotificationsDefinitionsType,
  NotificationsDefinitionsSuccess,
  UpdateNotificationsDefinitions as UpdateNotificationsDefinitionsType,
  FetchNotificationsDefinitions,
  FetchNotificationsDefinitionsById,
  NotificationsDefinitionsByIdSuccess,
} from "./types";

function* createWorker(action: CreateNotificationsDefinitionsType) {
  try {
    const options = {
      method: "POST",
      url: AddNotificationsDefinitions,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateNotificationsDefinitionsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* updateWorker(action: UpdateNotificationsDefinitionsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateNotificationsDefinitions(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateNotificationsDefinitionsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchNotificationsDefinitions) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: NotificationsDefinitionsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<NotificationsDefinitionsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchNotificationsDefinitionsListSuccess(data.data));
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching academy courses");
    }
  }
}

function* fetchNotificationsDefinitionsById(action: FetchNotificationsDefinitionsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindNotificationsDefinitionsById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<NotificationsDefinitionsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_NOTIFICATIONS_DEFINITIONS, createWorker);
  yield takeLatest(Types.UPDATE_NOTIFICATIONS_DEFINITIONS, updateWorker);
  yield takeEvery(Types.FETCH_NOTIFICATIONS_DEFINITIONS, fetchAllWorker);
  yield takeEvery(Types.FETCH_NOTIFICATIONS_DEFINITIONS_BY_ID, fetchNotificationsDefinitionsById);
}
