import React, {
  useState,
  useCallback,
  ReactEventHandler,
  useRef,
  MouseEvent,
  ChangeEvent,
  Dispatch,
} from "react";

import { Params, useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createKnowledge,
  removeAppNotification,
  updateKnowledge,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { KnowledgeDetailsType } from "../../../../../redux/knowledge/types";
import { formValidationMethod } from "../../../../../services/methods";
import { images } from "../../../../../theme";
import {
  AppNotificationsType,
  KnowledgeAttachment,
  KnowledgeForm,
  SelectOptions,
  SubcategoriesAttributesType,
} from "../../../../../types";

interface KnowledgeControllerProps {
  type: "add" | "edit";
  attachmentsPickerRef: React.RefObject<HTMLInputElement>;
  params: Readonly<Params<string>>;
}

interface AttachmentsType {
  id: string;
  error: string;
  name: string;
}

interface KnowledgeController {
  handleAttachment?: () => void;
  knowledgeFormData: KnowledgeForm;
  onTextInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmitAddForm: () => void;
  onSubmitUpdateForm: () => void;
  subCategoriesFilter: (item: SubcategoriesAttributesType) => boolean;
  loading: boolean;
  onBodyChange: (value: string) => void;
  attachmentModal: "" | "add" | "list";
  handleAttachmentsModal: () => void;
  onAttachmentsLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onClickAddAttachment: () => void;
  onClickBrowseAttachment: () => void;
  onAttachmentsFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCurrentAttachment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentAttachmentFile?: File | null;
  currentAttachment: KnowledgeAttachment;
  attachmentUploadProgress: string;
  onClickCancelAttachment: () => void;
  onClickSaveAttachment: () => void;
  getAttachmentFileIcon: (extension?: string) => any;
  handleShowAttachment: (e: MouseEvent<HTMLImageElement>) => void;
  handleDeleteAttachment: (e: MouseEvent<HTMLImageElement>) => void;
  handleCloseAttachment: () => void;
  documentId: string;
  fetchKnowledgeSuccess: (knowledge: KnowledgeDetailsType) => void;
  fetchKnowledgeError: (error?: string) => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  body: string;
  bodyError: string;
}

const defaultCurrentAttachment: KnowledgeAttachment = { id: "", name: "", extension: "" };

export const useKnowledgeController = ({
  attachmentsPickerRef,
  params,
}: KnowledgeControllerProps): KnowledgeController => {
  const id = params.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const defaultFormData: KnowledgeForm = {
    name: {
      value: "",
      error: "",
      required: true,
    },
    knowledgebase: {
      value: "",
      error: "",
      required: true,
    },
    category: {
      value: "",
      error: "",
      required: true,
    },
    subcategory: {
      value: "",
      error: "",
      required: false,
    },
    modules: {
      value: [],
      error: "",
      required: true,
    },
    summary: {
      value: "",
      error: "",
      required: true,
    },
    body: {
      value: "",
      error: "",
      required: true,
    },
    attachments: {
      value: [],
      error: "",
      required: false,
    },
    status: {
      value: "",
      error: "",
      required: false,
    },
    comment: {
      value: "",
      error: "",
      required: false,
    },
  };

  const [knowledgeFormData, setKnowledgeFormData] = useState<KnowledgeForm>(defaultFormData);
  const [body, setBody] = useState<string>("");
  const [bodyError, setBodyError] = useState<string>("");
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] =
    useState<KnowledgeAttachment>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>("");

  const rehydrateKnowledgeForm = useCallback(
    (data: KnowledgeDetailsType) => {
      let tempFormData = { ...knowledgeFormData };

      Object.keys(data).forEach((element) => {
        const name = element;
        let value = data[element];
        const tempModules: SelectOptions[] = [];
        const tempAttachments: KnowledgeAttachment[] = [];
        switch (element) {
          case "category":
          case "subcategory":
          case "knowledgebase":
            value = value?._id;
            break;
          case "modules":
            for (let i = 0; i < value.length; i++) {
              const tempValue = {
                value: value[i]._id,
                label: value[i].name,
              };
              tempModules.push(tempValue);
            }
            value = tempModules;
            break;
          case "attachments":
            for (let i = 0; i < value.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
            break;
          case "body":
            setBody(value);
            break;
        }
        if (tempFormData[element] && value !== undefined && value !== null) {
          tempFormData = {
            ...tempFormData,
            [name]: {
              ...tempFormData[name],
              value: value,
            },
          };
        }
      });
      setKnowledgeFormData(tempFormData);
    },
    [knowledgeFormData],
  );

  const fetchKnowledgeSuccess = useCallback(
    (knowledge: KnowledgeDetailsType) => {
      rehydrateKnowledgeForm(knowledge);
      setLoading(false);
      // setKnowledge(knowledge);
    },
    [rehydrateKnowledgeForm],
  );

  const fetchKnowledgeError = useCallback((error?: string) => {
    setLoading(false);
  }, []);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onAttachmentsLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // const marginRight = e.target.
  }, []);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...knowledgeFormData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...knowledgeFormData,
          attachments: {
            ...knowledgeFormData.attachments,
            value: attachments,
          },
        };
        setKnowledgeFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, knowledgeFormData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const getAttachmentFileIcon = useCallback((extension?: string) => {
    switch (extension) {
      case "pdf":
        return images.pdfFileIcon;
      default:
        return images.defaultFileIcon;
    }
  }, []);

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = knowledgeFormData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [knowledgeFormData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...knowledgeFormData.attachments.value];
      attachments.splice(index, 1);
      const tempKnowledgeFormData = {
        ...knowledgeFormData,
        attachments: {
          ...knowledgeFormData.attachments,
          value: attachments,
        },
      };
      setKnowledgeFormData(tempKnowledgeFormData);
    },
    [knowledgeFormData],
  );

  const validateForm = useCallback(() => {
    let tempFormData = { ...knowledgeFormData };
    tempFormData.body = {
      ...tempFormData.body,
      value: body,
    };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (item === "body" && ["", undefined, null, "<p><br></p>"].includes(currentField.value)) {
        valid = false;
        setBodyError("Required Field");
      }
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setKnowledgeFormData(tempFormData);
    return valid;
  }, [knowledgeFormData, body]);

  const onTextInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      setKnowledgeFormData({
        ...knowledgeFormData,
        [name]: {
          ...knowledgeFormData[name],
          value: value,
          error: "",
        },
      });
    },
    [knowledgeFormData],
  );

  const onBodyChange = useCallback((value) => {
    setBody(value);
    setBodyError("");
  }, []);

  const onSubmitAddForm = useCallback(() => {
    const generateData = {};
    const submit = validateForm();
    Object.keys(knowledgeFormData).forEach((el) => {
      if (knowledgeFormData[el].othersfield) {
        generateData[el] = knowledgeFormData[el].othersvalue;
      } else {
        if (el === "body") {
          generateData[el] = body;
        } else if (["groups", "modules"].includes(el)) {
          const temp = [];
          for (let i = 0; i < knowledgeFormData[el].value.length; i++) {
            temp.push(knowledgeFormData[el].value[i].value);
          }
          generateData[el] = temp;
        } else {
          generateData[el] = knowledgeFormData[el].value;
        }
      }
    });
    generateData["attachments"] = knowledgeFormData.attachments.value.map((item) => ({
      name: item.name,
      id: item.id,
    }));

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setLoading(false);
    const params = {
      data: generateData,
      onSuccess: (data) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article created successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        navigate(`/ep/knowledge/${data.data._id}/details`);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(createKnowledge(params));
  }, [validateForm, knowledgeFormData, body, dispatch, notificationsId, navigate]);

  const onSubmitUpdateForm = useCallback(() => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(knowledgeFormData).forEach((el) => {
      if (knowledgeFormData[el].othersfield) {
        generateData[el] = knowledgeFormData[el].othersvalue;
      } else if (["groups", "modules"].includes(el)) {
        const temp = [];
        for (let i = 0; i < knowledgeFormData[el].value.length; i++) {
          temp.push(knowledgeFormData[el].value[i].value);
        }
        generateData[el] = temp;
      } else if (el === "body") {
        generateData[el] = body;
      } else {
        generateData[el] = knowledgeFormData[el].value;
      }
    });
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setLoading(true);
    const params = {
      id: id,
      data: generateData,
      onSuccess: (data) => {
        setLoading(false);
        const id = data?.data?._id;
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article updated successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        if (id) {
          navigate(`/ep/knowledge/${id}/details`);
        }
      },

      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateKnowledge(params));
  }, [dispatch, id, knowledgeFormData, navigate, validateForm, body, notificationsId]);

  const subCategoriesFilter = useCallback(
    (item: SubcategoriesAttributesType) => {
      if (knowledgeFormData.category.value === item.parentCategory) {
        return true;
      }
      return false;
    },
    [knowledgeFormData],
  );

  return {
    // attachments,
    knowledgeFormData,
    onTextInputChange,
    onSubmitAddForm,
    onSubmitUpdateForm,
    subCategoriesFilter,
    loading,
    onBodyChange,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    getAttachmentFileIcon,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    fetchKnowledgeSuccess,
    fetchKnowledgeError,
    setLoading,
    body,
    bodyError,
  };
};
