import Types from "./actionType";
import {
  FetchDashboardTabsPayload,
  CreateDashboardTabs,
  CreateDashboardTabsPayload,
  FetchDashboardTabs,
  FetchDashboardTabsError,
  FetchDashboardTabsSuccess,
  UpdateDashboardTabs,
  UpdateDashboardTabsPayload,
  DashboardTabsListWithMetaData,
  FetchDashboardTabsByIdPayload,
  FetchDashboardTabsById,
  DeleteDashboardTabsPayload,
  DeleteDashboardTabs,
} from "./types";

export const fetchDashboardTabsList = ({
  params,
  onSuccess,
  onError,
}: FetchDashboardTabsPayload): FetchDashboardTabs => ({
  type: Types.FETCH_DASHBOARD_TABS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchDashboardTabsById = ({
  id,
  onSuccess,
  onError,
}: FetchDashboardTabsByIdPayload): FetchDashboardTabsById => ({
  type: Types.FETCH_DASHBOARD_TABS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDashboardTabsListSuccess = (
  data: DashboardTabsListWithMetaData,
): FetchDashboardTabsSuccess => ({
  type: Types.SUCCESS_FETCH_DASHBOARD_TABS,
  payload: {
    data,
  },
});

export const fetchDashboardTabsError = (error?: string): FetchDashboardTabsError => ({
  type: Types.ERROR_FETCH_DASHBOARD_TABS,
  payload: { error },
});

export const createDashboardTabs = ({
  data,
  onSuccess,
  onError,
}: CreateDashboardTabsPayload): CreateDashboardTabs => ({
  type: Types.CREATE_DASHBOARD_TABS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDashboardTabs = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDashboardTabsPayload): UpdateDashboardTabs => ({
  type: Types.UPDATE_DASHBOARD_TABS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteDashboardTabs = ({
  id,
  onSuccess,
  onError,
}: DeleteDashboardTabsPayload): DeleteDashboardTabs => ({
  type: Types.DELETE_DASHBOARD_TABS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
