import React from "react";

import { Layout } from "../../../presentationals";
import { Recruitments, RecruitmentsSurvey } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<Recruitments />}
      controller={<RecruitmentsSurvey />}
      admin={true}
      layout={"3"}
      title={strings.headerTitle.recruitments}
    />
  );
};

export default Route;
