import Types from "./actionType";
import {
  FetchEmailSchedularPayload,
  CreateEmailSchedular,
  CreateEmailSchedularPayload,
  FetchEmailSchedular,
  FetchEmailSchedularError,
  FetchEmailSchedularSuccess,
  UpdateEmailSchedular,
  UpdateEmailSchedularPayload,
  EmailSchedularListWithMetaData,
  FetchEmailSchedularByIdPayload,
  FetchEmailSchedularById,
  DeleteEmailSchedularPayload,
  DeleteEmailSchedular,
} from "./types";

export const fetchEmailSchedularList = ({
  params,
  onSuccess,
  onError,
}: FetchEmailSchedularPayload): FetchEmailSchedular => ({
  type: Types.FETCH_EMAIL_SCHEDULAR,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchEmailSchedularById = ({
  id,
  onSuccess,
  onError,
}: FetchEmailSchedularByIdPayload): FetchEmailSchedularById => ({
  type: Types.FETCH_EMAIL_SCHEDULAR_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchEmailSchedularListSuccess = (
  data: EmailSchedularListWithMetaData,
): FetchEmailSchedularSuccess => ({
  type: Types.SUCCESS_FETCH_EMAIL_SCHEDULAR,
  payload: {
    data,
  },
});

export const fetchEmailSchedularError = (error?: string): FetchEmailSchedularError => ({
  type: Types.ERROR_FETCH_EMAIL_SCHEDULAR,
  payload: { error },
});

export const createEmailSchedular = ({
  data,
  onSuccess,
  onError,
}: CreateEmailSchedularPayload): CreateEmailSchedular => ({
  type: Types.CREATE_EMAIL_SCHEDULAR,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateEmailSchedular = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateEmailSchedularPayload): UpdateEmailSchedular => ({
  type: Types.UPDATE_EMAIL_SCHEDULAR,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteEmailSchedular = ({
  id,
  onSuccess,
  onError,
}: DeleteEmailSchedularPayload): DeleteEmailSchedular => ({
  type: Types.DELETE_EMAIL_SCHEDULAR,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
