import styled from "styled-components";

import { fs, colors, images, fonts } from "../../../theme";

interface Props {
  currentOrg?: boolean;
  border?: string;
  top?: string;
}

export const Container = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  background-color: ${colors.navigationPanel.background};
  /* background: green; */
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
`;
export const OrgImageContainer = styled.div`
  height: 80px;
  max-width: 20vw;
  min-width: 20vw;
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: solid 0.7px ${colors.navigationPanel.separatorColor};
  z-index: 2;
  box-shadow: 0px 0 0px rgba(0, 0, 0, 0.25), 0px 0 5px rgba(0, 0, 0, 0.25);
`;

export const Separator = styled.div<Props>`
  height: 0.7px;
  width: 98%;
  background-color: ${colors.navigationPanel.separatorColor};
  margin-top: ${(props) => (props.top ? props.top : "0")};
`;

export const OrgImage = styled.img`
  height: 80%;
  width: 80%;
  object-fit: contain;
  align-self: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2.22vw;
  padding-right: 2.22vw;
  width: 80vw;
  box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  z-index: 1;
  /* margin-left: 20vw; */
`;

export const Title = styled.text`
  color: ${colors.navigationPanel.activeTabText};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
`;

export const PrevTitle = styled.text`
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  align-self: center;
  cursor: pointer;
`;

export const ProfileImage = styled.img<Props>`
  width: 36px;
  height: 36px;
  margin-left: 40px;
  border-radius: 2rem;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  object-fit: contain;
  align-self: center;
  cursor: pointer;
  border: solid 2px ${(props) => (props.border ? props.border : "transparent")};
`;

// Notification Modal
export const ModalContainer = styled.div``;

// Profile Modal
export const ProfileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 80%;
  right: 2%;
  width: 259px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: ${colors.common.white};
  z-index: 10;
`;

export const Name = styled.text`
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
`;

export const Designation = styled.text`
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.s};
  line-height: 20px;
  text-align: center;
`;

export const Seperator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.common.black};
  margin-top: 14px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`;

export const MenuItem = styled.div<Propd>`
  width: 239px;
  align-items: center;
  padding-left: 4px;
  height: 35px;
  display: flex;
  color: ${(props) => (props.currentOrg ? colors.common.titleColor : colors.common.black)};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  border-radius: 4px;
  cursor: pointer;
  transition: linear 0.4s;
  background-color: ${(props) => props.currentOrg && colors.common.primary};
  margin-top: 2px;
  &:hover {
    background-color: ${(props) => !props.currentOrg && colors.common.hoverTabBackground};
  }
`;

export const PanelSwitch = styled.div`
  width: 160px;
  align-items: center;
  justify-content: center;
  height: 40px;
  display: flex;
  color: ${colors.common.titleColor};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${colors.common.primary};
  margin-right: 10%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.common.black};
`;

export const ChangeOrganisationTitle = styled.div`
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
  cursor: pointer;
  text-align: center;
  padding-bottom: 6px;
  margin-left: 10px;
`;

export const LeftArrow = styled.img`
  height: 12px;
  width: 12px;
  object-fit: contain;
  margin-top: 2px;
  /* align-self: center; */
`;

export const BreadCrumbArrow = styled.img`
  height: 8px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
