import React, { useEffect, useMemo, memo } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType } from "../../../../types";
import { BugReportingListProps } from "../../../../types/models/BugReporting";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV2 } from "../../../components";

import { useBugReportingList } from "./hooks";

export const BugReportingList = memo(({ relatedList }: BugReportingListProps) => {
  const BugReporting = useAppSelector((state) => state.BugReportingReducer.data);
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.bugReporting;

  const { list, metaData } = BugReporting;
  const {
    fetchBugReporting,
    loading,
    handlePageChange,
    onClickBugReporting,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    bugReportingHeaderButtons,
    bugReportingFilterFields,
  } = useBugReportingList({
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      title: {
        title: "Title",
        sortSupported: true,
        width: "200px",
        key: "title",
      },
      assignedTo: {
        title: "Assigned To",
        width: "150px",
        key: "assignedTo",
        render: (item) => {
          return <text className='title'>{item.assignedTo?.name}</text>;
        },
      },
      status: {
        title: "Status",
        width: "150px",
        key: "status",
        render: (item) => {
          return <text className='title'>{item.status?.value}</text>;
        },
      },

      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        width: "150px",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        width: "150px",
        render: (item) => {
          return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        width: "150px",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        width: "150px",
        render: (item) => {
          return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.fields?.length
      ? tableListConfigs?.fields
      : tableListDefaultSelectFields.bugReporting;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortIndex = tablePublicFields.bugReporting.map((item) => item.key);
    const sortedColumns: ColumnsType[] = sortTableListColumns(sortIndex, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchBugReporting({});
    // Do not include fetchBugReporting to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV2
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={bugReportingHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.bugReporting}
      handleTableConfigs={handleTableConfigs}
      filters={bugReportingFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.bugReporting
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickBugReporting}
      headerTitle={strings.headerTitle.bugReporting}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apBugReporting.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
