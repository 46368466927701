import React from "react";

import { Layout } from "../../../presentationals";
import { SPSDealsList } from "../../../presentationals/screens/admin/SPSDeals/SPSDealsList";

const Route = () => {
  return <Layout activity={<SPSDealsList />} admin={true} layout={"2"} />;
};

export const APSPSDealsList = React.memo(Route);
