import styled from "styled-components";

import { colors, fonts, fs } from "../../../theme";

interface Props {
  layout?: "1" | "2" | "3";
  isOnlyListingView?: boolean;
  noPadding?: boolean;
}

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: row;
  position: relative;
  justify-content: center;
  .skeleton {
    margin-top: 10px;
    height: 60px;
  }
  .ruleGroup-addRule,
  .ruleGroup-addGroup,
  .ruleGroup-combinators {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    height: fit-content;
    padding: 4px 10px;
    color: ${colors.common.white};
    border: none;
    background-color: ${colors.common.yellow};
    font-size: ${fs.s};
    font-weight: 600;
    line-height: 20px;
  }
  .ruleGroup-remove,
  .rule-remove {
    display: flex !important ;
    padding: 4px 10px;
    color: ${colors.common.white};
    background-color: ${colors.common.red};
    margin-left: 20px;
    font-size: ${fs.s};
    font-weight: 600;
    line-height: 20px;
    border-radius: 5px;
    border: none;
  }
  .rule-fields,
  .rule-operators,
  .rule-value {
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
`;

// Left Panel
export const NavigationPanel = styled.div`
  min-width: 20vw;
  max-width: 20vw;
  display: flex;
  margin-top: 81px;
  z-index: 3;
`;

// Middle Panel
export const ViewPanelContainer = styled.div<Props>`
  width: ${(props) => (props.layout === "2" ? "80vw" : props.isOnlyListingView ? "60vw" : "30vw")};
  overflow-y: scroll;
  background-color: ${colors.mainPanel.background};
  transition: 0.6s;
`;
export const ViewPanel = styled.div<Props>`
  margin-top: 70px;
  padding: ${(props) => (props.noPadding ? "0" : "2.6% 2.77%")};
`;

// Right Panel
export const ControllerPanel = styled.div<Props>`
  margin-top: 70px;
  display: flex;
  width: ${(props) => (props.layout === "2" ? "0" : props.isOnlyListingView ? "20vw" : "5")};
  overflow-y: auto;
  transition: 0.6s;
  background-color: ${colors.controllerBackground};
  padding: ${(props) => (props.layout === "2" ? "0" : "2vh 0.6vw")};
`;

export const NotificationContainer = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  /* right: 10px; */
  align-self: center;
  z-index: 40;
  color: #929292;
  .app-notification-container {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
  }

  .notification-text {
    color: ${colors.common.white};
    font-family: ${fonts.SourceSansPro};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .success {
    background: linear-gradient(15deg, #6adb00, #04e800);
    color: #64c695;
  }
  .error {
    background: linear-gradient(15deg, #ff596d, #d72c2c);
    color: #030303;
  }
  .warning {
    background: linear-gradient(15deg, #ffac37, #ff9238);
    color: #030303;
  }
`;
