import React, { useCallback, useState, useMemo } from "react";

import { defaultOperators, Field, RuleGroupTypeIC, RuleType } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchTaskList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateTableConfigs,
  updateTask,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  FetchTaskParams,
  FetchTaskPayload,
  TaskList,
  TaskListWithMetaData,
} from "../../../../../redux/Task/types";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  fetchTaskProps,
  QueryBuilderType,
  RelatedList,
  sortType,
  TableHeaderButton,
  TablePublicFields,
  useTaskListProps,
  useTaskListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.tasks;
const defaultFilters: QueryBuilderType[] = [];

export const useTaskList = ({
  relatedList,
  associatedTo,
  active,
  assignedTo,
  assignedGroupOfuser,
  watchlistGroupOfuser,
}: useTaskListProps): useTaskListReturnType => {
  // const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const hrTasksListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.hrTasks;
  const companyTasksListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.companyTasks;
  const achTasksListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.achTasks;
  const isRelatedList = !!relatedList?.type;

  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.TaskReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const tasks = useAppSelector((state) => state.TaskReducer.data.list);
  const {
    employeesOptions,
    usersOptions,
    companyOptions,
    academyCandidateEnrollmentsOptions,
    academyCandidateLeadsOptions,
    academyStudentProfileOptions,
  } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");
  const taskPublicFields = useMemo(() => {
    let fields: TablePublicFields[] = [];
    switch (associatedTo) {
      case "hr":
        fields = tablePublicFields.hrTasks;
        break;
      case "company":
        fields = tablePublicFields.companyTasks;

        break;
      case "ach":
        fields = tablePublicFields.achTasks;

        break;
    }
    return fields;
  }, [associatedTo]);
  const taskAssociatedRecord = useCallback((task: TaskList, columnName: string) => {
    if (task?.associatedCompany?.company && columnName === "associatedCompany") {
      return task?.associatedCompany?.company;
    }
    if (task?.associatedEmployee?.name && columnName === "associatedEmployee") {
      return task?.associatedEmployee?.name;
    }
    if (task?.associatedCandidateLead?._id && columnName === "associatedCandidateLead") {
      const firstName = task?.associatedCandidateLead?.firstName || "";
      const lastName = task?.associatedCandidateLead?.lastName || "";
      return `${firstName} ${lastName}`;
    }
    if (
      task?.associatedCandidateEnrolment?.studentName &&
      columnName === "associatedCandidateEnrolment"
    ) {
      return task?.associatedCandidateEnrolment?.studentName;
    }
    if (
      task?.associatedCandidateStudent?.studentName &&
      columnName === "associatedCandidateStudent"
    ) {
      return task?.associatedCandidateStudent?.studentName;
    }
    return "-";
  }, []);
  const taskTableConfigKey = useMemo(() => {
    let prefix = "";
    switch (associatedTo) {
      case "hr":
        prefix = "hrTasks";
        break;
      case "company":
        prefix = "companyTasks";
        break;
      case "ach":
        prefix = "achTasks";
        break;
    }
    return prefix;
  }, [associatedTo]);

  const taskListConfigs = useMemo(() => {
    let configs: string[] = [];

    switch (associatedTo) {
      case "hr":
        configs = hrTasksListConfigs?.fields?.length
          ? hrTasksListConfigs?.fields
          : tableListDefaultSelectFields.hrTasks;
        break;
      case "company":
        configs = companyTasksListConfigs?.fields?.length
          ? companyTasksListConfigs?.fields
          : tableListDefaultSelectFields.companyTasks;

        break;
      case "ach":
        configs = achTasksListConfigs?.fields?.length
          ? achTasksListConfigs?.fields
          : tableListDefaultSelectFields.achTasks;

        break;
    }
    return configs;
  }, [
    achTasksListConfigs?.fields,
    associatedTo,
    companyTasksListConfigs?.fields,
    hrTasksListConfigs?.fields,
  ]);
  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: TaskList[]) => {
      const userPersonalizedFields: string[] = taskListConfigs;
      records = records?.map((item) => {
        const taskItem = { ...item };
        if (
          userPersonalizedFields?.includes("associatedEmployee") &&
          typeof item.associatedEmployee !== "string"
        ) {
          taskItem.associatedEmployee = item?.associatedEmployee?.name;
        }
        if (
          userPersonalizedFields?.includes("associatedCompany") &&
          typeof item.associatedCompany !== "string"
        ) {
          taskItem.associatedCompany = item?.associatedCompany?.company;
        }

        if (
          userPersonalizedFields?.includes("associatedCandidateLead") &&
          typeof item.associatedCandidateLead !== "string"
        ) {
          const firstName = item?.associatedCandidateLead?.firstName || "";
          const lastName = item?.associatedCandidateLead?.lastName || "";
          taskItem.associatedCandidateLead = `${firstName} ${lastName}`;
        }
        if (
          userPersonalizedFields?.includes("associatedCandidateEnrolment") &&
          typeof item.associatedCandidateEnrolment !== "string"
        ) {
          taskItem.associatedCandidateEnrolment = item?.associatedCandidateEnrolment?.studentName;
        }
        if (
          userPersonalizedFields?.includes("associatedCandidateStudent") &&
          typeof item.associatedCandidateStudent !== "string"
        ) {
          taskItem.associatedCandidateStudent = item?.associatedCandidateEnrolment?.studentName;
        }

        if (userPersonalizedFields?.includes("type") && typeof item.type !== "string") {
          taskItem.type = item?.type?.value;
        }
        if (userPersonalizedFields?.includes("assignedTo") && typeof item.assignedTo !== "string") {
          taskItem.assignedTo = item?.assignedTo?.name;
        }
        if (userPersonalizedFields?.includes("priority") && typeof item.priority !== "string") {
          taskItem.priority = item?.priority?.value;
        }
        if (userPersonalizedFields?.includes("state") && typeof item.state !== "string") {
          taskItem.state = item?.state?.value;
        }
        if (userPersonalizedFields?.includes("urgency") && typeof item.urgency !== "string") {
          taskItem.urgency = item?.urgency?.value;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          taskItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          taskItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          taskItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          taskItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete taskItem?._id;
        return taskItem;
      });
      const tasksFields = taskPublicFields;
      const columnHeaders = tasksFields.filter((item) => userPersonalizedFields.includes(item.key));
      downloadFile(columnHeaders, records, taskTableConfigKey);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, taskPublicFields, taskListConfigs, taskTableConfigKey],
  );

  // ================ Fetch Academy ACH Success/Error Methods ================

  const taskFetchSuccess = useCallback(
    (data: TaskListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const taskFetchError = useCallback(
    (error) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchTasks = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchTaskProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.tasks?.limit || 10;

      const params: FetchTaskParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };

      if (search) {
        params["task"] = search;
      }
      if (associatedTo) {
        params["associatedTo"] = associatedTo;
      }
      if (active) {
        params["active"] = active === "true";
      }
      if (assignedTo) {
        params["assignedTo"] = assignedTo;
      }
      if (watchlistGroupOfuser) {
        params["watchlistGroupOfuser"] = watchlistGroupOfuser;
      }
      if (assignedGroupOfuser) {
        params["assignedGroupOfuser"] = assignedGroupOfuser;
      }
      if (isRelatedList && relatedList.type) {
        params[relatedList.type] = relatedList.value;
      }
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      const payload: FetchTaskPayload = {
        params,
        onSuccess: (data: TaskListWithMetaData) => taskFetchSuccess(data, exportAll),
        onError: taskFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchTaskList(payload));
    },
    [
      metaData.currentPage,
      tableConfiguration?.tasks?.limit,
      mongoQuery,
      sortKey,
      sortType,
      associatedTo,
      active,
      assignedTo,
      watchlistGroupOfuser,
      assignedGroupOfuser,
      isRelatedList,
      relatedList,
      taskFetchError,
      dispatch,
      taskFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = tasks?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, tasks, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => fetchTasks({ exportAll: true }), [fetchTasks]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchTasks({ page });
  }, [fetchTasks, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchTasks({ page: parseInt(page), search });
    },
    [fetchTasks, search],
  );

  // ================ Add/Edit Methods ================

  const onClickTask = useCallback(
    (id: string) => {
      navigate(`${edit(id)}/?associatedTo=${associatedTo}`);
      // if (permissions?.tasks_view)
      // else {
      //   const notification: AppNotificationsType = {
      //     id: notificationsId + 1,
      //     title: "Permission denied",
      //     type: "error",
      //   };
      //   dispatch(addAppNotification({ notification }));
      //   setTimeout(() => {
      //     dispatch(removeAppNotification({ notification }));
      //   }, 2000);
      // }
    },
    [navigate, associatedTo],
  );

  const onClickAdd = useCallback(
    () => navigate(`${add}/?associatedTo=${associatedTo}`),
    [navigate, associatedTo],
  );
  // ================ Search Methods ================

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchTasks({ page: 1, search });
  }, [fetchTasks, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchTasks({ page: 1 });
  }, [fetchTasks]);
  const taskPrefix = useMemo(() => {
    let prefix = "";
    switch (associatedTo) {
      case "hr":
        prefix = "HRTASK00";
        break;
      case "company":
        prefix = "CPTASK00";
        break;
      case "ach":
        prefix = "ACHTASK00";
        break;
    }
    return prefix;
  }, [associatedTo]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchTasks({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchTasks],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchTasks({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchTasks],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRows = tasks?.map((item) => item?._id);
    return isEqualArrays(allRows, selectedRows);
  }, [selectedRows, tasks]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allItems: string[] = [];
            tasks?.forEach((item) => {
              if (item?._id) {
                allItems.push(item?._id);
              }
            });
            setSelectedRows(allItems);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, tasks, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const tableConfigLimit = tableConfiguration?.[taskTableConfigKey]?.limit || 10;
      const tableConfigs = {
        table: taskTableConfigKey,
        fields: fields,
        limit: tableConfigLimit,
      };
      const payload = {
        tableConfiguration: tableConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchTasks({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Tasks table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, tableConfiguration, taskTableConfigKey, fetchTasks, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const tasksConfigFields = tableConfiguration?.[taskTableConfigKey]?.fields;
      dispatch(showInAppLoader(true));
      const tasksConfigs = {
        table: taskTableConfigKey,
        fields: tasksConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: tasksConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchTasks({ limit: count });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Tasks table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration, taskTableConfigKey, dispatch, fetchTasks, notificationsId],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = tasks?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        associatedTo: associatedTo,
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchTasks({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Tasks updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Tasks ",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };

      dispatch(updateTask(payload));
    },
    [selectedRows, dispatch, tasks, associatedTo, notificationsId, fetchTasks],
  );
  // ================ Table Header Buttons ================

  const tasksHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const associatedTaskType = useMemo(() => {
    switch (associatedTo) {
      case "company":
        return "companyTaskType";
      case "ach":
        return "achTaskType";
      case "hr":
        return "taskType";
      default:
        return "taskType";
    }
  }, [associatedTo]);

  const listActionOptions = useMemo(() => {
    const HRTasksOptions = [
      {
        name: "associatedEmployee",
        label: "Employee",
        options: employeesOptions,
      },
    ];
    const CompanyTasksOptions = [
      {
        name: "associatedCompany",
        label: "Company",
        options: companyOptions,
      },
    ];
    const ACHTasksOptions = [
      {
        name: "associatedCandidateLead",
        label: "Associated Candidate Lead",
        options: academyCandidateLeadsOptions,
      },
    ];
    const options = [
      {
        name: "priority",
        label: "Priority",
        options: getAttributValues("taskPriority"),
      },
      {
        name: "active",
        label: "Active",
        options: ActiveSelectOptions,
      },
      {
        name: "state",
        label: "State",
        options: getAttributValues("taskState"),
      },
      {
        name: "type",
        label: "Type",
        options: getAttributValues(associatedTaskType),
      },
    ];
    switch (associatedTo) {
      case "company":
        return CompanyTasksOptions.concat(options);
      case "ach":
        return ACHTasksOptions.concat(options);
      case "hr":
        return HRTasksOptions.concat(options);
      default:
        return options;
    }
  }, [
    academyCandidateLeadsOptions,
    associatedTaskType,
    associatedTo,
    companyOptions,
    employeesOptions,
  ]);

  // ================ Filter Fields Configuration ================

  const tasksFilterFields = useMemo(() => {
    enum ValueEditorType {
      Select = "select",
      // Add other allowed values here
    }
    const validator = (r: RuleType) => !!r.value;
    const activityTypeOptions = getFilterOptions(getAttributValues("tasksType"), "Activity Type");
    const achStateOptions = getFilterOptions(getAttributValues("tasksState"), "State");
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const taskPriorityFilterOptions = getFilterOptions(getAttributValues("taskPriority"), "");
    const activeFilterOptions = getFilterOptions(ActiveSelectOptions, "");
    const taskStateFilterOptions = getFilterOptions(getAttributValues("taskState"), "");
    const taskTypeFilterOptions = getFilterOptions(getAttributValues(associatedTaskType), "");
    const fields: Field[] = [
      {
        name: "state",
        label: "State",
        valueEditorType: "select",
        values: taskStateFilterOptions,
        defaultValue: taskStateFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "priority",
        label: "Priority",
        valueEditorType: "select",
        values: taskPriorityFilterOptions,
        defaultValue: taskPriorityFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "type",
        label: "Type",
        valueEditorType: "select",
        values: taskTypeFilterOptions,
        defaultValue: taskTypeFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      ...(active?.length
        ? []
        : [
            {
              name: "active",
              label: "Active",
              valueEditorType: ValueEditorType.Select,
              values: activeFilterOptions,
              defaultValue: activeFilterOptions?.[0]?.options?.[0]?.name || "",
              operators: defaultOperators.filter((op) => op.name === "="),
            },
          ]),
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [active?.length, associatedTaskType, employeesOptions, usersOptions]);

  return {
    onClickTask,
    fetchTasks,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    taskAssociatedRecord,
    taskPrefix,
    taskPublicFields,
    taskListConfigs,
    listActionOptions,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportSelectedRecords,
    exportAllRecords,
    tasksFilterFields,
    tasksHeaderButtons,
  };
};
