/* eslint-disable no-unused-vars */
enum Types {
  FETCH_WORKSPACE = "FETCH_WORKSPACE",
  SUCCESS_FETCH_WORKSPACE = "SUCCESS_FETCH_WORKSPACE",
  SET_META_DATA_WORKSPACE = "SET_META_DATA_WORKSPACE",
  ERROR_FETCH_WORKSPACE = "ERROR_FETCH_WORKSPACE",
  FETCH_WORKSPACE_BY_ID = "FETCH_WORKSPACE_BY_ID",
  CREATE_WORKSPACE = "CREATE_WORKSPACE",
  UPDATE_WORKSPACE = "UPDATE_WORKSPACE",
  UPDATE_FITERS_WORKSPACE = "UPDATE_FITERS_WORKSPACE",
  WORKSPACE_FETCH_SUCCESS = "WORKSPACE_FETCH_SUCCESS",
  DELETE_WORKSPACE = "DELETE_WORKSPACE",
}

export default Types;
