import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { QueryBuilder, defaultValidator } from "react-querybuilder";
import { useLocation, useParams } from "react-router-dom";

import {
  ActiveSelectOptions,
  DashboardFilterTableOptions,
  DashboardWidgetTypeOptions,
  collections,
} from "../../../../../utils/constants";
import { useControllerConfigs } from "../../../../common";
import { FormTitle, QueryBuilderContainer, Row } from "../../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../../components";
import { TextInput, Select, SystemData } from "../../../../components/atoms";
import { FormChecklistV2 } from "../../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useDashboardWidgetsController } from "./hooks";

export const DashboardWidgetsController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { dashboardTabsOptions } = useControllerConfigs();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dashboardTabId = searchParams.get("dashboardTab");
  const {
    currentStepIndex,
    handleDefaultDashboardTab,
    getTableFields,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchDashboardWidgets,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    getTableFilters,
    query,
    handleQuerychange,
    formTitle,
    formSteps,
    formRelatedList,
    TableList,
  } = useDashboardWidgetsController({ type, id: params.id, attachmentsPickerRef });
  const currentDashboardWidgets = data;

  const { workLogs, attachments, title, description, active, table, dashboardTab, fields } =
    formData;

  useEffect(() => {
    if (type === "edit") {
      fetchDashboardWidgets();
    }
    // Do not include fetchDashboardWidgets to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);
  useEffect(() => {
    if (dashboardTabId) {
      handleDefaultDashboardTab(dashboardTabId);
    } // Do not include handleDefaultDashboardTab to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardTabId]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Title"}
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Desctiption'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              rows: 14,
              required: description.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    onTextChange,
    title.error,
    title.required,
    title.value,
  ]);

  const renderAccess = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <Select
            width={"33%"}
            name={"table"}
            label={"Table"}
            options={TableList}
            value={table.value}
            onChange={onTextChange}
            mandatory={table.required}
            error={table.error}
          />
          <Select
            width={"33%"}
            name={"type"}
            label={"Type"}
            options={DashboardWidgetTypeOptions}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
          <Select
            width={"33%"}
            name={"dashboardTab"}
            label={"Dashboard Tab"}
            options={dashboardTabsOptions}
            value={dashboardTab.value}
            onChange={onTextChange}
            mandatory={dashboardTab.required}
            error={dashboardTab.error}
          />
        </Row>
        {formData.type.value === "list" && (
          <Select
            width={"100%"}
            name={"fields"}
            label={"Fields"}
            isMulti
            options={getTableFields}
            value={fields.value}
            onChange={onTextChange}
            mandatory={fields.required}
            error={fields.error}
          />
        )}
        <QueryBuilderContainer>
          <FormTitle>{"Condition Builder"}</FormTitle>
          <QueryBuilder
            translations={{
              addRule: {
                label: "Add Rule",
              },
              addGroup: {
                label: "Add Group",
              },
              removeRule: {
                label: "Delete Rule",
              },
              removeGroup: {
                label: "Delete Group",
              },
            }}
            fields={getTableFilters}
            query={query}
            onQueryChange={handleQuerychange}
            addRuleToNewGroups
            debugMode
            independentCombinators
            showCombinatorsBetweenRules
            validator={defaultValidator}
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          />
        </QueryBuilderContainer>
      </React.Fragment>
    );
  }, [
    dashboardTab.error,
    dashboardTab.required,
    dashboardTab.value,
    dashboardTabsOptions,
    fields.error,
    fields.required,
    fields.value,
    formData.type.error,
    formData.type.required,
    formData.type.value,
    getTableFields,
    getTableFilters,
    handleQuerychange,
    onTextChange,
    query,
    table.error,
    table.required,
    table.value,
    TableList,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentDashboardWidgets?.createdBy !== "string"
        ? currentDashboardWidgets?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentDashboardWidgets?.updatedBy !== "string"
        ? currentDashboardWidgets?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentDashboardWidgets?.createdAt}
        updatedAt={currentDashboardWidgets?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentDashboardWidgets?.createdAt,
    currentDashboardWidgets?.createdBy,
    currentDashboardWidgets?.updatedAt,
    currentDashboardWidgets?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.dashboardWidgets.name} recordId={params.id} />,

    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.dashboardWidgets.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,

    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderAccess;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderAccess,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
