import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchAcademyStudentProfileList,
  getProfile,
  showInAppLoader,
  removeAppNotification,
  updateAcademyStudentProfile,
  updateTableConfigs,
  deleteAcademyStudentProfile,
} from "../../../../../redux";
import {
  AcademyStudentProfileList,
  AcademyStudentProfileListWithMetaData,
  DeleteAcademyStudentProfilePayload,
  FetchAcademyStudentProfileParams,
} from "../../../../../redux/AcademyStudentProfile/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchStudentProfileProps,
  sortType,
  useStudentProfileListProps,
  useStudentProfileListReturnType,
} from "../../../../../types";
import { AppRouteLinks, tablePublicFields } from "../../../../../utils";
import { getAcademyStudentProfileColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

import { tableListDefaultSelectFields } from "./../../../../../utils/tableConfigs";

const { add, edit } = AppRouteLinks.academyStudentProfile;
const defaultFilters: QueryBuilderType[] = [];

export const useAcademyStudentProfileList = ({
  relatedList,
  widgetId,
}: useStudentProfileListProps): useStudentProfileListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const metaData = useAppSelector((state) => state.AcademyStudentProfileReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const studentProfiles = useAppSelector((state) => state.AcademyStudentProfileReducer.data.list);
  const {
    employeesOptions,
    usersOptions,
    academyCoursesOptions,
    academyCandidateEnrollmentsOptions,
    academBatchesOptions,
  } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const studentProfileTableConfigFields = useMemo(() => {
    return tableConfiguration?.studentProfile?.fields?.length
      ? tableConfiguration?.studentProfile?.fields
      : tableListDefaultSelectFields.studentProfile;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = studentProfileTableConfigFields;
    return getAcademyStudentProfileColumns({ fields });
  }, [studentProfileTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: AcademyStudentProfileList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.studentProfile?.fields || tableListDefaultSelectFields.studentProfile;
      records = records?.map((item) => {
        const academyStudentProfileItem = { ...item };
        if (
          userPersonalizedFields?.includes("enrolledCourse") &&
          typeof item.enrolledCourse !== "string"
        ) {
          academyStudentProfileItem.enrolledCourse = item.enrolledCourse?.name;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          academyStudentProfileItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          academyStudentProfileItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          academyStudentProfileItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          academyStudentProfileItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete academyStudentProfileItem?._id;
        return academyStudentProfileItem;
      });
      const studentProfileFields = tablePublicFields.academyStudentProfile;
      const columnHeaders = studentProfileFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "student_profile");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.studentProfile?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Academy Student Profile Success/Error Methods ================
  const academyStudentProfileFetchSuccess = useCallback(
    (data: AcademyStudentProfileListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const academyStudentProfileFetchError = useCallback(
    (error) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [notificationsId, dispatch],
  );

  // ================ Fetch Academy Student Profile Method ================

  const fetchAcademyStudentProfile = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchStudentProfileProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.studentProfile?.limit || 10;

      const params: FetchAcademyStudentProfileParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: AcademyStudentProfileListWithMetaData) =>
          academyStudentProfileFetchSuccess(data, exportAll),
        onError: academyStudentProfileFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchAcademyStudentProfileList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.studentProfile?.limit,
      sortKey,
      sortType,
      relatedList,
      academyStudentProfileFetchError,
      dispatch,
      widgetsData,
      academyStudentProfileFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = studentProfiles?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, studentProfiles, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchAcademyStudentProfile({ exportAll: true });
  }, [fetchAcademyStudentProfile]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchAcademyStudentProfile({ page });
  }, [fetchAcademyStudentProfile, metaData.currentPage]);

  // ================ Add/Edit Methods ================

  const handlePageChange = useCallback(
    (page: string) => {
      fetchAcademyStudentProfile({ page: parseInt(page), search });
    },
    [fetchAcademyStudentProfile, search],
  );

  const onClickAcademyStudentProfile = useCallback(
    (id: string) => {
      if (permissions?.academyStudentProfile_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, notificationsId, permissions?.academyStudentProfile_view, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchAcademyStudentProfile({ page: 1, search });
  }, [fetchAcademyStudentProfile, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchAcademyStudentProfile({ page: 1 });
  }, [fetchAcademyStudentProfile]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchAcademyStudentProfile({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchAcademyStudentProfile],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchAcademyStudentProfile({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchAcademyStudentProfile],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = studentProfiles?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteAcademyStudentProfilePayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchAcademyStudentProfile({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteAcademyStudentProfile(payload));
  }, [selectedRows, studentProfiles, dispatch, notificationsId, fetchAcademyStudentProfile]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = studentProfiles?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, studentProfiles]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            studentProfiles?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, studentProfiles, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const academyStudentProfileConfigLimit = tableConfiguration?.studentProfile?.limit || 10;
      const academyStudentProfileConfigs = {
        table: "studentProfile",
        fields: fields,
        limit: academyStudentProfileConfigLimit,
      };
      const payload = {
        tableConfiguration: academyStudentProfileConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyStudentProfile({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Student Profile table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      tableConfiguration?.studentProfile?.limit,
      dispatch,
      fetchAcademyStudentProfile,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const studentProfileConfigFields = tableConfiguration?.studentProfile?.fields;
      dispatch(showInAppLoader(true));
      const studentProfileConfigs = {
        table: "studentProfile",
        fields: studentProfileConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: studentProfileConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyStudentProfile({ limit: count });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Student Profile table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchAcademyStudentProfile],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = studentProfiles?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchAcademyStudentProfile({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Student Profile updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating student profile",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateAcademyStudentProfile(payload));
    },
    [selectedRows, studentProfiles, dispatch, notificationsId, fetchAcademyStudentProfile],
  );

  // ================ Table Header Buttons ================

  const studentProfileHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "studentName",
      label: "Student Name",
      type: "text",
      required: true,
    },
    {
      name: "enrolledCourse",
      label: "Course",
      options: academyCoursesOptions,
      required: true,
    },
    {
      name: "associatedEnrollment",
      label: "Associated Enrollment",
      options: academyCandidateEnrollmentsOptions,
    },
    {
      name: "studentID",
      label: "Student ID",
      type: "text",
      required: true,
    },
    {
      name: "studentOfficialEmail",
      label: "Student Official Email",
      type: "text",
    },
    {
      name: "studentTrainingCordinator",
      label: "Student's Training Cordinator",
      options: employeesOptions,
    },
    {
      name: "batches",
      label: "Batches",
      options: academBatchesOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const studentProfileFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const coursesOptions = getFilterOptions(academyCoursesOptions, "Courses");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const studentTrainigOptions = getFilterOptions(employeesOptions, "Student Training Cordinator");
    const assosiateOptions = getFilterOptions(
      academyCandidateEnrollmentsOptions,
      "Assosiate Options",
    );

    const fields: Field[] = [
      {
        name: "studentName",
        label: "Name",
        placeholder: "Enter name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "studentID",
        label: "Student ID",
        placeholder: "Enter ID",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "studentOfficialEmail",
        label: "Official Email",
        placeholder: "Enter Email",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "enrolledCourse",
        label: "Enrolled Course",
        valueEditorType: "select",
        values: coursesOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "studentTrainingCordinator",
        label: "Student Training Cordinator",
        valueEditorType: "select",
        values: studentTrainigOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "associatedEnrollment",
        label: "Associated Enrollment",
        valueEditorType: "select",
        values: assosiateOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [academyCandidateEnrollmentsOptions, academyCoursesOptions, employeesOptions, usersOptions]);

  return {
    onClickAcademyStudentProfile,
    fetchAcademyStudentProfile,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    onSubmitDeleteRecord,
    listActionOptions,
    studentProfileFilterFields,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    columns,
    studentProfileTableConfigFields,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    studentProfileHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
  };
};
