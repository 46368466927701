import React, { memo } from "react";

import { Layout } from "../../../presentationals";
import { DashboardAdministrationController } from "../../../presentationals/screens";

export const APDashboardAdministrationEdit = memo(() => (
  <Layout
    activity={<DashboardAdministrationController type={"edit"} />}
    admin={true}
    layout={"2"}
  />
));
