import React, { useEffect, useMemo } from "react";

import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { colors, strings } from "../../../../theme";
import { ColumnsType, FiltersItem, TableHeaderButton, UsersListItem } from "../../../../types";
import {
  ActiveSelectOptions,
  BooleanOperators,
  StringOperators,
  tablePublicFields,
} from "../../../../utils";
import { userFilterFields } from "../../../../utils";
import { MyTooltip, TableListV2 } from "../../../components";

import { useUsersList } from "./hooks";
import { AccessLevel, UsernameContainer, AccessLevelContainer } from "./styles";

const usersColumnsSortIndex = [
  "name",
  "email",
  "active",
  "accessLevel",
  "admin",
  "organisationAdmin",
  "superAdmin",
  "createdAt",
  "createdBy",
  "updatedAt",
  "updatedBy",
];

const renderUsername = (item, activeUsers) => {
  const find = activeUsers.find((user) => user.id === item?._id);
  return (
    <MyTooltip text='Open Record'>
      <UsernameContainer>
        <div className='title clickable-row-border'>{item.name}</div>

        {!!find && (
          <MyTooltip text='User is currently online'>
            <AccessLevel color={colors.common.green} />
          </MyTooltip>
        )}
      </UsernameContainer>
    </MyTooltip>
  );
};

const renderAccesslevel = (color) => {
  return (
    <AccessLevelContainer className='title'>
      <AccessLevel color={color} />
    </AccessLevelContainer>
  );
};

export const UsersListing = () => {
  // const loading = useAppSelector((state) => state.UsersReducer.loading);
  const users = useAppSelector((state) => state.UsersReducer.data.list);
  const metaData = useAppSelector((state) => state.UsersReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration)?.users;
  const activeUsers = useAppSelector((state) => state.ApplicationReducer.activeUsers);
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("search");
  const queryPage = searchParams.get("page");

  const {
    getUsers,
    handleShowItemsPerPage,
    handleListActions,
    onClickRowSelection,
    exportRecords,
    exportAllRecords,
    allRowsSelected,
    selectedRows,
    loading,
    handleTableConfigs,
    handleTableConfigField,
    getAccessLevelColor,
    onClickUser,
    reloadUsers,
    handlePageChange,
    onSearch,
    onClickAdd,
    handleClearSearch,
    onFilterChange,
    filters,
    onClickApplyFilter,
    filterLogicQuery,
    onClickCloseFilter,
    handleSortList,
  } = useUsersList({
    querySearch,
    queryPage,
  });

  const getUsersListingColumns: ColumnsType[] = useMemo(() => {
    const usersColumns = {
      name: {
        title: "Name",
        sortSupported: true,
        key: "name",
        width: "300px",
        render: (item) => renderUsername(item, activeUsers),
      },
      email: {
        title: "Email",
        sortSupported: true,
        width: "400px",
        key: "email",
      },
      accessLevel: {
        title: "Access Level",
        width: "140px",
        key: "accessLevel",
        center: true,
        render: (item: UsersListItem) => {
          const { color, toolTip } = getAccessLevelColor(item);
          return <MyTooltip text={toolTip}>{renderAccesslevel(color)}</MyTooltip>;
        },
      },
      active: {
        title: "Active",
        sortSupported: true,
        width: "140px",
        key: "active",
        center: true,
        render: (item) => {
          return <div className='title'>{item.active ? "Yes" : "No"}</div>;
        },
      },
      admin: {
        title: "Admin",
        width: "140px",
        key: "admin",
        center: true,
        render: (item) => {
          return <div className='title'>{item.admin ? "Yes" : "No"}</div>;
        },
      },
      organisationAdmin: {
        title: "OG Admin",
        width: "140px",
        key: "organisationAdmin",
        center: true,
        render: (item) => {
          return <div className='title'>{item.organisationAdmin ? "Yes" : "No"}</div>;
        },
      },
      superAdmin: {
        title: "Unity Admin",
        sortSupported: true,
        width: "200px",
        key: "superAdmin",
        center: true,
        render: (item) => {
          return <div className='title'>{item.superAdmin ? "Yes" : "No"}</div>;
        },
      },
      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        width: "150px",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD",
              })}
            </div>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        width: "150px",
        render: (item) => {
          return <div className='title'>{item?.createdBy?.name || "Admin"}</div>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        width: "150px",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD",
              })}
            </div>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        width: "150px",
        render: (item) => {
          return <div className='title'>{item?.updatedBy?.name || "Admin"}</div>;
        },
      },
    };
    const selectedColumns = [];
    const usersFields = tableConfiguration?.fields?.length
      ? tableConfiguration?.fields
      : ["name", "email", "organisations", "superAdmin", "active", "createdAt", "updatedAt"];
    usersFields?.map((item) => {
      const userColumnItem = usersColumns?.[item];
      if (userColumnItem) {
        selectedColumns.push(userColumnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(
      usersColumnsSortIndex,
      selectedColumns,
    );
    return sortedColumns;
  }, [tableConfiguration, activeUsers, getAccessLevelColor]);

  useEffect(() => {
    if (!users.length || users.length !== 10) {
      getUsers(1);
    }
  }, []);

  const buttons: TableHeaderButton[] = [
    {
      title: strings.common.filter,
      onClick: onClickAdd,
      visible: false,
    },
    {
      title: strings.common.export,
      onClick: exportRecords,
      visible: true,
    },
    {
      title: strings.common.exportAll,
      onClick: exportAllRecords,
      visible: true,
    },
    {
      title: strings.common.add,
      onClick: onClickAdd,
      visible: true,
    },
  ];

  return (
    <TableListV2
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      handleSortList={handleSortList}
      headerButtons={buttons}
      filterLogicQuery={filterLogicQuery}
      onClickApplyFilter={onClickApplyFilter}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.users}
      handleTableConfigs={handleTableConfigs}
      selectedConfigFields={
        tableConfiguration?.fields?.length
          ? tableConfiguration?.fields
          : ["name", "email", "organisations", "superAdmin", "active", "createdAt", "updatedAt"]
      }
      rightButtonPress={exportRecords}
      onClickTableRow={onClickUser}
      refreshList={reloadUsers}
      headerTitle={strings.headerTitle.users}
      list={users}
      columns={getUsersListingColumns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      onSearch={onSearch}
      filters={userFilterFields}
      onFilterChange={onFilterChange}
      searchPlaceholder={strings.apUsers.search}
      clearSearch={handleClearSearch}
      onSearchChange={onFilterChange}
      searchValue={filters.search}
      horizontalScroll={true}
      listActionOptions={[
        {
          label: "Active",
          name: "active",
          options: ActiveSelectOptions,
        },
      ]}
    />
  );
};
