import Types from "./types";

export const fetchKnowledgebase = ({ onSuccess, onError, isEmployee, params }) => ({
  type: Types.KNOWLEDGEBASE_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
    isEmployee: isEmployee,
    params,
  },
});

export const fetchKnowledgebaseSuccess = (list: any) => ({
  type: Types.KNOWLEDGEBASE_FETCH_SUCCESS,
  payload: list,
});

export const fetchKnowledgebaseSelfSuccess = (list: any) => ({
  type: Types.KNOWLEDGEBASE_FETCH_SELF_SUCCESS,
  payload: list,
});

export const createKnowledgebase = ({ data, onSuccess, onError }) => ({
  type: Types.KNOWLEDGEBASE_ADD,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateKnowledgebase = ({ id, data, onSuccess, onError }) => ({
  type: Types.KNOWLEDGEBASE_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateKnowledgebaseFilters = ({ field, value }) => ({
  type: Types.KNOWLEDGEBASE_FILTERS_UPDATE,
  payload: {
    field: field,
    value: value,
  },
});
