import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { DepartmentsListProps } from "../../../../types/models/Departments";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useDepartmentsList } from "./hooks";

export const DepartmentsList = ({ relatedList }: DepartmentsListProps) => {
  const departments = useAppSelector((state) => state.DepartmentsReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const { list, metaData } = departments;
  const {
    listActionOptions,
    departmentsFilterFields,
    onClickDepartments,
    fetchDepartments,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSubmitDeleteRecord,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    departmentsHeaderButtons,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    DepartmentsConfigTableFields,
  } = useDepartmentsList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchDepartments({ page: 1 });
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleListActions={handleListActions}
      headerButtons={departmentsHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.departments}
      handleTableConfigs={handleTableConfigs}
      filters={departmentsFilterFields}
      selectedConfigFields={DepartmentsConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickDepartments}
      headerTitle={strings.headerTitle.departments}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apDepartments.search}
      onSearch={onSearch}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.departments_delete}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
