import React from "react";

import { Layout } from "../../../presentationals";
import { ResourcesController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<ResourcesController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APResourcesEdit = React.memo(Route);
