import styled from "styled-components";

import { colors, fs } from "../../../../../theme";

export const Container = styled.div`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: hidden;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const ViewContainer = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  font-weight: "600";
  font-size: 16px;
  line-height: 22px;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const DisplayImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
`;

export const DisplayPicture = styled.img`
  box-sizing: border-box;
  border: 1px solid ${colors.common.displayPictureBorderColor};
  height: 216px;
  width: 216px;
  border-radius: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
`;

export const DisplayPictureEdit = styled.img`
  height: 36px;
  width: 36px;
  margin-top: -40px;
  margin-bottom: 12px;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Column2 = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const NameContainer = styled.div``;

export const Name = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: ${colors.common.black};
`;

export const EmployeeID = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colors.common.black};
  opacity: 0.5;
`;

export const Company = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: ${colors.common.activeTabText};
`;

export const HorizontalLine = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 0.6px solid ${colors.common.greyBorder};
  margin-bottom: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${colors.common.modalTitleColor};
`;

export const CloseButton = styled.img``;

export const PopupInsideContainer = styled.div`
  padding: 15px;
  width: 345px;
`;

export const ImagePickerContainer = styled.div`
  border: 2px dashed ${colors.common.greyBorder};
  border-radius: 15px;
  padding: 5px;
  margin-top: 5px;
  height: 300px;
  width: 300px;
  margin: 14px;
`;

export const UploadPictureText = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: ${colors.common.modalTitleColor};
  text-align: center;
  margin-top: 14px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  left: 40%;
`;

export const ImagePickerModalContainer = styled.div`
  padding: 15;
  width: 345px;
`;

export const ImagePickerModalCloseBTNContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorText = styled.div`
  color: ${colors.common.red};
`;
