import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { RuleGroupTypeIC, formatQuery } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createDashboardWidgets,
  fetchDashboardWidgetsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateDashboardWidgets,
  uploadFile,
} from "../../../../../../redux";
import {
  CreateDashboardWidgetsPayload,
  DashboardWidgetsList,
  FetchDashboardWidgetsByIdPayload,
  UpdateDashboardWidgetsPayload,
} from "../../../../../../redux/Dashboard/Widgets/types";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { strings } from "../../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  DashboardWidgetsForm,
  TableHeaderButton,
  WorkLogItem,
  useDashboardWidgetsControllerProps,
  useDashboardWidgetsControllerReturnType,
} from "../../../../../../types";
import {
  academyCandidateLeadsFilterFields,
  collections,
  tablePublicFields,
  userFilterFields,
} from "../../../../../../utils";
import { useAcademyACHList } from "../../../AcademyACH/hooks";
import { useAcademyBatchList } from "../../../AcademyBatch/hooks/useAcademyBatchList";
import { useAcademyCandidateEnrollmentsList } from "../../../AcademyCandidateEnrollments/hooks";
import { useAcademyCandidateLeadsList } from "../../../AcademyCandidateLeads/hooks";
import { useAcademyCoursesList } from "../../../AcademyCourses/hooks";
import { useAcademyMentorFeedbackList } from "../../../AcademyMentorFeedback/hooks";
import { useAcademyStudentProfileList } from "../../../AcademyStudentProfile/hooks";
import { useAddressHistoryList } from "../../../AddressHistory/hooks";
import { useBankDetailsList } from "../../../BankDetails/hooks";
import { useEducationDetailsList } from "../../../EducationDetails/hooks";
import { useForm16List } from "../../../Form16/hooks";
import { usePIIDetailsList } from "../../../PIIDetails/hooks/usePIIDetailsList";
import { useSPSAccountContactList } from "../../../SPSAccountContact/hooks";
import { useSPSAccountsList } from "../../../SPSAccounts/hooks";
import { useSPSBGCheckList } from "../../../SPSBGCheck/hooks";
import { useSPSDealsList } from "../../../SPSDeals/hooks";
import { useSPSInsuranceList } from "../../../SPSInsurance/hooks";
import { useSPSPaperworkList } from "../../../SPSPaperwork/hooks";
import { useSPSProjectsList } from "../../../SPSProjects/hooks";
import { useSPSSalesProfileList } from "../../../SPSSalesProfile/hooks";

import { useAcademyCandidatesList } from "./../../../AcademyCandidates/hooks/useAcademyCandidatesList";
import { useAnnouncementsList } from "./../../../Announcements/hooks/useAnnouncementsList";
import { useBenefitsList } from "./../../../Benefits/hooks/useBenefitsList";
import { useEmployeesList } from "./../../../Employees/hooks/useEmployeesList";

const addFormSteps = ["Overview", "Access"];
const editFormSteps = [
  "Overview",
  "Access",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = ["title", "description", "active"];
export const useDashboardWidgetsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useDashboardWidgetsControllerProps): useDashboardWidgetsControllerReturnType => {
  const { employeesFilterFields } = useEmployeesList({});
  const { academyCandidatesFilterFields } = useAcademyCandidatesList({});
  const { academyBatchFilterFields } = useAcademyBatchList({});
  const { academyCandidateEnrollmentsFilterFields } = useAcademyCandidateEnrollmentsList({});
  const { academyCoursesFilterFields } = useAcademyCoursesList({});
  const { studentProfileFilterFields } = useAcademyStudentProfileList({});
  const { academyMentorFeedbackFilterFields } = useAcademyMentorFeedbackList({});
  const { academyACHFilterFields } = useAcademyACHList({});
  const { SPSAccountsFilterFields } = useSPSAccountsList({});
  const { academyCandidateLeadsFilterFields } = useAcademyCandidateLeadsList({});
  const { spsAccountContactFilterFields } = useSPSAccountContactList({});
  const { spsSalesProfileFilterFields } = useSPSSalesProfileList({});
  const { spsInsuranceFilterFields } = useSPSInsuranceList({});
  const { spsDealsFilterFields } = useSPSDealsList({});
  const { spsProjectFilterFields } = useSPSProjectsList({});
  const { spsBGCheckFilterFields } = useSPSBGCheckList({});
  const { spsPaperWorkFilterFields } = useSPSPaperworkList({});
  const { piiDetailsFilterFields } = usePIIDetailsList({});
  const { bankDetailsFilterFields } = useBankDetailsList({});
  const { benefitsFilterFields } = useBenefitsList({});
  const { form16FilterFields } = useForm16List({});
  const { addressHistoryFilterFields } = useAddressHistoryList({});
  const { announcementsFilterFields } = useAnnouncementsList({});
  const { educationDetailsFilterFields } = useEducationDetailsList({});

  const defaultFormData: DashboardWidgetsForm = {
    title: {
      value: "",
      error: "",
      required: true,
    },
    description: {
      value: "",
      error: "",
      required: true,
    },
    type: {
      value: "list",
      error: "",
      required: true,
    },
    dashboardTab: {
      value: "",
      error: "",
      required: true,
    },
    table: {
      value: "",
      error: "",
      required: true,
    },
    active: {
      value: false,
      error: "",
      required: false,
    },
    fields: {
      value: [],
      error: "",
      required: true,
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<DashboardWidgetsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<DashboardWidgetsList>({});

  const initialQuery: RuleGroupTypeIC = useMemo(() => {
    return { rules: [] };
  }, []);
  const [query, setQuery] = useState<RuleGroupTypeIC>(initialQuery);

  const handleQuerychange = useCallback((query: RuleGroupTypeIC) => {
    setQuery(query);
  }, []);
  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "table") {
        // Set the initial value for the 'fields' Select component
        tempFormData.fields.value = [] /* Set your initial value here */;
        const queryId = query.id;
        if (queryId) {
          const newQuery: RuleGroupTypeIC = {
            id: queryId,
            rules: [],
          };
          setQuery(newQuery);
        }
        // setQuery(initialQuery);
      }
      if (name === "type") {
        if (value === "count") {
          tempFormData = {
            ...tempFormData,
            fields: {
              ...tempFormData.fields,
              required: false,
              error: "",
            },
          };
        } else if (value === "list") {
          tempFormData = {
            ...tempFormData,
            fields: {
              ...tempFormData.fields,
              required: true,
              error: "",
            },
          };
        }
      }
      setFormData(tempFormData);
    },
    [formData, query.id],
  );
  const handleDefaultDashboardTab = useCallback(
    (id: string) => {
      const tempFormData = {
        ...formData,
        dashboardTab: {
          ...formData.dashboardTab,
          value: id,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const rehydrateFormData = useCallback(
    (dashboardWidgets: DashboardWidgetsList) => {
      try {
        const item = dashboardWidgets;
        if (item?.filter) {
          setQuery(item.filter);
        }
        const data = { ...formData };
        const tempAttachments: Attachments[] = [];
        Object.keys(formData).forEach((field) => {
          let value = item?.[field];
          if (["dashboardTab"].includes(field)) {
            const tempValue = value._id;
            value = tempValue;
          }
          if (field === "fields") {
            const tableName = item?.table || "";
            let fields = value?.map((item) =>
              tablePublicFields?.[tableName]?.find((widgetItem) => item === widgetItem.key),
            );
            fields = fields?.map((item) => {
              return { label: item.label, value: item.key };
            });
            value = fields;
          }
          if (field === "attachments") {
            for (let i = 0; i < value.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
                extension: value[i]?.extension,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
          }

          data[field].value = value;
        });
        setFormData(data);
      } catch (err) {
        console.log(err);
      }
    },
    [formData],
  );

  const fetchDashboardWidgets = useCallback(() => {
    setLoading(true);
    const payload: FetchDashboardWidgetsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchDashboardWidgetsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;
    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const { title, description, active, dashboardTab, table, type } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (!query.rules.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please Add the Condition",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const mongoQuery = formatQuery(query, "mongodb");
      const fields = formData.fields?.value?.map((item) => item.value);
      const payload: CreateDashboardWidgetsPayload = {
        data: {
          attachments: attachments,
          title: title.value,
          description: description.value,
          active: active.value,
          table: table.value,
          type: type.value,
          dashboardTab: dashboardTab.value,
          fields: type.value === "count" ? [] : fields,
          filter: query,
          filterMongoQuery: mongoQuery,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "DashboardWidgets created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createDashboardWidgets(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, query, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const { title, description, active, dashboardTab, table, type } = formData;

    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (!query.rules.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please Add the Condition",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const mongoQuery = formatQuery(query, "mongodb");
      const fields = formData.fields?.value?.map((item) => item.value);
      console.log(fields, formData.fields.value);
      setLoading(true);
      const payload: UpdateDashboardWidgetsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          title: title.value,
          description: description.value,
          active: active.value,
          table: table.value,
          type: type.value,
          dashboardTab: dashboardTab.value,
          fields: type.value === "count" ? [] : fields,
          filter: query,
          filterMongoQuery: mongoQuery,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Dashboard Widgets updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateDashboardWidgets(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, query, id]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateDashboardWidgetsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "DashboardWidgets updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateDashboardWidgets(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.title || "";
    }
    return [strings.headerTitle.dashboardWidgets, title];
  }, [data, type]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );
  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  const getTableFields = useMemo(() => {
    const {
      users,
      academyCourses,
      academyCandidates,
      academyCandidateLeads,
      academyCandidateEnrollments,
      academyBatch,
      academyMentorFeedback,
      academyStudentProfile,
      employees,
      benefits,
      piiDetails,
      form16,
      addressHistory,
      educationDetails,
      employmentHistory,
      company,
      country,
      currency,
      departments,
      platformModules,
      companyContact,
      categories,
      designations,
      checklistTemplate,
      subCategories,
      tags,
      certificationsAccreditations,
      employeeBankDetails,
      companyBankDetails,
      projectHistory,
      spsAccount,
      academyACH,
      spsAccountContact,
      spsSalesProfile,
      spsInsurance,
      spsDeals,
      spsProject,
      spsBGCheck,
      spsPaperwork,
      announcements,
    } = tablePublicFields;
    switch (formData.table.value) {
      case collections.users.name:
        return users.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.employees.name:
        return employees.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyCourses.name:
        return academyCourses.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.benefits.name:
        return benefits.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.form16.name:
        return form16.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.addressHistory.name:
        return addressHistory.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.company.name:
        return company.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.educationDetails.name:
        return educationDetails.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.employmentHistory.name:
        return employmentHistory.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.piiDetails.name:
        return piiDetails.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.country.name:
        return country.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.currency.name:
        return currency.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.departments.name:
        return departments.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.platformModules.name:
        return platformModules.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.companyContact.name:
        return companyContact.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.categories.name:
        return categories.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.designations.name:
        return designations.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.checklistTemplate.name:
        return checklistTemplate.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.subcategories.name:
        return subCategories.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.modules.name:
        return tags.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.certificationsAccreditations.name:
        return certificationsAccreditations.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.employeeBankDetails.name:
        return employeeBankDetails.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.companyBankDetails.name:
        return companyBankDetails.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyCandidates.name:
        return academyCandidates.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.projectHistory.name:
        return projectHistory.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyBatch.name:
        return academyBatch.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyCandidateEnrollments.name:
        return academyCandidateEnrollments.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyStudentProfile.name:
        return academyStudentProfile.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyMentorFeedback.name:
        return academyMentorFeedback.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsAccounts.name:
        return spsAccount.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyACH.name:
        return academyACH.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.academyCandidateLeads.name:
        return academyCandidateLeads.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsAccountContact.name:
        return spsAccountContact.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsSalesProfile.name:
        return spsSalesProfile.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsInsurance.name:
        return spsInsurance.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsDeals.name:
        return spsDeals.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsProjects.name:
        return spsProject.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsBGCheck.name:
        return spsBGCheck.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.spsPaperwork.name:
        return spsPaperwork.map((item) => {
          return { label: item.label, value: item.key };
        });
      case collections.announcements.name:
        return announcements.map((item) => {
          return { label: item.label, value: item.key };
        });
      case "ACADEMYBATCH":
        return academyBatch.map((item) => {
          return { label: item.label, value: item.key };
        });
      case "ACADEMYCANDIDATELEADS":
        return academyCandidateLeads.map((item) => {
          return { label: item.label, value: item.key };
        });
      default:
        return [];
    }
  }, [formData]);

  const getTableFilters = useMemo(() => {
    switch (formData.table.value) {
      case collections.users.name:
        return userFilterFields;
      case collections.employees.name:
        return employeesFilterFields;
      case collections.academyCourses.name:
        return academyCoursesFilterFields;
      case collections.academyCandidates.name:
        return academyCandidatesFilterFields;
      case collections.academyBatch.name:
        return academyBatchFilterFields;
      case collections.academyCandidateEnrollments.name:
        return academyCandidateEnrollmentsFilterFields;
      case collections.academyStudentProfile.name:
        return studentProfileFilterFields;
      case collections.academyMentorFeedback.name:
        return academyMentorFeedbackFilterFields;
      case collections.spsAccounts.name:
        return SPSAccountsFilterFields;
      case collections.academyACH.name:
        return academyACHFilterFields;
      case collections.academyCandidateLeads.name:
        return academyCandidateLeadsFilterFields;
      case collections.spsAccountContact.name:
        return spsAccountContactFilterFields;
      case collections.spsSalesProfile.name:
        return spsSalesProfileFilterFields;
      case collections.spsInsurance.name:
        return spsInsuranceFilterFields;
      case collections.spsDeals.name:
        return spsDealsFilterFields;
      case collections.spsProjects.name:
        return spsProjectFilterFields;
      case collections.spsBGCheck.name:
        return spsBGCheckFilterFields;
      case collections.spsPaperwork.name:
        return spsPaperWorkFilterFields;
      case collections.piiDetails.name:
        return piiDetailsFilterFields;
      case collections.employeeBankDetails.name:
        return bankDetailsFilterFields;
      case collections.companyBankDetails.name:
        return bankDetailsFilterFields;
      case collections.benefits.name:
        return benefitsFilterFields;
      case collections.form16.name:
        return form16FilterFields;
      case collections.addressHistory.name:
        return addressHistoryFilterFields;
      case collections.announcements.name:
        return announcementsFilterFields;
      case collections.educationDetails.name:
        return educationDetailsFilterFields;
      case "ACADEMYCANDIDATELEADS":
        return academyCandidateLeadsFilterFields;
      default:
        return [];
    }
  }, [
    formData.table.value,
    employeesFilterFields,
    academyCoursesFilterFields,
    academyCandidatesFilterFields,
    academyBatchFilterFields,
    academyCandidateEnrollmentsFilterFields,
    studentProfileFilterFields,
    academyMentorFeedbackFilterFields,
    SPSAccountsFilterFields,
    academyACHFilterFields,
    academyCandidateLeadsFilterFields,
    spsAccountContactFilterFields,
    spsSalesProfileFilterFields,
    spsInsuranceFilterFields,
    spsDealsFilterFields,
    spsProjectFilterFields,
    spsBGCheckFilterFields,
    spsPaperWorkFilterFields,
    piiDetailsFilterFields,
    bankDetailsFilterFields,
    benefitsFilterFields,
    form16FilterFields,
    addressHistoryFilterFields,
    announcementsFilterFields,
    educationDetailsFilterFields,
  ]);

  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (table && "dashboardSupported" in table && table.dashboardSupported) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null; // or undefined, depending on your preference
      })
      .filter(Boolean); // to remove any null or undefined entries
  }, []);

  return {
    getTableFilters,
    query,
    handleQuerychange,
    handleDefaultDashboardTab,
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchDashboardWidgets,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    getTableFields,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    TableList,
  };
};
