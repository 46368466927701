/* eslint-disable no-unused-vars */
enum Types {
  FETCH_PIIDETAILS = "FETCH_PIIDETAILS",
  FETCH_PIIDETAILS_BY_ID = "FETCH_PIIDETAILS_BY_ID",
  SUCCESS_FETCH_PIIDETAILS = "SUCCESS_FETCH_PIIDETAILS",
  ERROR_FETCH_PIIDETAILS = "ERROR_FETCH_PIIDETAILS",
  CREATE_PIIDETAILS = "CREATE_PIIDETAILS",
  UPDATE_PIIDETAILS = "UPDATE_PIIDETAILS",
  DELETE_PIIDETAILS = "DELETE_PIIDETAILS",
}

export default Types;
