import React, { useCallback, useState, MouseEvent, useMemo } from "react";

import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";

import { uploadFile, updateProfile } from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { images } from "../../../../../theme";

interface MultipleStepFormType {
  goTo: (e: MouseEvent<HTMLDivElement>) => void;
  currentStepIndex: number;
  onTextChange: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
  user: any;
  goBack: () => void;
  showChangePasswordModal: boolean;
  handleChangePasswordModal: () => void;
  showImagePickerModal: boolean;
  handleImagePickerModal: () => void;
  onFileChange: (e: React.InputHTMLAttributes<HTMLInputElement>) => void;
  onSubmit: () => void;
  formData: any;
  handleRaiseTicketNavigation: () => void;
  tempImageUrl: string;
  setTempImageUrl: (e: string) => void;
  handleImageDisplay: string;
  setToast: (e: toastType) => void;
  toast: toastType;
}

interface defaultFormDataType {
  timezone: Date;
  password: string;
  confirm_password: string;
  profileImage: {
    required: boolean;
    type: string;
    error: string;
    value: string;
  };
}

interface toastType {
  message: string;
  message_type: string;
}

export const useController = (): MultipleStepFormType => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const [showImagePickerModal, setShowImagePickerModal] = useState<boolean>(false);
  const [tempImageUrl, setTempImageUrl] = useState<string>("");
  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });

  const defaultFormData = {
    timezone: new Date(),
    password: "",
    confirm_password: "",
    profileImage: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
  };
  const [formData, setFormData] = useState<defaultFormDataType>(defaultFormData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.UserReducer);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const handleImagePickerModal = () => {
    setShowImagePickerModal(!showImagePickerModal);
  };

  const onTextChange = useCallback(
    (e: React.InputHTMLAttributes<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData],
  );

  const onSubmit = useCallback(() => {
    const payload = {
      timezone: formData.timezone,
      password: formData.password && formData.confirm_password ? sha256(formData.password) : null,
      profileImage: formData.profileImage.value,
      onSuccess: (res) => {
        // handleChangePasswordModal();
        setToast({
          message: "Updated Successfully",
          message_type: "success",
        });
      },
      onError: (error) => {
        setToast({
          message: error.message,
          message_type: "error",
        });
      },
    };
    dispatch(updateProfile(payload));
  }, [dispatch, formData, handleChangePasswordModal]);

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      const name = e.target.name;
      const payload = {
        file: file,
        type: "public",
        onSuccess: (data) => {
          console.log(data);
          setFormData({
            ...formData,
            [name]: {
              ...formData[name],
              value: data.id,
              error: ``,
            },
          });
          handleImagePickerModal();
          setTempImageUrl(URL.createObjectURL(e.target.files[0]));
        },
        onError: (err) => {
          setFormData({
            ...formData,
            [name]: {
              ...formData[name],
              error: err,
            },
          });
        },
        onProgress: (progress) => {
          setFormData({
            ...formData,
            [name]: {
              ...formData[name],
              error: `Upload in progress (${progress}%)`,
            },
          });
        },
      };
      dispatch(uploadFile(payload));
    },
    [dispatch, formData, onSubmit],
  );

  const handleRaiseTicketNavigation = useCallback(() => {
    navigate("/ep/tickets/created/add");
  }, []);

  const handleImageDisplay = useMemo(() => {
    return user?.profilePic?.location
      ? user?.profilePic?.location
      : tempImageUrl
      ? tempImageUrl
      : images.logoImg;
  }, [tempImageUrl, user]);

  return {
    currentStepIndex,
    goTo,
    user,
    goBack,
    showChangePasswordModal,
    handleChangePasswordModal,
    showImagePickerModal,
    handleImagePickerModal,
    onFileChange,
    onSubmit,
    formData,
    onTextChange,
    handleRaiseTicketNavigation,
    tempImageUrl,
    setTempImageUrl,
    handleImageDisplay,
    toast,
    setToast,
  };
};
