import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { updateKnowledgebaseFilters } from "../../redux/knowledgebase/actions";

import styles from "./KnowledgebaseFilters.styles";

const sortKeys = [
  { label: "Title", key: "title" },
  { label: "Created On", key: "createdAt", isDate: true },
  {
    label: "Description",
    key: "description",
  },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const KnowledgeBaseFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.updateKnowledgebaseFilters({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
        </h2>
      </styles.Header>
      <Input
        label={"Title"}
        type={"text"}
        name='title'
        value={props.filters.title}
        autoComplete={"off"}
        onChange={updateFilter}
        readOnly={false}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.KnowledgebaseReducer.filters,
});

const mapDispatchToProps = {
  updateKnowledgebaseFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBaseFilters);
