import Types from "./actionType";
import {
  FetchSPSDealsPayload,
  CreateSPSDeals,
  CreateSPSDealsPayload,
  FetchSPSDeals,
  FetchSPSDealsError,
  FetchSPSDealsSuccess,
  UpdateSPSDeals,
  UpdateSPSDealsPayload,
  SPSDealsListWithMetaData,
  FetchSPSDealsByIdPayload,
  FetchSPSDealsById,
  DeleteSPSDealsPayload,
  DeleteSPSDeals,
} from "./types";

export const fetchSPSDealsList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSDealsPayload): FetchSPSDeals => ({
  type: Types.FETCH_SPS_DEALS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSDealsById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSDealsByIdPayload): FetchSPSDealsById => ({
  type: Types.FETCH_SPS_DEALS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSDealsListSuccess = (data: SPSDealsListWithMetaData): FetchSPSDealsSuccess => ({
  type: Types.SUCCESS_FETCH_SPS_DEALS,
  payload: {
    data,
  },
});

export const fetchSPSDealsError = (error?: string): FetchSPSDealsError => ({
  type: Types.ERROR_FETCH_SPS_DEALS,
  payload: { error },
});

export const createSPSDeals = ({
  data,
  onSuccess,
  onError,
}: CreateSPSDealsPayload): CreateSPSDeals => ({
  type: Types.CREATE_SPS_DEALS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSDeals = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSDealsPayload): UpdateSPSDeals => ({
  type: Types.UPDATE_SPS_DEALS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSDeals = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSDealsPayload): DeleteSPSDeals => ({
  type: Types.DELETE_SPS_DEALS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
