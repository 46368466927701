import Types from "./actionType";
import { DriveActions, DriveState } from "./types";

const initialState: DriveState = {
  list: [],
  loading: false,
};

export const DriveReducer = (state = initialState, action: DriveActions) => {
  switch (action.type) {
    case Types.DRIVE_FETCH:
      return {
        ...state,
        loading: true,
      };
    case Types.DRIVE_FETCH_SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.metaData.trailingPath === action.payload.metaData.trailingPath,
      );
      const newList = [...state.list];
      if (index > -1) {
        newList[index] = action.payload;
      } else {
        newList.push(action.payload);
      }
      return {
        ...state,
        list: newList,
        loading: false,
      };
    }
    case Types.DRIVE_UPLOAD: {
      return state;
    }
    default:
      return state;
  }
};
