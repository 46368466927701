import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  CampaignsList,
  AddCampaigns,
  UpdateCampaigns,
  FindCampaigns,
  DeleteCampaigns,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchCampaignsListSuccess, fetchCampaignsError } from "./actions";
import Types from "./actionType";
import {
  CreateCampaignsuccess,
  CreateCampaigns as CreateCampaignsType,
  CampaignsSuccess,
  UpdateCampaigns as UpdateCampaignsType,
  FetchCampaigns,
  FetchCampaignsById,
  CampaignsByIdSuccess,
  DeleteCampaigns as DeleteCampaignsType,
} from "./types";

function* createWorker(action: CreateCampaignsType) {
  try {
    const options = {
      method: "POST",
      url: AddCampaigns,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCampaignsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateCampaignsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateCampaigns(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCampaignsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchCampaigns) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: CampaignsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<CampaignsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchCampaignsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchCampaignsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Campaigns");
    }
  }
}

function* fetchCampaignsById(action: FetchCampaignsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindCampaigns(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CampaignsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Campaigns");
    }
  }
}

function* deleteWorker(action: DeleteCampaignsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteCampaigns(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateCampaignsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_CAMPAIGNS, createWorker);
  yield takeLatest(Types.UPDATE_CAMPAIGNS, updateWorker);
  yield takeEvery(Types.FETCH_CAMPAIGNS, fetchAllWorker);
  yield takeEvery(Types.FETCH_CAMPAIGNS_BY_ID, fetchCampaignsById);
  yield takeEvery(Types.DELETE_CAMPAIGNS, deleteWorker);
}
