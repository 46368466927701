import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateGroups,
  updateTableConfigs,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  fetchGroupsProps,
  useGroupsListProps,
  useGroupsListReturnType,
} from "../../../../../types/models/Groups";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

import { fetchGroupsList } from "./../../../../../redux/Groups/actions";
import {
  GroupsList,
  GroupsListWithMetaData,
  FetchGroupsParams,
} from "./../../../../../redux/Groups/types";

const { add, edit } = AppRouteLinks.groups;
const defaultFilters: QueryBuilderType[] = [];

export const useGroupsList = ({ relatedList }: useGroupsListProps): useGroupsListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.GroupsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const groups = useAppSelector((state) => state.GroupsReducer.data.list);
  const { employeesOptions, usersOptions, countryOptions, departmentOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: GroupsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.groups?.fields || tableListDefaultSelectFields.groups;
      records = records?.map((item) => {
        const groupsItem = { ...item };
        if (userPersonalizedFields?.includes("createdAt")) {
          groupsItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          groupsItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          groupsItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          groupsItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete groupsItem?._id;
        return groupsItem;
      });
      const groupsFields = tablePublicFields.groups;
      const columnHeaders = groupsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "groups");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.groups?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const groupsFetchSuccess = useCallback(
    (data: GroupsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const groupsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchGroups = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchGroupsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.groups?.limit || 10;

      const params: FetchGroupsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: GroupsListWithMetaData) => groupsFetchSuccess(data, exportAll),
        onError: groupsFetchError,
      };
      dispatch(fetchGroupsList(payload));
      setSelectedRows([]);
    },
    [
      dispatch,
      groupsFetchError,
      groupsFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = groups?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, groups, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchGroups({ exportAll: true });
    },
    [fetchGroups],
  );

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchGroups({ page });
  }, [fetchGroups, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchGroups({ page: parseInt(page), search });
    },
    [fetchGroups, search],
  );

  const onClickGroups = useCallback(
    (id: string) => {
      if (permissions?.groups_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.groups_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchGroups({ page: 1, search });
  }, [fetchGroups, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchGroups({ page: 1 });
  }, [fetchGroups]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchGroups({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchGroups],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchGroups({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchGroups],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = groups?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, groups]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            groups?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, groups, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const groupsConfigLimit = tableConfiguration?.groups?.limit || 10;
      const groupsConfigs = {
        table: "groups",
        fields: fields,
        limit: groupsConfigLimit,
      };
      const payload = {
        tableConfiguration: groupsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchGroups({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.groups?.limit, dispatch, fetchGroups, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const groupsConfigFields = tableConfiguration?.groups?.fields;
      dispatch(showInAppLoader(true));
      const groupsConfigs = {
        table: "groups",
        fields: groupsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: groupsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchGroups({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchGroups],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = groups?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchGroups({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating groups",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateGroups(payload));
    },
    [selectedRows, groups, dispatch, notificationsId, fetchGroups],
  );
  // ================ Table Header Buttons ================

  const listActionOptions: ListActions[] = [
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "type",
      label: "Group Type",
      options: [
        { label: "Permissions", value: "permissions" },
        { label: "Knowledgebase", value: "knowledgebase" },
      ],
    },
  ];

  // ================ Filter Fields Configuration ================

  const groupsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const typeFilterOptions = getFilterOptions(
      [
        { label: "Permissions", value: "permissions" },
        { label: "Knowledgebase", value: "knowledgebase" },
      ],
      "Group Type",
    );

    const countryFilterOptions = getFilterOptions(countryOptions, "Country");
    const departmentFilterOptions = getFilterOptions(departmentOptions, "Departments");
    const userOptions = getFilterOptions(usersOptions, "Users");

    const fields: Field[] = [
      {
        name: "name",
        label: "Name",
        inputType: "string",
        validator,
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "type",
        label: "Group Type",
        valueEditorType: "select",
        values: typeFilterOptions,
        defaultValue: typeFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        defaultValue: countryFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "department",
        label: "Department",
        valueEditorType: "select",
        values: departmentFilterOptions,
        defaultValue: departmentFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "isueDate",
        label: "Issue Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "expiryDate",
        label: "Expiry Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions, countryOptions, departmentOptions]);

  return {
    listActionOptions,
    groupsFilterFields,
    onClickGroups,
    fetchGroups,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
  };
};
