/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SERVICE_REQUESTS = "FETCH_SERVICE_REQUESTS",
  FETCH_SERVICE_REQUESTS_BY_ID = "FETCH_SERVICE_REQUESTS_BY_ID",
  SUCCESS_FETCH_SERVICE_REQUESTS = "SUCCESS_FETCH_SERVICE_REQUESTS",
  ERROR_FETCH_SERVICE_REQUESTS = "ERROR_FETCH_SERVICE_REQUESTS",
  CREATE_SERVICE_REQUESTS = "CREATE_SERVICE_REQUESTS",
  UPDATE_SERVICE_REQUESTS = "UPDATE_SERVICE_REQUESTS",
}

export default Types;
