import React, { useEffect, useState } from "react";

import moment from "moment-timezone";
import {
  MdCancel,
  MdDynamicForm,
  MdOutlineNextPlan,
  MdCheckBoxOutlineBlank,
  MdEdit,
  MdPreview,
  MdLink,
  MdZoomIn,
  MdOutlineHistory,
  MdDelete,
  MdHistory,
} from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Header as MainHeader } from "../../presentationals/components";
import { deleteRecruits, fetchRecruits, rollbackRecruits } from "../../redux/recruits/actions";
import { useTitle } from "../../services/methods";
import { Sort } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./Recruitments.styles";

const Recruitment = (props) => {
  const navigate = useNavigate();
  const Tabs = props.Tabs;
  const [tab, setTab] = useState("");
  const [tabCount, setTabCount] = useState({});
  const Params = useParams();
  useTitle("Recruitment");
  useEffect(() => {
    props.fetchRecruits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const object = {};
    Tabs.forEach((element) => {
      const count = props.recruits.filter((item) => item.bucket === element.bucket).length;
      object[element.bucket] = count;
    });
    setTabCount(object);
  }, [Tabs, props.recruits]);

  const updateTab = (e) => {
    setTab(e.currentTarget.getAttribute("data-key"));
  };

  const findTab = ({ bucket, value }) => {
    if (bucket !== undefined) {
      return Tabs.find((item) => item.bucket === bucket);
    } else if (value) {
      return Tabs.find((item) => item.value === value);
    }
  };

  const getEmployeeInfo = (id) => {
    const employee = props.employees.find((employee) => employee._id === id);
    return employee ? employee?.label : "-";
  };

  const DeleteRow = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const name = e.currentTarget.getAttribute("data-name");
    if (window.confirm(`are you sure you want to delete ${name} record ?`)) {
      props.deleteRecruits({
        id: id,
        onSuccess: (data) => {
          props.fetchRecruits({});
        },
        onError: (err) => {
          alert("could not deleted record");
        },
      });
    }
  };

  const RollBack = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const name = e.currentTarget.getAttribute("data-name");
    if (window.confirm(`are you sure you want to rollback ${name} record ?`)) {
      props.rollbackRecruits({
        id: id,
        onSuccess: (data) => {
          props.fetchRecruits({});
        },
        onError: (err) => {
          alert("could not rollback record");
        },
      });
    }
  };

  const renderTable = () => {
    const tabbucket = findTab({ value: tab });
    const filterName = props.filters.name.split(" ").join("").toLowerCase();
    const filterMobile = props.filters.mobile;
    const filterEmail = props.filters.email;
    const filterNationalId = props.filters.nationalId;
    const filterNationalIdType = props.filters.nationalIdType;
    const filterReportingTalentAcquisition = props.filters.reportingTalentAcquisition;

    const array = props.recruits
      .filter((item) => {
        const reportingTalentAcquisition = item?.reportingTalentAcquisition || "";
        return (
          item.name.split(" ").join("").toLowerCase().includes(filterName) &&
          item.mobile.includes(filterMobile) &&
          item.email.includes(filterEmail) &&
          item.nationalId.includes(filterNationalId) &&
          String(item.nationalIdType).includes(filterNationalIdType) &&
          reportingTalentAcquisition.includes(filterReportingTalentAcquisition)
        );
      })
      .map((item, index) => {
        const assigned = item.assignments.length > 0 ? item.assignments?.slice(-1)[0] : null;
        const object = {
          _id: item._id,
          name: item.name,
          mobile: item.mobile,
          bucket: item.bucket,
          meetingInvite: assigned ? assigned.meetingInvite : "-",
          assignedTo: assigned ? assigned.assignedTo : "-",
          assignedToName: assigned ? getEmployeeInfo(assigned.assignedTo) : "-",
          dateScheduled: assigned ? assigned.dateScheduled : "",
          introductionRating: item.introductionRating,
        };
        return object;
      });

    let data = [];
    if (tabbucket && tabbucket.bucket !== undefined) {
      data = array
        .filter((item) => item.bucket === tabbucket.bucket)
        .sort((item1, item2) => {
          if (!item1.dateScheduled) {
            return -1;
          }
          return moment(item1.dateScheduled).valueOf() < moment(item2.dateScheduled).valueOf()
            ? -1
            : 1;
        });
    } else {
      data = array;
    }

    let serial = 0;
    let list = [];
    if (props.filters.sortKey) {
      data = Sort({
        data: data,
        field: props.filters.sortKey?.key,
        asc: props.filters.sortType === "asc",
        attributeKey: props.filters.sortKey?.attribute,
        isDate: props.filters.sortKey?.isDate,
      });
    }
    return data.map((item, index) => {
      serial++;
      list = [];
      props.permissions.recruitment_view &&
        list.push({
          link: `/ap/recruitment/${item._id}/view`,
          label: "View",
          icon: <MdZoomIn size={15} />,
        });
      props.permissions.recruitment_edit &&
        item.bucket !== 100 &&
        list.push({
          link: `/ap/recruitment/${item._id}/edit`,
          label: "Edit",
          icon: <MdEdit size={15} />,
        });
      props.permissions.recruitment_delete &&
        item.bucket !== 100 &&
        list.push({
          attributes: { "data-id": item._id, "data-name": item.name },
          onClick: DeleteRow,
          label: "Delete",
          icon: <MdDelete size={15} />,
        });

      list.push({
        link: `/ap/recruitment/${item._id}/history`,
        label: "History",
        icon: <MdOutlineHistory size={15} />,
      });
      props.permissions.recruitment_rollback &&
        item.bucket !== 100 &&
        list.push({
          attributes: { "data-id": item._id, "data-name": item.name },
          onClick: RollBack,
          label: "Rollback",
          icon: <MdHistory size={15} />,
        });
      return (
        <tr
          style={{
            backgroundColor: Params.id === String(item._id) ? "white" : "",
          }}
          key={index}
          data-index={index}
        >
          <td align='center'>{serial}</td>
          <td>{item.name}</td>
          <td align='center'>{item.assignedToName}</td>
          <td align='center'>
            <Tooltip
              text={item.meetingInvite}
              element={
                item.dateScheduled ? moment(item.dateScheduled).utc().format("DD-MM-YYYY") : "-"
              }
            />
          </td>
          <td align='center'>{findTab({ bucket: item?.bucket })?.label}</td>
          <td align='center'>{actionButton(item)}</td>
          {(props.permissions.recruitment_view || props.permissions.recruitment_edit) && (
            <td align='center'>
              <Dropdown element={"Action"} list={list} />
            </td>
          )}
        </tr>
      );
    });
  };

  const actionButton = (item) => {
    if (item.bucket === -1) {
      return <MdCancel size={15} />;
    } else if (!item.introductionRating && item.bucket === 1) {
      return (
        <Tooltip
          text={"Survey"}
          copy={false}
          element={
            <Link to={`/ap/recruitment/${item._id}/survey`}>
              <MdDynamicForm size={15} />
            </Link>
          }
        />
      );
    } else if (item.bucket === 4) {
      return (
        <Tooltip
          text={"Checklist"}
          copy={false}
          element={
            <Link to={`/ap/recruitment/${item._id}/checklist`}>
              <MdCheckBoxOutlineBlank size={15} />
            </Link>
          }
        />
      );
    } else if (item.bucket === 100) {
      return "-";
    } else {
      return (
        <Tooltip
          text={"Assignment"}
          copy={false}
          element={
            <Link to={`/ap/recruitment/${item._id}/assignment`}>
              <MdOutlineNextPlan size={15} />
            </Link>
          }
        />
      );
    }
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.recruitments} />
      <Header
        title={"Recruitment"}
        buttons={[
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/recruitment",
            },
            visible: true,
          },
          {
            title: "Add",
            visible: props.permissions.recruitment_add,
            onPress: {
              event: navigate,
              params: "/ap/recruitment/add",
            },
          },
        ]}
        onSync={{
          event: props.fetchRecruits,
          params: {},
        }}
      />

      <styles.TabContainer>
        {Tabs.map((item) => (
          <Button
            key={item.bucket}
            onClick={updateTab}
            inactive={tab !== item.value}
            attributes={{ "data-key": item.value }}
            label={`${item.label} (${
              item.bucket !== "" ? tabCount[item.bucket] : props.recruits.length
            })`}
            size='small'
          />
        ))}
      </styles.TabContainer>
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='center' width='5%'>
              #
            </th>
            <th align='left'>Name</th>
            <th align='center'>Assignee</th>
            <th align='center'>Date</th>
            <th>Status</th>
            <th>-</th>
            {(props.permissions.recruitment_view || props.permissions.recruitment_edit) && (
              <th>-</th>
            )}
          </tr>
        </thead>
        <tbody>{renderTable()}</tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.RecruitsReducer.loading,
  recruits: state.RecruitsReducer.recruits,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
  Tabs: state.RecruitsReducer.tabs,
  permissions: state.UserReducer.permissions,
  filters: state.RecruitsReducer.filters,
});

const mapDispatchToProps = {
  fetchRecruits,
  deleteRecruits,
  rollbackRecruits,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruitment);
