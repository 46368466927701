import Types from "./types";

export const fetchEmailTemplate = ({ onSuccess, onError }) => ({
  type: Types.EMAIL_FETCH,
  payload: { onSuccess, onError },
});

export const fetchEmailTemplateSuccess = (payload) => ({
  type: Types.EMAIL_FETCH_SUCCESS,
  payload: payload,
});

export const emailTemplateUpdate = ({ key, subject, body, useDefault, onSuccess, onError }) => ({
  type: Types.EMAIL_UPDATE,
  payload: { key, subject, body, useDefault, onSuccess, onError },
});

export const updateEmailFilters = ({ field, value }) => ({
  type: Types.EMAIL_UPDATE_FILTERS,
  payload: {
    field: field,
    value: value,
  },
});
