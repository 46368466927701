import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { JobsPostingControllerProps } from "../../../../types/models/JobsPosting";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useJobsPostingController } from "./hooks";

const Controller = ({ type }: JobsPostingControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    onDateChange,
    headerTitle,
    fetchJobsPosting,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useJobsPostingController({ type, id, attachmentsPickerRef });
  const currentJobsPosting = data;

  const {
    workLogs,
    attachments,
    title,
    summary,
    description,
    salary,
    location,
    sector,
    contactName,
    contactNumber,
    contactEmail,
    skills,
    referenceId,
    customDate,
    active,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchJobsPosting();
    }
    // Do not include fetchJobsPosting to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Title'
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
          />
          <TextInput
            label='Summary'
            type={"text"}
            width='48%'
            error={summary.error}
            inputProps={{
              name: "summary",
              value: summary.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: summary.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Description'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              rows: 12,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Custom Date'
            type={"date"}
            width='48%'
            error={customDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "customDate",
              value: customDate.value,
              required: customDate.required,
            }}
          />
          <TextInput
            label='Salary'
            type={"text"}
            width='48%'
            error={salary.error}
            inputProps={{
              name: "salary",
              value: salary.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: salary.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"type"}
            label={"Type"}
            options={getAttributValues("jobType")}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
          <TextInput
            label='Location'
            type={"text"}
            width='48%'
            error={location.error}
            inputProps={{
              name: "location",
              value: location.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: location.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Sector'
            type={"text"}
            width='100%'
            error={sector.error}
            inputProps={{
              name: "sector",
              value: sector.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: sector.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Contact Name'
            type={"text"}
            width='32%'
            error={contactName.error}
            inputProps={{
              name: "contactName",
              value: contactName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: contactName.required,
            }}
          />
          <TextInput
            label='Contact Number'
            type={"number"}
            width='32%'
            error={contactNumber.error}
            inputProps={{
              name: "contactNumber",
              value: contactNumber.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: contactNumber.required,
            }}
          />
          <TextInput
            label='Contact Email'
            type={"text"}
            width='32%'
            error={contactEmail.error}
            inputProps={{
              name: "contactEmail",
              value: contactEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: contactEmail.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Skills'
            type={"text"}
            width='48%'
            error={skills.error}
            inputProps={{
              name: "skills",
              value: skills.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: skills.required,
            }}
          />
          <TextInput
            label='Reference Id'
            type={"text"}
            width='48%'
            error={referenceId.error}
            inputProps={{
              name: "referenceId",
              value: referenceId.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: referenceId.required,
            }}
          />
        </Row>
        <Row>
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
      </>
    );
  }, [
    title.error,
    title.value,
    title.required,
    onTextChange,
    summary.error,
    summary.value,
    summary.required,
    description.error,
    description.value,
    description.required,
    customDate.error,
    customDate.value,
    customDate.required,
    onDateChange,
    salary.error,
    salary.value,
    salary.required,
    formData.type.value,
    formData.type.required,
    formData.type.error,
    location.error,
    location.value,
    location.required,
    sector.error,
    sector.value,
    sector.required,
    contactName.error,
    contactName.value,
    contactName.required,
    contactNumber.error,
    contactNumber.value,
    contactNumber.required,
    contactEmail.error,
    contactEmail.value,
    contactEmail.required,
    skills.error,
    skills.value,
    skills.required,
    referenceId.error,
    referenceId.value,
    referenceId.required,
    active.value,
    active.required,
    active.error,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentJobsPosting?.createdBy !== "string" ? currentJobsPosting?.createdBy?.name : "";
    const updatedBy =
      typeof currentJobsPosting?.updatedBy !== "string" ? currentJobsPosting?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentJobsPosting?.createdAt}
        updatedAt={currentJobsPosting?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentJobsPosting?.createdAt,
    currentJobsPosting?.createdBy,
    currentJobsPosting?.updatedAt,
    currentJobsPosting?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.jobs.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.jobs.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const JobsPostingController = memo(Controller);
