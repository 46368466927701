import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useEducationDetailsController } from "./hooks";

export const EducationDetailsController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions, employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchEducationDetails,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useEducationDetailsController({ type, id: params.id, attachmentsPickerRef });
  const currentEducationDetails = data;

  const {
    workLogs,
    attachments,
    associatedEmployee,
    country,
    state,
    instituteName,
    studentId,
    instituteAddress,
    city,
    postalCode,
    phone,
    receivedDegree,
    graduationDate,
    currentlyAttending,
    fromDate,
    toDate,
    educationType,
    degreeName,
    majorName,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchEducationDetails();
    }
    // Do not include fetchEducationDetails to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Name of the Institute'
            type={"text"}
            width='48%'
            error={instituteName.error}
            inputProps={{
              name: "instituteName",
              value: instituteName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: instituteName.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Student Id"}
            type={"text"}
            width='48%'
            error={studentId.error}
            inputProps={{
              name: "studentId",
              value: studentId.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentId.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Address of the Institute'
            type={"text"}
            width='48%'
            error={instituteAddress.error}
            inputProps={{
              name: "instituteAddress",
              value: instituteAddress.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: instituteAddress.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Postal Zone/ZIP Code"}
            type={"text"}
            width='48%'
            error={postalCode.error}
            inputProps={{
              name: "postalCode",
              type: "number",
              value: postalCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: postalCode.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Phone"}
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"currentlyAttending"}
            label={"Currently Attending"}
            options={ActiveSelectOptions}
            value={currentlyAttending.value}
            onChange={onTextChange}
            mandatory={currentlyAttending.required}
            error={currentlyAttending.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"receivedDegree"}
            label={"Did the employee graduate and receive a degree?"}
            options={ActiveSelectOptions}
            value={receivedDegree.value}
            onChange={onTextChange}
            mandatory={receivedDegree.required}
            error={receivedDegree.error}
          />
          <TextInput
            label='Graduation Date'
            type={"date"}
            width='48%'
            error={graduationDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "graduationDate",
              value: graduationDate.value,
              required: !!receivedDegree.value,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='From Date'
            type={"date"}
            width='48%'
            error={fromDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "fromDate",
              value: fromDate.value,
              required: fromDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='To Date'
            type={"date"}
            width='48%'
            error={toDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "toDate",
              minDate: fromDate.value ? new Date(fromDate.value) : undefined,
              value: toDate.value,
              required: toDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Select
          width={"100%"}
          name={"educationType"}
          label={"Education Type"}
          options={getAttributValues("educationType")}
          value={educationType.value}
          onChange={onTextChange}
          mandatory={educationType.required}
          error={educationType.error}
        />
        <Row>
          <TextInput
            label='Degree Name'
            type={"text"}
            width='48%'
            error={degreeName.error}
            inputProps={{
              name: "degreeName",
              value: degreeName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: degreeName.required,
            }}
          />
          <TextInput
            label='Major Name'
            type={"text"}
            width='48%'
            error={majorName.error}
            inputProps={{
              name: "majorName",
              value: majorName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: majorName.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    associatedEmployee.error,
    associatedEmployee.required,
    associatedEmployee.value,
    city.error,
    city.required,
    city.value,
    country.error,
    country.required,
    country.value,
    countryOptions,
    currentlyAttending.error,
    currentlyAttending.required,
    currentlyAttending.value,
    degreeName.error,
    degreeName.required,
    degreeName.value,
    educationType.error,
    educationType.required,
    educationType.value,
    employeesOptions,
    fromDate.error,
    fromDate.required,
    fromDate.value,
    graduationDate.error,
    graduationDate.value,
    instituteAddress.error,
    instituteAddress.required,
    instituteAddress.value,
    instituteName.error,
    instituteName.required,
    instituteName.value,
    majorName.error,
    majorName.required,
    majorName.value,
    onDateChange,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    postalCode.error,
    postalCode.required,
    postalCode.value,
    receivedDegree.error,
    receivedDegree.required,
    receivedDegree.value,
    state.error,
    state.required,
    state.value,
    studentId.error,
    studentId.required,
    studentId.value,
    toDate.error,
    toDate.required,
    toDate.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentEducationDetails?.createdBy !== "string"
        ? currentEducationDetails?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentEducationDetails?.updatedBy !== "string"
        ? currentEducationDetails?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentEducationDetails?.createdAt}
        updatedAt={currentEducationDetails?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentEducationDetails?.createdAt,
    currentEducationDetails?.createdBy,
    currentEducationDetails?.updatedAt,
    currentEducationDetails?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.educationDetails.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.educationDetails.name} />,
    [params.id],
  );

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);
  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderRelatedEmployees,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
