import React, { useEffect, useState } from "react";

import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import { connect } from "react-redux";

import Loader from "../../presentationals/components/Loader/Loader";
import { getFile } from "../../redux";
import Button from "../Button/button";

import { LoaderContainer } from "./Document.styles";

const unsupportedMimeTypes = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/pdf",
  "text/xml",
  "image/png",
  "image/jpeg",
];

const types = ["docx", "docx", "csv", "xlsx", "pptx", "ppt", "pdf", "xml", "png", "jpeg"];

const DocumentViewer = (props) => {
  const [documentData, setDocumentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(false);
  const [fileType, setFileType] = useState(false);
  const [mimeType, setMimeType] = useState(false);
  const [isSupportedFormat, setIsSupportedFormat] = useState(false);
  const getDocument = () => {
    setDocumentData({});
    setLoading(true);
    props.getFile({
      id: props.id,
      onSuccess: async (data) => {
        console.log(data);
        setDocumentData(data);
        let mimeType = data?.mimetype;
        const fileType = unsupportedMimeTypes.findIndex((item) => item === mimeType);
        setFileType(types[fileType]);
        mimeType = mimeType?.split("/");
        setMimeType(mimeType?.[1]);
        if (unsupportedMimeTypes.includes(data.mimetype)) {
          setIsSupportedFormat(false);
          axios({
            url: data.signedUrl,
            method: "GET",
            responseType: "blob",
          }).then((response) => {
            setLoading(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setFileUrl(url);
          });
        } else {
          setLoading(false);
          setIsSupportedFormat(true);
        }
      },
      onError: (err) => {
        alert("Document fetching failed", err);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    getDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const myHeader = () => {
    return <></>;
  };

  const onCliickDownload = () => {
    const name = `${props.name}.${fileType}`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", name);
    document.body.append(link);
    link.click();
  };
  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  } else {
    return (
      <>
        {isSupportedFormat ? (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: documentData.signedUrl, fileType: mimeType }]}
            config={{
              header: {
                overrideComponent: myHeader,
              },
            }}
          />
        ) : (
          fileUrl &&
          fileType && (
            <>
              <Button
                onClick={onCliickDownload}
                size={"normal"}
                label={"Download"}
                attributes={{
                  "data-index": fileUrl,
                }}
              />
              <FileViewer
                fileType={fileType}
                filePath={fileUrl}
                onError={(e) => console.log("Error in file viewer ::", e)}
              />
            </>
          )
        )}
      </>
    );
  }
};

const mapStateToDispatch = {
  getFile,
};

export default connect(null, mapStateToDispatch)(DocumentViewer);
