import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  CreateFilter,
  UpdateFilter,
  GetFilters,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchFilterSuccess, fetchFilterError } from "./actions";
import Types from "./actionType";
import {
  CreateFilterSuccess,
  CreateFilter as CreateFilterType,
  FilterSuccess,
  UpdateFilter as UpdateFilterType,
} from "./types";

function* createWorker(action: CreateFilterType) {
  try {
    const options = {
      method: "POST",
      url: CreateFilter,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateFilterSuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data.message);
  }
}

function* updateWorker(action: UpdateFilterType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateFilter(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateFilterSuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data.message);
  }
}

function* fetchAllWorker() {
  try {
    const options = {
      method: "GET",
      url: GetFilters,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<FilterSuccess> = yield call(axios, options);
    const { data } = response;
    if (data) {
      yield put(fetchFilterSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchFilterError(error.response?.data.message));
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_Filter_FILTER, createWorker);
  yield takeLatest(Types.UPDATE_Filter_FILTER, updateWorker);
  yield takeEvery(Types.FETCH_Filter_FILTER, fetchAllWorker);
}
