import React from "react";

import { Layout } from "../../../presentationals";
import { CertificationsAccreditationsController } from "../../../presentationals/screens";

const Route = () => {
  return (
    <Layout
      activity={<CertificationsAccreditationsController type={"edit"} />}
      admin={true}
      layout={"2"}
    />
  );
};

export const APCertificationsAccreditationsEdit = React.memo(Route);
