import Types from "./actionType";
import {
  FetchCertificationsAccreditationsPayload,
  CreateCertificationsAccreditations,
  CreateCertificationsAccreditationsPayload,
  FetchCertificationsAccreditations,
  FetchCertificationsAccreditationsError,
  FetchCertificationsAccreditationsSuccess,
  UpdateCertificationsAccreditations,
  UpdateCertificationsAccreditationsPayload,
  CertificationsAccreditationsListWithMetaData,
  FetchCertificationsAccreditationsByIdPayload,
  FetchCertificationsAccreditationsById,
  DeleteCertificationsAccreditationsPayload,
  DeleteCertificationsAccreditations,
} from "./types";

export const fetchCertificationsAccreditationsList = ({
  params,
  onSuccess,
  onError,
}: FetchCertificationsAccreditationsPayload): FetchCertificationsAccreditations => ({
  type: Types.FETCH_CERTIFICATIONS_ACCREDITATIONS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCertificationsAccreditationsById = ({
  id,
  onSuccess,
  onError,
}: FetchCertificationsAccreditationsByIdPayload): FetchCertificationsAccreditationsById => ({
  type: Types.FETCH_CERTIFICATIONS_ACCREDITATIONS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCertificationsAccreditationsListSuccess = (
  data: CertificationsAccreditationsListWithMetaData,
): FetchCertificationsAccreditationsSuccess => ({
  type: Types.SUCCESS_FETCH_CERTIFICATIONS_ACCREDITATIONS,
  payload: {
    data,
  },
});

export const fetchCertificationsAccreditationsError = (
  error?: string,
): FetchCertificationsAccreditationsError => ({
  type: Types.ERROR_FETCH_CERTIFICATIONS_ACCREDITATIONS,
  payload: { error },
});

export const createCertificationsAccreditations = ({
  data,
  onSuccess,
  onError,
}: CreateCertificationsAccreditationsPayload): CreateCertificationsAccreditations => ({
  type: Types.CREATE_CERTIFICATIONS_ACCREDITATIONS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCertificationsAccreditations = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCertificationsAccreditationsPayload): UpdateCertificationsAccreditations => ({
  type: Types.UPDATE_CERTIFICATIONS_ACCREDITATIONS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCertificationsAccreditations = ({
  id,
  onSuccess,
  onError,
}: DeleteCertificationsAccreditationsPayload): DeleteCertificationsAccreditations => ({
  type: Types.DELETE_CERTIFICATIONS_ACCREDITATIONS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
