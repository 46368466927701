import styled from "styled-components";

import { colors, fs } from "../../../theme";

interface Props {
  width?: string;
  height?: string;
  center?: boolean;
  horizontalScroll?: boolean;
  emptyList?: boolean;
  index?: number;
  background?: string;
}

export const TableWrapper = styled.div`
  display: flex;
  .table-list-select-row {
    position: absolute;
    top: 0;
  }
  .table-list-select-checkbox-container {
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    border-right: 0.3px solid black;
  }
  .table-list-select-checkbox {
    padding: 0;
    height: 25px;
    width: 25px;
    object-fit: contain;
  }
  .sticky-column {
    width: 50px;
    position: sticky;
    left: 0;
    background-color: ${colors.table.dataBackground};
    z-index: 1;
  }
`;

export const TableOuterContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  /* padding-bottom: 20px; */
  min-width: 100%;
  border: 0.3px solid ${colors.common.border};
  position: relative;
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-clip: padding-box;
    background-color: ${colors.common.primary};
  }
`;

export const TableContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled.div<Props>`
  display: flex;
  background-color: ${colors.table.headerBackground};
  height: 56px;
  width: 100%;
  border-bottom: 0.3px solid ${colors.common.border};
`;

export const TableRow = styled.div<Props>`
  height: 100%;
  min-height: 41px;
  display: flex;
  width: ${(props) => props.width};
  background-color: ${(props) => props.background};
  align-items: center;
  position: relative;
  text-align: ${(props) => (props.center ? "center" : "left")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  cursor: pointer;
  .title {
    /* padding: 0 20px; */
    font-size: ${fs.m};
    font-weight: 400;
    content: ${(props) => props.key};
    color: ${colors.common.lightTextColor};
    line-height: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    ${(props) => props.horizontalScroll && { width: props.width }}
  }
  .table-header-title {
    ${(props) => props.horizontalScroll && { width: props.width }}
  }
  .clickable-row-border {
    /* max-width: 90%; */
    min-width: auto;
    border-bottom: 0.3px solid ${colors.common.border};
    padding: 0;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .sortByTitle {
    margin-left: 25px;
  }
`;

export const Title = styled.h2<Props>`
  ${(props) => props.horizontalScroll && { width: props.width }}
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: 600;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const TableData = styled.div<Props>`
  display: flex;
  background-color: ${colors.table.dataBackground};
  width: 100%;
  flex-direction: column;
  .data-title {
    font-weight: 400;
    color: ${colors.common.lightTextColor};
    /* max-width: 90%; */
    /* -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; */
  }
`;

export const TableDataRowContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  overflow: visible;
  border-bottom: 0.6px solid rgba(0, 0, 0, 0.7);
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: linear 0.4s;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
`;

export const EmptyList = styled.div`
  height: 64px;
  border-bottom: 0.3px solid ${colors.common.border};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const DotsIcon = styled.img`
  height: 20px;
  width: 20px;
  display: flex;
  margin-right: 20px;
  cursor: pointer;
`;

export const SortDirectionIcon = styled.img`
  height: 20px;
  width: 20px;
  display: flex;
  cursor: pointer;
`;
