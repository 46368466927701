import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { clearAllFilter, fetchAllKnowledge, fetchKnowledgeArticles } from "../../../../../redux";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import {
  FetchKnowledgeArticleListParams,
  KnowledgeCurrentFilters,
} from "../../../../../redux/knowledge/types";
import { colors, strings } from "../../../../../theme";

interface RelatedList {
  type?: "categories" | "subcategories" | "tags" | "knowledgebase";
  value?: string;
}
interface Props {
  relatedList: RelatedList;
}

export const useKnowledgesConfigs = ({ relatedList }: Props) => {
  const isRelatedList = !!relatedList?.type;

  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const articles = useAppSelector((state) => state.KnowledgeReducer.list);
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const onFetchSuccess = useCallback(() => {
    console.log("AA");
    setLoading(false);
  }, []);

  const onFetchError = useCallback(() => {
    setLoading(false);
  }, []);

  const emptyListMessage = !permissions.groups_list
    ? `${relatedList.total} ${strings.emptyList.noPermissions}`
    : strings.emptyList.noRecords;

  const fetchKnowledges = useCallback(() => {
    console.log(isRelatedList, relatedList);
    setLoading(true);
    dispatch(clearAllFilter());
    const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10 };
    if (isRelatedList) {
      params[relatedList.type] = relatedList.value;
    }
    dispatch(
      fetchKnowledgeArticles({
        params,
        type: "all",
        onSuccess: onFetchSuccess,
        onError: onFetchError,
      }),
    );
  }, [dispatch, onFetchError, onFetchSuccess, isRelatedList, relatedList]);

  const onClickKnowledge = useCallback(
    (id) => {
      navigate(`/ep/knowledge/${id}/details`);
    },
    [navigate],
  );

  const onClickFilter = useCallback(() => navigate(`/ap/knowledge/filters`), [navigate]);

  const isRejected = useCallback((knowledge) => {
    if (knowledge?.approvals?.length > 0) {
      for (let i = 0; i < knowledge.approvals.length; i++) {
        if (knowledge.approvals[i].status === "rejected") {
          return true;
        }
      }
    }
    return false;
  }, []);

  const getStatus = useCallback(
    (knowledge: string) => {
      console.log(knowledge);
      if (knowledge?.status === "inreview") {
        return "In Review";
      } else if (isRejected(knowledge)) {
        return "Rejected";
      }
      return knowledge?.status;
    },
    [isRejected],
  );

  const getColor = useCallback(
    (knowledge) => {
      if (isRejected(knowledge)) {
        return colors.apKnowledge.rejectedStatus;
      }
      switch (knowledge.status) {
        case "drafted":
          return colors.apKnowledge.draftStatus;
        case "inreview":
          return colors.apKnowledge.inreviewStatus;
        case "published":
          return colors.apKnowledge.publishedStatus;
        default:
          return colors.apKnowledge.draftStatus;
      }
    },
    [isRejected],
  );

  const handleOnPageClickAll = useCallback(
    (page: number = 1, filters?: KnowledgeCurrentFilters) => {
      const params: FetchKnowledgeArticleListParams = { page, limit: 10 };
      if (search) {
        params.search = search;
      }
      // if (filters?.knowledgebase) {
      //   params.knowledgebase = filters?.knowledgebase;
      // }
      // if (filters?.categories.length) {
      //   params.categories = filters.categories.join(",");
      // }
      // if (filters?.subcategories.length) {
      //   params.subcategories = filters.subcategories.join(",");
      // }
      // if (filters?.tags.length) {
      //   params.tags = filters.tags.join(",");
      // }
      setLoading(true);
      dispatch(
        fetchKnowledgeArticles({
          params,
          type: "all",
          onError: onFetchError,
          onSuccess: onFetchSuccess,
        }),
      );
    },
    [dispatch, onFetchError, onFetchSuccess, search],
  );

  const onSearch = useCallback(
    (search) => {
      setSearch(search);
      const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, search };
      dispatch(
        fetchKnowledgeArticles({
          params,
          type: "all",
          onError: onFetchError,
          onSuccess: onFetchSuccess,
        }),
      );
    },
    [onFetchError, onFetchSuccess, dispatch],
  );

  const clearSearch = useCallback(() => {
    setSearch("");
    handleOnPageClickAll();
  }, [handleOnPageClickAll]);

  return {
    onClickKnowledge,
    fetchKnowledges,
    onClickFilter,
    emptyListMessage,
    isRejected,
    getStatus,
    getColor,
    handleOnPageClickAll,
    loading,
    onSearch,
    setSearch,
    clearSearch,
  };
};
