/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ADDRESS_HISTORY = "FETCH_ADDRESS_HISTORY",
  FETCH_ADDRESS_HISTORY_BY_ID = "FETCH_ADDRESS_HISTORY_BY_ID",
  SUCCESS_FETCH_ADDRESS_HISTORY = "SUCCESS_FETCH_ADDRESS_HISTORY",
  ERROR_FETCH_ADDRESS_HISTORY = "ERROR_FETCH_ADDRESS_HISTORY",
  CREATE_ADDRESS_HISTORY = "CREATE_ADDRESS_HISTORY",
  UPDATE_ADDRESS_HISTORY = "UPDATE_ADDRESS_HISTORY",
  DELETE_ADDRESS_HISTORY = "DELETE_ADDRESS_HISTORY",
}

export default Types;
