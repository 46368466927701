import React, { useEffect, useMemo, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { SPSAccountContactListProps } from "../../../../types/models/SPSAccountContact";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useSPSAccountContactList } from "./hooks";

export const SPSAccountContactList = memo(({ relatedList }: SPSAccountContactListProps) => {
  const SPSAccountContact = useAppSelector((state) => state.SPSAccountContactReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = SPSAccountContact;
  const {
    fetchSPSAccountContact,
    loading,
    onSubmitDeleteRecord,
    handlePageChange,
    onClickSPSAccountContact,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    columns,
    spsAccountContactTableConfigFields,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    spsAccountContactHeaderButtons,
    spsAccountContactFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useSPSAccountContactList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchSPSAccountContact({});
    // Do not include fetchSPSAccountContact to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={spsAccountContactHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      selectedConfigFields={spsAccountContactTableConfigFields}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.spsAccountContact}
      handleTableConfigs={handleTableConfigs}
      filters={spsAccountContactFilterFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.spsAccountContact_delete}
      onClickTableRow={onClickSPSAccountContact}
      headerTitle={strings.headerTitle.spsAccountContact}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apSPSAccountContact.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
