import React, { useCallback, useEffect, useState } from "react";

import { fetchAttributesList } from "../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { MomentDate } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType, FiltersItem, metaDataTypes } from "../../../../types";
import {
  Button,
  Header,
  Popup,
  Select,
  Table,
  TableHeader,
  TableListSkeleton,
  TextInput,
} from "../../../components";
import Pagination from "../../../components/Pagination";
import { FilterButton } from "../../atoms/FilterButton";

import {
  FilterContainer,
  FilterHeader,
  FilterItem,
  FilterItemsContainer,
  FilterModalContainer,
  FilterRightHeaderContainer,
  MainContainer,
} from "./styles";

interface Props {
  isRelatedList?: boolean;
  loading: boolean;
  rightButton?: string;
  headerTitle?: string;
  rightButtonPress?: React.MouseEventHandler;
  additionalButtonPress?: React.MouseEventHandler;
  filterButtonPress?: React.MouseEventHandler;
  list: object[];
  columns: ColumnsType[];
  metaData?: metaDataTypes;
  onPageChange?: (page: string) => void;
  onClickTableRow?: (id: string) => void;
  horizontalScroll?: boolean;
  emptyListMessage?: string;
  filterButton?: string;
  onSearch?: () => void;
  searchPlaceholder?: string;
  clearSearch?: () => void;
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue?: string;
  filters?: FiltersItem[];
  searchInputName?: string;
  additionalButton?: string;
}

export const TableList = ({
  isRelatedList,
  additionalButtonPress,
  loading,
  rightButton,
  headerTitle,
  rightButtonPress,
  filterButtonPress,
  list,
  columns,
  metaData,
  onPageChange,
  onClickTableRow,
  horizontalScroll,
  emptyListMessage,
  filterButton,
  onSearch,
  searchPlaceholder,
  clearSearch,
  onSearchChange,
  searchValue,
  filters,
  onFilterChange,
  searchInputName,
  additionalButton,
}: Props) => {
  const [filter, setFilter] = useState<boolean>(false);
  const handleFilter = useCallback(() => {
    setFilter((filter) => !filter);
  }, []);
  return (
    <MainContainer>
      {!isRelatedList && (
        <>
          {!!headerTitle && <Header title={headerTitle} />}
          <TableHeader
            rightButton={rightButton}
            additionalButton={additionalButton}
            additionalButtonPress={additionalButtonPress}
            rightButtonPress={rightButtonPress}
            filterButton={filterButton}
            filterButtonPress={handleFilter}
            onSearchPress={onSearch}
            onSearchChange={onSearchChange}
            searchPlaceholderText={searchPlaceholder}
            clearSearch={clearSearch}
            searchValue={searchValue}
            searchInputName={searchInputName}
          />
        </>
      )}
      {loading ? (
        <TableListSkeleton />
      ) : (
        <>
          <Table
            data={list}
            columns={columns}
            page={metaData?.currentPage || 1}
            pageSize={metaData?.limit || 10}
            onClickTableRow={onClickTableRow}
            emptyListMessage={emptyListMessage}
            horizontalScroll={horizontalScroll}
          />
          {!!metaData && (
            <Pagination
              currentPage={metaData.currentPage}
              totalCount={metaData.totalItems}
              pageSize={metaData.limit}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}
      <Popup isOpen={filter} closeModal={handleFilter}>
        <FilterContainer>
          <FilterHeader>
            <FilterButton className='table-filter-modal-button' title={strings.common.filter} />
            <FilterRightHeaderContainer>
              <Button className='table-filter-apply-button' title={strings.filters.apply} />
              <Button
                className='table-filter-close-button'
                title={strings.filters.close}
                onClick={handleFilter}
              />
            </FilterRightHeaderContainer>
          </FilterHeader>
          <FilterItemsContainer>
            {filters?.map((item, index) => {
              const { type, name, label, value } = item;
              return (
                <FilterItem key={index}>
                  {item.type === "text" && (
                    <TextInput
                      label={label}
                      type={"text"}
                      width='100%'
                      error={""}
                      inputProps={{
                        name: name,
                        value: String(value),
                        autoComplete: "off",
                        onChange: onFilterChange,
                        // required: name.required,
                      }}
                      textAreaProps={{}}
                    />
                  )}
                  {item.type === "select" && item.options && (
                    <Select
                      width={"100%"}
                      name={name}
                      label={label}
                      options={item.options}
                      value={value}
                      onChange={onFilterChange}
                      // mandatory={active.required}
                      error={""}
                    />
                  )}
                </FilterItem>
              );
            })}
          </FilterItemsContainer>
        </FilterContainer>
      </Popup>
      {/* {filter && (
        <FilterModalContainer onClick={handleFilter}>
          <FilterContainer>
            <></>
          </FilterContainer>
        </FilterModalContainer>
      )} */}
    </MainContainer>
  );
};
