import Types from "./actionTypes";
import {
  ClearAllFilter,
  FetchKnowledgeArticles,
  FetchKnowledgeArticlesPayload,
  FetchKnowledgeArticlesSuccess,
  FetchKnowledgeArticlesSuccessPayload,
  SetCategoriesFilter,
  SetCategoriesFilterPayload,
  SetKnowledgebaseFilter,
  SetKnowledgebaseFilterPayload,
  SetSubCategoriesFilterPayload,
  SetSubcategoriesFilter,
  SetTagsFilter,
  SetTagsFilterPayload,
  KnowledgeDashboardData,
  FetchKnowledgeDashboardSuccess,
  FetchKnowledgeDetailsPayload,
  FetchKnowledgeDetails,
} from "./types";

export const createKnowledge = ({ data, onSuccess, onError }) => ({
  type: Types.KNOWLEDGE_CREATE,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateKnowledge = ({ data, onSuccess, onError, id }) => ({
  type: Types.KNOWLEDGE_UPDATE,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
    id: id,
  },
});

export const reviseKnowledge = ({ id, onSuccess, onError }) => ({
  type: Types.REVISE_KNOWLEDGE,
  payload: {
    id: id,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchAllKnowledge = ({ onSuccess, onError }) => ({
  type: Types.KNOWLEDGE_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchKnowledgeSuccess = (list, allKnowledgebases, allCategories, allTags) => ({
  type: Types.KNOWLEDGE_FETCH_SUCCESS,
  payload: { list, allKnowledgebases, allCategories, allTags },
});

export const updateKnowledgeFilter = ({ field, value }) => ({
  type: Types.UPDATE_KNOWLEDGE_FILTER,
  payload: {
    field: field,
    value: value,
  },
});

export const updateApproval = ({ data, onSuccess, onError }) => ({
  type: Types.UPDATE_KNOWLEDGE_APPROVAL,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateReview = ({ id, onSuccess, onError }) => ({
  type: Types.UPDATE_KNOWLEDGE_REVIEW,
  payload: {
    id: id,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const retireKnowledge = ({ id, onSuccess, onError }) => ({
  type: Types.RETIRE_KNOWLEDGE,
  payload: {
    id: id,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchKnowledgeArticles = ({
  params,
  onSuccess,
  onError,
  type,
}: FetchKnowledgeArticlesPayload): FetchKnowledgeArticles => ({
  type: Types.FETCH_KNOWLEDGE_ARTICLES,
  payload: {
    type,
    params,
    onSuccess,
    onError,
  },
});

export const fetchKnowledgeArticlesSuccess = ({
  articles,
  appliedFilters,
}: FetchKnowledgeArticlesSuccessPayload): FetchKnowledgeArticlesSuccess => ({
  type: Types.FETCH_KNOWLEDGE_ARTICLES_SUCCESS,
  payload: { articles, appliedFilters },
});

export const setKnowledgeFilter = ({
  knowledgebase,
}: SetKnowledgebaseFilterPayload): SetKnowledgebaseFilter => ({
  type: Types.SET_KNOWLEDGEBASE_FILTER,
  payload: { knowledgebase },
});

export const setCategoriesFilter = ({
  categories,
}: SetCategoriesFilterPayload): SetCategoriesFilter => ({
  type: Types.SET_CATEGORIES_FILTER,
  payload: { categories },
});

export const setSubcategoriesFilter = ({
  subcategories,
}: SetSubCategoriesFilterPayload): SetSubcategoriesFilter => ({
  type: Types.SET_SUBCATEGORIES_FILTER,
  payload: { subcategories },
});

export const setTagsFilter = ({ tags }: SetTagsFilterPayload): SetTagsFilter => ({
  type: Types.SET_TAGS_FILTER,
  payload: { tags },
});

export const clearAllFilter = (): ClearAllFilter => ({
  type: Types.CLEAR_ALL_FILTER,
});

export const fetchKnowledgeDashboard = ({ onSuccess, onError }) => ({
  type: Types.KNOWLEDGE_DASHBOARD_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchKnowledgeDashboardSuccess = ({ data }): FetchKnowledgeDashboardSuccess => ({
  type: Types.KNOWLEDGE_DASHBOARD_FETCH_SUCCESS,
  payload: { data },
});

export const fetchKnowledgeDetails = ({
  id,
  onSuccess,
  onError,
}: FetchKnowledgeDetailsPayload): FetchKnowledgeDetails => ({
  type: Types.FETCH_KNOWLEDGE_DETAILS,
  payload: {
    id,
    onSuccess: onSuccess,
    onError: onError,
  },
});
