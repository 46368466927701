/* eslint-disable no-unused-vars */
enum Types {
  FETCH_NOTIFICATIONS_DEFINITIONS = "FETCH_NOTIFICATIONS_DEFINITIONS",
  SUCCESS_FETCH_NOTIFICATIONS_DEFINITIONS = "SUCCESS_FETCH_NOTIFICATIONS_DEFINITIONS",
  SET_META_DATA_NOTIFICATIONS_DEFINITIONS = "SET_META_DATA_NOTIFICATIONS_DEFINITIONS",
  ERROR_FETCH_NOTIFICATIONS_DEFINITIONS = "ERROR_FETCH_NOTIFICATIONS_DEFINITIONS",
  FETCH_NOTIFICATIONS_DEFINITIONS_BY_ID = "FETCH_NOTIFICATIONS_DEFINITIONS_BY_ID",
  CREATE_NOTIFICATIONS_DEFINITIONS = "CREATE_NOTIFICATIONS_DEFINITIONS",
  UPDATE_NOTIFICATIONS_DEFINITIONS = "UPDATE_NOTIFICATIONS_DEFINITIONS",
  UPDATE_FITERS_NOTIFICATIONS_DEFINITIONS = "UPDATE_FITERS_NOTIFICATIONS_DEFINITIONS",
  NOTIFICATIONS_DEFINITIONS_FETCH_SUCCESS = "NOTIFICATIONS_DEFINITIONS_FETCH_SUCCESS",
  DELETE_NOTIFICATIONS_DEFINITIONS = "DELETE_NOTIFICATIONS_DEFINITIONS",
}

export default Types;
