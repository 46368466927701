/* eslint-disable no-unused-vars */
enum Types {
  FETCH_TAGS = "FETCH_TAGS",
  FETCH_TAGS_BY_ID = "FETCH_TAGS_BY_ID",
  SUCCESS_FETCH_TAGS = "SUCCESS_FETCH_TAGS",
  ERROR_FETCH_TAGS = "ERROR_FETCH_TAGS",
  CREATE_TAGS = "CREATE_TAGS",
  UPDATE_TAGS = "UPDATE_TAGS",
  DELETE_TAGS = "DELETE_TAGS",
}

export default Types;
