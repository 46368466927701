import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyMentorFeedbackController } from "../../../presentationals/screens";

export const APAcademyMentorFeedbackAdd = () => {
  return (
    <Layout activity={<AcademyMentorFeedbackController type={"add"} />} admin={true} layout={"2"} />
  );
};
