import React, { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { images, strings } from "../../../theme";
import { Button } from "../atoms/Button";
import { FilterButton } from "../atoms/FilterButton";
import Search from "../Search";

import {
  Container,
  LeftContainer,
  RightContainer,
  SearchContainer,
  AdditionalButton,
  ExtraSaveButton,
  AttachmentsIcon,
  AttachmentsIconContainer,
  AttachmentsBadge,
} from "./styles";

interface ExtraButton {
  title: string;
  onPress: () => void;
}

interface ButtonType {
  backButtonTitle?: string;
  backButtonPress?: () => void;
  rightButtonPress?: React.MouseEventHandler;
  rightButton?: string;
  searchPlaceholderText?: string;
  rightButtonBorder?: string;
  rightButtonDisabled?: boolean;
  additionalButton?: string;
  additionalButtonPress?: React.MouseEventHandler;
  filterButton?: string;
  filterButtonPress?: React.MouseEventHandler;
  filterButtonBorder?: string;
  filterButtonDisabled?: boolean;
  rightButtonWidth?: string;
  extraSaveButton?: string;
  extraSaveButtonPress?: React.MouseEventHandler;
  extraSaveButtonWidth?: string;
  onAttachmentsClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  onAttachmentsIconLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  attachmentsBadge?: number;
  rightButtonClass?: string;
  // Search Props
  searchValue?: string;
  onSearchPress?: () => void;
  clearSearch?: () => void;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInputName?: string;
  extraButtons?: ExtraButton[];
}

export const TableHeader = ({
  backButtonTitle,
  extraButtons,
  rightButton,
  backButtonPress,
  rightButtonPress,
  searchPlaceholderText = "",
  rightButtonBorder = "0px",
  rightButtonDisabled,
  additionalButton,
  additionalButtonPress,
  filterButton,
  filterButtonPress,
  filterButtonBorder = "0px",
  filterButtonDisabled,
  rightButtonWidth = "69px",
  extraSaveButtonWidth = "70px",
  extraSaveButton,
  extraSaveButtonPress,
  onAttachmentsClick,
  onAttachmentsIconLoad,
  clearSearch,
  attachmentsBadge,
  rightButtonClass,
  // Search Props
  searchValue,
  onSearchPress,
  onSearchChange,
  searchInputName = "search",
}: ButtonType) => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    backButtonPress ? backButtonPress() : navigate(-1);
  }, [backButtonPress, navigate]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onSearchPress) {
        onSearchPress();
      }
    },
    [onSearchPress],
  );

  return (
    <Container>
      <LeftContainer>
        {backButtonTitle && (
          <Button
            className='table-header-back-button'
            leftArrow
            title={backButtonTitle}
            onClick={goBack}
          />
        )}
      </LeftContainer>
      <RightContainer>
        {onSearchPress && (
          <SearchContainer>
            <Search
              clearSearch={clearSearch}
              inputProps={{
                placeholder: searchPlaceholderText,
                onChange: onSearchChange,
                onKeyDown: onKeyDown,
                value: searchValue,
                name: searchInputName,
              }}
            />
            <Button title={strings.common.search} onClick={onSearchPress} />
          </SearchContainer>
        )}
        {onAttachmentsClick && (
          <AttachmentsIconContainer>
            <AttachmentsIcon
              onLoad={onAttachmentsIconLoad}
              onClick={onAttachmentsClick}
              src={images.attachmentsIcon}
            />
            {!!attachmentsBadge && <AttachmentsBadge>{attachmentsBadge}</AttachmentsBadge>}
          </AttachmentsIconContainer>
        )}
        {additionalButton && (
          <AdditionalButton>
            <Button title={additionalButton} onClick={additionalButtonPress} width='150%' />
          </AdditionalButton>
        )}
        {extraButtons?.length && (
          <>
            {extraButtons.map((item, index) => {
              <AdditionalButton key={index}>
                <Button title={item.title} onClick={item.onPress} width='150%' />
              </AdditionalButton>;
            })}
          </>
        )}
        {filterButton && (
          <FilterButton
            disabled={filterButtonDisabled}
            border={filterButtonBorder}
            title={filterButton}
            onClick={filterButtonPress}
          />
        )}
        {rightButton && (
          <Button
            className={rightButtonClass}
            disabled={rightButtonDisabled}
            border={rightButtonBorder}
            title={rightButton}
            onClick={rightButtonPress}
            width={rightButtonWidth}
          />
        )}
        {extraSaveButton && (
          <ExtraSaveButton>
            <Button
              disabled={rightButtonDisabled}
              border={rightButtonBorder}
              title={extraSaveButton}
              onClick={extraSaveButtonPress}
              width={extraSaveButtonWidth}
            />
          </ExtraSaveButton>
        )}
      </RightContainer>
    </Container>
  );
};
