import React, { useCallback, useMemo, useRef, useState } from "react";

import { UseTableV2Props, UseTableV2ReturnType } from "../../../../../types";
import { defaultTableListColumns } from "../../../../../utils";

export const useTableV3 = ({
  columns,
  handleSortList,
  data,
  onClickTableRow,
  advancedList,
  onClickRowSelection,
  onSearch,
  searchValue,
  dashboardWidget,
}: UseTableV2Props): UseTableV2ReturnType => {
  const parentRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const componentRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const defaultSearchValues = useMemo(() => {
    const values = {};
    columns.forEach((item) => {
      if (item.searchSupported && item.key) {
        values[item.key] = "";
      }
    });
    return values;
  }, [columns]);

  const [hoverRow, setHoverRow] = useState<number>(-1);
  const [headHovered, setHeadHovered] = useState<boolean>(false);
  const [searchbarVisible, setSearchbarVisible] = useState<boolean>(searchValue ? true : false);
  const [sortColumn, setSortColumn] = useState<number>(-1);
  const [searchValues, setSearchalues] = useState<object>(defaultSearchValues);

  const handleSearchBar = useCallback(() => {
    setSearchbarVisible((value) => !value);
  }, []);

  const tableColumns = useMemo(() => {
    return columns;
  }, [columns]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const currentSearchValues = { ...searchValues };
      currentSearchValues[name] = value;
      // setSearchalues(currentSearchValues);
    },
    [searchValues],
  );

  const clearSearch = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-key");
      const currentSearchValues = { ...searchValues };
      if (name && currentSearchValues[name]) {
        currentSearchValues[name] = "";
        setSearchalues(currentSearchValues);
      }
    },
    [searchValues],
  );

  const onSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && onSearch) {
        onSearch();
      }
    },
    [onSearch],
  );

  const handleSortPopup = useCallback(
    (e: React.MouseEvent) => {
      const columnIndex = e.currentTarget.getAttribute("data-index");
      console.log("Clicked ::", columnIndex);
      if (!isNaN(Number(columnIndex))) {
        if (Number(columnIndex) === sortColumn) {
          setSortColumn(-1);
        } else {
          setSortColumn(Number(columnIndex));
        }
      }
    },
    [sortColumn],
  );

  const closeSortPopup = useCallback(() => {
    setSortColumn(-1);
  }, []);

  const handleSortItem = useCallback(
    (e: React.MouseEvent) => {
      const sortType = e.currentTarget.getAttribute("data-sort");
      if (handleSortList && sortType && sortColumn >= 0) {
        const type = sortType === "asc" ? "asc" : "desc";
        const key = columns[sortColumn].key || "";
        handleSortList(key, type);
      }
      setSortColumn(-1);
    },
    [handleSortList, sortColumn, columns],
  );

  const onClickRow = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const columnIndex = e.currentTarget.getAttribute("data-column");
      const index = e.currentTarget.getAttribute("data-index") || -1;
      if (Number(index) >= 0 && onClickTableRow && Number(columnIndex) === 0) {
        const id = data[Number(index)]?._id;
        onClickTableRow(id);
      }
    },
    [data, onClickTableRow],
  );

  const handleHoverEnterHeader = useCallback((e: React.MouseEvent) => {
    const id = e.target?.id;
    if (id === "tableHeaderHeadRowItem") {
      setHeadHovered(true);
    } else if (!isNaN(Number(id))) {
      setHoverRow(Number(id));
    }
  }, []);

  const handleHoverLeaveHeader = useCallback((e: React.MouseEvent) => {
    const id = e.target?.id;
    if (id === "tableHeaderHeadRowItem") {
      setHeadHovered(false);
    } else {
      setHoverRow(-1);
    }
  }, []);

  const hanldeSelectRow = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClickRowSelection && onClickRowSelection(e);
    },
    [onClickRowSelection],
  );
  const closePopups = useCallback(() => {
    // setSearchbarVisible(false);
    closeSortPopup();
  }, [closeSortPopup]);
  return {
    parentRef,
    componentRef,
    tableColumns,
    hoverRow,
    sortColumn,
    handleSortPopup,
    closeSortPopup,
    handleSortItem,
    onClickRow,
    handleHoverEnterHeader,
    handleHoverLeaveHeader,
    headHovered,
    hanldeSelectRow,
    searchbarVisible,
    handleSearchBar,
    closePopups,
    searchValues,
    handleSearch,
    clearSearch,
    onSearchKeyDown,
  };
};
