/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ANNOUNCEMENTS = "FETCH_ANNOUNCEMENTS",
  FETCH_ANNOUNCEMENTS_BY_ID = "FETCH_ANNOUNCEMENTS_BY_ID",
  SUCCESS_FETCH_ANNOUNCEMENTS = "SUCCESS_FETCH_ANNOUNCEMENTS",
  ERROR_FETCH_ANNOUNCEMENTS = "ERROR_FETCH_ANNOUNCEMENTS",
  CREATE_ANNOUNCEMENTS = "CREATE_ANNOUNCEMENTS",
  UPDATE_ANNOUNCEMENTS = "UPDATE_ANNOUNCEMENTS",
  DELETE_ANNOUNCEMENTS = "DELETE_ANNOUNCEMENTS",
}

export default Types;
