import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { SPSInsuranceControllerProps } from "../../../../types/models/SPSInsurance";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSProjectsList } from "../SPSProjects";

import { useSPSInsuranceController } from "./hooks";

const Controller = ({ type }: SPSInsuranceControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsProjectOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    onDateChange,
    headerTitle,
    fetchSPSInsurance,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSInsuranceController({ type, id, attachmentsPickerRef });
  const currentSPSInsurance = data;

  const {
    workLogs,
    attachments,
    associatedProject,
    insuranceCompany,
    accountTakenfor,
    startDate,
    renewDate,
    amount,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSInsurance();
    }
    // Do not include fetchSPSInsurance to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Insurance Company'
            type={"text"}
            width='48%'
            error={insuranceCompany.error}
            inputProps={{
              name: "insuranceCompany",
              value: insuranceCompany.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: insuranceCompany.required,
            }}
          />
          <Select
            width={"48%"}
            name={"associatedProject"}
            label={"Associated Project"}
            options={spsProjectOptions}
            value={associatedProject.value}
            onChange={onTextChange}
            mandatory={associatedProject.required}
            error={associatedProject.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Amount'
            type={"text"}
            width='48%'
            error={amount.error}
            inputProps={{
              name: "amount",
              value: amount.value,
              type: "number",
              autoComplete: "off",
              onChange: onTextChange,
              required: amount.required,
            }}
          />
          <TextInput
            label='Account Taken For'
            type={"text"}
            width='48%'
            error={accountTakenfor.error}
            inputProps={{
              name: "accountTakenfor",
              value: accountTakenfor.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: accountTakenfor.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Start Date'
            type={"date"}
            width='48%'
            error={startDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "startDate",
              value: startDate.value,
              required: startDate.required,
            }}
          />
          <TextInput
            label='Renew Date'
            type={"date"}
            width='48%'
            error={renewDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "renewDate",
              minDate: startDate.value ? new Date(startDate.value) : undefined,
              value: renewDate.value,
              required: renewDate.required,
            }}
          />
        </Row>
        <Row>
          {/* <TextInput
            label='Associated Account'
            type={"text"}
            width='48%'
            error={associatedAccount.error}
            inputProps={{
              name: "associatedAccount",
              value: associatedAccount.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: associatedAccount.required,
            }}
          /> */}
        </Row>
      </React.Fragment>
    );
  }, [
    accountTakenfor.error,
    accountTakenfor.required,
    accountTakenfor.value,
    amount.error,
    amount.required,
    amount.value,
    associatedProject.error,
    associatedProject.required,
    associatedProject.value,
    insuranceCompany.error,
    insuranceCompany.required,
    insuranceCompany.value,
    onDateChange,
    onTextChange,
    renewDate.error,
    renewDate.required,
    renewDate.value,
    spsProjectOptions,
    startDate.error,
    startDate.required,
    startDate.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSInsurance?.createdBy !== "string"
        ? currentSPSInsurance?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentSPSInsurance?.updatedBy !== "string"
        ? currentSPSInsurance?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentSPSInsurance?.createdAt}
        updatedAt={currentSPSInsurance?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSInsurance?.createdAt,
    currentSPSInsurance?.createdBy,
    currentSPSInsurance?.updatedAt,
    currentSPSInsurance?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.spsInsurance.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsInsurance.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderSPSProject = useMemo(
    () => (
      <SPSProjectsList
        relatedList={{
          type: "_id",
          value:
            typeof currentSPSInsurance.associatedProject !== "string"
              ? currentSPSInsurance.associatedProject?._id
              : currentSPSInsurance.associatedProject,
        }}
      />
    ),
    [currentSPSInsurance.associatedProject],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderSPSProject;
      default:
        return null;
    }
  }, [
    renderSPSProject,
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSInsuranceController = memo(Controller);
