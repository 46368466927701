import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createBankDetails,
  deleteBankDetails,
  fetchBankDetailsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateBankDetails,
  uploadFile,
} from "../../../../../redux";
import {
  BankDetailsList,
  CreateBankDetailsPayload,
  FetchBankDetailsByIdPayload,
  UpdateBankDetailsPayload,
} from "../../../../../redux/BankDetails/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  BankDetailsForm,
  ChecklistTemplateChecklistItem,
  SelectOptions,
  TableHeaderButton,
  WorkLogItem,
  useBankDetailsControllerProps,
  useBankDetailsControllerReturnType,
} from "../../../../../types";
import { DashboardAdministrationFormDataType } from "../../../../../types";
import { collections, emailRegex, phoneRegex } from "../../../../../utils";

// ================ Contstants ================

const addFormSteps = ["Overview"];
const editFormSteps = ["Overview", "Checklist", "Activity Logs", "Worklogs", "System Data"];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "voidCheque",
  "active",
  "bankCountry",
  "bankName",
  "accountType",
  "accountNumber",
  "bankCurrency",
  "accountName",
  "routingNumber",
  "defaultAccount",
  "bankType",
  "routingType",
  "associatedRecord",
];

export const useBankDetailsController = ({
  type,
  id,
  attachmentsPickerRef,
  recordType,
}: useBankDetailsControllerProps): useBankDetailsControllerReturnType => {
  const defaultFormData: BankDetailsForm = {
    associatedEmployee: {
      value: null,
      required: false,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    bankCountry: {
      value: "",
      required: true,
      error: "",
    },
    associatedCompany: {
      value: null,
      required: false,
      error: "",
    },
    bankName: {
      value: "",
      required: true,
      error: "",
    },
    accountType: {
      value: "",
      required: true,
      error: "",
    },
    routingNumber: {
      value: "",
      required: true,
      error: "",
    },
    accountNumber: {
      value: null,
      required: true,
      error: "",
    },
    bankCurrency: {
      value: "",
      required: true,
      error: "",
    },
    accountName: {
      value: "",
      required: true,
      error: "",
    },
    associatedRecord: {
      value: recordType === "associatedEmployee" ? "employee" : "company",
      required: true,
      error: "",
    },
    swiftBICCode: {
      value: "",
      required: false,
      error: "",
    },
    defaultAccount: {
      value: false,
      required: false,
      error: "",
    },
    routingType: {
      value: "",
      required: true,
      error: "",
    },
    bankType: {
      value: "",
      required: true,
      error: "",
    },
    voidCheque: {
      value: false,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<BankDetailsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<BankDetailsList>({});

  // ================Checklist State Values ================
  const [checklist, setChecklist] = useState<ChecklistTemplateChecklistItem[]>([]);

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    const { employees, company } = collections;
    const employeesList = [employees.label];
    const companyList = [company.label];
    if (recordType === "associatedCompany") return companyList;
    return employeesList;
  }, [recordType]);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          if (formData.associatedRecord.value === "company" && formData.associatedCompany.error) {
            count += 1;
          }
          if (formData.associatedRecord.value === "employee" && formData.associatedEmployee.error) {
            count += 1;
          }
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "associatedRecord" && (value === "employee" || value === "company")) {
        tempFormData = {
          ...tempFormData,
          associatedCompany: {
            ...tempFormData.associatedCompany,
            value: null,
            error: "",
          },
          associatedEmployee: {
            ...tempFormData.associatedEmployee,
            value: null,
            error: "",
          },
        };
        tempFormData = {
          ...tempFormData,
          [name]: {
            ...tempFormData[name],
            value,
            error: "",
          },
        };
      }
      console.log("AA ::", tempFormData);
      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (item: BankDetailsList) => {
      try {
        if (item.checklist && item.checklist?.length >= 0) {
          setChecklist(item.checklist);
        }
        const data = { ...formData };
        const tempAttachments: Attachments[] = [];
        Object.keys(formData).map((field) => {
          let value = item?.[field];
          if (
            [
              "accountType",
              "associatedEmployee",
              "associatedCompany",
              "bankCountry",
              "bankCurrency",
              "bankType",
              "routingType",
            ].includes(field)
          ) {
            const tempValue = value?._id;
            value = tempValue;
          }
          if (field === "attachments") {
            for (let i = 0; i < value.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
                extension: value[i]?.extension,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
          }
          if (field === "associatedRecord") {
            if (item.associatedEmployee) {
              value = "employee";
            } else if (item.associatedCompany) {
              value = "company";
            }
          }

          data[field].value = value;
        });
        setFormData(data);
      } catch (e) {
        console.log("A ::", e);
      }
    },
    [formData],
  );

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        console.log(name, formData[name]?.value, value);
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );

  const fetchBankDetails = useCallback(() => {
    setLoading(true);
    const payload: FetchBankDetailsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchBankDetailsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;
    if (formData.voidCheque.value && !formData.attachments.value.length) {
      valid = false;
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Upload the required attachment/document.",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (
        item === "associatedCompany" &&
        formData.associatedRecord.value === "company" &&
        !validValue
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else if (
        item === "associatedEmployee" &&
        formData.associatedRecord.value === "employee" &&
        !validValue
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [dispatch, formData, notificationsId]);

  const onSubmitAdd = useCallback(() => {
    const {
      active,
      bankName,
      bankCountry,
      accountType,
      accountName,
      routingNumber,
      accountNumber,
      bankCurrency,
      routingType,
      swiftBICCode,
      associatedEmployee,
      associatedCompany,
      voidCheque,
      defaultAccount,
      bankType,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateBankDetailsPayload = {
        data: {
          attachments: attachments,
          active: active.value,
          bankName: bankName.value,
          bankCountry: bankCountry.value,
          accountType: accountType.value,
          accountName: accountName.value,
          routingNumber: routingNumber.value,
          accountNumber: accountNumber.value,
          bankCurrency: bankCurrency.value,
          routingType: routingType.value,
          swiftBICCode: swiftBICCode.value,
          associatedEmployee: associatedEmployee.value,
          associatedCompany: associatedCompany.value,
          voidCheque: voidCheque.value,
          defaultAccount: defaultAccount.value,
          bankType: bankType.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "BankDetails created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createBankDetails(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      active,
      bankName,
      bankCountry,
      accountType,
      accountName,
      routingNumber,
      accountNumber,
      bankCurrency,
      routingType,
      swiftBICCode,
      associatedEmployee,
      associatedCompany,
      voidCheque,
      defaultAccount,
      bankType,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const payload: UpdateBankDetailsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          associatedEmployee: associatedEmployee.value,
          active: active.value,
          bankName: bankName.value,
          bankCountry: bankCountry.value,
          accountType: accountType.value,
          accountName: accountName.value,
          routingNumber: routingNumber.value,
          accountNumber: accountNumber.value,
          bankCurrency: bankCurrency.value,
          routingType: routingType.value,
          swiftBICCode: swiftBICCode.value,
          associatedCompany: associatedCompany.value,
          voidCheque: voidCheque.value,
          defaultAccount: defaultAccount.value,
          bankType: bankType.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "BankDetails updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateBankDetails(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateBankDetailsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "BankDetails updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateBankDetails(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.accountName || "";
    }
    return [strings.headerTitle.bankDetails, title];
  }, [type, data]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  // ================ Checklist ================

  const onSubmitAddChecklist = useCallback(
    (type: string, checkListItems: ChecklistTemplateChecklistItem[]) => {
      setLoading(true);
      const payload: UpdateBankDetailsPayload = {
        id: id || "",
        data: {
          checklistType: "add",
          checklistItems: checkListItems,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateBankDetails(payload));
      setLoading(false);
    },
    [id, dispatch, notificationsId, rehydrateFormData],
  );
  const onSubmitUpdateChecklist = useCallback(
    (currentChecklistId: string, checklistItem: ChecklistTemplateChecklistItem) => {
      setLoading(true);

      const payload: UpdateBankDetailsPayload = {
        id: id || "",
        data: {
          checklistType: "update",
          checklistId: currentChecklistId,
          updateChecklist: checklistItem,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateBankDetails(payload));
      setLoading(false);
    },
    [id, rehydrateFormData, dispatch, notificationsId],
  );

  const deleteChecklist = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const checklistId = e.currentTarget?.getAttribute("data-index") || "";
      console.log(checklistId);
      const payload: UpdateBankDetailsPayload = {
        id: id || "",
        data: {
          checklistType: "delete",
          checklistId: checklistId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(deleteBankDetails(payload));
      setLoading(false);
    },
    [id, rehydrateFormData, dispatch, notificationsId],
  );

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    checklist,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchBankDetails,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    handleCheckbox,
  };
};
