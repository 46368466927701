import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ServiceRequestsList,
  AddServiceRequests,
  UpdateServiceRequests,
  FindServiceRequests,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchServiceRequestsListSuccess, fetchServiceRequestsError } from "./actions";
import Types from "./actionType";
import {
  CreateServiceRequestsuccess,
  CreateServiceRequests as CreateServiceRequestsType,
  ServiceRequestsSuccess,
  UpdateServiceRequests as UpdateServiceRequestsType,
  FetchServiceRequests,
  FetchServiceRequestsById,
  ServiceRequestsByIdSuccess,
} from "./types";

function* createWorker(action: CreateServiceRequestsType) {
  try {
    const options = {
      method: "POST",
      url: AddServiceRequests,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateServiceRequestsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* updateWorker(action: UpdateServiceRequestsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateServiceRequests(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateServiceRequestsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchServiceRequests) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ServiceRequestsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ServiceRequestsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchServiceRequestsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchServiceRequestsError(error.response?.data.message));
  }
}

function* fetchServiceRequestsById(action: FetchServiceRequestsById) {
  try {
    const params = {
      associatedTo: action.payload.associatedTo,
    };
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindServiceRequests(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ServiceRequestsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SERVICE_REQUESTS, createWorker);
  yield takeLatest(Types.UPDATE_SERVICE_REQUESTS, updateWorker);
  yield takeEvery(Types.FETCH_SERVICE_REQUESTS, fetchAllWorker);
  yield takeEvery(Types.FETCH_SERVICE_REQUESTS_BY_ID, fetchServiceRequestsById);
}
