import styled from "styled-components";

import { fonts } from "../../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    padding-bottom: 50px;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Column: styled.div`
    flex: 1;
  `,
  TopColumn: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin-top: 20px;
    box-shadow: 0px 1px 15px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 8px;
    top: 0;
    .icon {
      color: #b2c1cd;
      align-self: center;
    }
  `,
  TopDashboardContainer: styled.div`
    /* width: 60%; */
    margin-top: 10px;
    margin-left: 1%;
    display: flex;
    /* row-gap: 10px; */
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  `,
  PayrollStatus: styled.div`
    border-radius: 6px;
    height: ${(props) => (props.short === "true" ? "8vh" : "12vh")};
    display: flex;
    align-items: center;
    flex-direction: row;
    .title {
      display: flex;
      font-size: 14px;
      letter-spacing: 1px;
      color: white;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      font-weight: bold;
      height: 4vh;
      padding-left: 10px;
      padding-right: 10px;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#48b5e1")};
    }
    h3 {
      font-size: 12px;
      border: 1px solid #b2c1cd;
      height: 3.8vh;
      width: 2vw;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  TopGridItem: styled.div`
    border-radius: 6px;
    width: 8vw;
    height: 8vh;
    border: 1px solid #b2c1cd;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      display: flex;
      font-size: 14px;
      letter-spacing: 1px;
      color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-weight: bold;
      height: 4vh;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#48b5e1")};
      margin-bottom: 6%;
    }
    h3 {
      font-size: 12px;
      font-weight: 400;
      margin-top: ${(props) => (props.short === "true" ? "0" : "6%")};
    }
  `,
  TopColumnChild: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    text-align: center;
    font-family: ${fonts.font1};
    font-size: 13px;
  `,
  BottomColumn: styled.div`
    display: flex;
    height: 60%;
    overflow: scroll;
    flex-direction: column;
    background-color: white;
    padding: 10px;
  `,
};

export default styles;
