/* eslint-disable no-unused-vars */
enum Types {
  FETCH_EDUCATION_DETAILS = "FETCH_EDUCATION_DETAILS",
  FETCH_EDUCATION_DETAILS_BY_ID = "FETCH_EDUCATION_DETAILS_BY_ID",
  SUCCESS_FETCH_EDUCATION_DETAILS = "SUCCESS_FETCH_EDUCATION_DETAILS",
  ERROR_FETCH_EDUCATION_DETAILS = "ERROR_FETCH_EDUCATION_DETAILS",
  CREATE_EDUCATION_DETAILS = "CREATE_EDUCATION_DETAILS",
  UPDATE_EDUCATION_DETAILS = "UPDATE_EDUCATION_DETAILS",
  DELETE_EDUCATION_DETAILS = "DELETE_EDUCATION_DETAILS",
}

export default Types;
