import React from "react";

import { Layout } from "../../../presentationals";
import { LeadListsList } from "../../../presentationals/screens/admin/LeadLists/LeadListsList";

export const Route = () => {
  return <Layout activity={<LeadListsList />} admin={true} layout={"2"} />;
};

export const APLeadListsList = React.memo(Route);
