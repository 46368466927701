import React, { useCallback, useEffect, useMemo } from "react";

import { images, strings } from "../../../../theme";
import { ListActions } from "../../../../types";
import { useCommonActions } from "../../../common";
import { Button, Select, TextInput } from "../../atoms";
import { Popup } from "../Popup";

import { usePaginationV3 } from "./hooks/usePaginationV3";
import {
  ConfigArrow,
  ConfigItem,
  ConfigTitle,
  Container,
  DeletePopupContainer,
  DropdownListContainer,
  InfoContainer,
  PagesContainer,
  PaginationArrow,
  PaginationItem,
  SettingsIcon,
  TableConfigChildPopup,
  TableConfigPopup,
  TableConfigPopupContainer,
} from "./styles";

export interface PaginationV3Props {
  onPageChange?: (page: string) => void;
  onSubmitDeleteRecord?: () => void;
  deleteRecordEnabled?: boolean;
  isRelatedList?: boolean;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  containerClass?: string;
  selectedRows?: string[];
  handleShowItemsPerPage?: (count: number) => void;
  exportSelectedRecords: (type: string) => void;
  listActionOptions?: ListActions[];
  exportAllRecords: (type: string) => void;
  handleListActions?: (field: string, value: any, type?: string, isMulti?: boolean) => void;
}

export const PaginationV3 = ({
  onPageChange,
  onSubmitDeleteRecord,
  deleteRecordEnabled,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  containerClass = "",
  selectedRows,
  handleShowItemsPerPage,
  exportSelectedRecords,
  exportAllRecords,
  listActionOptions,
  handleListActions,
  isRelatedList,
}: PaginationV3Props) => {
  const {
    deleteRecords,
    lastPage,
    onPrevious,
    onNext,
    itemRange,
    goToFirstPage,
    goToLastPage,
    tableConfigOptions,
    selectedTableconfigOption,
    handleTableConfigOption,
    handleTableConfigChild,
    configPopup,
    handleConfigPopup,
    onListActionFieldChange,
    onListActionValueChange,
    handleActionsPopup,
    handleListAction,
    actionsPopup,
    allListActionFields,
    listActionField,
    listActionValue,
    closePopups,
    selectedListActionFieldOptions,
    listActionFieldType,
    listActionFieldIsMulti,
    listActionRequired,
    valueError,
  } = usePaginationV3({
    currentPage,
    onSubmitDeleteRecord,
    deleteRecordEnabled,
    totalCount,
    siblingCount,
    pageSize,
    onPageChange,
    selectedRows,
    handleShowItemsPerPage,
    exportSelectedRecords,
    exportAllRecords,
    listActionOptions,
    handleListActions,
  });
  const { handlePopupWrapperClick } = useCommonActions();
  useEffect(() => {
    const eventListener = (event) => {
      if (event.type === "myCustomEvent") {
        if (event.detail.popup === false) {
          closePopups();
        }
      }
    };

    document.addEventListener("myCustomEvent", eventListener);

    return () => {
      document.removeEventListener("myCustomEvent", eventListener);
    };
  }, []);

  const renderListActionField = useCallback(() => {
    switch (listActionFieldType) {
      case "text":
        return (
          <TextInput
            label='Value'
            type='text'
            width='100%'
            error={listActionRequired && valueError ? strings.common.required : ""}
            inputProps={{
              name: "listActionValue",
              value: listActionValue,
              autoComplete: "off",
              required: listActionRequired,
              onChange: onListActionValueChange,
            }}
            textAreaProps={{}}
          />
        );
      case "date":
        return (
          <TextInput
            label='Value'
            type={"date"}
            width='100%'
            error={listActionRequired && valueError ? strings.common.required : ""}
            onDateChange={onListActionValueChange}
            datePickerProps={{
              name: "listActionValue",
              value: listActionValue,
              required: listActionRequired,
            }}
            textAreaProps={{}}
          />
        );
      case "bigTextArea":
        return (
          <TextInput
            label='Value'
            type='textarea'
            width='100%'
            error={listActionRequired && valueError ? strings.common.required : ""}
            textAreaProps={{
              name: "listActionValue",
              rows: 6,
              value: listActionValue,
              autoComplete: "off",
              required: listActionRequired,

              onChange: onListActionValueChange,
            }}
          />
        );
      default:
        return (
          <Select
            name='listActionValue'
            label='Value'
            options={selectedListActionFieldOptions || []}
            isMulti={listActionFieldIsMulti}
            value={listActionValue}
            onChange={onListActionValueChange}
            mandatory={listActionRequired}
            readOnly={!listActionField}
            error={listActionRequired && valueError ? strings.common.required : ""}
            width='100%'
          />
        );
    }
  }, [
    listActionFieldType,
    listActionValue,
    listActionRequired,
    valueError,
    listActionFieldIsMulti,
    onListActionValueChange,
    selectedListActionFieldOptions,
    listActionField,
  ]);
  return (
    <Container className={containerClass}>
      <PagesContainer>
        <PaginationItem onClick={goToFirstPage} disabled={currentPage === 1 || totalCount === 0}>
          <PaginationArrow src={images.leftDoubleArrow} />
        </PaginationItem>
        <PaginationItem onClick={onPrevious} disabled={currentPage === 1 || totalCount === 0}>
          <PaginationArrow src={images.leftSingleArrow} />
        </PaginationItem>
        <InfoContainer>{`${itemRange} ${strings.common.of} ${totalCount}`}</InfoContainer>
        <PaginationItem onClick={onNext} disabled={currentPage === lastPage || totalCount === 0}>
          <PaginationArrow src={images.rightSingleArrow} />
        </PaginationItem>
        <PaginationItem
          onClick={goToLastPage}
          disabled={currentPage === lastPage || totalCount === 0}
        >
          <PaginationArrow src={images.rightDoubleArrow} />
        </PaginationItem>
      </PagesContainer>
      {!isRelatedList && <SettingsIcon src={images.settings} onClick={handleConfigPopup} />}
      {!isRelatedList && configPopup && (
        <TableConfigPopupContainer onClick={handlePopupWrapperClick}>
          <TableConfigPopup>
            {tableConfigOptions.map((item, index) => {
              const { label, active } = item;
              const isTabSelected = selectedTableconfigOption === index;
              return (
                <ConfigItem
                  key={index}
                  data-index={index}
                  onClick={handleTableConfigOption}
                  disabled={!active}
                  active={isTabSelected}
                >
                  <ConfigTitle>{label}</ConfigTitle>
                  <ConfigArrow src={isTabSelected ? images.activeRightArrow : images.arrowRight} />
                </ConfigItem>
              );
            })}
          </TableConfigPopup>
          {selectedTableconfigOption >= 0 && (
            <TableConfigChildPopup>
              {tableConfigOptions[selectedTableconfigOption]?.childs?.map((item, index) => {
                const { label, active, selected } = item;
                return (
                  <ConfigItem
                    key={index}
                    data-index={index}
                    onClick={handleTableConfigChild}
                    disabled={!active}
                    active={selected}
                  >
                    <ConfigTitle>{label}</ConfigTitle>
                  </ConfigItem>
                );
              })}
            </TableConfigChildPopup>
          )}
        </TableConfigPopupContainer>
      )}
      <Popup isOpen={!!actionsPopup} closeModal={handleActionsPopup}>
        {actionsPopup === "update" && (
          <DropdownListContainer>
            <Select
              name={"listActionField"}
              label={"Field"}
              options={allListActionFields || []}
              value={listActionField}
              onChange={onListActionFieldChange}
              mandatory={true}
              error={""}
              width='100%'
            />
            {renderListActionField()}
            {/* {listActionRequired && valueError && <span style={{ color: "red" }}>{valueError}</span>} */}
            <Button
              // disabled={!listActionField || ["", null, undefined].includes(listActionValue)}
              title={strings.common.save}
              onClick={handleListAction}
              width={"100%"}
            />
          </DropdownListContainer>
        )}
        {actionsPopup === "delete" && (
          <DeletePopupContainer>
            <ConfigTitle>{strings.common.deleteWarning}</ConfigTitle>
            <Button title={strings.common.delete} onClick={deleteRecords} width={"100%"} />
            <Button
              title={strings.common.cancel}
              onClick={() => handleActionsPopup("")}
              width={"100%"}
            />
          </DeletePopupContainer>
        )}
      </Popup>
    </Container>
  );
};
