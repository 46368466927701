import Types from "./actionType";
import {
  FetchContactUsPayload,
  CreateContactUs,
  CreateContactUsPayload,
  FetchContactUs,
  FetchContactUsError,
  FetchContactUsSuccess,
  UpdateContactUs,
  UpdateContactUsPayload,
  ContactUsListWithMetaData,
  FetchContactUsByIdPayload,
  FetchContactUsById,
  DeleteContactUsPayload,
  DeleteContactUs,
} from "./types";

export const fetchContactUsList = ({
  params,
  onSuccess,
  onError,
}: FetchContactUsPayload): FetchContactUs => ({
  type: Types.FETCH_CONTACT_US,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchContactUsById = ({
  id,
  onSuccess,
  onError,
}: FetchContactUsByIdPayload): FetchContactUsById => ({
  type: Types.FETCH_CONTACT_US_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchContactUsListSuccess = (
  data: ContactUsListWithMetaData,
): FetchContactUsSuccess => ({
  type: Types.SUCCESS_FETCH_CONTACT_US,
  payload: {
    data,
  },
});

export const fetchContactUsError = (error?: string): FetchContactUsError => ({
  type: Types.ERROR_FETCH_CONTACT_US,
  payload: { error },
});

export const createContactUs = ({
  data,
  onSuccess,
  onError,
}: CreateContactUsPayload): CreateContactUs => ({
  type: Types.CREATE_CONTACT_US,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateContactUs = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateContactUsPayload): UpdateContactUs => ({
  type: Types.UPDATE_CONTACT_US,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteContactUs = ({
  id,
  onSuccess,
  onError,
}: DeleteContactUsPayload): DeleteContactUs => ({
  type: Types.DELETE_CONTACT_US,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
