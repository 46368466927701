import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { LeadListsControllerProps } from "../../../../types/models/LeadLists";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useLeadListsController } from "./hooks";

const Controller = ({ type }: LeadListsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchLeadLists,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useLeadListsController({ type, id, attachmentsPickerRef });
  const currentLeadLists = data;

  const { workLogs, attachments, name, active } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchLeadLists();
    }
    // Do not include fetchLeadLists to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentLeadLists?.createdBy !== "string" ? currentLeadLists?.createdBy?.name : "";
    const updatedBy =
      typeof currentLeadLists?.updatedBy !== "string" ? currentLeadLists?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentLeadLists?.createdAt}
        updatedAt={currentLeadLists?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentLeadLists?.createdAt,
    currentLeadLists?.createdBy,
    currentLeadLists?.updatedAt,
    currentLeadLists?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.leadLists.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.leadLists.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const LeadListsController = memo(Controller);
