import React from "react";

import { Container, Title } from "./styles";

interface ButtonType {
  title: string;
  border?: string;
  leftArrow?: boolean;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  width?: string;
  height?: string;
  top?: string;
  attributes?: object;
  className?: string;
}

export const Button = ({
  title,
  border,
  leftArrow,
  onClick,
  disabled,
  top = "0",
  attributes = {},
  className = "",
}: ButtonType) => {
  return (
    <Container
      top={top}
      border={border}
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...attributes}
    >
      {leftArrow && <div className='arrow left' />}
      <Title leftArrow={leftArrow}>{title}</Title>
    </Container>
  );
};
