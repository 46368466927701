import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { fetchAllMedia } from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Sort } from "../../../../../services/methods";

interface useJobPostingConfig {
  filteredData: () => void;
  store: object;
  onClickAdd: () => void;
  onClickFilter: () => void;
  onClickRow: (id: number) => void;
}
export const useJobPostingConfigs = (): useJobPostingConfig => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAllMedia({}));
  }, [dispatch]);
  const store = {
    loading: useAppSelector((state) => state.UsersReducer.loading),
    user: useAppSelector((state) => state.UserReducer),
    data: useAppSelector((state) => state.MediaReducer.list),
    permissions: useAppSelector((state) => state.UserReducer.permissions),
    filters: useAppSelector((state) => state.MediaReducer.filters),
  };

  const filteredData = useCallback(() => {
    const sortKey = store.filters.sortKey.key;
    const isDate = store.filters.sortKey.isDate;
    const ascSort = store.filters.sortType === "asc";
    const attribute = store.filters.sortKey.attribute;
    let data = store.data;
    if (store.data?.[0]?.[sortKey]) {
      data = Sort({
        data: store.data,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return data;
  }, [
    store.data,
    store.filters.sortKey.attribute,
    store.filters.sortKey.isDate,
    store.filters.sortKey.key,
    store.filters.sortType,
  ]);
  const onClickRow = useCallback(
    (id) => {
      navigate(`/ap/media/${id}/edit`);
    },
    [navigate],
  );
  const onClickAdd = useCallback(() => navigate(`/ap/media/add`), [navigate]);
  const onClickFilter = useCallback(() => navigate(`/ap/media/filter`), [navigate]);
  return {
    filteredData,
    store,
    onClickAdd,
    onClickFilter,
    onClickRow,
  };
};
