import React from "react";

import { Layout } from "../../../presentationals";
import { Profile } from "../../../presentationals/screens/admin/Profile";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<Profile />}
      title={strings.headerTitle.profile}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
