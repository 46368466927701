import React from "react";

import { strings } from "../../../../theme";

import { Container, Name, Message } from "./styles";

const WelcomeCard = ({ name }) => {
  return (
    <Container>
      <Name>
        {strings.common.welcome} {name}
      </Name>
      <Message>{strings.common.welcomeQuote}</Message>
    </Container>
  );
};

export default WelcomeCard;
