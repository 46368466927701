import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createGroups,
  fetchGroupsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateGroups,
  uploadFile,
} from "../../../../../redux";
import {
  GroupsList,
  CreateGroupsPayload,
  FetchGroupsByIdPayload,
  UpdateGroupsPayload,
  MemberListUser,
} from "../../../../../redux/Groups/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  ChecklistTemplateChecklistItem,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  useGroupsControllerProps,
  useGroupsControllerReturnType,
  GroupsForm,
  User,
} from "../../../../../types/models/Groups";
import { collections } from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";
// ================ Contstants ================

const addFormSteps = ["Overview", "Permissions", "Members"];
const editFormSteps = [
  "Overview",
  "Permissions",
  "Members",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = ["name", "description", "groupCountry", "groupDept", "active", "type"];
export const useGroupsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useGroupsControllerProps): useGroupsControllerReturnType => {
  const defaultFormData: GroupsForm = {
    name: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: true,
      error: "",
    },
    groupCountry: {
      value: "",
      required: true,
      error: "",
    },
    groupDept: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    type: {
      value: "",
      required: false,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { countryOptions, departmentOptions, usersOptions } = useControllerConfigs();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<GroupsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [permissionList, setPermissionList] = useState<any[]>([]);
  const [membersList, setMembersList] = useState<MemberListUser[]>([]);
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<GroupsList>({});
  const [memberSelectValue, setMemberSelectValue] = useState<{ label: string; value: string }>({
    label: "",
    value: "",
  });
  const [availableList, setAvailableList] = useState<User[]>([]);
  const [selectedList, setSelectedList] = useState<User[]>([]);
  const [membersAvailableList, setMembersAvailableList] = useState<any[]>([]);
  const [membersSelectedList, setMembersSelectedList] = useState<any[]>([]);
  // ================Checklist State Values ================
  const [checklist, setChecklist] = useState<ChecklistTemplateChecklistItem[]>([]);

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field]?.error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const handleMemberSelectValue = useCallback(
    (value: { label: string; value: string }) => {
      setMemberSelectValue(value);
    },
    [setMemberSelectValue],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      console.log(value);
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const handleAllAdd = useCallback(
    (name: string) => {
      if (name === "groupCountry") {
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value: countryOptions,
            error: "",
          },
        };
        setFormData(tempFormData);
      } else if (name === "groupDept") {
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value: departmentOptions,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [countryOptions, formData, departmentOptions],
  );

  const setPermissionsArray = useCallback((inputObject) => {
    const groupedData = {};

    for (const [key, value] of Object.entries(inputObject)) {
      const groupName = key.split("_")[0];

      if (
        !groupedData[groupName] &&
        !["checklistTemplate", "dashboardWidgets", "dashboardTabs"].includes(groupName)
      ) {
        groupedData[groupName] = {
          groupName: groupName,
          label: collections?.[groupName]?.label,
        };
      }

      groupedData[groupName][key] = value;
    }

    const resultArray = Object.values(groupedData);

    const filteredArray = resultArray.filter((obj) =>
      Object.values(obj)
        .slice(2)
        .some((val) => val),
    );

    return filteredArray;
  }, []);

  const formatPermissionData = useCallback((permissionList) => {
    const transformedObject = {};

    permissionList.forEach((obj) => {
      const groupName = obj.groupName;
      const capitalizedGroupName = groupName.charAt(0).toUpperCase() + groupName.slice(1);

      transformedObject[groupName] = {
        name: groupName,
        label: collections?.[groupName]?.label,
        isSelected: true,
      };
    });

    return transformedObject;
  }, []);

  const handleAvailableSelectedList = useCallback((list: User[]) => {
    const updateSelectedList: User[] = [];
    const updatedAvailableList: User[] = [];

    Object.values(list).map((user) => {
      if (user?.isSelected) {
        updateSelectedList.push(user);
      } else if (
        user &&
        user.name &&
        !["checklistTemplate", "dashboardWidgets", "dashboardTabs"].includes(user.name)
      ) {
        updatedAvailableList.push(user);
      }
    });

    setSelectedList((prev) => {
      return [...prev, ...updateSelectedList];
    });
    setAvailableList((prev) => {
      return [...prev, ...updatedAvailableList];
    });
  }, []);

  const rehydrateFormData = useCallback(
    (groups: GroupsList) => {
      const item = groups;
      if (item.checklist && item.checklist?.length >= 0) {
        setChecklist(item.checklist);
      }

      if (item.permissions) {
        const resultArray = setPermissionsArray(item.permissions);
        setPermissionList(resultArray);
        const formatedObj = formatPermissionData(resultArray);
        handleAvailableSelectedList(formatedObj);
      }
      if (item.members) {
        setMembersList(item.members);
      }
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["groupDept", "groupCountry"].includes(field) && Array.isArray(value)) {
          const tempValue = value.map((item) => {
            return { label: item?.name, value: item?._id };
          });
          value = tempValue;
          data[field].value = value;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData, handleAvailableSelectedList, formatPermissionData, setPermissionsArray],
  );

  const fetchGroups = useCallback(() => {
    setLoading(true);
    const payload: FetchGroupsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchGroupsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const formatPermission = useCallback((inputArray) => {
    const resultObject = {};

    inputArray.forEach((obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (key !== "groupName") {
          resultObject[key] = value;
        }
      });
    });

    return resultObject;
  }, []);

  const onSubmitAdd = useCallback(() => {
    const { name, description, groupCountry, groupDept, active, type } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const permissions = formatPermission(permissionList);
      const tempGroupCountry = groupCountry.value.map((item) => item.value);
      const tempGroupDept = groupDept.value.map((item) => item.value);

      const payload: CreateGroupsPayload = {
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          groupCountry: tempGroupCountry,
          groupDept: tempGroupDept,
          active: active.value,
          type: type.value,
          permissions,
          members: membersList,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createGroups(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    navigate,
    permissionList,
    membersList,
    formatPermission,
  ]);

  const handleMemberDelete = useCallback(
    (member: User) => {
      const updatedList = membersList.filter((user) => user.user._id !== member.user._id);
      setMembersList(updatedList);
    },
    [setMembersList, membersList],
  );

  const onSubmitUpdate = useCallback(() => {
    const { name, description, groupCountry, groupDept, active } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const permissions = formatPermission(permissionList);
      const tempGroupCountry = groupCountry.value.map((item) => item.value);
      const tempGroupDept = groupDept.value.map((item) => item.value);

      const payload: UpdateGroupsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          groupCountry: tempGroupCountry,
          groupDept: tempGroupDept,
          active: active.value,
          permissions,
          members: membersList,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateGroups(payload));
    }
  }, [
    formData,
    id,
    dispatch,
    notificationsId,
    validateForm,
    permissionList,
    membersList,
    formatPermission,
  ]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateGroupsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateGroups(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    const type = data.type;
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.groups, title];
  }, [data]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  // ================ Checklist ================

  const onSubmitAddChecklist = useCallback(
    (type: string, checkListItems: ChecklistTemplateChecklistItem[]) => {
      setLoading(true);
      const payload: UpdateGroupsPayload = {
        id: id || "",
        data: {
          checklistType: "add",
          checklistItems: checkListItems,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateGroups(payload));
      setLoading(false);
    },
    [id, dispatch, notificationsId, rehydrateFormData],
  );
  const onSubmitUpdateChecklist = useCallback(
    (currentChecklistId: string, checklistItem: ChecklistTemplateChecklistItem) => {
      setLoading(true);

      const payload: UpdateGroupsPayload = {
        id: id || "",
        data: {
          checklistType: "update",
          checklistId: currentChecklistId,
          updateChecklist: checklistItem,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateGroups(payload));
      setLoading(false);
    },
    [id, rehydrateFormData, dispatch, notificationsId],
  );
  const handlePermissionSwitch = useCallback(
    (event, isAllSwitch = false) => {
      const target = event.target;
      const dataName = target.parentElement.getAttribute("data-name");
      const groupName = dataName.split("_");

      // Update permissionsList based on dataName
      setPermissionList((prevList) =>
        prevList.map((permission) =>
          permission.groupName === groupName[0]
            ? (() => {
                // Handle "All" switch logic
                if (isAllSwitch) {
                  const isAllActive = !permission[`${permission.groupName}_all`];

                  return {
                    ...permission,
                    [`${permission.groupName}_add`]: isAllActive,
                    [`${permission.groupName}_view`]: isAllActive,
                    [`${permission.groupName}_edit`]: isAllActive,
                    [`${permission.groupName}_delete`]: isAllActive,
                    [`${permission.groupName}_list`]: isAllActive,
                    [`${permission.groupName}_all`]: isAllActive,
                  };
                }

                // Handle other switches
                return { ...permission, [dataName]: !permission[dataName] };
              })()
            : (() => {
                return permission;
              })(),
        ),
      );
    },
    [setPermissionList],
  );
  const deleteChecklist = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const checklistId = e.currentTarget?.getAttribute("data-index") || "";
      const payload: UpdateGroupsPayload = {
        id: id || "",
        data: {
          checklistType: "delete",
          checklistId: checklistId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Groups checklsit updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateGroups(payload));
      setLoading(false);
    },
    [id, rehydrateFormData, dispatch, notificationsId],
  );

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    checklist,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchGroups,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    permissionList,
    setPermissionList,
    memberSelectValue,
    handleMemberSelectValue,
    handlePermissionSwitch,
    membersList,
    setMembersList,
    setAvailableList,
    availableList,
    setSelectedList,
    selectedList,
    handleAvailableSelectedList,
    handleMemberDelete,
    membersAvailableList,
    membersSelectedList,
    setMembersAvailableList,
    setMembersSelectedList,
    handleAllAdd,
  };
};
