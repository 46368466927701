import Types from "./actionType";
import {
  FetchCountryPayload,
  CreateCountry,
  CreateCountryPayload,
  FetchCountry,
  FetchCountryError,
  FetchCountrySuccess,
  UpdateCountry,
  UpdateCountryPayload,
  CountryListWithMetaData,
  FetchCountryByIdPayload,
  FetchCountryById,
  DeleteCountryPayload,
  DeleteCountry,
} from "./types";

export const fetchCountryList = ({
  params,
  onSuccess,
  onError,
}: FetchCountryPayload): FetchCountry => ({
  type: Types.FETCH_COUNTRY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCountryById = ({
  id,
  onSuccess,
  onError,
}: FetchCountryByIdPayload): FetchCountryById => ({
  type: Types.FETCH_COUNTRY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCountryListSuccess = (data: CountryListWithMetaData): FetchCountrySuccess => ({
  type: Types.SUCCESS_FETCH_COUNTRY,
  payload: {
    data,
  },
});

export const fetchCountryError = (error?: string): FetchCountryError => ({
  type: Types.ERROR_FETCH_COUNTRY,
  payload: { error },
});

export const createCountry = ({
  data,
  onSuccess,
  onError,
}: CreateCountryPayload): CreateCountry => ({
  type: Types.CREATE_COUNTRY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCountry = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCountryPayload): UpdateCountry => ({
  type: Types.UPDATE_COUNTRY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCountry = ({ id, onSuccess, onError }: DeleteCountryPayload): DeleteCountry => ({
  type: Types.DELETE_COUNTRY,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
