/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CAMPAIGNS_ASSETS = "FETCH_CAMPAIGNS_ASSETS",
  FETCH_CAMPAIGNS_ASSETS_BY_ID = "FETCH_CAMPAIGNS_ASSETS_BY_ID",
  SUCCESS_FETCH_CAMPAIGNS_ASSETS = "SUCCESS_FETCH_CAMPAIGNS_ASSETS",
  ERROR_FETCH_CAMPAIGNS_ASSETS = "ERROR_FETCH_CAMPAIGNS_ASSETS",
  CREATE_CAMPAIGNS_ASSETS = "CREATE_CAMPAIGNS_ASSETS",
  UPDATE_CAMPAIGNS_ASSETS = "UPDATE_CAMPAIGNS_ASSETS",
  DELETE_CAMPAIGNS_ASSETS = "DELETE_CAMPAIGNS_ASSETS",
}

export default Types;
