import Types from "./actionType";
import {
  FetchPlatformModulesPayload,
  CreatePlatformModules,
  CreatePlatformModulesPayload,
  FetchPlatformModules,
  FetchPlatformModulesError,
  FetchPlatformModulesSuccess,
  UpdatePlatformModules,
  UpdatePlatformModulesPayload,
  PlatformModulesListWithMetaData,
  FetchPlatformModulesByIdPayload,
  FetchPlatformModulesById,
  DeletePlatformModulesPayload,
  DeletePlatformModules,
} from "./types";

export const fetchPlatformModulesList = ({
  params,
  onSuccess,
  onError,
}: FetchPlatformModulesPayload): FetchPlatformModules => ({
  type: Types.FETCH_PLATFORM_MODULES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchPlatformModulesById = ({
  id,
  onSuccess,
  onError,
}: FetchPlatformModulesByIdPayload): FetchPlatformModulesById => ({
  type: Types.FETCH_PLATFORM_MODULES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchPlatformModulesListSuccess = (
  data: PlatformModulesListWithMetaData,
): FetchPlatformModulesSuccess => ({
  type: Types.SUCCESS_FETCH_PLATFORM_MODULES,
  payload: {
    data,
  },
});

export const fetchPlatformModulesError = (error?: string): FetchPlatformModulesError => ({
  type: Types.ERROR_FETCH_PLATFORM_MODULES,
  payload: { error },
});

export const createPlatformModules = ({
  data,
  onSuccess,
  onError,
}: CreatePlatformModulesPayload): CreatePlatformModules => ({
  type: Types.CREATE_PLATFORM_MODULES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updatePlatformModules = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdatePlatformModulesPayload): UpdatePlatformModules => ({
  type: Types.UPDATE_PLATFORM_MODULES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deletePlatformModules = ({
  id,
  onSuccess,
  onError,
}: DeletePlatformModulesPayload): DeletePlatformModules => ({
  type: Types.DELETE_PLATFORM_MODULES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
