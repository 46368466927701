import React from "react";

import { Layout } from "../../../presentationals";
import { TagsController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<TagsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APTagsAdd = React.memo(Route);
