import React, { useEffect, useMemo, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { CurrencyListProps, ColumnsType } from "../../../../types";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useCurrencyList } from "./hooks";

export const CurrencyList = memo(({ relatedList }: CurrencyListProps) => {
  const Currency = useAppSelector((state) => state.CurrencyReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = Currency;
  const {
    fetchCurrency,
    loading,
    handlePageChange,
    onClickCurrency,
    onSubmitDeleteRecord,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    currencyFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    CurrencyConfigTableFields,
  } = useCurrencyList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchCurrency({});
    // Do not include fetchCurrency to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.currency}
      handleTableConfigs={handleTableConfigs}
      filters={currencyFilterFields}
      selectedConfigFields={CurrencyConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickCurrency}
      headerTitle={strings.headerTitle.currency}
      list={list}
      columns={columns}
      metaData={metaData}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.currency_delete}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apCurrency.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
