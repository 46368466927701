import React, { useEffect } from "react";

import { MdEdit } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/button";
import EndpointCopy from "../../components/EndpointCopy";
import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import Config from "../../config";
import { fetchAllMedia } from "../../redux";
import { MomentDate, useTitle, Sort } from "../../services/methods";

import styles from "./styles";

const MediaListing = (props: any) => {
  const navigate = useNavigate();
  useTitle("Media Insights");

  useEffect(() => {
    props.fetchAllMedia({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSortedData = () => {
    const sortKey = props.filters.sortKey.key;
    const isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    const attribute = props.filters.sortKey.attribute;
    let data = props.data;
    if (props.data?.[0]?.[sortKey]) {
      data = Sort({
        data: props.data,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return data;
  };
  console.log(getSortedData());
  return (
    <styles.MainContainer>
      <Header
        title={"Media Insights"}
        buttons={[
          {
            title: "Add",
            onPress: {
              event: navigate,
              params: "/ap/media/add",
            },
            visible: props.permissions.media_add,
          },
        ]}
        onSync={{
          event: props.fetchAllMedia,
          params: {},
        }}
      />
      <styles.Header>
        <div>
          <styles.CodeContainer>
            <b>Base Url:</b>
            <EndpointCopy data={Config.url} />
          </styles.CodeContainer>
          <styles.CodeContainer>
            <b>Listing Endpoint:</b>
            <EndpointCopy
              data={
                "/rest/v1/mediainsights?organisation=" +
                props?.user?.organisation?._id +
                "&active=true&limit=20&page=1"
              }
            />
          </styles.CodeContainer>
          <styles.CodeContainer>
            <b>Single Media Endpoint:</b>
            <EndpointCopy data={"/rest/v1/mediainsights/%MEDIA_ID%"} />
          </styles.CodeContainer>
        </div>
      </styles.Header>
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              Sno.
            </th>
            <th align='left' width='15%'>
              Published
            </th>
            <th align='left' width='70%'>
              Name
            </th>
            <th>Active</th>
            {props.permissions.media_edit && <th>-</th>}
          </tr>
        </thead>
        <tbody>
          {getSortedData().map((media, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{MomentDate({ date: media.createdAt, format: "YYYY-MM-DD" })}</td>
              <td>{media.name}</td>
              <td align='center'>{media.active ? "Yes" : "No"}</td>
              {props.permissions.media_edit && (
                <td
                  onClick={() => navigate(`/ap/media/${media._id}/edit`)}
                  width='5%'
                  align='center'
                >
                  <MdEdit className='edit' size={15} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  permissions: state.UserReducer.permissions,
  data: state.MediaReducer.list,
  filters: state.MediaReducer.filters,
});

const mapDispatchToProps = {
  fetchAllMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaListing);
