import Types from "./actionType";
import {
  FetchLeadListsPayload,
  CreateLeadLists,
  CreateLeadListsPayload,
  FetchLeadLists,
  FetchLeadListsError,
  FetchLeadListsSuccess,
  UpdateLeadLists,
  UpdateLeadListsPayload,
  LeadListsListWithMetaData,
  FetchLeadListsByIdPayload,
  FetchLeadListsById,
  DeleteLeadListsPayload,
  DeleteLeadLists,
} from "./types";

export const fetchLeadListsList = ({
  params,
  onSuccess,
  onError,
}: FetchLeadListsPayload): FetchLeadLists => ({
  type: Types.FETCH_LEAD_LISTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchLeadListsById = ({
  id,
  onSuccess,
  onError,
}: FetchLeadListsByIdPayload): FetchLeadListsById => ({
  type: Types.FETCH_LEAD_LISTS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchLeadListsListSuccess = (
  data: LeadListsListWithMetaData,
): FetchLeadListsSuccess => ({
  type: Types.SUCCESS_FETCH_LEAD_LISTS,
  payload: {
    data,
  },
});

export const fetchLeadListsError = (error?: string): FetchLeadListsError => ({
  type: Types.ERROR_FETCH_LEAD_LISTS,
  payload: { error },
});

export const createLeadLists = ({
  data,
  onSuccess,
  onError,
}: CreateLeadListsPayload): CreateLeadLists => ({
  type: Types.CREATE_LEAD_LISTS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateLeadLists = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateLeadListsPayload): UpdateLeadLists => ({
  type: Types.UPDATE_LEAD_LISTS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteLeadLists = ({
  id,
  onSuccess,
  onError,
}: DeleteLeadListsPayload): DeleteLeadLists => ({
  type: Types.DELETE_LEAD_LISTS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
