/* eslint-disable no-unused-vars */
enum Types {
  FETCH_COMPANY = "FETCH_COMPANY",
  FETCH_COMPANY_BY_ID = "FETCH_COMPANY_BY_ID",
  SUCCESS_FETCH_COMPANY = "SUCCESS_FETCH_COMPANY",
  ERROR_FETCH_COMPANY = "ERROR_FETCH_COMPANY",
  CREATE_COMPANY = "CREATE_COMPANY",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  DELETE_COMPANY = "DELETE_COMPANY",
}

export default Types;
