import Types from "./actionType";
import {
  FetchDepartmentsPayload,
  CreateDepartment,
  CreateDepartmentPayload,
  DepartmentsList,
  FetchDepartments,
  FetchDepartmentsError,
  FetchDepartmentsSuccess,
  UpdateDepartment,
  UpdateDepartmentPayload,
  DepartmentsListWithMetaData,
  FetchDepartmentsByIdPayload,
  FetchDepartmentsById,
  DeleteDepartmentPayload,
  DeleteDepartment,
} from "./types";

export const fetchDepartmentsList = ({
  params,
  onSuccess,
  onError,
}: FetchDepartmentsPayload): FetchDepartments => ({
  type: Types.FETCH_DEPARTMENTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});
export const fetchDepartmentsById = ({
  id,
  onSuccess,
  onError,
}: FetchDepartmentsByIdPayload): FetchDepartmentsById => ({
  type: Types.FETCH_DEPARTMENT_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDepartmentsListSuccess = (
  data: DepartmentsListWithMetaData,
): FetchDepartmentsSuccess => ({
  type: Types.SUCCESS_FETCH_DEPARTMENTS,
  payload: {
    data,
  },
});

export const fetchDepartmentsError = (error?: string): FetchDepartmentsError => ({
  type: Types.ERROR_FETCH_DEPARTMENTS,
  payload: { error },
});

export const createDepartment = ({
  data,
  onSuccess,
  onError,
}: CreateDepartmentPayload): CreateDepartment => ({
  type: Types.CREATE_DEPARTMENT,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDepartment = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDepartmentPayload): UpdateDepartment => ({
  type: Types.UPDATE_DEPARTMENT,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteDepartment = ({
  id,
  onSuccess,
  onError,
}: DeleteDepartmentPayload): DeleteDepartment => ({
  type: Types.DELETE_DEPARTMENT,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
