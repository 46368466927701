/* eslint-disable no-unused-vars */
enum Types {
  KNOWLEDGE_CREATE = "KNOWLEDGE_CREATE",
  KNOWLEDGE_UPDATE = "KNOWLEDGE_UPDATE",
  KNOWLEDGE_FETCH = "KNOWLEDGE_FETCH",
  KNOWLEDGE_DASHBOARD_FETCH = "KNOWLEDGE_DASHBOARD_FETCH",
  KNOWLEDGE_FETCH_SUCCESS = "KNOWLEDGE_FETCH_SUCCESS",
  KNOWLEDGE_DASHBOARD_FETCH_SUCCESS = "KNOWLEDGE_DASHBOARD_FETCH_SUCCESS",
  UPDATE_KNOWLEDGE_FILTER = "UPDATE_KNOWLEDGE_FILTER",
  UPDATE_KNOWLEDGE_APPROVAL = "UPDATE_KNOWLEDGE_APPROVAL",
  UPDATE_KNOWLEDGE_REVIEW = "UPDATE_KNOWLEDGE_REVIEW",
  REVISE_KNOWLEDGE = "REVISE_KNOWLEDGE",
  RETIRE_KNOWLEDGE = "RETIRE_KNOWLEDGE",
  FETCH_KNOWLEDGE_ARTICLES = "FETCH_KNOWLEDGE_ARTICLES",
  FETCH_KNOWLEDGE_ARTICLES_SUCCESS = "FETCH_KNOWLEDGE_ARTICLES_SUCCESS",
  SET_KNOWLEDGEBASE_FILTER = "SET_KNOWLEDGEBASE_FILTER",
  SET_CATEGORIES_FILTER = "SET_CATEGORIES_FILTER",
  SET_SUBCATEGORIES_FILTER = "SET_SUBCATEGORIES_FILTER",
  SET_TAGS_FILTER = "SET_TAGS_FILTER",
  CLEAR_ALL_FILTER = "CLEAR_ALL_FILTER",
  FETCH_KNOWLEDGE_DETAILS = "FETCH_KNOWLEDGE_DETAILS",
}

export default Types;
