import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { clearAllFilter, fetchKnowledgeArticles } from "../../../../../redux/knowledge/actions";
import { FetchKnowledgeArticleListParams } from "../../../../../redux/knowledge/types";
import { strings } from "../../../../../theme";
import { KnowledgeListTemplate } from "../../../../components";
import { useKnowledgeList } from "../hooks/useKnowledgeApprovals";

export const MyKnowledgeArticles = () => {
  const dispatch = useAppDispatch();

  const myArticles = useAppSelector((state) => state.KnowledgeReducer.list);
  const loading = useAppSelector((state) => state.KnowledgeReducer.loading);
  const userId = useAppSelector((state) => state.UserReducer.id);
  const searchQuery = useAppSelector((state) => state.KnowledgeReducer.list.searchQuery);

  const {
    headerTitle,
    handleOnPageClickMyArticles,
    getStatus,
    onChangeSearch,
    search,
    handleClearSearch,
    handleReloadMyArticles,
  } = useKnowledgeList({ searchQuery });

  useEffect(() => {
    if (myArticles.type !== "my") {
      const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, createdBy: userId };
      dispatch(fetchKnowledgeArticles({ params, type: "my" }));
      dispatch(clearAllFilter());
    }
  }, [dispatch, userId, myArticles.type]);

  return (
    <>
      <KnowledgeListTemplate
        loading={loading}
        type='my'
        headerTitle={headerTitle("my")}
        bannerTitle={strings.epKnowledge.myArticles}
        data={myArticles.data}
        metaData={myArticles.metaData}
        filters={myArticles?.filters}
        handlePageChange={handleOnPageClickMyArticles}
        getStatus={getStatus}
        searchValue={search}
        onChangeSearch={onChangeSearch}
        handleClearSearch={handleClearSearch}
        handleReload={handleReloadMyArticles}
      />
    </>
  );
};
