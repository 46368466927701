import React from "react";

import { strings } from "../../../theme";
import { ColumnsType } from "../../../types";

import { TableContainer, TableHead, TableRow, Title, TableData, EmptyList } from "./styles";

interface TableType {
  data: object[];
  columns: ColumnsType[];
  page: number;
  pageSize: number;
  onClickTableRow?: (id: string) => void;
  emptyListMessage?: string;
  horizontalScroll?: boolean;
}

export const Table = ({
  data,
  columns,
  page,
  pageSize,
  onClickTableRow,
  emptyListMessage = strings.common.noRecordsFound,
  horizontalScroll = false,
}: TableType) => {
  const onClickRow = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClickTableRow) {
      const index = e.currentTarget.getAttribute("data-index");
      const id = data[index]?._id;
      onClickTableRow(id);
    }
  };

  return (
    <TableContainer horizontalScroll={horizontalScroll}>
      <TableHead>
        {columns.map((column, columnIndex) => {
          return (
            <TableRow
              width={column.width}
              key={columnIndex}
              center={column.center}
              background={column.background}
            >
              <Title className='table-header-title'>{column.title}</Title>
            </TableRow>
          );
        })}
      </TableHead>
      <TableData>
        {data?.length ? (
          data.map((dataItem, dataIndex) => {
            const serial = page === 1 ? dataIndex + 1 : (page - 1) * pageSize + dataIndex + 1;
            return (
              <div className='data-row' key={dataIndex} data-index={dataIndex} onClick={onClickRow}>
                {columns.map((column, columnIndex) => {
                  const title = column.key === "serial" ? serial : dataItem[column.key];
                  const className = dataItem[column.className];
                  return (
                    <TableRow
                      className={className}
                      width={column.width}
                      key={columnIndex}
                      center={column.center}
                      background={column.background}
                    >
                      {column.render ? (
                        column.render(dataItem)
                      ) : (
                        <Title className='data-title'>{title}</Title>
                      )}
                    </TableRow>
                  );
                })}
              </div>
            );
          })
        ) : (
          <EmptyList>{emptyListMessage}</EmptyList>
        )}
      </TableData>
    </TableContainer>
  );
};
