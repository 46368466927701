import React from "react";

import { Layout } from "../../../presentationals";
import { SPSSalesProfileList } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSSalesProfileList />} admin={true} layout={"2"} />;
};

export const APSPSSalesProfileListing = React.memo(Route);
