import React from "react";

import { Layout } from "../../../presentationals";
import { LeadListsController } from "../../../presentationals/screens/admin/LeadLists/LeadListsController";

const Route = () => {
  return <Layout activity={<LeadListsController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APLeadListsEdit = React.memo(Route);
