import React, { useEffect, useState } from "react";

import { MdEdit, MdLockOutline } from "react-icons/md";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Document from "../../components/Document/Document";
import Header from "../../components/Header";
import ModalComponent from "../../components/Modal/Modal";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import config from "../../config";
import { Header as MainHeader } from "../../presentationals/components";
import { fetchAllKnowledge } from "../../redux/knowledge/actions";
import {
  MomentDate,
  useTitle,
  Sort,
  getPrefixDigit,
  checkRevisePermission,
} from "../../services/methods";
import { strings } from "../../theme";

import styles from "./KnowledgeDetails.styles";

const KnowledgeDetails = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();

  useTitle("Knowledge Details");
  const [knowledge, setKnowledge] = useState({});
  const [documentId, setDocumentId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [isDocVisible, setIsDocVisible] = useState(false);
  useEffect(() => {
    // props.fetchAllKnowledge({});
    const knowledge = props.data.find((item) => item._id === params.id);
    setKnowledge(knowledge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, props.data]);

  const getColor = (status) => {
    switch (status) {
      case "drafted":
        return "#AFB4FF";
      case "inreview":
        return "#F7B847";
      case "published":
        return "#41988E";
      default:
        return "#F7B847";
    }
  };

  const getStatus = (status) => {
    if (status === "inreview") {
      return "IN REVIEW";
    }
    return status.toUpperCase();
  };

  const handleOnPressAttachment = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const name = e.currentTarget.getAttribute("data-name");
    setDocumentId(id);
    setDocumentName(name);
    setIsDocVisible(true);
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.knowledgeDetails} />
      <Header
        title='Knowledge'
        buttons={[
          {
            title: "Add",
            onPress: {
              event: navigate,
              params: "/ep/knowledge/add",
            },
            visible: props?.permissions?.knowledgebase_add,
          },
          {
            title: "Edit",
            onPress: {
              event: navigate,
              params: `/ep/knowledge/${knowledge?._id}/edit`,
            },
            visible: knowledge?.status === "drafted",
          },
        ]}
        onSync={{
          event: props.fetchAllKnowledge,
          params: {},
        }}
      />
      {knowledge?.body && (
        <>
          <StyledTable width={"100%"} onClick={() => console.log(knowledge)}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Knowledgebase</th>
                <th>Category</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${config.prefix.knowledge} ${getPrefixDigit(knowledge?.serial, 5)}`}</td>
                <td>{knowledge.name}</td>
                <td>{knowledge?.knowledgebase?.title}</td>
                <td>{knowledge?.category?.name}</td>
                <td>
                  {knowledge?.createdBy?.name}
                  <br />
                  <span>
                    {MomentDate({
                      date: knowledge?.createdAt,
                      format: "YYYY-MM-DD",
                    })}
                  </span>
                </td>
                <td>
                  {knowledge?.updatedBy?.name}
                  <br />
                  <span>
                    {MomentDate({
                      date: knowledge?.updatedAt,
                      format: "YYYY-MM-DD",
                    })}
                  </span>
                </td>
                <td>
                  <styles.Status color={getColor(knowledge?.status)}>
                    {getStatus(knowledge?.status)}
                  </styles.Status>
                </td>
              </tr>
            </tbody>
          </StyledTable>
          {knowledge?.revisedKnowledge && knowledge?.status === "published" && (
            <styles.Status color={getColor()}>Revision In Progress</styles.Status>
          )}
          <styles.ItemTitle>Version</styles.ItemTitle>
          <styles.Summary>{`V 1.${knowledge.revisionHistory.length} - ${
            config.prefix.knowledge
          } ${getPrefixDigit(knowledge?.serial, 5)}`}</styles.Summary>
          <styles.ItemTitle>Summary</styles.ItemTitle>
          <styles.Summary>{knowledge.summary}</styles.Summary>
          {knowledge?.subcategory && (
            <>
              <styles.ItemTitle>Sub Category</styles.ItemTitle>
              <styles.Summary>{knowledge.subcategory.name}</styles.Summary>
            </>
          )}
          <styles.ItemTitle>Tags</styles.ItemTitle>
          <styles.DataContainer>
            {Array.isArray(knowledge?.modules) &&
              knowledge?.modules.map((item) => {
                return (
                  <styles.DataRow>
                    <h2>{item.name}</h2>
                  </styles.DataRow>
                );
              })}
          </styles.DataContainer>
          <styles.ItemTitle>Body</styles.ItemTitle>
          <styles.Body>
            <div dangerouslySetInnerHTML={{ __html: knowledge.body }} />
          </styles.Body>

          {knowledge.attachments.length > 0 && (
            <>
              <styles.ItemTitle>Attachments</styles.ItemTitle>
              {knowledge.attachments.map((item, index) => {
                return (
                  <styles.DataRow
                    onClick={handleOnPressAttachment}
                    data-id={item.id}
                    data-name={item.name}
                  >
                    <h2>{item.name}</h2>
                  </styles.DataRow>
                );
              })}
            </>
          )}
        </>
      )}

      <ModalComponent isOpen={isDocVisible} closeModal={() => setIsDocVisible(false)}>
        <Document id={documentId} name={documentName} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.KnowledgeReducer.filters,
  data: state.KnowledgeReducer.list,
  permissions: state.UserReducer.permissions,
  userId: state.UserReducer.id,
});

const mapDispatchToProps = {
  fetchAllKnowledge,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeDetails);
