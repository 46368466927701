import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  KnowledgebaseAdd,
  KnowledgebaseUpdate,
  KnowledgebaseFetch,
  KnowledgebaseFetchEmployee,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchKnowledgebaseSuccess, fetchKnowledgebaseSelfSuccess } from "./actions";
import Types from "./types";

function* createWorker(action) {
  try {
    const options = {
      method: "POST",
      url: KnowledgebaseAdd,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: KnowledgebaseUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getAllWorker(action) {
  try {
    const options = {
      method: "GET",
      url: action.payload.isEmployee ? KnowledgebaseFetchEmployee : KnowledgebaseFetch,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: action.payload.params,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      if (action.payload.isEmployee) {
        yield put(fetchKnowledgebaseSelfSuccess(response.data.data));
      } else {
        const payload = {
          data: response.data.data,
          metaData: response.data.metaData,
        };
        yield put(fetchKnowledgebaseSuccess(payload));
      }
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      const error = {
        error: response.data.error,
        removedRecords: response.data.removedRecords,
      };
      action?.payload?.onError && action.payload.onError(error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* Saga() {
  yield takeLatest(Types.KNOWLEDGEBASE_ADD, createWorker);
  yield takeLatest(Types.KNOWLEDGEBASE_UPDATE, updateWorker);
  yield takeEvery(Types.KNOWLEDGEBASE_FETCH, getAllWorker);
}
