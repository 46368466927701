import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { DesignationsListProps } from "../../../../types/models/Designations";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useDesignationsList } from "./hooks";

export const DesignationsList = ({ relatedList }: DesignationsListProps) => {
  const designations = useAppSelector((state) => state.DesignationsReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const { list, metaData } = designations;
  const {
    listActionOptions,
    designationsFilterFields,
    onClickDesigantions,
    fetchDesignations,
    onSubmitDeleteRecord,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    designationsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    columns,
    DesignationsConfigTableFields,
  } = useDesignationsList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchDesignations({ page: 1 });
  }, []);

  return (
    <TableListV3
      exportAllRecords={exportAllRecords}
      exportSelectedRecords={exportSelectedRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleListActions={handleListActions}
      headerButtons={designationsHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.designations}
      handleTableConfigs={handleTableConfigs}
      filters={designationsFilterFields}
      selectedConfigFields={DesignationsConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickDesigantions}
      headerTitle={strings.headerTitle.designations}
      list={list}
      columns={columns}
      metaData={metaData}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.designations_delete}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apDesignations.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
