import Types from "./types";

export const createTicket = ({ data, onSuccess, onError }) => ({
  type: Types.TICKETS_CREATE,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateTicket = ({ id, data, onSuccess, onError }) => ({
  type: Types.TICKETS_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const createComment = ({ id, status, comment, onSuccess, onError }) => ({
  type: Types.TICKETS_COMMENT,
  payload: {
    id: id,
    status: status,
    comment: comment,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const getAllTickets = ({ admin = false, onSuccess, onError }) => ({
  type: Types.TICKETS_FETCH_ALL,
  payload: {
    admin: admin,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const getAllTicketsEmployeesSuccess = (list) => ({
  type: Types.TICKETS_FETCH_ALL_EMPLOYEES_SUCCESS,
  payload: list,
});

export const getAllTicketsAdminSuccess = (list) => ({
  type: Types.TICKETS_FETCH_ALL_ADMIN_SUCCESS,
  payload: list,
});

export const filterTickets = ({ field, value }) => ({
  type: Types.TICKETS_UPDATE_FILTER,
  payload: {
    field: field,
    value: value,
  },
});
