import React from "react";

import { Layout } from "../../../presentationals";
import { MyKnowledgeArticles } from "../../../presentationals/screens";
import { strings } from "../../../theme";

export const EPMyKnowledgeArticles = () => {
  return (
    <Layout
      activity={<MyKnowledgeArticles />}
      title={strings.headerTitle.knowledge}
      employee={true}
      layout={"2"}
      noPadding={true}
    />
  );
};
