import Types from "./actionType";
import {
  FetchServiceRequestsPayload,
  CreateServiceRequests,
  CreateServiceRequestsPayload,
  ServiceRequestsList,
  FetchServiceRequests,
  FetchServiceRequestsError,
  FetchServiceRequestsSuccess,
  UpdateServiceRequests,
  UpdateServiceRequestsPayload,
  ServiceRequestsListWithMetaData,
  FetchServiceRequestsByIdPayload,
  FetchServiceRequestsById,
} from "./types";

export const fetchServiceRequestsList = ({
  params,
  onSuccess,
  onError,
}: FetchServiceRequestsPayload): FetchServiceRequests => ({
  type: Types.FETCH_SERVICE_REQUESTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchServiceRequestsById = ({
  associatedTo,
  id,
  onSuccess,
  onError,
}: FetchServiceRequestsByIdPayload): FetchServiceRequestsById => ({
  type: Types.FETCH_SERVICE_REQUESTS_BY_ID,
  payload: {
    associatedTo,
    id,
    onSuccess,
    onError,
  },
});

export const fetchServiceRequestsListSuccess = (
  data: ServiceRequestsListWithMetaData,
): FetchServiceRequestsSuccess => ({
  type: Types.SUCCESS_FETCH_SERVICE_REQUESTS,
  payload: {
    data,
  },
});

export const fetchServiceRequestsError = (error?: string): FetchServiceRequestsError => ({
  type: Types.ERROR_FETCH_SERVICE_REQUESTS,
  payload: { error },
});

export const createServiceRequests = ({
  data,
  onSuccess,
  onError,
}: CreateServiceRequestsPayload): CreateServiceRequests => ({
  type: Types.CREATE_SERVICE_REQUESTS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateServiceRequests = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateServiceRequestsPayload): UpdateServiceRequests => ({
  type: Types.UPDATE_SERVICE_REQUESTS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
