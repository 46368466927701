import React, { useState, useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  clearAllFilter,
  setCategoriesFilter,
  setKnowledgeFilter,
  setSubcategoriesFilter,
  setTagsFilter,
} from "../../../../../redux/knowledge/actions";
import { FilterKnowledgebase, KnowledgeFilters } from "../../../../../redux/knowledge/types";
import { KnowledgeCurrentFilters } from "../../../../../redux/knowledge/types";

import { KnowledgeArticlesList } from "./../../../../../redux/knowledge/types";

interface KnowledgeFiltersHook {
  searchFilter: string;
  isRejected: (knowledge: object) => void;
  current: number;
  selectedKnowledgebase: string;
  selectedCategories: string[];
  selectedSubcategories: string[];
  selectedTags: string[];
  filterModel: string;
  filterModelKey: string;
  onSearch: () => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
  onChangeFilterSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearFilterSearch: () => void;
  clearSearch: () => void;
  handleAccordion: (e: React.MouseEvent<HTMLDivElement>) => void;
  closeFilterModel: () => void;
  openFilterModel: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleKnowledgebaseFilter: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleModelKnowledgebaseFilter: (e: React.MouseEvent<HTMLDivElement>) => void;
  applyModelFilters: () => void;
  onPageChange: (page) => void;
  handleArticleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isFiltersApplied: boolean;
  handleClearAllKnowledgeFilters: () => void;
  setSelectedKnowledgebase: React.Dispatch<React.SetStateAction<string>>;
  viewAll: boolean;
  handleViewAll: () => void;
  showCategoryGrid: boolean;
  handleShowCategoryGrid: () => void;
}

interface Props {
  handlePageChange: (page: number, filters?: KnowledgeCurrentFilters) => void;
  handleClearSearch: () => void;
  handleReload: () => void;
  data: KnowledgeArticlesList[];
  type: string;
}

export const useKnowledgeFilters = ({
  handlePageChange,
  handleClearSearch,
  handleReload,
  data,
  type,
}: Props): KnowledgeFiltersHook => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentFilters = useAppSelector((state) => state.KnowledgeReducer.currentFilters);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [current, setCurrent] = useState<number>(-1);
  const [selectedKnowledgebase, setSelectedKnowledgebase] = useState<string>(
    currentFilters?.knowledgebase,
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [filterModel, setFilterModel] = useState<string>("");
  const [filterModelKey, setFilterModelKey] = useState<string>("");
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [showCategoryGrid, setShowCategoryGrid] = useState<boolean>(false);

  const onSearch = useCallback(() => {
    handlePageChange(1);
    if (showCategoryGrid) {
      setShowCategoryGrid(false);
    }
  }, [handlePageChange, showCategoryGrid]);

  const handleViewAll = useCallback(() => {
    setViewAll((viewAll) => !viewAll);
  }, []);

  const handleShowCategoryGrid = useCallback(() => {
    setShowCategoryGrid((value) => !value);
  }, []);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && onSearch) {
        onSearch();
      }
    },
    [onSearch],
  );

  const onChangeFilterSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchFilter(value);
  }, []);

  const clearFilterSearch = useCallback(() => {
    setSearchFilter("");
  }, []);
  const isRejected = useCallback((knowledge) => {
    if (knowledge?.approvals?.length > 0) {
      for (let i = 0; i < knowledge.approvals.length; i++) {
        if (knowledge.approvals[i].status === "rejected") {
          return true;
        }
      }
    }
    return false;
  }, []);
  const clearSearch = useCallback(() => {
    handleClearSearch();
    handleReload();
  }, [handleClearSearch, handleReload]);

  const handleAccordion = (e: React.MouseEvent<HTMLDivElement>) => {
    const index = e.currentTarget.getAttribute("data-index");
    clearFilterSearch();
    if (current === Number(index)) {
      setCurrent(-1);
      return;
    }
    setCurrent(Number(index));
  };

  const closeFilterModel = useCallback(() => {
    switch (filterModelKey) {
      case "knowledgebases":
        setSelectedKnowledgebase(currentFilters.knowledgebase);
        break;
      case "categories":
        setSelectedCategories(currentFilters.categories);
        break;
      case "subcategories":
        setSelectedSubcategories(currentFilters.subcategories);
        break;
      case "tags":
        setSelectedTags(currentFilters.tags);
        break;
    }
    setFilterModel("");
    setFilterModelKey("");
  }, [
    currentFilters.categories,
    currentFilters.knowledgebase,
    currentFilters.subcategories,
    currentFilters.tags,
    filterModelKey,
  ]);

  const openFilterModel = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const title = e.currentTarget.getAttribute("data-title");
      const key = e.currentTarget.getAttribute("data-key");
      console.log(key);
      switch (key) {
        case "knowledgebases":
          setSelectedKnowledgebase(currentFilters.knowledgebase);
          break;
        case "categories":
          break;
        case "subcategories":
          break;
        case "tags":
          break;
      }
      setFilterModel(title || "");
      setFilterModelKey(key || "");
    },
    [currentFilters.knowledgebase],
  );

  const handleKnowledgebaseFilter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const type = e.currentTarget.getAttribute("data-type") || "";
      let tempCurrentFilters = { ...currentFilters };
      switch (type) {
        case "knowledgebases":
          {
            const knowledgebaseId = e.currentTarget.getAttribute("data-id") || "";
            if (currentFilters.knowledgebase === knowledgebaseId) {
              dispatch(setKnowledgeFilter({ knowledgebase: "" }));
              tempCurrentFilters = {
                ...tempCurrentFilters,
                knowledgebase: "",
              };
            } else {
              dispatch(setKnowledgeFilter({ knowledgebase: knowledgebaseId }));
              tempCurrentFilters = {
                ...tempCurrentFilters,
                knowledgebase: knowledgebaseId,
              };
            }
          }
          if (showCategoryGrid) {
            setShowCategoryGrid(false);
          }
          break;
        case "categories":
          {
            const categoryId = e.currentTarget.getAttribute("data-id") || "";
            const currentCategories = [...currentFilters.categories];
            if (currentCategories.includes(categoryId)) {
              const index = currentCategories.findIndex((item) => item === categoryId);
              if (index >= 0) {
                currentCategories.splice(index, 1);
              }
            } else {
              currentCategories.push(categoryId);
            }
            dispatch(setCategoriesFilter({ categories: currentCategories }));
            tempCurrentFilters = {
              ...tempCurrentFilters,
              categories: currentCategories,
            };
          }
          break;
        case "subcategories":
          {
            const subcategoryId = e.currentTarget.getAttribute("data-id") || "";
            const currentSubCategories = [...currentFilters.subcategories];
            if (currentSubCategories.includes(subcategoryId)) {
              const index = currentSubCategories.findIndex((item) => item === subcategoryId);
              if (index >= 0) {
                currentSubCategories.splice(index, 1);
              }
            } else {
              currentSubCategories.push(subcategoryId);
            }
            dispatch(setSubcategoriesFilter({ subcategories: currentSubCategories }));
            tempCurrentFilters = {
              ...tempCurrentFilters,
              subcategories: currentSubCategories,
            };
          }
          break;
        case "tags":
          {
            const tagId = e.currentTarget.getAttribute("data-id") || "";
            const currentTags = [...currentFilters.tags];
            if (currentTags.includes(tagId)) {
              const index = currentTags.findIndex((item) => item === tagId);
              if (index >= 0) {
                currentTags.splice(index, 1);
              }
            } else {
              currentTags.push(tagId);
            }
            dispatch(setTagsFilter({ tags: currentTags }));
            tempCurrentFilters = {
              ...tempCurrentFilters,
              tags: currentTags,
            };
          }
          break;
      }

      handlePageChange(1, tempCurrentFilters);
    },
    [currentFilters, dispatch, handlePageChange, showCategoryGrid],
  );

  const handleModelKnowledgebaseFilter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const type = e.currentTarget.getAttribute("data-type") || "";
      switch (type) {
        case "knowledgebases":
          {
            const knowledgebaseId = e.currentTarget.getAttribute("data-id") || "";
            if (selectedKnowledgebase === knowledgebaseId) {
              setSelectedKnowledgebase("");
            } else {
              setSelectedKnowledgebase(knowledgebaseId);
            }
          }
          break;
        case "categories":
          {
            const categoryId = e.currentTarget.getAttribute("data-id") || "";
            const currentCategories = [...selectedCategories];
            if (currentCategories.includes(categoryId)) {
              const index = currentCategories.findIndex((item) => item === categoryId);
              if (index >= 0) {
                currentCategories.splice(index, 1);
              }
            } else {
              currentCategories.push(categoryId);
            }
            setSelectedCategories(currentCategories);
          }
          break;
        case "subcategories":
          {
            const subcategoryId = e.currentTarget.getAttribute("data-id") || "";
            const currentSubCategories = [...selectedSubcategories];
            if (currentSubCategories.includes(subcategoryId)) {
              const index = currentSubCategories.findIndex((item) => item === subcategoryId);
              if (index >= 0) {
                currentSubCategories.splice(index, 1);
              }
            } else {
              currentSubCategories.push(subcategoryId);
            }
            setSelectedSubcategories(currentSubCategories);
          }
          break;
        case "tags":
          {
            const tagId = e.currentTarget.getAttribute("data-id") || "";
            const currentTags = [...selectedTags];
            if (currentTags.includes(tagId)) {
              const index = currentTags.findIndex((item) => item === tagId);
              if (index >= 0) {
                currentTags.splice(index, 1);
              }
            } else {
              currentTags.push(tagId);
            }
            setSelectedTags(currentTags);
          }
          break;
      }
    },
    [selectedCategories, selectedKnowledgebase, selectedSubcategories, selectedTags],
  );

  const applyModelFilters = useCallback(() => {
    dispatch(setKnowledgeFilter({ knowledgebase: selectedKnowledgebase }));
    dispatch(setCategoriesFilter({ categories: selectedCategories }));
    dispatch(setSubcategoriesFilter({ subcategories: selectedSubcategories }));
    dispatch(setTagsFilter({ tags: selectedTags }));
    let tempCurrentFilters = { ...currentFilters };
    tempCurrentFilters = {
      ...tempCurrentFilters,
      knowledgebase: selectedKnowledgebase,
      categories: selectedCategories,
      subcategories: selectedSubcategories,
      tags: selectedTags,
    };
    setFilterModel("");
    setFilterModelKey("");
    handlePageChange(1, tempCurrentFilters);
    if (showCategoryGrid) {
      setShowCategoryGrid(false);
    }
  }, [
    dispatch,
    selectedKnowledgebase,
    selectedCategories,
    selectedSubcategories,
    selectedTags,
    currentFilters,
    handlePageChange,
    showCategoryGrid,
  ]);

  const onPageChange = useCallback(
    (page) => {
      const tempCurrentFilters = { ...currentFilters };
      handlePageChange(parseInt(page), tempCurrentFilters);
    },
    [handlePageChange, currentFilters],
  );

  const handleArticleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const route = `/ep/knowledge/${data[index]._id}/details`;
      navigate(route, { state: type });
    },
    [navigate, data, type],
  );

  const isFiltersApplied = useMemo(() => {
    const { knowledgebase, categories, subcategories, tags } = currentFilters;
    if (knowledgebase || categories.length || subcategories.length || tags.length) {
      return true;
    }

    return false;
  }, [currentFilters]);

  const handleClearAllKnowledgeFilters = useCallback(() => {
    dispatch(clearAllFilter());
    if (showCategoryGrid) {
      setShowCategoryGrid(false);
    }
    handleReload();
  }, [dispatch, handleReload, showCategoryGrid]);

  return {
    searchFilter,
    onSearch,
    onKeyDown,
    clearSearch,
    handleAccordion,
    current,
    clearFilterSearch,
    onChangeFilterSearch,
    selectedKnowledgebase,
    selectedCategories,
    selectedSubcategories,
    selectedTags,
    filterModel,
    closeFilterModel,
    openFilterModel,
    filterModelKey,
    handleKnowledgebaseFilter,
    handleModelKnowledgebaseFilter,
    applyModelFilters,
    onPageChange,
    isRejected,
    handleArticleClick,
    isFiltersApplied,
    handleClearAllKnowledgeFilters,
    setSelectedKnowledgebase,
    viewAll,
    handleViewAll,
    showCategoryGrid,
    handleShowCategoryGrid,
  };
};
