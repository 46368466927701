import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyStudentProfileController } from "../../../presentationals/screens";

export const APAcademyStudentProfileAdd = () => {
  return (
    <Layout activity={<AcademyStudentProfileController type={"add"} />} admin={true} layout={"2"} />
  );
};
