import Types from "./actionType";
import {
  FetchSPSAccountContactPayload,
  CreateSPSAccountContact,
  CreateSPSAccountContactPayload,
  FetchSPSAccountContact,
  FetchSPSAccountContactError,
  FetchSPSAccountContactSuccess,
  UpdateSPSAccountContact,
  UpdateSPSAccountContactPayload,
  SPSAccountContactListWithMetaData,
  FetchSPSAccountContactByIdPayload,
  FetchSPSAccountContactById,
  DeleteSPSAccountContactPayload,
  DeleteSPSAccountContact,
} from "./types";

export const fetchSPSAccountContactList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSAccountContactPayload): FetchSPSAccountContact => ({
  type: Types.FETCH_SPS_ACCOUNT_CONTACT,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSAccountContactById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSAccountContactByIdPayload): FetchSPSAccountContactById => ({
  type: Types.FETCH_SPS_ACCOUNT_CONTACT_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSAccountContactListSuccess = (
  data: SPSAccountContactListWithMetaData,
): FetchSPSAccountContactSuccess => ({
  type: Types.SUCCESS_FETCH_SPS_ACCOUNT_CONTACT,
  payload: {
    data,
  },
});

export const fetchSPSAccountContactError = (error?: string): FetchSPSAccountContactError => ({
  type: Types.ERROR_FETCH_SPS_ACCOUNT_CONTACT,
  payload: { error },
});

export const createSPSAccountContact = ({
  data,
  onSuccess,
  onError,
}: CreateSPSAccountContactPayload): CreateSPSAccountContact => ({
  type: Types.CREATE_SPS_ACCOUNT_CONTACT,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSAccountContact = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSAccountContactPayload): UpdateSPSAccountContact => ({
  type: Types.UPDATE_SPS_ACCOUNT_CONTACT,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSAccountContact = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSAccountContactPayload): DeleteSPSAccountContact => ({
  type: Types.DELETE_SPS_ACCOUNT_CONTACT,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
