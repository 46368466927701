import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { ProjectHistoryListProps } from "../../../../types/models/ProjectHistory";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useProjectHistoryList } from "./hooks";

export const ProjectHistoryList = ({ relatedList }: ProjectHistoryListProps) => {
  const projectHistory = useAppSelector((state) => state.ProjectHistoryReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const { list, metaData } = projectHistory;
  const {
    listActionOptions,
    projectHistoryFilterFields,
    onClickProjectHistory,
    fetchProjectHistory,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    projectHistoryHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    columns,
    ProjectHistoryConfigTableFields,
  } = useProjectHistoryList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchProjectHistory({});
    // Do not include fetchProjectHistory to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportAllRecords={exportAllRecords}
      exportSelectedRecords={exportSelectedRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleListActions={handleListActions}
      headerButtons={projectHistoryHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.projectHistory}
      handleTableConfigs={handleTableConfigs}
      filters={projectHistoryFilterFields}
      selectedConfigFields={ProjectHistoryConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickProjectHistory}
      headerTitle={strings.headerTitle.projectHistory}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apProjectHistory.search}
      // onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
