import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  OrganisationsAdd,
  OrganisationsUpdate,
  OrganisationsFetch,
  getToken,
  getOrganisation,
  OGOrganisationsFetch,
  OGOrganisationsUpdate,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { organisationsFetchSuccess } from "./actions";
import Types from "./types";

function* organisationsAddWorker(action: any) {
  try {
    const options = {
      method: "POST",
      url: OrganisationsAdd(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e: any) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* organisationsUpdateWorker(action: any) {
  try {
    const options = {
      method: "PUT",
      url: action.payload.isOG
        ? OGOrganisationsUpdate(action.payload.id)
        : OrganisationsUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e: any) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* organisationsFetchWorker(action: any) {
  try {
    const options = {
      method: "GET",
      url: action.payload.isOG ? OGOrganisationsFetch() : OrganisationsFetch(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(organisationsFetchSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e: any) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* OrganisationsSaga() {
  yield takeLatest(Types.ORGANISATIONS_FETCH, organisationsFetchWorker);
  yield takeLatest(Types.ORGANISATIONS_ADD, organisationsAddWorker);
  yield takeLatest(Types.ORGANISATIONS_UPDATE, organisationsUpdateWorker);
}
