import React from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  closeModal: () => void;
}

export const Popup = (props: Props) => {
  return (
    <Modal
      classNames={{
        modal: "model-root",
        modalContainer: "model-container",
      }}
      open={props.isOpen}
      onClose={props.closeModal}
      center
      showCloseIcon={false}
    >
      {props.children}
    </Modal>
  );
};
