import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyCandidateEnrollmentsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    studentName: {
      title: "Learner's Name",
      sortSupported: true,
      searchSupported: true,
      key: "studentName",
    },
    studentOfficialEmail: {
      title: "Official Email",
      sortSupported: true,
      key: "studentOfficialEmail",
    },
    careerPath: {
      title: "Career Path",
      sortSupported: true,
      key: "careerPath",
      render: (item) => {
        return <text className='title'>{item.careerPath?.value || "-"}</text>;
      },
    },
    enrolledCourse: {
      title: "Enrolled Course",
      sortSupported: true,

      key: "enrolledCourse",
      render: (item) => {
        return <text className='title'>{item.enrolledCourse?.name || "-"}</text>;
      },
    },
    studentID: {
      title: "Learner's ID",
      sortSupported: true,
      key: "studentID",
    },

    candidateLead: {
      title: "Candidate Lead",
      sortSupported: true,
      key: "candidateLead",
      render: (item) => {
        return (
          <text className='title'>{item.candidateLead?.associatedContact?.firstName || "-"}</text>
        );
      },
    },
    batchStartDate: {
      title: "Batch Start Date",
      sortSupported: true,
      key: "batchStartDate",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.batchStartDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    trainingDurations: {
      title: "Training Durations",
      sortSupported: true,
      key: "trainingDurations",
    },
    enrollmentStage: {
      title: "Enrollment Stage",
      sortSupported: true,
      key: "enrollmentStage",

      render: (item) => {
        return <text className='title'>{item.enrollmentStage?.value || "-"}</text>;
      },
    },
    paymentStatus: {
      title: "Payment Status",
      sortSupported: true,
      key: "paymentStatus",

      render: (item) => {
        return <text className='title'>{item.paymentStatus?.value || "-"}</text>;
      },
    },
    totalFees: {
      title: "Total Fees",
      sortSupported: true,
      key: "totalFees",
    },
    careerCounseller: {
      title: "Career Counseller",
      sortSupported: true,
      key: "careerCounseller",
      render: (item) => {
        return (
          <text className='title'>{item?.academyOfficers?.careerCounseller?.name || "-"}</text>
        );
      },
    },
    enrollmentSpecialist: {
      title: "Enrollment Specialist",
      sortSupported: true,
      key: "enrollmentSpecialist",
      render: (item) => {
        return (
          <text className='title'>{item?.academyOfficers?.enrollmentSpecialist?.name || "-"}</text>
        );
      },
    },
    trainingCordinator: {
      title: "Training Cordinator",
      sortSupported: true,
      key: "trainingCordinator",
      render: (item) => {
        return (
          <text className='title'>{item?.academyOfficers?.trainingCordinator?.name || "-"}</text>
        );
      },
    },
    corporateRelationManager: {
      title: "Corporate RelationManager",
      sortSupported: true,
      key: "corporateRelationManager",
      render: (item) => {
        return (
          <text className='title'>
            {item?.academyOfficers?.corporateRelationManager?.name || "-"}
          </text>
        );
      },
    },
    currency: {
      title: "currency",
      sortSupported: true,
      key: "currency",
      render: (item) => {
        return <text className='title'>{item.currency?.name || "-"}</text>;
      },
    },
    paymentTerms: {
      title: "Payment Terms",
      sortSupported: true,
      key: "paymentTerms",
      render: (item) => {
        return <text className='title'>{item.paymentTerms?.value || "-"}</text>;
      },
    },
    NBFCcharges: {
      title: "NBFC Charges",
      sortSupported: true,
      searchSupported: true,
      key: "NBFCcharges",
    },
    nextPaymentDueDate: {
      title: "nextPaymentDueDate",
      sortSupported: true,
      key: "nextPaymentDueDate",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.nextPaymentDueDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    anyDiscountsGiven: {
      title: "anyDiscountsGiven",
      sortSupported: true,
      key: "anyDiscountsGiven",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
