import styled from "styled-components";

import { fonts, colors } from "../../services/theme";

const StyledTable = styled.table`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  caption-side: top;
  border-collapse: collapse;

  th {
    padding: 8px 12px;
    font-family: ${fonts.font1};
    font-size: 1.2rem;
    font-weight: 600;
  }

  td {
    padding: 8px 12px;
    font-family: ${fonts.font1};
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-weight: 400;
    color: #666666;
  }

  tbody tr {
    :nth-of-type(odd) {
    }
    :hover {
      background-color: white;
    }
  }
  thead > tr {
    background-color: #6c7ae0;
    color: white;
  }
`;

export default StyledTable;
