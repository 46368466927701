import Types from "./actionType";
import {
  FetchTagsPayload,
  CreateTags,
  CreateTagsPayload,
  FetchTags,
  FetchTagsError,
  FetchTagsSuccess,
  UpdateTags,
  UpdateTagsPayload,
  TagsListWithMetaData,
  FetchTagsByIdPayload,
  FetchTagsById,
  DeleteTagsPayload,
  DeleteTags,
} from "./types";

export const fetchTagsList = ({ params, onSuccess, onError }: FetchTagsPayload): FetchTags => ({
  type: Types.FETCH_TAGS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchTagsById = ({ id, onSuccess, onError }: FetchTagsByIdPayload): FetchTagsById => ({
  type: Types.FETCH_TAGS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchTagsListSuccess = (data: TagsListWithMetaData): FetchTagsSuccess => ({
  type: Types.SUCCESS_FETCH_TAGS,
  payload: {
    data,
  },
});

export const fetchTagsError = (error?: string): FetchTagsError => ({
  type: Types.ERROR_FETCH_TAGS,
  payload: { error },
});

export const createTags = ({ data, onSuccess, onError }: CreateTagsPayload): CreateTags => ({
  type: Types.CREATE_TAGS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateTags = ({ id, data, onSuccess, onError }: UpdateTagsPayload): UpdateTags => ({
  type: Types.UPDATE_TAGS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteTags = ({ id, onSuccess, onError }: DeleteTagsPayload): DeleteTags => ({
  type: Types.DELETE_TAGS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
