import styled from "styled-components";

import { fonts, colors, fs } from "../../../theme";

interface Props {
  active?: boolean;
  rows?: number;
}

export const Container = styled.div`
  width: 32.35%;
  height: 100%;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .filterRow {
    height: 57px;
  }
`;

export const NoData = styled.text<Props>`
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: bold;
  font-family: ${fonts.SourceSansPro};
  width: 100%;
  height: ${(props) => `${props.rows * 57}px`};
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  font-size: ${fs.m};
  height: 57px;
  padding-left: 8%;
  color: ${colors.common.black};
  background-color: ${colors.common.secondaryWhite};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
`;

export const ItemContainer = styled.div`
  width: 100%;
  height: 57px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 0.5px solid rgba(0, 0, 0, 0.5);
  padding-left: 8%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: linear 0.4s;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
`;

export const Title = styled.text`
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: bold;
  font-family: ${fonts.SourceSansPro};
`;

export const SubTitle = styled.text`
  font-size: ${fs.s};
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
`;

export const RightLabel = styled.text`
  font-size: ${fs.xs};
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
`;

export const FilterItem = styled.div<Props>`
  font-size: ${fs.m};
  color: ${(props) => (props.active ? colors.filter.activeTitle : colors.filter.inactivcTitle)};
  font-family: ${fonts.SourceSansPro};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  transition: linear 0.5s;
  background-color: ${(props) =>
    props.active ? colors.filter.activeBackground : colors.filter.inactiveBackground};
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  height: 10px;
  width: 10px;
  object-fit: contain;
  align-self: center;
  margin-right: 4px;
`;
