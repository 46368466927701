import Types from "./actionType";
import {
  FetchServiceNowSolutionsPayload,
  CreateServiceNowSolutions,
  CreateServiceNowSolutionsPayload,
  FetchServiceNowSolutions,
  FetchServiceNowSolutionsError,
  FetchServiceNowSolutionsSuccess,
  UpdateServiceNowSolutions,
  UpdateServiceNowSolutionsPayload,
  ServiceNowSolutionsListWithMetaData,
  FetchServiceNowSolutionsByIdPayload,
  FetchServiceNowSolutionsById,
  DeleteServiceNowSolutionsPayload,
  DeleteServiceNowSolutions,
} from "./types";

export const fetchServiceNowSolutionsList = ({
  params,
  onSuccess,
  onError,
}: FetchServiceNowSolutionsPayload): FetchServiceNowSolutions => ({
  type: Types.FETCH_SERVICE_NOW_SOLUTIONS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchServiceNowSolutionsById = ({
  id,
  onSuccess,
  onError,
}: FetchServiceNowSolutionsByIdPayload): FetchServiceNowSolutionsById => ({
  type: Types.FETCH_SERVICE_NOW_SOLUTIONS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchServiceNowSolutionsListSuccess = (
  data: ServiceNowSolutionsListWithMetaData,
): FetchServiceNowSolutionsSuccess => ({
  type: Types.SUCCESS_FETCH_SERVICE_NOW_SOLUTIONS,
  payload: {
    data,
  },
});

export const fetchServiceNowSolutionsError = (error?: string): FetchServiceNowSolutionsError => ({
  type: Types.ERROR_FETCH_SERVICE_NOW_SOLUTIONS,
  payload: { error },
});

export const createServiceNowSolutions = ({
  data,
  onSuccess,
  onError,
}: CreateServiceNowSolutionsPayload): CreateServiceNowSolutions => ({
  type: Types.CREATE_SERVICE_NOW_SOLUTIONS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateServiceNowSolutions = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateServiceNowSolutionsPayload): UpdateServiceNowSolutions => ({
  type: Types.UPDATE_SERVICE_NOW_SOLUTIONS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteServiceNowSolutions = ({
  id,
  onSuccess,
  onError,
}: DeleteServiceNowSolutionsPayload): DeleteServiceNowSolutions => ({
  type: Types.DELETE_SERVICE_NOW_SOLUTIONS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
