import { io, Socket } from "socket.io-client";

import config from "../config";
import { ApplicationActionTypes as Types } from "../redux/application/actionTypes";
import { store } from "../redux/store";

class SocketClass {
  socketInstance: Socket;
  isConnected: boolean = false;
  connectionData: { userId: string; organisationId: string };

  constructor() {
    this.socketInstance = io(config.socket);
  }

  public connect = () => {
    this.socketInstance.on("connect", () => {
      console.log("connected", this.socketInstance.id);
      this.isConnected = true;
      if (this?.connectionData?.userId) {
        this.userChanged(this.connectionData);
      }
      this.socketInstance.on("allSocketClients", (data) => {
        const uniqueUsers = [...new Map(data.map((v) => [v.id, v])).values()];
        console.log("All Socket Clients ::", uniqueUsers);
        store.dispatch({
          type: Types.UPDATE_ACTIVE_USERS,
          payload: uniqueUsers,
        });
      });
      this.socketInstance.on("disconnect", () => {
        console.log("disconnected");
        this.isConnected = false;
      });
    });
  };

  public userChanged = ({ userId, organisationId }) => {
    this.connectionData = { userId, organisationId };
    this.socketInstance.emit("updateUserData", { id: userId, organisation: organisationId });
  };

  public getAllClients = () => {
    this.socketInstance.emit("getAllSocketClients");
  };
}

export const socketInstance = new SocketClass();
