import React, { useEffect } from "react";

import { MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import { Header as MainHeader } from "../../presentationals/components";
import { getAllTickets } from "../../redux/tickets/actions";
import { MomentDate, serialPrefixTickets, useTitle } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./TicketsListing.styles";

const TicketsListing = (props) => {
  const navigate = useNavigate();
  useTitle("Tickets");

  useEffect(() => {
    props.getAllTickets({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StatusRender = (status) => {
    let output = "";
    if (props.assigned) {
      switch (status) {
        case "awaiting_reply":
          output = "Replied";
          break;
        case "replied":
          output = "Awaiting Reply";
          break;
        case "closed":
          output = "Ticket Closed";
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case "awaiting_reply":
          output = "Awaiting Reply";
          break;
        case "replied":
          output = "Replied";
          break;
        case "closed":
          output = "Ticket Closed";
          break;
        default:
          break;
      }
    }
    return output;
  };

  const renderData = () => {
    let array = [];
    if (props.all) {
      array = props.tickets;
    } else if (props.assigned) {
      array = props.tickets.filter((ticket) => ticket?.assignee?._id === props.user.id);
    } else {
      array = props.tickets.filter((ticket) => ticket?.userId?._id === props.user.id);
    }
    let serial = 0;
    return array.map((ticket) => {
      serial++;
      return (
        <tr key={ticket._id}>
          <td>{serial}</td>
          <td>{serialPrefixTickets(ticket.serial)}</td>
          <td>{ticket.subject}</td>
          <td align='center'>{StatusRender(ticket.status)}</td>
          <td align='center'>
            {MomentDate({
              date: ticket.createdAt,
              format: "YYYY-MM-DD",
            })}
          </td>
          <td align='center'>
            <Link
              to={
                props.all
                  ? `/ep/tickets/all/${ticket._id}/details`
                  : props.assigned
                  ? `/ep/tickets/assigned/${ticket._id}/details`
                  : `/ep/tickets/created/${ticket._id}/details`
              }
            >
              <MdArrowRightAlt size={20} />
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.ticketHeading} />
      <Header
        title={""}
        buttons={[
          {
            title: "Create New Request",
            onPress: {
              event: navigate,
              params: "/ep/tickets/created/add",
            },
            visible: !props.assigned,
          },
        ]}
        onSync={{
          event: props.getAllTickets,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              Sno.
            </th>
            <th align='left'>Ticket</th>
            <th align='left' width='50%'>
              Subject
            </th>
            <th>Status</th>
            <th>Created</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.UserReducer,
  tickets: state.TicketsReducer.ticketsEmployees,
});

const mapDispatchToProps = {
  getAllTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsListing);
