const environment = process.env.REACT_APP_ENV;

const data = {
  url: {
    local: "http://localhost:3001",
    dev: "https://api-dev.unitycloudbox.com",
    staging: "https://api-staging.unitycloudbox.com",
    production: "https://api.unitycloudbox.com",
  },
  socket: {
    local: "http://localhost:4001/",
    dev: "https://api-dev.unitycloudbox.com:4002/",
    staging: "https://api-staging.unitycloudbox.com:4001/",
    production: "https://api.unitycloudbox.com:4001/",
  },
};

const config = {
  sitetitle: "Unity ERP",
  url: data.url[environment],
  socket: data.socket[environment],
  environment: environment,
  prefix: {
    knowledge: "KBUN",
  },
};

export default config;
