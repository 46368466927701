import styled from "styled-components";

import { fonts, colors } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    padding-bottom: 50px;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1% 4%;
  `,
  Column: styled.div`
    flex: 1;
    font-size: 1.2rem;
  `,
  TopColumn: styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    margin-bottom: 5px;
    position: sticky;
    top: 0;
  `,
  TopColumnChild: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    text-align: center;
    font-family: ${fonts.font1};
    font-size: 13px;
  `,
  BottomColumn: styled.div`
    display: flex;
    height: 60%;
    overflow: scroll;
    flex-direction: column;
    background-color: white;
    padding: 10px;
  `,
  Title: styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    background-color: #6d8df7;
    padding: 6px;
    margin: 1vh 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ItemContainer: styled.div`
    align-self: center;
    border-radius: 4px;
    background-color: white;
    margin: 1vh 0;
    word-wrap: break-word;
    padding: 1% 4%;
  `,

  Item: styled.h2`
    font-size: 1.2rem;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  `,
  TabContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 20px;
  `,

  TabItem: styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 7px;
    /* margin: 2px; */
    background-color: ${(props) => (props.active ? "#6c7ae0" : colors.secondary)};
    font-family: ${fonts.font1};
    font-size: 1.2rem;
    color: ${(props) => (props.active ? "white" : colors.secondary_text)};
    cursor: ${(props) => (props.active ? "" : "pointer")};
    border-bottom: ${(props) => `2px solid ${props.error ? colors.error : "transparent"}`};
    border-radius: 4px;
  `,
  AttachmentsButtons: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  `,
  AttachmentIconContainer: styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    height: 6vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    border-radius: 4px;
    cursor: pointer;
  `,
  AttachmentsContainer: styled.div`
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1vh 1vw;
    margin-bottom: 1vh;
  `,
};

export default styles;
