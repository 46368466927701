import React, {
  useState,
  InputHTMLAttributes,
  CSSProperties,
  TextareaHTMLAttributes,
  useRef,
  useMemo,
} from "react";

import ReactQuill from "react-quill-with-table";
import { select } from "redux-saga/effects";
import SunEditor from "suneditor-react";

import { useAppSelector } from "../../../../redux/hooks";
import { getToken } from "../../../../services/api-end-points";

import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { Container, Wrapper, Label, Input, ErrorText, Switch, TextArea } from "./styles";

import "react-quill/dist/quill.snow.css";
import "react-quill-with-table/dist/quill.snow.css";
import "react-quill-with-table/dist/quill.bubble.css";

interface Props {
  error?: string;
  width?: string;
  placeholder: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const TextEditor: React.FC<Props> = ({
  error = "",
  width,
  onChange,
  value,
  placeholder,
  disabled = false,
}) => {
  const user = useAppSelector((state) => state.UserReducer);
  const reactQuillRef = useRef<ReactQuill>(null);
  const imageUploadHeaders = useMemo(() => {
    const headers = {
      Authorization: `${user.sessionid}:${user.token}`,
    };
    return headers;
  }, [user]);
  return (
    <Container width={width} error={error}>
      <SunEditor
        setOptions={{
          buttonList: [
            ["font", "fontSize", "formatBlock"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["align", "horizontalRule", "list", "table", "image"],
            ["fontColor", "hiliteColor", "textStyle"],
            ["outdent", "indent"],
            ["undo", "redo"],
            ["removeFormat"],
            ["link", "image"],
            ["preview", "print"],
            ["fullScreen", "showBlocks", "codeView"],
            ["paragraphStyle", "blockquote"],
          ],
          // imageUploadUrl: "http://localhost:3001/storage?acl=false",
          // imageUploadHeader: imageUploadHeaders,
        }}
        placeholder={placeholder}
        defaultValue={value}
        setContents={value}
        onChange={onChange}
        height='60vh'
      />
      <ErrorText>{error ? error : <br />}</ErrorText>
    </Container>
  );
};
