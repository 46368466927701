import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 88.88%;
  height: 43px;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: 0.3px solid rgba(0, 0, 0, 0.75);
  align-items: center;
  position: relative;
`;

export const SearchIcon = styled.img`
  right: 4%;
  height: 12px;
  width: 12px;
  object-fit: contain;
  align-self: center;
  position: absolute;
  cursor: pointer;
  /* z-index: 2; */
`;

export const InputField = styled.input`
  width: 100%;
  height: 100%;
  max-height: 92%;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding-left: 16%;
  /* z-index: 2; */
  font-size: 14px;
`;
