import React from "react";
import { useState, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  addDesignations,
  fetchControllerConfigs,
  removeAppNotification,
  updateDesignations,
} from "../../../../../redux";
import { EmployeesItem } from "../../../../../redux/employees/types";
import { EmployeeDesignations } from "../../../../../redux/employees/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  AppNotificationsType,
  EmployeeDesignations as EmployeeDesignationsForm,
} from "../../../../../types";

interface useMultiStepFormType {
  salaryModal: "add" | "edit" | "";
  handleSalaryModal: () => void;
  addSalaryModal: () => void;
  clearFormData: () => void;
  editSalaryModal: (id: string) => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDateChange: (value: Date, name: string) => void;
  formData: EmployeeDesignationsForm;
  loading: boolean;
  onSubmitAdd: () => void;
  onSubmitUpdate: () => void;
}
interface Props {
  salaryDetails: EmployeeDesignations[];
  fetchEmployees: () => void;
  employeeId: string;
}

const defaultSalaryDetails: EmployeeDesignationsForm = {
  date: {
    value: null,
    required: true,
    error: "",
  },
  designation: {
    value: "",
    required: true,
    error: "",
  },
  salaryBase: {
    value: null,
    required: true,
    error: "",
  },
  salaryBonus: {
    value: null,
    required: false,
    error: "",
  },
  salaryCurrency: {
    value: "",
    required: true,
    error: "",
  },
  salaryPayroll: {
    value: "",
    required: true,
    error: "",
  },
  currentSalary: {
    value: false,
    required: false,
    error: "",
  },
};

export const useEmployeeSalaryDetails = ({
  salaryDetails,
  fetchEmployees,
  employeeId,
}: Props): useMultiStepFormType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const dispatch = useAppDispatch();

  const [salaryModal, setSalaryModal] = useState<"add" | "edit" | "">("");
  const [currentRecordId, setCurrentRecordId] = useState<string>("");
  const [formData, setFormData] = useState<EmployeeDesignationsForm>(defaultSalaryDetails);
  const [loading, setLoading] = useState<boolean>(false);

  const clearFormData = useCallback(() => {
    const data = { ...formData };
    Object.keys(formData).map((field) => {
      let value = "";
      if (["date", "salaryBase", "salaryBonus"].includes(field)) {
        value = null;
      } else if (field === "currentSalary") {
        value = false;
      }
      data[field].value = value;
    });
    setFormData(data);
  }, [formData]);

  const handleSalaryModal = useCallback(() => {
    clearFormData();
    setSalaryModal("");
  }, [clearFormData]);

  const addSalaryModal = useCallback(() => {
    clearFormData();
    setSalaryModal("add");
  }, [clearFormData]);

  const rehydrateFormData = useCallback(
    (item?: EmployeeDesignations) => {
      const data = { ...formData };
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["designation", "salaryCurrency", "salaryPayroll"].includes(field)) {
          console.log("Test ::", field, item);
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      console.log(data);
      setFormData(data);
    },
    [formData],
  );

  const editSalaryModal = useCallback(
    (id = "") => {
      setCurrentRecordId(id);
      const item = salaryDetails.find((item) => item._id === id);
      rehydrateFormData(item);
      setSalaryModal("edit");
    },
    [salaryDetails, rehydrateFormData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onDateChange = useCallback(
    (value: Date, name: string) => {
      console.log("NAme ::", name, value);
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },

    [formData],
  );

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;
    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      date,
      designation,
      salaryBase,
      salaryBonus,
      salaryCurrency,
      salaryPayroll,
      currentSalary,
    } = formData;

    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const data: EmployeeDesignations = {
        date: date.value,
        designation: designation.value,
        salaryBase: salaryBase.value,
        salaryBonus: salaryBonus.value,
        salaryCurrency: salaryCurrency.value,
        salaryPayroll: salaryPayroll.value,
        currentSalary: currentSalary.value,
        employeeId: employeeId,
      };
      const payload = {
        data,
        onSuccess: (data: EmployeesItem) => {
          setSalaryModal("");
          fetchEmployees();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation added successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          setSalaryModal("");
        },
      };
      dispatch(addDesignations(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, employeeId, fetchEmployees]);

  const onSubmitUpdate = useCallback(() => {
    const {
      date,
      designation,
      salaryBase,
      salaryBonus,
      salaryCurrency,
      salaryPayroll,
      currentSalary,
    } = formData;
    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const payload = {
        id: currentRecordId || "",
        data: {
          date: date.value,
          designation: designation.value,
          salaryBase: salaryBase.value,
          salaryBonus: salaryBonus.value,
          salaryCurrency: salaryCurrency.value,
          salaryPayroll: salaryPayroll.value,
          currentSalary: currentSalary.value,
          employeeId: employeeId,
        },

        onSuccess: (data: EmployeesItem) => {
          setSalaryModal("");
          fetchEmployees();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateDesignations(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    currentRecordId,
    employeeId,
    fetchEmployees,
  ]);

  return {
    salaryModal,
    clearFormData,
    handleSalaryModal,
    addSalaryModal,
    editSalaryModal,
    onTextChange,
    onDateChange,
    formData,
    loading,
    onSubmitAdd,
    onSubmitUpdate,
  };
};
