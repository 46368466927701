import React from "react";

import { Layout } from "../../../presentationals";
import { VariableSetsList } from "../../../presentationals/screens/admin/VariableSets/VariableSetsList";

const Route = () => {
  return <Layout activity={<VariableSetsList />} admin={true} layout={"2"} />;
};

export const APVariableSetsList = React.memo(Route);
