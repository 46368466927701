const Types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SSO: "USER_LOGIN_SSO",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",

  USER_RESET: "USER_RESET",
  USER_RESET_REQUEST: "USER_RESET_REQUEST",

  USER_PROFILE: "USER_PROFILE",
  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",

  USER_PROFILE_UPDATE: "USER_PROFILE_UPDATE",
  USER_UPDATE_TABLE_CONFIGS: "USER_UPDATE_TABLE_CONFIGS",
  USER_UPDATE_ORGANISATION: "USER_UPDATE_ORGANISATION",
  USER_UPDATE_ORGANISATION_SUCCESS: "USER_UPDATE_ORGANISATION_SUCCESS",
};

export default Types;
