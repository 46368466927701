import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues, getAttributValuesBynameId } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads/AcademyCandidateLeadsList";
import { AcademyCoursesList } from "../AcademyCourses";

import { useAcademyCandidatesController } from "./hooks";

const { graduationYearTooltip } = strings.apAcademyCandidateLeads;

export const AcademyCandidatesController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCandidates,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    formSteps,
    formTitle,
    formRelatedList,
    headerButtons,
  } = useAcademyCandidatesController({ type, id: params.id, attachmentsPickerRef });

  const currentAcademyCandidates = data;

  const {
    workLogs,
    attachments,
    admissionCounsellor,
    firstName,
    email,
    phone,
    status,
    country,
    source,
    city,
    state,
    lastName,
    stage,
    course,
    dob,
    otherSource,
    graduationYear,
    graduationDegree,
    otherDegree,
    graduationMajor,
    linkedInURL,
  } = formData;

  const { academyCoursesOptions, countryOptions, employeesOptions } = useControllerConfigs();

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyCandidates();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <TextInput
          label='Full Name'
          type={"text"}
          width='100%'
          inputProps={{
            disabled: true,
            name: "fullName",
            value: `${firstName.value} ${lastName.value}`,
          }}
          textAreaProps={{}}
        />
        <Row>
          <TextInput
            label='First Name'
            type={"text"}
            width='48%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("academyCandidateStatus")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Last Name'
            type={"text"}
            width='48%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
            textAreaProps={{}}
          />

          <Select
            width={"48%"}
            name={"stage"}
            label={"Stage"}
            options={getAttributValues("academyCandidateStages")}
            value={stage.value}
            onChange={onTextChange}
            mandatory={stage.required}
            error={stage.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
            textAreaProps={{}}
          />

          <Select
            width={"48%"}
            name={"course"}
            label={"Courses Interested"}
            options={academyCoursesOptions}
            value={course.value}
            onChange={onTextChange}
            mandatory={course.required}
            error={course.error}
          />
        </Row>
        <Row>
          <TextInput
            label='DOB'
            type={"date"}
            width='48%'
            error={dob.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dob",
              value: dob.value,
              required: dob.required,
              maxDate: new Date(),
            }}
            textAreaProps={{}}
          />

          <Select
            width={"48%"}
            name={"source"}
            label={"Source"}
            options={getAttributValues("academyCandidateSource")}
            value={source.value}
            onChange={onTextChange}
            mandatory={source.required}
            error={source.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        {getAttributValuesBynameId("academyCandidateSource", source.value)?.toLowerCase() ===
          "other" && (
          <TextInput
            label={"Other Source"}
            type={"text"}
            width='100%'
            error={otherSource.error}
            inputProps={{
              name: "otherSource",
              value: otherSource.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: otherSource.required,
            }}
            textAreaProps={{}}
          />
        )}
        <Row>
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
        </Row>
        {/* <Select
          width={"48%"}
          name={"active"}
          label={"Active"}
          options={ActiveSelectOptions}
          value={active.value}
          onChange={onTextChange}
          mandatory={active.required}
          error={active.error}
        /> */}
        <Row>
          <TextInput
            label={"Graduation Year"}
            tooltip={graduationYearTooltip}
            type={"text"}
            width='48%'
            error={graduationYear.error}
            inputProps={{
              name: "graduationYear",
              value: graduationYear.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: graduationYear.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"graduationDegree"}
            label={"Graduation Degree"}
            options={getAttributValues("academyCandidateDegree", true)}
            value={graduationDegree.value}
            onChange={onTextChange}
            mandatory={graduationDegree.required}
            error={graduationDegree.error}
          />
        </Row>
        {getAttributValuesBynameId(
          "academyCandidateDegree",
          graduationDegree.value,
        )?.toLowerCase() === "other" && (
          <TextInput
            label={"Other Degree"}
            type={"text"}
            width='100%'
            error={otherDegree.error}
            inputProps={{
              name: "otherDegree",
              value: otherDegree.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: otherDegree.required,
            }}
            textAreaProps={{}}
          />
        )}
        <Row>
          <Select
            width={"48%"}
            name={"graduationMajor"}
            label={"Graduation Major"}
            options={getAttributValues("academyCandidateGraduationMajor", true)}
            value={graduationMajor.value}
            onChange={onTextChange}
            mandatory={graduationMajor.required}
            error={graduationMajor.error}
          />
          <Select
            width={"48%"}
            name={"admissionCounsellor"}
            label={"Admission Counsellor"}
            options={employeesOptions}
            value={admissionCounsellor.value}
            onChange={onTextChange}
            mandatory={admissionCounsellor.required}
            error={admissionCounsellor.error}
          />
        </Row>
        <TextInput
          label={"LinkedIn URL"}
          type={"text"}
          width='100%'
          error={linkedInURL.error}
          inputProps={{
            name: "linkedInURL",
            value: linkedInURL.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: linkedInURL.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    firstName.value,
    firstName.error,
    firstName.required,
    lastName.value,
    lastName.error,
    lastName.required,
    onTextChange,
    status.value,
    status.required,
    status.error,
    stage.value,
    stage.required,
    stage.error,
    email.error,
    email.value,
    email.required,
    academyCoursesOptions,
    course.value,
    course.required,
    course.error,
    dob.error,
    dob.value,
    dob.required,
    onDateChange,
    source.value,
    source.required,
    source.error,
    phone.error,
    phone.value,
    phone.required,
    city.error,
    city.value,
    city.required,
    otherSource.error,
    otherSource.value,
    otherSource.required,
    state.error,
    state.value,
    state.required,
    countryOptions,
    country.value,
    country.required,
    country.error,
    graduationYear.error,
    graduationYear.value,
    graduationYear.required,
    graduationDegree.value,
    graduationDegree.required,
    graduationDegree.error,
    otherDegree.error,
    otherDegree.value,
    otherDegree.required,
    graduationMajor.value,
    graduationMajor.required,
    graduationMajor.error,
    employeesOptions,
    admissionCounsellor.value,
    admissionCounsellor.required,
    admissionCounsellor.error,
    linkedInURL.error,
    linkedInURL.value,
    linkedInURL.required,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyCandidates?.createdBy !== "string"
        ? currentAcademyCandidates?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyCandidates?.updatedBy !== "string"
        ? currentAcademyCandidates?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyCandidates?.createdAt}
        updatedAt={currentAcademyCandidates?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyCandidates?.createdAt,
    currentAcademyCandidates?.createdBy,
    currentAcademyCandidates?.updatedAt,
    currentAcademyCandidates?.updatedBy,
  ]);

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyCandidates.name} />,
    [params.id],
  );

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const relatedCoursesList = useMemo(() => {
    return (
      <AcademyCoursesList
        relatedList={{
          type: "_id",
          value:
            typeof currentAcademyCandidates.course === "string"
              ? currentAcademyCandidates.course
              : currentAcademyCandidates.course?._id,
        }}
      />
    );
  }, [currentAcademyCandidates.course]);

  const relatedCandidateLeadsList = useMemo(() => {
    return (
      <AcademyCandidateLeadsList
        relatedList={{
          type: "associatedContact",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.academyCandidateLeads.name} recordId={params.id} />
    ),
    [params.id],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return relatedCoursesList;
      case 6:
        return relatedCandidateLeadsList;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    relatedCoursesList,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    relatedCandidateLeadsList,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
