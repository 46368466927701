import { useMemo, useCallback } from "react";

import { DOTS } from "../../../../utils";

interface pagination {
  paginationRange?: (number | string)[];
  lastPage: number;
  onNext: () => void;
  onPrevious: () => void;
  itemRange?: string;
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
}: {
  totalCount: number;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
  onPageChange: (...args: any[]) => void;
}) => {
  const range = useCallback((start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }, []);

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = siblingCount + 2;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  const lastPage = paginationRange?.[paginationRange?.length - 1];

  const getItemRange = useMemo(() => {
    let firstCount = 0;
    let lastCount = 0;
    if (currentPage === 1) {
      firstCount = 1;
      lastCount = pageSize;
    } else if (lastPage === currentPage) {
      firstCount = pageSize * currentPage + 1 - pageSize;
      lastCount = totalCount;
    } else {
      firstCount = pageSize * currentPage + 1 - pageSize;
      lastCount = pageSize * currentPage;
    }
    if (lastCount > totalCount) {
      lastCount = totalCount;
    }
    return `${firstCount}-${lastCount}`;
  }, [currentPage, lastPage, pageSize, totalCount]);

  const onNext = useCallback(() => {
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, lastPage, onPageChange]);

  const onPrevious = useCallback(() => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  const pagination: pagination = {
    paginationRange,
    lastPage,
    onNext,
    onPrevious,
    itemRange: getItemRange,
  };

  return pagination;
};
