import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AddressHistoryList,
  AddAddressHistory,
  UpdateAddressHistory,
  FindAddressHistory,
  DeleteAddressHistory,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAddressHistoryListSuccess, fetchAddressHistoryError } from "./actions";
import Types from "./actionType";
import {
  CreateAddressHistoryuccess,
  CreateAddressHistory as CreateAddressHistoryType,
  AddressHistorySuccess,
  UpdateAddressHistory as UpdateAddressHistoryType,
  FetchAddressHistory,
  FetchAddressHistoryById,
  AddressHistoryByIdSuccess,
  DeleteAddressHistory as DeleteAddressHistoryType,
} from "./types";

function* createWorker(action: CreateAddressHistoryType) {
  try {
    const options = {
      method: "POST",
      url: AddAddressHistory,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAddressHistoryuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* deleteWorker(action: DeleteAddressHistoryType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAddressHistory(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAddressHistoryuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateAddressHistoryType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAddressHistory(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAddressHistoryuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchAddressHistory) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AddressHistoryList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AddressHistorySuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchAddressHistoryListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchAddressHistoryError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching address history");
    }
  }
}

function* fetchAddressHistoryById(action: FetchAddressHistoryById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAddressHistory(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AddressHistoryByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ADDRESS_HISTORY, createWorker);
  yield takeLatest(Types.UPDATE_ADDRESS_HISTORY, updateWorker);
  yield takeLatest(Types.DELETE_ADDRESS_HISTORY, deleteWorker);
  yield takeEvery(Types.FETCH_ADDRESS_HISTORY, fetchAllWorker);
  yield takeEvery(Types.FETCH_ADDRESS_HISTORY_BY_ID, fetchAddressHistoryById);
}
