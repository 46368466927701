import React from "react";

import { Layout } from "../../../presentationals";
import { Organisations, OrganisationsController } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<Organisations />}
      controller={<OrganisationsController type='edit' />}
      admin={true}
      layout={"3"}
      title={strings.headerTitle.organisations}
    />
  );
};

export default Route;
