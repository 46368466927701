import React from "react";

import { Layout } from "../../../presentationals";
import { BugReportingController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<BugReportingController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APBugReportingEdit = React.memo(Route);
