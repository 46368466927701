import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    height: 100%;
    tr {
      font-size: 1.4rem;
      font-weight: 400;
      text-align: center;
    }
    td {
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
      padding: 16px 0;
    }
  `,
  Header: styled.div`
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1vh;
    h2 {
      font-size: 2rem;
      font-weight: 400;
      margin-left: 1vw;
    }
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 20px;
    margin-top: ${(props) => (props.desc ? "2vh" : "0")};
  `,
  Column: styled.div`
    flex: 1;
    text-align: center;
    font-family: ${fonts.font1};
    font-size: 12px;
  `,
  Container: styled.div`
    background-color: white;
    text-align: ${(props) => (props.othersComment ? "right" : "left")};
    padding: 20px;
    margin: 5px 0px;
    margin-bottom: ${(props) => (props.desc ? "4vh" : "0")};
    border-radius: 5px;
    border: ${(props) => (props.desc ? "solid 1px grey" : "none")};
  `,
  attachment: styled.div`
    font-family: ${fonts.font1};
    font-size: 12px;
    cursor: pointer;
  `,
  date: styled.div`
    font-family: ${fonts.font1};
    font-size: 10px;
    color: ${colors.light_black};
  `,
  comment: styled.div`
    font-family: ${fonts.font1};
    font-size: 1.3rem;
    opacity: 0.8;
  `,
  DescContainer: styled.div`
    h3 {
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 1vh;
      text-align: left;
    }
  `,
  CommentContainer: styled.div`
    background-color: #eff5f5;
    text-align: ${(props) => (props.othersComment ? "right" : "left")};
    padding: 20px;
    margin: 5px 0px;
    margin-bottom: ${(props) => (props.desc ? "4vh" : "0")};
    border-radius: 5px;
    border: ${(props) => (props.desc ? "solid 1px grey" : "none")};
  `,
  CommentName: styled.h2`
    font-size: 1.4rem;
    font-weight: 400;
    span {
      margin-left: 2vw;
      font-size: 1.1rem;
      opacity: 0.6;
    }
  `,
};

export default styles;
