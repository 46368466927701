import styled from "styled-components";

import { fonts } from "../../services/theme";

export const MainContainer = styled.div`
  height: 100%;
`;
export const Header = styled.div`
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }
  align-items: center;
  margin-bottom: 1vh;
`;

export const OtherInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60vw;
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  column-gap: 4vw;
  margin-top: 4vh;
`;
export const Column = styled.div`
  flex: 1;
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 14vh;
  border-radius: 1rem;
  background-color: ${(props) => props.color || "white"};
  color: ${(props) => props.textColor || "white"};
  font-family: ${fonts.font1};
  text-align: center;
  cursor: ${(props) => (props.onClick ? "pointer" : "cursor")};
  /* shadow */
  -webkit-box-shadow: 10px 10px 40px -16px ${(props) => props.color};
  -moz-box-shadow: 10px 10px 40px -16px ${(props) => props.color};
  box-shadow: 10px 10px 40px -16px ${(props) => props.color};
`;
export const Text = styled.div`
  font-size: 1.4rem;
  padding: 5px;
`;
