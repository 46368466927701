import React, { useEffect, useMemo, memo } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType } from "../../../../types";
import { ContactUsListProps } from "../../../../types/models/ContactUs";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useContactUsList } from "./hooks";

export const ContactUsList = memo(({ relatedList }: ContactUsListProps) => {
  const ContactUs = useAppSelector((state) => state.ContactUsReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.contactUs;

  const { list, metaData } = ContactUs;
  const {
    fetchContactUs,
    loading,
    handlePageChange,
    onSubmitDeleteRecord,
    onClickContactUs,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    contactUsFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useContactUsList({
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      name: {
        title: "Name",
        sortSupported: true,
        searchSupported: true,
        key: "name",
      },
      email: {
        title: "Email",
        sortSupported: true,
        key: "email",
      },
      jobTitle: {
        title: "Job",
        sortSupported: true,
        key: "jobTitle",
      },
      company: {
        title: "Company",
        sortSupported: true,
        key: "company",
      },
      phone: {
        title: "Phone",
        sortSupported: true,
        key: "phone",
      },
      description: {
        title: "Description",
        sortSupported: true,
        key: "description",
      },

      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        render: (item) => {
          return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        render: (item) => {
          return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.fields?.length
      ? tableListConfigs?.fields
      : tableListDefaultSelectFields.contactUs;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchContactUs({});
    // Do not include fetchContactUs to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.contactUs}
      handleTableConfigs={handleTableConfigs}
      filters={contactUsFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.contactUs
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.contactUs_delete}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickContactUs}
      headerTitle={strings.headerTitle.contactUs}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apContactUs.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
