import React, { useState } from "react";

import { MdSync } from "react-icons/md";

import Button from "../Button/button";

import { Container, ButtonContainer } from "./styles";

interface Props {
  title: string;
  onSync?: any;
  buttons?: [
    {
      buttonSize?: "small" | "normal" | "large";
      title: string;
      disabled?: boolean;
      block?: boolean;
      inactive?: boolean;
      type?: "primary" | "secondary" | "cancel";
      onPress?: Object;
      visible?: boolean;
    },
  ];
  titleColor?: string;
}

const Header = ({ title, onSync, buttons, titleColor }: Props) => {
  const [spin, setSpin] = useState(0);
  const sync = () => {
    setSpin(1);
    onSync?.event(onSync?.params || {});
    setTimeout(() => {
      setSpin(0);
    }, 2000);
  };

  const onPressButton = (e) => {
    const index = e.target.getAttribute("data-index");
    const item = buttons && buttons[index];
    item?.onPress?.event(item?.onPress?.params);
  };

  return (
    <Container titleColor={titleColor}>
      <text />
      <ButtonContainer>
        {onSync?.event && (
          <MdSync
            className={spin ? "sync-animate" : "sync"}
            onClick={sync}
            size={25}
            color={"#E94189"}
          />
        )}
        {buttons && buttons.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 10,
            }}
          >
            {buttons.map((item, index) => {
              if (item.visible) {
                return (
                  <Button
                    key={index}
                    onClick={onPressButton}
                    size={item?.buttonSize ? item?.buttonSize : "small"}
                    label={item?.title}
                    disabled={item?.disabled}
                    block={item?.block}
                    attributes={{
                      "data-index": index,
                    }}
                    inactive={item?.inactive}
                    type={item?.type}
                  />
                );
              }
            })}
          </div>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default Header;
