import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchServiceRequestsList,
  getProfile,
  removeAppNotification,
  updateServiceRequests,
  updateTableConfigs,
  showInAppLoader,
  // deleteServiceRequests,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  ServiceRequestsListWithMetaData,
  FetchServiceRequestsParams,
  ServiceRequestsList,
  // DeleteServiceRequestsPayload,
} from "../../../../../redux/ServiceRequests/types";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  fetchServiceRequestsProps,
  useServiceRequestsListProps,
  useServiceRequestsListReturnType,
} from "../../../../../types/models/ServiceCatalog";
import {
  AppRouteLinks,
  collections,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getServiceRequestsColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.serviceRequests;
const defaultFilters: QueryBuilderType[] = [];

export const useServiceRequestsList = ({
  relatedList,
  widgetId,
}: useServiceRequestsListProps): useServiceRequestsListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.ServiceRequestsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const serviceRequests = useAppSelector((state) => state.ServiceRequestsReducer.data.list);
  const { usersOptions, groupsOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const ServiceRequestsConfigTableFields = useMemo(() => {
    return tableConfiguration?.serviceRequests?.fields?.length
      ? tableConfiguration?.serviceRequests?.fields
      : tableListDefaultSelectFields.serviceRequests;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = ServiceRequestsConfigTableFields;
    return getServiceRequestsColumns({ fields });
  }, [ServiceRequestsConfigTableFields]);
  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: ServiceRequestsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.serviceRequests?.fields || tableListDefaultSelectFields.serviceRequests;
      records = records?.map((item) => {
        const serviceRequeststem = { ...item };
        if (userPersonalizedFields?.includes("assignedTo") && typeof item.assignedTo !== "string") {
          serviceRequeststem.assignedTo = item?.assignedTo?.name;
        }
        if (userPersonalizedFields?.includes("priority") && typeof item.priority !== "string") {
          serviceRequeststem.priority = item?.priority?.value;
        }
        if (userPersonalizedFields?.includes("state") && typeof item.state !== "string") {
          serviceRequeststem.state = item?.state?.value;
        }
        if (userPersonalizedFields?.includes("stage") && typeof item.stage !== "string") {
          serviceRequeststem.stage = item?.stage?.value;
        }
        if (userPersonalizedFields?.includes("urgency") && typeof item.urgency !== "string") {
          serviceRequeststem.urgency = item?.urgency?.value;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          serviceRequeststem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          serviceRequeststem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          serviceRequeststem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          serviceRequeststem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete serviceRequeststem?._id;
        return serviceRequeststem;
      });
      const serviceRequestsFields = tablePublicFields.serviceRequests;
      const columnHeaders = serviceRequestsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "serviceRequests");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.serviceRequests?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const serviceRequestsFetchSuccess = useCallback(
    (data: ServiceRequestsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const serviceRequestsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchServiceRequests = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchServiceRequestsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }

      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.serviceRequests?.limit || 10;

      const params: FetchServiceRequestsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: ServiceRequestsListWithMetaData) =>
          serviceRequestsFetchSuccess(data, exportAll),
        onError: serviceRequestsFetchError,
      };
      dispatch(fetchServiceRequestsList(payload));
    },
    [
      widgetId,
      widgetsData,
      dispatch,
      serviceRequestsFetchError,
      serviceRequestsFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = serviceRequests?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, serviceRequests, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchServiceRequests({ exportAll: true });
    },
    [fetchServiceRequests],
  );

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchServiceRequests({ page });
  }, [fetchServiceRequests, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchServiceRequests({ page: parseInt(page), search });
    },
    [fetchServiceRequests, search],
  );

  const onClickServiceRequests = useCallback(
    (id: string) => {
      if (permissions?.serviceRequests_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.serviceRequests_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchServiceRequests({ page: 1, search });
  }, [fetchServiceRequests, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchServiceRequests({ page: 1 });
  }, [fetchServiceRequests]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchServiceRequests({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchServiceRequests],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchServiceRequests({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchServiceRequests],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = serviceRequests?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, serviceRequests]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            serviceRequests?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, serviceRequests, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const serviceRequestsConfigLimit = tableConfiguration?.serviceRequests?.limit || 10;
      const serviceRequestsConfigs = {
        table: "serviceRequests",
        fields: fields,
        limit: serviceRequestsConfigLimit,
      };
      const payload = {
        tableConfiguration: serviceRequestsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchServiceRequests({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ServiceRequests table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.serviceRequests?.limit, dispatch, fetchServiceRequests, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const serviceRequestsConfigFields = tableConfiguration?.serviceRequests?.fields;
      dispatch(showInAppLoader(true));
      const serviceRequestsConfigs = {
        table: "serviceRequests",
        fields: serviceRequestsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: serviceRequestsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchServiceRequests({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ServiceRequests table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchServiceRequests],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = serviceRequests?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchServiceRequests({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ServiceRequests updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating ServiceRequests",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateServiceRequests(payload));
    },
    [selectedRows, serviceRequests, dispatch, notificationsId, fetchServiceRequests],
  );

  // // ================ Delete Method ================

  // const onSubmitDeleteRecord = useCallback(() => {
  //   if (!selectedRows.length) {
  //     const notification: AppNotificationsType = {
  //       id: notificationsId + 1,
  //       title: "No records selected",
  //       type: "warning",
  //     };
  //     dispatch(addAppNotification({ notification }));
  //     setTimeout(() => {
  //       dispatch(removeAppNotification({ notification }));
  //     }, 2000);
  //     return;
  //   }
  //   dispatch(showInAppLoader(true));
  //   const selectedRecords = serviceRequests?.filter((item) => {
  //     if (item?._id) {
  //       return selectedRows.includes(item?._id);
  //     }
  //     return false;
  //   });
  //   const payload: DeleteServiceRequestsPayload = {
  //     id: selectedRecords.map((item) => item?._id).join(","),
  //     onSuccess: () => {
  //       setLoading(false);
  //       fetchServiceRequests({});
  //       const notification: AppNotificationsType = {
  //         id: notificationsId + 1,
  //         title: "Record deleted successfully",
  //         type: "success",
  //       };
  //       dispatch(showInAppLoader(false));

  //       dispatch(addAppNotification({ notification }));
  //       setTimeout(() => {
  //         dispatch(removeAppNotification({ notification }));
  //       }, 2000);
  //     },
  //     onError: () => {
  //       setLoading(false);
  //       const notification: AppNotificationsType = {
  //         id: notificationsId + 1,
  //         title: "Error in deleting record",
  //         type: "error",
  //       };
  //       dispatch(showInAppLoader(false));
  //       dispatch(addAppNotification({ notification }));
  //       setTimeout(() => {
  //         dispatch(removeAppNotification({ notification }));
  //       }, 2000);
  //     },
  //   };
  //   dispatch(deleteServiceRequests(payload));
  // }, [selectedRows, serviceRequests, dispatch, notificationsId, fetchServiceRequests]);

  // ================ Table Header Buttons ================

  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (
          table &&
          typeof table === "object" && // Check if table is an object
          "serviceRequestsSupported" in table && // Check if serviceRequestsSupported is present
          typeof table.serviceRequestsSupported === "boolean" // Check if serviceRequestsSupported is a boolean
        ) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null;
      })
      .filter(Boolean);
  }, []);
  const listActionOptions: ListActions[] = [
    {
      name: "shortDescription",
      label: "Short Description",
      type: "bigTextArea",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
      required: true,
    },
    {
      name: "holdReason",
      label: "Hold Reason",
      type: "bigTextArea",
      required: true,
    },
    {
      name: "stage",
      label: "Stage",
      options: getAttributValues("serviceRequestsStage"),
    },
    {
      name: "dueDate",
      label: "Due Date",
      type: "date",
    },
    {
      name: "priority",
      label: "Priority",
      options: getAttributValues("serviceRequestsPriority"),
    },
    {
      name: "urgency",
      label: "Urgency",
      options: getAttributValues("serviceRequestsUrgency"),
    },
    {
      name: "state",
      label: "State",
      options: getAttributValues("serviceRequestsState"),
    },
    {
      name: "assignmentGroups",
      label: "Assignment Groups",
      options: groupsOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const serviceRequestsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");
    const priorityOptions = getFilterOptions(
      getAttributValues("serviceRequestsPriority"),
      "Priority",
    );
    const urgencyOptions = getFilterOptions(getAttributValues("serviceRequestsUrgency"), "Urgency");
    const stateOptions = getFilterOptions(getAttributValues("serviceRequestsState"), "Priority");
    const stageOptions = getFilterOptions(getAttributValues("serviceRequestsStage"), "Stage");
    const assignmentGroupsOptions = getFilterOptions(groupsOptions, "Priority");

    const fields: Field[] = [
      {
        name: "shortDescription",
        label: "Short Description",
        inputType: "string",
        validator,
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "holdReason",
        label: "Hold Reason",
        inputType: "string",
        validator,
      },
      {
        name: "stage",
        label: "stage",
        valueEditorType: "select",
        values: stageOptions,
        defaultValue: stageOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "dueDate",
        label: "Due Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "priority",
        label: "Priority",
        valueEditorType: "select",
        values: priorityOptions,
        defaultValue: priorityOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "urgency",
        label: "Urgency",
        valueEditorType: "select",
        values: urgencyOptions,
        defaultValue: urgencyOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "state",
        label: "State",
        valueEditorType: "select",
        values: stateOptions,
        defaultValue: stateOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "assignmentGroups",
        label: "Assignment Groups",
        valueEditorType: "select",
        values: assignmentGroupsOptions,
        defaultValue: assignmentGroupsOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [groupsOptions, usersOptions]);

  return {
    listActionOptions,
    serviceRequestsFilterFields,
    onClickServiceRequests,
    fetchServiceRequests,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    // onSubmitDeleteRecord,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
    ServiceRequestsConfigTableFields,
    columns,
  };
};
