import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {};
const colorExtractor = (type) => {
  const output: {
    background?: colors;
    text?: colors;
  } = {};
  switch (type) {
    case "error":
      output.background = colors.error;
      output.text = colors.error_text;
      break;
    case "warning":
      output.background = colors.warning;
      output.text = colors.warning_text;
      break;
    case "information":
    default:
      output.background = colors.information;
      output.text = colors.information_text;
      break;
  }
  return output;
};

styles.container = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${(props) => colorExtractor(props.type).background};
  margin-top: 15px;
  margin-bottom: 15px;
`;
styles.text = styled.span`
  flex: 1;
  font-size: 10px;
  color: ${(props) => colorExtractor(props.type).text};
  font-family: ${fonts.font1};
`;

export default styles;
