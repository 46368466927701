import styled from "styled-components";

import { fs, fonts, colors } from "../../../../theme";

interface Props {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const DashboardsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 10px;
  border-bottom: 0.3px solid ${colors.common.border};
  padding: 10px 0;
  margin-top: 10px;
  .dashboard-load-more {
    margin-top: 20px;
    align-self: center;
  }
`;

export const DashboardAdministrationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DashboardAdministrationItem = styled.div<Props>`
  display: flex;
  padding: 20px;
  font-size: ${fs.m};
  color: ${(props) => (props.active ? colors.filter.activeTitle : colors.filter.inactivcTitle)};
  font-family: ${fonts.SourceSansPro};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  transition: linear 0.5s;
  background-color: ${(props) =>
    props.active ? colors.filter.activeBackground : colors.filter.inactiveBackground};
`;

export const DashboardTabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3px;
  border-top: 0.3px solid ${colors.common.border};
  border-bottom: 0.3px solid ${colors.common.border};
`;

export const DashboardTabItem = styled.div<Props>`
  display: flex;
  padding: 20px;
  font-size: ${fs.m};
  color: ${(props) => (props.active ? colors.filter.activeTitle : colors.filter.inactivcTitle)};
  font-family: ${fonts.SourceSansPro};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  transition: linear 0.5s;
  background-color: ${(props) =>
    props.active ? colors.filter.activeBackground : colors.filter.inactiveBackground};
`;

export const DashboardWidgetsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const DashboardWidgetItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 49%;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const DashboardTitle = styled.div<Props>`
  font-size: ${fs.l};
  text-align: center;
  align-items: center;
  padding: 10px 10px;
  max-height: 2vh;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  background-color: ${colors.dashboard.tabBackground};
  font-size: ${fs.m};
  color: ${colors.white};
  cursor: pointer;
  border-radius: 5px;
  font-family: ${fonts.OpenSans};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  transition: linear 0.4s;
`;

export const DashboardTab = styled.div<Props>`
  font-size: ${fs.l};
  text-align: center;
  align-items: center;
  padding: 6px 6px;
  max-height: 2vh;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  background-color: ${colors.blue};
  font-size: ${fs.m};
  color: ${colors.white};
  cursor: pointer;
  border-radius: 5px;
  font-family: ${fonts.OpenSans};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  transition: linear 0.4s;
`;

export const DashboardItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WidgetsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 10px;
  padding: 20px 0;
`;

export const WidgetTitle = styled.div`
  font-size: ${fs.s};
  align-items: center;
  display: flex;
  justify-content: center;
  height: 4vh;
  width: 100%;
  border: solid 0.3px black;
`;

export const WidgetItem = styled.div`
  height: 500px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  border: solid 0.3px black;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const EmptyListMessage = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;
