/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SUBSCRIBER = "FETCH_SUBSCRIBER",
  FETCH_SUBSCRIBER_BY_ID = "FETCH_SUBSCRIBER_BY_ID",
  SUCCESS_FETCH_SUBSCRIBER = "SUCCESS_FETCH_SUBSCRIBER",
  ERROR_FETCH_SUBSCRIBER = "ERROR_FETCH_SUBSCRIBER",
  CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER",
  UPDATE_SUBSCRIBER = "UPDATE_SUBSCRIBER",
  DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER",
}

export default Types;
