/* eslint-disable no-unused-vars */
enum Types {
  FETCH_JOBS_POSTING = "FETCH_JOBS_POSTING",
  FETCH_JOBS_POSTING_BY_ID = "FETCH_JOBS_POSTING_BY_ID",
  SUCCESS_FETCH_JOBS_POSTING = "SUCCESS_FETCH_JOBS_POSTING",
  ERROR_FETCH_JOBS_POSTING = "ERROR_FETCH_JOBS_POSTING",
  CREATE_JOBS_POSTING = "CREATE_JOBS_POSTING",
  UPDATE_JOBS_POSTING = "UPDATE_JOBS_POSTING",
  DELETE_JOBS_POSTING = "DELETE_JOBS_POSTING",
}

export default Types;
