import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

// import { HTMLValidate } from "html-validator";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchControllerConfigs,
  removeAppNotification,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  createNewsletterResources,
  fetchNewsletterResourcesById,
  updateNewsletterResources,
} from "../../../../../redux/NewsletterResources/actions";
import {
  NewsletterResourcesList,
  CreateNewsletterResourcesPayload,
  FetchNewsletterResourcesByIdPayload,
  UpdateNewsletterResourcesPayload,
} from "../../../../../redux/NewsletterResources/types";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  NewsletterResourcesForm,
  useNewsletterResourcesControllerProps,
  useNewsletterResourcesControllerReturnType,
} from "../../../../../types/models/NewsletterResources";
// ================ Contstants ================
const validateHTML = async (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  return Array.from(doc.body.childNodes).every((node) => node.nodeType === 1);
  // try {
  //   // Validate HTML string
  //   const { messages } = await HTMLValidate({ data: htmlString });

  //   // Check if there are any validation errors
  //   if (messages.length > 0) {
  //     // HTML code is not valid
  //     console.error("HTML code is not valid:", messages);
  //     return false;
  //   } else {
  //     // HTML code is valid
  //     console.log("HTML code is valid");
  //     return true;
  //   }
  // } catch (error) {
  //   // Error occurred during validation
  //   console.error("Error validating HTML code:", error);
  //   return false;
  // }
};
const addFormSteps = ["Overview"];
const editFormSteps = ["Overview", "Checklist", "Activity Logs", "Worklogs", "System Data"];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = ["name", "active", "country"];
export const useNewsletterResourcesController = ({
  type,
  id,
  attachmentsPickerRef,
}: useNewsletterResourcesControllerProps): useNewsletterResourcesControllerReturnType => {
  const defaultAttachment = useMemo(() => {
    return {
      id: "",
      name: "",
    };
  }, []);
  const defaultFormData: NewsletterResourcesForm = {
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    name: {
      value: "",
      required: true,
      error: "",
    },
    status: {
      value: "draft",
      required: false,
      error: "",
    },
    body: {
      value: "",
      required: false,
      error: "",
    },
    subject: {
      value: "",
      required: false,
      error: "",
    },
    approvalBy: {
      value: "",
      required: true,
      error: "",
    },
    attachment: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    bodyType: {
      value: "editor",
      required: false,
      error: "",
    },
    sender: {
      value: 4011225,
      required: false,
      error: "",
    },
  };

  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<NewsletterResourcesForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<NewsletterResourcesList>({});
  const [body, setBody] = useState<string>("");
  const [bodyPreview, setBodyPreview] = useState<boolean>(false);
  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);
  const onBodyChange = useCallback(
    (value: string) => {
      if (formData.body.value !== "") {
        const tempFormData = { ...formData, body: { ...formData.body, value: "", error: "" } };
        setFormData(tempFormData);
      }
      setBody(value);
    },
    [formData],
  );

  const handleBodyPreview = useCallback(() => {
    setBodyPreview((prev) => !prev);
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData[field]?.error) {
              count += 1;
            }
          });
          break;
      }
      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = { ...formData, [name]: { ...formData[name], value, error: "" } };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      if (name === "body" && body !== "") {
        setBody("");
      }
      const tempFormData = { ...formData, [name]: { ...formData[name], value, error: "" } };
      setFormData(tempFormData);
    },
    [formData, body],
  );

  const rehydrateFormData = useCallback(
    (newsletterResources: NewsletterResourcesList) => {
      const item = newsletterResources;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).forEach((field) => {
        let value = item?.[field];
        if (field === "body") {
          if (item.bodyType === "editor") {
            setBody(value as string);
          } else {
            data[field].value = value;
          }
        }
        if (["approvalBy"].includes(field)) {
          const tempValue = value?._id;
          value = tempValue;
        }
        if (field === "attachments") {
          for (let i = 0; i < value?.length; i++) {
            const tempValue = {
              id: value[i]?.id,
              error: "",
              name: value[i]?.name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }
        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchNewsletterResources = useCallback(() => {
    setLoading(true);
    const payload: FetchNewsletterResourcesByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchNewsletterResourcesById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(async () => {
    const { name, status, subject, approvalBy, attachment, bodyType, sender } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const isBodyValid = await validateHTML(formData.body.value);
    console.log({ isBodyValid });
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const attachmentValue: Attachments = {
        id: attachment.value.id || null,
        name: attachment.value.name || "",
        extension: attachment.value.extension || "",
      };
      const payload: CreateNewsletterResourcesPayload = {
        data: {
          attachments: attachments,
          sender: sender.value,
          attachment: attachmentValue,
          name: name.value,
          status: status.value,
          body: bodyType.value === "html" ? formData.body.value : body,
          subject: subject.value,
          approvalBy: approvalBy.value,
          bodyType: bodyType.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NewsletterResources created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createNewsletterResources(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, body, navigate]);

  const onSubmitUpdate = useCallback(async () => {
    const { name, status, subject, approvalBy, attachment, bodyType, sender } = formData;
    const submit = validateForm();
    const isBodyValid = await validateHTML(formData.body.value);
    console.log({ isBodyValid });
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const attachmentValue: Attachments = {
        id: attachment.value.id || null,
        name: attachment.value.name || "",
        extension: attachment.value.extension || "",
      };
      setLoading(true);
      const payload: UpdateNewsletterResourcesPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          attachment: attachmentValue,
          sender: sender.value,
          name: name.value,
          status: status.value,
          body: bodyType.value === "html" ? formData.body.value : body,
          subject: subject.value,
          approvalBy: approvalBy.value,
          bodyType: bodyType.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NewsletterResources updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateNewsletterResources(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, id, body]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateNewsletterResourcesPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateNewsletterResources(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.newsletterResources, title];
  }, [type, data.name]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: "Preview body",
        onClick: handleBodyPreview,
        visible: true,
      },
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type, handleBodyPreview]);

  const onSaveAttachment = useCallback(
    (data: Attachments, fieldName: string) => {
      const attachmentsData = {
        id: data.id,
        name: data.name,
        extension: data.extension,
      };

      const tempFormData = {
        ...formData,
        [fieldName]: {
          ...formData[fieldName],
          value: attachmentsData,
        },
      };
      setFormData(tempFormData);
    },

    [formData],
  );

  const deleteAttachment = useCallback(
    (fieldName: string) => {
      const tempFormData = {
        ...formData,
        [fieldName]: {
          ...formData[fieldName],
          value: defaultAttachment,
        },
      };
      setFormData(tempFormData);
    },
    [defaultAttachment, formData],
  );

  return {
    formSteps,
    bodyPreview,
    handleBodyPreview,
    body,
    onBodyChange,
    onSaveAttachment,
    deleteAttachment,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchNewsletterResources,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
  };
};
