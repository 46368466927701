import Types from "./types";

const Initial = {
  loading: false,
  recruits: [],
  filters: {
    name: "",
    mobile: "",
    email: "",
    nationalId: "",
    nationalIdType: "",
    reportingTalentAcquisition: "",
    sortKey: {},
    sortType: "asc",
  },
  tabs: [
    {
      bucket: "",
      label: "All",
      value: "",
    },
    {
      bucket: 0,
      label: "Info Received",
      value: "information",
    },
    {
      bucket: 1,
      label: "Screening",
      value: "screening",
    },
    {
      bucket: 2,
      label: "Technical",
      value: "technical",
    },
    {
      bucket: 3,
      label: "Director",
      value: "director",
    },
    {
      bucket: 4,
      label: "Onboarding",
      value: "onboarding",
    },
    {
      bucket: -1,
      label: "Rejected",
      value: "rejected",
    },
    {
      bucket: 100,
      label: "Converted",
      value: "converted",
    },
  ],
};

const RecruitsReducer = (state = Initial, action) => {
  switch (action.type) {
    case Types.RECRUITS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case Types.RECRUITS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        recruits: action.payload.data,
      };
    case Types.RECRUITS_UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    case Types.RECRUITS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default RecruitsReducer;
