import React from "react";

import DatePicker from "react-date-picker";
import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {};
// styles.maincontainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* margin-top: 5px; */
//   margin-bottom: 10px;
// `;

// styles.container = styled.div`
//   display: flex;
//   flex-direction: row;
//   background-color: white;
//   box-shadow: ${(props) =>
//     props.isFocused
//       ? `0px 0px 5px ${colors.primary}`
//       : `0px 0px 0px transparent`};
//   padding: 7px;
//   flex: 1;
//   margin-top: 5px;
//   border: 0.1px solid #999;
//   border-radius: 3px;
// `;

// styles.input = styled.input`
//   width: 20px;
//   flex: 1;
//   /* background-color: transparent;
//   border: 0px;
//   font-size: 11px;
//   font-family: ${fonts.font1};
//   font-weight: 300; */
//   &:focus {
//     outline: none;
//     box-shadow: 0px 0px 0px transparent;
//   }
//   &::placeholder {
//     font-weight: 300;
//   }
//   z-index: 0;
// `;
// styles.labelContainer = styled.div`
//   margin-bottom: -10px;
//   z-index: 1;
// `;

// styles.label = styled.label`
//   font-size: 11px;
//   font-family: ${fonts.font1};
//   /* font-weight: 500; */
//   margin-left: 5px;
//   padding: 3px;
//   background-color: white;
// `;

styles.errorText = styled.span`
  font-size: 9px;
  font-family: ${fonts.font1};
  font-weight: 500;
  /* margin-top: 3px; */
  color: ${colors.error};
  cursor: ${(props) => (props.type === "document" ? "pointer" : "")};
`;

// new Styles
styles.ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-direction: column; */
  margin-bottom: 13px;
`;
styles.Wrapper = styled.div`
  position: relative;
  display: flex;
  /* flex-direction: column; */
  /* margin-bottom: 10px; */
`;

styles.TextArea = styled.textarea`
  flex: 1;
  /* fonts property start */
  font-size: 11px;
  font-family: ${fonts.font1};
  font-weight: normal;
  /* fonts property end */

  padding: 10px 10px 10px 5px;
  display: block;
  width: 20px;
  flex: 1;
  border: none;
  border: 1px solid ${(props) => (props.error ? colors.error : "#757575")};
  background-color: white;
  border-radius: 1px;
  &:focus {
    outline: none;
  }
`;

styles.Input = styled.input`
  /* fonts property start */
  font-size: 1.2rem;
  font-family: ${fonts.font1};
  font-weight: normal;
  /* fonts property end */

  padding: 10px 10px 10px 5px;
  display: block;
  width: ${(props) => (props.width ? props.width : "20px")};
  flex: 1;
  border: none;
  border: 1px solid ${(props) => (props.error ? colors.error : "#342283")};
  background-color: white;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &::file-selector-button {
    cursor: pointer;
  }
`;

styles.Label = styled.label`
  /* fonts property start */
  color: #999;
  font-size: 12px;
  font-weight: normal;
  font-family: ${fonts.font1};
  /* fonts property end */
  position: absolute;
  pointer-events: none;
  left: ${(props) => (props.type === "checkbox" ? "30px" : "10px")};
  top: ${(props) => (props.type === "checkbox" ? "2px" : "12px")};
  transition: 0.2s ease all;
  /* ${styles.Input}:invalid ~ & */
  /* ${styles.Input}:focus ~ & {
    top: -8px;
    font-size: 10px;
    color: ${colors.primary};
    left: 3px;
    padding: 3px;
    background-color: white;
  } */
  &.focused {
    ${(props) =>
      props.type !== "checkbox" && {
        top: "-8px",
        left: "3px",
      }}
    font-size: 10px;
    color: ${colors.primary};
    padding: 3px;
    background-color: white;
  }
`;

styles.DatePicker = styled(DatePicker)`
  flex: 1;
  color: #999;
  font-size: 11px;
  font-family: ${fonts.font1};
  font-weight: normal;
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid ${(props) => (props.error ? colors.error : "#757575")};
    padding: 2px;
  }
`;

styles.switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export default styles;
