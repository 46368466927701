import styled from "styled-components";

import { colors, fs } from "../../../../../theme";

interface Props {
  active?: boolean;
  noBorder?: boolean;
  loading?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.common.white};
  border: 0.6px solid ${colors.common.borderColor};
  box-shadow: 0px 2px 2px ${colors.common.shadow};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  opacity: ${(props) => (props.loading ? "0.4" : "1")};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
  .second-header {
    margin-top: 10px;
  }
  .add-filter {
    width: 100%;
    margin-top: 10px;
  }
  .filter-row {
    align-items: center;
  }
`;

export const FormTitle = styled.div`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background-color: green; */
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  /* background-color: blue; */
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const RelatedListContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const NoItemsContainer = styled.div`
  width: 100%;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;
