/* eslint-disable no-unused-vars */
enum Types {
  FETCH_GROUPS = "FETCH_GROUPS",
  FETCH_GROUPS_BY_ID = "FETCH_GROUPS_BY_ID",
  SUCCESS_FETCH_GROUPS = "SUCCESS_FETCH_GROUPS",
  ERROR_FETCH_GROUPS = "ERROR_FETCH_GROUPS",
  CREATE_GROUPS = "CREATE_GROUPS",
  UPDATE_GROUPS = "UPDATE_GROUPS",
}

export default Types;
