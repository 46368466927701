import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import Select from "../../components/Select/select";
import { filterUsers } from "../../redux/users/actions";

import styles from "./UserFilters.styles";

const sortKeys = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Admin", key: "admin" },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const UsersFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.filterUsers({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  users: state.UsersReducer.users,
  filters: state.UsersReducer.filters,
});

const mapDispatchToProps = {
  filterUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilters);
