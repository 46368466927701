/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_CANDIDATE_ENROLLMENT = "FETCH_ACADEMY_CANDIDATE_ENROLLMENT",
  FETCH_ACADEMY_CANDIDATE_ENROLLMENT_BY_ID = "FETCH_ACADEMY_CANDIDATE_ENROLLMENT_BY_ID",
  SUCCESS_FETCH_ACADEMY_CANDIDATE_ENROLLMENT = "SUCCESS_FETCH_ACADEMY_CANDIDATE_ENROLLMENT",
  ERROR_FETCH_ACADEMY_CANDIDATE_ENROLLMENT = "ERROR_FETCH_ACADEMY_CANDIDATE_ENROLLMENT",
  CREATE_ACADEMY_CANDIDATE_ENROLLMENT = "CREATE_ACADEMY_CANDIDATE_ENROLLMENT",
  UPDATE_ACADEMY_CANDIDATE_ENROLLMENT = "UPDATE_ACADEMY_CANDIDATE_ENROLLMENT",
  DELETE_ACADEMY_CANDIDATE_ENROLLMENT = "DELETE_ACADEMY_CANDIDATE_ENROLLMENT",
  ACADEMY_PAYMENT_DETAILS_ADD = "ACADEMY_PAYMENT_DETAILS_ADD",
  ACADEMY_PAYMENT_DETAILS_UPDATE = "ACADEMY_PAYMENT_DETAILS_UPDATE",
  DELETE_ACADEMY_PAYMENT_DETAILS = "DELETE_ACADEMY_PAYMENT_DETAILS",
}

export default Types;
