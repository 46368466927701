import styled from "styled-components";

import { fonts } from "../../services/theme";

export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

export const BackgroundImage = styled.img`
  flex: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 80vh;
  max-width: 80vw;
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 5px 44px 18px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 5px 44px 18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 5px 44px 18px rgba(0, 0, 0, 0.75);
`;
export const ImageContainer = styled.div`
  display: flex;
  width: 75%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const LoginContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 20vh 1vw 1vh 4vw; */
  justify-content: center;
`;

// export const TitleContianer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   row-gap: 10px;
//   margin-bottom: 20px;
// `;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  font-size: 22px;
  font-family: ${fonts.font1};
  font-weight: 600;
  margin-left: 4%;
`;

export const SubTitle = styled.div`
  font-size: 2rem;
  font-family: ${fonts.font1};
  /* font-weight: 600; */
  margin-top: 6vh;
  /* text-align: center; */
`;

export const FormContainer = styled.div`
  /* padding: 40px 25px; */
  /* background-color: green; */
  /* border: 0.5px #aaa solid; */
  /* border-radius: 5px; */
  width: 60%;
  margin-top: 10%;
`;

export const Label = styled.label`
  color: #333;
`;

export const BottomText = styled.div`
  font-size: 1rem;
  width: fit-content;
  font-family: ${fonts.font1};
  align-self: flex-end;
  cursor: pointer;
  margin-top: 51v;
`;
