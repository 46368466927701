import React, { memo } from "react";

import { strings } from "../../../../theme";

import { AnimatedLoader, Container, LoaderContainer, LoadingTitle } from "./styles";

export const InAppLoader = memo(() => {
  return (
    <Container>
      <LoaderContainer>
        <LoadingTitle>{strings.common.loading}</LoadingTitle>
        <AnimatedLoader>
          <span></span>
          <span></span>
          <span></span>
        </AnimatedLoader>
      </LoaderContainer>
    </Container>
  );
});
