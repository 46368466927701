import React, { useState, useMemo, useCallback } from "react";

import { ChecklistTemplateChecklistItem } from "../../../../redux/ChecklistTemplate/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { images, strings } from "../../../../theme";
import { ChecklistTemplateChecklistItemForm } from "../../../../types";
import { ActiveSelectOptions } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { CloseIcon } from "../../../common/styles";
import {
  SalaryFieldsContainer,
  SalaryModalContainer,
} from "../../../screens/admin/Employees/styles";
import { TextInput, Select, Button, Checkbox } from "../../atoms";
import { TableList } from "../../templates";
import { Popup } from "../Popup";

import { useMultiStepForm } from "./hooks";
import {
  Container,
  LeftContainer,
  RightContainer,
  FormTitle,
  FormFieldsContainer,
  StepContainer,
  RelatedListContainer,
  Row,
  FormChecklistContainer,
  ChecklistItemContainer,
  ChecklistModalContainer,
} from "./styles";

const columns = [
  {
    title: "Serial",
    width: "20%",
    key: "serial",
  },
  {
    title: "Name",
    width: "30%",
    key: "name",
  },
  {
    title: "Assigned To",
    width: "30%",
    render: (item) => {
      return <div className='title'>{item?.assignedTo?.name}</div>;
    },
  },
  {
    title: "Value",
    width: "20%",
    render: (item) => {
      return <div className='title'>{item?.value ? "Yes" : "No"}</div>;
    },
  },
];
const defaultChecklist: ChecklistTemplateChecklistItemForm = {
  name: {
    value: "",
    required: true,
    error: "",
  },
  assignedTo: {
    value: "",
    required: true,
    error: "",
  },
};
export const Form = ({
  data,
  onTextChange,
  addChecklistModal,
  checklist,
  editChecklistModal,
  checklistModal,
  handleChecklistModal,
  checklistFormData,
  onChecklistTextChange,
  onSubmitAddChecklist,
  onSubmitUpdateChecklist,
  handleCheckbox,
  checklistTemplateModal,
  selectedChecklistTemplate,
  selectedChecklistValues,
  handleChecklistTemplateModal,
  handleChecklistTemplate,
  handleChecklistTemplateValue,
}: {
  data: object;
  onTextChange: (e: any) => void;
  handleCheckbox: (e: React.MouseEvent) => void;
  addChecklistModal: () => void;
  checklist: any[];
  editChecklistModal: () => void;
  checklistModal: "add" | "edit" | "";
  handleChecklistModal: () => void;
  checklistFormData: ChecklistTemplateChecklistItemForm;
  onChecklistTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmitAddChecklist: (type: string) => void;
  onSubmitUpdateChecklist: () => void;

  checklistTemplateModal: boolean;
  selectedChecklistTemplate: string;
  selectedChecklistValues: ChecklistTemplateChecklistItem[];
  handleChecklistTemplateModal: () => void;
  handleChecklistTemplate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChecklistTemplateValue: (e: React.MouseEvent) => void;
}) => {
  const { currentStepIndex, goTo, steps, fields, getItem } = useMultiStepForm(data);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const dispatch = useAppDispatch();
  const { usersOptions, checklistTemplatesOptions } = useControllerConfigs();
  const checklistTemplates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.checklistTemplates,
  );

  const checklistItems = useMemo(() => {
    console.log(checklistTemplates, selectedChecklistTemplate);
    const selectedChecklistItems = checklistTemplates?.find(
      (item) => item._id === selectedChecklistTemplate,
    )?.checklist;
    return selectedChecklistItems;
  }, [checklistTemplates, selectedChecklistTemplate]);

  const isTemplateChecklistSelected = useCallback(
    (name, assignedTo) => {
      return selectedChecklistValues.some(
        (item) => item.name === name && item.assignedTo === assignedTo,
      );
    },
    [selectedChecklistValues],
  );

  return (
    <Container>
      <LeftContainer>
        <FormTitle>{getItem.title}</FormTitle>
        {getItem.workLogs && getItem.workLogs()}
        {getItem.checklist && (
          <FormChecklistContainer>
            <TableList
              rightButton={strings.common.addNew}
              rightButtonPress={addChecklistModal}
              additionalButton='Import from Template'
              additionalButtonPress={handleChecklistTemplateModal}
              loading={false}
              list={checklist}
              columns={columns}
              onClickTableRow={editChecklistModal}
            />
            <Popup isOpen={!!checklistModal} closeModal={handleChecklistModal}>
              <SalaryModalContainer>
                <CloseIcon src={images.closeModal} onClick={handleChecklistModal} />
                <FormTitle>
                  {checklistModal === "add"
                    ? "Add Checklist"
                    : checklistModal === "edit"
                    ? "Edit Checklist"
                    : ""}
                </FormTitle>
                <SalaryFieldsContainer>
                  <Row>
                    <TextInput
                      label={"Name"}
                      type={"text"}
                      width='48%'
                      error={checklistFormData.name.error}
                      inputProps={{
                        name: "name",
                        value: checklistFormData.name.value,
                        autoComplete: "off",
                        onChange: onChecklistTextChange,
                        required: checklistFormData.name.required,
                      }}
                      textAreaProps={{}}
                    />
                    <Select
                      width={"48%"}
                      name={"assignedTo"}
                      label={"Assigned To"}
                      options={usersOptions}
                      value={checklistFormData.assignedTo.value}
                      onChange={onChecklistTextChange}
                      mandatory={checklistFormData.assignedTo.required}
                      error={checklistFormData.assignedTo.error}
                    />
                  </Row>
                  <Checkbox
                    label={"Value"}
                    width='100%'
                    onClick={handleCheckbox}
                    value={checklistFormData?.value?.value || false}
                    required={false}
                    error={checklistFormData?.value?.error || ""}
                    attributes={{
                      "data-name": "value",
                    }}
                  />
                </SalaryFieldsContainer>
                <Button
                  className='employee-modal-save-button'
                  title={checklistModal === "add" ? strings.common.add : strings.common.save}
                  onClick={
                    checklistModal === "add" ? onSubmitAddChecklist : onSubmitUpdateChecklist
                  }
                />
              </SalaryModalContainer>
            </Popup>
            <Popup isOpen={!!checklistTemplateModal} closeModal={handleChecklistTemplateModal}>
              <ChecklistModalContainer>
                <CloseIcon src={images.closeModal} onClick={handleChecklistTemplateModal} />
                <FormTitle onClick={() => console.log(selectedChecklistValues)}>
                  Select Checklist Template
                </FormTitle>
                <SalaryFieldsContainer>
                  <Select
                    width={"100%"}
                    name={"checklistTemplate"}
                    label={"Checklist Template"}
                    options={checklistTemplatesOptions}
                    value={selectedChecklistTemplate}
                    onChange={handleChecklistTemplate}
                    mandatory={false}
                    error={""}
                  />
                  <ChecklistItemContainer>
                    {checklistItems?.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Checkbox
                            containerClass='table-configs-checkbox'
                            wrapperClass='table-configs-checkbox-wrapper'
                            labelClass='table-configs-checkbox-label'
                            label={item.name || ""}
                            width='100%'
                            onClick={handleChecklistTemplateValue}
                            value={isTemplateChecklistSelected(item.name, item.assignedTo)}
                            required={false}
                            error={""}
                            attributes={{
                              "data-name": item.name,
                              "data-assignedTo": item.assignedTo,
                              "data-index": index,
                            }}
                          />
                        </Row>
                      );
                    })}
                  </ChecklistItemContainer>
                </SalaryFieldsContainer>
                <Button
                  className='employee-modal-save-button'
                  title={checklistModal === "add" ? strings.common.add : strings.common.save}
                  onClick={() => {
                    handleChecklistTemplateModal();
                    onSubmitAddChecklist("template");
                  }}
                />
              </ChecklistModalContainer>
            </Popup>
          </FormChecklistContainer>
        )}
        {getItem.relatedList ? (
          <RelatedListContainer>{getItem.relatedList()}</RelatedListContainer>
        ) : (
          <FormFieldsContainer>
            {fields.map((item, fieldIndex) => {
              const fieldItem = getItem.fields[item];
              if (fieldItem && fieldItem.visible) {
                let name = fieldItem?.name;
                if (!name && name !== 0) {
                  name = item;
                }
                if (fieldItem.type === "select") {
                  return (
                    <Select
                      key={fieldIndex}
                      width={fieldItem.width}
                      name={name}
                      label={fieldItem.label}
                      options={fieldItem.options}
                      value={fieldItem.value}
                      onChange={fieldItem.onChange ? fieldItem.onChange : onTextChange}
                      mandatory={fieldItem.required}
                      readOnly={fieldItem.readOnly}
                      error={fieldItem.error}
                    />
                  );
                } else if (fieldItem.type === "button") {
                  return (
                    <Button
                      key={fieldIndex}
                      title={fieldItem.label}
                      onClick={fieldItem.onChange}
                      disabled={fieldItem.disabled}
                      width={fieldItem.width}
                      top={fieldItem.top}
                      height={fieldItem.height}
                      attributes={fieldItem.attributes}
                    />
                  );
                } else {
                  return (
                    <TextInput
                      key={fieldIndex}
                      width={fieldItem.width}
                      label={fieldItem.label}
                      type={fieldItem.type}
                      error={fieldItem.error}
                      inputProps={{
                        readOnly: fieldItem.readOnly,
                        required: fieldItem.required,
                        name: name,
                        value: fieldItem.value,
                        onChange: fieldItem.onChange ? fieldItem.onChange : onTextChange,
                      }}
                    />
                  );
                }
              }
              return <></>;
            })}
          </FormFieldsContainer>
        )}
      </LeftContainer>
      <RightContainer>
        {steps.map((stepItem, stepIndex) => {
          const Header = data[stepItem]?.header;
          return (
            <>
              {Header && <Header />}
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {data[stepItem].title}
                <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
              </StepContainer>
            </>
          );
        })}
      </RightContainer>
    </Container>
  );
};
