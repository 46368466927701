import Types from "./actionType";
import { DashboardsActions, DashboardsState } from "./types";

const initialState: DashboardsState = {
  data: {
    dashboardAdministrations: [],
    dashboardTabs: [],
    dashboardWidgets: [],
    widgetsData: [],
  },
  loading: false,
  loadingWidgets: [],
};

export const DashboardsReducer = (state = initialState, action: DashboardsActions) => {
  const currentWidgetData = [...state.data.widgetsData];
  const updatedWidgetsData = [...currentWidgetData];
  const currentLoadingIds = [...state.loadingWidgets];
  const updatedLoadingIds = [...currentLoadingIds];
  let widgetIndex = -1;
  let loadingIdIndex = -1;

  switch (action.type) {
    case Types.FETCH_DASHBOARDS:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_DASHBOARDS:
      return {
        ...state,
        data: {
          ...state.data,
          dashboardAdministrations: action.payload.data.dashboardAdministrations,
          dashboardTabs: action.payload.data.dashboardTabs,
          dashboardWidgets: action.payload.data.dashboardWidgets,
        },
        loading: false,
      };
    case Types.FETCH_DASHBOARD_WIDGET_DATA:
      if (action.payload.id) {
        updatedLoadingIds.push(action.payload.id);
      }
      return {
        ...state,
        loadingWidgets: updatedLoadingIds,
      };
    case Types.SUCCESS_FETCH_DASHBOARD_WIDGET_DATA:
      console.log({ action });

      if (action.payload?.data?.data?._id) {
        widgetIndex = currentWidgetData.findIndex(
          (item) => item?._id === action.payload?.data?.data?._id,
        );
        if (currentLoadingIds.includes(action.payload?.data?.data?._id)) {
          loadingIdIndex = currentLoadingIds.findIndex(
            (item) => item === action.payload?.data?.data?._id,
          );
        }
      }
      if (widgetIndex >= 0) {
        updatedWidgetsData.splice(widgetIndex, 1, action.payload?.data?.data);
      } else {
        updatedWidgetsData.push(action.payload?.data?.data);
      }
      if (loadingIdIndex >= 0) {
        updatedLoadingIds.splice(loadingIdIndex, 1);
      }
      return {
        ...state,
        data: {
          ...state.data,
          widgetsData: [...updatedWidgetsData],
        },
        loadingWidgets: [...updatedLoadingIds],
      };

    default:
      return state;
  }
};
