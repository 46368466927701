import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  DriveList,
  DriveGenerateURL,
  DriveOperations,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { DriveFetchSuccess } from "./actions";
import Types from "./actionType";
import {
  IDriveActionFetch,
  IDriveActionUpload,
  IDriveGenerateURLResponse,
  IDriveListingResponse,
} from "./types";

function* fetchWorker(action: IDriveActionFetch) {
  try {
    const options = {
      method: "GET",
      url: DriveList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: { path: action.payload.path },
    };
    const response: AxiosResponse<IDriveListingResponse> = yield call(axios, options);
    yield put(DriveFetchSuccess({ data: response.data.data }));
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* URLGeneratorWorker(action: IDriveActionUpload) {
  console.log("check");
  try {
    const options = {
      method: "GET",
      url: DriveGenerateURL,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: { [action.payload.type]: action.payload.path },
    };
    const response: AxiosResponse<IDriveGenerateURLResponse> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* uploadFileWorker(action) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload.file);
    const options = {
      method: "PUT",
      url: action.payload.url,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const size = (progressEvent.loaded / action.payload.file.size) * 100;
        return action.payload.onProgress(size > 100 ? 100 : size);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(axios, options);
    action?.payload?.onSuccess && action.payload.onSuccess(response);
  } catch (e) {
    const error = e?.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* operationsWorker(action) {
  const { payload } = action;
  try {
    const options = {
      method: "GET",
      url: DriveOperations,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: {
        option: payload.operation,
        path: payload.path,
        newpath: payload.newPath,
      },
    };
    const response = yield call(axios, options);
    action?.payload?.onSuccess && action.payload.onSuccess(response);
  } catch (e) {
    const error = e?.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* Saga() {
  yield takeLatest(Types.DRIVE_FETCH, fetchWorker);
  yield takeLatest(Types.DRIVE_UPLOAD, URLGeneratorWorker);
  yield takeLatest(Types.DRIVE_UPLOAD_S3, uploadFileWorker);
  yield takeEvery(Types.DRIVE_OPERATIONS, operationsWorker);
}
