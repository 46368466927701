import Select from "react-select";
import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {};
styles.maincontainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 5px; */
  margin-bottom: 10px;
  position: relative;
`;

styles.container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  box-shadow: ${(props) =>
    props.isFocused ? `0px 0px 5px ${colors.primary}` : `0px 0px 0px transparent`};
  /* padding: 0px; */
  flex: 1;
  margin-top: 5px;
`;

styles.select = styled(Select)`
  .Select__control {
    /* height: 40px;
    width: 100%; */
    border: 1px solid ${(props) => (props.error ? colors.error : "#342283")};
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: ${fonts.font1};
    font-weight: normal;
    /* padding: 2px 2px 2px 2px; */
  }

  .Select__option {
    font-size: 11px;
    font-family: ${fonts.font1};
    font-weight: normal;
    /* background-color: red; */
    /* &--is-focused {
      background-color: whitesmoke;
      color: black;
    } */
  }

  .Select__control:hover {
    border: 1px solid ${(props) => (props.error ? colors.error : "#757575")};
  }

  .Select__control--is-focused {
    box-shadow: none;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }
`;

// styles.select1 = styled.select`
//   flex: 1;
//   background-color: transparent;
//   border: 0px;
//   font-size: 11px;
//   height: 35px;
//   font-family: ${fonts.font1};
//   /* font-weight: 300; */
//   &:focus {
//     outline: none;
//     box-shadow: 0px 0px 0px transparent;
//   }
//   /* option {
//     color: black;
//     background: green;
//     display: flex;
//     white-space: pre;
//     min-height: 20px;
//     padding: 20px 2px 1px;
//     font-family: ${fonts.font1};
//   } */
// `;

styles.labelContainer = styled.div`
  margin-bottom: -10px;
  z-index: 1;
`;

styles.label = styled.label`
  /* font-weight: 400; */
  /* font-size: 11px;
  font-family: ${fonts.font1};
  
  margin-left: 5px;
  padding: 3px;
  background-color: white; */
  /* fonts property start */
  color: #999;
  font-size: 12px;
  font-weight: normal;
  font-family: ${fonts.font1};
  /* fonts property end */
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 12px;
  transition: 0.2s ease all;
  /* ${styles.Input}:invalid ~ & */
  /* ${styles.Input}:focus ~ & {
    top: -8px;
    font-size: 10px;
    color: ${colors.primary};
    left: 3px;
    padding: 3px;
    background-color: white;
  } */
  &.focused {
    top: -8px;
    font-size: 10px;
    color: ${colors.primary};
    left: 3px;
    padding: 3px;
    background-color: white;
  }
`;

styles.errorText = styled.span`
  font-size: 9px;
  font-family: ${fonts.font1};
  /* font-weight: 500; */
  margin-top: 3px;
  color: ${colors.error};
`;

export default styles;
