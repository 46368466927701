import React from "react";

import { strings } from "../../../../theme";
import { Row } from "../../../common/styles";

import { Container, Icon, Count, Title, Button } from "./styles";

const StatsCard = ({ gradientOne, gradientTwo, count, title, image, onClick }) => {
  const handleOnClick = () => {
    if (onClick?.event) {
      const event = onClick.event;
      event(onClick?.params);
    }
  };
  return (
    <Container gradientOne={gradientOne} gradientTwo={gradientTwo}>
      <Icon src={image} />
      <Count>{count}</Count>
      <Row marginTop='30px'>
        <Title>{title}</Title>
        <Button style={{ color: gradientTwo }} onClick={handleOnClick}>
          {strings.epHome.viewAll}
        </Button>
      </Row>
    </Container>
  );
};

export default StatsCard;
