import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Toast from "../../components/Toast/toast";
import { Header } from "../../presentationals/components";
import { uploadFile, getFile } from "../../redux/application/actions";
import {
  organisationsAdd,
  organisationsFetch,
  organisationsUpdate,
} from "../../redux/organisations/actions";
import { useTitle } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./OrganisationsController.styles";

const OrganisationsController = (props: any) => {
  const navigate = useNavigate();
  const defaultData = {
    serial: { required: false, type: "text", error: "", value: "" },
    name: { required: true, type: "text", error: "", value: "" },
    mailFrom: { required: true, type: "email", error: "", value: "" },
    mailFromName: { required: true, type: "text", error: "", value: "" },
    active: { required: false, type: "boolean", error: "", value: true },
    businessOwner: { required: true, type: "text", error: "", value: "" },
    orgImage: { required: false, type: "file", error: "", value: "" },
  };
  const defaultPermissionData = {
    organisations: false,
    users: false,
    employees: false,
    payroll: false,
    recruitsment: false,
    tickets: false,
    announcements: false,
    jobs: false,
    media: false,
    emailtemplates: false,
    groups: false,
    categories: false,
    modules: false,
    knowledgebase: false,
    drive: false,
    academyCourses: false,
    academyCandidates: false,
    academyCandidateLeads: false,
    academyBatch: false,
    academyMentorFeedback: false,
    academyStudentProfile: false,
    academyCandidateEnrollments: false,
    academyACH: false,
    // HR Workspace
    addressHistory: false,
    employmentHistory: false,
    educationDetails: false,
    form16: false,
    piiDetails: false,
    benefits: false,
    // SPS
    spsAccounts: false,
    spsAccountsContacts: false,
    spsSalesProfile: false,
    spsDeals: false,
    spsBGCheck: false,
    spsPaperwork: false,
    spsProjects: false,
    spsInsurance: false,
    // Dashboard
    dashboardAdministration: false,
    dashboardWidgets: false,
    dashboardTabs: false,
    // User
    departments: false,
    designations: false,
    country: false,
    // Core Configurations
    currency: false,
    // Knowledge
    subcategories: false,
    certifications: false,
    platformModules: false,
    // Company
    company: false,
    companyContact: false,
    // ITN Tables
    itnContact: false,
    itnServices: false,
    itnRFP: false,
    subscribers: false,
    newsletterResources: false,
    newsletterLeads: false,
    campaigns: false,
    campaignsAssets: false,
    newsLetterLeadLists: false,
    //  Catalog Administration
    catalogAdministrations: false,
    variableSets: false,
    variables: false,
    emailSchedular: false,
    serviceNowSolutions: false,
    resources: false,
    kanbanConfig: false,
    workspaces: false,
    spaces: false,
    notificationsDefinations: false,
    serviceRequests: false,
  };
  const params = useParams();
  const [id, setId] = useState("");
  const [data, setData] = useState(defaultData);
  const [permissionData, setPermissionData] = useState(defaultPermissionData);
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const [orgImage, setOrgImage] = useState("");
  useTitle("Organization");

  useEffect(() => {
    if (props.type === "edit" && params.id !== id) {
      if (props.data.length > 0) {
        const find = props.data.find((organisation: any) => organisation._id === params.id);
        if (find) {
          setId(find._id);
          HydrateData(find);
          setSubmitting(false);
          setToast({ message: "", message_type: "" });
        }
      }
    } else if (props.type === "add" && id !== "") {
      setId("");
      setData(defaultData);
      setPermissionData(defaultPermissionData);
      setSubmitting(false);
      setToast({ message: "", message_type: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, params.id, props.type]);

  const HydrateData = (rehydrateData) => {
    const object = { ...data };
    Object.keys(data).forEach((item) => {
      if (defaultData[item]) {
        object[item] = {
          ...object[item],
          value: rehydrateData[item],
        };
      }
    });
    setPermissionData(rehydrateData.permissions);
    setData(object);
  };

  const __handlerSetData = (name: any, value: any) => {
    // place verifier here
    setData({
      ...data,
      [name]: {
        ...data[name],
        value: value,
      },
    });
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    __handlerSetData(name, value);
  };

  const onPermissionChange = (e) => {
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    setPermissionData({
      ...permissionData,
      [e.target.name]: value,
    });
  };

  const onSubmitAddForm = () => {
    setSubmitting(true);
    const formData = {
      name: data?.name?.value,
      mailFrom: data.mailFrom.value,
      mailFromName: data.mailFromName.value,
      permissions: permissionData,
      businessOwner: data.businessOwner.value,
      orgImage: data?.orgImage?.value,
    };
    props.organisationsAdd({
      data: formData,
      onSuccess: (wow) => {
        props.organisationsFetch({
          onSuccess: (wow1) => {
            navigate("/ap/organisations");
          },
          onError: (error) => {
            setSubmitting(false);
            setToast({
              message: "Added Successfully, Please reload page",
              message_type: "warning",
            });
          },
        });
      },
      onError: (err) => {
        setSubmitting(false);
        setToast({
          message: err,
          message_type: "error",
        });
      },
    });
  };

  const onSubmitUpdateForm = () => {
    setSubmitting(true);
    const formData = {
      name: data.name.value,
      mailFrom: data.mailFrom.value,
      mailFromName: data.mailFromName.value,
      permissions: permissionData,
      businessOwner: data.businessOwner.value,
      orgImage: data.orgImage.value,
    };
    props.organisationsUpdate({
      id: id,
      data: formData,
      onSuccess: (wow) => {
        props.organisationsFetch({
          onSuccess: (wow1) => {
            setSubmitting(false);
            setToast({
              message: "Updated Successfully",
              message_type: "success",
            });
          },
          onError: (error) => {
            setSubmitting(false);
            setToast({
              message: "Updated Successfully, Please reload page",
              message_type: "warning",
            });
          },
        });
      },
      onError: (err) => {
        setSubmitting(false);
        setToast({
          message: err,
          message_type: "error",
        });
      },
    });
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      type: "public",
      onSuccess: ({ id, location }) => {
        setOrgImage(location);
        setData({
          ...data,
          [name]: {
            ...data[name],
            value: id,
            error: ``,
          },
        });
      },
      onError: (err) => {
        setData({
          ...data,
          [name]: {
            ...data[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setData({
          ...data,
          [name]: {
            ...data[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };
  return (
    <styles.MainContainer>
      <Header title={strings.headerTitle.organisationManage} />
      <styles.Header>
        <h2>Organization {props.type === "edit" ? "Edit" : "Add"}</h2>
      </styles.Header>
      <Toast type={toast.message_type} message={toast.message} />
      <Input
        readOnly={props.superAdmin ? false : true}
        label={"Full Name"}
        type={"text"}
        name='name'
        value={data.name.value}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={data?.name?.error}
        placeholder={undefined}
        onMessageClick={undefined}
      />
      <Input
        readOnly={false}
        label={"Email for notification"}
        type={"email"}
        name='mailFrom'
        value={data.mailFrom.value}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={data?.mailFrom?.error}
        placeholder={undefined}
        onMessageClick={undefined}
      />
      <Input
        readOnly={false}
        label={"Name for email notification"}
        type={"text"}
        name='mailFromName'
        value={data.mailFromName.value}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={data?.mailFromName?.error}
        placeholder={undefined}
        onMessageClick={undefined}
      />
      <Input
        readOnly={false}
        label={"Business Owner"}
        type={"text"}
        name='businessOwner'
        value={data.businessOwner.value}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={data?.businessOwner?.error}
        placeholder={undefined}
        onMessageClick={undefined}
      />
      {orgImage && (
        <img
          style={{
            height: "100px",
            width: "100%",
            margin: "2%",
            objectFit: "contain",
          }}
          alt='contain'
          src={orgImage}
        />
      )}
      <Input
        label={"Attach Image"}
        type={"file"}
        name='orgImage'
        message={false}
        autoComplete={"off"}
        onChange={onFileChange}
        mandatory={true}
        error={data?.orgImage?.error}
        value={""}
      />
      <div
        style={{
          pointerEvents: props.superAdmin || props.OGAdmin ? "auto" : "none",
        }}
      >
        <h3>Active Plugins</h3>
        <br />
        <Input
          label={"Organization"}
          type={"checkbox"}
          name='organisations'
          value={permissionData.organisations}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Users"}
          type={"checkbox"}
          name='users'
          value={permissionData.users}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Employees"}
          type={"checkbox"}
          name='employees'
          value={permissionData.employees}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Payroll"}
          type={"checkbox"}
          name='payroll'
          value={permissionData.payroll}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Recruitsment"}
          type={"checkbox"}
          name='recruitsment'
          value={permissionData.recruitsment}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Tickets"}
          type={"checkbox"}
          name='tickets'
          value={permissionData.tickets}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Announcements"}
          type={"checkbox"}
          name='announcements'
          value={permissionData.announcements}
          autoComplete={"off"}
          onChange={onPermissionChange}
          readOnly={false}
          attributes={{}}
          mandatory={undefined}
        />
        <Input
          label={"Jobs Posting"}
          type={"checkbox"}
          name='jobs'
          value={permissionData.jobs}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Media"}
          type={"checkbox"}
          name='media'
          value={permissionData.media}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Groups"}
          type={"checkbox"}
          name='groups'
          value={permissionData.groups}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Email Templates"}
          type={"checkbox"}
          name='emailtemplates'
          value={permissionData.emailtemplates}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Categories"}
          type={"checkbox"}
          name='categories'
          value={permissionData.categories}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Sub Categories"}
          type={"checkbox"}
          name='subcategories'
          value={permissionData.subcategories}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Modules"}
          type={"checkbox"}
          name='modules'
          value={permissionData.modules}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Knowledgebase"}
          type={"checkbox"}
          name='knowledgebase'
          value={permissionData.knowledgebase}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Drive"}
          type={"checkbox"}
          name='drive'
          value={permissionData.drive}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Course Offerings"}
          type={"checkbox"}
          name='academyCourses'
          value={permissionData.academyCourses}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Contact Web Portal"}
          type={"checkbox"}
          name='academyCandidates'
          value={permissionData.academyCandidates}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Academy Candidate Leads"}
          type={"checkbox"}
          name='academyCandidateLeads'
          value={permissionData.academyCandidateLeads}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Academy Batch"}
          type={"checkbox"}
          name='academyBatch'
          value={permissionData.academyBatch}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Academy Candidate Enrollments"}
          type={"checkbox"}
          name='academyCandidateEnrollments'
          value={permissionData.academyCandidateEnrollments}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Academy Calls/Meetings"}
          type={"checkbox"}
          name='academyACH'
          value={permissionData.academyACH}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Academy Mentor Feedback"}
          type={"checkbox"}
          name='academyMentorFeedback'
          value={permissionData.academyMentorFeedback}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Academy Lerner's Profile"}
          type={"checkbox"}
          name='academyStudentProfile'
          value={permissionData.academyStudentProfile}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Employment History"}
          type={"checkbox"}
          name='employmentHistory'
          value={permissionData.employmentHistory}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Address History"}
          type={"checkbox"}
          name='addressHistory'
          value={permissionData.addressHistory}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Education Details"}
          type={"checkbox"}
          name='educationDetails'
          value={permissionData.educationDetails}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Benefits"}
          type={"checkbox"}
          name='benefits'
          value={permissionData.benefits}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"PII Details"}
          type={"checkbox"}
          name='piiDetails'
          value={permissionData.piiDetails}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Form 16"}
          type={"checkbox"}
          name='form16'
          value={permissionData.form16}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Accounts"}
          type={"checkbox"}
          name='spsAccounts'
          value={permissionData.spsAccounts}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Accounts Contact"}
          type={"checkbox"}
          name='spsAccountsContacts'
          value={permissionData.spsAccountsContacts}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Sales Profile"}
          type={"checkbox"}
          name='spsSalesProfile'
          value={permissionData.spsSalesProfile}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Deals"}
          type={"checkbox"}
          name='spsDeals'
          value={permissionData.spsDeals}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS BG Check"}
          type={"checkbox"}
          name='spsBGCheck'
          value={permissionData.spsBGCheck}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Paperwork"}
          type={"checkbox"}
          name='spsPaperwork'
          value={permissionData.spsPaperwork}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Variable Sets"}
          type={"checkbox"}
          name='variableSets'
          value={permissionData.variableSets}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Email Schedular"}
          type={"checkbox"}
          name='emailSchedular'
          value={permissionData.emailSchedular}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Service Now Solutions"}
          type={"checkbox"}
          name='serviceNowSolutions'
          value={permissionData.serviceNowSolutions}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Resources"}
          type={"checkbox"}
          name='resources'
          value={permissionData.resources}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Subscribers"}
          type={"checkbox"}
          name='subscribers'
          value={permissionData.subscribers}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Newsletter Resources"}
          type={"checkbox"}
          name='newsletterResources'
          value={permissionData.newsletterResources}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Newsletter Leads"}
          type={"checkbox"}
          name='newsletterLeads'
          value={permissionData.newsletterLeads}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Campaigns"}
          type={"checkbox"}
          name='campaigns'
          value={permissionData.campaigns}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Campaigns Assets"}
          type={"checkbox"}
          name='campaignsAssets'
          value={permissionData.campaignsAssets}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"NewsLetter Lead Lists"}
          type={"checkbox"}
          name='newsLetterLeadLists'
          value={permissionData.newsLetterLeadLists}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Variables"}
          type={"checkbox"}
          name='variables'
          value={permissionData.variables}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Projects"}
          type={"checkbox"}
          name='spsProjects'
          value={permissionData.spsProjects}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"SPS Insurance"}
          type={"checkbox"}
          name='spsInsurance'
          value={permissionData.spsInsurance}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Dashboard Configs"}
          type={"checkbox"}
          name='dashboardAdministration'
          value={permissionData.dashboardAdministration}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Dashboard Tabs"}
          type={"checkbox"}
          name='dashboardTabs'
          value={permissionData.dashboardTabs}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Dashboard Widgets"}
          type={"checkbox"}
          name='dashboardWidgets'
          value={permissionData.dashboardWidgets}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Departments"}
          type={"checkbox"}
          name='departments'
          value={permissionData.departments}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Designations"}
          type={"checkbox"}
          name='designations'
          value={permissionData.designations}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Country"}
          type={"checkbox"}
          name='country'
          value={permissionData.country}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Currency"}
          type={"checkbox"}
          name='currency'
          value={permissionData.currency}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Platform Modules"}
          type={"checkbox"}
          name='platformModules'
          value={permissionData.platformModules}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Certifications"}
          type={"checkbox"}
          name='certifications'
          value={permissionData.certifications}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Company"}
          type={"checkbox"}
          name='company'
          value={permissionData.company}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />{" "}
        <Input
          label={"Company Contact"}
          type={"checkbox"}
          name='companyContact'
          value={permissionData.companyContact}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Contact US"}
          type={"checkbox"}
          name='itnContact'
          value={permissionData.itnContact}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"RFP"}
          type={"checkbox"}
          name='itnRFP'
          value={permissionData.itnRFP}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"ServiceNow Services"}
          type={"checkbox"}
          name='itnServices'
          value={permissionData.itnServices}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Catalog Administration"}
          type={"checkbox"}
          name='catalogAdministrations'
          value={permissionData.catalogAdministrations}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Kanban Config"}
          type={"checkbox"}
          name='kanbanConfig'
          value={permissionData.kanbanConfig}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Workspaces"}
          type={"checkbox"}
          name='workspaces'
          value={permissionData.workspaces}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Spaces"}
          type={"checkbox"}
          name='spaces'
          value={permissionData.spaces}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Notifications Definations"}
          type={"checkbox"}
          name='notificationsDefinations'
          value={permissionData.notificationsDefinations}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
        <Input
          label={"Service Requests"}
          type={"checkbox"}
          name='serviceRequests'
          value={permissionData.serviceRequests}
          autoComplete={"off"}
          onChange={onPermissionChange}
        />
      </div>
      <Button
        onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        disabled={submitting}
        size={"normal"}
        label={"Save"}
        block={true}
        attributes={{}}
        inactive={false}
        type={"primary"}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  data: state.OrganisationsReducer.list,
  OGAdmin: state.UserReducer.organisationAdmin,
  superAdmin: state.UserReducer.superAdmin,
});

const mapDispatchToProps = {
  organisationsAdd,
  organisationsUpdate,
  organisationsFetch,
  uploadFile,
  getFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsController);
