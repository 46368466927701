import styled from "styled-components";

const styles = {};

styles.MainContainer = styled.div`
  height: 100%;
`;

styles.Header = styled.div`
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }
  align-items: center;
  margin-bottom: 1vh;
`;

export default styles;
