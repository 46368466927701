import { useEffect } from "react";

import moment from "moment-timezone";
import { OptionGroup } from "react-querybuilder";

import config from "../config";
import { store } from "../redux/store";
import { images } from "../theme";
import { AttributesType, ColumnsType, SelectOptions } from "../types";

export const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = config.sitetitle + " - " + title;
    return () => {
      document.title = prevTitle;
    };
  }, []);
};

export const AttributesValues = (name: string, id: any) => {
  const state = store.getState();
  return state.ApplicationReducer?.attributes?.find(
    (item: any) => item.name === name && item._id === id,
  )?.value;
};

export const getAttributValues = (name: string, none = false): SelectOptions[] => {
  const state = store.getState();
  const attributes: AttributesType[] =
    state?.ApplicationReducer?.configurations?.controllerConfigs?.attributes
      ?.filter((item) => item.name === name)
      .sort((a, b) => (a.value < b.value ? -1 : 1));
  const result: SelectOptions[] = [];
  if (none) {
    result.push({
      label: "None",
      value: null,
    });
  }
  attributes.map((item) => {
    if (item.active) {
      result.push({
        label: item.label,
        value: item._id,
      });
    }
  });
  return result;
};

export const isEqualArrays = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

// Export items methods
export const createXMLTable = (table, fileName) => {
  const xmlTable = `
          <html xmlns:o="urn:schemas-microsoft-com:office:office xmlns:x="urn:schemas-microsoft-com:office:excel"
         xmlns="http://www.w3.org/TR/REC-html40"
          >
             <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
             <head>
                <xml>
                  <x:ExcelWorkbook>
                      <x:ExcelWorksheets>
                          <x:ExcelWorksheet>
                              <x:Name>${fileName}</x:Name>
                              <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                          </x:ExcelWorksheet>
                      </x:ExcelWorksheets>
                  </x:ExcelWorkbook>
                </xml>
             </head>
             <body>
               ${table}
             </body>
          </html> `;
  return xmlTable;
};
export const createFileUrl = (xmlTable) => {
  const tableBlob = new Blob([xmlTable], { type: "application/vnd.ms-excel;base64" });
  const downloadURL = URL.createObjectURL(tableBlob);
  return downloadURL;
};
export const downloadFile = (columnHeaders, records, fileName) => {
  const csvContent = [
    columnHeaders.map((item) => item.label).join(","),
    ...records.map((obj) => columnHeaders.map((item) => obj[item.key])).map((row) => row.join(",")),
  ].join("\n");

  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: "text/csv" });
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);
  // Create a download link
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.csv`);
  // Trigger the download link
  document.body.appendChild(link);
  link.click();
  // Clean up by revoking the URL
  window.URL.revokeObjectURL(url);
};
export const convertArrayToTable = async (apiArray, tableFields) => {
  //use keys from the first array object to form table column headers
  const tableHeaders = `<tr>${Object.keys(apiArray[0])
    .map((key) => {
      const label = key === "_id" ? "ID" : tableFields.find((item) => item.key === key)?.label;
      return `<td>${label}</td>`;
    })
    .join("")}</tr>`;
  //now loop through all array objects to form table rows
  const tableRows = apiArray
    .map((obj) => [
      `<tr>
          ${Object.keys(obj)
            .map((key) => `<td>${obj[key] === null || obj[key] === "" ? "" : obj[key]}</td>`)
            .join("")}
       <tr/>`,
    ])
    .join("");
  const table = `<table>${tableHeaders}${tableRows}</table>`.trim();
  return table;
};

export const sortTableListColumns = (sortOrder: string[], items: object[]): ColumnsType[] => {
  // columns.sort((a, b) => {
  //   const aKey = Object.keys(a)[0];
  //   const bKey = Object.keys(b)[0]?.key;
  return items.sort(function (a, b) {
    return sortOrder.indexOf(a?.key) - sortOrder.indexOf(b?.key);
  });
  // });
  // return columns;
};

export const daysBetweenDate = (first: Date, second: Date) => {
  const timeDiff = second.getTime() - first.getTime();
  return Math.round(timeDiff / (1000 * 60 * 60 * 24));
};

export const getAttributValuesBynameId = (name: string, id: any): string => {
  const state = store.getState();
  const attribute = state?.ApplicationReducer?.configurations?.controllerConfigs?.attributes?.find(
    (item: any) => item.name === name && item._id === id,
  );
  return attribute?.label || attribute?.name;
};

export const generateArrayAttributes = (name: string) => {
  return store
    .getState()
    .ApplicationReducer?.attributes?.filter((item) => item.name === name)
    .sort((a, b) => (a.value < b.value ? -1 : 1));
};

export const formValidationMethod = (name: any, value: any, validation: any) => {
  if (validation[name]) {
    const validationParam = validation[name];
    if (validationParam.required && value === "") {
      return "please enter value";
    } else {
      switch (validationParam.type) {
        case "fullname":
          var length = value.split(" ").length;
          return length > 3 || length < 1 ? "invalid full name" : "";
        case "email":
          var email = value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
          return !email ? "invalid email" : "";
        default:
          return "";
      }
    }
  }
};

export const currencyFormat = (value, currency = "USD") => {
  const output = new Intl.NumberFormat(`en-${currency.slice(0, 2)}`).format(value);
  return currency + " " + output;
};

export const serialPrefixTickets = (value: number) => {
  const number = "00000000" + value;
  return "ITSR-" + number.slice(-5);
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

interface MomentDateProps {
  date: string | Date;
  format: "YYYY-MM-DD" | "YYYY-MM-DD HH:mm" | "MMMM YYYY" | "MMMM Do YYYY, h:mm:ss a";
}

export const MomentDate = ({ date, format }: MomentDateProps) => {
  const timezone = store.getState().UserReducer.timezone;
  return moment(date).tz(timezone).format(format);
};

export const Sort = ({ data, field, isDate, asc, attributeKey }) => {
  if (Array.isArray(data) && data.length > 0) {
    let sorted = [];
    sorted = data.sort((a, b) => {
      const first = attributeKey
        ? String(AttributesValues(attributeKey, a[field])).toLowerCase()
        : isDate
        ? new Date(a[field])
        : typeof a[field] !== "string"
        ? a[field]
        : a[field]?.toLowerCase();
      const second = attributeKey
        ? String(AttributesValues(attributeKey, b[field])).toLowerCase()
        : isDate
        ? new Date(b[field])
        : typeof b[field] !== "string"
        ? b[field]
        : b[field]?.toLowerCase();
      if (first < second) {
        return asc ? -1 : 1;
      }
      if (first > second) {
        return asc ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  } else {
    return data;
  }
};

export const getPrefixDigit = (item: number, digit: number) => {
  return item.toLocaleString("en-US", {
    minimumIntegerDigits: digit,
    useGrouping: false,
  });
};

export const checkRevisePermission = (knowledge, userId) => {
  const authorisedUsers = [];
  if (userId && knowledge?.cratedBy?._id === userId) {
    authorisedUsers.push(userId);
  }
  const members = knowledge?.knowledgebase?.members;
  if (Array.isArray(members)) {
    for (let i = 0; i < members.length; i++) {
      if (
        ["manager", "owner"].includes(members[i]?.role) &&
        !authorisedUsers.includes(members[i]?.user?._id)
      ) {
        authorisedUsers.push(members[i]?.user?._id);
      }
    }
    console.log(members, authorisedUsers);
  }
  return (
    knowledge?.status === "published" &&
    authorisedUsers.includes(userId) &&
    !knowledge?.revisedKnowledge
  );
};

export const getArticlePermissions = (find, userId) => {
  const createdBy = String(find.createdBy._id) === String(userId);
  let knowledgebaseRole = ""; // To check if the user is part of knowledgebase
  let groupRole = ""; // To check if the user is part of group
  //Check if the user is part of knowledgebase
  find?.knowledgebase?.members.find((member) => {
    if (String(member.user._id) === String(userId)) {
      knowledgebaseRole = member.role;
      return true;
    }
  });
  //Check if the user is part of group
  const contributers = find?.knowledgebase?.contributers;
  if (Array.isArray(contributers)) {
    for (let i = 0; i < contributers.length; i++) {
      contributers[i].members.find((member) => {
        if (String(member.user._id) === String(userId)) {
          groupRole = member.role;
          return true;
        }
      });
    }
  }
  return {
    createdBy: createdBy,
    knowledgebaseRole: knowledgebaseRole,
    groupRole: groupRole,
  };
};

export const contains = (list = [], element) => {
  list.some((elem) => {
    return JSON.stringify(element) === JSON.stringify(elem);
  });
};

export const getDropDownOptions = (options: any[] = [], showSelect = true) => {
  const items: SelectOptions[] = [];
  if (showSelect) {
    items.push({ value: "", label: "Select..." });
  }
  if (options?.length) {
    options.map((item) => {
      let output = true;
      if (item?.active === false) output = false;
      if (output) {
        items.push({
          value: item._id,
          label: item.label || item.name,
        });
      }
    });
  }
  console.log("Items ::", items);
  return items;
};

export const getCurrentFinancialYear = () => {
  const thisYear = new Date().getFullYear();
  const yearArray = [0, 1, 2, 3, 4].map(
    (count) => `${(thisYear - count - 1).toString()}-${thisYear - count}`,
  );
  return yearArray;
};

export const validDate = (date) => {
  return date ? new Date(date) : null;
};

export const validTime = (time: string) => {
  const timeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

  if (typeof time === "string" && timeRegex.test(time)) {
    const [hours, minutes, seconds] = time.split(":");
    console.log("Parsed Hours:", hours);
    console.log("Parsed Minutes:", minutes);
    console.log("Parsed Seconds:", seconds);

    const timeDate = new Date();
    timeDate.setUTCHours(parseInt(hours, 10));
    timeDate.setUTCMinutes(parseInt(minutes, 10));
    timeDate.setUTCSeconds(parseInt(seconds, 10));
    timeDate.setUTCMilliseconds(0);

    console.log("Parsed Date:", timeDate);

    return timeDate;
  }

  console.log("Invalid Time Format:", time);
  return null;
};

export const getFilterOptions = (options, label) => {
  const items: any[] = options.map((item) => {
    return {
      name: item.value,
      label: item.label,
    };
  });

  const filterOptions: OptionGroup[] = [{ label: label, options: items }].map(
    ({ label, options }) => ({
      label,
      options: options.map((s) => ({ name: s?.name, label: s?.label })),
    }),
  );

  return filterOptions;
};

export const getAttachmentFileIcon = (extension?: string) => {
  switch (extension?.toLowerCase()) {
    case "pdf":
      return images.pdfFileIcon;
    case "png":
      return images.pngFileIcon;
    case "doc":
      return images.docFileIcon;
    case "docx":
      return images.docxFileIcon;
    case "ppt":
      return images.pptFileIcon;
    case "jpeg":
      return images.jpegFileIcon;
    case "csv":
      return images.csvFileIcon;
    case "xml":
      return images.xmlFileIcon;
    case "xlsx":
      return images.xlsxFileIcon;
    case "gif":
      return images.gifFileIcon;
    case "pptx":
      return images.pptxFileIcon;
    case "jpg":
      return images.jpgFileIcon;

    case "txt":
      return images.txtFileIcon;
    case "svg":
      return images.xmlFileIcon;
    default:
      return images.defaultFileIcon;
  }
};
