/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPSACCOUNTS = "FETCH_SPSACCOUNTS",
  FETCH_SPSACCOUNTS_BY_ID = "FETCH_SPSACCOUNTS_BY_ID",
  SUCCESS_FETCH_SPSACCOUNTS = "SUCCESS_FETCH_SPSACCOUNTS",
  ERROR_FETCH_SPSACCOUNTS = "ERROR_FETCH_SPSACCOUNTS",
  CREATE_SPSACCOUNTS = "CREATE_SPSACCOUNTS",
  UPDATE_SPSACCOUNTS = "UPDATE_SPSACCOUNTS",
  DELETE_SPSACCOUNTS = "DELETE_SPSACCOUNTS",
}

export default Types;
