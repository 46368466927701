import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ModulesAdd,
  ModulesFetch,
  ModulesUpdate,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchModulesSuccess } from "./actions";
import Types from "./types";

function* createWorker(action) {
  try {
    const options = {
      method: "POST",
      url: ModulesAdd,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: ModulesUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getAllWorker(action) {
  try {
    const options = {
      method: "GET",
      url: ModulesFetch,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchModulesSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* Saga() {
  yield takeLatest(Types.MODULES_ADD, createWorker);
  yield takeLatest(Types.MODULES_UPDATE, updateWorker);
  yield takeEvery(Types.MODULES_FETCH, getAllWorker);
}
