import { ColumnsType, SelectOptions } from "../types";

export const DOTS = "...";

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const phoneWithCountryCode = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

export const validatePhone = (number: string) => {
  const prefix = number[0];
  const onlyDigits = number.slice(1);
  if (!number.startsWith("+")) return false;
  if (!/^\d+$/.test(onlyDigits)) return false;
  return true;
};

export const sendgridSenders: SelectOptions[] = [
  {
    label: "brijesh.m@itnowinc.com",
    value: 5787402,
  },
  {
    label: "no_reply@itnowinc.com",
    value: 4011225,
  },
];

export const validateDate = (number: number) => {
  if (number >= 0 && number <= 31) {
    // Number is within the valid range
    return true;
  } else {
    // Number is outside the valid range
    return false;
  }
};

const attributeFields = {
  recordActive: {
    label: "recordActive",
    value: "recordActive",
    edit: false,
    add: false,
  },
};

export const collections = {
  // HR Workspace
  employees: {
    name: "employees",
    label: "Employees",
    displayField: "name",
    attributeFields: [attributeFields.country],
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  benefits: {
    name: "benefits",
    label: "Benefits",
    displayField: "type",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  newsletterResources: {
    name: "NewsletterResources",
    label: "Newsletter Resources",
    displayField: "type",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  groups: {
    name: "groups",
    label: "Groups",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: false,
    spaceSupported: true,
  },
  projectHistory: {
    name: "projectHistory",
    label: "Project Employee Allocation",
    displayField: "type",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  designations: {
    name: "designations",
    label: "Designations",
    displayField: "type",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  form16: {
    name: "form16",
    label: "Form 16",
    displayField: "",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  addressHistory: {
    name: "addressHistory",
    label: "Address History",
    displayField: "",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  educationDetails: {
    name: "educationDetails",
    label: "Education Details",
    displayField: "",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  employmentHistory: {
    name: "employmentHistory",
    label: "Employment History",
    displayField: "",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  announcements: {
    name: "announcements",
    label: "Announcements",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  attributes: {
    name: "attributes",
    label: "Attributes",
    displayField: "value",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: false,
    spaceSupported: true,
  },
  departments: {
    name: "departments",
    label: "Departments",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  storage: {
    name: "storage",
    label: "Storage",
    displayField: "",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  company: {
    name: "company",
    label: "Company",
    displayField: "company",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  // Academy
  academyCourses: {
    name: "academyCourses",
    label: "Course Offerings",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyCandidates: {
    name: "academyCandidates",
    label: "Contact Web Portal",
    displayField: "firstName",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyCandidateLeads: {
    name: "academyCandidateLeads",
    label: "Candidate Leads",
    displayField: "firstName",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyBatch: {
    name: "academyBatch",
    label: "Batch",
    displayField: "activityAgenda",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyCandidateEnrollments: {
    name: "academyCandidateEnrollments",
    label: "Candidate Enrollments",
    displayField: "studentName",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyACH: {
    name: "academyACH",
    label: "Calls/Meetings Activities",
    displayField: "activityAgenda",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyStudentProfile: {
    name: "academyStudentProfile",
    label: "Lerner's Profile",
    displayField: "studentName",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  achTask: {
    name: "achTask",
    label: "ACH Task",
    displayField: "studentName",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  academyMentorFeedback: {
    name: "academyMentorFeedback",
    label: "Mentor's Feedback",
    displayField: "mentor",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  // Dashbaord
  dashboardAdministration: {
    name: "dashboardAdministration",
    label: "Config",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  dashboardTabs: {
    name: "dashboardTabs",
    label: "Tabs",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  dashboardWidgets: {
    name: "dashboardWidgets",
    label: "Reports",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  // Users
  users: {
    name: "users",
    label: "Users",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: false,
    spaceSupported: true,
  }, // Knowledge
  knowledgebase: {
    name: "knowledgebase",
    label: "Knowledgebase",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: false,
    spaceSupported: true,
  },
  categories: {
    name: "categories",
    label: "Categories",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  leadLists: {
    name: "leadLists",
    label: "Lead Lists",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  subcategories: {
    name: "subCategories",
    label: "Sub Categories",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  modules: {
    name: "modules",
    label: "Tags",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  certificationsAccreditations: {
    name: "certificationsAccreditations",
    label: "Certifications Accreditations",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  platformModules: {
    name: "platformModules",
    label: "Platform Modules",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  checklistTemplate: {
    name: "checklistTemplate",
    label: "Checklist Template",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  companyContact: {
    name: "companyContact",
    label: "Company Contact",
    displayField: "firstName",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  currency: {
    name: "currency",
    label: "Currency",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  country: {
    name: "country",
    label: "Country",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  itnServices: {
    name: "itnServices",
    label: "ServiceNow Services",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  itnRFP: {
    name: "itnRFP",
    label: "ITN RFP",
    displayField: "fullName",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  companyBankDetails: {
    name: "companyBankDetails",
    label: "Company Bank Details",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  companyTask: {
    name: "companyTask",
    label: "Company Task",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  dashboardTab: {
    name: "dashboardTab",
    label: "Dashboard Tab",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  dashboardWidget: {
    name: "dashboardWidget",
    label: "Dashboard Widget",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  drive: {
    name: "drive",
    label: "Drive",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  emailtemplates: {
    name: "emailtemplates",
    label: "Email Templates",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  employeeBankDetails: {
    name: "employeeBankDetails",
    label: "Employee Bank Details",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  bankdetails: {
    name: "bankdetails",
    label: "Bank Details",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  jobs: {
    name: "jobs",
    label: "Jobs",
    displayField: "title",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  knowledge: {
    name: "knowledge",
    label: "Knowledge",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: false,
    spaceSupported: true,
  },
  media: {
    name: "media",
    label: "Media",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  organisations: {
    name: "organisations",
    label: "Organisations",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  payrolls: {
    name: "payrolls",
    label: "Payrolls",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  piiDetails: {
    name: "piiDetails",
    label: "Pii Details",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  recruitment: {
    name: "recruitment",
    label: "Recruitment",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsAccounts: {
    name: "spsAccounts",
    label: "SPS Accounts",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsAccountContact: {
    name: "spsAccountContact",
    label: "SPS Accounts Contacts",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsBGCheck: {
    name: "spsBGCheck",
    label: "SPS BG Check",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsDeals: {
    name: "spsDeals",
    label: "SPS Deals",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsInsurance: {
    name: "spsInsurance",
    label: "SPS Insurance",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsPaperwork: {
    name: "spsPaperwork",
    label: "SPS Paper Work",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsProjects: {
    name: "spsProjects",
    label: "SPS Projects",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spsSalesProfile: {
    name: "spsSalesProfile",
    label: "SPS Sales Profile",
    displayField: "name",
    dashboardSupported: true,
    notificationsDefinintionsSupported: true,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  task: {
    name: "task",
    label: "Task",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  tickets: {
    name: "tickets",
    label: "Tickets",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  itnContact: {
    name: "itnContact",
    label: "Contact US",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  catalogAdministrations: {
    name: "catalogAdministrations",
    label: "Catalog Administration",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  variables: {
    name: "variables",
    mongooseModel: "Variables",
    label: "Variables",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  resources: {
    name: "resources",
    mongooseModel: "Resources",
    label: "Resources",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  variableSets: {
    name: "variableSets",
    mongooseModel: "VariableSets",
    label: "Variable Sets",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  serviceNowSolutions: {
    name: "serviceNowSolutions",
    mongooseModel: "ServiceNowSolutions",
    label: "Service Now Solutions",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  // Newsletter
  subscribers: {
    name: "subscribers",
    mongooseModel: "Subscribers",
    label: "Resources",
    displayField: "email",
  },
  newsletterResources: {
    name: "newsletterResources",
    mongooseModel: "Newsletter Resources",
    label: "NewsletterResources",
    displayField: "name",
  },
  newsletterLeads: {
    name: "newsletterLeads",
    mongooseModel: "Newsletter Leads",
    label: "NewsletterLeads",
    displayField: "firstName",
  },
  campaigns: {
    name: "campaigns",
    mongooseModel: "Campaigns",
    label: "Campaigns",
    displayField: "name",
  },
  campaignsAssets: {
    name: "campaignsAssets",
    mongooseModel: "Campaigns Assets",
    label: "CampaignsAssets",
    displayField: "name",
  },
  newsLetterLeadLists: {
    name: "newsLetterLeadLists",
    mongooseModel: "NewsLetter Lead Lists",
    label: "NewsLetter Lead Lists",
    displayField: "name",
  },
  // Notifications
  emailSchedular: {
    name: "emailSchedular",
    mongooseModel: "EmailSchedular",
    label: "Email Schedular",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },

  // Workspace
  workspaces: {
    name: "workspaces",
    mongooseModel: "Workspace",
    label: "Workspaces",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  spaces: {
    name: "spaces",
    mongooseModel: "Spaces",
    label: "Spaces",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },

  kanbanConfig: {
    name: "kanbanConfig",
    label: "Kanban Config",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },

  // Notification Definitions
  notificationsDefinations: {
    name: "notificationsDefinations",
    mongooseModel: "notifiactionsDefinitions",
    label: "Notifiactions Definitions",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
  // Service Requests
  serviceRequests: {
    name: "serviceRequests",
    mongooseModel: "serviceRequests",
    label: "Service Requests",
    displayField: "name",
    dashboardSupported: false,
    notificationsDefinintionsSupported: false,
    kanbanSupported: false,
    workspaceSupported: true,
    spaceSupported: true,
  },
};

export const ActiveSelectOptions: SelectOptions[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const ITNContactUSOptions: SelectOptions[] = [
  { label: "ITnow Inc", value: "itnow" },
  { label: "DCH", value: "dch" },
];

export const AnnouncementsSelectOptions: SelectOptions[] = [
  { value: "ucb", label: "UCB" },
  { value: "itnowWebsite", label: "ITnow Website" },
];
export const AnnouncementsCategoriesOptions: SelectOptions[] = [
  { value: "event", label: "Event" },
  { value: "product", label: "Product" },
];
export const NewsletterResourcesStatus: SelectOptions[] = [
  { value: "draft", label: "Draft" },
  { value: "inreview", label: "In Review" },
  { value: "published", label: "Published" },
  { value: "sent", label: "Sent" },
];
export const VerticalSelectOptions: SelectOptions[] = [{ label: "ITnow", value: "itnow" }];
export const sectionAttachmentSelectOptions: SelectOptions[] = [
  { label: "Section Content", value: "sectionImageLink" },
  { label: "Youtube Video", value: "sectionVideoLink" },
  { label: "Quote", value: "quote" },
];
export const ServiceNowSelectOptions: SelectOptions[] = [
  { label: "Content", value: "content" },
  { label: "Demo", value: "demo" },
  { label: "Resource", value: "resource" },
];

export const resourcesTypeSelectOptions: SelectOptions[] = [
  { label: "Whitepaper", value: "whitepaper" },
  { label: "Case Studies", value: "caseStudies" },
  { label: "Blogs", value: "blogs" },
];
export const resourcesVerticalSelectOptions: SelectOptions[] = [
  { label: "Academy", value: "academy" },
  { label: "ServiceNow", value: "serviceNow" },
  { label: "Studios", value: "studios" },
  { label: "CloudSpace", value: "cloudSpace" },
];

export const FrequencySelectOptions: SelectOptions[] = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Periodically", value: "periodically" },
];

export const DaySelectOptions: SelectOptions[] = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];
export const EditUpdateAddSelectOptions: SelectOptions[] = [
  { label: "Add", value: "add" },
  { label: "Update", value: "update" },
  { label: "Delete", value: "delete" },
];
// export const ServiceNowSelectOptions: SelectOptions[] = [
//   { label: "Content", value: "content" },
//   { label: "Demo", value: "demo" },
//   { label: "Resource", value: "resource" },
// ];

// export const resourcesTypeSelectOptions: SelectOptions[] = [
//   { label: "Whitepaper", value: "whitepaper" },
//   { label: "Case Studies", value: "caseStudies" },
//   { label: "Blogsoob", value: "blogsoob" },
// ];

// export const FrequencySelectOptions: SelectOptions[] = [
//   { label: "Daily", value: "daily" },
//   { label: "Weekly", value: "weekly" },
//   { label: "Monthly", value: "monthly" },
//   { label: "Periodically", value: "periodically" },
// ];

// export const DaySelectOptions: SelectOptions[] = [
//   { label: "Monday", value: "monday" },
//   { label: "Tuesday", value: "tuesday" },
//   { label: "Wednesday", value: "wednesday" },
//   { label: "Thursday", value: "thursday" },
//   { label: "Friday", value: "friday" },
//   { label: "Saturday", value: "saturday" },
//   { label: "Sunday", value: "sunday" },
// ];

export const BankDetailsAssociatedTo: SelectOptions[] = [
  { label: "Employee", value: "employee" },
  { label: "Company", value: "company" },
];

export const TaskAssociatedTo: SelectOptions[] = [
  { label: "Employee", value: "employee" },
  { label: "Company", value: "company" },
  // { label: "Project History", value: "projectHistory" },
];

export const StatusSelectOptions: SelectOptions[] = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const DashboardFilterTableOptions: SelectOptions[] = [
  { label: "Employees", value: "employees" },
  { label: "Course Offerings", value: "academyCourses" },
  // { label: "Users", value: "users" },
  // { label: "Contact Web Portal", value: "academyCandidates" },
  // { label: "Academy Candidate Leads", value: "academyCandidateLeads" },
  // { label: "Academy Batch", value: "academyBatch" },
  // { label: "Academy Candidate Enrollments", value: "academyCandidateEnrollments" },
  // { label: "Lerner's Profile", value: "academyStudentProfile" },
  // { label: "Mentor's Feedback", value: "academyMentorFeedback" },
  // { label: "Calls/Meetings Activities", value: "academyACH" },
];
export const DashboardWidgetTypeOptions: SelectOptions[] = [
  { label: "List", value: "list" },
  { label: "Count", value: "count" },
];

export const UsersWidgetFields: SelectOptions[] = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Active", value: "active" },
];

export const UsersWidgetFilters: SelectOptions[] = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Active", value: "active" },
];
export const PositionOptions: SelectOptions[] = [
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" },
  { label: "Right", value: "right" },
  { label: "Left", value: "left" },
];

export const TypeOptions: SelectOptions[] = [
  { label: "String", value: "string" },
  { label: "Date/Time", value: "dateTime" },
  { label: "Boolean", value: "boolean" },
  { label: "Checkbox", value: "top" },
  { label: "Reference", value: "reference" },
  { label: "Choice", value: "choice" },
];

export const EmployeesWidgetFields: SelectOptions[] = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Mobile", value: "mobile" },
  { label: "Employment Status", value: "employmentStatus" },
  { label: "Employment Type", value: "employmentType" },
  { label: "Employment Department", value: "employmentDepartment" },
  { label: "Google Account Created", value: "accountGoogleCreated" },
  { label: "Veem Account Created", value: "accountVeemCreated" },
  { label: "NationalId Type", value: "nationalIdType" },
  { label: "Personal Gender", value: "personalGender" },
  { label: "Personal Martial", value: "personalMartial" },
];

export const EmployeesWidgetFilters: SelectOptions[] = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Employment Status", value: "employmentStatus" },
  { label: "Employee Type", value: "employeeType" },
];

export const StringOperators: SelectOptions[] = [
  { label: "Equals To", value: "equalsTo" },
  { label: "Not Equals To", value: "notEqualsTo" },
  { label: "Starts With", value: "startsWith" },
  { label: "Ends With", value: "endsWith" },
  { label: "Contains", value: "contains" },
  { label: "Not Contains", value: "notContains" },
];

export const BooleanOperators: SelectOptions[] = [{ label: "Equals To", value: "equalsTo" }];

export const StringFiltersFields = ["name", "email"];
export const BooleanFiltersFields = ["active"];
export const attributesFiltersFields = [
  "employmentStatus",
  "employeeType",
  "employmentDepartment",
  "technology",
];

export const dashboardColumnsTitle = {
  name: "Name",
  email: "Email",
  mobile: "Mobile",
  active: "Active",
  employmentStatus: "Employment Status",
  employmentType: "Employment Type",
  employmentDepartment: "Employment Department",
  accountGoogleCreated: "Google Account Created",
  accountVeemCreated: "Veem Account Created",
  nationalIdType: "NationalId Type",
  personalGender: "Personal Gender",
  personalMartial: "Personal Martial",
};
export const userPermissions = [
  "Users",
  "Employees",
  "Recruitment",
  "Payrolls",
  "Announcements",
  // "Tickets",
  "Jobs",
  "Media",
  "Email Templates",
  "Categories",
  "Groups",
  "Knowledge Base",
  "Modules",
  "Organisations",
];

export const groupsPermissions = [
  "Organisations",
  "Users", //add, edit, list, view
  "Employees", //add, edit, list, view
  "Recruitment", //add, edit, list, view, delete, rollback
  "Payrolls", //view edit
  "Announcements",
  //next 1
  // "Tickets",
  "Jobs",
  "Media",
  "Groups",
  "Modules",
  "Categories",
  "Subcategories",
  "Knowledgebase",
  "Emailtemplates", //edit, list
  "Knowledge", //list
  "SPSAccounts",
  "SPSAccountscontacts",
  "SPSSalesprofile",
  "SPSDeals",
  "SPSBGcheck",
  "SPSPaperwork",
  "SPSProjects",
  "SPSInsurance",
  //next 3
  // "DashboardAdministration",
  // "DashboardWidget",
  // "DashboardTab",
];

export const tablePublicFields = {
  users: [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "superAdmin", label: "Unity Admin" },
    { key: "active", label: "Active" },
    { key: "admin", label: "Admin" },
    { key: "organisationAdmin", label: "Organisation Admin" },
    { key: "accessLevel", label: "Access Level" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyCourses: [
    { key: "name", label: "Name" },
    { key: "shortDescription", label: "Short Description" },
    { key: "numberOfTopics", label: "Number Of Topics" },
    { key: "description", label: "Description" },
    { key: "downloadableResources", label: "Downloadable Resources" },
    { key: "cardIconAttached", label: "Card Icon Attached" },
    { key: "cardBackgroundAttached", label: "Card Background Attached" },
    { key: "courseDetailsIconAttached", label: "Course Details Icon Attached" },
    { key: "prerequisites", label: "Prerequisites" },
    { key: "anyDriveURL", label: "Any Drive URL" },
    { key: "active", label: "Active" },
    { key: "technology", label: "Technology" },
    { key: "preparedBy", label: "Prepared By" },
    { key: "curriculamAttached", label: "Curriculum Attached" },
    { key: "courseDuration", label: "Duration" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyCandidates: [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    // { key: "active", label: "Active" },
    { key: "status", label: "Status" },
    { key: "course", label: "Course" },
    { key: "dob", label: "Dob" },
    { key: "source", label: "Source" },
    { key: "admissionCounsellor", label: "Admission Counsellor" },
    { key: "graduationYear", label: "Graduation Year" },
    { key: "phone", label: "Phone" },
    { key: "email", label: "Email" },
    { key: "country", label: "Country" },
    { key: "city", label: "City" },
    { key: "state", label: "State" },
    { key: "graduationDegree", label: "Graduation Degree" },
    { key: "graduationMajor", label: "Graduation Major" },
    { key: "linkedInURL", label: "linkedInURL" },
    { key: "stage", label: "Stage" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyCandidateLeads: [
    { key: "firstName", label: "First Name" },
    { key: "lastName", label: "Last Name" },
    // { key: "active", label: "Active" },
    { key: "status", label: "Status" },
    { key: "course", label: "Course" },
    { key: "dob", label: "Dob" },
    { key: "source", label: "Source" },
    { key: "admissionCounsellor", label: "Admission Counsellor" },
    { key: "graduationYear", label: "Graduation Year" },
    { key: "phone", label: "Phone" },
    { key: "email", label: "Email" },
    { key: "country", label: "Country" },
    { key: "stage", label: "Stage" },
    { key: "associatedContact", label: "Associated Contact" },
    { key: "currency", label: "Currency" },
    { key: "city", label: "City" },
    { key: "state", label: "State" },
    { key: "graduationDegree", label: "Graduation Degree" },
    { key: "graduationMajor", label: "Graduation Major" },
    { key: "linkedInURL", label: "LinkedIn URL" },
    { key: "sourceContactDate", label: "Source Contact Date" },
    { key: "saleDate", label: "Sale Date" },
    { key: "enrollmentSpecialists", label: "Enrollment Specialists" },
    { key: "paymentTerms", label: "Payment Terms" },
    { key: "totalFees", label: "Total Fees" },
    { key: "anyDiscountsGiven", label: "Any Discounts Given" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyBatch: [
    { key: "batchNumber", label: "Batch Number" },
    { key: "courseOfferings", label: "Course" },
    { key: "state", label: "State" },
    { key: "totalStudents", label: "Total Students" },
    { key: "plannedDuration", label: "Planned Duration" },
    { key: "dailyHoursEffort", label: "Daily Hours Effort" },
    { key: "actualDuration", label: "Actual Duration" },
    { key: "plannedStartDate", label: "Planned Start Date" },
    { key: "actualStartDate", label: "ActualStartDate" },
    { key: "plannedEndDate", label: "Planned End Date" },
    { key: "scheduleTimeHealth", label: "Schedule Time Health" },
    { key: "actualEndDate", label: "Actual End Date" },
    { key: "overallHealth", label: "Overall Health" },
    { key: "trainingCordinator", label: "Training Cordinator" },
    { key: "shortDescription", label: "Short Description" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyCandidateEnrollments: [
    { key: "studentName", label: "Student Name" },
    { key: "studentOfficialEmail", label: "Official Email" },
    { key: "studentID", label: "Student ID" },
    { key: "careerPath", label: "Career Path" },
    { key: "enrolledCourse", label: "Course" },
    { key: "candidateLead", label: "Candidate Lead" },
    { key: "batchStartDate", label: "Batch Start Date" },
    { key: "trainingDurations", label: "Training Durations" },
    { key: "enrollmentStage", label: "Enrollment Stage" },
    { key: "paymentStatus", label: "Payment Status" },
    { key: "totalFees", label: "Total Fees" },
    { key: "careerCounseller", label: "Career Counseller" },
    { key: "enrollmentSpecialist", label: "Enrollment Specialist" },
    { key: "trainingCordinator", label: "Training Cordinator" },
    { key: "corporateRelationManager", label: "Corporate Relation Manager" },
    { key: "currency", label: "Currency" },
    { key: "paymentTerms", label: "Payment Terms" },
    { key: "NBFCcharges", label: "NBFC Charges" },
    { key: "nextPaymentDueDate", label: "Next Payment Due Date" },
    { key: "anyDiscountsGiven", label: "Any Discounts Given" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  announcements: [
    { key: "title", label: "Title" },
    { key: "active", label: "Active" },
    { key: "message", label: "Message" },
    { key: "type", label: "Announcements Type" },
    { key: "date", label: "Date" },
    { key: "order", label: "Order" },
    { key: "location", label: "Location" },
    { key: "host", label: "Host" },
    { key: "shortDescription", label: "Short Description" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyStudentProfile: [
    { key: "studentName", label: "Name" },
    { key: "studentID", label: "Student ID" },
    { key: "enrolledCourse", label: "Enrolled Course" },
    { key: "studentOfficialEmail", label: "Official Email" },
    { key: "associatedEnrollment", label: "Associated Enrollment" },
    { key: "studentTrainingCordinator", label: "Student Training Cordinator" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  academyMentorFeedback: [
    { key: "serial", label: "Number" },
    { key: "mentor", label: "Mentor" },
    { key: "state", label: "State" },
    { key: "overallScoring", label: "Overall Scoring" },
    { key: "feedbackSummary", label: "Feedback Summary" },
    { key: "weakPoints", label: "Weak Points" },
    { key: "studentProfile", label: "Learner's Profile" },
    { key: "strongPoints", label: "Strong Points" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  addressHistory: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "country", label: "Country" },
    { key: "fromDate", label: "From Date" },
    { key: "toDate", label: "To Date" },
    { key: "postalCode", label: "Postal Code" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  departments: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "departmentManager", label: "Department Manager" },
    { key: "createdAt", label: "Created On" },
    { key: "updatedAt", label: "Updated On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedBy", label: "Updated By" },
  ],
  employmentHistory: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "employmentType", label: "Employment Type" },
    { key: "currentlyWorking", label: "Currently Working" },
    { key: "permissionToContact", label: "Permission To Contact" },
    { key: "permissionToContactReason", label: "Permission To Contact Reason" },
    { key: "employerName", label: "Employer Name" },
    { key: "addressLineOne", label: "Address Line 1" },
    { key: "addressLineTwo", label: "Address Line 2" },
    { key: "state", label: "State" },
    { key: "city", label: "City" },
    { key: "postalCode", label: "Postal Code" },
    { key: "phone", label: "Phone" },
    { key: "extension", label: "Extension" },
    { key: "jobType", label: "Job Type" },
    { key: "releventExperience", label: "Relevent Experience" },
    { key: "isAgency", label: "Is Agency" },
    { key: "jobTitle", label: "Job Title" },
    { key: "department", label: "Department" },
    { key: "supervisorName", label: "Super visor Name" },
    { key: "supervisorTitle", label: "Supervisor Title" },
    { key: "supervisorEmail", label: "Supervisor Email" },
    { key: "supervisorPhone", label: "Supervisor Phone" },
    { key: "reasonForLeaving", label: "Reason For Leaving" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  educationDetails: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "country", label: "Country" },
    { key: "fromDate", label: "From Date" },
    { key: "toDate", label: "To Date" },
    { key: "educationType", label: "Education Type" },
    { key: "graduationDate", label: "Graduation Date" },
    { key: "state", label: "State/Province" },
    { key: "instituteName", label: "Name of the Institute" },
    { key: "studentId", label: "Student Id" },
    { key: "instituteAddress", label: "Address of the Institute" },
    { key: "city", label: "City" },
    { key: "postalCode", label: "Postal Zone/ZIP Code" },
    { key: "phone", label: "Phone" },
    { key: "currentlyAttending", label: "Currently Attending" },
    { key: "receivedDegree", label: "Did the employee graduate and receive a degree?" },
    { key: "degreeName", label: "Degree Name" },
    { key: "majorName", label: "Major Name" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  employees: [
    { key: "firstName", label: "firstName" },
    { key: "middleName", label: "Middle Name" },
    { key: "lastName", label: "Last Name" },
    { key: "personalEmail", label: "Personal Email" },
    { key: "officialEmail", label: "Official Email" },
    { key: "mobile", label: "Mobile" },
    { key: "employmentStatus", label: "Employment Status" },
    { key: "employeeID", label: "Employee ID" },
    { key: "employmentDepartment", label: "Employment Department" },
    { key: "employmentType", label: "Employment Type" },
    { key: "nickName", label: "Nick Name" },
    { key: "active", label: "Active" },
    { key: "alternateMobile", label: "Alternate Mobile" },
    { key: "generatePayrollOffshoreServices", label: "Generate Payroll Offshore Services" },
    { key: "employmentCompany", label: "Employment Company" },
    { key: "payrollCompany", label: "Payroll Company" },
    { key: "employeeIDofPayrollCompany", label: "Employee ID of Payroll Company" },
    { key: "offboardType", label: "Off Board Type" },
    { key: "shirtSize", label: "Shirt Size" },
    { key: "dateHire", label: "Hire Date" },
    { key: "dateJoining", label: "Joining Date" },
    { key: "probationStartDate", label: "Probation Start Date" },
    { key: "probationEndDate", label: "Probation End Date" },
    { key: "dateTermination", label: "Termination Date " },
    { key: "employmentDuration", label: "Employment Duration" },
    { key: "personalMartial", label: "Martial Status" },
    { key: "personalDOB", label: "Date Of Birth" },
    { key: "nationality", label: "Nationality" },
    { key: "personalGender", label: "Gender" },
    { key: "ethnicity", label: "Ethnicity" },
    { key: "race", label: "Race" },
    { key: "placeOfBirth", label: "Place Of Birth" },
    { key: "countryOfBirth", label: "Country Of Birth" },
    { key: "fatherName", label: "fatherName" },
    { key: "motherName", label: "Mother Name" },
    { key: "addressLineOne", label: "Address Line 1" },
    { key: "addressLineTwo", label: "Address Line 2" },
    { key: "state", label: "State" },
    { key: "zipCode", label: "Zip Code" },
    { key: "Country", label: "Country" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  benefits: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "active", label: "Active" },
    { key: "issueDate", label: "Issue Date" },
    { key: "expiryDate", label: "Expiry Date" },
    { key: "amount", label: "Amount" },
    { key: "type", label: "Type" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  projectHistory: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "projectCode", label: "Project Code" },
    { key: "projectType", label: "Project Type" },
    { key: "projectTechnology", label: "Technology" },
    { key: "startDate", label: "Start Date" },
    { key: "endDate", label: "End Date" },
    { key: "projectStatus", label: "Status" },
    { key: "currentlyWorking", label: "Currently Working" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  designations: [
    { key: "name", label: "Name" },
    { key: "department", label: "Department" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  piiDetails: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "piiType", label: "Pii Type" },
    { key: "active", label: "Active" },
    { key: "number", label: "Number" },
    { key: "issueDate", label: "Issue Date" },
    { key: "expiryDate", label: "Expiry Date" },
    { key: "requiredDocAttached", label: "Required Doc Attached" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  employeeBankDetails: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "bankCountry", label: "Bank Country" },
    { key: "bankCurrency", label: "Bank Currency" },
    { key: "accountType", label: "Account Type" },
    { key: "accountName", label: "Account Name" },
    { key: "active", label: "Active" },
    { key: "bankName", label: "Bank Name" },
    { key: "associatedRecord", label: "Associated To" },
    { key: "bankType", label: "Type of the Bank" },
    { key: "defaultAccount", label: "Default Account" },
    { key: "swiftBICCode", label: "Swift/BIC Code" },
    { key: "routingType", label: "Bank Routing Details" },
    { key: "routingNumber", label: "Bank Routing Number" },
    { key: "accountNumber", label: "Bank Account Number" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  companyBankDetails: [
    { key: "associatedCompany", label: "Company" },
    { key: "bankCountry", label: "Bank Country" },
    { key: "bankCurrency", label: "Bank Currency" },
    { key: "accountType", label: "Account Type" },
    { key: "accountName", label: "Account Name" },
    { key: "active", label: "Active" },
    { key: "bankName", label: "Bank Name" },
    { key: "associatedRecord", label: "Associated To" },
    { key: "bankType", label: "Type of the Bank" },
    { key: "defaultAccount", label: "Default Account" },
    { key: "swiftBICCode", label: "Swift/BIC Code" },
    { key: "routingType", label: "Bank Routing Details" },
    { key: "routingNumber", label: "Bank Routing Number" },
    { key: "accountNumber", label: "Bank Account Number" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  form16: [
    { key: "associatedEmployee", label: "Employee" },
    { key: "active", label: "Active" },
    { key: "form16Attached", label: "Form 16 Attached" },
    { key: "financialYear", label: "Financial Year" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  subscriber: [
    { key: "email", label: "Email" },
    { key: "active", label: "Active" },
    { key: "unsubscribedOnWhichResource", label: "Unsubscribed on which resource" },
    { key: "unsubscribeTimestamp", label: "Unsubscribe timestamp" },
    { key: "vertical", label: "Vertical" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  spsPaperwork: [
    { key: "preferredName", label: "Preffered Name" },
    { key: "consultantName", label: "Consultant Name" },
    { key: "associatedAccount", label: "SPS Account" },
    { key: "associatedDeal", label: "Associated Deal" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  // spsInsurance: [
  //   { key: "associatedProject", label: "Associated Project" },
  //   { key: "insuranceCompany", label: "Insurance Company" },
  //   { key: "accountTakenfor", label: "Account Taken For" },
  //   { key: "startDate", label: "Start Date" },
  //   { key: "renewDate", label: "End Date" },
  //   { key: "amount", label: "Amount" },
  //   { key: "createdAt", label: "Created On" },
  //   { key: "createdBy", label: "Created By" },
  //   { key: "updatedAt", label: "Updated On" },
  //   { key: "updatedBy", label: "Updated By" },
  // ],
  spsProject: [
    { key: "projectName", label: "Project Name" },
    { key: "projectCode", label: "Project Code" },
    { key: "projectStatus", label: "Project Status" },
    { key: "associatedDeal", label: "Associated Deal" },
    { key: "projectType", label: "Project Type" },
    { key: "platform", label: "Platform" },
    { key: "legalName", label: "Legal Name" },
    { key: "legalNameCode", label: "Legal Name Code" },
    { key: "projectGivenTo", label: "Project Given To" },
    { key: "rate", label: "Rate" },
    { key: "billingCycle", label: "Billing Cycle" },
    { key: "startDate", label: "Start Date" },
    { key: "expetectedLength", label: "Expected Length" },
    { key: "expetectedEndDate", label: "Expected End Date" },
    { key: "projectRole", label: "Project Role" },
    { key: "projectDescription", label: "Project Description" },
    { key: "terminationReason", label: "Termination Reason" },
    { key: "haltStartDate", label: "Halt Start Date" },
    { key: "haltEndDate", label: "Halt End Date" },
    { key: "haltReason", label: "Halt Reason" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  // spsAccount: [
  //   { key: "name", label: "Name" },
  //   { key: "type", label: "Type" },
  //   { key: "status", label: "Status" },
  //   { key: "industry", label: "Industry" },
  //   { key: "size", label: "Size" },
  //   { key: "region", label: "Region" },
  //   { key: "country", label: "Country" },
  //   { key: "createdAt", label: "Created On" },
  //   { key: "createdBy", label: "Created By" },
  //   { key: "updatedAt", label: "Updated On" },
  //   { key: "updatedBy", label: "Updated By" },
  // ],

  spsInsurance: [
    { key: "insuranceCompany", label: "Insurance Company" },
    { key: "associatedProject", label: "Associated Project" },
    { key: "accountTakenfor", label: "Account Taken For" },
    { key: "startDate", label: "Start Date" },
    { key: "renewDate", label: "End Date" },
    { key: "amount", label: "Amount" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  // spsProject: [
  //   { key: "projectName", label: "Project Name" },
  //   { key: "projectCode", label: "Project Code" },
  //   { key: "projectStatus", label: "Project Status" },
  //   { key: "associatedDeal", label: "Associated Deal" },
  //   { key: "projectType", label: "Project Type" },
  //   { key: "platform", label: "Platform" },
  //   { key: "legalName", label: "Legal Name" },
  //   { key: "legalNameCode", label: "Legal Name Code" },
  //   { key: "projectGivenTo", label: "Project Given To" },
  //   { key: "rate", label: "Rate" },
  //   { key: "billingCycle", label: "Billing Cycle" },
  //   { key: "startDate", label: "Start Date" },
  //   { key: "expetectedLength", label: "Expected Length" },
  //   { key: "expetectedEndDate", label: "Expected End Date" },
  //   { key: "createdAt", label: "Created On" },
  //   { key: "createdBy", label: "Created By" },
  //   { key: "updatedAt", label: "Updated On" },
  //   { key: "updatedBy", label: "Updated By" },
  // ],
  spsAccount: [
    { key: "name", label: "Name" },
    { key: "type", label: "Type" },
    { key: "status", label: "Status" },
    { key: "industry", label: "Industry" },
    { key: "size", label: "Size" },
    { key: "region", label: "Region" },
    { key: "country", label: "Country" },
    { key: "website", label: "Website" },
    { key: "salesRepresentative", label: "Sales Representative" },
    { key: "externalLinks", label: "External Links" },
    { key: "addressInfo", label: "Address Info" },
    { key: "street", label: "Street" },
    { key: "city", label: "City" },
    { key: "state", label: "State" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  spsAccountContact: [
    { key: "firstName", label: "FirstName" },
    { key: "lastName", label: "LastName" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile" },
    { key: "company", label: "Company" },
    { key: "status", label: "Status" },
    { key: "role", label: "Role" },
    { key: "alternativeEmail", label: "Alternative Email" },
    { key: "alternativeMobile", label: "Alternative Mobile" },
    { key: "associatedAccount", label: "Associated Account" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  spsSalesProfile: [
    { key: "firstName", label: "FirstName" },
    { key: "lastName", label: "LastName" },
    { key: "gmail", label: "Gmail" },
    { key: "gvoice", label: "Gvoice" },
    { key: "linkedin", label: "Linkedin" },
    { key: "locationUsed", label: "Location Used" },
    { key: "referenceUsed", label: "Reference Used" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  spsBGCheck: [
    { key: "preferredName", label: "Preferred Name" },
    { key: "consultantName", label: "Consultant Name" },
    { key: "status", label: "Status" },
    { key: "email", label: "Email" },
    { key: "recruiterName", label: "Recruiter Name" },
    { key: "phone", label: "Phone" },
    { key: "associatedAccount", label: "Associated Account" },
    { key: "associatedDeal", label: "Associated Deal" },
    { key: "newCompanyAppliedFor", label: "New Company Applied For" },
    { key: "newPositionAppliedFor", label: "New Position Applied For" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  spsDeals: [
    { key: "preferredFullName", label: "Preferred Full Name" },
    { key: "legalAccountName", label: "Legal Account Name" },
    { key: "SPSSalesProfile", label: "SPS Sales Profile" },
    { key: "accountOwner", label: "Account Owner" },
    { key: "rate", label: "Rate" },
    { key: "type", label: "Type" },
    { key: "dealSource", label: "Deal Source" },
    { key: "state", label: "State" },
    { key: "staffingName", label: "Staffing Name" },
    { key: "platform", label: "Platform" },
    { key: "c2cInfo", label: "C2C Info" },
    { key: "interviewDetails", label: "Interview Details" },
    { key: "recordingDriveLink", label: "Recording Drive Link" },
    { key: "addressUsed", label: "Address Used" },
    { key: "bankAccountDetailsUsed", label: "Bank Account Details Used" },
    { key: "dealGivenTo", label: "Deal Given To" },
    { key: "isTranscriptUploaded", label: "Curriculum Attached" },
    { key: "isJobDescriptionUploaded", label: "Is Job Description Uploaded" },
    { key: "isResumeUploaded", label: "Is Resume Uploaded" },
    { key: "potentialStartDate", label: "Potential Start Date" },
    { key: "potentialClosingDate", label: "Potential Closing Date" },
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "verifiedBy", label: "Verified By" },
    { key: "endClient", label: "End Client" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  newsletterResources: [
    { key: "name", label: "Name" },
    { key: "status", label: "Status" },
    { key: "body", label: "Body Text Fields" },
    { key: "subject", label: "Subject Text Fields" },
    { key: "approvalBy", label: "Approval By" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  newsletterLeads: [
    { key: "firstName", label: "FirstName" },
    { key: "lastName", label: "LastName" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "active", label: "Active" },
    { key: "unsubscribeCampaign", label: "Unsubscribe Campaign" },
    { key: "unsubscribeTime", label: "Unsubscribe Time" },
    { key: "jobTitle", label: "Job Title" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  academyACH: [
    { key: "serial", label: "Activity Number" },
    { key: "activityType", label: "Activity Type" },
    { key: "state", label: "State" },
    { key: "from", label: "From" },
    { key: "to", label: "To" },
    { key: "activityOwner", label: "Activity Owner" },
    { key: "activityDate", label: "Activity Date" },
    { key: "associatedCandidateLeads", label: "Associated Candidate Leads" },
    { key: "callDirection", label: "Call Direction" },
    { key: "activityAgenda", label: "Activity Agenda" },
    { key: "activityPurpose", label: "activity Purpose" },
    { key: "meetLocationURL", label: "Meet Location URL" },
    { key: "recordingURL", label: "Recording URL" },
    { key: "shortDescripiton", label: "Short Description" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  // Dashboard
  dashboardAdministration: [
    { key: "title", label: "Title" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  dashboardTabs: [
    { key: "title", label: "Title" },
    { key: "dashboard", label: "Dashboard Config" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  dashboardWidgets: [
    { key: "title", label: "Title" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "type", label: "Type" },
    { key: "table", label: "Table" },
    { key: "dashboardTab", label: "Dashboard Tab" },
    { key: "dashboard", label: "Dashboard Config" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  bugReporting: [
    { key: "title", label: "Title" },
    { key: "assignedTo", label: "Assigned To" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  currency: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "country", label: "Country" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  groups: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  categories: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  campaigns: [
    { key: "campaign", label: "Campaign" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  campaignsAssets: [
    { key: "name", label: "Name" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  leadLists: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  attributes: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  checklistTemplate: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  tags: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  country: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  subCategories: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "parentCategory", label: "Parent Category" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  platformModules: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "associatedTechnology", label: "Technology" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  certificationsAccreditations: [
    { key: "name", label: "Name" },
    { key: "type", label: "Type" },
    { key: "associatedEmployee", label: "Employee" },
    { key: "active", label: "active" },
    { key: "number", label: "Number" },
    { key: "issueDate", label: "Issue Date" },
    { key: "expiryDate", label: "Expiry Date" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  companyContact: [
    { key: "company", label: "Company" },
    { key: "firstName", label: "FirstName" },
    { key: "lastName", label: "LastName" },
    { key: "role", label: "Role" },
    { key: "email", label: "Email" },
    { key: "alternateEmail", label: "Alternate Email" },
    { key: "phone", label: "Phone" },
    { key: "alternatePhone", label: "Alternate Phone" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  jobsPosting: [
    { key: "serial", label: "Serial" },
    { key: "title", label: "Title" },
    { key: "summary", label: "Summary" },
    { key: "description", label: "Description" },
    { key: "salary", label: "Salary" },
    { key: "type", label: "Type" },
    { key: "location", label: "Location" },
    { key: "sector", label: "Sector" },
    { key: "contactNumber", label: "Contact Number" },
    { key: "contactName", label: "Contact Name" },
    { key: "contactEmail", label: "Contact Email" },
    { key: "skills", label: "Skills" },
    { key: "referenceId", label: "Reference Id" },
    { key: "customDate", label: "Custom Date" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  company: [
    { key: "company", label: "Company" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  hrTasks: [
    { key: "associatedEmployee", label: "Associated Employee" },
    { key: "number", label: "Number" },
    { key: "type", label: "Type" },
    { key: "assignedTo", label: "AssignedTo" },
    { key: "priority", label: "Priority" },
    { key: "state", label: "State" },
    { key: "urgency", label: "Urgency" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  companyTasks: [
    { key: "associatedCompany", label: "Associated Company" },
    { key: "number", label: "Number" },
    { key: "type", label: "Type" },
    { key: "assignedTo", label: "AssignedTo" },
    { key: "priority", label: "Priority" },
    { key: "state", label: "State" },
    { key: "urgency", label: "Urgency" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  achTasks: [
    { key: "associatedCandidateLead", label: "Associated Candidate Lead" },
    { key: "associatedCandidateEnrolment", label: "Associated Enrolment" },
    { key: "associatedCandidateStudent", label: "Associated Student" },
    { key: "number", label: "Number" },
    { key: "type", label: "Type" },
    { key: "assignedTo", label: "AssignedTo" },
    { key: "priority", label: "Priority" },
    { key: "state", label: "State" },
    { key: "urgency", label: "Urgency" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  contactUs: [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "jobTitle", label: "Job Title" },
    { key: "company", label: "Company" },
    { key: "phone", label: "Phone" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  itnServices: [
    { key: "name", label: "Name" },
    { key: "descriptionLabel", label: "Description Title" },
    { key: "description", label: "Description" },
    { key: "downloadButton", label: "Download Button Title" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  variableSets: [
    { key: "name", label: "Name" },
    { key: "order", label: "Order" },
    { key: "active", label: "Active" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  resources: [
    // Overview
    { key: "name", label: "Name" },
    { key: "shortDescription", label: "Short Description" },
    { key: "active", label: "Active" },
    { key: "state", label: "State" },
    { key: "downloadAvailable", label: "Download Available" },
    { key: "publishedDate", label: "Published Date" },
    { key: "type", label: "Type" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  variables: [
    { key: "name", label: "Name" },
    { key: "type", label: "Type" },
    { key: "active", label: "Active" },
    { key: "mandatory", label: "Mandatory" },
    { key: "order", label: "Order" },
    { key: "variableSets", label: "Variable Sets" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  emailSchedular: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "frequency", label: "Frequency" },
    { key: "time", label: "Time" },
    { key: "day", label: "Day" },
    { key: "date", label: "Date" },
    { key: "repeatInterval", label: "Repeat Interval" },
    { key: "starting", label: "Starting" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  serviceNowSolutions: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "fullName", label: "Full Name" },
    { key: "shortDescription", label: "Short Description" },
    { key: "technology", label: "Technology" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],

  itnRFP: [
    { key: "fullName", label: "FullName" },
    { key: "jobTitle", label: "Job Title" },
    { key: "businessEmail", label: "Business Email" },
    { key: "phone", label: "Phone" },
    { key: "country", label: "Country" },
    { key: "companyName", label: "Comapny Name" },
    { key: "industry", label: "Industry" },
    { key: "annualRevenue", label: "Annual Revenue" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  catalogAdministrations: [
    { key: "title", label: "Title" },
    { key: "manager", label: "Manager" },
    { key: "active", label: "Active" },
    { key: "categoryTitle", label: "Category Title" },
    // { key: "categoryIcon", label: "Category Icon" },
    { key: "categoryCatalog", label: "categoryCatalog" },
    { key: "categoryActive", label: "Category Active" },
    { key: "parentCategory", label: "Parent Category" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  kanbanConfig: [
    { key: "title", label: "Title" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "table", label: "Table" },
    { key: "columnField", label: "Column Field" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  workspaces: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "fullAccess", label: "Full Access" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  space: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "private", label: "Private" },
    { key: "workspace", label: "Workspace" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  notificationsDefinations: [
    { key: "name", label: "Name" },
    { key: "active", label: "Active" },
    { key: "description", label: "description" },
    { key: "table", label: "Table" },
    { key: "triggered", label: "Triggered" },
    { key: "insert", label: "Insert" },
    { key: "update", label: "Update" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  serviceRequests: [
    { key: "number", label: "Number" },
    { key: "assignedTo", label: "AssignedTo" },
    { key: "priority", label: "Priority" },
    { key: "state", label: "State" },
    { key: "urgency", label: "Urgency" },
    { key: "holdReason", label: "Hold Reason" },
    { key: "stage", label: "Stage" },
    { key: "dueDate", label: "Due Date" },
    { key: "shortDescription", label: "Short Description" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Created On" },
    { key: "createdBy", label: "Created By" },
    { key: "updatedAt", label: "Updated On" },
    { key: "updatedBy", label: "Updated By" },
  ],
  // addressHistory: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "country", label: "Country" },
  //   { key: "fromDate", label: "From Date" },
  //   { key: "toDate", label: "To Date" },
  //   { key: "postalCode", label: "Postal Code" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // Staging to prod
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "startDate", label: "Start Date" },
  //   { key: "endDate", label: "End Date" },
  //   { key: "employmentType", label: "Employment Type" },
  //   { key: "currentlyWorking", label: "Currently Working" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // educationDetails: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "country", label: "Country" },
  //   { key: "fromDate", label: "From Date" },
  //   { key: "toDate", label: "To Date" },
  //   { key: "educationType", label: "Education Type" },
  //   { key: "graduationDate", label: "Graduation Date" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // employees: [
  //   { key: "firstName", label: "firstName" },
  //   { key: "middleName", label: "Middle Name" },
  //   { key: "lastName", label: "Last Name" },
  //   { key: "personalEmail", label: "Personal Email" },
  //   { key: "officialEmail", label: "Official Email" },
  //   { key: "mobile", label: "Mobile" },
  //   { key: "employmentStatus", label: "Employment Status" },
  //   { key: "employeeID", label: "Employee ID" },
  //   { key: "employmentDepartment", label: "Employment Department" },
  //   { key: "employmentType", label: "Employment Type" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // benefits: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "active", label: "Active" },
  //   { key: "issueDate", label: "Issue Date" },
  //   { key: "expiryDate", label: "Expiry Date" },
  //   { key: "amount", label: "Amount" },
  //   { key: "type", label: "Type" },

  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // piiDetails: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "piiType", label: "Pii Type" },
  //   { key: "active", label: "Active" },
  //   { key: "number", label: "Number" },
  //   { key: "issueDate", label: "Issue Date" },
  //   { key: "expiryDate", label: "Expiry Date" },
  //   { key: "requiredDocAttached", label: "Required Doc Attached" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // employeeBankDetails: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "bankCountry", label: "Bank Country" },
  //   { key: "bankCurrency", label: "Bank Currency" },
  //   { key: "accountType", label: "Account Type" },
  //   { key: "accountName", label: "Account Name" },
  //   { key: "active", label: "Active" },
  //   { key: "bankName", label: "Bank Name" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // companyBankDetails: [
  //   { key: "associatedCompany", label: "Company" },
  //   { key: "bankCountry", label: "Bank Country" },
  //   { key: "bankCurrency", label: "Bank Currency" },
  //   { key: "accountType", label: "Account Type" },
  //   { key: "accountName", label: "Account Name" },
  //   { key: "active", label: "Active" },
  //   { key: "bankName", label: "Bank Name" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
  // form16: [
  //   { key: "associatedEmployee", label: "Employee" },
  //   { key: "active", label: "Active" },
  //   { key: "form16Attached", label: "Form 16 Attached" },
  //   { key: "financialYear", label: "Financial Year" },
  //   {
  //     key: "createdAt",
  //     label: "Created On",
  //   },
  //   {
  //     key: "createdBy",
  //     label: "Created By",
  //   },
  //   {
  //     key: "updatedAt",
  //     label: "Updated On",
  //   },
  //   {
  //     key: "updatedBy",
  //     label: "Updated By",
  //   },
  // ],
};
// Atom - TableV2
export const defaultTableListColumns: ColumnsType[] = [
  {
    title: "",
    width: "100px",
    key: "checkbox",
    center: true,
  },
  {
    title: "Serial",
    width: "100px",
    key: "serial",
    center: true,
  },
];
