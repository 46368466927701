import Types from "./types";

const Initial = {
  orgData: {},
  defData: {},
  filters: {
    name: "",
    sortKey: {},
    sortType: "asc",
  },
};

const EmailTemplateReducer = (state = Initial, action) => {
  switch (action.type) {
    case Types.EMAIL_FETCH_SUCCESS:
      return {
        ...state,
        orgData: action.payload.orgData,
        defData: action.payload.defData,
      };
    case Types.EMAIL_UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default EmailTemplateReducer;
