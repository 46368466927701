import React from "react";

import { Layout } from "../../../presentationals";
import { Screen404 } from "../../../screens";

interface Props {
  route: "admin" | "employee";
  type?: "permission" | "404";
}

const Route = ({ route, type = "404" }: Props) => {
  let employee = false;
  let admin = false;
  switch (route) {
    case "admin":
      admin = true;
      break;
    case "employee":
      employee = true;
      break;
    default:
      employee = true;
  }
  return (
    <Layout
      activity={<Screen404 type={type === "404" ? "Page not found" : "Permission Denied"} />}
      employee={employee}
      admin={admin}
      layout={"2"}
    />
  );
};

export default Route;
