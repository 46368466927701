import { metaDataTypes } from "../../types";

import Types from "./actionType";
import {
  CreateFilter,
  CreateFilterPayload,
  FilterList,
  FetchFilter,
  FetchFilterError,
  FetchFilterSuccess,
  SetFilterMetaData,
  UpdateFilter,
  UpdateFilterPayload,
} from "./types";

export const fetchFilter = (): FetchFilter => ({
  type: Types.FETCH_Filter_FILTER,
});

export const fetchFilterSuccess = (list: FilterList[]): FetchFilterSuccess => ({
  type: Types.SUCCESS_FETCH_Filter_FILTER,
  payload: {
    data: list,
  },
});

export const setFilterMetaData = (metaData: metaDataTypes): SetFilterMetaData => ({
  type: Types.SET_META_DATA_Filter_FILTER,
  payload: {
    data: metaData,
  },
});

export const fetchFilterError = (error?: string): FetchFilterError => ({
  type: Types.ERROR_FETCH_Filter_FILTER,
  payload: { error },
});

export const createFilter = (payload: CreateFilterPayload): CreateFilter => ({
  type: Types.CREATE_Filter_FILTER,
  payload,
});

export const updateFilter = (payload: UpdateFilterPayload): UpdateFilter => ({
  type: Types.UPDATE_Filter_FILTER,
  payload,
});

// export const FilterFilter = ({ field, value }) => ({
//   type: Types.Filter_FILTER_FITERS_UPDATE,
//   payload: {
//     field: field,
//     value: value,
//   },
// });

// export const getFilterById = (payload) => ({
//   type: Types.GET_Filter_FILTER_BY_ID,
//   payload,
// });
