import React, { useEffect, useMemo, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType } from "../../../../types";
import { SPSInsuranceListProps } from "../../../../types/models/SPSInsurance";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useSPSInsuranceList } from "./hooks";

const List = ({ relatedList }: SPSInsuranceListProps) => {
  const SPSInsurance = useAppSelector((state) => state.SPSInsuranceReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const { list, metaData } = SPSInsurance;
  const {
    fetchSPSInsurance,
    loading,
    handlePageChange,
    onClickSPSInsurance,
    onSubmitDeleteRecord,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    spsInsuranceHeaderButtons,
    spsInsuranceFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    spsInsuranceConfigFields,
  } = useSPSInsuranceList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchSPSInsurance({});
    // Do not include fetchSPSInsurance to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={spsInsuranceHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.spsInsurance}
      handleTableConfigs={handleTableConfigs}
      filters={spsInsuranceFilterFields}
      selectedConfigFields={spsInsuranceConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickSPSInsurance}
      headerTitle={strings.headerTitle.SPSInsurance}
      list={list}
      columns={columns}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.spsInsurance_delete}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apSPSInsurance.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};

export const SPSInsuranceList = memo(List);
