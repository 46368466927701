import React from "react";

import { Layout } from "../../../presentationals";
import { CompanyContactController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CompanyContactController type={"add"} />} admin={true} layout={"2"} />;
};

export const APCompanyContactAdd = React.memo(Route);
