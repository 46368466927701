import React from "react";
import { useState, useCallback } from "react";

import {
  addAppNotification,
  addEmergencyContact,
  fetchControllerConfigs,
  removeAppNotification,
  updateEmergencyContact,
} from "../../../../../redux";
import { EmployeesItem } from "../../../../../redux/employees/types";
import { EmployeeEmergencyContact } from "../../../../../redux/employees/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  AppNotificationsType,
  EmployeeEmergencyContact as EmployeeEmergencyContactForm,
} from "../../../../../types";
import { validatePhone } from "../../../../../utils";

interface useMultiStepFormType {
  salaryModal: "add" | "edit" | "";
  handleSalaryModal: () => void;
  addSalaryModal: () => void;
  editSalaryModal: (id: string) => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDateChange: (value: Date, name: string) => void;
  formData: EmployeeEmergencyContactForm;
  loading: boolean;
  onSubmitAdd: () => void;
  onSubmitUpdate: () => void;
}
interface Props {
  emergencyContact: EmployeeEmergencyContact[];
  fetchEmployees: () => void;
  employeeId: string;
}

const defaultEmergencyContact: EmployeeEmergencyContactForm = {
  type: {
    value: "",
    required: true,
    error: "",
  },
  emergencyFirstName: {
    value: "",
    required: true,
    error: "",
  },
  emergencyLastName: {
    value: "",
    required: true,
    error: "",
  },
  emergencyRelation: {
    value: "",
    required: true,
    error: "",
  },
  emergencyMobile: {
    value: "",
    required: true,
    error: "",
  },
  emergencyAddress: {
    value: "",
    required: true,
    error: "",
  },
};

export const useEmployeeEmergencyContact = ({
  emergencyContact,
  fetchEmployees,
  employeeId,
}: Props): useMultiStepFormType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const dispatch = useAppDispatch();

  const [salaryModal, setSalaryModal] = useState<"add" | "edit" | "">("");
  const [currentRecordId, setCurrentRecordId] = useState<string>("");
  const [formData, setFormData] = useState<EmployeeEmergencyContactForm>(defaultEmergencyContact);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSalaryModal = useCallback(() => {
    setSalaryModal("");
    setFormData(defaultEmergencyContact);
  }, []);

  const addSalaryModal = useCallback(() => {
    setFormData(defaultEmergencyContact);
    setSalaryModal("add");
  }, []);

  const rehydrateFormData = useCallback(
    (item?: EmployeeEmergencyContact) => {
      const data = { ...formData };
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (field === "type") {
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const editSalaryModal = useCallback(
    (id = "") => {
      setCurrentRecordId(id);
      const item = emergencyContact.find((item) => item._id === id);
      rehydrateFormData(item);
      setSalaryModal("edit");
    },
    [emergencyContact, rehydrateFormData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onDateChange = useCallback(
    (value: Date, name: string) => {
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },

    [formData],
  );

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.map((val) => (typeof val === "string" ? val.trim() : val))
        : typeof currentField.value === "string"
        ? currentField.value.trim()
        : currentField.value;

      if (
        ["emergencyMobile", "alternateMobile"].includes(item) &&
        !validatePhone(String(validValue))
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter a valid phone number with a country code",
          },
        };
      } else if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else {
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            value: validValue,
            error: "", // Clear error if the field is valid
          },
        };
      }
    });

    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      emergencyFirstName,
      emergencyLastName,
      type,
      emergencyAddress,
      emergencyMobile,
      emergencyRelation,
    } = formData;

    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const data: EmployeeEmergencyContact = {
        type: type.value,
        emergencyFirstName: emergencyFirstName.value,
        emergencyLastName: emergencyLastName.value,
        emergencyRelation: emergencyRelation.value,
        emergencyMobile: emergencyMobile.value,
        emergencyAddress: emergencyAddress.value,
        employeeId: employeeId,
      };
      const payload = {
        data,
        onSuccess: (data: EmployeesItem) => {
          setSalaryModal("");
          fetchEmployees();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation added successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          setSalaryModal("");
        },
      };
      dispatch(addEmergencyContact(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, employeeId, fetchEmployees]);

  const onSubmitUpdate = useCallback(() => {
    const {
      emergencyFirstName,
      emergencyLastName,
      type,
      emergencyAddress,
      emergencyMobile,
      emergencyRelation,
    } = formData;

    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const payload = {
        id: currentRecordId || "",
        data: {
          type: type.value,
          emergencyFirstName: emergencyFirstName.value,
          emergencyLastName: emergencyLastName.value,
          emergencyRelation: emergencyRelation.value,
          emergencyMobile: emergencyMobile.value,
          emergencyAddress: emergencyAddress.value,
          employeeId: employeeId,
        },

        onSuccess: (data: EmployeesItem) => {
          setSalaryModal("");
          fetchEmployees();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateEmergencyContact(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    currentRecordId,
    employeeId,
    fetchEmployees,
  ]);

  return {
    salaryModal,
    handleSalaryModal,
    addSalaryModal,
    editSalaryModal,
    onTextChange,
    onDateChange,
    formData,
    loading,
    onSubmitAdd,
    onSubmitUpdate,
  };
};
