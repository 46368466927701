import Types from "./actionType";
import {
  FetchNewsletterLeadsPayload,
  CreateNewsletterLeads,
  CreateNewsletterLeadsPayload,
  FetchNewsletterLeads,
  FetchNewsletterLeadsError,
  FetchNewsletterLeadsSuccess,
  UpdateNewsletterLeads,
  UpdateNewsletterLeadsPayload,
  NewsletterLeadsListWithMetaData,
  FetchNewsletterLeadsByIdPayload,
  FetchNewsletterLeadsById,
  DeleteNewsletterLeadsPayload,
  DeleteNewsletterLeads,
} from "./types";

export const fetchNewsletterLeadsList = ({
  params,
  onSuccess,
  onError,
}: FetchNewsletterLeadsPayload): FetchNewsletterLeads => ({
  type: Types.FETCH_NEWSLETTER_LEADS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchNewsletterLeadsById = ({
  id,
  onSuccess,
  onError,
}: FetchNewsletterLeadsByIdPayload): FetchNewsletterLeadsById => ({
  type: Types.FETCH_NEWSLETTER_LEADS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchNewsletterLeadsListSuccess = (
  data: NewsletterLeadsListWithMetaData,
): FetchNewsletterLeadsSuccess => ({
  type: Types.SUCCESS_FETCH_NEWSLETTER_LEADS,
  payload: {
    data,
  },
});

export const fetchNewsletterLeadsError = (error?: string): FetchNewsletterLeadsError => ({
  type: Types.ERROR_FETCH_NEWSLETTER_LEADS,
  payload: { error },
});

export const createNewsletterLeads = ({
  data,
  onSuccess,
  onError,
}: CreateNewsletterLeadsPayload): CreateNewsletterLeads => ({
  type: Types.CREATE_NEWSLETTER_LEADS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateNewsletterLeads = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateNewsletterLeadsPayload): UpdateNewsletterLeads => ({
  type: Types.UPDATE_NEWSLETTER_LEADS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteNewsletterLeads = ({
  id,
  onSuccess,
  onError,
}: DeleteNewsletterLeadsPayload): DeleteNewsletterLeads => ({
  type: Types.DELETE_NEWSLETTER_LEADS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
