import React, { useCallback, useMemo, useState } from "react";

import { addAppNotification, removeAppNotification } from "../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { images, strings } from "../../../../theme";
import {
  AppNotificationsType,
  ChecklistTemplateChecklistItem,
  ChecklistTemplateChecklistItemForm,
  ColumnsType,
  IDValueType,
  SelectOptions,
  TableHeaderButton,
} from "../../../../types";
import { useControllerConfigs } from "../../../common";
import { AttachmentIcon, CloseIcon, FormTitle, Row } from "../../../common/styles";
import { Button, Checkbox, MyTooltip, Select, TextInput } from "../../atoms";
import { CheckboxImage } from "../../atoms/Checkbox/styles";
import { TableList, TableListV2 } from "../../templates";
import { Popup } from "../Popup";

import {
  CheckboxLabel,
  ChecklistCheckboxContainer,
  ChecklistFieldsContainer,
  ChecklistModalContainer,
  FormChecklistContainer,
} from "./styles";

interface Props {
  onSubmitAddChecklist: (type: string, checkListItems: ChecklistTemplateChecklistItem[]) => void;
  onSubmitUpdateChecklist: (
    currentChecklistId: string,
    checklistItem: ChecklistTemplateChecklistItem,
  ) => void;
  checklist: ChecklistTemplateChecklistItem[];
  deleteChecklist: (e: React.MouseEvent) => void;
  showImportFromTemplateButton?: boolean;
}

export const FormChecklist = ({
  onSubmitAddChecklist,
  onSubmitUpdateChecklist,
  checklist,
  deleteChecklist,
  showImportFromTemplateButton = true,
}: Props) => {
  // ================ Reducer Values ================
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const checklistTemplates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.checklistTemplates,
  );
  // ================ Hooks ================

  const dispatch = useAppDispatch();

  const defaultChecklist: ChecklistTemplateChecklistItemForm = {
    name: {
      value: "",
      required: true,
      error: "",
    },
    assignedTo: {
      value: "",
      required: false,
      error: "",
    },
    value: {
      value: false,
      required: false,
      error: "",
    },
  };
  // ================Checklist State Values ================
  const [checklistTemplateModal, setChecklistTemplateModal] = useState<boolean>(false);
  const [selectedChecklistTemplate, setSelectedChecklistTemplate] = useState<SelectOptions[]>([]);
  const [selectedChecklistValues, setSelectedChecklistValues] = useState<
    ChecklistTemplateChecklistItem[]
  >([]);
  const [checklistModal, setChecklistModal] = useState<"add" | "edit" | "">("");
  const [currentChecklistId, setCurrentChecklistId] = useState<string>("");
  const [checklistFormData, setChecklistFormData] =
    useState<ChecklistTemplateChecklistItemForm>(defaultChecklist);
  const [checklistItems, setChecklistItems] = useState<ChecklistTemplateChecklistItem[]>([]);
  const { usersOptions, checklistTemplatesOptions } = useControllerConfigs();

  const renderDelete = useCallback(
    (item: ChecklistTemplateChecklistItem) => {
      return (
        <MyTooltip text='Delete'>
          <AttachmentIcon
            onClick={deleteChecklist}
            data-index={item._id}
            src={images.deleteAttachment}
          />
        </MyTooltip>
      );
    },
    [deleteChecklist],
  );

  const columns: ColumnsType[] = [
    {
      title: "Name",
      width: "30%",
      key: "name",
    },
    {
      title: "Assigned To",
      width: "30%",
      render: (item: ChecklistTemplateChecklistItem) => {
        return (
          <div className='title'>
            {typeof item.assignedTo !== "string" ? item?.assignedTo?.name : "-"}
          </div>
        );
      },
    },
  ];
  if (showImportFromTemplateButton) {
    columns.push({
      title: "Active",
      width: "20%",
      center: true,
      render: (item: ChecklistTemplateChecklistItem) => {
        return <div className='title'>{item?.value ? "Yes" : "No"}</div>;
      },
    });
  }
  columns.push({
    title: "Actions",
    width: "20%",
    center: true,
    render: (item: ChecklistTemplateChecklistItem) => renderDelete(item),
  });
  // ================ Checklist ================
  const clearFormData = useCallback(() => {
    const data = { ...checklistFormData };
    Object.keys(checklistFormData).forEach((field) => {
      const value = "";
      data[field].value = value;
      data[field].error = "";
    });
    setChecklistFormData(data);
  }, [checklistFormData]);

  const handleChecklistTemplateModal = useCallback(() => {
    setChecklistTemplateModal((value) => !value);
  }, []);

  const handleChecklistTemplate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedChecklistValues([]);
    setSelectedChecklistTemplate(value);
  }, []);
  const handleChecklistTemplateValue = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name") || "";
      const assignedTo = e.currentTarget.getAttribute("data-assignedTo") || "";
      const index = e.currentTarget.getAttribute("data-index") || "";
      const selectedValues = [...selectedChecklistValues];
      if (index) {
        const indexOfValue = selectedValues.findIndex((item) => item._id === index);
        if (indexOfValue >= 0) {
          selectedValues.splice(indexOfValue, 1);
        } else {
          selectedValues.push({
            name: name,
            assignedTo: assignedTo,
            value: false,
          });
        }
      }
      setSelectedChecklistValues(selectedValues);
    },
    [selectedChecklistValues],
  );

  const handleChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("");
  }, [clearFormData]);

  const addChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("add");
  }, [clearFormData]);

  const rehydrateChecklistFormData = useCallback(
    (checklistTemplate) => {
      const item = checklistTemplate;
      const data = { ...checklistFormData };
      Object.keys(checklistFormData).forEach((field) => {
        let value = item?.[field];
        if (field === "assignedTo") {
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      setChecklistFormData(data);
    },
    [checklistFormData],
  );

  const editChecklistModal = useCallback(
    (id = "") => {
      setCurrentChecklistId(id);
      const item = checklist.find((item) => item._id === id);
      rehydrateChecklistFormData(item);
      setChecklistModal("edit");
    },
    [checklist, rehydrateChecklistFormData],
  );

  const onChecklistTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...checklistFormData,
        [name]: {
          ...checklistFormData[name],
          value,
          error: "",
        },
      };
      setChecklistFormData(tempFormData);
    },
    [checklistFormData],
  );

  const validateChecklistForm = useCallback(() => {
    let tempFormData = { ...checklistFormData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });

    setChecklistFormData(tempFormData);
    return valid;
  }, [checklistFormData]);

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = checklistFormData[name]?.value ? false : true;
        console.log(name, checklistFormData[name]?.value, value);
        const tempFormData = {
          ...checklistFormData,
          [name]: {
            ...checklistFormData[name],
            value,
            error: "",
          },
        };
        setChecklistFormData(tempFormData);
      }
    },
    [checklistFormData],
  );

  const addChecklist = useCallback(
    (type) => {
      const checklistType = typeof type === "string" ? type : "";
      const submit = validateChecklistForm();
      if (!submit) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please update form",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const checkListItem: ChecklistTemplateChecklistItem = {
        name: checklistFormData.name.value,
        assignedTo: checklistFormData.assignedTo.value,
        value: checklistFormData.value?.value || false,
      };
      handleChecklistModal();
      onSubmitAddChecklist(checklistType, [checkListItem]);
    },
    [
      dispatch,
      notificationsId,
      validateChecklistForm,
      onSubmitAddChecklist,
      checklistFormData,
      handleChecklistModal,
    ],
  );

  const addChecklistFromTemplate = useCallback(() => {
    handleChecklistTemplateModal();
    let checklistItems: ChecklistTemplateChecklistItem[] = [];
    selectedChecklistTemplate.forEach((item) => {
      const id = item.value;
      const templateChecklist = checklistTemplates.find((item) => item._id === id)?.checklist;
      if (templateChecklist?.length) {
        checklistItems = [...checklistItems, ...templateChecklist];
      }
    });
    onSubmitAddChecklist("template", checklistItems);
  }, [
    handleChecklistTemplateModal,
    onSubmitAddChecklist,
    selectedChecklistTemplate,
    checklistTemplates,
  ]);

  const updateChecklist = useCallback(() => {
    const submit = validateChecklistForm();
    const checklistItem: ChecklistTemplateChecklistItem = {
      name: checklistFormData.name.value,
      assignedTo: checklistFormData.assignedTo.value,
      value: checklistFormData.value?.value,
    };
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    onSubmitUpdateChecklist(currentChecklistId, checklistItem);
    handleChecklistModal();
  }, [
    dispatch,
    notificationsId,
    validateChecklistForm,
    checklistFormData,
    currentChecklistId,
    onSubmitUpdateChecklist,
    handleChecklistModal,
  ]);

  const headerButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [
      {
        title: strings.common.add,
        onClick: addChecklistModal,
        visible: true,
      },
    ];

    if (showImportFromTemplateButton) {
      buttons.unshift({
        title: "Import from Template",
        onClick: handleChecklistTemplateModal,
        visible: true,
      });
    }
    return buttons;
  }, [addChecklistModal, showImportFromTemplateButton, handleChecklistTemplateModal]);

  return (
    <FormChecklistContainer>
      <TableListV2
        headerButtons={headerButtons}
        advancedList={false}
        loading={false}
        list={checklist}
        columns={columns}
        onClickTableRow={editChecklistModal}
      />
      <Popup isOpen={!!checklistModal} closeModal={handleChecklistModal}>
        <ChecklistModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleChecklistModal} />
          <FormTitle>
            {checklistModal === "add"
              ? "Add Checklist"
              : checklistModal === "edit"
              ? "Edit Checklist"
              : ""}
          </FormTitle>
          <ChecklistFieldsContainer>
            <Row>
              <TextInput
                label={"Name"}
                error={checklistFormData.name.error}
                inputProps={{
                  name: "name",
                  value: checklistFormData.name.value,
                  autoComplete: "off",
                  onChange: onChecklistTextChange,
                  required: checklistFormData.name.required,
                }}
                textAreaProps={{}}
              />
              <Select
                name={"assignedTo"}
                label={"Assigned To"}
                options={usersOptions}
                value={checklistFormData.assignedTo.value}
                onChange={onChecklistTextChange}
                mandatory={checklistFormData.assignedTo.required}
                error={checklistFormData.assignedTo.error}
              />
            </Row>
            <ChecklistCheckboxContainer>
              {showImportFromTemplateButton && (
                <React.Fragment>
                  <CheckboxLabel>Active</CheckboxLabel>
                  <CheckboxImage
                    onClick={handleCheckbox}
                    src={
                      checklistFormData?.value?.value || false
                        ? images.checkedCheckbox
                        : images.uncheckedCheckbox
                    }
                    data-name='value'
                  />
                </React.Fragment>
              )}
            </ChecklistCheckboxContainer>
            {/* <Checkbox
              wrapperClass='form-checklist-checkbox'
              labelClass='form-checklist-checkbox-label'
              reverse={true}
              label={"Active"}
              width='100%'
              onClick={handleCheckbox}
              value={checklistFormData?.value?.value || false}
              required={false}
              error={checklistFormData?.value?.error || ""}
             
            />
               <Label disabled={disabled} className={labelClass}>
                {label}
                {required && <span>{"*"}</span>}
              </Label>
            )}
            */}
          </ChecklistFieldsContainer>
          <Button
            className='checklist-modal-save-button'
            title={checklistModal === "add" ? strings.common.add : strings.common.save}
            onClick={checklistModal === "add" ? addChecklist : updateChecklist}
          />
        </ChecklistModalContainer>
      </Popup>
      <Popup isOpen={!!checklistTemplateModal} closeModal={handleChecklistTemplateModal}>
        <ChecklistModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleChecklistTemplateModal} />
          <FormTitle>Select Checklist Template</FormTitle>
          <ChecklistFieldsContainer>
            <Select
              width={"100%"}
              name={"checklistTemplate"}
              label={"Checklist Template"}
              options={checklistTemplatesOptions}
              value={selectedChecklistTemplate}
              onChange={handleChecklistTemplate}
              mandatory={false}
              isMulti={true}
              error={""}
            />
          </ChecklistFieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={checklistModal === "add" ? strings.common.add : strings.common.save}
            onClick={addChecklistFromTemplate}
          />
        </ChecklistModalContainer>
      </Popup>
    </FormChecklistContainer>
  );
};
