import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  MediaGetAll,
  MediaUpdate,
  MediaCreate,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchAllMediaSuccess } from "./actions";
import Types from "./types";

function* createMediaWorker(action) {
  try {
    const options = {
      method: "POST",
      url: MediaCreate,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateMediaWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: MediaUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getAllMediaWorker(action) {
  try {
    const options = {
      method: "GET",
      url: MediaGetAll,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchAllMediaSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* JobsSaga() {
  yield takeLatest(Types.MEDIA_CREATE, createMediaWorker);
  yield takeLatest(Types.MEDIA_UPDATE, updateMediaWorker);
  yield takeEvery(Types.MEDIA_FETCH, getAllMediaWorker);
}
