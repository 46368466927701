/* eslint-disable no-unused-vars */
enum Types {
  FETCH_VARIABLE_SETS = "FETCH_VARIABLE_SETS",
  FETCH_VARIABLE_SETS_BY_ID = "FETCH_VARIABLE_SETS_BY_ID",
  SUCCESS_FETCH_VARIABLE_SETS = "SUCCESS_FETCH_VARIABLE_SETS",
  ERROR_FETCH_VARIABLE_SETS = "ERROR_FETCH_VARIABLE_SETS",
  CREATE_VARIABLE_SETS = "CREATE_VARIABLE_SETS",
  UPDATE_VARIABLE_SETS = "UPDATE_VARIABLE_SETS",
  DELETE_VARIABLE_SETS = "DELETE_VARIABLE_SETS",
}

export default Types;
