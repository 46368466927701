import Types from "./actionType";
import {
  FetchChecklistPayload,
  CreateChecklist,
  CreateChecklistPayload,
  FetchChecklist,
  FetchChecklistError,
  FetchChecklistSuccess,
  UpdateChecklist,
  UpdateChecklistPayload,
  ChecklistListWithMetaData,
  FetchChecklistByIdPayload,
  FetchChecklistById,
  DeleteChecklistByIdPayload,
  DeleteChecklistById,
} from "./types";

export const fetchChecklistList = ({
  params,
  onSuccess,
  onError,
}: FetchChecklistPayload): FetchChecklist => ({
  type: Types.FETCH_CHECKLIST,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const deleteChecklistById = ({
  recordId,
  checklistId,
  onSuccess,
  onError,
}: DeleteChecklistByIdPayload): DeleteChecklistById => ({
  type: Types.DELETE_CHECKLIST_BY_ID,
  payload: {
    recordId,
    checklistId,
    onSuccess,
    onError,
  },
});

export const fetchChecklistListSuccess = (
  data: ChecklistListWithMetaData,
): FetchChecklistSuccess => ({
  type: Types.SUCCESS_FETCH_CHECKLIST,
  payload: {
    data,
  },
});

export const fetchChecklistError = (error?: string): FetchChecklistError => ({
  type: Types.ERROR_FETCH_CHECKLIST,
  payload: { error },
});

export const createChecklist = ({
  data,
  onSuccess,
  onError,
}: CreateChecklistPayload): CreateChecklist => ({
  type: Types.CREATE_CHECKLIST,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateChecklist = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateChecklistPayload): UpdateChecklist => ({
  type: Types.UPDATE_CHECKLIST,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
