import styled from "styled-components";

import { colors, fs } from "../../../../theme";

interface Props {
  border?: string;
  leftArrow?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
  top?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-top: ${(props) => props.top};

  background-color: ${colors.common.white};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  border: 0.6px solid rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 14.45px;
  box-sizing: border-box;
  color: ${colors.common.whiteGrey};
  font-size: ${fs.s};
`;

export const Title = styled.h2<Props>`
  font-weight: 400;
`;

export const FilterIcon = styled.img``;
