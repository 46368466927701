import React from "react";

import { Layout } from "../../../presentationals";
import { TicketsAdd } from "../../../ScreensEmployees";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<TicketsAdd />}
      title={strings.headerTitle.ticketCreate}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
