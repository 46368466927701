import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchEducationDetailsList,
  getProfile,
  showInAppLoader,
  removeAppNotification,
  updateEducationDetails,
  updateTableConfigs,
  deleteEducationDetails,
} from "../../../../../redux";
import {
  DeleteEducationDetailsPayload,
  EducationDetailsList,
  EducationDetailsListWithMetaData,
  FetchEducationDetailsParams,
} from "../../../../../redux/EducationDetails/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  RelatedList,
  TableHeaderButton,
  fetchEducationDetailsProps,
  sortType,
  useEducationDetailsListProps,
  useEducationDetailsListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getEducationDetailsColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.educationDetails;
const defaultFilters: QueryBuilderType[] = [];

export const useEducationDetailsList = ({
  relatedList,
  widgetId,
}: useEducationDetailsListProps): useEducationDetailsListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.EducationDetailsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const educationDetails = useAppSelector((state) => state.EducationDetailsReducer.data.list);
  const { employeesOptions, usersOptions, countryOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const EducationDetailsConfigTableFields = useMemo(() => {
    return tableConfiguration?.educationDetails?.fields?.length
      ? tableConfiguration?.educationDetails?.fields
      : tableListDefaultSelectFields.educationDetails;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = EducationDetailsConfigTableFields;
    return getEducationDetailsColumns({ fields });
  }, [EducationDetailsConfigTableFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: EducationDetailsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.educationDetails?.fields ||
        tableListDefaultSelectFields.educationDetails;
      records = records?.map((item) => {
        const educationDetailsItem = { ...item };
        if (userPersonalizedFields?.includes("country") && typeof item.country !== "string") {
          educationDetailsItem.country = item?.country?.name;
        }
        if (
          userPersonalizedFields?.includes("educationType") &&
          typeof item.educationType !== "string"
        ) {
          educationDetailsItem.educationType = item?.educationType?.value;
        }
        if (
          userPersonalizedFields?.includes("associatedEmployee") &&
          typeof item.associatedEmployee !== "string"
        ) {
          educationDetailsItem.associatedEmployee = item?.associatedEmployee?.name;
        }
        if (userPersonalizedFields?.includes("fromDate")) {
          educationDetailsItem.fromDate = MomentDate({
            date: item.fromDate || "-",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("toDate")) {
          educationDetailsItem.toDate = MomentDate({
            date: item.toDate || "-",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          educationDetailsItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          educationDetailsItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          educationDetailsItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          educationDetailsItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete educationDetailsItem?._id;
        return educationDetailsItem;
      });
      const educationDetailsFields = tablePublicFields.educationDetails;
      const columnHeaders = educationDetailsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "education_details");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.educationDetails?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Education Detailss Success/Error Methods ================
  const educationDetailsFetchSuccess = useCallback(
    (data: EducationDetailsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );
  const educationDetailsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchEducationDetails = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchEducationDetailsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.educationDetails?.limit || 10;

      const params: FetchEducationDetailsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: EducationDetailsListWithMetaData) =>
          educationDetailsFetchSuccess(data, exportAll),
        onError: educationDetailsFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchEducationDetailsList(payload));
    },
    [
      widgetId,
      widgetsData,
      dispatch,
      educationDetailsFetchError,
      educationDetailsFetchSuccess,
      relatedList,
      metaData.currentPage,
      mongoQuery,
      sortKey,
      sortType,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = educationDetails?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, educationDetails, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchEducationDetails({ exportAll: true });
  }, [fetchEducationDetails]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchEducationDetails({ page });
  }, [fetchEducationDetails, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchEducationDetails({ page: parseInt(page), search });
    },
    [fetchEducationDetails, search],
  );

  // ================ Add/Edit Methods ================

  const onClickEducationDetails = useCallback(
    (id: string) => {
      if (permissions?.educationDetails_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.educationDetails_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchEducationDetails({ page: 1, search });
  }, [fetchEducationDetails, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchEducationDetails({ page: 1 });
  }, [fetchEducationDetails]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchEducationDetails({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchEducationDetails],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchEducationDetails({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchEducationDetails],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = educationDetails?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteEducationDetailsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchEducationDetails({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteEducationDetails(payload));
  }, [selectedRows, educationDetails, dispatch, notificationsId, fetchEducationDetails]);
  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = educationDetails?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, educationDetails]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            educationDetails?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, educationDetails, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const educationDetailsConfigLimit = tableConfiguration?.educationDetails?.limit || 10;
      const educationDetailsConfigs = {
        table: "educationDetails",
        fields: fields,
        limit: educationDetailsConfigLimit,
      };
      const payload = {
        tableConfiguration: educationDetailsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchEducationDetails({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Education Details table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.educationDetails?.limit, dispatch, fetchEducationDetails, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const educationDetailsConfigFields = tableConfiguration?.educationDetails?.fields;
      dispatch(showInAppLoader(true));
      const educationDetailsConfigs = {
        table: "educationDetails",
        fields: educationDetailsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: educationDetailsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchEducationDetails({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Education Details table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      setSelectedRows([]);
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchEducationDetails],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = educationDetails?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchEducationDetails({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Education Details updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Education Details",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateEducationDetails(payload));
    },
    [selectedRows, educationDetails, dispatch, notificationsId, fetchEducationDetails],
  );
  // ================ Table Header Buttons ================

  const educationDetailsHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "associatedEmployee",
      label: "Employee",
      options: employeesOptions,
      required: true,
    },
    {
      name: "country",
      label: "Country",
      options: countryOptions,
      required: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      required: true,
    },
    {
      name: "instituteName",
      label: "Institute Name",
      type: "text",
      required: true,
    },
    {
      name: "studentId",
      label: "Student Id",
      type: "text",
      required: true,
    },
    {
      name: "instituteAddress",
      label: "Institute Address",
      type: "text",
      required: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
    },
    {
      name: "postalCode",
      label: "Postal Code",
      type: "text",
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
    },
    {
      name: "currentlyAttending",
      label: "Currently Attending",
      options: ActiveSelectOptions,
    },
    {
      name: "receivedDegree",
      label: "Received Degree",
      options: ActiveSelectOptions,
    },
    {
      name: "graduationDate",
      label: "Graduation Date",
      type: "date",
    },
    {
      name: "fromDate",
      label: "From Date",
      type: "date",
      required: true,
    },
    {
      name: "toDate",
      label: "To Date",
      type: "date",
    },
    {
      name: "educationType",
      label: "educationType",
      options: getAttributValues("educationType"),
      required: true,
    },
    {
      name: "degreeName",
      label: "Degree Name",
      type: "text",
      required: true,
    },
    {
      name: "majorName",
      label: "Major Name",
      type: "text",
      required: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
    },
    {
      name: "instituteName",
      label: "Institute Name",
      type: "text",
    },
    {
      name: "studentId",
      label: "Student Id",
      type: "text",
    },
    {
      name: "instituteAddress",
      label: "Institute Address",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
    },
    {
      name: "postalCode",
      label: "Postal Code",
      type: "text",
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
    },
    {
      name: "currentlyAttending",
      label: "Currently Attending",
      options: ActiveSelectOptions,
    },
    {
      name: "receivedDegree",
      label: "Received Degree",
      options: ActiveSelectOptions,
    },
    {
      name: "graduationDate",
      label: "Graduation Date",
      type: "date",
    },
    {
      name: "toDate",
      label: "To Date",
      type: "date",
    },
    {
      name: "educationType",
      label: "educationType",
      options: getAttributValues("educationType"),
    },
    {
      name: "degreeName",
      label: "Degree Name",
      type: "text",
    },
    {
      name: "majorName",
      label: "Major Name",
      type: "text",
    },
    {
      name: "state",
      label: "State",
      type: "text",
    },
    {
      name: "instituteName",
      label: "Institute Name",
      type: "text",
    },
    {
      name: "studentId",
      label: "Student Id",
      type: "text",
    },
    {
      name: "instituteAddress",
      label: "Institute Address",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
    },
    {
      name: "postalCode",
      label: "Postal Code",
      type: "text",
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
    },
    {
      name: "currentlyAttending",
      label: "Currently Attending",
      options: ActiveSelectOptions,
    },
    {
      name: "receivedDegree",
      label: "Received Degree",
      options: ActiveSelectOptions,
    },
    {
      name: "graduationDate",
      label: "Graduation Date",
      type: "date",
    },
    {
      name: "toDate",
      label: "To Date",
      type: "date",
    },
    {
      name: "educationType",
      label: "educationType",
      options: getAttributValues("educationType"),
    },
    {
      name: "degreeName",
      label: "Degree Name",
      type: "text",
    },
    {
      name: "majorName",
      label: "Major Name",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const educationDetailsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const countryFilterOptions = getFilterOptions(countryOptions, "Technology");
    const educationTypeFilterOptions = getFilterOptions(
      getAttributValues("educationType"),
      "Technology",
    );
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const activeOptions = getFilterOptions(ActiveSelectOptions, "Users");

    const fields: Field[] = [
      {
        name: "associatedEmployee",
        label: "Employee",
        valueEditorType: "select",
        values: employeeyOptions,
        defaultValue: employeeyOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        defaultValue: countryFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },

      {
        name: "educationType",
        label: "Education Type",
        valueEditorType: "select",
        values: educationTypeFilterOptions,
        defaultValue: educationTypeFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "graduationDate",
        label: "Graduation Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "state",
        label: "State/Province",
        inputType: "text",
        validator,
      },
      {
        name: "instituteName",
        label: "Name of the Institute",
        inputType: "text",
        validator,
      },
      {
        name: "studentId",
        label: "Student Id",
        inputType: "text",
        validator,
      },
      {
        name: "instituteAddress",
        label: "Address of the Institute",
        inputType: "text",
        validator,
      },
      {
        name: "city",
        label: "city",
        inputType: "text",
        validator,
      },
      {
        name: "phone",
        label: "phone",
        inputType: "number",
        validator,
      },
      {
        name: "currentlyAttending",
        label: "Currently Attending",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "receivedDegree",
        label: "Did the employee graduate and receive a degree?",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "degreeName",
        label: "Degree Name",
        inputType: "text",
        validator,
      },
      {
        name: "majorName",
        label: "Major Name",
        inputType: "text",
        validator,
      },
      {
        name: "postalCode",
        label: "Postal Code",
        inputType: "number",
        validator,
      },
      {
        name: "fromDate",
        label: "To Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "toDate",
        label: "From Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },

      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [employeesOptions, usersOptions, countryOptions]);

  return {
    listActionOptions,
    educationDetailsFilterFields,
    onClickEducationDetails,
    fetchEducationDetails,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    educationDetailsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
    columns,
    EducationDetailsConfigTableFields,
  };
};
