import React from "react";

import { Layout } from "../../../presentationals";
import { SubscriberController } from "../../../presentationals/screens/admin/Subscriber/SubscriberController";

const Route = () => {
  return <Layout activity={<SubscriberController type={"add"} />} admin={true} layout={"2"} />;
};

export const APSubscriberAdd = React.memo(Route);
