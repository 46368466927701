import Select from "react-select";
import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  position: relative;
  width: ${(props) => props.width};
`;

export const SelectInput = styled(Select)`
  .Select__control {
    font-size: ${fs.m};
    font-weight: 400;
    color: ${colors.common.lightTextColor};
    font-family: ${fonts.SourceSansPro};
    font-weight: normal;
    min-height: 50px;
    width: 100%;
    padding-left: 20px;
    background: ${colors.common.white};
    border: 0.6px solid ${(props) => (props.error ? colors.common.red : colors.common.black)};
    border-radius: 5px;
  }

  .Select__option {
    /* font-size: ${fs.m};
    font-weight: 500;
    color: ${colors.common.lightTextColor}; */
  }

  .Select__control:hover {
    border: 1px solid ${(props) => (props.error ? colors.common.error : "#757575")};
  }

  .Select__control--is-focused {
    box-shadow: none;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    font-size: ${fs.m};
    font-weight: 500;
    color: ${colors.common.lightTextColor};
  }
`;

export const Label = styled.label`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 14px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: ${fs.m};
  line-height: 20px;
  display: flex;
  color: ${colors.input.placeholder};
  transition: 0.2s ease all;
  span {
    color: ${colors.common.red};
  }
  &.focused {
    top: -10px;
    font-weight: 600;
    height: 24px;
    font-size: ${fs.s};
    color: ${colors.common.black};
    background-color: ${colors.common.white};
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.common.error};
  margin-top: 4px;
`;
