import React, { useCallback, useEffect, useState, Fragment, useMemo } from "react";

import { addAppNotification, removeAppNotification } from "../../../../redux";
import { fetchActivityLogsList } from "../../../../redux/ActivityLogs/actions";
import {
  ActivityLogsListWithMetaData,
  ActivityLogsLogItem,
  DisplayActivityLogs,
} from "../../../../redux/ActivityLogs/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { ActivityLog, AppNotificationsType, LogEntry } from "../../../../types";
import { activityMonitorDisplayFields } from "../../../../utils";
import { MyTooltip } from "../../atoms";

import {
  Comment,
  CommentDate,
  CommentItem,
  CommentItemContainer,
  CommentMetaContainer,
  CommentSeparator,
  ActivityLogsContainer,
  Container,
  EmptyCommentItem,
  NoActivityLogs,
  ProfileImage,
  ProfileImageContainer,
  StartLabel,
  UserNameInitial,
  ActivityLogsItemContainer,
  LogItemContainer,
} from "./styles";

interface Props {
  collection: string;
  recordId?: string;
}

export const ActivityLogs = ({ collection, recordId }: Props) => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const currenctUserProfile = useAppSelector((state) => state?.UserReducer?.profilePic?.location);
  const currentUserId = useAppSelector((state) => state.UserReducer.id);
  const currentUserName = useAppSelector((state) => state.UserReducer.name);
  const displayFields = useMemo(() => {
    return activityMonitorDisplayFields?.[collection];
  }, [collection]);

  const [logs, setLogs] = useState<ActivityLogsLogItem[]>([]);

  const dispatch = useAppDispatch();

  const getDate = useCallback((date?: Date) => {
    if (date) {
      date = new Date(date);
      return `${date.toDateString()}, ${date.getHours()}:${date.getMinutes()}`;
    }
    return typeof date;
  }, []);

  const fetchActivityLogsSuccess = useCallback((data: ActivityLogsListWithMetaData) => {
    const logs = data?.list;
    if (logs?.length) {
      setLogs(logs.reverse());
    }
    // if (logs?.length) {
    //   // Group logs by timestamp and then by updatedBy
    //   const groupedLogs: Map<
    //     string | Date | undefined,
    //     Map<string, ActivityLogsLogItem[]>
    //   > = new Map();

    //   logs.forEach((log) => {
    //     // Check if the timestamp is a Date
    //     const timestamp =
    //       log.timestamp instanceof Date
    //         ? log.timestamp.toISOString()
    //         : log.timestamp || "No Timestamp";
    //     const updatedBy = typeof log.updatedBy !== "string" ? log.updatedBy._id : log.updatedBy;
    //     if (!groupedLogs.has(timestamp)) {
    //       groupedLogs.set(timestamp, new Map());
    //     }
    //     if (!groupedLogs.get(timestamp).has(updatedBy)) {
    //       groupedLogs.get(timestamp).set(updatedBy, []);
    //     }
    //     groupedLogs.get(timestamp).get(updatedBy)?.push(log);
    //   });
    //   setLogs(groupedLogs);
    //   // groupedLogs.forEach((updatedByLogs, timestamp) => {
    //   //   console.log(`Timestamp: ${timestamp}`);
    //   //   updatedByLogs.forEach((logs, updatedBy) => {
    //   //     console.log(`  Updated By: ${updatedBy}`);
    //   //     logs.forEach((log) => {
    //   //       console.log(`    Field: ${log.field}`);
    //   //       console.log(`    Old Value: ${log.oldValue}`);
    //   //       console.log(`    New Value: ${log.newValue}`);
    //   //       console.log(`    Reference Type: ${log.referenceType}`);
    //   //       console.log(`    Old Reference ID: ${log.oldReferenceId}`);
    //   //       console.log(`    New Reference ID: ${log.newReferenceId}`);
    //   //       console.log("---");
    //   //     });
    //   //   });
    //   // });
    // }
  }, []);

  const fetchActivityLogsError = useCallback(
    (error: string) => {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [notificationsId, dispatch],
  );

  const logDisplayValue = useCallback((item: ActivityLogsLogItem, username: string): string => {
    return `Test`;
  }, []);

  const renderComment = useCallback(
    (logItem: ActivityLogsLogItem) => {
      const { field, newValue, oldValue, actionType } = logItem;
      const fieldName = displayFields?.[field];
      switch (actionType) {
        case "ADD":
          return (
            <Comment>
              Added <b>{fieldName}</b>
              <i>{`${newValue}`} </i>
            </Comment>
          );
        case "UPDATE":
          return (
            <Comment>
              Changed <b>{fieldName}</b> from <i>{`${oldValue}`}</i> to
              <i>{`${newValue}`} </i>
            </Comment>
          );
        case "Delete":
          return (
            <Comment>
              Deleted <b>{fieldName}</b>
              <i>{`${newValue}`} </i>
            </Comment>
          );
      }
    },
    [displayFields],
  );

  const Logs = ({ logs }: { logs: ActivityLogsLogItem[] }) => {
    const groupedLogs: DisplayActivityLogs[] = [];
    const logsMap = new Map();

    for (const log of logs) {
      const { timestamp, updatedBy } = log;
      const key = `${timestamp}_${updatedBy}`;
      if (!logsMap.has(key)) {
        logsMap.set(key, {
          timestamp,
          updatedBy,
          logs: [],
        });
      }
      logsMap.get(key).logs.push(log);
    }

    groupedLogs.push(...logsMap.values());
    return (
      <React.Fragment>
        {groupedLogs.map((activityLogs, index) => {
          const username =
            typeof activityLogs.updatedBy !== "string" ? activityLogs.updatedBy.name : "";
          const profilePic =
            typeof activityLogs.updatedBy !== "string"
              ? activityLogs?.updatedBy?.profilePic?.location
              : "";
          const timeStampDate = activityLogs.timestamp
            ? new Date(activityLogs.timestamp)
            : new Date();
          return (
            <ActivityLogsItemContainer key={index}>
              <LogItemContainer>
                {activityLogs.logs.map((logItem, logIndex) => {
                  return (
                    <CommentItemContainer key={logIndex}>
                      {renderComment(logItem)}
                    </CommentItemContainer>
                  );
                })}
                <CommentMetaContainer>
                  <CommentDate>{username}</CommentDate>
                  <CommentDate>{getDate(new Date(timeStampDate))}</CommentDate>
                </CommentMetaContainer>
              </LogItemContainer>
              <ProfileImageContainer>
                <MyTooltip text={username}>
                  {profilePic ? (
                    <ProfileImage src={profilePic} />
                  ) : (
                    <UserNameInitial>{username?.[0]}</UserNameInitial>
                  )}
                </MyTooltip>
              </ProfileImageContainer>
            </ActivityLogsItemContainer>
          );
        })}
      </React.Fragment>
    );
  };

  useEffect(() => {
    const payload = {
      params: {
        recordId,
        collection,
      },
      onSuccess: fetchActivityLogsSuccess,
      onError: fetchActivityLogsError,
    };
    dispatch(fetchActivityLogsList(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, recordId, dispatch]);

  return (
    <Container>
      <ActivityLogsContainer>
        {!logs?.length ? (
          <NoActivityLogs>{strings.common.noActivityLogs}</NoActivityLogs>
        ) : (
          <>
            <Logs logs={logs} />
          </>
        )}

        {!!logs?.length && (
          <CommentItemContainer>
            <EmptyCommentItem />
            <ProfileImageContainer>
              <CommentSeparator />
              <StartLabel>{strings.common.start}</StartLabel>
            </ProfileImageContainer>
            <EmptyCommentItem />
          </CommentItemContainer>
        )}
      </ActivityLogsContainer>
    </Container>
  );
};
