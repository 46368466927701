export const images = {
  searchIcon: require("../assets/images/searchIcon.png"),
  epHome: require("../assets/images/epHome.png"),
  epHomeActive: require("../assets/images/epHomeActive.png"),
  epSalary: require("../assets/images/epSalary.png"),
  epSalaryActive: require("../assets/images/epSalaryActive.png"),
  epTodo: require("../assets/images/epTodo.png"),
  epTodoActive: require("../assets/images/epTodoActive.png"),
  epKnowledge: require("../assets/images/epKnowledge.png"),
  settings: require("../assets/images/settings.svg").default,
  epKnowledgeActive: require("../assets/images/epKnowledgeActive.png"),
  epServiceTickets: require("../assets/images/epServiceTickets.png"),
  epServiceTicketsActive: require("../assets/images/epServiceTicketsActive.png"),
  arrowRight: require("../assets/images/arrowRight.png"),
  activeRightArrow: require("../assets/images/activeRightArrow.svg").default,
  crossIcon: require("../assets/images/crossIcon.svg").default,
  sortIcon: require("../assets/images/sortIcon.svg").default,
  arrowLeft: require("../assets/images/arrowLeft.png"),
  accordionIcon: require("../assets/images/accordionIcon.png"),
  accordionActive: require("../assets/images/accordionIconActive.png"),
  epTickets: require("../assets/images/epTickets.png"),
  epTicketsActive: require("../assets/images/epTicketsActive.png"),
  epNotification: require("../assets/images/epNotification.png"),
  defaultProfile: require("../assets/images/defaultProfile.png"),
  blueLeftArrow: require("../assets/images/blueLeftArrow.png"),
  orangeDoubleTick: require("../assets/images/orangeDoubleTick.png"),
  epView: require("../assets/images/epView.png"),
  epCreatedBy: require("../assets/images/epCreatedBy.png"),
  epLine: require("../assets/images/epLine.png"),
  epHistory: require("../assets/images/epHistory.png"),
  epDoubleTick: require("../assets/images/epDoubleTick.png"),
  defaultOrg: require("../assets/images/unity.png"),
  filterIcon: require("../assets/images/FilterIcon.svg"),
  breadCrumbArrowIcon: require("../assets/images/breadCrumbArrow.png"),
  activePackageTick: require("../assets/images/activePackageTick.png"),
  greenPencil: require("../assets/images/greenPencil.png"),
  TableGraphic: require("../assets/images/TableGraphic.png"),
  Tempswitch: require("../assets/images/Tempswitch.png"),
  knowledgeListIcon: require("../assets/images/knowledgeListIcon.svg").default,
  knowledgeAttachment: require("../assets/images/knowledgeAttachment.svg").default,
  knowledgeCreatedBy: require("../assets/images/knowledgeCreatedBy.svg").default,
  knowledgeHistory: require("../assets/images/knowledgeHistory.svg").default,
  searchCancel: require("../assets/images/searchCancel.svg").default,
  uncheckedCheckbox: require("../assets/images/uncheckedCheckbox.svg").default,
  checkedCheckbox: require("../assets/images/checkedCheckbox.svg").default,
  closeModal: require("../assets/images/closeModal.png"),
  toolTip: require("../assets/images/toolTip.png"),
  crossFilterIcon: require("../assets/images/crossFilterIcon.svg").default,
  KnowledgeCategoryIcon: require("../assets/images/KnowledgeCategoryIcon.svg").default,
  attachmentsIcon: require("../assets/images/attachmentsIcon.svg").default,
  defaultFileIcon: require("../assets/images/defaultFileIcon.svg").default,
  deleteAttachment: require("../assets/images/deleteAttachment.svg").default,
  downloadAttachment: require("../assets/images/downloadAttachment.svg").default,
  viewAttachment: require("../assets/images/viewAttachment.svg").default,
  dragIcon: require("../assets/images/dragIcon.svg").default,
  pdfFileIcon: require("../assets/images/pdfFileIcon.svg").default,
  logoImg:
    "https://itnow.nyc3.digitaloceanspaces.com/development/621b3b95df07798996b5c131-1666902021426",
  switchOn: require("../assets/images/switchOn.png"),
  switchOff: require("../assets/images/switchOff.png"),
  boxArrowRight: require("../assets/images/boxArrowRight.png"),
  boxArrowLeft: require("../assets/images/boxArrowLeft.png"),
  search: require("../assets/images/Search.png"),
  blackPencil: require("../assets/images/blackPencil.png"),
  redTrash: require("../assets/images/redTrash.png"),
  reloadIcon: require("../assets/images/reloadIcon.svg").default,
  pngFileIcon: require("../assets/images/pngFileIcon.png"),
  docFileIcon: require("../assets/images/docFileIcon.png"),
  docxFileIcon: require("../assets/images/docxFileIcon.png"),
  pptFileIcon: require("../assets/images/pptFileIcon.png"),
  jpegFileIcon: require("../assets/images/jpegFileIcon.png"),
  csvFileIcon: require("../assets/images/csvFileIcon.png"),
  xmlFileIcon: require("../assets/images/xmlFileIcon.png"),
  xlsxFileIcon: require("../assets/images/xlsxFileIcon.png"),
  gifFileIcon: require("../assets/images/gifFileIcon.png"),
  jpgFileIcon: require("../assets/images/pdfIcon.png"),
  pptxFileIcon: require("../assets/images/pptxFileIcon.png"),
  txtFileIcon: require("../assets/images/txtFileIcon.png"),
  dotsIcon: require("../assets/images/dots.png"),
  ascIcon: require("../assets/images/asc.png"),
  descIcon: require("../assets/images/desc.png"),
  refreshIcon: require("../assets/images/refresh.svg").default,
  // listSearchIcon: require("../assets/images/listSearchIcon.svg").default,
  personalisedListIcon: require("../assets/images/personalisedListIcon.png"),
  leftSingleArrow: require("../assets/images/leftSingleArrow.png"),
  rightSingleArrow: require("../assets/images/rightSingleArrow.png"),
  leftDoubleArrow: require("../assets/images/leftDoubleArrow.png"),
  rightDoubleArrow: require("../assets/images/rightDoubleArrow.png"),
};
