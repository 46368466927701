import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import {
  ActiveSelectOptions,
  BankDetailsAssociatedTo,
  collections,
} from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, Checkbox, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useBankDetailsController } from "./hooks";
import { Row } from "./styles";

export const BankDetailsController = ({ type }: { type?: "add" | "edit" }) => {
  const [searchQuery] = useSearchParams();
  const recordType = searchQuery.get("recordType");
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions, employeesOptions, currencyOptions, companyOptions } =
    useControllerConfigs();

  const params = useParams();
  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    handleCheckbox,
    loading,
    headerTitle,
    fetchBankDetails,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    checklist,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useBankDetailsController({ type, id: params.id, attachmentsPickerRef, recordType });
  const currentBankDetails = data;

  const {
    workLogs,
    attachments,
    associatedEmployee,
    active,
    bankName,
    bankCountry,
    accountType,
    accountName,
    routingNumber,
    accountNumber,
    bankCurrency,
    routingType,
    swiftBICCode,
    associatedRecord,
    associatedCompany,
    voidCheque,
    defaultAccount,
    bankType,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchBankDetails();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"associatedRecord"}
            label={"Associated To"}
            options={BankDetailsAssociatedTo}
            value={associatedRecord.value}
            onChange={onTextChange}
            mandatory={associatedRecord.required}
            error={associatedRecord.error}
          />
          {associatedRecord.value === "employee" && (
            <Select
              width={"48%"}
              name={"associatedEmployee"}
              label={"Associated Employee"}
              options={employeesOptions}
              value={associatedEmployee.value}
              onChange={onTextChange}
              mandatory={associatedRecord.value === "employee"}
              error={associatedEmployee.error}
            />
          )}
          {associatedRecord.value === "company" && (
            <Select
              width={"48%"}
              name={"associatedCompany"}
              label={"Associated Company"}
              options={companyOptions}
              value={associatedCompany.value}
              onChange={onTextChange}
              mandatory={associatedRecord.value === "company"}
              error={associatedCompany.error}
            />
          )}
        </Row>
        <Row>
          <Select
            width={"33%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
          <Select
            width={"33%"}
            name={"bankType"}
            label={"Type of the Bank"}
            options={getAttributValues("bankType")}
            value={bankType.value}
            onChange={onTextChange}
            mandatory={bankType.required}
            error={bankType.error}
          />
          <Select
            width={"33%"}
            name={"defaultAccount"}
            label={"Default Account"}
            options={ActiveSelectOptions}
            value={defaultAccount.value}
            onChange={onTextChange}
            mandatory={defaultAccount.required}
            error={defaultAccount.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Bank Name"}
            type={"text"}
            width='33%'
            error={bankName.error}
            inputProps={{
              name: "bankName",
              value: bankName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: bankName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"33%"}
            name={"bankCountry"}
            label={"Country"}
            options={countryOptions}
            value={bankCountry.value}
            onChange={onTextChange}
            mandatory={bankCountry.required}
            error={bankCountry.error}
          />
          <Select
            width={"33%"}
            name={"bankCurrency"}
            label={"Bank Currency"}
            options={currencyOptions}
            value={bankCurrency.value}
            onChange={onTextChange}
            mandatory={bankCurrency.required}
            error={bankCurrency.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"accountType"}
            label={"Type of the Account"}
            options={getAttributValues("bankAccountType")}
            value={accountType.value}
            onChange={onTextChange}
            mandatory={accountType.required}
            error={accountType.error}
          />
          <TextInput
            label={"Swift / BIC Code"}
            type={"text"}
            width='48%'
            error={swiftBICCode.error}
            inputProps={{
              name: "swiftBICCode",
              value: swiftBICCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: swiftBICCode.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Account Name"}
            type={"text"}
            width='48%'
            error={accountName.error}
            inputProps={{
              name: "accountName",
              required: accountName.required,
              value: accountName.value,
              autoComplete: "off",
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Account Number"}
            type={"text"}
            width='48%'
            error={accountNumber.error}
            inputProps={{
              type: "number",
              name: "accountNumber",
              required: accountNumber.required,
              value: accountNumber.value,
              autoComplete: "off",
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"routingType"}
            label={"Bank Routing Details"}
            options={getAttributValues("bankRoutingType")}
            value={routingType.value}
            onChange={onTextChange}
            mandatory={routingType.required}
            error={routingType.error}
          />

          <TextInput
            label={"Bank Routing Number"}
            type={"text"}
            width='48%'
            error={routingNumber.error}
            inputProps={{
              name: "routingNumber",
              value: routingNumber.value,
              autoComplete: "off",
              required: routingNumber.required,
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
        </Row>

        <Row>
          <Checkbox
            label={"Voided Cheque Attached"}
            width='48%'
            error={voidCheque.error}
            required={voidCheque.required}
            onClick={handleCheckbox}
            value={voidCheque.value}
            attributes={{
              "data-name": "voidCheque",
            }}
          />
        </Row>
      </>
    );
  }, [
    associatedRecord.value,
    associatedRecord.required,
    associatedRecord.error,
    onTextChange,
    employeesOptions,
    associatedEmployee.value,
    associatedEmployee.error,
    companyOptions,
    associatedCompany.value,
    associatedCompany.error,
    active.value,
    active.required,
    active.error,
    bankType.value,
    bankType.required,
    bankType.error,
    defaultAccount.value,
    defaultAccount.required,
    defaultAccount.error,
    bankName.error,
    bankName.value,
    bankName.required,
    countryOptions,
    bankCountry.value,
    bankCountry.required,
    bankCountry.error,
    bankCurrency.value,
    bankCurrency.required,
    bankCurrency.error,
    accountType.value,
    accountType.required,
    accountType.error,
    swiftBICCode.error,
    swiftBICCode.value,
    swiftBICCode.required,
    accountName.error,
    accountName.required,
    accountName.value,
    accountNumber.error,
    accountNumber.required,
    accountNumber.value,
    routingType.value,
    routingType.required,
    routingType.error,
    routingNumber.error,
    routingNumber.value,
    routingNumber.required,
    voidCheque.error,
    voidCheque.required,
    voidCheque.value,
    handleCheckbox,
    currencyOptions,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentBankDetails?.createdBy !== "string" ? currentBankDetails?.createdBy?.name : "";
    const updatedBy =
      typeof currentBankDetails?.updatedBy !== "string" ? currentBankDetails?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentBankDetails?.createdAt}
        updatedAt={currentBankDetails?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentBankDetails?.createdAt,
    currentBankDetails?.createdBy,
    currentBankDetails?.updatedAt,
    currentBankDetails?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        recordId={params.id}
        collection={
          recordType === "associatedCompany"
            ? collections.companyBankDetails.name
            : collections.employeeBankDetails.name
        }
      />
    ),

    [params.id, recordType],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.bankdetails.name} />,
    [params.id],
  );

  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData();
      case 5:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderRelatedEmployees,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    renderActivitylogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
