import React, { useCallback, useEffect, useMemo, useState } from "react";

import { connect } from "react-redux";

import Header from "../../components/Header";
import Input from "../../components/Input/input";
import StyledTable from "../../components/Table/tables.styles";
import { Button, Header as MainHeader } from "../../presentationals/components";
import {
  DriveFetch as DriveFetchDispatch,
  DriveUpload,
  uploadFileS3,
  driveOperations,
} from "../../redux/Drive/actions";
import { useTitle } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./DriveListing.styles";

const DriveListing = (props: any) => {
  const fetch = props.DriveFetchDispatch;
  const upload = props.DriveUpload;
  const fileUploadS3 = props.uploadFileS3;
  const [path, setPath] = useState("");
  const [newFolder, setNewFolder] = useState(false);
  const [newFile, setNewFile] = useState(false);
  const [newFileMessage, setNewFileMessage] = useState("");
  const [folderName, setFolderName] = useState("");
  const [copyInfo, setCopyInfo] = useState({
    type: "",
    path: "",
  });
  const [moveInfo, setMoveInfo] = useState({
    type: "",
    path: "",
  });
  const activeIndex = useMemo(() => {
    const index = props.data.findIndex((item) => item.metaData.trailingPath === path);
    return index;
  }, [path, props.data]);

  const metaData = useMemo(() => {
    return props.data?.[activeIndex]?.metaData;
  }, [activeIndex, props.data]);

  const actionDriveOperation = props.driveOperations;

  const findAndUpdatePathByIndex = useCallback(
    (index) => {
      const path = props.data[index];
      setPath(path.metaData.trailingPath);
    },
    [props.data],
  );

  const onFolderClick = useCallback(
    (e) => {
      const value = e.target.getAttribute("data-folder");
      setPath(value);
      fetch({ path: value });
    },
    [fetch],
  );
  const onFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      const split = file.name.split(".");
      const extension = split[split.length - 1];
      split.pop();
      const fileName = split.join("").replace(/[^A-Z0-9]/gi, "_") + "." + extension;

      upload({
        path: props.data?.[activeIndex]?.metaData?.trailingPath
          ? props.data?.[activeIndex]?.metaData?.trailingPath + "/" + fileName
          : fileName,
        type: "file",
        onSuccess: (data) => {
          console.log(data);
          fileUploadS3({
            file: file,
            url: data.data.url,
            onProgress: (progress) => {
              setNewFileMessage(`Upload ${progress}%`);
            },
            onSuccess: () => {
              setNewFile(false);
              setNewFileMessage("");
              fetch({ path: props.data?.[activeIndex]?.metaData?.trailingPath });
            },
            onError: (error) => {
              console.log(error);
            },
          });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    },
    [activeIndex, fetch, fileUploadS3, props.data, upload],
  );

  const NewFolderRender = useMemo(() => {
    return (
      <div>
        <Input
          label={"Folder Name"}
          type={"text"}
          name='name'
          value={folderName}
          autoComplete={"off"}
          onChange={(e) => setFolderName(e.target.value?.replace(" ", ""))}
        />
        <Button
          title={"Create"}
          onClick={() => {
            upload({
              path: props.data?.[activeIndex]?.metaData?.trailingPath
                ? props.data?.[activeIndex]?.metaData?.trailingPath + "/" + folderName
                : folderName,
              type: "folder",
              onSuccess: (data) => {
                setNewFolder(false);
                setFolderName("");
                fetch({ path: props.data?.[activeIndex]?.metaData?.trailingPath });
              },
              onError: (error) => {
                console.log(error);
              },
            });
          }}
        />
      </div>
    );
  }, [activeIndex, fetch, folderName, props.data, upload]);

  const newFileRender = useMemo(() => {
    return (
      <div>
        <Input
          label={"File for upload"}
          type={"file"}
          name='name'
          autoComplete={"off"}
          message={newFileMessage}
          onChange={onFileChange}
        />
      </div>
    );
  }, [newFileMessage, onFileChange]);

  const handleRefresh = useCallback(() => {
    fetch({ path: props.data?.[activeIndex]?.metaData?.trailingPath });
  }, [activeIndex, fetch, props.data]);

  const handleOnDelete = useCallback(
    (e) => {
      const path = e.target.getAttribute("data-path");
      const type = e.target.getAttribute("data-type");
      const trailingPath = metaData?.trailingPath;
      const appendedPath = type === "folder" && trailingPath ? trailingPath + "/" + path : path;
      actionDriveOperation({
        operation: "delete",
        path: type === "folder" ? appendedPath + "/" : appendedPath,
        onSuccess: handleRefresh,
        onError: (err) => {
          console.log(err);
        },
      });
    },
    [actionDriveOperation, handleRefresh, metaData?.trailingPath],
  );

  const handleCopyClick = useCallback(
    (e) => {
      const path = e.target.getAttribute("data-path");
      const type = e.target.getAttribute("data-type");
      const trailingPath = metaData?.trailingPath;
      const appendedPath = trailingPath ? trailingPath + "/" + path : path;
      setCopyInfo({
        type: type,
        path: appendedPath,
      });
    },
    [metaData?.trailingPath],
  );

  const handleOnCopy = useCallback(() => {
    const trailingPath = metaData?.trailingPath;
    const pathArray = copyInfo.path.split("/");
    const endPath = pathArray[pathArray.length - 1];
    const appendedPath = trailingPath ? trailingPath + "/" + endPath : endPath;
    actionDriveOperation({
      operation: "copy",
      path: copyInfo.path,
      newPath: appendedPath,
      onSuccess: () => {
        handleRefresh();
        setCopyInfo({ type: "", path: "" });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }, [actionDriveOperation, copyInfo, handleRefresh, metaData?.trailingPath]);

  const handlePasteClick = useCallback(() => {
    handleOnCopy();
  }, [handleOnCopy]);

  const handleMoveClick = useCallback(
    (e) => {
      const path = e.target.getAttribute("data-path");
      const type = e.target.getAttribute("data-type");
      const trailingPath = metaData?.trailingPath;
      const appendedPath = trailingPath ? trailingPath + "/" + path : path;
      console.log(appendedPath);
      setMoveInfo({
        type: type,
        path: appendedPath,
      });
    },
    [metaData?.trailingPath],
  );

  const handleOnMove = useCallback(() => {
    const trailingPath = metaData?.trailingPath;
    const pathArray = moveInfo.path.split("/");
    const endPath = pathArray[pathArray.length - 1];
    const appendedPath = trailingPath ? trailingPath + "/" + endPath : endPath;
    actionDriveOperation({
      operation: "rename",
      path: moveInfo.path,
      newPath: appendedPath,
      onSuccess: () => {
        handleRefresh();
        setMoveInfo({ type: "", path: "" });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }, [actionDriveOperation, handleRefresh, metaData?.trailingPath, moveInfo.path]);

  const handleOnMoveClick = useCallback(() => {
    handleOnMove();
  }, [handleOnMove]);

  useTitle("Drive");

  useEffect(() => {
    fetch({});
  }, [fetch]);

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.driveListing} />
      <Header title={"Drive"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ cursor: "pointer", verticalAlign: "center" }}
            onClick={() => findAndUpdatePathByIndex(0)}
          >
            Home
          </div>
          {props.data?.[activeIndex]?.metaData?.trailingPath.split("/").map((item, index) => (
            <div
              style={{ cursor: "pointer" }}
              key={index}
              onClick={() => findAndUpdatePathByIndex(index + 1)}
            >
              / {item}
            </div>
          ))}
        </div>
        <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
          {!!moveInfo.path && <Button title={"Move"} onClick={handleOnMoveClick} />}
          {!!copyInfo.path && <Button title={"Paste"} onClick={handlePasteClick} />}
          <Button
            title={"Add Folder"}
            onClick={() => {
              setNewFolder(true);
              setNewFile(false);
            }}
          />
          <Button
            title={"Add File"}
            onClick={() => {
              setNewFile(true);
              setNewFolder(false);
            }}
          />
        </div>
      </div>

      {newFolder && NewFolderRender}
      {newFile && newFileRender}
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              -
            </th>
            <th align='left'>Name</th>
            <th align='left'>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 &&
            props.data?.[activeIndex]?.folders?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>Folder</td>
                  <td
                    data-folder={
                      props.data?.[activeIndex]?.metaData?.trailingPath
                        ? props.data?.[activeIndex]?.metaData?.trailingPath + "/" + item
                        : item
                    }
                    style={{ cursor: "pointer" }}
                    onClick={onFolderClick}
                  >
                    {item}
                  </td>
                  <td>
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item}
                      data-type={"folder"}
                      onClick={handleMoveClick}
                    >
                      move
                    </span>{" "}
                    -{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item}
                      data-type={"folder"}
                      onClick={handleCopyClick}
                    >
                      copy
                    </span>{" "}
                    -{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item}
                      data-type={"folder"}
                      onClick={handleOnDelete}
                    >
                      delete
                    </span>
                  </td>
                </tr>
              );
            })}

          {props.data.length > 0 &&
            props.data?.[activeIndex]?.files?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>File</td>
                  <td>{item.Key}</td>
                  <td>
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item.Key}
                      data-type={"file"}
                      onClick={handleMoveClick}
                    >
                      move
                    </span>{" "}
                    -{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item.Key}
                      data-type={"file"}
                      onClick={handleCopyClick}
                    >
                      copy
                    </span>{" "}
                    -{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      data-path={item.Key}
                      data-type={"file"}
                      onClick={handleOnDelete}
                    >
                      delete
                    </span>
                  </td>
                </tr>
              );
            })}
          {/* {filteredData().map((key, index: number) => {
            const item = props.defData?.[key];
            if (item?.name?.toLowerCase().includes(props.filters.name)) {
              return (
                typeof item === "object" && (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td align='center'>
                      <Link to={`/ap/emailtemplates/${key}/edit`}>Edit</Link>
                    </td>
                  </tr>
                )
              );
            }
          })} */}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  data: state.DriveReducer.list,
  loading: state.DriveReducer.loading,
});

const mapDispatchToProps = {
  DriveFetchDispatch,
  DriveUpload,
  uploadFileS3,
  driveOperations,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriveListing);
