import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { CampaignsControllerProps } from "../../../../types/models/Campaigns";
import { NewsletterResourcesStatus, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useCampaignsController } from "./hooks";

const Controller = ({ type }: CampaignsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { CampaignsAssetsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchCampaigns,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useCampaignsController({ type, id, attachmentsPickerRef });
  const currentCampaigns = data;

  const { workLogs, attachments, campaign, status } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchCampaigns();
    }
    // Do not include fetchCampaigns to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <Select
            width={"48%"}
            name={"campaign"}
            label={"Campaign"}
            options={CampaignsAssetsOptions}
            value={campaign.value}
            onChange={onTextChange}
            mandatory={campaign.required}
            error={campaign.error}
          />
          <Select
            name={"status"}
            label={"Status"}
            options={NewsletterResourcesStatus}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    CampaignsAssetsOptions,
    campaign.error,
    campaign.required,
    campaign.value,
    onTextChange,
    status.error,
    status.required,
    status.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentCampaigns?.createdBy !== "string" ? currentCampaigns?.createdBy?.name : "";
    const updatedBy =
      typeof currentCampaigns?.updatedBy !== "string" ? currentCampaigns?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentCampaigns?.createdAt}
        updatedAt={currentCampaigns?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentCampaigns?.createdAt,
    currentCampaigns?.createdBy,
    currentCampaigns?.updatedAt,
    currentCampaigns?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.campaigns.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.campaigns.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const CampaignsController = memo(Controller);
