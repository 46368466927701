import "./loader.css";
import { FaSpinner } from "react-icons/fa";

import { fonts } from "../../services/theme";

function Loader() {
  return (
    <div className='container'>
      <FaSpinner icon='spinner' size={20} className='spinner' />
      <span style={{ fontFamily: fonts.font1, fontSize: 12, marginTop: 10 }}>please wait</span>
    </div>
  );
}

export default Loader;
