import { AxiosError, AxiosResponse } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  KnowledgeAdd,
  KnowledgeUpdate,
  KnowledgeFetch,
  KnowledgeApproval,
  KnowledgeRequestReview,
  KnowledgeRevise,
  KnowledgeRetire,
  KnowledgeDashboard,
  KnowledgeDetails,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { strings } from "../../theme";
import { AppNotificationsType, AxiosFailure } from "../../types";
import { addAppNotification } from "../application/actions";

import { NoData } from "./../../presentationals/components/DashboardTable/styles";
import { contains } from "./../../services/methods";
import {
  fetchKnowledgeSuccess,
  fetchKnowledgeArticlesSuccess,
  fetchKnowledgeDashboardSuccess,
} from "./actions";
import Types from "./actionTypes";
import {
  ArticlesListWithMetaData,
  FetchKnowledgeSuccess,
  FetchKnowledgeArticles,
  KnowledgeArticlesList,
  FetchKnowledgeDashboardPayload,
  FetchKnowledgeDashboardSuccessResponse,
  FetchKnowledgeDetails,
  FetchKnowledgeDetailsSuccessResponse,
} from "./types";
import { KnowledgeDetailsType } from "./types";

function* createKnowledgeWorker(action) {
  try {
    const options = {
      method: "POST",
      url: KnowledgeAdd,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getAllKnowledgeWorker(action) {
  try {
    const options = {
      method: "GET",
      url: KnowledgeFetch,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      const allKnowledges = response.data.data;
      let allKnowledgebases = [];
      let allCategories = [];
      let allTags = [];
      allKnowledges?.map((item) => {
        if (
          item?.knowledgebase &&
          !allKnowledgebases.includes(JSON.stringify(item.knowledgebase))
        ) {
          allKnowledgebases.push(JSON.stringify(item?.knowledgebase));
        }
        if (item.category && !allCategories.includes(JSON.stringify(item?.category))) {
          allCategories.push(JSON.stringify(item?.category));
        }
        item.modules?.map((tag) => {
          if (!allTags.includes(JSON.stringify(tag))) {
            allTags.push(JSON.stringify(tag));
          }
        });
      });
      allKnowledgebases = allKnowledgebases.map((item) => JSON.parse(item));
      allCategories = allCategories.map((item) => JSON.parse(item));
      allTags = allTags.map((item) => JSON.parse(item));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
      yield put(
        fetchKnowledgeSuccess(response.data.data, allKnowledgebases, allCategories, allTags),
      );
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* updateKnowledgeWorker(action) {
  try {
    console.log(action);
    const options = {
      method: "PUT",
      url: KnowledgeUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    console.log(e);
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* reviseKnowledgeWorker(action) {
  try {
    const options = {
      method: "POST",
      url: KnowledgeRevise(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* retireKnowledgeWorker(action) {
  try {
    const options = {
      method: "DELETE",
      url: KnowledgeRetire(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* updateReviewWorker(action) {
  try {
    const options = {
      method: "POST",
      url: KnowledgeRequestReview(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateKnowledgeApprovalWorker(action) {
  try {
    console.log("Saga", action);
    const options = {
      method: "POST",
      url: KnowledgeApproval(action.payload.data.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        status: action.payload.data.status,
        comment: action.payload.data.comment,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
    console.log(e);
  }
}

function* fetchKnowledgeArticlesWorker(action: FetchKnowledgeArticles) {
  try {
    const { params } = action.payload;
    const options = {
      method: "GET",
      url: KnowledgeFetch,
      params,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<FetchKnowledgeSuccess> = yield call(axios, options);
    if (response.data.success) {
      const articles: ArticlesListWithMetaData = {
        data: response.data.data,
        type: action.payload.type,
        metaData: response.data.metaData,
        searchQuery: action.payload.params.search || "",
        filters: response.data.filters,
        appliedFilters: response.data.appliedFilters,
        removedFilters: response.data.removedFilters,
      };
      const appliedFilters = response.data.appliedFilters;
      action?.payload?.onSuccess && action.payload.onSuccess(articles);
      yield put(fetchKnowledgeArticlesSuccess({ articles, appliedFilters }));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data.message);
  }
}

function* fetchKnowledgeDashboardWorker(action: FetchKnowledgeDashboardPayload) {
  try {
    const options = {
      method: "GET",
      url: KnowledgeDashboard,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<FetchKnowledgeDashboardSuccessResponse> = yield call(
      axios,
      options,
    );
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
      const knowledgeDashboard = response.data.data;
      console.log("Success ::", knowledgeDashboard);
      yield put(fetchKnowledgeDashboardSuccess({ data: knowledgeDashboard }));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data.message);
  }
}

function* fetchKnowledgeDetailsWorker(action: FetchKnowledgeDetails) {
  try {
    const options = {
      method: "GET",
      url: KnowledgeDetails(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<FetchKnowledgeDetailsSuccessResponse> = yield call(
      axios,
      options,
    );
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.status);
  }
}

export default function* KnowledgeSaga() {
  yield takeLatest(Types.KNOWLEDGE_CREATE, createKnowledgeWorker);
  yield takeEvery(Types.KNOWLEDGE_FETCH, getAllKnowledgeWorker);
  yield takeEvery(Types.UPDATE_KNOWLEDGE_APPROVAL, updateKnowledgeApprovalWorker);
  yield takeEvery(Types.UPDATE_KNOWLEDGE_REVIEW, updateReviewWorker);
  yield takeEvery(Types.KNOWLEDGE_UPDATE, updateKnowledgeWorker);
  yield takeEvery(Types.REVISE_KNOWLEDGE, reviseKnowledgeWorker);
  yield takeEvery(Types.RETIRE_KNOWLEDGE, retireKnowledgeWorker);
  yield takeEvery(Types.FETCH_KNOWLEDGE_ARTICLES, fetchKnowledgeArticlesWorker);
  yield takeEvery(Types.KNOWLEDGE_DASHBOARD_FETCH, fetchKnowledgeDashboardWorker);
  yield takeEvery(Types.FETCH_KNOWLEDGE_DETAILS, fetchKnowledgeDetailsWorker);
}
