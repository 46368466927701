import config from "../config";

const baseUrl = config.url;

export function getToken(state: any) {
  return `${state.UserReducer.sessionid}:${state.UserReducer.token}`;
}
export function getOrganisation(state: any) {
  return `${state.UserReducer.organisation._id}`;
}
//   end points begin
export const UserProfile = () => {
  return `${baseUrl}/login`;
};
export const UserProfileUpdate = () => {
  return `${baseUrl}/profile`;
};
export const UserLogin = () => {
  return `${baseUrl}/login`;
};
export const UserLoginSSO = () => {
  return `${baseUrl}/login/google`;
};
export const UserResetPassword = () => {
  return `${baseUrl}/resetpassword`;
};
export const UserLogout = () => {
  return `${baseUrl}/logout`;
};

// Users
export const UsersList = `${baseUrl}/users/list`;

export const UsersUpdateMany = `${baseUrl}/users-update`;

export const UsersAdd = () => {
  return `${baseUrl}/users`;
};
export const GetUserById = (id: string) => {
  return `${baseUrl}/users/${id}`;
};
export const UsersUpdate = (id: string) => {
  return `${baseUrl}/users/${id}`;
};

export const UsersAddChecklist = `${baseUrl}/users/checklist`;

export const UsersUpdateChecklist = (id: string) => {
  return `${baseUrl}/users/checklist/${id}`;
};

// Employees
export const EmployeesGet = (id: string) => {
  return `${baseUrl}/employees/${id}`;
};
export const EmployeesUpdate = (id: string) => {
  return `${baseUrl}/employees/${id}`;
};
export const EmployeesAdd = () => {
  return `${baseUrl}/employees`;
};

export const EmployeeById = (id: string) => {
  return `${baseUrl}/employee/${id}`;
};
export const EmployeesListBasic = () => {
  return `${baseUrl}/employees/basic/get`;
};
export const EmployeesList = `${baseUrl}/employees`;
export const EmployeesOpenList = `${baseUrl}/employees?employee=true`;
// employees comments
export const EmployeesGetComments = (employeeId) => {
  return `${baseUrl}/employees/comments/${employeeId}`;
};
export const EmployeesAddComments = () => {
  return `${baseUrl}/employees/comments`;
};
export const EmployeesDeleteComments = (id: string) => {
  return `${baseUrl}/employees/comments/${id}`;
};
// Designations
export const EmployeesAddDesignations = () => {
  return `${baseUrl}/employees/designations`;
};
export const EmployeesUpdateDesignations = (id: string) => {
  return `${baseUrl}/employees/designations/${id}`;
};

// Payrolls
export const EmployeesFetchPayrolls = () => {
  return `${baseUrl}/payrolls`;
};
export const EmployeesFetchPayrollsSelf = () => {
  return `${baseUrl}/payrolls/self`;
};
export const EmployeesAddPayrolls = () => {
  return `${baseUrl}/payrolls`;
};
export const EmployeesUpdatePayrolls = (id: string) => {
  return `${baseUrl}/payrolls/${id}`;
};
export const EmployeesUpdatePayrollsSelf = (id: string) => {
  return `${baseUrl}/payrolls/${id}/self`;
};

// Recruits
export const RecruitsList = () => {
  return `${baseUrl}/recruits`;
};
export const RecruitsAdd = () => {
  return `${baseUrl}/recruits`;
};
export const RecruitsEdit = (id: string) => {
  return `${baseUrl}/recruits/${id}`;
};
export const RecruitsDelete = (id: string) => {
  return `${baseUrl}/recruits/${id}`;
};

export const RecruitsRollback = (id: string) => {
  return `${baseUrl}/recruits/${id}/assignments`;
};
// assinments
export const RecruitsAssignemntAdd = (recruitId: string) => {
  return `${baseUrl}/recruits/${recruitId}/assignments`;
};

// Storage
export const StorageUpload = (isPublic) => {
  return `${baseUrl}/storage?acl=${isPublic}`;
};
export const StorageGet = (id) => {
  return `${baseUrl}/storage/${id}`;
};
// Tickets
export const TicketsGetAll = () => {
  return `${baseUrl}/tickets`;
};
export const TicketCreate = () => {
  return `${baseUrl}/tickets`;
};
export const TicketUpdate = (id: string) => {
  return `${baseUrl}/tickets/${id}`;
};
export const TicketComment = (id: string) => {
  return `${baseUrl}/tickets/${id}/comment`;
};
// Jobs
export const JobsGetAll = () => {
  return `${baseUrl}/jobs`;
};
export const JobsCreate = () => {
  return `${baseUrl}/jobs`;
};
export const JobsUpdate = (id: string) => {
  return `${baseUrl}/jobs/${id}`;
};
// Organisations
export const OrganisationsFetch = () => {
  return `${baseUrl}/organisations`;
};

export const OrganisationsAdd = () => {
  return `${baseUrl}/organisations`;
};

export const OrganisationsUpdate = (id: any) => {
  return `${baseUrl}/organisations/${id}`;
};
export const OGOrganisationsFetch = () => {
  return `${baseUrl}/og/organisations`;
};

export const OGOrganisationsUpdate = (id: any) => {
  return `${baseUrl}/og/organisations/${id}`;
};

//MediaInsights
export const MediaGetAll = `${baseUrl}/mediainsights`;
export const MediaCreate = `${baseUrl}/mediainsights`;
export const MediaUpdate = (id: string) => `${baseUrl}/mediainsights/${id}`;

// Email Templates
export const EmailTemplateFetch = `${baseUrl}/emailtemplates`;
export const EmailTemplateUpdate = `${baseUrl}/emailtemplates`;

// Categories
export const CategoriesList = `${baseUrl}/categories`;
export const AddCategories = `${baseUrl}/categories`;
export const UpdateCategories = (id: string) => `${baseUrl}/categories/${id}`;
export const FindCategories = (id: string) => `${baseUrl}/categories/${id}`;
export const DeleteCategories = (id: string) => `${baseUrl}/categories/${id}`;

// SubCategories
export const SubCategoriesFetch = `${baseUrl}/subcategories`;
export const SubCategoriesAdd = `${baseUrl}/subcategories`;
export const SubCategoriesUpdate = (id: string) => `${baseUrl}/subcategories/${id}`;

// Modules
export const ModulesFetch = `${baseUrl}/modules`;
export const ModulesAdd = `${baseUrl}/modules`;
export const ModulesUpdate = (id: string) => `${baseUrl}/modules/${id}`;

// Groups
export const GroupsFetch = `${baseUrl}/groups`;
export const GroupsFetchEmployee = `${baseUrl}/groups/?employee=true`;
export const GroupsAdd = `${baseUrl}/groups`;
export const GroupsUpdate = (id: string) => `${baseUrl}/groups/${id}`;

// Knowledgebase
export const KnowledgebaseFetch = `${baseUrl}/knowledgebase`;
export const KnowledgebaseFetchEmployee = `${baseUrl}/knowledgebase?employee=true`;
export const KnowledgebaseAdd = `${baseUrl}/knowledgebase`;
export const KnowledgebaseUpdate = (id: string) => `${baseUrl}/knowledgebase/${id}`;

export const KnowledgeApproval = (id: string) => `${baseUrl}/knowledges/${id}/approval`;
export const KnowledgeRequestReview = (id: string) => `${baseUrl}/knowledges/${id}/requestreview`;
export const KnowledgeRevise = (id: string) => `${baseUrl}/knowledges/${id}/revise`;
export const KnowledgeRetire = (id: string) => `${baseUrl}/knowledges/${id}`;

// // SPS
// export const SPSAccountsFetch = `${baseUrl}/sps/accounts`;
// export const SPSAccountsFetchEmployee = `${baseUrl}/sps/accounts/employees/list`;
// export const SPSAccountsAdd = `${baseUrl}/sps/accounts`;
// export const SPSAccountsUpdate = (id) => `${baseUrl}/sps/accounts/${id}/edit`;
// export const SPSAccountsWorklog = (id) => `${baseUrl}/sps/accounts/${id}/worklogs`;

// export const SPSAccountContactFetch = `${baseUrl}/sps/accountcontact`;
// export const SPSAccountContactAdd = `${baseUrl}/sps/accountcontact`;
// export const SPSAccountContactUpdate = (id) => `${baseUrl}/sps/accountcontact/${id}`;
// export const SPSAccountContactWorklog = (id) => `${baseUrl}/sps/accountcontact/${id}/worklogs`;

// export const SPSSalesProfileFetch = `${baseUrl}/sps/salesprofile`;
// export const SPSSalesProfileAdd = `${baseUrl}/sps/salesprofile`;
// export const SPSSalesProfileUpdate = (id) => `${baseUrl}/sps/salesprofile/${id}/edit`;
// export const SPSSalesProfileWorklog = (id) => `${baseUrl}/sps/salesprofile/${id}/worklogs`;

// export const SPSDealsFetch = `${baseUrl}/sps/deals`;
// export const SPSDealsAdd = `${baseUrl}/sps/deals`;
// export const SPSDealsUpdate = (id) => `${baseUrl}/sps/deals/${id}/edit`;
// export const SPSDealsWorklog = (id) => `${baseUrl}/sps/deals/${id}/worklogs`;

// export const SPSBGCheckFetch = `${baseUrl}/sps/bgcheck`;
// export const SPSBGCheckAdd = `${baseUrl}/sps/bgcheck`;
// export const SPSBGCheckUpdate = (id) => `${baseUrl}/sps/bgcheck/${id}/edit`;
// export const SPSBGCheckWorklog = (id) => `${baseUrl}/sps/bgcheck/${id}/worklogs`;

// export const SPSPaperworkActivitiesFetch = `${baseUrl}/sps/bgcheck`;
// export const SPSPaperworkActivitiesAdd = `${baseUrl}/sps/bgcheck`;
// export const SPSPaperworkActivitiesUpdate = (id) => `${baseUrl}/sps/bgcheck/${id}/edit`;
// export const SPSPaperworkActivitiesWorklog = (id) => `${baseUrl}/sps/bgcheck/${id}/worklogs`;

// export const SPSProjectsFetch = `${baseUrl}/sps/projects`;
// export const SPSProjectsAdd = `${baseUrl}/sps/projects`;
// export const SPSProjectsUpdate = (id) => `${baseUrl}/sps/projects/${id}/edit`;
// export const SPSProjectsWorklog = (id) => `${baseUrl}/sps/projects/${id}/worklogs`;

export const Birthdays = `${baseUrl}/birthdays`;

// Filter
export const GetFilters = `${baseUrl}/table/filter`;
export const CreateFilter = `${baseUrl}/table/filter`;
export const UpdateFilter = (id: string) => `${baseUrl}/table/filter/${id}`;

// Dashboards
export const DashboardsList = `${baseUrl}/dashboards`;

// Knowledge
export const KnowledgeFetch = `${baseUrl}/knowledges`;
export const KnowledgeAdd = `${baseUrl}/knowledges`;
export const KnowledgeUpdate = (id: string) => {
  return `${baseUrl}/knowledges/${id}`;
};
export const KnowledgeDashboard = `${baseUrl}/dashboard/knowledges`;
export const KnowledgeDetails = (id: string) => `${baseUrl}/knowledges/${id}`;

// Configurations
export const ControllerConfigurations = `${baseUrl}/configurations/controller`;

// Attributes
export const AttributesList = `${baseUrl}/attributes`;
export const AddAttributes = `${baseUrl}/attribute`;
export const UpdateAttributes = (id: string) => `${baseUrl}/attribute/${id}`;
export const FindAttributes = (id: string) => `${baseUrl}/attribute/${id}`;

// General
export const GeneralAttributesFetch = () => {
  return `${baseUrl}/general/attributes`;
};

// Departments
export const DepartmentsList = `${baseUrl}/department`;
export const AddDepartment = `${baseUrl}/department`;
export const FindDepartmentById = (id: string) => `${baseUrl}/department/${id}`;
export const UpdateDepartment = (id: string) => `${baseUrl}/department/${id}`;
export const DeleteDepartment = (id: string) => `${baseUrl}/department/${id}`;

// Designations
export const DesignationsList = `${baseUrl}/designation`;
export const AddDesignation = `${baseUrl}/designation`;
export const FindDesignationById = (id: string) => `${baseUrl}/designation/${id}`;
export const UpdateDesignation = (id: string) => `${baseUrl}/designation/${id}`;
export const DeleteDesignation = (id: string) => `${baseUrl}/designation/${id}`;

// Country
export const CountryList = `${baseUrl}/country`;
export const AddCountry = `${baseUrl}/country`;
export const FindCountry = (id: string) => `${baseUrl}/country/${id}`;
export const UpdateCountry = (id: string) => `${baseUrl}/country/${id}`;
export const DeleteCountry = (id: string) => `${baseUrl}/country/${id}`;

// Currency
export const CurrencyList = `${baseUrl}/currency`;
export const AddCurrency = `${baseUrl}/currency`;
export const FindCurrency = (id: string) => `${baseUrl}/currency/${id}`;
export const UpdateCurrency = (id: string) => `${baseUrl}/currency/${id}`;
export const DeleteCurrency = (id: string) => `${baseUrl}/currency/${id}`;

// Company Contact
export const CompanyContactList = `${baseUrl}/company-contact`;
export const AddCompanyContact = `${baseUrl}/company-contact`;
export const UpdateCompanyContact = (id: string) => `${baseUrl}/company-contact/${id}`;
export const FindCompanyContact = (id: string) => `${baseUrl}/company-contact/${id}`;
export const DeleteCompanyContact = (id: string) => `${baseUrl}/company-contact/${id}`;

// Company
export const CompanyList = `${baseUrl}/company`;
export const AddCompany = `${baseUrl}/company`;
export const UpdateCompany = (id: string) => `${baseUrl}/company/${id}`;
export const FindCompany = (id: string) => `${baseUrl}/company/${id}`;
export const DeleteCompany = (id: string) => `${baseUrl}/company/${id}`;

// Employee

// Form 16
export const Form16List = `${baseUrl}/form-16`;
export const AddForm16 = `${baseUrl}/form-16`;
export const DeleteForm16 = (id: string) => `${baseUrl}/form-16/${id}`;
export const UpdateForm16 = (id: string) => `${baseUrl}/form-16/${id}`;
export const FindForm16 = (id: string) => `${baseUrl}/form-16/${id}`;

// Benefits
export const BenefitsList = `${baseUrl}/benefits`;
export const AddBenefits = `${baseUrl}/benefits`;
export const DeleteBenefits = (id: string) => `${baseUrl}/benefits/${id}`;
export const UpdateBenefits = (id: string) => `${baseUrl}/benefits/${id}`;
export const FindBenefits = (id: string) => `${baseUrl}/benefits/${id}`;

// PII Details
export const PIIDetailsList = `${baseUrl}/pii-details`;
export const AddPIIDetails = `${baseUrl}/pii-details`;
export const DeletePIIDetails = (id: string) => `${baseUrl}/pii-details/${id}`;
export const UpdatePIIDetails = (id: string) => `${baseUrl}/pii-details/${id}`;
export const FindPIIDetails = (id: string) => `${baseUrl}/pii-details/${id}`;

// Bank Details
export const BankDetailsList = `${baseUrl}/bank-details`;
export const AddBankDetails = `${baseUrl}/bank-details`;
export const DeleteBankDetails = (id: string) => `${baseUrl}/bank-details/${id}`;
export const UpdateBankDetails = (id: string) => `${baseUrl}/bank-details/${id}`;
export const FindBankDetails = (id: string) => `${baseUrl}/bank-details/${id}`;

// Certifications & Accreditations
export const CertificationsAccreditationsList = `${baseUrl}/certifications-accreditations`;
export const AddCertificationsAccreditations = `${baseUrl}/certifications-accreditations`;
export const UpdateCertificationsAccreditations = (id: string) =>
  `${baseUrl}/certifications-accreditations/${id}`;
export const FindCertificationsAccreditations = (id: string) =>
  `${baseUrl}/certifications-accreditations/${id}`;
export const DeleteCertificationsAccreditations = (id: string) =>
  `${baseUrl}/certifications-accreditations/${id}`;

// Education Details
export const EducationDetailsList = `${baseUrl}/education-details`;
export const AddEducationDetails = `${baseUrl}/education-details`;
export const DeleteEducationDetails = (id: string) => `${baseUrl}/education-details/${id}`;
export const UpdateEducationDetails = (id: string) => `${baseUrl}/education-details/${id}`;
export const FindEducationDetails = (id: string) => `${baseUrl}/education-details/${id}`;

// Employment History
export const EmploymentHistoryList = `${baseUrl}/employment-history`;
export const AddEmploymentHistory = `${baseUrl}/employment-history`;
export const DeleteEmploymentHistory = (id: string) => `${baseUrl}/employment-history/${id}`;
export const UpdateEmploymentHistory = (id: string) => `${baseUrl}/employment-history/${id}`;
export const FindEmploymentHistory = (id: string) => `${baseUrl}/employment-history/${id}`;

// Project History
export const ProjectHistoryList = `${baseUrl}/project-history`;
export const AddProjectHistory = `${baseUrl}/project-history`;
export const UpdateProjectHistory = (id: string) => `${baseUrl}/project-history/${id}`;
export const FindProjectHistory = (id: string) => `${baseUrl}/project-history/${id}`;
export const DeleteProjectHistory = (id: string) => `${baseUrl}/project-history/${id}`;

// Address History
export const AddressHistoryList = `${baseUrl}/address-history`;
export const AddAddressHistory = `${baseUrl}/address-history`;
export const DeleteAddressHistory = (id: string) => `${baseUrl}/address-history/${id}`;
export const UpdateAddressHistory = (id: string) => `${baseUrl}/address-history/${id}`;
export const FindAddressHistory = (id: string) => `${baseUrl}/address-history/${id}`;

// Platform Modules
export const PlatformModulesList = `${baseUrl}/platform-modules`;
export const AddPlatformModules = `${baseUrl}/platform-modules`;
export const UpdatePlatformModules = (id: string) => `${baseUrl}/platform-modules/${id}`;
export const FindPlatformModules = (id: string) => `${baseUrl}/platform-modules/${id}`;
export const DeletePlatformModules = (id: string) => `${baseUrl}/platform-modules/${id}`;

// Task
export const TaskList = `${baseUrl}/task/`;
export const AddTask = `${baseUrl}/task`;
export const UpdateTask = (id: string) => `${baseUrl}/task/${id}`;
export const FindTask = (id: string) => `${baseUrl}/task-details/${id}`;
export const DeleteTask = (id: string) => `${baseUrl}/task-details/${id}`;

// Drive
export const DriveList = `${baseUrl}/drive`;
export const DriveGenerateURL = `${baseUrl}/drive/upload`;
export const DriveOperations = `${baseUrl}/drive/operations`;

// Employee Profile
export const EmployeesAddAppraisals = () => {
  return `${baseUrl}/employees/appraisals`;
};
export const EmployeesUpdateAppraisals = (id: string) => {
  return `${baseUrl}/employees/appraisals/${id}`;
};
export const EmployeesDeleteAppraisals = (id: string) => `${baseUrl}/employees/appraisals/${id}`;
export const EmployeesAddEmergencyContact = () => {
  return `${baseUrl}/employees/emergency-contact`;
};
export const EmployeesUpdateEmergencyContact = (id: string) => {
  return `${baseUrl}/employees/emergency-contact/${id}`;
};

// Academy Courses
export const AcademyCoursesList = `${baseUrl}/academy-courses`;
export const UpdateAcademyCourses = (id: string) => `${baseUrl}/academy-courses/${id}`;
export const DeleteAcademyCourses = (id: string) => `${baseUrl}/academy-courses/${id}`;
export const AddAcademyCourses = `${baseUrl}/academy-courses`;
export const FindAcademyCourseById = (id: string) => `${baseUrl}/academy-courses/${id}`;

// Academy Candidates
export const AcademyCandidatesList = `${baseUrl}/academy-candidates`;
export const UpdateAcademyCandidates = (id: string) => `${baseUrl}/academy-candidates/${id}`;
export const DeleteAcademyCandidates = (id: string) => `${baseUrl}/academy-candidates/${id}`;
export const AddAcademyCandidates = `${baseUrl}/academy-candidates`;
export const FindAcademyCandidateById = (id: string) => `${baseUrl}/academy-candidates/${id}`;

// Academy Candidate Leads
export const AcademyCandidateLeadsList = `${baseUrl}/academy-candidate-leads`;
export const UpdateAcademyCandidateLead = (id: string) =>
  `${baseUrl}/academy-candidate-leads/${id}`;
export const DeleteAcademyCandidateLead = (id: string) =>
  `${baseUrl}/academy-candidate-leads/${id}`;
export const AddAcademyCandidateLead = `${baseUrl}/academy-candidate-leads`;
export const FindAcademyCandidateLeadById = (id: string) =>
  `${baseUrl}/academy-candidate-leads/${id}`;

// Academy Student Profile
export const AcademyStudentProfileList = `${baseUrl}/academy-student-profile`;
export const UpdateAcademyStudentProfile = (id: string) =>
  `${baseUrl}/academy-student-profile/${id}`;
export const DeleteAcademyStudentProfile = (id: string) =>
  `${baseUrl}/academy-student-profile/${id}`;
export const AddAcademyStudentProfile = `${baseUrl}/academy-student-profile`;
export const FindAcademyStudentProfileById = (id: string) =>
  `${baseUrl}/academy-student-profile/${id}`;

// Academy Mentor Feedback
export const AcademyMentorFeedbackList = `${baseUrl}/academy-mentor-feedback`;
export const UpdateAcademyMentorFeedback = (id: string) =>
  `${baseUrl}/academy-mentor-feedback/${id}`;
export const DeleteAcademyMentorFeedback = (id: string) =>
  `${baseUrl}/academy-mentor-feedback/${id}`;
export const AddAcademyMentorFeedback = `${baseUrl}/academy-mentor-feedback`;
export const FindAcademyMentorFeedbackById = (id: string) =>
  `${baseUrl}/academy-mentor-feedback/${id}`;

// Academy Batch
export const AcademyBatchList = `${baseUrl}/academy-batch`;
export const UpdateAcademyBatch = (id: string) => `${baseUrl}/academy-batch/${id}`;
export const DeleteAcademyBatch = (id: string) => `${baseUrl}/academy-batch/${id}`;
export const AddAcademyBatch = `${baseUrl}/academy-batch`;
export const FindAcademyBatchById = (id: string) => `${baseUrl}/academy-batch/${id}`;

// Academy Candidate Enrollments
export const AcademyCandidateEnrollmentsList = `${baseUrl}/academy-candidate-enrollments`;
export const UpdateAcademyCandidateEnrollments = (id: string) =>
  `${baseUrl}/academy-candidate-enrollments/${id}`;
export const DeleteAcademyCandidateEnrollments = (id: string) =>
  `${baseUrl}/academy-candidate-enrollments/${id}`;
export const AddAcademyCandidateEnrollments = `${baseUrl}/academy-candidate-enrollments`;
export const FindAcademyCandidateEnrollmentsById = (id: string) =>
  `${baseUrl}/academy-candidate-enrollments/${id}`;
export const AddAcademyCandidateEnrollmentPayment = `${baseUrl}/academy-candidate-enrollments/payment-details`;
export const UpdateAcademyCandidateEnrollmentPayment = (id: string) =>
  `${baseUrl}/academy-candidate-enrollments/payment-details/${id}`;

// Academy ACH
export const AcademyACHList = `${baseUrl}/academy-ach`;
export const UpdateAcademyACH = (id: string) => `${baseUrl}/academy-ach/${id}`;
export const DeleteAcademyACH = (id: string) => `${baseUrl}/academy-ach/${id}`;
export const AddAcademyACH = `${baseUrl}/academy-ach`;
export const FindAcademyACHById = (id: string) => `${baseUrl}/academy-ach/${id}`;

// Checklsist Template
export const ChecklistTemplateList = `${baseUrl}/checklist-template`;
export const AddChecklistTemplate = `${baseUrl}/checklist-template`;
export const UpdateChecklistTemplate = (id: string) => `${baseUrl}/checklist-template/${id}`;
export const FindChecklistTemplate = (id: string) => `${baseUrl}/checklist-template/${id}`;
export const DeleteChecklistTemplate = (id: string) => `${baseUrl}/checklist-template/${id}`;

// Announcement
export const AnnouncementsList = `${baseUrl}/announcements`;
export const AddAnnouncements = `${baseUrl}/announcements`;
export const DeleteAnnouncements = (id: string) => `${baseUrl}/announcements/${id}`;
export const UpdateAnnouncements = (id: string) => `${baseUrl}/announcements/${id}`;
export const FindAnnouncementsById = (id: string) => `${baseUrl}/announcements/${id}`;

// Activity Monitor
export const ActivityLogsList = `${baseUrl}/activity-logs`;

// SPS Paperwork
export const SPSPaperworkList = `${baseUrl}/sps-paperwork`;
export const AddSPSPaperwork = `${baseUrl}/sps-paperwork`;
export const UpdateSPSPaperwork = (id: string) => `${baseUrl}/sps-paperwork/${id}`;
export const DeleteSPSPaperwork = (id: string) => `${baseUrl}/sps-paperwork/${id}`;
export const FindSPSPaperwork = (id: string) => `${baseUrl}/sps-paperwork/${id}`;

// SPS NewsletterResources
export const NewsletterResourcesList = `${baseUrl}/newsletter-resources`;
export const AddNewsletterResources = `${baseUrl}/newsletter-resources`;
export const UpdateNewsletterResources = (id: string) => `${baseUrl}/newsletter-resources/${id}`;
export const DeleteNewsletterResources = (id: string) => `${baseUrl}/newsletter-resources/${id}`;
export const FindNewsletterResources = (id: string) => `${baseUrl}/newsletter-resources/${id}`;

// Newsletterleads
export const NewsletterLeadsList = `${baseUrl}/newsletter-leads`;
export const AddNewsletterLeads = `${baseUrl}/newsletter-leads`;
export const UpdateNewsletterLeads = (id: string) => `${baseUrl}/newsletter-leads/${id}`;
export const DeleteNewsletterLeads = (id: string) => `${baseUrl}/newsletter-leads/${id}`;
export const FindNewsletterLeads = (id: string) => `${baseUrl}/newsletter-leads/${id}`;

// Campaigns
export const CampaignsList = `${baseUrl}/campaigns`;
export const AddCampaigns = `${baseUrl}/campaigns`;
export const UpdateCampaigns = (id: string) => `${baseUrl}/campaigns/${id}`;
export const DeleteCampaigns = (id: string) => `${baseUrl}/campaigns/${id}`;
export const FindCampaigns = (id: string) => `${baseUrl}/campaigns/${id}`;

// CampaignsAssets
export const CampaignsAssetsList = `${baseUrl}/campaigns-assets`;
export const AddCampaignsAssets = `${baseUrl}/campaigns-assets`;
export const UpdateCampaignsAssets = (id: string) => `${baseUrl}/campaigns-assets/${id}`;
export const DeleteCampaignsAssets = (id: string) => `${baseUrl}/campaigns-assets/${id}`;
export const FindCampaignsAssets = (id: string) => `${baseUrl}/campaigns-assets/${id}`;

// LeadLists
export const LeadListsList = `${baseUrl}/lead-lists`;
export const AddLeadLists = `${baseUrl}/lead-lists`;
export const UpdateLeadLists = (id: string) => `${baseUrl}/lead-lists/${id}`;
export const DeleteLeadLists = (id: string) => `${baseUrl}/lead-lists/${id}`;
export const FindLeadLists = (id: string) => `${baseUrl}/lead-lists/${id}`;

// SPS Paperwork
export const SubscriberList = `${baseUrl}/subscribers`;
export const AddSubscriber = `${baseUrl}/subscribers`;
export const UpdateSubscriber = (id: string) => `${baseUrl}/subscribers/${id}`;
export const DeleteSubscriber = (id: string) => `${baseUrl}/subscribers/${id}`;
export const FindSubscriber = (id: string) => `${baseUrl}/subscribers/${id}`;

// SPS Insurance
export const SPSInsuranceList = `${baseUrl}/sps-insurance`;
export const AddSPSInsurance = `${baseUrl}/sps-insurance`;
export const UpdateSPSInsurance = (id: string) => `${baseUrl}/sps-insurance/${id}`;
export const DeleteSPSInsurance = (id: string) => `${baseUrl}/sps-insurance/${id}`;
export const FindSPSInsurance = (id: string) => `${baseUrl}/sps-insurance/${id}`;

// SPS Projects
export const SPSProjectList = `${baseUrl}/sps-projects`;
export const AddSPSProject = `${baseUrl}/sps-projects`;
export const UpdateSPSProject = (id: string) => `${baseUrl}/sps-projects/${id}`;
export const DeleteSPSProject = (id: string) => `${baseUrl}/sps-projects/${id}`;
export const FindSPSProject = (id: string) => `${baseUrl}/sps-projects/${id}`;

// SPS Accounts
export const SPSAccountsList = `${baseUrl}/sps-accounts`;
export const AddSPSAccounts = `${baseUrl}/sps-accounts`;
export const DeleteSPSAccounts = (id: string) => `${baseUrl}/sps-accounts/${id}`;
export const UpdateSPSAccounts = (id: string) => `${baseUrl}/sps-accounts/${id}`;
export const FindSPSAccounts = (id: string) => `${baseUrl}/sps-accounts/${id}`;

// SPS Account Contact
export const SPSAccountContactList = `${baseUrl}/sps-accountcontact`;
export const AddSPSAccountContact = `${baseUrl}/sps-accountcontact`;
export const UpdateSPSAccountContact = (id: string) => `${baseUrl}/sps-accountcontact/${id}`;
export const DeletePSAccountContact = (id: string) => `${baseUrl}/sps-accountcontact/${id}`;
export const FindSPSAccountContact = (id: string) => `${baseUrl}/sps-accountcontact/${id}`;

// Service Now Solutions
export const ServiceNowSolutionsList = `${baseUrl}/servicenow-solutions`;
export const AddServiceNowSolutions = `${baseUrl}/servicenow-solutions`;
export const UpdateServiceNowSolutions = (id: string) => `${baseUrl}/servicenow-solutions/${id}`;
export const DeleteServiceNowSolutions = (id: string) => `${baseUrl}/servicenow-solutions/${id}`;
export const FindServiceNowSolutions = (id: string) => `${baseUrl}/servicenow-solutions/${id}`;

// SPS SalesProfile
export const SPSSalesProfileList = `${baseUrl}/sps-salesprofile`;
export const AddSPSSalesProfile = `${baseUrl}/sps-salesprofile`;
export const UpdateSPSSalesProfile = (id: string) => `${baseUrl}/sps-salesprofile/${id}`;
export const DeleteSPSSalesProfile = (id: string) => `${baseUrl}/sps-salesprofile/${id}`;
export const FindSPSSalesProfile = (id: string) => `${baseUrl}/sps-salesprofile/${id}`;

// SPS BG Check
export const SPSBGCheckList = `${baseUrl}/sps-bgcheck`;
export const AddSPSBGCheck = `${baseUrl}/sps-bgcheck`;
export const UpdateSPSBGCheck = (id: string) => `${baseUrl}/sps-bgcheck/${id}`;
export const DeleteSPSBGCheck = (id: string) => `${baseUrl}/sps-bgcheck/${id}`;
export const FindSPSBGCheck = (id: string) => `${baseUrl}/sps-bgcheck/${id}`;

// SPS Deals
export const SPSDealsList = `${baseUrl}/sps-deals`;
export const AddSPSDeals = `${baseUrl}/sps-deals`;
export const UpdateSPSDeals = (id: string) => `${baseUrl}/sps-deals/${id}`;
export const DeleteSPSDeals = (id: string) => `${baseUrl}/sps-deals/${id}`;
export const FindSPSDeals = (id: string) => `${baseUrl}/sps-deals/${id}`;

// Dashboard Administration
export const DashboardAdministrationList = `${baseUrl}/dashboard-administration`;
export const FindDashboardAdministrationById = (id: string) =>
  `${baseUrl}/dashboard-administration/${id}`;
export const AddDashboardAdministration = `${baseUrl}/dashboard-administration`;
export const UpdateDashboardAdministration = (id: string) =>
  `${baseUrl}/dashboard-administration/${id}`;

// Dashboard Tab
export const DashboardTabsList = `${baseUrl}/dashboard-tab`;
export const FindDashboardTabById = (id: string) => `${baseUrl}/dashboard-tab/${id}`;
export const AddDashboardTab = `${baseUrl}/dashboard-tab`;
export const UpdateDashboardTab = (id: string) => `${baseUrl}/dashboard-tab/${id}`;

// Dashboard Widget
export const DashboardWidgetsList = `${baseUrl}/dashboard-widget`;
export const FindDashboardWidgetById = (id: string) => `${baseUrl}/dashboard-widget/${id}`;
export const AddDashboardWidget = `${baseUrl}/dashboard-widget`;
export const UpdateDashboardWidget = (id: string) => `${baseUrl}/dashboard-widget/${id}`;
export const DashboardWidgetData = (id: string) => `${baseUrl}/dashboard-widget-data/${id}`;

// Bug Reporting
export const BugReportingList = `${baseUrl}/bug-reporting`;
export const FindBugReporting = (id: string) => `${baseUrl}/bug-reporting/${id}`;
export const AddBugReporting = `${baseUrl}/bug-reporting`;
export const UpdateBugReporting = (id: string) => `${baseUrl}/bug-reporting/${id}`;

// Checklist
export const ChecklistList = `${baseUrl}/checklist`;
export const FindChecklistById = (id: string) => `${baseUrl}/checklist/${id}`;
export const AddChecklist = `${baseUrl}/checklist`;
export const UpdateChecklist = (id: string) => `${baseUrl}/checklist/${id}`;
export const DeleteChecklist = `${baseUrl}/checklist`;

// Tags
export const TagsList = `${baseUrl}/tags`;
export const AddTags = `${baseUrl}/tags`;
export const UpdateTags = (id: string) => `${baseUrl}/tags/${id}`;
export const FindTags = (id: string) => `${baseUrl}/tags/${id}`;
export const DeleteTags = (id: string) => `${baseUrl}/tags/${id}`;

// Sub Categories
export const SubCategoriesList = `${baseUrl}/subcategories`;
export const AddSubCategories = `${baseUrl}/subcategories`;
export const UpdateSubCategories = (id: string) => `${baseUrl}/subcategories/${id}`;
export const FindSubCategories = (id: string) => `${baseUrl}/subcategories/${id}`;
export const DeleteSubCategories = (id: string) => `${baseUrl}/subcategories/${id}`;

// Jobs Posting
export const JobsPostingList = `${baseUrl}/jobs`;
export const AddJobsPosting = `${baseUrl}/jobs`;
export const UpdateJobsPosting = (id: string) => `${baseUrl}/jobs/${id}`;
export const FindJobsPosting = (id: string) => `${baseUrl}/jobs/${id}`;
export const DeleteJobsPosting = (id: string) => `${baseUrl}/jobs/${id}`;

// Contact US
export const ContactUsList = `${baseUrl}/itn-contactus`;
export const AddContactUs = `${baseUrl}/itn-contactus`;
export const UpdateContactUs = (id: string) => `${baseUrl}/itn-contactus/${id}`;
export const FindContactUs = (id: string) => `${baseUrl}/itn-contactus/${id}`;
export const DeleteContactUs = (id: string) => `${baseUrl}/itn-contactus/${id}`;

// Variable Sets
export const VariableSetsList = `${baseUrl}/variable-sets`;
export const AddVariableSets = `${baseUrl}/variable-sets`;
export const UpdateVariableSets = (id: string) => `${baseUrl}/variable-sets/${id}`;
export const FindVariableSets = (id: string) => `${baseUrl}/variable-sets/${id}`;
export const DeleteVariableSets = (id: string) => `${baseUrl}/variable-sets/${id}`;

// Variables
export const VariablesList = `${baseUrl}/variables`;
export const AddVariables = `${baseUrl}/variables`;
export const UpdateVariables = (id: string) => `${baseUrl}/variables/${id}`;
export const FindVariables = (id: string) => `${baseUrl}/variables/${id}`;
export const DeleteVariables = (id: string) => `${baseUrl}/variables/${id}`;

// Email Schedular
export const EmailSchedularList = `${baseUrl}/email-schedular`;
export const AddEmailSchedular = `${baseUrl}/email-schedular`;
export const UpdateEmailSchedular = (id: string) => `${baseUrl}/email-schedular/${id}`;
export const FindEmailSchedular = (id: string) => `${baseUrl}/email-schedular/${id}`;
export const DeleteEmailSchedular = (id: string) => `${baseUrl}/email-schedular/${id}`;

// Resources
export const ResourcesList = `${baseUrl}/resources`;
export const AddResources = `${baseUrl}/resources`;
export const UpdateResources = (id: string) => `${baseUrl}/resources/${id}`;
export const FindResources = (id: string) => `${baseUrl}/resources/${id}`;
export const DeleteResources = (id: string) => `${baseUrl}/resources/${id}`;

// ITN Services
export const ITNServicesList = `${baseUrl}/itn-services`;
export const AddITNServices = `${baseUrl}/itn-services`;
export const UpdateITNServices = (id: string) => `${baseUrl}/itn-services/${id}`;
export const FindITNServices = (id: string) => `${baseUrl}/itn-services/${id}`;
export const DeleteITNServices = (id: string) => `${baseUrl}/itn-services/${id}`;

// ITN Services
export const ITNRFPList = `${baseUrl}/itn-rfp`;
export const AddITNRFP = `${baseUrl}/itn-rfp`;
export const UpdateITNRFP = (id: string) => `${baseUrl}/itn-rfp/${id}`;
export const FindITNRFP = (id: string) => `${baseUrl}/itn-rfp/${id}`;
export const DeleteITNRFP = (id: string) => `${baseUrl}/itn-rfp/${id}`;

// Catalog Administration
export const CatalogAdministrationsList = `${baseUrl}/catalog-administrations`;
export const AddCatalogAdministrations = `${baseUrl}/catalog-administrations`;
export const DeleteCatalogAdministrations = (id: string) =>
  `${baseUrl}/catalog-administrations/${id}`;
export const UpdateCatalogAdministrations = (id: string) =>
  `${baseUrl}/catalog-administrations/${id}`;
export const FindCatalogAdministrations = (id: string) =>
  `${baseUrl}/catalog-administrations/${id}`;

// Kanban Config
export const KanbanConfigList = `${baseUrl}/kanban-config`;
export const FindKanbanConfigById = (id: string) => `${baseUrl}/kanban-config/${id}`;
export const AddKanbanConfig = `${baseUrl}/kanban-config`;
export const UpdateKanbanConfig = (id: string) => `${baseUrl}/kanban-config/${id}`;
export const KanbanConfigData = (id: string) => `${baseUrl}/kanban-config-data/${id}`;

//Workspace
export const WorkspacesList = `${baseUrl}/workspaces`;
export const FindWorkspaceById = (id: string) => `${baseUrl}/workspaces/${id}`;
export const AddWorkspace = `${baseUrl}/workspaces`;
export const UpdateWorkspace = (id: string) => `${baseUrl}/workspaces/${id}`;
export const WorkspaceData = (id: string) => `${baseUrl}/workspaces-data/${id}`;

//Space
export const SpacesList = `${baseUrl}/spaces`;
export const FindSpaceById = (id: string) => `${baseUrl}/spaces/${id}`;
export const AddSpace = `${baseUrl}/spaces`;
export const UpdateSpace = (id: string) => `${baseUrl}/spaces/${id}`;
export const SpaceData = (id: string) => `${baseUrl}/spaces-data/${id}`;

//Notifications Definitions
export const NotificationsDefinitionsList = `${baseUrl}/notifications-definations`;
export const FindNotificationsDefinitionsById = (id: string) =>
  `${baseUrl}/notifications-definations/${id}`;
export const AddNotificationsDefinitions = `${baseUrl}/notifications-definations`;
export const UpdateNotificationsDefinitions = (id: string) =>
  `${baseUrl}/notifications-definations/${id}`;
export const NotificationsDefinitionsData = (id: string) =>
  `${baseUrl}/notifications-definations-data/${id}`;

// Service Requests
export const ServiceRequestsList = `${baseUrl}/service-requests/`;
export const AddServiceRequests = `${baseUrl}/service-requests`;
export const UpdateServiceRequests = (id: string) => `${baseUrl}/service-requests/${id}`;
export const FindServiceRequests = (id: string) => `${baseUrl}/service-requests/${id}`;
export const DeleteServiceRequests = (id: string) => `${baseUrl}/service-requests/${id}`;
