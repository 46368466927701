import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { MomentDate } from "../../../../services/methods";
import { DepartmetssControllerProps } from "../../../../types/models/Departments";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { Row } from "../../../common/styles";
import { ActivityLogs, WorkLogs, TableController } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { DesignationsList } from "../Designations";
import { EmployeesListing } from "../Employees";

import { useDepartmentController } from "./hooks";
import { RelatedListContainer } from "./styles";

export const DepartmentsController = ({ type }: DepartmetssControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const steps = ["Overview"];
  const {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    onPostWorklog,
    getStepBadge,
    data,
    headerButtons,
    fetchDepartments,
    departmentManagerOptions,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
  } = useDepartmentController({ type, id: params.id, attachmentsPickerRef });
  const currentDepartment = data;
  if (type === "edit") {
    steps.push("System Data");
  }

  const { workLogs, attachments, name, active, departmentManager, description } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchDepartments();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Name"}
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"departmentManager"}
          label={"Department Manager"}
          options={departmentManagerOptions}
          value={departmentManager.value}
          onChange={onTextChange}
          mandatory={departmentManager.required}
          error={departmentManager.error}
        />
        <TextInput
          label={"Description"}
          type={"textarea"}
          width='100%'
          error={description.error}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    departmentManager.error,
    departmentManager.required,
    departmentManager.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
    departmentManagerOptions,
    description,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentDepartment?.createdBy !== "string" ? currentDepartment?.createdBy?.name : "";
    const updatedBy =
      typeof currentDepartment?.updatedBy !== "string" ? currentDepartment?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentDepartment?.createdAt}
        updatedAt={currentDepartment?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentDepartment?.createdAt,
    currentDepartment?.createdBy,
    currentDepartment?.updatedAt,
    currentDepartment?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.departments.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.departments.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderRelatedDesignations = useMemo(() => {
    return (
      <RelatedListContainer>
        <DesignationsList
          relatedList={{
            type: "department",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderRelatedEmployees = useMemo(() => {
    return (
      <RelatedListContainer>
        <EmployeesListing
          relatedList={{
            type: "employmentDepartment",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderRelatedDesignations;
      case 6:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
    renderRelatedDesignations,
    renderRelatedEmployees,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
