import React from "react";

import { Layout } from "../../../presentationals";
import { NewsletterLeadsController } from "../../../presentationals/screens/admin/NewsletterLeads/NewsletterLeadsController";

const Route = () => {
  return <Layout activity={<NewsletterLeadsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APNewsletterLeadsAdd = React.memo(Route);
