import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { CompanyContactControllerProps } from "../../../../types/models/CompanyContactForm";
import { StatusSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useCompanyContactController } from "./hooks";

const Controller = ({ type }: CompanyContactControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { companyOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchCompanyContact,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useCompanyContactController({ type, id, attachmentsPickerRef });
  const currentCompanyContact = data;

  const {
    workLogs,
    attachments,
    company,
    firstName,
    lastName,
    role,
    email,
    alternateEmail,
    phone,
    alternatePhone,
    status,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchCompanyContact();
    }
    // Do not include fetchCompanyContact to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <Select
            width={"48%"}
            name={"company"}
            label={"Company"}
            options={companyOptions}
            value={company.value}
            onChange={onTextChange}
            mandatory={company.required}
            error={company.error}
          />
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("companyContactStatus")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"First Name"}
            type={"text"}
            width='32%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
          />
          <TextInput
            label={"Last Name"}
            type={"text"}
            width='32%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
          />
          <TextInput
            label={"Role"}
            type={"text"}
            width='32%'
            error={role.error}
            inputProps={{
              name: "role",
              value: role.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: role.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label={"Email"}
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
          <TextInput
            label={"Alternate Email"}
            type={"text"}
            width='48%'
            error={alternateEmail.error}
            inputProps={{
              name: "alternateEmail",
              value: alternateEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: alternateEmail.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label={"Phone"}
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
          <TextInput
            label={"Alternate Phone"}
            type={"text"}
            width='48%'
            error={alternatePhone.error}
            inputProps={{
              name: "alternatePhone",
              value: alternatePhone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: alternatePhone.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    alternateEmail.error,
    alternateEmail.required,
    alternateEmail.value,
    alternatePhone.error,
    alternatePhone.required,
    alternatePhone.value,
    company.error,
    company.required,
    company.value,
    companyOptions,
    email.error,
    email.required,
    email.value,
    firstName.error,
    firstName.required,
    firstName.value,
    lastName.error,
    lastName.required,
    lastName.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    role.error,
    role.required,
    role.value,
    status.error,
    status.required,
    status.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentCompanyContact?.createdBy !== "string"
        ? currentCompanyContact?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentCompanyContact?.updatedBy !== "string"
        ? currentCompanyContact?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentCompanyContact?.createdAt}
        updatedAt={currentCompanyContact?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentCompanyContact?.createdAt,
    currentCompanyContact?.createdBy,
    currentCompanyContact?.updatedAt,
    currentCompanyContact?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.company.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.companyContact.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const CompanyContactController = memo(Controller);
