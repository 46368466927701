import styled from "styled-components";

import { colors, fs } from "../../../../theme";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const FilterModalContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.65);
  z-index: 3;
`;

export const FilterContainer = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
  width: 28vw;
  padding: 6%;
  box-sizing: border-box;
  z-index: 2;
  border: 0.6px solid rgba(0, 0, 0, 0.75);
`;

export const FilterHeader = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  .table-filter-modal-button {
    border: none;
    padding: 9px 18px;
    font-size: ${fs.m};
    width: 100px;
  }
`;

export const FilterRightHeaderContainer = styled.div`
  display: flex;
  .table-filter-apply-button {
    padding: 9px 18px;
    margin-left: 10px;
  }
  .table-filter-close-button {
    padding: 9px 18px;
    margin-left: 10px;
    background-color: ${colors.common.white};
    color: rgba(0, 0, 0, 0.75);
  }
`;

export const FilterItemsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FilterItem = styled.div`
  display: flex;
  width: 100%;
`;

// export const FilterModalContainer = styled.div`
//   position: absolute;
//   display: flex;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 100vw;
//   background: rgba(0, 0, 0, 0.65);
//   z-index: 3;
// `;

// export const FilterContainer = styled.div`
//   position: absolute;
//   display: flex;
//   top: 11%;
//   right: 1%;
//   height: 80vh;
//   width: 30vw;
//   background: green;
//   padding: 30px;
//   box-sizing: border-box;
//   z-index: 2;
//   border: 0.6px solid rgba(0, 0, 0, 0.75);
// `;
