import Types from "./actionType";
import { AnnouncementsActions, AnnouncementsState } from "./types";

const initialState: AnnouncementsState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
};

export const AnnouncementsReducer = (state = initialState, action: AnnouncementsActions) => {
  switch (action.type) {
    case Types.SUCCESS_FETCH_ANNOUNCEMENTS:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default AnnouncementsReducer;
