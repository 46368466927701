import React, { useCallback } from "react";

interface useCommonActionsReturnType {
  handlePopupWrapperClick: (e: React.MouseEvent) => void;
}

export const useCommonActions = (): useCommonActionsReturnType => {
  const handlePopupWrapperClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);
  return {
    handlePopupWrapperClick,
  };
};
