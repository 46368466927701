import React from "react";

import { Layout } from "../../../presentationals";
import { SPSPaperworkController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSPaperworkController type={"add"} />} admin={true} layout={"2"} />;
};

export const APSPSPaperworkAdd = React.memo(Route);
