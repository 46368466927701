import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { MomentDate, getAttributValues } from "../../../../services/methods";
import { CompanysControllerProps } from "../../../../types/models/Company";
import { collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { WorkLogs, ActivityLogs, TableController } from "../../../components";
import { TextInput, Select, Checkbox } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { BankDetailsList } from "../BankDetails/BankDetailsList";
import { CompanyContactList } from "../CompanyContact";
import { RelatedListContainer } from "../Departments/styles";
import { TaskList } from "../Task";

import { useCompanyController } from "./hooks";
import { Row } from "./styles";

export const CompanyController = ({ type }: CompanysControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions, employeesOptions, companyOptions, companyContactOptions } =
    useControllerConfigs();

  const steps = ["Overview", "Address/ Location", "Additional Details", "Service Details"];
  if (type === "edit") {
    steps.push("Worklogs");
    steps.push("System Data");
  }
  const {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchCompanys,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    handleCheckbox,
  } = useCompanyController({ type, id: params.id, attachmentsPickerRef });
  const currentCompany = data;

  const {
    company,
    serviceDetails,
    corporationType,
    W9,
    AOI,
    ACH,
    voidedCheck,
    GST,
    PAN,
    IEC,
    AOA,
    MOA,
    COITaken,
    about,
    companyType,
    companyClassification,
    companyOwner,
    existingCompany,
    industry,
    website,
    size,
    externalLinks,
    accountOwner,
    country,
    province,
    addressLineOne,
    addressLineTwo,
    city,
    zipCode,
    phone,
    workLogs,
    attachments,
  } = formData;
  const { serviceModel, serviceType, startDate, endDate, renewDate, details } = serviceDetails;

  useEffect(() => {
    if (type === "edit") {
      fetchCompanys();
    }
    // Do not include fetchBenefits to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const renderRelatedBankDetails = useCallback(() => {
    return (
      <RelatedListContainer>
        <BankDetailsList
          listType='associatedCompany'
          relatedList={{
            type: "associatedCompany",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderCompanyContact = useCallback(() => {
    return (
      <RelatedListContainer>
        <CompanyContactList
          relatedList={{
            type: "company",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedTasks = useCallback(() => {
    return (
      <RelatedListContainer>
        <TaskList
          relatedList={{
            type: "associatedCompany",
            value: params.id,
            associatedTo: "company",
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Company"}
            type={"text"}
            width='48%'
            error={company.error}
            inputProps={{
              name: "company",
              value: company.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: company.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"companyClassification"}
            label={"Company Classification"}
            options={getAttributValues("companyClassification")}
            value={companyClassification.value}
            onChange={onTextChange}
            mandatory={companyClassification.required}
            error={companyClassification.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"companyType"}
          label={"Company Type"}
          options={getAttributValues("companyType")}
          isMulti
          value={companyType.value}
          onChange={onTextChange}
          mandatory={companyType.required}
          error={companyType.error}
        />
        <Select
          width={"100%"}
          name={"corporationType"}
          label={"Type of Corporation "}
          options={getAttributValues("corporationType")}
          value={corporationType.value}
          onChange={onTextChange}
          mandatory={corporationType.required}
          error={corporationType.error}
        />
        <TextInput
          label='About Company'
          type={"textarea"}
          width='100%'
          error={about.error}
          inputProps={{}}
          textAreaProps={{
            name: "about",
            rows: 12,
            value: about.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: about.required,
          }}
        />
        <Row>
          <Select
            width={"48%"}
            name={"companyOwner"}
            label={"Company Owner"}
            options={companyContactOptions}
            value={companyOwner.value}
            onChange={onTextChange}
            mandatory={companyOwner.required}
            error={companyOwner.error}
          />
          <Select
            width={"48%"}
            name={"existingCompany"}
            label={"Associated to any Exisiting Company"}
            options={companyOptions}
            value={existingCompany.value}
            onChange={onTextChange}
            mandatory={existingCompany.required}
            error={existingCompany.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"size"}
            label={"Company Size"}
            options={getAttributValues("companySize")}
            value={size.value}
            onChange={onTextChange}
            mandatory={size.required}
            error={size.error}
          />
          <Select
            width={"48%"}
            name={"industry"}
            label={"Industry"}
            options={getAttributValues("companyIndustry")}
            value={industry.value}
            onChange={onTextChange}
            mandatory={industry.required}
            error={industry.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Website"}
            type={"text"}
            width='48%'
            error={website.error}
            inputProps={{
              name: "website",
              value: website.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: website.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"accountOwner"}
            label={"Account Owner"}
            options={employeesOptions}
            value={accountOwner.value}
            onChange={onTextChange}
            mandatory={accountOwner.required}
            error={accountOwner.error}
          />
        </Row>
        <TextInput
          label={"External File Links"}
          type={"textarea"}
          width='100%'
          error={externalLinks.error}
          inputProps={{}}
          textAreaProps={{
            name: "externalLinks",
            rows: 12,
            value: externalLinks.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: externalLinks.required,
          }}
        />
      </>
    );
  }, [
    onTextChange,
    about.error,
    about.required,
    about.value,
    accountOwner.error,
    accountOwner.required,
    accountOwner.value,
    company.error,
    company.required,
    company.value,
    companyOptions,
    companyClassification.error,
    companyClassification.required,
    companyClassification.value,
    companyOwner.error,
    companyContactOptions,
    companyOwner.required,
    companyOwner.value,
    companyType.error,
    companyType.required,
    companyType.value,
    corporationType.error,
    corporationType.required,
    corporationType.value,
    employeesOptions,
    existingCompany.error,
    existingCompany.required,
    existingCompany.value,
    externalLinks.error,
    externalLinks.required,
    externalLinks.value,
    industry.error,
    industry.required,
    industry.value,
    size.error,
    size.required,
    size.value,
    website.error,
    website.required,
    website.value,
  ]);

  const renderAddress = useMemo(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={province.error}
            inputProps={{
              name: "province",
              value: province.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: province.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Address Line 1"}
            type={"text"}
            width='48%'
            error={addressLineOne.error}
            inputProps={{
              name: "addressLineOne",
              value: addressLineOne.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineOne.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Address Line 2"}
            type={"text"}
            width='48%'
            error={addressLineTwo.error}
            inputProps={{
              name: "addressLineTwo",
              value: addressLineTwo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineTwo.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Postal Zone/ZIP Code"}
            type={"text"}
            width='48%'
            error={zipCode.error}
            inputProps={{
              name: "zipCode",
              value: zipCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: zipCode.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label={"Phone"}
          type={"text"}
          width='100%'
          error={phone.error}
          inputProps={{
            name: "phone",
            value: phone.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: phone.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    onTextChange,
    addressLineOne.error,
    addressLineOne.required,
    addressLineOne.value,
    addressLineTwo.error,
    addressLineTwo.required,
    addressLineTwo.value,
    city.error,
    city.required,
    city.value,
    country.error,
    country.required,
    country.value,
    countryOptions,
    phone.error,
    phone.required,
    phone.value,
    province.error,
    province.required,
    province.value,
    zipCode.error,
    zipCode.required,
    zipCode.value,
  ]);

  const renderAdditionalDetails = useMemo(() => {
    return (
      <>
        <Row>
          <Checkbox
            label={"W9 Attached"}
            width='33%'
            error={W9.error}
            required={W9.required}
            onClick={handleCheckbox}
            value={W9.value}
            attributes={{
              "data-name": "W9",
            }}
          />
          <Checkbox
            label={"AOI Attached"}
            width='33%'
            error={AOI.error}
            required={AOI.required}
            onClick={handleCheckbox}
            value={AOI.value}
            attributes={{
              "data-name": "AOI",
            }}
          />
          <Checkbox
            label={"ACH Attached"}
            width='33%'
            error={ACH.error}
            required={ACH.required}
            onClick={handleCheckbox}
            value={ACH.value}
            attributes={{
              "data-name": "ACH",
            }}
          />
        </Row>
        <Row>
          <Checkbox
            label={"Voided Check Attached"}
            width='33%'
            error={voidedCheck.error}
            required={voidedCheck.required}
            onClick={handleCheckbox}
            value={voidedCheck.value}
            attributes={{
              "data-name": "voidedCheck",
            }}
          />
          <Checkbox
            label={"GST Attached"}
            width='33%'
            error={GST.error}
            required={GST.required}
            onClick={handleCheckbox}
            value={GST.value}
            attributes={{
              "data-name": "GST",
            }}
          />
          <Checkbox
            label={"PAN Attached"}
            width='33%'
            error={PAN.error}
            required={PAN.required}
            onClick={handleCheckbox}
            value={PAN.value}
            attributes={{
              "data-name": "PAN",
            }}
          />
        </Row>
        <Row>
          <Checkbox
            label={"IEC Attached"}
            width='33%'
            error={IEC.error}
            required={IEC.required}
            onClick={handleCheckbox}
            value={IEC.value}
            attributes={{
              "data-name": "IEC",
            }}
          />
          <Checkbox
            label={"AOA Attached"}
            width='33%'
            error={AOA.error}
            required={AOA.required}
            onClick={handleCheckbox}
            value={AOA.value}
            attributes={{
              "data-name": "AOA",
            }}
          />
          <Checkbox
            label={"MOA Attached"}
            width='33%'
            error={MOA.error}
            required={MOA.required}
            onClick={handleCheckbox}
            value={MOA.value}
            attributes={{
              "data-name": "MOA",
            }}
          />
        </Row>{" "}
        <Checkbox
          label={"COI Taken Attached"}
          width='33%'
          error={COITaken.error}
          required={COITaken.required}
          onClick={handleCheckbox}
          value={COITaken.value}
          attributes={{
            "data-name": "COITaken",
          }}
        />
      </>
    );
  }, [
    handleCheckbox,
    ACH.error,
    ACH.required,
    ACH.value,
    AOA.error,
    AOA.required,
    AOA.value,
    AOI.error,
    AOI.required,
    AOI.value,
    COITaken.error,
    COITaken.required,
    COITaken.value,
    GST.error,
    GST.required,
    GST.value,
    IEC.error,
    IEC.required,
    IEC.value,
    MOA.error,
    MOA.required,
    MOA.value,
    PAN.error,
    PAN.required,
    PAN.value,
    W9.error,
    W9.required,
    W9.value,
    voidedCheck.error,
    voidedCheck.required,
    voidedCheck.value,
  ]);

  const renderServiceDetails = useMemo(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"serviceType"}
            label={"Type of Service"}
            options={getAttributValues("companyServiceType", true)}
            value={serviceType.value}
            onChange={onTextChange}
            mandatory={serviceType.required}
            error={serviceType.error}
          />
          <Select
            width={"48%"}
            name={"serviceModel"}
            label={"Type of Model"}
            options={getAttributValues("companyModelType", true)}
            value={serviceModel.value}
            onChange={onTextChange}
            mandatory={serviceModel.required}
            error={serviceModel.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Start Date'
            type={"date"}
            width='33%'
            error={startDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "startDate",
              value: startDate.value,
              required: startDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='End Date'
            type={"date"}
            width='33%'
            error={endDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "endDate",
              minDate: startDate.value ? new Date(startDate.value) : undefined,
              value: endDate.value,
              required: endDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Renew Date'
            type={"date"}
            width='33%'
            error={renewDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "renewDate",
              minDate: endDate.value ? new Date(endDate.value) : undefined,
              value: renewDate.value,
              required: renewDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label={"Service Details"}
          type={"textarea"}
          width='100%'
          error={details.error}
          textAreaProps={{
            name: "details",
            value: details.value,
            rows: 12,
            onChange: onTextChange,
            required: details.required,
          }}
        />
      </>
    );
  }, [
    onDateChange,
    details.error,
    details.required,
    details.value,
    endDate.error,
    endDate.required,
    endDate.value,
    onTextChange,
    renewDate.error,
    renewDate.required,
    renewDate.value,
    serviceModel.error,
    serviceModel.required,
    serviceModel.value,
    serviceType.error,
    serviceType.required,
    serviceType.value,
    startDate.error,
    startDate.required,
    startDate.value,
  ]);

  const renderSystemData = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Created On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentCompany?.createdAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentCompany?.updatedAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Created By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentCompany?.createdBy?.name,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentCompany?.updatedBy?.name,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    currentCompany?.createdAt,
    currentCompany?.createdBy?.name,
    currentCompany?.updatedAt,
    currentCompany?.updatedBy?.name,
  ]);

  const renderWorklogs = useMemo(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.company.name} />,
    [params.id],
  );

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.company.name} recordId={params.id} />,
    [params.id],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderAddress;
      case 2:
        return renderAdditionalDetails;
      case 3:
        return renderServiceDetails;
      case 4:
        return renderChecklist;
      case 5:
        return renderActivitylogs;
      case 6:
        return renderWorklogs;
      case 7:
        return renderSystemData;
      case 8:
        return renderCompanyContact;
      case 9:
        return renderRelatedBankDetails;
      case 10:
        return renderRelatedTasks;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderAdditionalDetails,
    renderServiceDetails,
    renderSystemData,
    renderWorklogs,
    renderRelatedBankDetails,
    renderAddress,
    renderCompanyContact,
    renderRelatedTasks,
    renderActivitylogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
