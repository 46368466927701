import Types from "./actionType";
import {
  FetchCompanyPayload,
  CreateCompany,
  CreateCompanyPayload,
  CompanyList,
  FetchCompany,
  FetchCompanyError,
  FetchCompanySuccess,
  UpdateCompany,
  UpdateCompanyPayload,
  CompanyListWithMetaData,
  FetchCompanyByIdPayload,
  FetchCompanyById,
  DeleteCompanyPayload,
  DeleteCompany,
} from "./types";

export const fetchCompanyList = ({
  params,
  onSuccess,
  onError,
}: FetchCompanyPayload): FetchCompany => ({
  type: Types.FETCH_COMPANY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCompanyById = ({
  id,
  onSuccess,
  onError,
}: FetchCompanyByIdPayload): FetchCompanyById => ({
  type: Types.FETCH_COMPANY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCompanyListSuccess = (data: CompanyListWithMetaData): FetchCompanySuccess => ({
  type: Types.SUCCESS_FETCH_COMPANY,
  payload: {
    data,
  },
});

export const fetchCompanyError = (error?: string): FetchCompanyError => ({
  type: Types.ERROR_FETCH_COMPANY,
  payload: { error },
});

export const createCompany = ({
  data,
  onSuccess,
  onError,
}: CreateCompanyPayload): CreateCompany => ({
  type: Types.CREATE_COMPANY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCompany = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCompanyPayload): UpdateCompany => ({
  type: Types.UPDATE_COMPANY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCompany = ({ id, onSuccess, onError }: DeleteCompanyPayload): DeleteCompany => ({
  type: Types.DELETE_COMPANY,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
