/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CHECKLIST_TEMPLATE = "FETCH_CHECKLIST_TEMPLATE",
  FETCH_CHECKLIST_TEMPLATE_BY_ID = "FETCH_CHECKLIST_TEMPLATE_BY_ID",
  SUCCESS_FETCH_CHECKLIST_TEMPLATE = "SUCCESS_FETCH_CHECKLIST_TEMPLATE",
  ERROR_FETCH_CHECKLIST_TEMPLATE = "ERROR_FETCH_CHECKLIST_TEMPLATE",
  CREATE_CHECKLIST_TEMPLATE = "CREATE_CHECKLIST_TEMPLATE",
  UPDATE_CHECKLIST_TEMPLATE = "UPDATE_CHECKLIST_TEMPLATE",
  DELETE_CHECKLIST_TEMPLATE = "DELETE_CHECKLIST_TEMPLATE",
}

export default Types;
