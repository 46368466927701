import Types from "./actionType";
import {
  FetchSPSAccountsPayload,
  CreateSPSAccounts,
  CreateSPSAccountsPayload,
  FetchSPSAccounts,
  FetchSPSAccountsError,
  FetchSPSAccountsSuccess,
  UpdateSPSAccounts,
  UpdateSPSAccountsPayload,
  SPSAccountsListWithMetaData,
  FetchSPSAccountsByIdPayload,
  FetchSPSAccountsById,
  DeleteSPSAccountsPayload,
  DeleteSPSAccounts,
} from "./types";

export const fetchSPSAccountsList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSAccountsPayload): FetchSPSAccounts => ({
  type: Types.FETCH_SPSACCOUNTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSAccountsById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSAccountsByIdPayload): FetchSPSAccountsById => ({
  type: Types.FETCH_SPSACCOUNTS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSAccountsListSuccess = (
  data: SPSAccountsListWithMetaData,
): FetchSPSAccountsSuccess => ({
  type: Types.SUCCESS_FETCH_SPSACCOUNTS,
  payload: {
    data,
  },
});

export const fetchSPSAccountsError = (error?: string): FetchSPSAccountsError => ({
  type: Types.ERROR_FETCH_SPSACCOUNTS,
  payload: { error },
});

export const createSPSAccounts = ({
  data,
  onSuccess,
  onError,
}: CreateSPSAccountsPayload): CreateSPSAccounts => ({
  type: Types.CREATE_SPSACCOUNTS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSAccounts = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSAccountsPayload): UpdateSPSAccounts => ({
  type: Types.UPDATE_SPSACCOUNTS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSAccounts = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSAccountsPayload): DeleteSPSAccounts => ({
  type: Types.DELETE_SPSACCOUNTS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
