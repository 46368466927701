import React, { MouseEvent, useCallback, useRef, useState } from "react";

import { MyTooltip, Popup, TextInput } from "../..";
import Document from "../../../../components/Document/Document";
import { uploadFile } from "../../../../redux";
import { useAppDispatch } from "../../../../redux/hooks";
import { getAttachmentFileIcon } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { Attachments } from "../../../../types";
import {
  AttachmentIcon,
  AttachmentIconsContainer,
  AttachmentListItem,
  AttachmentListItemContainer,
  AttachmentNameContainer,
  AttachmentTitle,
  AttachmentsModalContainer,
  BrowseAttachmentContainer,
  ButtonsContainer,
  CloseIcon,
  CurrentAttachmentContainer,
  DocumentViewContainer,
  NoAttachmentsContainer,
} from "../../../common/styles";
import { Button } from "../Button";
import { ErrorText, ParentWrapper } from "../TextInput/styles";

import { Label } from "./styles";
import { InputDiv } from "./styles";

interface AttachmentInputPropsTypes {
  label: string;
  attachment: { value: Attachments; required: boolean; error: string };
  onSave: (data, name) => void;
  handleDeleteAttachment: (fieldName) => void;
  width?: string;
  containerClass?: string;
  fieldName: string;
  accept?: string;
  aclType?: string;
  error?: string;
  required?: boolean;
}
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
function AttachmentInput({
  label,
  attachment,
  onSave,
  handleDeleteAttachment,
  width = "48%",
  containerClass,
  fieldName,
  accept = ".gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml,.txt",
  aclType,
  error,
  required,
}: AttachmentInputPropsTypes) {
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [documentId, setDocumentId] = useState<string>("");
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);

  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const dispatch = useAppDispatch();

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const documentId = attachment.value.id || "";
      setAttachmentModal("list");
      setDocumentId(documentId);
    },
    [attachment],
  );

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );
  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);
  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );
  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);
  const { name = "", extension } = attachment.value || {};
  let fileName = name;
  if (extension) {
    fileName = `${name}.${extension}`;
  }

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        onSave(
          { ...data, name: currentAttachment.name, extension: currentAttachment.extension },
          fieldName,
        );
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    if (aclType === "public") {
      params["type"] = "public";
    }
    dispatch(uploadFile(params));
  }, [
    currentAttachment.extension,
    currentAttachment.name,
    currentAttachmentFile,
    dispatch,
    fieldName,
    onSave,
    aclType,
  ]);
  return (
    <React.Fragment>
      <ParentWrapper width={width} className={containerClass}>
        <InputDiv
          error={error}
          style={{ display: "flex", justifyContent: "start", gap: "1rem", alignItems: "center" }}
          width={width}
        >
          {!attachment?.value?.name && (
            <Button title='Choose File' onClick={handleAttachmentsModal} />
          )}
          <p>{attachment?.value?.name}</p>
          {attachment?.value?.name && (
            <React.Fragment>
              <MyTooltip text='View Attachment'>
                <AttachmentIcon onClick={handleShowAttachment} src={images.viewAttachment} />
              </MyTooltip>
              <MyTooltip text='Delete Attachment'>
                <AttachmentIcon
                  onClick={() => handleDeleteAttachment(fieldName)}
                  src={images.deleteAttachment}
                />
              </MyTooltip>
            </React.Fragment>
          )}
        </InputDiv>
        <Label className={"focused"}>
          {label}
          <span style={{ color: "red" }}>{required ? "*" : ""}</span>
        </Label>
        <ErrorText>{error ? error : <br />}</ErrorText>
      </ParentWrapper>
      <Popup isOpen={!!attachmentModal} closeModal={handleAttachmentsModal}>
        {documentId ? (
          <DocumentViewContainer>
            <CloseIcon src={images.closeModal} onClick={handleCloseAttachment} />
            <Document id={documentId} />
          </DocumentViewContainer>
        ) : (
          <AttachmentsModalContainer>
            <CloseIcon src={images.closeModal} onClick={handleAttachmentsModal} />
            {attachmentModal === "list" && (
              <>
                {attachment.value?.name ? (
                  <AttachmentListItemContainer>
                    <AttachmentListItem>
                      <AttachmentNameContainer>
                        <AttachmentIcon src={getAttachmentFileIcon(extension)} />
                        <AttachmentTitle>{fileName}</AttachmentTitle>
                      </AttachmentNameContainer>
                      <AttachmentIconsContainer>
                        <MyTooltip text='View Attachment'>
                          <AttachmentIcon
                            onClick={handleShowAttachment}
                            src={images.viewAttachment}
                          />
                        </MyTooltip>
                        <MyTooltip text='Delete Attachment'>
                          <AttachmentIcon
                            onClick={() => handleDeleteAttachment(fieldName)}
                            src={images.deleteAttachment}
                          />
                        </MyTooltip>
                      </AttachmentIconsContainer>
                    </AttachmentListItem>
                    <AttachmentListItem className='attachments-buttons-container'>
                      <Button title={strings.common.ok} onClick={handleAttachmentsModal} />
                    </AttachmentListItem>
                  </AttachmentListItemContainer>
                ) : (
                  <>
                    <NoAttachmentsContainer>
                      {strings.attachments.noAttachments}
                    </NoAttachmentsContainer>
                    <Button
                      title={strings.common.add}
                      className='knowledge-attachments-add'
                      onClick={onClickAddAttachment}
                    />
                  </>
                )}
              </>
            )}
            {attachmentModal === "add" && (
              <>
                {currentAttachmentFile ? (
                  <CurrentAttachmentContainer>
                    <TextInput
                      label='Attachment'
                      width='90%'
                      inputProps={{
                        name: "attachment",
                        onChange: handleCurrentAttachment,
                        value: currentAttachment.name,
                      }}
                      error={attachmentUploadProgress}
                    />
                    <ButtonsContainer>
                      <Button
                        title={strings.common.save}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickSaveAttachment}
                      />
                      <Button
                        title={strings.common.cancel}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickCancelAttachment}
                      />
                    </ButtonsContainer>
                  </CurrentAttachmentContainer>
                ) : (
                  <>
                    <input
                      type='file'
                      name='attachments'
                      ref={attachmentsPickerRef}
                      onChange={onAttachmentsFileChange}
                      hidden={true}
                      accept={accept}
                    />
                    <BrowseAttachmentContainer>
                      Add attachments Supported Files:{accept}
                      <Button title={strings.common.browse} onClick={onClickBrowseAttachment} />
                    </BrowseAttachmentContainer>
                  </>
                )}
              </>
            )}
          </AttachmentsModalContainer>
        )}
      </Popup>
    </React.Fragment>
  );
}

export default AttachmentInput;
