import Types from "./actionType";
import {
  FetchActivityLogsPayload,
  CreateActivityLogs,
  CreateActivityLogsPayload,
  ActivityLogsList,
  FetchActivityLogs,
  FetchActivityLogsError,
  FetchActivityLogsSuccess,
  UpdateActivityLogs,
  UpdateActivityLogsPayload,
  ActivityLogsListWithMetaData,
  FetchActivityLogsByIdPayload,
  FetchActivityLogsById,
} from "./types";

export const fetchActivityLogsList = ({
  params,
  onSuccess,
  onError,
}: FetchActivityLogsPayload): FetchActivityLogs => ({
  type: Types.FETCH_ACTIVITY_LOGS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchActivityLogsById = ({
  id,
  onSuccess,
  onError,
}: FetchActivityLogsByIdPayload): FetchActivityLogsById => ({
  type: Types.FETCH_ACTIVITY_LOGS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchActivityLogsListSuccess = (
  data: ActivityLogsListWithMetaData,
): FetchActivityLogsSuccess => ({
  type: Types.SUCCESS_FETCH_ACTIVITY_LOGS,
  payload: {
    data,
  },
});

export const fetchActivityLogsError = (error?: string): FetchActivityLogsError => ({
  type: Types.ERROR_FETCH_ACTIVITY_LOGS,
  payload: { error },
});

export const createActivityLogs = ({
  data,
  onSuccess,
  onError,
}: CreateActivityLogsPayload): CreateActivityLogs => ({
  type: Types.CREATE_ACTIVITY_LOGS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateActivityLogs = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateActivityLogsPayload): UpdateActivityLogs => ({
  type: Types.UPDATE_ACTIVITY_LOGS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
