import React, { useEffect, useState } from "react";

import FolderTree from "react-folder-tree";
import { connect } from "react-redux";

import Header from "../../components/Header";
import { fetchEmployees } from "../../redux/employees/actions";
import { useTitle } from "../../services/methods";

import styles from "./APEmployeesPOD.styles";
import "react-folder-tree/dist/style.css";

const APEmployeesPOD = (props) => {
  const [finalData, setFinalData] = useState([]);
  useTitle("POD Tree");
  useEffect(() => {
    if (!props.employees) {
      props.fetchEmployees();
    }
    const object = {};
    const parent = {};
    for (let i = 0; i < props.employees.length; i++) {
      const element = props.employees[i];
      const elementId = element?._id;
      const elementPod = element?.reportingPOD;

      // check object is this key exist or not
      if (
        (!object[elementId] || !object[elementId].name || !object[elementId].email) &&
        elementId
      ) {
        if (!object[elementId]) {
          object[elementId] = {};
        }
        object[elementId]["name"] = element.name;
        object[elementId]["email"] = element.email;
      }
      if (!object[elementPod] && elementPod) {
        object[elementPod] = {};
      }
      if (elementPod) {
        if (!object[elementPod][elementId]) {
          object[elementPod][elementId] = {};
        }
        object[elementPod][elementId]["name"] = element.name;
        object[elementPod][elementId]["email"] = element.email;
      }
      if ((!elementPod && !parent[elementId]) || elementId === elementPod) {
        parent[elementId] = elementId;
      }
    }

    const temp = generateFinalData(parent, object);
    if (temp.length > 0) {
      setFinalData(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employees]);

  const generateFinalData = (parent, object) => {
    const temp = Object.keys(parent).map((item, index) => {
      const parent = recursiveFunction(item, object);
      return parent;
    });
    return temp;
  };

  const recursiveFunction = (item, object, isAlreadyArray) => {
    const parent = object[item];
    const tempData = {
      name: parent.name,
      isOpen: false,
      children: [],
    };
    //Base Condition
    const childKeys = Object.keys(parent);
    for (let i = 0; i < childKeys.length; i++) {
      if (typeof object[childKeys[i]] === "object" && childKeys[i] !== item) {
        recursiveFunction(childKeys[i], object, tempData);
      }
    }
    if (tempData.children.length === 0) {
      delete tempData.children;
    }
    isAlreadyArray?.children.push(tempData);
    return tempData;
  };

  return (
    <styles.MainContainer>
      <Header
        title='POD Tree'
        onSync={{
          event: props.fetchEmployees,
          params: {},
        }}
      />
      {finalData?.map((item) => {
        return (
          <FolderTree
            data={{
              name: item.name,
              isOpen: false,
              children: item.children,
            }}
            initOpenStatus='closed'
            showCheckbox={false}
            readOnly={true}
            // onChange={ onTreeStateChange }
          />
        );
      })}

      {/* <MuiTreeView tree={finalData} /> */}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.EmployeesReducer.loading,
  employees: state.EmployeesReducer.employees,
  filters: state.EmployeesReducer.filters,
  permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(APEmployeesPOD);
