import Types from "./actionTypes";
import {
  FetchKanbanConfigPayload,
  CreateKanbanConfig,
  CreateKanbanConfigPayload,
  FetchKanbanConfig,
  FetchKanbanConfigError,
  FetchKanbanConfigSuccess,
  UpdateKanbanConfig,
  UpdateKanbanConfigPayload,
  KanbanConfigListWithMetaData,
  FetchKanbanConfigByIdPayload,
  FetchKanbanConfigById,
  DeleteKanbanConfigPayload,
  DeleteKanbanConfig,
} from "./types";

export const fetchKanbanConfigList = ({
  params,
  onSuccess,
  onError,
}: FetchKanbanConfigPayload): FetchKanbanConfig => ({
  type: Types.FETCH_KANBAN_CONFIG,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchKanbanConfigById = ({
  id,
  onSuccess,
  onError,
}: FetchKanbanConfigByIdPayload): FetchKanbanConfigById => ({
  type: Types.FETCH_KANBAN_CONFIG_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchKanbanConfigListSuccess = (
  data: KanbanConfigListWithMetaData,
): FetchKanbanConfigSuccess => ({
  type: Types.SUCCESS_FETCH_KANBAN_CONFIG,
  payload: {
    data,
  },
});

export const fetchKanbanConfigError = (error?: string): FetchKanbanConfigError => ({
  type: Types.ERROR_FETCH_KANBAN_CONFIG,
  payload: { error },
});

export const createKanbanConfig = ({
  data,
  onSuccess,
  onError,
}: CreateKanbanConfigPayload): CreateKanbanConfig => ({
  type: Types.CREATE_KANBAN_CONFIG,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateKanbanConfig = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateKanbanConfigPayload): UpdateKanbanConfig => ({
  type: Types.UPDATE_KANBAN_CONFIG,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteKanbanConfig = ({
  id,
  onSuccess,
  onError,
}: DeleteKanbanConfigPayload): DeleteKanbanConfig => ({
  type: Types.DELETE_KANBAN_CONFIG,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
