import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchSPSDealsList,
  getProfile,
  showInAppLoader,
  removeAppNotification,
  updateSPSDeals,
  updateTableConfigs,
  deleteSPSDeals,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  SPSDealsListWithMetaData,
  FetchSPSDealsParams,
  SPSDealsList,
  DeleteSPSDealsPayload,
} from "../../../../../redux/spsDeals/types";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  sortType,
} from "../../../../../types";
import {
  fetchSPSDealsProps,
  useSPSDealsListProps,
  useSPSDealsListReturnType,
} from "../../../../../types/models/SPSDeals";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getSPSDealsColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.spsDeals;
const defaultFilters: QueryBuilderType[] = [];

export const useSPSDealsList = ({
  relatedList,
  widgetId,
}: useSPSDealsListProps): useSPSDealsListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.SPSDealsReducer.data.metaData);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const spsDeals = useAppSelector((state) => state.SPSDealsReducer.data.list);
  const { usersOptions, spsAccountsOptions, SPSSalesProfileOptions, modulesOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const spsDealsTableConfigFields = useMemo(() => {
    return tableConfiguration?.spsDeals?.fields?.length
      ? tableConfiguration?.spsDeals?.fields
      : tableListDefaultSelectFields.spsDeals;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = spsDealsTableConfigFields;
    return getSPSDealsColumns({ fields });
  }, [spsDealsTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: SPSDealsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.spsDeals?.fields || tableListDefaultSelectFields.spsDeals;
      records = records?.map((item) => {
        const spsDealstem = { ...item };
        if (
          userPersonalizedFields?.includes("SPSSalesProfile") &&
          typeof item.SPSSalesProfile !== "string"
        ) {
          spsDealstem.SPSSalesProfile = `${item?.SPSSalesProfile?.firstName} ${item?.SPSSalesProfile?.lastName}`;
        }
        if (
          userPersonalizedFields?.includes("accountOwner") &&
          typeof item.accountOwner !== "string"
        ) {
          spsDealstem.accountOwner = item?.accountOwner?.name;
        }
        if (userPersonalizedFields?.includes("type") && typeof item.type !== "string") {
          spsDealstem.type = item?.type?.value;
        }
        if (userPersonalizedFields?.includes("dealSource") && typeof item.dealSource !== "string") {
          spsDealstem.dealSource = item?.dealSource?.value;
        }
        if (userPersonalizedFields?.includes("state") && typeof item.state !== "string") {
          spsDealstem.state = item?.state?.value;
        }
        if (userPersonalizedFields?.includes("endClient") && typeof item.endClient !== "string") {
          spsDealstem.endClient = item?.endClient?.name;
        }

        if (userPersonalizedFields?.includes("createdAt")) {
          spsDealstem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          spsDealstem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          spsDealstem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          spsDealstem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete spsDealstem?._id;
        return spsDealstem;
      });
      const spsDealsFields = tablePublicFields.spsDeals;
      const columnHeaders = spsDealsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "spsDeals");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.spsDeals?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const spsDealsFetchSuccess = useCallback(
    (data: SPSDealsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const spsDealsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchSPSDeals = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchSPSDealsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.spsDeals?.limit || 10;

      const params: FetchSPSDealsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["preferredFullName"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: SPSDealsListWithMetaData) => spsDealsFetchSuccess(data, exportAll),
        onError: spsDealsFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchSPSDealsList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.spsDeals?.limit,
      sortKey,
      sortType,
      relatedList,
      spsDealsFetchError,
      dispatch,
      widgetsData,
      spsDealsFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = spsDeals?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, spsDeals, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchSPSDeals({ exportAll: true });
  }, [fetchSPSDeals]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchSPSDeals({ page });
  }, [fetchSPSDeals, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchSPSDeals({ page: parseInt(page), search });
    },
    [fetchSPSDeals, search],
  );

  const onClickSPSDeals = useCallback(
    (id: string) => {
      if (permissions?.spsDeals_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.spsDeals_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchSPSDeals({ page: 1, search });
  }, [fetchSPSDeals, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchSPSDeals({ page: 1 });
  }, [fetchSPSDeals]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchSPSDeals({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchSPSDeals],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchSPSDeals({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchSPSDeals],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = spsDeals?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, spsDeals]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            spsDeals?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, spsDeals, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const spsDealsConfigLimit = tableConfiguration?.spsDeals?.limit || 10;
      const spsDealsConfigs = {
        table: "spsDeals",
        fields: fields,
        limit: spsDealsConfigLimit,
      };
      const payload = {
        tableConfiguration: spsDealsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchSPSDeals({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Deals table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.spsDeals?.limit, dispatch, fetchSPSDeals, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const spsDealsConfigFields = tableConfiguration?.spsDeals?.fields;
      dispatch(showInAppLoader(true));
      const spsDealsConfigs = {
        table: "spsDeals",
        fields: spsDealsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: spsDealsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchSPSDeals({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Deals table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchSPSDeals],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = spsDeals?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchSPSDeals({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Deals updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Deals",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateSPSDeals(payload));
    },
    [selectedRows, spsDeals, dispatch, notificationsId, fetchSPSDeals],
  );

  // ================ Delete Method ================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = spsDeals?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteSPSDealsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchSPSDeals({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteSPSDeals(payload));
  }, [selectedRows, dispatch, spsDeals, notificationsId, fetchSPSDeals]);

  // ================ Table Header Buttons ================

  const spsDealsHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "SPSSalesProfile",
      label: "SPS Sales Profile",
      options: SPSSalesProfileOptions,
    },
    {
      name: "accountOwner",
      label: "Account Owner",
      options: usersOptions,
    },
    {
      name: "type",
      label: "Type",
      options: getAttributValues("spsDealsType"),
    },
    {
      name: "dealSource",
      label: "Deal Source",
      options: getAttributValues("dealSource"),
    },
    {
      name: "state",
      label: "State",
      options: getAttributValues("dealsState"),
    },
    {
      name: "endClient",
      label: "End Client",
      options: spsAccountsOptions,
    },
    {
      name: "preferredFullName",
      label: "Preferred FullName",
      type: "text",
    },
    {
      name: "legalAccountName",
      label: "Legal Account Name",
      type: "text",
    },
    {
      name: "rate",
      label: "Rate",
      type: "text",
    },
    {
      name: "staffingName",
      label: "Staffing Name",
      options: spsAccountsOptions,
    },
    {
      name: "platform",
      label: "Platform",
      options: getAttributValues("dealsPlateform"),
    },
    {
      name: "c2cInfo",
      label: "C2C Info",
      options: spsAccountsOptions,
    },
    {
      name: "modulesInvolved",
      label: "Modules Involved",
      options: modulesOptions,
    },
    {
      name: "interviewDetails",
      label: "Interview Details",
      type: "text",
    },
    {
      name: "recordingDriveLink",
      label: "Recording Drive Link",
      type: "text",
    },
    {
      name: "addressUsed",
      label: "Address Used",
      type: "text",
    },
    {
      name: "bankAccountDetailsUsed",
      label: "Bank Account Details Used",
      type: "text",
    },
    {
      name: "dealGivenTo",
      label: "Deal Given To",
      type: "text",
    },
    {
      name: "isTranscriptUploaded",
      label: "Curriculum Attached",
      options: ActiveSelectOptions,
    },
    {
      name: "isJobDescriptionUploaded",
      label: "Is Job Description Uploaded",
      options: ActiveSelectOptions,
    },
    {
      name: "isResumeUploaded",
      label: "Is Resume Uploaded",
      options: ActiveSelectOptions,
    },
    {
      name: "potentialStartDate",
      label: "Potential Start Date",
      type: "date",
    },
    {
      name: "potentialClosingDate",
      label: "Potential Closing Date",
      type: "date",
    },
    {
      name: "name",
      label: "Name",
      type: "text",
    },
    {
      name: "email",
      label: "Email",
      type: "text",
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
    },
    {
      name: "verifiedBy",
      label: "Verified By",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const spsDealsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;

    const userOptions = getFilterOptions(usersOptions, "Users");
    const spsAccountsFilterOptions = getFilterOptions(spsAccountsOptions, "SPS Account");
    const spsSalesProfileFilterOptions = getFilterOptions(
      SPSSalesProfileOptions,
      "SPS SalesProfile",
    );

    const dealSourceOptions = getFilterOptions(getAttributValues("dealSource"), "Deal Source");
    const dealsStateOptions = getFilterOptions(getAttributValues("dealsState"), "Deals State");
    const spsDealsTypeOptions = getFilterOptions(
      getAttributValues("spsDealsType"),
      "SPS Deals Type",
    );
    const dealPlatformOptions = getFilterOptions(getAttributValues("dealsPlateform"), "Platform");
    const activeOptions = getFilterOptions(usersOptions, "Select");

    const fields: Field[] = [
      {
        name: "preferredFullName",
        label: "Preferred Full Name",
        inputType: "string",
        validator,
      },
      {
        name: "legalAccountName",
        label: "Legal Account Name",
        inputType: "string",
        validator,
      },
      {
        name: "SPSSalesProfile",
        label: "SPS Sales Profile",
        valueEditorType: "select",
        values: spsSalesProfileFilterOptions,
        defaultValue: spsSalesProfileFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "accountOwner",
        label: "Account Owner",
        valueEditorType: "select",
        values: userOptions,
        defaultValue: userOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "rate",
        label: "Rate",
        inputType: "string",
        validator,
      },
      {
        name: "Type",
        label: "type",
        valueEditorType: "select",
        values: spsDealsTypeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "Deal Source",
        label: "dealSource",
        valueEditorType: "select",
        values: dealSourceOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "State",
        label: "state",
        valueEditorType: "select",
        values: dealsStateOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "endClient",
        label: "End Client",
        valueEditorType: "select",
        values: spsAccountsFilterOptions,
        defaultValue: spsAccountsFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "staffingName",
        label: "Staffing Name",
        valueEditorType: "select",
        values: spsAccountsFilterOptions,
        defaultValue: spsAccountsFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "platform",
        label: "Platform",
        valueEditorType: "select",
        values: dealPlatformOptions,
        defaultValue: dealPlatformOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "c2cInfo",
        label: "C2C Info",
        inputType: "string",
        validator,
      },
      {
        name: "interviewDetails",
        label: "Interview Details",
        inputType: "string",
        validator,
      },
      {
        name: "recordingDriveLink",
        label: "Recording Drive Link",
        inputType: "string",
        validator,
      },
      {
        name: "addressUsed",
        label: "Address Used",
        inputType: "string",
        validator,
      },
      {
        name: "bankAccountDetailsUsed",
        label: "Bank Account Details Used",
        inputType: "string",
        validator,
      },
      {
        name: "dealGivenTo",
        label: "Deal Given To",
        inputType: "string",
        validator,
      },
      {
        name: "isTranscriptUploaded",
        label: "Curriculum Attached",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "isJobDescriptionUploaded",
        label: "Is Job Description Uploaded",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "isResumeUploaded",
        label: "Is Resume Uploaded",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "potentialStartDate",
        label: "Potential Start Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "potentialClosingDate",
        label: "Potential Closing Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "name",
        label: "Name",
        inputType: "string",
        validator,
      },
      {
        name: "email",
        label: "Email",
        inputType: "string",
        validator,
      },
      {
        name: "phone",
        label: "Phone",
        inputType: "string",
        validator,
      },
      {
        name: "verifiedBy",
        label: "Verified By",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [SPSSalesProfileOptions, spsAccountsOptions, usersOptions]);

  return {
    listActionOptions,
    spsDealsFilterFields,
    onClickSPSDeals,
    fetchSPSDeals,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    columns,
    spsDealsTableConfigFields,
    refreshList,
    onFilterChange,
    onSubmitDeleteRecord,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    spsDealsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
  };
};
