import Types from "./actionType";
import {
  FetchCampaignsAssetsPayload,
  CreateCampaignsAssets,
  CreateCampaignsAssetsPayload,
  FetchCampaignsAssets,
  FetchCampaignsAssetsError,
  FetchCampaignsAssetsSuccess,
  UpdateCampaignsAssets,
  UpdateCampaignsAssetsPayload,
  CampaignsAssetsListWithMetaData,
  FetchCampaignsAssetsByIdPayload,
  FetchCampaignsAssetsById,
  DeleteCampaignsAssetsPayload,
  DeleteCampaignsAssets,
} from "./types";

export const fetchCampaignsAssetsList = ({
  params,
  onSuccess,
  onError,
}: FetchCampaignsAssetsPayload): FetchCampaignsAssets => ({
  type: Types.FETCH_CAMPAIGNS_ASSETS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCampaignsAssetsById = ({
  id,
  onSuccess,
  onError,
}: FetchCampaignsAssetsByIdPayload): FetchCampaignsAssetsById => ({
  type: Types.FETCH_CAMPAIGNS_ASSETS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCampaignsAssetsListSuccess = (
  data: CampaignsAssetsListWithMetaData,
): FetchCampaignsAssetsSuccess => ({
  type: Types.SUCCESS_FETCH_CAMPAIGNS_ASSETS,
  payload: {
    data,
  },
});

export const fetchCampaignsAssetsError = (error?: string): FetchCampaignsAssetsError => ({
  type: Types.ERROR_FETCH_CAMPAIGNS_ASSETS,
  payload: { error },
});

export const createCampaignsAssets = ({
  data,
  onSuccess,
  onError,
}: CreateCampaignsAssetsPayload): CreateCampaignsAssets => ({
  type: Types.CREATE_CAMPAIGNS_ASSETS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCampaignsAssets = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCampaignsAssetsPayload): UpdateCampaignsAssets => ({
  type: Types.UPDATE_CAMPAIGNS_ASSETS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCampaignsAssets = ({
  id,
  onSuccess,
  onError,
}: DeleteCampaignsAssetsPayload): DeleteCampaignsAssets => ({
  type: Types.DELETE_CAMPAIGNS_ASSETS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
