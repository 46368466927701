import React, { useCallback, useState } from "react";

import { addAppNotification, removeAppNotification } from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { fetchKnowledgeArticles } from "../../../../../redux/knowledge/actions";
import {
  ArticlesListWithMetaData,
  FetchKnowledgeArticleListParams,
  KnowledgeArticlesList,
  KnowledgeCurrentFilters,
} from "../../../../../redux/knowledge/types";
import { colors, strings } from "../../../../../theme";
import { AppNotificationsType, KnowledgeStatus } from "../../../../../types";

interface KnowledgeApprovalsProps {
  searchQuery?: string;
}

interface KnowledgeApprovalsType {
  headerTitle: (type: "all" | "my" | "inreview" | "published") => string | string[];
  handleOnPageClickMyArticles: (page: number) => void;
  handleOnPageClickApprovals: (page: number) => void;
  handleOnPageClickPublished: (page: number) => void;
  handleOnPageClickAll: (page: number) => void;
  handleReloadMyArticles: () => void;
  handleReloadApprovals: () => void;
  handleReloadPublished: () => void;
  handleReloadAll: () => void;
  getStatus: (article: KnowledgeArticlesList) => KnowledgeStatus;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search: string;
  handleClearSearch: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const useKnowledgeList = ({
  searchQuery = "",
}: KnowledgeApprovalsProps): KnowledgeApprovalsType => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.UserReducer.id);
  const notificationId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const [search, setSearch] = useState<string>("");

  const fetchArticlesSuccess = useCallback(
    (data: ArticlesListWithMetaData) => {
      const notification: AppNotificationsType = {
        id: notificationId + 1,
        type: "warning",
        title: strings.epKnowledge.removedFilters,
      };
      if (
        data.removedFilters.tags.length ||
        data.removedFilters.subcategories.length ||
        data.removedFilters.categories.length
      ) {
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 5000);
      }
    },
    [dispatch, notificationId],
  );

  const onChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, []);

  const headerTitle = useCallback((type: "all" | "my" | "inreview" | "published") => {
    const { myArticles, publishedArticles, allArticles, approvalsArticles } = strings.epKnowledge;
    switch (type) {
      case "all":
        return allArticles;
      case "inreview":
        return approvalsArticles;
      case "my":
        return myArticles;
      case "published":
        return publishedArticles;
    }
  }, []);

  const handleOnPageClickMyArticles = useCallback(
    (page: number = 1, filters?: KnowledgeCurrentFilters) => {
      const params: FetchKnowledgeArticleListParams = {
        page,
        limit: 10,
        createdBy: userId,
      };
      if (search) {
        params.search = search;
      }
      if (filters?.knowledgebase) {
        params.knowledgebase = filters?.knowledgebase;
      }
      if (filters?.categories.length) {
        params.categories = filters.categories.join(",");
      }
      if (filters?.subcategories.length) {
        params.subcategories = filters.subcategories.join(",");
      }
      if (filters?.tags.length) {
        params.tags = filters.tags.join(",");
      }
      console.log("A", params);
      dispatch(fetchKnowledgeArticles({ params, type: "my", onSuccess: fetchArticlesSuccess }));
    },
    [userId, search, dispatch, fetchArticlesSuccess],
  );

  const handleOnPageClickApprovals = useCallback(
    (page: number = 1, filters?: KnowledgeCurrentFilters) => {
      const params: FetchKnowledgeArticleListParams = {
        page,
        limit: 10,
        status: "inreview",
      };
      if (search) {
        params.search = search;
      }
      if (filters?.knowledgebase) {
        params.knowledgebase = filters?.knowledgebase;
      }
      if (filters?.categories.length) {
        params.categories = filters.categories.join(",");
      }
      if (filters?.subcategories.length) {
        params.subcategories = filters.subcategories.join(",");
      }
      if (filters?.tags.length) {
        params.tags = filters.tags.join(",");
      }
      console.log("A", params);
      dispatch(
        fetchKnowledgeArticles({ params, type: "inreview", onSuccess: fetchArticlesSuccess }),
      );
    },
    [dispatch, search, fetchArticlesSuccess],
  );

  const handleOnPageClickPublished = useCallback(
    (page: number = 1, filters?: KnowledgeCurrentFilters) => {
      const params: FetchKnowledgeArticleListParams = {
        page,
        limit: 10,
        status: "published",
      };
      if (search) {
        params.search = search;
      }
      if (filters?.knowledgebase) {
        params.knowledgebase = filters?.knowledgebase;
      }
      if (filters?.categories.length) {
        params.categories = filters.categories.join(",");
      }
      if (filters?.subcategories.length) {
        params.subcategories = filters.subcategories.join(",");
      }
      if (filters?.tags.length) {
        params.tags = filters.tags.join(",");
      }
      console.log("A", params);
      dispatch(
        fetchKnowledgeArticles({ params, type: "published", onSuccess: fetchArticlesSuccess }),
      );
    },
    [dispatch, search, fetchArticlesSuccess],
  );

  const handleOnPageClickAll = useCallback(
    (page: number = 1, filters?: KnowledgeCurrentFilters) => {
      const params: FetchKnowledgeArticleListParams = { page, limit: 10 };
      if (search) {
        params.search = search;
      }
      if (filters?.knowledgebase) {
        params.knowledgebase = filters?.knowledgebase;
      }
      if (filters?.categories.length) {
        params.categories = filters.categories.join(",");
      }
      if (filters?.subcategories.length) {
        params.subcategories = filters.subcategories.join(",");
      }
      if (filters?.tags.length) {
        params.tags = filters.tags.join(",");
      }
      dispatch(fetchKnowledgeArticles({ params, type: "all", onSuccess: fetchArticlesSuccess }));
    },
    [dispatch, search, fetchArticlesSuccess],
  );

  const handleReloadMyArticles = useCallback(() => {
    const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, createdBy: userId };
    dispatch(fetchKnowledgeArticles({ params, type: "my" }));
  }, [dispatch, userId]);

  const handleReloadApprovals = useCallback(() => {
    const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, status: "inreview" };
    dispatch(fetchKnowledgeArticles({ params, type: "inreview" }));
  }, [dispatch]);

  const handleReloadPublished = useCallback(() => {
    const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, status: "published" };
    dispatch(fetchKnowledgeArticles({ params, type: "published" }));
  }, [dispatch]);

  const handleReloadAll = useCallback(() => {
    const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10 };
    dispatch(fetchKnowledgeArticles({ params, type: "all" }));
  }, [dispatch]);

  const getStatus = useCallback((article: KnowledgeArticlesList) => {
    const { drafted, inreview, published, revised } = strings.epKnowledge;
    const { draftStatus, inreviewStatus, publishedStatus } = colors.knowledge;
    const status: KnowledgeStatus = {
      title: "",
      color: colors.knowledge.publishedStatus,
    };
    switch (article.status) {
      case "drafted":
        status.title = drafted;
        status.color = draftStatus;
        break;
      case "inreview":
        status.title = inreview;
        status.color = inreviewStatus;
        break;
      case "published":
        status.title = published;
        status.color = publishedStatus;
        break;
      case "revised":
        status.title = revised;
        status.color = publishedStatus;
        break;
    }
    return status;
  }, []);

  return {
    headerTitle,
    handleOnPageClickMyArticles,
    getStatus,
    handleOnPageClickApprovals,
    handleOnPageClickPublished,
    handleOnPageClickAll,
    onChangeSearch,
    search,
    handleClearSearch,
    handleReloadMyArticles,
    handleReloadApprovals,
    handleReloadAll,
    handleReloadPublished,
    setSearch,
  };
};
