import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import { addRecruitsAssignment, fetchRecruits, updateRecruits } from "../../redux/recruits/actions";
import { formValidationMethod, useTitle } from "../../services/methods";

import styles from "./RecruitmentsAssignments.styles";

const RecruitmentAssignment = (props) => {
  const defaultFormData = {
    bucketId: {
      required: true,
      error: "",
      value: "",
    },
    assignedTo: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    takenBy: {
      required: true,
      type: "",
      error: "",
      value: props.employeeId ? props.employeeId : "",
    },
    dateScheduled: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    meetingInvite: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    feedback: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
  };
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [bucket, setBucket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [currentRound, setCurrentRound] = useState({});
  const [nextRound, setNextRound] = useState({});

  useEffect(() => {
    if (params.id !== id) {
      setLoading(true);
      setSubmitting(false);
      setNextRound({});
      const find = props.recruits.filter((recruit) => recruit._id === params.id);
      if (find.length > 0) {
        setId(params.id);
        setBucket(find[0].bucket);
        setLoading(false);
        setFormData({
          ...defaultFormData,
          feedback: {
            ...defaultFormData.feedback,
            value: find[0].temp_feedback,
          },
        });
        const findCurrentTab = props.tabs.filter((item) => {
          return item.bucket === find[0].bucket + 0;
        });
        setCurrentRound(findCurrentTab[0]);
        const findNextTab = props.tabs.filter((item) => {
          return item.bucket === find[0].bucket + 1;
        });
        setNextRound(findNextTab[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, props.recruits]);

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const validateForm = () => {
    let output = true;
    const newFormValidation = { ...formData };
    const formDataIteration = { ...formData };
    if (formData.bucketId.value <= 0) {
      delete formDataIteration.assignedTo;
      delete formDataIteration.dateScheduled;
      delete formDataIteration.meetingInvite;
    }
    if (nextRound.bucket === 4) {
      delete formDataIteration.meetingInvite;
    }
    Object.keys(formDataIteration).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation[name] = {
        ...newFormValidation[name],
        error: error,
      };
    });
    setFormData(newFormValidation);
    return output;
  };

  const generateFormObject = () => {
    const object = {};
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      object[name] = value;
    });
    return object;
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 ? true : false;
    return value;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const status = validateForm();
    if (status) {
      if (formData.bucketId.value === "-1") {
        window.confirm("Are you sure you want to reject this applicant ?")
          ? __handleOnSubmit()
          : console.log("cancelled");
      } else {
        __handleOnSubmit();
      }
    } else {
      alert("please fill the form");
    }
  };

  const __handleOnSubmit = () => {
    const formDataObject = generateFormObject();
    setSubmitting(true);
    const sagaData = {
      recruitsId: id,
      data: formDataObject,
      onSuccess: (id) => {
        props.fetchRecruits({
          onSuccess: () => {
            navigate("/ap/recruitment");
          },
        });
      },
      onError: (err) => {
        setSubmitting(false);
        alert(err);
      },
    };
    props.addRecruitsAssignment(sagaData);
  };

  const handleSaveFeedback = () => {
    setSubmitting(true);
    props.updateRecruits({
      id: id,
      data: {
        temp_feedback: formData.feedback.value,
      },
      onSuccess: (data) => {
        setSubmitting(false);
      },
      onError: () => {
        setSubmitting(false);
      },
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>Assignment</h2>
      </styles.Header>
      <hr style={{ width: "100%" }} />
      <styles.Body>
        <form onSubmit={handleOnSubmit}>
          <h3>Current - {currentRound.label} Round</h3>
          <br />
          <Select
            name={"takenBy"}
            label={"Current Round Taken By"}
            options={props.employees.map((value, index) => ({
              label: value?.label + "(ITN-" + value?._id?.slice(-5) + ")",
              value: value._id,
            }))}
            value={formData.takenBy.value}
            onChange={onTextChange}
            error={formData?.takenBy?.error}
          />
          <Input
            label={"Feedback"}
            type={"textarea"}
            name='feedback'
            value={formData.feedback.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.feedback?.error}
          />
          <Button
            label='Save Feedback Only'
            size='small'
            disabled={submitting}
            onClick={handleSaveFeedback}
          />
          <br />
          <br />
          <h3>Next - {nextRound.label} Round</h3>
          <br />
          <Select
            name={"bucketId"}
            label={"Candidate Accepted"}
            options={[
              { label: "Yes", value: String(bucket + 1) },
              { label: "No", value: "-1" },
            ]}
            value={formData.bucketId.value}
            onChange={onTextChange}
            error={formData?.bucketId?.error}
          />
          {formData.bucketId.value !== "-1" && (
            <>
              <Input
                label={"Date Scheduled"}
                type={"date"}
                name='dateScheduled'
                value={formData.dateScheduled.value}
                autoComplete={"off"}
                onChange={onTextChange}
                mandatory={true}
                error={formData?.dateScheduled?.error}
              />
              <Select
                name={"assignedTo"}
                label={"Assigned To"}
                options={props.employees.map((value, index) => ({
                  label: value?.label + "(ITN-" + value?._id?.slice(-5) + ")",
                  value: value._id,
                }))}
                value={formData.assignedTo.value}
                onChange={onTextChange}
                error={formData?.assignedTo?.error}
              />
              {nextRound.bucket !== 4 && (
                <Input
                  label={"Meeting Invite Link"}
                  type={"text"}
                  name='meetingInvite'
                  value={formData.meetingInvite.value}
                  autoComplete={"off"}
                  onChange={onTextChange}
                  mandatory={true}
                  error={formData?.meetingInvite?.error}
                />
              )}
            </>
          )}

          <Button
            onClick={handleOnSubmit}
            disabled={SubmitDisabled() || submitting}
            size={"normal"}
            label={"Save"}
          />
          <br />
          <br />
          <br />
        </form>
      </styles.Body>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  recruits: state.RecruitsReducer.recruits,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
  tabs: state.RecruitsReducer.tabs,
  employeeId: state.UserReducer.employeeId,
});

const mapDispatchToProps = {
  fetchRecruits,
  addRecruitsAssignment,
  updateRecruits,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentAssignment);
