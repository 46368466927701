import styled from "styled-components";

import { fs, fonts, colors } from "../../../theme";

interface Props {
  width?: string;
  active?: boolean;
}

export const Container = styled.div<Props>`
  width: ${(props) => props.width};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px 2px 0px 0px;
  overflow: hidden;
  max-height: 399px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.div`
  height: 14.28%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  padding-left: 4%;
  align-items: center;
  background-color: ${colors.common.secondaryWhite};
  color: ${colors.common.black};
  font-size: ${fs.m};
  font-family: ${fonts.OpenSans};
`;

export const AccordionContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

export const Item = styled.div``;

export const Row = styled.div<Props>`
  display: flex;
  height: 57px;
  align-items: center;
  padding-left: 4%;
  border-top: 0.3px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
  .arrow {
    position: absolute;
    right: 8%;
    transition: linear 0.5s;
    transform: ${(props) => props.active && "rotate(90deg)"};
  }
`;

export const ItemTitle = styled.text<Props>`
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  color: ${(props) => (props.active ? colors.common.activeTabText : colors.common.black)};
`;

export const Icon = styled.img`
  height: 12px;
  width: 12px;
  object-fit: contain;
  align-self: center;
`;

export const AnimationDiv = styled.div`
  padding: 0 4%;
  cursor: pointer;
  .accordian-body {
    transition: height 400ms;
  }
  .inactive {
    height: 0;
    opacity: 0;
  }
  .active {
    height: 285px;
    animation: 4s;
  }

  @keyframes slidein {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Accordianmessage = styled.p`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.m};
`;

export const NoData = styled.div`
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: bold;
  font-family: ${fonts.SourceSansPro};
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
`;
