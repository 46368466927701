import Types from "./actionType";
import {
  FetchDashboardWidgetsPayload,
  CreateDashboardWidgets,
  CreateDashboardWidgetsPayload,
  FetchDashboardWidgets,
  FetchDashboardWidgetsError,
  FetchDashboardWidgetsSuccess,
  UpdateDashboardWidgets,
  UpdateDashboardWidgetsPayload,
  DashboardWidgetsListWithMetaData,
  FetchDashboardWidgetsByIdPayload,
  FetchDashboardWidgetsById,
  DeleteDashboardWidgetsPayload,
  DeleteDashboardWidgets,
} from "./types";

export const fetchDashboardWidgetsList = ({
  params,
  onSuccess,
  onError,
}: FetchDashboardWidgetsPayload): FetchDashboardWidgets => ({
  type: Types.FETCH_DASHBOARD_WIDGETS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchDashboardWidgetsById = ({
  id,
  onSuccess,
  onError,
}: FetchDashboardWidgetsByIdPayload): FetchDashboardWidgetsById => ({
  type: Types.FETCH_DASHBOARD_WIDGETS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDashboardWidgetsListSuccess = (
  data: DashboardWidgetsListWithMetaData,
): FetchDashboardWidgetsSuccess => ({
  type: Types.SUCCESS_FETCH_DASHBOARD_WIDGETS,
  payload: {
    data,
  },
});

export const fetchDashboardWidgetsError = (error?: string): FetchDashboardWidgetsError => ({
  type: Types.ERROR_FETCH_DASHBOARD_WIDGETS,
  payload: { error },
});

export const createDashboardWidgets = ({
  data,
  onSuccess,
  onError,
}: CreateDashboardWidgetsPayload): CreateDashboardWidgets => ({
  type: Types.CREATE_DASHBOARD_WIDGETS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDashboardWidgets = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDashboardWidgetsPayload): UpdateDashboardWidgets => ({
  type: Types.UPDATE_DASHBOARD_WIDGETS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteDashboardWidgets = ({
  id,
  onSuccess,
  onError,
}: DeleteDashboardWidgetsPayload): DeleteDashboardWidgets => ({
  type: Types.DELETE_DASHBOARD_WIDGETS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
