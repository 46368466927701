import React from "react";

import { images } from "../../../../theme";

import { Container, Label, SwitchOff, SwitchOn } from "./styles";

interface Props {
  value: number;
  label?: string;
  onPress?: any;
  height?: string;
  width?: string;
  name?: string;
  opacity?: number;
}

export const Switch = (props: Props) => {
  const { value, onPress, label, height, width, opacity, name, ...attributes } = props;
  return (
    <Container onClick={onPress} data-target={value === 0 ? 1 : 0} data-name={name} {...attributes}>
      {label && <Label>{label}</Label>}
      {value ? (
        <SwitchOn
          height={height ? height : "26px"}
          width={width ? width : "50px"}
          src={images.switchOn}
        />
      ) : (
        <SwitchOff
          style={{ opacity: opacity ? opacity : 1 }}
          height={height ? height : "26px"}
          width={width ? width : "50px"}
          src={images.switchOff}
        />
      )}
    </Container>
  );
};
