import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchAcademyCandidateLeadsList,
  getProfile,
  removeAppNotification,
  updateAcademyCandidateLeads,
  updateTableConfigs,
  showInAppLoader,
  deleteAcademyCandidateLeads,
} from "../../../../../redux";
import {
  AcademyCandidateLeadsList,
  AcademyCandidateLeadsListWithMetaData,
  DeleteAcademyCandidateLeadsPayload,
  FetchAcademyCandidateLeadsParams,
} from "../../../../../redux/AcademyCandidateLeads/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchAcademyCandidateLeadsProps,
  sortType,
  useAcademyCandidateLeadsListProps,
  useAcademyCandidateLeadsListReturnType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getAcademyCandidateLeadsColumns } from "../../../../columns/AcademyCandidateLeads";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.academyCandidateLeads;
const defaultFilters: QueryBuilderType[] = [];

export const useAcademyCandidateLeadsList = ({
  relatedList,
  widgetId,
}: useAcademyCandidateLeadsListProps): useAcademyCandidateLeadsListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.AcademyCandidateLeadsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const academyCandidateLeads = useAppSelector(
    (state) => state.AcademyCandidateLeadsReducer.data.list,
  );
  const {
    employeesOptions,
    usersOptions,
    academyCoursesOptions,
    countryOptions,
    currencyOptions,
    academyCandidatesOptions,
  } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const academyCandidateLeadsTableConfigFields = useMemo(() => {
    return tableConfiguration?.academyCandidateLeads?.fields?.length
      ? tableConfiguration?.academyCandidateLeads?.fields
      : tableListDefaultSelectFields.academyCandidateLeads;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = academyCandidateLeadsTableConfigFields;
    return getAcademyCandidateLeadsColumns({ fields });
  }, [academyCandidateLeadsTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: AcademyCandidateLeadsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.academyCandidateLeads?.fields ||
        tableListDefaultSelectFields.academyCandidateLeads;
      records = records?.map((item) => {
        const academyCandidateItem = { ...item };
        if (userPersonalizedFields?.includes("stage") && typeof item.stage !== "string") {
          academyCandidateItem.stage = item?.stage?.value;
        }
        if (
          userPersonalizedFields?.includes("associatedContact") &&
          typeof item.associatedContact !== "string"
        ) {
          academyCandidateItem.associatedContact = item?.associatedContact?.value;
        }
        if (
          userPersonalizedFields?.includes("enrollmentSpecialists") &&
          typeof item.enrollmentSpecialists !== "string"
        ) {
          academyCandidateItem.enrollmentSpecialists = item?.enrollmentSpecialists?.value;
        }
        if (
          userPersonalizedFields?.includes("paymentTerms") &&
          typeof item.paymentTerms !== "string"
        ) {
          academyCandidateItem.paymentTerms = item?.paymentTerms?.value;
        }
        if (userPersonalizedFields?.includes("currency") && typeof item.currency !== "string") {
          academyCandidateItem.currency = item?.currency?.value;
        }
        if (userPersonalizedFields?.includes("country") && typeof item.country !== "string") {
          academyCandidateItem.country = item?.country?.name;
        }
        if (userPersonalizedFields?.includes("status") && typeof item.status !== "string") {
          academyCandidateItem.status = item?.status?.value;
        }
        if (userPersonalizedFields?.includes("course") && typeof item.course !== "string") {
          academyCandidateItem.course = item?.course?.name;
        }
        if (
          userPersonalizedFields?.includes("graduationDegree") &&
          typeof item.graduationDegree !== "string"
        ) {
          academyCandidateItem.graduationDegree = item?.graduationDegree?.value;
        }
        if (
          userPersonalizedFields?.includes("graduationMajor") &&
          typeof item.graduationMajor !== "string"
        ) {
          academyCandidateItem.graduationMajor = item?.graduationMajor?.value;
        }
        if (userPersonalizedFields?.includes("source") && typeof item.source !== "string") {
          academyCandidateItem.source = item?.source?.value;
        }
        if (
          userPersonalizedFields?.includes("admissionCounsellor") &&
          typeof item.admissionCounsellor !== "string"
        ) {
          academyCandidateItem.admissionCounsellor = item?.admissionCounsellor?.name;
        }

        if (userPersonalizedFields?.includes("createdAt")) {
          academyCandidateItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          academyCandidateItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          academyCandidateItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          academyCandidateItem.updatedBy = item?.updatedBy?.name || "Admin";
        }

        delete academyCandidateItem?._id;
        return academyCandidateItem;
      });
      const academyCandidateLeadsFields = tablePublicFields.academyCandidateLeads;
      const columnHeaders = academyCandidateLeadsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "candidate_leads");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.academyCandidateLeads?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Academy Candidate Leads Success/Error Methods ================

  const academyCandidateLeadsFetchSuccess = useCallback(
    (data: AcademyCandidateLeadsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const academyCandidateLeadsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );
  // ================ Fetch Academy Candidate Leads Method ================

  const fetchAcademyCandidateLeads = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchAcademyCandidateLeadsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.academyCandidateLeads?.limit || 10;

      const params: FetchAcademyCandidateLeadsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };

      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type) {
        params["relatedListType"] = relatedList?.type;
        params["relatedListValue"] = relatedList?.value;
      }
      const payload = {
        params,
        onSuccess: (data) => academyCandidateLeadsFetchSuccess(data, exportAll),
        onError: academyCandidateLeadsFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchAcademyCandidateLeadsList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.academyCandidateLeads?.limit,
      sortKey,
      sortType,
      relatedList,
      academyCandidateLeadsFetchError,
      dispatch,
      widgetsData,
      academyCandidateLeadsFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = academyCandidateLeads?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, academyCandidateLeads, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchAcademyCandidateLeads({ exportAll: true });
  }, [fetchAcademyCandidateLeads]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchAcademyCandidateLeads({ page });
  }, [fetchAcademyCandidateLeads, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchAcademyCandidateLeads({ page: parseInt(page), search });
    },
    [fetchAcademyCandidateLeads, search],
  );

  // ================ Add/Edit Methods ================

  const onClickAcademyCandidateLeads = useCallback(
    (id: string) => {
      if (permissions?.academyCandidateLeads_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.academyCandidateLeads_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchAcademyCandidateLeads({ page: 1, search });
  }, [fetchAcademyCandidateLeads, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchAcademyCandidateLeads({ page: 1 });
  }, [fetchAcademyCandidateLeads]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchAcademyCandidateLeads({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchAcademyCandidateLeads],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchAcademyCandidateLeads({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchAcademyCandidateLeads],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = academyCandidateLeads?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteAcademyCandidateLeadsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchAcademyCandidateLeads({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteAcademyCandidateLeads(payload));
  }, [selectedRows, academyCandidateLeads, dispatch, notificationsId, fetchAcademyCandidateLeads]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allCandidateLeads = academyCandidateLeads?.map((item) => item?._id);
    return isEqualArrays(allCandidateLeads, selectedRows);
  }, [selectedRows, academyCandidateLeads]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allCandidateLeads: string[] = [];
            academyCandidateLeads?.forEach((item) => {
              if (item?._id) {
                allCandidateLeads.push(item?._id);
              }
            });
            setSelectedRows(allCandidateLeads);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, academyCandidateLeads, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      setLoading(true);
      const academyCandidateLeadsConfigLimit =
        tableConfiguration?.academyCandidateLeads?.limit || 10;
      const academyCandidateLeadsConfigs = {
        table: "academyCandidateLeads",
        fields: fields,
        limit: academyCandidateLeadsConfigLimit,
      };
      const payload = {
        tableConfiguration: academyCandidateLeadsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidateLeads({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Acandemy Candidate Leads table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      tableConfiguration?.academyCandidateLeads?.limit,
      dispatch,
      fetchAcademyCandidateLeads,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const academyCandidateLeadsConfigFields = tableConfiguration?.academyCandidateLeads?.fields;
      dispatch(showInAppLoader(true));
      const academyCandidateLeadsConfigs = {
        table: "academyCandidateLeads",
        fields: academyCandidateLeadsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: academyCandidateLeadsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidateLeads({ limit: count });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Academy Candidate Leads table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchAcademyCandidateLeads],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = academyCandidateLeads?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidateLeads({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Candidate Lead updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating candidate lead",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateAcademyCandidateLeads(payload));
    },
    [selectedRows, academyCandidateLeads, dispatch, notificationsId, fetchAcademyCandidateLeads],
  );

  // ================ Table Header Buttons ================

  const academyCandidateLeadsHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "associatedContact",
      label: "Associated Contact",
      options: academyCandidatesOptions,
      required: true,
    },
    {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
    },
    {
      name: "status",
      label: "Status",
      options: getAttributValues("academyCandidateStatus"),
      required: true,
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
    },
    {
      name: "stage",
      label: "Stage",
      options: getAttributValues("academyCandidateLeadStages"),
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      required: true,
    },
    {
      name: "dob",
      label: "D.O.B",
      type: "date",
      required: true,
    },
    {
      name: "currency",
      label: "Currency",
      options: currencyOptions,
    },
    {
      name: "course",
      label: "Course Intrested",
      options: academyCoursesOptions,
      required: true,
    },
    {
      name: "source",
      label: "Source",
      options: getAttributValues("academyCandidateSource"),
      required: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
    },
    {
      name: "state",
      label: "State/Province",
      type: "text",
      required: true,
    },
    {
      name: "country",
      label: "Country/Region",
      options: countryOptions,
      required: true,
    },
    {
      name: "graduationYear",
      label: "Graduation Year",
      type: "text",
    },
    {
      name: "graduationDegree",
      label: "Graduation Degree",
      options: getAttributValues("academyCandidateDegree"),
    },
    {
      name: "graduationMajor",
      label: "Graduation Major",
      options: getAttributValues("academyCandidateGraduationMajor"),
    },
    {
      name: "linkedInURL",
      label: "LinkedIn URL",
      type: "text",
    },
    {
      name: "sourceContactDate",
      label: "Source Contact Date",
      type: "date",
    },
    {
      name: "saleDate",
      label: "Sale Date",
      type: "date",
    },
    {
      name: "admissionCounsellor",
      label: "Admission Counsellor",
      options: employeesOptions,
    },
    {
      name: "enrollmentSpecialists",
      label: "Enrollment Specialists",
      options: employeesOptions,
    },
    {
      name: "totalFees",
      label: "Total Fees",
      type: "text",
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      options: getAttributValues("academyPaymentTerms"),
    },
    {
      name: "anyDiscountsGiven",
      label: "Any Discounts Given",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const academyCandidateLeadsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const statusOptions = getFilterOptions(getAttributValues("academyCandidateStatus"), "Status");
    const sourceOptions = getFilterOptions(getAttributValues("academyCandidateSource"), "Source");
    const paymentOptions = getFilterOptions(getAttributValues("academyPaymentTerms"), "Payment");
    const courseOptions = getFilterOptions(academyCoursesOptions, "Course");
    const assosiateContactOptions = getFilterOptions(academyCandidatesOptions, "Assosiate Contact");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const countryFilterOptions = getFilterOptions(countryOptions, "Country");
    const currencyFilterOptions = getFilterOptions(currencyOptions, "Country");
    const graduationDegreeOptions = getFilterOptions(
      getAttributValues("academyCandidateDegree"),
      "Graducation Degree",
    );
    const graduationMajorOptions = getFilterOptions(
      getAttributValues("academyCandidateGraduationMajor"),
      "Graducation Major",
    );

    const stageOptions = getFilterOptions(getAttributValues("academyCandidateLeadStages"), "Stage");

    const fields: Field[] = [
      {
        name: "associatedContact",
        label: "Associated Contact",
        valueEditorType: "select",
        values: assosiateContactOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "firstName",
        label: "First Name",
        placeholder: "Enter first name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter last name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "status",
        label: "Status",
        valueEditorType: "select",
        values: statusOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "course",
        label: "Course",
        valueEditorType: "select",
        values: courseOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "dob",
        label: "DOB",
        inputType: "date",
        operators: defaultOperators.filter((op) => ["=", ">", "<"].includes(op.name)),
      },
      {
        name: "admissionCounsellor",
        label: "Admission Counsellor",
        valueEditorType: "select",
        values: employeeyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "source",
        label: "Source",
        valueEditorType: "select",
        values: sourceOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "currency",
        label: "Currency",
        valueEditorType: "select",
        values: currencyFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "city",
        label: "City",
        placeholder: "Enter City",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "state",
        label: "State",
        placeholder: "Enter State",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "graduationDegree ",
        label: "Graduation Degree",
        valueEditorType: "select",
        values: graduationDegreeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "graduationMajor ",
        label: "Graduation Major",
        valueEditorType: "select",
        values: graduationMajorOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "otherDegree",
        label: "Other Degree",
        placeholder: "Enter otherDegree",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "linkedInURL",
        label: "LinkedIn URL",
        placeholder: "Enter LinkedIn URL",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "sourceContactDate",
        label: "Source Contact Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => ["=", ">", "<"].includes(op.name)),
      },
      {
        name: "saleDate",
        label: "Sale Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => ["=", ">", "<"].includes(op.name)),
      },
      {
        name: "enrollmentSpecialists ",
        label: "Enrollment Specialists",
        valueEditorType: "select",
        values: employeeyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "totalFees",
        label: "Total Fees",
        placeholder: "Enter Total Fees",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "paymentTerms ",
        label: "Payment Terms",
        valueEditorType: "select",
        values: paymentOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "anyDiscountsGiven",
        label: "Any Discounts Given",
        placeholder: "Enter Any Discounts Given",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "graduationYear",
        label: "Graduation Year",
        placeholder: "Enter graduation year",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "phone",
        label: "Phone",
        placeholder: "Enter phone",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "email",
        label: "Email",
        placeholder: "Enter email",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "stage",
        label: "Stage",
        valueEditorType: "select",
        values: stageOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [
    academyCandidatesOptions,
    academyCoursesOptions,
    countryOptions,
    currencyOptions,
    employeesOptions,
    usersOptions,
  ]);

  return {
    onClickAcademyCandidateLeads,
    academyCandidateLeadsFilterFields,
    fetchAcademyCandidateLeads,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    listActionOptions,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    columns,
    academyCandidateLeadsTableConfigFields,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyCandidateLeadsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
  };
};
