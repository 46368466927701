import React, { memo, useCallback } from "react";

import { motion } from "framer-motion";

import { removeAppNotification } from "../../../../redux";
import { useAppDispatch } from "../../../../redux/hooks";
import { images } from "../../../../theme";
import { AppNotificationsType } from "../../../../types";

import { CloseIcon, NotificationTitle } from "./styles";

const notificationVariants = {
  initial: {
    opacity: 0,
    y: 50,
    scale: 0.2,
    transition: { duration: 0.1 },
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.2,
    transition: { ease: "easeOut", duration: 0.15 },
  },
  hover: { scale: 1.05, transition: { duration: 0.1 } },
};

interface Props {
  notification: AppNotificationsType;
}

export const Notification = memo(({ notification }: Props) => {
  const { title, type } = notification;

  const dispatch = useAppDispatch();
  const handleClose = useCallback(
    () => dispatch(removeAppNotification({ notification })),
    [dispatch, notification],
  );

  return (
    <motion.li
      className={`app-notification-container ${type}`}
      variants={notificationVariants}
      whileHover='hover'
      initial='initial'
      animate='animate'
      exit='exit'
    >
      <NotificationTitle className='notification-text'>
        {typeof title === "string" ? title : ""}
      </NotificationTitle>
      <CloseIcon src={images.crossIcon} onClick={handleClose} />
    </motion.li>
  );
});
