/* eslint-disable no-unused-vars */
enum Types {
  FETCH_RESOURCES = "FETCH_RESOURCES",
  FETCH_RESOURCES_BY_ID = "FETCH_RESOURCES_BY_ID",
  SUCCESS_FETCH_RESOURCES = "SUCCESS_FETCH_RESOURCES",
  ERROR_FETCH_RESOURCES = "ERROR_FETCH_RESOURCES",
  CREATE_RESOURCES = "CREATE_RESOURCES",
  UPDATE_RESOURCES = "UPDATE_RESOURCES",
  DELETE_RESOURCES = "DELETE_RESOURCES",
}

export default Types;
