import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  PlatformModulesList,
  AddPlatformModules,
  UpdatePlatformModules,
  FindPlatformModules,
  DeletePlatformModules,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchPlatformModulesListSuccess, fetchPlatformModulesError } from "./actions";
import Types from "./actionType";
import {
  CreatePlatformModulesuccess,
  CreatePlatformModules as CreatePlatformModulesType,
  PlatformModulesSuccess,
  UpdatePlatformModules as UpdatePlatformModulesType,
  FetchPlatformModules,
  FetchPlatformModulesById,
  PlatformModulesByIdSuccess,
  DeletePlatformModules as DeletePlatformModulesType,
} from "./types";

function* createWorker(action: CreatePlatformModulesType) {
  try {
    const options = {
      method: "POST",
      url: AddPlatformModules,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreatePlatformModulesuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdatePlatformModulesType) {
  try {
    const options = {
      method: "PUT",
      url: UpdatePlatformModules(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreatePlatformModulesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchPlatformModules) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: PlatformModulesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<PlatformModulesSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchPlatformModulesListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchPlatformModulesError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching SPS Paperwork");
    }
  }
}

function* fetchPlatformModulesById(action: FetchPlatformModulesById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindPlatformModules(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<PlatformModulesByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching SPS Paperwork");
    }
  }
}

function* deleteWorker(action: DeletePlatformModulesType) {
  try {
    const options = {
      method: "DELETE",
      url: DeletePlatformModules(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreatePlatformModulesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_PLATFORM_MODULES, createWorker);
  yield takeLatest(Types.UPDATE_PLATFORM_MODULES, updateWorker);
  yield takeEvery(Types.FETCH_PLATFORM_MODULES, fetchAllWorker);
  yield takeLatest(Types.DELETE_PLATFORM_MODULES, deleteWorker);
  yield takeEvery(Types.FETCH_PLATFORM_MODULES_BY_ID, fetchPlatformModulesById);
}
