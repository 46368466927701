import React, { memo } from "react";

import { Layout } from "../../../presentationals";
import { SPSInsuranceController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSInsuranceController type={"edit"} />} admin={true} layout={"2"} />;
};

export const SPSInsuranceEdit = React.memo(Route);
