import React, { useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import UCBConfig from "../../../../package.json";
import { updateEPAccordion } from "../../../redux/application/actions";
import { doLogout } from "../../../redux/user/actions";
import { images, strings } from "../../../theme";
import Search from "../../components/Search";

import {
  Container,
  OrgImageContainer,
  Version,
  OrgImage,
  Separator,
  ActionsContainer,
  BasicActionItemContainer,
  NavigationLink,
  Icon,
  TextHolder,
  Accordion,
  AccordionContainer,
  AccordionChildContainer,
  AccordionLine,
  SearchContainer,
} from "./styles";

const defaultOrg = require("../../../assets/images/unity.png");

const employeeActions = [
  {
    label: "Home",
    icon: images.epHome,
    activeIcon: images.epHomeActive,
    link: "/ep",
    end: true,
  },
  {
    label: "Salary Slips",
    icon: images.epSalary,
    activeIcon: images.epSalaryActive,
    link: "/ep/payrolls",
  },
  {
    label: "To do list",
    icon: images.epTodo,
    activeIcon: images.epTodoActive,
    link: "/ep/todo",
  },
];
const employeeAccordions = [
  {
    label: "KNOWLEDGE",
    icon: images.epKnowledge,
    activeIcon: images.epKnowledgeActive,
    link: "/ep/knowledge",
    child: [
      {
        label: "Create Article",
        link: "/add",
      },
      {
        label: "My Articles",
        link: "/owner",
        search: "?type=owner",
      },
      {
        label: "Dashboard",
        link: "/dashboard",
      },
      {
        label: "Published",
        link: "/published",
        search: "?type=published",
      },
      {
        label: "Approvals",
        link: "/approvals",
        search: "?type=approvals",
      },
      {
        label: "All Articles",
        link: "/all",
        search: "?type=all",
      },
    ],
  },
  {
    label: "SERVICE TICKETS",
    icon: images.epTickets,
    activeIcon: images.epTicketsActive,
    link: "/ep/tickets",
    child: [
      {
        label: "All",
        link: "/all",
      },
      {
        label: "Created",
        link: "/created",
      },
      {
        label: "Assigned",
        link: "/assigned",
      },
    ],
  },
];
const Employee = (props) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const [epActions, setEPActions] = useState(employeeActions);
  const [epAccordions, setEPAccordions] = useState(employeeAccordions);

  const handleAccordion = (e) => {
    const index = e.target.getAttribute("data-index");
    const link: string = index >= 0 ? employeeAccordions[index]?.label : "";
    const epAccordion = props.epAccordion;
    console.log(index, link, epAccordion);
    if (epAccordion === link) {
      props.updateEPAccordion({
        data: "",
      });
    } else {
      props.updateEPAccordion({
        data: link,
      });
    }
  };

  const getAccordionRightBorder = (count) => {
    let height = 100 / count;
    height = height * (count - 0.5);
    return `${height}%`;
  };

  const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filteredActions = employeeActions.filter((item) => {
      const label = item.label.toUpperCase();
      return label.includes(value.toUpperCase());
    });
    const filteredAccordions = employeeAccordions.map((element) => {
      return {
        ...element,
        child: element.child.filter((subElement) =>
          subElement.label.toUpperCase().includes(value.toUpperCase()),
        ),
      };
    });

    if (value) {
      setEPActions(filteredActions);
      setEPAccordions(filteredAccordions);
    } else {
      setEPActions(employeeActions);
      setEPAccordions(employeeAccordions);
    }
  };

  return (
    <Container>
      {/* <OrgImageContainer>
        <OrgImage src={props?.OrgImage || defaultOrg} />
      </OrgImageContainer>
      <Separator /> */}
      <SearchContainer>
        <Search
          iconClass={"navigation-search-icon"}
          inputProps={{
            placeholder: strings.common.search,
            onChange: onSearch,
          }}
        />
      </SearchContainer>
      <ActionsContainer>
        <BasicActionItemContainer>
          {epActions.map((item, index) => {
            return (
              <NavigationLink
                key={index}
                to={item.link}
                active={path === item.link}
                activeclassname='active'
                end={item.end}
              >
                <Icon src={path === item.link ? item.activeIcon : item.icon} />
                <TextHolder>{item.label}</TextHolder>
              </NavigationLink>
            );
          })}
          <Separator top='12%' />
          {epAccordions.map((item, index) => {
            if (item.child.length > 0) {
              return (
                <AccordionContainer key={index}>
                  <Accordion
                    onClick={handleAccordion}
                    data-index={index}
                    active={props.epAccordion === item.label}
                  >
                    <Icon src={props.epAccordion === item.label ? item.activeIcon : item.icon} />
                    <TextHolder onClick={handleAccordion} data-index={index}>
                      {item.label}
                    </TextHolder>
                    <Icon
                      onClick={handleAccordion}
                      data-index={index}
                      active={props.epAccordion === item.label}
                      small={true}
                      className='arrow'
                      src={images.arrowRight}
                    />
                  </Accordion>
                  {item.child.length > 0 && props.epAccordion === item.label && (
                    <AccordionChildContainer>
                      <AccordionLine height={getAccordionRightBorder(item.child.length)} />
                      {item.child.map((child, childIndex) => {
                        let link = item.link + child.link;
                        if (child.search) {
                          link += child.search;
                        }
                        return (
                          <NavigationLink
                            key={childIndex}
                            to={link}
                            active={path === link}
                            activeclassname='active'
                            left='0'
                          >
                            <Icon
                              src={path === link ? images.accordionActive : images.accordionIcon}
                            />
                            <TextHolder>{child.label}</TextHolder>
                          </NavigationLink>
                        );
                      })}
                    </AccordionChildContainer>
                  )}
                </AccordionContainer>
              );
            }
          })}
        </BasicActionItemContainer>
      </ActionsContainer>
      <Version>V {UCBConfig.version}</Version>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  OrgImage: state.UserReducer?.organisation?.orgImage?.location,
  permissions: state.UserReducer.permissions,
  epAccordion: state.ApplicationReducer.epAccordion,
  apAccordion: state.ApplicationReducer.apAccordion,
});

const mapDispatchToProps = {
  doLogout,
  updateEPAccordion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
