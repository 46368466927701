import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createResources,
  fetchResourcesById,
  fetchControllerConfigs,
  removeAppNotification,
  updateResources,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  ResourcesList,
  CreateResourcesPayload,
  FetchResourcesByIdPayload,
  UpdateResourcesPayload,
  ResourcesSection,
  ResourcesStats,
  CreateResourcesData,
} from "../../../../../redux/Resources/types";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  ResourcesForm,
  ResourcesSectionsForm,
  ResourcesStatsForm,
  useResourcesControllerProps,
  useResourcesControllerReturnType,
} from "../../../../../types/models/Resources";

// ================ Contstants ================

const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "name",
  "shortDescription",
  "active",
  "state",
  "listImage",
  "downloadAvailable",
  "downloadLabel",
  "publishedDate",
  "type",
  "whitepaperTitle",
  "whitepaperAttachment",
  "whitepaperImage",
  "blogAttachment",
  "clientLogo",
  "caseStudyTagline",
  "caseStudyBanner",
  "statsIncluded",
  "caseStudyAttachment",
  "caseStudyClientDetails",
  "clientName",
  "clientHeadquater",
  "clientIndustry",
  "clientProductType",
  "clientEmployees",
];
const blogsFields = ["publisherName"];
export const useResourcesController = ({
  type,
  id,
  attachmentsPickerRef,
}: useResourcesControllerProps): useResourcesControllerReturnType => {
  const defaultAttachment = useMemo(() => {
    return {
      id: "",
      name: "",
    };
  }, []);
  const defaultFormData: ResourcesForm = {
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    name: {
      value: "",
      required: false,
      error: "",
    },
    tags: {
      value: "",
      required: false,
      error: "",
    },
    shortDescription: {
      value: "",
      required: false,
      error: "",
    },
    active: {
      value: false,
      required: false,
      error: "",
    },
    state: {
      value: "",
      required: false,
      error: "",
    },
    listImage: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    coverImage: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    publisherImage: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    downloadAvailable: {
      value: false,
      required: false,
      error: "",
    },
    downloadLabel: {
      value: "",
      required: false,
      error: "",
    },
    publishedDate: {
      value: undefined,
      required: false,
      error: "",
    },
    type: {
      value: "whitepaper",
      required: false,
      error: "",
    },
    vertical: {
      value: "",
      required: false,
      error: "",
    },
    whitepaperTitle: {
      value: "",
      required: false,
      error: "",
    },
    whitepaperAttachment: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    whitepaperImage: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    publisherName: {
      value: "",
      required: false,
      error: "",
    },
    readTime: {
      value: "",
      required: false,
      error: "",
    },
    sections: {
      value: [],
      required: false,
      error: "",
    },
    blogAttachment: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    clientLogo: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    caseStudyTagline: {
      value: "",
      required: false,
      error: "",
    },
    caseStudyBanner: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    statsIncluded: {
      value: false,
      required: false,
      error: "",
    },
    stats: {
      value: [],
      required: false,
      error: "",
    },
    caseStudyAttachment: {
      value: defaultAttachment,
      required: false,
      error: "",
    },
    caseStudyClientDetails: {
      value: false,
      required: false,
      error: "",
    },
    clientName: {
      value: "",
      required: false,
      error: "",
    },
    clientHeadquater: {
      value: "",
      required: false,
      error: "",
    },
    clientIndustry: {
      value: "",
      required: false,
      error: "",
    },
    clientProductType: {
      value: "",
      required: false,
      error: "",
    },
    clientEmployees: {
      value: "",
      required: false,
      error: "",
    },
    imageVideoPosition: {
      value: "right",
      required: false,
      error: "",
    },
  };

  const defaultSectionData = useMemo(
    () => ({
      sectionImageLink: {
        value: defaultAttachment,
        required: false,
        error: "",
      },
      sectionVideoLink: {
        value: "",
        required: false,
        error: "",
      },
      sectionAttachment: {
        value: "",
        required: false,
        error: "",
      },
      imageVideoPosition: {
        value: "right",
        required: false,
        error: "",
      },
      quote: {
        value: "",
        required: false,
        error: "",
      },
      _id: "",
    }),
    [defaultAttachment],
  );

  const defaultStatsData = useMemo(
    () => ({
      title: {
        value: "",
        required: false,
        error: "",
      },
      tagline: {
        value: "",
        required: false,
        error: "",
      },
      _id: "",
    }),
    [],
  );
  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ResourcesForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<ResourcesList>({});
  // White paper
  const [whitepaperdescription, setwhitePaperDescription] = useState("");

  // Section states
  const [sectionsData, setSectionsData] = useState<ResourcesSectionsForm>(defaultSectionData);
  const [sectionsModal, setSectionsModal] = useState<"add" | "edit" | "">("");
  const [currentSectionId, setCurrentSectionId] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionValidation, setDescriptionValidation] = useState<boolean>(false);
  // Stats states
  const [caseStudyDescription, setCaseStudyDescription] = useState("");
  const [statsData, setStatsData] = useState<ResourcesStatsForm>(defaultStatsData);
  const [statsModal, setStatsModal] = useState<"add" | "edit" | "">("");
  const [currentStatsId, setCurrentStatsId] = useState("");
  const addFormSteps = useMemo(() => {
    if (formData.type.value === "blogs") {
      return ["Overview", "Blogs"];
    } else if (formData.type.value === "caseStudies") {
      return ["Overview", "Case Studies"];
    } else if (formData.type.value === "whitepaper") {
      return ["Overview", "Whitepaper"];
    } else {
      return ["Overview"];
    }
  }, [formData?.type?.value]);
  const editFormSteps = useMemo(() => {
    if (formData.type?.value === "blogs") {
      return [
        "Overview",
        "Blogs",
        "Blogs-Sections",
        "Checklist",
        "Activity Logs",
        "Worklogs",
        "System Data",
      ];
    } else if (formData.type.value === "caseStudies") {
      return [
        "Overview",
        "Case Studies",
        "Case Studies-Stats",
        "Checklist",
        "Activity Logs",
        "Worklogs",
        "System Data",
      ];
    } else if (formData.type.value === "whitepaper") {
      return ["Overview", "Whitepaper", "Checklist", "Activity Logs", "Worklogs", "System Data"];
    } else {
      return ["Overview", "Checklist", "Activity Logs", "Worklogs", "System Data"];
    }
  }, [formData.type.value]);

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [addFormSteps, editFormSteps, type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0: {
          overViewFields.forEach((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        }
        case 1:
          if (formData.type.value === "blogs")
            blogsFields.forEach((field) => {
              if (formData[field].error) {
                count += 1;
              }
            });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData;
      if (name === "downloadAvailable") {
        tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
          downloadLabel: {
            ...formData["downloadLabel"],
            value: "",
            error: "",
          },
        };
      }
      if (name === "type") {
        switch (value) {
          case "whitepaper": {
            setDescription("");
            setCaseStudyDescription("");
            tempFormData = {
              ...formData,
              [name]: {
                ...formData[name],
                value,
                error: "",
              },
              publisherName: {
                value: "",
                required: false,
                error: "",
              },
              readTime: {
                value: "",
                required: false,
                error: "",
              },
              blogAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              sections: {
                value: [],
                required: false,
                error: "",
              },
              clientLogo: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              caseStudyTagline: {
                value: "",
                required: false,
                error: "",
              },
              caseStudyBanner: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              statsIncluded: {
                value: false,
                required: false,
                error: "",
              },
              stats: {
                value: [],
                required: false,
                error: "",
              },
              caseStudyAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              caseStudyClientDetails: {
                value: false,
                required: false,
                error: "",
              },
              clientName: {
                value: "",
                required: false,
                error: "",
              },
              clientHeadquater: {
                value: "",
                required: false,
                error: "",
              },
              clientIndustry: {
                value: "",
                required: false,
                error: "",
              },
              clientProductType: {
                value: "",
                required: false,
                error: "",
              },
              clientEmployees: {
                value: "",
                required: false,
                error: "",
              },
            };
            break;
          }
          case "caseStudies": {
            setDescription("");
            setwhitePaperDescription("");
            tempFormData = {
              ...formData,
              [name]: {
                ...formData[name],
                value,
                error: "",
              },
              whitepaperTitle: {
                value: "",
                required: false,
                error: "",
              },
              whitepaperAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              publisherName: {
                value: "",
                required: false,
                error: "",
              },
              readTime: {
                value: "",
                required: false,
                error: "",
              },
              blogAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              sections: {
                value: [],
                required: false,
                error: "",
              },
            };
            break;
          }
          case "blogs": {
            setCaseStudyDescription("");
            setwhitePaperDescription("");
            tempFormData = {
              ...formData,
              [name]: {
                ...formData[name],
                value,
                error: "",
              },
              publisherName: {
                value: "",
                required: true,
                error: "",
              },
              readTime: {
                value: "",
                required: false,
                error: "",
              },
              whitepaperTitle: {
                value: "",
                required: false,
                error: "",
              },
              whitepaperAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              clientLogo: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              caseStudyTagline: {
                value: "",
                required: false,
                error: "",
              },
              caseStudyBanner: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              statsIncluded: {
                value: false,
                required: false,
                error: "",
              },
              stats: {
                value: [],
                required: false,
                error: "",
              },
              caseStudyAttachment: {
                value: defaultAttachment,
                required: false,
                error: "",
              },
              caseStudyClientDetails: {
                value: false,
                required: false,
                error: "",
              },
              clientName: {
                value: "",
                required: false,
                error: "",
              },
              clientHeadquater: {
                value: "",
                required: false,
                error: "",
              },
              clientIndustry: {
                value: "",
                required: false,
                error: "",
              },
              clientProductType: {
                value: "",
                required: false,
                error: "",
              },
              clientEmployees: {
                value: "",
                required: false,
                error: "",
              },
            };
            break;
          }
        }
      } else {
        tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [defaultAttachment, formData],
  );

  const rehydrateFormData = useCallback(
    (resources: ResourcesList) => {
      const item = resources;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      setCaseStudyDescription(resources?.caseStudyDescription || "");
      setwhitePaperDescription(resources?.whitepaperDescription || "");
      Object.keys(formData).forEach((field) => {
        let value = item?.[field];
        if (["state"].includes(field)) {
          const tempValue = value?._id;
          value = tempValue;
        }
        if (field === "type" && item[field] === "blogs") {
          data.publisherName.required = true;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }
        if (
          field === "listImage" ||
          field === "coverImage" ||
          field === "publisherImage" ||
          field === "whitepaperAttachment" ||
          field === "whitepaperImage" ||
          field === "blogAttachment" ||
          field === "clientLogo" ||
          field === "caseStudyBanner" ||
          field === "caseStudyAttachment"
        ) {
          if (!resources[field]) {
            value = defaultAttachment;
          }
        }
        data[field].value = value;
      });
      setFormData(data);
    },
    [defaultAttachment, formData],
  );

  const fetchResources = useCallback(() => {
    setLoading(true);
    const payload: FetchResourcesByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchResourcesById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(
    (section = "overview") => {
      let tempFormData;
      if (section === "overview") {
        tempFormData = { ...formData };
      } else if (section === "section") {
        tempFormData = { ...sectionsData };
      }
      let valid = true;
      Object.keys(tempFormData).forEach((item) => {
        const currentField = tempFormData[item];
        let validValue = currentField.value;

        // Trim the value if it's a string
        if (typeof validValue === "string") {
          validValue = validValue.trim();
        }

        // Check for required fields and non-empty values
        if (currentField.required && !validValue) {
          valid = false;
          tempFormData = {
            ...tempFormData,
            [item]: {
              ...tempFormData[item],
              error: "Required Field",
            },
          };
        }
        if (currentField.required && typeof validValue === "object") {
          if (!validValue?.id) {
            valid = false;
            tempFormData = {
              ...tempFormData,
              [item]: {
                ...tempFormData[item],
                error: "Required Field",
              },
            };
          }
        }
        if (item === "sectionVideoLink" && validValue && !validator.isURL(validValue || "")) {
          valid = false;
          tempFormData = {
            ...tempFormData,
            [item]: {
              ...tempFormData[item],
              error: "Please enter valid URL",
            },
          };
        }
      });
      if (section === "overview") {
        setFormData(tempFormData);
      } else if (section === "section") {
        setSectionsData(tempFormData);
      }
      return valid;
    },
    [formData, sectionsData],
  );

  const onSubmitAdd = useCallback(() => {
    const {
      name,
      tags,
      shortDescription,
      active,
      state,
      downloadAvailable,
      downloadLabel,
      publishedDate,
      type,
      vertical,
      whitepaperTitle,
      publisherName,
      readTime,
      blogAttachment,
      clientLogo,
      caseStudyTagline,
      caseStudyBanner,
      statsIncluded,
      caseStudyAttachment,
      caseStudyClientDetails,
      clientName,
      clientHeadquater,
      clientIndustry,
      clientProductType,
      clientEmployees,
      listImage,
      coverImage,
      publisherImage,
      whitepaperAttachment,
      whitepaperImage,
      imageVideoPosition,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));

      const listImageValue: Attachments = {
        id: listImage.value.id || null,
        name: listImage.value.name || "",
        extension: listImage.value.extension || "",
      };
      const coverImageValue: Attachments = {
        id: coverImage.value.id || null,
        name: coverImage.value.name || "",
        extension: coverImage.value.extension || "",
      };
      const publisherImageValue: Attachments = {
        id: publisherImage.value.id || null,
        name: publisherImage.value.name || "",
        extension: publisherImage.value.extension || "",
      };
      const whitepaperAttachmentValue = {
        id: whitepaperAttachment.value.id || null,
        name: whitepaperAttachment.value.name || "",
        extension: whitepaperAttachment.value.extension || "",
      };
      const whitepaperImageValue = {
        id: whitepaperImage.value.id || null,
        name: whitepaperImage.value.name || "",
        extension: whitepaperImage.value.extension || "",
      };

      const blogAttachmentValue = {
        id: blogAttachment.value.id || null,
        name: blogAttachment.value.name || "",
        extension: blogAttachment.value.extension || "",
      };

      const clientLogoValue = {
        id: clientLogo.value.id || null,
        name: clientLogo.value.name || "",
        extension: clientLogo.value.extension || "",
      };
      const caseStudyBannerValue = {
        id: caseStudyBanner.value.id || null,
        name: caseStudyBanner.value.name || "",
        extension: caseStudyBanner.value.extension || "",
      };
      const caseStudyAttachmentValue = {
        id: caseStudyAttachment.value.id || null,
        name: caseStudyAttachment.value.name || "",
        extension: caseStudyAttachment.value.extension || "",
      };
      let data: CreateResourcesData = {
        tags: tags.value,
        attachments: attachments,
        name: name.value,
        shortDescription: shortDescription.value,
        active: active.value,
        state: state.value,
        listImage: listImageValue,
        downloadAvailable: downloadAvailable.value,
        downloadLabel: downloadLabel.value,
        publishedDate: publishedDate.value,
        type: type.value,
        vertical: vertical.value,
        publisherImage: publisherImageValue,
      };
      if (type.value === "whitepaper") {
        data = {
          ...data,
          whitepaperTitle: whitepaperTitle.value,
          whitepaperAttachment: whitepaperAttachmentValue,
          whitepaperImage: whitepaperImageValue,
          whitepaperDescription: whitepaperdescription,
          coverImage: coverImageValue,
          imageVideoPosition: imageVideoPosition.value,
        };
      }
      if (type.value === "blogs") {
        data = {
          ...data,
          publisherName: publisherName.value,
          readTime: readTime.value,
          blogAttachment: blogAttachmentValue,
          coverImage: coverImageValue,
        };
      }
      if (type.value === "caseStudies") {
        data = {
          ...data,
          clientLogo: clientLogoValue,
          caseStudyTagline: caseStudyTagline.value,
          caseStudyBanner: caseStudyBannerValue,
          statsIncluded: statsIncluded.value,
          caseStudyAttachment: caseStudyAttachmentValue,
          caseStudyClientDetails: caseStudyClientDetails.value,
          clientName: clientName.value,
          clientHeadquater: clientHeadquater.value,
          clientIndustry: clientIndustry.value,
          clientProductType: clientProductType.value,
          clientEmployees: clientEmployees.value,
          caseStudyDescription,
        };
      }
      const payload: CreateResourcesPayload = {
        data,
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Resources created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createResources(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    whitepaperdescription,
    caseStudyDescription,
    navigate,
  ]);

  const onSubmitUpdate = useCallback(() => {
    const {
      name,
      tags,
      shortDescription,
      active,
      state,
      downloadAvailable,
      downloadLabel,
      publishedDate,
      type,
      vertical,
      whitepaperTitle,
      publisherName,
      readTime,
      blogAttachment,
      clientLogo,
      caseStudyTagline,
      caseStudyBanner,
      statsIncluded,
      caseStudyAttachment,
      caseStudyClientDetails,
      clientName,
      clientHeadquater,
      clientIndustry,
      clientProductType,
      clientEmployees,
      listImage,
      coverImage,
      publisherImage,
      whitepaperAttachment,
      imageVideoPosition,
      whitepaperImage,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const listImageValue: Attachments = {
        id: listImage.value.id || null,
        name: listImage.value.name || "",
        extension: listImage.value.extension || "",
      };
      const coverImageValue: Attachments = {
        id: coverImage.value.id || null,
        name: coverImage.value.name || "",
        extension: coverImage.value.extension || "",
      };
      const publisherImageValue: Attachments = {
        id: publisherImage.value.id || null,
        name: publisherImage.value.name || "",
        extension: publisherImage.value.extension || "",
      };
      const whitepaperAttachmentValue = {
        id: whitepaperAttachment.value.id || null,
        name: whitepaperAttachment.value.name || "",
        extension: whitepaperAttachment.value.extension || "",
      };
      const whitepaperImageValue = {
        id: whitepaperImage.value.id || null,
        name: whitepaperImage.value.name || "",
        extension: whitepaperImage.value.extension || "",
      };

      const blogAttachmentValue = {
        id: blogAttachment.value.id || null,
        name: blogAttachment.value.name || "",
        extension: blogAttachment.value.extension || "",
      };
      const clientLogoValue = {
        id: clientLogo.value.id || null,
        name: clientLogo.value.name || "",
        extension: clientLogo.value.extension || "",
      };
      const caseStudyBannerValue = {
        id: caseStudyBanner.value.id || null,
        name: caseStudyBanner.value.name || "",
        extension: caseStudyBanner.value.extension || "",
      };
      const caseStudyAttachmentValue = {
        id: caseStudyAttachment.value.id || null,
        name: caseStudyAttachment.value.name || "",
        extension: caseStudyAttachment.value.extension || "",
      };

      setLoading(true);
      let data: CreateResourcesData = {
        attachments: attachments,
        tags: tags.value,
        name: name.value,
        shortDescription: shortDescription.value,
        active: active.value,
        state: state.value,
        listImage: listImageValue,
        downloadAvailable: downloadAvailable.value,
        downloadLabel: downloadLabel.value,
        publishedDate: publishedDate.value,
        type: type.value,
        vertical: vertical.value,
        publisherImage: publisherImageValue,
      };
      if (type.value === "whitepaper") {
        data = {
          ...data,
          whitepaperTitle: whitepaperTitle.value,
          whitepaperAttachment: whitepaperAttachmentValue,
          whitepaperImage: whitepaperImageValue,
          whitepaperDescription: whitepaperdescription,
          coverImage: coverImageValue,
          imageVideoPosition: imageVideoPosition.value,
        };
      }
      if (type.value === "blogs") {
        data = {
          ...data,
          publisherName: publisherName.value,
          readTime: readTime.value,
          blogAttachment: blogAttachmentValue,
          coverImage: coverImageValue,
        };
      }
      if (type.value === "caseStudies") {
        data = {
          ...data,
          clientLogo: clientLogoValue,
          caseStudyTagline: caseStudyTagline.value,
          caseStudyBanner: caseStudyBannerValue,
          statsIncluded: statsIncluded.value,
          caseStudyAttachment: caseStudyAttachmentValue,
          caseStudyClientDetails: caseStudyClientDetails.value,
          clientName: clientName.value,
          clientHeadquater: clientHeadquater.value,
          clientIndustry: clientIndustry.value,
          clientProductType: clientProductType.value,
          clientEmployees: clientEmployees.value,
          caseStudyDescription,
        };
      }
      const payload: UpdateResourcesPayload = {
        id: id || "",
        data,
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Resources updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateResources(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    id,
    whitepaperdescription,
    caseStudyDescription,
  ]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateResourcesPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateResources(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.resources, title];
  }, [type, data.name]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);
  const onSaveAttachment = useCallback(
    (data, fieldName) => {
      const attachmentsData = {
        id: data.id,
        name: data.name,
        extension: data.extension,
      };
      if (fieldName === "sectionImageLink") {
        const tempFormData = {
          ...sectionsData,
          [fieldName]: {
            value: attachmentsData,
            required: false,
            error: "",
          },
        };
        setSectionsData(tempFormData);
      } else {
        const tempFormData = {
          ...formData,
          [fieldName]: {
            ...formData[fieldName],
            value: attachmentsData,
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData, sectionsData],
  );

  const deleteAttachment = useCallback(
    (fieldName) => {
      if (fieldName === "sectionImageLink") {
        const tempFormData = {
          ...sectionsData,
          [fieldName]: {
            value: defaultAttachment,
            required: false,
            error: "",
          },
        };
        setSectionsData(tempFormData);
      } else {
        const tempFormData = {
          ...formData,
          [fieldName]: {
            ...formData[fieldName],
            value: defaultAttachment,
          },
        };
        setFormData(tempFormData);
      }
    },
    [defaultAttachment, formData, sectionsData],
  );
  // Section Functions
  const addResourcesSection = useCallback(() => {
    setLoading(true);
    const submit = validateForm("section");
    console.log(submit);
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      setLoading(false);
      return;
    }
    if (submit) {
      const sectionImageValue: Attachments = {
        id: sectionsData.sectionImageLink.value.id || null,
        name: sectionsData.sectionImageLink.value.name || "",
        extension: sectionsData.sectionImageLink.value.extension || "",
      };
      const ResourcesSection: ResourcesSection = {
        description: description,
        sectionImageLink: sectionImageValue,
        sectionVideoLink: sectionsData.sectionVideoLink.value,
        sectionAttachment: sectionsData.sectionAttachment.value,
        imageVideoPosition: sectionsData.imageVideoPosition.value,
        quote: sectionsData.quote.value,
      };
      const payload: UpdateResourcesPayload = {
        id: id || "",
        data: {
          sectionType: "add",
          sectionItems: [ResourcesSection],
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Resources added successfully",
            type: "success",
          };
          setSectionsData(defaultSectionData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsModal("");
          setSectionsData(defaultSectionData);
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateResources(payload));
    }
  }, [
    validateForm,
    notificationsId,
    dispatch,
    sectionsData.sectionImageLink.value.id,
    sectionsData.sectionImageLink.value.name,
    sectionsData.sectionImageLink.value.extension,
    sectionsData.sectionVideoLink.value,
    sectionsData.sectionAttachment.value,
    sectionsData.imageVideoPosition.value,
    sectionsData.quote.value,
    description,
    id,
    rehydrateFormData,
    defaultSectionData,
  ]);

  const deleteServiceNowSection = useCallback(
    (e: React.MouseEvent) => {
      const sectionId = e.currentTarget?.getAttribute("data-index") || "";
      setLoading(true);
      const payload: UpdateResourcesPayload = {
        id: id || "",
        data: {
          sectionType: "delete",
          sectionId: sectionId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions deleted successfully",
            type: "success",
          };
          setSectionsData(defaultSectionData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsData(defaultSectionData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateResources(payload));
    },
    [notificationsId, dispatch, id, rehydrateFormData, defaultSectionData],
  );

  const updateResourcesSection = useCallback(() => {
    setLoading(true);
    const submit = validateForm("section");
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      setLoading(false);
      return;
    }
    if (submit) {
      const sectionImageValue: Attachments = {
        id: sectionsData.sectionImageLink.value.id || null,
        name: sectionsData.sectionImageLink.value.name || "",
        extension: sectionsData.sectionImageLink.value.extension || "",
      };
      const ResourcesSection: ResourcesSection = {
        description: description,
        sectionImageLink: sectionImageValue,
        sectionVideoLink: sectionsData.sectionVideoLink.value,
        sectionAttachment: sectionsData.sectionAttachment.value,
        imageVideoPosition: sectionsData.imageVideoPosition.value,
        quote: sectionsData.quote.value,
      };
      const payload: UpdateResourcesPayload = {
        id: id || "",
        data: {
          sectionType: "update",
          sectionId: currentSectionId,
          updateSection: ResourcesSection,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Resources updated successfully",
            type: "success",
          };
          setSectionsData(defaultSectionData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsData(defaultSectionData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateResources(payload));
    }
  }, [
    validateForm,
    notificationsId,
    dispatch,
    sectionsData.sectionImageLink.value.id,
    sectionsData.sectionImageLink.value.name,
    sectionsData.sectionImageLink.value.extension,
    sectionsData.sectionVideoLink.value,
    sectionsData.sectionAttachment.value,
    sectionsData.imageVideoPosition.value,
    sectionsData.quote.value,
    description,
    id,
    currentSectionId,
    rehydrateFormData,
    defaultSectionData,
  ]);

  const handleSectionsModal = useCallback(
    (item?: string) => {
      try {
        const sectionId = typeof item === "string" ? item : "";
        switch (item) {
          case "add":
            setDescription("");
            setSectionsData(defaultSectionData);
            setSectionsModal("add");
            break;

          default:
            if (sectionId) {
              const sectionItem = formData.sections?.value.find((item) => item._id === sectionId);
              if (sectionItem?._id) {
                setCurrentSectionId(sectionItem?._id);
              }
              if (sectionItem?.description) {
                setDescription(sectionItem?.description);
              }
              if (
                sectionItem?.sectionAttachment === "sectionImageLink" &&
                sectionItem?.sectionImageLink
              ) {
                setSectionsData({
                  ...sectionsData,
                  sectionAttachment: {
                    value: `sectionImageLink`,
                    required: false,
                    error: "",
                  },
                  sectionImageLink: {
                    value: sectionItem?.sectionImageLink,
                    error: "",
                    required: false,
                  },
                  imageVideoPosition: {
                    value: sectionItem.imageVideoPosition || "right",
                    error: "",
                    required: false,
                  },
                  quote: {
                    value: sectionItem.quote || "",
                    error: "",
                    required: false,
                  },
                  _id: sectionItem?._id || "",
                });
              }
              if (
                sectionItem?.sectionAttachment === "sectionVideoLink" &&
                sectionItem?.sectionVideoLink
              ) {
                setSectionsData({
                  ...sectionsData,
                  sectionAttachment: {
                    value: `sectionVideoLink`,
                    required: false,
                    error: "",
                  },
                  sectionVideoLink: {
                    value: sectionItem?.sectionVideoLink,
                    error: "",
                    required: false,
                  },
                  imageVideoPosition: {
                    value: sectionItem.imageVideoPosition || "right",
                    error: "",
                    required: false,
                  },
                  _id: sectionItem?._id || "",
                });
              }
              if (sectionItem?.sectionAttachment === "quote") {
                setSectionsData({
                  ...sectionsData,
                  sectionAttachment: {
                    value: `quote`,
                    required: false,
                    error: "",
                  },
                  quote: {
                    value: sectionItem?.quote || "",
                    error: "",
                    required: false,
                  },
                  _id: sectionItem?._id || "",
                });
              }
              setSectionsModal("edit");
            } else {
              setSectionsModal("");
              setDescription("");
              setSectionsData(defaultSectionData);
              break;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [defaultSectionData, formData?.sections?.value, sectionsData],
  );

  const onCaseStudyDescriptionChange = useCallback((value: string) => {
    setCaseStudyDescription(value);
  }, []);
  const onWhitePaperDescriptionChange = useCallback((value: string) => {
    setwhitePaperDescription(value);
  }, []);
  const onBlogSectionDescriptionChange = useCallback((value: string) => {
    setDescriptionValidation(false);
    setDescription(value);
  }, []);
  const onSectionTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData;
      if (name === "sectionAttachment") {
        tempFormData = {
          ...sectionsData,
          [name]: {
            ...sectionsData[name],
            value,
            error: "",
          },
          sectionImageLink: {
            ...sectionsData.sectionImageLink,
            required: false,
            value: defaultAttachment,
          },
          sectionVideoLink: {
            ...sectionsData.sectionVideoLink,
            required: false,
            value: "",
          },
          quote: {
            value: "",
            required: false,
            error: "",
          },
        };
      } else {
        // Update the field as usual
        tempFormData = {
          ...sectionsData,
          [name]: {
            ...sectionsData[name],
            value,
            error: "",
          },
        };
      }

      setSectionsData(tempFormData);
    },
    [defaultAttachment, sectionsData],
  );

  const sectionHeaderButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  // Stats Functions
  const addResourcesStats = useCallback(() => {
    setLoading(true);
    const ResourcesStats: ResourcesStats = {
      tagline: statsData.tagline.value,
      title: statsData.title.value,
    };
    const payload: UpdateResourcesPayload = {
      id: id || "",
      data: {
        statType: "add",
        statItems: [ResourcesStats],
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Resources added successfully",
          type: "success",
        };
        setStatsData(defaultStatsData);
        setStatsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setStatsModal("");
        setStatsData(defaultStatsData);
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateResources(payload));
  }, [
    statsData.tagline.value,
    statsData.title.value,
    id,
    dispatch,
    rehydrateFormData,
    notificationsId,
    defaultStatsData,
  ]);

  const deleteServiceNowStats = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const sectionId = e.currentTarget?.getAttribute("data-index") || "";
      setLoading(true);
      const payload: UpdateResourcesPayload = {
        id: id || "",
        data: {
          statType: "delete",
          statId: sectionId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions deleted successfully",
            type: "success",
          };
          setStatsData(defaultStatsData);
          setStatsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setStatsData(defaultStatsData);
          setStatsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateResources(payload));
    },
    [id, dispatch, rehydrateFormData, notificationsId, defaultStatsData],
  );

  const updateResourcesStats = useCallback(() => {
    setLoading(true);
    const ResourcesStats: ResourcesStats = {
      tagline: statsData.tagline.value,
      title: statsData.title.value,
    };
    const payload: UpdateResourcesPayload = {
      id: id || "",
      data: {
        statType: "update",
        statId: currentStatsId,
        updateSection: ResourcesStats,
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Resources updated successfully",
          type: "success",
        };
        setStatsData(defaultStatsData);
        setStatsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setStatsData(defaultStatsData);
        setStatsModal("");
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    setStatsModal("");
    dispatch(updateResources(payload));
  }, [
    statsData.tagline.value,
    statsData.title.value,
    id,
    currentStatsId,
    dispatch,
    rehydrateFormData,
    notificationsId,
    defaultStatsData,
  ]);

  const handleStatsModal = useCallback(
    (item?: string) => {
      const sectionId = typeof item === "string" ? item : "";
      switch (item) {
        case "add":
          setStatsModal("add");
          break;
        // case "edit":
        //   setLearningPointsModal("edit");
        //   break;
        default:
          if (sectionId) {
            const sectionItem = formData.stats?.value.find((item) => item._id === sectionId);
            if (sectionItem?._id) {
              setCurrentStatsId(sectionItem?._id);
            }
            setStatsData({
              ...statsData,
              tagline: {
                ...statsData.tagline,
                value: sectionItem?.tagline || "",
              },
              title: {
                ...statsData.title,
                value: sectionItem?.title || "",
              },
              _id: sectionItem?._id || "",
            });
            setStatsModal("edit");
          } else {
            setStatsModal("");
            setStatsData(defaultStatsData);
          }
          break;
      }
    },
    [defaultStatsData, formData.stats?.value, statsData],
  );

  const onStatsTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      // Update the field as usual
      const tempFormData = {
        ...statsData,
        [name]: {
          ...statsData[name],
          value,
          error: "",
        },
      };

      setStatsData(tempFormData);
    },
    [statsData],
  );

  const statsHeaderButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);
  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchResources,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    onSaveAttachment,
    deleteAttachment,
    // Section Function
    addResourcesSection,
    updateResourcesSection,
    sectionHeaderButtons,
    onSectionTextChange,
    handleSectionsModal,
    sectionsData,
    sectionsModal,
    deleteServiceNowSection,
    // Stats Function
    addResourcesStats,
    updateResourcesStats,
    statsHeaderButtons,
    onStatsTextChange,
    handleStatsModal,
    statsData,
    statsModal,
    deleteServiceNowStats,
    caseStudyDescription,
    onCaseStudyDescriptionChange,
    onBlogSectionDescriptionChange,
    description,
    onWhitePaperDescriptionChange,
    whitepaperdescription,
    descriptionValidation,
  };
};
