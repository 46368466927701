import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
`;

export const AccessLevel = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;
