import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  EmploymentHistoryList,
  AddEmploymentHistory,
  UpdateEmploymentHistory,
  FindEmploymentHistory,
  DeleteEmploymentHistory,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchEmploymentHistoryListSuccess, fetchEmploymentHistoryError } from "./actions";
import Types from "./actionType";
import {
  CreateEmploymentHistoryuccess,
  CreateEmploymentHistory as CreateEmploymentHistoryType,
  EmploymentHistorySuccess,
  UpdateEmploymentHistory as UpdateEmploymentHistoryType,
  FetchEmploymentHistory,
  FetchEmploymentHistoryById,
  EmploymentHistoryByIdSuccess,
  DeleteEmploymentHistory as DeleteEmploymentHistoryType,
} from "./types";

function* createWorker(action: CreateEmploymentHistoryType) {
  try {
    const options = {
      method: "POST",
      url: AddEmploymentHistory,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateEmploymentHistoryuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateEmploymentHistoryType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateEmploymentHistory(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateEmploymentHistoryuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}
function* deleteWorker(action: DeleteEmploymentHistoryType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteEmploymentHistory(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateEmploymentHistoryuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchEmploymentHistory) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: EmploymentHistoryList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<EmploymentHistorySuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchEmploymentHistoryListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchEmploymentHistoryError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(
        error?.response?.data?.message || "Error in fetching employment history",
      );
    }
  }
}

function* fetchEmploymentHistoryById(action: FetchEmploymentHistoryById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindEmploymentHistory(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<EmploymentHistoryByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(
        error?.response?.data?.message || "Error in fetching employment histotry",
      );
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_EMPLOYMENT_HISTORY, createWorker);
  yield takeLatest(Types.UPDATE_EMPLOYMENT_HISTORY, updateWorker);
  yield takeLatest(Types.DELETE_EMPLOYMENT_HISTORY, deleteWorker);
  yield takeEvery(Types.FETCH_EMPLOYMENT_HISTORY, fetchAllWorker);
  yield takeEvery(Types.FETCH_EMPLOYMENT_HISTORY_BY_ID, fetchEmploymentHistoryById);
}
