import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AcademyACHList,
  AddAcademyACH,
  UpdateAcademyACH,
  FindAcademyBatchById,
  FindAcademyACHById,
  DeleteAcademyACH,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAcademyACHListSuccess, fetchAcademyACHError } from "./actions";
import Types from "./actionType";
import {
  CreateAcademyACHuccess,
  CreateAcademyACH as CreateAcademyACHType,
  AcademyACHSuccess,
  UpdateAcademyACH as UpdateAcademyACHType,
  FetchAcademyACH,
  FetchAcademyACHById,
  AcademyACHByIdSuccess,
  DeleteAcademyACH as DeleteAcademyACHType,
} from "./types";

function* createWorker(action: CreateAcademyACHType) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyACH,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyACHuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* deleteWorker(action: DeleteAcademyACHType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAcademyACH(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAcademyACHuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateAcademyACHType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyACH(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyACHuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchAcademyACH) {
  console.log("A ::");
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AcademyACHList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AcademyACHSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchAcademyACHListSuccess(data.data));
    }
  } catch (e) {
    console.log("e", e);
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchAcademyACHError(error.response?.data.message));
  }
}

function* fetchAcademyACHById(action: FetchAcademyACHById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAcademyACHById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AcademyACHByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ACADEMY_ACH, createWorker);
  yield takeLatest(Types.DELETE_ACADEMY_ACH, deleteWorker);
  yield takeLatest(Types.UPDATE_ACADEMY_ACH, updateWorker);
  yield takeLatest(Types.DELETE_ACADEMY_ACH, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_ACH, fetchAllWorker);
  yield takeEvery(Types.FETCH_ACADEMY_ACH_BY_ID, fetchAcademyACHById);
}
