import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateTableConfigs,
} from "../../../../../redux";
import {
  deleteCampaignsAssets,
  fetchCampaignsAssetsList,
  updateCampaignsAssets,
} from "../../../../../redux/CampaignsAssets/actions";
import {
  CampaignsAssetsListWithMetaData,
  FetchCampaignsAssetsParams,
  CampaignsAssetsList,
  DeleteCampaignsAssetsPayload,
} from "../../../../../redux/CampaignsAssets/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  fetchCampaignsAssetsProps,
  useCampaignsAssetsListProps,
  useCampaignsAssetsListReturnType,
} from "../../../../../types/models/CampaignsAssets";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getCampaignsAssetsColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.campaignsAssets;
const defaultFilters: QueryBuilderType[] = [];

export const useCampaignsAssetsList = ({
  relatedList,
  widgetId,
}: useCampaignsAssetsListProps): useCampaignsAssetsListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.CampaignsAssetsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const campaignsAssets = useAppSelector((state) => state.CampaignsAssetsReducer.data.list);
  const { usersOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const CampaignsAssetsConfigTableFields = useMemo(() => {
    return tableConfiguration?.campaignsAssets?.fields?.length
      ? tableConfiguration?.campaignsAssets?.fields
      : tableListDefaultSelectFields.campaignsAssets;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = CampaignsAssetsConfigTableFields;
    return getCampaignsAssetsColumns({ fields });
  }, [CampaignsAssetsConfigTableFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: CampaignsAssetsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.campaignsAssets?.fields || tableListDefaultSelectFields.campaignsAssets;
      records = records?.map((item) => {
        const campaignsAssetstem = { ...item };
        if (userPersonalizedFields?.includes("createdAt")) {
          campaignsAssetstem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          campaignsAssetstem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          campaignsAssetstem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          campaignsAssetstem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete campaignsAssetstem?._id;
        return campaignsAssetstem;
      });
      const campaignsAssetsFields = tablePublicFields.campaignsAssets;
      const columnHeaders = campaignsAssetsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "campaignsAssets");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.campaignsAssets?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const campaignsAssetsFetchSuccess = useCallback(
    (data: CampaignsAssetsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const campaignsAssetsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchCampaignsAssets = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchCampaignsAssetsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }

      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.campaignsAssets?.limit || 10;

      const params: FetchCampaignsAssetsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: CampaignsAssetsListWithMetaData) =>
          campaignsAssetsFetchSuccess(data, exportAll),
        onError: campaignsAssetsFetchError,
      };
      dispatch(fetchCampaignsAssetsList(payload));
    },
    [
      widgetId,
      widgetsData,
      dispatch,
      campaignsAssetsFetchError,
      campaignsAssetsFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = campaignsAssets?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, campaignsAssets, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchCampaignsAssets({ exportAll: true });
    },
    [fetchCampaignsAssets],
  );

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchCampaignsAssets({ page });
  }, [fetchCampaignsAssets, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchCampaignsAssets({ page: parseInt(page), search });
    },
    [fetchCampaignsAssets, search],
  );

  const onClickCampaignsAssets = useCallback(
    (id: string) => {
      if (permissions?.campaignsAssets_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.campaignsAssets_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchCampaignsAssets({ page: 1, search });
  }, [fetchCampaignsAssets, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchCampaignsAssets({ page: 1 });
  }, [fetchCampaignsAssets]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchCampaignsAssets({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchCampaignsAssets],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchCampaignsAssets({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchCampaignsAssets],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = campaignsAssets?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, campaignsAssets]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            campaignsAssets?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, campaignsAssets, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const campaignsAssetsConfigLimit = tableConfiguration?.campaignsAssets?.limit || 10;
      const campaignsAssetsConfigs = {
        table: "campaignsAssets",
        fields: fields,
        limit: campaignsAssetsConfigLimit,
      };
      const payload = {
        tableConfiguration: campaignsAssetsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchCampaignsAssets({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "CampaignsAssets table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.campaignsAssets?.limit, dispatch, fetchCampaignsAssets, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const campaignsAssetsConfigFields = tableConfiguration?.campaignsAssets?.fields;
      dispatch(showInAppLoader(true));
      const campaignsAssetsConfigs = {
        table: "campaignsAssets",
        fields: campaignsAssetsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: campaignsAssetsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchCampaignsAssets({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "CampaignsAssets table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchCampaignsAssets],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = campaignsAssets?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchCampaignsAssets({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "CampaignsAssets updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating CampaignsAssets",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateCampaignsAssets(payload));
    },
    [selectedRows, campaignsAssets, dispatch, notificationsId, fetchCampaignsAssets],
  );

  // ================ Delete Method ================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = campaignsAssets?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteCampaignsAssetsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchCampaignsAssets({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteCampaignsAssets(payload));
  }, [selectedRows, campaignsAssets, dispatch, notificationsId, fetchCampaignsAssets]);

  // ================ Table Header Buttons ================

  const listActionOptions: ListActions[] = [
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const campaignsAssetsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");

    const fields: Field[] = [
      {
        name: "name",
        label: "Name",
        inputType: "string",
        validator,
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions]);

  return {
    listActionOptions,
    campaignsAssetsFilterFields,
    onClickCampaignsAssets,
    fetchCampaignsAssets,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    onSubmitDeleteRecord,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
    columns,
    CampaignsAssetsConfigTableFields,
  };
};
