import React from "react";

import { images, strings } from "../../../../theme";

import {
  Container,
  CardTitle,
  BirthdaysContainer,
  ItemContainer,
  ProfileImage,
  Name,
  DOB,
  NoData,
} from "./styles";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const BirthdaysCard = ({ data, title }) => {
  const getDisplayDate = (date) => {
    const today = new Date().getUTCDate();
    const currentMonth = new Date().getMonth();

    const day = new Date(date).getUTCDate();
    const month = new Date(date).getMonth();
    if (today === day && currentMonth === month) {
      return "Today";
    }
    return `${day} ${months[month]}`;
  };
  return (
    <Container>
      <CardTitle>{title}</CardTitle>
      {data.length > 0 ? (
        <>
          {data.map((item, index) => {
            const profileImage = item?.profileImage || images.defaultProfile;
            return (
              <ItemContainer key={index} today={getDisplayDate(item.dob) === "Today"}>
                <ProfileImage src={profileImage} />
                <Name>{item.name}</Name>
                <DOB>{getDisplayDate(item.dob)}</DOB>
              </ItemContainer>
            );
          })}
        </>
      ) : (
        <NoData>{strings.common.noBirthdays}</NoData>
      )}
    </Container>
  );
};

export default BirthdaysCard;
