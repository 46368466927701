import Types from "./actionTypes";
import {
  FetchEmployeeById,
  FetchEmployees,
  FetchEmployeeByIdPayload,
  FetchEmployeesPayload,
  FetchEmployeesSuccess,
  FetchEmployeesSuccessPayload,
  CreateEmployeePayload,
  CreateEmployee,
  UpdateEmployee,
} from "./types";

interface openEmployees {
  _id: string;
  name: string;
}

export const fetchEmployees = ({
  onSuccess,
  onError,
  params,
}: FetchEmployeesPayload): FetchEmployees => ({
  type: Types.EMPLOYEES_FETCH,
  payload: {
    onSuccess,
    onError,
    params,
  },
});

export const fetchEmployeesSuccess = ({
  data,
}: FetchEmployeesSuccessPayload): FetchEmployeesSuccess => ({
  type: Types.EMPLOYEES_FETCH_SUCCESS,
  payload: {
    data,
  },
});

export const fetchEmployeesById = ({
  onSuccess,
  onError,
  id,
}: FetchEmployeeByIdPayload): FetchEmployeeById => ({
  type: Types.EMPLOYEE_DETAILS_BY_ID,
  payload: {
    onSuccess,
    onError,
    id,
  },
});

export const createEmployee = ({
  onSuccess,
  onError,
  data,
}: CreateEmployeePayload): CreateEmployee => ({
  type: Types.EMPLOYEES_ADD,
  payload: {
    onSuccess,
    onError,
    data,
  },
});

export const updateEmployee = ({
  onSuccess,
  onError,
  data,
  id,
}: CreateEmployeePayload): UpdateEmployee => ({
  type: Types.EMPLOYEES_UPDATE,
  payload: {
    onSuccess,
    onError,
    data,
    id,
  },
});

// Appraisals
export const addAppraisal = ({ data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD_APPRAISAL,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateAppraisal = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE_APPRAISAL,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

// Emergency Contact
export const addEmergencyContact = ({ data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD_EMERGENCY_CONTACT,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateEmergencyContact = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE_EMERGENCY_CONTACT,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchEmployeesBasic = ({ organisation, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_FETCH_BASIC,
  payload: { organisation, onSuccess, onError },
});

export const fetchEmployeesOpenSuccess = (list = []) => ({
  type: Types.EMPLOYEES_FETCH_OPEN_SUCCESS,
  payload: { data: list },
});

export const fetchEmployeesBirthdays = (payload) => ({
  type: Types.FETCH_EMPLOYEES_BIRTHDAYS,
  payload: payload,
});

export const fetchEmployeesBirthdaysSuccess = (payload) => ({
  type: Types.SET_EMPLOYEES_BIRTHDAYS,
  payload: payload,
});

export const fetchEmployeesError = (payload) => ({
  type: Types.EMPLOYEES_FETCH_ERROR,
  payload: payload,
});

export const getEmployees = ({ id, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_GET,
  payload: {
    id: id,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateEmployees = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const addEmployees = ({ data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const filterEmployees = ({ field, value }) => ({
  type: Types.EMPLOYEES_UPDATE_FILTER,
  payload: {
    field: field,
    value: value,
  },
});

export const filterPayrolls = ({ field, value }) => ({
  type: Types.PAYROLLS_FILTERS,
  payload: {
    field: field,
    value: value,
  },
});

export const getComments = ({ employeeId, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_GET_COMMENTS,
  payload: {
    employeeId: employeeId,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const deleteComments = ({ id, employeeId, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_DELETE_COMMENTS,
  payload: {
    id: id,
    employeeId: employeeId,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const addComments = ({ employeeId, date, rating, feedback, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD_COMMENTS,
  payload: {
    employeeId: employeeId,
    date: date,
    rating: rating,
    feedback: feedback,
    onSuccess: onSuccess,
    onError: onError,
  },
});

// Designations

export const addDesignations = ({ data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD_DESIGNATIONS,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateDesignations = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE_DESIGNATIONS,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchPayrollData = (payload) => ({
  type: Types.EMPLOYEES_FETCH_PAYROLLDATA,
  payload: payload,
});

export const fetchPayrollDataSuccess = (payrolls) => ({
  type: Types.EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS,
  payload: {
    payrolls: payrolls,
  },
});

export const fetchPayrollDataSelf = (payload) => ({
  type: Types.EMPLOYEES_FETCH_PAYROLLDATA_SELF,
  payload: payload,
});

export const fetchPayrollDataSelfSuccess = (payrolls) => ({
  type: Types.EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS_SELF,
  payload: {
    payrolls: payrolls,
  },
});

export const getPayrollData = (payload) => ({
  type: Types.EMPLOYEES_GET_PAYROLLDATA,
  payload: payload,
});

export const addPayrollData = ({ date, status, list, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_ADD_PAYROLLDATA,
  payload: { date, status, list, onSuccess, onError },
});

export const updatePayrollData = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE_PAYROLLDATA,
  payload: { id, data, onSuccess, onError },
});

export const updatePayrollSelfData = ({ id, data, onSuccess, onError }) => ({
  type: Types.EMPLOYEES_UPDATE_PAYROLLDATA_SELF,
  payload: { id, data, onSuccess, onError },
});

export const payrollsRehydrate = (flag) => ({
  type: Types.EMPLOYEES_PAYROLLS_REHYDRATE,
  payload: flag,
});

export const updateChildPayrollsFilters = ({ field, value }) => ({
  type: Types.CHILD_PAYROLLS_FILTERS,
  payload: {
    field: field,
    value: value,
  },
});
