/* eslint-disable no-unused-vars */
enum Types {
  FETCH_BANKDETAILS = "FETCH_BANKDETAILS",
  FETCH_BANKDETAILS_BY_ID = "FETCH_BANKDETAILS_BY_ID",
  SUCCESS_FETCH_BANKDETAILS = "SUCCESS_FETCH_BANKDETAILS",
  ERROR_FETCH_BANKDETAILS = "ERROR_FETCH_BANKDETAILS",
  CREATE_BANKDETAILS = "CREATE_BANKDETAILS",
  UPDATE_BANKDETAILS = "UPDATE_BANKDETAILS",
  DELETE_BANKDETAILS = "DELETE_BANKDETAILS",
}

export default Types;
