import Types from "./actionType";
import {
  FetchSPSPaperworkPayload,
  CreateSPSPaperwork,
  CreateSPSPaperworkPayload,
  FetchSPSPaperwork,
  FetchSPSPaperworkError,
  FetchSPSPaperworkSuccess,
  UpdateSPSPaperwork,
  UpdateSPSPaperworkPayload,
  SPSPaperworkListWithMetaData,
  FetchSPSPaperworkByIdPayload,
  FetchSPSPaperworkById,
  DeleteSPSPaperworkPayload,
  DeleteSPSPaperwork,
} from "./types";

export const fetchSPSPaperworkList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSPaperworkPayload): FetchSPSPaperwork => ({
  type: Types.FETCH_SPSPAPERWORK,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSPaperworkById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSPaperworkByIdPayload): FetchSPSPaperworkById => ({
  type: Types.FETCH_SPSPAPERWORK_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSPaperworkListSuccess = (
  data: SPSPaperworkListWithMetaData,
): FetchSPSPaperworkSuccess => ({
  type: Types.SUCCESS_FETCH_SPSPAPERWORK,
  payload: {
    data,
  },
});

export const fetchSPSPaperworkError = (error?: string): FetchSPSPaperworkError => ({
  type: Types.ERROR_FETCH_SPSPAPERWORK,
  payload: { error },
});

export const createSPSPaperwork = ({
  data,
  onSuccess,
  onError,
}: CreateSPSPaperworkPayload): CreateSPSPaperwork => ({
  type: Types.CREATE_SPSPAPERWORK,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSPaperwork = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSPaperworkPayload): UpdateSPSPaperwork => ({
  type: Types.UPDATE_SPSPAPERWORK,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSPaperwork = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSPaperworkPayload): DeleteSPSPaperwork => ({
  type: Types.DELETE_SPSPAPERWORK,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
