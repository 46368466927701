import React from "react";

import { Layout } from "../../../presentationals";
import { KnowledgeController } from "../../../presentationals/screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<KnowledgeController type='add' />}
      title={strings.headerTitle.knowledgeAdd}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
