import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { AcademyMentorFeedbackControllerProps } from "../../../../types";
import { collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyStudentProfileList } from "../AcademyStudentProfile/AcademyStudentProfileList";

import { useAcademyMentorFeedbackController } from "./hooks";

export const AcademyMentorFeedbackController = ({ type }: AcademyMentorFeedbackControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyMentorFeedback,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useAcademyMentorFeedbackController({ type, id: params.id, attachmentsPickerRef });
  const currentAcademyMentorFeedback = data;

  const { employeesOptions, academyStudentProfileOptions } = useControllerConfigs();

  const {
    workLogs,
    attachments,
    serial,
    studentProfile,
    mentor,
    feedbackSummary,
    weakPoints,
    strongPoints,
    description,
    state,
    overallScoring,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyMentorFeedback();
    }
    // Do not include fetchAcademyMentorFeedback to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Number'
            type={"text"}
            width='48%'
            error={serial.error}
            disabled={true}
            inputProps={{
              name: "serial",
              value: `${serial.value}`,
              autoComplete: "off",
              onChange: onTextChange,
              disabled: true,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("academyStudentState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>

        <Row>
          <Select
            width={"48%"}
            name={"studentProfile"}
            label={"Learner's Profile"}
            options={academyStudentProfileOptions}
            value={studentProfile.value}
            onChange={onTextChange}
            mandatory={studentProfile.required}
            error={studentProfile.error}
          />
          <Select
            width={"48%"}
            name={"overallScoring"}
            label={"Overall Scoring"}
            options={getAttributValues("academyStudentScoring")}
            value={overallScoring.value}
            onChange={onTextChange}
            mandatory={overallScoring.required}
            error={overallScoring.error}
          />
        </Row>

        <Select
          width={"100%"}
          name={"mentor"}
          label={"Mentor"}
          options={employeesOptions}
          value={mentor.value}
          onChange={onTextChange}
          mandatory={mentor.required}
          error={mentor.error}
        />
        <TextInput
          label='Feedback Summary'
          type={"textarea"}
          width='100%'
          error={feedbackSummary.error}
          inputProps={{}}
          textAreaProps={{
            name: "feedbackSummary",
            rows: 4,
            value: feedbackSummary.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: feedbackSummary.required,
          }}
        />

        <TextInput
          label='Weak Points'
          type={"textarea"}
          width='100%'
          error={weakPoints.error}
          inputProps={{}}
          textAreaProps={{
            name: "weakPoints",
            rows: 4,
            value: weakPoints.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: weakPoints.required,
          }}
        />
        <TextInput
          label='Strong Points'
          type={"textarea"}
          width='100%'
          error={strongPoints.error}
          inputProps={{}}
          textAreaProps={{
            name: "strongPoints",
            rows: 4,
            value: strongPoints.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: strongPoints.required,
          }}
        />

        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </>
    );
  }, [
    academyStudentProfileOptions,
    description.error,
    description.required,
    description.value,
    employeesOptions,
    feedbackSummary.error,
    feedbackSummary.required,
    feedbackSummary.value,
    mentor.error,
    mentor.required,
    mentor.value,
    onTextChange,
    overallScoring.error,
    overallScoring.required,
    overallScoring.value,
    serial.error,
    serial.value,
    state.error,
    state.required,
    state.value,
    strongPoints.error,
    strongPoints.required,
    strongPoints.value,
    studentProfile.error,
    studentProfile.required,
    studentProfile.value,
    weakPoints.error,
    weakPoints.required,
    weakPoints.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyMentorFeedback?.createdBy !== "string"
        ? currentAcademyMentorFeedback?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyMentorFeedback?.updatedBy !== "string"
        ? currentAcademyMentorFeedback?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyMentorFeedback?.createdAt}
        updatedAt={currentAcademyMentorFeedback?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyMentorFeedback?.createdAt,
    currentAcademyMentorFeedback?.createdBy,
    currentAcademyMentorFeedback?.updatedAt,
    currentAcademyMentorFeedback?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.academyMentorFeedback.name} recordId={params.id} />
    ),
    [params.id],
  );
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyMentorFeedback.name} />,
    [params.id],
  );
  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const relatedStudentProfile = useCallback(() => {
    return (
      <AcademyStudentProfileList
        relatedList={{
          type: "_id",
          value: studentProfile.value,
        }}
      />
    );
  }, [studentProfile]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return relatedStudentProfile();
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    relatedStudentProfile,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
