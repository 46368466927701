import React, { useState, useEffect } from "react";

import { sha256 } from "js-sha256";
import { connect } from "react-redux";

import Timezones from "../../assets/timezones.json";
import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { uploadFile, getFile } from "../../redux/application/actions";
import { getProfile, updateProfile } from "../../redux/user/actions";
import { useTitle } from "../../services/methods";

import styles from "./UserProfileUpdate.styles";

const UsersProfileUpdate = (props) => {
  useTitle("Update Profile");
  const defaultFormData = {
    timezone: props.UserReducer.timezone,
    password: "",
    confirm_password: "",
    profileImage: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
  };
  const defaultToastData = {
    message: "",
    message_type: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState(defaultToastData);

  const onTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    props.updateProfile({
      timezone: formData.timezone,
      password: formData.password && formData.confirm_password ? sha256(formData.password) : null,
      profileImage: formData.profileImage.value,
      onSuccess: () => {
        props.getProfile({
          onSuccess: (response) => {
            setLoader(false);
            setFormData({
              timezone: response.data.timezone,
              password: "",
              confirm_password: "",
            });
            setToast({
              message_type: "success",
              message: "Sucessfully Updated",
            });
          },
        });
      },
      onError: (error) => {
        setLoader(false);
        setToast({
          message_type: "error",
          message: error,
        });
      },
    });
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      type: "public",
      onSuccess: (data) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            value: data.id,
            error: ``,
          },
        });
      },
      onError: (err) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>Update Profile</h2>
      </styles.Header>
      <hr width={"100%"} />
      <Toast type={toast.message_type} message={toast.message} />
      <form onSubmit={onSubmit} autoComplete='off'>
        <Select
          name={"timezone"}
          label={"Timezone"}
          options={Timezones.map((item) => {
            return { label: item, value: item };
          })}
          value={formData.timezone}
          onChange={onTextChange}
        />
        <Input
          label={"Attach Image"}
          type={"file"}
          name='profileImage'
          message={false}
          autoComplete={"off"}
          onChange={onFileChange}
          mandatory={true}
          error={formData?.profileImage?.error}
        />
        <Input
          label={"Password"}
          type={"password"}
          name='password'
          value={formData.password}
          autoComplete={"off"}
          onChange={onTextChange}
        />
        <Input
          label={"Confirm Password"}
          type={"password"}
          name='confirm_password'
          value={formData.confirm_password}
          autoComplete={"off"}
          onChange={onTextChange}
        />
        <Button
          block={true}
          onClick={onSubmit}
          label={loader ? "Saving" : "Save"}
          // disabled={
          //   formData.password === "" ||
          //   formData.password !== formData.confirm_password
          // }
        />
      </form>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
});

const mapDispatchToProps = {
  getProfile,
  updateProfile,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersProfileUpdate);
