import styled from "styled-components";

const styles = {};

styles.MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

styles.Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  /* flex: 1; */
`;

export default styles;
