export const AppRouteLinks = {
  users: {
    list: "/ap/users/list",
    edit: "/ap/users/",
    add: "/ap/users/add",
  },
  academyCourses: {
    list: "/ap/academy-courses",
    edit: (id: string) => `/ap/academy-courses/${id}`,
    add: "/ap/academy-courses/add",
  },
  Announcements: {
    list: "/ap/announcements",
    edit: (id: string) => `/ap/announcements/${id}`,
    add: "/ap/announcements/add",
  },
  academyCandidates: {
    list: "/ap/academy-candidates",
    edit: (id: string) => `/ap/academy-candidates/${id}`,
  },
  academyCandidateLeads: {
    list: "/ap/academy-candidate-leads",
    edit: (id: string) => `/ap/academy-candidate-leads/${id}`,
    add: "/ap/academy-candidate-leads/add",
  },
  academyBatch: {
    list: "/ap/academy-batch",
    edit: (id: string) => `/ap/academy-batch/${id}`,
    add: "/ap/academy-batch/add",
  },
  academyCandidateEnrollments: {
    list: "/ap/academy-candidate-enrollments",
    edit: (id: string) => `/ap/academy-candidate-enrollments/${id}`,
    add: "/ap/academy-candidate-enrollments/add",
  },
  academyACH: {
    list: "/ap/academy-ach",
    edit: (id: string) => `/ap/academy-ach/${id}`,
    add: "/ap/academy-ach/add",
  },
  academyStudentProfile: {
    list: "/ap/academy-learners-profile",
    edit: (id: string) => `/ap/academy-learners-profile/${id}`,
    add: "/ap/academy-learners-profile/add",
  },
  academyMentorFeedback: {
    list: "/ap/academy-mentor-feedback",
    edit: (id: string) => `/ap/academy-mentor-feedback/${id}`,
    add: "/ap/academy-mentor-feedback/add",
  },
  // HR Workspace
  addressHistory: {
    list: "/ap/address-history",
    edit: (id: string) => `/ap/address-history/${id}`,
    add: "/ap/address-history/add",
  },
  departments: {
    list: "/ap/departments",
    edit: (id: string) => `/ap/departments/${id}/edit`,
    add: "/ap/departments/add",
  },
  employmentHistory: {
    list: "/ap/employment-history",
    edit: (id: string) => `/ap/employment-history/${id}`,
    add: "/ap/employment-history/add",
  },
  educationDetails: {
    list: "/ap/education-details",
    edit: (id: string) => `/ap/education-details/${id}`,
    add: "/ap/education-details/add",
  },
  form16: {
    list: "/ap/form-16",
    edit: (id: string) => `/ap/form-16/${id}`,
    add: "/ap/form-16/add",
  },
  benefits: {
    list: "/ap/benefits",
    edit: (id: string) => `/ap/benefits/${id}`,
    add: "/ap/benefits/add",
  },
  subscriber: {
    list: "/ap/subscribers",
    edit: (id: string) => `/ap/subscribers/${id}`,
    add: "/ap/subscribers/add",
  },
  groups: {
    list: "/ap/groups",
    edit: (id: string) => `/ap/groups/${id}`,
    add: "/ap/groups/add",
  },
  projectHistory: {
    list: "/ap/project-history",
    edit: (id: string) => `/ap/project-history/${id}/edit`,
    add: "/ap/project-history/add",
  },
  designations: {
    list: "/ap/designations",
    edit: (id: string) => `/ap/designations/${id}/edit`,
    add: "/ap/designations/add",
  },
  newsletterResources: {
    list: "/ap/newsletter-resources",
    edit: (id: string) => `/ap/newsletter-resources/${id}`,
    add: "/ap/newsletter-resources/add",
  },
  piiDetails: {
    list: "/ap/pii-details",
    edit: (id: string) => `/ap/pii-details/${id}`,
    add: "/ap/pii-details/add",
  },
  bankDetails: {
    list: "/ap/bank-details",
    edit: (id: string) => `/ap/bank-details/${id}`,
    add: "/ap/bank-details/add",
  },
  employees: {
    list: "/ap/employees",
    edit: (id: string) => `/ap/employees/${id}`,
    add: "/ap/employees/add",
  },
  spsPaperwork: {
    list: "/ap/sps-paperwork",
    edit: (id: string) => `/ap/sps-paperwork/${id}`,
    add: "/ap/sps-paperwork/add",
  },
  spsInsurance: {
    list: "/ap/sps-insurance",
    edit: (id: string) => `/ap/sps-insurance/${id}`,
    add: "/ap/sps-insurance/add",
  },
  spsProject: {
    list: "/ap/sps-projects",
    edit: (id: string) => `/ap/sps-projects/${id}`,
    add: "/ap/sps-projects/add",
  },
  spsAccounts: {
    list: "/ap/sps-accounts",
    edit: (id: string) => `/ap/sps-accounts/${id}`,
    add: "/ap/sps-accounts/add",
  },
  spsAccountContact: {
    list: "/ap/sps-account-contact",
    edit: (id: string) => `/ap/sps-account-contact/${id}`,
    add: "/ap/sps-account-contact/add",
  },
  spsSalesProfile: {
    list: "/ap/sps-sales-profile",
    edit: (id: string) => `/ap/sps-sales-profile/${id}`,
    add: "/ap/sps-sales-profile/add",
  },
  spsBGCheck: {
    list: "/ap/sps-bg-check",
    edit: (id: string) => `/ap/sps-bg-check/${id}`,
    add: "/ap/sps-bg-check/add",
  },
  spsDeals: {
    list: "/ap/sps-deals",
    edit: (id: string) => `/ap/sps-deals/${id}`,
    add: "/ap/sps-deals/add",
  },
  dashboardAdministration: {
    list: "/ap/dashboard-administration",
    edit: (id: string) => `/ap/dashboard-administration/${id}`,
    add: "/ap/dashboard-administration/add",
  },
  dashboardTabs: {
    list: "/ap/dashboard-tabs",
    edit: (id: string) => `/ap/dashboard-tabs/${id}`,
    add: "/ap/dashboard-tabs/add",
  },
  dashboardWidgets: {
    list: "/ap/dashboard-widgets",
    edit: (id: string) => `/ap/dashboard-widgets/${id}`,
    add: "/ap/dashboard-widgets/add",
  },
  bugReporting: {
    list: "/ap/bug-reporting",
    edit: (id: string) => `/ap/bug-reporting/${id}`,
    add: "/ap/bug-reporting/add",
  },
  currency: {
    list: "/ap/currency",
    edit: (id: string) => `/ap/currency/${id}`,
    add: "/ap/currency/add",
  },
  categories: {
    list: "/ap/categories",
    edit: (id: string) => `/ap/categories/${id}`,
    add: "/ap/categories/add",
  },
  tags: {
    list: "/ap/tags",
    edit: (id: string) => `/ap/tags/${id}`,
    add: "/ap/tags/add",
  },
  country: {
    list: "/ap/country",
    edit: (id: string) => `/ap/country/${id}`,
    add: "/ap/country/add",
  },
  subCategories: {
    list: "/ap/sub-categories",
    edit: (id: string) => `/ap/sub-categories/${id}`,
    add: "/ap/sub-categories/add",
  },
  platformModules: {
    list: "/ap/platform-modules",
    edit: (id: string) => `/ap/platform-modules/${id}`,
    add: "/ap/platform-modules/add",
  },
  certificationsAccreditations: {
    list: "/ap/certifications-accreditations",
    edit: (id: string) => `/ap/certifications-accreditations/${id}`,
    add: "/ap/certifications-accreditations/add",
  },
  companyContact: {
    list: "/ap/company-contact",
    edit: (id: string) => `/ap/company-contact/${id}`,
    add: "/ap/company-contact/add",
  },
  checklistTemplate: {
    list: "/ap/checklist-template",
    edit: (id: string) => `/ap/checklist-template/${id}`,
    add: "/ap/checklist-template/add",
  },
  jobsPosting: {
    list: "/ap/jobs",
    edit: (id: string) => `/ap/jobs/${id}`,
    add: "/ap/jobs/add",
  },
  company: {
    list: "/ap/company",
    edit: (id: string) => `/ap/company/${id}/edit`,
    add: "/ap/company/add",
  },
  tasks: {
    list: "/ap/task",
    edit: (id: string) => `/ap/task/${id}`,
    add: "/ap/task/add",
  },
  attributes: {
    list: "/ap/attributes",
    edit: (name: string, id: string) => `/ap/attributes/${name}/${id}/edit`,
    add: (name: string) => `/ap/attributes/${name}/add`,
  },
  contactUs: {
    list: "/ap/itn-contactus",
    edit: (id: string) => `/ap/itn-contactus/${id}`,
    add: "/ap/itn-contactus/add",
  },
  itnServices: {
    list: "/ap/itn-services",
    edit: (id: string) => `/ap/itn-services/${id}`,
    add: "/ap/itn-services/add",
  },
  itnRFP: {
    list: "/ap/itn-rfp",
    edit: (id: string) => `/ap/itn-rfp/${id}`,
    add: "/ap/itn-rfp/add",
  },
  variableSets: {
    list: "/ap/variable-sets",
    edit: (id: string) => `/ap/variable-sets/${id}`,
    add: "/ap/variable-sets/add",
  },
  variables: {
    list: "/ap/variables",
    edit: (id: string) => `/ap/variables/${id}`,
    add: "/ap/variables/add",
  },
  emailSchedular: {
    list: "/ap/email-schedular",
    edit: (id: string) => `/ap/email-schedular/${id}`,
    add: "/ap/email-schedular/add",
  },
  catalogAdministrations: {
    list: "/ap/catalogAdministrations",
    edit: (id: string) => `/ap/catalog-administrations/${id}`,
    add: "/ap/catalog-administrations/add",
  },
  kanbanConfig: {
    list: "/ap/kanban-config",
    edit: (id: string) => `/ap/kanban-config/${id}`,
    add: "/ap/kanban-config/add",
  },
  serviceNowSolutions: {
    list: "/ap/servicenow-solutions",
    edit: (id: string) => `/ap/servicenow-solutions/${id}`,
    add: "/ap/servicenow-solutions/add",
  },
  resources: {
    list: "/ap/resources",
    edit: (id: string) => `/ap/resources/${id}`,
    add: "/ap/resources/add",
  },
  workspaces: {
    list: "/ap/workspaces",
    edit: (id: string) => `/ap/workspaces/${id}`,
    add: "/ap/workspaces/add",
  },
  space: {
    list: "/ap/spaces",
    edit: (id: string) => `/ap/spaces/${id}`,
    add: "/ap/spaces/add",
  },
  notificationsDefinations: {
    list: "/ap/notificationsDefinations",
    edit: (id: string) => `/ap/notificationsDefinations/${id}`,
    add: "/ap/notificationsDefinations/add",
  },
  serviceRequests: {
    list: "/ap/service-requests",
    edit: (id: string) => `/ap/service-requests/${id}`,
    add: "/ap/service-requests/add",
  },
  leadLists: {
    list: "/ap/lead-lists",
    edit: (id: string) => `/ap/lead-lists/${id}`,
    add: "/ap/lead-lists/add",
  },
  campaignsAssets: {
    list: "/ap/campaigns-assets",
    edit: (id: string) => `/ap/campaigns-assets/${id}`,
    add: "/ap/campaigns-assets/add",
  },
  campaigns: {
    list: "/ap/campaigns",
    edit: (id: string) => `/ap/campaigns/${id}`,
    add: "/ap/campaigns/add",
  },
  newsletterLeads: {
    list: "/ap/newsletter-leads",
    edit: (id: string) => `/ap/newsletter-leads/${id}`,
    add: "/ap/newsletter-leads/add",
  },
};
