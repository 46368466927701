import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  VariablesList,
  AddVariables,
  UpdateVariables,
  FindVariables,
  DeleteVariables,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchVariablesListSuccess, fetchVariablesError } from "./actions";
import Types from "./actionType";
import {
  CreateVariablesuccess,
  CreateVariables as CreateVariablesType,
  VariablesSuccess,
  UpdateVariables as UpdateVariablesType,
  FetchVariables,
  FetchVariablesById,
  VariablesByIdSuccess,
  DeleteVariables as DeleteVariablesType,
} from "./types";

function* createWorker(action: CreateVariablesType) {
  try {
    const options = {
      method: "POST",
      url: AddVariables,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateVariablesuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateVariablesType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateVariables(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateVariablesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchVariables) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: VariablesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<VariablesSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchVariablesListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchVariablesError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Variable");
    }
  }
}

function* fetchVariablesById(action: FetchVariablesById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindVariables(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<VariablesByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Variable");
    }
  }
}

function* deleteWorker(action: DeleteVariablesType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteVariables(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateVariablesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_VARIABLES, createWorker);
  yield takeLatest(Types.UPDATE_VARIABLES, updateWorker);
  yield takeEvery(Types.FETCH_VARIABLES, fetchAllWorker);
  yield takeEvery(Types.FETCH_VARIABLES_BY_ID, fetchVariablesById);
  yield takeEvery(Types.DELETE_VARIABLES, deleteWorker);
}
