import React, { useEffect, useState } from "react";

import moment from "moment-timezone";
import { MdOutlineEdit, MdTableView, MdFeedback, MdZoomIn } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../components/Button/button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import {Header as MainHeader} from '../../presentationals/components'
import { fetchEmployees } from "../../redux/employees/actions";
import { AttributesValues, useTitle, Sort } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./Employees.styles";

const Employees = (props) => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState([]);
  useTitle("Employees List");
  useEffect(() => {
    props.fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTable = () => {
    let filtered = props.employees.filter((item) => {
      let filterName = true;
      let filterMobile = true;
      let filterEmploymentStatus = true;
      let filterEmployeeType = true;
      let filterReportingPOD = true;
      let filterNationalID = true;
      let filterNationalIDType = true;
      let filterDateJoining = true;
      // var
      if (props.filters?.name) {
        filterName =
          item?.name
            .toLowerCase()
            .split(" ")
            .join("")
            .includes(props.filters?.name.toLowerCase().split(" ").join("")) ||
          item?.nickName
            ?.toLowerCase()
            .split(" ")
            .join("")
            .includes(props.filters?.name.toLowerCase().split(" ").join(""));
      }

      if (props.filters?.mobile) {
        filterMobile =
          item?.mobile?.includes(props.filters?.mobile) ||
          item?.mobile1?.includes(props.filters?.mobile);
      }

      if (props.filters?.employmentStatus) {
        filterEmploymentStatus =
          String(props.filters?.employmentStatus) === String(item.employmentStatus);
      }

      if (props.filters?.employeeType) {
        filterEmployeeType = String(props.filters?.employeeType) === String(item.employeeType);
      }

      if (props.filters?.reportingPOD) {
        filterReportingPOD = String(props.filters?.reportingPOD) === String(item.reportingPOD);
      }

      if (props.filters?.nationalId) {
        filterNationalID = item.nationalId.includes(props.filters?.nationalId);
      }

      if (props.filters?.nationalIdType) {
        filterNationalIDType =
          String(props.filters?.nationalIdType) === String(item.nationalIdType);
      }

      if (props.filters?.dateJoiningStart && props.filters?.dateJoiningEnd) {
        filterDateJoining =
          moment(item.dateJoining).valueOf() >= moment(props.filters?.dateJoiningStart).valueOf() &&
          moment(props.filters?.dateJoiningEnd).valueOf() >= moment(item.dateJoining).valueOf();
      }

      return (
        filterName &&
        filterMobile &&
        filterEmploymentStatus &&
        filterEmployeeType &&
        filterReportingPOD &&
        filterNationalID &&
        filterNationalIDType &&
        filterDateJoining
      );
    });
    let serial = 0;
    let list = [];
    if (props.filters.sortKey) {
      filtered = Sort({
        data: filtered,
        field: props.filters.sortKey?.key,
        asc: props.filters.sortType === "asc",
        attributeKey: props.filters.sortKey?.attribute,
        isDate: props.filters.sortKey?.isDate,
      });
    }
    return filtered.map((item, index) => {
      serial++;
      list = [];
      props.permissions.employees_view &&
        list.push({
          link: `/ap/employees/${item._id}/view`,
          label: "View",
          icon: <MdZoomIn size={15} />,
        });
      props.permissions.employees_edit &&
        list.push({
          link: `/ap/employees/${item._id}/edit`,
          label: "Edit",
          icon: <MdOutlineEdit size={15} />,
        });
      list.push({
        link: `/ap/employees/${item._id}/comments`,
        label: "Feedback",
        icon: <MdFeedback size={15} />,
      });

      return (
        <tr key={index} data-index={index}>
          <td align='center'>{serial}</td>
          <td>
            {item.name}
            <br />
            <div style={{ fontSize: 10, color: "#666", marginTop: 3 }}>
              {item.official_email || ""}
            </div>
          </td>
          <td align='center'>
            {item.employmentDepartment &&
              AttributesValues("employmentDepartment", item.employmentDepartment)}
            <br />
            <div style={{ fontSize: 10, color: "#666", marginTop: 3 }}>
              {item.employeeType && AttributesValues("employeeType", item.employeeType)}
            </div>
          </td>
          <td align='center'>{AttributesValues("employeeCountry", item.personalCountry) || ""}</td>
          <td align='center'>
            {item.employmentStatus
              ? AttributesValues("employmentStatus", item.employmentStatus)
              : "-"}
          </td>
          <td align='center'>
            <Dropdown element={"Action"} list={list} />
          </td>
        </tr>
      );
    });
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.employees} />
      <Header
        title='Employees'
        buttons={[
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/employees",
            },
            visible: true,
          },
          {
            visible: props.permissions.employees_add,
            onPress: {
              event: navigate,
              params: "/ap/employees/add",
            },
            title: "Add",
          },
          {
            title: "POD Tree",
            onPress: {
              event: navigate,
              params: "/ap/employees/pod",
            },
            visible: true,
          },
        ]}
        onSync={{
          event: props.fetchEmployees,
          params: {},
        }}
      />

      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='center' width='5%'>
              #
            </th>
            <th align='left'>Information</th>
            <th>Department - Emploee Type</th>
            <th>Country</th>
            <th>Status</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>{renderTable()}</tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.EmployeesReducer.loading,
  employees: state.EmployeesReducer.employees,
  filters: state.EmployeesReducer.filters,
  permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
