import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

interface Props {
  open?: boolean;
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.5vh;
  margin-bottom: 20px;
  position: relative;
  .table-header-back-button {
    border: 0.3px solid ${colors.common.white};
  }
`;

export const LeftContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  column-gap: 20px;
  .search-container {
    height: 38px;
    width: 253px;
    border: 0.6px solid ${colors.common.borderColor};
    border-radius: 10px;
    margin-right: 10px;
  }
  .search-input {
    padding-left: 10px;
    font-size: ${fs.s};
    color: ${colors.common.lightTextColor};
  }
`;

export const AttachmentsIconContainer = styled.div`
  display: flex;
  position: relative;
`;

export const AttachmentsIcon = styled.img`
  height: 35px;
  width: 35px;
  object-fit: contain;
  cursor: pointer;
`;

export const AttachmentsBadge = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 1rem;
  background-color: ${colors.common.yellow};
  position: absolute;
  font-size: ${fs.s};
  color: ${colors.common.white};
  font-weight: 600;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: -8px;
`;

export const ListActionButtonsContainer = styled.div`
  display: flex;
  height: 100%;
  border-radius: 1rem;
  border: solid 1px ${colors.common.borderColor};
  background-color: ${colors.common.white};
  position: relative;
`;

export const FilterButtonContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-right: solid 1px ${colors.common.borderColor};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding-left: 12px;
  padding-right: 14.45px;
  box-sizing: border-box;
  color: ${colors.common.whiteGrey};
  font-size: ${fs.s};
  transition: 0.5s;
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;

export const FilterButonTitle = styled.h2<Props>`
  font-weight: 400;
  margin-left: 10px;
`;

export const FilterIcon = styled.img``;

export const RefreshIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-left: solid 1px ${colors.common.borderColor};
  padding: 0 10px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;

export const ListActionButtonIcon = styled.img`
  height: 14px;
  width: 14px;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px ${colors.common.borderColor};
  padding: 0 10px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;

export const PersonalisedListContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  transition: 0.5s;
  background-color: ${(props) =>
    props.active ? colors.common.activeTabBackground : "transparent"};
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;

export const FieldsPopup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.common.white};
  width: 220px;
  padding: 15px 20px;
  box-sizing: border-box;
  position: absolute;
  top: 120%;
  border-radius: 5px;
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  z-index: 10000;
  .table-field-search-container {
    width: 100%;
    margin-bottom: 15px;
    background-color: white;
  }
  .table-field-search-input {
    /* background-color: white; */
  }
`;

export const SelectedFieldsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  row-gap: 15px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px ${colors.common.borderColor};
`;

export const FieldsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  row-gap: 15px;
  max-height: 300px;
  margin-bottom: 20px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-clip: padding-box;
    background-color: ${colors.table.scrollThumb};
  }
`;

export const FieldListItem = styled.div`
  display: flex;
  position: relative;
  .table-list-field-drag {
    position: absolute;
    right: 0;
  }
  .table-list-field-drag-title {
    width: 70% !important;
  }
`;

export const FieldLisImage = styled.img`
  display: flex;
  object-fit: contain;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
`;

export const FieldListTitle = styled.text`
  font-size: ${fs.m};
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-family: ${fonts.SourceSansPro};
  margin-left: 10px;
`;

export const FilterModalContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.65);
  z-index: 3;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 6%;
  z-index: 20;
  box-sizing: border-box;
  background-color: ${colors.common.white};
  border-radius: 10px;
`;

export const FilterHeader = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  .table-filter-modal-button {
    border: none;
    padding: 9px 12px;
    font-size: ${fs.m};
    width: 100px;
  }
`;

export const FilterRightHeaderContainer = styled.div`
  display: flex;
  .table-filter-apply-button {
    padding: 9px 18px;
    margin-left: 10px;
  }
  .table-filter-close-button {
    padding: 9px 18px;
    margin-left: 10px;
    background-color: ${colors.common.white};
    color: rgba(0, 0, 0, 0.75);
  }
  .table-filter-clear-button {
    padding: 9px 18px;
    margin-left: 20px;
  }
`;

export const FilterItemsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: scroll;
  /* .ruleGroup-addRule,
  .ruleGroup-addGroup,
  .ruleGroup-combinators {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    height: fit-content;
    padding: 4px 10px;
    color: ${colors.common.white};
    border: none;
    background-color: ${colors.common.yellow};
    font-size: ${fs.s};
    font-weight: 600;
    line-height: 20px;
  }
  .ruleGroup-remove,
  .rule-remove {
    display: flex !important ;
    padding: 4px 10px;
    color: ${colors.common.white};
    background-color: ${colors.common.red};
    margin-left: 20px;
    font-size: ${fs.s};
    font-weight: 600;
    line-height: 20px;
    border-radius: 5px;
    border: none;
  }
  .rule-fields,
  .rule-operators,
  .rule-value {
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
  } */
`;

export const FilterItem = styled.div`
  display: flex;
  width: 100%;
`;

export const FilterContainerNew = styled.div`
  background-color: ${colors.common.white};
  width: 800px;
  box-sizing: border-box;
  position: absolute;
  top: 120%;
  z-index: 1;
  right: -50%;
  border-radius: 10px;
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
`;
