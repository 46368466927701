/* eslint-disable no-unused-vars */
enum Types {
  FETCH_COUNTRY = "FETCH_COUNTRY",
  SUCCESS_FETCH_COUNTRY = "SUCCESS_FETCH_COUNTRY",
  ERROR_FETCH_COUNTRY = "ERROR_FETCH_COUNTRY",
  CREATE_COUNTRY = "CREATE_COUNTRY",
  UPDATE_COUNTRY = "UPDATE_COUNTRY",
  DELETE_COUNTRY = "DELETE_COUNTRY",
  FETCH_COUNTRY_BY_ID = "FETCH_COUNTRY_BY_ID",
}

export default Types;
