import React from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import GlobalStyle from "./index.styles";
import { store, persistor } from "./redux/store";
import App from "./Routes/index";
import "./index.css";
import { socketInstance } from "./utils/socket";

socketInstance.connect();
Modal.setAppElement("#root");

ReactDOM.render(
  <GoogleOAuthProvider clientId='34267009860-40012utb3abujd3v1brifkt8u781k2dv.apps.googleusercontent.com'>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
