/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPSINSURANCE = "FETCH_SPSINSURANCE",
  FETCH_SPSINSURANCE_BY_ID = "FETCH_SPSINSURANCE_BY_ID",
  SUCCESS_FETCH_SPSINSURANCE = "SUCCESS_FETCH_SPSINSURANCE",
  ERROR_FETCH_SPSINSURANCE = "ERROR_FETCH_SPSINSURANCE",
  CREATE_SPSINSURANCE = "CREATE_SPSINSURANCE",
  UPDATE_SPSINSURANCE = "UPDATE_SPSINSURANCE",
  DELETE_SPSINSURANCE = "DELETE_SPSINSURANCE",
}

export default Types;
