/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_CANDIDATES = "FETCH_ACADEMY_CANDIDATES",
  FETCH_ACADEMY_CANDIDATES_BY_ID = "FETCH_ACADEMY_CANDIDATES_BY_ID",
  SUCCESS_FETCH_ACADEMY_CANDIDATES = "SUCCESS_FETCH_ACADEMY_CANDIDATES",
  ERROR_FETCH_ACADEMY_CANDIDATES = "ERROR_FETCH_ACADEMY_CANDIDATES",
  CREATE_ACADEMY_CANDIDATES = "CREATE_ACADEMY_CANDIDATES",
  UPDATE_ACADEMY_CANDIDATES = "UPDATE_ACADEMY_CANDIDATES",
  DELETE_ACADEMY_CANDIDATES = "DELETE_ACADEMY_CANDIDATES",
}

export default Types;
