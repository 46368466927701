import type { Field, OptionGroup, RuleType } from "react-querybuilder";
import { defaultOperators } from "react-querybuilder";

import { getAttributValues } from "../../services/methods";

const getTechnologyOptions = () => {
  console.log("AA :: yo");
  const technologyAttributes: any[] = getAttributValues("technology").map((item) => {
    return {
      name: item.value,
      label: item.label,
    };
  });
  const technologyOptions: OptionGroup[] = [
    {
      label: "Technology",
      options: technologyAttributes,
    },
  ].map(({ label, options }) => ({
    label,
    options: options.map((s) => ({ name: s, label: s })),
  }));
  console.log("A ::", technologyAttributes, technologyOptions);
  return technologyOptions;
};

const validator = (r: RuleType) => !!r.value;

export const academyCandidateLeadsFilterFields: Field[] = [
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter first name",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter last name",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "phone",
    label: "Phone",
    placeholder: "Enter phone",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "email",
    label: "email",
    placeholder: "Enter email",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "dob",
    label: "DOB",
    inputType: "date",
    operators: defaultOperators.filter((op) => ["=", ">", "<"].includes(op.name)),
  },

  {
    name: "graduationYear",
    label: "Graduation Year",
    placeholder: "Enter graduation year",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "active",
    label: "Active",
    valueEditorType: "checkbox",
    operators: defaultOperators.filter((op) => op.name === "="),
    defaultValue: false,
  },

  // {
  //   name: "gender",
  //   label: "Gender",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  //   valueEditorType: "radio",
  //   values: [
  //     { name: "M", label: "Male" },
  //     { name: "F", label: "Female" },
  //     { name: "O", label: "Other" },
  //   ],
  // },
  // {
  //   name: "technology",
  //   label: "Technology",
  //   valueEditorType: "select",
  //   values: getTechnologyOptions(),
  //   defaultValue: "",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  // },
];

// "preparedBy",
// "curriculamAttached",
// "createdBy",
// "updatedBy",
