import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* height: 500px; */
  /* background: green; */
  flex-direction: column;
  border-bottom: 0.6px solid black;
  box-sizing: border-box;
  padding: 1% 5%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  /* background: green; */
  justify-content: space-between;
`;

export const ProfileImage = styled.img`
  height: 36px;
  width: 36px;
  z-index: 2;
  border-radius: 20px;
  background-color: ${colors.common.white};
  object-fit: cover;
  border: 0.6px solid black;
`;

export const UserNameInitial = styled.div`
  height: 36px;
  width: 36px;
  z-index: 2;
  border-radius: 20px;
  background-color: ${colors.common.white};
  border: 0.6px solid black;
  text-transform: capitalize;
  font-size: ${fs.xl};
  font-weight: bold;
  color: ${colors.common.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommentPostContainer = styled.div`
  display: flex;
  height: 36px;
  width: 92%;
  border-radius: 2px;
  border: 0.6px solid rgba(0, 0, 0, 0.65);
  align-items: center;
  justify-content: space-between;
`;

export const ActivityLogsInput = styled.input`
  height: 100%;
  width: 85%;
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  padding-left: 20px;
  color: ${colors.common.lightTextColor};
  background-color: transparent;
  border: none;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`;

export const NoActivityLogs = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const ActivityLogsContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  width: 102%;
  box-sizing: border-box;
  padding-right: 2%;
  margin-top: 10px;
  flex-direction: column;
  height: 100%;
  position: relative;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const CommentItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 20px;
  justify-content: space-between;
  .activity-logger {
    width: 100%;
  }
  .activity-comment {
    font-size: 14px;
  }
  .comment-box {
    position: relative;
    background: ${colors.common.secondaryWhite};
    border-radius: 0.4em;
  }

  .comment-box:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    margin-top: -14px;
  }
  .left:after {
    right: 0;
    border-left-color: ${colors.common.secondaryWhite};
    border-right: 0;
    margin-right: -14px;
  }
  .right:after {
    left: 0;
    border-right-color: ${colors.common.secondaryWhite};
    border-left: 0;
    margin-left: -14px;
  }
`;

export const CommentItem = styled.div`
  display: flex;
  /* align-items: center; */
  box-sizing: border-box;
  position: relative;
  padding: 14px 20px;
  width: 40%;
  flex-direction: column;
  margin-top: 20px;
`;

export const Comment = styled.div`
  font-size: 14px;
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  /* margin-top: 3px; */
  color: ${colors.common.commentColor};
  /* border: 0.6px solid black; */
  /* line-height: 18px; */
  b {
    font-weight: bold;
  }
`;

export const CommentMetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 14px 20px;
`;

export const CommentDate = styled.div`
  margin-top: 10px;
  align-self: flex-end;
  font-size: 12px;
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  color: ${colors.common.commentColor};
  /* border: 0.6px solid black; */
  line-height: 18px;
`;

export const EmptyCommentItem = styled.div`
  display: flex;
  height: 100%;
  width: 40%;
`;

export const CommentSeparator = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 0.6px;
  z-index: 1;
  background-color: ${colors.common.black};
  align-self: center;
`;

export const ProfileImageContainer = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

export const TriangleRight = styled.div`
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 25px solid transparent;
`;

export const StartLabel = styled.div`
  display: flex;
  padding: 8px 20px;
  background-color: ${colors.common.lightGreen};
  font-family: ${fonts.SourceSansPro};
  font-weight: 700;
  font-size: 12px;
  color: ${colors.common.white};
  z-index: 2;
  border-radius: 15px;
`;

export const ActivityLogsItemContainer = styled.div`
  display: flex;
  /* background: green; */
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const LogItemContainer = styled.div`
  display: flex;
  width: 90%;
  background: ${colors.common.secondaryWhite};
  border-radius: 0.4em;
  flex-direction: column;
`;
