import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import type { Field } from "react-querybuilder";
import type { RuleGroupTypeIC } from "react-querybuilder";
import { defaultValidator, formatQuery, QueryBuilder } from "react-querybuilder";

import { Header, TableListSkeleton, TableHeaderV3, TableV3, PaginationV3 } from "../..";
import { strings } from "../../../../theme";
import {
  ColumnsType,
  ListActions,
  SelectOptions,
  TableHeaderButton,
  TablePublicFields,
  metaDataTypes,
} from "../../../../types";
import { FilterButton } from "../../atoms/FilterButton";

import "react-querybuilder/dist/query-builder.css";
import "../../../common/conditionBuilder.css";
import { MainContainer } from "./styles";

interface Props {
  dashboardWidget?: boolean;
  handleShowItemsPerPage?: (count: number) => void;
  onSubmitDeleteRecord?: () => void;
  deleteRecordEnabled?: boolean;
  headerButtons?: TableHeaderButton[];
  isRelatedList?: boolean;
  allRowsSelected?: boolean;
  selectedRows?: string[];
  handleListActions?: (field: string, value: any) => void;
  onClickRowSelection?: (e: React.MouseEvent) => void;
  tablePublicFields?: TablePublicFields[];
  selectedConfigFields?: string[];
  handleTableConfigs?: (fields: string[]) => void;
  tableFields?: [];
  loading: boolean;
  rightButton?: string;
  headerTitle?: string;
  rightButtonPress?: React.MouseEventHandler;
  filterButtonPress?: React.MouseEventHandler;
  list: object[];
  columns: ColumnsType[];
  metaData?: metaDataTypes;
  onPageChange?: (page: string) => void;
  onClickTableRow?: (id: string) => void;
  horizontalScroll?: boolean;
  emptyListMessage?: string;
  filterButton?: string;
  onSearch?: React.MouseEventHandler;
  searchPlaceholder?: string;
  clearSearch?: () => void;
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue?: string;
  filters?: Field[];
  searchInputName?: string;
  refreshList?: () => void;
  onClickApplyFilter?: (
    mongoQuery: string,
    jsonQuery: string,
    filterLogicQuery: RuleGroupTypeIC,
  ) => void;
  onClickCloseFilter?: () => void;
  filterLogicQuery?: RuleGroupTypeIC;
  handleSortList?: (columnKey: string, sortType: "asc" | "desc") => void;
  listActionOptions?: ListActions[];
  advancedList?: boolean;
  exportSelectedRecords?: (type: string) => void;
  exportAllRecords?: (type: string) => void;
  disableSelect?: boolean;
  disableFilter?: boolean;
  primaryTable?: boolean;
}

export const TableListV3 = memo(
  ({
    handleShowItemsPerPage,
    dashboardWidget,
    onSubmitDeleteRecord,
    deleteRecordEnabled,
    headerButtons,
    handleListActions,
    isRelatedList,
    selectedRows,
    onClickRowSelection,
    allRowsSelected,
    tablePublicFields,
    selectedConfigFields,
    loading,
    rightButton,
    headerTitle,
    rightButtonPress,
    list,
    columns,
    metaData,
    onPageChange,
    onClickTableRow,
    horizontalScroll,
    emptyListMessage,
    onSearch,
    searchPlaceholder,
    clearSearch,
    onSearchChange,
    searchValue,
    filters,
    searchInputName,
    refreshList,
    onClickApplyFilter,
    handleTableConfigs,
    filterLogicQuery,
    handleSortList,
    advancedList = true,
    listActionOptions,
    exportSelectedRecords,
    exportAllRecords,
    disableSelect,
    disableFilter,
    primaryTable,
  }: Props) => {
    const [tableConfigModal, setTableConfigModal] = useState<boolean>(false);
    const [configFields, setConfigFields] = useState<string[]>(selectedConfigFields || []);

    const handleConfigField = useCallback(
      (e: React.MouseEvent) => {
        const key = e.currentTarget.getAttribute("data-key");
        if (key) {
          const fieldExists = configFields.findIndex((item) => item === key);
          if (fieldExists >= 0) {
            const newArray = [...configFields];
            newArray.splice(fieldExists, 1);
            setConfigFields(newArray);
          } else {
            setConfigFields([...configFields, key]);
          }
        }
      },
      [configFields],
    );

    const onConfigFieldsSave = useCallback(() => {
      if (handleTableConfigs) {
        setTableConfigModal(false);
        handleTableConfigs(configFields);
      }
    }, [handleTableConfigs, configFields]);

    const handleTableConfigModal = useCallback(() => {
      setTableConfigModal((value) => !value);
    }, []);
    return (
      <MainContainer>
        {!!headerTitle && !isRelatedList && <Header title={headerTitle} />}
        {!dashboardWidget && (
          <TableHeaderV3
            isRelatedList={isRelatedList}
            onClickAdd={rightButtonPress}
            headerButtons={headerButtons}
            handleTableConfigs={handleTableConfigs}
            onSearchPress={onSearch}
            refreshList={refreshList}
            tablePublicFields={tablePublicFields}
            selectedConfigFields={selectedConfigFields}
            disableFilter={disableFilter}
            filterLogicQuery={filterLogicQuery}
            onClickApplyFilter={onClickApplyFilter}
            filters={filters}
          />
        )}
        {loading ? (
          <TableListSkeleton />
        ) : (
          <React.Fragment>
            <TableV3
              dashboardWidget={dashboardWidget}
              isRelatedList={isRelatedList}
              onSearch={onSearch}
              onSearchChange={onSearchChange}
              searchPlaceholder={searchPlaceholder}
              clearSearch={clearSearch}
              searchValue={searchValue}
              handleSortList={handleSortList}
              data={list}
              metaData={metaData}
              selectedRows={selectedRows}
              allRowsSelected={allRowsSelected}
              onClickRowSelection={onClickRowSelection}
              columns={columns}
              page={metaData?.currentPage}
              pageSize={metaData?.limit}
              onClickTableRow={onClickTableRow}
              emptyListMessage={emptyListMessage}
              horizontalScroll={horizontalScroll}
              advancedList={advancedList}
              disableSelect={disableSelect}
              primaryTable={primaryTable}
            />
            {!!metaData && (
              <PaginationV3
                isRelatedList={isRelatedList}
                onSubmitDeleteRecord={onSubmitDeleteRecord}
                deleteRecordEnabled={deleteRecordEnabled}
                currentPage={metaData.currentPage}
                totalCount={metaData.totalItems}
                pageSize={metaData.limit}
                onPageChange={onPageChange}
                selectedRows={selectedRows}
                handleShowItemsPerPage={handleShowItemsPerPage}
                exportSelectedRecords={exportSelectedRecords}
                exportAllRecords={exportAllRecords}
                handleListActions={handleListActions}
                listActionOptions={listActionOptions}
              />
            )}
          </React.Fragment>
        )}
      </MainContainer>
    );
  },
);
