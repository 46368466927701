import DatePicker from "react-date-picker";
import styled from "styled-components";

import { colors, fs, fonts } from "../../../../theme";

interface Props {
  width?: string;
  disabled?: boolean;
  error?: string;
  hidden?: boolean;
}

export const ParentWrapper = styled.div<Props>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  margin-top: 26px;
  position: relative;
  width: ${(props) => props.width};
  /* background: green; */
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  color: ${colors.common.lightTextColor};
  background: ${(props) => (props.disabled ? colors.common.lightGrey : colors.common.white)};
  border: ${(props) => (props.error ? `0.6px solid ${colors.common.red}` : "none")};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

export const Label = styled.label<Props>`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 14px;
  margin-left: 10%;
  font-style: normal;
  font-weight: 400;
  font-size: ${fs.m};
  line-height: 20px;
  display: flex;
  color: ${colors.input.placeholder};
  transition: 0.2s ease all;
  span {
    color: ${colors.common.red};
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.common.error};
  margin-top: 4px;
`;
export const CheckboxImage = styled.img`
  height: 25px;
  width: 25px;
  object-fit: contain;
  cursor: pointer;
`;
