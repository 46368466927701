import React from "react";

import { Layout } from "../../../presentationals";
import { CertificationsAccreditationsList as CertificationsAccreditationsListScreen } from "../../../presentationals/screens";

export const Route = () => {
  return <Layout activity={<CertificationsAccreditationsListScreen />} admin={true} layout={"2"} />;
};

export const APCertificationsAccreditationsList = React.memo(Route);
