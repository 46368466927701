import React from "react";

import { Layout } from "../../../presentationals";
import { PublishedKnowledgeArticles } from "../../../presentationals/screens";
import { strings } from "../../../theme";

export const EPPublishedKnowledgeArticles = () => {
  return (
    <Layout
      activity={<PublishedKnowledgeArticles />}
      title={strings.headerTitle.knowledge}
      employee={true}
      layout={"2"}
      noPadding={true}
    />
  );
};
