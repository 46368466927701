import { createGlobalStyle } from "styled-components";
import "@fontsource/source-sans-pro";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Poppins";
  src: url('/fonts/Poppins-Regular.woff') format('woff');
}
@font-face {
  font-family: "PoppinsMedium";
  src: url('/fonts/Poppins-Medium.woff') format('woff');
}
@font-face {
  font-family: "PoppinsBold";
  src: url('/fonts/Poppins-Bold.woff') format('woff');
}
@font-face {
  font-family: "Roboto";
  src: url('/fonts/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: "RobotoMedium";
  src: url('/fonts/Roboto-Medium.woff') format('woff');
}
@font-face {
  font-family: "RobotoBold";
  src: url('/fonts/Roboto-Bold.woff') format('woff');
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}


  html,body {
    margin: 0;
    padding: 0;
    font-size: 11px;
    line-height: 1;
    font-family: 'Source Sans Pro';
    .model-root {
      background: transparent;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0;
  }
  };

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
  a {
    color: inherit;
    text-decoration: inherit;
  }

  .dashboard-row {
  display: flex;
}
`;

export default GlobalStyle;
