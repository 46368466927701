import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SPSDealsList,
  AddSPSDeals,
  UpdateSPSDeals,
  FindSPSDeals,
  DeleteSPSDeals,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSPSDealsListSuccess, fetchSPSDealsError } from "./actions";
import Types from "./actionType";
import {
  CreateSPSDealsuccess,
  CreateSPSDeals as CreateSPSDealsType,
  SPSDealsSuccess,
  UpdateSPSDeals as UpdateSPSDealsType,
  FetchSPSDeals,
  FetchSPSDealsById,
  SPSDealsByIdSuccess,
  DeleteSPSDeals as DeleteSPSDealsType,
} from "./types";

function* createWorker(action: CreateSPSDealsType) {
  try {
    const options = {
      method: "POST",
      url: AddSPSDeals,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSDealsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateSPSDealsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSPSDeals(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSDealsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchSPSDeals) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SPSDealsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SPSDealsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchSPSDealsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchSPSDealsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching SPS  Deals");
    }
  }
}

function* fetchSPSDealsById(action: FetchSPSDealsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSPSDeals(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SPSDealsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching SPS  Deals");
    }
  }
}

function* deleteWorker(action: DeleteSPSDealsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteSPSDeals(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateSPSDealsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SPS_DEALS, createWorker);
  yield takeLatest(Types.UPDATE_SPS_DEALS, updateWorker);
  yield takeEvery(Types.FETCH_SPS_DEALS, fetchAllWorker);
  yield takeEvery(Types.FETCH_SPS_DEALS_BY_ID, fetchSPSDealsById);
  yield takeEvery(Types.DELETE_SPS_DEALS, deleteWorker);
}
