import Types from "./actionType";
import {
  FetchAcademyStudentProfilePayload,
  CreateAcademyStudentProfile,
  CreateAcademyStudentProfilePayload,
  FetchAcademyStudentProfile,
  FetchAcademyStudentProfileError,
  FetchAcademyStudentProfileSuccess,
  UpdateAcademyStudentProfile,
  UpdateAcademyStudentProfilePayload,
  AcademyStudentProfileListWithMetaData,
  FetchAcademyStudentProfileByIdPayload,
  FetchAcademyStudentProfileById,
  DeleteAcademyStudentProfilePayload,
  DeleteAcademyStudentProfile,
} from "./types";

export const fetchAcademyStudentProfileList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyStudentProfilePayload): FetchAcademyStudentProfile => ({
  type: Types.FETCH_ACADEMY_STUDENT_PROFILE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyStudentProfileById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyStudentProfileByIdPayload): FetchAcademyStudentProfileById => ({
  type: Types.FETCH_ACADEMY_STUDENT_PROFILE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyStudentProfileListSuccess = (
  data: AcademyStudentProfileListWithMetaData,
): FetchAcademyStudentProfileSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_STUDENT_PROFILE,
  payload: {
    data,
  },
});

export const fetchAcademyStudentProfileError = (
  error?: string,
): FetchAcademyStudentProfileError => ({
  type: Types.ERROR_FETCH_ACADEMY_STUDENT_PROFILE,
  payload: { error },
});

export const createAcademyStudentProfile = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyStudentProfilePayload): CreateAcademyStudentProfile => ({
  type: Types.CREATE_ACADEMY_STUDENT_PROFILE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyStudentProfile = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyStudentProfilePayload): UpdateAcademyStudentProfile => ({
  type: Types.UPDATE_ACADEMY_STUDENT_PROFILE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAcademyStudentProfile = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyStudentProfilePayload): DeleteAcademyStudentProfile => ({
  type: Types.DELETE_ACADEMY_STUDENT_PROFILE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
