import React, { useMemo, useCallback, useState } from "react";

import { ListActions, SelectOptions } from "../../../../../types";
import { DOTS } from "../../../../../utils";

interface TableConfigOptions {
  label: string;
  childs?: TableConfigOptions[];
  active?: boolean;
  value?: string;
  selected?: boolean;
}
interface pagination {
  paginationRange?: (number | string)[];
  deleteRecords: () => void;
  lastPage: number;
  onNext: () => void;
  onPrevious: () => void;
  goToFirstPage: () => void;
  goToLastPage: () => void;
  itemRange?: string;
  tableConfigOptions: TableConfigOptions[];
  selectedTableconfigOption: number;
  handleTableConfigOption: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleTableConfigChild: (e: React.MouseEvent<HTMLDivElement>) => void;
  configPopup: boolean;
  handleConfigPopup: (e: React.MouseEvent) => void;
  onListActionFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onListActionValueChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleActionsPopup: (type?: string) => void;
  handleListAction: () => void;
  actionsPopup: string;
  allListActionFields?: SelectOptions[];
  selectedListActionFieldOptions?: SelectOptions[];
  listActionField: string;
  listActionRequired: boolean;
  valueError?: string;
  listActionValue: string | Date;
  closePopups: () => void;
  listActionFieldType?: string;
  listActionFieldIsMulti?: boolean;
}

export const usePaginationV3 = ({
  totalCount,
  onSubmitDeleteRecord,
  deleteRecordEnabled,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
  selectedRows,
  handleShowItemsPerPage,
  exportSelectedRecords,
  exportAllRecords,
  handleListActions,
  listActionOptions,
}: {
  totalCount: number;
  onSubmitDeleteRecord?: () => void;
  deleteRecordEnabled?: boolean;
  pageSize: number;
  siblingCount: number;
  currentPage: number;
  onPageChange?: (...args: any[]) => void;
  selectedRows?: string[];
  handleShowItemsPerPage?: (count: number) => void;
  exportSelectedRecords: (type: string) => void;
  exportAllRecords: (type: string) => void;
  handleListActions?: (field: string, value: any) => void;
  listActionOptions?: ListActions[];
}) => {
  const [selectedTableconfigOption, setSelectedTableConfigOption] = useState<number>(-1);
  const [configPopup, setConfigPopup] = useState<boolean>(false);
  const [actionsPopup, setActionsPopup] = useState<string>("");
  const [listActionField, setListActionField] = useState<string>("");
  const [listActionRequired, setListActionReuired] = useState<boolean>(false);
  const [listActionValue, setListActionValue] = useState<string>("");
  const [listActionFieldType, setListActionFieldType] = useState<
    "text" | "date" | "bigTextArea" | ""
  >("");
  const [listActionFieldIsMulti, setListActionFieldIsMulti] = useState<boolean>(false);
  const [valueError, setValueError] = useState<string>("");
  const handleConfigPopup = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setConfigPopup((value) => !value);
    setSelectedTableConfigOption(-1);
  }, []);

  const closePopups = useCallback(() => {
    setConfigPopup(false);
    setSelectedTableConfigOption(-1);
  }, []);

  const tableConfigOptions = useMemo(() => {
    const options: TableConfigOptions[] = [
      {
        label: "List Actions",
        active: !!selectedRows?.length,
        childs: [
          { label: "Update", value: "update", active: true },
          { label: "Update All", value: "update", active: false },
          // {
          //   label: "Delete",
          //   value: "delete",
          //   active: !!onSubmitDeleteRecord && deleteRecordEnabled,
          // },
        ],
      },
      {
        label: "Show",
        active: true,
        childs: [
          { label: "10 Rows per page", value: "10", active: true, selected: pageSize === 10 },
          { label: "15 Rows per page", value: "15", active: true, selected: pageSize === 15 },
          { label: "20 Rows per page", value: "20", active: true, selected: pageSize === 20 },
          { label: "30 Rows per page", value: "30", active: true, selected: pageSize === 30 },
          { label: "50 Rows per page", value: "50", active: true, selected: pageSize === 50 },
          { label: "100 Rows per page", value: "100", active: true, selected: pageSize === 100 },
        ],
      },
      {
        label: "Export",
        active: !!selectedRows?.length,
        childs: [
          { label: "CSV", value: "csv", active: true },
          { label: "PDF", value: "pdf", active: false },
        ],
      },
      {
        label: "Export All",
        active: true,
        childs: [
          { label: "CSV", value: "csv", active: true },
          { label: "PDF", value: "pdf", active: false },
        ],
      },
    ];
    return options;
  }, [selectedRows, pageSize, deleteRecordEnabled, onSubmitDeleteRecord]);

  const handleTableConfigOption = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const data = e.currentTarget.getAttribute("data-index");
      const index = Number(data);
      if (!isNaN(index)) {
        if (selectedTableconfigOption === index) {
          setSelectedTableConfigOption(-1);
        } else {
          setSelectedTableConfigOption(index);
        }
      }
    },
    [selectedTableconfigOption],
  );

  const range = useCallback((start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }, []);

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = siblingCount + 2;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage, range]);

  const lastPage = paginationRange?.[paginationRange?.length - 1];

  const getItemRange = useMemo(() => {
    let firstCount = 0;
    let lastCount = 0;
    if (currentPage === 1) {
      firstCount = 1;
      lastCount = pageSize;
    } else if (lastPage === currentPage) {
      firstCount = pageSize * currentPage + 1 - pageSize;
      lastCount = totalCount;
    } else {
      firstCount = pageSize * currentPage + 1 - pageSize;
      lastCount = pageSize * currentPage;
    }
    if (lastCount > totalCount) {
      lastCount = totalCount;
    }
    return `${firstCount} to ${lastCount}`;
  }, [currentPage, lastPage, pageSize, totalCount]);

  const goToFirstPage = useCallback(() => {
    onPageChange && totalCount > 0 && onPageChange(1);
  }, [onPageChange, totalCount]);

  const goToLastPage = useCallback(() => {
    onPageChange && totalCount > 0 && onPageChange(lastPage);
  }, [onPageChange, lastPage, totalCount]);

  const onNext = useCallback(() => {
    if (currentPage !== lastPage && totalCount > 0) {
      onPageChange && onPageChange(currentPage + 1);
    }
  }, [currentPage, lastPage, onPageChange, totalCount]);

  const onPrevious = useCallback(() => {
    if (currentPage !== 1 && totalCount > 0) {
      onPageChange && onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange, totalCount]);

  const allListActionFields = useMemo(() => {
    const tempFields = listActionOptions?.filter((item) => item.hide !== true);
    const fields: SelectOptions[] | undefined = tempFields?.map((item) => {
      return {
        label: item.label,
        value: item.name,
        type: item?.type,
        isMulti: item?.isMulti,
        mandatory: item?.required,
      };
    });
    return fields;
  }, [listActionOptions]);

  // List actions

  const onListActionFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      const selectedOption = allListActionFields?.find((option) => option?.value === value);
      const type = selectedOption?.type;
      setListActionField(value);
      setListActionReuired(selectedOption?.mandatory);
      setListActionFieldType(type);
      if (selectedOption?.isMulti) {
        setListActionFieldIsMulti(selectedOption?.isMulti);
      }
      setListActionValue("");
    },
    [allListActionFields],
  );

  const onDateChange = useCallback((value: Date) => {
    // Update listActionValue with the date value
    setListActionValue(value.toISOString()); // Adjust the format as needed
  }, []);

  const onListActionValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (listActionFieldType === "date") {
        // Handle date input
        onDateChange(e);
        if (listActionRequired && ["", null, undefined].includes(e)) {
          setValueError("Value is required");
        } else {
          setValueError("");
        }
      } else {
        const { value } = e.target;
        // Handle text input
        setListActionValue(value);
        if (listActionRequired && ["", null, undefined].includes(value)) {
          setValueError("Value is required");
        } else {
          setValueError("");
        }
      }
    },
    [onDateChange, listActionFieldType, listActionRequired],
  );

  const handleActionsPopup = useCallback((type) => {
    if (type) {
      setActionsPopup(type);
    } else {
      setActionsPopup("");
    }
  }, []);

  const deleteRecords = useCallback(() => {
    if (onSubmitDeleteRecord) {
      onSubmitDeleteRecord();
    }
  }, [onSubmitDeleteRecord]);

  const handleListAction = useCallback(() => {
    if (listActionRequired && ["", null, undefined].includes(listActionValue)) {
      setValueError("Value is required");
    } else {
      const field = listActionField;
      const value = listActionValue;
      const type = listActionFieldType;
      const isMulti = listActionFieldIsMulti;
      if (handleListActions) {
        handleListActions(field, value, type, isMulti);
      }
      setActionsPopup("");
      setListActionField("");
      setListActionValue("");
      setListActionFieldType("");
      setListActionFieldIsMulti(false);
      setListActionReuired(false);
      setValueError("");
    }
  }, [
    handleListActions,
    listActionField,
    listActionValue,
    listActionFieldType,
    listActionFieldIsMulti,
    listActionRequired,
  ]);

  const selectedListActionFieldOptions = useMemo(() => {
    return listActionOptions?.find((item) => item.name === listActionField)?.options;
  }, [listActionOptions, listActionField]);
  const handleTableConfigChild = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const data = e.currentTarget.getAttribute("data-index");
      const index = Number(data);
      if (!isNaN(index) && selectedTableconfigOption >= 0) {
        const childValue = tableConfigOptions[selectedTableconfigOption].childs?.[index]?.value;
        switch (selectedTableconfigOption) {
          case 0:
            if (childValue) {
              handleActionsPopup(childValue);
            }
            break;
          case 1:
            if (Number(childValue) >= 0 && handleShowItemsPerPage) {
              handleShowItemsPerPage(Number(childValue));
            }
            break;
          case 2:
            if (childValue) {
              exportSelectedRecords(childValue);
            }
            break;
          case 3:
            if (childValue) {
              exportAllRecords(childValue);
            }
            break;
        }
      }
    },
    [
      handleActionsPopup,
      handleShowItemsPerPage,
      exportSelectedRecords,
      selectedTableconfigOption,
      tableConfigOptions,
      exportAllRecords,
    ],
  );
  const pagination: pagination = {
    paginationRange,
    deleteRecords,
    lastPage,
    onNext,
    onPrevious,
    itemRange: getItemRange,
    goToFirstPage,
    goToLastPage,
    tableConfigOptions,
    handleTableConfigOption,
    handleTableConfigChild,
    selectedTableconfigOption,
    configPopup,
    handleConfigPopup,
    onListActionFieldChange,
    onListActionValueChange,
    handleActionsPopup,
    handleListAction,
    actionsPopup,
    allListActionFields,
    closePopups,
    selectedListActionFieldOptions,
    listActionField,
    listActionValue,
    listActionFieldType,
    listActionFieldIsMulti,
    listActionRequired,
    valueError,
  };

  return pagination;
};
