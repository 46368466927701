import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import navigationLinks from "utils/navigationLinks";

import { images } from "../../../theme";
import { Row } from "../../common/styles";

import {
  Container,
  CardTitle,
  ItemContainer,
  Title,
  SubTitle,
  RightLabel,
  FilterItem,
  RightContainer,
  Icon,
  NoData,
} from "./styles";

const DashboardTable = ({
  title,
  data = [],
  rows = 3,
  filters = [],
  filterKey = "",
  rightIcon,
  knowledgeRow = false,
  navigateTo,
  noDataLabel,
}) => {
  const [filter, setFilter] = useState(filters[0]?.key || "");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    filterData(filter);
  }, [filter, data]);

  const filterData = (filter) => {
    let availableData = data;
    if (filters.length > 0 && filterKey) {
      availableData = availableData.filter((item) => item[filterKey] === filter);
    }
    if (rows < availableData.length) {
      setTableData(availableData);
    } else {
      const remainingRows = new Array(rows - availableData.length).fill({});
      const tableData = availableData.concat(remainingRows);
      setTableData(tableData);
    }
  };

  const handleFilter = (e) => {
    const item = e.target.getAttribute("data-index");
    if (item >= 0) {
      const filter = filters[item]?.key;
      setFilter(filter);
      filterData(filter);
    }
  };
  const handleRowClick = (e) => {
    const id = e.currentTarget.getAttribute("data-index");
    if (id) {
      const path = navigationLinks?.[navigateTo]?.details(id);
      navigate(path);
    }
  };

  return (
    <Container>
      <CardTitle>{title}</CardTitle>
      {filters.length > 1 && (
        <Row className='filterRow'>
          {filters.map((item, index) => {
            const width = 100 / filters.length;
            return (
              <FilterItem
                onClick={handleFilter}
                data-index={index}
                key={index}
                active={filter === item.key}
                style={{
                  width: `${width}%`,
                }}
              >
                {item.value}
              </FilterItem>
            );
          })}
        </Row>
      )}
      {noDataLabel ? (
        <NoData rows={rows}>{noDataLabel}</NoData>
      ) : (
        <>
          {tableData.map((item, index) => {
            if (index < rows) {
              return (
                <ItemContainer key={index} onClick={handleRowClick} data-index={item.id}>
                  <Title>{item.title}</Title>
                  <Row marginTop='8px'>
                    {knowledgeRow && item.createdBy ? (
                      <RightContainer>
                        <Icon src={images.epCreatedBy} />
                        <SubTitle style={{ fontSize: "12px" }}>{item.createdBy}</SubTitle>
                        <Icon src={images.epLine} />
                        <Icon src={images.epHistory} />
                        <SubTitle style={{ fontSize: "12px" }}>{item.createdAt}</SubTitle>
                      </RightContainer>
                    ) : (
                      <SubTitle>{item.subtitle}</SubTitle>
                    )}
                    {!knowledgeRow && (
                      <RightContainer>
                        {rightIcon && <Icon src={rightIcon} />}
                        <RightLabel>{item.rightLabel}</RightLabel>
                      </RightContainer>
                    )}
                  </Row>
                </ItemContainer>
              );
            }
          })}
        </>
      )}
    </Container>
  );
};
export default DashboardTable;
