import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteSPSPaperwork,
  fetchSPSPaperworkList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateSPSPaperwork,
  updateTableConfigs,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  SPSPaperworkListWithMetaData,
  FetchSPSPaperworkParams,
  SPSPaperworkList,
  DeleteSPSPaperworkPayload,
} from "../../../../../redux/SPSPaperwork/types";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchSPSPaperworkProps,
  sortType,
  useSPSPaperworkListProps,
  useSPSPaperworkListReturnType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getSPSPaperworkColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.spsPaperwork;
const defaultFilters: QueryBuilderType[] = [];

export const useSPSPaperworkList = ({
  relatedList,
  widgetId,
}: useSPSPaperworkListProps): useSPSPaperworkListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const metaData = useAppSelector((state) => state.SPSPaperworkReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const spsPaperwork = useAppSelector((state) => state.SPSPaperworkReducer.data.list);
  const { usersOptions, spsAccountsOptions, spsDealsOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const spsPaperWorkTableConfigFields = useMemo(() => {
    return tableConfiguration?.spsPaperwork?.fields?.length
      ? tableConfiguration?.spsPaperwork?.fields
      : tableListDefaultSelectFields.spsPaperwork;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = spsPaperWorkTableConfigFields;
    return getSPSPaperworkColumns({ fields });
  }, [spsPaperWorkTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: SPSPaperworkList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.spsPaperwork?.fields || tableListDefaultSelectFields.spsPaperwork;
      records = records?.map((item) => {
        const spsPaperworktem = { ...item };
        if (
          userPersonalizedFields?.includes("associatedAccount") &&
          typeof item.associatedAccount !== "string"
        ) {
          spsPaperworktem.associatedAccount = item?.associatedAccount?.name;
        }
        if (
          userPersonalizedFields?.includes("associatedDeal") &&
          typeof item.associatedDeal !== "string"
        ) {
          spsPaperworktem.associatedDeal = `${item?.associatedDeal?.legalAccountName}`;
        }
        if (userPersonalizedFields?.includes("expiryDate")) {
          spsPaperworktem.expiryDate = MomentDate({
            date: item.expiryDate || "-",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          spsPaperworktem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          spsPaperworktem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          spsPaperworktem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          spsPaperworktem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete spsPaperworktem?._id;
        return spsPaperworktem;
      });
      const spsPaperworkFields = tablePublicFields.spsPaperwork;
      const columnHeaders = spsPaperworkFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "spsPaperwork");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.spsPaperwork?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const spsPaperworkFetchSuccess = useCallback(
    (data: SPSPaperworkListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const spsPaperworkFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchSPSPaperwork = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchSPSPaperworkProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.spsPaperwork?.limit || 10;

      const params: FetchSPSPaperworkParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["preferredName"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: SPSPaperworkListWithMetaData) =>
          spsPaperworkFetchSuccess(data, exportAll),
        onError: spsPaperworkFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchSPSPaperworkList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.spsPaperwork?.limit,
      sortKey,
      sortType,
      relatedList,
      spsPaperworkFetchError,
      dispatch,
      widgetsData,
      spsPaperworkFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = spsPaperwork?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, spsPaperwork, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchSPSPaperwork({ exportAll: true });
  }, [fetchSPSPaperwork]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchSPSPaperwork({ page });
  }, [fetchSPSPaperwork, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchSPSPaperwork({ page: parseInt(page), search });
    },
    [fetchSPSPaperwork, search],
  );

  const onClickSPSPaperwork = useCallback(
    (id: string) => {
      if (permissions?.spsPaperwork_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.spsPaperwork_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchSPSPaperwork({ page: 1, search });
  }, [fetchSPSPaperwork, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchSPSPaperwork({ page: 1 });
  }, [fetchSPSPaperwork]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchSPSPaperwork({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchSPSPaperwork],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchSPSPaperwork({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchSPSPaperwork],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = spsPaperwork?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, spsPaperwork]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            spsPaperwork?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, spsPaperwork, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const spsPaperworkConfigLimit = tableConfiguration?.spsPaperwork?.limit || 10;
      const spsPaperworkConfigs = {
        table: "spsPaperwork",
        fields: fields,
        limit: spsPaperworkConfigLimit,
      };
      const payload = {
        tableConfiguration: spsPaperworkConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchSPSPaperwork({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Paperwork table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.spsPaperwork?.limit, dispatch, fetchSPSPaperwork, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const spsPaperworkConfigFields = tableConfiguration?.spsPaperwork?.fields;
      dispatch(showInAppLoader(true));
      const spsPaperworkConfigs = {
        table: "spsPaperwork",
        fields: spsPaperworkConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: spsPaperworkConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchSPSPaperwork({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Paperwork table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchSPSPaperwork],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = spsPaperwork?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchSPSPaperwork({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Paperwork updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Paperwork",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateSPSPaperwork(payload));
    },
    [selectedRows, spsPaperwork, dispatch, notificationsId, fetchSPSPaperwork],
  );

  // ==================== Delete =====================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = spsPaperwork?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteSPSPaperworkPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchSPSPaperwork({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteSPSPaperwork(payload));
  }, [selectedRows, dispatch, spsPaperwork, notificationsId, fetchSPSPaperwork]);

  // ================ Table Header Buttons ================

  const spsPaperWorkHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "associatedAccount",
      label: "Associated Account",
      options: spsAccountsOptions,
      required: true,
    },
    {
      name: "associatedDeal",
      label: "Associated Deal",
      options: spsDealsOptions,
      required: true,
    },
    {
      name: "preferredName",
      label: "Preferred Name",
      type: "text",
      required: true,
    },
    {
      name: "consultantName",
      label: "Consultant Name",
      type: "text",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const spsPaperWorkFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const spsAccountsFilterOptions = getFilterOptions(spsAccountsOptions, "SPS Account");
    const spsDealssFilterOptions = getFilterOptions(spsDealsOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");

    const fields: Field[] = [
      {
        name: "associatedAccount",
        label: "SPS Account",
        valueEditorType: "select",
        values: spsAccountsFilterOptions,
        defaultValue: spsAccountsFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "associatedDeal",
        label: "Associated Deal",
        valueEditorType: "select",
        values: spsDealssFilterOptions,
        defaultValue: spsDealssFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "consultantName",
        label: "Consultant Name",
        inputType: "string",
        validator,
      },
      {
        name: "preferredName",
        label: "Preffered Name",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [spsAccountsOptions, spsDealsOptions, usersOptions]);

  return {
    listActionOptions,
    spsPaperWorkFilterFields,
    onClickSPSPaperwork,
    fetchSPSPaperwork,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    columns,
    spsPaperWorkTableConfigFields,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    onSubmitDeleteRecord,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    spsPaperWorkHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
  };
};
