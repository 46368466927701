import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { SubCategoriesListProps } from "../../../../types/models/SubCategories";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useSubCategoriesList } from "./hooks";

export const SubCategoriesList = memo(({ relatedList }: SubCategoriesListProps) => {
  const SubCategories = useAppSelector((state) => state.SubCategoriesReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = SubCategories;
  const {
    fetchSubCategories,
    loading,
    handlePageChange,
    onClickSubCategories,
    onClickAdd,
    onSubmitDeleteRecord,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    subCategoriesFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    SubcategoriesConfigTableFields,
  } = useSubCategoriesList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchSubCategories({});
    // Do not include fetchSubCategories to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.subCategories}
      handleTableConfigs={handleTableConfigs}
      filters={subCategoriesFilterFields}
      selectedConfigFields={SubcategoriesConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickSubCategories}
      headerTitle={strings.headerTitle.subCategories}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.subcategories_delete}
      loading={loading}
      searchPlaceholder={strings.apSubCategories.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
