import React, { useState } from "react";

import styles from "./select.styles";

const Select = ({
  label,
  value,
  name,
  options = [],
  onChange,
  readOnly = false,
  attributes = {},
  mandatory,
  error,
  valueField = "value",
  isMulti = false,
  defaultFocused = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const onSelectChange = (value) => {
    const object = {};
    object.target = {
      ...attributes,
      isMulti: isMulti,
      name: name,
      value: isMulti ? value : value[valueField],
    };
    onChange(object);
  };

  return (
    <styles.maincontainer>
      {/* <styles.labelContainer> */}
      {/* </styles.labelContainer> */}
      {/* <styles.container isFocused={false}> */}

      <styles.select
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        classNamePrefix='Select'
        placeholder={""}
        name={name}
        isMulti={isMulti}
        // onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        onChange={onSelectChange}
        value={isMulti ? value : options.filter((option) => option?.value === value)}
        // disabled={readOnly}
        options={options}
        error={error}
        {...attributes}
        isDisabled={readOnly}
        // isClearable={true}
      />
      <styles.label
        className={
          isFocused || defaultFocused || (value !== null && value !== undefined) ? "focused" : ""
        }
      >
        {label}
        {mandatory ? " *" : ""}
      </styles.label>

      {/* </styles.container> */}
      <styles.errorText>{error ? error : <br />}</styles.errorText>
    </styles.maincontainer>
  );
};

export default Select;
