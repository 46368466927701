import styled from "styled-components";

import { colors } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 100000000;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  background-color: ${colors.common.blurBackground};
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  margin-top: 4vh;
  width: 353px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid ${colors.common.primary};
  background: ${colors.common.white};
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
`;

export const LoadingTitle = styled.h2`
  color: ${colors.common.primary};
  font-family: ${colors.common.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AnimatedLoader = styled.div`
  margin-top: 10px;
  span {
    margin: 0 5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${colors.common.primary};
    opacity: 0;
  }

  span:nth-child(1) {
    animation: opacitychange 1s ease-in-out infinite;
  }

  span:nth-child(2) {
    animation: opacitychange 1s ease-in-out 0.33s infinite;
  }

  span:nth-child(3) {
    animation: opacitychange 1s ease-in-out 0.66s infinite;
  }

  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }
  }
`;
