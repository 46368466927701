import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import { images } from "../../../../theme";
import { GroupsControllerProps, User } from "../../../../types/models/Groups";
import { collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import {
  ActivityLogs,
  FormChecklist,
  Popup,
  TableController,
  TableListV3,
  TableV3,
  WorkLogs,
} from "../../../components";
import { TextInput, Select, SystemData, Button, MyTooltip } from "../../../components/atoms";
import { Switch } from "../../../components/atoms/Switch/Switch";
import { SlushTable } from "../../../components/organisms/SlushTable/SlushTable";

import { useGroupsController } from "./hooks/useGroupsController";
import {
  ActionContainer,
  ButtonRow,
  CloseIcon,
  CloseModalButton,
  DropdownListContainer,
  EditHead,
  EditHeading,
  FooterContainer,
  Icon,
} from "./styles";

export const GroupsController = ({ type }: GroupsControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions, departmentOptions, usersOptions } = useControllerConfigs();
  const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
  const [openMembersModal, setOpenMembersModal] = useState<boolean>(false);
  const [openEditMembers, setOpenEditMembers] = useState<boolean>(false);
  const [editMemberValue, setEditMemberValue] = useState<{}>({});
  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchGroups,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    checklist,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    memberSelectValue,
    permissionList,
    setPermissionList,
    handleMemberSelectValue,
    handlePermissionSwitch,
    membersList,
    setMembersList,
    setAvailableList,
    availableList,
    setSelectedList,
    selectedList,
    handleAvailableSelectedList,
    handleMemberDelete,
    membersAvailableList,
    membersSelectedList,
    setMembersAvailableList,
    setMembersSelectedList,
    handleAllAdd,
  } = useGroupsController({ type, id: params.id, attachmentsPickerRef });
  const currentGroups = data;

  const { workLogs, attachments, name, description, groupCountry, groupDept, active } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchGroups();
    }
    handleAvailableSelectedList(collections);
    handleAvailableSelectedMembersList(usersOptions);
    // Do not include fetchGroups to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              required: name.required,
              onChange: onTextChange,
            }}
            width={"46%"}
            label={"Name"}
            type={"text"}
            error={name?.error}
          />
          <Select
            mandatory={active.required}
            width={"46%"}
            name={"active"}
            label={"Active"}
            defaultFocused={true}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            value={active.value}
            error={active.error}
            onChange={onTextChange}
          />
        </Row>

        <Row>
          <Select
            mandatory={formData.type.required}
            width={"46%"}
            name={"type"}
            label={"Type"}
            defaultFocused={true}
            options={[
              { label: "Permissions", value: "permissions" },
              { label: "Knowledgebase", value: "knowledgebase" },
            ]}
            value={formData.type?.value}
            onChange={onTextChange}
            error={formData.type?.error}
          />
        </Row>

        <ButtonRow>
          <Select
            mandatory={groupCountry.required}
            width={"80%"}
            name={"groupCountry"}
            label={"Country"}
            isMulti={true}
            options={countryOptions}
            value={groupCountry.value}
            onChange={onTextChange}
            error={groupCountry?.error}
          />
          <Button title='All' onClick={() => handleAllAdd("groupCountry")} />
        </ButtonRow>
        <ButtonRow>
          <Select
            width={"80%"}
            mandatory={groupDept.required}
            name={"groupDept"}
            isMulti
            label={"Department"}
            options={departmentOptions}
            value={groupDept.value}
            onChange={onTextChange}
            error={groupDept?.error}
          />
          <Button title='All' onClick={() => handleAllAdd("groupDept")} />
        </ButtonRow>
        <Row>
          <TextInput
            type={"textarea"}
            textAreaProps={{
              name: "description",
              rows: 8,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
            width={"100%"}
            label={"Description"}
            error={description?.error}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.value,
    description.error,
    description.value,
    description.required,
    groupCountry.error,
    groupCountry.value,
    groupDept?.error,
    active.required,
    formData.type.required,
    groupCountry.required,
    groupDept.required,
    name.required,
    groupDept.value,
    name?.error,
    name.value,
    onTextChange,
    countryOptions,
    departmentOptions,
    formData.type.error,
    formData.type.value,
    handleAllAdd,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentGroups?.createdBy !== "string" ? currentGroups?.createdBy?.name : "";
    const updatedBy =
      typeof currentGroups?.updatedBy !== "string" ? currentGroups?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentGroups?.createdAt}
        updatedAt={currentGroups?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentGroups?.createdAt,
    currentGroups?.createdBy,
    currentGroups?.updatedAt,
    currentGroups?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklist
        onSubmitAddChecklist={onSubmitAddChecklist}
        onSubmitUpdateChecklist={onSubmitUpdateChecklist}
        checklist={checklist}
        deleteChecklist={deleteChecklist}
      />
    ),

    [onSubmitAddChecklist, onSubmitUpdateChecklist, checklist, deleteChecklist],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.groups.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const permissionColumns = useMemo(
    () => [
      {
        title: "Table",
        key: "table",
        render: (item) => {
          return <text className='title'>{item?.label || ""}</text>;
        },
      },
      {
        title: "Create",
        width: "150px",
        key: "create",
        render: (item) => {
          return (
            <Switch
              // type={add}
              name={`${item.groupName}_add`}
              onPress={handlePermissionSwitch}
              value={item[`${item.groupName}_add`] === true ? 1 : 0}
            />
          );
        },
      },
      {
        title: "View",
        width: "150px",
        key: "view",
        render: (item) => {
          return (
            <Switch
              // data-type={add}
              name={`${item.groupName}_view`}
              onPress={handlePermissionSwitch}
              value={item[`${item.groupName}_view`] === true ? 1 : 0}
            />
          );
        },
      },
      {
        title: "Update",
        width: "150px",
        key: "update",
        render: (item) => {
          return (
            <Switch
              // type={add}
              name={`${item.groupName}_edit`}
              onPress={handlePermissionSwitch}
              value={item[`${item.groupName}_edit`] === true ? 1 : 0}
            />
          );
        },
      },
      {
        title: "Delete",
        width: "150px",
        key: "delete",
        render: (item) => {
          return (
            <Switch
              // type={add}
              name={`${item.groupName}_delete`}
              onPress={handlePermissionSwitch}
              value={item[`${item.groupName}_delete`] === true ? 1 : 0}
            />
          );
        },
      },
      {
        title: "List",
        width: "150px",
        key: "list",
        render: (item) => {
          return (
            <Switch
              // type={add}
              name={`${item.groupName}_list`}
              onPress={handlePermissionSwitch}
              value={item[`${item.groupName}_list`] === true ? 1 : 0}
            />
          );
        },
      },
      {
        title: "All",
        width: "150px",
        key: "all",
        render: (item) => {
          const isAllActive =
            item[`${item.groupName}_add`] &&
            item[`${item.groupName}_view`] &&
            item[`${item.groupName}_edit`] &&
            item[`${item.groupName}_delete`] &&
            item[`${item.groupName}_list`];
          return (
            <Switch
              // type={add}
              name={`${item.groupName}_all`}
              onPress={(e) => handlePermissionSwitch(e, true)}
              value={isAllActive ? 1 : 0}
            />
          );
        },
      },
    ],
    [handlePermissionSwitch],
  );

  const membersColumns = useMemo(
    () => [
      {
        title: "Users",
        width: "400px",
        key: "user",
        render: (item) => {
          return <div className='title'>{item.user.name}</div>;
        },
      },
      {
        title: "Type",
        width: "400px",
        key: "role",
      },
      {
        title: "Actions",
        key: "action",
        render: (item) => {
          return (
            <ActionContainer>
              <MyTooltip text='Edit'>
                <Icon
                  src={images.blackPencil}
                  onClick={() => {
                    handleEditMembersModal();
                    setEditMemberValue({ item });
                  }}
                />
              </MyTooltip>
              <MyTooltip text='Delete'>
                <Icon src={images.deleteAttachment} onClick={() => handleMemberDelete(item)} />
              </MyTooltip>
            </ActionContainer>
          );
        },
      },
    ],
    [handleMemberDelete],
  );

  const handleAvailableSelectedMembersList = useCallback(
    (list: User[]) => {
      const selectedList: User[] = [];
      const availableList: User[] = [];
      Object.values(list).map((user) => {
        if (user?.isSelected) {
          selectedList.push(user);
        } else {
          availableList.push(user);
        }
      });
      setMembersSelectedList((prev) => {
        return [...prev, ...selectedList];
      });
      setMembersAvailableList((prev) => {
        return [...prev, ...availableList];
      });
    },
    [setMembersAvailableList, setMembersSelectedList],
  );

  const setPermissionsArray = (inputObject) => {
    const groupedData = {};

    // Iterate through each key-value pair in the inputObject
    for (const [key, value] of Object.entries(inputObject)) {
      // Extract the group name from the key
      const groupName = key.split("_")[0];

      // Create a new object for each group if it doesn't exist
      if (!groupedData[groupName]) {
        groupedData[groupName] = {
          groupName: groupName,
          label: collections?.[groupName]?.label,
        };
      }

      // Add the action field to the current group
      groupedData[groupName][key] = value;
    }

    // Convert the groupedData object into an array of objects
    const resultArray = Object.values(groupedData);
    return resultArray;
  };

  const groupPermissions = useCallback(
    (list, fullAccess = false) => {
      setSelectedList(list);
      setPermissionList((prevPermissionList) => {
        let inputObject = {
          ...prevPermissionList.reduce((acc, user) => ({ ...acc, ...user }), {}),
        };

        list?.forEach((user) => {
          // Check if the user is already present in the permissionList
          const existingUser = prevPermissionList.find((item) => item.groupName === user?.name);

          if (!existingUser) {
            const userObject = {
              [`${user?.name}_add`]: fullAccess,
              [`${user?.name}_edit`]: fullAccess,
              [`${user?.name}_list`]: fullAccess,
              [`${user?.name}_view`]: fullAccess,
              [`${user?.name}_delete`]: fullAccess,
            };
            inputObject = { ...inputObject, ...userObject };
          } else {
            // If fullAccess is true, set all values to true for the existing user
            if (fullAccess) {
              const userObject = {
                [`${user?.name}_add`]: true,
                [`${user?.name}_edit`]: true,
                [`${user?.name}_list`]: true,
                [`${user?.name}_view`]: true,
                [`${user?.name}_delete`]: true,
              };
              inputObject = { ...inputObject, ...userObject };
            }
          }
        });

        // Remove keys corresponding to users not present in the current list
        const currentUsers = list.map((user) => user.name);
        Object.keys(inputObject).forEach((key) => {
          const groupName = key.split("_")[0];
          if (!currentUsers.includes(groupName)) {
            delete inputObject[key];
          }
        });

        // Create availableList including objects from collections not present in the list
        const availableList = Object.keys(collections)
          .filter((name) => !currentUsers.includes(name))
          .filter(
            (name) => !["checklistTemplate", "dashboardWidgets", "dashboardTabs"].includes(name),
          )
          .map((name) => ({ name, label: collections[name]?.label }));

        setAvailableList(availableList);

        // Convert the groupedData object into an array of objects
        const resultArray = setPermissionsArray(inputObject);
        return [...resultArray];
      });
    },
    [setPermissionList, setSelectedList, setAvailableList],
  );

  const handleAddTable = useCallback(() => {
    const selectedUserNames = selectedList.map((user) => user.name);
    const filteredAvailableList = availableList.filter(
      (user) => !selectedUserNames.includes(user.name),
    );
    setAvailableList(filteredAvailableList);
    setOpenPermissionModal((prev) => !prev);
  }, [availableList, setAvailableList, selectedList]);
  const permissions = useMemo(
    () => (
      <>
        <TableListV3
          headerButtons={[
            {
              title: "Add Tables",
              onClick: handleAddTable,
              visible: true,
            },
          ]}
          headerTitle={"Permissions"}
          loading={false}
          columns={permissionColumns}
          list={permissionList}
          disableSelect={true}
          disableFilter={true}
          primaryTable={false}
        />
        <SlushTable
          open={openPermissionModal}
          setOpen={setOpenPermissionModal}
          title={{ label: "Add Tables", value: "Add Tables" }}
          AvailableList={availableList}
          SelectedList={selectedList}
          fullAccessActive={true}
          setAvailableList={setAvailableList}
          noRecordMessage='No Tables Found'
          setSelectedList={setSelectedList}
          groupPermissions={groupPermissions}
        />
      </>
    ),
    [
      openPermissionModal,
      availableList,
      selectedList,
      handleAddTable,
      permissionColumns,
      permissionList,
      groupPermissions,
      setAvailableList,
      setSelectedList,
    ],
  );
  const handleMembersList = useCallback(
    (list: []) => {
      const filteredPrev = membersList.filter((item) => item.role !== memberSelectValue.value);

      // Map the new members without checking for duplicates initially
      const newMembersList = list.map((user) => ({
        user: { name: user.label, _id: user.value },
        role: memberSelectValue.value,
      }));

      // Merge both arrays without filtering
      const mergedList = [...filteredPrev, ...newMembersList];

      // Filter out duplicates based on user._id
      const uniqueMembersList = mergedList.filter(
        (value, index, self) => index === self.findIndex((v) => v.user._id === value.user._id),
      );

      // Add users not present in uniqueMembersList to setMembersAvailableList
      const usersNotInUniqueList = membersList.filter(
        (user) => !uniqueMembersList.some((uniqueUser) => uniqueUser.user._id === user.user._id),
      );

      // Create objects with 'label' and 'value' fields and add to setMembersAvailableList
      const availableListObjects = usersNotInUniqueList.map((user) => ({
        label: user.user.name,
        value: user.user._id,
      }));

      setMembersAvailableList((prevAvailableList) => [
        ...prevAvailableList,
        ...availableListObjects,
      ]);

      setMembersList(uniqueMembersList);
      setMembersSelectedList(uniqueMembersList);
      handleMemberSelectValue("");
    },
    [
      membersList,
      setMembersList,
      setMembersSelectedList,
      handleMemberSelectValue,
      memberSelectValue.value,
      setMembersAvailableList,
    ],
  );

  const memoizedHandleMemberList = useMemo(() => {
    const handleMemberList = (type: { label: string; value: string }) => {
      const updatedList: any[] = membersList
        .filter((user) => user.role === type.value)
        .map((user) => ({
          label: user.user.name,
          value: user.user._id,
          isSelected: true,
        }));

      const selectedUserIds = updatedList.map((user) => user.value); // Use value instead of _id

      // Filter availableList to exclude users present in selectedList
      const filteredAvailableList = membersAvailableList.filter(
        (user) => !selectedUserIds.includes(user.value),
      );

      // Update availableList with the filtered list
      setMembersAvailableList(filteredAvailableList);
      setMembersSelectedList(updatedList);
    };

    return handleMemberList;
  }, [membersList, membersAvailableList, setMembersAvailableList, setMembersSelectedList]);

  const handleEditMembersModal = () => {
    setOpenEditMembers((prev) => !prev);
  };

  const typeOptions = useMemo(() => {
    const options: { label: string; value: string }[] = [
      { label: "Co-Manager", value: "comanager" },
      { label: "Members", value: "member" },
      { label: "Manager", value: "manager" },
    ];
    return options;
  }, []);

  const handleEditMember = (event) => {
    setEditMemberValue((prev) => {
      return { item: { ...prev.item, role: event.target.value } };
    });
  };
  const handleEditSaveData = useCallback(() => {
    const updatedList = membersList.map((user) => {
      if (user.user._id === editMemberValue?.item?.user?._id) {
        return editMemberValue.item;
      }
      return user;
    });
    setMembersList(updatedList);
    setEditMemberValue({});
    setOpenEditMembers(false);
  }, [membersList, editMemberValue, setMembersList]);

  const handleAddMember = useCallback(() => {
    handleMemberSelectValue({ label: "Add Members", value: "member" });
    memoizedHandleMemberList({ label: "Add Members", value: "member" });
    setOpenMembersModal((prev) => !prev);
  }, [memoizedHandleMemberList, handleMemberSelectValue]);

  const handleAddCoManger = useCallback(() => {
    handleMemberSelectValue({ label: "Add Co-Manager", value: "comanager" });
    memoizedHandleMemberList({ label: "Add Co-Manager", value: "comanager" });
    setOpenMembersModal((prev) => !prev);
  }, [memoizedHandleMemberList, handleMemberSelectValue]);

  const handleAddManager = useCallback(() => {
    handleMemberSelectValue({ label: "Add Manager", value: "manager" });
    memoizedHandleMemberList({ label: "Add Manager", value: "manager" });
    setOpenMembersModal((prev) => !prev);
  }, [memoizedHandleMemberList, handleMemberSelectValue]);
  const memebers = useMemo(
    () => (
      <>
        <TableListV3
          headerButtons={[
            {
              title: "Add Members",
              onClick: handleAddMember,
              visible: true,
            },
            {
              title: "Add Co-Manager",
              onClick: handleAddCoManger,
              visible: true,
            },
            {
              title: "Add Manager",
              onClick: handleAddManager,
              visible: true,
            },
          ]}
          headerTitle={"Members"}
          list={membersList}
          columns={membersColumns}
          loading={false}
          disableFilter={true}
          disableSelect={true}
          primaryTable={false}
        />
        <SlushTable
          open={openMembersModal}
          setOpen={setOpenMembersModal}
          title={memberSelectValue}
          fullAccessActive={false}
          AvailableList={membersAvailableList}
          SelectedList={membersSelectedList}
          noRecordMessage='No Users Found'
          setAvailableList={setMembersAvailableList}
          setSelectedList={setMembersSelectedList}
          groupPermissions={handleMembersList}
        />
        <Popup isOpen={openEditMembers} closeModal={handleEditMembersModal}>
          <DropdownListContainer>
            <EditHead>
              <EditHeading>Edit Member</EditHeading>
              <CloseModalButton>
                <CloseIcon
                  src={images.closeModal}
                  onClick={() => {
                    handleEditMembersModal();
                  }}
                />
              </CloseModalButton>
            </EditHead>
            <div>
              <TextInput
                width='100%'
                disabled
                label='Name'
                inputProps={{
                  name: "name",
                  value: editMemberValue?.item?.user?.name,
                  autoComplete: "off",
                  required: true,
                  disabled: true,
                }}
              />
              <Select
                options={typeOptions}
                label='Type'
                width='100%'
                name='edit-member'
                onChange={handleEditMember}
                value={editMemberValue?.item?.role}
                error=''
              />
            </div>

            <FooterContainer>
              <Button title='Save' onClick={handleEditSaveData} />
            </FooterContainer>
          </DropdownListContainer>
        </Popup>
      </>
    ),
    [
      openMembersModal,
      memberSelectValue,
      editMemberValue,
      membersSelectedList,
      membersAvailableList,
      membersColumns,
      handleAddCoManger,
      handleAddManager,
      handleAddMember,
      membersList,
      handleEditSaveData,
      typeOptions,
      openEditMembers,
      handleMembersList,
      setMembersAvailableList,
      setMembersSelectedList,
    ],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return permissions;
      case 2:
        return memebers;
      case 3:
        return renderChecklist;
      case 4:
        return renderActivitylogs;
      case 5:
        return renderWorklogs;
      case 6:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    renderActivitylogs,
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    permissions,
    memebers,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
