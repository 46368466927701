import Types from "./actionType";
import {
  FetchAnnouncementsPayload,
  CreateAnnouncements,
  CreateAnnouncementsPayload,
  FetchAnnouncements,
  FetchAnnouncementsError,
  FetchAnnouncementsSuccess,
  UpdateAnnouncements,
  UpdateAnnouncementsPayload,
  AnnouncementsListWithMetaData,
  FetchAnnouncementsByIdPayload,
  FetchAnnouncementsById,
  DeleteAnnouncementsPayload,
  DeleteAnnouncements,
} from "./types";

export const fetchAnnouncementsList = ({
  params,
  onSuccess,
  onError,
}: FetchAnnouncementsPayload): FetchAnnouncements => ({
  type: Types.FETCH_ANNOUNCEMENTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAnnouncementsById = ({
  id,
  onSuccess,
  onError,
}: FetchAnnouncementsByIdPayload): FetchAnnouncementsById => ({
  type: Types.FETCH_ANNOUNCEMENTS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAnnouncementsListSuccess = (
  data: AnnouncementsListWithMetaData,
): FetchAnnouncementsSuccess => ({
  type: Types.SUCCESS_FETCH_ANNOUNCEMENTS,
  payload: {
    data,
  },
});

export const fetchAnnouncementsError = (error?: string): FetchAnnouncementsError => ({
  type: Types.ERROR_FETCH_ANNOUNCEMENTS,
  payload: { error },
});

export const createAnnouncements = ({
  data,
  onSuccess,
  onError,
}: CreateAnnouncementsPayload): CreateAnnouncements => ({
  type: Types.CREATE_ANNOUNCEMENTS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAnnouncements = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAnnouncementsPayload): UpdateAnnouncements => ({
  type: Types.UPDATE_ANNOUNCEMENTS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAnnouncements = ({
  id,
  onSuccess,
  onError,
}: DeleteAnnouncementsPayload): DeleteAnnouncements => ({
  type: Types.DELETE_ANNOUNCEMENTS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
