import styled from "styled-components";

import { colors, fs, fonts } from "../../../../theme";

export const Container = styled.div`
  width: 90%;
  height: 195px;
  /* margin-top: 30px; */
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: ${colors.common.white};
  position: relative;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 43px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ImageContainer = styled.div`
  height: 38px;
  width: 38px;
  display: flex;
  border-radius: 10px;
  background-color: ${colors.common.primary};
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`;

export const CardTitle = styled.div`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-size: ${fs.m};
  line-height: 20px;
  margin-top: 10px;
  color: ${colors.common.black};
`;

export const Description = styled.div`
  text-align: center;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 30px;
  margin: 10px 8.65%;
  font-size: ${fs.xs};
  line-height: 15px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${fonts.SourceSansPro};
`;

export const Button = styled.div`
  display: flex;
  padding: 5px 13px;
  border-radius: 20px;
  border: 1px solid ${colors.common.primary};
  color: ${colors.common.primary};
  /* margin-top: 5px; */
  cursor: pointer;
  font-size: ${fs.xs};
  line-height: 15px;
  font-family: ${fonts.SourceSansPro};
  transition: linear 0.4s;
  &:hover {
    color: ${colors.common.titleColor};
    background-color: ${colors.common.primary};
  }
`;
