import React, { useEffect } from "react";

import moment from "moment-timezone";
import { MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../components/Button/button";
import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import { Header as MainHeader } from "../../presentationals/components";
import { getAllTickets } from "../../redux/tickets/actions";
import { serialPrefixTickets, useTitle, MomentDate } from "../../services/methods";
import { Sort } from "../../services/methods";

import styles from "./TicketsListing.styles";

const TicketsListing = (props) => {
  const navigate = useNavigate();
  useTitle("Tickets");

  useEffect(() => {
    props.getAllTickets({ admin: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StatusRender = (status) => {
    let output = "";
    switch (status) {
      case "awaiting_reply":
        output = "Replied";
        break;
      case "replied":
        output = "Awaiting Reply";
        break;
      case "closed":
        output = "Ticket Closed";
        break;
      default:
        break;
    }
    return output;
  };

  const data = () => {
    return;
  };

  const renderData = () => {
    let array = props.tickets.filter(
      (ticket) =>
        ticket?.status?.includes(props.filters.status) &&
        ticket?.userId?._id?.includes(props.filters.requestedFor) &&
        (props.filters.assignee ? ticket?.assignee?._id?.includes(props.filters.assignee) : true) &&
        (ticket?.createdBy ? ticket?.createdBy?._id?.includes(props.filters.createdBy) : true),
    );
    let serial = 0;
    if (props.filters.sortKey) {
      array = Sort({
        data: array,
        field: props.filters.sortKey?.key,
        asc: props.filters.sortType === "asc",
        attributeKey: props.filters.sortKey?.attribute,
        isDate: props.filters.sortKey?.isDate,
      });
    }
    return array.map((ticket, index) => {
      serial++;
      return (
        <tr key={index}>
          <td>{serial}</td>
          <td align='center'>
            {MomentDate({
              date: ticket.createdAt,
              format: "YYYY-MM-DD",
            })}
          </td>
          <td>
            {ticket.subject}
            <br />
            <span style={{ fontSize: 10, color: "#333" }}>
              {serialPrefixTickets(ticket.serial)}
            </span>
          </td>
          <td align='center'>
            <span style={{ fontSize: 10 }}>Created By:</span>{" "}
            <span style={{ fontSize: 10, fontWeight: 500 }}>{ticket?.createdBy?.name}</span>
            <br />
            <span style={{ fontSize: 10 }}>Assigned To:</span>{" "}
            <span style={{ fontSize: 10, fontWeight: 500 }}>
              {ticket.assignee ? ticket.assignee.name : "-"}
            </span>
            <br />
            <span style={{ fontSize: 10 }}>Requested For:</span>{" "}
            <span style={{ fontSize: 10, fontWeight: 500 }}>{ticket.userId.name}</span>
          </td>

          <td align='center'>{StatusRender(ticket.status)}</td>
          <td align='center'>
            <Link to={`/ap/tickets/${ticket._id}/details`}>
              <MdArrowRightAlt size={20} />
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <styles.MainContainer>
      <MainHeader title='Service Tickets' />
      <Header
        title='Service Tickets'
        buttons={[
          {
            title: "Open A Request",
            onPress: {
              event: navigate,
              params: "/ap/tickets/add",
            },
            visible: true,
          },
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/tickets/filters",
            },
            visible: true,
          },
        ]}
        onSync={{
          event: props.getAllTickets,
          params: { admin: true },
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              Sno.
            </th>
            <th>Created</th>
            <th align='left' width='30%'>
              Subject
            </th>
            <th>Users</th>
            <th>Status</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.TicketsReducer.filters,
  tickets: state.TicketsReducer.ticketsAdmin,
});

const mapDispatchToProps = {
  getAllTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsListing);
