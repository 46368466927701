import React from "react";

import { Layout } from "../../../presentationals";
import { CurrencyController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CurrencyController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APCurrencyEdit = React.memo(Route);
