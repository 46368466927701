import React from "react";

import { images } from "../../../../theme";

import { ParentWrapper, Wrapper, Label, ErrorText, CheckboxImage } from "./styles";

interface Props {
  label: string;
  error?: string;
  labelClass?: string;
  wrapperClass?: string;
  width?: string;
  disabled?: boolean;
  containerClass?: string;
  hidden?: boolean;
  required?: boolean;
  value?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  attributes?: any;
  reverse?: boolean;
}

export const Checkbox = ({
  label,
  error = "",
  wrapperClass,
  labelClass,
  width,
  disabled = false,
  containerClass,
  hidden = false,
  required,
  value,
  onClick,
  attributes = {},
  reverse,
}: Props) => {
  return (
    <ParentWrapper width={width} hidden={hidden} className={containerClass}>
      <Wrapper error={error} disabled={disabled} className={wrapperClass}>
        {reverse ? (
          <>
            {!!label && (
              <Label disabled={disabled} className={labelClass}>
                {label}
                {required && <span>{"*"}</span>}
              </Label>
            )}
            <CheckboxImage
              onClick={onClick}
              src={value ? images.checkedCheckbox : images.uncheckedCheckbox}
              {...attributes}
            />
          </>
        ) : (
          <>
            <CheckboxImage
              onClick={onClick}
              src={value ? images.checkedCheckbox : images.uncheckedCheckbox}
              {...attributes}
            />
            {!!label && (
              <Label disabled={disabled} className={labelClass}>
                {label}
                {required && <span>{"*"}</span>}
              </Label>
            )}
          </>
        )}
      </Wrapper>
      {!!error && <ErrorText>{error ? error : <br />}</ErrorText>}
    </ParentWrapper>
  );
};
