import React, { useEffect, useRef, useState } from "react";

// additional imports
import { MdCancel } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { useControllerConfigs } from "../../presentationals/common";
import { uploadFile } from "../../redux/application/actions";
import { fetchControllerConfigs } from "../../redux/application/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchKnowledgebase,
  createKnowledgebase,
  updateKnowledgebase,
} from "../../redux/knowledgebase/actions";
import { formValidationMethod, useTitle, MomentDate } from "../../services/methods";

import styles from "./KnowledgebaseController.styles";

const KnowledgebaseController = (props) => {
  const params = useParams();
  const groupsOptions = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.groups,
  );
  const roles = ["Member", "Manager", "Owner"];
  const navigate = useNavigate();
  const readOnly = ["edit", "add", "member"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useAppDispatch();

  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const defaultData = {
    title: {
      required: true,
      type: "",
      error: "",
      value: "",
    },

    description: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    members: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    contributers: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    approval: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    icon: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    active: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
  };

  useEffect(() => {
    if (props.type === "view" || props.type === "edit" || props.type === "member") {
      if (id !== params.id || viewType.current !== props.type) {
        viewType.current = props.type;
        setFormData(defaultData);
        setSelectedMember("");
        setSelectedRole("");
        setSelectedGroup("");
        setLoader(true);
        if (props.knowledgebase.length > 0) {
          const find = props.knowledgebase.find((item) => item._id === params.id);
          if (find) {
            updateValues(find);
            setLoader(false);
            setSubmitting(false);
            setId(find._id);
            setToast({
              message: "",
              message_type: "",
            });
          }
        }
      }
    } else if (props.type === "add") {
      viewType.current = props.type;
      setLoader(false);
      setSubmitting(false);
      setFormData(defaultData);
      setId("");
      setToast({
        message: "",
        message_type: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.knowledgebase]);

  const [formData, setFormData] = useState(defaultData);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [members, setMembers] = useState({});
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedGroupDec, setSelectedGroupDec] = useState("");
  const [tab, setTab] = useState("0");
  const [metaData, setMetaData] = useState({});
  const updateValues = (data) => {
    const object = { ...formData };
    console.log("data", data);
    const metaData = {
      createdBy: data?.createdBy?.name,
      createdAt: data?.createdAt,
      updatedBy: data?.updatedBy?.name,
      updatedAt: data?.updatedAt,
    };
    setMetaData(metaData);
    Object.keys(data).forEach((element) => {
      const name = element;
      const value = data[element];
      if (formData[element] && value !== undefined && value !== null) {
        object[name] = {
          ...object[name],
          value: value,
        };
      }
    });
    setFormData(object);
    filterMembers(object);
  };

  const filterMembers = (object) => {
    if (props.type == "member" && Array.isArray(object.members.value)) {
      const members = {};
      for (let i = 0; i < object.members.value.length; i++) {
        const item = object.members.value[i];
        if (members[item?.role]) {
          members[item.role] = [...members[item.role], item];
        } else {
          members[item.role] = [item];
        }
      }
      setMembers(members);
    }
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const error = formValidationMethod(name, value, formData);

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Knowledgebase";
      case "add":
        return "Add Knowledgebase";
      case "edit":
        return "Edit Knowledgebase";
      case "member":
        return "Edit Members";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const onSubmitAddForm = () => {
    const submit = validateForm();
    const generateData = {};

    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      setSubmitting(true);
      props.createKnowledgebase({
        data: generateData,
        onSuccess: (data) => {
          dispatch(fetchControllerConfigs({}));
          props.fetchKnowledgebase({
            onSuccess: () => {
              navigate("/ap/knowledgebase");
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);

    return output;
  };

  const onSubmitUpdateForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      setSubmitting(true);
      props.updateKnowledgebase({
        id: id,
        data: generateData,
        onSuccess: (data) => {
          dispatch(fetchControllerConfigs({}));
          props.fetchKnowledgebase({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 || submitting ? true : false;
    return value;
  };

  const addDisabled = (type) => {
    if (type == "member") {
      if (selectedMember.length > 0 && selectedRole.length > 0) {
        return false;
      }
      return true;
    } else if (type == "group") {
      if (selectedGroup.length > 0) {
        return false;
      }
      return true;
    }
  };

  const handleSelectMember = (e) => {
    const value = e.target.value;
    setSelectedMember(value);
  };

  const handleSelectGroup = (e) => {
    const value = e.target.value;
    setSelectedGroup(value);
    const dec = groupsOptions.find((item) => item?._id == value)?.description;
    setSelectedGroupDec(dec);
  };

  const handleSelectRole = (e) => {
    const value = e.target.value;
    setSelectedRole(value);
  };

  const addMember = () => {
    const currentMembers = [...formData.members.value];
    const current = props.users.find((item) => item._id == selectedMember);
    const index = currentMembers.find((item) => item.user._id == current._id);
    if (index) {
      alert(`${index.user.name} is already ${index.role}`);
      return;
    }
    currentMembers.push({
      user: {
        _id: current._id,
        name: current.name,
      },
      role: selectedRole.toLowerCase(),
    });
    const object = {
      ...formData,
      members: {
        ...formData["members"],
        value: currentMembers,
      },
    };
    setFormData(object);
    filterMembers(object);
    setSelectedMember("");
    setSelectedRole("");
  };
  const addGroup = () => {
    const currentGroups = [...formData.contributers.value];
    const current = groupsOptions?.find((item) => item?._id == selectedGroup);
    const index = currentGroups.find((item) => item._id == current._id);
    if (index) {
      alert(`${index.name}(${index.serial}) is already added`);
      return;
    }
    currentGroups.push({
      _id: current._id,
      name: current.name,
      members: current.members,
      serial: current.serial,
    });
    const object = {
      ...formData,
      contributers: {
        ...formData["contributers"],
        value: currentGroups,
      },
    };
    setFormData(object);
    setSelectedGroup({});
    setSelectedGroupDec("");
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      type: "public",
      onSuccess: ({ id, location }) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            value: id,
            error: ``,
          },
        });
      },
      onError: (err) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };
  const removeMember = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const currentMembers = [...formData.members.value];
    const current = currentMembers.findIndex((item) => item.user._id == id);
    currentMembers.splice(current, 1);
    const object = {
      ...formData,
      members: {
        ...formData["members"],
        value: currentMembers,
      },
    };
    setFormData(object);
    filterMembers(object);
  };
  const removeGroup = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const currentGroups = [...formData.contributers.value];
    const current = currentGroups.findIndex((item) => item._id == id);
    currentGroups.splice(current, 1);
    const object = {
      ...formData,
      contributers: {
        ...formData["contributers"],
        value: currentGroups,
      },
    };
    console.log(object, currentGroups, current);
    setFormData(object);
  };
  const Tabs = () => {
    const tabs = [
      {
        tab: "0",
        label: "Members",
      },
      {
        tab: "1",
        label: "Groups",
      },
    ];
    return tabs;
  };
  const updateTab = (e) => {
    setTab(e.currentTarget.getAttribute("data-key"));
  };
  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header onClick={() => console.log(formData)}>
        <h2>{getTitle()}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form
        onSubmit={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        autoComplete='off'
      >
        {props.type == "member" ? (
          <>
            <styles.TabContainer>
              {Tabs().map((item) => (
                <styles.TabItem
                  key={item.tab}
                  onClick={updateTab}
                  data-key={item.tab}
                  data-tab={item.tab}
                  active={tab === item.tab}
                  // error={item.fields ? CheckForTabError(item.fields) : false}
                >
                  {" "}
                  {item.label}
                </styles.TabItem>
              ))}
            </styles.TabContainer>
            {tab == "0" ? (
              <>
                <Select
                  name={"member"}
                  label={"Select Member"}
                  defaultFocused={true}
                  options={[
                    { label: "Select...", value: "" },
                    ...props.users.map((value) => ({
                      label: `${value.name}`,
                      value: value._id,
                    })),
                  ]}
                  value={selectedMember}
                  onChange={handleSelectMember}
                />
                <Select
                  name={"role"}
                  label={"Select Role"}
                  defaultFocused={true}
                  options={[
                    { label: "Select...", value: "" },
                    ...roles.map((value) => ({
                      label: value,
                      value: value.toLowerCase(),
                    })),
                  ]}
                  value={selectedRole}
                  onChange={handleSelectRole}
                />
                <Button
                  onClick={addMember}
                  disabled={SubmitDisabled() || addDisabled("member")}
                  size={"small"}
                  label={"Add"}
                />
                {Object.keys(members).map((item, index) => {
                  if (Array.isArray(members[item])) {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: "3%",
                          marginBottom: "3%",
                        }}
                      >
                        <styles.memberTitle>{item}</styles.memberTitle>
                        {members[item].map((item, index) => {
                          return (
                            <styles.memberItemContainer key={index}>
                              <styles.memberItem>{item?.user?.name}</styles.memberItem>
                              <styles.removeContainer
                                data-id={item?.user?._id}
                                onClick={removeMember}
                              >
                                <MdCancel size={20} />
                              </styles.removeContainer>
                            </styles.memberItemContainer>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <>
                <Select
                  name={"group"}
                  label={"Select Group"}
                  defaultFocused={true}
                  options={[
                    { label: "Select...", value: "" },
                    ...groupsOptions.map((value) => ({
                      label: `${value.name} (${value.serial})`,
                      value: value._id,
                    })),
                  ]}
                  value={selectedGroup}
                  onChange={handleSelectGroup}
                />
                {selectedGroupDec && <styles.dec>{selectedGroupDec}</styles.dec>}
                <Button
                  onClick={addGroup}
                  disabled={SubmitDisabled() || addDisabled("group")}
                  size={"small"}
                  label={"Add"}
                />
                {Array.isArray(formData.contributers.value) && (
                  <div
                    style={{
                      marginTop: "3%",
                      marginBottom: "3%",
                    }}
                  >
                    <styles.memberTitle>Groups</styles.memberTitle>
                    {formData.contributers.value.map((item, index) => {
                      return (
                        <styles.memberItemContainer key={index}>
                          <styles.memberItem>{`${item.name}(${item.serial})`} </styles.memberItem>
                          <styles.removeContainer data-id={item?._id} onClick={removeGroup}>
                            <MdCancel size={20} />
                          </styles.removeContainer>
                        </styles.memberItemContainer>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Input
              readOnly={readOnly}
              label={"Title"}
              type={"text"}
              name='title'
              value={formData.title.value}
              autoComplete={"off"}
              onChange={onTextChange}
              mandatory={true}
              error={formData?.title?.error}
            />
            <Input
              readOnly={readOnly}
              label={"Description"}
              type={"textarea"}
              name='description'
              value={formData.description.value}
              autoComplete={"off"}
              onChange={onTextChange}
              mandatory={true}
              error={formData?.description?.error}
            />
            <Select
              name={"approval"}
              label={"Approval"}
              defaultFocused={true}
              options={[
                { label: "Select...", value: "" },
                { label: "Anyone", value: "anyone" },
                { label: "All", value: "all" },
              ]}
              value={formData.approval.value}
              onChange={onTextChange}
            />
            <Input
              label={"Icon Image"}
              type={"file"}
              name='icon'
              message={false}
              autoComplete={"off"}
              onChange={onFileChange}
              mandatory={true}
              error={formData?.icon?.error}
            />
            {formData.icon.value?.location && (
              <styles.IconContainer>
                <styles.Icon src={formData.icon.value.location} />
              </styles.IconContainer>
            )}
            <Select
              name={"active"}
              label={"Active"}
              defaultFocused={true}
              options={[
                { label: "Select...", value: "" },
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              value={formData.active.value}
              onChange={onTextChange}
            />
          </>
        )}

        {!readOnly && (
          <Button
            onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
            disabled={SubmitDisabled()}
            size={"normal"}
            label={"Save"}
          />
        )}
      </form>
      <br />
      <styles.metaDataContainer>
        <styles.metaDataItem>Created On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.createdAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Created By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.createdBy}</styles.metaDataInfo>
      </styles.metaDataContainer>

      <styles.metaDataContainer>
        <styles.metaDataItem>Updated On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.updatedAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Updated By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.updatedBy}</styles.metaDataInfo>
      </styles.metaDataContainer>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  knowledgebase: state.KnowledgebaseReducer.list.data,
  // groups: state.GroupsReducer.list.data,
  users: state.ApplicationReducer.configurations.controllerConfigs.users.filter(
    (item) => item.active,
  ),
});

const mapDispatchToProps = {
  createKnowledgebase,
  updateKnowledgebase,
  fetchKnowledgebase,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgebaseController);
