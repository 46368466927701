import Types from "./actionType";
import {
  FetchGroupsPayload,
  CreateGroups,
  CreateGroupsPayload,
  GroupsList,
  FetchGroups,
  FetchGroupsError,
  FetchGroupsSuccess,
  UpdateGroups,
  UpdateGroupsPayload,
  GroupsListWithMetaData,
  FetchGroupsByIdPayload,
  FetchGroupsById,
} from "./types";

export const fetchGroupsList = ({
  params,
  onSuccess,
  onError,
}: FetchGroupsPayload): FetchGroups => ({
  type: Types.FETCH_GROUPS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchGroupsById = ({
  id,
  onSuccess,
  onError,
}: FetchGroupsByIdPayload): FetchGroupsById => ({
  type: Types.FETCH_GROUPS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchGroupsListSuccess = (data: GroupsListWithMetaData): FetchGroupsSuccess => ({
  type: Types.SUCCESS_FETCH_GROUPS,
  payload: {
    data,
  },
});

export const fetchGroupsError = (error?: string): FetchGroupsError => ({
  type: Types.ERROR_FETCH_GROUPS,
  payload: { error },
});

export const createGroups = ({ data, onSuccess, onError }: CreateGroupsPayload): CreateGroups => ({
  type: Types.CREATE_GROUPS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateGroups = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateGroupsPayload): UpdateGroups => ({
  type: Types.UPDATE_GROUPS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
