import styled from "styled-components";

const styles = {
  MainContainer: styled.div`
    height: 100%;
    .desc {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      max-width: 40px;
    }
    .edit {
      cursor: pointer;
    }
    tr {
      justify-content: center;
    }
    td {
      justify-content: center;
      text-align: center;
    }
  `,
  Header: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }
    align-items: center;
    margin-bottom: 1vh;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Title: styled.h1`
    font-size: 2rem;
    font-weight: 600;
  `,
  ItemTitle: styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 2vh;
  `,
  DataContainer: styled.div`
    display: grid;
    /* background-color: green; */
    /* flex-direction: row; */
    margin-top: 1vh;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  `,
  DataRow: styled.div`
    display: flex;
    width: fit-content;
    padding: 1vh 1vw;
    align-self: center;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    background-color: #eaeaea;
    color: black;
    flex-direction: column;
    h2 {
      font-weight: 400;
      font-size: 1.4rem;
    }
  `,
  Status: styled.text`
    font-size: 1.2rem;
    padding: 1vh 1vw;
    width: fit-content;
    height: fit-content;
    background-color: ${(props) => props.color};
    border-radius: 4px;
    color: white;
  `,
  Summary: styled.h1`
    font-size: 1.4rem;
    font-weight: 400;
  `,
  Body: styled.div`
    min-height: 30vh;
    height: fit-content;
    width: 100%;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #000;
    margin-top: 1vh;
  `,
  Column: styled.div`
    flex: 1;
  `,
  CodeContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    b {
      width: 10vw;
      font-size: 1rem;
    }
  `,
  metaDataContainer: styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    margin-top: 1vh;
  `,
  metaDataItem: styled.div`
    font-size: 1rem;
    font-weight: 600;
    background-color: #48b5e1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    padding-left: 10px;
    padding-right: 10px;
    /* margin: 5% 1%; */
    color: white;
  `,
  metaDataInfo: styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #48b5e1;
    border: solid 1px black;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 4vh;
    width: 10vw;
  `,
};

export default styles;
