import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getToken,
  GeneralAttributesFetch,
  StorageUpload,
  StorageGet,
  ControllerConfigurations,
  getOrganisation,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchAttributesSuccess, fetchControllerConfigsSuccess } from "./actions";
import { ApplicationActionTypes as Types } from "./actionTypes";

function* fetchAttributes(action) {
  try {
    const options = {
      method: "GET",
      url: GeneralAttributesFetch(),
      headers: {
        Authorization: yield select(getToken),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchAttributesSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getFileWorker(action) {
  try {
    const options = {
      method: "GET",
      url: StorageGet(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* uploadFileWorker(action) {
  try {
    const formData = new FormData();
    formData.append("file", action.payload.file);
    const isPublic = action.payload?.type === "public" ? "public" : false;
    const options = {
      method: "POST",
      url: StorageUpload(isPublic),
      data: formData,
      onUploadProgress: (progressEvent) => {
        const size = (progressEvent.loaded / action.payload.file.size) * 100;
        return action.payload.onProgress(size > 100 ? 100 : size);
      },
      headers: {
        Authorization: yield select(getToken),
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* fetchControllerConfigs(action) {
  try {
    const options = {
      method: "GET",
      url: ControllerConfigurations,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchControllerConfigsSuccess({ controllerConfigs: response.data.data }));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* ApplicationSaga() {
  yield takeLatest(Types.ATTRIBUTES_FETCH, fetchAttributes);
  yield takeEvery(Types.STORAGE_GET, getFileWorker);
  yield takeEvery(Types.STORAGE_UPLOAD, uploadFileWorker);
  yield takeLatest(Types.FETCH_CONTROLLER_CONFIGURATIONS, fetchControllerConfigs);
}
