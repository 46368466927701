import Types from "./actionType";
import {
  FetchDesignationsPayload,
  CreateDesignation,
  CreateDesignationPayload,
  DesignationsList,
  FetchDesignations,
  FetchDesignationsError,
  FetchDesignationsSuccess,
  UpdateDesignation,
  UpdateDesignationPayload,
  DesignationsListWithMetaData,
  FetchDesignationsByIdPayload,
  FetchDesignationsById,
  DeleteDesignationsPayload,
  DeleteDesignations,
} from "./types";

export const fetchDesignationsList = ({
  params,
  onSuccess,
  onError,
}: FetchDesignationsPayload): FetchDesignations => ({
  type: Types.FETCH_DESIGNATIONS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});
export const fetchDesignationsById = ({
  id,
  onSuccess,
  onError,
}: FetchDesignationsByIdPayload): FetchDesignationsById => ({
  type: Types.FETCH_DESIGNATION_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
export const fetchDesignationsListSuccess = (
  data: DesignationsListWithMetaData,
): FetchDesignationsSuccess => ({
  type: Types.SUCCESS_FETCH_DESIGNATIONS,
  payload: {
    data,
  },
});

export const fetchDesignationsError = (error?: string): FetchDesignationsError => ({
  type: Types.ERROR_FETCH_DESIGNATIONS,
  payload: { error },
});

export const createDesignation = ({
  data,
  onSuccess,
  onError,
}: CreateDesignationPayload): CreateDesignation => ({
  type: Types.CREATE_DESIGNATION,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDesignation = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDesignationPayload): UpdateDesignation => ({
  type: Types.UPDATE_DESIGNATION,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteDesignation = ({
  id,
  onSuccess,
  onError,
}: DeleteDesignationsPayload): DeleteDesignations => ({
  type: Types.DELETE_DESIGNATION,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
