import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { AcademyACHControllerProps } from "../../../../types";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads/AcademyCandidateLeadsList";

import { useAcademyACHController } from "./hooks";

export const AcademyACHController = ({ type }: AcademyACHControllerProps) => {
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const params = useParams();

  const {
    onDateChange,
    formSteps,
    formTitle,
    formRelatedList,
    currentStepIndex,
    headerButtons,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyACH,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
  } = useAcademyACHController({ type, id: params.id, attachmentsPickerRef });
  const currentAcademyACH = data;

  const { employeesOptions, academyCandidateLeadsOptions } = useControllerConfigs();

  const {
    workLogs,
    attachments,
    serial,
    activityType,
    state,
    associatedCandidateLeads,
    activityOwner,
    activityDate,
    callDirection,
    activityAgenda,
    activityPurpose,
    activityResult,
    meetLocationURL,
    from,
    to,
    recordingURL,
    shortDescripiton,
    description,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyACH();
    }
    // Do not include fetchAcademyACH to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Activity Number'
            type={"text"}
            width='48%'
            error={serial.error}
            inputProps={{
              name: "serial",
              disabled: true,
              value: `ACHCRM${serial.value}`,
              autoComplete: "off",
              onChange: onTextChange,
              required: serial.required,
            }}
            textAreaProps={{}}
          />

          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("academyACHState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"activityType"}
            label={"Activity Type"}
            options={getAttributValues("academyACHType")}
            value={activityType.value}
            onChange={onTextChange}
            mandatory={activityType.required}
            error={activityType.error}
          />
          <Select
            width={"48%"}
            name={"associatedCandidateLeads"}
            label={"Associated To Candidate Leads"}
            options={academyCandidateLeadsOptions}
            value={associatedCandidateLeads.value}
            onChange={onTextChange}
            mandatory={associatedCandidateLeads.required}
            error={associatedCandidateLeads.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"activityOwner"}
            label={"Activity Owner"}
            options={employeesOptions}
            value={activityOwner.value}
            onChange={onTextChange}
            mandatory={activityOwner.required}
            error={activityOwner.error}
          />
          <TextInput
            label='Acitivity Date'
            type={"date"}
            width='48%'
            error={activityDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "activityDate",
              value: activityDate.value,
              required: activityDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"callDirection"}
            label={"Call Direction"}
            options={getAttributValues("academyACHCallDirection")}
            value={callDirection.value}
            onChange={onTextChange}
            mandatory={callDirection.required}
            error={callDirection.error}
          />
          <TextInput
            label='Activity Agenda'
            type={"text"}
            width='48%'
            error={activityAgenda.error}
            inputProps={{
              name: "activityAgenda",
              value: activityAgenda.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: activityAgenda.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"activityPurpose"}
            label={"Activity Purpose"}
            options={getAttributValues("academyACHPurpose")}
            value={activityPurpose.value}
            onChange={onTextChange}
            mandatory={activityPurpose.required}
            error={activityPurpose.error}
          />
          <Select
            width={"48%"}
            name={"activityResult"}
            label={"Activity Result"}
            options={getAttributValues("academyACHResult")}
            value={activityResult.value}
            onChange={onTextChange}
            mandatory={activityResult.required}
            error={activityResult.error}
          />
        </Row>
        <TextInput
          label='Meet Location URL'
          type={"text"}
          width='100%'
          error={meetLocationURL.error}
          inputProps={{
            name: "meetLocationURL",
            value: meetLocationURL.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: meetLocationURL.required,
          }}
          textAreaProps={{}}
        />
        <Row>
          <TextInput
            label='From'
            type={"date"}
            width='48%'
            error={from.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "from",
              value: from.value,
              required: from.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='To'
            type={"date"}
            width='48%'
            error={to.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "to",
              value: to.value,
              required: to.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label='Recording URL'
          type={"text"}
          width='100%'
          error={recordingURL.error}
          inputProps={{
            name: "recordingURL",
            value: recordingURL.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: recordingURL.required,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label='Short Descripiton'
          type={"textarea"}
          width='100%'
          error={shortDescripiton.error}
          inputProps={{}}
          textAreaProps={{
            name: "shortDescripiton",
            rows: 3,
            value: shortDescripiton.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescripiton.required,
          }}
        />
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </>
    );
  }, [
    serial.error,
    serial.value,
    serial.required,
    onTextChange,
    state.value,
    state.required,
    state.error,
    activityType.value,
    activityType.required,
    activityType.error,
    academyCandidateLeadsOptions,
    associatedCandidateLeads.value,
    associatedCandidateLeads.required,
    associatedCandidateLeads.error,
    employeesOptions,
    activityOwner.value,
    activityOwner.required,
    activityOwner.error,
    activityDate.error,
    activityDate.value,
    activityDate.required,
    onDateChange,
    callDirection.value,
    callDirection.required,
    callDirection.error,
    activityAgenda.error,
    activityAgenda.value,
    activityAgenda.required,
    activityPurpose.value,
    activityPurpose.required,
    activityPurpose.error,
    activityResult.value,
    activityResult.required,
    activityResult.error,
    meetLocationURL.error,
    meetLocationURL.value,
    meetLocationURL.required,
    from.error,
    from.value,
    from.required,
    to.error,
    to.value,
    to.required,
    recordingURL.error,
    recordingURL.value,
    recordingURL.required,
    shortDescripiton.error,
    shortDescripiton.value,
    shortDescripiton.required,
    description.error,
    description.value,
    description.required,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyACH?.createdBy !== "string" ? currentAcademyACH?.createdBy?.name : "";
    const updatedBy =
      typeof currentAcademyACH?.updatedBy !== "string" ? currentAcademyACH?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentAcademyACH?.createdAt}
        updatedAt={currentAcademyACH?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyACH?.createdAt,
    currentAcademyACH?.createdBy,
    currentAcademyACH?.updatedAt,
    currentAcademyACH?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.academyACH.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyACH.name} />,
    [params.id],
  );
  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const relatedCandidateLead = useMemo(() => {
    return (
      <AcademyCandidateLeadsList
        relatedList={{
          type: "_id",
          value:
            typeof currentAcademyACH.associatedCandidateLeads === "string"
              ? currentAcademyACH.associatedCandidateLeads
              : currentAcademyACH.associatedCandidateLeads?._id,
        }}
      />
    );
  }, [currentAcademyACH.associatedCandidateLeads]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return relatedCandidateLead;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    relatedCandidateLead,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
