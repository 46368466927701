export const activityMonitorDisplayFields = {
  // HR Workspace
  employees: {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    nickName: "Nick Name",
    active: "Active",
    shirtSize: "Shirt Size",
    payrollCompany: "Payroll Company",
    employmentDepartment: "Department",
    employmentStatus: "Status",
    personalEmail: "Personal Email",
    officialEmail: "Official Email",
    mobile: "Primary Phone",
    alternateMobile: "Alternative Phone",
    generatePayrollOffshoreServices: "Generate Payroll Offshore Services",
    employeeID: "Employee ID",
    employeeIDofPayrollCompany: "Employee ID of Payroll Company",
    detailsForOffboard: "Details For Offboard",
    resumeAttached: "Resume Attached",
    coverLettersAttached: "Cover Letters Attached",
    consultantProfile: "Consultant Profile",
    "timelineDetails.dateJoining": "Date of Joining",
    "timelineDetails.dateHire": "Hire Date",
    "timelineDetails.dateTermination": "Termination Date",
    "timelineDetails.probationStartDate": "Probation Start Date",
    "timelineDetails.probationEndDate": "Probation End Date",
    "additionalDetails.externalFolderLink": "External Folder Link",
    "additionalDetails.isEmployeeReferenced": "Is Employee Referenced",
    "additionalDetails.emergencyAddress": "Emergency Address",
    "additionalDetails.referralName": "Name of The Referral",
    "additionalDetails.referralMobile": "Referral Mobile",
    "additionalDetails.referralEmail": "Referral Email",
    "personalDetails.Country": "Country",
    "personalDetails.personalMartial": "Martial Status",
    "personalDetails.personalDOB": "Date of Birth",
    "personalDetails.nationality": "Nationality",
    "personalDetails.personalGender": "Gender",
    "personalDetails.ethnicity": "Ethnicity",
    "personalDetails.race": "Race",
    "personalDetails.placeOfBirth": "Place Of Birth",
    "personalDetails.countryOfBirth": "Country Of Birth",
    "personalDetails.fatherName": "Father's Name",
    "personalDetails.motherName": "Mother's Name",
    "personalDetails.addressLineOne": "Address Line One",
    "personalDetails.addressLineTwo": "Address Line Two",
    "personalDetails.city": "City",
    "personalDetails.state": "State",
    "personalDetails.zipCode": "Postal Code",
    "reportingDetails.managerLevel": "Manager Level",
    "reportingDetails.reportingManager": "Reporting Manager",
    "reportingDetails.reportingPOD": "POD Leader",
    "reportingDetails.companyBuddy": "Company Buddy",
    // Salary Details
    "designations.currentSalary": "Salary Details - Current Salary",
    "designations.date": "Salary Details - Date",
    "designations.designation": "Salary Details - Designation",
    "designations.salaryAdditional": "Salary Details - Additional",
    "designations.salaryBase": "Salary Details - Base",
    "designations.salaryBonus": "Salary Details - Bonus",
    "designations.salaryCurrency": "Salary Details - Currency",
    "designations.salaryPayroll": "Salary Details - Freqency",
    // Appraisals
    "appraisals.lastAppraisalDate": "Appraisals - Last Appraisal Date",
    "appraisals.nextAppraisalDueDate": "Appraisals - Next Appraisal Date",
    // Emergency Contact
    "emergencyContact.type": "Emergency Contact - Type",
    "emergencyContact.emergencyFirstName": "Emergency Contact - First Name",
    "emergencyContact.emergencyLastName": "Emergency Contact - lastName",
    "emergencyContact.emergencyRelation": "Emergency Contact - Relation",
    "emergencyContact.emergencyMobile": "Emergency Contact - Mobile",
    "emergencyContact.emergencyAddress": "Emergency Contact - Address",
  },
  benefits: {
    type: "Benefit Type",
    associatedEmployee: "Associated Employee",
    active: "Active",
    details: "Details",
    amount: "Amount",
    issueDate: "Issue Date",
    expiryDate: "Expiry Date",
  },
  projectHistory: {
    projectCode: "Project Code",
    associatedEmployee: "Employee",
    projectType: "Project Type",
    projectTechnology: "Project Technology",
    startDate: "Start Date",
    endDate: "End Date",
    projectStatus: "Project Status",
    currentlyWorking: "Currently Working",
    terminationReason: "Termination Reason",
    projectModules: "Project Modules",
    projectExternalLink: "Project External Link",
  },
  form16: {
    financialYear: "Financial Year",
    associatedEmployee: "Associated Employee",
    active: "Active",
    form16Attached: "Form 16 Attached",
  },
  departments: {
    name: "Name",
    active: "Active",
    departmentManager: "Department Manager",
    description: "Description",
  },
  designations: {
    name: "Name",
    department: "Department",
    active: "Active",
  },
  groups: {
    name: "Name",
    active: "Active",
    type: "Type",
    country: "Country",
    department: "Department",
    description: "Description",
  },
  addressHistory: {
    country: "Country",
    city: "City",
    associatedEmployee: "Associated Employee",
    state: "State",
    addressLineOne: "Address Line One",
    addressLineTwo: "Address Line Two",
    currentlyLives: "Currently Lives",
    postalCode: "Postal Code",
    fromDate: "From Date",
    toDate: "To Date",
  },
  educationDetails: {
    country: "Country",
    educationType: "Education Type",
    associatedEmployee: "Associated Employee",
    active: "Active",
    state: "State",
    instituteName: "Institute Name",
    studentId: "Student Id",
    currentlyAttending: "Currently Attending",
    majorName: "Major Name",
    degreeName: "Degree Name",
    receivedDegree: "Received Degree",
    phone: "Phone",
    postalCode: "Postal Code",
    city: "City",
    instituteAddress: "Institute Address",
    fromDate: "From Date",
    toDate: "To Date",
    graduationDate: "Graduation Date",
  },
  employmentHistory: {
    associatedEmployee: "Associated Employee",
    employmentType: "Employment Type",
    startDate: "Start Date",
    endDate: "End Date",
    currentlyWorking: "Currently Working",
    permissionToContact: "Permission To Contact",
    permissionToContactReason: "Permission To Contact Reason",
    "address.employerName": "Employer Name",
    "address.country": "Country",
    "address.city": "City",
    "address.state": "State",
    "address.addressLineOne": "Address Line One",
    "address.addressLineTwo": "Address Line Two",
    "address.postalCode": "Postal Code",
    "address.phone": "Phone",
    "address.extension": "Extension",
    "employmentJobDetails.jobType": "Job Type",
    "employmentJobDetails.isAgency": "Is Agency",
    "employmentJobDetails.agencyName": "Agency Name",
    "employmentJobDetails.agencyAddressLineTwo": "Agency Address LineTwo",
    "employmentJobDetails.agencyCountry": "Agency Country",
    "employmentJobDetails.agencyCity": "Agency City",
    "employmentJobDetails.agencyState": " gency State",
    "employmentJobDetails.agencyAddressLineOne": "Agency Address Line One",
    "employmentJobDetails.agencyPostalCode": "Agency Postal Code",
    "employmentJobDetails.releventExperience": "Relevent Experience",
    "employmentJobDetails.department": "Department",
    "employmentJobDetails.jobTitle": "Job Title",
    "employmentJobDetails.supervisorName": "Supervisor Name",
    "employmentJobDetails.supervisorTitle": "Supervisor Title",
    "employmentJobDetails.supervisorEmail": "Supervisor Email",
    "employmentJobDetails.supervisorPhone": "Supervisor Phone",
    "employmentJobDetails.reasonForLeaving": "Reason For Leaving",
  },
  announcements: {
    title: "Title",
    active: "Active",
    message: "Message",
  },
  // Academy
  // Academy
  academyCourses: {
    name: "Name",
    shortDescription: "Short Description",
    numberOfTopics: "Number Of Topics",
    description: "Description",
    downloadableResources: "Downloadable Resources",
    cardIconAttached: "CardIcon Attached",
    cardBackgroundAttached: "Card Background Attached",
    courseDetailsIconAttached: "Course DetailsIcon Attached",
    active: "Active",
    technology: "Technology",
    preparedBy: "Prepared By",
    curriculamAttached: "Curriculam Attached",
    courseDuration: "Course Duration",
    prerequisites: "Prerequisites",
    anyDriveURL: "Any Drive URL",
  },
  academyCandidates: {
    firstName: "First Name",
    lastName: "LastName",
    email: "Email",
    phone: "Phone",
    stage: "Stage",
    dob: "Date of Birth",
    course: "Course",
    source: "Source",
    otherSource: "Other Source",
    city: "City",
    state: "State",
    country: "Country",
    admissionCounsellor: "Admission Counsellor",
    graduationYear: "Graduation Year",
    graduationDegree: "Graduation Degree",
    otherDegree: "Other Degree",
    graduationMajor: "Graduation Major",
    linkedInURL: "LinkedIn URL",
    status: "Status",
    active: "Active",
  },
  academyCandidateLeads: {
    associatedContact: "Associated Contact",
    currency: "Currency",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    course: "Course",
    dob: "Date of Birth",
    source: "Source",
    otherSource: "Other Source",
    city: "City",
    state: "State",
    country: "Country",
    graduationYear: "Graduation Year",
    graduationDegree: "Graduation Degree",
    otherDegree: "Other Degree",
    graduationMajor: "Graduation Major",
    status: "Status",
    linkedInURL: "LinkedIn URL",
    stage: "Stage",
    admissionCounsellor: "Admission Counsellor",
    enrollmentSpecialists: "Enrollment Specialists",
    sourceContactDate: "Source Contact Date",
    saleDate: "Sale Date",
    totalFees: "Total Fees",
    paymentTerms: "Payment Terms",
    anyDiscountsGiven: "Any Discounts Given",
  },
  academyBatch: {
    batchNumber: "Batch Number",
    mentors: "Mentors",
    shortDescription: "Short Description",
    totalStudents: "Total Students",
    description: "Description",
    courseOfferings: "Course Offerings",
    trainingCordinator: "Training Cordinator",
    plannedDuration: "Planned Duration",
    dailyHoursEffort: "Daily Hours Effort",
    actualDuration: "Actual Duration",
    state: "State",
    plannedStartDate: "Planned Start Date",
    actualStartDate: "Actual Start Date",
    plannedEndDate: "Planned End Date",
    actualEndDate: "Actual End Date",
    overallHealth: "Overall Health",
    scheduleTimeHealth: "Schedule Time Health",
    active: "Active",
  },
  academyCandidateEnrollments: {
    studentName: "Lerner's Name",
    currency: "Currency",
    careerPath: "Career Path",
    studentID: "StudentID",
    enrolledCourse: "Enrolled Course",
    candidateLead: "Candidate Lead",
    batchStartDate: "Batch Start Date",
    trainingDurations: "Training Durations",
    enrollmentStage: "Enrollment Stage",
    active: "Active",
    batchSelected: "Batches",
    studentOfficialEmail: "Student Official Email",
    paymentStatus: "Payment Status",
    totalFees: "Total Fees",
    paymentTerms: "Payment Terms",
    feesPaid: "Fees Paid",
    taxedOnFees: "Taxed On Fees",
    NBFCcharges: "NBFC charges",
    netRevenue: "Net Revenue",
    nextPaymentDueDate: "Next Payment Due Date",
    anyDiscountsGiven: "Any Discounts Given",
    "academyOfficers.careerCounseller": "Career Counseller",
    "academyOfficers.enrollmentSpecialist": "Enrollment Specialist",
    "academyOfficers.trainingCordinator": "Training Cordinator",
    "academyOfficers.corporateRelationManager": "Corporate Relation Manager",
    "paymentDetails.installmentNumber": "Payment Details - Installment Number",
    "paymentDetails.amountPaid": "Payment Details - Amount Paid",
    "paymentDetails.taxedAmount": "Payment Details - Taxed Amount",
    "paymentDetails.invoiceNumber": "Payment Details - Invoice Number",
    "paymentDetails.invoicedDate": "Payment Details - Invoiced Date",
    // "paymentDetails.installmentPaid": "Payment Details - Installment Paid",
    "paymentDetails.invoiceAttached": "Payment Details - invoice Attached",
    "paymentDetails.paymentReceiptAttached": "Payment Details - Payment Receipt Attached",
    "paymentDetails.paymentDate": "Payment Details - Payment Date",
  },
  academyACH: {
    activityType: "ActivityType",
    active: "Active",
    state: "State",
    associatedCandidateLeads: "Associated Candidate Leads",
    activityOwner: "Activity Owner",
    activityDate: "Activity Date",
    callDirection: "Call Direction",
    activityAgenda: "Activity Agenda",
    activityPurpose: "Activity Purpose",
    activityResult: "Activity Result",
    meetLocationURL: "Meet Location URL",
    from: "From",
    to: "To",
    recordingURL: "Recording URL",
    shortDescripiton: "Short Descripiton",
    description: "Description",
  },
  academyStudentProfile: {
    studentName: "Student Name",
    associatedEnrollment: "Associated Enrollment",
    studentOfficialEmail: "Student Official Email",
    studentID: "StudentID",
    batchs: "Batches",
    active: "Active",
    enrolledCourse: "EnrolledCourse",
    studentTrainingCordinator: "Student Training Cordinator",
  },
  academyMentorFeedback: {
    description: "Description",
    active: "Active",
    studentProfile: "Student Profile",
    state: "State",
    overallScoring: "Overall Scoring",
    mentor: "Mentor",
    feedbackSummary: "Feedback Summary",
    weakPoints: "Weak Points",
    strongPoints: "Strong Points",
  },
  // Dashboard
  dashboardAdministration: { title: "Title", active: "Active", description: "description" },
  dashboardTabs: {
    title: "Title",
    active: "Active",
    description: "description",
  },
  dashboardWidgets: {
    title: "Title",
    active: "Active",
    description: "description",
    type: "Type",
    table: "Table",
    dashboardTab: "Dashboard Tab",
    dashboard: "Dashboard Config",
  },
  // User

  country: { name: "Name", active: "Active" },
  currency: { name: "Name", active: "Active", country: "Country" },
  // Knowledge
  categories: { name: "Name", active: "Active", knowledgebase: "Knowledgebase" },
  subCategories: {
    name: "Name",
    active: "Active",
    knowledgebase: "Knowledgebase",
    parentCategory: "Parent Category",
  },
  modules: { name: "Name", active: "Active", parentModule: "Parent Module" },
  certificationsAccreditations: {
    type: "Type",
    associatedEmployee: "Associated Employee",
    isAttachmentAdded: "Attachment Added",
    active: "Active",
    name: "Name",
    number: "Number",
    issueDate: "Issue Date",
    expiryDate: "Expiry Date",
  },
  company: {
    company: "Company",
    serviceDetails: "Service Details",
    corporationType: "Corporation Type",
    W9: "W9",
    AOI: "AOI",
    ACH: "ACH",
    voidedCheck: "Voided Check",
    GST: "GST",
    PAN: "PAN",
    IEC: "IEC",
    AOA: "AOA",
    MOA: "MOA",
    COITaken: "COI Taken",
    about: "About",
    companyType: "Company Type",
    companyClassification: "Company Classification",
    companyOwner: "Company Owner",
    existingCompany: "Existing Company",
    industry: "Industry",
    website: "Website",
    size: "Size",
    externalLinks: "External Links",
    accountOwner: "Account Owner",
    country: "Country",
    province: "Province",
    addressLineOne: "Address Line One",
    addressLineTwo: "Address Line Two",
    city: "City",
    zipCode: "Zipcode",
    phone: "Phone",
    "serviceDetails.serviceType": "Service Type",
    "serviceDetails.serviceModel": "Service Model",
    "serviceDetails.details": "Service Details",
    "serviceDetails.startDate": "Start Date",
    "serviceDetails.endDate": "EndDate",
    "serviceDetails.renewDate": "Renew Date",
  },
  checklistTemplate: {
    name: "Name",
    active: "Active",
  },
  // SPS
  spsAccounts: {
    name: "Name",
    type: "Type",
    status: "Status",
    industry: "Industry",
    website: "Website",
    size: "Size",
    region: "Region",
    salesRepresentative: "Sales Representative",
    addressInfo: "Address Info",
    country: "Country",
    street: "Street",
    state: "State",
    city: "City",
    externalLinks: "External Links",
  },
  spsAccountContact: {
    associatedAccount: "Associated Account",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
    email: "Email",
    alternativeEmail: "Alternative Email",
    mobile: "Mobile",
    alternativeMobile: "Alternative Mobile",
    company: "Company",
    status: "Status",
  },
  spsSalesProfile: {
    firstName: "First Name",
    lastName: "Last Name",
    gmail: "Gmail",
    gvoice: "Gvoice",
    linkedin: "Linkedin",
    locationUsed: "Location Used",
    referenceUsed: "Reference Used",
  },
  spsDeals: {
    SPSSalesProfile: "SPS Sales Profile",
    accountOwner: "Account Owner",
    legalAccountName: "Legal Account Name",
    preferredFullName: "Rreferred Full Name",
    rate: "Rate",
    type: "Type",
    dealSource: "Deal Source",
    state: "State",
    staffingName: "Staffing Name",
    endClient: "End Client",
    platform: "Platform",
    interviewDetails: "Interview Details",
    recordingDriveLink: "Recording Drive Link",
    addressUsed: "ddressUsed",
    bankAccountDetailsUsed: "BankAccount Details Used",
    dealGivenTo: "Deal Given To",
    isTranscriptUploaded: "Is TranscriptUploaded",
    isJobDescriptionUploaded: "Is Job Description Uploaded",
    isResumeUploaded: "Is Resume Uploaded",
    c2cInfo: "C2C Info",
    potentialStartDate: "Potential Start Date",
    potentialClosingDate: "Potential Closing Date",
    modulesInvolved: "Modules Involved",
    name: "Name",
    email: "Email",
    phone: "Phone",
    verifiedBy: "Verified By",
  },
  spsBGCheck: {
    associatedAccount: "Associated Account",
    associatedDeal: "Associated Deal",
    consultantName: "Consultant Name",
    preferredName: "Preferred Name",
    status: "Status",
    newCompanyAppliedFor: "NewCompany Applied For",
    newPositionAppliedFor: "New Position Applied For",
    recruiterName: "Recruiter Name",
    email: "Email",
    phone: "Phone",
  },
  spsPaperwork: {
    associatedAccount: "Associated Account",
    associatedDeal: "Associated Deal",
    consultantName: "Consultant Name",
    preferredName: "Preferred Name",
  },
  spsProjects: {
    associatedDeal: "Associated Deal",
    projectCode: "Project Code",
    projectDescription: "Project Description",
    projectName: "Project Name",
    projectType: "Project Type",
    platform: "Platform",
    projectRole: "Project Role",
    legalName: "Lega lName",
    legalNameCode: "Legal Name Code",
    projectGivenTo: "Project Given To",
    rate: "Rate",
    billingCycle: "Billing Cycle",
    startDate: "Start Date",
    expetectedLength: "Expetected Length",
    expetectedEndDate: "Expetected End Date",
    projectStatus: "Project Status",
    haltReason: "Halt Reason",
    haltStartDate: "Halt Start Date",
    haltEndDate: "Halt End Date",
    terminationReason: "Termination Reason",
  },
  spsInsurance: {
    associatedProject: "Associated Project",
    accountTakenfor: "Account Taken for",
    startDate: "Start Date",
    renewDate: "Renew Date",
    insuranceCompany: "Insurance Company",
    amount: "Amount",
  },
  jobs: {
    title: "Title",
    summary: "Summary",
    description: "Description",
    salary: "Salary",
    type: "Type",
    location: "Location",
    sector: "Sector",
    contactName: "Contact Name",
    contactNumber: "Contact Number",
    contactEmail: "Contact Email",
    skills: "Skills",
    referenceId: "Reference Id",
    customDate: "Custom Date",
    active: "Active",
  },
  platformModules: {
    name: "Name",
    active: "Active",
    associatedTechnology: "Technology",
  },
  // platformModules:{"associatedTechnology", "name", "active"},
  // // Company
  // company:{
  //   "company",
  //   "corporationType",
  //   "about",
  //   "companyClassification",
  //   "companyOwner",
  //   "existingCompany",
  //   "industry",
  //   "website",
  //   "size",
  //   "externalLinks",
  //   "accountOwner",
  //   "country",
  //   "province",
  //   "addressLineOne",
  //   "addressLineTwo",
  //   "city",
  //   "zipCode",
  //   "phone",

  // },

  companyContact: {
    company: "Company",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
    email: "Email",
    alternateEmail: "Alternate Email",
    phone: "Phone",
    alternatePhone: "Alternate Phone",
    status: "Status",
  },
  bankdetails: {
    active: "Active",
    bankName: "Bank Name",
    bankCountry: "Bank Country",
    accountType: "Account Type",
    accountName: "Account Name",
    routingNumber: "Routing Number",
    accountNumber: "Account Number",
    bankCurrency: "Bank Currency",
    routingType: "Routing Type",
    swiftBICCode: "Swift BIC Code",
    associatedEmployee: "Associated Employee",
    associatedCompany: "Associated Company",
    voidCheque: "Void Cheque",
    defaultAccount: "Default Account",
    bankType: "Bank Type",
  },
  itnContact: {
    name: "Name",
    email: "Email",
    jobTitle: "Job Title",
    company: "Company",
    description: "Description",
    phone: "Phone",
  },
  itnRFP: {
    fullName: "fullName",
    jobTitle: "Job Title",
    businessEmail: "businessEmail",
    phone: "Phone",
    country: "Country",
    companyName: "Company Name",
    industry: "Industry",
    annualRevenue: "Annual Revenue",
    description: "description",
  },
  itnServices: {
    name: "Name",
    active: "Active",
    descriptionLabel: "Description Label",
    downloadButton: "Download Button",
    description: "Description",
    coverImageAttached: "Cover Image Attached",
    serviceFileAttached: "Service File Attached",
  },
  serviceNowSolutions: {
    name: "Name",
    fullName: "Full Name",
    active: "Active",
    listImage: "List Image",
    technology: "Technology",
    shortDescription: "shortDescription",
    description: "description",
  },
  resources: {
    name: "Name",
    shortDescription: "Short Description",
    active: "Active",
    state: "State",
    listImage: "List Image",
    coverImage: "Cover Image",
    downloadAvailable: "Download Available",
    downloadLabel: "Download Label",
    publishedDate: "Published Date",
    metaTags: "Meta Tags",
    type: "Type",
    whitepaperTitle: "Whitepaper Title",
    whitepaperAttachment: "Whitepaper Attachment",
    whitepaperImage: "Whitepaper Image",
    publisherName: "Publisher Name",
    blogAttachment: "Blog Attachment",
    clientLogo: "Client Logo",
    caseStudyTagline: "Case Study Tag line",
    caseStudyBanner: "Case Study Banner",
    statsIncluded: "Stats Included",
    caseStudyAttachment: "Case Study Attachment",
    caseStudyClientDetails: "Case Study Client Details",
    clientName: "Client Name",
    clientHeadquater: "Client Headquater",
    clientIndustry: "Client Industry",
    clientProductType: "Client Product Type",
    clientEmployees: "Client Employees",
  },
  kanbanConfig: {
    title: "Title",
    active: "Active",
    description: "Description",
    groups: "Groups",
    users: "Users",
    table: "Table",
    columnField: "Column Field",
    displayField: "Display Field",
  },
  workspaces: {
    name: "Name",
    active: "Active",
    description: "Description",
    fullAccess: "Full Access",
    workspaceGroups: "Workspace Groups",
    workspaceOwners: "Workspace Owners",
    workspaceUsers: "Workspace Users",
    tables: "Tables",
  },
  spaces: {
    name: "Name",
    active: "Active",
    description: "Description",
    private: "Private",
    spaceGroups: "Space Groups",
    spaceOwners: "Space Owners",
    spaceUsers: "Space Users",
    workspace: "Workspace",
  },
  notificationsDefinations: {
    name: "Name",
    description: "Description",
    active: "Active",
    triggered: "Triggered",
    table: "Table",
    emailSchedular: "Email Schedular",
    insert: "Insert",
    update: "Update",
    watchListUser: "Watch List User",
    watchListGroup: "Watch List Groups",
  },
  variableSets: {
    name: "Name",
    active: "Active",
    order: "Order",
  },
  variables: {
    name: "Name",
    active: "Active",
    order: "Order",
    type: "Type",
    mandatory: "Mandatory",
    variableSets: "Variable Sets",
  },
};
