import React, { memo } from "react";

import { Layout } from "../../../presentationals";
import { SPSInsuranceController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSInsuranceController type={"add"} />} admin={true} layout={"2"} />;
};

export const SPSInsuranceAdd = React.memo(Route);
