import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { NewsletterLeadsControllerProps } from "../../../../types/models/NewsletterLeads";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useNewsletterLeadsController } from "./hooks";

const Controller = ({ type }: NewsletterLeadsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { CampaignsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchNewsletterLeads,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useNewsletterLeadsController({ type, id, attachmentsPickerRef });
  const currentNewsletterLeads = data;

  const {
    workLogs,
    attachments,
    firstName,
    lastName,
    email,
    phone,
    active,
    unsubscribeCampaign,
    unsubscribeTime,
    jobTitle,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchNewsletterLeads();
    }
    // Do not include fetchNewsletterLeads to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='FirstName'
            type={"text"}
            width='48%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
          />
          <TextInput
            label='LastName'
            type={"text"}
            width='48%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
        </Row>
        <Row>
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
          <Select
            width={"48%"}
            name={"unsubscribeCampaign"}
            label={"Unsubscribe Campaign"}
            options={CampaignsOptions}
            value={unsubscribeCampaign.value}
            onChange={onTextChange}
            mandatory={unsubscribeCampaign.required}
            error={unsubscribeCampaign.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Unsubscribe Time'
            type={"text"}
            width='48%'
            error={unsubscribeTime.error}
            inputProps={{
              name: "unsubscribeTime",
              value: unsubscribeTime.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: unsubscribeTime.required,
            }}
          />
          <TextInput
            label='Job Title'
            type={"text"}
            width='48%'
            error={jobTitle.error}
            inputProps={{
              name: "jobTitle",
              value: jobTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: jobTitle.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    CampaignsOptions,
    active.error,
    active.required,
    active.value,
    email.error,
    email.required,
    email.value,
    firstName.error,
    firstName.required,
    firstName.value,
    jobTitle.error,
    jobTitle.required,
    jobTitle.value,
    lastName.error,
    lastName.required,
    lastName.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    unsubscribeCampaign.error,
    unsubscribeCampaign.required,
    unsubscribeCampaign.value,
    unsubscribeTime.error,
    unsubscribeTime.required,
    unsubscribeTime.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentNewsletterLeads?.createdBy !== "string"
        ? currentNewsletterLeads?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentNewsletterLeads?.updatedBy !== "string"
        ? currentNewsletterLeads?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentNewsletterLeads?.createdAt}
        updatedAt={currentNewsletterLeads?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentNewsletterLeads?.createdAt,
    currentNewsletterLeads?.createdBy,
    currentNewsletterLeads?.updatedAt,
    currentNewsletterLeads?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.newsletterLeads.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.newsletterLeads.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const NewsletterLeadsController = memo(Controller);
