import styled from "styled-components";

import { fonts, colors } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    h2 {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 1vh;
      margin-left: 1vw;
    }
    .back {
      cursor: pointer;
    }
    /* justify-content: space-between; */
  `,
  Body: styled.div`
    flex: 1;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  `,
  Column: styled.div`
    flex: 1;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-family: ${fonts.font1};
    font-size: 11px;
  `,
  AttachmentIconContainer: styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    height: 60%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
    border-radius: 4px;
    cursor: pointer;
  `,
  memberTitle: styled.h3`
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #48b5e1;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 2% 2% 1% 3%;
    margin: 5% 1%;
    color: white;
  `,
  memberItemContainer: styled.div`
    margin: 2% 4%;
    padding: 1% 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px white;
    border-radius: 6px;
  `,
  removeContainer: styled.div`
    cursor: pointer;
  `,
  memberItem: styled.h3`
    font-size: 1.2rem;
    font-weight: 400;
    width: 86%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  IconContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  `,
  Icon: styled.img`
    height: 100px;
    width: 160px;
    object-fit: contain;
  `,
  TabContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 20px;
  `,

  TabItem: styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 7px;
    /* margin: 2px; */
    background-color: ${(props) => (props.active ? "#6c7ae0" : colors.secondary)};
    font-family: ${fonts.font1};
    font-size: 1.2rem;
    color: ${(props) => (props.active ? "white" : colors.secondary_text)};
    cursor: ${(props) => (props.active ? "" : "pointer")};
    border-bottom: ${(props) => `2px solid ${props.error ? colors.error : "transparent"}`};
    border-radius: 4px;
  `,
  dec: styled.div`
    font-size: 1.2rem;
    font-weight: 400;
    max-height: 30%;
    display: inline-block;
    width: 0px;
    min-width: 92%;
    border: solid 1px #48b5e1;
    border-radius: 4px;
    white-space: pre-line;
    word-wrap: break-word;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    padding: 6px 4%;
  `,
  metaDataContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1vh;
  `,
  metaDataItem: styled.div`
    font-size: 1rem;
    font-weight: 600;
    background-color: #48b5e1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    /* margin: 5% 1%; */
    color: white;
  `,
  metaDataInfo: styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #48b5e1;
    border: solid 1px white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 4vh;
    width: 50%;
  `,
  approvalType: styled.div`
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2vh;
    h2 {
      font-size: 1.2rem;
      font-weight: 600;
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 400;
    }
  `,
  comment: styled.div`
    font-size: 1.4rem;
    color: white;
    margin-top: 1vh;
    padding: 2%;
    background-color: ${(props) => (props.approved ? "green" : "red")};
    border-radius: 4px;
  `,
};

export default styles;
