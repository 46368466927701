import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  fetchControllerConfigs,
  removeAppNotification,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  createServiceNowSolutions,
  fetchServiceNowSolutionsById,
  updateServiceNowSolutions,
} from "../../../../../redux/ServiceNowSolutions/actions";
import {
  CreateServiceNowSolutionsPayload,
  FetchServiceNowSolutionsByIdPayload,
  ServiceNowSolutionsList,
  ServiceNowSolutionsSection,
  UpdateServiceNowSolutionsPayload,
} from "../../../../../redux/ServiceNowSolutions/types";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  ServiceNowSolutionsForm,
  ServiceNowSolutionsSectionsForm,
  useServiceNowSolutionsControllerProps,
  useServiceNowSolutionsControllerReturnType,
} from "../../../../../types/models/ServiceNowSolutions";

// ================ Contstants ================

const addFormSteps = ["Overview"];
const editFormSteps = [
  "Overview",
  "Sections",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "associatedAccount",
  "firstName",
  "lastName",
  "role",
  "email",
  "alternativeEmail",
  "mobile",
  "alternativeMobile",
  "company",
  "status",
];
export const useServiceNowSolutionsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useServiceNowSolutionsControllerProps): useServiceNowSolutionsControllerReturnType => {
  const defaultAttachment = useMemo(() => {
    return {
      id: "",
      name: "",
    };
  }, []);
  const defaultFormData: ServiceNowSolutionsForm = {
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    name: {
      value: "",
      required: false,
      error: "",
    },
    fullName: {
      value: "",
      required: false,
      error: "",
    },
    shortDescription: {
      value: "",
      required: false,
      error: "",
    },
    listImage: {
      value: defaultAttachment,
      required: false,
      error: "",
    },

    technology: {
      value: "",
      required: false,
      error: "",
    },
    sections: {
      value: [],
      required: false,
      error: "",
    },
    active: {
      value: false,
      error: "",
      required: false,
    },
    description: {
      value: "",
      required: false,
      error: "",
    },
  };

  const defaultSectionsData: ServiceNowSolutionsSectionsForm = useMemo(() => {
    return {
      sectionName: {
        value: "",
        required: false,
        error: "",
      },
      type: {
        value: "",
        required: false,
        error: "",
      },
      sectionDescription: {
        value: "",
        required: false,
        error: "",
      },
      sectionImage: {
        value: defaultAttachment,
        required: false,
        error: "",
      },
      anyPointers: {
        value: false,
        required: false,
        error: "",
      },
      pointerLabel: {
        value: "",
        required: false,
        error: "",
      },
      pointers: {
        value: "",
        required: false,
        error: "",
      },
      demoTitle: {
        value: "",
        required: false,
        error: "",
      },
      demoVideo: {
        value: "",
        required: false,
        error: "",
      },
      resourceTitle: {
        value: "",
        required: false,
        error: "",
      },
      resourceLabel: {
        value: "",
        required: false,
        error: "",
      },
      resourceLink: {
        value: "",
        required: false,
        error: "",
      },
      imageVideoPosition: {
        value: "right",
        required: false,
        error: "",
      },
      _id: "",
      order: {
        value: 0,
        required: false,
        error: "",
      },
    };
  }, [defaultAttachment]);
  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ServiceNowSolutionsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<ServiceNowSolutionsList>({});
  const [sectionsData, setSectionsData] =
    useState<ServiceNowSolutionsSectionsForm>(defaultSectionsData);
  const [sectionsModal, setSectionsModal] = useState<"add" | "edit" | "">("");
  const [sectionDescription, setSectionDescription] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [currentSectionId, setCurrentSectionId] = useState("");
  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData?.[field]?.error) {
              count += 1;
            }
          });
          break;
      }
      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onDescriptionChange = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const rehydrateFormData = useCallback(
    (serviceNowSolutions: ServiceNowSolutionsList) => {
      try {
        const item = serviceNowSolutions;
        const data = { ...formData };
        const tempAttachments: Attachments[] = [];
        setDescription(serviceNowSolutions?.description || "");
        Object.keys(formData).forEach((field) => {
          let value = item?.[field];
          if (["technology"].includes(field)) {
            const tempValue = value?._id;
            value = tempValue;
          }
          if (field === "attachments") {
            for (let i = 0; i < value?.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
                extension: value[i]?.extension,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
          }
          data[field].value = value;
        });
        setFormData(data);
      } catch (err) {
        console.log(err);
      }
    },
    [formData],
  );

  const fetchServiceNowSolutions = useCallback(() => {
    setLoading(true);
    const payload: FetchServiceNowSolutionsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchServiceNowSolutionsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(
    (validateFor = "overview") => {
      let tempFormData;
      if (validateFor === "overview") {
        tempFormData = { ...formData };
      } else if (validateFor === "section") {
        tempFormData = { ...sectionsData };
      }
      let valid = true;

      Object.keys(tempFormData).forEach((item) => {
        const currentField = tempFormData[item];

        const validValue = Array.isArray(currentField.value)
          ? currentField.value.length
          : currentField.value;
        if (currentField.required && !validValue) {
          valid = false;
          tempFormData = {
            ...tempFormData,
            [item]: {
              ...tempFormData[item],
              error: "Required Field",
            },
          };
        } else if (item === "resourceLink" && validValue && !validator.isURL(validValue || "")) {
          valid = false;
          tempFormData = {
            ...tempFormData,
            [item]: {
              ...tempFormData[item],
              error: "Please enter valid URL",
            },
          };
        } else if (item === "demoVideo" && validValue && !validator.isURL(validValue || "")) {
          valid = false;
          tempFormData = {
            ...tempFormData,
            [item]: {
              ...tempFormData[item],
              error: "Please enter valid URL",
            },
          };
        }
      });
      if (validateFor === "overview") {
        setFormData(tempFormData);
      } else if (validateFor === "section") {
        setSectionsData(tempFormData);
      }
      return valid;
    },
    [formData, sectionsData],
  );

  const onSubmitAdd = useCallback(() => {
    const { name, active, fullName, shortDescription, technology, listImage } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const listImageValue: Attachments = {
        id: listImage.value.id || null,
        name: listImage.value.name || "",
        extension: listImage.value.extension || "",
      };
      const payload: CreateServiceNowSolutionsPayload = {
        data: {
          attachments: attachments,
          name: name.value,
          active: active.value,
          fullName: fullName.value,
          shortDescription: shortDescription.value,
          description: description,
          technology: technology.value,
          listImage: listImageValue,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createServiceNowSolutions(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, description, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const { name, active, fullName, shortDescription, technology, listImage } = formData;

    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));

      setLoading(true);
      const listImageValue: Attachments = {
        id: listImage.value.id || null,
        name: listImage.value.name || "",
        extension: listImage.value.extension || "",
      };
      const payload: UpdateServiceNowSolutionsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          name: name.value,
          active: active.value,
          fullName: fullName.value,
          shortDescription: shortDescription.value,
          description: description,
          technology: technology.value,
          listImage: listImageValue,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateServiceNowSolutions(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, id, description]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateServiceNowSolutionsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateServiceNowSolutions(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.serviceNowSolutions, title];
  }, [type, data.name]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      if (attachmentModal) {
        setAttachmentModal("");
        setCurrentSectionId("");
      } else {
        setAttachmentModal("list");
      }
      setDocumentId("");
    }
  }, [attachmentModal, attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments?.value?.[index]?.id || "";
      setDocumentId(documentId);
    },
    [formData?.attachments?.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  const updateServiceNowSection = useCallback(() => {
    setLoading(true);
    const sectionImageValue: Attachments = {
      id: sectionsData.sectionImage.value.id || null,
      name: sectionsData.sectionImage.value.name || "",
      extension: sectionsData.sectionImage.value.extension || "",
    };
    const serviceNowSolutionsSection: ServiceNowSolutionsSection = {
      sectionName: sectionsData.sectionName.value,
      type: sectionsData.type.value,
      order: Number(sectionsData.order.value),
      sectionImage: sectionImageValue,
      anyPointers: sectionsData.anyPointers.value,
      pointerLabel: sectionsData.pointerLabel.value,
      pointers: sectionsData.pointers.value,
      demoTitle: sectionsData.demoTitle.value,
      demoVideo: sectionsData.demoVideo.value,
      resourceTitle: sectionsData.resourceTitle.value,
      resourceLabel: sectionsData.resourceLabel.value,
      resourceLink: sectionsData.resourceLink.value,
      sectionDescription: sectionDescription,
      imageVideoPosition: sectionsData.imageVideoPosition.value,
    };
    const payload: UpdateServiceNowSolutionsPayload = {
      id: id || "",
      data: {
        sectionType: "update",
        sectionId: currentSectionId,
        updateSection: serviceNowSolutionsSection,
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Service Now Solutions updated successfully",
          type: "success",
        };
        setSectionsData(defaultSectionsData);
        setSectionsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setSectionsData(defaultSectionsData);
        setSectionsModal("");
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateServiceNowSolutions(payload));
  }, [
    sectionsData.sectionName.value,
    sectionsData.type.value,
    sectionsData.order.value,
    sectionsData.sectionImage.value,
    sectionsData.anyPointers.value,
    sectionsData.pointerLabel.value,
    sectionsData.pointers.value,
    sectionsData.demoTitle.value,
    sectionsData.demoVideo.value,
    sectionsData.resourceTitle.value,
    sectionsData.resourceLabel.value,
    sectionsData.resourceLink.value,
    sectionsData.imageVideoPosition.value,
    sectionDescription,
    id,
    currentSectionId,
    dispatch,
    rehydrateFormData,
    notificationsId,
    defaultSectionsData,
  ]);

  const addServiceNowSection = useCallback(() => {
    const submit = validateForm("section");
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const serviceNowSolutionsSection: ServiceNowSolutionsSection = {
        sectionName: sectionsData.sectionName.value,
        type: sectionsData.type.value,
        order: sectionsData.order.value,
        sectionImage: sectionsData.sectionImage.value,
        anyPointers: sectionsData.anyPointers.value,
        pointerLabel: sectionsData.pointerLabel.value,
        pointers: sectionsData.pointers.value,
        demoTitle: sectionsData.demoTitle.value,
        demoVideo: sectionsData.demoVideo.value,
        resourceTitle: sectionsData.resourceTitle.value,
        resourceLabel: sectionsData.resourceLabel.value,
        resourceLink: sectionsData.resourceLink.value,
        sectionDescription: sectionDescription,
        imageVideoPosition: sectionsData.imageVideoPosition.value,
      };
      const payload: UpdateServiceNowSolutionsPayload = {
        id: id || "",
        data: {
          sectionType: "add",
          sectionItems: [serviceNowSolutionsSection],
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions added successfully",
            type: "success",
          };
          setSectionsData(defaultSectionsData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsModal("");
          setSectionsData(defaultSectionsData);
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateServiceNowSolutions(payload));
    }
  }, [
    validateForm,
    notificationsId,
    dispatch,
    sectionsData.sectionName.value,
    sectionsData.type.value,
    sectionsData.order.value,
    sectionsData.sectionImage.value,
    sectionsData.anyPointers.value,
    sectionsData.pointerLabel.value,
    sectionsData.pointers.value,
    sectionsData.demoTitle.value,
    sectionsData.demoVideo.value,
    sectionsData.resourceTitle.value,
    sectionsData.resourceLabel.value,
    sectionsData.resourceLink.value,
    sectionsData.imageVideoPosition.value,
    sectionDescription,
    id,
    rehydrateFormData,
    defaultSectionsData,
  ]);

  const onSectionDescriptionChange = useCallback((value: string) => {
    setSectionDescription(value);
  }, []);

  const onSectionTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData;

      if (name === "type") {
        // Only update the "type" field and keep others unchanged
        setDescription("");
        tempFormData = {
          ...defaultSectionsData,
          [name]: {
            ...sectionsData[name],
            value,
            error: "",
          },
        };
      } else {
        // Update the field as usual
        tempFormData = {
          ...sectionsData,
          [name]: {
            ...sectionsData[name],
            value,
            error: "",
          },
        };
      }

      setSectionsData(tempFormData);
    },
    [defaultSectionsData, sectionsData],
  );

  const handleSectionsModal = useCallback(
    (item?: string) => {
      const sectionId = typeof item === "string" ? item : "";
      switch (item) {
        case "add":
          setSectionsModal("add");
          setSectionDescription("");

          break;
        // case "edit":
        //   setLearningPointsModal("edit");
        //   break;
        default:
          if (sectionId) {
            const sectionItem = formData.sections?.value.find((item) => item._id === sectionId);
            if (sectionItem?._id) {
              setCurrentSectionId(sectionItem?._id);
            }
            const sectionImageValue: Attachments = {
              id: sectionItem?.sectionImage?.id || null,
              name: sectionItem?.sectionImage?.name || "",
              extension: sectionItem?.sectionImage?.extension || "",
            };
            setSectionDescription(sectionItem?.sectionDescription || "");
            setSectionsData({
              ...sectionsData,
              sectionName: {
                ...sectionsData.sectionName,
                value: sectionItem?.sectionName || "",
              },
              type: {
                ...sectionsData.type,
                value: sectionItem?.type || "",
              },
              order: {
                ...sectionsData.order,
                value: sectionItem?.order || 0,
              },
              sectionDescription: {
                ...sectionsData.sectionDescription,
                value: sectionItem?.sectionDescription || "",
              },
              sectionImage: {
                ...sectionsData.sectionImage,
                value: sectionImageValue || defaultAttachment,
              },
              anyPointers: {
                ...sectionsData.anyPointers,
                value: sectionItem?.anyPointers || false,
              },
              pointers: {
                ...sectionsData.pointers,
                value: sectionItem?.pointers || "",
              },
              demoTitle: {
                ...sectionsData.demoTitle,
                value: sectionItem?.demoTitle || "",
              },
              demoVideo: {
                ...sectionsData.demoVideo,
                value: sectionItem?.demoVideo || "",
              },
              resourceTitle: {
                ...sectionsData.resourceTitle,
                value: sectionItem?.resourceTitle || "",
              },
              resourceLabel: {
                ...sectionsData.resourceLabel,
                value: sectionItem?.resourceLabel || "",
              },
              resourceLink: {
                ...sectionsData.resourceLink,
                value: sectionItem?.resourceLink || "",
              },
              pointerLabel: {
                ...sectionsData.pointerLabel,
                value: sectionItem?.pointerLabel || "",
              },
              imageVideoPosition: {
                ...sectionsData.imageVideoPosition,
                value: sectionItem?.imageVideoPosition || "right",
              },
              _id: sectionItem?._id || "",
            });
            setSectionsModal("edit");
          } else {
            setSectionDescription("");
            setSectionsModal("");
            setSectionsData(defaultSectionsData);
            setSectionsData(defaultSectionsData);
          }
          break;
      }
    },
    [defaultAttachment, defaultSectionsData, formData.sections?.value, sectionsData],
  );

  const deleteServiceNowSection = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const sectionId = e.currentTarget?.getAttribute("data-index") || "";
      setLoading(true);
      const payload: UpdateServiceNowSolutionsPayload = {
        id: id || "",
        data: {
          sectionType: "delete",
          sectionId: sectionId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Service Now Solutions deleted successfully",
            type: "success",
          };
          setSectionsData(defaultSectionsData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsData(defaultSectionsData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateServiceNowSolutions(payload));
    },
    [id, dispatch, rehydrateFormData, notificationsId, defaultSectionsData],
  );

  const onSaveAttachment = useCallback(
    (data, fieldName) => {
      const attachmentsData = {
        id: data.id,
        name: data.name,
        extension: data.extension,
      };
      let tempFormData;
      switch (fieldName) {
        case "listImage": {
          tempFormData = {
            ...formData,
            listImage: {
              ...formData.listImage,
              value: attachmentsData,
            },
          };
          setFormData(tempFormData);
          break;
        }
        case "sectionImage": {
          tempFormData = {
            ...sectionsData,
            sectionImage: {
              ...sectionsData.sectionImage,
              value: attachmentsData,
            },
          };
          setSectionsData(tempFormData);
          break;
        }
      }
    },
    [formData, sectionsData],
  );
  const deleteAttachment = useCallback(
    (fieldName) => {
      let tempFormData;
      switch (fieldName) {
        case "listImage": {
          tempFormData = {
            ...formData,
            listImage: {
              ...formData.listImage,
              value: defaultAttachment,
            },
          };
          setFormData(tempFormData);
          break;
        }
        case "sectionImage": {
          tempFormData = {
            ...sectionsData,
            sectionImage: {
              ...sectionsData.sectionImage,
              value: defaultAttachment,
            },
          };
          setSectionsData(tempFormData);
          break;
        }
      }
    },
    [defaultAttachment, formData, sectionsData],
  );
  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchServiceNowSolutions,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    deleteServiceNowSection,
    sectionsData,
    onDescriptionChange,
    handleSectionsModal,
    sectionsModal,
    onSectionTextChange,
    sectionDescription,
    onSectionDescriptionChange,
    addServiceNowSection,
    updateServiceNowSection,
    onSaveAttachment,
    deleteAttachment,
    description,
  };
};
