import React, { useCallback, useMemo, useState } from "react";

import { RuleGroupTypeIC } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchUsers,
  removeAppNotification,
  updateManyUsers,
} from "../../../../../redux";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import {
  convertArrayToTable,
  isEqualArrays,
  createXMLTable,
  createFileUrl,
  downloadFile,
  MomentDate,
} from "../../../../../services/methods";
import { colors, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  GetAccessLevelColor,
  GetUsersArguments,
  UseUsersList,
  UseUsersListProps,
  UsersFilters,
  UsersListItem,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";

import { getProfile, updateTableConfigs } from "./../../../../../redux/user/actions";

const defaultFilters: UsersFilters[] = [];

const { unityAdmin, epAccess, admin, ogAdmin } = strings.apUsers;
const { green, red, orange, purple } = colors.common;
const { add, edit } = AppRouteLinks.users;

export const useUsersList = ({ querySearch, queryPage }: UseUsersListProps): UseUsersList => {
  const metaData = useAppSelector((state) => state.UsersReducer.data.metaData);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const users = useAppSelector((state) => state.UsersReducer.data.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [appliedFilters, setAppliedFiltersFilters] = useState<UsersFilters[]>(defaultFilters);
  const [filters, setFilters] = useState<UsersFilters[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [jsonQuery, setJsonQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<"asc" | "desc">("asc");
  const [allUsers, setAllUsers] = useState([]);
  // Row selection methods
  const allRowsSelected = useMemo(() => {
    const allUsers = users?.map((item) => item?._id);
    return isEqualArrays(allUsers, selectedRows);
  }, [selectedRows, users]);
  const getAccessLevelColor = useCallback((user: UsersListItem): GetAccessLevelColor => {
    const { superAdmin, organisationAdmin } = user;
    const accessLevelMeta = {
      color: green,
      toolTip: epAccess,
    };

    if (superAdmin) {
      accessLevelMeta.color = red;
      accessLevelMeta.toolTip = unityAdmin;
    } else if (organisationAdmin) {
      accessLevelMeta.color = orange;
      accessLevelMeta.toolTip = ogAdmin;
    } else if (user.admin) {
      accessLevelMeta.color = purple;
      accessLevelMeta.toolTip = admin;
    }

    return accessLevelMeta;
  }, []);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allUsers = users?.map((item) => item?._id);
            setSelectedRows(allUsers);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, users, allRowsSelected],
  );

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = users?.filter((item) => selectedRows.includes(item?._id));
      const payload = {
        data: {
          ids: selectedRecords.map((item) => item?._id),
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          getUsers({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Users updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating users",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };

      dispatch(updateManyUsers(payload));
    },
    [selectedRows, notificationsId, dispatch, users],
  );

  const getUsers = useCallback(
    ({
      page,
      limit,
      clearSearch,
      configFields,
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: GetUsersArguments) => {
      setLoading(true);
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.users?.limit || 10;
      const usersListData = {
        fields: configFields?.length ? configFields : tableConfiguration?.users?.fields,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      const payload = {
        page: itemPage,
        limit: recoredsLimit,
        data: usersListData,
        exportAll,
        onSuccess: async (data) => {
          if (exportAll) {
            console.log("ALL USERS ::", data);
            const userPersonalizedFields = tableConfiguration?.users?.fields;
            const allUsers = data?.data?.list?.map((item) => {
              const userItem = { ...item };
              if (userPersonalizedFields?.includes("accessLevel")) {
                const { toolTip } = getAccessLevelColor(item);
                userItem.accessLevel = toolTip;
              }
              if (userPersonalizedFields?.includes("createdAt")) {
                userItem.createdAt = MomentDate({
                  date: item.createdAt || "2023-01-01T08:54:36.357Z",
                  format: "YYYY-MM-DD",
                });
              }
              if (userPersonalizedFields?.includes("updatedAt")) {
                userItem.updatedAt = MomentDate({
                  date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                  format: "YYYY-MM-DD",
                });
              }
              if (userPersonalizedFields?.includes("createdBy")) {
                userItem.createdBy = item?.createdBy?.name || "Admin";
              }
              if (userPersonalizedFields?.includes("updatedBy")) {
                userItem.updatedBy = item?.updatedBy?.name || "Admin";
              }
              delete userItem._id;
              return userItem;
            });
            const usersFields = tablePublicFields.users;
            const columnHeaders = usersFields.filter((item) =>
              userPersonalizedFields.includes(item.key),
            );
            downloadFile(columnHeaders, allUsers, "users");
          }
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      };
      Object.keys(filters).map((item) => {
        if (filters[item]) {
          payload[item] = filters[item];
        }
      });
      if (clearSearch) {
        payload["search"] = "";
      }
      dispatch(fetchUsers(payload));
    },
    [
      dispatch,
      metaData.currentPage,
      filters,
      tableConfiguration,
      mongoQuery,
      sortKey,
      sortType,
      getAccessLevelColor,
    ],
  );

  // Export items methods
  const exportRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const userPersonalizedFields: string[] =
      tableConfiguration?.users?.fields || tableListDefaultSelectFields.users;
    let selectedRecords = users?.filter((item) => selectedRows.includes(item?._id));
    selectedRecords = selectedRecords?.map((item) => {
      const userItem = { ...item };
      if (userPersonalizedFields?.includes("accessLevel")) {
        const { toolTip } = getAccessLevelColor(item);
        userItem.accessLevel = toolTip;
      }
      if (userPersonalizedFields?.includes("createdAt")) {
        userItem.createdAt = MomentDate({
          date: item.createdAt || "2023-01-01T08:54:36.357Z",
          format: "YYYY-MM-DD",
        });
      }
      if (userPersonalizedFields?.includes("updatedAt")) {
        userItem.updatedAt = MomentDate({
          date: item.updatedAt || "2023-01-01T08:54:36.357Z",
          format: "YYYY-MM-DD",
        });
      }
      if (userPersonalizedFields?.includes("createdBy")) {
        userItem.createdBy = item?.createdBy?.name || "Admin";
      }
      if (userPersonalizedFields?.includes("updatedBy")) {
        userItem.updatedBy = item?.updatedBy?.name || "Admin";
      }
      delete userItem._id;
      return userItem;
    });
    const usersFields = tablePublicFields.users;
    const columnHeaders = usersFields.filter((item) => userPersonalizedFields.includes(item.key));
    downloadFile(columnHeaders, selectedRecords, "users");
  }, [selectedRows, tableConfiguration, users, notificationsId, dispatch, getAccessLevelColor]);

  const exportAllRecords = useCallback(async () => {
    getUsers({ exportAll: true });
  }, [getUsers]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setJsonQuery(jsonQuery);
      setFilterLogicQuery(filterLogicQuery);
      getUsers({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [getUsers],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      getUsers({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [getUsers],
  );

  const onClickCloseFilter = useCallback(() => {
    const tempFilters = { ...filters };
    setFilters(tempFilters);
  }, [filters]);

  const onSearch = useCallback(() => {
    const tempFilters = { ...filters };
    setAppliedFiltersFilters(tempFilters);
    getUsers({ page: 1, searchQuery: filters.search });
  }, [getUsers, filters]);

  const reloadUsers = useCallback(() => {
    const page = metaData.currentPage || 1;
    getUsers({ page });
  }, [getUsers, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      getUsers({ page: parseInt(page) });
    },
    [getUsers],
  );

  const handleTableConfigField = useCallback((e: React.MouseEvent) => {
    const key = e.currentTarget.getAttribute("data-key");
    console.log(key);
  }, []);

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      setLoading(true);
      const academyCoursesConfigLimit = tableConfiguration?.users?.limit || 10;
      const userConfigs = {
        table: "users",
        fields: fields,
        limit: academyCoursesConfigLimit,
      };
      const payload = {
        tableConfiguration: userConfigs,
        onSuccess: () => {
          setLoading(false);
          getUsers({ configFields: fields });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "User table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, getUsers, notificationsId, tableConfiguration?.users?.limit],
  );

  const handleShowItemsPerPage = useCallback(
    (e: React.ChangeEvent) => {
      const value = e.target?.value;
      const userConfigFields = tableConfiguration?.users?.fields;
      setLoading(true);
      const userConfigs = {
        table: "users",
        fields: userConfigFields,
        limit: parseInt(value),
      };
      const payload = {
        tableConfiguration: userConfigs,
        onSuccess: () => {
          setLoading(false);
          getUsers({ configFields: userConfigFields, limit: Number(value) });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "User table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, getUsers, notificationsId, tableConfiguration?.users?.fields],
  );

  const onClickUser = useCallback(
    (id: string) => {
      if (permissions?.users_view) {
        const path = edit + id;
        navigate(path);
      }
    },
    [navigate, permissions],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  const handleClearSearch = useCallback(() => {
    let userFilters = { ...filters };
    userFilters = {
      ...userFilters,
      search: "",
    };
    setFilters(userFilters);
    getUsers({ page: 1, clearSearch: true });
  }, [getUsers, filters]);

  return {
    getUsers,
    handleSortList,
    mongoQuery,
    jsonQuery,
    filterLogicQuery,
    handleShowItemsPerPage,
    handleListActions,
    exportRecords,
    exportAllRecords,
    allRowsSelected,
    selectedRows,
    onClickRowSelection,
    loading,
    getAccessLevelColor,
    onClickUser,
    handlePageChange,
    onSearch,
    onClickAdd,
    handleClearSearch,
    onFilterChange,
    reloadUsers,
    filters,
    onClickApplyFilter,
    onClickCloseFilter,
    handleTableConfigs,
    handleTableConfigField,
  };
};
