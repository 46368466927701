import React, { useCallback, useEffect, useRef, memo, useMemo } from "react";

import { useParams } from "react-router-dom";

import { AnnouncementsControllerProps } from "../../../../types";
import {
  ActiveSelectOptions,
  AnnouncementsCategoriesOptions,
  AnnouncementsSelectOptions,
  collections,
} from "../../../../utils/constants";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import AttachmentInput from "../../../components/atoms/AttachmentInput/AttachmentInput";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useAnnouncementsController } from "./hooks";

const Controller = ({ type }: AnnouncementsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const {
    formSteps,
    formTitle,
    formRelatedList,
    currentStepIndex,
    headerButtons,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAnnouncements,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    deleteAttachment,
    onSaveAttachment,
    data,
    onDateChange,
  } = useAnnouncementsController({ type, id, attachmentsPickerRef });

  const currentAnnouncements = data;
  const {
    workLogs,
    attachments,
    title,
    message,
    active,
    date,
    host,
    order,
    location,
    description,
    image,
    ctaButton,
    category,
    ctaButtonLink,
    ctaButtonTitle,
    shortDescription,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAnnouncements();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Title'
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <AttachmentInput
            aclType={"public"}
            attachment={image}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='image'
            label='Image'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />{" "}
          <Select
            name={"category"}
            label={"Announcements Category"}
            options={AnnouncementsCategoriesOptions}
            value={formData.category.value}
            onChange={onTextChange}
            mandatory={formData.category.required}
            error={formData.category.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Order'
            type={"text"}
            width='48%'
            error={order.error}
            inputProps={{
              name: "order",
              type: "number",
              value: order.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: order.required,
            }}
          />
          <Select
            name={"type"}
            label={"Announcements Type"}
            options={AnnouncementsSelectOptions}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Host'
            type={"text"}
            width='48%'
            error={host.error}
            inputProps={{
              name: "host",
              value: host.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: host.required,
            }}
          />
          <TextInput
            label='Location'
            type={"text"}
            width='48%'
            error={location.error}
            inputProps={{
              name: "location",
              value: location.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: location.required,
            }}
          />
        </Row>
        <Row>
          <Select
            name={"ctaButton"}
            label={"CTA Button"}
            options={ActiveSelectOptions}
            value={ctaButton.value}
            onChange={onTextChange}
            mandatory={ctaButton.required}
            error={ctaButton.error}
          />
          <TextInput
            label='CTA Button Title'
            type={"text"}
            width='48%'
            error={ctaButtonTitle.error}
            inputProps={{
              name: "ctaButtonTitle",
              value: ctaButtonTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: ctaButtonTitle.required,
            }}
          />
        </Row>
        <TextInput
          label='CTA Button Link'
          type={"text"}
          width='100%'
          error={ctaButtonLink.error}
          inputProps={{
            name: "ctaButtonLink",
            value: ctaButtonLink.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: ctaButtonLink.required,
          }}
        />
        <TextInput
          label='Date'
          type={"date"}
          width='48%'
          error={date.error}
          onDateChange={onDateChange}
          datePickerProps={{
            name: "date",
            value: date.value,
            required: date.required,
          }}
          textAreaProps={{}}
        />
        <Row>
          <TextInput
            label='Short Description'
            type={"textarea"}
            width='100%'
            error={shortDescription.error}
            textAreaProps={{
              name: "shortDescription",
              rows: 3,
              value: shortDescription.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: shortDescription.required,
            }}
          />
        </Row>
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={message.error}
          textAreaProps={{
            name: "message",
            rows: 12,
            value: message.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: message.required,
          }}
        />
      </React.Fragment>
    );
  }, [
    title.error,
    title.value,
    title.required,
    onTextChange,
    active.value,
    active.required,
    active.error,
    image,
    deleteAttachment,
    onSaveAttachment,
    formData.category.value,
    formData.category.required,
    formData.category.error,
    formData.type.value,
    formData.type.required,
    formData.type.error,
    order.error,
    order.value,
    order.required,
    host.error,
    host.value,
    host.required,
    location.error,
    location.value,
    location.required,
    ctaButton.value,
    ctaButton.required,
    ctaButton.error,
    ctaButtonTitle.error,
    ctaButtonTitle.value,
    ctaButtonTitle.required,
    ctaButtonLink.error,
    ctaButtonLink.value,
    ctaButtonLink.required,
    date.error,
    date.value,
    date.required,
    onDateChange,
    shortDescription.error,
    shortDescription.value,
    shortDescription.required,
    message.error,
    message.value,
    message.required,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentAnnouncements?.createdBy !== "string"
        ? currentAnnouncements?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAnnouncements?.updatedBy !== "string"
        ? currentAnnouncements?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAnnouncements?.createdAt}
        updatedAt={currentAnnouncements?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAnnouncements?.createdAt,
    currentAnnouncements?.createdBy,
    currentAnnouncements?.updatedAt,
    currentAnnouncements?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.announcements.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.announcements.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const AnnouncementsController = memo(Controller);
