/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ITNRFP = "FETCH_ITNRFP",
  SUCCESS_FETCH_ITNRFP = "SUCCESS_FETCH_ITNRFP",
  ERROR_FETCH_ITNRFP = "ERROR_FETCH_ITNRFP",
  CREATE_ITNRFP = "CREATE_ITNRFP",
  UPDATE_ITNRFP = "UPDATE_ITNRFP",
  DELETE_ITNRFP = "DELETE_ITNRFP",
  FETCH_ITNRFP_BY_ID = "FETCH_ITNRFP_BY_ID",
}

export default Types;
