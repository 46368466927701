import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchJobsPostingList,
  getProfile,
  removeAppNotification,
  updateJobsPosting,
  showInAppLoader,
  updateTableConfigs,
  deleteJobsPosting,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  JobsPostingListWithMetaData,
  FetchJobsPostingParams,
  JobsPostingList,
  DeleteJobsPostingPayload,
} from "../../../../../redux/jobs/types";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

import {
  fetchJobsPostingProps,
  useJobsPostingListProps,
  useJobsPostingListReturnType,
} from "./../../../../../types/models/JobsPosting";

const { add, edit } = AppRouteLinks.jobsPosting;
const defaultFilters: QueryBuilderType[] = [];

export const useJobsPostingList = ({
  relatedList,
}: useJobsPostingListProps): useJobsPostingListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.JobsPostingReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const jobsPosting = useAppSelector((state) => state.JobsPostingReducer.data.list);
  const { usersOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: JobsPostingList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.jobsPosting?.fields || tableListDefaultSelectFields.jobsPosting;
      records = records?.map((item) => {
        const jobsPostingtem = { ...item };
        if (userPersonalizedFields?.includes("type") && typeof item.type !== "string") {
          jobsPostingtem.type = item?.type?.value;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          jobsPostingtem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          jobsPostingtem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          jobsPostingtem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          jobsPostingtem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete jobsPostingtem?._id;
        return jobsPostingtem;
      });
      const jobsPostingFields = tablePublicFields.jobsPosting;
      const columnHeaders = jobsPostingFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "jobsPosting");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.jobsPosting?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const jobsPostingFetchSuccess = useCallback(
    (data: JobsPostingListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const jobsPostingFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchJobsPosting = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchJobsPostingProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.jobsPosting?.limit || 10;

      const params: FetchJobsPostingParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["title"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: JobsPostingListWithMetaData) => jobsPostingFetchSuccess(data, exportAll),
        onError: jobsPostingFetchError,
      };
      dispatch(fetchJobsPostingList(payload));
    },
    [
      dispatch,
      jobsPostingFetchError,
      jobsPostingFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = jobsPosting?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, jobsPosting, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchJobsPosting({ exportAll: true });
    },
    [fetchJobsPosting],
  );

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchJobsPosting({ page });
  }, [fetchJobsPosting, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchJobsPosting({ page: parseInt(page), search });
    },
    [fetchJobsPosting, search],
  );

  const onClickJobsPosting = useCallback(
    (id: string) => {
      if (permissions?.jobs_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.jobs_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchJobsPosting({ page: 1, search });
  }, [fetchJobsPosting, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchJobsPosting({ page: 1 });
  }, [fetchJobsPosting]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchJobsPosting({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchJobsPosting],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchJobsPosting({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchJobsPosting],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = jobsPosting?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, jobsPosting]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            jobsPosting?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, jobsPosting, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const jobsPostingConfigLimit = tableConfiguration?.jobsPosting?.limit || 10;
      const jobsPostingConfigs = {
        table: "jobsPosting",
        fields: fields,
        limit: jobsPostingConfigLimit,
      };
      const payload = {
        tableConfiguration: jobsPostingConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchJobsPosting({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "JobsPosting table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.jobsPosting?.limit, dispatch, fetchJobsPosting, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const jobsPostingConfigFields = tableConfiguration?.jobsPosting?.fields;
      dispatch(showInAppLoader(true));
      const jobsPostingConfigs = {
        table: "jobsPosting",
        fields: jobsPostingConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: jobsPostingConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchJobsPosting({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "JobsPosting table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));

          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchJobsPosting],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = jobsPosting?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchJobsPosting({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Jobs Posting updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Jobs Posting",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateJobsPosting(payload));
    },
    [selectedRows, jobsPosting, dispatch, notificationsId, fetchJobsPosting],
  );

  // ================ Delete Method ================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = jobsPosting?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteJobsPostingPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchJobsPosting({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteJobsPosting(payload));
  }, [selectedRows, jobsPosting, dispatch, notificationsId, fetchJobsPosting]);
  // ================ Table Header Buttons ================

  const listActionOptions: ListActions[] = [
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "type",
      label: "Type",
      options: getAttributValues("jobType"),
    },
    {
      name: "title",
      label: "Title",
      type: "text",
      required: true,
    },
    {
      name: "summary",
      label: "Summary",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
    },
    {
      name: "customDate",
      label: "Custom Date",
      type: "date",
    },
    {
      name: "salary",
      label: "Salary",
      type: "text",
    },
    {
      name: "location",
      label: "Location",
      type: "text",
    },
    {
      name: "sector",
      label: "Sector",
      type: "text",
    },
    {
      name: "contactName",
      label: "Contact Name",
      type: "text",
    },
    {
      name: "contactNumber",
      label: "Contact Number",
      type: "text",
    },
    {
      name: "contactEmail",
      label: "contactEmail",
      type: "text",
    },
    {
      name: "skills",
      label: "skills",
      type: "text",
    },
    {
      name: "referenceId",
      label: "Reference Id",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const jobsPostingFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");
    const JobsPostingyOptions = getFilterOptions(getAttributValues("jobType"), "Type");

    const fields: Field[] = [
      {
        name: "title",
        label: "Title",
        placeholder: "Enter Title",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "summary",
        label: "Summary",
        placeholder: "Enter Summary",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "salary",
        label: "Salary",
        placeholder: "Enter Salary",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "type",
        label: "Type",
        valueEditorType: "select",
        values: JobsPostingyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "loaction",
        label: "Location",
        placeholder: "Enter Location",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "sector",
        label: "Sector",
        placeholder: "Enter Sector",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "contactName",
        label: "Contact Name",
        placeholder: "Enter Contact Name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "contactNumber",
        label: "Contact Number",
        placeholder: "Enter Contact Number",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "contactEmail",
        label: "Contact Email",
        placeholder: "Contact Email",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "skills",
        label: "Skills",
        placeholder: "Enter Skills",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "referenceId",
        label: "ReferenceId",
        valueEditorType: "select",
        placeholder: "Enter Reference Id",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "customDate",
        label: "Custom Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions]);

  return {
    listActionOptions,
    jobsPostingFilterFields,
    onClickJobsPosting,
    fetchJobsPosting,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    onSubmitDeleteRecord,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
  };
};
