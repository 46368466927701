import React from "react";

import { Layout } from "../../../presentationals";
import { CampaignsAssetsController } from "../../../presentationals/screens/admin/CampaignsAssets/CampaignsAssetsController";

const Route = () => {
  return (
    <Layout activity={<CampaignsAssetsController type={"edit"} />} admin={true} layout={"2"} />
  );
};

export const APCampaignsAssetsEdit = React.memo(Route);
