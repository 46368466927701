import Types from "./actionType";
import {
  FetchSPSBGCheckPayload,
  CreateSPSBGCheck,
  CreateSPSBGCheckPayload,
  FetchSPSBGCheck,
  FetchSPSBGCheckError,
  FetchSPSBGCheckSuccess,
  UpdateSPSBGCheck,
  UpdateSPSBGCheckPayload,
  SPSBGCheckListWithMetaData,
  FetchSPSBGCheckByIdPayload,
  FetchSPSBGCheckById,
  DeleteSPSBGCheckPayload,
  DeleteSPSBGCheck,
} from "./types";

export const fetchSPSBGCheckList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSBGCheckPayload): FetchSPSBGCheck => ({
  type: Types.FETCH_SPS_BG_CHECK,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSBGCheckById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSBGCheckByIdPayload): FetchSPSBGCheckById => ({
  type: Types.FETCH_SPS_BG_CHECK_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSBGCheckListSuccess = (
  data: SPSBGCheckListWithMetaData,
): FetchSPSBGCheckSuccess => ({
  type: Types.SUCCESS_FETCH_SPS_BG_CHECK,
  payload: {
    data,
  },
});

export const fetchSPSBGCheckError = (error?: string): FetchSPSBGCheckError => ({
  type: Types.ERROR_FETCH_SPS_BG_CHECK,
  payload: { error },
});

export const createSPSBGCheck = ({
  data,
  onSuccess,
  onError,
}: CreateSPSBGCheckPayload): CreateSPSBGCheck => ({
  type: Types.CREATE_SPS_BG_CHECK,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSBGCheck = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSBGCheckPayload): UpdateSPSBGCheck => ({
  type: Types.UPDATE_SPS_BG_CHECK,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSBGCheck = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSBGCheckPayload): DeleteSPSBGCheck => ({
  type: Types.DELETE_SPS_BG_CHECK,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
