import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  getToken,
  UsersList,
  UsersAdd,
  UsersUpdate,
  getOrganisation,
  GetUserById,
  UsersUpdateMany,
  UsersAddChecklist,
  UsersUpdateChecklist,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchUsersError, fetchUsersSuccess, updateUsers, setMetaData } from "./actions";
import Types from "./types";

function* fetchUsersWorker(action) {
  try {
    const page = action.payload?.page || 1;
    const limit = action.payload?.limit || 10;
    const isEmployee = action.payload?.employee;
    const isExportAll = action.payload?.exportAll;
    let params = {};
    if (isEmployee) {
      params = {
        employee: true,
      };
    } else if (isExportAll) {
      params = {
        exportType: "all",
      };
    } else {
      params = {
        page,
        limit,
      };
    }

    if (action.payload.search) {
      params.search = action.payload.search;
    }

    const options = {
      method: "POST",
      url: UsersList,
      data: action.payload.data,
      params,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      if (!isExportAll) {
        yield put(fetchUsersSuccess(response.data.data, isEmployee));
      }
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      if (!isExportAll) {
        yield put(fetchUsersError(response.data.error));
      }
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchUsersError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getUserByIdWorker(action) {
  try {
    const id = action?.payload?.id;
    const options = {
      method: "GET",
      url: GetUserById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      throw response.data.error;
    }
  } catch (e) {
    yield put(fetchUsersError(e));
    action?.payload?.onError && action.payload.onError(e);
  }
}

function* addUsersWorker(action) {
  try {
    const options = {
      method: "POST",
      url: UsersAdd(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateUsersWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: UsersUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateManyUsersWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: UsersUpdateMany,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* addUsersChecklistWorker(action) {
  try {
    const options = {
      method: "POST",
      url: UsersAddChecklist,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      console.log(response, response.data);
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    console.log(e.response?.data);
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateUsersChecklistWorker(action) {
  try {
    const id = action.payload?.id;
    const options = {
      method: "PUT",
      url: UsersUpdateChecklist(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* UsersSaga() {
  yield takeLatest(Types.USERS_FETCH, fetchUsersWorker);
  yield takeLatest(Types.GET_USER_BY_ID, getUserByIdWorker);
  yield takeLatest(Types.USERS_ADD, addUsersWorker);
  yield takeLatest(Types.USERS_UPDATE, updateUsersWorker);
  yield takeLatest(Types.USERS_UPDATE_MANY, updateManyUsersWorker);
  yield takeLatest(Types.USERS_ADD_CHECKLIST, addUsersChecklistWorker);
  yield takeLatest(Types.USERS_UPDATE_CHECKLIST, updateUsersChecklistWorker);
}
