import React from "react";

import { Layout } from "../../../presentationals";
import { TagsController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<TagsController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APTagsEdit = React.memo(Route);
