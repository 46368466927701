import React, { useEffect } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { EmployeeEmergencyContact } from "../../../../types";
import { useControllerConfigs } from "../../../common";
import { CloseIcon } from "../../../common/styles";
import { Button, Popup, Select, TableList, TextInput } from "../../../components";

import { useEmployeesList } from "./hooks";
import { useEmployeeEmergencyContact } from "./hooks/useEmergencyContacts";
import { FormTitle, Row, SalaryFieldsContainer, SalaryModalContainer } from "./styles";

interface Props {
  firstName: string;
  lastName: string;
  emergencyContact: EmployeeEmergencyContact[];
  employeeId: string;
  fetchEmployees: () => void;
}

export const EmployeeEmergencyContactDetails = ({
  employeeId,
  firstName,
  lastName,
  emergencyContact,
  fetchEmployees,
}: Props) => {
  //   const loading = useAppSelector((state) => state.UsersReducer.loading);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const employees = useAppSelector((state) => state.EmployeesReducer.data);
  const {
    handleSalaryModal,
    salaryModal,
    addSalaryModal,
    editSalaryModal,
    onDateChange,
    onTextChange,
    formData,
    onSubmitAdd,
    onSubmitUpdate,
  } = useEmployeeEmergencyContact({ emergencyContact, fetchEmployees, employeeId });

  const { addEmergencyContact, editEmergencyContact } = strings.apEmployees;

  const columns = [
    {
      title: "Serial",
      width: "10%",
      key: "serial",
    },
    {
      title: "Name",
      width: "22.5%",
      render: () => {
        return <div className='title'>{`${firstName} ${lastName}`}</div>;
      },
    },
    {
      title: "Emergency Contact Name",
      width: "22.5%",
      render: (item) => {
        return (
          <div className='title'>{`${item.emergencyFirstName} ${item.emergencyLastName}`}</div>
        );
      },
    },
    {
      title: "Emergency Relation",
      width: "22.5%",
      key: "emergencyRelation",
    },
    {
      title: "Type",
      width: "22.5%",
      render: (item) => {
        return <div className='title'>{item?.type?.value}</div>;
      },
    },
  ];

  const {
    type,
    emergencyAddress,
    emergencyFirstName,
    emergencyLastName,
    emergencyMobile,
    emergencyRelation,
  } = formData;

  return (
    <>
      <TableList
        // headerTitle={strings.headerTitle.employees}
        rightButton={strings.common.addNew}
        rightButtonPress={addSalaryModal}
        // filterButton={strings.common.filter}
        // filterButtonPress={onClickFilter}
        loading={false}
        list={emergencyContact}
        columns={columns}
        // onPageChange={handlePageChange}
        onClickTableRow={editSalaryModal}
        // metaData={employees?.metaData}
      />
      <Popup isOpen={!!salaryModal} closeModal={handleSalaryModal}>
        <SalaryModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleSalaryModal} />
          <FormTitle>
            {salaryModal === "add"
              ? addEmergencyContact
              : salaryModal === "edit"
              ? editEmergencyContact
              : ""}
          </FormTitle>
          <SalaryFieldsContainer>
            <Row>
              <TextInput
                label={"First Name"}
                type={"text"}
                width='48%'
                error={emergencyFirstName.error}
                inputProps={{
                  name: "emergencyFirstName",
                  value: emergencyFirstName.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: emergencyFirstName.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Last Name"}
                type={"text"}
                width='48%'
                error={emergencyLastName.error}
                inputProps={{
                  name: "emergencyLastName",
                  value: emergencyLastName.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: emergencyLastName.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <TextInput
                label={"Mobile"}
                type={"text"}
                width='48%'
                error={emergencyMobile.error}
                inputProps={{
                  name: "emergencyMobile",
                  value: emergencyMobile.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: emergencyMobile.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Relation"}
                type={"text"}
                width='48%'
                error={emergencyRelation.error}
                inputProps={{
                  name: "emergencyRelation",
                  value: emergencyRelation.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: emergencyRelation.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Select
              width={"100%"}
              name={"type"}
              label={"Type"}
              options={getAttributValues("emergencyContactType")}
              value={type.value}
              onChange={onTextChange}
              mandatory={type.required}
              error={type.error}
            />
            <TextInput
              label={"Address"}
              type={"text"}
              width='100%'
              error={emergencyAddress.error}
              inputProps={{
                name: "emergencyAddress",
                value: emergencyAddress.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: emergencyAddress.required,
              }}
              textAreaProps={{}}
            />
          </SalaryFieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={salaryModal === "add" ? strings.common.add : strings.common.save}
            onClick={salaryModal === "add" ? onSubmitAdd : onSubmitUpdate}
          />
        </SalaryModalContainer>
      </Popup>
    </>
  );
};
