import React, { useState, useCallback, useMemo, SetStateAction, Dispatch } from "react";

import { useNavigate } from "react-router-dom";

import config from "../../../../../config";
import {
  addAppNotification,
  fetchControllerConfigs,
  fetchKnowledgeDetails,
  removeAppNotification,
  retireKnowledge,
  reviseKnowledge,
  updateApproval,
  updateReview,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { KnowledgeDetailsType } from "../../../../../redux/knowledge/types";
import { getArticlePermissions, getPrefixDigit } from "../../../../../services/methods";
import { colors, strings } from "../../../../../theme";
import { AppNotificationsType, KnowledgeStatus, SelectOptions } from "../../../../../types";

const draftArticleActions = [{ label: "Request Review", value: "Request Review" }];
const inreviewArticlesActions = [
  { label: "Approve", value: "approved" },
  { label: "Reject", value: "rejected" },
];
const publishedArticleActions = [
  { label: "Revise", value: "revise" },
  { label: "Retire", value: "retire" },
];

const actionsPermissions = ["owner", "manager", "comanager"];

interface Props {
  type: string;
}

interface UseKnowledgeDetails {
  knowledge: KnowledgeDetailsType;
  fetchKnowledgeSuccess: (knowledge: KnowledgeDetailsType) => void;
  fetchKnowledgeError: (error?: string) => void;
  headerTitle: string[];
  loading: boolean;
  knowledgeSerial: string;
  status: KnowledgeStatus;
  isRejected: (knowledge: object) => void;
  approvalList: object;
  currentVersion: string;
  versionHistory: SelectOptions[];
  versionPopup: boolean;
  handleVersionPopup: () => void;
  actionsPopup: boolean;
  actionsModal: "" | "review" | "approve" | "reject" | "revise" | "retire" | "comment";
  handleActionsPopup: () => void;
  handleActionsModal: () => void;
  knowledgeActions: SelectOptions[];
  handleEditArticle: () => void;
  handleActionsDropdown: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleRequestReview: () => void;
  closeAllPopup: () => void;
  comment: string;
  handleComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleApprove: () => void;
  handleReject: () => void;
  handleRevise: () => void;
  handleRetire: () => void;
  isActionLoading: boolean;
  handleCommentStatus: (e: React.MouseEvent<HTMLImageElement>) => void;
  commentError: string;
  handleOnVersionClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  documentId: string;
  attachmentModal: boolean;
  handleAttachmentsModal: () => void;
  handleShowAttachment: (e: React.MouseEvent<HTMLImageElement>) => void;
  error: string;
}

const defaultKnowledge: KnowledgeDetailsType = {
  _id: "",
  name: "",
  serial: 0,
  organisation: "",
  summary: "",
  body: "",
  knowledgebase: {
    _id: "",
    title: "",
    description: "",
    approval: "all",
    active: false,
    members: [],
    contributers: [],
  },
  subcategory: {
    _id: "",
    name: "",
  },
  modules: [],
  category: {
    _id: "",
    name: "",
  },
  publishedDate: "",
  revisedKnowledge: {
    _id: "",
    serial: 0,
    revisionHistory: [],
  },
  revisionHistory: [],
  status: "drafted",
  createdBy: {
    _id: "",
    name: "",
  },
  updatedBy: {
    _id: "",
    name: "",
  },
  votes: 0,
  approvals: [],
  attachments: [],
  createdAt: "",
  updatedAt: "",
};

export const useKnowledgeDetails = ({ type }: Props): UseKnowledgeDetails => {
  const userId = useAppSelector((state) => state.UserReducer.id);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [knowledge, setKnowledge] = useState<KnowledgeDetailsType>(defaultKnowledge);
  const [versionPopup, setVersionPopup] = useState<boolean>(false);
  const [actionsPopup, setActionsPopup] = useState<boolean>(false);
  const [actionsModal, setActionsModal] = useState<
    "" | "review" | "approve" | "reject" | "revise" | "retire" | "comment"
  >("");
  const [comment, setComment] = useState<string>("");
  const [commentError, setCommentError] = useState<string>("");
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>("");
  const [attachmentModal, setAttachmentModal] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleAttachmentsModal = useCallback(() => {
    setAttachmentModal((value) => !value);
    setDocumentId("");
  }, []);

  const closeAllPopup = useCallback((e: React.MouseEvent) => {
    console.log(e.currentTarget);
    setActionsPopup(false);
    setVersionPopup(false);
  }, []);

  const handleShowAttachment = useCallback((e: React.MouseEvent<HTMLImageElement>) => {
    const documentId = e.currentTarget.getAttribute("data-id") || "";
    setDocumentId(documentId);
    setAttachmentModal(true);
  }, []);

  const isRejected = useCallback((knowledge) => {
    if (knowledge?.approvals?.length > 0) {
      for (let i = 0; i < knowledge.approvals.length; i++) {
        if (knowledge.approvals[i].status === "rejected") {
          return true;
        }
      }
    }
    return false;
  }, []);
  const handleVersionPopup = useCallback(
    (e: React.MouseEvent) => {
      e?.stopPropagation();
      if (knowledge.revisionHistory.length || knowledge?.revisedKnowledge?._id) {
        setVersionPopup((value) => !value);
      }
    },
    [knowledge],
  );

  const handleActionsPopup = useCallback((e: React.MouseEvent) => {
    e?.stopPropagation();
    setActionsPopup((value) => !value);
  }, []);

  const handleActionsModal = useCallback(() => {
    setActionsPopup(false);
    setActionsModal("");
    setComment("");
    setCommentError("");
  }, []);

  const handleEditArticle = useCallback(() => {
    navigate(`/ep/knowledge/${knowledge._id}/edit`);
  }, [knowledge, navigate]);

  const headerTitle = useMemo(() => {
    const { allArticles, inreview, myArticles, published } = strings.epKnowledge;
    let title = strings.epKnowledge.knowledge;
    switch (type) {
      case "all":
        title = allArticles;
        break;
      case "inreview":
        title = inreview;
        break;
      case "my":
        title = myArticles;
        break;
      case "published":
        title = published;
        break;
    }
    return [title, knowledge.name];
  }, [type, knowledge.name]);

  const fetchKnowledgeSuccess = useCallback((knowledge: KnowledgeDetailsType) => {
    setLoading(false);
    setKnowledge(knowledge);
    setIsActionLoading(false);
  }, []);

  const fetchKnowledgeError = useCallback(
    (error) => {
      if (error === 403) {
        navigate(-1);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "You do not have permission to view this article",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 5000);
        setError("Permission Denied");
      }
      setLoading(false);
      setIsActionLoading(false);
    },
    [dispatch, notificationsId, navigate],
  );

  const knowledgeSerial = useMemo(() => {
    return `${config.prefix.knowledge} ${getPrefixDigit(knowledge?.serial, 5)}`;
  }, [knowledge?.serial]);

  const status = useMemo(() => {
    const { drafted, inreview, published, revised } = strings.epKnowledge;
    const { draftStatus, inreviewStatus, publishedStatus } = colors.knowledge;
    const status: KnowledgeStatus = {
      title: "",
      color: colors.knowledge.publishedStatus,
    };
    switch (knowledge.status) {
      case "drafted":
        status.title = drafted;
        status.color = draftStatus;
        break;
      case "inreview":
        status.title = inreview;
        status.color = inreviewStatus;
        break;
      case "published":
        status.title = published;
        status.color = publishedStatus;
        break;
      case "revised":
        status.title = revised;
        status.color = publishedStatus;
        break;
    }
    return status;
  }, [knowledge.status]);

  const approvalList = useMemo(() => {
    const { knowledgebase, approvals } = knowledge;
    const members = {};
    const approvalType = knowledgebase?.approval;
    let isApprovedByAnyone = false;
    let isRejectedByAnyone = false;
    for (let i = 0; i < approvals.length; i++) {
      const user = approvals[i].user;
      if (user) {
        const object = {
          id: user._id,
          name: user.name,
          status: approvals[i].status === "approved" ? "Approved" : "Rejected",
          comment: approvals[i].comment,
        };
        if (approvals[i].status === "approved") {
          isApprovedByAnyone = true;
        }
        if (approvals[i].status === "rejected") {
          isRejectedByAnyone = true;
        }
        members[object.id] = object;
      }
    }
    for (let i = 0; i < knowledgebase?.members.length; i++) {
      if (
        !members[knowledgebase?.members[i].user._id] &&
        knowledgebase?.members[i]?.role !== "member"
      ) {
        let tempStatus = "Pending Approval";
        if (approvalType === "anyone") {
          if (isApprovedByAnyone || isRejectedByAnyone) {
            tempStatus = "No Longer Required";
          }
        } else if (approvalType === "all") {
          if (isRejectedByAnyone) {
            tempStatus = "No Longer Required";
          }
        }

        const object = {
          id: knowledgebase?.members?.[i].user._id,
          name: knowledgebase?.members?.[i].user.name,
          status: tempStatus,
        };
        members[object.id] = object;
      }
    }
    const groups = knowledgebase?.contributers;
    for (let i = 0; i < groups?.length; i++) {
      if (groups[i]?.active) {
        if (groups?.[i]?.members?.length > 0) {
          const groupMembers = groups?.[i]?.members;
          if (groupMembers?.length > 0) {
            for (let i = 0; i < groupMembers?.length; i++) {
              if (!members[groupMembers?.[i].user._id] && groupMembers?.[i]?.role !== "member") {
                let tempStatus = "Pending Approval";
                if (approvalType === "anyone") {
                  if (isApprovedByAnyone || isRejectedByAnyone) {
                    tempStatus = "No Longer Required";
                  }
                } else if (approvalType === "all") {
                  if (isRejectedByAnyone) {
                    tempStatus = "No Longer Required";
                  }
                }
                const object = {
                  id: groupMembers?.[i].user._id,
                  name: groupMembers?.[i].user.name,
                  status: tempStatus,
                };
                members[object.id] = object;
              }
            }
          }
        }
      }
    }
    return members;
  }, [knowledge]);

  const currentVersion = useMemo(() => {
    return `Version 1.${knowledge.revisionHistory?.length}`;
  }, [knowledge]);

  const versionHistory = useMemo(() => {
    const history: SelectOptions[] = [];
    if (knowledge?.revisedKnowledge?.serial) {
      const item = knowledge.revisedKnowledge;
      const label = `Version 1.${item.revisionHistory.length} - ${
        config.prefix.knowledge
      } ${getPrefixDigit(item.serial, 5)}`;
      history.push({
        label: label,
        value: item._id,
      });
    }
    if (knowledge.revisionHistory?.length) {
      for (let i = 0; i < knowledge.revisionHistory.length; i++) {
        const item = knowledge.revisionHistory[i];
        if (item?.serial) {
          const label = `Version 1.${item.revisionHistory.length} - ${
            config.prefix.knowledge
          } ${getPrefixDigit(item?.serial, 5)}`;
          history.push({
            label: label,
            value: item._id,
          });
        }
      }
    }
    return history;
  }, [knowledge]);

  const handleOnVersionClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        setVersionPopup(false);
        navigate(`/ep/knowledge/${id}/details`);
      }
    },
    [navigate],
  );

  const knowledgeActions = useMemo(() => {
    let actions: SelectOptions[] = [];
    const { createdBy, groupRole, knowledgebaseRole } = getArticlePermissions(knowledge, userId);
    switch (knowledge.status) {
      case "drafted":
        if (
          actionsPermissions.includes(knowledgebaseRole) ||
          actionsPermissions.includes(groupRole) ||
          createdBy
        ) {
          actions = draftArticleActions;
        }
        break;
      case "published":
        console.log(knowledge);
        if (
          (actionsPermissions.includes(knowledgebaseRole) ||
            actionsPermissions.includes(groupRole) ||
            createdBy) &&
          !knowledge?.revisedKnowledge
        ) {
          actions = publishedArticleActions;
        }
        break;
      case "inreview":
        if (
          actionsPermissions.includes(knowledgebaseRole) ||
          actionsPermissions.includes(groupRole)
        ) {
          actions = inreviewArticlesActions;
        }
        break;
    }
    return actions;
  }, [knowledge, userId]);

  const reloadKnowledge = useCallback(() => {
    const id = knowledge._id;
    const fetchKnowledgeParams = {
      id,
      onSuccess: fetchKnowledgeSuccess,
      onError: fetchKnowledgeError,
    };
    dispatch(fetchKnowledgeDetails(fetchKnowledgeParams));
  }, [knowledge._id, dispatch, fetchKnowledgeSuccess, fetchKnowledgeError]);

  const handleRequestReview = useCallback(() => {
    setIsActionLoading(true);
    setActionsPopup(false);
    setActionsModal("");
    const params = {
      id: knowledge._id,
      onSuccess: (data) => {
        reloadKnowledge();
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article requested for reiew",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
      },
    };
    dispatch(updateReview(params));
  }, [knowledge._id, dispatch, reloadKnowledge, notificationsId]);

  const handleApprove = useCallback(() => {
    if (!comment) {
      setCommentError("Please enter valid comment");
      return;
    }
    setIsActionLoading(true);
    setActionsPopup(false);
    setActionsModal("");
    const params = {
      data: {
        id: knowledge._id,
        status: "approved",
        comment: comment,
      },
      onSuccess: (data) => {
        reloadKnowledge();
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article approved successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
      },
    };
    dispatch(updateApproval(params));
  }, [dispatch, knowledge, comment, reloadKnowledge, notificationsId]);

  const handleReject = useCallback(() => {
    if (!comment) {
      setCommentError("Please enter valid comment");
      return;
    }
    setIsActionLoading(true);
    setActionsPopup(false);
    setActionsModal("");
    const params = {
      data: {
        id: knowledge._id,
        status: "rejected",
        comment: comment,
      },
      onSuccess: (data) => {
        reloadKnowledge();
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article rejected successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
      },
    };
    dispatch(updateApproval(params));
  }, [dispatch, knowledge, comment, reloadKnowledge, notificationsId]);

  const handleRevise = useCallback(() => {
    setIsActionLoading(true);
    setActionsPopup(false);
    setActionsModal("");
    const params = {
      id: knowledge._id,
      onSuccess: (res) => {
        const id = res.data._id;
        setIsActionLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article Revised Successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        navigate(`/ep/knowledge/${id}/edit`);
      },
      onError: (e) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: e,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
      },
    };
    dispatch(reviseKnowledge(params));
  }, [dispatch, knowledge._id, navigate, notificationsId]);

  const handleRetire = useCallback(() => {
    setIsActionLoading(true);
    setActionsPopup(false);
    setActionsModal("");
    const params = {
      id: knowledge._id,
      onSuccess: () => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Article Retired Successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
        navigate(`/ep/knowledge/owner?type=owner`);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setIsActionLoading(false);
      },
    };
    dispatch(retireKnowledge(params));
  }, [dispatch, knowledge._id, navigate, notificationsId]);

  const handleCommentStatus = useCallback((e: React.MouseEvent<HTMLImageElement>) => {
    const comment = e.currentTarget.getAttribute("data-comment") || "";
    setActionsModal("comment");
    setComment(comment);
  }, []);

  const handleActionsDropdown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const item = e.currentTarget.getAttribute("data-item");
    switch (item) {
      case "Request Review":
        setActionsModal("review");
        break;
      case "approved":
        setActionsModal("approve");
        break;
      case "rejected":
        setActionsModal("reject");
        break;
      case "revise":
        setActionsModal("revise");
        break;
      case "retire":
        setActionsModal("retire");
        break;
    }
  }, []);

  const handleComment = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setComment(value);
    setCommentError("");
  }, []);

  return {
    knowledge,
    fetchKnowledgeSuccess,
    headerTitle,
    fetchKnowledgeError,
    loading,
    knowledgeSerial,
    status,
    approvalList,
    versionHistory,
    currentVersion,
    versionPopup,
    handleVersionPopup,
    handleOnVersionClick,
    actionsPopup,
    handleActionsPopup,
    actionsModal,
    handleActionsModal,
    knowledgeActions,
    handleEditArticle,
    handleActionsDropdown,
    handleRequestReview,
    comment,
    handleComment,
    handleApprove,
    handleReject,
    handleRevise,
    handleRetire,
    isActionLoading,
    handleCommentStatus,
    commentError,
    documentId,
    attachmentModal,
    handleAttachmentsModal,
    handleShowAttachment,
    closeAllPopup,
    isRejected,
    error,
  };
};
