import React from "react";

import { AcademyCandidateLeadsList as AcademyCandidateLeadsListType } from "../../redux/AcademyCandidateLeads/types";
import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyCandidateLeadsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    firstName: {
      title: "First Name",
      sortSupported: true,
      searchSupported: true,
      key: "firstName",
    },
    lastName: {
      title: "Last Name",
      sortSupported: true,
      // searchSupported: true,
      key: "lastName",
    },
    status: {
      title: "Status",
      sortSupported: true,

      key: "status",
      render: (item) => {
        return <text className='title'>{item?.status?.value || "-"}</text>;
      },
    },
    associatedContact: {
      title: "Associated Contact",
      sortSupported: true,

      key: "associatedContact",
      render: (item) => {
        return <text className='title'>{item?.associatedContact?.firstName || "-"}</text>;
      },
    },
    course: {
      title: "Course",
      sortSupported: true,

      key: "course",
      render: (item) => {
        return <text className='title'>{item?.course?.name || "-"}</text>;
      },
    },
    source: {
      title: "Source",
      sortSupported: true,

      key: "source",
      render: (item) => {
        return <text className='title'>{item?.source?.value || "-"}</text>;
      },
    },
    admissionCounsellor: {
      title: "Admission Counsellor",
      sortSupported: true,

      key: "admissionCounsellor",
      render: (item) => {
        return <text className='title'>{item?.admissionCounsellor?.name || "-"}</text>;
      },
    },
    graduationYear: {
      title: "Graduation Year",
      sortSupported: true,

      key: "graduationYear",
    },
    dob: {
      title: "DOB",
      sortSupported: true,

      key: "dob",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.dob,
              format: "YYYY-MM-DD",
            })}
          </text>
        );
      },
    },
    // active: {
    //   title: "Active",
    //   sortSupported: true,
    //
    //   key: "active",
    //   center: true,
    //   render: (item: AcademyCandidateLeadsListType) => {
    //     return <text className='title'>{item?.active ? "Yes" : "No"}</text>;
    //   },
    // },
    phone: {
      title: "Phone",
      sortSupported: true,

      key: "phone",
    },
    email: {
      title: "Email",
      sortSupported: true,

      key: "email",
    },
    country: {
      title: "Country",

      key: "country",
      render: (item) => {
        return <text className='title'>{item?.country?.name || "-"}</text>;
      },
    },
    stage: {
      title: "Stage",
      sortSupported: true,

      key: "stage",
      render: (item) => {
        return <text className='title'>{item?.stage?.value || "-"}</text>;
      },
    },
    currency: {
      title: "Currency",
      sortSupported: true,

      key: "currency",
      render: (item) => {
        return <text className='title'>{item?.currency?.name || "-"}</text>;
      },
    },
    city: {
      title: "city",
      sortSupported: true,
      key: "city",
    },
    state: {
      title: "state",
      sortSupported: true,
      key: "state",
    },
    graduationDegree: {
      title: "Graduation Degree",
      sortSupported: true,
      key: "graduationDegree",
      render: (item) => {
        return <text className='title'>{item?.graduationDegree?.name || "-"}</text>;
      },
    },
    graduationMajor: {
      title: "Graduation Major",
      sortSupported: true,
      key: "graduationMajor",
      render: (item) => {
        return <text className='title'>{item?.graduationMajor?.value || "-"}</text>;
      },
    },
    linkedInURL: {
      title: "LinkedIn URL",
      sortSupported: true,
      key: "linkedInURL",
    },
    sourceContactDate: {
      title: "Source Contact Date",
      sortSupported: true,
      key: "sourceContactDate",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.sourceContactDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    saleDate: {
      title: "Sale Date",
      sortSupported: true,
      key: "saleDate",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.saleDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    enrollmentSpecialists: {
      title: "Enrollment Specialists",
      sortSupported: true,
      key: "enrollmentSpecialists",
      render: (item) => {
        return <text className='title'>{item?.enrollmentSpecialists?.name || "-"}</text>;
      },
    },
    paymentTerms: {
      title: "Payment Terms",
      sortSupported: true,
      key: "paymentTerms",
      render: (item) => {
        return <text className='title'>{item?.paymentTerms?.value || "-"}</text>;
      },
    },
    totalFees: {
      title: "Total Fees",
      sortSupported: true,
      key: "totalFees",
    },
    anyDiscountsGiven: {
      title: "Any Discounts Given",
      sortSupported: true,
      key: "anyDiscountsGiven",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
