import React from "react";

import { Layout } from "../../../presentationals";
import { CampaignsAssetsList } from "../../../presentationals/screens/admin/CampaignsAssets/CampaignsAssetsList";

export const Route = () => {
  return <Layout activity={<CampaignsAssetsList />} admin={true} layout={"2"} />;
};

export const APCampaignsAssetsList = React.memo(Route);
