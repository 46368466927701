import Types from "./actionType";
import {
  FetchAcademyACHPayload,
  CreateAcademyACH,
  CreateAcademyACHPayload,
  AcademyACHList,
  FetchAcademyACH,
  FetchAcademyACHError,
  FetchAcademyACHSuccess,
  UpdateAcademyACH,
  UpdateAcademyACHPayload,
  AcademyACHListWithMetaData,
  FetchAcademyACHByIdPayload,
  FetchAcademyACHById,
  DeleteAcademyACHPayload,
  DeleteAcademyACH,
} from "./types";

export const fetchAcademyACHList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyACHPayload): FetchAcademyACH => ({
  type: Types.FETCH_ACADEMY_ACH,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyACHById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyACHByIdPayload): FetchAcademyACHById => ({
  type: Types.FETCH_ACADEMY_ACH_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyACHListSuccess = (
  data: AcademyACHListWithMetaData,
): FetchAcademyACHSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_ACH,
  payload: {
    data,
  },
});

export const fetchAcademyACHError = (error?: string): FetchAcademyACHError => ({
  type: Types.ERROR_FETCH_ACADEMY_ACH,
  payload: { error },
});

export const createAcademyACH = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyACHPayload): CreateAcademyACH => ({
  type: Types.CREATE_ACADEMY_ACH,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyACH = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyACHPayload): UpdateAcademyACH => ({
  type: Types.UPDATE_ACADEMY_ACH,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAcademyACH = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyACHPayload): DeleteAcademyACH => ({
  type: Types.DELETE_ACADEMY_ACH,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
