import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { KanbanConfigListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useKanbanConfigList } from "./hooks";

export const KanbanConfigList = ({ relatedList }: KanbanConfigListProps) => {
  const kanbanConfig = useAppSelector((state) => state.KanbanConfigReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { list, metaData } = kanbanConfig;
  const {
    fetchKanbanConfig,
    loading,
    handlePageChange,
    onClickKanbanConfig,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    kanbanConfigHeaderButtons,
    kanbanConfigFilterFields,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
    columns,
    kanbanConfigFields,
  } = useKanbanConfigList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchKanbanConfig({});
    // Do not include fetchKanbanConfig to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportAllRecords={exportAllRecords}
      exportSelectedRecords={exportSelectedRecords}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.kanbanConfig_delete}
      handleShowItemsPerPage={handleShowItemsPerPage}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleListActions={handleListActions}
      headerButtons={kanbanConfigHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.kanbanConfig}
      handleTableConfigs={handleTableConfigs}
      filters={kanbanConfigFilterFields}
      selectedConfigFields={kanbanConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickKanbanConfig}
      headerTitle={strings.headerTitle.kanbanConfig}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apKanbanConfig.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
