import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { ResourcesSection, ResourcesStats } from "../../../../redux/Resources/types";
import { getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { ColumnsType, TableHeaderButton } from "../../../../types";
import { ResourcesControllerProps } from "../../../../types/models/Resources";
import {
  ActiveSelectOptions,
  PositionOptions,
  collections,
  resourcesTypeSelectOptions,
  resourcesVerticalSelectOptions,
  sectionAttachmentSelectOptions,
} from "../../../../utils";
import { AttachmentIcon, ControllerMessage, FormTitle, Row } from "../../../common/styles";
import { ActivityLogs, Popup, TableController, TableListV2, WorkLogs } from "../../../components";
import {
  TextInput,
  Select,
  SystemData,
  MyTooltip,
  Button,
  TextEditor,
} from "../../../components/atoms";
import AttachmentInput from "../../../components/atoms/AttachmentInput/AttachmentInput";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useResourcesController } from "./hooks";
import { SectionModalContainer } from "./style";

const Controller = ({ type }: ResourcesControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchResources,
    onPostWorklog,
    onDateChange,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    onSaveAttachment,
    deleteAttachment,
    // Section
    addResourcesSection,
    updateResourcesSection,
    sectionHeaderButtons,
    onSectionTextChange,
    handleSectionsModal,
    sectionsData,
    sectionsModal,
    deleteServiceNowSection,
    // Stats Function
    addResourcesStats,
    updateResourcesStats,
    statsHeaderButtons,
    onStatsTextChange,
    handleStatsModal,
    statsData,
    statsModal,
    deleteServiceNowStats,
    caseStudyDescription,
    onCaseStudyDescriptionChange,
    onBlogSectionDescriptionChange,
    description,
    onWhitePaperDescriptionChange,
    whitepaperdescription,
    descriptionValidation,
  } = useResourcesController({ type, id, attachmentsPickerRef });
  const currentResources = data;

  const {
    workLogs,
    attachments,
    name,
    shortDescription,
    active,
    state,
    tags,
    downloadAvailable,
    downloadLabel,
    publishedDate,
    whitepaperTitle,
    publisherName,
    readTime,
    blogAttachment,
    sections,
    clientLogo,
    caseStudyTagline,
    caseStudyBanner,
    statsIncluded,
    stats,
    caseStudyAttachment,
    caseStudyClientDetails,
    clientName,
    clientHeadquater,
    clientIndustry,
    clientProductType,
    clientEmployees,
    listImage,
    coverImage,
    whitepaperAttachment,
    whitepaperImage,
    publisherImage,
  } = formData;
  const { sectionAttachment, sectionImageLink, sectionVideoLink, imageVideoPosition, quote } =
    sectionsData;
  const { title, tagline } = statsData;
  useEffect(() => {
    if (type === "edit") {
      fetchResources();
    }
    // Do not include fetchResources to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <ControllerMessage>
          You can add multiple tags by separating them using comas.
        </ControllerMessage>
        <TextInput
          label='Tags'
          width='100%'
          type={"textarea"}
          error={tags.error}
          textAreaProps={{
            name: "tags",
            rows: 4,
            value: tags.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: tags.required,
          }}
        />
        <ControllerMessage>
          If you modify the type, the corresponding field of that type will be removed.
        </ControllerMessage>
        <Row>
          <Select
            name={"type"}
            label={"Type"}
            options={resourcesTypeSelectOptions}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
          <Select
            name={"vertical"}
            label={"Vertical"}
            options={resourcesVerticalSelectOptions}
            value={formData.vertical.value}
            onChange={onTextChange}
            mandatory={formData.vertical.required}
            error={formData.vertical.error}
          />
        </Row>
        <Row>
          <Select
            name={"state"}
            label={"State"}
            options={getAttributValues("resourcesState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
          {/* List Image */}
          <AttachmentInput
            aclType={"public"}
            attachment={listImage}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='listImage'
            label='List Image'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />
        </Row>
        <Row>
          <Select
            name={"downloadAvailable"}
            label={"Download Available"}
            options={ActiveSelectOptions}
            value={downloadAvailable.value}
            onChange={onTextChange}
            mandatory={downloadAvailable.required}
            error={downloadAvailable.error}
          />
          <TextInput
            label='Published Date'
            type={"date"}
            width='48%'
            error={publishedDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "publishedDate",
              value: publishedDate.value,
              required: publishedDate.required,
            }}
          />
        </Row>
        <Row>
          {downloadAvailable.value && (
            <TextInput
              label='Download Label Name'
              type={"text"}
              width='48%'
              error={downloadLabel.error}
              inputProps={{
                name: "downloadLabel",
                value: downloadLabel.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: downloadLabel.required,
              }}
            />
          )}
          {(formData.type.value === "whitepaper" || formData.type.value === "blogs") && (
            <AttachmentInput
              aclType={"public"}
              attachment={coverImage}
              width='48%'
              handleDeleteAttachment={deleteAttachment}
              fieldName='coverImage'
              label='Cover Image'
              onSave={onSaveAttachment}
              accept='.png,.jpg.jpeg'
            />
          )}
        </Row>
        <Row>
          <AttachmentInput
            aclType={"public"}
            attachment={publisherImage}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='publisherImage'
            label='Publisher Image'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />
        </Row>
        <TextInput
          label='Short Description'
          type={"textarea"}
          width='100%'
          error={shortDescription.error}
          textAreaProps={{
            name: "shortDescription",
            value: shortDescription.value,
            rows: 8,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescription.required,
          }}
        />
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    coverImage,
    deleteAttachment,
    downloadAvailable.error,
    downloadAvailable.required,
    downloadAvailable.value,
    downloadLabel.error,
    downloadLabel.required,
    downloadLabel.value,
    formData.type.error,
    formData.type.required,
    formData.type.value,
    formData.vertical.error,
    formData.vertical.required,
    formData.vertical.value,
    listImage,
    name.error,
    name.required,
    name.value,
    onDateChange,
    onSaveAttachment,
    onTextChange,
    publishedDate.error,
    publishedDate.required,
    publishedDate.value,
    publisherImage,
    shortDescription.error,
    shortDescription.required,
    shortDescription.value,
    state.error,
    state.required,
    state.value,
    tags.error,
    tags.required,
    tags.value,
  ]);
  // White Paper Section
  const renderWhitePaper = useMemo(() => {
    return (
      <>
        <React.Fragment>
          <ControllerMessage>
            Whitepaper Attachment will be sent to the respective Email
          </ControllerMessage>
          <Row>
            <TextInput
              label='Whitepaper Title'
              type={"text"}
              width='48%'
              error={whitepaperTitle.error}
              inputProps={{
                name: "whitepaperTitle",
                value: whitepaperTitle.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: whitepaperTitle.required,
              }}
            />
            {/* White Paper Attachment */}
            <AttachmentInput
              aclType={"public"}
              attachment={whitepaperAttachment}
              width='48%'
              handleDeleteAttachment={deleteAttachment}
              fieldName='whitepaperAttachment'
              label='White Paper Attachment'
              onSave={onSaveAttachment}
              accept='.pdf'
            />
          </Row>
          <Row>
            <AttachmentInput
              aclType={"public"}
              attachment={whitepaperImage}
              width='48%'
              handleDeleteAttachment={deleteAttachment}
              fieldName='whitepaperImage'
              label='White Paper Image'
              onSave={onSaveAttachment}
              accept='.png,.jpg.jpeg'
            />
            <Select
              width='48%'
              name={"imageVideoPosition"}
              label={"Video Position"}
              options={PositionOptions}
              value={formData.imageVideoPosition?.value}
              onChange={onTextChange}
              mandatory={formData.imageVideoPosition?.required}
              error={formData.imageVideoPosition?.error}
            />
          </Row>
          <TextEditor
            error={""}
            value={whitepaperdescription}
            onChange={onWhitePaperDescriptionChange}
            placeholder={"Description"}
          />
        </React.Fragment>
      </>
    );
  }, [
    deleteAttachment,
    formData.imageVideoPosition?.error,
    formData.imageVideoPosition?.required,
    formData.imageVideoPosition?.value,
    onSaveAttachment,
    onTextChange,
    onWhitePaperDescriptionChange,
    whitepaperAttachment,
    whitepaperImage,
    whitepaperTitle.error,
    whitepaperTitle.required,
    whitepaperTitle.value,
    whitepaperdescription,
  ]);
  // Case
  const renderCaseStep = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          {/* Client Logo */}
          <AttachmentInput
            aclType={"public"}
            attachment={clientLogo}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='clientLogo'
            label='Client Logo'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />
          <AttachmentInput
            aclType={"public"}
            attachment={caseStudyBanner}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='caseStudyBanner'
            label='Case Study Banner'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />
        </Row>
        <TextInput
          label='Case Study Tagline'
          type={"textarea"}
          width='100%'
          error={caseStudyTagline.error}
          textAreaProps={{
            name: "caseStudyTagline",
            rows: 4,
            value: caseStudyTagline.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: caseStudyTagline.required,
          }}
        />
        {/* Banner Image */}
        <ControllerMessage>
          Case Study Attachment will be sent to the respective Email
        </ControllerMessage>
        <Row>
          <Select
            name={"statsIncluded"}
            label={"Stats Included"}
            options={ActiveSelectOptions}
            value={statsIncluded.value}
            onChange={onTextChange}
            mandatory={statsIncluded.required}
            error={statsIncluded.error}
          />
          {/* Case Study Attachment */}
          <AttachmentInput
            aclType={"public"}
            attachment={caseStudyAttachment}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='caseStudyAttachment'
            label='Case Study Attachment'
            onSave={onSaveAttachment}
            accept='.pdf'
          />
        </Row>
        <Row>
          <Select
            name={"caseStudyClientDetails"}
            label={"Case Study Client Details"}
            options={ActiveSelectOptions}
            value={caseStudyClientDetails.value}
            onChange={onTextChange}
            mandatory={caseStudyClientDetails.required}
            error={caseStudyClientDetails.error}
          />
          <TextInput
            label='Client Name'
            type={"text"}
            width='48%'
            error={clientName.error}
            inputProps={{
              name: "clientName",
              value: clientName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: clientName.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Client Headquater'
            type={"text"}
            width='48%'
            error={clientHeadquater.error}
            inputProps={{
              name: "clientHeadquater",
              value: clientHeadquater.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: clientHeadquater.required,
            }}
          />
          <TextInput
            label='Client Industry'
            type={"text"}
            width='48%'
            error={clientIndustry.error}
            inputProps={{
              name: "clientIndustry",
              value: clientIndustry.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: clientIndustry.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Client Product Type'
            type={"text"}
            width='48%'
            error={clientProductType.error}
            inputProps={{
              name: "clientProductType",
              value: clientProductType.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: clientProductType.required,
            }}
          />
          <TextInput
            label='Client Employees'
            type={"text"}
            width='48%'
            error={clientEmployees.error}
            inputProps={{
              name: "clientEmployees",
              value: clientEmployees.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: clientEmployees.required,
            }}
          />
        </Row>
        <TextEditor
          error={""}
          value={caseStudyDescription}
          onChange={onCaseStudyDescriptionChange}
          placeholder={"Description"}
        />
      </React.Fragment>
    );
  }, [
    caseStudyAttachment,
    caseStudyBanner,
    caseStudyClientDetails.error,
    caseStudyClientDetails.required,
    caseStudyClientDetails.value,
    caseStudyDescription,
    caseStudyTagline.error,
    caseStudyTagline.required,
    caseStudyTagline.value,
    clientEmployees.error,
    clientEmployees.required,
    clientEmployees.value,
    clientHeadquater.error,
    clientHeadquater.required,
    clientHeadquater.value,
    clientIndustry.error,
    clientIndustry.required,
    clientIndustry.value,
    clientLogo,
    clientName.error,
    clientName.required,
    clientName.value,
    clientProductType.error,
    clientProductType.required,
    clientProductType.value,
    deleteAttachment,
    onCaseStudyDescriptionChange,
    onSaveAttachment,
    onTextChange,
    statsIncluded.error,
    statsIncluded.required,
    statsIncluded.value,
  ]);
  // Blog
  const renderBlogsStep = useMemo(() => {
    return (
      <React.Fragment>
        <ControllerMessage>Blog Attachment will be sent to the respective Email</ControllerMessage>
        <Row>
          <TextInput
            label='Publisher Name'
            type={"text"}
            width='48%'
            error={publisherName.error}
            inputProps={{
              name: "publisherName",
              value: publisherName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: publisherName.required,
            }}
          />
          {/* Blog Attachment */}
          <AttachmentInput
            aclType={"public"}
            attachment={blogAttachment}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='blogAttachment'
            label='Blog Attachment'
            onSave={onSaveAttachment}
            accept='.pdf'
          />
        </Row>
        <Row>
          <TextInput
            label='Read Time'
            type={"text"}
            width='48%'
            error={readTime.error}
            inputProps={{
              name: "readTime",
              value: readTime.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: readTime.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    blogAttachment,
    deleteAttachment,
    onSaveAttachment,
    onTextChange,
    publisherName.error,
    publisherName.required,
    publisherName.value,
    readTime.error,
    readTime.required,
    readTime.value,
  ]);
  // Sections Data
  const renderSections = useMemo(() => {
    const columns: ColumnsType[] = [
      {
        title: "Section Attachment Available",
        key: "sectionAttachment",
        width: "500px",
        render: (item) => {
          return (
            <text className='title'>
              {item.sectionAttachment === "sectionImageLink"
                ? "Section Content"
                : item.sectionAttachment === "sectionVideoLink"
                ? "Youtube Video"
                : item.sectionAttachment === "quote"
                ? "Quote"
                : "-"}
            </text>
          );
        },
      },
      {
        title: "Actions",
        width: "100px",
        center: true,
        render: (item: ResourcesSection) => {
          return (
            <MyTooltip text='Delete'>
              <AttachmentIcon
                onClick={deleteServiceNowSection}
                data-index={item?._id}
                src={images.deleteAttachment}
              />
            </MyTooltip>
          );
        },
      },
    ];

    const buttons: TableHeaderButton[] = [
      {
        title: "Add",
        onClick: () => handleSectionsModal("add"),
        visible: true,
      },
    ];
    return (
      <>
        <TableListV2
          columns={columns}
          advancedList={false}
          headerButtons={buttons}
          loading={false}
          list={sections?.value}
          onClickTableRow={handleSectionsModal}
        />
        <Popup isOpen={!!sectionsModal} closeModal={handleSectionsModal}>
          <SectionModalContainer className='section-modal'>
            <FormTitle style={{ alignSelf: "left" }}>
              {sectionsModal === "add" ? "Add" : sectionsModal === "edit" ? "Edit" : ""}
            </FormTitle>

            <Row>
              <Select
                name={"sectionAttachment"}
                label={"Section Attachment Available"}
                options={sectionAttachmentSelectOptions}
                value={sectionAttachment.value}
                onChange={onSectionTextChange}
                mandatory={sectionAttachment.required}
                error={sectionAttachment.error}
              />
              {sectionAttachment.value === "sectionImageLink" && (
                <AttachmentInput
                  aclType={"public"}
                  attachment={sectionImageLink}
                  width='48%'
                  required={sectionImageLink.required}
                  error={sectionImageLink.error}
                  handleDeleteAttachment={deleteAttachment}
                  fieldName='sectionImageLink'
                  label='Section Image'
                  onSave={onSaveAttachment}
                  accept='.png,.jpg.jpeg'
                />
              )}
              {sectionAttachment.value === "sectionVideoLink" && (
                <TextInput
                  label='Section Video Link'
                  type={"text"}
                  width='48%'
                  error={sectionVideoLink?.error}
                  inputProps={{
                    name: "sectionVideoLink",
                    value: sectionVideoLink?.value,
                    autoComplete: "off",
                    onChange: onSectionTextChange,
                    required: sectionVideoLink?.required,
                  }}
                />
              )}
            </Row>
            {sectionAttachment.value === "sectionVideoLink" ||
              (sectionAttachment.value === "sectionImageLink" && (
                <Row>
                  <Select
                    width='48%'
                    name={"imageVideoPosition"}
                    label={"Video Position"}
                    options={PositionOptions}
                    value={imageVideoPosition?.value}
                    onChange={onSectionTextChange}
                    mandatory={imageVideoPosition?.required}
                    error={imageVideoPosition?.error}
                  />
                </Row>
              ))}
            <Row>
              {sectionAttachment.value === "quote" ? (
                <TextInput
                  label='Quote'
                  type={"textarea"}
                  width='100%'
                  error={quote.error}
                  textAreaProps={{
                    name: "quote",
                    value: quote.value,
                    rows: 8,
                    autoComplete: "off",
                    onChange: onSectionTextChange,
                    required: quote.required,
                  }}
                />
              ) : (
                <TextEditor
                  width='100%'
                  error={descriptionValidation ? "Required Field" : ""}
                  value={description}
                  onChange={onBlogSectionDescriptionChange}
                  placeholder={"Description"}
                />
              )}
            </Row>
            <div className='centerStyle'>
              <Button
                className='learning-point-modal-save-button'
                title={sectionsModal === "add" ? strings.common.add : strings.common.save}
                onClick={sectionsModal === "add" ? addResourcesSection : updateResourcesSection}
              />
            </div>
          </SectionModalContainer>
        </Popup>
      </>
    );
  }, [
    addResourcesSection,
    deleteAttachment,
    deleteServiceNowSection,
    description,
    descriptionValidation,
    handleSectionsModal,
    imageVideoPosition?.error,
    imageVideoPosition?.required,
    imageVideoPosition?.value,
    onBlogSectionDescriptionChange,
    onSaveAttachment,
    onSectionTextChange,
    quote.error,
    quote.required,
    quote.value,
    sectionAttachment.error,
    sectionAttachment.required,
    sectionAttachment.value,
    sectionImageLink,
    sectionVideoLink?.error,
    sectionVideoLink?.required,
    sectionVideoLink?.value,
    sections?.value,
    sectionsModal,
    updateResourcesSection,
  ]);

  // Stats Data
  const renderStats = useMemo(() => {
    const columns: ColumnsType[] = [
      {
        title: "Title",
        key: "title",
        width: "200px",
        render: (item) => {
          return <text className='title'>{item.title || "-"}</text>;
        },
      },
      {
        title: "Tag Line",
        key: "tagline",
        width: "200px",
        render: (item) => {
          return <text className='title'>{item.tagline || "-"}</text>;
        },
      },
      {
        title: "Actions",
        width: "100px",
        center: true,
        render: (item: ResourcesStats) => {
          return (
            <MyTooltip text='Delete'>
              <AttachmentIcon
                onClick={deleteServiceNowStats}
                data-index={item?._id}
                src={images.deleteAttachment}
              />
            </MyTooltip>
          );
        },
      },
    ];

    const buttons: TableHeaderButton[] = [
      {
        title: "Add",
        onClick: () => handleStatsModal("add"),
        visible: true,
      },
    ];
    return (
      <>
        <TableListV2
          columns={columns}
          advancedList={false}
          headerButtons={buttons}
          loading={false}
          list={stats?.value}
          onClickTableRow={handleStatsModal}
        />
        <Popup isOpen={!!statsModal} closeModal={handleStatsModal}>
          <SectionModalContainer className='section-modal'>
            <FormTitle style={{ alignSelf: "left" }}>
              {statsModal === "add" ? "Add" : statsModal === "edit" ? "Edit" : ""}
            </FormTitle>
            {/* Case Studies */}

            <Row>
              <TextInput
                label='Title'
                type={"text"}
                width='48%'
                error={title?.error}
                inputProps={{
                  name: "title",
                  value: title?.value,
                  autoComplete: "off",
                  onChange: onStatsTextChange,
                  required: title?.required,
                }}
              />
              <TextInput
                label='Tag Line'
                type={"text"}
                width='48%'
                error={tagline?.error}
                inputProps={{
                  name: "tagline",
                  value: tagline?.value,
                  autoComplete: "off",
                  onChange: onStatsTextChange,
                  required: tagline?.required,
                }}
              />
            </Row>
            <div className='centerStyle'>
              <Button
                className='learning-point-modal-save-button'
                title={statsModal === "add" ? strings.common.add : strings.common.save}
                onClick={statsModal === "add" ? addResourcesStats : updateResourcesStats}
              />
            </div>
          </SectionModalContainer>
        </Popup>
      </>
    );
  }, [
    addResourcesStats,
    deleteServiceNowStats,
    handleStatsModal,
    onStatsTextChange,
    stats?.value,
    statsModal,
    tagline?.error,
    tagline?.required,
    tagline?.value,
    title?.error,
    title?.required,
    title?.value,
    updateResourcesStats,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentResources?.createdBy !== "string" ? currentResources?.createdBy?.name : "";
    const updatedBy =
      typeof currentResources?.updatedBy !== "string" ? currentResources?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentResources?.createdAt}
        updatedAt={currentResources?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentResources?.createdAt,
    currentResources?.createdBy,
    currentResources?.updatedAt,
    currentResources?.updatedBy,
  ]);

  // CheckList Data
  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.resources.name} recordId={id} />,
    [id],
  );

  // Activity Logs
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.resources.name} />,
    [id],
  );
  // WorkLogs
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        if (formData.type.value === "blogs") {
          return renderBlogsStep;
        } else if (formData.type.value === "whitepaper") {
          return renderWhitePaper;
        } else if (formData.type.value === "caseStudies") {
          return renderCaseStep;
        }
        break;
      case 2:
        if (formData.type.value === "blogs" && type === "edit") {
          return renderSections;
        } else if (formData.type.value === "caseStudies" && type === "edit") {
          return renderStats;
        } else {
          return renderChecklist;
        }
      case formData.type.value === "blogs" ||
      formData.type.value === "caseStudies" ||
      formData.type.value === "whitepaper"
        ? 3
        : 2:
        return formData.type.value === "blogs" || formData.type.value === "caseStudies"
          ? renderChecklist
          : renderActivitylogs;
      case formData.type.value === "blogs" ||
      formData.type.value === "caseStudies" ||
      formData.type.value === "whitepaper"
        ? 4
        : 3:
        return formData.type.value === "blogs" || formData.type.value === "caseStudies"
          ? renderActivitylogs
          : renderWorklogs;
      case formData.type.value === "blogs" ||
      formData.type.value === "caseStudies" ||
      formData.type.value === "whitepaper"
        ? 5
        : 4:
        return formData.type.value === "blogs" || formData.type.value === "caseStudies"
          ? renderWorklogs
          : renderSystemData;
      case formData.type.value === "blogs" ||
      formData.type.value === "caseStudies" ||
      formData.type.value === "whitepaper"
        ? 6
        : 5:
        return formData.type.value === "blogs" || formData.type.value === "caseStudies"
          ? renderSystemData
          : null;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    formData.type.value,
    type,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
    renderBlogsStep,
    renderWhitePaper,
    renderCaseStep,
    renderSections,
    renderStats,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ResourcesController = memo(Controller);
