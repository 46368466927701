import React from "react";

import { Layout } from "../../../presentationals";
import { InReviewKnowledgeArticles } from "../../../presentationals/screens";
import { strings } from "../../../theme";

export const EPInReviewKnowledgeArticles = () => {
  return (
    <Layout
      activity={<InReviewKnowledgeArticles />}
      title={strings.headerTitle.knowledge}
      employee={true}
      layout={"2"}
      noPadding={true}
    />
  );
};
