import axios from "axios";

const AxiosInstance: any = axios.create({});
let expired = false;
// AxiosInstance.interceptors.request.use(async function (config) {});

AxiosInstance.interceptors.response.use(
  function (response) {
    if (expired) {
      throw new Error("Session Expired");
    }
    return response;
  },
  function (error) {
    if (!error.response.config.url.includes("/login")) {
      if (error.response.status === 401 && !expired) {
        expired = true;
        alert("Your session has expired, please login again");
        window.localStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default AxiosInstance;
