import React from "react";

import { Layout } from "../../../presentationals";
import { TicketsListing } from "../../../ScreensEmployees";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<TicketsListing assigned={false} />}
      title={strings.headerTitle.tickets}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
