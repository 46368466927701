import Types from "./actionType";
import {
  FetchJobsPostingPayload,
  CreateJobsPosting,
  CreateJobsPostingPayload,
  FetchJobsPosting,
  FetchJobsPostingError,
  FetchJobsPostingSuccess,
  UpdateJobsPosting,
  UpdateJobsPostingPayload,
  JobsPostingListWithMetaData,
  FetchJobsPostingByIdPayload,
  FetchJobsPostingById,
  DeleteJobsPostingPayload,
  DeleteJobsPosting,
} from "./types";

export const fetchJobsPostingList = ({
  params,
  onSuccess,
  onError,
}: FetchJobsPostingPayload): FetchJobsPosting => ({
  type: Types.FETCH_JOBS_POSTING,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchJobsPostingById = ({
  id,
  onSuccess,
  onError,
}: FetchJobsPostingByIdPayload): FetchJobsPostingById => ({
  type: Types.FETCH_JOBS_POSTING_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchJobsPostingListSuccess = (
  data: JobsPostingListWithMetaData,
): FetchJobsPostingSuccess => ({
  type: Types.SUCCESS_FETCH_JOBS_POSTING,
  payload: {
    data,
  },
});

export const fetchJobsPostingError = (error?: string): FetchJobsPostingError => ({
  type: Types.ERROR_FETCH_JOBS_POSTING,
  payload: { error },
});

export const createJobsPosting = ({
  data,
  onSuccess,
  onError,
}: CreateJobsPostingPayload): CreateJobsPosting => ({
  type: Types.CREATE_JOBS_POSTING,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateJobsPosting = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateJobsPostingPayload): UpdateJobsPosting => ({
  type: Types.UPDATE_JOBS_POSTING,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteJobsPosting = ({
  id,
  onSuccess,
  onError,
}: DeleteJobsPostingPayload): DeleteJobsPosting => ({
  type: Types.DELETE_JOBS_POSTING,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
