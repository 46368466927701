/* eslint-disable no-unused-vars */
enum Types {
  DRIVE_FETCH = "DRIVE_FETCH",
  DRIVE_FETCH_SUCCESS = "DRIVE_FETCH_SUCCESS",
  DRIVE_UPLOAD = "DRIVE_UPLOAD",
  DRIVE_UPLOAD_S3 = "DRIVE_UPLOAD_S3",
  DRIVE_OPERATIONS = "DRIVE_OPERATIONS",
}

export default Types;
