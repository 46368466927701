import React, { useCallback, useEffect, useMemo, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { Search, KnowledgeCard, DashboardTable, Header, Button } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { fetchAllKnowledge, setKnowledgeFilter } from "../../../../../redux/knowledge/actions";
import { KnowledgeArticlesList, KnowledgeFilters } from "../../../../../redux/knowledge/types";
import { useTitle, timeSince, MomentDate } from "../../../../../services/methods";
import { strings, colors, images } from "../../../../../theme";
import { KnowledgeStatus, metaDataTypes } from "../../../../../types";
import { Popup } from "../../../organisms";
import Pagination from "../../../Pagination";
import { useKnowledgeFilters } from "../hooks/useKnowledgeFilters";
import "react-loading-skeleton/dist/skeleton.css";

import {
  Container,
  KnowledgeArticlesContainer,
  KnowledgeArticlesHeader,
  KnowledgeBanner,
  KnowledgeIcon,
  ListContainer,
  ListItem,
  MetaContainer,
  MetaIcon,
  MetaRow,
  MetaSeperator,
  MetaTitle,
  Name,
  ResultsCount,
  Status,
  Summary,
  Tag,
  TagsContainer,
  Title,
  SearchContainer,
  FiltersContainer,
  FiltersHeader,
  FilterItemRow,
  FilterItemTitle,
  Icon,
  ExpandedFilterContainer,
  FilterChildContainer,
  Checkbox,
  FilterChildTitle,
  ModelContainer,
  ModelTitle,
  ModelFilterContainer,
  NoRecords,
  AppliedFiltersContainer,
  AppliedFiltersTitle,
  ClearAllTitle,
  FilterItem,
  FiltersListContainer,
  CrossIcon,
  CategoriesGridContainer,
  CategoryGrid,
  CategoryGridImage,
  CategoryGridTitle,
  ViewAll,
} from "./styles";

const advanceSearch = [
  { title: "Knowledge Base", key: "knowledgebases" },
  { title: "Categories", key: "categories" },
  { title: "Subcategories", key: "subcategories" },
  { title: "Tags", key: "tags" },
];

interface KnowledgeListTemplateProps {
  headerTitle: string | string[];
  bannerTitle: string;
  data: KnowledgeArticlesList[];
  metaData: metaDataTypes;
  loading: boolean;
  handlePageChange: (page: number) => void;
  getStatus: (article: KnowledgeArticlesList) => KnowledgeStatus;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  handleClearSearch: () => void;
  handleReload: () => void;
  filters: KnowledgeFilters;
  preAppliedKnowledgebase?: string;
  categoryGrid?: boolean;
  type: "all" | "my" | "inreview" | "published";
}

export const KnowledgeListTemplate = ({
  headerTitle,
  bannerTitle,
  data,
  metaData,
  loading,
  handlePageChange,
  getStatus,
  onChangeSearch,
  searchValue,
  handleClearSearch,
  handleReload,
  filters,
  preAppliedKnowledgebase,
  categoryGrid,
  type,
}: KnowledgeListTemplateProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    searchFilter,
    clearSearch,
    current,
    onSearch,
    onKeyDown,
    handleAccordion,
    onChangeFilterSearch,
    clearFilterSearch,
    filterModel,
    closeFilterModel,
    openFilterModel,
    filterModelKey,
    selectedKnowledgebase,
    selectedCategories,
    selectedSubcategories,
    selectedTags,
    handleKnowledgebaseFilter,
    handleModelKnowledgebaseFilter,
    applyModelFilters,
    onPageChange,
    handleArticleClick,
    isFiltersApplied,
    handleClearAllKnowledgeFilters,
    viewAll,
    handleViewAll,
    showCategoryGrid,
    handleShowCategoryGrid,
    isRejected,
  } = useKnowledgeFilters({
    handlePageChange,
    handleClearSearch,
    handleReload,
    data,
    type,
  });

  useEffect(() => {
    if (categoryGrid) {
      handleShowCategoryGrid();
    }
  }, [categoryGrid, handleShowCategoryGrid]);

  const currentFilters = useAppSelector((state) => state.KnowledgeReducer.currentFilters);

  return (
    <Container>
      <Header title={headerTitle} />
      <KnowledgeBanner onClick={() => console.log(currentFilters)}>
        <Title>{bannerTitle}</Title>
        <SearchContainer>
          <Search
            containerClass={"ep-knowledge-search-container"}
            inputClass='ep-knowledge-input'
            inputProps={{
              placeholder: strings.epKnowledge.searchTopics,
              onChange: onChangeSearch,
              value: searchValue,
              onKeyDown,
            }}
            clearSearch={clearSearch}
          />
          <Button title={strings.common.search} onClick={onSearch} />
        </SearchContainer>
      </KnowledgeBanner>
      {!loading && showCategoryGrid && (
        <CategoriesGridContainer>
          {filters.categories.map((category, categoryIndex) => {
            if (categoryIndex < 8 || viewAll)
              return (
                <CategoryGrid
                  key={categoryIndex}
                  active={currentFilters.categories.includes(category.id)}
                  onClick={handleKnowledgebaseFilter}
                  data-id={category.id}
                  data-type='categories'
                >
                  <CategoryGridImage src={images.KnowledgeCategoryIcon} />
                  <CategoryGridTitle>{category.title}</CategoryGridTitle>
                </CategoryGrid>
              );
          })}
        </CategoriesGridContainer>
      )}
      {!loading && showCategoryGrid && filters.categories.length > 8 && (
        <ViewAll onClick={handleViewAll}>
          {viewAll ? strings.common.collapse : strings.common.viewAll}
        </ViewAll>
      )}
      <KnowledgeArticlesContainer>
        {loading ? (
          <Skeleton className='skeleton' count={10} />
        ) : (
          <ListContainer>
            <KnowledgeArticlesHeader>
              <ResultsCount>{`${metaData.totalItems} ${strings.epKnowledge.results}`}</ResultsCount>
            </KnowledgeArticlesHeader>
            {isFiltersApplied && (
              <AppliedFiltersContainer>
                <AppliedFiltersTitle>{strings.epKnowledge.appliedFilters}</AppliedFiltersTitle>
                <FiltersListContainer>
                  {filters?.knowledgebases?.map((knowledgebase, knowledgebaseIndex) => {
                    if (currentFilters.knowledgebase === knowledgebase.id) {
                      return (
                        <FilterItem key={knowledgebaseIndex}>
                          {knowledgebase.title}
                          <CrossIcon
                            onClick={handleKnowledgebaseFilter}
                            data-id={knowledgebase.id}
                            data-type='knowledgebases'
                            src={images.crossFilterIcon}
                          />
                        </FilterItem>
                      );
                    }
                  })}
                  {filters?.categories?.map((category, categoryIndex) => {
                    if (currentFilters.categories.includes(category.id)) {
                      return (
                        <FilterItem key={categoryIndex}>
                          {category.title}
                          <CrossIcon
                            onClick={handleKnowledgebaseFilter}
                            data-id={category.id}
                            data-type='categories'
                            src={images.crossFilterIcon}
                          />
                        </FilterItem>
                      );
                    }
                  })}
                  {filters?.subcategories?.map((subcategory, subcategoryIndex) => {
                    if (currentFilters.subcategories.includes(subcategory.id)) {
                      return (
                        <FilterItem key={subcategoryIndex}>
                          {subcategory.title}
                          <CrossIcon
                            onClick={handleKnowledgebaseFilter}
                            data-id={subcategory.id}
                            data-type='subcategories'
                            src={images.crossFilterIcon}
                          />
                        </FilterItem>
                      );
                    }
                  })}
                  {filters?.tags?.map((tag, tagIndex) => {
                    if (currentFilters.tags.includes(tag.id)) {
                      return (
                        <FilterItem key={tagIndex}>
                          {tag.title}
                          <CrossIcon
                            onClick={handleKnowledgebaseFilter}
                            data-id={tag.id}
                            data-type='tags'
                            src={images.crossFilterIcon}
                          />
                        </FilterItem>
                      );
                    }
                  })}
                </FiltersListContainer>
                <ClearAllTitle onClick={handleClearAllKnowledgeFilters}>
                  {strings.epKnowledge.clearAll}
                </ClearAllTitle>
              </AppliedFiltersContainer>
            )}
            {data.length === 0 && (
              <ListItem>
                <NoRecords>{strings.common.noArticles}</NoRecords>
              </ListItem>
            )}
            {data.map((article, index) => {
              const status = getStatus(article);
              return (
                <ListItem key={index} data-index={index} onClick={handleArticleClick}>
                  <Name>{article.name}</Name>
                  <KnowledgeIcon src={images.knowledgeListIcon} />
                  <TagsContainer>
                    {article.modules.map((tag, tagIndex) => {
                      return <Tag key={tagIndex}>{tag.name}</Tag>;
                    })}
                    {/* {["Design", "Logo", "Basics"].map((tag, tagIndex) => {
                      return <Tag key={tagIndex}>{tag}</Tag>;
                    })} */}
                  </TagsContainer>
                  <Summary>{article?.category?.name}</Summary>
                  {/* <Summary>{article?.subcategory?.name}</Summary> */}
                  {/* <Summary>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore.
                  </Summary> */}
                  <MetaContainer>
                    <MetaRow>
                      <MetaIcon src={images.knowledgeListIcon} />
                      <MetaTitle>{article.knowledgebase.name}</MetaTitle>
                    </MetaRow>
                    <MetaSeperator />
                    <MetaRow>
                      <MetaIcon src={images.knowledgeCreatedBy} />
                      <MetaTitle>{article?.createdBy?.name}</MetaTitle>
                    </MetaRow>
                    <MetaSeperator />
                    <MetaRow>
                      <MetaIcon src={images.knowledgeHistory} />
                      <MetaTitle>
                        {MomentDate({ date: article.createdAt, format: "YYYY-MM-DD" })}
                      </MetaTitle>
                    </MetaRow>

                    {!!article.attachments.length && (
                      <>
                        <MetaSeperator />
                        <MetaRow>
                          <MetaIcon src={images.knowledgeAttachment} />
                          <MetaTitle>{strings.epKnowledge.attachmentsIncluded}</MetaTitle>
                        </MetaRow>
                      </>
                    )}
                  </MetaContainer>
                  {isRejected(article) && (
                    <Status className='knowledge-rejected-status' color={"red"}>
                      {"Rejected"}
                    </Status>
                  )}
                  <Status color={status.color}>{status.title}</Status>
                </ListItem>
              );
            })}
            <Pagination
              currentPage={metaData.currentPage || 1}
              totalCount={metaData.totalItems || 0}
              pageSize={metaData.limit}
              onPageChange={onPageChange}
            />
          </ListContainer>
        )}
        <FiltersContainer loading={loading}>
          <FiltersHeader onClick={() => console.log(currentFilters)}>
            {strings.epKnowledge.advanceSearch}
          </FiltersHeader>
          {advanceSearch.map((item, index) => {
            return (
              <>
                <FilterItemRow
                  key={index}
                  active={current === index}
                  onClick={handleAccordion}
                  data-index={index}
                >
                  <FilterItemTitle>{item.title}</FilterItemTitle>
                  <Icon data-index={index} className='arrow' src={images.arrowRight} />
                </FilterItemRow>
                {index === current && (
                  <ExpandedFilterContainer>
                    <Search
                      containerClass={"ep-knowledge-filter-search-container"}
                      inputClass='ep-knowledge-filter-input'
                      inputProps={{
                        placeholder: strings.common.search,
                        onChange: onChangeFilterSearch,
                        value: searchFilter,
                      }}
                      iconClass='ep-knowledge-filter-icon'
                    />

                    {Array.isArray(filters[item.key]) &&
                      filters[item.key].map((filterItem, filterIndex) => {
                        const handleFilterChange = handleKnowledgebaseFilter;
                        let checkIcon = images.uncheckedCheckbox;
                        switch (item.key) {
                          case "knowledgebases":
                            if (currentFilters?.knowledgebase === String(filterItem.id)) {
                              checkIcon = images.checkedCheckbox;
                            }
                            break;
                          case "categories":
                            if (currentFilters.categories.includes(filterItem.id)) {
                              checkIcon = images.checkedCheckbox;
                            }
                            break;
                          case "subcategories":
                            if (currentFilters.subcategories.includes(filterItem.id)) {
                              checkIcon = images.checkedCheckbox;
                            }
                            break;
                          case "tags":
                            if (currentFilters.tags.includes(filterItem.id)) {
                              checkIcon = images.checkedCheckbox;
                            }
                            break;
                        }

                        if (
                          filterIndex < 4 &&
                          String(filterItem?.title)
                            .toLowerCase()
                            .includes(searchFilter.toLowerCase())
                        ) {
                          return (
                            <FilterChildContainer
                              key={filterIndex}
                              onClick={handleFilterChange}
                              data-item={filterItem.title}
                              data-type={item.key}
                              data-id={filterItem.id}
                              data-fetch={true}
                            >
                              <Checkbox src={checkIcon} />
                              <FilterChildTitle>{filterItem.title}</FilterChildTitle>
                            </FilterChildContainer>
                          );
                        }
                      })}
                    {Array.isArray(filters[item.key]) && filters[item.key].length >= 4 && (
                      <FilterChildContainer
                        onClick={openFilterModel}
                        data-title={item.title}
                        data-key={item.key}
                      >
                        <FilterChildTitle className='show-more'>
                          {strings.common.showMore}
                        </FilterChildTitle>
                      </FilterChildContainer>
                    )}
                  </ExpandedFilterContainer>
                )}
              </>
            );
          })}
        </FiltersContainer>
      </KnowledgeArticlesContainer>
      <Popup isOpen={!!filterModel} closeModal={closeFilterModel}>
        <ModelContainer>
          <Search
            containerClass={"ep-knowledge-filter-search-model"}
            inputClass='ep-knowledge-filter-model-input'
            inputProps={{
              placeholder: strings.common.search,
              onChange: onChangeFilterSearch,
              value: searchFilter,
            }}
            iconClass='ep-knowledge-filter-model-icon'
          />
          <ModelTitle onClick={() => console.log(currentFilters)}>{filterModel}</ModelTitle>
          <ModelFilterContainer>
            {filters?.[filterModelKey]?.map((filterItem, filterIndex) => {
              let checkIcon = images.uncheckedCheckbox;
              switch (filterModelKey) {
                case "knowledgebases":
                  if (selectedKnowledgebase === String(filterItem.id)) {
                    checkIcon = images.checkedCheckbox;
                  }
                  break;
                case "categories":
                  if (selectedCategories.includes(filterItem.id)) {
                    checkIcon = images.checkedCheckbox;
                  }
                  break;
                case "subcategories":
                  if (selectedSubcategories.includes(filterItem.id)) {
                    checkIcon = images.checkedCheckbox;
                  }
                  break;
                case "tags":
                  if (selectedTags.includes(filterItem.id)) {
                    checkIcon = images.checkedCheckbox;
                  }
                  break;
              }
              if (String(filterItem?.title).toLowerCase().includes(searchFilter.toLowerCase())) {
                return (
                  <FilterChildContainer
                    key={filterIndex}
                    className='model-filter-container'
                    onClick={handleModelKnowledgebaseFilter}
                    data-type={filterModelKey}
                    data-item={filterItem.title}
                    data-id={filterItem.id}
                  >
                    <Checkbox src={checkIcon} />
                    <FilterChildTitle>{filterItem.title}</FilterChildTitle>
                  </FilterChildContainer>
                );
              }
            })}
          </ModelFilterContainer>
          <Button
            onClick={applyModelFilters}
            className='knowledge-filter-apply'
            title={strings.epKnowledge.apply}
          />
        </ModelContainer>
      </Popup>
    </Container>
  );
};
