import React from "react";

import { Layout } from "../../../presentationals";
import { CurrencyList } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CurrencyList />} admin={true} layout={"2"} />;
};

export const APCurrencyList = React.memo(Route);
