import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useLocation, useParams } from "react-router-dom";

import { ActiveSelectOptions, collections } from "../../../../../utils/constants";
import { useControllerConfigs } from "../../../../common";
import { Row } from "../../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../../components";
import { TextInput, Select, SystemData } from "../../../../components/atoms";
import { FormChecklistV2 } from "../../../../components/templates/FormChecklistV2/FormChecklistV2";
import { DashboardAdministrationList } from "../Administration";
import { DashboardWidgetsList } from "../Widgets";

import { useDashboardTabsController } from "./hooks";

export const DashboardTabsController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { dashboardAdministrationOptions } = useControllerConfigs();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dashboardAdministrationId = searchParams.get("dashboardAdministration");
  const {
    currentStepIndex,
    handleDefaultDashboardAdministration,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchDashboardTabs,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useDashboardTabsController({ type, id: params.id, attachmentsPickerRef });
  const currentDashboardTabs = data;

  const { workLogs, attachments, title, description, active, dashboard } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchDashboardTabs();
    }
    // Do not include fetchDashboardTabs to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  useEffect(() => {
    if (dashboardAdministrationId) {
      handleDefaultDashboardAdministration(dashboardAdministrationId);
    }
    // Do not include handleDefaultDashboardAdministration to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAdministrationId]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Title"}
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Desctiption'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              rows: 14,
              required: description.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    onTextChange,
    title.error,
    title.required,
    title.value,
  ]);

  const renderAccess = useMemo(() => {
    return (
      <React.Fragment>
        <Select
          width={"100%"}
          name={"dashboard"}
          label={"Dashboard Config"}
          options={dashboardAdministrationOptions}
          value={dashboard.value}
          onChange={onTextChange}
          mandatory={dashboard.required}
          error={dashboard.error}
        />
      </React.Fragment>
    );
  }, [
    dashboard.error,
    dashboard.required,
    dashboard.value,
    dashboardAdministrationOptions,
    onTextChange,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentDashboardTabs?.createdBy !== "string"
        ? currentDashboardTabs?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentDashboardTabs?.updatedBy !== "string"
        ? currentDashboardTabs?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentDashboardTabs?.createdAt}
        updatedAt={currentDashboardTabs?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentDashboardTabs?.createdAt,
    currentDashboardTabs?.createdBy,
    currentDashboardTabs?.updatedAt,
    currentDashboardTabs?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.dashboardTabs.name} recordId={params.id} />,

    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.dashboardTabs.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,

    [onPostWorklog, workLogs.value],
  );
  const renderRelatedAdministration = useMemo(() => {
    return (
      <DashboardAdministrationList
        relatedList={{
          type: "_id",
          value: dashboard.value,
        }}
      />
    );
  }, [dashboard.value]);

  const renderRelatedWidgets = useMemo(() => {
    return (
      <DashboardWidgetsList
        relatedList={{
          type: "dashboardTab",
          value: params.id,
        }}
      />
    );
  }, [params.id]);
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderAccess;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      case 6:
        return renderRelatedAdministration;
      case 7:
        return renderRelatedWidgets;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderRelatedAdministration,
    renderRelatedWidgets,
    renderAccess,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
