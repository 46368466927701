import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteAcademyACH,
  fetchAcademyACHList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateAcademyACH,
  updateTableConfigs,
} from "../../../../../redux";
import {
  AcademyACHList,
  AcademyACHListWithMetaData,
  DeleteAcademyACHPayload,
  FetchAcademyACHParams,
} from "../../../../../redux/AcademyACH/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchAcademyACHProps,
  sortType,
  useAcademyACHListProps,
  useAcademyACHListReturnType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getAcademyACHColumns } from "../../../../columns/AcademyACH";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.academyACH;
const { activate, deactivate } = strings.common;
const defaultFilters: QueryBuilderType[] = [];

export const useAcademyACHList = ({
  relatedList,
  widgetId,
}: useAcademyACHListProps): useAcademyACHListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const metaData = useAppSelector((state) => state.AcademyACHReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const academyACH = useAppSelector((state) => state.AcademyACHReducer.data.list);

  const { employeesOptions, usersOptions, academyCandidateLeadsOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const academyACHTableConfigFields = useMemo(() => {
    return tableConfiguration?.academyACH?.fields?.length
      ? tableConfiguration?.academyACH?.fields
      : tableListDefaultSelectFields.academyACH;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = academyACHTableConfigFields;
    return getAcademyACHColumns({ fields });
  }, [academyACHTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: AcademyACHList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.academyACH?.fields || tableListDefaultSelectFields.academyACH;
      records = records?.map((item) => {
        const academyACHItem = { ...item };
        if (userPersonalizedFields?.includes("state") && typeof item.state !== "string") {
          academyACHItem.state = item?.state?.value;
        }
        if (
          userPersonalizedFields?.includes("activityOwner") &&
          typeof item.activityOwner !== "string"
        ) {
          academyACHItem.activityOwner = item?.activityOwner?.firstName;
        }
        if (
          userPersonalizedFields?.includes("activityPurpose") &&
          typeof item.activityPurpose !== "string"
        ) {
          academyACHItem.activityPurpose = item?.activityPurpose?.value;
        }
        if (
          userPersonalizedFields?.includes("associatedCandidateLeads") &&
          typeof item.associatedCandidateLeads !== "string"
        ) {
          academyACHItem.associatedCandidateLeads = item?.associatedCandidateLeads?.firstName;
        }
        if (
          userPersonalizedFields?.includes("activityType") &&
          typeof item.activityType !== "string"
        ) {
          academyACHItem.activityType = item?.activityType?.value;
        }
        if (
          userPersonalizedFields?.includes("callDirection") &&
          typeof item.callDirection !== "string"
        ) {
          academyACHItem.callDirection = item?.callDirection?.value;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          academyACHItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          academyACHItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          academyACHItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          academyACHItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete academyACHItem?._id;
        return academyACHItem;
      });
      const academyACHFields = tablePublicFields.academyACH;
      const columnHeaders = academyACHFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "calls_meetings_activities");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.academyACH?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Academy ACH Success/Error Methods ================
  const academyACHFetchSuccess = useCallback(
    (data: AcademyACHListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const academyACHFetchError = useCallback(
    (error) => {
      console.log({ error });
      setLoading(false);
      dispatch(showInAppLoader(false));
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  // ================ Fetch Academy ACH Method ================

  const fetchAcademyACH = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchAcademyACHProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.academyACH?.limit || 10;

      const params: FetchAcademyACHParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: AcademyACHListWithMetaData) => academyACHFetchSuccess(data, exportAll),
        onError: academyACHFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchAcademyACHList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.academyACH?.limit,
      sortKey,
      sortType,
      relatedList,
      academyACHFetchError,
      dispatch,
      widgetsData,
      academyACHFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = academyACH?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, academyACH, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchAcademyACH({ exportAll: true });
  }, [fetchAcademyACH]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchAcademyACH({ page });
  }, [fetchAcademyACH, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchAcademyACH({ page: parseInt(page), search });
    },
    [fetchAcademyACH, search],
  );

  // ================ Add/Edit Methods ================

  const onClickAcademyACH = useCallback(
    (id: string) => {
      if (permissions?.academyACH_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, dispatch, permissions?.academyACH_view, notificationsId],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchAcademyACH({ page: 1, search });
  }, [fetchAcademyACH, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchAcademyACH({ page: 1 });
  }, [fetchAcademyACH]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchAcademyACH({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchAcademyACH],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchAcademyACH({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchAcademyACH],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = academyACH?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteAcademyACHPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchAcademyACH({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteAcademyACH(payload));
  }, [selectedRows, academyACH, dispatch, notificationsId, fetchAcademyACH]);
  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRows = academyACH?.map((item) => item?._id);
    return isEqualArrays(allRows, selectedRows);
  }, [selectedRows, academyACH]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allItems: string[] = [];
            academyACH?.forEach((item) => {
              if (item?._id) {
                allItems.push(item?._id);
              }
            });
            setSelectedRows(allItems);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, academyACH, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const tableConfigLimit = tableConfiguration?.academyACH?.limit || 10;
      const tableConfigs = {
        table: "academyACH",
        fields: fields,
        limit: tableConfigLimit,
      };
      const payload = {
        tableConfiguration: tableConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyACH({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Calls/Meetings table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.academyACH?.limit, dispatch, fetchAcademyACH, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const academyACHConfigFields = tableConfiguration?.academyACH?.fields;
      dispatch(showInAppLoader(true));
      const academyACHConfigs = {
        table: "academyACH",
        fields: academyACHConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: academyACHConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyACH({ limit: count });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Calls/Meetings table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchAcademyACH],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = academyACH?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchAcademyACH({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Calls/Meetings updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Calls/Meetings ",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };

      dispatch(updateAcademyACH(payload));
    },
    [selectedRows, academyACH, dispatch, notificationsId, fetchAcademyACH],
  );

  // ================ Table Header Buttons ================

  const academyACHHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "state",
      label: "State",
      options: getAttributValues("academyACHState"),
      required: true,
    },
    {
      name: "activityType",
      label: "Activity Type",
      options: getAttributValues("academyACHType"),
      required: true,
    },
    {
      name: "associatedCandidateLeads",
      label: "Associated Candidate Leads",
      options: academyCandidateLeadsOptions,
      required: true,
    },
    {
      name: "activityOwner",
      label: "Activity Owner",
      options: employeesOptions,
      required: true,
    },
    {
      name: "activityDate",
      label: "activityDate",
      type: "date",
      required: true,
    },
    {
      name: "callDirection",
      label: "Call Direction",
      options: getAttributValues("academyACHCallDirection"),
    },
    {
      name: "activityAgenda",
      label: "Activity Agenda",
      type: "text",
    },
    {
      name: "activityPurpose",
      label: "Activity Purpose",
      options: getAttributValues("academyACHPurpose"),
    },
    {
      name: "activityResult",
      label: "Activity Result",
      options: getAttributValues("academyACHResult"),
    },
    {
      name: "meetLocationURL",
      label: "Meet Location URL",
      type: "text",
    },
    {
      name: "from",
      label: "From",
      type: "date",
    },
    {
      name: "to",
      label: "To",
      type: "date",
    },
    {
      name: "recordingURL",
      label: "Recording URL",
      type: "text",
    },
    {
      name: "shortDescripiton",
      label: "shortDescripiton",
      type: "bigTextArea",
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
    },
    {
      name: "associatedCandidateLeads",
      label: "Associated Candidate Leads",
      options: academyCandidateLeadsOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const academyACHFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const activityTypeOptions = getFilterOptions(
      getAttributValues("academyACHType"),
      "Activity Type",
    );
    const achStateOptions = getFilterOptions(getAttributValues("academyACHState"), "State");
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const associateCandidateLeadsOptions = getFilterOptions(academyCandidateLeadsOptions, "Users");
    const callDirectionOptions = getFilterOptions(
      getAttributValues("academyACHCallDirection"),
      "Call Direction",
    );
    const activityPurposeOptions = getFilterOptions(
      getAttributValues("academyACHPurpose"),
      "Activity Purpose",
    );

    const fields: Field[] = [
      {
        name: "activityType",
        label: "Activity Type",
        valueEditorType: "select",
        values: activityTypeOptions,
        defaultValue: activityTypeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "associatedCandidateLeads",
        label: "Associated Candidate Leads",
        valueEditorType: "select",
        values: associateCandidateLeadsOptions,
        defaultValue: associateCandidateLeadsOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "serial",
        label: "Activity Number",
        placeholder: "Any Activity Number",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "callDirection",
        label: "Call Direction",
        valueEditorType: "select",
        values: callDirectionOptions,
        defaultValue: callDirectionOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "activityAgenda",
        label: "Activity Agenda",
        placeholder: "Any Activity Agenda",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "activityPurpose",
        label: "Activity Purpose",
        valueEditorType: "select",
        values: activityPurposeOptions,
        defaultValue: activityPurposeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "meetLocationURL",
        label: "Meet Location URL",
        placeholder: "Any Meet Location URL",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "recordingURL",
        label: "Recording URL",
        placeholder: "Any Recording URL",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "description",
        label: "Description",
        placeholder: "Any Description",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "shortDescripiton",
        label: "Short Descripiton",
        placeholder: "Any Short Descripiton",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "state",
        label: "State",
        valueEditorType: "select",
        values: achStateOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "from",
        label: "From",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "to",
        label: "To",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "activityDate",
        label: "Activity Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "activityOwner",
        label: "Activity Owner",
        valueEditorType: "select",
        values: employeeyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [academyCandidateLeadsOptions, employeesOptions, usersOptions]);

  return {
    onClickAcademyACH,
    fetchAcademyACH,
    onClickAdd,
    loading,
    columns,
    academyACHTableConfigFields,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    listActionOptions,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyACHHeaderButtons,
    academyACHFilterFields,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
  };
};
