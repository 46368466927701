import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createTask,
  fetchTaskById,
  fetchControllerConfigs,
  removeAppNotification,
  updateTask,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  CreateTaskPayload,
  FetchTaskByIdPayload,
  TaskList,
  UpdateTaskPayload,
} from "../../../../../redux/Task/types";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  TaskForm,
  SelectOptions,
  WorkLogItem,
} from "../../../../../types";
import { emailRegex, phoneRegex } from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

interface useMultiStepFormType {
  onPostWorklog: (comment: string) => void;
  assignmentOptions: SelectOptions[];
  currentStepIndex: number;
  step: string;
  goTo: (e: MouseEvent<HTMLDivElement>) => void;
  next: () => void;
  back: () => void;
  data: TaskList;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  formData: TaskForm;
  onSubmitAdd: () => void;
  onSubmitUpdate: () => void;
  loading: boolean;
  headerTitle: string[];
  fetchTask: () => void;
  getStepBadge: (step: number) => number;
  // taskContactAttributes: SelectOptions[];
  employeesAttributes: SelectOptions[];
  countryOptions: SelectOptions[];
  attachmentModal: "" | "add" | "list";
  taskPrefix: string;
  handleAttachmentsModal: () => void;
  onAttachmentsLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onClickAddAttachment: () => void;
  onClickBrowseAttachment: () => void;
  onAttachmentsFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCurrentAttachment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentAttachmentFile?: File | null;
  currentAttachment: Attachments;
  attachmentUploadProgress: string;
  onClickCancelAttachment: () => void;
  onClickSaveAttachment: () => void;
  getAttachmentFileIcon: (extension?: string) => any;
  handleShowAttachment: (e: MouseEvent<HTMLImageElement>) => void;
  handleDeleteAttachment: (e: MouseEvent<HTMLImageElement>) => void;
  handleCloseAttachment: () => void;
  documentId: string;
  onDateChange?: (value: Date, name: string) => void;
}

interface Props {
  steps: string[];
  type?: "add" | "edit";
  id?: string;
  attachmentsPickerRef: React.RefObject<HTMLInputElement>;
  associatedTo?: string | null;
}

const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
export const useTaskController = ({
  steps,
  type,
  id,
  attachmentsPickerRef,
  associatedTo,
}: Props): useMultiStepFormType => {
  const list = useAppSelector((state) => state.TaskReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const country = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.country,
  );
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  // const companies = useAppSelector(
  //   (state) => state.ApplicationReducer.configurations.controllerConfigs.task,
  // );
  // const taskContacts = useAppSelector(
  //   (state) => state.ApplicationReducer.configurations.controllerConfigs.taskContact,
  // );
  const employees = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.employees,
  );

  const groups = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.groups,
  );

  const { usersOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const defaultFormData: TaskForm = {
    associatedEmployee: {
      value: "",
      required: associatedTo === "hr" ? true : false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    watchListGroup: {
      value: [],
      required: false,
      error: "",
    },
    number: {
      value: "",
      required: false,
      error: "",
    },
    type: {
      value: "",
      required: false,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    shortDescription: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: true,
      error: "",
    },
    assignmentGroups: {
      value: "",
      required: false,
      error: "",
    },
    assignedTo: {
      value: "",
      required: true,
      error: "",
    },
    watchListUser: {
      value: [],
      required: false,
      error: "",
    },
    priority: {
      value: "",
      required: true,
      error: "",
    },
    urgency: {
      value: "",
      required: true,
      error: "",
    },
    state: {
      value: "",
      required: true,
      error: "",
    },
    holdReason: {
      value: "",
      required: false,
      error: "",
    },
    associatedCompany: {
      value: "",
      required: associatedTo === "company" ? true : false,
      error: "",
    },
    associatedCandidateLead: {
      value: "",
      required: false,
      error: "",
    },
    associatedCandidateEnrolment: {
      value: "",
      required: false,
      error: "",
    },
    associatedCandidateStudent: {
      value: "",
      required: false,
      error: "",
    },
    achAssociationType: {
      value: "",
      required: associatedTo === "ach" ? true : false,
      error: "",
    },
  };
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<TaskForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<TaskList>({});

  const assignmentOptions = useMemo(() => {
    const users: SelectOptions[] = usersOptions;
    const groupUsers: SelectOptions[] = [];
    const assignmentGroup = formData.assignmentGroups.value;
    if (formData.assignmentGroups.value) {
      const groupMembers = groups.find((item) => item._id === assignmentGroup);
      groupMembers?.members.map((item) => {
        if (item.user.active) {
          groupUsers.push({
            label: item.user.name || "",
            value: item.user._id,
          });
        }
      });
      return groupUsers;
    }
    return users;
  }, [formData.assignmentGroups.value, groups, usersOptions]);

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= steps.length - 1 ? i : i + 1));
  }, [steps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;
      const overViewFields = [
        "associatedEmployee",
        "associatedCandidateLead",
        "associatedCandidateEnrolment",
        "associatedCandidateStudent",
        "associatedCompany",
        "achAssociationType",
        "number",
        "type",
        "active",
        "shortDescription",
        "description",
        "assignmentGroups",
        "watchListGroup",
        "assignedTo",
        "watchListUser",
        "priority",
        "urgency",
        "state",
        "holdReason",
      ];

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field]?.error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (["achAssociationType"].includes(name)) {
        switch (value) {
          case "lead":
            tempFormData = {
              ...tempFormData,
              associatedCandidateLead: {
                ...formData.associatedCandidateLead,
                required: true,
              },
              associatedCandidateEnrolment: {
                ...formData.associatedCandidateEnrolment,
                required: false,
              },
              associatedCandidateStudent: {
                ...formData.associatedCandidateStudent,
                required: false,
              },
            };
            break;
          case "enrolment":
            tempFormData = {
              ...tempFormData,
              associatedCandidateLead: {
                ...formData.associatedCandidateLead,
                required: false,
              },
              associatedCandidateEnrolment: {
                ...formData.associatedCandidateEnrolment,
                required: true,
              },
              associatedCandidateStudent: {
                ...formData.associatedCandidateStudent,
                required: false,
              },
            };
            break;
          case "student":
            tempFormData = {
              ...tempFormData,
              associatedCandidateLead: {
                ...formData.associatedCandidateLead,
                required: false,
              },
              associatedCandidateEnrolment: {
                ...formData.associatedCandidateEnrolment,
                required: false,
              },
              associatedCandidateStudent: {
                ...formData.associatedCandidateStudent,
                required: true,
              },
            };
            break;
        }
      }
      if (["associatedCandidateLead"].includes(name)) {
        tempFormData = {
          ...tempFormData,
          associatedCandidateLead: {
            ...formData.associatedCandidateLead,
            value: value,
            error: "",
          },
          associatedCandidateEnrolment: {
            ...formData.associatedCandidateEnrolment,
            value: null,
            error: "",
          },
          associatedCandidateStudent: {
            ...formData.associatedCandidateStudent,
            value: null,
            error: "",
          },
        };
      }
      if (["associatedCandidateEnrolment"].includes(name)) {
        tempFormData = {
          ...tempFormData,
          associatedCandidateLead: {
            ...formData.associatedCandidateLead,
            value: null,
            error: "",
          },
          associatedCandidateEnrolment: {
            ...formData.associatedCandidateEnrolment,
            value: value,
            error: "",
          },
          associatedCandidateStudent: {
            ...formData.associatedCandidateStudent,
            value: null,
            error: "",
          },
        };
      }
      if (["associatedCandidateStudent"].includes(name)) {
        tempFormData = {
          ...tempFormData,
          associatedCandidateLead: {
            ...formData.associatedCandidateLead,
            value: null,
            error: "",
          },
          associatedCandidateEnrolment: {
            ...formData.associatedCandidateEnrolment,
            value: null,
            error: "",
          },
          associatedCandidateStudent: {
            ...formData.associatedCandidateStudent,
            value: value,
            error: "",
          },
        };
      }
      if (name === "assignmentGroups" && formData.assignedTo.value && value) {
        const groupMembers = groups.find((item) => item._id === value)?.members;
        const currentAssignedTo = formData.assignedTo.value;
        let currentAssignedToInGroup = false;
        groupMembers?.map((item) => {
          if (item.user._id === currentAssignedTo) {
            currentAssignedToInGroup = true;
          }
        });
        if (!currentAssignedToInGroup) {
          tempFormData = {
            ...tempFormData,
            assignedTo: {
              ...formData.assignedTo,
              value: "",
              error: "",
            },
          };
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title:
              "Assigned to is not part of the assignemnt group , select the user from the selcted group",
            type: "warning",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 5000);
        }
      }
      setFormData(tempFormData);
    },
    [formData, groups, dispatch, notificationsId],
  );

  const rehydrateFormData = useCallback(
    (task) => {
      const item = task;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (
          [
            "associatedEmployee",
            "associatedCompany",
            "associatedCandidateLead",
            "associatedCandidateEnrolment",
            "associatedCandidateStudent",
            "type",
            "assignedTo",
            "priority",
            "urgency",
            "state",
            "assignmentGroups",
          ].includes(field)
        ) {
          const tempValue = value?._id;
          value = tempValue;
        }
        if (["watchListGroup", "watchListUser"].includes(field)) {
          value = item?.[field]?.map((item) => ({
            label: item.name,
            value: item._id,
          }));
        }
        if (["achAssociationType"].includes(field)) {
          if (item?.associatedCandidateLead) {
            value = "lead";
          }
          if (item?.associatedCandidateEnrolment) {
            value = "enrolment";
          }
          if (item?.associatedCandidateStudent) {
            value = "student";
          }
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchTask = useCallback(() => {
    setLoading(true);
    const payload: FetchTaskByIdPayload = {
      associatedTo: associatedTo,
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        setData(data);
        rehydrateFormData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchTaskById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId, associatedTo]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    // if (associatedTo === "ach") {
    //   const { associatedCandidateLead, associatedCandidateEnrolment, associatedCandidateStudent } =
    //     formData;
    //   if (
    //     !associatedCandidateLead.value &&
    //     !associatedCandidateEnrolment.value &&
    //     !associatedCandidateStudent.value
    //   ) {
    //     valid = false;
    //     const notification: AppNotificationsType = {
    //       id: notificationsId + 1,
    //       title: "Associated Candidate or Associated Enrolment or Associated Lerner is required",
    //       type: "warning",
    //     };
    //     dispatch(addAppNotification({ notification }));
    //     setTimeout(() => {
    //       dispatch(removeAppNotification({ notification }));
    //     }, 4000);
    //     return;
    //   }
    // }
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      attachments,
      associatedEmployee,
      associatedCandidateLead,
      associatedCandidateEnrolment,
      associatedCandidateStudent,
      associatedCompany,
      number,
      type,
      active,
      shortDescription,
      description,
      assignmentGroups,
      assignedTo,
      watchListGroup,
      watchListUser,
      priority,
      urgency,
      state,
      holdReason,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
      }));
      const tempWatchlistGroups = watchListGroup.value.map((item) => item.value);
      const tempWatchlistUser = watchListUser.value.map((item) => item.value);
      const payload: CreateTaskPayload = {
        associatedTo: associatedTo,
        data: {
          attachments: attachments,
          number: number.value,
          type: type.value,
          active: active.value,
          shortDescription: shortDescription.value,
          description: description.value,
          assignmentGroups: assignmentGroups.value,
          assignedTo: assignedTo.value,
          watchListGroup: tempWatchlistGroups,
          watchListUser: tempWatchlistUser,
          priority: priority.value,
          urgency: urgency.value,
          state: state.value,
          holdReason: holdReason.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Task created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      switch (associatedTo) {
        case "hr":
          payload.data.associatedEmployee = associatedEmployee.value;
          break;
        case "company":
          payload.data.associatedCompany = associatedCompany.value;
          break;
        case "ach":
          payload.data.associatedCandidateLead = associatedCandidateLead.value;

          payload.data.associatedCandidateEnrolment = associatedCandidateEnrolment.value;
          payload.data.associatedCandidateStudent = associatedCandidateStudent.value;
          break;
      }
      dispatch(createTask(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate, associatedTo]);

  const onSubmitUpdate = useCallback(() => {
    const {
      associatedEmployee,
      associatedCandidateLead,
      associatedCandidateEnrolment,
      associatedCandidateStudent,
      associatedCompany,
      number,
      type,
      active,
      shortDescription,
      description,
      assignmentGroups,
      assignedTo,
      watchListGroup,
      watchListUser,
      priority,
      urgency,
      state,
      holdReason,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
      }));
      setLoading(true);
      const tempWatchlistGroups = watchListGroup.value.map((item) => item.value);
      const tempWatchlistUser = watchListUser.value.map((item) => item.value);
      const payload: UpdateTaskPayload = {
        associatedTo: associatedTo,
        id: id || "",
        data: {
          attachments: attachments,
          number: number.value,
          type: type.value,
          active: active.value,
          shortDescription: shortDescription.value,
          description: description.value,
          assignmentGroups: assignmentGroups.value,
          assignedTo: assignedTo.value,
          watchListGroup: tempWatchlistGroups,
          watchListUser: tempWatchlistUser,
          priority: priority.value,
          urgency: urgency.value,
          state: state.value,
          holdReason: holdReason.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Task updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      switch (associatedTo) {
        case "hr":
          payload.data.associatedEmployee = associatedEmployee.value;
          break;
        case "company":
          payload.data.associatedCompany = associatedCompany.value;
          break;
        case "ach":
          payload.data.associatedCandidateLead = associatedCandidateLead.value;
          payload.data.associatedCandidateEnrolment = associatedCandidateEnrolment.value;
          payload.data.associatedCandidateStudent = associatedCandidateStudent.value;
          break;
      }
      dispatch(updateTask(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm, associatedTo]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateTaskPayload = {
          associatedTo: associatedTo,
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "Task updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateTask(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id, associatedTo],
  );

  const taskPrefix = useMemo(() => {
    let prefix = "";
    switch (associatedTo) {
      case "hr":
        prefix = "HRTASK00";
        break;
      case "company":
        prefix = "CPTASK00";
        break;
      case "ach":
        prefix = "ACHTASK00";
        break;
    }
    return prefix;
  }, [associatedTo]);

  const headerTitle = useMemo(() => {
    const { task, hrTask, companyTask, ACHTask } = strings.headerTitle;
    let titleTwo = "";
    let titleOne = task;
    switch (associatedTo) {
      case "hr":
        titleOne = hrTask;
        break;
      case "company":
        titleOne = companyTask;
        break;
      case "ach":
        titleOne = ACHTask;
        break;
    }
    if (type === "add") {
      titleTwo = "Create";
    } else {
      titleTwo = `${taskPrefix}${data?.number}` || "";
    }
    return [titleOne, titleTwo];
  }, [data, type, associatedTo, taskPrefix]);

  // const taskAttributes = useMemo(() => {
  //   const taskAttributes = companies.map((item) => {
  //     return {
  //       label: item.label,
  //       value: item._id,
  //     };
  //   });
  //   return taskAttributes;
  // }, [companies]);

  // const taskContactAttributes = useMemo(() => {
  //   const taskContactAttributes = taskContacts.map((item) => {
  //     return {
  //       label: item.label,
  //       value: item._id,
  //     };
  //   });
  //   return taskContactAttributes;
  // }, [taskContacts]);

  const employeesAttributes = useMemo(() => {
    const employeesAttributes = employees.map((item) => {
      return {
        label: item.label,
        value: item._id,
      };
    });
    return employeesAttributes;
  }, [employees]);

  const countryOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    country?.map((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [country]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onAttachmentsLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // const marginRight = e.target.
  }, []);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const getAttachmentFileIcon = useCallback((extension?: string) => {
    switch (extension) {
      case "pdf":
        return images.pdfFileIcon;
      default:
        return images.defaultFileIcon;
    }
  }, []);

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    goTo,
    assignmentOptions,
    next,
    back,
    formData,
    onTextChange,
    onSubmitAdd,
    onSubmitUpdate,
    loading,
    headerTitle,
    fetchTask,
    onPostWorklog,
    // taskContactAttributes,
    employeesAttributes,
    getStepBadge,
    countryOptions,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    getAttachmentFileIcon,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    taskPrefix,
  };
};
