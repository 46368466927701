import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  TicketComment,
  TicketCreate,
  TicketsGetAll,
  TicketUpdate,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { getAllTicketsAdminSuccess, getAllTicketsEmployeesSuccess } from "./actions";
import Types from "./types";

function* createTicketWorker(action) {
  try {
    const options = {
      method: "POST",
      url: TicketCreate(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateTicketWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: TicketUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* createCommentWorker(action) {
  try {
    const options = {
      method: "POST",
      url: TicketComment(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        status: action.payload.status,
        comment: action.payload.comment,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getAllTicketsWorker(action) {
  try {
    const options = {
      method: "GET",
      url: TicketsGetAll(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: {
        admin: action.payload.admin ? "true" : "false",
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      if (action.payload.admin) {
        yield put(getAllTicketsAdminSuccess(response.data.data));
      } else {
        yield put(getAllTicketsEmployeesSuccess(response.data.data));
      }
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* TicketsSaga() {
  yield takeLatest(Types.TICKETS_CREATE, createTicketWorker);
  yield takeLatest(Types.TICKETS_UPDATE, updateTicketWorker);
  yield takeEvery(Types.TICKETS_FETCH_ALL, getAllTicketsWorker);
  yield takeLatest(Types.TICKETS_COMMENT, createCommentWorker);
}
