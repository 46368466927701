import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  clearAllFilter,
  fetchKnowledgeArticles,
  setKnowledgeFilter,
} from "../../../../../redux/knowledge/actions";
import { FetchKnowledgeArticleListParams } from "../../../../../redux/knowledge/types";
import { strings } from "../../../../../theme";
import { KnowledgeListTemplate } from "../../../../components";
import { useKnowledgeList } from "../hooks/useKnowledgeApprovals";

export const AllKnowledgeArticles = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const articles = useAppSelector((state) => state.KnowledgeReducer.list);
  const loading = useAppSelector((state) => state.KnowledgeReducer.loading);
  const searchQuery = useAppSelector((state) => state.KnowledgeReducer.list.searchQuery);

  const {
    headerTitle,
    handleOnPageClickAll,
    getStatus,
    search,
    onChangeSearch,
    handleClearSearch,
    handleReloadAll,
    setSearch,
  } = useKnowledgeList({ searchQuery });

  useEffect(() => {
    console.log("Articles ::", articles.type, location);
    if (articles.type !== "all" || location.state?.knowledgebase || location.state?.searchQuery) {
      dispatch(clearAllFilter());
      console.log("location ::", location);
      const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10 };
      const search = location.state?.searchQuery;
      const knowledgebase = location.state?.knowledgebase;
      if (knowledgebase) {
        dispatch(setKnowledgeFilter({ knowledgebase: knowledgebase }));
        params.knowledgebase = knowledgebase;
      }
      if (search) {
        setSearch(search);
        params.search = search;
      }
      dispatch(fetchKnowledgeArticles({ params, type: "all" }));
    }
  }, [dispatch, location, setSearch, articles.type]);

  return (
    <>
      <KnowledgeListTemplate
        loading={loading}
        type='all'
        headerTitle={headerTitle("all")}
        bannerTitle={strings.epKnowledge.allArticles}
        data={articles.data}
        metaData={articles.metaData}
        handlePageChange={handleOnPageClickAll}
        getStatus={getStatus}
        searchValue={search}
        onChangeSearch={onChangeSearch}
        handleClearSearch={handleClearSearch}
        handleReload={handleReloadAll}
        filters={articles.filters}
        preAppliedKnowledgebase={location.state?.knowledgebase || ""}
        categoryGrid={!!location.state?.knowledgebase}
      />
    </>
  );
};
