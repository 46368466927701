import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { AttributesControllerProps } from "../../../../types/models/AttributesForm";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { Row } from "../../../common/styles";
import { ActivityLogs, FormChecklist, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";

import { useAttributesController } from "./hooks";

const Controller = ({ type }: AttributesControllerProps) => {
  const params = useParams();
  const name = params.name;
  const id = params.id;
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAttributes,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    checklist,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useAttributesController({ type, id, attachmentsPickerRef, name });
  const currentAttributes = data;

  const { workLogs, attachments, value, active } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAttributes();
    }
    // Do not include fetchAttributes to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={value.error}
            inputProps={{
              name: "value",
              value: value.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: value.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    onTextChange,
    value.error,
    value.required,
    value.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentAttributes?.createdBy !== "string" ? currentAttributes?.createdBy?.name : "";
    const updatedBy =
      typeof currentAttributes?.updatedBy !== "string" ? currentAttributes?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentAttributes?.createdAt}
        updatedAt={currentAttributes?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAttributes?.createdAt,
    currentAttributes?.createdBy,
    currentAttributes?.updatedAt,
    currentAttributes?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklist
        onSubmitAddChecklist={onSubmitAddChecklist}
        onSubmitUpdateChecklist={onSubmitUpdateChecklist}
        checklist={checklist}
        deleteChecklist={deleteChecklist}
      />
    ),

    [onSubmitAddChecklist, onSubmitUpdateChecklist, checklist, deleteChecklist],
  );
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.attributes.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const AttributesController = memo(Controller);
