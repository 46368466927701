import styled from "styled-components";

import { colors, fonts } from "../../../../../theme";

interface Props {
  loading?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-direction: column;
  opacity: ${(props) => (props.loading ? "0.6" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "all")};
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FormFieldsContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  height: 75vh;
  background: ${colors.common.white};
  border: 0.3px solid rgba(0, 0, 0, 0.75);
  border-right: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px 35px;
  .model-filter-container {
    width: 85%;
    margin: 0 1% 0 0;
    padding: 0;
    height: fit-content;
    background: transparent;
    border: none;
  }
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
    position: absolute;
    left: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  width: 20%;
  /* height: 75vh; */
  background: ${colors.common.white};
  border: 0.3px solid rgba(0, 0, 0, 0.75);
`;

export const FormTitle = styled.p`
  font-family: ${fonts.OpenSans};
  width: fit-content;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.common.lightTextColor};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AttachmentsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 200px; */
  width: 300px;
  border-radius: 5px;
  background-color: ${colors.attachments.background};
  padding-top: 20px;
  position: relative;
  /* position: fixed;
  top: 18%;
  right: 10%; */
  .knowledge-attachments-add {
    width: 20%;
    align-self: center;
    margin-bottom: 6%;
  }
`;

export const CloseIcon = styled.img`
  height: 23px;
  width: 23px;
  cursor: pointer;
  object-fit: contain;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const NoAttachmentsContainer = styled.div`
  display: flex;
  margin: 10%;
  width: 80%;
  height: 140px;
  border-radius: 5px;
  border-width: 0.6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  font-family: ${fonts.OpenSans};
  color: ${colors.common.lightTextColor};
`;

export const BrowseAttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 10%;
  width: 70%;
  text-align: center;
  border-radius: 5px;
  border-width: 0.6px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.65);
  align-items: center;
  justify-content: space-around;
  font-family: ${fonts.OpenSans};
  color: ${colors.common.lightTextColor};
  padding: 0 5%;
`;

export const CurrentAttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 10%;
  width: 80%;
  border-radius: 5px;
  border-width: 0.6px;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.65);
  align-items: center;
  justify-content: space-around;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
`;

export const AttachmentListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 300px;
  .attachments-buttons-container {
    justify-content: space-around;
  }
`;

export const AttachmentListItem = styled.div`
  display: flex;
  height: 45px;
  border-width: 0.6px 0;
  border-style: solid;
  align-items: center;
  justify-content: center;
  border-color: rgba(0, 0, 0, 0.5);
`;

export const AttachmentNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 100%;
`;
export const AttachmentTitle = styled.p`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
  margin-left: 20px;
  color: ${colors.common.black};
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: ${fonts.SourceSansPro};
`;
export const AttachmentIcon = styled.img`
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin-left: 10px;
  cursor: pointer;
`;

export const AttachmentIconsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 20%;
  height: 100%;
`;

export const DocumentViewContainer = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 80vw;
  padding-top: 40px;
`;
