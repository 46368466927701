import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyCandidateLeadsController } from "../../../presentationals/screens";

export const APAcademyCandidateLeadsAdd = () => {
  return (
    <Layout activity={<AcademyCandidateLeadsController type={"add"} />} admin={true} layout={"2"} />
  );
};
