import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { AcademyBatchListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useAcademyBatchList } from "./hooks";

export const AcademyBatchList = ({ relatedList }: AcademyBatchListProps) => {
  const academyBatch = useAppSelector((state) => state.AcademyBatchReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = academyBatch;
  const {
    fetchAcademyBatch,
    loading,
    handlePageChange,
    onSubmitDeleteRecord,
    onClickAcademyBatch,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    columns,
    academyBatchTableConfigFields,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyBatchHeaderButtons,
    academyBatchFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useAcademyBatchList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchAcademyBatch({});
    // Do not include fetchAcademyBatch to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.academyBatch_delete}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={academyBatchHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.academyBatch}
      handleTableConfigs={handleTableConfigs}
      filters={academyBatchFilterFields}
      selectedConfigFields={academyBatchTableConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickAcademyBatch}
      headerTitle={strings.headerTitle.academyBatch}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apAcademyBatch.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
