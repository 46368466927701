import React, { useEffect } from "react";

import Skeleton from "react-loading-skeleton";

import config from "../../../../../config";
import { clearAllFilter, fetchKnowledgeArticles, setKnowledgeFilter } from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { FetchKnowledgeArticleListParams } from "../../../../../redux/knowledge/types";
import { getPrefixDigit } from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import { Table, TableHeader, TableListSkeleton } from "../../../../components";
import { Header } from "../../../../components";
import Pagination from "../../../../components/Pagination";
import { useKnowledgesConfigs } from "../hooks";

import { KnowledgeStatus, MainContainer } from "./styles";

interface RelatedList {
  type?: "categories" | "subcategories" | "tags" | "knowledgebase";
  value: string;
}
export const KnowledgeList = ({ relatedList }: { relatedList: RelatedList }) => {
  const dispatch = useAppDispatch();

  const articles = useAppSelector((state) => state.KnowledgeReducer.list);
  const metaData = useAppSelector((state) => state.KnowledgeReducer.list.metaData);

  const {
    onClickKnowledge,
    fetchKnowledges,
    onClickFilter,
    emptyListMessage,
    getStatus,
    getColor,
    handleOnPageClickAll,
    loading,
    onSearch,
    clearSearch,
  } = useKnowledgesConfigs({
    relatedList,
  });
  const columns = [
    {
      title: "Serial",
      width: "10%",
      center: true,
      key: "serial",
    },
    {
      title: "Name",
      width: "20%",
      key: "name",
    },
    {
      title: "Description",
      width: "30%",
      key: "summary",
    },
    {
      title: "Status",
      width: "25%",
      render: (item) => {
        return <KnowledgeStatus color={getColor(item)}>{getStatus(item)}</KnowledgeStatus>;
      },
    },
    {
      title: "Category",
      width: "15%",
      render: (item) => {
        return <div className='title'>{item.category?.name}</div>;
      },
    },
  ];
  useEffect(() => {
    fetchKnowledges();
    console.log(fetchKnowledges);
  }, [fetchKnowledges]);

  return (
    <MainContainer>
      {!relatedList && <Header title={"Knowledge"} />}
      {!relatedList && (
        <TableHeader
          searchPlaceholderText={strings.apKnowledge.search}
          onSearchPress={onSearch}
          clearSearch={clearSearch}
        />
      )}
      {loading ? (
        <TableListSkeleton />
      ) : (
        <>
          <Table
            emptyListMessage={emptyListMessage}
            data={articles.data}
            columns={columns}
            page={metaData?.currentPage || 1}
            pageSize={metaData?.limit || 10}
            onClickTableRow={onClickKnowledge}
          />
          <Pagination
            currentPage={articles?.metaData?.currentPage || 1}
            totalCount={articles?.metaData?.totalItems || 0}
            pageSize={articles?.metaData?.limit}
            onPageChange={handleOnPageClickAll}
          />
        </>
      )}
    </MainContainer>
  );
};
