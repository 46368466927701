import styled from "styled-components";

import { colors, fonts, fs } from "../../theme";

interface StylesProps {
  container: any;
  button: any;
  block?: any;
  sizeInfo?: any;
  inactive?: any;
  type?: any;
}

const styles: StylesProps = {
  container: styled.div<StylesProps>`
    display: ${(props) => (props.block ? "flex" : "")};
  `,
  button: styled.button<StylesProps>`
    flex: 1;
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    border: none;
    padding: ${(props) =>
      `${props.sizeInfo * 12}px ${props.sizeInfo * 20}px ${props.sizeInfo * 12}px ${
        props.sizeInfo * 20
      }px`};
    font-size: ${(props) => `${props.sizeInfo} * 12px`};
    font-family: ${fonts.Poppins};
    opacity: ${(props) => (props.disabled || props.inactive ? 0.6 : 1)};
    color: ${(props) =>
      props.disabled || props.inactive ? colors.disabledButtonTextColor : colors.buttonTextColor};
    border-radius: 0.4rem;
    background-color: ${(props) => (props.type === "cancel" ? colors.error : colors.blue)};
  `,
};

export default styles;
