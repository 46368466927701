import Types from "./actionType";
import {
  FetchSPSProjectPayload,
  CreateSPSProject,
  CreateSPSProjectPayload,
  FetchSPSProject,
  FetchSPSProjectError,
  FetchSPSProjectSuccess,
  UpdateSPSProject,
  UpdateSPSProjectPayload,
  SPSProjectListWithMetaData,
  FetchSPSProjectByIdPayload,
  FetchSPSProjectById,
  DeleteSPSProjectPayload,
  DeleteSPSProject,
} from "./types";

export const fetchSPSProjectList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSProjectPayload): FetchSPSProject => ({
  type: Types.FETCH_SPS_PROJECTS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSProjectById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSProjectByIdPayload): FetchSPSProjectById => ({
  type: Types.FETCH_SPS_PROJECTS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSProjectListSuccess = (
  data: SPSProjectListWithMetaData,
): FetchSPSProjectSuccess => ({
  type: Types.SUCCESS_FETCH_SPS_PROJECTS,
  payload: {
    data,
  },
});

export const fetchSPSProjectError = (error?: string): FetchSPSProjectError => ({
  type: Types.ERROR_FETCH_SPS_PROJECTS,
  payload: { error },
});

export const createSPSProject = ({
  data,
  onSuccess,
  onError,
}: CreateSPSProjectPayload): CreateSPSProject => ({
  type: Types.CREATE_SPS_PROJECTS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSProject = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSProjectPayload): UpdateSPSProject => ({
  type: Types.UPDATE_SPS_PROJECTS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSProject = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSProjectPayload): DeleteSPSProject => ({
  type: Types.DELETE_SPS_PROJECTS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
