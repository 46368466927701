/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_ACH = "FETCH_ACADEMY_ACH",
  FETCH_ACADEMY_ACH_BY_ID = "FETCH_ACADEMY_ACH_BY_ID",
  SUCCESS_FETCH_ACADEMY_ACH = "SUCCESS_FETCH_ACADEMY_ACH",
  ERROR_FETCH_ACADEMY_ACH = "ERROR_FETCH_ACADEMY_ACH",
  CREATE_ACADEMY_ACH = "CREATE_ACADEMY_ACH",
  UPDATE_ACADEMY_ACH = "UPDATE_ACADEMY_ACH",
  DELETE_ACADEMY_ACH = "DELETE_ACADEMY_ACH",
}

export default Types;
