import React, { useMemo } from "react";

import { useAppSelector } from "../../redux/hooks";
import { ChecklistTemplateAttributes, SelectOptions } from "../../types";

interface ControllerConfigs {
  departmentOptions: SelectOptions[];
  knowledgebaseOptions: SelectOptions[];
  companyOptions: SelectOptions[];
  NewsletterResourcesOptions: SelectOptions[];
  countryOptions: SelectOptions[];
  currencyOptions: SelectOptions[];
  variableSetsOptions: SelectOptions[];
  subCategoriesOptions: SelectOptions[];
  groupsOptions: SelectOptions[];
  employeesOptions: SelectOptions[];
  usersOptions: SelectOptions[];
  designationsOptions: SelectOptions[];
  academyCoursesOptions: SelectOptions[];
  academyCandidatesOptions: SelectOptions[];
  academyCandidateLeadsOptions: SelectOptions[];
  academBatchesOptions: SelectOptions[];
  academyCandidateEnrollmentsOptions: SelectOptions[];
  academyStudentProfileOptions: SelectOptions[];
  checklistTemplatesOptions: SelectOptions[];
  spsAccountsOptions: SelectOptions[];
  spsDealsOptions: SelectOptions[];
  spsProjectOptions: SelectOptions[];
  modulesOptions: SelectOptions[];
  CampaignsAssetsOptions: SelectOptions[];
  SPSSalesProfileOptions: SelectOptions[];
  dashboardAdministrationOptions: SelectOptions[];
  companyContactOptions: SelectOptions[];
  dashboardTabsOptions: SelectOptions[];
  dashboardWidgetsOptions: SelectOptions[];
  categoriesOptions: SelectOptions[];
  workspaceOptions: SelectOptions[];
  emailTemplatesOptions: SelectOptions[];
  CampaignsOptions: SelectOptions[];
}

export const useControllerConfigs = (): ControllerConfigs => {
  const dashboardAdministration = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.dashboardAdministration,
  );
  const dashboardTabs = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.dashboardTabs,
  );
  const dashboardWidgets = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.dashboardWidgets,
  );
  const departments = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.departments,
  );
  const knowledgebases = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.knowledgebases,
  );
  const academyStudentProfile = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.academyStudentProfile,
  );
  const academyCourses = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.academyCourses,
  );
  const academyCandidateLeads = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.academyCandidatesLeads,
  );
  const academyCandidates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.academyCandidates,
  );
  const academyCandidateEnrollments = useAppSelector(
    (state) =>
      state.ApplicationReducer.configurations.controllerConfigs.academyCandidateEnrollments,
  );
  const checklistTemplates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.checklistTemplates,
  );
  const academBatches = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.academyBatches,
  );
  const companies = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.company,
  );
  const currency = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.currency,
  );
  const designations = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.designations,
  );
  const employees = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.employees,
  );
  const countries = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.country,
  );
  const groups = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.groups,
  );
  const spsAccounts = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.spsAccounts,
  );
  const spsDeals = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.spsDeals,
  );
  const spsProject = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.spsProjects,
  );
  const modules = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.platformModules,
  );
  const users = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.users,
  );
  const SPSSalesProfile = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.spsSalesProfile,
  );
  const categories = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.categories,
  );
  const subCategories = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.subCategories,
  );
  const companyContacts = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.companyContact,
  );
  const NewsletterResources = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.NewsletterResources,
  );
  const variableSets = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.variableSets,
  );
  const workspace = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.workspaces,
  );
  const CampaignsAssets = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.campaignsAssets,
  );
  const Campaigns = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.Campaigns,
  );
  const emailTemplates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.workspaces,
  );

  const companyContactOptions = useMemo(() => {
    const companyContactAttributes = companyContacts.map((item) => {
      return {
        label: item.label,
        value: item._id,
      };
    });
    return companyContactAttributes;
  }, [companyContacts]);

  const dashboardAdministrationOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    dashboardAdministration?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [dashboardAdministration]);

  const dashboardTabsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    dashboardTabs?.forEach((item) => {
      // if (item.active) {
      data.push({
        label: item.label || "",
        value: item._id,
      });
      // }
    });
    return data;
  }, [dashboardTabs]);

  const CampaignsAssetsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    CampaignsAssets?.forEach((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [CampaignsAssets]);

  const CampaignsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    Campaigns?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [Campaigns]);

  const NewsletterResourcesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    NewsletterResources?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [NewsletterResources]);

  const dashboardWidgetsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    dashboardWidgets?.forEach((item) => {
      // if (item.active) {
      data.push({
        label: item.label || "",
        value: item._id,
      });
      // }
    });
    return data;
  }, [dashboardWidgets]);

  const categoriesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    categories?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [categories]);

  const subCategoriesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    subCategories?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [subCategories]);

  const departmentOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    departments?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [departments]);

  const SPSSalesProfileOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    SPSSalesProfile?.forEach((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [SPSSalesProfile]);

  const modulesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    modules?.forEach((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [modules]);

  const spsProjectOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    spsProject?.forEach((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [spsProject]);

  const spsAccountsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    spsAccounts?.map((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [spsAccounts]);

  const spsDealsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    spsDeals?.map((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [spsDeals]);

  const knowledgebaseOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    knowledgebases?.map((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [knowledgebases]);

  const academyStudentProfileOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academyStudentProfile?.map((item) => {
      // if (item.active) {
      data.push({
        label: item?.studentName || "",
        value: item._id,
      });
      // }
    });
    return data;
  }, [academyStudentProfile]);
  const checklistTemplatesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    checklistTemplates?.map((item) => {
      // if (item.active) {
      data.push({
        label: item?.name || "",
        value: item._id,
      });
      // }
    });
    return data;
  }, [checklistTemplates]);
  const academyCoursesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academyCourses?.map((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [academyCourses]);
  const academyCandidateLeadsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academyCandidateLeads?.map((item) => {
      data.push({
        label: item?.firstName && item?.lastName ? `${item?.firstName} ${item?.lastName}` : "",
        value: item._id,
      });
    });
    return data;
  }, [academyCandidateLeads]);
  const academyCandidatesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academyCandidates?.map((item) => {
      data.push({
        label: item?.firstName && item?.lastName ? `${item?.firstName} ${item?.lastName}` : "",
        value: item._id,
      });
    });
    return data;
  }, [academyCandidates]);
  const academyCandidateEnrollmentsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academyCandidateEnrollments?.map((item) => {
      data.push({
        label: item?.studentName,
        value: item._id,
      });
    });
    return data;
  }, [academyCandidateEnrollments]);
  const academBatchesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    academBatches?.map((item) => {
      data.push({
        label: item?.batchNumber || "",
        value: item._id,
      });
    });
    return data;
  }, [academBatches]);

  const designationsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    designations?.map((item) => {
      if (item.active) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [designations]);
  const currencyOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    currency?.map((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [currency]);
  const companyOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    companies?.map((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [companies]);

  const groupsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    groups?.map((item) => {
      if (item.active) {
        data.push({
          label: item.name || item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [groups]);

  const usersOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    users?.map((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [users]);

  const countryOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    countries?.forEach((item) => {
      if (item.active !== false) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [countries]);

  const variableSetsOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    variableSets?.forEach((item) => {
      if (item.active !== false) {
        data.push({
          label: item.label || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [variableSets]);

  const employeesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    employees?.map((item) => {
      data.push({
        label: item.label || "",
        value: item._id,
      });
    });
    return data;
  }, [employees]);

  const workspaceOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    workspace?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [workspace]);

  const emailTemplatesOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    workspace?.forEach((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [workspace]);

  return {
    modulesOptions,
    CampaignsOptions,
    CampaignsAssetsOptions,
    dashboardAdministrationOptions,
    companyContactOptions,
    dashboardTabsOptions,
    dashboardWidgetsOptions,
    NewsletterResourcesOptions,
    spsProjectOptions,
    subCategoriesOptions,
    spsAccountsOptions,
    spsDealsOptions,
    SPSSalesProfileOptions,
    departmentOptions,
    checklistTemplatesOptions,
    knowledgebaseOptions,
    academyStudentProfileOptions,
    companyOptions,
    academyCoursesOptions,
    academBatchesOptions,
    groupsOptions,
    usersOptions,
    countryOptions,
    employeesOptions,
    designationsOptions,
    currencyOptions,
    academyCandidateLeadsOptions,
    academyCandidatesOptions,
    academyCandidateEnrollmentsOptions,
    categoriesOptions,
    variableSetsOptions,
    workspaceOptions,
    emailTemplatesOptions,
  };
};
