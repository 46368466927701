import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  BankDetailsList,
  AddBankDetails,
  UpdateBankDetails,
  FindBankDetails,
  DeleteBankDetails,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchBankDetailsListSuccess, fetchBankDetailsError } from "./actions";
import Types from "./actionType";
import {
  CreateBankDetailsuccess,
  CreateBankDetails as CreateBankDetailsType,
  BankDetailsSuccess,
  UpdateBankDetails as UpdateBankDetailsType,
  FetchBankDetails,
  FetchBankDetailsById,
  BankDetailsByIdSuccess,
  DeleteBankDetails as DeleteBankDetailsType,
} from "./types";

function* createWorker(action: CreateBankDetailsType) {
  try {
    const options = {
      method: "POST",
      url: AddBankDetails,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateBankDetailsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* deleteWorker(action: DeleteBankDetailsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteBankDetails(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateBankDetailsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateBankDetailsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateBankDetails(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateBankDetailsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchBankDetails) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: BankDetailsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<BankDetailsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchBankDetailsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchBankDetailsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching bank details");
    }
  }
}

function* fetchBankDetailsById(action: FetchBankDetailsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindBankDetails(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<BankDetailsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching bank details");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_BANKDETAILS, createWorker);
  yield takeLatest(Types.UPDATE_BANKDETAILS, updateWorker);
  yield takeLatest(Types.DELETE_BANKDETAILS, deleteWorker);
  yield takeEvery(Types.FETCH_BANKDETAILS, fetchAllWorker);
  yield takeEvery(Types.FETCH_BANKDETAILS_BY_ID, fetchBankDetailsById);
}
