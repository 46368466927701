import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSDealsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    preferredFullName: {
      title: "Preferred Full Name",
      sortSupported: true,
      searchSupported: true,
      key: "preferredFullName",
    },
    legalAccountName: { title: "Legal Account Name", sortSupported: true, key: "legalAccountName" },
    SPSSalesProfile: {
      title: "SPS Sales Profile",
      key: "SPSSalesProfile",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {item?.SPSSalesProfile?.firstName} {item?.SPSSalesProfile?.lastName}{" "}
          </text>
        );
      },
    },
    accountOwner: {
      title: "Account Owner",
      key: "accountOwner",
      render: (item) => {
        return <text className='title'>{item?.accountOwner?.name || "-"}</text>;
      },
    },
    rate: { title: "Rate", sortSupported: true, key: "rate" },
    type: {
      title: "Type",
      sortSupported: true,
      key: "type",
      render: (item) => {
        return <text className='title'>{item?.type?.value || "-"}</text>;
      },
    },
    dealSource: {
      title: "Deal Source",
      sortSupported: true,
      key: "dealSource",
      render: (item) => {
        return <text className='title'>{item?.dealSource?.value || "-"}</text>;
      },
    },
    state: {
      title: "State",
      sortSupported: true,
      key: "state",
      render: (item) => {
        return <text className='title'>{item?.state?.value || "-"}</text>;
      },
    },
    endClient: {
      title: "End Client",
      sortSupported: true,
      key: "endClient",
      render: (item) => {
        return <text className='title'>{item?.endClient?.name || "-"}</text>;
      },
    },
    staffingName: {
      title: "Staffing Name",
      sortSupported: true,
      key: "staffingName",
      render: (item) => {
        return <text className='title'>{item?.staffingName?.name || "-"}</text>;
      },
    },
    platform: {
      title: "Platform",
      sortSupported: true,
      key: "platform",
      render: (item) => {
        return <text className='title'>{item?.platform?.value || "-"}</text>;
      },
    },
    c2cInfo: {
      title: "C2C Info",
      key: "c2cInfo",
    },
    interviewDetails: {
      title: "Interview Details",
      key: "interviewDetails",
    },
    recordingDriveLink: {
      title: "Recording Drive Link",
      key: "recordingDriveLink",
    },
    addressUsed: {
      title: "Address Used",
      key: "addressUsed",
    },
    bankAccountDetailsUsed: {
      title: "Bank Account Details Used",
      key: "bankAccountDetailsUsed",
    },
    dealGivenTo: {
      title: "Deal Given To",
      key: "dealGivenTo",
    },
    isTranscriptUploaded: {
      title: "Curriculum Attached",
      sortSupported: true,
      key: "isTranscriptUploaded",
      render: (item) => {
        return <div className='title'>{item.isTranscriptUploaded ? "Yes" : "No"}</div>;
      },
    },
    isJobDescriptionUploaded: {
      title: "Is Job Description Uploaded",
      sortSupported: true,
      key: "isJobDescriptionUploaded",
      render: (item) => {
        return <div className='title'>{item.isJobDescriptionUploaded ? "Yes" : "No"}</div>;
      },
    },
    isResumeUploaded: {
      title: "Is Resume Uploaded",
      sortSupported: true,
      key: "isResumeUploaded",
      render: (item) => {
        return <div className='title'>{item.isResumeUploaded ? "Yes" : "No"}</div>;
      },
    },
    potentialStartDate: {
      title: "Potential Start Date",
      sortSupported: true,
      key: "potentialStartDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.potentialStartDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    potentialClosingDate: {
      title: "Potential Closing Date",
      sortSupported: true,
      key: "potentialClosingDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.potentialClosingDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    name: {
      title: "Name",
      key: "name",
    },
    email: {
      title: "Email",
      key: "email",
    },
    phone: {
      title: "Phone",
      key: "phone",
    },
    verifiedBy: {
      title: "Verified By",
      key: "verifiedBy",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
