/* eslint-disable no-unused-vars */
enum Types {
  FETCH_LEAVES = "FETCH_LEAVES",
  FETCH_LEAVES_BY_ID = "FETCH_LEAVES_BY_ID",
  SUCCESS_FETCH_LEAVES = "SUCCESS_FETCH_LEAVES",
  ERROR_FETCH_LEAVES = "ERROR_FETCH_LEAVES",
  CREATE_LEAVES = "CREATE_LEAVES",
  UPDATE_LEAVES = "UPDATE_LEAVES",
}

export default Types;
