import React, { useEffect } from "react";

import Loader from "../../../../../components/Loader/loader";
import { fetchFilter } from "../../../../../redux";
import { FilterList as FilterListType } from "../../../../../redux/filters/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";
import { Header, Table, TableHeader } from "../../../../components";
import { useFilter } from "../hooks";

import { MainContainer } from "./styles";

export const FiltersList = () => {
  const { permissions, loading, onClickAdd, onClickItem } = useFilter();
  const data = useAppSelector((state) => state.FiltersReducer.list);
  const dispatch = useAppDispatch();

  const columns = [
    {
      title: "Serial",
      width: "10%",
      key: "serial",
    },
    {
      title: "Title",
      width: "25%",
      key: "title",
    },
    {
      title: "Table",
      width: "25%",
      render: (item: FilterListType) => {
        return <div className='title'>{item.table.toUpperCase()}</div>;
      },
    },
    {
      title: "Description",
      width: "30%",
      key: "description",
    },
    {
      title: "Active",
      width: "10%",
      key: "active",
      render: (item: FilterListType) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchFilter());
  }, [dispatch]);

  return (
    <MainContainer>
      <Header title={strings.headerTitle.filters} />
      <TableHeader
        rightButton={strings.common.add}
        rightButtonPress={onClickAdd}
        rightButtonWidth='69px'
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={data}
            columns={columns}
            page={1}
            pageSize={10}
            onClickTableRow={onClickItem}
            emptyListMessage={strings.common.noRecordsFound}
          />
        </>
      )}
    </MainContainer>
  );
};
