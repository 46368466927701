import React, { useState } from "react";

import { images, strings } from "../../../theme";

import {
  Container,
  Title,
  AccordionContainer,
  Item,
  Row,
  ItemTitle,
  Icon,
  AnimationDiv,
  Accordianmessage,
  NoData,
} from "./styles";

const Accordion = ({ title, data, width = "100%" }) => {
  const [current, setCurrent] = useState(false);

  const handleAccordion = (e) => {
    const index = e.target.getAttribute("data-index");
    if (typeof current === "number") {
      setCurrent(false);
      return;
    }
    setCurrent(Number(index));
  };

  return (
    <Container width={width}>
      <Title>{title}</Title>
      {Array.isArray(data) && data.length ? (
        <AccordionContainer>
          {data.map((item, index) => {
            const showHide = typeof current === "number" ? current === index : true;
            if (showHide) {
              return (
                <Item key={index}>
                  <Row onClick={handleAccordion} data-index={index} active={current === index}>
                    <ItemTitle active={current === index}>{item.title}</ItemTitle>
                    <Icon data-index={index} className='arrow' src={images.arrowRight} />
                  </Row>
                  <AnimationDiv onClick={handleAccordion}>
                    <div className={`accordian-body ${current === index ? "active" : "inactive"}`}>
                      <Accordianmessage>{item.message}</Accordianmessage>
                    </div>
                  </AnimationDiv>
                </Item>
              );
            }
          })}
        </AccordionContainer>
      ) : (
        <NoData>{strings.common.noAnnouncementsAvailable}</NoData>
      )}
    </Container>
  );
};
export default Accordion;
