import styled from "styled-components";

import { fonts } from "../../services/theme";

export const TooltipText = styled.div`
  /* background: rgba(28, 56, 151, 0.9); */
  /* color: #fff;
  width: 200px;
  text-align: center;
  line-height: 44px;
  border-radius: 3px; */
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  font-family: ${fonts.font1};
  font-size: 10px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  /* width: 150px; */
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  z-index: 1;
  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 10px;
    top: -8px;
    position: absolute;
    border: 5px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  position: relative;
  z-index: 10;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 150px;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    }
  }
`;
