import React, { useEffect, useMemo, useState, useCallback } from "react";

import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { fetchAllKnowledge, fetchKnowledgeDashboard } from "../../../../../redux/knowledge/actions";
import { useTitle, timeSince } from "../../../../../services/methods";
import { strings, colors, images } from "../../../../../theme";
import { Search, KnowledgeCard, DashboardTable, Header, Button } from "../../../../components";
import { useKnowledgeList } from "../hooks/useKnowledgeApprovals";

import {
  Container,
  TopBanner,
  Title,
  SubTitle,
  CardsContainer,
  ArticlesContainer,
  ViewAll,
  SearchContainer,
} from "./styles";

const KnowledgeDashboard = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const knowledgeDashboard = useAppSelector((state) => state.KnowledgeReducer.dashboardData);
  const searchQuery = useAppSelector((state) => state.KnowledgeReducer.list.searchQuery);

  const { onChangeSearch, search, handleClearSearch } = useKnowledgeList({ searchQuery });

  const [mostViewedArticles, setMostViewedArticles] = useState([]);
  const [viewAll, setViewAll] = useState(false);

  const getArticles = useMemo(() => {
    const mostViewedArticles = knowledgeDashboard.mostViewedArticles.map((item) => {
      return {
        id: item?._id,
        title: item?.name,
        createdBy: item?.createdBy?.name,
        createdAt: timeSince(new Date(item?.createdAt)) + " ago",
        updatedAt: item?.updatedAt,
      };
    });

    return {
      mostViewedArticles,
    };
  }, [knowledgeDashboard.mostViewedArticles]);

  const navigateToCreateArticle = () => {
    navigate("/ep/knowledge/add");
  };

  const handleViewAll = () => {
    setViewAll(!viewAll);
  };

  const onSearch = useCallback(() => {
    navigate("/ep/knowledge/all", { state: { searchQuery: search } });
  }, [navigate, search]);

  useEffect(() => {
    const { mostViewedArticles } = getArticles;
    setMostViewedArticles(mostViewedArticles);
  }, [getArticles, props.published]);
  useEffect(() => {
    dispatch(fetchKnowledgeDashboard({}));
  }, [dispatch]);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && onSearch) {
        onSearch();
      }
    },
    [onSearch],
  );
  return (
    <Container>
      <Header title={strings.headerTitle.knowledgeDashboard} />
      <TopBanner>
        <Title>{strings.epKnowledge.knowledge}</Title>
        <SubTitle>{strings.epKnowledge.subtitle}</SubTitle>
        <SearchContainer>
          <Search
            containerClass={"ep-knowledge-dashboard-search-container"}
            inputClass='ep-knowledge-dashboard-input'
            inputProps={{
              placeholder: strings.epKnowledge.searchTopics,
              onChange: onChangeSearch,
              value: search,
              onKeyDown,
            }}
            clearSearch={handleClearSearch}
          />
          <Button title={strings.common.search} onClick={onSearch} />
        </SearchContainer>
      </TopBanner>
      <CardsContainer>
        {knowledgeDashboard.knowledgebases.map((item, index) => {
          if (viewAll || index <= 5) {
            return <KnowledgeCard key={index} item={item} index={index} />;
          }
        })}
      </CardsContainer>
      {knowledgeDashboard.knowledgebases.length > 6 && (
        <ViewAll onClick={handleViewAll}>
          {viewAll ? strings.common.collapse : strings.common.viewAll}
        </ViewAll>
      )}
      <ArticlesContainer>
        <DashboardTable
          title={strings.epKnowledge.recentlyUpdatedArticles}
          data={mostViewedArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!mostViewedArticles.length && strings.common.noArticles}
        />
        <DashboardTable
          title={strings.epKnowledge.mostViewedArticles}
          data={mostViewedArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!mostViewedArticles.length && strings.common.noArticles}
        />

        <DashboardTable
          title={strings.epKnowledge.staffPickedArticles}
          data={mostViewedArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!mostViewedArticles.length && strings.common.noArticles}
        />
      </ArticlesContainer>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.UserReducer.permissions,
  userId: state.UserReducer.id,
});

const mapDispatchToProps = {
  fetchAllKnowledge,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeDashboard);
