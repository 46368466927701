import styled from "styled-components";

import { fonts, fs, colors } from "../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => (props.titleColor ? props.titleColor : colors.primaryFontColor)};
  text {
    font-family: ${fonts.PoppinsMedium};
    font-size: ${fs.xxl};
  }
  align-items: center;
  margin-bottom: 1vh;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  .sync {
    margin-right: 25px;
    cursor: pointer;
  }
  .sync-animate {
    margin-right: 25px;
    cursor: pointer;
    -webkit-animation: spin 2s linear;
    -moz-animation: spin 2s linear;
    animation: spin 2s linear;
  }
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
`;
