/* eslint-disable no-unused-vars */
enum Types {
  FETCH_PLATFORM_MODULES = "FETCH_PLATFORM_MODULES",
  FETCH_PLATFORM_MODULES_BY_ID = "FETCH_PLATFORM_MODULES_BY_ID",
  SUCCESS_FETCH_PLATFORM_MODULES = "SUCCESS_FETCH_PLATFORM_MODULES",
  ERROR_FETCH_PLATFORM_MODULES = "ERROR_FETCH_PLATFORM_MODULES",
  CREATE_PLATFORM_MODULES = "CREATE_PLATFORM_MODULES",
  UPDATE_PLATFORM_MODULES = "UPDATE_PLATFORM_MODULES",
  DELETE_PLATFORM_MODULES = "DELETE_PLATFORM_MODULES",
}

export default Types;
