import Types from "./types";

export const createMedia = ({ data, onSuccess, onError }) => ({
  type: Types.MEDIA_CREATE,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateMedia = ({ id, data, onSuccess, onError }) => ({
  type: Types.MEDIA_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchAllMedia = ({ onSuccess, onError }) => ({
  type: Types.MEDIA_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchAllMediaSuccess = (list) => ({
  type: Types.MEDIA_FETCH_SUCCESS,
  payload: list,
});

export const mediaFilters = ({ field, value }) => ({
  type: Types.MEDIA_FILTER,
  payload: { field: field, value: value },
});
