import React from "react";

import { Layout } from "../../../presentationals";
import { TicketsDetails } from "../../../ScreensEmployees";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<TicketsDetails all={true} />}
      title={strings.headerTitle.ticketDetails}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
