import React, { useEffect, useMemo, memo } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { AnnouncementsListProps, ColumnsType } from "../../../../types";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useAnnouncementsList } from "./hooks";

const List = ({ relatedList }: AnnouncementsListProps) => {
  const announcements = useAppSelector((state) => state.AnnouncementsReducer.data);
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.announcements;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { list, metaData } = announcements;
  const {
    fetchAnnouncements,
    refreshList,
    listActionOptions,
    loading,
    handlePageChange,
    onClickAnnouncements,
    onClickAdd,
    handleClearSearch,
    search,
    onSearchChange,
    onSearch,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    announcementsHeaderButtons,
    announcementsFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    onSubmitDeleteRecord,
  } = useAnnouncementsList({
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const AnnouncementsColumns = {
      title: {
        title: "Title",
        sortSupported: true,
        key: "title",
      },
      shortDescription: {
        title: "Short Description",
        sortSupported: true,
        key: "shortDescription",
      },
      order: {
        title: "Order",
        sortSupported: true,
        key: "order",
      },
      description: {
        title: "Description",
        sortSupported: true,
        key: "description",
      },
      date: {
        title: "Date",
        sortSupported: true,
        key: "date",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.date || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </div>
          );
        },
      },
      type: {
        title: "Announcements Type",
        key: "type",
        render: (item) => {
          return <text className='title'>{item.type?.label || "-"}</text>;
        },
      },
      location: {
        title: "Location",
        sortSupported: true,
        key: "location",
      },
      host: {
        title: "Host",
        sortSupported: true,
        key: "host",
      },
      active: {
        title: "Active",
        sortSupported: true,
        key: "active",
        render: (item) => {
          return <div className='title'>{item.active ? "Yes" : "No"}</div>;
        },
      },
      message: {
        title: "Message",
        sortSupported: true,
        key: "message",
      },
      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </div>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        render: (item) => {
          return <div className='title'>{item?.createdBy?.name || "Admin"}</div>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",

        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </div>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",

        render: (item) => {
          return <div className='title'>{item?.updatedBy?.name || "Admin"}</div>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.fields?.length
      ? tableListConfigs?.fields
      : tableListDefaultSelectFields.announcements;
    fields?.forEach((item) => {
      const columnItem = AnnouncementsColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchAnnouncements({});
    // Do not include fetchAnnouncements to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.announcements_delete}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={announcementsHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.announcements}
      handleTableConfigs={handleTableConfigs}
      filters={announcementsFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.announcements
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickAnnouncements}
      headerTitle={strings.headerTitle.announcements}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apAnnouncements.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};

export const AnnouncementList = memo(List);
