import React from "react";

import { Layout } from "../../../presentationals";
import { SPSBGCheckController } from "../../../presentationals/screens/admin/SPSBGCheck/SPSBGCheckController";

const Route = () => {
  return <Layout activity={<SPSBGCheckController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APSPSBGCheckEdit = React.memo(Route);
