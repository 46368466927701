import React from "react";

import { Layout } from "../../../presentationals";
import { SubscriberList } from "../../../presentationals/screens/admin/Subscriber/SubscriberList";

const Route = () => {
  return <Layout activity={<SubscriberList />} admin={true} layout={"2"} />;
};

export const APSubscriberList = React.memo(Route);
