import Types from "./actionType";
import {
  FetchNewsletterResourcesPayload,
  CreateNewsletterResources,
  CreateNewsletterResourcesPayload,
  FetchNewsletterResources,
  FetchNewsletterResourcesError,
  FetchNewsletterResourcesSuccess,
  UpdateNewsletterResources,
  UpdateNewsletterResourcesPayload,
  NewsletterResourcesListWithMetaData,
  FetchNewsletterResourcesByIdPayload,
  FetchNewsletterResourcesById,
  DeleteNewsletterResourcesPayload,
  DeleteNewsletterResources,
} from "./types";

export const fetchNewsletterResourcesList = ({
  params,
  onSuccess,
  onError,
}: FetchNewsletterResourcesPayload): FetchNewsletterResources => ({
  type: Types.FETCH_NEWSLETTER_RESOURCES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchNewsletterResourcesById = ({
  id,
  onSuccess,
  onError,
}: FetchNewsletterResourcesByIdPayload): FetchNewsletterResourcesById => ({
  type: Types.FETCH_NEWSLETTER_RESOURCES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchNewsletterResourcesListSuccess = (
  data: NewsletterResourcesListWithMetaData,
): FetchNewsletterResourcesSuccess => ({
  type: Types.SUCCESS_FETCH_NEWSLETTER_RESOURCES,
  payload: {
    data,
  },
});

export const fetchNewsletterResourcesError = (error?: string): FetchNewsletterResourcesError => ({
  type: Types.ERROR_FETCH_NEWSLETTER_RESOURCES,
  payload: { error },
});

export const createNewsletterResources = ({
  data,
  onSuccess,
  onError,
}: CreateNewsletterResourcesPayload): CreateNewsletterResources => ({
  type: Types.CREATE_NEWSLETTER_RESOURCES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateNewsletterResources = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateNewsletterResourcesPayload): UpdateNewsletterResources => ({
  type: Types.UPDATE_NEWSLETTER_RESOURCES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteNewsletterResources = ({
  id,
  onSuccess,
  onError,
}: DeleteNewsletterResourcesPayload): DeleteNewsletterResources => ({
  type: Types.DELETE_NEWSLETTER_RESOURCES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
