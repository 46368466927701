import Types from "./actionType";
import { FilterActions, FilterState } from "./types";

const initialState: FilterState = {
  list: [],
  loading: false,
};

const Reducer = (state = initialState, action: FilterActions) => {
  switch (action.type) {
    case Types.FETCH_Filter_FILTER:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_Filter_FILTER:
      return {
        ...state,
        list: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default Reducer;
