/* eslint-disable no-unused-vars */
enum Types {
  FETCH_EMAIL_SCHEDULAR = "FETCH_EMAIL_SCHEDULAR",
  FETCH_EMAIL_SCHEDULAR_BY_ID = "FETCH_EMAIL_SCHEDULAR_BY_ID",
  SUCCESS_FETCH_EMAIL_SCHEDULAR = "SUCCESS_FETCH_EMAIL_SCHEDULAR",
  ERROR_FETCH_EMAIL_SCHEDULAR = "ERROR_FETCH_EMAIL_SCHEDULAR",
  CREATE_EMAIL_SCHEDULAR = "CREATE_EMAIL_SCHEDULAR",
  UPDATE_EMAIL_SCHEDULAR = "UPDATE_EMAIL_SCHEDULAR",
  DELETE_EMAIL_SCHEDULAR = "DELETE_EMAIL_SCHEDULAR",
}

export default Types;
