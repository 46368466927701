import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getEmployeesColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    firstName: {
      title: "First Name",
      sortSupported: true,
      searchSupported: true,
      key: "firstName",
    },
    middleName: {
      title: "Middle Name",
      sortSupported: true,
      key: "middleName",
    },
    lastName: {
      title: "Last Name",
      sortSupported: true,
      key: "lastName",
    },
    mobile: {
      title: "Mobile",
      sortSupported: true,
      key: "mobile",
    },
    employeeID: {
      title: "Employee ID",
      sortSupported: true,
      key: "employeeID",
    },
    personalEmail: {
      title: "Personal Email",
      sortSupported: true,
      key: "personalEmail",
    },
    officialEmail: {
      title: "Official Email",
      sortSupported: true,
      key: "officialEmail",
    },
    nickName: {
      title: "Preffered Name",
      sortSupported: true,
      key: "nickName",
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",
      render: (item) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
    alternateMobile: {
      title: "Alternative Phone",
      sortSupported: true,
      key: "alternateMobile",
    },
    generatePayrollOffshoreServices: {
      title: "Generate Payroll Offshore Services",
      key: "generatePayrollOffshoreServices",
      render: (item) => {
        return <text className='title'>{item.generatePayrollOffshoreServices ? "Yes" : "No"}</text>;
      },
    },
    employmentCompany: {
      title: "Company",
      key: "employmentCompany",
      render: (item) => {
        return <text className='title'>{item.employmentCompany?.company || "-"}</text>;
      },
    },
    payrollCompany: {
      title: "Payroll Company",
      key: "payrollCompany",
      render: (item) => {
        return <text className='title'>{item.payrollCompany?.company || "-"}</text>;
      },
    },
    employeeIDofPayrollCompany: {
      title: "Payroll Company's Employee Id",
      sortSupported: true,
      key: "employeeIDofPayrollCompany",
    },
    offboardType: {
      title: "Offboard Type",
      key: "offboardType",
      render: (item) => {
        return <text className='title'>{item.offboardType?.value || "-"}</text>;
      },
    },
    shirtSize: {
      title: "Shirt Size",
      key: "shirtSize",
      render: (item) => {
        return <text className='title'>{item.shirtSize?.value || "-"}</text>;
      },
    },
    dateHire: {
      title: "Hire Date",
      sortSupported: true,
      key: "dateHire",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.dateHire || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    dateJoining: {
      title: "Date of Joining",
      sortSupported: true,
      key: "dateJoining",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.dateJoining || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    probationStartDate: {
      title: "Probation Start date",
      sortSupported: true,
      key: "probationStartDate",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.probationStartDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    probationEndDate: {
      title: "Probation End date",
      sortSupported: true,
      key: "probationEndDate",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.probationEndDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    dateTermination: {
      title: "Termination Date",
      sortSupported: true,
      key: "dateTermination",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.dateTermination || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },

    employmentDuration: {
      title: "Employment Duration",
      sortSupported: true,
      key: "employmentDuration",
    },
    personalMartial: {
      title: "Marital Status",
      key: "personalMartial",
      render: (item) => {
        return <text className='title'>{item.personalDetails.personalMartial?.value || "-"}</text>;
      },
    },
    personalDOB: {
      title: "Date of Birth",
      sortSupported: true,
      key: "personalDOB",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.personalDOB || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    nationality: {
      title: "Nationality",
      key: "nationality",
      render: (item) => {
        return <text className='title'>{item.personalDetails.nationality?.value || "-"}</text>;
      },
    },
    personalGender: {
      title: "Gender",
      key: "personalGender",
      render: (item) => {
        return <text className='title'>{item.personalDetails.personalGender?.value || "-"}</text>;
      },
    },
    ethnicity: {
      title: "Ethnicity",
      key: "ethnicity",
      render: (item) => {
        return <text className='title'>{item.personalDetails.ethnicity?.value || "-"}</text>;
      },
    },
    race: {
      title: "Race",
      key: "race",
      render: (item) => {
        return <text className='title'>{item.personalDetails.race?.value || "-"}</text>;
      },
    },
    placeOfBirth: {
      title: "Place Of Birth",
      sortSupported: true,
      key: "placeOfBirth",
    },
    countryOfBirth: {
      title: "Country Of Birth",
      key: "countryOfBirth",
      render: (item) => {
        return <text className='title'>{item.personalDetails.countryOfBirth?.name || "-"}</text>;
      },
    },
    fatherName: {
      title: "Father's Name",
      sortSupported: true,
      key: "fatherName",
    },
    motherName: {
      title: "Mother's Name",
      sortSupported: true,
      key: "motherName",
    },
    addressLineOne: {
      title: "Address Line 1",
      sortSupported: true,
      key: "addressLineOne",
    },
    addressLineTwo: {
      title: "Address Line 2",
      sortSupported: true,
      key: "addressLineTwo",
    },
    state: {
      title: "State/Province",
      sortSupported: true,
      key: "state",
    },
    zipCode: {
      title: "Zip Code",
      sortSupported: true,
      key: "zipCode",
    },
    Country: {
      title: "Country",
      key: "Country",
      render: (item) => {
        return <text className='title'>{item.Country?.name || "-"}</text>;
      },
    },
    employmentDepartment: {
      title: "Employment Department",
      key: "employmentDepartment",
      render: (item) => {
        return <text className='title'>{item.employmentDepartment?.name || "-"}</text>;
      },
    },
    employmentType: {
      title: "Employment Type",
      key: "employmentType",
      render: (item) => {
        return <text className='title'>{item.employmentType?.value || "-"}</text>;
      },
    },
    employmentStatus: {
      title: "Employment Status",
      key: "employmentStatus",
      render: (item) => {
        return <text className='title'>{item.employmentStatus?.value || "-"}</text>;
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
