import React, { useCallback, useEffect, useState } from "react";

import { MdOutlineEdit, MdLockOutline } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Loader from "../../components/Loader/loader";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import Pagination from "../../presentationals/components/Pagination";
import { fetchUsers } from "../../redux";
import { useTitle } from "../../services/methods";
import { colors } from "../../theme";

import { MainContainer, AccessLevel } from "./Users.styles";

const Users = (props) => {
  const navigate = useNavigate();
  useTitle("Users List");

  const getUsers = useCallback(
    (page) => {
      const currentPage = props.metaData.currentPage || 1;
      const itemPage = typeof page === "number" ? page : currentPage;
      props.fetchUsers({
        page: itemPage,
      });
    },
    [props.metaData.currentPage],
  );

  useEffect(() => {
    if (!props.users.length) {
      handlePageChange(1);
    }
  }, [getUsers, props.users.length]);

  const handlePageChange = (page) => {
    getUsers(parseInt(page));
  };

  const getAccessLevelColor = useCallback((item) => {
    const { green, red, orange, purple } = colors.common;
    let color = green;
    let toolTip = "Employee Access";
    if (item.superAdmin) {
      color = red;
      toolTip = "Unity Admin";
    } else if (item.organisationAdmin) {
      color = orange;
      toolTip = "Organisation Admin";
    } else if (item.admin) {
      color = purple;
      toolTip = "Admin";
    }
    return {
      color,
      toolTip,
    };
  }, []);

  const renderBody = () => {
    let serial = 0;
    const data = props.users;
    return data.map((item, index) => {
      serial = serial + 1;
      const { color, toolTip } = getAccessLevelColor(item);
      return (
        <tr key={index} data-index={index}>
          <td align='center'>{serial}</td>
          <td>{item.name}</td>
          {props.isOnlyListingView && (
            <>
              <td align='center'>{item.email}</td>
              <td align='center'>
                <Tooltip element={<AccessLevel color={color} />} text={toolTip} copy={false} />
              </td>
            </>
          )}

          {props.permissions.users_edit && (
            <>
              <td align='center'>
                <Link to={`/ap/users/${item._id}/org/edit`} state={item}>
                  <MdLockOutline size={15} />
                </Link>
              </td>
              <td align='center'>
                <Link to={`/ap/users/${item._id}/user/edit`} state={item}>
                  <MdOutlineEdit size={15} />
                </Link>
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  return (
    <MainContainer>
      <Header
        title='Users'
        buttons={[
          {
            onPress: {
              event: navigate,
              params: "/ap/users/add",
            },
            visible: props.permissions.users_add,
            title: "Add",
          },
          {
            visible: true,
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/users",
            },
            visible: true,
          },
        ]}
        onSync={{
          event: getUsers,
          params: {},
        }}
      />
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <StyledTable width={"100%"}>
            <thead>
              <tr>
                <th align='center' width='5%'>
                  ID
                </th>
                <th align='left'>Name</th>
                {props.isOnlyListingView && (
                  <>
                    <th>Email</th>
                    <th>Access Level</th>
                  </>
                )}

                {props.permissions.users_edit && (
                  <>
                    <th>Organization</th>
                    <th>Edit</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </StyledTable>
          <Pagination
            currentPage={props.metaData.currentPage || 1}
            totalCount={props.metaData.totalItems || 0}
            pageSize={10}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.UsersReducer.loading,
  users: state.UsersReducer.users,
  metaData: state.UsersReducer.metaData,
  permissions: state.UserReducer.permissions,
  organisation: state.UserReducer.organisation,
});

const mapDispatchToProps = {
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
