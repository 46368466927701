import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  VariableSetsList,
  AddVariableSets,
  UpdateVariableSets,
  FindVariableSets,
  DeleteVariableSets,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchVariableSetsListSuccess, fetchVariableSetsError } from "./actions";
import Types from "./actionType";
import {
  CreateVariableSetsuccess,
  CreateVariableSets as CreateVariableSetsType,
  VariableSetsSuccess,
  UpdateVariableSets as UpdateVariableSetsType,
  FetchVariableSets,
  FetchVariableSetsById,
  VariableSetsByIdSuccess,
  DeleteVariableSets as DeleteVariableSetsType,
} from "./types";

function* createWorker(action: CreateVariableSetsType) {
  try {
    const options = {
      method: "POST",
      url: AddVariableSets,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateVariableSetsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateVariableSetsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateVariableSets(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateVariableSetsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchVariableSets) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: VariableSetsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<VariableSetsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchVariableSetsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchVariableSetsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Variable Sets");
    }
  }
}

function* fetchVariableSetsById(action: FetchVariableSetsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindVariableSets(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<VariableSetsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Variable Sets");
    }
  }
}

function* deleteWorker(action: DeleteVariableSetsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteVariableSets(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateVariableSetsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_VARIABLE_SETS, createWorker);
  yield takeLatest(Types.UPDATE_VARIABLE_SETS, updateWorker);
  yield takeEvery(Types.FETCH_VARIABLE_SETS, fetchAllWorker);
  yield takeEvery(Types.FETCH_VARIABLE_SETS_BY_ID, fetchVariableSetsById);
  yield takeEvery(Types.DELETE_VARIABLE_SETS, deleteWorker);
}
