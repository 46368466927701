import React, { useEffect, useState } from "react";

import moment from "moment-timezone";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { addComments, deleteComments, getComments } from "../../redux/employees/actions";
import { MomentDate } from "../../services/methods";

import styles from "./EmployeesComments.styles";

const EmployeesComments = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [formData, setFormData] = useState({
    date: MomentDate({ date: moment(), format: "YYYY-MM-DD" }),
    rating: "",
    feedback: "",
  });

  useEffect(() => {
    setData([]);
    setShowAdd(false);
    setFormData({
      date: MomentDate({ date: moment(), format: "YYYY-MM-DD" }),
      rating: "",
      feedback: "",
    });
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetch = () => {
    props.getComments({
      employeeId: params.id,
      onSuccess: (data) => {
        setData(data.filter((item) => item.active));
      },
      onError: (error) => {
        // console.log(error);
      },
    });
  };
  const onTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onDeleteSubmit = (id) => {
    if (id) {
      props.deleteComments({
        id: id,
        employeeId: params.id,
        onSuccess: (data) => {
          fetch();
        },
        onError: (error) => {
          // console.log(error);
        },
      });
    }
  };
  const onDelete = (e) => {
    const id = e.target.getAttribute("data-id");
    window.confirm(`Are you sure you want to delete this comment ?`)
      ? onDeleteSubmit(id)
      : onDeleteSubmit();
  };

  const onSubmitForm = () => {
    props.addComments({
      employeeId: params.id,
      date: formData.date,
      rating: formData.rating,
      feedback: formData.feedback,
      onSuccess: (data) => {
        fetch();
        setShowAdd(false);
        setFormData({ ...formData, rating: "", feedback: "" });
      },
      onError: (err) => {
        // console.log(err);
      },
    });
  };

  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>Comments</h2>
      </styles.Header>
      <Button
        onClick={() => setShowAdd((current) => !current)}
        block={true}
        label={!showAdd ? "Add New" : "Close Menu"}
      />
      {showAdd && (
        <styles.Item>
          <Input
            label={"Date"}
            type={"date"}
            name='date'
            value={formData.date}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          <Select
            name={"rating"}
            label={"Rating"}
            options={[1, 2, 3, 4, 5].map((value, index) => ({
              label: value,
              value: value,
            }))}
            value={formData.rating}
            onChange={onTextChange}
          />
          <Input
            label={"Feedback"}
            type={"input"}
            name='feedback'
            value={formData.feedback}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          <Button
            disabled={!formData.date || !formData.rating || !formData.feedback}
            onClick={onSubmitForm}
            block={true}
            label={"Submit"}
          />
        </styles.Item>
      )}
      {data.map((item, index) => {
        return (
          <styles.Item key={index}>
            <div>Date: {MomentDate({ date: item.date, format: "DD-MM-YYYY" })}</div>
            <div>Rating: {item.rating}</div>
            <div>{item.feedback}</div>
            <div data-id={item._id} onClick={onDelete}>
              Delete ?
            </div>
          </styles.Item>
        );
      })}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  //   employees: state.EmployeesReducer.employees,
  //   filters: state.EmployeesReducer.filters,
});

const mapDispatchToProps = {
  //   filterEmployees,
  getComments,
  addComments,
  deleteComments,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesComments);
