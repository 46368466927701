import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { NotificationsDefinitionsListProps } from "../../../../types/models/NotificationsDefinitions";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useNotificationsDefinitionsList } from "./hooks";

export const NotificationsDefinitionsList = memo(
  ({ relatedList }: NotificationsDefinitionsListProps) => {
    const NotificationsDefinitions = useAppSelector(
      (state) => state.NotificationsDefinitionsReducer.data,
    );
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const widgetId = searchParams.get("widgetId");
    const permissions = useAppSelector((state) => state.UserReducer.permissions);

    const { list, metaData } = NotificationsDefinitions;
    const {
      fetchNotificationsDefinitions,
      loading,
      handlePageChange,
      onClickNotificationsDefinitions,
      onClickAdd,
      onSearch,
      handleClearSearch,
      onSubmitDeleteRecord,
      search,
      onSearchChange,
      refreshList,
      listActionOptions,
      onFilterChange,
      onClickApplyFilter,
      handleSortList,
      handleTableConfigs,
      onClickRowSelection,
      allRowsSelected,
      selectedRows,
      filterLogicQuery,
      handleListActions,
      handleShowItemsPerPage,
      notificationsDefinationsFilterFields,
      exportSelectedRecords,
      exportAllRecords,
      columns,
      NotificationsDefinitionsConfigTableFields,
    } = useNotificationsDefinitionsList({
      relatedList,
      widgetId,
    });

    useEffect(() => {
      fetchNotificationsDefinitions({});
      // Do not include fetchNotificationsDefinitions to avoid multiple rerenders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <TableListV3
        exportSelectedRecords={exportSelectedRecords}
        exportAllRecords={exportAllRecords}
        isRelatedList={!!relatedList?.type}
        advancedList={!(relatedList?.type && relatedList.value)}
        handleShowItemsPerPage={handleShowItemsPerPage}
        handleListActions={handleListActions}
        filterLogicQuery={filterLogicQuery}
        selectedRows={selectedRows}
        allRowsSelected={allRowsSelected}
        onClickRowSelection={onClickRowSelection}
        tablePublicFields={tablePublicFields.notificationsDefinations}
        handleTableConfigs={handleTableConfigs}
        filters={notificationsDefinationsFilterFields}
        selectedConfigFields={NotificationsDefinitionsConfigTableFields}
        handleSortList={handleSortList}
        onClickApplyFilter={onClickApplyFilter}
        onFilterChange={onFilterChange}
        horizontalScroll={true}
        refreshList={refreshList}
        rightButton={strings.common.add}
        rightButtonPress={onClickAdd}
        onClickTableRow={onClickNotificationsDefinitions}
        onSubmitDeleteRecord={onSubmitDeleteRecord}
        deleteRecordEnabled={permissions?.task_delete}
        headerTitle={strings.headerTitle.notificationsDefinations}
        list={list}
        columns={columns}
        metaData={metaData}
        onPageChange={handlePageChange}
        loading={loading}
        searchPlaceholder={strings.apNotificationsDefinitions.search}
        onSearch={onSearch}
        clearSearch={handleClearSearch}
        searchValue={search}
        onSearchChange={onSearchChange}
        listActionOptions={listActionOptions}
      />
    );
  },
);
