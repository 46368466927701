import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { QueryBuilder, defaultValidator } from "react-querybuilder";
import { useLocation, useParams } from "react-router-dom";

import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { FormTitle, QueryBuilderContainer, Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useKanbanConfigController } from "./hooks";

export const KanbanConfigController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { groupsOptions, usersOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    getTableFields,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchKanbanConfig,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    getTableFilters,
    query,
    handleQuerychange,
    formTitle,
    formSteps,
    formRelatedList,
    TableList,
  } = useKanbanConfigController({ type, id: params.id, attachmentsPickerRef });
  const currentKanbanConfig = data;

  const {
    workLogs,
    attachments,
    title,
    active,
    description,
    groups,
    users,
    table,
    columnField,
    displayFields,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchKanbanConfig();
    }
    // Do not include fetchKanbanConfig to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Title"}
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Desctiption'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              rows: 14,
              required: description.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    onTextChange,
    title.error,
    title.required,
    title.value,
  ]);

  const renderAccess = useMemo(() => {
    return (
      <React.Fragment>
        <Select
          width={"100%"}
          name={"groups"}
          label={"Groups"}
          options={groupsOptions}
          isMulti
          value={groups.value}
          onChange={onTextChange}
          mandatory={groups.required}
          error={groups.error}
        />
        <Select
          width={"100%"}
          name={"users"}
          label={"Users"}
          options={usersOptions}
          isMulti
          value={users.value}
          onChange={onTextChange}
          mandatory={users.required}
          error={users.error}
        />{" "}
        <Select
          width={"33%"}
          name={"table"}
          label={"Table"}
          options={TableList}
          value={table.value}
          onChange={onTextChange}
          mandatory={table.required}
          error={table.error}
        />
        <Select
          width={"33%"}
          name={"columnField"}
          label={"Column Field"}
          options={TableList}
          value={columnField.value}
          onChange={onTextChange}
          mandatory={table.required}
          error={table.error}
        />
        <Select
          width={"100%"}
          name={"displayFields"}
          label={"Display Fields"}
          options={getTableFields}
          isMulti
          value={displayFields?.value}
          onChange={onTextChange}
          mandatory={displayFields?.required}
          error={displayFields?.error}
        />
        <QueryBuilderContainer>
          <FormTitle>{"Condition Builder"}</FormTitle>
          <QueryBuilder
            translations={{
              addRule: {
                label: "Add Rule",
              },
              addGroup: {
                label: "Add Group",
              },
              removeRule: {
                label: "Delete Rule",
              },
              removeGroup: {
                label: "Delete Group",
              },
            }}
            fields={getTableFilters}
            query={query}
            onQueryChange={handleQuerychange}
            addRuleToNewGroups
            debugMode
            independentCombinators
            showCombinatorsBetweenRules
            validator={defaultValidator}
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          />
        </QueryBuilderContainer>
      </React.Fragment>
    );
  }, [
    TableList,
    columnField.value,
    displayFields?.error,
    displayFields?.required,
    displayFields?.value,
    getTableFields,
    getTableFilters,
    groups.error,
    groups.required,
    groups.value,
    groupsOptions,
    handleQuerychange,
    onTextChange,
    query,
    table.error,
    table.required,
    table.value,
    users.error,
    users.required,
    users.value,
    usersOptions,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentKanbanConfig?.createdBy !== "string"
        ? currentKanbanConfig?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentKanbanConfig?.updatedBy !== "string"
        ? currentKanbanConfig?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentKanbanConfig?.createdAt}
        updatedAt={currentKanbanConfig?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentKanbanConfig?.createdAt,
    currentKanbanConfig?.createdBy,
    currentKanbanConfig?.updatedAt,
    currentKanbanConfig?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.kanbanConfig.name} recordId={params.id} />,

    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.kanbanConfig.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,

    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderAccess;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderAccess,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
