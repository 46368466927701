import Types from "./actionType";
import {
  FetchAcademyCandidateLeadsPayload,
  CreateAcademyCandidateLeads,
  CreateAcademyCandidateLeadsPayload,
  AcademyCandidateLeadsList,
  FetchAcademyCandidateLeads,
  FetchAcademyCandidateLeadsError,
  FetchAcademyCandidateLeadsSuccess,
  UpdateAcademyCandidateLeads,
  UpdateAcademyCandidateLeadsPayload,
  AcademyCandidateLeadsListWithMetaData,
  FetchAcademyCandidateLeadsByIdPayload,
  FetchAcademyCandidateLeadsById,
  DeleteAcademyCandidateLeadsPayload,
  DeleteAcademyCandidateLeads,
} from "./types";

export const fetchAcademyCandidateLeadsList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyCandidateLeadsPayload): FetchAcademyCandidateLeads => ({
  type: Types.FETCH_ACADEMY_CANDIDATE_LEADS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidateLeadsById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyCandidateLeadsByIdPayload): FetchAcademyCandidateLeadsById => ({
  type: Types.FETCH_ACADEMY_CANDIDATE_LEADS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidateLeadsListSuccess = (
  data: AcademyCandidateLeadsListWithMetaData,
): FetchAcademyCandidateLeadsSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_CANDIDATE_LEADS,
  payload: {
    data,
  },
});

export const fetchAcademyCandidateLeadsError = (
  error?: string,
): FetchAcademyCandidateLeadsError => ({
  type: Types.ERROR_FETCH_ACADEMY_CANDIDATE_LEADS,
  payload: { error },
});

export const createAcademyCandidateLeads = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyCandidateLeadsPayload): CreateAcademyCandidateLeads => ({
  type: Types.CREATE_ACADEMY_CANDIDATE_LEADS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyCandidateLeads = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyCandidateLeadsPayload): UpdateAcademyCandidateLeads => ({
  type: Types.UPDATE_ACADEMY_CANDIDATE_LEADS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteAcademyCandidateLeads = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyCandidateLeadsPayload): DeleteAcademyCandidateLeads => ({
  type: Types.DELETE_ACADEMY_CANDIDATE_LEADS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
