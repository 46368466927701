import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSAccountContactColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    firstName: {
      title: "FirstName",
      sortSupported: true,
      searchSupported: true,
      key: "firstName",
    },

    lastName: {
      title: "LastName",
      sortSupported: true,
      key: "lastName",
    },
    email: {
      title: "Email",
      sortSupported: true,
      key: "email",
    },
    mobile: {
      title: "Mobile",
      sortSupported: true,
      key: "mobile",
    },
    company: {
      title: "Company",
      sortSupported: true,
      key: "company",
    },
    status: {
      title: "Status",
      key: "status",
      render: (item) => {
        return <text className='title'>{item?.status?.value || "-"}</text>;
      },
    },
    role: {
      title: "Role",
      key: "role",
      sortSupported: true,
    },
    associatedAccount: {
      title: "SPS Account",
      key: "associatedAccount",
      render: (item) => {
        return <text className='title'>{item?.associatedAccount?.name || "-"}</text>;
      },
    },
    alternativeEmail: {
      title: "Alternative Email",
      key: "alternativeEmail",
      sortSupported: true,
    },
    alternativeMobile: {
      title: "Alternative Mobile",
      key: "alternativeMobile",
      sortSupported: true,
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
