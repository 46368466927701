import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../../../redux/hooks";

interface useCategoriesConfig {
  permissions: object;
  onClickAdd: () => void;
  loading: boolean;
  onClickItem: (id: number) => void;
}

export const useFilter = (): useCategoriesConfig => {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.FiltersReducer.loading);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const onClickItem = useCallback(
    (id) => {
      navigate(`/ap/table/filters/${id}/edit`);
    },
    [navigate],
  );
  const onClickAdd = useCallback(() => navigate(`/ap/table/filters/add`), [navigate]);
  return {
    onClickAdd,
    loading,
    permissions,
    onClickItem,
  };
};
