import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { PIIDetailsControllerProps } from "../../../../types";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { usePIIDetailsController } from "./hooks";

export const PIIDetailsController = ({ type }: PIIDetailsControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchPIIDetails,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = usePIIDetailsController({ type, id: params.id, attachmentsPickerRef });
  const currentPIIDetails = data;

  const {
    workLogs,
    attachments,
    associatedEmployee,
    piiType,
    number,
    issueDate,
    expiryDate,
    active,
    requiredDocAttached,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchPIIDetails();
    }
    // Do not include fetchPIIDetails to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"piiType"}
            label={"PII Type"}
            options={getAttributValues("piiType")}
            value={piiType.value}
            onChange={onTextChange}
            mandatory={piiType.required}
            error={piiType.error}
          />
          <TextInput
            label='Number'
            type={"text"}
            width='48%'
            error={number.error}
            inputProps={{
              name: "number",
              value: number.value,
              onChange: onTextChange,
              required: number.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Issue Date'
            type={"date"}
            width='33%'
            error={issueDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "issueDate",
              value: issueDate.value,
              required: issueDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Expiry Date'
            type={"date"}
            width='33%'
            error={expiryDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "expiryDate",
              minDate: issueDate.value ? new Date(issueDate.value) : undefined,
              value: expiryDate.value,
              required: expiryDate.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"33%"}
            name={"requiredDocAttached"}
            label={"Required Doc Attached"}
            options={ActiveSelectOptions}
            value={requiredDocAttached.value}
            onChange={onTextChange}
            mandatory={requiredDocAttached.required}
            error={requiredDocAttached.error}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    associatedEmployee.error,
    associatedEmployee.required,
    associatedEmployee.value,
    employeesOptions,
    expiryDate.error,
    expiryDate.required,
    expiryDate.value,
    issueDate.error,
    issueDate.required,
    issueDate.value,
    number.error,
    number.required,
    number.value,
    onDateChange,
    onTextChange,
    piiType.error,
    piiType.required,
    piiType.value,
    requiredDocAttached.error,
    requiredDocAttached.required,
    requiredDocAttached.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentPIIDetails?.createdBy !== "string" ? currentPIIDetails?.createdBy?.name : "";
    const updatedBy =
      typeof currentPIIDetails?.updatedBy !== "string" ? currentPIIDetails?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentPIIDetails?.createdAt}
        updatedAt={currentPIIDetails?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentPIIDetails?.createdAt,
    currentPIIDetails?.createdBy,
    currentPIIDetails?.updatedAt,
    currentPIIDetails?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.piiDetails.name} recordId={params.id} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderWorklogs;
      case 3:
        return renderSystemData();
      case 4:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderRelatedEmployees,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
