import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchAcademyCandidatesList,
  getProfile,
  removeAppNotification,
  updateAcademyCandidates,
  updateTableConfigs,
  showInAppLoader,
  deleteAcademyCandidates,
} from "../../../../../redux";
import {
  AcademyCandidatesList,
  AcademyCandidatesListWithMetaData,
  DeleteAcademyCandidatesPayload,
  FetchAcademyCandidatesParams,
} from "../../../../../redux/AcademyCandidates/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchAcademyCandidatesProps,
  sortType,
  useAcademyCandidatesListProps,
  useAcademyCandidatesListReturnType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getAcademyCandidatesColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { edit } = AppRouteLinks.academyCandidates;
const defaultFilters: QueryBuilderType[] = [];

export const useAcademyCandidatesList = ({
  relatedList,
  widgetId,
}: useAcademyCandidatesListProps): useAcademyCandidatesListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.AcademyCandidatesReducer.data.metaData);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const academyCandidates = useAppSelector((state) => state.AcademyCandidatesReducer.data.list);

  const { employeesOptions, usersOptions, academyCoursesOptions, countryOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>("");

  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const academyCandidatesTableConfigFields = useMemo(() => {
    return tableConfiguration?.academyCandidates?.fields?.length
      ? tableConfiguration?.academyCandidates?.fields
      : tableListDefaultSelectFields.academyCandidates;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = academyCandidatesTableConfigFields;
    return getAcademyCandidatesColumns({ fields });
  }, [academyCandidatesTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: AcademyCandidatesList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.academyCandidates?.fields ||
        tableListDefaultSelectFields.academyCandidates;
      records = records?.map((item) => {
        const academyCandidateItem = { ...item };
        if (userPersonalizedFields?.includes("stage") && typeof item.stage !== "string") {
          academyCandidateItem.stage = item?.stage?.value;
        }
        if (userPersonalizedFields?.includes("country") && typeof item.country !== "string") {
          academyCandidateItem.country = item?.country?.name;
        }
        if (userPersonalizedFields?.includes("status") && typeof item.status !== "string") {
          academyCandidateItem.status = item?.status?.value;
        }
        if (userPersonalizedFields?.includes("course") && typeof item.course !== "string") {
          academyCandidateItem.course = item?.course?.name;
        }
        if (userPersonalizedFields?.includes("source") && typeof item.source !== "string") {
          academyCandidateItem.source = item?.source?.value;
        }
        if (
          userPersonalizedFields?.includes("admissionCounsellor") &&
          typeof item.admissionCounsellor !== "string"
        ) {
          academyCandidateItem.admissionCounsellor = item?.admissionCounsellor?.name || "";
        }
        if (
          userPersonalizedFields?.includes("graduationMajor") &&
          typeof item.graduationMajor !== "string"
        ) {
          academyCandidateItem.graduationMajor = item?.graduationMajor?.name || "";
        }
        if (
          userPersonalizedFields?.includes("graduationDegree") &&
          typeof item.graduationDegree !== "string"
        ) {
          academyCandidateItem.graduationDegree = item?.graduationDegree?.name || "";
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          academyCandidateItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          academyCandidateItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          academyCandidateItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          academyCandidateItem.updatedBy = item?.updatedBy?.name || "Admin";
        }

        delete academyCandidateItem?._id;
        return academyCandidateItem;
      });
      const academyCandidatesFields = tablePublicFields.academyCandidates;
      const columnHeaders = academyCandidatesFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "contact_web_portal");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.academyCandidates?.fields],
  );

  // ================ Fetch Academy Candidates Success/Error Methods ================
  const academyCandidatesFetchSuccess = useCallback(
    async (data: AcademyCandidatesListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const academyCandidatesFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  // ================ Fetch Academy Candidates Method ================

  const fetchAcademyCandidates = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchAcademyCandidatesProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.academyCandidates?.limit || 10;

      const params: FetchAcademyCandidatesParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (relatedList?.type) {
        params["relatedListType"] = relatedList?.type;
        params["relatedListValue"] = relatedList?.value;
      }
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      const payload = {
        params,
        onSuccess: (data) => academyCandidatesFetchSuccess(data, exportAll),
        onError: academyCandidatesFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchAcademyCandidatesList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.academyCandidates?.limit,
      sortKey,
      sortType,
      relatedList,
      academyCandidatesFetchError,
      dispatch,
      widgetsData,
      academyCandidatesFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = academyCandidates?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, academyCandidates, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchAcademyCandidates({ exportAll: true });
    },
    [fetchAcademyCandidates],
  );

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchAcademyCandidates({ page });
  }, [fetchAcademyCandidates, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchAcademyCandidates({ page: parseInt(page), search });
    },
    [fetchAcademyCandidates, search],
  );

  // ================ Add/Edit Methods ================

  const onClickAcademyCandidates = useCallback(
    (id: string) => {
      if (permissions?.academyCandidates_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.academyCandidates_view, notificationsId, dispatch],
  );

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchAcademyCandidates({ page: 1, search });
  }, [fetchAcademyCandidates, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchAcademyCandidates({ page: 1 });
  }, [fetchAcademyCandidates]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchAcademyCandidates({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchAcademyCandidates],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchAcademyCandidates({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchAcademyCandidates],
  );

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = academyCandidates?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteAcademyCandidatesPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchAcademyCandidates({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteAcademyCandidates(payload));
  }, [selectedRows, academyCandidates, dispatch, notificationsId, fetchAcademyCandidates]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allCandidates = academyCandidates?.map((item) => item?._id);
    return isEqualArrays(allCandidates, selectedRows);
  }, [selectedRows, academyCandidates]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allCandidates: string[] = [];
            academyCandidates?.forEach((item) => {
              if (item?._id) {
                allCandidates.push(item?._id);
              }
            });
            setSelectedRows(allCandidates);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, academyCandidates, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const academyCandidatesConfigLimit = tableConfiguration?.academyCandidates?.limit || 10;
      const academyCandidatesConfigs = {
        table: "academyCandidates",
        fields: fields,
        limit: academyCandidatesConfigLimit,
      };
      const payload = {
        tableConfiguration: academyCandidatesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidates({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Candidate table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(showInAppLoader(false));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(showInAppLoader(false));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      tableConfiguration?.academyCandidates?.limit,
      dispatch,
      fetchAcademyCandidates,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const academyCandidatesConfigFields = tableConfiguration?.academyCandidates?.fields;
      dispatch(showInAppLoader(true));
      const academyCandidatesConfigs = {
        table: "academyCandidates",
        fields: academyCandidatesConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: academyCandidatesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidates({ limit: count });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Academy Candidate table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchAcademyCandidates],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = academyCandidates?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCandidates({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Candidates updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating candidate",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateAcademyCandidates(payload));
    },
    [selectedRows, academyCandidates, dispatch, notificationsId, fetchAcademyCandidates],
  );

  // ================ Table Header Buttons ================

  const academyCandidatesHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "status",
      label: "Status",
      options: getAttributValues("academyCandidateStatus"),
      required: true,
    },
    {
      name: "source",
      label: "Source",
      options: getAttributValues("academyCandidateSource"),
      required: true,
    },
    {
      name: "course",
      label: "Course",
      options: academyCoursesOptions,
    },
    {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
    },
    {
      name: "stage",
      label: "Stage",
      options: getAttributValues("academyCandidateStages"),
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      required: true,
    },
    {
      name: "dob",
      label: "D.O.B",
      type: "date",
      required: true,
    },
    {
      name: "phone",
      label: "phone",
      type: "text",
      required: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      required: true,
    },
    {
      name: "country",
      label: "Country",
      options: countryOptions,
      required: true,
    },
    {
      name: "course",
      label: "Course",
      options: academyCoursesOptions,
      required: true,
    },
    {
      name: "graduationYear",
      label: "Graduation Year",
      type: "text",
      required: true,
    },
    {
      name: "graduationDegree",
      label: "Graduation Degree",
      options: getAttributValues("academyCandidateDegree"),
    },
    {
      name: "graduationMajor",
      label: "Graduation Major",
      options: getAttributValues("academyCandidateGraduationMajor"),
    },
    {
      name: "admissionCounsellor",
      label: "Admission Counsellor",
      options: employeesOptions,
      required: true,
    },
    {
      name: "linkedInURL",
      label: "LinkedIn URL",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const academyCandidatesFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const statusOptions = getFilterOptions(getAttributValues("academyCandidateStatus"), "Status");
    const sourceOptions = getFilterOptions(getAttributValues("academyCandidateSource"), "Source");
    const courseOptions = getFilterOptions(academyCoursesOptions, "Course");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const countryFilterOptions = getFilterOptions(countryOptions, "Country");
    const stageOptions = getFilterOptions(getAttributValues("academyCandidateStages"), "Country");
    const academyGraducationOptions = getFilterOptions(
      getAttributValues("academyCandidateGraduationMajor"),
      "Academy Candidate Graduation Major",
    );
    const academyGraduationDegreeOptions = getFilterOptions(
      getAttributValues("academyCandidateDegree"),
      "Academy Graduation Degree",
    );
    const fields: Field[] = [
      {
        name: "firstName",
        label: "First Name",
        placeholder: "Enter first name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter last name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "status",
        label: "Status",
        valueEditorType: "select",
        values: statusOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "course",
        label: "Course",
        valueEditorType: "select",
        values: courseOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "dob",
        label: "DOB",
        inputType: "date",
        operators: defaultOperators.filter((op) => ["=", ">", "<"].includes(op.name)),
      },
      {
        name: "admissionCounsellor",
        label: "Admission Counsellor",
        valueEditorType: "select",
        values: employeeyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "source",
        label: "Source",
        valueEditorType: "select",
        values: sourceOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "graduationMajor",
        label: "Graduation Major",
        valueEditorType: "select",
        values: academyGraducationOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "graduationYear",
        label: "Graduation Year",
        placeholder: "Enter graduation year",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "phone",
        label: "Phone",
        placeholder: "Enter phone",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "email",
        label: "email",
        placeholder: "Enter email",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "city",
        label: "City",
        placeholder: "Enter city",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "state",
        label: "State",
        placeholder: "Enter State",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "graduationDegree",
        label: "Graduation Degree",
        valueEditorType: "select",
        values: academyGraduationDegreeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "linkedInURL",
        label: "LinkedIn URL",
        placeholder: "Enter linkedInURL",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "stage",
        label: "Stage",
        valueEditorType: "select",
        values: stageOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [academyCoursesOptions, countryOptions, employeesOptions, usersOptions]);

  return {
    onClickAcademyCandidates,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    fetchAcademyCandidates,
    listActionOptions,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    columns,
    academyCandidatesTableConfigFields,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyCandidatesHeaderButtons,
    academyCandidatesFilterFields,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
  };
};
