import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { BenefitsControllerProps } from "../../../../types/models/Benefits";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useBenefitsController } from "./hooks";

export const BenefitsController = ({ type }: BenefitsControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchBenefits,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useBenefitsController({ type, id: params.id, attachmentsPickerRef });
  const currentBenefits = data;

  const {
    workLogs,
    attachments,
    associatedEmployee,
    details,
    amount,
    issueDate,
    expiryDate,
    active,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchBenefits();
    }
    // Do not include fetchBenefits to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"type"}
            label={"Type"}
            options={getAttributValues("benefitType")}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
          <TextInput
            label='Amount'
            type={"text"}
            width='48%'
            error={amount.error}
            inputProps={{
              name: "amount",
              value: amount.value,
              autoComplete: "off",
              type: "number",
              onChange: onTextChange,
              required: amount.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Issue Date'
            type={"date"}
            width='48%'
            error={issueDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "issueDate",
              value: issueDate.value,
              required: issueDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Expiry Date'
            type={"date"}
            width='48%'
            error={expiryDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "expiryDate",
              minDate: issueDate.value ? new Date(issueDate.value) : undefined,
              value: expiryDate.value,
              required: expiryDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label='Details'
          type={"textarea"}
          width='100%'
          error={details.error}
          inputProps={{}}
          textAreaProps={{
            name: "details",
            rows: 12,
            value: details.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: details.required,
          }}
        />
        {/* <Select
          width={"100%"}
          name={"benefitsType"}
          label={"Benefits Type"}
          options={getAttributValues("benefitsType")}
          isMulti
          value={benefitsType.value}
          onChange={onTextChange}
          mandatory={benefitsType.required}
          error={benefitsType.error}
        />
        */}
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    amount.error,
    amount.required,
    amount.value,
    associatedEmployee.error,
    associatedEmployee.required,
    associatedEmployee.value,
    details.error,
    details.required,
    details.value,
    employeesOptions,
    expiryDate.error,
    expiryDate.required,
    expiryDate.value,
    formData.type.error,
    formData.type.required,
    formData.type.value,
    issueDate.error,
    issueDate.required,
    issueDate.value,
    onDateChange,
    onTextChange,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentBenefits?.createdBy !== "string" ? currentBenefits?.createdBy?.name : "";
    const updatedBy =
      typeof currentBenefits?.updatedBy !== "string" ? currentBenefits?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentBenefits?.createdAt}
        updatedAt={currentBenefits?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentBenefits?.createdAt,
    currentBenefits?.createdBy,
    currentBenefits?.updatedAt,
    currentBenefits?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        collection={collections.certificationsAccreditations.name}
        recordId={params.id}
      />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.benefits.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderRelatedEmployees;
      default:
        return null;
    }
  }, [
    renderActivitylogs,
    renderRelatedEmployees,
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
