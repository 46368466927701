const Types = {
  RECRUITS_FETCH: "RECRUITS_FETCH",
  RECRUITS_FETCH_SUCCESS: "RECRUITS_FETCH_SUCCESS",
  RECRUITS_FETCH_ERROR: "RECRUITS_FETCH_ERROR",

  RECRUITS_ADD: "RECRUITS_ADD",
  RECRUITS_UPDATE: "RECRUITS_UPDATE",
  RECRUITS_DELETE: "RECRUITS_DELETE",
  RECRUITS_ROLLBACK: "RECRUITS_ROLLBACK",

  RECRUITS_UPDATE_FILTER: "RECRUITS_UPDATE_FILTER",

  RECRUITS_ASSIGNMENTS_ADD: "RECRUITS_ASSIGNMENTS_ADD",
};

export default Types;
