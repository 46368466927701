import React from "react";

import { Layout } from "../../../presentationals";
import { ChecklistTemplateController } from "../../../presentationals/screens";

const Route = () => {
  return (
    <Layout activity={<ChecklistTemplateController type={"edit"} />} admin={true} layout={"2"} />
  );
};

export const APChecklistTemplateEdit = React.memo(Route);
