import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  BugReportingList,
  AddBugReporting,
  UpdateBugReporting,
  FindBugReporting,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchBugReportingListSuccess, fetchBugReportingError } from "./actions";
import Types from "./actionType";
import {
  CreateBugReportinguccess,
  CreateBugReporting as CreateBugReportingType,
  BugReportingSuccess,
  UpdateBugReporting as UpdateBugReportingType,
  FetchBugReporting,
  FetchBugReportingById,
  BugReportingByIdSuccess,
} from "./types";

function* createWorker(action: CreateBugReportingType) {
  try {
    const options = {
      method: "POST",
      url: AddBugReporting,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateBugReportinguccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateBugReportingType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateBugReporting(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateBugReportinguccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchBugReporting) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: BugReportingList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<BugReportingSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchBugReportingListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchBugReportingError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Bug Reporting");
    }
  }
}

function* fetchBugReportingById(action: FetchBugReportingById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindBugReporting(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<BugReportingByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Bug Reporting");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_BUGREPORTING, createWorker);
  yield takeLatest(Types.UPDATE_BUGREPORTING, updateWorker);
  yield takeEvery(Types.FETCH_BUGREPORTING, fetchAllWorker);
  yield takeEvery(Types.FETCH_BUGREPORTING_BY_ID, fetchBugReportingById);
}
