import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { ServiceNowSolutionsListProps } from "../../../../types/models/ServiceNowSolutions";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useServiceNowSolutionsList } from "./hooks";

export const ServiceNowSolutionsList = memo(({ relatedList }: ServiceNowSolutionsListProps) => {
  const ServiceNowSolutions = useAppSelector((state) => state.ServiceNowSolutionsReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = ServiceNowSolutions;
  const {
    fetchServiceNowSolutions,
    loading,
    onSubmitDeleteRecord,
    handlePageChange,
    onClickServiceNowSolutions,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    columns,
    serviceNowSolutionsTableConfigFields,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    serviceNowSolutionsHeaderButtons,
    serviceNowSolutionsFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useServiceNowSolutionsList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchServiceNowSolutions({});
    // Do not include fetchServiceNowSolutions to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={serviceNowSolutionsHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      selectedConfigFields={serviceNowSolutionsTableConfigFields}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.serviceNowSolutions}
      handleTableConfigs={handleTableConfigs}
      filters={serviceNowSolutionsFilterFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.serviceNowSolutions_delete}
      onClickTableRow={onClickServiceNowSolutions}
      headerTitle={strings.headerTitle.serviceNowSolutions}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apServiceNowSolutions.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
