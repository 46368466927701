import Types from "./actionType";
import {
  FetchPIIDetailsPayload,
  CreatePIIDetails,
  CreatePIIDetailsPayload,
  PIIDetailsList,
  FetchPIIDetails,
  FetchPIIDetailsError,
  FetchPIIDetailsSuccess,
  UpdatePIIDetails,
  UpdatePIIDetailsPayload,
  PIIDetailsListWithMetaData,
  FetchPIIDetailsByIdPayload,
  FetchPIIDetailsById,
  DeletePIIDetailsPayload,
  DeletePIIDetails,
} from "./types";

export const fetchPIIDetailsList = ({
  params,
  onSuccess,
  onError,
}: FetchPIIDetailsPayload): FetchPIIDetails => ({
  type: Types.FETCH_PIIDETAILS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchPIIDetailsById = ({
  id,
  onSuccess,
  onError,
}: FetchPIIDetailsByIdPayload): FetchPIIDetailsById => ({
  type: Types.FETCH_PIIDETAILS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchPIIDetailsListSuccess = (
  data: PIIDetailsListWithMetaData,
): FetchPIIDetailsSuccess => ({
  type: Types.SUCCESS_FETCH_PIIDETAILS,
  payload: {
    data,
  },
});

export const fetchPIIDetailsError = (error?: string): FetchPIIDetailsError => ({
  type: Types.ERROR_FETCH_PIIDETAILS,
  payload: { error },
});

export const createPIIDetails = ({
  data,
  onSuccess,
  onError,
}: CreatePIIDetailsPayload): CreatePIIDetails => ({
  type: Types.CREATE_PIIDETAILS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updatePIIDetails = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdatePIIDetailsPayload): UpdatePIIDetails => ({
  type: Types.UPDATE_PIIDETAILS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deletePIIDetails = ({
  id,
  onSuccess,
  onError,
}: DeletePIIDetailsPayload): DeletePIIDetails => ({
  type: Types.DELETE_PIIDETAILS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
