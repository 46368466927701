import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { update } from "react-querybuilder";
import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createAcademyCandidateEnrollments,
  createAcademyStudentProfile,
  fetchAcademyCandidateEnrollmentsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateAcademyCandidateEnrollments,
  uploadFile,
} from "../../../../../redux";
import {
  AcademyCandidateEnrollmentsList,
  CreateAcademyCandidateEnrollmentsPayload,
  FetchAcademyCandidateEnrollmentsByIdPayload,
  UpdateAcademyCandidateEnrollmentsPayload,
} from "../../../../../redux/AcademyCandidateEnrollments/types";
import { CreateAcademyStudentProfilePayload } from "../../../../../redux/AcademyStudentProfile/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  AcademyCandidateEnrollmentsForm,
  SelectOptions,
  WorkLogItem,
  useAcademyCandidateEnrollmentsControllerProps,
  useAcademyCandidateEnrollmentsControllerReturnType,
  TableHeaderButton,
} from "../../../../../types";
import { DashboardAdministrationFormDataType } from "../../../../../types";
import { emailRegex, phoneRegex } from "../../../../../utils";

// ================ Contstants ================

const addFormSteps = ["Overview", "Academy Officers", "Payment Overview"];
const editFormSteps = [
  "Overview",
  "Academy Officers",
  "Payment Overview",
  "Payment Details",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };

const overViewFields = [
  "studentName",
  "studentID",
  "enrolledCourse",
  "candidateLead",
  "enrollementOwner",
  "batchStartDate",
  "batchSelected",
  "trainingDurations",
  "enrollmentStage",
  "studentOfficialEmail",
  "paymentStatus",
  "totalFees",
  "paymentTerms",
  "feesPaid",
  "taxedOnFees",
  "currency",
  "NBFCcharges",
  "netRevenue",
  "anyDiscountsGiven",
  "careerPath",
  "nextPaymentDueDate",
];
const paymentOverViewFields = ["currency"];

const academyOfficersFields = [
  "careerCounseller",
  "enrollmentSpecialist",
  "trainingCordinator",
  "corporateRelationManager",
];
export const useAcademyCandidateEnrollmentsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useAcademyCandidateEnrollmentsControllerProps): useAcademyCandidateEnrollmentsControllerReturnType => {
  const defaultFormData: AcademyCandidateEnrollmentsForm = {
    studentName: {
      value: "",
      required: true,
      error: "",
    },
    studentID: {
      value: "",
      required: false,
      error: "",
    },
    enrolledCourse: {
      value: "",
      required: true,
      error: "",
    },
    candidateLead: {
      value: "",
      required: true,
      error: "",
    },
    enrollementOwner: {
      value: "",
      required: false,
      error: "",
    },
    batchStartDate: {
      value: null,
      required: false,
      error: "",
    },
    batchSelected: {
      value: [],
      required: false,
      error: "",
    },
    trainingDurations: {
      value: "",
      required: false,
      error: "",
    },
    enrollmentStage: {
      value: "",
      required: false,
      error: "",
    },
    studentOfficialEmail: {
      value: "",
      required: false,
      error: "",
    },
    paymentStatus: {
      value: "",
      required: false,
      error: "",
    },
    totalFees: {
      value: "",
      required: false,
      error: "",
    },
    paymentTerms: {
      value: "",
      required: false,
      error: "",
    },
    feesPaid: {
      value: "",
      required: false,
      error: "",
    },
    taxedOnFees: {
      value: "",
      required: false,
      error: "",
    },
    NBFCcharges: {
      value: 0,
      required: false,
      error: "",
    },
    netRevenue: {
      value: "",
      required: false,
      error: "",
    },
    nextPaymentDueDate: {
      value: null,
      required: false,
      error: "",
    },
    paymentDetails: {
      value: [],
      required: false,
      error: "",
    },
    academyOfficers: {
      careerCounseller: {
        value: "",
        required: true,
        error: "",
      },
      enrollmentSpecialist: {
        value: "",
        required: true,
        error: "",
      },
      trainingCordinator: {
        value: "",
        required: false,
        error: "",
      },
      corporateRelationManager: {
        value: "",
        required: false,
        error: "",
      },
    },
    active: {
      value: false,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    currency: {
      value: "",
      required: true,
      error: "",
    },
    anyDiscountsGiven: {
      value: "",
      required: false,
      error: "",
    },
    careerPath: {
      value: "",
      required: false,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const list = useAppSelector((state) => state.AcademyCoursesReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AcademyCandidateEnrollmentsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<AcademyCandidateEnrollmentsList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return ["Course Offerings", "Candidate Lead", "Learner's Profile", "ACH Tasks"];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const netRevenueValue = useMemo(() => {
    let value = 0;
    if (formData.feesPaid.value) {
      value = parseFloat(formData.feesPaid.value);
    }
    if (formData.taxedOnFees.value) {
      value = value - parseFloat(formData.taxedOnFees.value);
    }
    if (formData.NBFCcharges.value) {
      value = value - formData.NBFCcharges.value;
    }
    return value;
  }, [formData.feesPaid.value, formData.NBFCcharges.value, formData.taxedOnFees.value]);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        case 1:
          academyOfficersFields.map((field) => {
            if (formData.academyOfficers?.[field].error) {
              count += 1;
            }
          });
          break;
        case 2:
          paymentOverViewFields.map((field) => {
            if (formData?.[field].error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      if (academyOfficersFields.includes(name)) {
        tempFormData = {
          ...tempFormData,
          academyOfficers: {
            ...tempFormData.academyOfficers,
            [name]: {
              ...tempFormData[name],
              value,
              error: "",
            },
          },
        };
      } else {
        tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (academyCandidateEnrollments: AcademyCandidateEnrollmentsList) => {
      const item = academyCandidateEnrollments;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["feesPaid"].includes(field) && academyCandidateEnrollments?.paymentDetails?.length) {
          const totalFees = academyCandidateEnrollments?.paymentDetails
            ?.flatMap((e) => e.amountPaid)
            ?.reduce((a, b) => a + b);
          value = totalFees;
          data[field].value = value;
        } else if (
          ["taxedOnFees"].includes(field) &&
          academyCandidateEnrollments?.paymentDetails?.length
        ) {
          const totalTax = academyCandidateEnrollments?.paymentDetails
            ?.flatMap((e) => e.taxedAmount)
            ?.reduce((a, b) => a + b);
          value = totalTax;
          data[field].value = value;
        } else if (["batchSelected"].includes(field)) {
          const tempValue = value.map((item) => {
            return { label: item?.batchNumber, value: item?._id };
          });
          value = tempValue;
          data[field].value = value;
        } else if (
          [
            "enrolledCourse",
            "preparedBy",
            "candidateLead",
            "currency",
            "paymentTerms",
            "paymentStatus",
          ].includes(field)
        ) {
          const tempValue = value?._id;
          value = tempValue;
        } else if (field === "academyOfficers") {
          let academyOfficers = { ...formData.academyOfficers };
          Object.keys(item.academyOfficers).map((childItem) => {
            academyOfficers = {
              ...academyOfficers,
              [childItem]: {
                ...academyOfficers[childItem],
                value: item.academyOfficers[childItem]?._id,
              },
            };
          });
          data.academyOfficers = academyOfficers;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchAcademyCandidateEnrollments = useCallback(() => {
    setLoading(true);
    const payload: FetchAcademyCandidateEnrollmentsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchAcademyCandidateEnrollmentsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      // if (item === "anyDriveURL" && validValue && !validator.isURL(validValue || "")) {
      //   valid = false;
      //   tempFormData = {
      //     ...tempFormData,
      //     [item]: {
      //       ...tempFormData[item],
      //       error: "Please enter valid URL",
      //     },
      //   };
      // } else
      if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    // Academy Officers
    let academyOfficers = { ...tempFormData.academyOfficers };
    Object.keys(tempFormData.academyOfficers).map((item) => {
      const currentField = tempFormData.academyOfficers[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        academyOfficers = {
          ...academyOfficers,
          [item]: {
            ...academyOfficers[item],
            error: "Required Field",
          },
        };
      }
    });
    tempFormData.academyOfficers = academyOfficers;

    setFormData(tempFormData);
    // if (formData.curriculamAttached.value && !formData.attachments.value.length) {
    //   valid = false;
    //   const notification: AppNotificationsType = {
    //     id: notificationsId + 1,
    //     title: "Upload the required attachment/document.",
    //     type: "warning",
    //   };
    //   dispatch(addAppNotification({ notification }));
    //   setTimeout(() => {
    //     dispatch(removeAppNotification({ notification }));
    //   }, 2000);
    // }
    return valid;
  }, [formData]);
  const handleConvertToStudent = useCallback(() => {
    const { studentName, studentOfficialEmail, studentID, enrolledCourse, academyOfficers } =
      formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateAcademyStudentProfilePayload = {
        data: {
          attachments: attachments,
          studentName: studentName.value,
          studentOfficialEmail: studentOfficialEmail.value,
          studentID: studentID.value,
          enrolledCourse: enrolledCourse.value,
          batches: [],
          studentTrainingCordinator: academyOfficers.trainingCordinator.value,
          associatedEnrollment: id,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "AcademyStudentProfile created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(`/ap/academy-learners-profile/${data?._id}`);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createAcademyStudentProfile(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, id, navigate]);

  const onSubmitAdd = useCallback(() => {
    const {
      studentName,
      anyDiscountsGiven,
      careerPath,
      studentID,
      enrolledCourse,
      candidateLead,
      enrollementOwner,
      batchStartDate,
      batchSelected,
      trainingDurations,
      enrollmentStage,
      studentOfficialEmail,
      paymentStatus,
      totalFees,
      paymentTerms,
      feesPaid,
      taxedOnFees,
      currency,
      NBFCcharges,
      netRevenue,
      nextPaymentDueDate,
      academyOfficers,
    } = formData;
    const { careerCounseller, corporateRelationManager, trainingCordinator, enrollmentSpecialist } =
      academyOfficers;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const batches = batchSelected.value.map((item) => String(item.value));

      const payload: CreateAcademyCandidateEnrollmentsPayload = {
        data: {
          attachments: attachments,
          studentName: studentName.value,
          anyDiscountsGiven: anyDiscountsGiven.value,
          careerPath: careerPath.value,
          studentID: studentID.value,
          enrolledCourse: enrolledCourse.value,
          candidateLead: candidateLead.value,
          enrollementOwner: enrollementOwner.value,
          batchStartDate: batchStartDate.value,
          batchSelected: batches,
          trainingDurations: trainingDurations.value,
          enrollmentStage: enrollmentStage.value,
          studentOfficialEmail: studentOfficialEmail.value,
          paymentStatus: paymentStatus.value,
          totalFees: totalFees.value,
          paymentTerms: paymentTerms.value,
          feesPaid: feesPaid.value,
          taxedOnFees: taxedOnFees.value,
          currency: currency.value,
          NBFCcharges: NBFCcharges.value,
          netRevenue: netRevenue.value,
          nextPaymentDueDate: nextPaymentDueDate.value,
          academyOfficers: {
            careerCounseller: careerCounseller.value,
            corporateRelationManager: corporateRelationManager.value,
            trainingCordinator: trainingCordinator.value,
            enrollmentSpecialist: enrollmentSpecialist.value,
          },
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "AcademyCandidateEnrollments created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createAcademyCandidateEnrollments(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      studentName,
      anyDiscountsGiven,
      careerPath,
      studentID,
      enrolledCourse,
      candidateLead,
      enrollementOwner,
      batchStartDate,
      batchSelected,
      trainingDurations,
      enrollmentStage,
      studentOfficialEmail,
      paymentStatus,
      totalFees,
      paymentTerms,
      feesPaid,
      taxedOnFees,
      currency,
      NBFCcharges,
      netRevenue,
      nextPaymentDueDate,
      academyOfficers,
    } = formData;
    const { careerCounseller, corporateRelationManager, trainingCordinator, enrollmentSpecialist } =
      academyOfficers;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const batches = batchSelected.value.map((item) => String(item.value));
      setLoading(true);
      const payload: UpdateAcademyCandidateEnrollmentsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          currency: currency.value,
          studentName: studentName.value,
          anyDiscountsGiven: anyDiscountsGiven.value,
          careerPath: careerPath.value,
          studentID: studentID.value,
          enrolledCourse: enrolledCourse.value,
          candidateLead: candidateLead.value,
          enrollementOwner: enrollementOwner.value,
          batchStartDate: batchStartDate.value,
          batchSelected: batches,
          trainingDurations: trainingDurations.value,
          enrollmentStage: enrollmentStage.value,
          studentOfficialEmail: studentOfficialEmail.value,
          paymentStatus: paymentStatus.value,
          totalFees: totalFees.value,
          paymentTerms: paymentTerms.value,
          feesPaid: feesPaid.value,
          taxedOnFees: taxedOnFees.value,
          NBFCcharges: NBFCcharges.value,
          netRevenue: netRevenue.value,
          nextPaymentDueDate: nextPaymentDueDate.value,
          academyOfficers: {
            careerCounseller: careerCounseller.value,
            corporateRelationManager: corporateRelationManager.value,
            trainingCordinator: trainingCordinator.value,
            enrollmentSpecialist: enrollmentSpecialist.value,
          },
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "AcademyCandidateEnrollments updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyCandidateEnrollments(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateAcademyCandidateEnrollmentsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "AcademyCandidateEnrollments updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateAcademyCandidateEnrollments(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.studentName || "";
    }
    return [strings.headerTitle.academyCandidateEnrollments, title];
  }, [type, data]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
      {
        title: "Convert to Learner",
        onClick: handleConvertToStudent,
        visible: type === "edit",
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type, handleConvertToStudent]);

  return {
    currentStepIndex,
    netRevenueValue,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCandidateEnrollments,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    formSteps,
    formRelatedList,
    formTitle,
    headerButtons,
  };
};
