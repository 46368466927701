import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSSalesProfileColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    firstName: {
      title: "FirstName",
      sortSupported: true,
      searchSupported: true,
      key: "firstName",
    },
    lastName: {
      title: "LastName",
      sortSupported: true,
      // searchSupported: true,
      key: "lastName",
    },
    gmail: {
      title: "Gmail",
      sortSupported: true,
      key: "gmail",
    },
    gvoice: {
      title: "Gvoice",
      sortSupported: true,
      key: "gvoice",
    },
    referenceUsed: {
      title: "Reference Used",
      sortSupported: true,
      key: "referenceUsed",
    },
    linkedin: {
      title: "Linkedin",
      sortSupported: true,
      key: "linkedin",
    },
    locationUsed: {
      title: "Location Used",
      sortSupported: true,
      key: "locationUsed",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
