import React from "react";

import { Layout } from "../../../presentationals";
import { VariablesList } from "../../../presentationals/screens/admin/Variables/VariablesList";

const Route = () => {
  return <Layout activity={<VariablesList />} admin={true} layout={"2"} />;
};

export const APVariablesList = React.memo(Route);
