import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import Input from "../../../../components/Input/input";
import { AcademyCourseSection } from "../../../../redux/AcademyCourses/types";
import { getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { AcademyCoursesControllerProps, ColumnsType, TableHeaderButton } from "../../../../types";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { AttachmentIcon, ControllerMessage, FormTitle, Row } from "../../../common/styles";
import {
  ActivityLogs,
  Notification,
  Popup,
  TableController,
  TableListV2,
  WorkLogs,
} from "../../../components";
import {
  TextInput,
  Select,
  SystemData,
  Button,
  TextEditor,
  MyTooltip,
  Checkbox,
} from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyBatchList } from "../AcademyBatch";
import { AcademyCandidateEnrollmentsList } from "../AcademyCandidateEnrollments/AcademyCandidateEnrollmentsList";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads";
import { AcademyCandidatesList } from "../AcademyCandidates";
import { AcademyStudentProfileList } from "../AcademyStudentProfile/AcademyStudentProfileList";

import { useAcademyCoursesController } from "./hooks";
import { ModalContainer, SectionModalContainer } from "./styles";

export const AcademyCoursesController = ({ type }: AcademyCoursesControllerProps) => {
  const params = useParams();
  const id = params.id;
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions } = useControllerConfigs();
  const {
    formSteps,
    formTitle,
    formRelatedList,
    currentStepIndex,
    headerButtons,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCourses,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    handleCheckbox,
    handleLearningPointsModal,
    sectionDescription,
    onSectionDescriptionChange,
    learningPointsModal,
    updateLearningPoints,
    sectionsData,
    sectionsModal,
    handleSectionsModal,
    onSectionTextChange,
    addCourseSection,
    deleteLearningPoints,
    updateCourseSection,
    deleteCourseSection,
  } = useAcademyCoursesController({ type, id, attachmentsPickerRef });

  const currentAcademyCourses = data;
  const { title, sectionDuration } = sectionsData;
  const {
    workLogs,
    attachments,
    name,
    description,
    active,
    technology,
    curriculamAttached,
    preparedBy,
    courseDuration,
    prerequisites,
    anyDriveURL,
    shortDescription,
    numberOfTopics,
    downloadableResources,
    learningPoints,
    learningPointItem,
    sections,
    cardBackgroundAttached,
    cardIconAttached,
    courseDetailsIconAttached,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyCourses();
    }
    // Do not include fetchAcademyCourses to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <ControllerMessage>
          Card Background, Card Icon, Course Details Icon attachments are required.
        </ControllerMessage>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
            textAreaProps={{}}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <Select
            name={"technology"}
            label={"Technology"}
            options={getAttributValues("technology")}
            value={technology.value}
            onChange={onTextChange}
            mandatory={technology.required}
            error={technology.error}
          />
          <Select
            name={"preparedBy"}
            label={"Course Prepared By"}
            options={employeesOptions}
            value={preparedBy.value}
            onChange={onTextChange}
            mandatory={preparedBy.required}
            error={preparedBy.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Number Of Topics'
            type={"text"}
            width='48%'
            error={numberOfTopics.error}
            inputProps={{
              name: "numberOfTopics",
              value: numberOfTopics.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: numberOfTopics.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Downloadable Resources'
            type={"text"}
            width='48%'
            error={downloadableResources.error}
            inputProps={{
              name: "downloadableResources",
              value: downloadableResources.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: downloadableResources.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label='Short Description'
          type={"textarea"}
          width='100%'
          error={shortDescription.error}
          textAreaProps={{
            name: "shortDescription",
            value: shortDescription.value,
            rows: 2,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescription.required,
          }}
        />
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
        <Row>
          <Select
            name={"curriculamAttached"}
            label={"Curriculum Attached"}
            options={ActiveSelectOptions}
            value={curriculamAttached.value}
            onChange={onTextChange}
            mandatory={curriculamAttached.required}
            error={curriculamAttached.error}
          />
          <TextInput
            label='Course Duration'
            type={"text"}
            error={courseDuration.error}
            inputProps={{
              name: "courseDuration",
              value: courseDuration.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: courseDuration.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label='Prerequisites'
          type={"text"}
          width='100%'
          error={prerequisites.error}
          inputProps={{
            name: "prerequisites",
            value: prerequisites.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: prerequisites.required,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label='Any Drive URL'
          type={"text"}
          width='100%'
          error={anyDriveURL.error}
          inputProps={{
            name: "anyDriveURL",
            value: anyDriveURL.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: anyDriveURL.required,
          }}
          textAreaProps={{}}
        />
        <Row>
          <Checkbox
            label={"Card Background Attached"}
            width='33%'
            error={cardBackgroundAttached.error}
            required={cardBackgroundAttached.required}
            onClick={handleCheckbox}
            value={cardBackgroundAttached.value}
            attributes={{
              "data-name": "cardBackgroundAttached",
            }}
          />
          <Checkbox
            label={"Card Icon Attached"}
            width='33%'
            error={cardIconAttached.error}
            required={cardIconAttached.required}
            onClick={handleCheckbox}
            value={cardIconAttached.value}
            attributes={{
              "data-name": "cardIconAttached",
            }}
          />
          <Checkbox
            label={"Course Details Icon Attached"}
            width='33%'
            error={courseDetailsIconAttached.error}
            required={courseDetailsIconAttached.required}
            onClick={handleCheckbox}
            value={courseDetailsIconAttached.value}
            attributes={{
              "data-name": "courseDetailsIconAttached",
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    anyDriveURL.error,
    anyDriveURL.required,
    anyDriveURL.value,
    cardBackgroundAttached.error,
    cardBackgroundAttached.required,
    cardBackgroundAttached.value,
    cardIconAttached.error,
    cardIconAttached.required,
    cardIconAttached.value,
    courseDetailsIconAttached.error,
    courseDetailsIconAttached.required,
    courseDetailsIconAttached.value,
    courseDuration.error,
    courseDuration.required,
    courseDuration.value,
    curriculamAttached.error,
    curriculamAttached.required,
    curriculamAttached.value,
    description.error,
    description.required,
    description.value,
    downloadableResources.error,
    downloadableResources.required,
    downloadableResources.value,
    employeesOptions,
    handleCheckbox,
    name.error,
    name.required,
    name.value,
    numberOfTopics.error,
    numberOfTopics.required,
    numberOfTopics.value,
    onTextChange,
    preparedBy.error,
    preparedBy.required,
    preparedBy.value,
    prerequisites.error,
    prerequisites.required,
    prerequisites.value,
    shortDescription.error,
    shortDescription.required,
    shortDescription.value,
    technology.error,
    technology.required,
    technology.value,
  ]);

  const renderLearningPoints = useCallback(() => {
    const columns: ColumnsType[] = [
      {
        title: "Learning Point",
        key: "value",
        width: "600px",
      },
      {
        title: "Actions",
        width: "100px",
        center: true,
        render: (item) => {
          return (
            <MyTooltip text='Delete'>
              <AttachmentIcon
                onClick={deleteLearningPoints}
                data-index={item?.value}
                src={images.deleteAttachment}
              />
            </MyTooltip>
          );
        },
      },
    ];
    const list = learningPoints.value.map((item, index) => {
      return { _id: index, value: item };
    });
    const buttons: TableHeaderButton[] = [
      {
        title: "Add",
        onClick: () => handleLearningPointsModal("add"),
        visible: true,
      },
    ];
    return (
      <>
        <TableListV2
          columns={columns}
          advancedList={false}
          headerButtons={buttons}
          loading={false}
          list={list}
          onClickTableRow={handleLearningPointsModal}
        />
        <Popup isOpen={!!learningPointsModal} closeModal={handleLearningPointsModal}>
          <ModalContainer>
            <FormTitle>
              {learningPointsModal === "add" ? "Add" : learningPointsModal === "edit" ? "Edit" : ""}
            </FormTitle>
            <Row>
              <TextInput
                label={"Learning Point"}
                type='textarea'
                width='100%'
                error={""}
                textAreaProps={{
                  name: "learningPointItem",
                  rows: 4,
                  value: learningPointItem.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: true,
                }}
              />
            </Row>
            <Button
              className='learning-point-modal-save-button'
              title={learningPointsModal === "add" ? strings.common.add : strings.common.save}
              onClick={updateLearningPoints}
              disabled={!learningPointItem.value}
            />
          </ModalContainer>
        </Popup>
      </>
    );
  }, [
    learningPoints.value,
    handleLearningPointsModal,
    learningPointsModal,
    learningPointItem.value,
    onTextChange,
    updateLearningPoints,
    deleteLearningPoints,
  ]);

  const renderSections = useCallback(() => {
    const columns: ColumnsType[] = [
      {
        title: "Title",
        key: "title",
        width: "200px",
      },
      {
        title: "Duration",
        key: "sectionDuration",
        width: "200px",
      },
      {
        title: "Actions",
        width: "100px",
        center: true,
        render: (item: AcademyCourseSection) => {
          return (
            <MyTooltip text='Delete'>
              <AttachmentIcon
                onClick={deleteCourseSection}
                data-index={item?._id}
                src={images.deleteAttachment}
              />
            </MyTooltip>
          );
        },
      },
      // {
      //   title: "Description",
      //   key: "sectionDescription",
      //   width: "400px",
      // },

      // const renderDelete = useCallback((item: ChecklistTemplateChecklistItem) => {
      //   return (
      //     <MyTooltip text='Delete'>
      //       <AttachmentIcon
      //         onClick={deleteChecklist}
      //         data-index={item._id}
      //         src={images.deleteAttachment}
      //       />
      //     </MyTooltip>
      //   );
      // }, []);
    ];
    const buttons: TableHeaderButton[] = [
      {
        title: "Add",
        onClick: () => handleSectionsModal("add"),
        visible: true,
      },
    ];
    return (
      <>
        <TableListV2
          columns={columns}
          advancedList={false}
          headerButtons={buttons}
          loading={false}
          list={sections.value}
          onClickTableRow={handleSectionsModal}
        />
        <Popup isOpen={!!sectionsModal} closeModal={handleSectionsModal}>
          <SectionModalContainer className='section-modal'>
            <FormTitle>
              {sectionsModal === "add" ? "Add" : sectionsModal === "edit" ? "Edit" : ""}
            </FormTitle>
            <Row>
              <TextInput
                label='Title'
                type={"text"}
                width='48%'
                error={title.error}
                inputProps={{
                  name: "title",
                  value: title.value,
                  autoComplete: "off",
                  onChange: onSectionTextChange,
                  required: title.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label='Duration'
                type={"text"}
                width='48%'
                error={sectionDuration.error}
                inputProps={{
                  name: "sectionDuration",
                  value: sectionDuration.value,
                  autoComplete: "off",
                  onChange: onSectionTextChange,
                  required: sectionDuration.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            {/* <TextInput
              label={"Description"}
              type='textarea'
              width='100%'
              error={""}
              textAreaProps={{
                name: "sectionDescription",
                rows: 6,
                value: sectionDescription.value,
                autoComplete: "off",
                onChange: onSectionTextChange,
                required: true,
              }}
            /> */}{" "}
            <TextEditor
              error={""}
              value={sectionDescription}
              onChange={onSectionDescriptionChange}
              placeholder={"Description"}
            />
            <Button
              className='learning-point-modal-save-button'
              title={sectionsModal === "add" ? strings.common.add : strings.common.save}
              onClick={sectionsModal === "add" ? addCourseSection : updateCourseSection}
              disabled={!title.value || !sectionDescription || !sectionDuration.value}
            />
          </SectionModalContainer>
        </Popup>
      </>
    );
  }, [
    sections.value,
    handleSectionsModal,
    sectionsModal,
    title.error,
    title.value,
    title.required,
    onSectionTextChange,
    sectionDuration.error,
    sectionDuration.value,
    sectionDuration.required,
    onSectionDescriptionChange,
    deleteCourseSection,
    addCourseSection,
    sectionDescription,
    updateCourseSection,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyCourses?.createdBy !== "string"
        ? currentAcademyCourses?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyCourses?.updatedBy !== "string"
        ? currentAcademyCourses?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyCourses?.createdAt}
        updatedAt={currentAcademyCourses?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyCourses?.createdAt,
    currentAcademyCourses?.createdBy,
    currentAcademyCourses?.updatedAt,
    currentAcademyCourses?.updatedBy,
  ]);
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.academyCourses.name} />,
    [id],
  );
  const renderWorklogs = useCallback(() => {
    return <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />;
  }, [onPostWorklog, workLogs.value]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.academyCourses.name} recordId={params.id} />,
    [params.id],
  );

  // Related Lists
  const renderContactWebPortal = useMemo(
    () => (
      <AcademyCandidatesList
        relatedList={{
          type: "course",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderCandidateLeads = useMemo(
    () => (
      <AcademyCandidateLeadsList
        relatedList={{
          type: "course",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderBatch = useMemo(
    () => (
      <AcademyBatchList
        relatedList={{
          type: "courseOfferings",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderCandidateEnrolments = useMemo(
    () => (
      <AcademyCandidateEnrollmentsList
        relatedList={{
          type: "enrolledCourse",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderStudentProfile = useMemo(
    () => (
      <AcademyStudentProfileList
        relatedList={{
          type: "enrolledCourse",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderLearningPoints();
      case 2:
        return renderSections();
      case 3:
        return renderChecklist;
      case 4:
        return renderActivitylogs;
      case 5:
        return renderWorklogs();
      case 6:
        return renderSystemData();
      case 7:
        return renderContactWebPortal;
      case 8:
        return renderCandidateLeads;
      case 9:
        return renderBatch;
      case 10:
        return renderCandidateEnrolments;
      case 11:
        return renderStudentProfile;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderContactWebPortal,
    renderCandidateLeads,
    renderBatch,
    renderCandidateEnrolments,
    renderStudentProfile,
    renderOverview,
    renderActivitylogs,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
    renderLearningPoints,
    renderSections,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
