import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { EmployeesListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useEmployeesList } from "./hooks";

export const EmployeesListing = ({ relatedList }: EmployeesListProps) => {
  const employees = useAppSelector((state) => state.EmployeesReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = employees;
  const {
    fetchEmployees,
    employeesTableConfigFields,
    columns,
    loading,
    handlePageChange,
    onClickEmployees,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    employeesFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useEmployeesList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchEmployees({});
    // Do not include fetchEmployees to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.employees}
      handleTableConfigs={handleTableConfigs}
      filters={employeesFilterFields}
      selectedConfigFields={employeesTableConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickEmployees}
      headerTitle={strings.headerTitle.employees}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apEmployees.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
