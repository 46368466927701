import Types from "./actionType";
import {
  FetchVariablesPayload,
  CreateVariables,
  CreateVariablesPayload,
  FetchVariables,
  FetchVariablesError,
  FetchVariablesSuccess,
  UpdateVariables,
  UpdateVariablesPayload,
  VariablesListWithMetaData,
  FetchVariablesByIdPayload,
  FetchVariablesById,
  DeleteVariablesPayload,
  DeleteVariables,
} from "./types";

export const fetchVariablesList = ({
  params,
  onSuccess,
  onError,
}: FetchVariablesPayload): FetchVariables => ({
  type: Types.FETCH_VARIABLES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchVariablesById = ({
  id,
  onSuccess,
  onError,
}: FetchVariablesByIdPayload): FetchVariablesById => ({
  type: Types.FETCH_VARIABLES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchVariablesListSuccess = (
  data: VariablesListWithMetaData,
): FetchVariablesSuccess => ({
  type: Types.SUCCESS_FETCH_VARIABLES,
  payload: {
    data,
  },
});

export const fetchVariablesError = (error?: string): FetchVariablesError => ({
  type: Types.ERROR_FETCH_VARIABLES,
  payload: { error },
});

export const createVariables = ({
  data,
  onSuccess,
  onError,
}: CreateVariablesPayload): CreateVariables => ({
  type: Types.CREATE_VARIABLES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateVariables = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateVariablesPayload): UpdateVariables => ({
  type: Types.UPDATE_VARIABLES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteVariables = ({
  id,
  onSuccess,
  onError,
}: DeleteVariablesPayload): DeleteVariables => ({
  type: Types.DELETE_VARIABLES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
