import React from "react";

import { Layout } from "../../../presentationals";
import { VariableSetsController } from "../../../presentationals/screens/admin/VariableSets/VariableSetsController";

const Route = () => {
  return <Layout activity={<VariableSetsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APVariableSetsAdd = React.memo(Route);
