/* eslint-disable no-unused-vars */
export enum colors {
  primary = "green",
  primary_text = "white",
  secondary = "grey",
  secondary_text = "white",
  error = "red",
  error_text = "white",
  warning = "yellow",
  warning_text = "black",
  information = "blue",
  information_text = "white",
  black = "#333",
  light_black = "#666",
  primary_blue = "#5C61EF",
  primary_pink = "#E94189",
}

export const fonts = {
  font1: '"Sora", sans-serif',
};
