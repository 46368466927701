import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SpacesList,
  AddSpace,
  UpdateSpace,
  FindSpaceById,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSpaceListSuccess } from "./action";
import Types from "./actionType";
import {
  CreateSpaceuccess,
  CreateSpace as CreateSpaceType,
  SpaceSuccess,
  UpdateSpace as UpdateSpaceType,
  FetchSpace,
  FetchSpaceById,
  SpaceByIdSuccess,
} from "./types";

function* createWorker(action: CreateSpaceType) {
  try {
    const options = {
      method: "POST",
      url: AddSpace,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSpaceuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* updateWorker(action: UpdateSpaceType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSpace(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSpaceuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchSpace) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SpacesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SpaceSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchSpaceListSuccess(data.data));
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching academy courses");
    }
  }
}

function* fetchSpaceById(action: FetchSpaceById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSpaceById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SpaceByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SPACE, createWorker);
  yield takeLatest(Types.UPDATE_SPACE, updateWorker);
  yield takeEvery(Types.FETCH_SPACE, fetchAllWorker);
  yield takeEvery(Types.FETCH_SPACE_BY_ID, fetchSpaceById);
}
