import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {};

styles.MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

styles.Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  /* flex: 1; */
  h2 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1vh;
  }
`;

styles.TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 10px;
  margin-bottom: 10px;
`;

styles.TabItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 7px;
  /* margin: 2px; */
  background-color: ${(props) => (props.active ? "#6c7ae0" : colors.secondary)};
  font-family: ${fonts.font1};
  font-size: 1.2rem;
  color: ${(props) => (props.active ? "white" : colors.secondary_text)};
  cursor: ${(props) => (props.active ? "" : "pointer")};
  border-bottom: ${(props) => `2px solid ${props.error ? colors.error : "transparent"}`};
  border-radius: 4px;
  .icon {
    margin-right: 10px;
  }
`;
styles.OrgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 30px 0 0;
`;
styles.OrgItem = styled.div`
  font-size: 1rem;
  font-weight: 600;
  background-color: #48b5e1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4vh;
  padding-left: 10px;
  padding-right: 10px;
  /* margin: 5% 1%; */
  color: white;
`;
styles.OrgInfo = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #48b5e1;
  border: solid 1px white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 4vh;
`;
styles.EmployeeRecordContainer = styled.div`
  background-color: rgba(108, 122, 224, 0.4);
  padding-top: 25px;
  border-radius: 6px;
  position: relative;
`;
export default styles;
