import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { CatalogAdministrationsControllerProps } from "../../../../types/models/CatalogAdministrations";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useCatalogAdministrationsController } from "./hooks";

export const CatalogAdministrationsController = ({
  type,
}: CatalogAdministrationsControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchCatalogAdministrations,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useCatalogAdministrationsController({ type, id: params.id, attachmentsPickerRef });
  const currentCatalogAdministrations = data;

  const {
    workLogs,
    attachments,
    title,
    manager,
    active,
    categoryTitle,
    // categoryIcon,
    categoryActive,
    categoryCatalog,
    description,
    parentCategory,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchCatalogAdministrations();
    }
    // Do not include fetchCatalogAdministrations to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Title'
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"manager"}
            label={"Manager"}
            options={employeesOptions}
            value={manager.value}
            onChange={onTextChange}
            mandatory={manager.required}
            error={manager.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='description'
            type={"textarea"}
            width='100%'
            error={description.error}
            inputProps={{}}
            textAreaProps={{
              name: "description",
              rows: 12,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    employeesOptions,
    manager.error,
    manager.required,
    manager.value,
    onTextChange,
    title.error,
    title.required,
    title.value,
    description.error,
    description.required,
    description.value,
  ]);

  const renderCategory = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Title'
            type={"text"}
            width='48%'
            error={categoryTitle.error}
            inputProps={{
              name: "categoryTitle",
              value: categoryTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: categoryTitle.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"categoryActive"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={categoryActive.value}
            onChange={onTextChange}
            mandatory={categoryActive.required}
            error={categoryActive.error}
          />
        </Row>
      </>
    );
  }, [
    categoryActive.error,
    categoryActive.required,
    categoryActive.value,
    categoryTitle.error,
    categoryTitle.required,
    categoryTitle.value,
    onTextChange,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentCatalogAdministrations?.createdBy !== "string"
        ? currentCatalogAdministrations?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentCatalogAdministrations?.updatedBy !== "string"
        ? currentCatalogAdministrations?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentCatalogAdministrations?.createdAt}
        updatedAt={currentCatalogAdministrations?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentCatalogAdministrations?.createdAt,
    currentCatalogAdministrations?.createdBy,
    currentCatalogAdministrations?.updatedAt,
    currentCatalogAdministrations?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        collection={collections.certificationsAccreditations.name}
        recordId={params.id}
      />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => (
      <ActivityLogs recordId={params.id} collection={collections.catalogAdministrations.name} />
    ),
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderCategory;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderCategory,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
