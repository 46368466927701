import React from "react";

import { Layout } from "../../../presentationals";
import { PlatformModulesController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<PlatformModulesController type={"add"} />} admin={true} layout={"2"} />;
};

export const APPlatformModulesAdd = React.memo(Route);
