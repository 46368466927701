import styled from "styled-components";

import { colors, fonts, fs } from "../../../theme";

interface Props {
  disabled?: boolean;
  active?: boolean;
}

export const Container = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  background-color: ${colors.common.white};
  height: 54px;
  border: 0.6px solid ${colors.common.borderColor};
  padding: 0 15px;
`;

export const InfoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${colors.common.lightTextColor};
  font-size: ${fs.m};
`;

export const PagesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationItem = styled.li<Props>`
  height: 26px;
  width: 26px;
  text-align: center;
  margin: auto 4px;
  color: ${(props) => (props.active ? colors.common.primary : colors.common.black)};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  border: 1px solid ${(props) => (props.active ? colors.common.primary : colors.common.black)};
  border-radius: 2px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    transition: linear 0.4s;
    background-color: ${colors.common.hoverTabBackground};
    cursor: pointer;
  }

  .arrow {
    &::before {
      position: relative;
      content: "";
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.18em solid ${colors.common.border};
      border-top: 0.18em solid ${colors.common.border};
    }
    &.left {
      transform: rotate(-135deg) translate(-25%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid ${colors.common.border};
      border-top: 0.12em solid ${colors.common.border};
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;

export const ShowRecordsContainer = styled.div`
  display: flex;
  margin-left: 20px;
  column-gap: 10px;
`;
