import styled from "styled-components";

import { colors, fs } from "../../../../theme";

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseIcon = styled.img`
  right: 4%;
  height: 28px;
  width: 28px;
  object-fit: contain;
  align-self: center;
  cursor: pointer;
  /* z-index: 2; */
`;

export const SubHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
`;

export const PermissionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DropdownListContainer = styled.div`
  display: flex;
  /* position: absolute; */
  min-width: 60rem;
  row-gap: 20px;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 10px;
  padding: 25px 45px;
  z-index: 1;
  /* top: 38px; */
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const ModalHeading = styled.span`
  font-size: 1.6rem;
`;
export const CloseModalButton = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
`;

export const SectionContainer = styled.div`
  display: flex;
  gap: 1rem;
  min-height: 100px;
`;

export const ListContainer = styled.div`
  width: 40%;
  min-height: 100%;
  border: 1px solid ${colors.common.greyBorder};
`;
export const ActionContainer = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.common.black};
  align-items: center;
`;

export const Icon = styled.img`
  right: 4%;
  height: 12px;
  width: 12px;
  object-fit: contain;
  align-self: center;
  cursor: pointer;
  /* z-index: 2; */
`;

export const ListHeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: ${colors.common.lightGrey};
`;
export const ListSubHeadingContainer = styled.div`
  width: 100%;
  padding: 0.8rem 1rem;
`;

export const ListHeading = styled.span`
  font-size: 1.5rem;
  color: ${colors.common.purpleTitle};
  font-weight: 600;
`;

export const UsersContainer = styled.div`
  width: 100%;
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-clip: padding-box;
    background-color: ${colors.table.scrollThumb};
  }
`;

export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  height: 30px;
  padding: 2px 14px;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  border-bottom: 1px solid ${colors.common.greyBorder};
  color: ${colors.common.whiteGrey};
  font-weight: 600;
`;

export const ActiveUserContainer = styled.div`
  width: 100%;
  display: flex;
  height: 30px;
  padding: 2px 14px;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${colors.common.activeTabText};
  border-bottom: 1px solid ${colors.common.greyBorder};
  background-color: ${colors.common.activeTabBackground};
  font-weight: 600;
`;

export const FooterContainer = styled.div`
  display: flex;
`;
