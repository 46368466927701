import React from "react";

import { MomentDate } from "../../../../services/methods";
import { Row } from "../../../common/styles";
import { TextInput } from "../TextInput";

interface Props {
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export const SystemData = ({ createdAt, updatedAt, createdBy, updatedBy }: Props) => {
  return (
    <>
      <Row>
        <TextInput
          label={"Created On"}
          inputProps={{
            disabled: true,
            name: "name",
            value: MomentDate({
              date: createdAt || "",
              format: "YYYY-MM-DD HH:mm",
            }),
          }}
          textAreaProps={{}}
        />
        <TextInput
          label={"Updated On"}
          inputProps={{
            disabled: true,
            name: "name",
            value: MomentDate({
              date: updatedAt || "",
              format: "YYYY-MM-DD HH:mm",
            }),
          }}
          textAreaProps={{}}
        />
      </Row>
      <Row>
        <TextInput
          label={"Created By"}
          inputProps={{
            disabled: true,
            name: "name",
            value: createdBy,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label={"Updated By"}
          inputProps={{
            disabled: true,
            name: "name",
            value: updatedBy,
          }}
          textAreaProps={{}}
        />
      </Row>
    </>
  );
};
