/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CONTACT_US = "FETCH_CONTACT_US",
  FETCH_CONTACT_US_BY_ID = "FETCH_CONTACT_US_BY_ID",
  SUCCESS_FETCH_CONTACT_US = "SUCCESS_FETCH_CONTACT_US",
  ERROR_FETCH_CONTACT_US = "ERROR_FETCH_CONTACT_US",
  CREATE_CONTACT_US = "CREATE_CONTACT_US",
  UPDATE_CONTACT_US = "UPDATE_CONTACT_US",
  DELETE_CONTACT_US = "DELETE_CONTACT_US",
}

export default Types;
