import Types from "./types";

export const fetchModules = ({ onSuccess, onError }) => ({
  type: Types.MODULES_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchModulesSuccess = (list: any) => ({
  type: Types.MODULES_FETCH_SUCCESS,
  payload: list,
});

export const createModules = ({ data, onSuccess, onError }) => ({
  type: Types.MODULES_ADD,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateModules = ({ id, data, onSuccess, onError }) => ({
  type: Types.MODULES_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateModulesFilters = ({ field, value }) => ({
  type: Types.MODULES_FILTERS_UPDATE,
  payload: {
    field: field,
    value: value,
  },
});
