import React from "react";

import { Layout } from "../../../presentationals";
import { ResourcesList } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<ResourcesList />} admin={true} layout={"2"} />;
};

export const APResourcesListing = React.memo(Route);
