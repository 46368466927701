import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { filterEmployees } from "../../redux/employees/actions";
import { generateArrayAttributes } from "../../services/methods";

import styles from "./EmployeesFilters.styles";

const sortKeys = [
  { label: "Name", key: "name" },
  { label: "Date Hire", key: "dateHire", isDate: true },
  { label: "Official Email", key: "official_email", isDate: false },
  {
    label: "Department",
    key: "employmentDepartment",
    attribute: "employmentDepartment",
  },
  { label: "Type", key: "employeeType", attribute: "employeeType" },
  { label: "Country", key: "personalCountry", attribute: "employeeCountry" },
  { label: "Status", key: "employmentStatus", attribute: "employmentStatus" },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const EmployeesFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.filterEmployees({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Input
        label={"Full Name"}
        type={"text"}
        name='name'
        value={props.filters.name}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Input
        label={"Mobile"}
        type={"text"}
        name='mobile'
        value={props.filters.mobile}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Select
        name={"employmentStatus"}
        label={"Employment Status"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...generateArrayAttributes("employmentStatus").map((value) => ({
            label: value.value,
            value: value._id,
          })),
        ]}
        value={props.filters.employmentStatus}
        onChange={updateFilter}
      />
      <Select
        name={"employeeType"}
        label={"Employee Type"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...generateArrayAttributes("employeeType").map((value) => ({
            label: value.value,
            value: value._id,
          })),
        ]}
        value={props.filters.employeeType}
        onChange={updateFilter}
      />
      <Select
        name={"reportingPOD"}
        label={"POD Leader"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...props.employees.map((value) => ({
            label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
            value: value._id,
          })),
        ]}
        value={props.filters.reportingPOD}
        onChange={updateFilter}
      />
      <Input
        label={"National ID"}
        type={"text"}
        name='nationalId'
        value={props.filters.nationalId}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Select
        name={"nationalIdType"}
        label={"National ID Type"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...generateArrayAttributes("nationalIdType").map((value) => ({
            label: value.value,
            value: value._id,
          })),
        ]}
        value={props.filters.nationalIdType}
        onChange={updateFilter}
      />
      {/* <styles.Row>
        <styles.Column> */}
      <Input
        label={"joining Start"}
        type={"date"}
        name='dateJoiningStart'
        value={props.filters?.dateJoiningStart || ""}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      {/* </styles.Column>
        <styles.Column> */}
      <Input
        label={"joining end"}
        type={"date"}
        name='dateJoiningEnd'
        value={props.filters?.dateJoiningEnd || ""}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      {/* </styles.Column>
      </styles.Row> */}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  employees: state.EmployeesReducer.employees,
  filters: state.EmployeesReducer.filters,
});

const mapDispatchToProps = {
  filterEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesFilters);
