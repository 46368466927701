import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { CountryListProps } from "../../../../types/models/CountryForm";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useCountryList } from "./hooks";

export const CountryList = memo(({ relatedList }: CountryListProps) => {
  const Country = useAppSelector((state) => state.CountryReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const { list, metaData } = Country;
  const {
    fetchCountry,
    loading,
    handlePageChange,
    onClickCountry,
    onSubmitDeleteRecord,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    countryFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    CountryConfigTableFields,
  } = useCountryList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchCountry({});
    // Do not include fetchCountry to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.country}
      handleTableConfigs={handleTableConfigs}
      filters={countryFilterFields}
      selectedConfigFields={CountryConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickCountry}
      headerTitle={strings.headerTitle.country}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.country_delete}
      searchPlaceholder={strings.apCountry.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
