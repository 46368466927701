import Input from "../../../components/Input/input";
// import Select from "../../../../components/Select/select";
// import styles from "../EmployeesController.styles";
// import { generateArrayAttributes } from "../../../../services/methods";

export const Tab6 = ({ formData, readOnly, onTextChange }) => {
  return (
    <>
      <Input
        readOnly={readOnly}
        label={"Full Name"}
        type={"text"}
        name='emergencyName'
        value={formData.emergencyName}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact Relationship"}
        type={"text"}
        name='emergencyRelation'
        value={formData.emergencyRelation}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact Number"}
        type={"text"}
        name='emergencyMobile'
        value={formData.emergencyMobile}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact Address"}
        type={"text"}
        name='emergencyAddress'
        value={formData.emergencyAddress}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact 2: Full Name"}
        type={"text"}
        name='emergency1Name'
        value={formData.emergency1Name}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact 2: Relationship"}
        type={"text"}
        name='emergency1Relationship'
        value={formData.emergency1Relationship}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact 2: Number"}
        type={"text"}
        name='emergency1Mobile'
        value={formData.emergency1Mobile}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Contact 2: Address"}
        type={"text"}
        name='emergency1Address'
        value={formData.emergency1Address}
        autoComplete={"off"}
        onChange={onTextChange}
      />
    </>
  );
};
