import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { SPSDealsControllerProps } from "../../../../types/models/SPSDeals";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSBGCheckList } from "../SPSBGCheck";
import { SPSPaperworkList } from "../SPSPaperwork";
import { SPSProjectsList } from "../SPSProjects";
import { SPSSalesProfileList } from "../SPSSalesProfile";

import { useSPSDealsController } from "./hooks";

const Controller = ({ type }: SPSDealsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { usersOptions, spsAccountsOptions, SPSSalesProfileOptions, modulesOptions } =
    useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    onDateChange,
    loading,
    headerTitle,
    fetchSPSDeals,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSDealsController({ type, id, attachmentsPickerRef });
  const currentSPSDeals = data;

  const {
    workLogs,
    attachments,
    preferredFullName,
    legalAccountName,
    SPSSalesProfile,
    accountOwner,
    rate,
    dealSource,
    state,
    staffingName,
    endClient,
    name,
    email,
    phone,
    verifiedBy,
    platform,
    modulesInvolved,
    interviewDetails,
    recordingDriveLink,
    addressUsed,
    bankAccountDetailsUsed,
    dealGivenTo,
    isTranscriptUploaded,
    isJobDescriptionUploaded,
    isResumeUploaded,
    c2cInfo,
    potentialStartDate,
    potentialClosingDate,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSDeals();
    }
    // Do not include fetchSPSDeals to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Preferred Name'
            type={"text"}
            width='48%'
            error={preferredFullName.error}
            inputProps={{
              name: "preferredFullName",
              value: preferredFullName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: preferredFullName.required,
            }}
          />
          <TextInput
            label='Legal Account Name'
            type={"text"}
            width='48%'
            error={legalAccountName.error}
            inputProps={{
              name: "legalAccountName",
              value: legalAccountName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: legalAccountName.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"SPSSalesProfile"}
            label={"SPS Sales Profile"}
            options={SPSSalesProfileOptions}
            value={SPSSalesProfile.value}
            onChange={onTextChange}
            mandatory={SPSSalesProfile.required}
            error={SPSSalesProfile.error}
          />
          <Select
            width={"48%"}
            name={"accountOwner"}
            label={"Account Owner"}
            options={usersOptions}
            value={accountOwner.value}
            onChange={onTextChange}
            mandatory={accountOwner.required}
            error={accountOwner.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Rate'
            type={"text"}
            width='48%'
            error={rate.error}
            inputProps={{
              name: "rate",
              value: rate.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: rate.required,
            }}
          />
          <Select
            width={"48%"}
            name={"type"}
            label={"Type"}
            options={getAttributValues("spsDealsType")}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"dealSource"}
            label={"Deal Source"}
            options={getAttributValues("dealSource")}
            value={dealSource.value}
            onChange={onTextChange}
            mandatory={dealSource.required}
            error={dealSource.error}
          />
          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("dealsState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"staffingName"}
            label={"Staffing Name"}
            options={spsAccountsOptions}
            value={staffingName.value}
            onChange={onTextChange}
            mandatory={staffingName.required}
            error={staffingName.error}
          />
          <Select
            width={"48%"}
            name={"endClient"}
            label={"End Client"}
            options={spsAccountsOptions}
            value={endClient.value}
            onChange={onTextChange}
            mandatory={endClient.required}
            error={endClient.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"platform"}
            label={"Platform"}
            options={getAttributValues("dealsPlateform")}
            value={platform.value}
            onChange={onTextChange}
            mandatory={platform.required}
            error={platform.error}
          />
          <TextInput
            label='C2C Info'
            type={"text"}
            width='48%'
            error={c2cInfo.error}
            inputProps={{
              name: "c2cInfo",
              value: c2cInfo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: c2cInfo.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"100%"}
            name={"modulesInvolved"}
            label={"Modules Involved"}
            isMulti
            options={modulesOptions}
            value={modulesInvolved.value}
            onChange={onTextChange}
            mandatory={modulesInvolved.required}
            error={modulesInvolved.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Interview Details'
            type={"text"}
            width='48%'
            error={interviewDetails.error}
            inputProps={{
              name: "interviewDetails",
              value: interviewDetails.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: interviewDetails.required,
            }}
          />
          <TextInput
            label='Recording Drive Link'
            type={"text"}
            width='48%'
            error={recordingDriveLink.error}
            inputProps={{
              name: "recordingDriveLink",
              value: recordingDriveLink.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: recordingDriveLink.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Address Used'
            type={"text"}
            width='48%'
            error={addressUsed.error}
            inputProps={{
              name: "addressUsed",
              value: addressUsed.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressUsed.required,
            }}
          />
          <TextInput
            label='Bank Account Details Used'
            type={"text"}
            width='48%'
            error={bankAccountDetailsUsed.error}
            inputProps={{
              name: "bankAccountDetailsUsed",
              value: bankAccountDetailsUsed.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: bankAccountDetailsUsed.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Deal Given To'
            type={"text"}
            width='48%'
            error={dealGivenTo.error}
            inputProps={{
              name: "dealGivenTo",
              value: dealGivenTo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: dealGivenTo.required,
            }}
          />
          <Select
            name={"isTranscriptUploaded"}
            label={"Curriculum Attached"}
            options={ActiveSelectOptions}
            value={isTranscriptUploaded.value}
            onChange={onTextChange}
            mandatory={isTranscriptUploaded.required}
            error={isTranscriptUploaded.error}
          />
        </Row>
        <Row>
          <Select
            name={"isJobDescriptionUploaded"}
            label={"Is Job Description Uploaded"}
            options={ActiveSelectOptions}
            value={isJobDescriptionUploaded.value}
            onChange={onTextChange}
            mandatory={isJobDescriptionUploaded.required}
            error={isJobDescriptionUploaded.error}
          />
          <Select
            name={"isResumeUploaded"}
            label={"Is Resume Uploaded"}
            options={ActiveSelectOptions}
            value={isResumeUploaded.value}
            onChange={onTextChange}
            mandatory={isResumeUploaded.required}
            error={isResumeUploaded.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Potential Start Date'
            type={"date"}
            width='48%'
            error={potentialStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "potentialStartDate",
              value: potentialStartDate.value,
              required: potentialStartDate.required,
            }}
          />
          <TextInput
            label='Potential Closing Date'
            type={"date"}
            width='48%'
            error={potentialClosingDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "potentialClosingDate",
              minDate: potentialStartDate.value ? new Date(potentialStartDate.value) : undefined,
              value: potentialClosingDate.value,
              required: potentialClosingDate.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    SPSSalesProfile.error,
    SPSSalesProfile.required,
    SPSSalesProfile.value,
    SPSSalesProfileOptions,
    accountOwner.error,
    accountOwner.required,
    accountOwner.value,
    addressUsed.error,
    addressUsed.required,
    addressUsed.value,
    bankAccountDetailsUsed.error,
    bankAccountDetailsUsed.required,
    bankAccountDetailsUsed.value,
    c2cInfo.error,
    c2cInfo.required,
    c2cInfo.value,
    dealGivenTo.error,
    dealGivenTo.required,
    dealGivenTo.value,
    dealSource.error,
    dealSource.required,
    dealSource.value,
    endClient.error,
    endClient.required,
    endClient.value,
    interviewDetails.error,
    interviewDetails.required,
    interviewDetails.value,
    isJobDescriptionUploaded.error,
    isJobDescriptionUploaded.required,
    isJobDescriptionUploaded.value,
    isResumeUploaded.error,
    isResumeUploaded.required,
    isResumeUploaded.value,
    isTranscriptUploaded.error,
    isTranscriptUploaded.required,
    isTranscriptUploaded.value,
    legalAccountName.error,
    legalAccountName.required,
    legalAccountName.value,
    modulesInvolved.error,
    modulesInvolved.required,
    modulesInvolved.value,
    modulesOptions,
    onDateChange,
    onTextChange,
    platform.error,
    platform.required,
    platform.value,
    potentialClosingDate.error,
    potentialClosingDate.required,
    potentialClosingDate.value,
    potentialStartDate.error,
    potentialStartDate.required,
    potentialStartDate.value,
    preferredFullName.error,
    preferredFullName.required,
    preferredFullName.value,
    rate.error,
    rate.required,
    rate.value,
    recordingDriveLink.error,
    recordingDriveLink.required,
    recordingDriveLink.value,
    spsAccountsOptions,
    staffingName.error,
    staffingName.required,
    staffingName.value,
    state.error,
    state.required,
    state.value,
    formData.type.error,
    formData.type.required,
    formData.type.value,
    usersOptions,
  ]);

  const POC = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
          <TextInput
            label='Verified By'
            type={"text"}
            width='48%'
            error={verifiedBy.error}
            inputProps={{
              name: "verifiedBy",
              value: verifiedBy.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: verifiedBy.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    email.error,
    email.required,
    email.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    verifiedBy.error,
    verifiedBy.required,
    verifiedBy.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSDeals?.createdBy !== "string" ? currentSPSDeals?.createdBy?.name : "";
    const updatedBy =
      typeof currentSPSDeals?.updatedBy !== "string" ? currentSPSDeals?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentSPSDeals?.createdAt}
        updatedAt={currentSPSDeals?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSDeals?.createdAt,
    currentSPSDeals?.createdBy,
    currentSPSDeals?.updatedAt,
    currentSPSDeals?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.spsDeals.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsDeals.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  // Related List
  const renderSPSSalesProfile = useMemo(
    () => (
      <SPSSalesProfileList
        relatedList={{
          type: "_id",
          value:
            (typeof currentSPSDeals.SPSSalesProfile !== "string" &&
              currentSPSDeals.SPSSalesProfile?._id) ||
            "",
        }}
      />
    ),
    [currentSPSDeals.SPSSalesProfile],
  );

  const renderSPSBGCheck = useMemo(
    () => (
      <SPSBGCheckList
        relatedList={{
          type: "associatedDeal",
          value: id,
        }}
      />
    ),
    [id],
  );

  const renderSPSPaperwork = useMemo(
    () => (
      <SPSPaperworkList
        relatedList={{
          type: "associatedDeal",
          value: id,
        }}
      />
    ),
    [id],
  );

  const renderSPSProjects = useMemo(
    () => (
      <SPSProjectsList
        relatedList={{
          type: "associatedDeal",
          value: id,
        }}
      />
    ),
    [id],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return POC;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      case 6:
        return renderSPSSalesProfile;
      case 7:
        return renderSPSBGCheck;
      case 8:
        return renderSPSPaperwork;
      case 9:
        return renderSPSProjects;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderSPSSalesProfile,
    renderActivitylogs,
    renderOverview,
    POC,
    renderChecklist,
    renderWorklogs,
    renderSystemData,
    renderSPSBGCheck,
    renderSPSPaperwork,
    renderSPSProjects,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSDealsController = memo(Controller);
