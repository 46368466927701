import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { SubscriberControllerProps } from "../../../../types/models/Subscriber";
import { ActiveSelectOptions, VerticalSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useSubscriberController } from "./hooks";

const Controller = ({ type }: SubscriberControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsAccountsOptions, NewsletterResourcesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchSubscriber,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    onDateChange,
    formSteps,
    formRelatedList,
  } = useSubscriberController({ type, id, attachmentsPickerRef });
  const currentSubscriber = data;

  const {
    workLogs,
    attachments,
    email,
    active,
    unsubscribedOnWhichResource,
    unsubscribeTimestamp,
    vertical,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSubscriber();
    }
    // Do not include fetchSubscriber to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              disabled: true,
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
          <Select
            name={"active"}
            readOnly={true}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"unsubscribedOnWhichResource"}
            readOnly={true}
            label={"Unsubscribed On Which Resource"}
            options={NewsletterResourcesOptions}
            value={unsubscribedOnWhichResource.value}
            onChange={onTextChange}
            mandatory={unsubscribedOnWhichResource.required}
            error={unsubscribedOnWhichResource.error}
          />
          <TextInput
            label='Unsubscribe Timestamp'
            type={"date"}
            width='48%'
            error={unsubscribeTimestamp.error}
            onDateChange={onDateChange}
            datePickerProps={{
              disabled: true,
              name: "unsubscribeTimestamp",
              value: unsubscribeTimestamp.value,
              required: unsubscribeTimestamp.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            readOnly={true}
            name={"vertical"}
            label={"Vertical"}
            options={VerticalSelectOptions}
            value={vertical.value}
            onChange={onTextChange}
            mandatory={vertical.required}
            error={vertical.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    NewsletterResourcesOptions,
    active.error,
    active.required,
    active.value,
    email.error,
    email.required,
    email.value,
    onDateChange,
    onTextChange,
    unsubscribeTimestamp.error,
    unsubscribeTimestamp.required,
    unsubscribeTimestamp.value,
    unsubscribedOnWhichResource.error,
    unsubscribedOnWhichResource.required,
    unsubscribedOnWhichResource.value,
    vertical.error,
    vertical.required,
    vertical.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSubscriber?.createdBy !== "string" ? currentSubscriber?.createdBy?.name : "";
    const updatedBy =
      typeof currentSubscriber?.updatedBy !== "string" ? currentSubscriber?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentSubscriber?.createdAt}
        updatedAt={currentSubscriber?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSubscriber?.createdAt,
    currentSubscriber?.createdBy,
    currentSubscriber?.updatedAt,
    currentSubscriber?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.subscribers.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.subscribers.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SubscriberController = memo(Controller);
