import React, { useEffect, useRef, useState } from "react";

import moment from "moment-timezone";
import {
  MdList,
  MdPerson,
  MdTimer,
  MdInput,
  MdManageAccounts,
  MdPersonalInjury,
  MdEmergency,
} from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import DocumentViewer from "../../components/Document/Document";
import Loader from "../../components/Loader/loader";
import ModalComponent from "../../components/Modal/Modal";
import Toast from "../../components/Toast/toast";
import Tooltip from "../../components/Tooltip/Tooltip";
import { uploadFile, getFile } from "../../redux/application/actions";
import {
  getEmployees,
  updateEmployees,
  fetchEmployees,
  addEmployees,
  addDesignations,
  updateDesignations,
} from "../../redux/employees/actions";
import { formValidationMethod, useTitle } from "../../services/methods";

import styles from "./EmployeesController.styles";
import { Tab0, Tab1, Tab2, Tab3, Tab4, Tab5, Tab6 } from "./Tabs";

const EmployeesController = (props) => {
  const defaultToast = {
    message: "",
    message_type: "",
  };
  const params = useParams();
  const navigate = useNavigate();
  const readOnly = ["edit", "add"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  const [toast, setToast] = useState(defaultToast);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  // const [documentData, setDocumentData] = useState({});
  // const [numPages, setnumPages] = useState("-");
  useEffect(() => {
    if (props.type === "view" || props.type === "edit") {
      if (id !== params.id || viewType.current !== props.type) {
        viewType.current = props.type;
        setFormData({ ...formData });
        setLoader(true);
        setTab("0");
        props.getEmployees({
          id: params.id,
          onSuccess: (data) => {
            setLoader(false);
            setId(params.id);
            setToast(defaultToast);
            updateFormData(data);
            if (props.type === "edit") {
              setDesignationData([
                { hidden: true },
                ...data.designations.sort((a, b) =>
                  moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                ),
              ]);
            } else {
              setDesignationData(
                data.designations.sort((a, b) =>
                  moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                ),
              );
            }
          },
          onError: (error) => {
            // console.log(error);
          },
        });
      }
    } else if (props.type === "add") {
      viewType.current = props.type;
      setLoader(false);
      setTab("0");
      updateFormData({});
      setDesignationData([{ hidden: true }]);
      setId("");
      setToast(defaultToast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id]);

  const [tab, setTab] = useState("0");
  const [designationData, setDesignationData] = useState([]);
  const [formData, setFormData] = useState({
    // tab0
    name: "",
    nickName: "",
    employeeLevel: "",
    employeeType: "",
    email: "",
    official_email: "",
    mobile: "",
    mobile1: "",
    employmentStatus: "",
    employmentType: "",
    employmentDepartment: "",
    employmentCompany: "",
    referral: "",
    referralMobile: "",
    referralEmail: "",
    // tab1
    isPODManager: false,
    reportingManager: "",
    reportingHR: "",
    reportingPOD: "",
    reportingOperations: "",
    reportingCompany: "",
    // tab2
    dateJoining: "",
    dateHire: "",
    dateTrainingStart: "",
    dateTrainingEnd: "",
    dateAppraisal: "",
    dateTermination: "",
    terminationComment: "",
    // tab3
    // new array here
    // tab4
    accountGoogleCreated: "",
    accountGoogleSuspended: "",
    accountVeemCreated: "",
    accountVeemCreatedReason: "",
    accountDeleted: "",
    accountDeletedComment: "",
    // tab5
    nationalId: "",
    nationalIdType: "",
    personalGender: "",
    personalMartial: "",
    personalDOB: "",
    personalRelationshipName: "",
    personalRelationship: "",
    personalRelationshipContact: "",
    personalCitizenship: "",
    personalAddress: "",
    personalCity: "",
    personalState: "",
    personalZip: "",
    personalCountry: "",
    personalComment: "",
    // tab 6
    emergencyName: "",
    emergencyRelation: "",
    emergencyMobile: "",
    emergencyAddress: "",
    emergency1Name: "",
    emergency1Relationship: "",
    emergency1Mobile: "",
    emergency1Address: "",
    // attachment
    attachmentResume: "",
    attachmentCoverLetter: "",
    attachmentInternshipCertificate: "",
    attachmentConsultantProfile: "",
  });
  const [actualEmploymentStatus, setSctualEmploymentStatus] = useState("");
  const [formValidation, setFormValidation] = useState({
    name: {
      required: true,
      type: "fullname",
      error: "",
    },
    email: {
      required: true,
      type: "email",
      error: "",
    },
    official_email: {
      required: true,
      type: "email",
      error: "",
    },
    mobile: {
      required: true,
      type: "",
      error: "",
    },
    nationalId: {
      required: true,
      type: "",
      error: "",
    },
    personalCountry: {
      required: true,
      type: "",
      error: "",
    },
    nationalIdType: {
      required: true,
      type: "",
      error: "",
    },
    // attachment
    attachmentResume: {
      required: true,
      type: "file",
      error: "",
    },
    attachmentCoverLetter: {
      required: false,
      type: "file",
      error: "",
    },
    attachmentInternshipCertificate: {
      required: false,
      type: "file",
      error: "",
    },
    attachmentConsultantProfile: {
      required: false,
      type: "file",
      error: "",
    },
  });

  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
    // props.getFile({
    //   id: id,
    //   onSuccess: (data) => {
    //     setDocumentData(data);
    //   },
    //   onError: (err) => {
    //     alert("Document fetching failed", err);
    //   },
    // });
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (typeof formValidation[name] === "object") {
      const error = formValidationMethod(name, value, formValidation);
      if (error) {
        setFormValidation({
          ...formValidation,
          [name]: {
            ...formValidation[name],
            error: error,
          },
        });
      } else {
        setFormValidation({
          ...formValidation,
          [name]: {
            ...formValidation[name],
            error: "",
          },
        });
      }
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      onSuccess: (data) => {
        setFormData({
          ...formData,
          [name]: data.id,
        });
        setFormValidation({
          ...formValidation,
          [name]: {
            ...formValidation[name],
            error: ``,
          },
        });
      },
      onError: (err) => {
        setFormValidation({
          ...formValidation,
          [name]: {
            ...formValidation[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setFormValidation({
          ...formValidation,
          [name]: {
            ...formValidation[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };

  const onTextChangeDesignation = (e) => {
    const index = e.target?.getAttribute
      ? e.target.getAttribute("data-index")
      : e.target["data-index"];
    const name = e.target.name;
    const value = e.target.value;
    const newArray = [...designationData];
    newArray[index][name] = value;
    setDesignationData(newArray);
  };

  const updateDesignationData = (index, name, value) => {
    const newArray = [...designationData];
    newArray[index][name] = value;
    setDesignationData(newArray);
  };

  const updateTab = (e) => {
    setToast(defaultToast);
    setTab(e.currentTarget.getAttribute("data-key"));
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Employee: ITN-12";
      case "add":
        return "Add Employee";
      case "edit":
        return "Edit Employee";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const updateFormData = (value) => {
    setSctualEmploymentStatus(value.employmentStatus || "");
    setFormData({
      ...formData,
      name: value.name || "",
      nickName: value.nickName || "",
      employeeLevel: value.employeeLevel || "",
      employeeType: value.employeeType || "",
      email: value.email || "",
      official_email: value.official_email || "",
      mobile: value.mobile || "",
      mobile1: value.mobile1 || "",
      employmentStatus: value.employmentStatus || "",
      employmentType: value.employmentType || "",
      employmentDepartment: value.employmentDepartment || "",
      employmentCompany: value.employmentCompany || "",
      referral: value.referral || "",
      referralMobile: value.referralMobile || "",
      referralEmail: value.referralEmail || "",
      // tab1
      isPODManager: value.isPODManager || false,
      reportingManager: value.reportingManager || null,
      reportingHR: value.reportingHR || null,
      reportingPOD: value.reportingPOD || null,
      reportingOperations: value.reportingOperations || null,
      reportingCompany: value.reportingCompany || null,
      // tab2
      dateJoining: value.dateJoining ? moment(value.dateJoining).format("YYYY-MM-DD") : "",
      dateHire: value.dateHire ? moment(value.dateHire).format("YYYY-MM-DD") : "",
      dateTrainingStart: value.dateTrainingStart
        ? moment(value.dateTrainingStart).format("YYYY-MM-DD")
        : "",
      dateTrainingEnd: value.dateTrainingEnd
        ? moment(value.dateTrainingEnd).format("YYYY-MM-DD")
        : "",
      dateAppraisal: value.dateAppraisal ? moment(value.dateAppraisal).format("YYYY-MM-DD") : "",
      dateTermination: value.dateTermination
        ? moment(value.dateTermination).format("YYYY-MM-DD")
        : "",
      terminationComment: value.terminationComment || "",
      // tab3
      accountGoogleCreated:
        value.accountGoogleCreated !== undefined ? value.accountGoogleCreated : "",
      accountGoogleSuspended:
        value.accountGoogleSuspended !== undefined ? value.accountGoogleSuspended : "",
      accountDeleted: value.accountDeleted !== undefined ? value.accountDeleted : "",
      accountVeemCreated: value.accountVeemCreated !== undefined ? value.accountVeemCreated : "",
      accountVeemCreatedReason:
        value.accountVeemCreatedReason !== undefined ? value.accountVeemCreatedReason : "",
      accountDeletedComment:
        value.accountDeletedComment !== undefined ? value.accountDeletedComment : "",
      // tab4
      // new array here
      // tab5
      nationalId: value.nationalId || "",
      nationalIdType: value.nationalIdType || "",
      personalGender: value.personalGender || "",
      personalMartial: value.personalMartial || "",
      personalDOB: value.personalDOB || "",
      personalRelationshipName: value.personalRelationshipName || "",
      personalRelationship: value.personalRelationship || "",
      personalRelationshipContact: value.personalRelationshipContact || "",
      personalCitizenship: value.personalCitizenship || "",
      personalAddress: value.personalAddress || "",
      personalCity: value.personalCity || "",
      personalState: value.personalState || "",
      personalZip: value.personalZip || "",
      personalCountry: value.personalCountry || "",
      personalComment: value.personalComment || "",
      // tab 6
      emergencyName: value.emergencyName || "",
      emergencyRelation: value.emergencyRelation || "",
      emergencyMobile: value.emergencyMobile || "",
      emergencyAddress: value.emergencyAddress || "",
      emergency1Name: value.emergency1Name || "",
      emergency1Relationship: value.emergency1Relationship || "",
      emergency1Mobile: value.emergency1Mobile || "",
      emergency1Address: value.emergency1Address || "",
      // attachment
      attachmentResume: value.attachmentResume || "",
      attachmentCoverLetter: value.attachmentCoverLetter || "",
      attachmentInternshipCertificate: value.attachmentInternshipCertificate || "",
      attachmentConsultantProfile: value.attachmentConsultantProfile || "",
    });
  };

  const onSubmitAddForm = () => {
    const submit = validateForm();
    if (submit) {
      props.addEmployees({
        data: { ...formData, designations: designationData[0] },
        onSuccess: (data) => {
          props.fetchEmployees({
            onSuccess: () => {
              navigate("/ap/employees");
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setTimeout(() => {
                setToast(defaultToast);
              }, 5000);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setTimeout(() => {
            setToast(defaultToast);
          }, 5000);
        },
      });
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formValidation;
    Object.keys(formValidation).forEach((item) => {
      const name = item;
      const value = formData[name];
      const error = formValidationMethod(name, value, formValidation);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormValidation(newFormValidation);
    return output;
  };

  const onSubmitUpdateForm = () => {
    const submit = validateForm();
    console.log("FormData", formData);
    if (submit) {
      props.updateEmployees({
        id: id,
        data: formData,
        onSuccess: () => {
          props.fetchEmployees({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setTimeout(() => {
                setToast(defaultToast);
              }, 5000);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setTimeout(() => {
                setToast(defaultToast);
              }, 5000);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setTimeout(() => {
            setToast(defaultToast);
          }, 5000);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const onSubmitDesignationForm = (e) => {
    // let submit = validateForm();
    const index = parseInt(e.target.getAttribute("data-index"));
    if (index === 0) {
      updateDesignationData(index, "loading", true);
      props.addDesignations({
        data: { ...designationData[0], employeeId: id },
        onSuccess: (data) => {
          props.getEmployees({
            id: params.id,
            onSuccess: (data1) => {
              updateFormData(data1);
              if (props.type === "edit") {
                setToast({
                  message: "Added Successfully",
                  message_type: "success",
                });
                setTimeout(() => {
                  setToast(defaultToast);
                }, 5000);
                setDesignationData([
                  { hidden: true },
                  ...data1.designations.sort((a, b) =>
                    moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                  ),
                ]);
              } else {
                setDesignationData(
                  data1.designations.sort((a, b) =>
                    moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                  ),
                );
              }
            },
            onError: (error) => {
              // console.log(error);
            },
          });
        },
        onError: (err) => {
          updateDesignationData(index, "loading", false);
        },
      });
    } else {
      updateDesignationData(index, "loading", true);
      props.updateDesignations({
        id: designationData[index]["_id"],
        data: { ...designationData[index], employeeId: id },
        onSuccess: (data) => {
          props.getEmployees({
            id: params.id,
            onSuccess: (data1) => {
              updateFormData(data1);
              if (props.type === "edit") {
                setToast({
                  message: "Updated Successfully",
                  message_type: "success",
                });
                setTimeout(() => {
                  setToast(defaultToast);
                }, 5000);
                setDesignationData([
                  { hidden: true },
                  ...data1.designations.sort((a, b) =>
                    moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                  ),
                ]);
              } else {
                setDesignationData(
                  data1.designations.sort((a, b) =>
                    moment(a.date).valueOf() < moment(b.date) ? 1 : -1,
                  ),
                );
              }
            },
            onError: () => {},
          });
        },
        onError: (err) => {
          updateDesignationData(index, "loading", false);
        },
      });
    }
  };

  const Tabs = () => {
    const tabs = [
      {
        tab: "0",
        icon: MdList,
        label: "Employee Overview",
        fields: ["name", "email", "official_email", "mobile"],
      },
      {
        tab: "1",
        icon: MdPerson,
        label: "Reporting Managers",
      },
      {
        tab: "2",
        icon: MdTimer,
        label: "Time Details",
      },
      {
        tab: "3",
        icon: MdInput,
        label: "Salary Information",
      },
      {
        tab: "4",
        icon: MdManageAccounts,
        label: "Account Details",
      },
      {
        tab: "5",
        icon: MdPersonalInjury,
        label: "Personal Details",
        fields: ["nationalId", "nationalIdType"],
      },
      {
        tab: "6",
        icon: MdEmergency,
        label: "Emergency Details",
      },
    ];
    return tabs;
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formValidation).filter((item) => formValidation[item].error);
    const value = count.length > 0 ? true : false;
    return value;
  };

  const CheckForTabError = (fields) => {
    let error = false;
    fields.forEach((item) => {
      if (formValidation[item].error) {
        error = true;
      }
    });
    return error;
  };

  // const modalContent = () => {
  //   var split = documentData?.mimetype?.split("/")[0];
  //   switch (split) {
  //     case "image":
  //       return <img alt="check" width={"100%"} src={documentData.signedUrl} />;
  //     case "application":
  //       return (
  //         <div>
  //           <Document
  //             file={{ url: documentData.signedUrl }}
  //             onLoadSuccess={({ numPages }) => setnumPages(numPages)}
  //           >
  //             <Page pageNumber={1} />
  //           </Document>
  //           <p>
  //             Page {1} of {numPages}
  //           </p>
  //         </div>
  //       );
  //     default:
  //       return "";
  //   }
  // };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>{getTitle()}</h2>
        {!readOnly && (
          <Button
            onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
            disabled={SubmitDisabled()}
            size={"small"}
            label={"Save"}
          />
        )}
      </styles.Header>
      <styles.TabContainer>
        {Tabs().map((item) => (
          <Tooltip
            text={item.label}
            copy={false}
            element={
              <styles.TabItem
                key={item.tab}
                onClick={updateTab}
                data-key={item.tab}
                data-tab={item.tab}
                active={tab === item.tab}
                error={item.fields ? CheckForTabError(item.fields) : false}
              >
                <item.icon size={16} />
              </styles.TabItem>
            }
          />
        ))}
      </styles.TabContainer>
      <styles.TabItem active={true}>{Tabs().find((item) => item.tab === tab).label}</styles.TabItem>
      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form autoComplete='off'>
        {tab === "0" && (
          <Tab0
            readOnly={readOnly}
            getDocument={getDocument}
            onTextChange={onTextChange}
            onFileChange={onFileChange}
            formData={formData}
            formValidation={formValidation}
            actualEmploymentStatus={actualEmploymentStatus}
          />
        )}
        {tab === "1" && (
          <Tab1
            readOnly={readOnly}
            formData={formData}
            onTextChange={onTextChange}
            employees={props.employees}
          />
        )}
        {tab === "2" && (
          <Tab2 readOnly={readOnly} formData={formData} onTextChange={onTextChange} />
        )}
        {tab === "3" && (
          <Tab3
            readOnly={readOnly}
            designationData={designationData}
            onTextChange={onTextChangeDesignation}
            setDesignationData={setDesignationData}
            onSubmitDesignationForm={onSubmitDesignationForm}
            pageType={props.type}
          />
        )}
        {tab === "4" && (
          <Tab4 readOnly={readOnly} formData={formData} onTextChange={onTextChange} />
        )}
        {tab === "5" && (
          <Tab5
            readOnly={readOnly}
            formData={formData}
            onTextChange={onTextChange}
            formValidation={formValidation}
          />
        )}
        {tab === "6" && (
          <Tab6 readOnly={readOnly} formData={formData} onTextChange={onTextChange} />
        )}
      </form>
      <br />
      <br />
      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <DocumentViewer id={documentId} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  employees: state.EmployeesReducer.employees,
});

const mapDispatchToProps = {
  getEmployees,
  updateEmployees,
  fetchEmployees,
  addEmployees,
  uploadFile,
  getFile,
  addDesignations,
  updateDesignations,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesController);
