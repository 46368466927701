import React, { useEffect, useMemo } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { TaskList as TaskListType } from "../../../../redux/Task/types";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType, TaskListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableList, TableListV3 } from "../../../components";

import { useTaskList } from "./hooks";

export const TaskList = ({ relatedList }: TaskListProps) => {
  const task = useAppSelector((state) => state.TaskReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Accessing specific query parameters
  const associatedTo = searchParams.get("associatedTo") || relatedList?.associatedTo;
  const active = searchParams.get("active");
  const assignedTo = searchParams.get("assignedTo");
  const assignedGroupOfuser = searchParams.get("assignedGroupOfuser");
  const watchlistGroupOfuser = searchParams.get("watchlistGroupOfuser");

  const { list, metaData } = task;

  const {
    fetchTasks,
    loading,
    handlePageChange,
    onClickTask,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    tasksFilterFields,
    tasksHeaderButtons,
    exportSelectedRecords,
    exportAllRecords,
    taskAssociatedRecord,
    taskPrefix,
    taskPublicFields,
    taskListConfigs,
  } = useTaskList({
    relatedList,
    associatedTo,
    active,
    assignedTo,
    assignedGroupOfuser,
    watchlistGroupOfuser,
  });

  // const {
  //   getTask,
  //   taskPrefix,
  //   loading,
  //   handlePageChange,
  //   onClickTask,
  //   onClickAdd,
  //   onSearch,
  //   handleClearSearch,
  //   search,
  //   onSearchChange,
  //   taskAssociatedRecord,
  // } = useTaskList({
  //   relatedList,
  // });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      associatedEmployee: {
        title: "Employee",
        key: "associatedEmployee",
        render: (item: TaskListType) => {
          return <div className='title'>{taskAssociatedRecord(item, "associatedEmployee")}</div>;
        },
      },
      associatedCompany: {
        title: "Company",
        key: "associatedCompany",
        render: (item: TaskListType) => {
          return <div className='title'>{taskAssociatedRecord(item, "associatedCompany")}</div>;
        },
      },
      associatedCandidateLead: {
        title: "Associated Candidate Lead",
        key: "associatedCandidateLead",
        render: (item: TaskListType) => {
          return (
            <div className='title'>{taskAssociatedRecord(item, "associatedCandidateLead")}</div>
          );
        },
      },
      associatedCandidateEnrolment: {
        title: "Associated Enrolment",
        key: "associatedCandidateEnrolment",
        render: (item: TaskListType) => {
          return (
            <div className='title'>
              {taskAssociatedRecord(item, "associatedCandidateEnrolment")}
            </div>
          );
        },
      },
      associatedCandidateStudent: {
        title: "Associated Lerner",
        key: "associatedCandidateStudent",
        render: (item: TaskListType) => {
          return (
            <div className='title'>{taskAssociatedRecord(item, "associatedCandidateStudent")}</div>
          );
        },
      },
      number: {
        title: "Number",
        key: "number",
        render: (item) => {
          return <div className='title'>{`${taskPrefix}${item.number}`}</div>;
        },
      },
      priority: {
        title: "Priority",
        key: "priority",
        render: (item) => {
          return <div className='title'>{item?.priority?.value}</div>;
        },
      },
      active: {
        title: "Active",
        key: "active",
        render: (item) => {
          return <div className='title'>{item?.active ? "Yes" : "No"}</div>;
        },
      },
      state: {
        title: "State",
        key: "state",
        render: (item) => {
          return <div className='title'>{item?.state?.value}</div>;
        },
      },
      urgency: {
        title: "Urgency",
        key: "urgency",
        render: (item) => {
          return <div className='title'>{item?.urgency?.value}</div>;
        },
      },
      type: {
        title: "Type",
        key: "type",
        render: (item) => {
          return <div className='title'>{item.type?.value || "-"}</div>;
        },
      },
      assignedTo: {
        title: "Assigned To",
        key: "assignedTo",
        render: (item) => {
          return <div className='title'>{item?.assignedTo?.name}</div>;
        },
      },

      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",

        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",

        render: (item) => {
          return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",

        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",

        render: (item) => {
          return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = taskListConfigs;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [taskAssociatedRecord, taskListConfigs, taskPrefix]);

  useEffect(() => {
    fetchTasks({});
    // Do not include getTask to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={tasksHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={taskPublicFields}
      handleTableConfigs={handleTableConfigs}
      filters={tasksFilterFields}
      selectedConfigFields={taskListConfigs}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickTask}
      headerTitle={strings.headerTitle.task}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apTask.search}
      // onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
// <TableList
//   isRelatedList={!!relatedList?.type}
//   rightButton={strings.common.add}
//   rightButtonPress={onClickAdd}
//   onClickTableRow={onClickTask}
//   headerTitle={strings.headerTitle.task}
//   list={list}
//   columns={columns}
//   metaData={metaData}
//   onPageChange={handlePageChange}
//   loading={loading}
//   searchPlaceholder={strings.apTask.search}
//   // onSearch={onSearch}
//   clearSearch={handleClearSearch}
//   searchValue={search}
//   onSearchChange={onSearchChange}
// />
