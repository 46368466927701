import React from "react";

import { Layout } from "../../../presentationals";
import { EducationDetailsController } from "../../../presentationals/screens";

export const APEducationDetailsEdit = () => {
  return (
    <Layout activity={<EducationDetailsController type={"edit"} />} admin={true} layout={"2"} />
  );
};
