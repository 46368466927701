import styled from "styled-components";

import { fonts, colors } from "../../services/theme";

const styles = {};

styles.MainContainer = styled.div`
  height: 100%;
`;

styles.Header = styled.div`
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }
  align-items: center;
  margin-bottom: 1vh;
`;

styles.TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1vh;
`;

styles.TabItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 7px;
  margin: 2px;
  background-color: ${(props) => (props.active ? "rgba(0, 128, 0, 0.2)" : colors.secondary)};
  font-family: ${fonts.font1};
  font-size: 10px;
  color: ${(props) => (props.active ? "black" : colors.secondary_text)};
  cursor: ${(props) => (props.active ? "" : "pointer")};
`;

styles.Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
`;
styles.Column = styled.div`
  flex: 1;
`;

export default styles;
