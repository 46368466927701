import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ITNServicesList,
  AddITNServices,
  UpdateITNServices,
  FindITNServices,
  DeleteITNServices,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchITNServicesListSuccess, fetchITNServicesError } from "./actions";
import Types from "./actionType";
import {
  CreateITNServicesuccess,
  CreateITNServices as CreateITNServicesType,
  ITNServicesSuccess,
  UpdateITNServices as UpdateITNServicesType,
  FetchITNServices,
  FetchITNServicesById,
  ITNServicesByIdSuccess,
  DeleteITNServices as DeleteITNServicesType,
} from "./types";

function* createWorker(action: CreateITNServicesType) {
  try {
    const options = {
      method: "POST",
      url: AddITNServices,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateITNServicesuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateITNServicesType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateITNServices(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateITNServicesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchITNServices) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ITNServicesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ITNServicesSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchITNServicesListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchITNServicesError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching ITNServices");
    }
  }
}

function* fetchITNServicesById(action: FetchITNServicesById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindITNServices(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<ITNServicesByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching ITNServices");
    }
  }
}

function* deleteWorker(action: DeleteITNServicesType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteITNServices(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateITNServicesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ITNSERVICES, createWorker);
  yield takeLatest(Types.UPDATE_ITNSERVICES, updateWorker);
  yield takeEvery(Types.FETCH_ITNSERVICES, fetchAllWorker);
  yield takeLatest(Types.DELETE_ITNSERVICES, deleteWorker);
  yield takeEvery(Types.FETCH_ITNSERVICES_BY_ID, fetchITNServicesById);
}
