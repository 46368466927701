import Types from "./actionType";
import {
  FetchITNServicesPayload,
  CreateITNServices,
  CreateITNServicesPayload,
  FetchITNServices,
  FetchITNServicesError,
  FetchITNServicesSuccess,
  UpdateITNServices,
  UpdateITNServicesPayload,
  ITNServicesListWithMetaData,
  FetchITNServicesByIdPayload,
  FetchITNServicesById,
  DeleteITNServicesPayload,
  DeleteITNServices,
} from "./types";

export const fetchITNServicesList = ({
  params,
  onSuccess,
  onError,
}: FetchITNServicesPayload): FetchITNServices => ({
  type: Types.FETCH_ITNSERVICES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchITNServicesById = ({
  id,
  onSuccess,
  onError,
}: FetchITNServicesByIdPayload): FetchITNServicesById => ({
  type: Types.FETCH_ITNSERVICES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchITNServicesListSuccess = (
  data: ITNServicesListWithMetaData,
): FetchITNServicesSuccess => ({
  type: Types.SUCCESS_FETCH_ITNSERVICES,
  payload: {
    data,
  },
});

export const fetchITNServicesError = (error?: string): FetchITNServicesError => ({
  type: Types.ERROR_FETCH_ITNSERVICES,
  payload: { error },
});

export const createITNServices = ({
  data,
  onSuccess,
  onError,
}: CreateITNServicesPayload): CreateITNServices => ({
  type: Types.CREATE_ITNSERVICES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateITNServices = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateITNServicesPayload): UpdateITNServices => ({
  type: Types.UPDATE_ITNSERVICES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteITNServices = ({
  id,
  onSuccess,
  onError,
}: DeleteITNServicesPayload): DeleteITNServices => ({
  type: Types.DELETE_ITNSERVICES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
