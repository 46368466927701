import Types from "./actionType";
import { CertificationsAccreditationsActions, CertificationsAccreditationsState } from "./types";

const initialState: CertificationsAccreditationsState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const CertificationsAccreditationsReducer = (
  state = initialState,
  action: CertificationsAccreditationsActions,
) => {
  switch (action.type) {
    case Types.FETCH_CERTIFICATIONS_ACCREDITATIONS:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_CERTIFICATIONS_ACCREDITATIONS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
