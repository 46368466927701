import Types from "./types";

export const organisationsFetch = ({ onSuccess, onError, isOG }) => ({
  type: Types.ORGANISATIONS_FETCH,
  payload: {
    onSuccess: onSuccess,
    onError: onError,
    isOG: isOG,
  },
});

export const organisationsFetchSuccess = (list: any) => ({
  type: Types.ORGANISATIONS_FETCH_SUCCESS,
  payload: list,
});

export const organisationsAdd = ({ data, onSuccess, onError }) => ({
  type: Types.ORGANISATIONS_ADD,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const organisationsUpdate = ({ id, data, onSuccess, onError }) => ({
  type: Types.ORGANISATIONS_UPDATE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const filterOrganisation = ({ field, value }) => ({
  type: Types.ORGANISATIONS_FILTERS_UPDATE,
  payload: {
    field: field,
    value: value,
  },
});
