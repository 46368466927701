import Types from "./actionType";
import {
  FetchForm16Payload,
  CreateForm16,
  CreateForm16Payload,
  Form16List,
  FetchForm16,
  FetchForm16Error,
  FetchForm16Success,
  UpdateForm16,
  UpdateForm16Payload,
  Form16ListWithMetaData,
  FetchForm16ByIdPayload,
  FetchForm16ById,
  DeleteForm16Payload,
  DeleteForm16,
} from "./types";

export const fetchForm16List = ({
  params,
  onSuccess,
  onError,
}: FetchForm16Payload): FetchForm16 => ({
  type: Types.FETCH_FORM16,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchForm16ById = ({
  id,
  onSuccess,
  onError,
}: FetchForm16ByIdPayload): FetchForm16ById => ({
  type: Types.FETCH_FORM16_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchForm16ListSuccess = (data: Form16ListWithMetaData): FetchForm16Success => ({
  type: Types.SUCCESS_FETCH_FORM16,
  payload: {
    data,
  },
});

export const fetchForm16Error = (error?: string): FetchForm16Error => ({
  type: Types.ERROR_FETCH_FORM16,
  payload: { error },
});

export const createForm16 = ({ data, onSuccess, onError }: CreateForm16Payload): CreateForm16 => ({
  type: Types.CREATE_FORM16,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateForm16 = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateForm16Payload): UpdateForm16 => ({
  type: Types.UPDATE_FORM16,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteForm16 = ({ id, onSuccess, onError }: DeleteForm16Payload): DeleteForm16 => ({
  type: Types.DELETE_FORM16,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
