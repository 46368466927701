import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateTableConfigs,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  deleteNewsletterResources,
  fetchNewsletterResourcesList,
  updateNewsletterResources,
} from "../../../../../redux/NewsletterResources/actions";
import {
  NewsletterResourcesListWithMetaData,
  FetchNewsletterResourcesParams,
  NewsletterResourcesList,
  DeleteNewsletterResourcesPayload,
} from "../../../../../redux/NewsletterResources/types";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { NewsletterResourcesStatus } from "../../../../../utils/constants";
import { getNewsletterResourcesColumns } from "../../../../columns/NewsletterResources";
import { useControllerConfigs } from "../../../../common";

import {
  fetchNewsletterResourcesProps,
  useNewsletterResourcesListProps,
  useNewsletterResourcesListReturnType,
} from "./../../../../../types/models/NewsletterResources";

const { add, edit } = AppRouteLinks.newsletterResources;
const defaultFilters: QueryBuilderType[] = [];

export const useNewsletterResourcesList = ({
  relatedList,
  widgetId,
}: useNewsletterResourcesListProps): useNewsletterResourcesListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.NewsletterResourcesReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const newsletterResources = useAppSelector((state) => state.NewsletterResourcesReducer.data.list);
  const { usersOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const NewsletterResourcesConfigTableFields = useMemo(() => {
    return tableConfiguration?.newsletterResources?.fields?.length
      ? tableConfiguration?.newsletterResources?.fields
      : tableListDefaultSelectFields.newsletterResources;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = NewsletterResourcesConfigTableFields;
    return getNewsletterResourcesColumns({ fields });
  }, [NewsletterResourcesConfigTableFields]);

  // ================ Export Record Method ================

  const exportRecords = useCallback(
    async (records: NewsletterResourcesList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.newsletterResources?.fields ||
        tableListDefaultSelectFields.newsletterResources;
      records = records?.map((item) => {
        const newsletterResourcesItem = { ...item };
        if (userPersonalizedFields?.includes("createdAt")) {
          newsletterResourcesItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          newsletterResourcesItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          newsletterResourcesItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          newsletterResourcesItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete newsletterResourcesItem?._id;
        return newsletterResourcesItem;
      });
      const newsletterResourcesFields = tablePublicFields.newsletterResources;
      const columnHeaders = newsletterResourcesFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "newsletterResources");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.newsletterResources?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const newsletterResourcesFetchSuccess = useCallback(
    (data: NewsletterResourcesListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const newsletterResourcesFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchNewsletterResources = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchNewsletterResourcesProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }

      const currentPage = metaData?.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.newsletterResources?.limit || 10;

      const params: FetchNewsletterResourcesParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: NewsletterResourcesListWithMetaData) =>
          newsletterResourcesFetchSuccess(data, exportAll),
        onError: newsletterResourcesFetchError,
      };
      dispatch(fetchNewsletterResourcesList(payload));
    },
    [
      widgetId,
      widgetsData,
      dispatch,
      newsletterResourcesFetchError,
      newsletterResourcesFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = newsletterResources?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, newsletterResources, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchNewsletterResources({ exportAll: true });
    },
    [fetchNewsletterResources],
  );

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchNewsletterResources({ page });
  }, [fetchNewsletterResources, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchNewsletterResources({ page: parseInt(page), search });
    },
    [fetchNewsletterResources, search],
  );

  const onClickNewsletterResources = useCallback(
    (id: string) => {
      if (permissions?.newsletterResources_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.newsletterResources_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchNewsletterResources({ page: 1, search });
  }, [fetchNewsletterResources, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchNewsletterResources({ page: 1 });
  }, [fetchNewsletterResources]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchNewsletterResources({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchNewsletterResources],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchNewsletterResources({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchNewsletterResources],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = newsletterResources?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, newsletterResources]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            newsletterResources?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, newsletterResources, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const newsletterResourcesConfigLimit = tableConfiguration?.newsletterResources?.limit || 10;
      const newsletterResourcesConfigs = {
        table: "newsletterResources",
        fields: fields,
        limit: newsletterResourcesConfigLimit,
      };
      const payload = {
        tableConfiguration: newsletterResourcesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchNewsletterResources({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NewsletterResources table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      tableConfiguration?.newsletterResources?.limit,
      dispatch,
      fetchNewsletterResources,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const newsletterResourcesConfigFields = tableConfiguration?.newsletterResources?.fields;
      setLoading(true);
      const newsletterResourcesConfigs = {
        table: "newsletterResources",
        fields: newsletterResourcesConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: newsletterResourcesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchNewsletterResources({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NewsletterResources table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchNewsletterResources],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any, type?: "text" | "date" | "bigTextArea" | "", isMulti?: boolean) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }

      dispatch(showInAppLoader(true));
      const selectedRecords = newsletterResources?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });

      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {},
        onSuccess: () => {
          setLoading(false);
          fetchNewsletterResources({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "NewsletterResources updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating NewsletterResources",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };

      // Handle special case for countries with multi-select
      if (isMulti) {
        payload.data[field] = value.map((item) => item.value);
      } else {
        payload.data[field] = value;
      }

      dispatch(updateNewsletterResources(payload));
    },
    [selectedRows, newsletterResources, dispatch, notificationsId, fetchNewsletterResources],
  );

  // ================ Delete Method ================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = newsletterResources?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteNewsletterResourcesPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchNewsletterResources({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteNewsletterResources(payload));
  }, [selectedRows, newsletterResources, dispatch, notificationsId, fetchNewsletterResources]);

  // ================ Table Header Buttons ================

  const listActionOptions: ListActions[] = [
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "status",
      label: "Status",
      options: NewsletterResourcesStatus,
    },
    {
      name: "body",
      label: "Body Text Fields",
      type: "text",
      required: true,
    },
    {
      name: "subject",
      label: "Subject Text Fields",
      type: "text",
      required: true,
    },
    {
      name: "approvalBy",
      label: "Approval By",
      type: "text",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const newsletterResourcesFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");

    const fields: Field[] = [
      {
        name: "name",
        label: "Name",
        inputType: "string",
        validator,
      },
      {
        name: "status",
        label: "Status",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "select",
        values: [
          { name: "draft", label: "Draft" },
          { name: "inreview", label: "In Review" },
          { name: "published", label: "Published" },
          { name: "sent", label: "Sent" },
        ],
      },
      {
        name: "body",
        label: "Body Text Fields",
        inputType: "string",
        validator,
      },
      {
        name: "subject",
        label: "Subject Text Fields",
        inputType: "string",
        validator,
      },
      {
        name: "approvalBy",
        label: "Approval By",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions]);

  return {
    listActionOptions,
    newsletterResourcesFilterFields,
    onClickNewsletterResources,
    fetchNewsletterResources,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onSubmitDeleteRecord,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
    columns,
    NewsletterResourcesConfigTableFields,
  };
};
