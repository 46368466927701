import React, { useEffect } from "react";

import { MdEdit, MdLockOutline } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import { fetchModules } from "../../redux/modules/actions";
import { MomentDate, useTitle, Sort } from "../../services/methods";

import styles from "./ModulesListing.styles";

const ModulesListing = (props: any) => {
  const navigate = useNavigate();
  useTitle("Tags");

  useEffect(() => {
    props.fetchModules({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = () => {
    console.log(props.data);
    const name = props.filters.name.split(" ").join("").toLowerCase();
    let tempData = props.data.filter((item) =>
      item.name.split(" ").join("").toLowerCase().includes(name),
    );
    const sortKey = props.filters.sortKey.key;
    const isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    const attribute = props.filters.sortKey.attribute;
    if (tempData?.[0]?.[sortKey]) {
      tempData = Sort({
        data: tempData,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return tempData;
  };

  return (
    <styles.MainContainer>
      <Header
        title='Tags'
        buttons={[
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/modules",
            },
            visible: true,
          },
          {
            title: "Add",
            onPress: {
              event: navigate,
              params: "/ap/modules/add",
            },
            visible: props.permissions.modules_add,
          },
        ]}
        onSync={{
          event: props.fetchModules,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='10%'>
              Sno.
            </th>
            <th align='left' width='20%'>
              Name
            </th>
            <th align='left' width='25%'>
              Active
            </th>
            <th align='left' width='20%'>
              -
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData()?.map((module, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{module.name}</td>
              <td>{module.active ? "Yes" : "No"}</td>
              <td>
                <Tooltip
                  text={"Edit module"}
                  copy={false}
                  element={
                    <MdEdit
                      className='edit'
                      onClick={() => navigate(`/ap/modules/${module._id}/edit`)}
                      size={15}
                    />
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.ModulesReducer.filters,
  user: state.UserReducer,
  permissions: state.UserReducer.permissions,
  data: state.ModulesReducer.list,
});

const mapDispatchToProps = {
  fetchModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulesListing);
