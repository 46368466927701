import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { AcademyCandidateEnrollmentsListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useAcademyCandidateEnrollmentsList } from "./hooks";

export const AcademyCandidateEnrollmentsList = ({
  relatedList,
}: AcademyCandidateEnrollmentsListProps) => {
  const academyCandidateEnrollments = useAppSelector(
    (state) => state.AcademyCandidateEnrollmentsReducer.data,
  );
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = academyCandidateEnrollments;
  const {
    fetchAcademyCandidateEnrollments,
    loading,
    handlePageChange,
    onClickAcademyCandidateEnrollments,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    columns,
    academyCandidateEnrollmentsTableConfigFields,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyCandidateEnrollmentsFilterFields,
    academyCandidateEnrollmentsHeaderButtons,
    exportSelectedRecords,
    exportAllRecords,
    onSubmitDeleteRecord,
  } = useAcademyCandidateEnrollmentsList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchAcademyCandidateEnrollments({});
    // Do not include fetchAcademyCandidateEnrollments to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.academyCandidateEnrollments_delete}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={academyCandidateEnrollmentsHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.academyCandidateEnrollments}
      handleTableConfigs={handleTableConfigs}
      filters={academyCandidateEnrollmentsFilterFields}
      selectedConfigFields={academyCandidateEnrollmentsTableConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickAcademyCandidateEnrollments}
      headerTitle={strings.headerTitle.academyCandidateEnrollments}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apAcademyCandidateEnrollments.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
