import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  TaskList,
  AddTask,
  UpdateTask,
  FindTask,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchTaskListSuccess, fetchTaskError } from "./actions";
import Types from "./actionType";
import {
  CreateTaskuccess,
  CreateTask as CreateTaskType,
  TaskSuccess,
  UpdateTask as UpdateTaskType,
  FetchTask,
  FetchTaskById,
  TaskByIdSuccess,
} from "./types";

function* createWorker(action: CreateTaskType) {
  try {
    const params = {
      associatedTo: action.payload.associatedTo,
    };
    const options = {
      method: "POST",
      url: AddTask,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
      params,
    };
    const response: AxiosResponse<CreateTaskuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* updateWorker(action: UpdateTaskType) {
  try {
    const params = {
      associatedTo: action.payload.associatedTo,
    };
    const options = {
      method: "PUT",
      url: UpdateTask(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
      params,
    };
    const response: AxiosResponse<CreateTaskuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchTask) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: TaskList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<TaskSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchTaskListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchTaskError(error.response?.data.message));
  }
}

function* fetchTaskById(action: FetchTaskById) {
  try {
    const params = {
      associatedTo: action.payload.associatedTo,
    };
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindTask(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<TaskByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_LEAVES, createWorker);
  yield takeLatest(Types.UPDATE_LEAVES, updateWorker);
  yield takeEvery(Types.FETCH_LEAVES, fetchAllWorker);
  yield takeEvery(Types.FETCH_LEAVES_BY_ID, fetchTaskById);
}
