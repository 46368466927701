import React, { useEffect } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { EmployeeDesignations } from "../../../../types";
import { ActiveSelectOptions } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { CloseIcon } from "../../../common/styles";
import { Button, Popup, Select, TableList, TextInput } from "../../../components";

import { useEmployeeSalaryDetails } from "./hooks/useSalaryDetails";
import { FormTitle, Row, SalaryFieldsContainer, SalaryModalContainer } from "./styles";

interface Props {
  name: string;
  salaryDetails: EmployeeDesignations[];
  employeeId: string;
  fetchEmployees: () => void;
}

export const EmployeeSalaryDetails = ({
  employeeId,
  name,
  salaryDetails,
  fetchEmployees,
}: Props) => {
  //   const loading = useAppSelector((state) => state.UsersReducer.loading);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const employees = useAppSelector((state) => state.EmployeesReducer.data);
  const {
    handleSalaryModal,
    salaryModal,
    addSalaryModal,
    clearFormData,
    editSalaryModal,
    onDateChange,
    onTextChange,
    formData,
    onSubmitAdd,
    onSubmitUpdate,
  } = useEmployeeSalaryDetails({ salaryDetails, fetchEmployees, employeeId });
  const { designationsOptions, currencyOptions } = useControllerConfigs();

  const { addSalary, editSalary } = strings.apEmployees;

  const columns = [
    {
      title: "Serial",
      width: "10%",
      key: "serial",
    },
    {
      title: "Date",
      width: "20%",
      render: (item) => {
        return <div className='title'>{MomentDate({ date: item.date, format: "YYYY-MM-DD" })}</div>;
      },
    },
    {
      title: "Name",
      width: "20%",
      render: () => {
        return <div className='title'>{name}</div>;
      },
    },
    {
      title: "Current Salary",
      width: "20%",
      render: (item) => {
        return <div className='title'>{item?.currentSalary ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Designation",
      width: "20%",
      render: (item) => {
        return <div className='title'>{item.designation?.name}</div>;
      },
    },
    {
      title: "Base Salary",
      width: "10%",
      center: true,
      render: (item) => {
        return <div className='title'>{item.salaryBase}</div>;
      },
    },
  ];

  const {
    date,
    designation,
    salaryBase,
    salaryBonus,
    salaryCurrency,
    salaryPayroll,
    currentSalary,
  } = formData;
  useEffect(() => {
    // if (!employees?.list.length) {
    //   getEmployees({});
    // }
  }, []);

  return (
    <>
      <TableList
        // headerTitle={strings.headerTitle.employees}
        rightButton={strings.common.addNew}
        rightButtonPress={addSalaryModal}
        // filterButton={strings.common.filter}
        // filterButtonPress={onClickFilter}
        loading={false}
        list={salaryDetails}
        columns={columns}
        // onPageChange={handlePageChange}
        onClickTableRow={editSalaryModal}
        // metaData={employees?.metaData}
      />
      <Popup isOpen={!!salaryModal} closeModal={handleSalaryModal}>
        <SalaryModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleSalaryModal} />
          <FormTitle>
            {salaryModal === "add" ? addSalary : salaryModal === "edit" ? editSalary : ""}
          </FormTitle>
          <SalaryFieldsContainer>
            <Row>
              <Select
                width={"48%"}
                name={"designation"}
                label={"Designation"}
                options={designationsOptions}
                value={designation.value}
                onChange={onTextChange}
                mandatory={designation.required}
                error={designation.error}
              />
              <TextInput
                label='Date'
                type={"date"}
                width='48%'
                error={date.error}
                onDateChange={onDateChange}
                datePickerProps={{
                  name: "date",
                  value: date.value,
                  required: date.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <TextInput
                label={"Salary Base"}
                type={"text"}
                width='48%'
                error={salaryBase.error}
                inputProps={{
                  name: "salaryBase",
                  type: "number",
                  value: salaryBase.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: salaryBase.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Salary Bounus"}
                type={"text"}
                width='48%'
                error={salaryBase.error}
                inputProps={{
                  name: "salaryBonus",
                  type: "number",
                  value: salaryBonus.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: salaryBonus.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <Select
                width={"48%"}
                name={"salaryCurrency"}
                label={"Currency"}
                options={currencyOptions}
                value={salaryCurrency.value}
                onChange={onTextChange}
                mandatory={salaryCurrency.required}
                error={salaryCurrency.error}
              />
              <Select
                width={"48%"}
                name={"salaryPayroll"}
                label={"Freqency"}
                options={getAttributValues("salaryPayroll")}
                value={salaryPayroll.value}
                onChange={onTextChange}
                mandatory={salaryPayroll.required}
                error={salaryPayroll.error}
              />
            </Row>
            <Select
              width={"48%"}
              name={"currentSalary"}
              label={"Current Salary"}
              options={ActiveSelectOptions}
              value={currentSalary.value}
              onChange={onTextChange}
              mandatory={currentSalary.required}
              error={currentSalary.error}
            />
          </SalaryFieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={salaryModal === "add" ? strings.common.add : strings.common.save}
            onClick={salaryModal === "add" ? onSubmitAdd : onSubmitUpdate}
          />
        </SalaryModalContainer>
      </Popup>
    </>
  );
};
