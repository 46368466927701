import React, { useEffect, useState } from "react";

import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import ModalComponent from "../../components/Modal/Modal";
import Select from "../../components/Select/select";
import StyledTable from "../../components/Table/tables.styles";
import { Header } from "../../presentationals/components";
import { uploadFile } from "../../redux/application/actions";
import {
  createComment,
  createTicket,
  getAllTickets,
  updateTicket,
} from "../../redux/tickets/actions";
import {
  useTitle,
  AttributesValues,
  serialPrefixTickets,
  MomentDate,
} from "../../services/methods";
import { strings } from "../../theme";

import "suneditor/dist/css/suneditor.min.css";

import styles from "./TicketsDetails.styles";

const TicketsDetailsAdmin = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [id, setId] = useState("");
  const [data, setData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [assignee, setAssignee] = useState(null);
  const [requestedFor, setRequestedFor] = useState(null);

  useTitle("Tickets Details");

  useEffect(() => {
    if (props.tickets.length > 0 && params.id !== id) {
      const find = props.tickets.find((item) => String(item._id) === params.id);
      if (find) {
        setId(params.id);
        setData(find);
        setAssignee(find?.assignee?._id);
        setRequestedFor(find?.userId?._id);
      }
    }
  }, [props.tickets, params.id, id]);

  const onTextChange = (e) => {
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    setComment(value);
  };

  const StatusRender = (status) => {
    let output = "";
    switch (status) {
      case "awaiting_reply":
        output = "Replied";
        break;
      case "replied":
        output = "Awaiting Reply";
        break;
      case "closed":
        output = "Ticket Closed";
        break;
      default:
        break;
    }
    return output;
  };

  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };
  const onAssigneeChange = (e) => {
    setAssignee(e.target.value);
    props.updateTicket({
      id: id,
      data: {
        assignee: e.target.value,
      },
      onError: (err) => {
        alert(err);
      },
    });
  };

  const onRequestedForChange = (e) => {
    setRequestedFor(e.target.value);
    props.updateTicket({
      id: id,
      data: {
        userId: e.target.value,
      },
      onError: (err) => {
        alert(err);
      },
    });
  };

  const handleSubmit = (e) => {
    const dataStatus = e.currentTarget.getAttribute("data-status");
    const status = data.userId._id === props.UserReducer.id ? "awaiting_reply" : "replied";
    setSubmitting(true);
    const object = {
      id: id,
      status: dataStatus ? dataStatus : status,
      comment: comment,
      onSuccess: (data) => {
        setSubmitting(false);
        setData(data.data);
        setAssignee(data?.data?.assignee?._id);
        setComment("");
      },
      onError: (err) => {
        setSubmitting(false);
        alert(err);
      },
    };
    props.createComment(object);
  };

  return (
    <styles.MainContainer>
      <Header title={strings.headerTitle.job} />
      <styles.Header>
        <Link to={"/ap/tickets"}>
          <MdArrowBack size={"30"} />
        </Link>
        <h2>Service Request Details</h2>
      </styles.Header>
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Created By</th>
            <th>Subject</th>
            <th>Topic</th>
            <th>Priority</th>
            <th>Status</th>
            <th>Created Date</th>
            <th>Comment Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serialPrefixTickets(data?.serial)}</td>
            <td>
              {data?.userId?.name} ({data?.userId?._id?.slice(-5)})
            </td>
            <td>{data?.subject}</td>
            <td>{AttributesValues("requestTicketTopic", data?.topic)}</td>
            <td>{AttributesValues("priority", data?.priority)}</td>
            <td>{StatusRender(data?.status)}</td>
            <td>
              {data.createdAt
                ? MomentDate({
                    date: data?.createdAt,
                    format: "YYYY-MM-DD",
                  })
                : "-"}
            </td>
            <td>
              {" "}
              {data.commentDate
                ? MomentDate({
                    date: data?.commentDate,
                    format: "YYYY-MM-DD",
                  })
                : "-"}
            </td>
          </tr>
        </tbody>
      </StyledTable>
      <br />
      <styles.Row desc={true}>
        <styles.Column>
          <styles.DescContainer>
            <h3>Description</h3>
            <styles.Container desc={true}>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </styles.Container>
          </styles.DescContainer>
        </styles.Column>
        <styles.Column>
          <Select
            readOnly={false}
            label={"Assignee"}
            options={props.users.map((value, index) => ({
              label: value.name,
              value: value._id,
            }))}
            value={assignee}
            onChange={onAssigneeChange}
          />
          <Select
            readOnly={false}
            label={"Requested For"}
            options={props.users.map((value, index) => ({
              label: value.name,
              value: value._id,
            }))}
            value={requestedFor}
            onChange={onRequestedForChange}
          />
        </styles.Column>
        <styles.Column>
          <Input
            readOnly={false}
            label={"Comment"}
            type={"textarea"}
            name='subject'
            autoComplete={"off"}
            onChange={onTextChange}
            value={comment}
          />
          <styles.Row>
            <styles.Column>
              <Button
                onClick={handleSubmit}
                disabled={submitting || comment.length === 0}
                type={"cancel"}
                size={"normal"}
                label={"Comment & Close"}
                attributes={{ "data-status": "closed" }}
              />
            </styles.Column>
            <styles.Column>
              <Button
                onClick={handleSubmit}
                disabled={submitting || comment.length === 0}
                size={"normal"}
                label={data.status === "closed" ? "Comment & Reopen Ticket" : "Send Comment"}
              />
            </styles.Column>
          </styles.Row>
        </styles.Column>
      </styles.Row>

      {data?.attachments?.length > 0 && (
        <>
          <h3>Attachments</h3>
          <styles.Container>
            {data?.attachments?.map((attachment, index) => {
              return (
                <styles.attachment key={index} onClick={() => getDocument(attachment.attachment)}>
                  {attachment.name || attachment.attachment}
                </styles.attachment>
              );
            })}
          </styles.Container>
          <br />
        </>
      )}

      <br />
      {data?.comments?.length > 0 && (
        <>
          <h3>Comments</h3>
          {data?.comments?.map((comment, index) => {
            return (
              <styles.CommentContainer
                othersComment={comment?.userId?._id !== data.userId._id}
                key={index}
                onClick={() => console.log(comment)}
              >
                <styles.CommentName>
                  {comment?.userId?.name}{" "}
                  <span>
                    {MomentDate({
                      date: comment.date,
                      format: "YYYY-MM-DD HH:mm",
                    })}
                  </span>
                </styles.CommentName>

                <br />
                <styles.comment>{comment.comment}</styles.comment>
              </styles.CommentContainer>
            );
          })}
          <br />
          <br />
        </>
      )}

      <br />
      <br />
      <br />
      <br />

      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <Document id={documentId} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  users: state.UsersReducer.openList,
  tickets: state.TicketsReducer.ticketsAdmin,
});

const mapDispatchToProps = {
  uploadFile,
  createTicket,
  updateTicket,
  getAllTickets,
  createComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDetailsAdmin);
