import React from "react";

import { Layout } from "../../../presentationals";
import { CompanyContactList as CompanyContactListScreen } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CompanyContactListScreen />} admin={true} layout={"2"} />;
};

export const APCompanyContactList = React.memo(Route);
