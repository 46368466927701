import Types from "./actionType";
import { CatalogAdministrationsActions, CatalogAdministrationsState } from "./types";

const initialState: CatalogAdministrationsState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const CatalogAdministrationsReducer = (
  state = initialState,
  action: CatalogAdministrationsActions,
) => {
  switch (action.type) {
    case Types.FETCH_CATALOG_ADMINISTRATION:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_CATALOG_ADMINISTRATION:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
