import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getEmailSchedularColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    name: {
      title: "Name",
      sortSupported: true,
      searchSupported: true,
      key: "name",
    },
    active: {
      title: "Active",
      key: "active",
      render: (item) => {
        return <text className='title'>{item.active ? "Yes" : "No"}</text>;
      },
    },
    frequency: {
      title: "Frequency",
      sortSupported: true,
      key: "frequency",
    },
    time: {
      title: "Time",
      sortSupported: true,
      key: "time",
    },
    day: {
      title: "Day",
      sortSupported: true,
      key: "day",
    },
    date: {
      title: "Date",
      sortSupported: true,
      key: "date",
    },
    repeatInterval: {
      title: "Repeat Interval",
      sortSupported: true,
      key: "repeatInterval",
    },

    starting: {
      title: "Starting",
      sortSupported: true,
      key: "starting",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.starting || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },

    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
