import React from "react";

import "./Tooltip.css";
import { TooltipBox, TooltipCard, TooltipText } from "./Tooltip.styles";

const Tooltip = ({ text = "", copy = true, element = "?" }) => {
  const copyToClipboard = () => {
    // textArea.current.select();
    // document.execCommand("copy");
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return (
    <TooltipCard>
      <TooltipText onClick={copyToClipboard}>{element}</TooltipText>
      <TooltipBox>
        {text}
        {copy && (
          <>
            <br />
            <br />
            Tap To Copy
          </>
        )}
      </TooltipBox>
    </TooltipCard>
  );
};

export default Tooltip;
