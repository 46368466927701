import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import Document from "../../../../components/Document/Document";
import { useAppSelector } from "../../../../redux/hooks";
import {
  MomentDate,
  generateArrayAttributes,
  getAttributValues,
} from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { EmployeesControllerProps } from "../../../../types";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Seperator } from "../../../common/styles";
import {
  ActivityLogs,
  Header,
  Popup,
  TableController,
  TableHeader,
  TableList,
  WorkLogs,
} from "../../../components";
import { TextInput, Select, Button, Checkbox, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyACHList } from "../AcademyACH";
import { AcademyBatchList } from "../AcademyBatch";
import { AcademyCandidateEnrollmentsList } from "../AcademyCandidateEnrollments";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads";
import { AcademyCandidatesList } from "../AcademyCandidates";
import { AcademyCoursesList } from "../AcademyCourses";
import { AcademyMentorFeedbackList } from "../AcademyMentorFeedback";
import { AcademyStudentProfileList } from "../AcademyStudentProfile";
import { AddressHistoryList } from "../AddressHistory";
import { BankDetailsList } from "../BankDetails";
import { BenefitsList } from "../Benefits";
import { CertificationsAccreditationsList } from "../CertificationsAccreditations";
import { EducationDetailsList } from "../EducationDetails";
import { EmploymentHistoryList } from "../EmploymentHistory";
import { Form16List } from "../Form16";
import { PIIDetailsList } from "../PIIDetails";
import { ProjectHistoryList } from "../ProjectHistory";
import { SPSAccountsList } from "../SPSAccounts";
import { SPSProjectsList } from "../SPSProjects";
import { TaskList } from "../Task";

import { EmployeeAppraisalDetails } from "./AppraisalDetails";
import { EmployeeEmergencyContactDetails } from "./EmergencyContacts";
import { useEmployeesController } from "./hooks";
import { EmployeeSalaryDetails } from "./SalaryDetails";
import {
  Container,
  LeftContainer,
  RightContainer,
  StepContainer,
  FormTitle,
  Row,
  FormFieldsContainer,
  FormContainer,
  RelatedListContainer,
  ProfileImageContainer,
  ProfileImage,
  PencilIcon,
  UserNameInitial,
  TimeLineDetailsContainer,
} from "./styles";

export const EmployeesController = ({ type }: EmployeesControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const profilePicPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    clearFormData,
    formData,
    relatedUser,
    onTextChange,
    handleCheckbox,
    loading,
    headerTitle,
    employeeFullName,
    fetchEmployeeDetails,
    onPostWorklog,
    onDateChange,
    employmentDuration,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    emailSignature,
    getStepBadge,
    onProfilePicChange,
    onClickBrowseProfilePic,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useEmployeesController({
    type,
    id: params.id,
    attachmentsPickerRef,
    profilePicPickerRef,
  });

  const { departmentOptions, companyOptions, countryOptions, employeesOptions } =
    useControllerConfigs();

  const {
    firstName,
    active,
    attachments,
    workLogs,
    photo,
    middleName,
    lastName,
    nickName,
    personalEmail,
    officialEmail,
    mobile,
    alternateMobile,
    employmentStatus,
    generatePayrollOffshoreServices,
    employmentType,
    employmentDepartment,
    employmentCompany,
    employeeID,
    payrollCompany,
    employeeIDofPayrollCompany,
    shirtSize,
    offboardType,
    detailsForOffboard,
    resumeAttached,
    coverLettersAttached,
    consultantProfile,
    timelineDetails,
    personalDetails,
    designations,
    appraisals,
    reportingDetails,
    additionalDetails,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  } = formData;

  const { dateJoining, dateHire, dateTermination, probationEndDate, probationStartDate } =
    timelineDetails;
  const {
    personalMartial,
    nationality,
    personalDOB,
    personalGender,
    ethnicity,
    race,
    placeOfBirth,
    countryOfBirth,
    fatherName,
    motherName,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zipCode,
    Country,
  } = personalDetails;

  const { managerLevel, reportingManager, reportingPOD, companyBuddy } = reportingDetails;

  const { externalFolderLink, isEmployeeReferenced, referralEmail, referralMobile, referralName } =
    additionalDetails;

  useEffect(() => {
    if (type === "edit") {
      fetchEmployeeDetails();
    }
    // Do not include fetchEmployeeDetails to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  useEffect(() => {
    clearFormData();
    // Do not include clearFormData to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, params.id]);

  const renderOverview = useCallback(() => {
    console.log("AA ::", relatedUser);
    return (
      <>
        <Row
          style={{
            justifyContent: "center",
          }}
        >
          <input
            type='file'
            name='profilePic'
            ref={profilePicPickerRef}
            onChange={onProfilePicChange}
            hidden={true}
            readOnly={true}
            accept='.jpg,.jpeg,.png'
          />
          <ProfileImageContainer>
            <ProfileImage src={relatedUser?.profilePic?.location || images.defaultProfile} />
            <PencilIcon onClick={onClickBrowseProfilePic} src={images.greenPencil} />
          </ProfileImageContainer>
        </Row>
        <Seperator />
        <Row>
          <TextInput
            label={"Legal Full Name"}
            type={"text"}
            width='100%'
            inputProps={{
              disabled: true,
              value: employeeFullName,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"First Name"}
            type={"text"}
            width='33%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Middle Name"}
            type={"text"}
            width='33%'
            error={middleName.error}
            inputProps={{
              name: "middleName",
              value: middleName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: middleName.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Last Name"}
            type={"text"}
            width='33%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Preffered Name"}
            type={"text"}
            width='48%'
            error={nickName.error}
            inputProps={{
              name: "nickName",
              value: nickName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: nickName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Personal Email"}
            type={"text"}
            width='48%'
            error={personalEmail.error}
            inputProps={{
              name: "personalEmail",
              value: personalEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: personalEmail.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Official Email"}
            type={"text"}
            width='48%'
            error={officialEmail.error}
            inputProps={{
              name: "officialEmail",
              value: officialEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: officialEmail.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Primary Phone"}
            type={"text"}
            width='48%'
            error={mobile.error}
            inputProps={{
              name: "mobile",
              value: mobile.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: mobile.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Alternative Phone"}
            type={"text"}
            width='48%'
            error={alternateMobile.error}
            inputProps={{
              name: "alternateMobile",
              value: alternateMobile.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: alternateMobile.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"employmentStatus"}
            label={"Status"}
            options={getAttributValues("employmentStatus")}
            value={employmentStatus.value}
            onChange={onTextChange}
            mandatory={employmentStatus.required}
            error={employmentStatus.error}
          />
          <Select
            width={"48%"}
            name={"generatePayrollOffshoreServices"}
            label={"Generate Payroll Offshore Services"}
            options={ActiveSelectOptions}
            value={generatePayrollOffshoreServices.value}
            onChange={onTextChange}
            mandatory={generatePayrollOffshoreServices.required}
            error={generatePayrollOffshoreServices.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"employmentType"}
            label={"Employee Type"}
            options={getAttributValues("employmentType")}
            value={employmentType.value}
            onChange={onTextChange}
            mandatory={employmentType.required}
            error={employmentType.error}
          />
          <Select
            width={"48%"}
            name={"employmentDepartment"}
            label={"Department"}
            options={departmentOptions}
            value={employmentDepartment.value}
            onChange={onTextChange}
            mandatory={employmentDepartment.required}
            error={employmentDepartment.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"employmentCompany"}
            label={"Company"}
            options={companyOptions}
            value={employmentCompany.value}
            onChange={onTextChange}
            mandatory={employmentCompany.required}
            error={employmentCompany.error}
          />
          <TextInput
            label={"Employee Id"}
            type={"text"}
            width='48%'
            error={employeeID.error}
            inputProps={{
              name: "employeeID",
              value: employeeID.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: employeeID.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"payrollCompany"}
            label={"Payroll Company"}
            options={companyOptions}
            value={payrollCompany.value}
            onChange={onTextChange}
            mandatory={payrollCompany.required}
            error={payrollCompany.error}
          />
          <TextInput
            label={"Payroll Company's Employee Id"}
            type={"text"}
            width='48%'
            error={employeeIDofPayrollCompany.error}
            inputProps={{
              name: "employeeIDofPayrollCompany",
              value: employeeIDofPayrollCompany.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: employeeIDofPayrollCompany.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"shirtSize"}
            label={"Shirt Size"}
            options={getAttributValues("shirtSize")}
            value={shirtSize.value}
            onChange={onTextChange}
            mandatory={shirtSize.required}
            error={shirtSize.error}
          />
          <Select
            width={"48%"}
            name={"offboardType"}
            label={"Offboard Type"}
            options={getAttributValues("offboardType", true)}
            value={offboardType.value}
            onChange={onTextChange}
            mandatory={offboardType.required}
            error={offboardType.error}
          />
        </Row>
        {!!offboardType.value && (
          <TextInput
            label={"Details for Offboard"}
            type={"textarea"}
            width='100%'
            inputProps={{}}
            error={detailsForOffboard.error}
            textAreaProps={{
              value: detailsForOffboard.value,
              name: "detailsForOffboard",
              rows: 4,
              onChange: onTextChange,
              required: !!offboardType.value,
            }}
          />
        )}
        {type === "edit" && (
          <TextInput
            label={"Email Signature"}
            type={"textarea"}
            width='100%'
            inputProps={{}}
            textAreaProps={{
              rows: 6,
              value: emailSignature,
              disabled: true,
            }}
          />
        )}

        <Row>
          <Checkbox
            label={"Resume Attached"}
            width='33%'
            error={resumeAttached.error}
            required={resumeAttached.required}
            onClick={handleCheckbox}
            value={resumeAttached.value}
            attributes={{
              "data-name": "resumeAttached",
            }}
          />
          <Checkbox
            label={"Cover Letters Attached"}
            width='33%'
            error={coverLettersAttached.error}
            required={coverLettersAttached.required}
            onClick={handleCheckbox}
            value={coverLettersAttached.value}
            attributes={{
              "data-name": "coverLettersAttached",
            }}
          />
          <Checkbox
            label={"Consultant Profile"}
            width='33%'
            error={consultantProfile.error}
            required={consultantProfile.required}
            onClick={handleCheckbox}
            value={consultantProfile.value}
            attributes={{
              "data-name": "consultantProfile",
            }}
          />
        </Row>
      </>
    );
  }, [
    relatedUser,
    onProfilePicChange,
    onClickBrowseProfilePic,
    employeeFullName,
    firstName.error,
    firstName.value,
    firstName.required,
    onTextChange,
    middleName.error,
    middleName.value,
    middleName.required,
    lastName.error,
    lastName.value,
    lastName.required,
    nickName.error,
    nickName.value,
    nickName.required,
    active.value,
    active.required,
    active.error,
    personalEmail.error,
    personalEmail.value,
    personalEmail.required,
    officialEmail.error,
    officialEmail.value,
    officialEmail.required,
    mobile.error,
    mobile.value,
    mobile.required,
    alternateMobile.error,
    alternateMobile.value,
    alternateMobile.required,
    employmentStatus.value,
    employmentStatus.required,
    employmentStatus.error,
    generatePayrollOffshoreServices.value,
    generatePayrollOffshoreServices.required,
    generatePayrollOffshoreServices.error,
    employmentType.value,
    employmentType.required,
    employmentType.error,
    departmentOptions,
    employmentDepartment.value,
    employmentDepartment.required,
    employmentDepartment.error,
    companyOptions,
    employmentCompany.value,
    employmentCompany.required,
    employmentCompany.error,
    employeeID.error,
    employeeID.value,
    employeeID.required,
    payrollCompany.value,
    payrollCompany.required,
    payrollCompany.error,
    employeeIDofPayrollCompany.error,
    employeeIDofPayrollCompany.value,
    employeeIDofPayrollCompany.required,
    shirtSize.value,
    shirtSize.required,
    shirtSize.error,
    offboardType.value,
    offboardType.required,
    offboardType.error,
    detailsForOffboard.error,
    detailsForOffboard.value,
    type,
    emailSignature,
    resumeAttached.error,
    resumeAttached.required,
    resumeAttached.value,
    handleCheckbox,
    coverLettersAttached.error,
    coverLettersAttached.required,
    coverLettersAttached.value,
    consultantProfile.error,
    consultantProfile.required,
    consultantProfile.value,
  ]);
  const renderTimelineDetails = useCallback(() => {
    return (
      <TimeLineDetailsContainer>
        <Row>
          <TextInput
            label='Hire Date'
            type={"date"}
            width='48%'
            error={dateHire.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dateHire",
              value: dateHire.value,
              required: dateHire.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Date of Joining'
            type={"date"}
            width='48%'
            error={dateJoining.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dateJoining",
              value: dateJoining.value,
              minDate: dateHire.value ? new Date(dateHire.value) : undefined,
              required: dateJoining.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Probation Start date'
            type={"date"}
            width='48%'
            error={probationStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              minDate: dateJoining.value ? new Date(dateJoining.value) : undefined,
              name: "probationStartDate",
              value: probationStartDate.value,
              required: probationStartDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Probation End date'
            type={"date"}
            width='48%'
            error={probationEndDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              minDate: probationStartDate.value ? new Date(probationStartDate.value) : undefined,
              name: "probationEndDate",
              value: probationEndDate.value,
              required: probationEndDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Termination Date'
            type={"date"}
            width='48%'
            error={dateTermination.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dateTermination",
              minDate: dateJoining.value ? new Date(dateJoining.value) : undefined,
              value: dateTermination.value,
              required: dateTermination.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Empoyment Duration'
            type={"text"}
            inputProps={{ value: employmentDuration, disabled: true }}
            textAreaProps={{}}
          />
        </Row>
      </TimeLineDetailsContainer>
    );
  }, [
    dateHire.error,
    dateHire.value,
    dateHire.required,
    onDateChange,
    dateJoining.error,
    dateJoining.value,
    dateJoining.required,
    probationStartDate.error,
    probationStartDate.value,
    probationStartDate.required,
    probationEndDate.error,
    probationEndDate.value,
    probationEndDate.required,
    dateTermination.error,
    dateTermination.value,
    dateTermination.required,
    employmentDuration,
  ]);

  const renderPersonalInformation = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"personalMartial"}
            label={"Marital Status"}
            options={getAttributValues("personalMartial")}
            value={personalMartial.value}
            onChange={onTextChange}
            mandatory={personalMartial.required}
            error={personalMartial.error}
          />
          <TextInput
            label='Date of Birth'
            type={"date"}
            width='48%'
            error={personalDOB.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "personalDOB",
              maxDate: new Date(),
              value: personalDOB.value,
              required: personalDOB.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"nationality"}
            label={"Nationality"}
            options={getAttributValues("citizenship")}
            value={nationality.value}
            onChange={onTextChange}
            mandatory={nationality.required}
            error={nationality.error}
          />
          <Select
            width={"48%"}
            name={"personalGender"}
            label={"Gender"}
            options={getAttributValues("gender")}
            value={personalGender.value}
            onChange={onTextChange}
            mandatory={personalGender.required}
            error={personalGender.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"ethnicity"}
            label={"Ethnicity"}
            options={getAttributValues("ethnicity")}
            value={ethnicity.value}
            onChange={onTextChange}
            mandatory={ethnicity.required}
            error={ethnicity.error}
          />
          <Select
            width={"48%"}
            name={"race"}
            label={"Race"}
            options={getAttributValues("race")}
            value={race.value}
            onChange={onTextChange}
            mandatory={race.required}
            error={race.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Place of Birth'
            type={"text"}
            width='48%'
            error={placeOfBirth.error}
            inputProps={{
              name: "placeOfBirth",
              value: placeOfBirth.value,
              required: placeOfBirth.required,
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"countryOfBirth"}
            label={"Country of Birth"}
            options={countryOptions}
            value={countryOfBirth.value}
            onChange={onTextChange}
            mandatory={countryOfBirth.required}
            error={countryOfBirth.error}
          />
        </Row>
        <Row>
          <TextInput
            label={`Father's Name`}
            type={"text"}
            width='48%'
            error={fatherName.error}
            inputProps={{
              name: "fatherName",
              value: fatherName.value,
              required: fatherName.required,
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={`Mother's Name`}
            type={"text"}
            width='48%'
            error={motherName.error}
            inputProps={{
              name: "motherName",
              value: motherName.value,
              required: motherName.required,
              onChange: onTextChange,
            }}
            textAreaProps={{}}
          />
        </Row>

        <Row>
          <TextInput
            label={"Address Line 1"}
            type={"text"}
            width='48%'
            error={addressLineOne.error}
            inputProps={{
              name: "addressLineOne",
              value: addressLineOne.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineOne.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Address Line 2"}
            type={"text"}
            width='48%'
            error={addressLineTwo.error}
            inputProps={{
              name: "addressLineTwo",
              value: addressLineTwo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineTwo.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Postal Zone/ZIP Code"}
            type={"text"}
            width='48%'
            error={zipCode.error}
            inputProps={{
              name: "zipCode",
              type: "number",
              value: zipCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: zipCode.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"Country"}
            label={"Country/Region"}
            options={countryOptions}
            value={Country.value}
            onChange={onTextChange}
            mandatory={Country.required}
            error={Country.error}
          />
        </Row>
      </>
    );
  }, [
    Country.error,
    Country.required,
    Country.value,
    addressLineOne.error,
    addressLineOne.required,
    addressLineOne.value,
    addressLineTwo.error,
    addressLineTwo.required,
    addressLineTwo.value,
    city.error,
    city.required,
    city.value,
    countryOfBirth.error,
    countryOfBirth.required,
    countryOfBirth.value,
    countryOptions,
    ethnicity.error,
    ethnicity.required,
    ethnicity.value,
    fatherName.error,
    fatherName.required,
    fatherName.value,
    motherName.error,
    motherName.required,
    motherName.value,
    nationality.error,
    nationality.required,
    nationality.value,
    onDateChange,
    onTextChange,
    personalDOB.error,
    personalDOB.required,
    personalDOB.value,
    personalGender.error,
    personalGender.required,
    personalGender.value,
    personalMartial.error,
    personalMartial.required,
    personalMartial.value,
    placeOfBirth.error,
    placeOfBirth.required,
    placeOfBirth.value,
    race.error,
    race.required,
    race.value,
    state.error,
    state.required,
    state.value,
    zipCode.error,
    zipCode.required,
    zipCode.value,
  ]);

  const renderAppraisalDetails = useCallback(() => {
    return (
      <EmployeeAppraisalDetails
        firstName={firstName.value}
        lastName={lastName.value}
        appraisals={appraisals.value}
        employeeId={params.id || ""}
        fetchEmployees={fetchEmployeeDetails}
      />
    );
  }, [firstName.value, lastName.value, appraisals.value, params.id, fetchEmployeeDetails]);

  const renderSalaryDetails = useCallback(() => {
    const fullName: string[] = [];
    const { firstName, lastName } = formData;
    if (firstName) {
      fullName.push(firstName.value);
    }
    if (lastName) {
      fullName.push(lastName.value);
    }

    return (
      <EmployeeSalaryDetails
        name={fullName.join(" ")}
        salaryDetails={designations.value}
        employeeId={params.id || ""}
        fetchEmployees={fetchEmployeeDetails}
      />
    );
  }, [formData, designations.value, params.id, fetchEmployeeDetails]);

  const renderReportingDetails = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"managerLevel"}
            label={"Manager Level"}
            options={getAttributValues("managerLevel")}
            value={managerLevel.value}
            onChange={onTextChange}
            mandatory={managerLevel.required}
            error={managerLevel.error}
          />
          <Select
            width={"48%"}
            name={"reportingManager"}
            label={"Reporting Manager"}
            options={employeesOptions}
            value={reportingManager.value}
            onChange={onTextChange}
            mandatory={reportingManager.required}
            error={reportingManager.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"reportingPOD"}
            label={"POD Leader"}
            options={employeesOptions}
            value={reportingPOD.value}
            onChange={onTextChange}
            mandatory={reportingPOD.required}
            error={reportingPOD.error}
          />
          <Select
            width={"48%"}
            name={"companyBuddy"}
            label={"Company Buddy"}
            options={employeesOptions}
            value={companyBuddy.value}
            onChange={onTextChange}
            mandatory={companyBuddy.required}
            error={companyBuddy.error}
          />
        </Row>
      </>
    );
  }, [
    companyBuddy.error,
    companyBuddy.required,
    companyBuddy.value,
    employeesOptions,
    managerLevel.error,
    managerLevel.required,
    managerLevel.value,
    onTextChange,
    reportingManager.error,
    reportingManager.required,
    reportingManager.value,
    reportingPOD.error,
    reportingPOD.required,
    reportingPOD.value,
  ]);

  const renderEmergencyContact = useCallback(() => {
    return (
      <EmployeeEmergencyContactDetails
        firstName={firstName.value}
        lastName={lastName.value}
        emergencyContact={formData.emergencyContact.value}
        employeeId={params.id || ""}
        fetchEmployees={fetchEmployeeDetails}
      />
    );
  }, [
    firstName.value,
    lastName.value,
    formData.emergencyContact.value,
    params.id,
    fetchEmployeeDetails,
  ]);

  const renderAdditionalDetails = useCallback(() => {
    return (
      <>
        <TextInput
          label={"External Folder Link"}
          type={"text"}
          width='100%'
          error={externalFolderLink.error}
          inputProps={{
            name: "externalFolderLink",
            value: externalFolderLink.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: externalFolderLink.required,
          }}
          textAreaProps={{}}
        />
        <Row>
          <Select
            width={"48%"}
            name={"isEmployeeReferenced"}
            label={"Is Emp Referenced"}
            options={ActiveSelectOptions}
            value={isEmployeeReferenced.value}
            onChange={onTextChange}
            mandatory={isEmployeeReferenced.required}
            error={isEmployeeReferenced.error}
          />
          <TextInput
            label={"Name of the Referral "}
            type={"text"}
            width='48%'
            error={referralName.error}
            inputProps={{
              name: "referralName",
              value: referralName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: referralName.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Referral Phone Number"}
            type={"text"}
            width='48%'
            error={referralMobile.error}
            inputProps={{
              name: "referralMobile",
              type: "text",
              value: referralMobile.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: referralMobile.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Referral Email"}
            type={"text"}
            width='48%'
            error={referralEmail.error}
            inputProps={{
              name: "referralEmail",
              value: referralEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: referralEmail.required,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    externalFolderLink.error,
    externalFolderLink.required,
    externalFolderLink.value,
    isEmployeeReferenced.error,
    isEmployeeReferenced.required,
    isEmployeeReferenced.value,
    onTextChange,
    referralEmail.error,
    referralEmail.required,
    referralEmail.value,
    referralMobile.error,
    referralMobile.required,
    referralMobile.value,
    referralName.error,
    referralName.required,
    referralName.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdAtValue = createdAt.value || dateHire.value;
    const updateddAtValue = createdAt.value || dateHire.value;

    return (
      <SystemData
        createdAt={createdAtValue}
        updatedAt={updateddAtValue}
        createdBy={createdBy.value.name}
        updatedBy={updatedBy.value.name}
      />
    );
  }, [createdAt.value, createdBy.value, dateHire.value, updatedBy.value]);

  const renderRelatedBankDetails = useCallback(() => {
    return (
      <RelatedListContainer>
        <BankDetailsList
          listType='associatedEmployee'
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderRelatedPIIDetails = useCallback(() => {
    return (
      <RelatedListContainer>
        <PIIDetailsList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderRelatedCertificationsAccreditations = useCallback(() => {
    return (
      <RelatedListContainer>
        <CertificationsAccreditationsList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderRelatedEducationDetails = useCallback(() => {
    return (
      <RelatedListContainer>
        <EducationDetailsList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedAddressHistory = useCallback(() => {
    return (
      <RelatedListContainer>
        <AddressHistoryList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedEmploymentHistory = useCallback(() => {
    return (
      <RelatedListContainer>
        <EmploymentHistoryList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedBenefits = useCallback(() => {
    return (
      <RelatedListContainer>
        <BenefitsList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedProjectHistory = useCallback(() => {
    return (
      <RelatedListContainer>
        <ProjectHistoryList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedTask = useCallback(() => {
    return (
      <RelatedListContainer>
        <TaskList
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
            associatedTo: "hr",
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);
  const renderRelatedForm16List = useCallback(() => {
    return (
      <RelatedListContainer>
        <Form16List
          relatedList={{
            type: "associatedEmployee",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderRelatedAcademyACH = useMemo(
    () => (
      <AcademyACHList
        relatedList={{
          type: "associatedEmployee",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelatedAcademyBatch = useMemo(
    () => (
      <AcademyBatchList
        relatedList={{
          type: "trainingCordinator",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelatedAcademyCandidates = useMemo(
    () => (
      <AcademyCandidatesList
        relatedList={{
          type: "admissionCounsellor",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaAcademyCandidateLeads = useMemo(
    () => (
      <AcademyCandidateLeadsList
        relatedList={{
          type: "admissionCounsellor",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaAcademyCourses = useMemo(
    () => (
      <AcademyCoursesList
        relatedList={{
          type: "preparedBy",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaAcademyMentorFeedback = useMemo(
    () => (
      <AcademyMentorFeedbackList
        relatedList={{
          type: "mentor",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaAcademyStudentProfile = useMemo(
    () => (
      <AcademyStudentProfileList
        relatedList={{
          type: "studentTrainingCordinator",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaSPSAccounts = useMemo(
    () => (
      <SPSAccountsList
        relatedList={{
          type: "salesRepresentative",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );
  const renderRelaSPSProjects = useMemo(
    () => (
      <SPSProjectsList
        relatedList={{
          type: "projectGivenTo",
          value: params.id,
        }}
      />
    ),
    [params.id],
  );

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.employees.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.employees.name} />,
    [params.id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderTimelineDetails();
      case 2:
        return renderPersonalInformation();
      case 3:
        return renderReportingDetails();
      case 4:
        return renderAdditionalDetails();
      case 5:
        return renderSalaryDetails();
      case 6:
        return renderAppraisalDetails();
      case 7:
        return renderEmergencyContact();
      case 8:
        return renderChecklist;
      case 9:
        return renderActivitylogs;
      case 10:
        return renderWorklogs;
      case 11:
        return renderSystemData();
      case 12:
        return renderRelatedBankDetails();
      case 13:
        return renderRelatedPIIDetails();
      case 14:
        return renderRelatedCertificationsAccreditations();
      case 15:
        return renderRelatedEducationDetails();
      case 16:
        return renderRelatedAddressHistory();
      case 17:
        return renderRelatedEmploymentHistory();
      case 18:
        return renderRelatedBenefits();
      case 19:
        return renderRelatedProjectHistory();
      case 20:
        return renderRelatedTask();
      case 21:
        return renderRelatedForm16List();
      case 22:
        return renderRelatedAcademyACH;
      case 23:
        return renderRelatedAcademyBatch;
      case 24:
        return renderRelatedAcademyCandidates;
      case 25:
        return renderRelaAcademyCandidateLeads;
      case 26:
        return renderRelaAcademyCourses;
      case 27:
        return renderRelaAcademyMentorFeedback;
      case 28:
        return renderRelaAcademyStudentProfile;
      case 29:
        return renderRelaSPSAccounts;
      case 30:
        return renderRelaSPSProjects;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderRelatedAcademyCandidates,
    renderRelaAcademyCandidateLeads,
    renderRelaAcademyCourses,
    renderRelaAcademyMentorFeedback,
    renderRelaAcademyStudentProfile,
    renderRelaSPSAccounts,
    renderRelaSPSProjects,
    renderRelatedAcademyBatch,
    renderRelatedAcademyACH,
    renderOverview,
    renderSystemData,
    renderRelatedBankDetails,
    renderTimelineDetails,
    renderActivitylogs,
    renderPersonalInformation,
    renderSalaryDetails,
    renderAppraisalDetails,
    renderReportingDetails,
    renderEmergencyContact,
    renderChecklist,
    renderWorklogs,
    renderAdditionalDetails,
    renderRelatedPIIDetails,
    renderRelatedCertificationsAccreditations,
    renderRelatedEducationDetails,
    renderRelatedAddressHistory,
    renderRelatedEmploymentHistory,
    renderRelatedBenefits,
    renderRelatedProjectHistory,
    renderRelatedTask,
    renderRelatedForm16List,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
