import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { fork } from "redux-saga/effects";

import { AcademyACHReducer } from "./AcademyACH/reducer";
import AcademyACHSaga from "./AcademyACH/saga";
import { AcademyBatchReducer } from "./AcademyBatch/reducer";
import AcademyBatchSaga from "./AcademyBatch/saga";
import { AcademyCandidateEnrollmentsReducer } from "./AcademyCandidateEnrollments/reducer";
import AcademyCandidateEnrollmentsSaga from "./AcademyCandidateEnrollments/saga";
import { AcademyCandidateLeadsReducer } from "./AcademyCandidateLeads/reducer";
import AcademyCandidateLeadsSaga from "./AcademyCandidateLeads/saga";
import { AcademyCandidatesReducer } from "./AcademyCandidates/reducer";
import AcademyCandidatesSaga from "./AcademyCandidates/saga";
import { AcademyCoursesReducer } from "./AcademyCourses/reducer";
import AcademyCoursesSaga from "./AcademyCourses/saga";
import { AcademyMentorFeedbackReducer } from "./AcademyMentorFeedback/reducer";
import AcademyMentorFeedbackSaga from "./AcademyMentorFeedback/saga";
import { AcademyStudentProfileReducer } from "./AcademyStudentProfile/reducer";
import AcademyStudentProfileSaga from "./AcademyStudentProfile/saga";
import { SPSAccountsReducer } from "./AccountsSPS/reducer";
import SPSAccountsSaga from "./AccountsSPS/saga";
import { ActivityLogsReducer } from "./ActivityLogs/reducer";
import ActivityLogsSaga from "./ActivityLogs/saga";
import { AddressHistoryReducer } from "./AddressHistory/reducer";
import AddressHistorySaga from "./AddressHistory/saga";
import AnnouncementsReducer from "./Announcements/reducer";
import AnnouncementsSaga from "./Announcements/saga";
import ApplicationReducer from "./application/reducer";
import ApplicationSaga from "./application/saga";
import { AttributesReducer } from "./Attribute/reducer";
import AttributesSaga from "./Attribute/saga";
import { BankDetailsReducer } from "./BankDetails/reducer";
import BankDetailsSaga from "./BankDetails/saga";
import { BenefitsReducer } from "./Benefits/reducer";
import BenefitsSaga from "./Benefits/saga";
import { BugReportingReducer } from "./BugReporting/reducer";
import BugReportingSaga from "./BugReporting/saga";
import { CampaignsReducer } from "./Campaigns/reducer";
import CampaignsSaga from "./Campaigns/saga";
import { CampaignsAssetsReducer } from "./CampaignsAssets/reducer";
import CampaignsAssetsSaga from "./CampaignsAssets/saga";
import { CatalogAdministrationsReducer } from "./CatalogAdministrations/reducer";
import CatalogAdministrationsSaga from "./CatalogAdministrations/saga";
import { CategoriesReducer } from "./categories/reducer";
import CategoriesSaga from "./categories/saga";
import { CertificationsAccreditationsReducer } from "./CertificationsAccreditations/reducer";
import CertificationsAccreditationsSaga from "./CertificationsAccreditations/saga";
import { ChecklistReducer } from "./Checklist/reducer";
import ChecklistSaga from "./Checklist/saga";
import { ChecklistTemplateReducer } from "./ChecklistTemplate/reducer";
import ChecklistTemplateSaga from "./ChecklistTemplate/saga";
import { CompanyReducer } from "./Company/reducer";
import CompanySaga from "./Company/saga";
import { CompanyContactReducer } from "./CompanyContact/reducer";
import CompanyContactSaga from "./CompanyContact/saga";
import { ContactUsReducer } from "./ContactUS/reducer";
import ContactUsSaga from "./ContactUS/saga";
import { CountryReducer } from "./Country/reducer";
import CountrySaga from "./Country/saga";
import { CurrencyReducer } from "./Currency/reducer";
import CurrencySaga from "./Currency/saga";
import { DashboardAdministrationReducer } from "./Dashboard/Administration/reducer";
import DashboardAdministrationSaga from "./Dashboard/Administration/saga";
import { DashboardsReducer } from "./Dashboard/reducer";
import DashboardsSaga from "./Dashboard/saga";
import { DashboardTabsReducer } from "./Dashboard/Tabs/reducer";
import DashboardTabSaga from "./Dashboard/Tabs/saga";
import { DashboardWidgetsReducer } from "./Dashboard/Widgets/reducer";
import DashboardWidgetSaga from "./Dashboard/Widgets/saga";
import { DepartmentsReducer } from "./Departments/reducer";
import DepartmentsSaga from "./Departments/saga";
import { DesignationsReducer } from "./Designations/reducer";
import DesignationsSaga from "./Designations/saga";
import { DriveReducer } from "./Drive/reducer";
import DriveSaga from "./Drive/saga";
import { EducationDetailsReducer } from "./EducationDetails/reducer";
import EducationDetailsSaga from "./EducationDetails/saga";
import { EmailSchedularReducer } from "./EmailSchedular/reducer";
import EmailSchedularSaga from "./EmailSchedular/saga";
import EmailTemplatesReducer from "./emailtemplates/reducer";
import EmailTemplatesSaga from "./emailtemplates/saga";
import EmployeesReducer from "./employees/reducer";
import EmployeesSaga from "./employees/saga";
import { EmploymentHistoryReducer } from "./EmploymentHistory/reducer";
import EmploymentHistorySaga from "./EmploymentHistory/saga";
import FiltersReducer from "./filters/reducer";
import FiltersSaga from "./filters/saga";
import { Form16Reducer } from "./Form16/reducer";
import Form16Saga from "./Form16/saga";
import { GroupsReducer } from "./GroupsV2/reducer";
import GroupsSaga from "./GroupsV2/saga";
import { SPSInsuranceReducer } from "./Insurance/reducer";
import SPSInsuranceSaga from "./Insurance/saga";
import { ITNServicesReducer } from "./ITNServices/reducer";
import ITNServicesSaga from "./ITNServices/saga";
import { JobsPostingReducer } from "./jobs/reducer";
import JobsPostingSaga from "./jobs/saga";
import { KanbanConfigReducer } from "./KanbanConfig/reducer";
import KanbanConfigSaga from "./KanbanConfig/saga";
import KnowledgeReducer from "./knowledge/reducer";
import KnowledgeSaga from "./knowledge/saga";
import KnowledgebaseReducer from "./knowledgebase/reducer";
import KnowledgebaseSaga from "./knowledgebase/saga";
import { LeadListsReducer } from "./LeadLists/reducer";
import LeadListsSaga from "./LeadLists/saga";
import MediaReducer from "./media/reducer";
import MediaSaga from "./media/saga";
import ModulesReducer from "./modules/reducer";
import ModulesSaga from "./modules/saga";
import { NewsletterLeadsReducer } from "./NewsletterLeads/reducer";
import NewsletterLeadsSaga from "./NewsletterLeads/saga";
import { NewsletterResourcesReducer } from "./NewsletterResources/reducer";
import NewsletterResourcesSaga from "./NewsletterResources/saga";
import { NotificationsDefinitionsReducer } from "./NotificationsDefinitions/reducer";
import NotificationsDefinitionsSaga from "./NotificationsDefinitions/saga";
import OrganisationsReducer from "./organisations/reducer";
import OrganisationsSaga from "./organisations/saga";
import { PIIDetailsReducer } from "./PIIDetails/reducer";
import PIIDetailsSaga from "./PIIDetails/saga";
import { PlatformModulesReducer } from "./PlatformModules/reducer";
import PlatformModulesSaga from "./PlatformModules/saga";
import { ProjectHistoryReducer } from "./ProjectHistory/reducer";
import ProjectHistorySaga from "./ProjectHistory/saga";
import RecruitsReducer from "./recruits/reducer";
import RecruitsSaga from "./recruits/saga";
import { ResourcesReducer } from "./Resources/reducer";
import ResourcesSaga from "./Resources/saga";
import { ITNRFPReducer } from "./RFP/reducer";
import ITNRFPSaga from "./RFP/saga";
import { ServiceNowSolutionsReducer } from "./ServiceNowSolutions/reducer";
import ServiceNowSolutionsSaga from "./ServiceNowSolutions/saga";
import { ServiceRequestsReducer } from "./ServiceRequests/reducer";
import ServiceRequestsSaga from "./ServiceRequests/saga";
import { SpaceReducer } from "./Space/reducer";
import SpaceSaga from "./Space/saga";
import { SPSAccountContactReducer } from "./spsAccountContact/reducer";
import SPSAccountContactSaga from "./spsAccountContact/saga";
import { SPSBGCheckReducer } from "./spsBGCheck/reducer";
import SPSBGCheckSaga from "./spsBGCheck/saga";
import { SPSDealsReducer } from "./spsDeals/reducer";
import SPSDealsSaga from "./spsDeals/saga";
import { SPSPaperworkReducer } from "./SPSPaperwork/reducer";
import SPSPaperworkSaga from "./SPSPaperwork/saga";
import { SPSProjectsReducer } from "./SPSProjects/reducer";
import SPSProjectsSaga from "./SPSProjects/saga";
import { SPSSalesProfileReducer } from "./spsSalesProfile/reducer";
import SPSSalesProfileSaga from "./spsSalesProfile/saga";
import { SubCategoriesReducer } from "./subcategories/reducer";
import SubCategoriesSaga from "./subcategories/saga";
import { SubscriberReducer } from "./Subscriber/reducer";
import SubscriberSaga from "./Subscriber/saga";
import { TagsReducer } from "./Tags/reducer";
import TagsSaga from "./Tags/saga";
import { TaskReducer } from "./Task/reducer";
import TaskSaga from "./Task/saga";
import TicketsReducer from "./tickets/reducer";
import TicketsSaga from "./tickets/saga";
import UserReducer from "./user/reducer";
import UserSaga from "./user/saga";
import UsersReducer from "./users/reducer";
import UsersSaga from "./users/saga";
import { VariablesReducer } from "./Variables/reducer";
import VariablesSaga from "./Variables/saga";
import { VariableSetsReducer } from "./VariableSets/reducer";
import VariableSetsSaga from "./VariableSets/saga";
import { WorkspaceReducer } from "./Workspace/reducer";
import WorkspaceSaga from "./Workspace/saga";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  ITNServicesReducer,
  CampaignsAssetsReducer,
  CampaignsReducer,
  ResourcesReducer,
  ServiceNowSolutionsReducer,
  EmailSchedularReducer,
  VariableSetsReducer,
  VariablesReducer,
  ITNRFPReducer,
  DashboardsReducer,
  ContactUsReducer,
  ActivityLogsReducer,
  UserReducer,
  UsersReducer,
  EmployeesReducer,
  ApplicationReducer,
  RecruitsReducer,
  TicketsReducer,
  OrganisationsReducer,
  AnnouncementsReducer,
  JobsPostingReducer,
  MediaReducer,
  EmailTemplatesReducer,
  GroupsReducer,
  CategoriesReducer,
  TagsReducer,
  SubCategoriesReducer,
  ModulesReducer,
  KnowledgebaseReducer,
  KnowledgeReducer,
  SPSAccountsReducer,
  SPSAccountContactReducer,
  SpaceReducer,
  SPSSalesProfileReducer,
  SPSDealsReducer,
  SPSBGCheckReducer,
  SPSPaperworkReducer,
  SubscriberReducer,
  SPSProjectsReducer,
  SPSInsuranceReducer,
  DashboardAdministrationReducer,
  DashboardWidgetsReducer,
  DashboardTabsReducer,
  FiltersReducer,
  AttributesReducer,
  DepartmentsReducer,
  DesignationsReducer,
  CountryReducer,
  CurrencyReducer,
  CompanyContactReducer,
  CompanyReducer,
  Form16Reducer,
  BenefitsReducer,
  EmploymentHistoryReducer,
  EducationDetailsReducer,
  CertificationsAccreditationsReducer,
  PIIDetailsReducer,
  BankDetailsReducer,
  ProjectHistoryReducer,
  AddressHistoryReducer,
  TaskReducer,
  PlatformModulesReducer,
  DriveReducer,
  AcademyCoursesReducer,
  AcademyCandidatesReducer,
  AcademyCandidateLeadsReducer,
  AcademyMentorFeedbackReducer,
  AcademyStudentProfileReducer,
  AcademyBatchReducer,
  AcademyCandidateEnrollmentsReducer,
  AcademyACHReducer,
  ChecklistTemplateReducer,
  BugReportingReducer,
  ChecklistReducer,
  CatalogAdministrationsReducer,
  KanbanConfigReducer,
  WorkspaceReducer,
  NotificationsDefinitionsReducer,
  NewsletterResourcesReducer,
  LeadListsReducer,
  NewsletterLeadsReducer,
  ServiceRequestsReducer,
});
function* rootSaga() {
  yield fork(NewsletterLeadsSaga);
  yield fork(DashboardsSaga);
  yield fork(CampaignsSaga);
  yield fork(CampaignsAssetsSaga);
  yield fork(LeadListsSaga);
  yield fork(NewsletterResourcesSaga);
  yield fork(ServiceNowSolutionsSaga);
  yield fork(ContactUsSaga);
  yield fork(EmailSchedularSaga);
  yield fork(UserSaga);
  yield fork(UsersSaga);
  yield fork(EmployeesSaga);
  yield fork(ApplicationSaga);
  yield fork(RecruitsSaga);
  yield fork(ITNServicesSaga);
  yield fork(ITNRFPSaga);
  yield fork(TicketsSaga);
  yield fork(OrganisationsSaga);
  yield fork(AnnouncementsSaga);
  yield fork(JobsPostingSaga);
  yield fork(MediaSaga);
  yield fork(EmailTemplatesSaga);
  yield fork(GroupsSaga);
  yield fork(CategoriesSaga);
  yield fork(SubCategoriesSaga);
  yield fork(SubscriberSaga);
  yield fork(ModulesSaga);
  yield fork(KnowledgebaseSaga);
  yield fork(KnowledgeSaga);
  yield fork(SPSAccountsSaga);
  yield fork(VariableSetsSaga);
  yield fork(VariablesSaga);
  yield fork(SPSAccountContactSaga);
  yield fork(SPSSalesProfileSaga);
  yield fork(SPSDealsSaga);
  yield fork(SPSBGCheckSaga);
  yield fork(SPSPaperworkSaga);
  yield fork(SPSProjectsSaga);
  yield fork(SPSInsuranceSaga);
  yield fork(DashboardAdministrationSaga);
  yield fork(DashboardWidgetSaga);
  yield fork(DashboardTabSaga);
  yield fork(FiltersSaga);
  yield fork(AttributesSaga);
  yield fork(DepartmentsSaga);
  yield fork(DesignationsSaga);
  yield fork(CountrySaga);
  yield fork(CurrencySaga);
  yield fork(CompanyContactSaga);
  yield fork(ResourcesSaga);
  yield fork(CompanySaga);
  yield fork(BenefitsSaga);
  yield fork(PIIDetailsSaga);
  yield fork(BankDetailsSaga);
  yield fork(Form16Saga);
  yield fork(TaskSaga);
  yield fork(TagsSaga);
  yield fork(PlatformModulesSaga);
  yield fork(EducationDetailsSaga);
  yield fork(EmploymentHistorySaga);
  yield fork(ProjectHistorySaga);
  yield fork(AddressHistorySaga);
  yield fork(CertificationsAccreditationsSaga);
  yield fork(DriveSaga);
  yield fork(AcademyCandidatesSaga);
  yield fork(AcademyCoursesSaga);
  yield fork(AcademyCandidateLeadsSaga);
  yield fork(AcademyMentorFeedbackSaga);
  yield fork(AcademyStudentProfileSaga);
  yield fork(AcademyBatchSaga);
  yield fork(AcademyCandidateEnrollmentsSaga);
  yield fork(AcademyACHSaga);
  yield fork(ChecklistTemplateSaga);
  yield fork(ActivityLogsSaga);
  yield fork(BugReportingSaga);
  yield fork(ChecklistSaga);
  yield fork(CatalogAdministrationsSaga);
  yield fork(KanbanConfigSaga);
  yield fork(WorkspaceSaga);
  yield fork(SpaceSaga);
  yield fork(NotificationsDefinitionsSaga);
  yield fork(ServiceRequestsSaga);
}

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["UserReducer", "ApplicationReducer"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware];
  },
});
const persistor = persistStore(store);

type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);

export { store, persistor, RootState, AppDispatch };
