import Types from "./types";

export const fetchRecruits = (payload) => ({
  type: Types.RECRUITS_FETCH,
  payload: payload,
});

export const fetchRecruitsSuccess = (list = []) => ({
  type: Types.RECRUITS_FETCH_SUCCESS,
  payload: {
    data: list,
  },
});

export const fetchRecruitsError = (payload) => ({
  type: Types.RECRUITS_FETCH_ERROR,
  payload: payload,
});

export const filterRecruits = ({ field, value }) => ({
  type: Types.RECRUITS_UPDATE_FILTER,
  payload: {
    field: field,
    value: value,
  },
});

export const addRecruits = ({ data, onSuccess, onError }) => ({
  type: Types.RECRUITS_ADD,
  payload: { data, onSuccess, onError },
});

export const deleteRecruits = ({ id, onSuccess, onError }) => ({
  type: Types.RECRUITS_DELETE,
  payload: { id, onSuccess, onError },
});

export const rollbackRecruits = ({ id, onSuccess, onError }) => ({
  type: Types.RECRUITS_ROLLBACK,
  payload: { id, onSuccess, onError },
});

export const updateRecruits = ({ id, data, onSuccess, onError }) => ({
  type: Types.RECRUITS_UPDATE,
  payload: { id, data, onSuccess, onError },
});

export const addRecruitsAssignment = ({ recruitsId, data, onSuccess, onError }) => ({
  type: Types.RECRUITS_ASSIGNMENTS_ADD,
  payload: {
    recruitsId,
    data,
    onSuccess,
    onError,
  },
});
