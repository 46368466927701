import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  DesignationsList,
  AddDesignation,
  UpdateDesignation,
  FindDesignationById,
  DeleteDesignation,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchDesignationsListSuccess, fetchDesignationsError } from "./actions";
import Types from "./actionType";
import {
  CreateDesignationSuccess,
  CreateDesignation as CreateDesignationType,
  DesignationsSuccess,
  UpdateDesignation as UpdateDesignationType,
  FetchDesignations,
  DesignationsByIdSuccess,
  FetchDesignationsById,
  DeleteDesignations as DeleteDesignationType,
} from "./types";

function* createWorker(action: CreateDesignationType) {
  try {
    const options = {
      method: "POST",
      url: AddDesignation,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateDesignationSuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* updateWorker(action: UpdateDesignationType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateDesignation(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateDesignationSuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchDesignations) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: DesignationsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<DesignationsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchDesignationsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchDesignationsError(error.response?.data.message));
  }
}

function* fetchDesignationsById(action: FetchDesignationsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindDesignationById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<DesignationsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

function* deleteWorker(action: DeleteDesignationType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteDesignation(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateDesignationSuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_DESIGNATION, createWorker);
  yield takeLatest(Types.DELETE_DESIGNATION, deleteWorker);
  yield takeLatest(Types.UPDATE_DESIGNATION, updateWorker);
  yield takeEvery(Types.FETCH_DESIGNATIONS, fetchAllWorker);
  yield takeEvery(Types.FETCH_DESIGNATION_BY_ID, fetchDesignationsById);
}
