import Types from "./actionType";
import {
  FetchITNRFPPayload,
  CreateITNRFP,
  CreateITNRFPPayload,
  FetchITNRFP,
  FetchITNRFPError,
  FetchITNRFPSuccess,
  UpdateITNRFP,
  UpdateITNRFPPayload,
  ITNRFPListWithMetaData,
  FetchITNRFPByIdPayload,
  FetchITNRFPById,
  DeleteITNRFPPayload,
  DeleteITNRFP,
} from "./types";

export const fetchITNRFPList = ({
  params,
  onSuccess,
  onError,
}: FetchITNRFPPayload): FetchITNRFP => ({
  type: Types.FETCH_ITNRFP,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchITNRFPById = ({
  id,
  onSuccess,
  onError,
}: FetchITNRFPByIdPayload): FetchITNRFPById => ({
  type: Types.FETCH_ITNRFP_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchITNRFPListSuccess = (
  data: ITNRFPListWithMetaData,
): FetchITNRFPSuccess => ({
  type: Types.SUCCESS_FETCH_ITNRFP,
  payload: {
    data,
  },
});

export const fetchITNRFPError = (error?: string): FetchITNRFPError => ({
  type: Types.ERROR_FETCH_ITNRFP,
  payload: { error },
});

export const createITNRFP = ({
  data,
  onSuccess,
  onError,
}: CreateITNRFPPayload): CreateITNRFP => ({
  type: Types.CREATE_ITNRFP,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateITNRFP = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateITNRFPPayload): UpdateITNRFP => ({
  type: Types.UPDATE_ITNRFP,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteITNRFP = ({
  id,
  onSuccess,
  onError,
}: DeleteITNRFPPayload): DeleteITNRFP => ({
  type: Types.DELETE_ITNRFP,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
