import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyCandidateEnrollmentsController } from "../../../presentationals/screens";

export const APAcademyCandidateEnrollmentsEdit = () => {
  return (
    <Layout
      activity={<AcademyCandidateEnrollmentsController type={"edit"} />}
      admin={true}
      layout={"2"}
    />
  );
};
