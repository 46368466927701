import Types from "./actionTypes";
import { EmployeesActions, EmployeesState } from "./types";

const initialData = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
    },
  },
};

const initialState: EmployeesState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
  employees: [],
  openList: [],
  payrolls: [],
  payrollsRehydrate: false,
  payrollsSelf: [],
  birthdays: [],
  filters: {
    name: "",
    employmentStatus: "",
    employeeType: "",
    mobile: "",
    reportingPOD: "",
    dateJoiningStart: "",
    dateJoiningEnd: "",
    nationalId: "",
    nationalIdType: "",
    sortKey: {},
    sortType: "asc",
  },
  payrollsFilters: {
    sortKey: {},
    sortType: "asc",
  },
  childPayrollsFilters: {
    name: "",
    status: {},
    selectedStatus: "",
  },
};

const EmployeesReducer = (state = initialState, action: EmployeesActions) => {
  switch (action.type) {
    case Types.EMPLOYEES_FETCH:
      return {
        ...state,
        loading: true,
      };
    case "USER_UPDATE_ORGANISATION_SUCCESS":
      return {
        ...state,
        data: initialData,
      };
    case Types.EMPLOYEES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case Types.EMPLOYEES_FETCH_OPEN_SUCCESS:
      return {
        ...state,
        openList: action.payload.data,
      };
    case Types.EMPLOYEES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Types.EMPLOYEES_UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    case Types.EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS:
      return {
        ...state,
        payrolls: action.payload.payrolls,
      };
    case Types.EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS_SELF:
      return {
        ...state,
        payrollsSelf: action.payload.payrolls,
      };
    case Types.SET_EMPLOYEES_BIRTHDAYS:
      return {
        ...state,
        birthdays: action.payload,
      };
    case Types.EMPLOYEES_PAYROLLS_REHYDRATE:
      return {
        ...state,
        payrollsRehydrate: action.payload,
      };
    case Types.PAYROLLS_FILTERS:
      return {
        ...state,
        payrollsFilters: {
          ...state.payrollsFilters,
          [action.payload.field]: action.payload.value,
        },
      };
    case Types.CHILD_PAYROLLS_FILTERS:
      return {
        ...state,
        childPayrollsFilters: {
          ...state.childPayrollsFilters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default EmployeesReducer;
