import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyCandidateLeadsController } from "../../../presentationals/screens";

export const APAcademyCandidateLeadsEdit = () => {
  return (
    <Layout
      activity={<AcademyCandidateLeadsController type={"edit"} />}
      admin={true}
      layout={"2"}
    />
  );
};
