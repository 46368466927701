import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  NewsletterLeadsList,
  AddNewsletterLeads,
  UpdateNewsletterLeads,
  FindNewsletterLeads,
  DeleteNewsletterLeads,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchNewsletterLeadsListSuccess, fetchNewsletterLeadsError } from "./actions";
import Types from "./actionType";
import {
  CreateNewsletterLeadsuccess,
  CreateNewsletterLeads as CreateNewsletterLeadsType,
  NewsletterLeadsSuccess,
  UpdateNewsletterLeads as UpdateNewsletterLeadsType,
  FetchNewsletterLeads,
  FetchNewsletterLeadsById,
  NewsletterLeadsByIdSuccess,
  DeleteNewsletterLeads as DeleteNewsletterLeadsType,
} from "./types";

function* createWorker(action: CreateNewsletterLeadsType) {
  try {
    const options = {
      method: "POST",
      url: AddNewsletterLeads,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateNewsletterLeadsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateNewsletterLeadsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateNewsletterLeads(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateNewsletterLeadsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchNewsletterLeads) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: NewsletterLeadsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<NewsletterLeadsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchNewsletterLeadsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchNewsletterLeadsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data?.message || "Error in fetching SPS Newsletter Resources",
      );
    }
  }
}

function* fetchNewsletterLeadsById(action: FetchNewsletterLeadsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindNewsletterLeads(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<NewsletterLeadsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data.message || "Error in fetching SPS Newsletter Resources",
      );
    }
  }
}

function* deleteWorker(action: DeleteNewsletterLeadsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteNewsletterLeads(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateNewsletterLeadsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_NEWSLETTER_LEADS, createWorker);
  yield takeLatest(Types.UPDATE_NEWSLETTER_LEADS, updateWorker);
  yield takeEvery(Types.FETCH_NEWSLETTER_LEADS, fetchAllWorker);
  yield takeEvery(Types.FETCH_NEWSLETTER_LEADS_BY_ID, fetchNewsletterLeadsById);
  yield takeEvery(Types.DELETE_NEWSLETTER_LEADS, deleteWorker);
}
