import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    height: 100%;
  `,
  Header: styled.div`
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 2rem;
      font-weight: 400;
      margin-left: 10px;
    }
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: ${(props) => (props.type === "comment" ? "column" : "row")};
    border-bottom: ${(props) => (props.type !== "comment" ? "1px solid #000" : "none")};
    h3 {
      font-size: 1.6rem;
      font-weight: 400;
    }
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    background-color: ${(props) => (props.header ? "#EBF1F8" : "#C5EDEE")};
    padding: 4px;
    margin-top: ${(props) => (props.header ? "4%" : "0")};
  `,
  Column: styled.div`
    flex: 1;
    text-align: center;
    font-family: ${fonts.font1};
    font-size: 12px;
  `,
  Container: styled.div`
    width: ${(props) => (props.width ? props.width : "auto")};
    background-color: ${(props) => (props.background ? props.background : "none")};
    text-align: ${(props) => (props.othersComment ? "right" : "left")};
    padding: 20px;
    border-radius: 5px;
    margin-left: ${(props) => (props.type === "desc" ? "4%" : "0")};
    .desc {
      word-wrap: break-word;
    }
  `,
  attachment: styled.div`
    font-family: ${fonts.font1};
    font-size: 12px;
    cursor: pointer;
  `,
  date: styled.div`
    font-family: ${fonts.font1};
    font-size: 10px;
    color: ${colors.light_black};
  `,
  comment: styled.div`
    font-family: ${fonts.font1};
    font-size: 12px;
  `,
};

export default styles;
