export const colors = {
  navigationPanel: {
    background: "#FFFFFF",
    borderColor: "rgba(0, 0, 0, 0.6)",
    separatorColor: "rgba(0, 0, 0, 0.6)",
    dropdownBackground: "rgba(0,0,0,0.05)",
    hoverTabBackground: "#EDEBEBE5",
    activeTabBackground: "#E2CCFF",
    hoverTabText: "#000000",
    activeTabText: "#6F00FF",
    accordionBorder: "#686868",
  },
  mainPanel: {
    background: "#F7F7FA",
  },
  table: {
    headerBackground: "#F1F1F180",
    dataBackground: "white",
    borderColor: "#C4C0C0",
    // scrollThumb: "#D9CDFF",
    selectedRow: "#5B8FF6",
    selectedRowBackground: "#5B8FF61F",
    scrollThumb: "#A173FF",
  },
  filter: {
    activeBackground: "#E2CCFF",
    activeTitle: "#6F00FF",
    inactiveBackground: "#ffffff",
    inactivcTitle: "rgba(0, 0, 0, 0.5);",
  },
  common: {
    blurBackground: "rgba(0,0,0,0.5)",
    primary: "#6F00FF",
    titleColor: "#ffffff",
    black: "#000000",
    white: "#ffffff",
    activeTabBackground: "#E2CCFF",
    secondaryWhite: "#f1f1f1",
    activeTabText: "#6F00FF",
    pink: "#EBB1FF",
    yellow: "#F5A40C",
    hoverTabBackground: "#EDEBEBE5",
    border: "#686868",
    modelBackground: "rgba(0,0,0,0.2)",
    green: "green",
    red: "red",
    error: "red",
    orange: "orange",
    purple: "#AA336A",
    lightWhite: "#F7F7FA",
    borderColor: "#e7e7e7",
    lightTextColor: "rgba(0, 0, 0, 0.75)",
    shadow: "rgba(0, 0, 0, 0.15);",
    whiteGrey: "rgba(0, 0, 0, 0.6);",
    lightGrey: "#F1F1F1",
    greyBorder: "rgba(0, 0, 0, 0.25)",
    modalTitleColor: "rgba(0, 0, 0, 0.65)",
    displayPictureBorderColor: "#65c08e",
    purpleTitle: "#b073ff",
    strongViolet: "#6314ca",
    commentColor: "#717073",
    lightGreen: "#69C894",
    disabledText: "rgba(0, 0, 0, 0.35)",
  },
  dashboard: {
    tabBackground: "#6F00FF",
    conuntGradientOne: "#09C6F9",
    conuntGradientTwo: "#045DE9",
  },
  gradients: {
    blueOne: "#09C6F9",
    blueTwo: "#045DE9",
    orangeOne: "#FDB03B",
    orangeTwo: "#FB6D13",
  },
  input: {
    placeholder: "rgba(0, 0, 0, 0.65)",
    placeholderFocus: "rgba(17, 91, 24, 0.8)",
    dropDownItem: "#F3EFFD",
  },
  apKnowledge: {
    rejectedStatus: "#FF4E00",
    draftStatus: "#AFB4FF",
    publishedStatus: "#199225",
    inreviewStatus: "#B9890C",
  },
  knowledge: {
    rejectedStatus: "#FF4E00",
    draftStatus: "#E2E2E2",
    publishedStatus: "#86DF75",
    inreviewStatus: "#FFE600",
    tagsBackground: "#EDEDED",
  },
  attachments: {
    background: "#FFFDFD",
  },
  primaryBackground: "#F4F4F4",
  secondaryBackground: "#EFF1F7",
  controllerBackground: "#FCFCFC",
  blue: "#0099ff", // "#0116EB"
  grey: "#959DA4",
  primaryFontColor: "#283049",
  lightFontColor: "rgba(0,0,0,0.6)",
  secondaryFontColor: "#FCFBFC",
  error: "#FF0032",
  errorText: "#F7F5EB",
  disabledButtonTextColor: "rgba(255,255,255,0.8)",
  buttonTextColor: "#F4F4F4",
  white: "white",
};

export const dark = {
  navBar: {
    background: "#1B1E21",
    dropdownBackground: "rgba(0,0,0,0.05)",
  },
  primaryBackground: "#0F1215",
  secondaryBackground: "#EFF1F7",
  controllerBackground: "#1B1E21",
  blue: "#6569EA", // "#0116EB"
  grey: "#959DA4",
  primaryFontColor: "#283049",
  lightFontColor: "rgba(0,0,0,0.6)",
  secondaryFontColor: "#FCFBFC",
};
