import React, { useCallback, useMemo, useState } from "react";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { ActivityLogItem, WorkLogItem } from "../../../../types";
import { Button } from "../../atoms";

import {
  Comment,
  CommentMetaContainer,
  CommentDate,
  CommentItem,
  CommentItemContainer,
  CommentPostContainer,
  CommentSeparator,
  CommentsContainer,
  Container,
  EmptyCommentItem,
  HeaderContainer,
  NoWorkLogs,
  ProfileImage,
  ProfileImageContainer,
  StartLabel,
  TriangleRight,
  UserNameInitial,
  WorkLogInput,
} from "./styles";

interface Props {
  workLogs: WorkLogItem[] | ActivityLogItem[];
  activityLogger?: boolean;
  onPostWorklog?: (comment: string) => void;
}

export const WorkLogs = ({ workLogs, onPostWorklog, activityLogger }: Props) => {
  const currenctUserProfile = useAppSelector((state) => state?.UserReducer?.profilePic?.location);
  const currentUserId = useAppSelector((state) => state.UserReducer.id);
  const currentUserName = useAppSelector((state) => state.UserReducer.name);

  const [workLog, setWorkLog] = useState("");

  const onChangeWorklog = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWorkLog(value);
  }, []);

  const handlePost = useCallback(() => {
    if (onPostWorklog) {
      onPostWorklog(workLog);
      setWorkLog("");
    }
  }, [onPostWorklog, workLog]);

  const sortedWorklogs = useMemo(() => {
    return workLogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [workLogs]);

  const getDate = useCallback((date?: Date) => {
    if (date) {
      date = new Date(date);
      return `${date.toDateString()}, ${date.getHours()}:${date.getMinutes()}`;
    }
    return typeof date;
  }, []);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handlePost();
      }
    },
    [handlePost],
  );

  return (
    <Container>
      {onPostWorklog && (
        <HeaderContainer>
          {currenctUserProfile ? (
            <ProfileImage src={currenctUserProfile} />
          ) : (
            <UserNameInitial>{currentUserName?.[0]}</UserNameInitial>
          )}
          <CommentPostContainer>
            <WorkLogInput
              onKeyDown={onKeyDown}
              placeholder='Post Work Log'
              value={workLog}
              onChange={onChangeWorklog}
            />
            <Button title='Post' className='worklog-post-button' onClick={handlePost} />
          </CommentPostContainer>
        </HeaderContainer>
      )}
      <CommentsContainer>
        {!workLogs.length && <NoWorkLogs>{strings.common.noWorkLogs}</NoWorkLogs>}
        <>
          {activityLogger ? (
            <>
              {sortedWorklogs.map((item, index) => {
                return (
                  <CommentItemContainer key={index}>
                    <ProfileImageContainer>
                      <CommentSeparator />
                      <Tooltip
                        text={item?.user?.name}
                        element={
                          item?.user?.profilePic?.location ? (
                            <ProfileImage src={item?.user?.profilePic?.location} />
                          ) : (
                            <UserNameInitial>{item?.user?.name?.[0]}</UserNameInitial>
                          )
                        }
                        copy={false}
                      />
                    </ProfileImageContainer>
                    <CommentItem className='comment-box right activity-logger'>
                      {item?.log?.map((log, logIndex) => {
                        return (
                          <Comment
                            className='activity-comment'
                            dangerouslySetInnerHTML={{ __html: log }}
                            key={logIndex}
                          />
                        );
                      })}
                      <CommentMetaContainer>
                        <CommentDate>{currentUserName}</CommentDate>
                        <CommentDate>{getDate(item.date)}</CommentDate>
                      </CommentMetaContainer>
                    </CommentItem>
                  </CommentItemContainer>
                );
              })}
            </>
          ) : (
            <>
              {sortedWorklogs.map((item, index) => {
                return (
                  <CommentItemContainer key={index}>
                    {item?.user?._id !== currentUserId ? (
                      <CommentItem className='comment-box left'>
                        <Comment>{item.comment}</Comment>{" "}
                        <CommentMetaContainer>
                          <CommentDate>{item?.user?.name}</CommentDate>
                          <CommentDate>{getDate(item.date)}</CommentDate>
                        </CommentMetaContainer>
                      </CommentItem>
                    ) : (
                      <EmptyCommentItem />
                    )}
                    <ProfileImageContainer>
                      <CommentSeparator />
                      <Tooltip
                        text={item?.user?.name}
                        element={
                          item?.user?.profilePic?.location ? (
                            <ProfileImage src={item?.user?.profilePic?.location} />
                          ) : (
                            <UserNameInitial>{item?.user?.name?.[0]}</UserNameInitial>
                          )
                        }
                        copy={false}
                      />
                    </ProfileImageContainer>
                    {item?.user?._id !== currentUserId ? (
                      <EmptyCommentItem />
                    ) : (
                      <CommentItem className='comment-box right'>
                        <Comment>{item.comment}</Comment>
                        <CommentMetaContainer>
                          <CommentDate>{currentUserName}</CommentDate>
                          <CommentDate>{getDate(item.date)}</CommentDate>
                        </CommentMetaContainer>
                      </CommentItem>
                    )}
                  </CommentItemContainer>
                );
              })}
            </>
          )}
        </>
        {!!workLogs.length && (
          <CommentItemContainer>
            <EmptyCommentItem />
            <ProfileImageContainer>
              <CommentSeparator />
              <StartLabel>{strings.common.start}</StartLabel>
            </ProfileImageContainer>
            <EmptyCommentItem />
          </CommentItemContainer>
        )}
      </CommentsContainer>
    </Container>
  );
};
