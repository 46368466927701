import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import { Header as MainHeader } from "../../presentationals/components";
import { fetchEmailTemplate } from "../../redux/emailtemplates/actions";
import { useTitle, Sort } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./styles";

const EmailTemplatesListing = (props: any) => {
  const loop = [
    "account_created",
    "password_reset",
    "password_reset_successful",
    "ticket_comment",
    "ticket_created",
  ];
  useTitle("Email Templates");
  const navigate = useNavigate();
  useEffect(() => {
    props.fetchEmailTemplate({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filteredData = () => {
    const tempData = loop;
    // let sortKey = props.filters.sortKey.key;
    // let isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    tempData.sort((itemOne, itemTwo) => (ascSort ? itemOne > itemTwo : itemOne < itemTwo));
    // let attribute = props.filters.sortKey.attribute;
    // if (tempData?.[0]?.[sortKey]) {
    //   tempData = Sort({
    //     data: tempData,
    //     field: sortKey,
    //     isDate: isDate,
    //     asc: ascSort,
    //     attributeKey: attribute,
    //   });
    // }
    return tempData;
  };
  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.emailTemplates} />
      <Header
        title={"Email Templates"}
        onSync={{
          event: props.fetchEmailTemplate,
          params: {},
        }}
        buttons={[
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/emailtemplates",
            },
            visible: true,
          },
        ]}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              Sno.
            </th>
            <th align='left'>Name</th>
            <th align='left'>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filteredData().map((key, index: number) => {
            const item = props.defData?.[key];
            if (item?.name?.toLowerCase().includes(props.filters.name)) {
              return (
                typeof item === "object" && (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td align='center'>
                      <Link to={`/ap/emailtemplates/${key}/edit`}>Edit</Link>
                    </td>
                  </tr>
                )
              );
            }
          })}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  defData: state.EmailTemplatesReducer.defData,
  orgData: state.EmailTemplatesReducer.orgData,
  filters: state.EmailTemplatesReducer.filters,
  permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesListing);
