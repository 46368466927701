import React, { useEffect } from "react";

import moment from "moment-timezone";
import { MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import { Header as MainHeader } from "../../presentationals/components";
import { fetchPayrollDataSelf } from "../../redux/employees/actions";
import { AttributesValues, currencyFormat, useTitle } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./PayrollsEmployeesListing.styles";

const PayrollsEmployeesListing = (props) => {
  //   const [feedback, setFeedback] = useState([]);
  useTitle("Payrolls List");
  useEffect(() => {
    props.fetchPayrollDataSelf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const payableCalculation = (payroll, workingdays) => {
  //   var salaryBase = parseFloat(payroll.salaryBase) || 0;
  //   var salaryBonus = parseFloat(payroll.salaryBonus) || 0;
  //   var additionalExpense = parseFloat(payroll.additionalExpense) || 0;

  //   var deductibleSalary = salaryBase;
  //   var sum = salaryBase + salaryBonus + additionalExpense;
  //   var perDaySalary = deductibleSalary / workingdays;

  //   var nonWorkingDays =
  //     parseFloat(workingdays) - parseFloat(payroll.workingdays);
  //   var deducatedSalary = perDaySalary * nonWorkingDays;
  //   return currencyFormat(
  //     sum - deducatedSalary,
  //     AttributesValues("currency", payroll.salaryCurrency)
  //   );
  // };

  const CurrencyFormatMethod = (value, curency) => {
    return currencyFormat(value, AttributesValues("currency", curency));
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.payrolls} />
      <Header
        title={"Payrolls"}
        onSync={{
          event: props.fetchPayrollDataSelf,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left'>Sno.</th>
            <th align='left'>Month</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.payrolls.map((payroll, index) => {
            const serial = index + 1;
            return (
              <tr key={index}>
                <td valign='top'>{serial}</td>
                <td valign='top'>{moment(payroll.date).utc().format("YYYY-MMM")}</td>
                <td valign='top' align='center'>
                  <Link to={`/ep/payrolls/${payroll._id}/view`}>
                    <MdArrowRightAlt size={20} />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  payrolls: state.EmployeesReducer.payrollsSelf.filter((item) => item.list.status !== "drafted"),
});

const mapDispatchToProps = {
  fetchPayrollDataSelf,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsEmployeesListing);
