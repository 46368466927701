import styled from "styled-components";

import { colors, fs } from "../../../../theme";

export const Container = styled.div<Props>`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const RenderContainer = styled.div`
  width: 100%;
`;

export const FormTitle = styled.div`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  align-items: center;
`;
export const RowRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 1%;
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border: 0.4px solid ${colors.common.black};
`;

export const TableRow = styled.tr`
  border-bottom: 0.5px solid black;
`;

export const TableHeading = styled.th`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.common.black};
  padding-top: 18px;
  padding-bottom: 18px;
  background: ${colors.table.headerBackground};
  width: 14%;
`;

export const TableContent = styled.td`
  text-align: center;
  height: 56px;
  vertical-align: middle;

  font-weight: 400;
  font-size: 16px;
  color: ${colors.common.modalTitleColor};
`;

export const FirstTableContent = styled.td`
  /* text-align: center; */
  padding-left: 2%;
  height: 56px;
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.common.modalTitleColor};
`;

export const PopupInsideContainer = styled.div`
  padding: 15px;
  width: 645px;
`;

export const AddTablesModalBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 500px;
`;

export const TablesList = styled.div`
  width: 45%;
  border: 0.5px solid black;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const TablesListHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.table.headerBackground};
  padding: 2%;
  border-bottom: 0.4px solid black;
`;

export const TableTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${colors.common.purpleTitle};
`;

export const TableSearchBarContainer = styled.div`
  justify-content: center;
  display: flex;
  border-bottom: 0.4px solid black;
`;

export const PermissionsList = styled.div``;

export const HorizontalLine = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 0.6px solid ${colors.common.greyBorder};
`;

export const ListItem = styled.div<Props>`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.common.modalTitleColor};
  background-color: ${(props) =>
    props.selected ? colors.navigationPanel.activeTabBackground : colors.common.white};
  color: ${(props) => (props.selected ? colors.common.purpleTitle : colors.common.black)};
  padding: 12px;
  cursor: pointer;
`;

export const CenterContainer = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ArrowRight = styled.img`
  height: 24px;
  width: 24px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const ArrowLeft = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const FullAccessSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalSaveContainer = styled.div`
  margin-top: 2%;
  width: 18%;
`;

export const ListItemContainer = styled.div`
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 2px;
`;

export const TempContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const TempRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  margin-right: 10%;
  width: 33%;
`;

export const TempTitle = styled.div`
  font-size: small;
  margin-right: 7px;
`;

export const AddTableButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${colors.common.black};
  margin: 10px;
  width: 90%;
  height: 50%;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
`;

export const SearchInput = styled.input`
  border: 0;
  flex: 1;
  display: flex;
  padding: 8px;
  outline: none;
  width: 100%;
`;

export const SearchIcon = styled.img`
  margin-right: 10px;
  position: absolute;
  right: 0;
`;

export const Icon = styled.img`
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin-left: 10px;
  cursor: pointer;
`;
export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;
export const TempRowContainer = styled.div`
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  width: 100%;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${colors.common.modalTitleColor};
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;

export const ActionItem = styled.img`
  cursor: pointer;
  margin-right: 6px;
`;

export const ParentPermissionContainer = styled.div`
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  .dropdown {
    width: 80%;
  }
  .selectAll {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 3vh;
    display: flex;
    border-radius: 4px;
    width: 15%;
    margin-top: 1vh;
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 1rem;
  }
`;

export const ModalContainer = styled.div`
  width: 288px;
  padding: 12px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalBTNContainer = styled.div`
  width: 50%;
  margin-top: 12px;
`;

export const OverViewRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const DropDownContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

export const DropdownListContainer = styled.div`
  display: flex;
  min-width: 30vw;
  row-gap: 20px;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 3px;
  padding: 20px;
  z-index: 1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const EditHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditHeading = styled.span`
  font-size: 1.6rem;
`;

export const CloseModalButton = styled.div`
  width: 10px;
  position: relative;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  left: -20px;
`;

export const CloseIcon = styled.img`
  right: 4%;
  height: 28px;
  width: 28px;
  object-fit: contain;
  align-self: center;
  cursor: pointer;
  /* z-index: 2; */
`;

export const FooterContainer = styled.div`
  display: flex;
`;
