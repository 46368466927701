import React, { useEffect } from "react";

import { MdEdit, MdLockOutline } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Header as MainHeader } from "../../presentationals/components";
import { fetchKnowledgebase } from "../../redux/knowledgebase/actions";
import { MomentDate, useTitle, Sort } from "../../services/methods";

import styles from "./KnowledgebaseListing.styles";

const KnowledgebaseListing = (props: any) => {
  const navigate = useNavigate();
  useTitle("Knowledgebase");

  useEffect(() => {
    props.fetchKnowledgebase({
      params: { page: 1, limit: 100 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = () => {
    const name = props.filters.title.split(" ").join("").toLowerCase();
    let tempData = props?.data?.filter((item) =>
      item.title.split(" ").join("").toLowerCase().includes(name),
    );
    const sortKey = props.filters.sortKey.key;
    const isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    const attribute = props.filters.sortKey.attribute;
    if (tempData?.[0]?.[sortKey]) {
      tempData = Sort({
        data: tempData,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return tempData;
  };

  return (
    <styles.MainContainer>
      <MainHeader title={"Knowledgebase"} />
      <Header
        title='Knowledgebase'
        buttons={[
          {
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/knowledgebase",
            },
            visible: true,
          },
          {
            title: "Add",
            onPress: {
              event: navigate,
              params: "/ap/knowledgebase/add",
            },
            visible: props?.permissions?.knowledgebase_add,
          },
        ]}
        onSync={{
          event: props.fetchGroups,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width='5%'>
              Sno.
            </th>
            <th align='left' width='15%'>
              Title
            </th>
            <th align='left' width='50%'>
              Description
            </th>
            <th align='left' width='10%'>
              Active
            </th>
            <th align='left' width='10%'>
              -
            </th>
            <th align='left' width='10%'>
              -
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData()?.map((knowledgebase, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{knowledgebase.title}</td>
              <td className='desc'>{knowledgebase.description}</td>
              <td>
                {knowledgebase.active ? (
                  <TiTick size={20} color={"#54B435"} />
                ) : (
                  <RiCloseFill size={20} color={"#FF6464"} />
                )}
              </td>
              <td>
                <Tooltip
                  text={"Edit members and groups"}
                  copy={false}
                  element={
                    <MdLockOutline
                      className='edit'
                      onClick={() => navigate(`/ap/knowledgebase/${knowledgebase._id}/members`)}
                      size={15}
                    />
                  }
                />
              </td>
              <td>
                <Tooltip
                  text={"Edit knowledgebase"}
                  copy={false}
                  element={
                    <MdEdit
                      className='edit'
                      onClick={() => navigate(`/ap/knowledgebase/${knowledgebase._id}/edit`)}
                      size={15}
                    />
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.KnowledgebaseReducer.filters,
  user: state.UserReducer,
  data: state.KnowledgebaseReducer.list.data,
  permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchKnowledgebase,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgebaseListing);
