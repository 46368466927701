import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SPSProjectList,
  AddSPSProject,
  UpdateSPSProject,
  FindSPSProject,
  DeleteSPSProject,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSPSProjectListSuccess, fetchSPSProjectError } from "./actions";
import Types from "./actionType";
import {
  CreateSPSProjectuccess,
  CreateSPSProject as CreateSPSProjectType,
  SPSProjectSuccess,
  UpdateSPSProject as UpdateSPSProjectType,
  FetchSPSProject,
  FetchSPSProjectById,
  SPSProjectByIdSuccess,
  DeleteSPSProject as DeleteSPSProjectType,
} from "./types";

function* createWorker(action: CreateSPSProjectType) {
  try {
    const options = {
      method: "POST",
      url: AddSPSProject,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSProjectuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateSPSProjectType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSPSProject(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSProjectuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchSPSProject) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SPSProjectList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SPSProjectSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchSPSProjectListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchSPSProjectError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching SPS Project");
    }
  }
}

function* fetchSPSProjectById(action: FetchSPSProjectById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSPSProject(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SPSProjectByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching SPS Project");
    }
  }
}

function* deleteWorker(action: DeleteSPSProjectType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteSPSProject(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateSPSProjectuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SPS_PROJECTS, createWorker);
  yield takeLatest(Types.UPDATE_SPS_PROJECTS, updateWorker);
  yield takeEvery(Types.FETCH_SPS_PROJECTS, fetchAllWorker);
  yield takeEvery(Types.FETCH_SPS_PROJECTS_BY_ID, fetchSPSProjectById);
  yield takeEvery(Types.DELETE_SPS_PROJECTS, deleteWorker);
}
