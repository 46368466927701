import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSAccountsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    name: {
      title: "Name",
      sortSupported: true,
      searchSupported: true,
      key: "name",
    },
    type: {
      title: "Type",
      key: "type",
      render: (item) => {
        return <text className='title'>{item?.type?.value || "-"}</text>;
      },
    },
    status: {
      title: "Status",
      key: "status",
      render: (item) => {
        return <text className='title'>{item?.status?.value || "-"}</text>;
      },
    },
    industry: {
      title: "Industry",
      sortSupported: true,
      key: "industry",
    },
    size: {
      title: "Size",
      sortSupported: true,
      key: "size",
    },
    region: {
      title: "Region",
      sortSupported: true,
      key: "region",
    },
    country: {
      title: "Country",
      key: "country",
      render: (item) => {
        return <text className='title'>{item?.country?.name || "-"}</text>;
      },
    },
    website: {
      title: "Website",
      sortSupported: true,
      searchSupported: true,
      key: "website",
    },
    salesRepresentative: {
      title: "Sales   Representative",
      key: "salesRepresentative",
      render: (item) => {
        return <text className='title'>{item?.salesRepresentative?.name || "-"}</text>;
      },
    },
    externalLinks: {
      title: "External Links",
      sortSupported: true,
      searchSupported: true,
      key: "externalLinks",
    },
    addressInfo: {
      title: "Address Info",
      sortSupported: true,
      searchSupported: true,
      key: "addressInfo",
    },
    street: {
      title: "Street",
      sortSupported: true,
      searchSupported: true,
      key: "street",
    },
    city: {
      title: "City",
      sortSupported: true,
      searchSupported: true,
      key: "city",
    },
    state: {
      title: "State",
      sortSupported: true,
      searchSupported: true,
      key: "state",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
