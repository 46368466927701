import React, { useState } from "react";

import Modal from "react-modal";
import "./modal.css";

const ModalComponent = ({ isOpen = false, closeModal, children }) => {
  return (
    <Modal
      // style={{ overlay: { zIndex: 1000 } }}
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      //   contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
