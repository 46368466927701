import React from "react";

import { Layout } from "../../../presentationals";
import { NewsletterResourcesController } from "../../../presentationals/screens/admin/NewsletterResources/NewsletterResourcesController";

const Route = () => {
  return (
    <Layout activity={<NewsletterResourcesController type={"add"} />} admin={true} layout={"2"} />
  );
};

export const APNewsletterResourcesAdd = React.memo(Route);
