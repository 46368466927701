import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { ITNServicesControllerProps } from "../../../../types/models/ITNServices";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { ControllerMessage, Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData, Checkbox } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useITNServicesController } from "./hooks";

const Controller = ({ type }: ITNServicesControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchITNServices,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleCheckbox,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useITNServicesController({ type, id, attachmentsPickerRef });
  const currentITNServices = data;

  const {
    workLogs,
    attachments,
    descriptionLabel,
    description,
    active,
    downloadButton,
    name,
    coverImageAttached,
    serviceFileAttached,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchITNServices();
    }
    // Do not include fetchITNServices to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <ControllerMessage>
          Cover Image and Service File should be attached in order to display image for the website.
        </ControllerMessage>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              disabled: true,
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Description Title'
            type={"text"}
            width='48%'
            error={descriptionLabel.error}
            inputProps={{
              name: "descriptionLabel",
              value: descriptionLabel.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: descriptionLabel.required,
            }}
          />{" "}
          <TextInput
            label='Download Button Title'
            type={"text"}
            width='48%'
            error={downloadButton.error}
            inputProps={{
              name: "downloadButton",
              value: downloadButton.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: downloadButton.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Description'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              rows: 8,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
        <Row>
          <Checkbox
            label={"Cover Image Attached"}
            width='48%'
            error={coverImageAttached.error}
            required={coverImageAttached.required}
            onClick={handleCheckbox}
            value={coverImageAttached.value}
            attributes={{
              "data-name": "coverImageAttached",
            }}
          />
          <Checkbox
            label={"Service File Attached"}
            width='48%'
            error={serviceFileAttached.error}
            required={serviceFileAttached.required}
            onClick={handleCheckbox}
            value={serviceFileAttached.value}
            attributes={{
              "data-name": "serviceFileAttached",
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    coverImageAttached.error,
    coverImageAttached.required,
    coverImageAttached.value,
    description.error,
    description.required,
    description.value,
    descriptionLabel.error,
    descriptionLabel.required,
    descriptionLabel.value,
    downloadButton.error,
    downloadButton.required,
    downloadButton.value,
    handleCheckbox,
    name.error,
    name.required,
    name.value,
    onTextChange,
    serviceFileAttached.error,
    serviceFileAttached.required,
    serviceFileAttached.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentITNServices?.createdBy !== "string" ? currentITNServices?.createdBy?.name : "";
    const updatedBy =
      typeof currentITNServices?.updatedBy !== "string" ? currentITNServices?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentITNServices?.createdAt}
        updatedAt={currentITNServices?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentITNServices?.createdAt,
    currentITNServices?.createdBy,
    currentITNServices?.updatedAt,
    currentITNServices?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.itnServices.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.itnServices.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderActivitylogs,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ITNServicesController = memo(Controller);
