import { useState, useMemo, useCallback, MouseEvent } from "react";

interface useMultiStepFormType {
  currentStepIndex: number;
  step: string;
  goTo: (e: MouseEvent<HTMLDivElement>) => void;
  next: () => void;
  back: () => void;
  steps: string[];
  fields: any[];
  getItem: any;
}

export const useMultiStepForm = (data: object): useMultiStepFormType => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const steps = Object.keys(data);
  let fields: string[] = [];
  const getItem = useMemo(() => {
    return data[steps[currentStepIndex]];
  }, [currentStepIndex, data, steps]);

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= steps.length - 1 ? i : i + 1));
  }, [steps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);
  if (getItem.fields) {
    fields = Object.keys(getItem?.fields);
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    goTo,
    next,
    back,
    steps,
    fields: fields ? fields : [],
    getItem,
  };
};
