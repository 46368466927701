import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getBankDetailsColumns = ({ fields, recordType }: ColumnsProps) => {
  const tableColumns = {
    associatedEmployee: {
      title: "Associated Employee",
      sortSupported: true,
      key: "associatedEmployee",
      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    associatedCompany: {
      title: "Company",
      sortSupported: true,
      key: "associatedCompany",
      render: (item) => {
        return <text className='title'>{item?.associatedCompany?.company || "-"}</text>;
      },
    },
    bankName: {
      title: "Bank Name",
      sortSupported: true,

      key: "bankName",
    },
    accountName: {
      title: "Account Name",
      sortSupported: true,
      key: "accountName",
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",
      render: (item) => {
        return <text className='title'>{item.active ? "Yes" : "No"}</text>;
      },
    },
    bankCountry: {
      title: "Country",
      key: "bankCountry",
      render: (item) => {
        return <text className='title'>{item.bankCountry?.name || "-"}</text>;
      },
    },
    accountType: {
      title: "Account Type",
      key: "accountType",
      render: (item) => {
        return <text className='title'>{item.accountType?.value || "-"}</text>;
      },
    },
    bankCurrency: {
      title: "Currency",
      key: "bankCurrency",
      render: (item) => {
        return <text className='title'>{item.bankCurrency?.name || "-"}</text>;
      },
    },
    associatedRecord: {
      title: "Associated To",
      key: "associatedRecord",
      render: (item) => {
        return (
          <text className='title'>
            {recordType === "associatedEmployee" ? "Employee" : "Company"}
          </text>
        );
      },
    },
    bankType: {
      title: "Type of the Bank",
      key: "bankType",
      render: (item) => {
        return <text className='title'>{item?.bankType?.value || "-"}</text>;
      },
    },
    defaultAccount: {
      title: "Default Account",
      key: "defaultAccount",
      render: (item) => {
        return <text className='title'>{item?.defaultAccount ? "Yes" : "No"}</text>;
      },
    },
    swiftBICCode: {
      title: "Swift / BIC Code",
      key: "swiftBICCode",
    },
    routingType: {
      title: "Bank Routing Details",
      key: "routingType",
      render: (item) => {
        return <text className='title'>{item.routingType?.value || "-"}</text>;
      },
    },
    routingNumber: {
      title: "Bank Routing Number",
      key: "routingNumber",
    },
    accountNumber: {
      title: "Bank Account Number",
      key: "accountNumber",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
