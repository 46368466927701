import Types from "./actionType";
import { ServiceNowSolutionsActions, ServiceNowSolutionsState } from "./types";

const initialState: ServiceNowSolutionsState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const ServiceNowSolutionsReducer = (
  state = initialState,
  action: ServiceNowSolutionsActions,
) => {
  switch (action.type) {
    case Types.FETCH_SERVICE_NOW_SOLUTIONS:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_SERVICE_NOW_SOLUTIONS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
