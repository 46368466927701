import React from "react";

import styles from "./button.styles";

interface Props {
  label: string;
  disabled?: boolean;
  block?: boolean;
  size?: "small" | "normal" | "large";
  onClick: any;
  attributes?: object;
  inactive?: boolean;
  type?: "primary" | "secondary" | "cancel";
  onPress?: Function;
}

const Button: React.FC<Props> = ({
  label,
  disabled = false,
  block = true,
  size = "normal",
  onClick,
  attributes = {},
  inactive = false,
  type = "primary",
}: Props) => {
  let sizeInfo = 1;
  switch (size) {
    case "small":
      sizeInfo = 0.75;
      break;
    default:
    case "normal":
      sizeInfo = 1;
      break;
    case "large":
      sizeInfo = 1.25;
      break;
  }

  const onClickHandle = (e: any) => {
    e.preventDefault();
  };

  return (
    <styles.container
      {...attributes}
      sizeInfo={sizeInfo}
      onClick={disabled ? onClickHandle : onClick}
      block={block}
      type={type}
    >
      <styles.button
        {...attributes}
        sizeInfo={sizeInfo}
        onClick={onClickHandle}
        disabled={disabled}
        inactive={inactive}
        type={type}
      >
        {label}
      </styles.button>
    </styles.container>
  );
};

export default Button;
