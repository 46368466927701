/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SUB_CATEGORIES = "FETCH_SUB_CATEGORIES",
  FETCH_SUB_CATEGORIES_BY_ID = "FETCH_SUB_CATEGORIES_BY_ID",
  SUCCESS_FETCH_SUB_CATEGORIES = "SUCCESS_FETCH_SUB_CATEGORIES",
  ERROR_FETCH_SUB_CATEGORIES = "ERROR_FETCH_SUB_CATEGORIES",
  CREATE_SUB_CATEGORIES = "CREATE_SUB_CATEGORIES",
  UPDATE_SUB_CATEGORIES = "UPDATE_SUB_CATEGORIES",
  DELETE_SUB_CATEGORIES = "DELETE_SUB_CATEGORIES",
}

export default Types;
