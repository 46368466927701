/* eslint-disable no-unused-vars */
enum Types {
  FETCH_Filter_FILTER = "FETCH_Filter_FILTER",
  FETCH_FilterS = "FETCH_FilterS",
  SUCCESS_FETCH_Filter_FILTER = "SUCCESS_FETCH_Filter_FILTER",
  SET_META_DATA_Filter_FILTER = "SET_META_DATA_Filter_FILTER",
  ERROR_FETCH_Filter_FILTER = "ERROR_FETCH_Filter_FILTER",
  GET_Filter_FILTER_BY_ID = "GET_Filter_FILTER_BY_ID",
  CREATE_Filter_FILTER = "CREATE_Filter_FILTER",
  UPDATE_Filter_FILTER = "UPDATE_Filter_FILTER",
  UPDATE_FITERS_Filter_FILTER = "UPDATE_FITERS_Filter_FILTER",
  Filter_FILTER_FETCH_SUCCESS = "Filter_FILTER_FETCH_SUCCESS",
  FilterS_FETCH_SUCCESS = "DASHBOARS_FETCH_SUCCESS",
}

export default Types;
