import styled from "styled-components";

const styles = {
  MainContainer: styled.div`
    height: 100%;
  `,
  Header: styled.div`
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }
    align-items: center;
    margin-bottom: 1vh;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Column: styled.div`
    flex: 1;
  `,
};

export default styles;
