import React, { useEffect, useRef, useState } from "react";

// additional imports
import { GrAttachment } from "react-icons/gr";
import { MdCancel, MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import SunEditor from "suneditor-react";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import ModalComponent from "../../components/Modal/Modal";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import ToolTip from "../../components/Tooltip/Tooltip";
import config from "../../config";
import { Header } from "../../presentationals/components";
import { uploadFile } from "../../redux/application/actions";
import {
  fetchAllKnowledge,
  createKnowledge,
  updateApproval,
  updateReview,
  updateKnowledge,
  reviseKnowledge,
  retireKnowledge,
} from "../../redux/knowledge/actions";
import { fetchKnowledgebase } from "../../redux/knowledgebase/actions";
import {
  formValidationMethod,
  useTitle,
  MomentDate,
  checkRevisePermission,
  getArticlePermissions,
  getPrefixDigit,
} from "../../services/methods";
import { strings } from "../../theme";

import styles from "./KnowledgeController.styles";

const publishedArticleActions = ["Revise", "Retire"];
const draftArticleActions = ["Request Review"];

const actionsPermissions = ["owner", "manager", "comanager"];

const KnowledgeController = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const readOnly = ["edit", "add", "status"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState("");
  const defaultBody = "";
  const [body, setBody] = useState(defaultBody);
  const [attachments, setAttachments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [knowledgeBase, setKnowledgebase] = useState([]);
  const [currentKnowledge, setCurrentKnowledge] = useState({});
  const [approvalList, setApprovalList] = useState([]);
  const [approvedByCurrentUser, setApprovedByCurrentUser] = useState(false);
  const [actionType, setActionType] = useState({
    label: "Select...",
    value: "",
  });
  const [createdBySelf, setCreatedBySelf] = useState(false);
  const [knowledgebaseRole, setKnowledgebaseRole] = useState(false);
  const [groupRole, setGroupRole] = useState(false);
  const onChangeBody = (event) => {
    setBody(event);
  };
  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const [versions, setVersions] = useState([]);
  const defaultData = {
    name: {
      required: true,
      type: "",
      error: "",
      value: "",
    },

    summary: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    body: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    knowledgebase: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    modules: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    category: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    subcategory: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    groups: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    status: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    comment: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
  };

  const setHistory = (find) => {
    const history = [];
    if (find.revisedKnowledge && find.serial) {
      const label = `V 1.${find.revisionHistory.length + 1} - ${
        config.prefix.knowledge
      } ${getPrefixDigit(find.serial, 5)}`;
      history.push({
        label: label,
        value: find.revisedKnowledge,
      });
    }
    if (find.revisionHistory.length > 0) {
      for (let i = 0; i < find.revisionHistory.length; i++) {
        const knowledge = props.knowledge.find((item) => item._id === find.revisionHistory[i]);
        console.log("Revision ::", find.revisionHistory[i], knowledge);
        if (knowledge?.serial) {
          const label = `V 1.${knowledge.revisionHistory.length} - ${
            config.prefix.knowledge
          } ${getPrefixDigit(knowledge?.serial, 5)}`;
          history.push({
            label: label,
            value: find.revisionHistory[i],
          });
        }
      }
    }
    setVersions(history);
  };

  useEffect(() => {
    props.fetchKnowledgebase({
      isEmployee: true,
    });
    if (props.type === "status") {
      setFormData(defaultData);
      if (props.knowledge.length > 0) {
        const find = props.knowledge.find((item) => item._id === params.id);
        console.log("Find ::", find);
        if (find) {
          setCurrentStatus(find.status);

          setCurrentKnowledge(find);
          setHistory(find);
          if (find.knowledgebase) {
            const { createdBy, groupRole, knowledgebaseRole } = getArticlePermissions(
              find,
              props.userId,
            );
            setCreatedBySelf(createdBy);
            setKnowledgebaseRole(knowledgebaseRole);
            setGroupRole(groupRole);
          }

          const members = {};
          const approvalType = find.knowledgebase?.approval;
          let isApprovedByAnyone = false;
          let isRejectedByAnyone = false;
          for (let i = 0; i < find.approvals.length; i++) {
            const user = find.approvals[i].user;
            if (user) {
              const object = {
                id: user._id,
                name: user.name,
                status: find.approvals[i].status === "approved" ? "Approved" : "Rejected",
              };
              if (object.id === props.userId && object.status === "Approved") {
                setApprovedByCurrentUser(true);
              }
              if (find.approvals[i].status === "approved") {
                isApprovedByAnyone = true;
              }
              if (find.approvals[i].status === "rejected") {
                isRejectedByAnyone = true;
              }
              members[object.id] = object;
            }
          }
          console.log("Members ::", find);
          for (let i = 0; i < find?.knowledgebase?.members.length; i++) {
            if (
              !members[find?.knowledgebase?.members[i].user._id] &&
              find?.knowledgebase?.members[i]?.role !== "member"
            ) {
              let tempStatus = "Pending Approval";
              if (approvalType === "anyone") {
                if (isApprovedByAnyone || isRejectedByAnyone) {
                  tempStatus = "No Longer Required";
                }
              } else if (approvalType === "all") {
                if (isRejectedByAnyone) {
                  tempStatus = "No Longer Required";
                }
              }

              const object = {
                id: find?.knowledgebase?.members?.[i].user._id,
                name: find?.knowledgebase?.members?.[i].user.name,
                status: tempStatus,
              };
              members[object.id] = object;
            }
          }
          const groups = find?.knowledgebase?.contributers;
          for (let i = 0; i < groups?.length; i++) {
            if (groups[i]?.active) {
              if (groups?.[i]?.members?.length > 0) {
                const groupMembers = groups?.[i]?.members;
                if (groupMembers?.length > 0) {
                  for (let i = 0; i < groupMembers?.length; i++) {
                    if (
                      !members[groupMembers?.[i].user._id] &&
                      groupMembers?.[i]?.role !== "member"
                    ) {
                      let tempStatus = "Pending Approval";
                      if (approvalType === "anyone") {
                        if (isApprovedByAnyone || isRejectedByAnyone) {
                          tempStatus = "No Longer Required";
                        }
                      } else if (approvalType === "all") {
                        if (isRejectedByAnyone) {
                          tempStatus = "No Longer Required";
                        }
                      }
                      const object = {
                        id: groupMembers?.[i].user._id,
                        name: groupMembers?.[i].user.name,
                        status: tempStatus,
                      };
                      members[object.id] = object;
                    }
                  }
                }
              }
            }
          }
          setApprovalList(members);
        }
      }
    }
    if (props.type === "add" || props.type === "edit") {
      if (props.type === "add") {
        viewType.current = props.type;
        setLoader(false);
        setSubmitting(false);
        setFormData(defaultData);
        setId("");
        setToast({
          message: "",
          message_type: "",
        });
      } else if (id !== params.id || viewType.current !== props.type) {
        console.log("Params", params, props);
        viewType.current = props.type;
        setFormData(defaultData);
        setLoader(true);
        if (props.openKnowledgebase.length > 0) {
          const find = props.knowledge.find((item) => item._id === params.id);
          if (props.revise) {
            console.log("Revise ::", find);
            props.reviseKnowledge({
              id: params.id,
              onSuccess: (res) => {
                const revisedId = res.data?._id;
                console.log("Revise::", revisedId);
                props.fetchAllKnowledge({
                  onSuccess: (data) => {
                    const find = data?.data?.find((item) => item._id === revisedId);
                    console.log("Success ::", find, data, revisedId);
                    if (find) {
                      setCurrentKnowledge(find);
                      setHistory(find);
                      updateValues(find);
                      setLoader(false);
                      setSubmitting(false);
                      setId(find._id);
                      setToast({
                        message: "",
                        message_type: "",
                      });
                    }

                    setSubmitting(false);
                  },
                  onError: (e) => {
                    setToast({
                      message: "Updated Successfully, Please reload page",
                      message_type: "warning",
                    });
                    console.log("Error ::", e);
                    setLoader(false);
                    setSubmitting(false);
                  },
                });
              },
              onError: (e) => {
                console.log(e, e.length > 0);
                setLoader(false);
                if (Array.isArray[e] && e.length > 0) {
                  setToast({
                    message: e[0],
                    message_type: "warning",
                  });
                }
              },
            });
          } else {
            const find = props.knowledge.find((item) => item._id === params.id);
            if (find) {
              setCurrentKnowledge(find);
              setHistory(find);
              updateValues(find);
              setLoader(false);
              setSubmitting(false);
              setId(find._id);
              setToast({
                message: "",
                message_type: "",
              });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.knowledgebase, props.knowledge]);

  const [formData, setFormData] = useState(defaultData);
  const [metaData, setMetaData] = useState({});
  const updateValues = (data) => {
    const object = { ...formData };
    console.log("data", data);
    const metaData = {
      createdBy: data?.createdBy?.name,
      createdAt: data?.createdAt,
      updatedBy: data?.updatedBy?.name,
      updatedAt: data?.updatedAt,
    };
    setMetaData(metaData);
    Object.keys(data).forEach((element) => {
      const name = element;
      let value = data[element];
      if (element === "category" || element === "subcategory") {
        value = value?._id;
      } else if (element === "groups" && Array.isArray(value)) {
        const temp = [];
        for (let i = 0; i < value.length; i++) {
          const tempValue = {
            value: value[i]._id,
            label: value[i].name,
          };
          temp.push(tempValue);
        }
        value = temp;
      } else if (element === "knowledgebase") {
        value = value?._id;
      } else if (element === "modules") {
        const temp = [];
        for (let i = 0; i < value.length; i++) {
          const tempValue = {
            value: value[i]._id,
            label: value[i].name,
          };
          temp.push(tempValue);
        }
        value = temp;
      } else if (element === "attachments") {
        const temp = [];
        for (let i = 0; i < value.length; i++) {
          const tempValue = {
            id: value[i].id,
            error: "",
            name: value[i].name,
          };
          temp.push(tempValue);
        }
        setAttachments(temp);
      } else if (element === "body") {
        setBody(value);
        // setDefaultBody(value);
      }
      if (formData[element] && value !== undefined && value !== null) {
        object[name] = {
          ...object[name],
          value: value,
        };
      }
    });
    setFormData(object);
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = formValidationMethod(name, value, formData);
    console.log(value, e.target.type, e.target.type);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const onActionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    setActionType({
      ...actionType[name],
      value: value,
    });
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Knowledge";
      case "add":
        return "Add Knowledge";
      case "edit":
        return "Edit Knowledge";
      case "status":
        return "Actions";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const onSubmitAddForm = () => {
    let submit = validateForm();
    const generateData = {};

    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        if (el === "body") {
          if (body.trim().length === 0) {
            alert("Please enter body");
            submit = false;
          }
          generateData[el] = body;
        } else if (["groups", "modules"].includes(el)) {
          const temp = [];
          for (let i = 0; i < formData[el].value.length; i++) {
            temp.push(formData[el].value[i].value);
          }
          generateData[el] = temp;
        } else {
          generateData[el] = formData[el].value;
        }
      }
    });
    generateData["attachments"] = attachments.map((item) => ({
      name: item.name,
      id: item.id,
    }));
    if (submit) {
      setSubmitting(true);
      props.createKnowledge({
        data: generateData,
        onSuccess: (data) => {
          props.fetchAllKnowledge({
            onSuccess: () => {
              console.log("Data", data);
              navigate(`/ep/knowledge/${data.data._id}/details`);
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);

    return output;
  };

  const onSubmitUpdateForm = () => {
    let submit = validateForm();
    const generateData = {};

    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        if (el === "body") {
          if (body.trim().length === 0) {
            alert("Please enter body");
            submit = false;
          }
          generateData[el] = body;
        } else if (["groups", "modules"].includes(el)) {
          const temp = [];
          for (let i = 0; i < formData[el].value.length; i++) {
            temp.push(formData[el].value[i].value);
          }
          generateData[el] = temp;
        } else {
          generateData[el] = formData[el].value;
        }
      }
    });
    generateData["attachments"] = attachments.map((item) => ({
      name: item.name,
      id: item.id,
    }));
    if (submit) {
      setSubmitting(true);
      props.updateKnowledge({
        id: id,
        data: generateData,
        onSuccess: (data) => {
          props.fetchAllKnowledge({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setSubmitting(false);
              const id = data?.data?._id;
              if (id) {
                navigate(`/ep/knowledge/${id}/details`);
              }
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    if (submitting) {
      return true;
    }
    if (currentStatus === "drafted") {
      return false;
    } else if (
      currentStatus === "inreview" &&
      (formData.status.value === "" || formData.comment.value === "")
    ) {
      return true;
    } else {
      const count = Object.keys(formData).filter((item) => formData[item].error);
      const value = count.length > 0 ? true : false;
      return value;
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    // var name = e.target.name;
    const index = e.currentTarget.getAttribute("data-index");
    const newArray = [...attachments];
    if (!newArray[index]["name"]) {
      const fileName = file?.name?.split(".");
      fileName?.pop();
      newArray[index]["name"] = fileName?.join(".");
      setAttachments(newArray);
    }
    props.uploadFile({
      file: file,
      onSuccess: (data) => {
        const newArray = [...attachments];
        newArray[index]["id"] = data.id;
        newArray[index]["error"] = "";
        setAttachments(newArray);
      },
      onError: (err) => {
        const newArray = [...attachments];
        newArray[index]["error"] = err;
        setAttachments(newArray);
      },
      onProgress: (progress) => {
        const newArray = [...attachments];
        newArray[index]["error"] = `Upload in progress (${progress}%)`;
        setAttachments(newArray);
      },
    });
  };
  const addAttachment = () => {
    const newArray = [...attachments];
    newArray.push({ name: "", id: "" });
    setAttachments(newArray);
  };

  const removeAttachment = (index) => {
    const newArray = [...attachments];
    newArray.splice(index, 1);
    setAttachments(newArray);
  };

  const attachmentTextChange = (e) => {
    const newArray = [...attachments];
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const index = e.currentTarget.getAttribute("data-index");
    newArray[index][name] = value;
    setAttachments(newArray);
  };
  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };
  const onSubmitStatus = () => {
    if (currentStatus === "drafted") {
      setSubmitting(true);
      props.updateReview({
        id: params.id,
        onSuccess: (data) => {
          props.fetchAllKnowledge({
            onSuccess: () => {
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      setSubmitting(true);
      props.updateApproval({
        data: {
          id: params.id,
          status: formData.status.value,
          comment: formData.comment.value,
        },
        onSuccess: (data) => {
          navigate("/ep/knowledge/owner?type=owner");
          props.fetchAllKnowledge({
            onSuccess: () => {
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    }
  };

  const isRejected = () => {
    for (let i = 0; i < currentKnowledge?.approvals?.length; i++) {
      if (currentKnowledge.approvals[i].status === "rejected") {
        return true;
      }
    }
    return false;
  };

  const onRetire = () => {
    setLoader(true);
    props.retireKnowledge({
      id: currentKnowledge?._id,
      onSuccess: () => {
        setLoader(false);
        navigate(`/ep/knowledge/owner?type=owner`);
      },
      onError: () => {
        setLoader(false);
      },
    });
  };

  const goToVersion = (e) => {
    const value = e.target.value;
    console.log(value, currentKnowledge);
    if (value) {
      navigate(`/ep/knowledge/${value}/details`);
    }
  };

  const onSaveStatus = () => {
    const action = actionType.value;
    if (action !== "") {
      if (action === "Revise") {
        if (window.confirm(`Are you sure you want to revise the knowledge ?`)) {
          navigate(`/ep/knowledge/${currentKnowledge?._id}/revise`);
        }
      } else {
        if (window.confirm(`Are you sure you want to ${actionType.value} ?`)) {
          if (actionType.value === "Retire") {
            onRetire();
          } else {
            onSubmitStatus();
          }
        }
      }
    }
  };

  const subCategoriesFilter = (item) => {
    if (formData.category.value == item.parentCategory) {
      return true;
    }
    return false;
  };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer onClick={() => console.log(formData, attachments)}>
      <Header title={strings.headerTitle.knowledgeAdd} />
      <styles.Header onClick={() => console.log(currentStatus)}>
        {props.type !== "status" && (
          <Link to='/ep/knowledge'>
            <MdArrowBack className='back' size={24} />
          </Link>
        )}
        <h2>{getTitle()}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />

      <form
        onSubmit={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        autoComplete='off'
      >
        {props.type !== "status" ? (
          <>
            <styles.Row>
              <styles.Column>
                <Input
                  readOnly={readOnly}
                  label={"Name"}
                  type={"text"}
                  name='name'
                  value={formData.name.value}
                  autoComplete={"off"}
                  onChange={onTextChange}
                  mandatory={true}
                  error={formData?.name?.error}
                />
              </styles.Column>
              <styles.Column>
                <styles.AttachmentIconContainer onClick={addAttachment}>
                  <ToolTip
                    text='Add Attachments  Supported Files: doc, docx, ppt, pdf, png, jpeg, xml, csv, xslx'
                    copy={false}
                    element={<GrAttachment size={20} />}
                  />
                </styles.AttachmentIconContainer>
              </styles.Column>
            </styles.Row>
            {attachments.map((item, index) => (
              <styles.Row key={index}>
                <styles.Column>
                  <Input
                    readOnly={false}
                    label={"Attachment Name"}
                    type={"text"}
                    name='name'
                    autoComplete={"off"}
                    onChange={attachmentTextChange}
                    value={item.name}
                    attributes={{ "data-index": index }}
                    mandatory={true}
                    // error={formData?.subject?.error}
                  />
                </styles.Column>
                <styles.Column>
                  <Input
                    readOnly={false}
                    label={"Attachment"}
                    type={"file"}
                    name='attachment'
                    message={item.attachment}
                    onMessageClick={() => getDocument(item.attachment)}
                    autoComplete={"off"}
                    onChange={onFileChange}
                    mandatory={true}
                    attributes={{ "data-index": index }}
                    error={item.error}
                  />
                </styles.Column>
                <styles.Column>
                  <Button
                    type={"cancel"}
                    onClick={() => removeAttachment(index)}
                    size={"normal"}
                    label={"Remove"}
                  />
                </styles.Column>
              </styles.Row>
            ))}
            <styles.Row>
              <styles.Column>
                <Select
                  name={"knowledgebase"}
                  label={"Knowledgebase"}
                  defaultFocused={true}
                  options={[
                    { label: "Select...", value: "" },
                    ...props.openKnowledgebase.map((item) => ({
                      label: item.title,
                      value: item._id,
                    })),
                  ]}
                  mandatory={true}
                  value={formData.knowledgebase.value}
                  onChange={onTextChange}
                />
              </styles.Column>
              <styles.Column>
                <Select
                  name={"category"}
                  label={"Categories"}
                  // isMulti={true}
                  defaultFocused={true}
                  options={props.categories.map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  value={formData.category.value}
                  onChange={onTextChange}
                />
              </styles.Column>
              {/* {Array.isArray(props.subcategories) &&
                props.subcategories.length > 0 &&
                formData.category.value && ( */}
              <styles.Column>
                <Select
                  name={"subcategory"}
                  label={"SubCategories"}
                  // isMulti={true}
                  defaultFocused={true}
                  options={props.subcategories.filter(subCategoriesFilter).map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  value={formData.subcategory.value}
                  onChange={onTextChange}
                />
              </styles.Column>
              {/* )} */}
              <styles.Column>
                {Array.isArray(props.modules) && (
                  <Select
                    name={"modules"}
                    label={"Tags"}
                    defaultFocused={true}
                    isMulti={true}
                    options={props?.modules?.map((item) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                    value={formData.modules.value}
                    onChange={onTextChange}
                  />
                )}
              </styles.Column>
            </styles.Row>
            {!props.openKnowledgebase?.length > 0 && (
              <Toast
                type={"error"}
                message={
                  "You can only create a knowledge when you are part of atleast one knowledgebase"
                }
              />
            )}
            {/* <ToolTip
              text="Add Additional Groups if Required"
              copy={false}
              element={ */}
            {/* <Select
              name={"groups"}
              label={"Additional Groups"}
              defaultFocused={true}
              isMulti={true}
              options={[
                { label: "Select", value: "" },
                ...props.openGroups.map((item) => ({
                  label: item.name,
                  value: item._id,
                })),
              ]}
              value={formData.groups.value}
              onChange={onTextChange}
            /> */}
            {/* }
            /> */}

            <Input
              readOnly={readOnly}
              label={"Summary"}
              type={"textarea"}
              name='summary'
              value={formData.summary.value}
              autoComplete={"off"}
              onChange={onTextChange}
              mandatory={true}
              error={formData?.summary?.error}
            />
            <SunEditor
              lang={"en"}
              placeholder='Please enter the body here'
              height='30vh'
              defaultValue={defaultBody}
              // hideToolbar={true}
              // readOnly={true}
              onChange={onChangeBody}
              setContents={body}
              // imageUploadHandler={imageUploadHandler}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  // ['paragraphStyle', 'blockquote'],
                  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],

                  ["table", "horizontalRule", "link" /*"image"*/],
                  // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                  // ['imageGallery'], // You must add the "imageGalleryUrl".
                  ["fullScreen", "showBlocks", "codeView"],
                  ["preview", "print"],
                  ["removeFormat"],

                  // ['save', 'template'],
                  // '/', Line break
                ],
              }}
            />
            {!readOnly && (
              <Button
                onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
                disabled={SubmitDisabled()}
                size={"normal"}
                label={"Save"}
              />
            )}
          </>
        ) : (
          <>
            {currentStatus === "inreview" && (
              <>
                {actionsPermissions.includes(knowledgebaseRole) ||
                actionsPermissions.includes(groupRole) ? (
                  <>
                    <Select
                      name={"status"}
                      label={"Select Status"}
                      defaultFocused={true}
                      options={[
                        { label: "Select", value: "" },
                        { label: "Approve", value: "approved" },
                        { label: "Reject", value: "rejected" },
                      ]}
                      value={formData.status.value}
                      onChange={onTextChange}
                    />
                    <Input
                      readOnly={readOnly}
                      label={"Comment"}
                      type={"textarea"}
                      name='comment'
                      value={formData.comment.value}
                      autoComplete={"off"}
                      onChange={onTextChange}
                      mandatory={true}
                      error={formData?.comment?.error}
                    />
                    {!readOnly && (
                      <Button
                        onClick={onSubmitStatus}
                        disabled={SubmitDisabled()}
                        size={"normal"}
                        label={"Save"}
                      />
                    )}
                  </>
                ) : (
                  <div>You do not have permissions to perform any action</div>
                )}
              </>
            )}
            {currentStatus === "drafted" && (
              <>
                {actionsPermissions.includes(knowledgebaseRole) ||
                actionsPermissions.includes(groupRole) ||
                createdBySelf ? (
                  <>
                    <Select
                      name={"action"}
                      label={"Select Action"}
                      defaultFocused={true}
                      options={[
                        { label: "Select", value: "" },
                        ...draftArticleActions.map((item) => ({
                          label: item,
                          value: item,
                        })),
                      ]}
                      value={actionType.value}
                      onChange={onActionChange}
                    />
                    {!readOnly && (
                      <Button
                        onClick={onSaveStatus}
                        disabled={SubmitDisabled()}
                        size={"normal"}
                        label={"Save"}
                      />
                    )}
                  </>
                ) : (
                  <div>You do not have permissions to perform any action</div>
                )}
              </>
            )}

            {currentStatus === "published" && (
              <>
                {(actionsPermissions.includes(knowledgebaseRole) ||
                  actionsPermissions.includes(groupRole) ||
                  createdBySelf) &&
                !currentKnowledge?.revisedKnowledge ? (
                  <>
                    <Select
                      name={"action"}
                      label={"Select Action"}
                      defaultFocused={true}
                      options={[
                        { label: "Select", value: "" },
                        ...publishedArticleActions.map((item) => ({
                          label: item,
                          value: item,
                        })),
                      ]}
                      value={actionType.value}
                      onChange={onActionChange}
                    />
                    {!readOnly && (
                      <Button
                        onClick={onSaveStatus}
                        disabled={SubmitDisabled()}
                        size={"normal"}
                        label={"Save"}
                      />
                    )}
                  </>
                ) : (
                  <div>You can not perform any action on this article</div>
                )}
              </>
            )}
            {(actionsPermissions.includes(knowledgebaseRole) ||
              actionsPermissions.includes(groupRole)) &&
              currentKnowledge.revisionHistory && (
                <>
                  <h2 style={{ marginTop: "4px", marginBottom: "6px" }}>Version History</h2>
                  <Select
                    name={"versionHistory"}
                    label={"Select Version"}
                    defaultFocused={true}
                    options={[
                      { label: "Select", value: "" },
                      ...versions.map((item) => ({
                        label: item.label,
                        value: item.value,
                      })),
                    ]}
                    value={""}
                    onChange={goToVersion}
                  />
                </>
              )}
          </>
        )}

        {(isRejected() || currentKnowledge?.status === "inreview") && props.type === "status" && (
          <>
            <styles.approvalType>
              <h2>Approval Type</h2>
              <h3>{currentKnowledge?.knowledgebase?.approval === "all" ? "All" : "Any One"}</h3>
            </styles.approvalType>
            {Object.keys(approvalList).map((item) => {
              let comment = "";
              const isApproved = approvalList[item].status === "Approved";
              if (Array.isArray(currentKnowledge?.approvals)) {
                comment = currentKnowledge?.approvals.find(
                  (comment) => comment.user?._id === item,
                )?.comment;
              }
              return (
                <>
                  <styles.metaDataContainer>
                    <styles.metaDataItem>{approvalList[item].name}</styles.metaDataItem>
                    <styles.metaDataInfo>{approvalList[item].status}</styles.metaDataInfo>
                  </styles.metaDataContainer>
                  {comment && <styles.comment approved={isApproved}>{comment}</styles.comment>}
                </>
              );
            })}
          </>
        )}
      </form>
      <br />
      <br />
      <br />
      <br />
      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <Document id={documentId} />
      </ModalComponent>
      {/* <styles.metaDataContainer>
        <styles.metaDataItem>Created On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.createdAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Created By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.createdBy}</styles.metaDataInfo>
      </styles.metaDataContainer>

      <styles.metaDataContainer>
        <styles.metaDataItem>Updated On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.updatedAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Updated By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.updatedBy}</styles.metaDataInfo>
      </styles.metaDataContainer> */}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  knowledgebase: state.KnowledgebaseReducer.list,
  openKnowledgebase: state.KnowledgebaseReducer.openList,
  groups: state.GroupsReducer.list,
  openGroups: state.GroupsReducer.openList,
  modules: state.ModulesReducer.list,
  categories: state.CategoriesReducer.list,
  subcategories: state.SubCategoriesReducer.list,
  knowledge: state.KnowledgeReducer.list,
  currentUser: state.UserReducer.id,
  userId: state.UserReducer.id,
});

const mapDispatchToProps = {
  fetchAllKnowledge,
  createKnowledge,
  uploadFile,
  updateApproval,
  updateReview,
  fetchKnowledgebase,
  updateKnowledge,
  reviseKnowledge,
  retireKnowledge,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeController);
