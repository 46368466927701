import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  CertificationsAccreditationsList,
  AddCertificationsAccreditations,
  UpdateCertificationsAccreditations,
  FindCertificationsAccreditations,
  DeleteCertificationsAccreditations,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import {
  fetchCertificationsAccreditationsListSuccess,
  fetchCertificationsAccreditationsError,
} from "./actions";
import Types from "./actionType";
import {
  CreateCertificationsAccreditationsuccess,
  CreateCertificationsAccreditations as CreateCertificationsAccreditationsType,
  CertificationsAccreditationsSuccess,
  UpdateCertificationsAccreditations as UpdateCertificationsAccreditationsType,
  FetchCertificationsAccreditations,
  FetchCertificationsAccreditationsById,
  CertificationsAccreditationsByIdSuccess,
  DeleteCertificationsAccreditations as DeleteCertificationsAccreditationsType,
} from "./types";

function* createWorker(action: CreateCertificationsAccreditationsType) {
  try {
    const options = {
      method: "POST",
      url: AddCertificationsAccreditations,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCertificationsAccreditationsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateCertificationsAccreditationsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateCertificationsAccreditations(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCertificationsAccreditationsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchCertificationsAccreditations) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: CertificationsAccreditationsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<CertificationsAccreditationsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchCertificationsAccreditationsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchCertificationsAccreditationsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data?.message || "Error in fetching CertificationsAccreditations",
      );
    }
  }
}

function* fetchCertificationsAccreditationsById(action: FetchCertificationsAccreditationsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindCertificationsAccreditations(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CertificationsAccreditationsByIdSuccess> = yield call(
      axios,
      options,
    );
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data.message || "Error in fetching CertificationsAccreditations",
      );
    }
  }
}

function* deleteWorker(action: DeleteCertificationsAccreditationsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteCertificationsAccreditations(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateCertificationsAccreditationsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_CERTIFICATIONS_ACCREDITATIONS, createWorker);
  yield takeLatest(Types.UPDATE_CERTIFICATIONS_ACCREDITATIONS, updateWorker);
  yield takeLatest(Types.DELETE_CERTIFICATIONS_ACCREDITATIONS, deleteWorker);
  yield takeEvery(Types.FETCH_CERTIFICATIONS_ACCREDITATIONS, fetchAllWorker);
  yield takeEvery(
    Types.FETCH_CERTIFICATIONS_ACCREDITATIONS_BY_ID,
    fetchCertificationsAccreditationsById,
  );
}
