import Types from "./types";

const Initial = {
  list: [],
  filters: {
    sortKey: {},
    sortType: "asc",
  },
};

const MediaReducer = (state = Initial, action) => {
  switch (action.type) {
    case Types.MEDIA_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case Types.MEDIA_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default MediaReducer;
