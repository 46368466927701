import React from "react";

import { Layout } from "../../../presentationals";
import { PayrollsEmployeesListing } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<PayrollsEmployeesListing />}
      title={strings.headerTitle.payroll}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
