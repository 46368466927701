import Input from "../../../components/Input/input";
// import Select from "../../../../components/Select/select";
// import styles from "../EmployeesController.styles";
// import { generateArrayAttributes } from "../../../../services/methods";

export const Tab2 = ({ formData, readOnly, onTextChange }) => {
  return (
    <>
      <Input
        readOnly={readOnly}
        label={"Date of joining"}
        type={"date"}
        name='dateJoining'
        value={formData.dateJoining}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Hiring Date"}
        type={"date"}
        name='dateHire'
        value={formData.dateHire}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Training Start Date"}
        type={"date"}
        name='dateTrainingStart'
        value={formData.dateTrainingStart}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Training End Date"}
        type={"date"}
        name='dateTrainingEnd'
        value={formData.dateTrainingEnd}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Next Appraisal Date"}
        type={"date"}
        name='dateAppraisal'
        value={formData.dateAppraisal}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Termination Date"}
        type={"date"}
        name='dateTermination'
        value={formData.dateTermination}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Termination Reason"}
        type={"textarea"}
        name='terminationComment'
        value={formData.terminationComment}
        autoComplete={"off"}
        onChange={onTextChange}
      />
    </>
  );
};
