import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSInsuranceColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    insuranceCompany: {
      title: "Insurance Company",
      sortSupported: true,
      searchSupported: true,
      key: "insuranceCompany",
    },
    accountTakenfor: { title: "Account Taken For", sortSupported: true, key: "accountTakenfor" },
    amount: { title: "Amount", sortSupported: true, key: "amount" },
    associatedProject: {
      title: "Associated Project",
      key: "associatedProject",
      render: (item) => {
        return <text className='title'>{item.associatedProject?.projectName || "-"}</text>;
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    startDate: {
      title: "Start Date",
      sortSupported: true,
      key: "startDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.startDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    renewDate: {
      title: "Renew Date",
      sortSupported: true,
      key: "renewDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.renewDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
