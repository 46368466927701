import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteEmploymentHistory,
  fetchEmploymentHistoryList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateEmploymentHistory,
  updateTableConfigs,
} from "../../../../../redux";
import {
  DeleteEmploymentHistoryPayload,
  EmploymentHistoryList,
  EmploymentHistoryListWithMetaData,
  FetchEmploymentHistoryParams,
} from "../../../../../redux/EmploymentHistory/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchEmploymentHistoryProps,
  sortType,
  useEmploymentHistoryListProps,
  useEmploymentHistoryListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getEmploymentHistoryColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.employmentHistory;
const defaultFilters: QueryBuilderType[] = [];

export const useEmploymentHistoryList = ({
  relatedList,
  widgetId,
}: useEmploymentHistoryListProps): useEmploymentHistoryListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.EmploymentHistoryReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const employmentHistory = useAppSelector((state) => state.EmploymentHistoryReducer.data.list);
  const { employeesOptions, usersOptions, countryOptions, departmentOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const EmploymentHistoryConfigTableFields = useMemo(() => {
    return tableConfiguration?.employmentHistory?.fields?.length
      ? tableConfiguration?.employmentHistory?.fields
      : tableListDefaultSelectFields.employmentHistory;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = EmploymentHistoryConfigTableFields;
    return getEmploymentHistoryColumns({ fields });
  }, [EmploymentHistoryConfigTableFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: EmploymentHistoryList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.employmentHistory?.fields ||
        tableListDefaultSelectFields.employmentHistory;
      records = records?.map((item) => {
        const employmentHistoryItem = { ...item };
        if (userPersonalizedFields?.includes("country") && typeof item.country !== "string") {
          employmentHistoryItem.country = item?.country?.name;
        }
        if (
          userPersonalizedFields?.includes("associatedEmployee") &&
          typeof item.associatedEmployee !== "string"
        ) {
          employmentHistoryItem.associatedEmployee = item?.associatedEmployee?.name;
        }
        if (
          userPersonalizedFields?.includes("employmentType") &&
          typeof item.employmentType !== "string"
        ) {
          employmentHistoryItem.employmentType = item.employmentType?.value;
        }
        if (userPersonalizedFields?.includes("fromDate")) {
          employmentHistoryItem.startDate = MomentDate({
            date: item.startDate || "-",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("toDate")) {
          employmentHistoryItem.endDate = MomentDate({
            date: item.endDate || "-",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          employmentHistoryItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          employmentHistoryItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          employmentHistoryItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          employmentHistoryItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete employmentHistoryItem?._id;
        return employmentHistoryItem;
      });
      const employmentHistoryFields = tablePublicFields.employmentHistory;
      const columnHeaders = employmentHistoryFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "employment_history");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.employmentHistory?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Employment History Success/Error Methods ================
  const employmentHistoryFetchSuccess = useCallback(
    (data: EmploymentHistoryListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const employmentHistoryFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchEmploymentHistory = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchEmploymentHistoryProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.employmentHistory?.limit || 10;

      const params: FetchEmploymentHistoryParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: EmploymentHistoryListWithMetaData) =>
          employmentHistoryFetchSuccess(data, exportAll),
        onError: employmentHistoryFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchEmploymentHistoryList(payload));
    },
    [
      widgetId,
      widgetsData,
      dispatch,
      employmentHistoryFetchError,
      employmentHistoryFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = employmentHistory?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, employmentHistory, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchEmploymentHistory({ exportAll: true });
  }, [fetchEmploymentHistory]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchEmploymentHistory({ page });
  }, [fetchEmploymentHistory, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchEmploymentHistory({ page: parseInt(page), search });
    },
    [fetchEmploymentHistory, search],
  );

  const onClickEmploymentHistory = useCallback(
    (id: string) => {
      if (permissions?.employmentHistory_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.employmentHistory_view, notificationsId, dispatch],
  );
  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchEmploymentHistory({ page: 1, search });
  }, [fetchEmploymentHistory, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchEmploymentHistory({ page: 1 });
  }, [fetchEmploymentHistory]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchEmploymentHistory({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchEmploymentHistory],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchEmploymentHistory({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchEmploymentHistory],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = employmentHistory?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteEmploymentHistoryPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchEmploymentHistory({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteEmploymentHistory(payload));
  }, [selectedRows, employmentHistory, dispatch, notificationsId, fetchEmploymentHistory]);
  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = employmentHistory?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, employmentHistory]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            employmentHistory?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, employmentHistory, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const employmentHistoryConfigLimit = tableConfiguration?.employmentHistory?.limit || 10;
      const employmentHistoryConfigs = {
        table: "employmentHistory",
        fields: fields,
        limit: employmentHistoryConfigLimit,
      };
      const payload = {
        tableConfiguration: employmentHistoryConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchEmploymentHistory({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employment History table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      tableConfiguration?.employmentHistory?.limit,
      dispatch,
      fetchEmploymentHistory,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const employmentHistoryConfigFields = tableConfiguration?.employmentHistory?.fields;
      dispatch(showInAppLoader(true));
      const employmentHistoryConfigs = {
        table: "employmentHistory",
        fields: employmentHistoryConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: employmentHistoryConfigs,
        onSuccess: () => {
          dispatch(showInAppLoader(true));
          fetchEmploymentHistory({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employment History table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchEmploymentHistory],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = employmentHistory?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchEmploymentHistory({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employment History updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating employment history",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateEmploymentHistory(payload));
    },
    [selectedRows, employmentHistory, dispatch, notificationsId, fetchEmploymentHistory],
  );

  // ================ Table Header Buttons ================

  const employmentHistoryHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "associatedEmployee",
      label: "Employee",
      options: employeesOptions,
      required: true,
    },
    {
      name: "country",
      label: "Country",
      options: countryOptions,
      required: true,
    },
    {
      name: "employmentType",
      label: "employmentType",
      options: getAttributValues("employmentType"),
      required: true,
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      required: true,
    },
    {
      name: "currentlyWorking",
      label: "Currently Working",
      options: ActiveSelectOptions,
    },
    {
      name: "permissionToContact",
      label: "Permission To Contact",
      options: ActiveSelectOptions,
    },
    {
      name: "permissionToContactReason",
      label: "Permission To Contact Reason",
      type: "text",
      required: true,
    },
    {
      name: "employerName",
      label: "Employer Name",
      type: "text",
      required: true,
    },
    {
      name: "addressLineOne",
      label: "Address Line 1",
      type: "text",
      required: true,
    },
    {
      name: "addressLineTwo",
      label: "Address Line 2",
      type: "text",
      required: true,
    },
    {
      name: "state",
      label: "State/Province",
      type: "text",
      required: true,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
    },
    {
      name: "postalCode",
      label: "Postal Zone/ZIP Code",
      type: "text",
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      required: true,
    },
    {
      name: "extension",
      label: "Extension",
      type: "text",
      required: true,
    },
    {
      name: "jobType",
      label: "Job Type",
      options: getAttributValues("jobType"),
      required: true,
    },
    {
      name: "releventExperience",
      label: "Relevent Experience",
      options: ActiveSelectOptions,
      required: true,
    },
    {
      name: "isAgency",
      label: "Is Agency",
      options: ActiveSelectOptions,
      required: true,
    },
    {
      name: "jobTitle",
      label: "Job Title",
      type: "text",
      required: true,
    },
    {
      name: "department",
      label: "Department",
      options: departmentOptions,
      required: true,
    },
    {
      name: "supervisorName",
      label: "Supervisor Name",
      type: "text",
      required: true,
    },
    {
      name: "supervisorTitle",
      label: "Supervisor Title",
      type: "text",
      required: true,
    },
    {
      name: "supervisorEmail",
      label: "Supervisor Email",
      type: "text",
      required: true,
    },
    {
      name: "supervisorPhone",
      label: "Supervisor Phone",
      type: "text",
      required: true,
    },
    {
      name: "reasonForLeaving",
      label: "Reason For Leaving",
      type: "text",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const employmentHistoryFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const countryFilterOptions = getFilterOptions(countryOptions, "Technology");
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const employmentTypeOptions = getFilterOptions(
      getAttributValues("employmentType"),
      "Employment Type",
    );
    const jobTypeOptions = getFilterOptions(getAttributValues("jobType"), "Job Type");
    const departmentUSerOptions = getFilterOptions(departmentOptions, "Departments");
    const activeOptions = getFilterOptions(ActiveSelectOptions, "");
    const fields: Field[] = [
      {
        name: "associatedEmployee",
        label: "Employee",
        valueEditorType: "select",
        values: employeeyOptions,
        defaultValue: employeeyOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        defaultValue: countryFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "currentlyWorking",
        label: "Currently Working",
        valueEditorType: "checkbox",
        operators: defaultOperators.filter((op) => op.name === "="),
        defaultValue: false,
      },
      {
        name: "startDate",
        label: "Start Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<", "=", "!="].includes(op.name)),
      },
      {
        name: "endDate",
        label: "End Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "employmentType",
        label: "Employee Type",
        valueEditorType: "select",
        values: employmentTypeOptions,
        defaultValue: employmentTypeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "permissionToContact",
        label: "Permission To Contact",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "permissionToContactReason",
        label: "Permission To Contact Reason",
        inputType: "string",
        validator,
      },
      {
        name: "employerName",
        label: "Employer Name",
        inputType: "string",
        validator,
      },
      {
        name: "addressLineOne",
        label: "Address Line 1",
        inputType: "string",
        validator,
      },
      {
        name: "addressLineTwo",
        label: "Address Line 2",
        inputType: "string",
        validator,
      },
      {
        name: "state",
        label: "State",
        inputType: "string",
        validator,
      },
      {
        name: "city",
        label: "City",
        inputType: "string",
        validator,
      },
      {
        name: "postalCode",
        label: "Postal Zone/ZIP Code",
        inputType: "string",
        validator,
      },
      {
        name: "phone",
        label: "Phone",
        inputType: "string",
        validator,
      },
      {
        name: "extension",
        label: "Extension",
        inputType: "string",
        validator,
      },
      {
        name: "jobType",
        label: "Job Type",
        valueEditorType: "select",
        values: employmentTypeOptions,
        defaultValue: employmentTypeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "employmentType",
        label: "Employee Type",
        valueEditorType: "select",
        values: jobTypeOptions,
        defaultValue: jobTypeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "releventExperience",
        label: "Relevent Experience",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "isAgency",
        label: "Is Agency",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "jobTitle",
        label: "Job Title",
        inputType: "string",
        validator,
      },
      {
        name: "department",
        label: "Department",
        valueEditorType: "select",
        values: departmentUSerOptions,
        defaultValue: departmentUSerOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "supervisorName",
        label: "Supervisor Name",
        inputType: "string",
        validator,
      },
      {
        name: "supervisorTitle",
        label: "Supervisor Title",
        inputType: "string",
        validator,
      },
      {
        name: "supervisorEmail",
        label: "Supervisor Email",
        inputType: "string",
        validator,
      },
      {
        name: "supervisorPhone",
        label: "Supervisor Phone",
        inputType: "string",
        validator,
      },
      {
        name: "reasonForLeaving",
        label: "Reason For Leaving",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [countryOptions, employeesOptions, usersOptions, departmentOptions]);

  return {
    listActionOptions,
    employmentHistoryFilterFields,
    onClickEmploymentHistory,
    fetchEmploymentHistory,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    employmentHistoryHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
    columns,
    EmploymentHistoryConfigTableFields,
  };
};
