import React from "react";

import { Layout } from "../../../presentationals";
import { ResourcesController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<ResourcesController type={"add"} />} admin={true} layout={"2"} />;
};

export const APResourcesAdd = React.memo(Route);
