import React, { useEffect, useState, useCallback, useMemo } from "react";

import { MdList, MdSettingsAccessibility, MdCancel } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { fetchUsers, addUsers, updateUsers, getUserById } from "../../redux";
import { fetchEmployeesBasic } from "../../redux/employees/actions";
import { getProfile } from "../../redux/user/actions";
import { useTitle } from "../../services/methods";

import styles from "./UsersController.styles";

const Users = (props) => {
  const defaultForm = {
    loading: false,
    message: "",
    message_type: "",
    id: "",
    name: "",
    email: "",
    organisations: [],
    // admin: false,
    superAdmin: false,
    active: "",
  };
  useTitle(`${props.type === "edit" ? "Edit" : "Add"} User`);
  const navigate = useNavigate();
  const params = useParams();
  // const location = useLocation();
  const [formData, setFormData] = useState(defaultForm);
  const [tab, setTab] = useState("0");
  const [employeesList, setEmployeesList] = useState([]);
  const [newOrganisation, setNewOrganisation] = useState({
    label: "Select..",
    value: "",
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState("");
  const [selectedOrganisationPermissions, setSelectedOrganisationPermissions] = useState({});
  const [selectedOrganisationIndex, setSelectedOrganisationIndex] = useState("");
  const [admin, setAdmin] = useState(false);
  const [organisationAdmin, setOrganisationAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchEmployeesRecord = (organisations) => {
    props.fetchEmployeesBasic({
      organisation: organisations.map((el) => el.organisation.value).join(","),
      onSuccess: (data) => {
        setEmployeesList(data);
      },
      onError: (error) => {},
    });
  };

  const updateValues = (values) => {
    if (props.type === "edit") {
      const organisations = [];
      for (let i = 0; i < values.organisations.length; i++) {
        const el = values.organisations[i];
        if (el?.organisation?._id === props.UserReducer.organisation._id) {
          setAdmin(el.admin);
          setOrganisationAdmin(el.organisationAdmin);
        }
        if (el?.organisation) {
          organisations.push({
            organisation: {
              label: el?.organisation?.name,
              value: el?.organisation?._id,
            },
            employee: {
              label: el?.employee?.name,
              value: el?.employee?._id,
            },
            permissions: el.permissions,
            admin: el.admin,
            organisationAdmin: el.organisationAdmin,
          });
        }
      }
      setFormData({
        ...formData,
        message_type: "",
        message: "",
        loading: false,
        id: values._id,
        name: values.name,
        email: values.email,
        // admin: values.admin,
        superAdmin: values.superAdmin,
        organisations: organisations,
        active: values.active,
      });
      fetchEmployeesRecord(organisations);
      setNewOrganisation({
        label: "Select..",
        value: "",
      });
    } else {
      setFormData(defaultForm);
      setEmployeesList([]);
      setNewOrganisation({
        label: "Select..",
        value: "",
      });
    }
  };
  const getUserById = useCallback(() => {
    setLoading(true);
    const id = params.id;
    props.getUserById({
      id,
      onSuccess: onGetUserSuccess,
      onError: onGetUserError,
    });
  }, [params.id, props]);

  useEffect(() => {
    getUserById();
  }, [props.users, params.id, getUserById]);

  const onGetUserSuccess = useCallback(
    (user) => {
      setLoading(false);
      if (props.type === "edit" && params.id !== formData.id) {
        if (user.data) {
          setTab("0");
          setSelectedOrganisation("");
          setSelectedOrganisationPermissions({});
          updateValues(user.data);
        }
      } else if (props.type === "add" && formData.id !== "") {
        setTab("0");
        setSelectedOrganisation("");
        setSelectedOrganisationPermissions({});
        updateValues({});
      }
    },
    [formData.id, params.id, props.type, updateValues],
  );

  const onGetUserError = useCallback((err) => {
    setLoading(false);
  }, []);
  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    if (["admin", "organisationAdmin"].includes(name)) {
      if (name === "admin") {
        setAdmin(value);
      } else {
        setOrganisationAdmin(value);
      }
      const currentOrg = formData.organisations.map((item) => {
        if (item.organisation.value === props.UserReducer.organisation._id) {
          item[name] = value;
        }
        return item;
      });
      console.log(currentOrg);
      setFormData({
        ...formData,
        organisations: currentOrg,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const onOrganisationChange = (e) => {
    const value = e.target.value;
    const index = formData.organisations.findIndex((el) => el.organisation.value === value);
    setSelectedOrganisation(value);
    const temp = props.userOrganisations.find((item) => item.organisation._id === value);
    setSelectedOrganisationPermissions(temp.organisation.permissions);
    setSelectedOrganisationIndex(index);
  };

  const onOrganisationEmployeeChange = (e) => {
    const value = e.target.value;
    const index = e.target.name;
    const sampleArray = [...formData.organisations];
    sampleArray[index] = {
      ...sampleArray[index],
      employee: {
        label: "value",
        value: value,
      },
    };
    setFormData({
      ...formData,
      organisations: sampleArray,
    });
  };

  const onOrganisationPermissionChange = (e) => {
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const name = e.target.name;
    const sampleArray = [...formData.organisations];
    sampleArray[selectedOrganisationIndex] = {
      ...sampleArray[selectedOrganisationIndex],
      permissions: {
        ...sampleArray[selectedOrganisationIndex].permissions,
        [name]: value,
      },
    };
    setFormData({
      ...formData,
      organisations: sampleArray,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (props.type === "edit") {
      UpdateFormSubmit();
    } else if (props.type === "add") {
      AddFormSubmit();
    }
  };

  const AddFormSubmit = () => {
    setFormData({
      ...formData,
      message_type: "",
      message: "",
      loading: true,
    });
    props.addUsers({
      data: {
        name: formData.name,
        email: formData.email,
        // admin: formData.admin,
        superAdmin: formData.superAdmin,
        organisations: formData.organisations.map((el) => ({
          organisation: el.organisation.value,
          employee: el.employee.value,
          permissions: el.permissions,
          admin: el.admin,
          organisationAdmin: el.organisationAdmin,
        })),
      },
      onSuccess: (data) => {
        const currentPage = props.metaData.currentPage || 1;
        props.fetchUsers({
          page: currentPage,
          onSuccess: () => {
            navigate("/ap/users");
          },
        });
      },
      onError: (error) => {
        setFormData({
          ...formData,
          message_type: "error",
          message: error,
          loading: false,
        });
      },
    });
  };

  const UpdateFormSubmit = () => {
    setFormData({
      ...formData,
      message_type: "",
      message: "",
      loading: true,
    });
    props.updateUsers({
      id: formData.id,
      data: {
        name: formData.name,
        email: formData.email,
        // admin: formData.admin,
        superAdmin: formData.superAdmin,
        organisations: formData.organisations.map((el) => ({
          organisation: el.organisation.value,
          employee: el.employee.value,
          permissions: el.permissions,
          admin: el.admin,
          organisationAdmin: el.organisationAdmin,
        })),
      },
      onSuccess: (data) => {
        const currentPage = props.metaData.currentPage || 1;
        props.fetchUsers({
          page: currentPage,
          onSuccess: () => {
            if (props.UserReducer.id === formData.id) {
              props.getProfile();
            }
            setFormData({
              ...formData,
              message_type: "success",
              message: "Sucessfully Updated",
              loading: false,
            });
          },
        });
      },
      onError: (error) => {
        setFormData({
          ...formData,
          message_type: "error",
          message: error,
          loading: false,
        });
      },
    });
  };

  const onDelete = () => {
    if (window.confirm(`Are you sure you want to revoke user access ?`)) {
      setFormData({
        ...formData,
        message_type: "",
        message: "",
        loading: true,
      });
      props.updateUsers({
        id: formData.id,
        data: {
          sessions: [],
          active: false,
        },
        onSuccess: (data) => {
          props.fetchUsers({
            onSuccess: () => {
              if (props.UserReducer.id === formData.id) {
                props.getProfile();
              }
              setFormData({
                ...formData,
                message_type: "success",
                message: "Sucessfully Updated",
                loading: false,
                active: false,
              });
            },
          });
        },
        onError: (error) => {
          setFormData({
            ...formData,
            message_type: "error",
            message: error,
            loading: false,
          });
        },
      });
    }
  };

  const onActivate = () => {
    if (window.confirm(`Are you sure you want to activate user access ?`)) {
      setFormData({
        ...formData,
        message_type: "",
        message: "",
        loading: true,
      });
      props.updateUsers({
        id: formData.id,
        data: {
          active: true,
        },
        onSuccess: (data) => {
          props.fetchUsers({
            onSuccess: () => {
              if (props.UserReducer.id === formData.id) {
                props.getProfile();
              }
              setFormData({
                ...formData,
                message_type: "success",
                message: "Sucessfully Updated",
                loading: false,
                active: true,
              });
            },
          });
        },
        onError: (error) => {
          setFormData({
            ...formData,
            message_type: "error",
            message: error,
            loading: false,
          });
        },
      });
    }
  };

  const Tabs = () => {
    const tabs = [
      {
        tab: "0",
        icon: MdList,
        label: "Information",
        // fields: ["name", "email", "official_email", "mobile"],
      },
    ];
    if (formData.organisations.filter(organisationFilter).length > 0) {
      tabs.push({
        tab: "1",
        icon: MdSettingsAccessibility,
        label: "Permissions",
      });
    }
    return tabs;
  };

  const updateTab = (e) => {
    setTab(e.currentTarget.getAttribute("data-key"));
  };

  const onTextChangeOrganisation = (e) => {
    const value = e.target.value;
    const organisation = props.organisations.find((el) => String(el._id) === String(value));
    const index = [...formData.organisations].find((item) => item.organisation.value == value);
    if (index) {
      alert(`Organisation already added`);
      return;
    }
    setNewOrganisation({
      label: organisation.name,
      value: value,
    });
  };

  const addOrganisation = (e) => {
    const sampleArray = [...formData.organisations];
    sampleArray.push({
      organisation: {
        label: newOrganisation.label,
        value: newOrganisation.value,
      },
      employee: {
        label: "",
        value: null,
      },
      permissions: {
        organisations_list: false,
        organisations_add: false,
        organisations_edit: false,
        users_list: false,
        users_view: false,
        users_add: false,
        users_edit: false,
        employees_list: false,
        employees_view: false,
        employees_edit: false,
        employees_add: false,
        recruitment_list: false,
        recruitment_view: false,
        recruitment_edit: false,
        recruitment_add: false,
        recruitment_delete: false,
        recruitment_rollback: false,
        payrolls_view: false,
        payrolls_edit: false,
        announcements_list: false,
        announcements_add: false,
        announcements_edit: false,
        tickets: false,
        drive: false,
        jobs_list: false,
        jobs_add: false,
        jobs_edit: false,
        media_list: false,
        media_add: false,
        media_edit: false,
        groups_list: false,
        groups_add: false,
        groups_edit: false,
        categories_list: false,
        categories_add: false,
        categories_edit: false,
        modules_list: false,
        modules_add: false,
        modules_edit: false,
        knowledgebase_list: false,
        knowledgebase_add: false,
        knowledgebase_edit: false,
        emailtemplates_list: false,
        emailtemplates_edit: false,
        // change_user_permissions: false,
      },
    });
    setFormData({
      ...formData,
      organisations: sampleArray,
    });
    setNewOrganisation({
      label: "",
      value: "",
    });
    fetchEmployeesRecord(sampleArray);
  };

  const removeOrganisation = (e) => {
    const index = e.target.getAttribute("data-index");
    window.confirm(
      `Are you sure you want to revoke '${formData.organisations?.[index]?.organisation?.label}' from user ?`,
    )
      ? setFormData({
          ...formData,
          ...formData.organisations.splice(index, 1),
        })
      : console.log("check");
  };

  const organisationFilter = (el) => {
    if (props.UserReducer.superAdmin) {
      return true;
    } else if (
      el.organisation.value === props.UserReducer?.organisation?._id &&
      props.UserReducer.organisationAdmin
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getUserButtonTitle = useCallback(() => {
    let label = "";
    if (formData.loading) {
      label = "Processing";
    } else if (formData.active) {
      label = "Revoke Access";
    } else {
      label = "Activate Access";
    }
    return label;
  }, [formData.active, formData.loading]);

  const selectedPermissions = formData.organisations?.[selectedOrganisationIndex]?.permissions;

  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>{props.type === "edit" ? "Edit" : "Add"} User</h2>
      </styles.Header>
      {loading ? (
        <Loader />
      ) : (
        <>
          {params.type === "user" && (
            <>
              <styles.TabContainer>
                {Tabs().map((item) => (
                  <styles.TabItem
                    key={item.tab}
                    onClick={updateTab}
                    data-key={item.tab}
                    data-tab={item.tab}
                    active={tab === item.tab}
                  >
                    <item.icon className='icon' size={16} /> {item.label}
                  </styles.TabItem>
                ))}
              </styles.TabContainer>
            </>
          )}

          <Toast type={formData.message_type} message={formData.message} />
          <form onSubmit={onSubmit} autoComplete='off'>
            {params.type === "org" ? (
              <>
                {props.UserReducer?.permissions?.organisations_list &&
                  props.UserReducer?.superAdmin && (
                    <div
                      style={{
                        marginBottom: 25,
                      }}
                    >
                      New Organization
                      <br />
                      <br />
                      <Select
                        name={"Organisation"}
                        defaultFocused
                        label={"Organization"}
                        options={[
                          { label: "Select...", value: "" },
                          ...props.organisations.map((el) => ({
                            label: el.name,
                            value: el._id,
                          })),
                        ]}
                        value={newOrganisation.value}
                        onChange={onTextChangeOrganisation}
                      />
                      <Button
                        size='small'
                        block
                        onClick={addOrganisation}
                        label={"Add"}
                        disabled={!newOrganisation.value}
                      />
                    </div>
                  )}
                {formData.organisations.filter(organisationFilter).map((el, index) => (
                  <styles.EmployeeRecordContainer key={index}>
                    <Input
                      readOnly
                      label={"Organization"}
                      type={"email"}
                      name='email'
                      value={el?.organisation.label}
                      autoComplete={"off"}
                      onChange={onTextChange}
                    />
                    <Select
                      name={index}
                      defaultFocused={true}
                      label={"Employee Record"}
                      options={[
                        { label: "Select...", value: "" },
                        ...employeesList
                          .filter(
                            (el1) => String(el1.organisation) === String(el.organisation.value),
                          )
                          .map((value) => ({
                            label: value?.name,
                            value: value?._id,
                          })),
                      ]}
                      attributes={{ "data-index": index }}
                      value={formData.organisations?.[index]?.employee.value}
                      onChange={onOrganisationEmployeeChange}
                    />
                    {props.UserReducer?.superAdmin && (
                      <MdCancel
                        style={{
                          position: "absolute",
                          top: 2,
                          right: 2,
                          cursor: "pointer",
                        }}
                        size={25}
                        data-index={index}
                        onClick={removeOrganisation}
                      />
                    )}
                  </styles.EmployeeRecordContainer>
                ))}
              </>
            ) : (
              <>
                {props.type === "edit" && (
                  <Input
                    readOnly={true}
                    label={"User ID"}
                    type={"text"}
                    name='id'
                    value={formData.id}
                    autoComplete={"off"}
                  />
                )}
                {tab === "0" && (
                  <>
                    <Input
                      label={"Full Name"}
                      type={"text"}
                      name='name'
                      value={formData.name}
                      autoComplete={"off"}
                      onChange={onTextChange}
                    />
                    <Input
                      label={"Email"}
                      type={"email"}
                      name='email'
                      value={formData.email}
                      autoComplete={"off"}
                      onChange={onTextChange}
                    />

                    <Select
                      name={"admin"}
                      label={"Admin"}
                      options={[
                        { label: "Admin Access", value: true },
                        { label: "Employee Access", value: false },
                      ]}
                      value={admin}
                      onChange={onTextChange}
                    />

                    {(props.UserReducer.superAdmin || props?.UserReducer?.organisationAdmin) && (
                      <Select
                        name={"organisationAdmin"}
                        label={"Organization Admin"}
                        options={[
                          { label: "Enabled", value: true },
                          { label: "Disabled", value: false },
                        ]}
                        value={organisationAdmin}
                        onChange={onTextChange}
                        readOnly={!props?.UserReducer?.superAdmin}
                      />
                    )}
                    {props.UserReducer.superAdmin && (
                      <Select
                        name={"superAdmin"}
                        label={"Unity Admin"}
                        options={[
                          { label: "Enabled", value: true },
                          { label: "Disabled", value: false },
                        ]}
                        value={formData.superAdmin}
                        onChange={onTextChange}
                      />
                    )}
                  </>
                )}

                {tab === "1" && (
                  <>
                    <Select
                      name={"Organisation"}
                      defaultFocused={true}
                      label={"Organization"}
                      options={[
                        { label: "Select...", value: "" },
                        ...formData.organisations.filter(organisationFilter).map((el) => ({
                          label: el?.organisation.label,
                          value: el?.organisation.value,
                        })),
                      ]}
                      value={selectedOrganisation}
                      onChange={onOrganisationChange}
                    />
                    {selectedOrganisation && (
                      <div>
                        {selectedOrganisationPermissions?.organisations && (
                          <>
                            <Input
                              label={"Organizations List"}
                              type={"checkbox"}
                              name='organisations_list'
                              value={selectedPermissions?.organisations_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Organizations Add"}
                              type={"checkbox"}
                              name='organisations_add'
                              value={selectedPermissions?.organisations_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Organizations Edit"}
                              type={"checkbox"}
                              name='organisations_edit'
                              value={selectedPermissions?.organisations_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.users && (
                          <>
                            <Input
                              label={"Users List"}
                              type={"checkbox"}
                              name='users_list'
                              value={selectedPermissions?.users_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Users View"}
                              type={"checkbox"}
                              name='users_view'
                              value={selectedPermissions?.users_view}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Users Add"}
                              type={"checkbox"}
                              name='users_add'
                              value={selectedPermissions?.users_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Users Edit"}
                              type={"checkbox"}
                              name='users_edit'
                              value={selectedPermissions?.users_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.employees && (
                          <>
                            <Input
                              label={"Employees List"}
                              type={"checkbox"}
                              name='employees_list'
                              value={selectedPermissions?.employees_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Employees View"}
                              type={"checkbox"}
                              name='employees_view'
                              value={selectedPermissions?.employees_view}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Employees Add"}
                              type={"checkbox"}
                              name='employees_add'
                              value={selectedPermissions?.employees_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Employees Edit"}
                              type={"checkbox"}
                              name='employees_edit'
                              value={selectedPermissions?.employees_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.recruitsment && (
                          <>
                            <Input
                              label={"Recruit List"}
                              type={"checkbox"}
                              name='recruitment_list'
                              value={selectedPermissions?.recruitment_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Recruit View"}
                              type={"checkbox"}
                              name='recruitment_view'
                              value={selectedPermissions?.recruitment_view}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Recruit Add"}
                              type={"checkbox"}
                              name='recruitment_add'
                              value={selectedPermissions?.recruitment_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Recruit Edit"}
                              type={"checkbox"}
                              name='recruitment_edit'
                              value={selectedPermissions?.recruitment_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Recruit Delete"}
                              type={"checkbox"}
                              name='recruitment_delete'
                              value={selectedPermissions?.recruitment_delete}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Recruitment Rollback"}
                              type={"checkbox"}
                              name='recruitment_rollback'
                              value={selectedPermissions?.recruitment_rollback}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.payroll && (
                          <>
                            <Input
                              label={"Payrolls View"}
                              type={"checkbox"}
                              name='payrolls_view'
                              value={selectedPermissions?.payrolls_view}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Payrolls Edit"}
                              type={"checkbox"}
                              name='payrolls_edit'
                              value={selectedPermissions?.payrolls_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.tickets && (
                          <Input
                            label={"Tickets Module"}
                            type={"checkbox"}
                            name='tickets'
                            value={selectedPermissions?.tickets}
                            autoComplete={"off"}
                            onChange={onOrganisationPermissionChange}
                          />
                        )}
                        {selectedOrganisationPermissions?.drive && (
                          <Input
                            label={"Drive Module"}
                            type={"checkbox"}
                            name='drive'
                            value={selectedPermissions?.drive}
                            autoComplete={"off"}
                            onChange={onOrganisationPermissionChange}
                          />
                        )}
                        {selectedOrganisationPermissions?.announcements && (
                          <>
                            <Input
                              label={"Announcements List"}
                              type={"checkbox"}
                              name='announcements_list'
                              value={selectedPermissions?.announcements_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Announcements Add"}
                              type={"checkbox"}
                              name='announcements_add'
                              value={selectedPermissions?.announcements_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Announcements Edit"}
                              type={"checkbox"}
                              name='announcements_edit'
                              value={selectedPermissions?.announcements_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.jobs && (
                          <>
                            <Input
                              label={"Jobs List"}
                              type={"checkbox"}
                              name='jobs_list'
                              value={selectedPermissions?.jobs_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Jobs Add"}
                              type={"checkbox"}
                              name='jobs_add'
                              value={selectedPermissions?.jobs_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Jobs Edit"}
                              type={"checkbox"}
                              name='jobs_edit'
                              value={selectedPermissions?.jobs_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.media && (
                          <>
                            <Input
                              label={"Media List"}
                              type={"checkbox"}
                              name='media_list'
                              value={selectedPermissions?.media_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Media Add"}
                              type={"checkbox"}
                              name='media_add'
                              value={selectedPermissions?.media_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Media Edit"}
                              type={"checkbox"}
                              name='media_edit'
                              value={selectedPermissions?.media_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.emailtemplates && (
                          <>
                            <Input
                              label={"Email Templates List"}
                              type={"checkbox"}
                              name='emailtemplates_list'
                              value={selectedPermissions?.emailtemplates_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Email Templates Add"}
                              type={"checkbox"}
                              name='emailtemplates_add'
                              value={selectedPermissions?.emailtemplates_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Email Templates Edit"}
                              type={"checkbox"}
                              name='emailtemplates_edit'
                              value={selectedPermissions?.emailtemplates_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.groups && (
                          <>
                            <Input
                              label={"Groups List"}
                              type={"checkbox"}
                              name='groups_list'
                              value={selectedPermissions?.groups_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Groups Add"}
                              type={"checkbox"}
                              name='groups_add'
                              value={selectedPermissions?.groups_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Groups Edit"}
                              type={"checkbox"}
                              name='groups_edit'
                              value={selectedPermissions?.groups_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.categories && (
                          <>
                            <Input
                              label={"Categories List"}
                              type={"checkbox"}
                              name='categories_list'
                              value={selectedPermissions?.categories_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Categories Add"}
                              type={"checkbox"}
                              name='categories_add'
                              value={selectedPermissions?.categories_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Categories Edit"}
                              type={"checkbox"}
                              name='categories_edit'
                              value={selectedPermissions?.categories_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.modules && (
                          <>
                            <Input
                              label={"Tags List"}
                              type={"checkbox"}
                              name='modules_list'
                              value={selectedPermissions?.modules_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Tags Add"}
                              type={"checkbox"}
                              name='modules_add'
                              value={selectedPermissions?.modules_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Tags Edit"}
                              type={"checkbox"}
                              name='modules_edit'
                              value={selectedPermissions?.modules_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                        {selectedOrganisationPermissions?.knowledgebase && (
                          <>
                            <Input
                              label={"Knowledgebase List"}
                              type={"checkbox"}
                              name='knowledgebase_list'
                              value={selectedPermissions?.knowledgebase_list}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Knowledgebase Add"}
                              type={"checkbox"}
                              name='knowledgebase_add'
                              value={selectedPermissions?.knowledgebase_add}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                            <Input
                              label={"Knowledgebase Edit"}
                              type={"checkbox"}
                              name='knowledgebase_edit'
                              value={selectedPermissions?.knowledgebase_edit}
                              autoComplete={"off"}
                              onChange={onOrganisationPermissionChange}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            <Button
              block
              onClick={onSubmit}
              label={formData.loading ? "Saving" : "Save"}
              disabled={!formData.name || !formData.email || formData.admin === ""}
            />
            {params.type === "user" && (
              <Button
                type={formData.active ? "cancel" : "secondary"}
                block
                onClick={formData.active ? onDelete : onActivate}
                label={getUserButtonTitle()}
                disabled={formData.loading}
              />
            )}
          </form>
        </>
      )}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  organisations: state.OrganisationsReducer.list,
  UserReducer: state.UserReducer,
  loading: state.UsersReducer.loading,
  users: state.UsersReducer.users,
  employees: state.EmployeesReducer.employees,
  userOrganisations: state.UserReducer?.organisations,
  metaData: state.UsersReducer.metaData,
});

const mapDispatchToProps = {
  fetchEmployeesBasic,
  fetchUsers,
  addUsers,
  updateUsers,
  getProfile,
  getUserById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
