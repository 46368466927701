import styled from "styled-components";

import { fonts } from "../../services/theme";

export const MainContainer = styled.div`
  display: flex;
  margin-right: 1vw;
  flex-direction: column;
  background-color: #d2dcfc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.isExpanded ? "0" : "4px")};
  border-bottom-right-radius: ${(props) => (props.isExpanded ? "0" : "4px")};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 14vw;
  /* padding-left: 0.4vw; */
  position: relative;
`;

export const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* padding: 0.1vh 1vw; */
  position: relative;
  width: 80%;
`;

export const Selected = styled.div`
  font-size: 1.2rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const ListContainer = styled.div`
  /* position: absolute; */
  z-index: 1;
  width: 14vw;
  /* padding-left: 1vw; */
  position: absolute;
  top: 100%;
  background-color: #d2dcfc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 2px solid white;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  width: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
  white-space: nowrap;
  overflow: hidden !important;
  display: inline-block;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  text-align: center;
  border-bottom: 2px solid white;
`;

export const Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  margin: 10px 0px;
  padding: 10px 10px;
  background-color: #ccc;
  border-radius: 3px;
`;
export const Column = styled.div`
  flex: ${(props) => props.flex ?? "1"};
  text-align: ${(props) => (props.atEnd ? "right" : "left")};
`;
