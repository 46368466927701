import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getServiceRequestsColumns = ({ fields }: ColumnsProps) => {
  const serviceRequestsPrefix = "SRQ000";
  const tableColumns = {
    number: {
      title: "Number",
      key: "number",
      render: (item) => {
        return <div className='title'>{`${serviceRequestsPrefix}${item.number}`}</div>;
      },
    },
    priority: {
      title: "Priority",
      key: "priority",
      render: (item) => {
        return <div className='title'>{item?.priority?.value || "-"}</div>;
      },
    },
    state: {
      title: "State",
      key: "state",
      render: (item) => {
        return <div className='title'>{item?.state?.value || "-"}</div>;
      },
    },
    urgency: {
      title: "Urgency",
      key: "urgency",
      render: (item) => {
        return <div className='title'>{item?.urgency?.value || "-"}</div>;
      },
    },
    assignedTo: {
      title: "Assigned To",
      key: "assignedTo",
      render: (item) => {
        return <div className='title'>{item?.assignedTo?.name || "-"}</div>;
      },
    },
    holdReason: {
      title: "Hold Reason",
      key: "holdReason",
    },
    shortDescription: {
      title: "Short Description",
      key: "shortDescription",
    },
    description: {
      title: "Description",
      key: "description",
    },
    stage: {
      title: "Stage",
      key: "stage",
      render: (item) => {
        return <div className='title'>{item?.stage?.value || "-"}</div>;
      },
    },
    dueDate: {
      title: "Due Date",
      sortSupported: true,
      key: "dueDate",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.dueDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
