import styled from "styled-components";

import { fonts, fs, colors } from "../../../../../theme";

interface Props {
  size?: "large" | "small";
  color?: string;
  active?: boolean;
  loading?: boolean;
}

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  .skeleton {
    margin-top: 4px;
    height: 147px;
    width: 45vw;
  }
  .ep-knowledge-filter-search-container {
    display: flex;
    height: 57px;
    width: auto;
    max-width: 100%;
    font-weight: 600;
    border-radius: 0;
    border: 0.3px solid ${colors.common.black};
    border-bottom: 0;
    background-color: ${colors.common.white};
    justify-content: center;
  }
  .ep-knowledge-filter-input {
    height: 34px;
    width: 60%;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    padding-left: 14%;
  }
  .ep-knowledge-filter-icon {
    left: 20%;
  }
`;

export const KnowledgeBanner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(268.94deg, #3f107b -4.87%, #8e37ff 112.84%);
  .ep-knowledge-search-container {
    background-color: ${colors.common.secondaryWhite};
    width: 58%;
    position: relative;
    margin-right: 20px;
  }
  .ep-knowledge-input {
    padding-left: 4%;
  }
`;

export const Title = styled.text`
  font-family: ${fonts.OpenSans};
  font-weight: 800;
  font-size: ${fs.xxl};
  color: ${colors.common.white};
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const CategoriesGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 94.38%;
  margin-top: 30px;
  align-self: center;
  row-gap: 16px;
  overflow: hidden;
  column-gap: 20px;
`;

export const CategoryGrid = styled.div<Props>`
  display: flex;
  flex-direction: row;
  height: 67px;
  background: ${colors.common.white};
  border: ${(props) =>
    props.active ? `0.3px solid ${colors.common.primary}` : "0.3px solid rgba(0, 0, 0, 0.5)"};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 0 30px;
  align-items: center;
  cursor: pointer;
`;

export const CategoryGridImage = styled.img`
  height: 32px;
  width: 32px;
  object-fit: content;
`;

export const CategoryGridTitle = styled.div`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
  margin-left: 20px;
  color: ${colors.common.primary};
  font-size: 16px;
  font-weight: 700;
  font-family: ${fonts.SourceSansPro};
`;

export const ViewAll = styled.div`
  display: flex;
  padding: 10px 20px;
  width: fit-content;
  align-self: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  color: ${colors.common.titleColor};
  cursor: pointer;
  font-size: ${fs.s};
  line-height: 15px;
  font-family: ${fonts.SourceSansPro};
  transition: linear 0.4s;
  background-color: ${colors.common.yellow};
  margin-top: 20px;
`;

export const KnowledgeArticlesContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 30px;
  padding: 0 2.81%;
  flex-direction: row;
  padding-bottom: 100px;
  justify-content: space-between;
`;
export const KnowledgeArticlesHeader = styled.div`
  display: flex;
  height: 50px;
  max-width: 100%;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  background-color: ${colors.common.secondaryWhite};
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
`;

export const ResultsCount = styled.div`
  color: ${colors.common.black};
  font-size: ${fs.s};
  font-family: ${fonts.SourceSansPro};
  font-weight: 600;
`;

export const NoRecords = styled.div`
  display: flex;
  height: 100px;
  width: 94%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
`;

export const AppliedFiltersContainer = styled.div`
  display: flex;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  max-width: 100%;
  padding: 16px 16px 16px 30px;
  align-items: center;
  position: relative;
`;

export const AppliedFiltersTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
`;

export const ClearAllTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 16px;
  font-family: ${fonts.SourceSansPro};
  cursor: pointer;
`;

export const FiltersListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  max-width: 80%;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 15px;
`;

export const FilterItem = styled.div`
  border-radius: 10px;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  font-size: 10px;
  color: ${colors.common.borderColor};
  font-weight: 600;
  padding: 0px 12px;
  height: 25px;
  display: flex;
  align-items: center;
  font-family: ${fonts.SourceSansPro};
`;

export const CrossIcon = styled.img`
  height: 10px;
  width: 10px;
  margin-left: 10px;
  cursor: pointer;
  object-fit: contain;
`;

export const ListContainer = styled.div`
  display: flex;
  width: 63.23%;
  overflow-y: scroll;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  max-width: 100%;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  background-color: ${colors.common.white};
  /* height: 147px; */
  padding: 20px 30px 20px 70px;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  transition: linear 0.6s;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
  .knowledge-rejected-status {
    right: 18%;
    color: white;
  }
`;

export const Name = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
  line-height: 25px;
  height: fit-content;
  color: ${colors.common.black};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 80%;
`;

export const KnowledgeIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  position: absolute;
  left: 4.45%;
  top: 24px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  max-width: 80%;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`;

export const Tag = styled.div`
  border-radius: 10px;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  background-color: ${colors.knowledge.tagsBackground};
  font-size: 10px;
  color: ${colors.common.black};
  font-weight: 600;
  padding: 4px 12px;
  font-family: ${fonts.SourceSansPro};
`;

export const Summary = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
  height: fit-content;
  color: ${colors.common.black};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 95%;
  margin-top: 10px;
  line-height: 25px;
`;

export const Status = styled.div<Props>`
  background-color: ${(props) => props.color};
  border-radius: 10px;
  font-size: 12px;
  color: ${colors.common.black};
  font-weight: 600;
  padding: 6px 18px;
  font-family: ${fonts.SourceSansPro};
  position: absolute;
  top: 20px;
  right: 30px;
`;

export const MetaContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const MetaRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 25%;
`;

export const MetaIcon = styled.img`
  height: 10px;
  width: 10px;
  object-fit: contain;
`;

export const MetaTitle = styled.div`
  font-size: 12px;
  color: ${colors.common.black};
  font-weight: 400;
  font-family: ${fonts.SourceSansPro};
  margin-left: 5px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const MetaSeperator = styled.div`
  height: 12px;
  width: 1px;
  background-color: ${colors.common.black};
  margin: 0px 5px;
`;

export const FiltersContainer = styled.div<Props>`
  display: flex;
  width: 34%;
  flex-direction: column;
  height: fit-content;
  opacity: ${(props) => (props.loading ? "0.6" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const FiltersHeader = styled.div`
  display: flex;
  padding: 20px 30px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 600;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  background-color: ${colors.common.secondaryWhite};
  align-items: center;
  justify-content: space-between;
`;

export const FilterItemRow = styled.div<Props>`
  display: flex;
  max-width: 100%;
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  background-color: ${colors.common.white};
  padding: 20px 30px;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  transition: linear 0.6s;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
  .arrow {
    position: absolute;
    right: 8%;
    transition: linear 0.5s;
    transform: ${(props) => props.active && "rotate(90deg)"};
  }
`;

export const FilterItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Icon = styled.img`
  height: 12px;
  width: 12px;
  object-fit: contain;
  align-self: center;
`;

export const ExpandedFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: linear 0.5s;
  height: fit-content;
`;

export const FilterChildContainer = styled.div`
  display: flex;
  max-width: 100%;
  /* height: 57px; */
  background-color: ${colors.common.white};
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  border-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 30px;
  cursor: pointer;
  transition: linear 0.5s;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
  .show-more {
    color: ${colors.common.primary};
  }
`;

export const Checkbox = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

export const FilterChildTitle = styled.div`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60vh;
  width: 70vw;
  overflow: hidden;
  background-color: white;
  .knowledge-filter-apply {
    width: 200px;
    /* margin: 20px 30px; */
    position: absolute;
    bottom: 20px;
    right: 20px;
    align-self: flex-end;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px 30px;
    height: fit-content;
    width: fit-content;
  }
  .ep-knowledge-filter-search-model {
    width: 100%;
    height: 74px;
    background-color: #d9d9d9;
    border-radius: 0;
    padding-left: 4%;
  }
  .ep-knowledge-filter-model-input {
    height: 37px;
    max-width: 30%;
    background: #f8f8f8;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    padding-left: 5%;
  }
  .ep-knowledge-filter-model-icon {
    left: 6%;
  }
`;

export const ModelTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0;
  padding-left: 4%;
  color: ${colors.common.black};
`;

export const ModelFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto;
  margin: 0 4%;
  row-gap: 30px;
  max-height: 55%;
  .model-filter-container {
    width: 85%;
    margin: 0 1% 0 0;
    padding: 0;
    height: fit-content;
    background: transparent;
    border: none;
  }
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;
