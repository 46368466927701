import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { AcademyBatchControllerProps } from "../../../../types";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyCoursesList } from "../AcademyCourses/AcademyCoursesList";
import { AcademyStudentProfileList } from "../AcademyStudentProfile/AcademyStudentProfileList";

import { useAcademyBatchController } from "./hooks";

export const AcademyBatchController = ({ type }: AcademyBatchControllerProps) => {
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const params = useParams();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    onDateChange,
    loading,
    headerTitle,
    fetchAcademyBatch,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    formSteps,
    formTitle,
    formRelatedList,
    headerButtons,
  } = useAcademyBatchController({ type, id: params.id, attachmentsPickerRef });
  const currentAcademyBatch = data;

  const { employeesOptions, academyCoursesOptions } = useControllerConfigs();

  const {
    workLogs,
    attachments,
    batchNumber,
    shortDescription,
    description,
    courseOfferings,
    trainingCordinator,
    mentors,
    plannedDuration,
    dailyHoursEffort,
    actualDuration,
    state,
    plannedStartDate,
    actualStartDate,
    plannedEndDate,
    actualEndDate,
    totalStudents,
    overallHealth,
    scheduleTimeHealth,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyBatch();
    }
    // Do not include fetchAcademyBatch to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Batch Number'
            type={"text"}
            width='48%'
            error={batchNumber.error}
            inputProps={{
              name: "batchNumber",
              value: batchNumber.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: batchNumber.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("academyBatchState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Planned Start Date'
            type={"date"}
            width='48%'
            error={plannedStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "plannedStartDate",
              value: plannedStartDate.value,
              required: plannedStartDate.required,
            }}
            textAreaProps={{}}
          />{" "}
          <TextInput
            label='Planned End Date'
            type={"date"}
            width='48%'
            error={plannedEndDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "plannedEndDate",
              minDate: plannedStartDate.value ? new Date(plannedStartDate.value) : undefined,
              value: plannedEndDate.value,
              required: plannedEndDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Actual Start Date'
            type={"date"}
            width='48%'
            error={actualStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "actualStartDate",
              value: actualStartDate.value,
              required: actualStartDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Actual End Date'
            type={"date"}
            width='48%'
            error={actualEndDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "actualEndDate",
              minDate: actualStartDate.value ? new Date(actualStartDate.value) : undefined,
              value: actualEndDate.value,
              required: actualEndDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"courseOfferings"}
            label={"Course Offerings"}
            options={academyCoursesOptions}
            value={courseOfferings.value}
            onChange={onTextChange}
            mandatory={courseOfferings.required}
            error={courseOfferings.error}
          />
          <Select
            width={"48%"}
            name={"trainingCordinator"}
            label={"Training Cordinator"}
            options={employeesOptions}
            value={trainingCordinator.value}
            onChange={onTextChange}
            mandatory={trainingCordinator.required}
            error={trainingCordinator.error}
          />
        </Row>

        <Row>
          <Select
            width={"48%"}
            name={"mentors"}
            label={"Mentors"}
            options={employeesOptions}
            isMulti={true}
            value={mentors.value}
            onChange={onTextChange}
            mandatory={mentors.required}
            error={mentors.error}
          />
          <TextInput
            label='Total Learners'
            type={"text"}
            width='48%'
            error={totalStudents.error}
            inputProps={{
              name: "totalStudents",
              disabled: true,
              value: totalStudents.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: totalStudents.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Planned Duration'
            type={"text"}
            width='48%'
            error={plannedDuration.error}
            inputProps={{
              name: "plannedDuration",
              value: plannedDuration.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: plannedDuration.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"overallHealth"}
            label={"Overall Health"}
            options={getAttributValues("academyBatchOverallHealth")}
            value={overallHealth.value}
            onChange={onTextChange}
            mandatory={overallHealth.required}
            error={overallHealth.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Daily Hours Effort'
            type={"text"}
            width='48%'
            error={dailyHoursEffort.error}
            inputProps={{
              name: "dailyHoursEffort",
              value: dailyHoursEffort.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: dailyHoursEffort.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"scheduleTimeHealth"}
            label={"Schedule Time Health"}
            options={getAttributValues("academyBatchTimeHealth")}
            value={scheduleTimeHealth.value}
            onChange={onTextChange}
            mandatory={scheduleTimeHealth.required}
            error={scheduleTimeHealth.error}
          />
        </Row>
        <TextInput
          label='Actual Duration'
          type={"text"}
          width='48%'
          error={actualDuration.error}
          inputProps={{
            name: "actualDuration",
            value: actualDuration.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: actualDuration.required,
          }}
          textAreaProps={{}}
        />

        <TextInput
          label='Short Description'
          type={"textarea"}
          width='100%'
          error={shortDescription.error}
          textAreaProps={{
            name: "shortDescription",
            rows: 4,
            value: shortDescription.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescription.required,
          }}
        />
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          textAreaProps={{
            name: "description",
            rows: 8,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </>
    );
  }, [
    batchNumber.error,
    batchNumber.value,
    batchNumber.required,
    onTextChange,
    state.value,
    state.required,
    state.error,
    plannedStartDate.error,
    plannedStartDate.value,
    plannedStartDate.required,
    onDateChange,
    plannedEndDate.error,
    plannedEndDate.value,
    plannedEndDate.required,
    actualStartDate.error,
    actualStartDate.value,
    actualStartDate.required,
    actualEndDate.error,
    actualEndDate.value,
    actualEndDate.required,
    academyCoursesOptions,
    courseOfferings.value,
    courseOfferings.required,
    courseOfferings.error,
    employeesOptions,
    trainingCordinator.value,
    trainingCordinator.required,
    trainingCordinator.error,
    mentors.value,
    mentors.required,
    mentors.error,
    totalStudents.error,
    totalStudents.value,
    totalStudents.required,
    plannedDuration.error,
    plannedDuration.value,
    plannedDuration.required,
    overallHealth.value,
    overallHealth.required,
    overallHealth.error,
    dailyHoursEffort.error,
    dailyHoursEffort.value,
    dailyHoursEffort.required,
    scheduleTimeHealth.value,
    scheduleTimeHealth.required,
    scheduleTimeHealth.error,
    actualDuration.error,
    actualDuration.value,
    actualDuration.required,
    shortDescription.error,
    shortDescription.value,
    shortDescription.required,
    description.error,
    description.value,
    description.required,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyBatch?.createdBy !== "string"
        ? currentAcademyBatch?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyBatch?.updatedBy !== "string"
        ? currentAcademyBatch?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyBatch?.createdAt}
        updatedAt={currentAcademyBatch?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyBatch?.createdAt,
    currentAcademyBatch?.createdBy,
    currentAcademyBatch?.updatedAt,
    currentAcademyBatch?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.academyBatch.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyBatch.name} />,
    [params.id],
  );
  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const relatedCourses = useMemo(() => {
    return (
      <AcademyCoursesList
        relatedList={{
          type: "_id",
          value: courseOfferings.value,
        }}
      />
    );
  }, [courseOfferings.value]);

  const relatedLerners = useMemo(() => {
    return (
      <AcademyStudentProfileList
        relatedList={{
          type: "associatedBatch",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  // const relatedEnrolments = useCallback(() => {
  //   return (
  //     <AcademyCandidateEnrollmentsList
  //       relatedList={{
  //         type: "associatedBatch",
  //         value: params.id,
  //       }}
  //     />
  //   );
  // }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return relatedCourses;
      case 6:
        return relatedLerners;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    relatedCourses,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    relatedLerners,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
