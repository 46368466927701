import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AcademyCandidateEnrollmentsList,
  AddAcademyCandidateEnrollments,
  UpdateAcademyCandidateEnrollments,
  FindAcademyBatchById,
  FindAcademyCandidateEnrollmentsById,
  AddAcademyCandidateEnrollmentPayment,
  UpdateAcademyCandidateEnrollmentPayment,
  DeleteAcademyCandidateEnrollments,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import {
  fetchAcademyCandidateEnrollmentsListSuccess,
  fetchAcademyCandidateEnrollmentsError,
} from "./actions";
import Types from "./actionType";
import {
  CreateAcademyCandidateEnrollmentsuccess,
  CreateAcademyCandidateEnrollments as CreateAcademyCandidateEnrollmentsType,
  AcademyCandidateEnrollmentsSuccess,
  UpdateAcademyCandidateEnrollments as UpdateAcademyCandidateEnrollmentsType,
  FetchAcademyCandidateEnrollments,
  FetchAcademyCandidateEnrollmentsById,
  AcademyCandidateEnrollmentsByIdSuccess,
  DeleteAcademyCandidateEnrollments as DeleteAcademyCandidateEnrollmentsType,
} from "./types";

function* createWorker(action: CreateAcademyCandidateEnrollmentsType) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyCandidateEnrollments,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyCandidateEnrollmentsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}
function* deleteWorker(action: DeleteAcademyCandidateEnrollmentsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAcademyCandidateEnrollments(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAcademyCandidateEnrollmentsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}
function* updateWorker(action: UpdateAcademyCandidateEnrollmentsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyCandidateEnrollments(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyCandidateEnrollmentsuccess> = yield call(
      axios,
      options,
    );
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchAcademyCandidateEnrollments) {
  console.log("A ::");
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AcademyCandidateEnrollmentsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AcademyCandidateEnrollmentsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchAcademyCandidateEnrollmentsListSuccess(data.data));
      }
    }
  } catch (e) {
    console.log("e", e);
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchAcademyCandidateEnrollmentsError(error.response?.data.message));
  }
}

function* fetchAcademyCandidateEnrollmentsById(action: FetchAcademyCandidateEnrollmentsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAcademyCandidateEnrollmentsById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AcademyCandidateEnrollmentsByIdSuccess> = yield call(
      axios,
      options,
    );
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* addAcademyPaymentDetailWorker(action) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyCandidateEnrollmentPayment,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateAcademyPaymentDetailWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyCandidateEnrollmentPayment(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ACADEMY_CANDIDATE_ENROLLMENT, createWorker);
  yield takeLatest(Types.UPDATE_ACADEMY_CANDIDATE_ENROLLMENT, updateWorker);
  yield takeLatest(Types.DELETE_ACADEMY_PAYMENT_DETAILS, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_CANDIDATE_ENROLLMENT, fetchAllWorker);
  yield takeLatest(Types.DELETE_ACADEMY_CANDIDATE_ENROLLMENT, deleteWorker);
  yield takeLatest(Types.ACADEMY_PAYMENT_DETAILS_ADD, addAcademyPaymentDetailWorker);
  yield takeLatest(Types.ACADEMY_PAYMENT_DETAILS_UPDATE, updateAcademyPaymentDetailWorker);
  yield takeEvery(
    Types.FETCH_ACADEMY_CANDIDATE_ENROLLMENT_BY_ID,
    fetchAcademyCandidateEnrollmentsById,
  );
}
