/* eslint-disable no-unused-vars */
enum Types {
  FETCH_FORM16 = "FETCH_FORM16",
  FETCH_FORM16_BY_ID = "FETCH_FORM16_BY_ID",
  SUCCESS_FETCH_FORM16 = "SUCCESS_FETCH_FORM16",
  ERROR_FETCH_FORM16 = "ERROR_FETCH_FORM16",
  CREATE_FORM16 = "CREATE_FORM16",
  UPDATE_FORM16 = "UPDATE_FORM16",
  DELETE_FORM16 = "DELETE_FORM16",
}

export default Types;
