import Types from "./actionType";
import {
  FetchNotificationsDefinitionsPayload,
  CreateNotificationsDefinitions,
  CreateNotificationsDefinitionsPayload,
  FetchNotificationsDefinitions,
  FetchNotificationsDefinitionsError,
  FetchNotificationsDefinitionsSuccess,
  UpdateNotificationsDefinitions,
  UpdateNotificationsDefinitionsPayload,
  NotificationsDefinitionsListWithMetaData,
  FetchNotificationsDefinitionsByIdPayload,
  FetchNotificationsDefinitionsById,
  DeleteNotificationsDefinitionsPayload,
  DeleteNotificationsDefinitions,
} from "./types";

export const fetchNotificationsDefinitionsList = ({
  params,
  onSuccess,
  onError,
}: FetchNotificationsDefinitionsPayload): FetchNotificationsDefinitions => ({
  type: Types.FETCH_NOTIFICATIONS_DEFINITIONS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchNotificationsDefinitionsById = ({
  id,
  onSuccess,
  onError,
}: FetchNotificationsDefinitionsByIdPayload): FetchNotificationsDefinitionsById => ({
  type: Types.FETCH_NOTIFICATIONS_DEFINITIONS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchNotificationsDefinitionsListSuccess = (
  data: NotificationsDefinitionsListWithMetaData,
): FetchNotificationsDefinitionsSuccess => ({
  type: Types.SUCCESS_FETCH_NOTIFICATIONS_DEFINITIONS,
  payload: {
    data,
  },
});

export const fetchNotificationsDefinitionsError = (
  error?: string,
): FetchNotificationsDefinitionsError => ({
  type: Types.ERROR_FETCH_NOTIFICATIONS_DEFINITIONS,
  payload: { error },
});

export const createNotificationsDefinitions = ({
  data,
  onSuccess,
  onError,
}: CreateNotificationsDefinitionsPayload): CreateNotificationsDefinitions => ({
  type: Types.CREATE_NOTIFICATIONS_DEFINITIONS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateNotificationsDefinitions = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateNotificationsDefinitionsPayload): UpdateNotificationsDefinitions => ({
  type: Types.UPDATE_NOTIFICATIONS_DEFINITIONS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteNotificationsDefinitions = ({
  id,
  onSuccess,
  onError,
}: DeleteNotificationsDefinitionsPayload): DeleteNotificationsDefinitions => ({
  type: Types.DELETE_NOTIFICATIONS_DEFINITIONS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
