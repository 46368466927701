import Types from "./actionType";
import {
  FetchAcademyBatchPayload,
  CreateAcademyBatch,
  CreateAcademyBatchPayload,
  AcademyBatchList,
  FetchAcademyBatch,
  FetchAcademyBatchError,
  FetchAcademyBatchSuccess,
  UpdateAcademyBatch,
  UpdateAcademyBatchPayload,
  AcademyBatchListWithMetaData,
  FetchAcademyBatchByIdPayload,
  FetchAcademyBatchById,
  DeleteAcademyBatchPayload,
  DeleteAcademyBatch,
} from "./types";

export const fetchAcademyBatchList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyBatchPayload): FetchAcademyBatch => ({
  type: Types.FETCH_ACADEMY_BATCH,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyBatchById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyBatchByIdPayload): FetchAcademyBatchById => ({
  type: Types.FETCH_ACADEMY_BATCH_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyBatchListSuccess = (
  data: AcademyBatchListWithMetaData,
): FetchAcademyBatchSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_BATCH,
  payload: {
    data,
  },
});

export const fetchAcademyBatchError = (error?: string): FetchAcademyBatchError => ({
  type: Types.ERROR_FETCH_ACADEMY_BATCH,
  payload: { error },
});

export const createAcademyBatch = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyBatchPayload): CreateAcademyBatch => ({
  type: Types.CREATE_ACADEMY_BATCH,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyBatch = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyBatchPayload): UpdateAcademyBatch => ({
  type: Types.UPDATE_ACADEMY_BATCH,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAcademyBatch = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyBatchPayload): DeleteAcademyBatch => ({
  type: Types.DELETE_ACADEMY_BATCH,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
