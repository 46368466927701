import Types from "./actionType";
import { BugReportingActions, BugReportingState } from "./types";

const initialState: BugReportingState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const BugReportingReducer = (state = initialState, action: BugReportingActions) => {
  switch (action.type) {
    case Types.FETCH_BUGREPORTING:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_BUGREPORTING:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
