import React, { useEffect, useState } from "react";

import { MdArrowDropDown } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateOrganisation } from "../../redux/user/actions";

import {
  MainContainer,
  Selected,
  SelectedContainer,
  ListContainer,
  ListItem,
} from "./HomeController.styles";

const HomeControllerAdmin = (props) => {
  const navigate = useNavigate();
  const [currentOrganisation, setCurrentOrginasition] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    let temp = {};
    for (let i = 0; i < props.user?.organisations?.length; i++) {
      if (
        props.user?.organisation?._id &&
        props.user?.organisation?._id === props.user?.organisations[i]?.organisation?._id
      ) {
        temp = props.user.organisations[i].organisation;
      }
    }
    setCurrentOrginasition(temp);
  }, [props.user?.organisation?._id, props.user.organisations]);

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const index = e.target.getAttribute("data-index");
    const organisation = props.user.organisations[index].organisation;
    const employee = props.user.organisations[index].employee;
    props.updateOrganisation({ organisation, employee });
    navigate("/ep");
  };
  return (
    <MainContainer isExpanded={isExpanded}>
      <SelectedContainer onClick={() => setIsExpanded(!isExpanded)}>
        <Selected>{currentOrganisation.name}</Selected>
        <MdArrowDropDown size={40} />
      </SelectedContainer>
      {isExpanded && (
        <ListContainer>
          {props?.user?.organisations?.length > 0 && (
            <>
              {props.user.organisations.map((item, index) => {
                if (item.organisation && currentOrganisation._id !== item.organisation._id) {
                  return (
                    <ListItem
                      key={index}
                      data-index={index}
                      onClick={(e) => {
                        handleOnClick(e);
                        setCurrentOrginasition(item.organisation);
                        setIsExpanded(false);
                      }}
                    >
                      {item.organisation.name}
                    </ListItem>
                  );
                }
              })}
            </>
          )}
        </ListContainer>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = {
  updateOrganisation,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeControllerAdmin);
