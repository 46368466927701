import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { ResourcesListProps } from "../../../../types/models/Resources";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useResourcesList } from "./hooks";

export const ResourcesList = memo(({ relatedList }: ResourcesListProps) => {
  const Resources = useAppSelector((state) => state.ResourcesReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = Resources;
  const {
    fetchResources,
    loading,
    handlePageChange,
    onSubmitDeleteRecord,
    onClickResources,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    columns,
    resourcesTableConfigFields,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    resourcesHeaderButtons,
    resourcesFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useResourcesList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchResources({});
    // Do not include fetchResources to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={resourcesHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.resources}
      handleTableConfigs={handleTableConfigs}
      filters={resourcesFilterFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.resources_delete}
      refreshList={refreshList}
      rightButton={strings.common.add}
      selectedConfigFields={resourcesTableConfigFields}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickResources}
      headerTitle={strings.headerTitle.resources}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apResources.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
