import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { clearAllFilter, fetchKnowledgeArticles } from "../../../../../redux/knowledge/actions";
import { FetchKnowledgeArticleListParams } from "../../../../../redux/knowledge/types";
import { strings } from "../../../../../theme";
import { KnowledgeListTemplate } from "../../../../components";
import { useKnowledgeList } from "../hooks/useKnowledgeApprovals";

export const InReviewKnowledgeArticles = () => {
  const dispatch = useAppDispatch();

  const articles = useAppSelector((state) => state.KnowledgeReducer.list);
  const loading = useAppSelector((state) => state.KnowledgeReducer.loading);
  const searchQuery = useAppSelector((state) => state.KnowledgeReducer.list.searchQuery);

  const {
    headerTitle,
    handleOnPageClickApprovals,
    getStatus,
    search,
    onChangeSearch,
    handleClearSearch,
    handleReloadApprovals,
  } = useKnowledgeList({ searchQuery });

  useEffect(() => {
    if (articles.type !== "inreview") {
      const params: FetchKnowledgeArticleListParams = { page: 1, limit: 10, status: "inreview" };
      dispatch(fetchKnowledgeArticles({ params, type: "inreview" }));
      dispatch(clearAllFilter());
    }
  }, [dispatch, articles.type]);

  return (
    <>
      <KnowledgeListTemplate
        loading={loading}
        headerTitle={headerTitle("inreview")}
        type='inreview'
        bannerTitle={strings.epKnowledge.approvals}
        data={articles.data}
        metaData={articles.metaData}
        handlePageChange={handleOnPageClickApprovals}
        getStatus={getStatus}
        searchValue={search}
        onChangeSearch={onChangeSearch}
        handleClearSearch={handleClearSearch}
        handleReload={handleReloadApprovals}
        filters={articles.filters}
      />
    </>
  );
};
