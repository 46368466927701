import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  CompanyContactList,
  AddCompanyContact,
  UpdateCompanyContact,
  FindCompanyContact,
  DeleteCompanyContact,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchCompanyContactListSuccess, fetchCompanyContactError } from "./actions";
import Types from "./actionType";
import {
  CreateCompanyContactuccess,
  CreateCompanyContact as CreateCompanyContactType,
  CompanyContactSuccess,
  UpdateCompanyContact as UpdateCompanyContactType,
  FetchCompanyContact,
  FetchCompanyContactById,
  CompanyContactByIdSuccess,
  DeleteCompanyContact as DeleteCompanyContactType,
} from "./types";

function* createWorker(action: CreateCompanyContactType) {
  try {
    const options = {
      method: "POST",
      url: AddCompanyContact,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCompanyContactuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateCompanyContactType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateCompanyContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCompanyContactuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchCompanyContact) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: CompanyContactList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<CompanyContactSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchCompanyContactListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchCompanyContactError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Company Contact");
    }
  }
}

function* fetchCompanyContactById(action: FetchCompanyContactById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindCompanyContact(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CompanyContactByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Company Contact");
    }
  }
}

function* deleteWorker(action: DeleteCompanyContactType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteCompanyContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateCompanyContactuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}
export default function* Saga() {
  yield takeLatest(Types.CREATE_COMPANY_CONTACT, createWorker);
  yield takeLatest(Types.UPDATE_COMPANY_CONTACT, updateWorker);
  yield takeLatest(Types.DELETE_COMPANY_CONTACT, deleteWorker);
  yield takeEvery(Types.FETCH_COMPANY_CONTACT, fetchAllWorker);
  yield takeEvery(Types.FETCH_COMPANY_CONTACT_BY_ID, fetchCompanyContactById);
}
