import Types from "./actionType";
import {
  FetchAttributesPayload,
  CreateAttributes,
  CreateAttributesPayload,
  FetchAttributes,
  FetchAttributesError,
  FetchAttributesSuccess,
  UpdateAttributes,
  UpdateAttributesPayload,
  AttributesListWithMetaData,
  FetchAttributesByIdPayload,
  FetchAttributesById,
} from "./types";

export const fetchAttributesList = ({
  params,
  onSuccess,
  onError,
}: FetchAttributesPayload): FetchAttributes => ({
  type: Types.FETCH_ATTRIBUTE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAttributesById = ({
  id,
  onSuccess,
  onError,
}: FetchAttributesByIdPayload): FetchAttributesById => ({
  type: Types.FETCH_ATTRIBUTE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAttributesListSuccess = (
  data: AttributesListWithMetaData,
): FetchAttributesSuccess => ({
  type: Types.SUCCESS_FETCH_ATTRIBUTE,
  payload: {
    data,
  },
});

export const fetchAttributesError = (error?: string): FetchAttributesError => ({
  type: Types.ERROR_FETCH_ATTRIBUTE,
  payload: { error },
});

export const createAttributes = ({
  data,
  onSuccess,
  onError,
}: CreateAttributesPayload): CreateAttributes => ({
  type: Types.CREATE_ATTRIBUTE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAttributes = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAttributesPayload): UpdateAttributes => ({
  type: Types.UPDATE_ATTRIBUTE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
