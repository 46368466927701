import styled from "styled-components";

import { fonts, fs, colors } from "../../../../../theme";

interface Props {
  size?: "large" | "small";
}

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  .ep-knowledge-dashboard-search-container {
    background-color: ${colors.common.secondaryWhite};
    width: 58%;
    position: relative;
    margin-right: 20px;
  }
  .ep-knowledge-dashboard-input {
    padding-left: 4%;
  }
`;

export const TopBanner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(268.94deg, #3f107b -4.87%, #8e37ff 112.84%);
`;

export const Title = styled.text`
  /* margin-top: 30px; */
  font-family: ${fonts.OpenSans};
  font-weight: 800;
  font-size: ${fs.xl};
  text-transform: uppercase;
  color: ${colors.common.pink};
`;

export const SubTitle = styled.text`
  margin-top: 10px;
  font-family: ${fonts.OpenSans};
  font-weight: 800;
  font-size: ${fs.xxl};
  color: ${colors.common.white};
`;
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px 8%;
  row-gap: 40px;
  justify-content: space-between;
`;

export const ViewAll = styled.div`
  display: flex;
  padding: 10px 20px;
  width: fit-content;
  align-self: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  color: ${colors.common.titleColor};
  cursor: pointer;
  font-size: ${fs.s};
  line-height: 15px;
  font-family: ${fonts.SourceSansPro};
  transition: linear 0.4s;
  background-color: ${colors.common.yellow};
  /* &:hover {
    color: ${colors.common.titleColor};
    background-color: ${colors.common.primary};
  } */
`;

export const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 100%; */
  padding: 32px;
  margin-top: 31px;
`;
const styles = {
  MainContainer: styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    .header {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      color: ${colors.white};
      text {
        font-family: ${fonts.PoppinsMedium};
        font-size: ${fs.xxl};
      }
      .button {
        position: absolute;
        right: 0;
      }
      align-items: center;
      margin-bottom: 1vh;
    }
    svg {
      position: absolute;
      width: 100%;
      z-index: -1;
      top: -20px;
    }
    .padding {
      padding: 2vh 2vw;
    }
    .desc {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      max-width: 40px;
    }
    .edit {
      cursor: pointer;
    }
  `,
  Header: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }
    align-items: center;
    margin-bottom: 1vh;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Column: styled.div`
    flex: 1;
  `,
  CodeContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    b {
      width: 10vw;
      font-size: 1rem;
    }
  `,
  ItemContainer: styled.div`
    width: 100%;
    /* display: grid; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-around; */
    column-gap: 4%;
    margin-top: 4vh;
    gap: 1rem;
    margin-top: 25vh;
    /* grid-template-columns: repeat(2, minmax(300px, 1fr)); */
    .item {
      height: 150px;
      width: 250px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      position: relative;
      flex-direction: column;
      cursor: pointer;
      background-color: rgba(130, 205, 71, 0.1);
      color: ${colors.primaryFontColor};
      .logo {
        height: 50px;
        width: 100px;
        object-fit: contain;
        margin-top: 4%;
      }
      h1 {
        font-size: ${fs.l};
        font-weight: 400;
        font-family: ${fonts.PoppinsMedium};
      }
      h2 {
        font-size: ${fs.m};
        font-weight: 200;
        font-family: ${fonts.RobotoMedium};
        background-color: ${colors.blue};
        color: ${colors.secondaryFontColor};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        height: 20%;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      border: 0.6px solid ${colors.grey};
      background: ${colors.secondaryBackground};
    }
  `,
};

export default styles;
