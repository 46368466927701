import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { CertificationsAccreditationsListProps } from "../../../../types/models/CertificationsAccreditations";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useCertificationsAccreditationsList } from "./hooks";

export const CertificationsAccreditationsList = memo(
  ({ relatedList }: CertificationsAccreditationsListProps) => {
    const CertificationsAccreditations = useAppSelector(
      (state) => state.CertificationsAccreditationsReducer.data,
    );
    const permissions = useAppSelector((state) => state.UserReducer.permissions);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const widgetId = searchParams.get("widgetId");
    const { list, metaData } = CertificationsAccreditations;
    const {
      fetchCertificationsAccreditations,
      loading,
      handlePageChange,
      onClickCertificationsAccreditations,
      onClickAdd,
      onSearch,
      handleClearSearch,
      search,
      onSubmitDeleteRecord,
      onSearchChange,
      refreshList,
      listActionOptions,
      onFilterChange,
      onClickApplyFilter,
      handleSortList,
      handleTableConfigs,
      onClickRowSelection,
      allRowsSelected,
      selectedRows,
      filterLogicQuery,
      handleListActions,
      handleShowItemsPerPage,
      certificationsAccreditationsFilterFields,
      exportSelectedRecords,
      exportAllRecords,
      columns,
      CertificationAccreditationConfigTableFields,
    } = useCertificationsAccreditationsList({
      relatedList,
      widgetId,
    });

    useEffect(() => {
      fetchCertificationsAccreditations({});
      // Do not include fetchCertificationsAccreditations to avoid multiple rerenders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TableListV3
        exportSelectedRecords={exportSelectedRecords}
        exportAllRecords={exportAllRecords}
        isRelatedList={!!relatedList?.type}
        advancedList={!(relatedList?.type && relatedList.value)}
        handleShowItemsPerPage={handleShowItemsPerPage}
        handleListActions={handleListActions}
        filterLogicQuery={filterLogicQuery}
        selectedRows={selectedRows}
        allRowsSelected={allRowsSelected}
        onClickRowSelection={onClickRowSelection}
        tablePublicFields={tablePublicFields.certificationsAccreditations}
        handleTableConfigs={handleTableConfigs}
        filters={certificationsAccreditationsFilterFields}
        selectedConfigFields={CertificationAccreditationConfigTableFields}
        handleSortList={handleSortList}
        onClickApplyFilter={onClickApplyFilter}
        onFilterChange={onFilterChange}
        horizontalScroll={true}
        refreshList={refreshList}
        rightButton={strings.common.add}
        rightButtonPress={onClickAdd}
        onClickTableRow={onClickCertificationsAccreditations}
        headerTitle={strings.headerTitle.certificationsAccreditations}
        list={list}
        columns={columns}
        metaData={metaData}
        onSubmitDeleteRecord={onSubmitDeleteRecord}
        deleteRecordEnabled={permissions?.certificationsAccreditations_delete}
        onPageChange={handlePageChange}
        loading={loading}
        searchPlaceholder={strings.apCertificationsAccreditations.search}
        onSearch={onSearch}
        clearSearch={handleClearSearch}
        searchValue={search}
        onSearchChange={onSearchChange}
        listActionOptions={listActionOptions}
      />
    );
  },
);
