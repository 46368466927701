import React from "react";

import { Layout } from "../../../presentationals";
import { AcademyCandidatesController } from "../../../presentationals/screens";

export const APAcademyCandidatesEdit = () => {
  return (
    <Layout activity={<AcademyCandidatesController type={"edit"} />} admin={true} layout={"2"} />
  );
};
