import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteDashboardWidgets,
  fetchDashboardWidgetsList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateDashboardWidgets,
  updateTableConfigs,
} from "../../../../../../redux";
import {
  DashboardWidgetsList,
  DashboardWidgetsListWithMetaData,
  FetchDashboardWidgetsParams,
} from "../../../../../../redux/Dashboard/Widgets/types";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../../services/methods";
import { strings } from "../../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchDashboardWidgetsProps,
  sortType,
  useDashboardWidgetsListProps,
  useDashboardWidgetsListReturnType,
} from "../../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  DashboardWidgetTypeOptions,
  collections,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../../utils";
import { useControllerConfigs } from "../../../../../common";

import { DeleteDashboardWidgetsPayload } from "./../../../../../../redux/Dashboard/types";

const { add, edit } = AppRouteLinks.dashboardWidgets;
const defaultFilters: QueryBuilderType[] = [];

export const useDashboardWidgetsList = ({
  relatedList,
}: useDashboardWidgetsListProps): useDashboardWidgetsListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.DashboardWidgetsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const dashboardWidgets = useAppSelector((state) => state.DashboardWidgetsReducer.data.list);
  const { usersOptions, dashboardTabsOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: DashboardWidgetsList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.dashboardWidgets?.fields ||
        tableListDefaultSelectFields.dashboardWidgets;
      records = records?.map((item) => {
        const dashboardWidgetsItem = { ...item };
        if (
          userPersonalizedFields?.includes("dashboardTab") &&
          typeof item.dashboardTab !== "string"
        ) {
          dashboardWidgetsItem.dashboardTab = item?.dashboardTab?.title;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          dashboardWidgetsItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          dashboardWidgetsItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          dashboardWidgetsItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          dashboardWidgetsItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete dashboardWidgetsItem?._id;
        return dashboardWidgetsItem;
      });
      const dashboardWidgetsFields = tablePublicFields.dashboardWidgets;
      const columnHeaders = dashboardWidgetsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "dashboard_widgets");
    },
    [tableConfiguration?.dashboardWidgets?.fields],
  );

  // ================ Fetch Widgets Details Success/Error Methods ================
  const dashboardWidgetsFetchSuccess = useCallback(
    (data: DashboardWidgetsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        exportRecords(data.list);
      }
    },
    [exportRecords],
  );
  const dashboardWidgetsFetchError = useCallback(
    (error: string) => {
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchDashboardWidgets = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchDashboardWidgetsProps) => {
      setLoading(true);

      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.dashboardWidgets?.limit || 10;

      const params: FetchDashboardWidgetsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["title"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: DashboardWidgetsListWithMetaData) =>
          dashboardWidgetsFetchSuccess(data, exportAll),
        onError: dashboardWidgetsFetchError,
      };
      dispatch(fetchDashboardWidgetsList(payload));
    },
    [
      dispatch,
      dashboardWidgetsFetchError,
      dashboardWidgetsFetchSuccess,
      relatedList,
      metaData.currentPage,
      mongoQuery,
      sortKey,
      sortType,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = dashboardWidgets?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, dashboardWidgets, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchDashboardWidgets({ exportAll: true });
  }, [fetchDashboardWidgets]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchDashboardWidgets({ page });
  }, [fetchDashboardWidgets, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchDashboardWidgets({ page: parseInt(page), search });
    },
    [fetchDashboardWidgets, search],
  );

  // ================ Add/Edit Methods ================

  const onClickDashboardWidgets = useCallback(
    (id: string) => {
      if (permissions?.dashboardWidget_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.dashboardWidget_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchDashboardWidgets({ page: 1, search });
  }, [fetchDashboardWidgets, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchDashboardWidgets({ page: 1 });
  }, [fetchDashboardWidgets]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchDashboardWidgets({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchDashboardWidgets],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchDashboardWidgets({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchDashboardWidgets],
  );

  // ===================Delete Method ======================
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = dashboardWidgets?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteDashboardWidgetsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchDashboardWidgets({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteDashboardWidgets(payload));
  }, [selectedRows, dispatch, notificationsId, dashboardWidgets, fetchDashboardWidgets]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = dashboardWidgets?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, dashboardWidgets]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            dashboardWidgets?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, dashboardWidgets, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      setLoading(true);
      const dashboardWidgetsConfigLimit = tableConfiguration?.dashboardWidgets?.limit || 10;
      const dashboardWidgetsConfigs = {
        table: "dashboardWidgets",
        fields: fields,
        limit: dashboardWidgetsConfigLimit,
      };
      const payload = {
        tableConfiguration: dashboardWidgetsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchDashboardWidgets({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Dashboard Widgets table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.dashboardWidgets?.limit, dispatch, fetchDashboardWidgets, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const dashboardWidgetsConfigFields = tableConfiguration?.dashboardWidgets?.fields;
      setLoading(true);
      const dashboardWidgetsConfigs = {
        table: "dashboardWidgets",
        fields: dashboardWidgetsConfigFields,
        limit: Number(count),
      };
      const payload = {
        tableConfiguration: dashboardWidgetsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchDashboardWidgets({ limit: Number(count), page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Dashboard Widgets table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchDashboardWidgets],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = dashboardWidgets?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchDashboardWidgets({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Dashboard Widgets updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Configs",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateDashboardWidgets(payload));
    },
    [selectedRows, dashboardWidgets, dispatch, notificationsId, fetchDashboardWidgets],
  );
  // ================ Table Header Buttons ================

  const dashboardWidgetsHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);
  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (table && "dashboardSupported" in table && table.dashboardSupported) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null; // or undefined, depending on your preference
      })
      .filter(Boolean); // to remove any null or undefined entries
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "title",
      label: "Title",
      type: "text",
      required: true,
    },
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
      required: true,
    },
    {
      name: "type",
      label: "Type",
      options: DashboardWidgetTypeOptions,
    },
    {
      name: "dashboardTab",
      label: "Dashboard Tab",
      options: dashboardTabsOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const dashboardWidgetsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");
    const dashboardTabsFilterOptions = getFilterOptions(dashboardTabsOptions, "Dashboard Tabs");
    const typeOptions = getFilterOptions(DashboardWidgetTypeOptions, "Type");
    const tableOptions = getFilterOptions(TableList, "Type");

    const fields: Field[] = [
      {
        name: "title",
        label: "Title",
        inputType: "string",
        validator,
      },
      {
        name: "table",
        label: "Table",
        inputType: "select",
        values: tableOptions,
        defaultValue: tableOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "type",
        label: "Type",
        inputType: "select",
        values: typeOptions,
        defaultValue: typeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "dashboardTab",
        label: "Dashboard Tab",
        inputType: "select",
        values: dashboardTabsFilterOptions,
        defaultValue: dashboardTabsFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions, dashboardTabsOptions]);

  return {
    listActionOptions,
    dashboardWidgetsFilterFields,
    onClickDashboardWidgets,
    fetchDashboardWidgets,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    dashboardWidgetsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
  };
};
