import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  getToken,
  getOrganisation,
  EmailTemplateFetch,
  EmailTemplateUpdate,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchEmailTemplateSuccess } from "./actions";
import Types from "./types";

function* doFetchWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmailTemplateFetch,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(
        fetchEmailTemplateSuccess({
          orgData: response.data.data.orgData,
          defData: response.data.data.defData,
        }),
      );
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* doUpdateWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmailTemplateUpdate,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        key: action.payload.key,
        subject: action.payload.subject,
        body: action.payload.body,
        useDefault: action.payload.useDefault,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* EmailTemplatesSaga() {
  yield takeLatest(Types.EMAIL_FETCH, doFetchWorker);
  yield takeLatest(Types.EMAIL_UPDATE, doUpdateWorker);
}
