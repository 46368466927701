import React from "react";

import { Layout } from "../../../presentationals";
import { ITNRFPList as ITNRFPListScreen } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<ITNRFPListScreen />} admin={true} layout={"2"} />;
};

export const APITNRFPList = React.memo(Route);
