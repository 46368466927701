import React from "react";

import { Layout } from "../../../presentationals";
import { AttributesController } from "../../../presentationals/screens";
import { strings } from "../../../theme";

export const AttributesAdd = () => {
  return (
    <Layout
      activity={<AttributesController type={"add"} />}
      title={strings.headerTitle.categories}
      admin={true}
      layout={"2"}
    />
  );
};
