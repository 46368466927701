/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPACE = "FETCH_SPACE",
  SUCCESS_FETCH_SPACE = "SUCCESS_FETCH_SPACE",
  SET_META_DATA_SPACE = "SET_META_DATA_SPACE",
  ERROR_FETCH_SPACE = "ERROR_FETCH_SPACE",
  FETCH_SPACE_BY_ID = "FETCH_SPACE_BY_ID",
  CREATE_SPACE = "CREATE_SPACE",
  UPDATE_SPACE = "UPDATE_SPACE",
  UPDATE_FITERS_SPACE = "UPDATE_FITERS_SPACE",
  SPACE_FETCH_SUCCESS = "SPACE_FETCH_SUCCESS",
  DELETE_SPACE = "DELETE_SPACE",
}

export default Types;
