/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ITNSERVICES = "FETCH_ITNSERVICES",
  SUCCESS_FETCH_ITNSERVICES = "SUCCESS_FETCH_ITNSERVICES",
  ERROR_FETCH_ITNSERVICES = "ERROR_FETCH_ITNSERVICES",
  CREATE_ITNSERVICES = "CREATE_ITNSERVICES",
  UPDATE_ITNSERVICES = "UPDATE_ITNSERVICES",
  DELETE_ITNSERVICES = "DELETE_ITNSERVICES",
  FETCH_ITNSERVICES_BY_ID = "FETCH_ITNSERVICES_BY_ID",
}

export default Types;
