import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const AccessLevelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AccessLevel = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

export const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  /* flex-direction: colu; */
`;
