import React from "react";

import { images } from "../../../theme";

import { Container, SearchIcon, InputField } from "./styles";

interface InputProps {
  containerClass?: string;
  iconClass?: string;
  inputClass?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  clearSearch?: (e: React.MouseEvent) => void;
  searchIconAttributes?: any;
}

const Search = ({
  containerClass,
  iconClass,
  inputClass,
  inputProps,
  searchIconAttributes,
  clearSearch,
}: InputProps) => {
  return (
    <Container className={`search-container ${containerClass}`}>
      <SearchIcon
        className={iconClass}
        src={clearSearch && inputProps?.value ? images.searchCancel : images.searchIcon}
        onClick={clearSearch && clearSearch}
        {...searchIconAttributes}
      />
      <InputField className={`search-input ${inputClass}`} {...inputProps} />
    </Container>
  );
};

export default Search;
