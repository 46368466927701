import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { TagsListProps } from "../../../../types/models/Tags";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useTagsList } from "./hooks";

export const TagsList = memo(({ relatedList }: TagsListProps) => {
  const Tags = useAppSelector((state) => state.TagsReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { list, metaData } = Tags;
  const {
    fetchTags,
    loading,
    handlePageChange,
    onClickTags,
    onClickAdd,
    onSearch,
    handleClearSearch,
    onSubmitDeleteRecord,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    tagsFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    TagsConfigTableFields,
  } = useTagsList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchTags({});
    // Do not include fetchTags to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.tags}
      handleTableConfigs={handleTableConfigs}
      filters={tagsFilterFields}
      selectedConfigFields={TagsConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickTags}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.task_delete}
      headerTitle={strings.headerTitle.tags}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apTags.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
