import React, { useEffect } from "react";

import moment from "moment-timezone";
import { MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import {Header as MainHeader} from '../../presentationals/components'
import { fetchPayrollData } from "../../redux/employees/actions";
import { useTitle, Sort } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./PayrollsListing.styles";

const Employees = (props) => {
  useTitle("Payrolls List");
  useEffect(() => {
    props.fetchPayrollData();
  }, []);

  const getSortedData = () => {
    const sortKey = props.payrollsFilters.sortKey.key;
    const isDate = props.payrollsFilters.sortKey.isDate;
    const ascSort = props.payrollsFilters.sortType === "asc";
    const attribute = props.payrollsFilters.sortKey.attribute;
    let data = props.payrolls;
    if (props.payrolls?.[0]?.[sortKey]) {
      data = Sort({
        data: props.payrolls,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return data;
  };
  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.payrolls} />
      <Header
        title='Payrolls'
        onSync={{
          event: props.fetchPayrollData,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left'>Sno.</th>
            <th align='left'>Month</th>
            {/* <th>Status</th> */}
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {getSortedData().map((payroll, index) => {
            if (payroll?.list?.length > 0) {
              return (
                <tr key={index}>
                  <td width={"5%"}>{index + 1}</td>
                  <td>{moment(payroll.date).utc().format("YYYY MMMM")}</td>
                  {/* <td align="center">
                    {payroll.status} ({payroll.list.length})
                  </td> */}
                  <td width={"10%"} align='center'>
                    <Link to={`/ap/payrolls/${payroll._id}/childs`}>
                      <MdArrowRightAlt size={20} />
                    </Link>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  payrolls: state.EmployeesReducer.payrolls,
  payrollsFilters: state.EmployeesReducer.payrollsFilters,
  //   loading: state.EmployeesReducer.loading,
  //   employees: state.EmployeesReducer.employees,
  //   filters: state.EmployeesReducer.filters,
  //   permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchPayrollData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
