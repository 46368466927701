import React from "react";

import { Layout } from "../../../presentationals";
import { SPSAccountsController } from "../../../presentationals/screens/admin/SPSAccounts";

const Route = () => {
  return <Layout activity={<SPSAccountsController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APSPSAccountsEdit = React.memo(Route);
