/* eslint-disable no-unused-vars */
enum Types {
  FETCH_DASHBOARD_ADMINISTRATION = "FETCH_DASHBOARD_ADMINISTRATION",
  SUCCESS_FETCH_DASHBOARD_ADMINISTRATION = "SUCCESS_FETCH_DASHBOARD_ADMINISTRATION",
  SET_META_DATA_DASHBOARD_ADMINISTRATION = "SET_META_DATA_DASHBOARD_ADMINISTRATION",
  ERROR_FETCH_DASHBOARD_ADMINISTRATION = "ERROR_FETCH_DASHBOARD_ADMINISTRATION",
  FETCH_DASHBOARD_ADMINISTRATION_BY_ID = "FETCH_DASHBOARD_ADMINISTRATION_BY_ID",
  CREATE_DASHBOARD_ADMINISTRATION = "CREATE_DASHBOARD_ADMINISTRATION",
  UPDATE_DASHBOARD_ADMINISTRATION = "UPDATE_DASHBOARD_ADMINISTRATION",
  UPDATE_FITERS_DASHBOARD_ADMINISTRATION = "UPDATE_FITERS_DASHBOARD_ADMINISTRATION",
  DASHBOARD_ADMINISTRATION_FETCH_SUCCESS = "DASHBOARD_ADMINISTRATION_FETCH_SUCCESS",
  DELETE_DASHBOARD_ADMINISTRATION = "DELETE_DASHBOARD_ADMINISTRATION",
}

export default Types;
