import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  Form16List,
  AddForm16,
  UpdateForm16,
  FindForm16,
  DeleteForm16,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchForm16ListSuccess, fetchForm16Error } from "./actions";
import Types from "./actionType";
import {
  CreateForm16uccess,
  CreateForm16 as CreateForm16Type,
  Form16Success,
  UpdateForm16 as UpdateForm16Type,
  FetchForm16,
  FetchForm16ById,
  Form16ByIdSuccess,
  DeleteForm16 as DeleteForm16Type,
} from "./types";

function* createWorker(action: CreateForm16Type) {
  try {
    const options = {
      method: "POST",
      url: AddForm16,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateForm16uccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateForm16Type) {
  try {
    const options = {
      method: "PUT",
      url: UpdateForm16(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateForm16uccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* deleteWorker(action: DeleteForm16Type) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteForm16(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateForm16uccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchForm16) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: Form16List,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<Form16Success> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchForm16ListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchForm16Error(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching form 16");
    }
  }
}

function* fetchForm16ById(action: FetchForm16ById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindForm16(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<Form16ByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching form 16");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_FORM16, createWorker);
  yield takeLatest(Types.UPDATE_FORM16, updateWorker);
  yield takeLatest(Types.DELETE_FORM16, deleteWorker);
  yield takeEvery(Types.FETCH_FORM16, fetchAllWorker);
  yield takeEvery(Types.FETCH_FORM16_BY_ID, fetchForm16ById);
}
