/* eslint-disable no-unused-vars */
enum Types {
  FETCH_NEWSLETTER_RESOURCES = "FETCH_NEWSLETTER_RESOURCES",
  FETCH_NEWSLETTER_RESOURCES_BY_ID = "FETCH_NEWSLETTER_RESOURCES_BY_ID",
  SUCCESS_FETCH_NEWSLETTER_RESOURCES = "SUCCESS_FETCH_NEWSLETTER_RESOURCES",
  ERROR_FETCH_NEWSLETTER_RESOURCES = "ERROR_FETCH_NEWSLETTER_RESOURCES",
  CREATE_NEWSLETTER_RESOURCES = "CREATE_NEWSLETTER_RESOURCES",
  UPDATE_NEWSLETTER_RESOURCES = "UPDATE_NEWSLETTER_RESOURCES",
  DELETE_NEWSLETTER_RESOURCES = "DELETE_NEWSLETTER_RESOURCES",
}

export default Types;
