import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads/AcademyCandidateLeadsList";
import { AcademyCoursesList } from "../AcademyCourses/AcademyCoursesList";
import { AcademyStudentProfileList } from "../AcademyStudentProfile/AcademyStudentProfileList";
import { RelatedListContainer } from "../Departments/styles";
import { TaskList } from "../Task";

import { AcademyPaymentDetails } from "./AcademyPaymentDetails";
import { useAcademyCandidateEnrollmentsController } from "./hooks";
import { Row } from "./styles";

export const AcademyCandidateEnrollmentsController = ({ type }: { type?: "add" | "edit" }) => {
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { taxTooltip, nbfcTooltip } = strings.apAcademyCandidateEnrollments;

  const params = useParams();

  const {
    currentStepIndex,
    goTo,
    netRevenueValue,
    formData,
    onTextChange,
    onDateChange,
    loading,
    headerTitle,
    fetchAcademyCandidateEnrollments,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    formSteps,
    formTitle,
    formRelatedList,
    headerButtons,
  } = useAcademyCandidateEnrollmentsController({
    type,
    id: params.id,
    attachmentsPickerRef,
  });
  const currentAcademyCandidateEnrollments = data;

  const {
    employeesOptions,
    academyCoursesOptions,
    academBatchesOptions,
    academyCandidateLeadsOptions,
    currencyOptions,
  } = useControllerConfigs();

  const {
    attachments,
    studentName,
    anyDiscountsGiven,
    careerPath,
    workLogs,
    studentID,
    enrolledCourse,
    candidateLead,
    batchStartDate,
    batchSelected,
    trainingDurations,
    enrollmentStage,
    studentOfficialEmail,
    paymentStatus,
    totalFees,
    paymentTerms,
    feesPaid,
    taxedOnFees,
    currency,
    NBFCcharges,
    netRevenue,
    nextPaymentDueDate,
    paymentDetails,
    academyOfficers,
  } = formData;
  const { careerCounseller, corporateRelationManager, trainingCordinator, enrollmentSpecialist } =
    academyOfficers;
  useEffect(() => {
    if (type === "edit") {
      fetchAcademyCandidateEnrollments();
    }
    // Do not include fetchAcademyCandidateEnrollments to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Learner Name'
            type={"text"}
            width='48%'
            error={studentName.error}
            inputProps={{
              name: "studentName",
              value: studentName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"candidateLead"}
            label={"Associated To Candidate Leads"}
            options={academyCandidateLeadsOptions}
            value={candidateLead.value}
            onChange={onTextChange}
            mandatory={candidateLead.required}
            error={candidateLead.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Learner ID'
            type={"text"}
            width='48%'
            error={studentID.error}
            inputProps={{
              name: "studentID",
              value: studentID.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentID.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"enrollmentStage"}
            label={"Enrollment Stage"}
            options={getAttributValues("academyEnrollmentStage")}
            value={enrollmentStage.value}
            onChange={onTextChange}
            mandatory={enrollmentStage.required}
            error={enrollmentStage.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Learner Email'
            type={"text"}
            width='48%'
            error={studentOfficialEmail.error}
            inputProps={{
              name: "studentOfficialEmail",
              value: studentOfficialEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentOfficialEmail.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"enrolledCourse"}
            label={"Enrolled Course"}
            options={academyCoursesOptions}
            value={enrolledCourse.value}
            onChange={onTextChange}
            mandatory={enrolledCourse.required}
            error={enrolledCourse.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Batch Start Date'
            type={"date"}
            width='48%'
            error={batchStartDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "batchStartDate",
              value: batchStartDate.value,
              required: batchStartDate.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"batchSelected"}
            isMulti
            label={"Batch Selected"}
            options={academBatchesOptions}
            value={batchSelected.value}
            onChange={onTextChange}
            mandatory={batchSelected.required}
            error={batchSelected.error}
          />
        </Row>

        <TextInput
          label='Training Durations'
          type={"text"}
          width='48%'
          error={trainingDurations.error}
          inputProps={{
            name: "trainingDurations",
            value: trainingDurations.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: trainingDurations.required,
          }}
          textAreaProps={{}}
        />
        <Select
          width={"48%"}
          name={"careerPath"}
          label={"Career Path"}
          options={getAttributValues("academycareerPath", true)}
          value={careerPath.value}
          onChange={onTextChange}
          mandatory={careerPath.required}
          error={careerPath.error}
        />
      </>
    );
  }, [
    studentName.error,
    studentName.value,
    studentName.required,
    onTextChange,
    academyCandidateLeadsOptions,
    candidateLead.value,
    candidateLead.required,
    candidateLead.error,
    studentID.error,
    studentID.value,
    studentID.required,
    enrollmentStage.value,
    enrollmentStage.required,
    enrollmentStage.error,
    studentOfficialEmail.error,
    careerPath.error,
    careerPath.value,
    careerPath.required,
    studentOfficialEmail.value,
    studentOfficialEmail.required,
    academyCoursesOptions,
    enrolledCourse.value,
    enrolledCourse.required,
    enrolledCourse.error,
    batchStartDate.error,
    batchStartDate.value,
    batchStartDate.required,
    onDateChange,
    academBatchesOptions,
    batchSelected.value,
    batchSelected.required,
    batchSelected.error,
    trainingDurations.error,
    trainingDurations.value,
    trainingDurations.required,
  ]);
  const renderPaymentOverview = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"paymentStatus"}
            label={"Payment Status"}
            options={getAttributValues("academyPaymentStatus")}
            value={paymentStatus.value}
            onChange={onTextChange}
            mandatory={paymentStatus.required}
            error={paymentStatus.error}
          />
          <Select
            width={"48%"}
            name={"currency"}
            label={"Currency"}
            options={currencyOptions}
            value={currency.value}
            onChange={onTextChange}
            mandatory={currency.required}
            error={currency.error}
          />
        </Row>
        <Row>
          <TextInput
            label='TotalFees'
            type={"text"}
            width='48%'
            error={totalFees.error}
            inputProps={{
              name: "totalFees",
              value: totalFees.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: totalFees.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Fees Paid'
            type={"text"}
            width='48%'
            error={feesPaid.error}
            inputProps={{
              name: "feesPaid",
              disabled: true,
              type: "number",
              value: feesPaid.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: feesPaid.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Taxed On Fees'
            type={"text"}
            width='48%'
            tooltip={taxTooltip}
            error={taxedOnFees.error}
            inputProps={{
              disabled: true,
              name: "taxedOnFees",
              value: taxedOnFees.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: taxedOnFees.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='NBFC charges'
            type={"text"}
            width='48%'
            tooltip={nbfcTooltip}
            error={NBFCcharges.error}
            inputProps={{
              type: "number",
              name: "NBFCcharges",
              value: NBFCcharges.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: NBFCcharges.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='Net Revenue'
            type={"text"}
            width='48%'
            error={netRevenue.error}
            inputProps={{
              disabled: true,
              name: "netRevenue",
              value: netRevenueValue,
              autoComplete: "off",
              onChange: onTextChange,
              required: netRevenue.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Next Payment Due Date'
            type={"date"}
            width='48%'
            error={nextPaymentDueDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "nextPaymentDueDate",
              value: nextPaymentDueDate.value,
              required: nextPaymentDueDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"paymentTerms"}
            label={"Payment Terms"}
            options={getAttributValues("academyPaymentTerms")}
            value={paymentTerms.value}
            onChange={onTextChange}
            mandatory={paymentTerms.required}
            error={paymentTerms.error}
          />
          <TextInput
            label='Any Discounts Given'
            type={"text"}
            width='48%'
            error={anyDiscountsGiven.error}
            inputProps={{
              name: "anyDiscountsGiven",
              value: anyDiscountsGiven.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: anyDiscountsGiven.required,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    paymentStatus.value,
    paymentStatus.required,
    paymentStatus.error,
    onTextChange,
    currencyOptions,
    currency.value,
    currency.required,
    currency.error,
    totalFees.error,
    totalFees.value,
    totalFees.required,
    feesPaid.error,
    feesPaid.value,
    feesPaid.required,
    taxTooltip,
    taxedOnFees.error,
    taxedOnFees.value,
    taxedOnFees.required,
    nbfcTooltip,
    NBFCcharges.error,
    NBFCcharges.value,
    NBFCcharges.required,
    netRevenue.error,
    netRevenue.required,
    nextPaymentDueDate.error,
    nextPaymentDueDate.value,
    nextPaymentDueDate.required,
    onDateChange,
    paymentTerms.value,
    paymentTerms.required,
    paymentTerms.error,
    anyDiscountsGiven.error,
    anyDiscountsGiven.value,
    anyDiscountsGiven.required,
    netRevenueValue,
  ]);

  const renderPaymentDetails = useCallback(() => {
    return (
      <>
        <AcademyPaymentDetails
          paymentDetails={paymentDetails.value}
          candidateEnrollmentId={params.id}
          fetchPaymentDetails={fetchAcademyCandidateEnrollments}
        />
      </>
    );
  }, [fetchAcademyCandidateEnrollments, params.id, paymentDetails.value]);

  const remderAcademyOfficers = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"careerCounseller"}
            label={"Career Counseller"}
            options={employeesOptions}
            value={careerCounseller.value}
            onChange={onTextChange}
            mandatory={careerCounseller.required}
            error={careerCounseller.error}
          />
          <Select
            width={"48%"}
            name={"enrollmentSpecialist"}
            label={"Enrollment Specialist"}
            options={employeesOptions}
            value={enrollmentSpecialist.value}
            onChange={onTextChange}
            mandatory={enrollmentSpecialist.required}
            error={enrollmentSpecialist.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"trainingCordinator"}
            label={"Training Cordinator"}
            options={employeesOptions}
            value={trainingCordinator.value}
            onChange={onTextChange}
            mandatory={trainingCordinator.required}
            error={trainingCordinator.error}
          />
          <Select
            width={"48%"}
            name={"corporateRelationManager"}
            label={"Corporate Relation Manager"}
            options={employeesOptions}
            value={corporateRelationManager.value}
            onChange={onTextChange}
            mandatory={corporateRelationManager.required}
            error={corporateRelationManager.error}
          />
        </Row>
      </>
    );
  }, [
    careerCounseller.error,
    careerCounseller.required,
    careerCounseller.value,
    corporateRelationManager.error,
    corporateRelationManager.required,
    corporateRelationManager.value,
    employeesOptions,
    enrollmentSpecialist.error,
    enrollmentSpecialist.required,
    enrollmentSpecialist.value,
    onTextChange,
    trainingCordinator.error,
    trainingCordinator.required,
    trainingCordinator.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyCandidateEnrollments?.createdBy !== "string"
        ? currentAcademyCandidateEnrollments?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyCandidateEnrollments?.updatedBy !== "string"
        ? currentAcademyCandidateEnrollments?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyCandidateEnrollments?.createdAt}
        updatedAt={currentAcademyCandidateEnrollments?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyCandidateEnrollments?.createdAt,
    currentAcademyCandidateEnrollments?.createdBy,
    currentAcademyCandidateEnrollments?.updatedAt,
    currentAcademyCandidateEnrollments?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        collection={collections.academyCandidateEnrollments.name}
        recordId={params.id}
      />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => (
      <ActivityLogs
        recordId={params.id}
        collection={collections.academyCandidateEnrollments.name}
      />
    ),
    [params.id],
  );

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  // const relatedContact = useCallback(() => {
  //   return (
  //     <AcademyCandidatesList
  //       relatedList={{
  //         type: "academyCandidate",
  //         value: currentAcademyCandidateEnrollments?.candidateLead?.associatedContact,
  //       }}
  //     />
  //   );
  // }, [currentAcademyCandidateEnrollments]);
  const relatedCourses = useMemo(() => {
    return (
      <AcademyCoursesList
        relatedList={{
          type: "_id",
          value: enrolledCourse.value,
        }}
      />
    );
  }, [enrolledCourse.value]);

  const relatedCandidateLead = useMemo(() => {
    return (
      <AcademyCandidateLeadsList
        relatedList={{
          type: "_id",
          value: candidateLead.value,
        }}
      />
    );
  }, [candidateLead.value]);

  const relatedLerner = useMemo(() => {
    return (
      <AcademyStudentProfileList
        relatedList={{
          type: "associatedEnrollment",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const renderRelatedTasks = useMemo(() => {
    return (
      <RelatedListContainer>
        <TaskList
          relatedList={{
            type: "associatedCandidateEnrolment",
            value: params.id,
            associatedTo: "ach",
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return remderAcademyOfficers();
      case 2:
        return renderPaymentOverview();
      case 3:
        return renderPaymentDetails();
      case 4:
        return renderChecklist;
      case 5:
        return renderActivitylogs;
      case 6:
        return renderWorklogs();
      case 7:
        return renderSystemData();
      case 8:
        return relatedCourses;
      case 9:
        return relatedCandidateLead;
      case 10:
        return relatedLerner;
      case 11:
        return renderRelatedTasks;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    relatedCourses,
    renderActivitylogs,
    remderAcademyOfficers,
    renderPaymentOverview,
    renderChecklist,
    relatedLerner,
    renderOverview,
    renderPaymentDetails,
    renderSystemData,
    renderWorklogs,
    relatedCandidateLead,
    renderRelatedTasks,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
