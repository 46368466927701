import styled from "styled-components";

import { fs } from "../../../../../theme";

interface Props {
  color: string;
}

export const MainContainer = styled.div`
  height: 100%;
  width: 99%;
  .name {
    background-color: green;
  }
  .skeleton {
    margin-top: 4px;
    height: 40px;
    width: 100%;
  }
`;

export const KnowledgeStatus = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 60%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: ${fs.xs};
  text-transform: capitalize;
`;
