/* eslint-disable no-unused-vars */
enum Types {
  FETCH_DASHBOARDS = "FETCH_DASHBOARDS",
  SUCCESS_FETCH_DASHBOARDS = "SUCCESS_FETCH_DASHBOARDS",
  SUCCESS_FETCH_DASHBOARD_WIDGET_DATA = "SUCCESS_FETCH_DASHBOARD_WIDGET_DATA",
  SET_META_DATA_DASHBOARDS = "SET_META_DATA_DASHBOARDS",
  ERROR_FETCH_DASHBOARDS = "ERROR_FETCH_DASHBOARDS",
  FETCH_DASHBOARDS_BY_ID = "FETCH_DASHBOARDS_BY_ID",
  CREATE_DASHBOARDS = "CREATE_DASHBOARDS",
  UPDATE_DASHBOARDS = "UPDATE_DASHBOARDS",
  FETCH_DASHBOARD_WIDGET_DATA = "FETCH_DASHBOARD_WIDGET_DATA",
  UPDATE_FITERS_DASHBOARDS = "UPDATE_FITERS_DASHBOARDS",
  DASHBOARDS_FETCH_SUCCESS = "DASHBOARDS_FETCH_SUCCESS",
}

export default Types;
