import styled from "styled-components";

import { fonts } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 1vh;
    }
    /* justify-content: space-between; */
  `,
  Body: styled.div`
    flex: 1;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  `,
  Column: styled.div`
    flex: 1;
    justify-content: center;
    text-align: center;
    background-color: #eee;
    border-radius: 10px;
    font-family: ${fonts.font1};
    font-size: 11px;
    padding: 5px;
  `,
  metaDataContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  metaDataItem: styled.div`
    font-size: 1rem;
    font-weight: 600;
    background-color: #48b5e1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    padding-left: 10px;
    padding-right: 10px;
    /* margin: 5% 1%; */
    color: white;
  `,
  metaDataInfo: styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #48b5e1;
    border: solid 1px white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 4vh;
    width: 6vw;
  `,
};

export default styles;
