import React, { useState } from "react";

import styles from "./input.styles";
import "./input.css";

interface Props {
  label: string;
  type: "date" | "checkbox" | "textarea" | "text" | "password" | "email" | "file";
  name: string;
  value: string | Array<any> | boolean;
  readOnly?: boolean;
  autoComplete?: string | "off";
  onChange: Function;
  attributes?: object;
  mandatory?: boolean;
  error?: string;
  placeholder?: string;
  message?: string | boolean;
  onMessageClick?: () => void;
  width?: string;
}

const Input: React.FC<Props> = ({
  label,
  type,
  name,
  value,
  onChange,
  autoComplete = "off",
  placeholder = "",
  readOnly = false,
  attributes = {},
  mandatory = false,
  error = "",
  message = "",
  onMessageClick,
  width,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const onChangeDate = (date) => {
    const object = {
      target: {
        ...attributes,
        name: name,
        value: date,
      },
    };
    onChange(object);
  };
  let typeModified = "input";
  let valueModified = "";
  switch (type) {
    case "date":
      typeModified = "date";
      valueModified = value ? new Date(value) : null;
      break;
    default:
      typeModified = type;
      valueModified = value;
      break;
  }
  return (
    <styles.ParentWrapper>
      <styles.Wrapper>
        {/* <styles.container isFocused={isFocused}> */}
        {type === "date" && (
          <styles.DatePicker
            dayPlaceholder='dd'
            monthPlaceholder='mm'
            yearPlaceholder='yyyy'
            onChange={onChangeDate}
            value={valueModified}
            {...attributes}
            error={error}
          />
        )}
        {type === "checkbox" && (
          <styles.switch>
            <input
              type='checkbox'
              onChange={onChange}
              name={name}
              checked={valueModified ? true : false}
              value={valueModified}
              {...attributes}
              error={error}
            />
            <span className='slider round'></span>
          </styles.switch>
        )}
        {type === "textarea" && (
          <styles.TextArea
            rows={10}
            readOnly={readOnly}
            disabled={readOnly}
            placeholder={placeholder}
            type={typeModified}
            onChange={onChange}
            name={name}
            value={valueModified}
            autoComplete={autoComplete}
            autoCapitalize='none'
            //isfocused
            onFocus={() => !readOnly && setIsFocused(true)}
            onBlur={() => !readOnly && setIsFocused(false)}
            checked={valueModified ? true : false}
            {...attributes}
            error={error}
          />
        )}
        {!["date", "textarea", "checkbox"].includes(type) && (
          <styles.Input
            width={width}
            readOnly={readOnly}
            disabled={readOnly}
            placeholder={placeholder}
            type={typeModified}
            onChange={onChange}
            name={name}
            value={valueModified}
            autoComplete={autoComplete}
            autoCapitalize='none'
            //isfocused
            onFocus={() => !readOnly && setIsFocused(true)}
            onBlur={() => !readOnly && setIsFocused(false)}
            {...attributes}
            error={error}
            accept='.gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml'
          />
        )}
        <styles.Label
          type={type}
          className={
            valueModified || isFocused || type === "file" || type === "date" ? "focused" : ""
          }
        >
          {label}
        </styles.Label>
        {/* </styles.container> */}
      </styles.Wrapper>
      <styles.errorText>{error ? error : <br />}</styles.errorText>
      {message && (
        <styles.errorText type={"document"} {...attributes} onClick={onMessageClick}>
          {message ?? "View Document"}
        </styles.errorText>
      )}
    </styles.ParentWrapper>

    // <styles.maincontainer>
    //   <styles.labelContainer>
    //     <styles.label>
    //       {label}
    //       {mandatory ? "*" : ""}
    //     </styles.label>
    //   </styles.labelContainer>
    //   <styles.container isFocused={isFocused}>
    //   <styles.input
    //     readOnly={readOnly}
    //     placeholder={placeholder}
    //     type={typeModified}
    //     onChange={onChange}
    //     name={name}
    //     value={valueModified}
    //     autoComplete={autoComplete}
    //     autoCapitalize="none"
    //     //isfocused
    //     onFocus={() => setIsFocused(true)}
    //     onBlur={() => setIsFocused(false)}
    //     checked={valueModified ? true : false}
    //     {...attributes}
    //   />
    // </styles.container>
    //   <styles.errorText>{error ? error : <br />}</styles.errorText>
    //   {message ? (
    //     <a
    //       href=""
    //       onClick={(e) => {
    //         e.preventDefault();
    //         onMessageClick();
    //       }}
    //     >
    //       View Document
    //     </a>
    //   ) : (
    //     "  "
    //   )}
    // </styles.maincontainer>
  );
};

export default Input;

// import React from "react";
// import styles from "./input.styles";

// const FloatingInput = () => (
//   <styles.Wrapper>
//     <styles.Input />
//     <styles.Label>First Name</styles.Label>
//   </styles.Wrapper>
// );

// export default FloatingInput;
