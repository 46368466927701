import React from "react";

import { Layout } from "../../../presentationals";
import { AttributesList as AttributesListScreen } from "../../../presentationals/screens";
import { strings } from "../../../theme";

export const AttributesList = () => {
  return (
    <Layout
      activity={<AttributesListScreen />}
      admin={true}
      layout={"2"}
      title={strings.headerTitle.categories}
    />
  );
};
