import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteKanbanConfig,
  fetchKanbanConfigList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateKanbanConfig,
  updateTableConfigs,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  KanbanConfigList,
  KanbanConfigListWithMetaData,
  FetchKanbanConfigParams,
  DeleteKanbanConfigPayload,
} from "../../../../../redux/KanbanConfig/types";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchKanbanConfigProps,
  sortType,
  useKanbanConfigListProps,
  useKanbanConfigListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  collections,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getKanbanConfigColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.kanbanConfig;
const defaultFilters: QueryBuilderType[] = [];

export const useKanbanConfigList = ({
  relatedList,
  widgetId,
}: useKanbanConfigListProps): useKanbanConfigListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.KanbanConfigReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const kanbanConfig = useAppSelector((state) => state.KanbanConfigReducer.data.list);
  const { usersOptions, dashboardTabsOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const kanbanConfigFields = useMemo(() => {
    return tableConfiguration?.kanbanConfig?.fields?.length
      ? tableConfiguration?.kanbanConfig?.fields
      : tableListDefaultSelectFields.kanbanConfig;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = kanbanConfigFields;
    return getKanbanConfigColumns({ fields });
  }, [kanbanConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: KanbanConfigList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.kanbanConfig?.fields || tableListDefaultSelectFields.kanbanConfig;
      records = records?.map((item) => {
        const kanbanConfigItem = { ...item };
        if (userPersonalizedFields?.includes("table")) {
          kanbanConfigItem.table = item?.table;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          kanbanConfigItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          kanbanConfigItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          kanbanConfigItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          kanbanConfigItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete kanbanConfigItem?._id;
        return kanbanConfigItem;
      });
      const kanbanConfigFields = tablePublicFields.kanbanConfig;
      const columnHeaders = kanbanConfigFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "dashboard_widgets");
    },
    [tableConfiguration?.kanbanConfig?.fields],
  );

  // ================ Fetch Widgets Details Success/Error Methods ================
  const kanbanConfigFetchSuccess = useCallback(
    (data: KanbanConfigListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        exportRecords(data.list);
      }
    },
    [exportRecords],
  );
  const kanbanConfigFetchError = useCallback(
    (error: string) => {
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchKanbanConfig = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchKanbanConfigProps) => {
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }

      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.kanbanConfig?.limit || 10;

      const params: FetchKanbanConfigParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["title"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: KanbanConfigListWithMetaData) =>
          kanbanConfigFetchSuccess(data, exportAll),
        onError: kanbanConfigFetchError,
      };
      dispatch(fetchKanbanConfigList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.kanbanConfig?.limit,
      sortKey,
      sortType,
      relatedList?.type,
      relatedList?.value,
      kanbanConfigFetchError,
      dispatch,
      widgetsData,
      kanbanConfigFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = kanbanConfig?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, kanbanConfig, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchKanbanConfig({ exportAll: true });
  }, [fetchKanbanConfig]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchKanbanConfig({ page });
  }, [fetchKanbanConfig, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchKanbanConfig({ page: parseInt(page), search });
    },
    [fetchKanbanConfig, search],
  );

  // ================ Add/Edit Methods ================

  const onClickKanbanConfig = useCallback(
    (id: string) => {
      if (permissions?.dashboardWidget_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.dashboardWidget_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchKanbanConfig({ page: 1, search });
  }, [fetchKanbanConfig, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchKanbanConfig({ page: 1 });
  }, [fetchKanbanConfig]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchKanbanConfig({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchKanbanConfig],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchKanbanConfig({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchKanbanConfig],
  );

  // ===================Delete Method ======================
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = kanbanConfig?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteKanbanConfigPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchKanbanConfig({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteKanbanConfig(payload));
  }, [selectedRows, dispatch, notificationsId, kanbanConfig, fetchKanbanConfig]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = kanbanConfig?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, kanbanConfig]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            kanbanConfig?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, kanbanConfig, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      setLoading(true);
      const kanbanConfigConfigLimit = tableConfiguration?.kanbanConfig?.limit || 10;
      const kanbanConfigConfigs = {
        table: "kanbanConfig",
        fields: fields,
        limit: kanbanConfigConfigLimit,
      };
      const payload = {
        tableConfiguration: kanbanConfigConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchKanbanConfig({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Kanban Config table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.kanbanConfig?.limit, dispatch, fetchKanbanConfig, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const kanbanConfigConfigFields = tableConfiguration?.kanbanConfig?.fields;
      setLoading(true);
      const kanbanConfigConfigs = {
        table: "kanbanConfig",
        fields: kanbanConfigConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: kanbanConfigConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchKanbanConfig({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Kanban Config table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchKanbanConfig],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = kanbanConfig?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchKanbanConfig({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Kanban Config updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating Configs",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateKanbanConfig(payload));
    },
    [selectedRows, kanbanConfig, dispatch, notificationsId, fetchKanbanConfig],
  );
  // ================ Table Header Buttons ================

  const kanbanConfigHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (table && "dashboardSupported" in table && table.dashboardSupported) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null; // or undefined, depending on your preference
      })
      .filter(Boolean); // to remove any null or undefined entries
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "title",
      label: "Title",
      type: "text",
      required: true,
    },
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const kanbanConfigFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");
    const dashboardTabsFilterOptions = getFilterOptions(dashboardTabsOptions, "Dashboard Tabs");
    const tableListOptions = getFilterOptions(TableList, "Tables");
    const fields: Field[] = [
      {
        name: "title",
        label: "Title",
        inputType: "string",
        validator,
      },
      {
        name: "table",
        label: "Table",
        values: tableListOptions,
        defaultValue: tableListOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "type",
        label: "Type",
        inputType: "string",
        validator,
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "dashboardTab",
        label: "Dashboard Tab",
        inputType: "select",
        values: dashboardTabsFilterOptions,
        defaultValue: dashboardTabsFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions, dashboardTabsOptions, TableList]);

  return {
    listActionOptions,
    kanbanConfigFilterFields,
    onClickKanbanConfig,
    fetchKanbanConfig,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    kanbanConfigHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
    columns,
    kanbanConfigFields,
  };
};
