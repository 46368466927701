import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { AddressHistoryControllerProps } from "../../../../types";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useAddressHistoryController } from "./hooks";

export const AddressHistoryController = ({ type }: AddressHistoryControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions, employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAddressHistory,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useAddressHistoryController({ type, id: params.id, attachmentsPickerRef });
  const currentAddressHistory = data;

  const {
    workLogs,
    attachments,
    associatedEmployee,
    country,
    state,
    addressLineOne,
    addressLineTwo,
    city,
    postalCode,
    fromDate,
    toDate,
    currentlyLives,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAddressHistory();
    }
    // Do not include fetchAddressHistory to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
          <Select
            width={"48%"}
            name={"currentlyLives"}
            label={"Currently Lives"}
            options={ActiveSelectOptions}
            value={formData.currentlyLives.value}
            onChange={onTextChange}
            mandatory={formData.currentlyLives.required}
            error={formData.currentlyLives.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Address Line 1"}
            type={"text"}
            width='48%'
            error={addressLineOne.error}
            inputProps={{
              name: "addressLineOne",
              value: addressLineOne.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineOne.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Address Line 2"}
            type={"text"}
            width='48%'
            error={addressLineTwo.error}
            inputProps={{
              name: "addressLineTwo",
              value: addressLineTwo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineTwo.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label={"Postal Zone/ZIP Code"}
          type={"text"}
          width='48%'
          error={postalCode.error}
          inputProps={{
            name: "postalCode",
            value: postalCode.value,
            autoComplete: "off",
            type: "number",
            onChange: onTextChange,
            required: postalCode.required,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label={"City"}
          type={"text"}
          width='48%'
          error={city.error}
          inputProps={{
            name: "city",
            value: city.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: city.required,
          }}
          textAreaProps={{}}
        />
        <Row>
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
        </Row>
        <Row>
          <TextInput
            label='From Date'
            type={"date"}
            width='48%'
            error={fromDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "fromDate",
              value: fromDate.value,
              required: fromDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='To Date'
            type={"date"}
            disabled={currentlyLives.value}
            width='48%'
            error={toDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "toDate",
              value: toDate.value,
              minDate: fromDate.value ? new Date(fromDate.value) : undefined,
              required: toDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    employeesOptions,
    associatedEmployee.value,
    associatedEmployee.required,
    associatedEmployee.error,
    onTextChange,
    formData.currentlyLives.value,
    formData.currentlyLives.required,
    formData.currentlyLives.error,
    addressLineOne.error,
    addressLineOne.value,
    addressLineOne.required,
    addressLineTwo.error,
    addressLineTwo.value,
    addressLineTwo.required,
    postalCode.error,
    postalCode.value,
    postalCode.required,
    city.error,
    city.value,
    city.required,
    state.error,
    state.value,
    state.required,
    countryOptions,
    country.value,
    country.required,
    country.error,
    fromDate.error,
    fromDate.value,
    fromDate.required,
    onDateChange,
    currentlyLives.value,
    toDate.error,
    toDate.value,
    toDate.required,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentAddressHistory?.createdBy !== "string"
        ? currentAddressHistory?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAddressHistory?.updatedBy !== "string"
        ? currentAddressHistory?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAddressHistory?.createdAt}
        updatedAt={currentAddressHistory?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAddressHistory?.createdAt,
    currentAddressHistory?.createdBy,
    currentAddressHistory?.updatedAt,
    currentAddressHistory?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        collection={collections.certificationsAccreditations.name}
        recordId={params.id}
      />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.addressHistory.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderRelatedEmployees;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderRelatedEmployees,
    renderOverview,
    renderActivitylogs,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
