import styled from "styled-components";

import { colors, fs } from "../../../../theme";

export const FormChecklistContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const ChecklistModalContainer = styled.div`
  display: flex;
  padding: 30px;
  width: 30vw;
  flex-direction: column;
  background-color: ${colors.common.white};
  .checklist-modal-save-button {
    width: fit-content;
  }
`;

export const ChecklistCheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

export const CheckboxLabel = styled.label`
  pointer-events: none;
  font-style: normal;
  font-weight: 400;
  margin-right: 10px;
  font-size: ${fs.m};
  line-height: 20px;
  display: flex;
  color: ${colors.input.placeholder};
`;

export const ChecklistItemContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  height: 40vh;
  /* background: blue; */
  overflow-y: auto;
  .table-configs-checkbox {
    margin-top: 0;
  }
  .table-configs-checkbox-wrapper {
    padding: 0;
    margin-top: 6px;
    height: fit-content;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .table-configs-checkbox-label {
    position: relative;
    margin: 0 10px;
    top: 0;
  }
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const ChecklistFieldsContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 20vh;
  z-index: 10;
`;
