import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    height: 100%;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  TabContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 20px;
  `,

  TabItem: styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 7px;
    /* margin: 2px; */
    background-color: ${(props) => (props.active ? "#6c7ae0" : colors.secondary)};
    font-family: ${fonts.font1};
    font-size: 1.2rem;
    color: ${(props) => (props.active ? "white" : colors.secondary_text)};
    cursor: ${(props) => (props.active ? "" : "pointer")};
    border-bottom: ${(props) => `2px solid ${props.error ? colors.error : "transparent"}`};
    border-radius: 4px;
  `,

  Row: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1% 4%;
  `,
  Column: styled.div`
    flex: 1;
    font-size: 1.2rem;
  `,
  Title: styled.h1`
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    background-color: #6d8df7;
    padding: 6px;
    margin: 1vh 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Feedback: styled.div`
    font-size: 1.2rem;
    font-weight: 400;
    max-height: 30%;
    display: inline-block;
    width: 0px;
    min-width: 92%;
    border: solid 1px #48b5e1;
    border-radius: 4px;
    white-space: pre-line;
    word-wrap: break-word;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    padding: 6px 4%;
  `,

  AttachmentIconContainer: styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    height: 6vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    border-radius: 4px;
    cursor: pointer;
  `,
  AttachmentsContainer: styled.div`
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1vh 1vw;
    margin-bottom: 1vh;
  `,
};

export default styles;
