import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getSPSProjectsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    projectName: {
      title: "Project Name",
      sortSupported: true,
      searchSupported: true,
      key: "projectName",
    },
    projectType: {
      title: "Project Type",
      sortSupported: true,
      key: "projectType",
      render: (item) => {
        return <text className='title'>{item.projectType?.value || "-"}</text>;
      },
    },
    projectCode: { title: "Project Code", sortSupported: true, key: "projectCode" },
    associatedDeal: {
      title: "Deal",
      key: "associatedDeal",
      render: (item) => {
        return <text className='title'>{item.associatedDeal?.legalAccountName || "-"}</text>;
      },
    },
    platform: {
      title: "Platform",
      key: "platform",
      render: (item) => {
        return <text className='title'>{item.platform?.value}</text>;
      },
    },
    legalName: { title: "Legal Name", sortSupported: true, key: "legalName" },
    legalNameCode: { title: "Legal Name Code", sortSupported: true, key: "legalNameCode" },
    projectGivenTo: {
      title: "Project Given To",
      key: "projectGivenTo",
      render: (item) => {
        return <text className='title'>{item?.projectGivenTo?.name || "-"}</text>;
      },
    },
    rate: { title: "Rate", key: "rate", sortSupported: true },
    billingCycle: {
      title: "Billing Cycle",
      key: "billingCycle",
      render: (item) => {
        return <text className='title'>{item?.billingCycle?.value || "-"}</text>;
      },
    },
    startDate: {
      title: "Start Date",
      key: "startDate",
      sortSupported: true,
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.startDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    expetectedLength: { title: "Expected Length", key: "expetectedLength", sortSupported: true },
    expetectedEndDate: {
      title: "Expected End Date",
      key: "expetectedEndDate",
      sortSupported: true,
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.expetectedEndDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    projectStatus: {
      title: "Status",
      key: "projectStatus",
      render: (item) => {
        return <text className='title'>{item?.projectStatus?.value || "-"}</text>;
      },
    },
    projectDescription: {
      title: "Project Description",
      sortSupported: true,
      searchSupported: true,
      key: "projectDescription",
    },
    terminationReason: {
      title: "Termination Reason",
      sortSupported: true,
      searchSupported: true,
      key: "terminationReason",
    },
    haltStartDate: {
      title: "Halt Start Date",
      sortSupported: true,
      key: "haltStartDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.haltStartDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    haltEndDate: {
      title: "Halt End Date",
      sortSupported: true,
      key: "haltEndDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.haltEndDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    haltReason: {
      title: "Halt Reason",
      sortSupported: true,
      searchSupported: true,
      key: "haltReason",
    },
    projectRole: {
      title: "Project Role",
      sortSupported: true,
      searchSupported: true,
      key: "projectRole",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
