/* eslint-disable no-unused-vars */
enum Types {
  FETCH_PROJECT_HISTORY = "FETCH_PROJECT_HISTORY",
  FETCH_PROJECT_HISTORY_BY_ID = "FETCH_PROJECT_HISTORY_BY_ID",
  SUCCESS_FETCH_PROJECT_HISTORY = "SUCCESS_FETCH_PROJECT_HISTORY",
  ERROR_FETCH_PROJECT_HISTORY = "ERROR_FETCH_PROJECT_HISTORY",
  CREATE_PROJECT_HISTORY = "CREATE_PROJECT_HISTORY",
  UPDATE_PROJECT_HISTORY = "UPDATE_PROJECT_HISTORY",
}

export default Types;
