import React, { useEffect, useRef, useState } from "react";

// additional imports
import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import SunEditor, { buttonList } from "suneditor-react";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import MetaData from "../../components/MetaData";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { uploadFile, getFile } from "../../redux/application/actions";
import { createMedia, updateMedia, fetchAllMedia } from "../../redux/media/actions";
import { formValidationMethod, MomentDate, useTitle } from "../../services/methods";

import styles from "./styles";
import "suneditor/dist/css/suneditor.min.css";

const MediaController = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const readOnly = ["edit", "add"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState("");
  const [localImage, setLocalImage] = useState("");
  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const defaultData = {
    name: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    url: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    image: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    active: {
      required: false,
      type: "",
      error: "",
      value: false,
    },
    createdBy: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    updatedBy: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    createdAt: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    updatedAt: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
  };

  useEffect(() => {
    if (props.type === "view" || props.type === "edit") {
      if (id !== params.id || viewType.current !== props.type) {
        viewType.current = props.type;
        setFormData(defaultData);
        setLoader(true);
        setLocalImage("");
        if (props.media.length > 0) {
          const find = props.media.find((item) => item._id === params.id);
          if (find) {
            updateValues(find);
            setLoader(false);
            setSubmitting(false);
            setId(find._id);
            setToast({
              message: "",
              message_type: "",
            });
          }
        }
      }
    } else if (props.type === "add") {
      viewType.current = props.type;
      setLoader(false);
      setSubmitting(false);
      setFormData(defaultData);
      setLocalImage("");
      setId("");
      setToast({
        message: "",
        message_type: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.media]);

  const [formData, setFormData] = useState(defaultData);

  const updateValues = (data) => {
    const object = { ...formData };
    Object.keys(data).forEach((element) => {
      const name = element;
      const value = data[element];
      if (formData[element] && value !== undefined && value !== null) {
        object[name] = {
          ...object[name],
          value: value,
        };
      }
    });
    setFormData(object);
    if (object.image?.value?.location) {
      setLocalImage(object.image.value?.location);
    }
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const error = formValidationMethod(name, value, formData);

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Media";
      case "add":
        return "Add Media";
      case "edit":
        return "Edit Media";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const onSubmitAddForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      setSubmitting(true);
      props.createMedia({
        data: generateData,
        onSuccess: (data) => {
          props.fetchAllMedia({
            onSuccess: () => {
              navigate("/ap/media");
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);

    return output;
  };

  const onSubmitUpdateForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    generateData["image"] = generateData["image"]._id;
    if (submit) {
      setSubmitting(true);
      props.updateMedia({
        id: id,
        data: generateData,
        onSuccess: (data) => {
          props.fetchAllMedia({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 || submitting ? true : false;
    return value;
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      type: "public",
      onSuccess: (data) => {
        setLocalImage(data.location);
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            value: data.id,
            error: ``,
          },
        });
      },
      onError: (err) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        {/* <Link to={"/ap/media"}>
          <MdArrowBack size={"30"} />
        </Link> */}
        <h2>{getTitle()}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form
        onSubmit={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        autoComplete='off'
      >
        <Input
          readOnly={readOnly}
          label={"Name"}
          type={"text"}
          name='name'
          value={formData.name.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.name?.error}
        />
        <Input
          readOnly={readOnly}
          label={"URL"}
          type={"text"}
          name='url'
          value={formData.url.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.name?.error}
        />
        {localImage && (
          <styles.MediaImageContainer>
            <a target='_blank' href={localImage} rel='noreferrer'>
              <styles.MediaImage src={localImage} />
            </a>
          </styles.MediaImageContainer>
        )}
        <Input
          readOnly={readOnly}
          label={"Attach Image"}
          type={"file"}
          name='image'
          message={false}
          autoComplete={"off"}
          onChange={onFileChange}
          mandatory={true}
          error={formData?.image?.error}
        />

        {props.type !== "add" && (
          <Select
            name={"active"}
            label={"Media Enabled ?"}
            options={[
              { label: "Enabled", value: true },
              { label: "Disabled", value: false },
            ]}
            value={formData.active.value}
            onChange={onTextChange}
          />
        )}

        {!readOnly && (
          <Button
            onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
            disabled={SubmitDisabled()}
            size={"normal"}
            label={"Save"}
          />
        )}
      </form>
      <br />
      <br />
      <br />

      {formData.createdAt.value && (
        <MetaData
          title='Created On'
          value={MomentDate({
            date: formData.createdAt.value,
            format: "YYYY-MM-DD",
          })}
        />
      )}
      {formData.createdBy.value && (
        <MetaData title={"Created By"} value={formData.createdBy.value.name} />
      )}
      {formData.updatedAt.value && formData.createdAt.value !== formData.updatedAt.value && (
        <>
          <MetaData
            title={"Updated On"}
            value={MomentDate({
              date: formData.updatedAt.value,
              format: "YYYY-MM-DD",
            })}
          />
          <MetaData title={"Updated By"} value={formData.updatedBy.value.name} />
        </>
      )}
      <br />
      <br />
      <br />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  media: state.MediaReducer.list,
});

const mapDispatchToProps = {
  createMedia,
  updateMedia,
  fetchAllMedia,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaController);
