import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { colors, images, strings } from "../../../../theme";
import { TableV2Props } from "../../../../types";
import { useCommonActions } from "../../../common";
import { MyTooltip } from "../../atoms";
import Search from "../../Search";

import { useTableV3 } from "./hooks/useTableV3";
import {
  TableContainer,
  TableHead,
  TableDataRow,
  Title,
  TableData,
  EmptyList,
  TableDataRowContainer,
  TableOuterContainer,
  TableWrapper,
  TableHeadRow,
  TableBottomEmptyContainer,
  SelectBox,
  TableHeadTitleContainer,
  TableHeadActionsContainer,
  SortIcon,
  SortPopupContainer,
  SortPopupItem,
} from "./styles";

const { noRecordsFound } = strings.common;

export const TableV3 = ({
  data,
  dashboardWidget,
  selectedRows,
  columns,
  page = 1,
  pageSize = 10,
  onClickTableRow,
  onClickRowSelection,
  allRowsSelected,
  emptyListMessage = noRecordsFound,
  horizontalScroll = false,
  handleSortList,
  metaData,
  onSearch,
  disableSelect,
  searchPlaceholder,
  clearSearch,
  searchValue,
  onSearchChange,
  primaryTable = true,
  advancedList = true,
  isRelatedList,
}: TableV2Props) => {
  const {
    tableColumns,
    parentRef,
    componentRef,
    hoverRow,
    sortColumn,
    handleSortPopup,
    closeSortPopup,
    handleSortItem,
    onClickRow,
    handleHoverEnterHeader,
    handleHoverLeaveHeader,
    hanldeSelectRow,
    headHovered,
    searchbarVisible,
    closePopups,
    handleSearchBar,
    searchValues,
    handleSearch,
    onSearchKeyDown,
  } = useTableV3({
    columns,
    handleSortList,
    onClickTableRow,
    data,
    advancedList,
    onSearch,
    onClickRowSelection,
    dashboardWidget,
    searchValue,
  });
  const { handlePopupWrapperClick } = useCommonActions();

  const renderDataRow = useCallback(
    ({ column, columnIndex, dataItem, serial, dataIndex }) => {
      const title = dataItem[column.key];
      const primaryColumn = columnIndex === 0;
      const titleClasses = primaryColumn ? "data-title clickable-row-border" : "data-title";
      const isRowSelected = selectedRows?.includes(dataItem?._id);
      const isRowSelectBoxVisible = isRowSelected || hoverRow === dataIndex;
      if (column?.render) {
        if (primaryColumn) {
          return (
            <React.Fragment>
              {!dashboardWidget && !isRelatedList && columnIndex === 0 && !disableSelect && (
                <SelectBox
                  data-id={dataItem?._id}
                  onClick={onClickRowSelection}
                  selected={isRowSelected}
                  visible={isRowSelectBoxVisible}
                />
              )}
              {primaryTable ? (
                <MyTooltip text='Open Record'>
                  <div
                    onClick={onClickRow}
                    data-index={dataIndex}
                    data-column={columnIndex}
                    data-id={dataItem?._id}
                    className={titleClasses}
                  >
                    {column.render(dataItem)}
                  </div>
                </MyTooltip>
              ) : (
                <div
                  onClick={onClickRow}
                  data-index={dataIndex}
                  data-column={columnIndex}
                  data-id={dataItem?._id}
                  className={titleClasses}
                >
                  {column.render(dataItem)}
                </div>
              )}
            </React.Fragment>
          );
        }

        return column?.render(dataItem);
      }
      return (
        <>
          {primaryColumn && primaryTable ? (
            <React.Fragment>
              {!dashboardWidget && !isRelatedList && columnIndex === 0 && !disableSelect && (
                <SelectBox
                  data-id={dataItem?._id}
                  onClick={onClickRowSelection}
                  selected={isRowSelected}
                  visible={isRowSelectBoxVisible}
                />
              )}
              <MyTooltip text='Open Record'>
                <Title
                  data-column={columnIndex}
                  data-id={dataItem?._id}
                  onClick={onClickRow}
                  data-index={dataIndex}
                  index={columnIndex}
                  horizontalScroll={horizontalScroll}
                  width={column.width}
                  className={titleClasses}
                >
                  {title
                    ? typeof title === "number" || typeof title === "boolean"
                      ? String(title)
                      : typeof title === "string" && title.trim() !== ""
                      ? title
                      : typeof title === "object" &&
                        (title.name.trim() !== "" || title.values.trim() !== "")
                      ? title.name || title.values
                      : "-"
                    : "-"}
                </Title>
              </MyTooltip>
            </React.Fragment>
          ) : (
            <Title
              index={columnIndex}
              horizontalScroll={horizontalScroll}
              width={column.width}
              className={titleClasses}
            >
              {title
                ? typeof title === "number" || typeof title === "boolean"
                  ? String(title)
                  : typeof title === "string" && title.trim() !== ""
                  ? title
                  : typeof title === "object" &&
                    (title.name.trim() !== "" || title.values.trim() !== "")
                  ? title.name || title.values
                  : "-"
                : "-"}
            </Title>
          )}
        </>
      );
    },
    [
      selectedRows,
      hoverRow,
      primaryTable,
      dashboardWidget,
      isRelatedList,
      disableSelect,
      onClickRowSelection,
      onClickRow,
      horizontalScroll,
    ],
  );

  const renderEmptyRows = useCallback(
    (rowCount: number): React.JSX.Element[] => {
      const emptyRows = Array.from({ length: rowCount }, (_, i) => (
        <TableDataRowContainer key={`empty-${i}`}>
          {columns.map((column, columnIndex) => (
            <TableDataRow
              horizontalScroll={horizontalScroll}
              key={`empty-${i}-${columnIndex}`}
              width={column.width}
              center={column.key === "serial" ? true : column.center}
              background={column.background}
            >
              {/* You can render any content for the empty cells if needed */}
            </TableDataRow>
          ))}
        </TableDataRowContainer>
      ));
      return emptyRows;
    },
    [columns, horizontalScroll],
  );

  // Handling width of the table colums in case of only 2-3 columns are there.
  // Which can lead to empty space in the table UI
  useEffect(() => {
    const handleResize = () => {
      const parentScrollWidth = parentRef?.current?.scrollWidth;
      const parentClientWidth = parentRef?.current?.clientWidth;
      if (componentRef.current) {
        if (parentScrollWidth && parentClientWidth && parentScrollWidth > parentClientWidth) {
          componentRef.current.style.width = parentRef.current.scrollWidth + "px";
        } else {
          componentRef.current.style.width = "100%";
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [componentRef, parentRef]);

  useEffect(() => {
    const container = parentRef?.current;
    const hasVerticalScrollbar = container && container.scrollWidth > container.clientWidth;
    if (container) {
      container.style.borderBottom = hasVerticalScrollbar
        ? `0.3px solid ${colors.common.border}`
        : "none";
    }
  }, [parentRef]);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.type === "myCustomEvent") {
        if (event.detail.popup === false) {
          closePopups();
        }
      }
    };

    document.addEventListener("myCustomEvent", eventListener);

    return () => {
      document.removeEventListener("myCustomEvent", eventListener);
    };
  }, []);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.type === "tableListSearchBar") {
        handleSearchBar();
      }
    };

    document.addEventListener("tableListSearchBar", eventListener);

    return () => {
      document.removeEventListener("tableListSearchBar", eventListener);
    };
  }, []);
  useEffect(() => {}, [sortColumn]);

  return (
    <TableWrapper>
      <TableOuterContainer ref={parentRef}>
        <TableContainer ref={componentRef}>
          <TableHead horizontalScroll={horizontalScroll && advancedList}>
            {columns.map((column, columnIndex) => {
              const isRowSelectBoxVisible = allRowsSelected || headHovered;
              return (
                <TableHeadRow
                  horizontalScroll={horizontalScroll}
                  id='tableHeaderHeadRowItem'
                  onMouseEnter={handleHoverEnterHeader}
                  onMouseLeave={handleHoverLeaveHeader}
                  width={column.width}
                  key={columnIndex}
                  center={column.key === "serial" ? true : column.center}
                  background={column.background}
                >
                  {!dashboardWidget && !isRelatedList && columnIndex === 0 && !disableSelect && (
                    <SelectBox
                      data-index={columnIndex}
                      data-id={"all"}
                      onClick={onClickRowSelection}
                      selected={allRowsSelected}
                      visible={isRowSelectBoxVisible}
                    />
                  )}
                  <TableHeadActionsContainer>
                    <TableHeadTitleContainer index={columnIndex} onClick={handlePopupWrapperClick}>
                      <Title width={column.width} horizontalScroll={horizontalScroll}>
                        {column.title}
                      </Title>
                      {!dashboardWidget && column.sortSupported && (
                        <SortIcon
                          src={images.sortIcon}
                          onClick={handleSortPopup}
                          data-index={columnIndex}
                        />
                      )}
                    </TableHeadTitleContainer>
                    {column.searchSupported && searchbarVisible && (
                      <Search
                        containerClass={"table-list-search-container"}
                        inputClass='table-list-search-input'
                        inputProps={{
                          name: column.key,
                          placeholder: strings.common.search,
                          onChange: onSearchChange,
                          value: searchValue,
                          onKeyDown: onSearchKeyDown,
                        }}
                        clearSearch={clearSearch}
                        searchIconAttributes={{
                          "data-key": column.key,
                        }}
                      />
                    )}
                  </TableHeadActionsContainer>
                  {column.sortSupported && sortColumn === columnIndex && (
                    <SortPopupContainer onClick={handlePopupWrapperClick}>
                      <SortPopupItem
                        active={
                          metaData?.sortBy === column.key && metaData?.sortDirection === "asc"
                        }
                        onClick={handleSortItem}
                        data-sort={"asc"}
                      >
                        {strings.common.sortAsc}
                      </SortPopupItem>
                      <SortPopupItem
                        active={
                          metaData?.sortBy === column.key && metaData?.sortDirection === "desc"
                        }
                        onClick={handleSortItem}
                        data-sort={"desc"}
                      >
                        {strings.common.sortDesc}
                      </SortPopupItem>
                    </SortPopupContainer>
                  )}
                </TableHeadRow>
              );
            })}
          </TableHead>
          <TableData horizontalScroll={horizontalScroll && advancedList} emptyList={!data?.length}>
            {data?.length ? (
              data.map((dataItem, dataIndex) => {
                const serial = page === 1 ? dataIndex + 1 : (page - 1) * pageSize + dataIndex + 1;
                return (
                  <TableDataRowContainer
                    horizontalScroll={horizontalScroll}
                    key={dataIndex}
                    data-index={dataIndex}
                    selected={selectedRows?.includes(dataItem?._id)}
                  >
                    {columns.map((column, columnIndex) => {
                      const className = dataItem[column.className];
                      return (
                        <TableDataRow
                          horizontalScroll={horizontalScroll}
                          className={className}
                          id={`${dataIndex}`}
                          onMouseEnter={handleHoverEnterHeader}
                          onMouseLeave={handleHoverLeaveHeader}
                          width={column.width}
                          data-index={dataIndex}
                          key={columnIndex}
                          center={column.key === "serial" ? true : column.center}
                          background={column.background}
                          selected={selectedRows?.includes(dataItem?._id)}
                        >
                          {renderDataRow({ dataIndex, column, columnIndex, dataItem, serial })}
                        </TableDataRow>
                      );
                    })}
                  </TableDataRowContainer>
                );
              })
            ) : (
              <EmptyList>{emptyListMessage}</EmptyList>
            )}
            {/* {data?.length && renderEmptyRows(10 - (data?.length || 0))} */}
          </TableData>
          <TableBottomEmptyContainer />
        </TableContainer>
      </TableOuterContainer>
    </TableWrapper>
  );
};
