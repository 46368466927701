import React from "react";

import { Layout } from "../../../presentationals";
import { EmploymentHistoryController } from "../../../presentationals/screens";

export const APEmploymentHistoryEdit = () => {
  return (
    <Layout activity={<EmploymentHistoryController type={"edit"} />} admin={true} layout={"2"} />
  );
};
