import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { createFilter, updateFilter } from "../../../../../redux";
import { CreateFilterPayload, UpdateFilterPayload } from "../../../../../redux/filters/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";
import { SelectOptions } from "../../../../../types";
import { FiltersFormDataType } from "../../../../../types";
import {
  BooleanOperators,
  EmployeesWidgetFields,
  EmployeesWidgetFilters,
  StringOperators,
  UsersWidgetFilters,
} from "../../../../../utils/constants";

interface useMultiStepFormType {
  currentStepIndex: number;
  step: string;
  goTo: (e: MouseEvent<HTMLDivElement>) => void;
  next: () => void;
  back: () => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  formData: FiltersFormDataType;
  saveDisabled: boolean;
  onSubmitAdd: () => void;
  onSubmitUpdate: () => void;
  getTableFilters: SelectOptions[];
  loading: boolean;
  addFilter: () => void;
  removeFilter: (e: MouseEvent<HTMLDivElement>) => void;
  onChangeInputFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSelectFilter: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  getOperators: (index: number) => SelectOptions[] | boolean;
  getTitle: string[];
}

export const useController = (
  steps: string[],
  type: "add" | "edit" | undefined,
  id: string | undefined,
): useMultiStepFormType => {
  const list = useAppSelector((state) => state.FiltersReducer.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const defaultFormData: FiltersFormDataType = {
    title: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: true,
      required: true,
      error: "",
    },
    filters: {
      value: [],
      required: true,
      error: "",
    },
    table: {
      value: "",
      error: "",
      required: true,
    },
  };
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FiltersFormDataType>(defaultFormData);

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= steps.length - 1 ? i : i + 1));
  }, [steps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const tempFormData = {
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    };
    setFormData(tempFormData);
  };

  const saveDisabled = useMemo(() => {
    let isValidFilters = true;
    formData.filters.value.map((item) => {
      if (item.field || !item.operator) {
        console.log(item);
        isValidFilters = false;
      }
    });
    return !formData.title.value || !formData.description.value || isValidFilters;
  }, [formData]);

  const rehydrateFormData = useCallback(() => {
    const item = list.find((item) => item._id === id);
    let data = { ...formData };
    Object.keys(formData).map((field) => {
      const value = item?.[field];

      data = {
        ...data,
        [field]: {
          ...data[field],
          value: value,
        },
      };
    });

    setFormData(data);
  }, [id, list]);

  const onSubmitAdd = useCallback(() => {
    const { title, description, active, table, filters } = formData;
    setLoading(true);
    const payload: CreateFilterPayload = {
      data: {
        title: title.value,
        description: description.value,
        active: active.value,
        table: table.value,
        filters: filters.value,
      },
      onSuccess: () => {
        setLoading(false);
        navigate(-1);
      },
      onError: () => {
        setLoading(false);
      },
    };
    dispatch(createFilter(payload));
  }, [formData, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const { title, description, active, table, filters } = formData;
    setLoading(true);
    const payload: UpdateFilterPayload = {
      id: id || "",
      data: {
        title: title.value,
        description: description.value,
        active: active.value,
        table: table.value,
        filters: filters.value,
      },
      onSuccess: () => {
        setLoading(false);
        navigate(-1);
      },
      onError: () => {
        setLoading(false);
      },
    };
    dispatch(updateFilter(payload));
  }, [formData, dispatch, id, navigate]);

  const getTableFilters = useMemo(() => {
    switch (formData.table.value.toUpperCase()) {
      case "EMPLOYEES":
        return EmployeesWidgetFilters;
      case "USERS":
        return UsersWidgetFilters;
      default:
        return [];
    }
  }, [formData]);

  const addFilter = useCallback(() => {
    let tempFormData = { ...formData };
    if (tempFormData.filters.value.length < 3) {
      const tempFilters = [...tempFormData.filters.value];
      tempFilters.push({
        field: "",
        operator: "",
        value: "",
      });
      tempFormData = {
        ...tempFormData,
        filters: {
          ...tempFormData.filters,
          value: tempFilters,
        },
      };
    }
    setFormData(tempFormData);
  }, [formData]);

  const removeFilter = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const index = e.currentTarget.getAttribute("data-index");
      let tempFormData = { ...formData };
      const tempFilters = [...tempFormData.filters.value];
      tempFilters.splice(index, 1);
      tempFormData = {
        ...tempFormData,
        filters: {
          ...tempFormData.filters,
          value: tempFilters,
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const setFilters = useCallback(
    (index: number, name: string, value: string) => {
      let tempFormData = { ...formData };
      const tempFilters = [...tempFormData.filters.value];
      const item = {
        field: tempFilters[index].field,
        operator: tempFilters[index].operator,
        value: tempFilters[index].value,
      };
      switch (name) {
        case "field":
          item.field = value;
          break;
        case "operator":
          item.operator = value;
          break;
        case "value":
          item.value = value;
          break;
        default:
          break;
      }
      tempFilters.splice(index, 1, item);
      tempFormData = {
        ...tempFormData,
        filters: {
          ...tempFormData.filters,
          value: tempFilters,
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onChangeInputFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const index = e.target.getAttribute("data-index");
      const name = e.target.name;
      const value = e.target.value;
      setFilters(Number(index), name, value);
    },
    [setFilters],
  );

  const onChangeSelectFilter = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const index = e.target?.["data-index"];
      const name = e.target.name;
      const value = e.target.value;
      setFilters(Number(index), name, value);
    },
    [setFilters],
  );

  const getOperators = useCallback(
    (index) => {
      switch (formData.filters.value[index].field) {
        case "name":
        case "email":
          return StringOperators;
        case "active":
        case "employmentStatus":
        case "employeeType":
          return BooleanOperators;
        default:
          return [];
      }
    },
    [formData],
  );
  const getTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = list.find((item) => item._id === id)?.title || "";
    }
    return [strings.headerTitle.filters, title];
  }, [id, list, type]);
  useEffect(() => {
    if (type === "edit") {
      rehydrateFormData();
    }
  }, [id, type, rehydrateFormData]);

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    goTo,
    next,
    back,
    formData,
    onTextChange,
    saveDisabled,
    onSubmitAdd,
    onSubmitUpdate,
    loading,
    getTableFilters,
    addFilter,
    removeFilter,
    onChangeInputFilter,
    onChangeSelectFilter,
    getOperators,
    getTitle,
  };
};
