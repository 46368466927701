import React from "react";

import { Layout } from "../../../presentationals";
import { GroupsList } from "../../../presentationals/screens/admin/Groups/GroupsList";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<GroupsList />}
      // controller={<GroupsFilters />}
      admin={true}
      title={strings.headerTitle.groups}
      layout={"2"}
    />
  );
};

export const APGroupsList = React.memo(Route);
