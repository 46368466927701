import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { ActiveSelectOptions, collections } from "../../../../../utils/constants";
import { useControllerConfigs } from "../../../../common";
import { Row } from "../../../../common/styles";
import { ActivityLogs, FormChecklist, TableController, WorkLogs } from "../../../../components";
import { TextInput, Select, SystemData } from "../../../../components/atoms";
import { FormChecklistV2 } from "../../../../components/templates/FormChecklistV2/FormChecklistV2";
import { DashboardTabsList } from "../Tabs";

import { useDashboardAdministrationController } from "./hooks";

export const DashboardAdministrationController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { groupsOptions, usersOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchDashboardAdministration,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useDashboardAdministrationController({ type, id: params.id, attachmentsPickerRef });
  const currentDashboardAdministration = data;

  const { workLogs, attachments, title, description, active, groups, users } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchDashboardAdministration();
    }
    // Do not include fetchDashboardAdministration to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Title"}
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />{" "}
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Desctiption'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              rows: 14,
              required: description.required,
            }}
          />
        </Row>
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    onTextChange,
    title.error,
    title.required,
    title.value,
  ]);

  const renderAccess = useMemo(() => {
    return (
      <React.Fragment>
        <Select
          width={"100%"}
          name={"users"}
          label={"Users"}
          options={usersOptions}
          isMulti
          value={users.value}
          onChange={onTextChange}
          mandatory={users.required}
          error={users.error}
        />
        <Select
          width={"100%"}
          name={"groups"}
          label={"Groups"}
          options={groupsOptions}
          isMulti
          value={groups.value}
          onChange={onTextChange}
          mandatory={groups.required}
          error={groups.error}
        />
      </React.Fragment>
    );
  }, [
    groups.error,
    groups.required,
    groups.value,
    groupsOptions,
    onTextChange,
    users.error,
    users.required,
    users.value,
    usersOptions,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentDashboardAdministration?.createdBy !== "string"
        ? currentDashboardAdministration?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentDashboardAdministration?.updatedBy !== "string"
        ? currentDashboardAdministration?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentDashboardAdministration?.createdAt}
        updatedAt={currentDashboardAdministration?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentDashboardAdministration?.createdAt,
    currentDashboardAdministration?.createdBy,
    currentDashboardAdministration?.updatedAt,
    currentDashboardAdministration?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.dashboardAdministration.name} recordId={params.id} />
    ),

    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => (
      <ActivityLogs recordId={params.id} collection={collections.dashboardAdministration.name} />
    ),
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,

    [onPostWorklog, workLogs.value],
  );

  const renderRelatedTabs = useMemo(() => {
    return (
      <DashboardTabsList
        listType='dashboard'
        relatedList={{
          type: "dashboard",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderAccess;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      case 6:
        return renderRelatedTabs;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderRelatedTabs,
    renderAccess,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
