import Types from "./actionType";
import { AcademyCandidatesActions, AcademyCandidatesState } from "./types";

const initialState: AcademyCandidatesState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
};

export const AcademyCandidatesReducer = (
  state = initialState,
  action: AcademyCandidatesActions,
) => {
  switch (action.type) {
    case Types.SUCCESS_FETCH_ACADEMY_CANDIDATES:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
