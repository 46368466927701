import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { ServiceNowSolutionsSection } from "../../../../redux/ServiceNowSolutions/types";
import { getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { ColumnsType, TableHeaderButton } from "../../../../types";
import { ServiceNowSolutionsControllerProps } from "../../../../types/models/ServiceNowSolutions";
import {
  ActiveSelectOptions,
  PositionOptions,
  ServiceNowSelectOptions,
  collections,
} from "../../../../utils";
import { AttachmentIcon, Row, FormTitle, ControllerMessage } from "../../../common/styles";
import { ActivityLogs, Popup, TableController, TableListV2, WorkLogs } from "../../../components";
import {
  TextInput,
  Select,
  SystemData,
  TextEditor,
  MyTooltip,
  Button,
} from "../../../components/atoms";
import AttachmentInput from "../../../components/atoms/AttachmentInput/AttachmentInput";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useServiceNowSolutionsController } from "./hooks";
import { SectionModalContainer } from "./styles";

const Controller = ({ type }: ServiceNowSolutionsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchServiceNowSolutions,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    onDescriptionChange,
    deleteServiceNowSection,
    handleSectionsModal,
    sectionsModal,
    sectionsData,
    onSectionTextChange,
    sectionDescription,
    onSectionDescriptionChange,
    addServiceNowSection,
    updateServiceNowSection,
    onSaveAttachment,
    deleteAttachment,
    description,
  } = useServiceNowSolutionsController({
    type,
    id,
    attachmentsPickerRef,
  });
  const currentServiceNowSolutions = data;

  const {
    sectionName,
    order,
    anyPointers,
    sectionImage,
    pointers,
    demoTitle,
    demoVideo,
    resourceTitle,
    resourceLink,
    pointerLabel,
    resourceLabel,
    imageVideoPosition,
  } = sectionsData;
  const {
    workLogs,
    attachments,
    name,
    active,
    fullName,
    listImage,
    shortDescription,
    technology,
    sections,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchServiceNowSolutions();
    }
    // Do not include fetchServiceNowSolutions to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row onClick={() => console.log({ formData })}>
          <TextInput
            label='Listing Name'
            type={"text"}
            width='48%'
            error={name?.error}
            inputProps={{
              name: "name",
              value: name?.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name?.required,
            }}
          />
          <TextInput
            label='Full Name'
            type={"text"}
            width='48%'
            error={fullName?.error}
            inputProps={{
              name: "fullName",
              value: fullName?.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: fullName?.required,
            }}
          />
        </Row>
        <Row>
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active?.value}
            onChange={onTextChange}
            mandatory={active?.required}
            error={active?.error}
          />
          <AttachmentInput
            aclType={"public"}
            attachment={listImage}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='listImage'
            label='List Image'
            onSave={onSaveAttachment}
            accept='.png,.jpg.jpeg'
          />
        </Row>
        <Row>
          <Select
            name={"technology"}
            label={"Technology"}
            options={getAttributValues("technology")}
            value={technology?.value}
            onChange={onTextChange}
            mandatory={technology?.required}
            error={technology?.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Short Description'
            type={"textarea"}
            width='100%'
            error={shortDescription?.error}
            textAreaProps={{
              name: "shortDescription",
              value: shortDescription?.value,
              rows: 8,
              autoComplete: "off",
              onChange: onTextChange,
              required: shortDescription?.required,
            }}
          />
        </Row>
        <TextEditor
          error={""}
          value={description}
          onChange={onDescriptionChange}
          placeholder={"Description"}
        />
      </React.Fragment>
    );
  }, [
    active?.error,
    active?.required,
    active?.value,
    deleteAttachment,
    description,
    formData,
    fullName?.error,
    fullName?.required,
    fullName?.value,
    listImage,
    name?.error,
    name?.required,
    name?.value,
    onDescriptionChange,
    onSaveAttachment,
    onTextChange,
    shortDescription?.error,
    shortDescription?.required,
    shortDescription?.value,
    technology?.error,
    technology?.required,
    technology?.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentServiceNowSolutions?.createdBy !== "string"
        ? currentServiceNowSolutions?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentServiceNowSolutions?.updatedBy !== "string"
        ? currentServiceNowSolutions?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentServiceNowSolutions?.createdAt}
        updatedAt={currentServiceNowSolutions?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentServiceNowSolutions?.createdAt,
    currentServiceNowSolutions?.createdBy,
    currentServiceNowSolutions?.updatedAt,
    currentServiceNowSolutions?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.serviceNowSolutions.name} recordId={id} />,

    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.serviceNowSolutions.name} />,
    [id],
  );

  const renderSections = useMemo(() => {
    const columns: ColumnsType[] = [
      {
        title: "Type",
        key: "type",
        width: "100px",
        render: (item) => {
          const type = item.type || "-";
          const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
          return <text className='title'>{capitalizedType}</text>;
        },
      },
      {
        title: "Section Name",
        key: "sectionName",
        width: "200px",
        render: (item) => {
          return <text className='title'>{item.sectionName || "-"}</text>;
        },
      },
      {
        title: "Demo Title",
        key: "demoTitle",
        width: "200px",
        render: (item) => {
          return <text className='title'>{item.demoTitle || "-"}</text>;
        },
      },
      {
        title: "Resource Title",
        key: "resourceTitle",
        width: "200px",
        render: (item) => {
          return <text className='title'>{item.resourceTitle || "-"}</text>;
        },
      },
      {
        title: "Order",
        key: "order",
        width: "100px",
      },
      {
        title: "Actions",
        width: "100px",
        center: true,
        render: (item: ServiceNowSolutionsSection) => {
          return (
            <MyTooltip text='Delete'>
              <AttachmentIcon
                onClick={deleteServiceNowSection}
                data-index={item?._id}
                src={images.deleteAttachment}
              />
            </MyTooltip>
          );
        },
      },
    ];

    const buttons: TableHeaderButton[] = [
      {
        title: "Add",
        onClick: () => handleSectionsModal("add"),
        visible: true,
      },
    ];
    return (
      <>
        <TableListV2
          columns={columns}
          advancedList={false}
          headerButtons={buttons}
          loading={false}
          list={sections?.value}
          onClickTableRow={handleSectionsModal}
        />
        <Popup isOpen={!!sectionsModal} closeModal={handleSectionsModal}>
          <SectionModalContainer className='section-modal'>
            <FormTitle style={{ alignSelf: "left" }}>
              {sectionsModal === "add" ? "Add" : sectionsModal === "edit" ? "Edit" : ""}
            </FormTitle>
            <Row>
              <Select
                name={"type"}
                label={"Type"}
                width='48%'
                options={ServiceNowSelectOptions}
                value={sectionsData.type?.value}
                onChange={onSectionTextChange}
                mandatory={sectionsData.type?.required}
                error={sectionsData.type?.error}
              />
              <TextInput
                label='Order'
                type={"text"}
                width='48%'
                error={order?.error}
                inputProps={{
                  name: "order",
                  type: "number",
                  value: order?.value,
                  autoComplete: "off",
                  onChange: onSectionTextChange,
                  required: order?.required,
                }}
              />
            </Row>

            {/* Content Selection */}
            {sectionsData.type?.value === "content" && (
              <React.Fragment>
                <Row>
                  <TextInput
                    label='Section Name'
                    type={"text"}
                    width='48%'
                    error={sectionName?.error}
                    inputProps={{
                      name: "sectionName",
                      value: sectionName?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: sectionName?.required,
                    }}
                  />
                  <AttachmentInput
                    aclType={"public"}
                    attachment={sectionImage}
                    width='48%'
                    handleDeleteAttachment={deleteAttachment}
                    label='Section Image'
                    fieldName='sectionImage'
                    onSave={onSaveAttachment}
                    accept='.png,.jpg.jpeg'
                  />
                </Row>
                <Row>
                  <Select
                    width='48%'
                    name={"anyPointers"}
                    label={"Any Pointers"}
                    options={ActiveSelectOptions}
                    value={anyPointers?.value}
                    onChange={onSectionTextChange}
                    mandatory={anyPointers?.required}
                    error={anyPointers?.error}
                  />
                  <Select
                    width='48%'
                    name={"imageVideoPosition"}
                    label={"Image Position"}
                    options={PositionOptions}
                    value={imageVideoPosition?.value}
                    onChange={onSectionTextChange}
                    mandatory={imageVideoPosition?.required}
                    error={imageVideoPosition?.error}
                  />
                </Row>
                {/* Rendered Only when Pointer have to Add */}
                {anyPointers?.value && (
                  <React.Fragment>
                    <Row>
                      <TextInput
                        label='Pointer Title'
                        type={"text"}
                        width='48%'
                        error={pointerLabel?.error}
                        inputProps={{
                          name: "pointerLabel",
                          value: pointerLabel?.value,
                          autoComplete: "off",
                          onChange: onSectionTextChange,
                          required: pointerLabel?.required,
                        }}
                      />
                    </Row>
                    <ControllerMessage>
                      Add Numbers of Pointers By Comma (,) Seprated.
                    </ControllerMessage>
                    <Row>
                      <TextInput
                        label="Pointer's List"
                        type={"textarea"}
                        width='100%'
                        error={pointers?.error}
                        textAreaProps={{
                          name: "pointers",
                          value: pointers?.value,
                          rows: 6,
                          autoComplete: "off",
                          onChange: onSectionTextChange,
                          required: pointers?.required,
                        }}
                      />
                    </Row>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {/* Demo Selection */}
            {sectionsData.type?.value === "demo" && (
              <React.Fragment>
                <Row>
                  <TextInput
                    label='Demo Title'
                    type={"text"}
                    width='48%'
                    error={demoTitle?.error}
                    inputProps={{
                      name: "demoTitle",
                      value: demoTitle?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: demoTitle?.required,
                    }}
                  />
                  <TextInput
                    label='Demo Video'
                    type={"text"}
                    width='48%'
                    error={demoVideo?.error}
                    inputProps={{
                      name: "demoVideo",
                      value: demoVideo?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: demoVideo?.required,
                    }}
                  />
                </Row>
                <Row>
                  <Select
                    width='48%'
                    name={"imageVideoPosition"}
                    label={"Video Position"}
                    options={PositionOptions}
                    value={imageVideoPosition?.value}
                    onChange={onSectionTextChange}
                    mandatory={imageVideoPosition?.required}
                    error={imageVideoPosition?.error}
                  />
                </Row>
              </React.Fragment>
            )}
            {/* Resource Selection */}
            {sectionsData.type?.value === "resource" && (
              <React.Fragment>
                <Row>
                  <TextInput
                    label='Resource Title'
                    type={"text"}
                    width='48%'
                    error={resourceTitle?.error}
                    inputProps={{
                      name: "resourceTitle",
                      value: resourceTitle?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: resourceTitle?.required,
                    }}
                  />
                  <TextInput
                    label='Resource Link'
                    type={"text"}
                    width='48%'
                    error={resourceLink?.error}
                    inputProps={{
                      name: "resourceLink",
                      value: resourceLink?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: resourceLink?.required,
                    }}
                  />
                </Row>
                <Row>
                  <TextInput
                    label='Resource Label'
                    type={"text"}
                    width='48%'
                    error={resourceLabel?.error}
                    inputProps={{
                      name: "resourceLabel",
                      value: resourceLabel?.value,
                      autoComplete: "off",
                      onChange: onSectionTextChange,
                      required: resourceLabel?.required,
                    }}
                  />
                </Row>
              </React.Fragment>
            )}
            <TextEditor
              error={""}
              value={sectionDescription}
              onChange={onSectionDescriptionChange}
              placeholder={"Description"}
            />
            <div className='centerStyle'>
              <Button
                className='learning-point-modal-save-button'
                title={sectionsModal === "add" ? strings.common.add : strings.common.save}
                onClick={sectionsModal === "add" ? addServiceNowSection : updateServiceNowSection}
              />
            </div>
          </SectionModalContainer>
        </Popup>
      </>
    );
  }, [
    sections?.value,
    handleSectionsModal,
    sectionsModal,
    sectionsData.type?.value,
    sectionsData.type?.required,
    sectionsData.type?.error,
    onSectionTextChange,
    order?.error,
    order?.value,
    order?.required,
    sectionName?.error,
    sectionName?.value,
    sectionName?.required,
    sectionImage,
    deleteAttachment,
    onSaveAttachment,
    anyPointers?.value,
    anyPointers?.required,
    anyPointers?.error,
    imageVideoPosition?.value,
    imageVideoPosition?.required,
    imageVideoPosition?.error,
    pointerLabel?.error,
    pointerLabel?.value,
    pointerLabel?.required,
    pointers?.error,
    pointers?.value,
    pointers?.required,
    demoTitle?.error,
    demoTitle?.value,
    demoTitle?.required,
    demoVideo?.error,
    demoVideo?.value,
    demoVideo?.required,
    resourceTitle?.error,
    resourceTitle?.value,
    resourceTitle?.required,
    resourceLink?.error,
    resourceLink?.value,
    resourceLink?.required,
    resourceLabel?.error,
    resourceLabel?.value,
    resourceLabel?.required,
    sectionDescription,
    onSectionDescriptionChange,
    addServiceNowSection,
    updateServiceNowSection,
    deleteServiceNowSection,
  ]);

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs?.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs?.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderSections;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;

      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
    renderSections,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments?.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ServiceNowSolutionsController = memo(Controller);
