import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { SPSAccountsControllerProps } from "../../../../types/models/SPSAccounts";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSAccountContactList } from "../SPSAccountContact";
import { SPSBGCheckList } from "../SPSBGCheck";
import { SPSPaperworkList } from "../SPSPaperwork";

import { useSPSAccountsController } from "./hooks";
import { SPSAccountsList } from "./SPSAccountsList";

const Controller = ({ type }: SPSAccountsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsAccountsOptions, countryOptions, employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchSPSAccounts,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    resetSteps,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSAccountsController({ type, id, attachmentsPickerRef });
  const currentSPSAccounts = data;

  const {
    workLogs,
    attachments,
    name,
    status,
    associatedAccounts,
    industry,
    website,
    size,
    region,
    country,
    salesRepresentative,
    addressInfo,
    street,
    city,
    state,
    externalLinks,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSAccounts();
    }
    // Do not include fetchSPSAccounts to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    resetSteps();
  }, [resetSteps, id]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            width={"48%"}
            name={"type"}
            label={"Type"}
            options={getAttributValues("spsAccountType")}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("spsAccountStatus")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
          <TextInput
            label='Size'
            type={"text"}
            width='48%'
            error={size.error}
            inputProps={{
              name: "size",
              value: size.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: size.required,
            }}
          />
        </Row>
        <Select
          width={"100%"}
          name={"associatedAccounts"}
          label={"SPS Account"}
          isMulti
          options={spsAccountsOptions}
          value={associatedAccounts.value}
          onChange={onTextChange}
          mandatory={associatedAccounts.required}
          error={associatedAccounts.error}
        />
        <Row>
          <TextInput
            label='Industry'
            type={"text"}
            width='48%'
            error={industry.error}
            inputProps={{
              name: "industry",
              value: industry.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: industry.required,
            }}
          />
          <TextInput
            label='Website'
            type={"text"}
            width='48%'
            error={website.error}
            inputProps={{
              name: "website",
              value: website.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: website.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"salesRepresentative"}
            label={"Primary Sales Representative"}
            options={employeesOptions}
            value={salesRepresentative.value}
            onChange={onTextChange}
            mandatory={salesRepresentative.required}
            error={salesRepresentative.error}
          />
          <TextInput
            label='External Links'
            type={"text"}
            width='48%'
            error={externalLinks.error}
            inputProps={{
              name: "externalLinks",
              value: externalLinks.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: externalLinks.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Address Info'
            type={"text"}
            width='48%'
            error={addressInfo.error}
            inputProps={{
              name: "addressInfo",
              value: addressInfo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressInfo.required,
            }}
          />
          <TextInput
            label='Street'
            type={"text"}
            width='48%'
            error={street.error}
            inputProps={{
              name: "street",
              value: street.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: street.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Region'
            type={"text"}
            width='48%'
            error={region.error}
            inputProps={{
              name: "region",
              value: region.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: region.required,
            }}
          />
          <TextInput
            label='City'
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='State'
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
          />
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    name.error,
    name.value,
    name.required,
    onTextChange,
    formData.type.value,
    formData.type.required,
    formData.type.error,
    status.value,
    status.required,
    status.error,
    spsAccountsOptions,
    associatedAccounts.value,
    associatedAccounts.required,
    associatedAccounts.error,
    industry.error,
    industry.value,
    industry.required,
    website.error,
    website.value,
    website.required,
    size.error,
    size.value,
    size.required,
    region.error,
    region.value,
    region.required,
    employeesOptions,
    salesRepresentative.value,
    salesRepresentative.required,
    salesRepresentative.error,
    externalLinks.error,
    externalLinks.value,
    externalLinks.required,
    addressInfo.error,
    addressInfo.value,
    addressInfo.required,
    street.error,
    street.value,
    street.required,
    city.error,
    city.value,
    city.required,
    state.error,
    state.value,
    state.required,
    countryOptions,
    country.value,
    country.required,
    country.error,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSAccounts?.createdBy !== "string" ? currentSPSAccounts?.createdBy?.name : "";
    const updatedBy =
      typeof currentSPSAccounts?.updatedBy !== "string" ? currentSPSAccounts?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentSPSAccounts?.createdAt}
        updatedAt={currentSPSAccounts?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSAccounts?.createdAt,
    currentSPSAccounts?.createdBy,
    currentSPSAccounts?.updatedAt,
    currentSPSAccounts?.updatedBy,
  ]);
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsAccounts.name} />,
    [id],
  );

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2
        collection={collections.spsAccounts.name}
        recordId={id}
        // onSubmitAddChecklist={onSubmitAddChecklist}
        // onSubmitUpdateChecklist={onSubmitUpdateChecklist}
        // checklist={checklist}
        // deleteChecklist={deleteChecklist}
      />
    ),

    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  // Related Lists
  const renderSPSAccounts = useMemo(
    () => (
      <SPSAccountsList
        relatedList={{
          type: "selfAssociatedAccounts",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderSPSAccountContacts = useMemo(
    () => (
      <SPSAccountContactList
        relatedList={{
          type: "associatedAccount",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderSPSBGCheck = useMemo(
    () => (
      <SPSBGCheckList
        relatedList={{
          type: "associatedAccount",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderSPSPaperwork = useMemo(
    () => (
      <SPSPaperworkList
        relatedList={{
          type: "associatedAccount",
          value: id,
        }}
      />
    ),
    [id],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderSPSAccounts;
      case 6:
        return renderSPSAccountContacts;
      case 7:
        return renderSPSBGCheck;
      case 8:
        return renderSPSPaperwork;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    renderSPSAccounts,
    renderSPSAccountContacts,
    renderSPSBGCheck,
    renderSPSPaperwork,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSAccountsController = memo(Controller);
