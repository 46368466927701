import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  getToken,
  EmployeesList,
  EmployeesOpenList,
  EmployeesListBasic,
  EmployeesGet,
  EmployeesAdd,
  EmployeesGetComments,
  EmployeesDeleteComments,
  EmployeesAddComments,
  EmployeesUpdate,
  EmployeesAddDesignations,
  EmployeesUpdateDesignations,
  EmployeesAddPayrolls,
  EmployeesFetchPayrolls,
  EmployeesUpdatePayrolls,
  EmployeesFetchPayrollsSelf,
  EmployeesUpdatePayrollsSelf,
  getOrganisation,
  Birthdays,
  EmployeeById,
  EmployeesUpdateEmergencyContact,
  EmployeesAddEmergencyContact,
  EmployeesUpdateAppraisals,
  EmployeesAddAppraisals,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import {
  fetchEmployeesSuccess,
  fetchEmployeesError,
  fetchPayrollDataSuccess,
  fetchPayrollDataSelfSuccess,
  fetchEmployeesBirthdaysSuccess,
  fetchEmployeesOpenSuccess,
} from "./actions";
import Types from "./actionTypes";

function* fetchEmployeesBasicWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesListBasic(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: {
        organisation: action.payload.organisation,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* fetchEmployeesBirthdaysWorker(action) {
  try {
    const options = {
      method: "GET",
      url: Birthdays,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchEmployeesBirthdaysSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* fetchEmployeesWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: action?.payload?.params,
    };
    const response = yield call(axios, options);
    const data = response.data.data;
    if (response.data.success) {
      if (action.payload.employee) {
        yield put(fetchEmployeesOpenSuccess(response.data.data));
      } else {
        yield put(fetchEmployeesSuccess({ data }));
      }
      action?.payload?.onSuccess && action.payload.onSuccess(response.data?.data);
    } else {
      yield put(fetchEmployeesError(response.data.error));
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchEmployeesError(error));
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* getEmployeesWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesGet(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updateEmployeesWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdate(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addEmployeesWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAdd(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* getEmployeesCommentsWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesGetComments(action.payload.employeeId),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addEmployeesCommentsWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAddComments(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        employeeId: action.payload.employeeId,
        date: action.payload.date,
        rating: action.payload.rating,
        feedback: action.payload.feedback,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* deleteEmployeesCommentsWorker(action) {
  try {
    const options = {
      method: "DELETE",
      url: EmployeesDeleteComments(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        employeeId: action.payload.employeeId,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addEmployeesDesignationsWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAddDesignations(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updateEmployeesDesignationsWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdateDesignations(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addEmployeesAppraisalsWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAddAppraisals(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updateEmployeesAppraisalsWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdateAppraisals(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addEmployeesEmergencyContactWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAddEmergencyContact(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updateEmployeesEmergencyContactWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdateEmergencyContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* fetchPayrollWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesFetchPayrolls(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchPayrollDataSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* fetchPayrollSelfWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeesFetchPayrollsSelf(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchPayrollDataSelfSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* addPayrollWorker(action) {
  try {
    const options = {
      method: "POST",
      url: EmployeesAddPayrolls(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: {
        date: action.payload.date,
        status: action.payload.status,
        list: action.payload.list,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updatePayrollWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdatePayrolls(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* updatePayrollSelfWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: EmployeesUpdatePayrollsSelf(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

function* fetchEmployeeByIdWorker(action) {
  try {
    const options = {
      method: "GET",
      url: EmployeeById(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params: action?.payload?.params,
    };
    const response = yield call(axios, options);
    // const data = response.data.data;
    if (response.data.success) {
      action?.payload?.onSuccess &&
        action.payload.onSuccess(response.data.data, response.data?.relatedUser);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "");
    }
  }
}

export default function* EmployeesSaga() {
  yield takeLatest(Types.EMPLOYEES_FETCH_BASIC, fetchEmployeesBasicWorker);
  yield takeLatest(Types.EMPLOYEES_FETCH, fetchEmployeesWorker);
  yield takeLatest(Types.EMPLOYEE_DETAILS_BY_ID, fetchEmployeeByIdWorker);
  yield takeLatest(Types.EMPLOYEES_GET, getEmployeesWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE, updateEmployeesWorker);
  yield takeLatest(Types.EMPLOYEES_ADD, addEmployeesWorker);

  // comments
  yield takeLatest(Types.EMPLOYEES_GET_COMMENTS, getEmployeesCommentsWorker);
  yield takeLatest(Types.EMPLOYEES_ADD_COMMENTS, addEmployeesCommentsWorker);
  yield takeLatest(Types.EMPLOYEES_DELETE_COMMENTS, deleteEmployeesCommentsWorker);

  // Designations
  yield takeLatest(Types.EMPLOYEES_ADD_DESIGNATIONS, addEmployeesDesignationsWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE_DESIGNATIONS, updateEmployeesDesignationsWorker);

  // Appraisals
  yield takeLatest(Types.EMPLOYEES_ADD_APPRAISAL, addEmployeesAppraisalsWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE_APPRAISAL, updateEmployeesAppraisalsWorker);

  // Emergency Contact
  yield takeLatest(Types.EMPLOYEES_ADD_EMERGENCY_CONTACT, addEmployeesEmergencyContactWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE_EMERGENCY_CONTACT, updateEmployeesEmergencyContactWorker);

  // Payrolls
  yield takeLatest(Types.EMPLOYEES_FETCH_PAYROLLDATA, fetchPayrollWorker);
  yield takeLatest(Types.FETCH_EMPLOYEES_BIRTHDAYS, fetchEmployeesBirthdaysWorker);
  yield takeLatest(Types.EMPLOYEES_FETCH_PAYROLLDATA_SELF, fetchPayrollSelfWorker);
  yield takeLatest(Types.EMPLOYEES_ADD_PAYROLLDATA, addPayrollWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE_PAYROLLDATA, updatePayrollWorker);
  yield takeLatest(Types.EMPLOYEES_UPDATE_PAYROLLDATA_SELF, updatePayrollSelfWorker);
}
