import React from "react";

import { Layout } from "../../../presentationals";
import { CampaignsController } from "../../../presentationals/screens/admin/Campaigns/CampaignsController";

const Route = () => {
  return <Layout activity={<CampaignsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APCampaignsAdd = React.memo(Route);
