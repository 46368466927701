import Types from "./actionType";
import {
  FetchEmploymentHistoryPayload,
  CreateEmploymentHistory,
  CreateEmploymentHistoryPayload,
  FetchEmploymentHistory,
  FetchEmploymentHistoryError,
  FetchEmploymentHistorySuccess,
  UpdateEmploymentHistory,
  UpdateEmploymentHistoryPayload,
  EmploymentHistoryListWithMetaData,
  FetchEmploymentHistoryByIdPayload,
  FetchEmploymentHistoryById,
  DeleteEmploymentHistoryPayload,
  DeleteEmploymentHistory,
} from "./types";

export const fetchEmploymentHistoryList = ({
  params,
  onSuccess,
  onError,
}: FetchEmploymentHistoryPayload): FetchEmploymentHistory => ({
  type: Types.FETCH_EMPLOYMENT_HISTORY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchEmploymentHistoryById = ({
  id,
  onSuccess,
  onError,
}: FetchEmploymentHistoryByIdPayload): FetchEmploymentHistoryById => ({
  type: Types.FETCH_EMPLOYMENT_HISTORY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchEmploymentHistoryListSuccess = (
  data: EmploymentHistoryListWithMetaData,
): FetchEmploymentHistorySuccess => ({
  type: Types.SUCCESS_FETCH_EMPLOYMENT_HISTORY,
  payload: {
    data,
  },
});

export const fetchEmploymentHistoryError = (error?: string): FetchEmploymentHistoryError => ({
  type: Types.ERROR_FETCH_EMPLOYMENT_HISTORY,
  payload: { error },
});

export const createEmploymentHistory = ({
  data,
  onSuccess,
  onError,
}: CreateEmploymentHistoryPayload): CreateEmploymentHistory => ({
  type: Types.CREATE_EMPLOYMENT_HISTORY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateEmploymentHistory = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateEmploymentHistoryPayload): UpdateEmploymentHistory => ({
  type: Types.UPDATE_EMPLOYMENT_HISTORY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteEmploymentHistory = ({
  id,
  onSuccess,
  onError,
}: DeleteEmploymentHistoryPayload): DeleteEmploymentHistory => ({
  type: Types.DELETE_EMPLOYMENT_HISTORY,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
