import React from "react";

import { Layout } from "../../../presentationals";
import { KnowledgeDashboard } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<KnowledgeDashboard />}
      title={strings.headerTitle.knowledgeDashboard}
      employee={true}
      layout={"2"}
      noPadding={true}
    />
  );
};

export default Route;
