import React from "react";

import { Layout } from "../../../presentationals";
import { KnowledgeDetails } from "../../../presentationals/screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<KnowledgeDetails />}
      title={strings.headerTitle.knowledgeDetails}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
