import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getPIIDetailsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    associatedEmployee: {
      title: "Employee",
      sortSupported: true,
      searchSupported: true,
      key: "associatedEmployee",

      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    active: {
      title: "Active",

      key: "active",

      render: (item) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
    number: {
      title: "Number",

      key: "number",
    },
    requiredDocAttached: {
      title: "Required Doc Attached",

      key: "requiredDocAttached",

      render: (item) => {
        return <div className='title'>{item.requiredDocAttached ? "Yes" : "No"}</div>;
      },
    },
    piiType: {
      title: "PII Type",
      key: "piiType",

      render: (item) => {
        return <div className='title'>{item?.piiType?.value || "-"}</div>;
      },
    },
    issueDate: {
      title: "Issue Date",
      sortSupported: true,
      key: "issueDate",

      render: (item) => {
        return (
          <text className='title'>
            {item.issueDate
              ? MomentDate({
                  date: item.issueDate,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    expiryDate: {
      title: "Expiry Date",
      sortSupported: true,
      key: "expiryDate",

      render: (item) => {
        return (
          <text className='title'>
            {item.expiryDate
              ? MomentDate({
                  date: item.expiryDate,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
