import styled from "styled-components";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    height: 100%;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
};

export default styles;
