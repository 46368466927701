import React, { useCallback, useMemo, useRef, useState } from "react";

import moment from "moment-timezone";
import PasswordStrengthBar from "react-password-strength-bar";

import Toast from "../../../../../components/Toast/toast";
import { AttributesValues, getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import { Button, Header, TableHeader, TextInput } from "../../../../components";
import { Popup } from "../../../../components/organisms/Popup/Popup";
import { useController } from "../hooks/useController";

import {
  ButtonContainer,
  CloseButton,
  Column,
  Column2,
  Company,
  Container,
  DisplayImageContainer,
  DisplayPicture,
  DisplayPictureEdit,
  EmployeeID,
  ErrorText,
  FirstRow,
  FormFieldsContainer,
  Heading,
  HorizontalLine,
  ImagePickerContainer,
  ImagePickerModalCloseBTNContainer,
  ImagePickerModalContainer,
  LeftContainer,
  ModalTitle,
  Name,
  NameContainer,
  PopupInsideContainer,
  RightContainer,
  Row,
  StepContainer,
  UploadPictureText,
  ViewContainer,
} from "./styles";

export const Profile = () => {
  const steps = [
    "Profile Overview",
    "Salary Information",
    // 'Banking Details',
    // 'Contact Details',
    // 'Emergency Details',
    // 'Personal Education'
  ];
  const [showPwdError, setShowPwdError] = useState<boolean>(false);
  const imagePicker = useRef(null);
  const {
    goTo,
    user,
    currentStepIndex,
    goBack,
    showChangePasswordModal,
    handleChangePasswordModal,
    showImagePickerModal,
    handleImagePickerModal,
    onFileChange,
    formData,
    onTextChange,
    onSubmit,
    handleRaiseTicketNavigation,
    handleImageDisplay,
    toast,
  } = useController();

  const invokeImagePicker = () => {
    imagePicker.current.click();
  };

  const getDesignation = useMemo(() => {
    const designations = user?.employee?.designations;
    const currentSalary = designations?.find((item) => {
      if (item?.currentSalary) {
        return item;
      }
    });
    if (currentSalary?.designation?.name) {
      return currentSalary?.designation?.name;
    }

    return user?.employee?.designations?.[user.employee.designations.length - 1]?.designation?.name;
  }, [user?.employee?.designations]);

  const renderProfileOverview = useMemo(() => {
    return (
      <ViewContainer>
        <Heading onClick={() => console.log(formData)}>{strings.epProfile.profile}</Heading>
        <Popup closeModal={handleImagePickerModal} isOpen={showImagePickerModal}>
          <ImagePickerModalContainer>
            <ImagePickerModalCloseBTNContainer>
              <CloseButton
                onClick={handleImagePickerModal}
                height={30}
                width={30}
                src={images.closeModal}
              />
            </ImagePickerModalCloseBTNContainer>
            <ImagePickerContainer>
              <ViewContainer>
                <UploadPictureText>{strings.common.uploadImageText}</UploadPictureText>
                <ButtonContainer>
                  <Button title={strings.common.browse} onClick={invokeImagePicker} />
                </ButtonContainer>
              </ViewContainer>
            </ImagePickerContainer>
          </ImagePickerModalContainer>
        </Popup>
        <FirstRow>
          <Column>
            <DisplayImageContainer>
              <DisplayPicture onClick={handleImagePickerModal} src={handleImageDisplay} />
              <DisplayPictureEdit onClick={handleImagePickerModal} src={images.greenPencil} />
            </DisplayImageContainer>
            <input
              accept='image/*'
              hidden={true}
              onChange={onFileChange}
              name={"profileImage"}
              ref={imagePicker}
              type={"file"}
            />
          </Column>
          <Column2>
            <NameContainer>
              <Name>{user.name}</Name>
              {/* <EmployeeID>{`EMP ID ${user.id}`}</EmployeeID> */}
              {/* <Company>Branstr Designing Company</Company> */}
            </NameContainer>
          </Column2>
        </FirstRow>
        <HorizontalLine />
        <Row onClick={() => console.log(user)}>
          <TextInput
            inputProps={{
              name: "designation",
              value: getDesignation,

              autoComplete: "off",
              placeholder: undefined,
              disabled: true,
            }}
            type='text'
            label={strings.epProfile.designation}
            disabled={true}
            width={"46%"}
          />
          <TextInput
            inputProps={{
              name: "personalEmail",
              value: user?.employee?.personalEmail || user?.employee?.email,
              autoComplete: "off",
              placeholder: undefined,
              disabled: true,
            }}
            label={strings.epProfile.personalEmail}
            type={"email"}
            width={"46%"}
          />
        </Row>
        <Row>
          <TextInput
            inputProps={{
              name: "orgEmail",
              value: user?.employee?.officialEmail || user?.employee?.official_email,
              autoComplete: "off",
              placeholder: undefined,
              disabled: true,
            }}
            type='text'
            label={strings.epProfile.organizationEmail}
            width={"46%"}
          />
          <TextInput
            inputProps={{
              name: "mobile",
              value: user?.employee?.mobile,
              autoComplete: "off",
              placeholder: undefined,
              disabled: true,
            }}
            label={strings.epProfile.mobile}
            type={"email"}
            width={"46%"}
          />
        </Row>
        {/* <Row>
                    <TextInput
                        inputProps={{
                            name: 'address',
                            // value: data.name.value,
                            autoComplete: 'off',
                            // onChange: onTextChange,
                            placeholder: undefined,
                            disabled: true
                        }}
                        type="text"
                        label={'Address'}
                        width={'46%'}
                    />
                </Row> */}
      </ViewContainer>
    );
  }, [
    formData,
    getDesignation,
    handleImageDisplay,
    handleImagePickerModal,
    onFileChange,
    showImagePickerModal,
    user,
  ]);

  const renderSalaryInformation = useMemo(() => {
    if (user?.employee?.designations?.length) {
      const currentSalary = user?.employee?.designations?.find((item) => {
        if (item?.currentSalary) {
          return item;
        }
      });
      const lastDesignation =
        user?.employee?.designations?.[user?.employee?.designations?.length - 1];
      const designation = currentSalary?._id ? currentSalary : lastDesignation;
      return (
        <ViewContainer>
          <Heading>{strings.epProfile.salaryInfo}</Heading>
          <Row>
            <TextInput
              inputProps={{
                name: "date",
                value: moment(designation?.date).format("DD-MM-YYYY"),
                autoComplete: "off",
                placeholder: undefined,
                disabled: true,
              }}
              type='text'
              label={strings.epProfile.date}
              width={"46%"}
            />
            <TextInput
              inputProps={{
                name: "baseSalary",
                value: designation?.salaryBase,
                autoComplete: "off",
                disabled: true,
                placeholder: undefined,
              }}
              label={strings.epProfile.baseSalary}
              type={"text"}
              width={"46%"}
            />
          </Row>
          <Row>
            <TextInput
              inputProps={{
                name: "bonus",
                value: designation?.salaryBonus,
                autoComplete: "off",
                placeholder: undefined,
                disabled: true,
              }}
              type='text'
              label={strings.epProfile.bonus}
              width={"46%"}
            />
            <TextInput
              inputProps={{
                name: "frequency",
                value: getAttributValuesBynameId("salaryPayroll", designation?.salaryPayroll),
                autoComplete: "off",
                placeholder: undefined,
                disabled: true,
              }}
              label={strings.epProfile.frequency}
              type={"email"}
              width={"46%"}
            />
          </Row>
        </ViewContainer>
      );
    }
  }, [user]);

  const renderBankingDetails = useMemo(() => {
    return (
      <ViewContainer>
        <Heading>{strings.epProfile.bankingDetails}</Heading>
      </ViewContainer>
    );
  }, []);

  const renderContactDetails = useMemo(() => {
    return (
      <ViewContainer>
        <Heading>{strings.epProfile.contactDetails}</Heading>
      </ViewContainer>
    );
  }, []);

  const renderEmergencyDetails = useMemo(() => {
    return (
      <ViewContainer>
        <Heading>{strings.epProfile.emergencyDetails}</Heading>
      </ViewContainer>
    );
  }, []);

  const renderPersonalEducation = useMemo(() => {
    return (
      <ViewContainer>
        <Heading>{strings.epProfile.personalEducation}</Heading>
      </ViewContainer>
    );
  }, []);

  const renderLeftContainer = () => {
    switch (currentStepIndex) {
      case 0:
        return renderProfileOverview;
      case 1:
        if (user?.employee?.designations?.length) {
          return renderSalaryInformation;
        }
      // case 2:
      //     return renderBankingDetails;
      // case 3:
      //     return renderContactDetails;
      // case 4:
      //     return renderEmergencyDetails;
      // case 5:
      //     return renderPersonalEducation
    }
  };

  return (
    <>
      <TableHeader
        backButtonTitle={strings.common.back}
        backButtonPress={goBack}
        rightButton={strings.common.changePassword}
        rightButtonPress={() => {
          setShowPwdError(false);
          handleChangePasswordModal();
        }}
        additionalButton={strings.common.raiseATicket}
        additionalButtonPress={handleRaiseTicketNavigation}
        extraSaveButton={strings.common.save}
        extraSaveButtonPress={onSubmit}
      />
      <Container>
        <Header title={strings.epProfile.profile} />
        <LeftContainer>
          <Toast type={toast.message_type} message={toast.message} />
          <Popup isOpen={showChangePasswordModal} closeModal={handleChangePasswordModal}>
            <PopupInsideContainer>
              <Row>
                <ModalTitle>{strings.common.changePassword}</ModalTitle>
                <CloseButton
                  onClick={handleChangePasswordModal}
                  height={30}
                  width={30}
                  src={images.closeModal}
                />
              </Row>
              {showPwdError && (
                <Row>
                  <ErrorText>{strings.common.passwordError}</ErrorText>
                </Row>
              )}
              <TextInput
                inputProps={{
                  name: "password",
                  value: formData.password,
                  autoComplete: "off",
                  onChange: onTextChange,
                  placeholder: undefined,
                }}
                type='password'
                label={strings.common.newPassword}
                width={"100%"}
              />
              <PasswordStrengthBar password={formData.password} />
              <TextInput
                inputProps={{
                  name: "confirm_password",
                  value: formData.confirm_password,
                  autoComplete: "off",
                  onChange: onTextChange,
                  placeholder: undefined,
                }}
                type='password'
                label={strings.common.confirmPassword}
                width={"100%"}
              />
              <Button
                onClick={() => {
                  if (formData.password === formData.confirm_password) {
                    onSubmit();
                  } else {
                    setShowPwdError(true);
                  }
                }}
                title='Save'
                top='10%'
              />
            </PopupInsideContainer>
          </Popup>
          <FormFieldsContainer>{renderLeftContainer()}</FormFieldsContainer>
        </LeftContainer>
        <RightContainer>
          {steps.map((stepItem, stepIndex) => {
            return (
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {stepItem}
                <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
              </StepContainer>
            );
          })}
        </RightContainer>
      </Container>
    </>
  );
};
