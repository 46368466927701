const Types = {
  KNOWLEDGEBASE_FETCH: "KNOWLEDGEBASE_FETCH",
  KNOWLEDGEBASE_FETCH_SUCCESS: "KNOWLEDGEBASE_FETCH_SUCCESS",
  KNOWLEDGEBASE_FETCH_SELF_SUCCESS: "KNOWLEDGEBASE_FETCH_SELF_SUCCESS",

  KNOWLEDGEBASE_ADD: "KNOWLEDGEBASE_ADD",
  KNOWLEDGEBASE_UPDATE: "KNOWLEDGEBASE_UPDATE",
  KNOWLEDGEBASE_FILTERS_UPDATE: "KNOWLEDGEBASE_FILTES_UPDATE",
};

export default Types;
