import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { EmailSchedularListProps } from "../../../../types/models/EmailSchedular";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useEmailSchedularList } from "./hooks";

export const EmailSchedularList = memo(({ relatedList }: EmailSchedularListProps) => {
  const EmailSchedular = useAppSelector((state) => state.EmailSchedularReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = EmailSchedular;
  const {
    fetchEmailSchedular,
    loading,
    handlePageChange,
    onSubmitDeleteRecord,
    onClickEmailSchedular,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    columns,
    emailSchedularTableConfigFields,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    emailSchedularHeaderButtons,
    emailSchedularFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useEmailSchedularList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchEmailSchedular({});
    // Do not include fetchEmailSchedular to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={emailSchedularHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.emailSchedular}
      handleTableConfigs={handleTableConfigs}
      filters={emailSchedularFilterFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.emailSchedular_delete}
      refreshList={refreshList}
      rightButton={strings.common.add}
      selectedConfigFields={emailSchedularTableConfigFields}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickEmailSchedular}
      headerTitle={strings.headerTitle.emailSchedular}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apEmailSchedular.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
