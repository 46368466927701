import React from "react";

import { Layout } from "../../../presentationals";
import { NotificationsDefinitionsController } from "../../../presentationals/screens";

export const APNotificationsDefinitionsEdit = () => {
  return (
    <Layout
      activity={<NotificationsDefinitionsController type={"edit"} />}
      admin={true}
      layout={"2"}
    />
  );
};
