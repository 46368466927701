import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { ServiceRequestsControllerProps } from "../../../../types/models/ServiceCatalog";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useServiceRequestsController } from "./hooks";

const Controller = ({ type }: ServiceRequestsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { groupsOptions, usersOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchServiceRequests,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    assignmentOptions,
    onDateChange,
  } = useServiceRequestsController({ type, id, attachmentsPickerRef });
  const currentServiceRequests = data;

  const {
    workLogs,
    attachments,
    assignedTo,
    assignmentGroups,
    description,
    dueDate,
    holdReason,
    number,
    priority,
    shortDescription,
    stage,
    state,
    urgency,
    watchListGroup,
    watchListUser,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchServiceRequests();
      console.log(fetchServiceRequests());
    }

    // Do not include fetchServiceRequests to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    const serviceRequestsPrefix = "SRQ000";
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Number'
            type={"text"}
            width='48%'
            error={number.error}
            inputProps={{
              disabled: true,
              value: `${serviceRequestsPrefix}${number.value}`,
              autoComplete: "off",
              onChange: onTextChange,
              required: number.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"priority"}
            label={"Priority"}
            options={getAttributValues("serviceRequestsPriority")}
            value={priority.value}
            onChange={onTextChange}
            mandatory={priority.required}
            error={priority.error}
          />
          <TextInput
            label='Due Date'
            type={"date"}
            width='48%'
            error={dueDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dueDate",
              value: dueDate.value,
              required: dueDate.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"assignedTo"}
            label={"Assigned To"}
            options={assignmentOptions}
            value={assignedTo.value}
            onChange={onTextChange}
            mandatory={assignedTo.required}
            error={assignedTo.error}
          />
          <Select
            width={"48%"}
            name={"watchListUser"}
            label={"Watch List User"}
            options={usersOptions}
            isMulti={true}
            value={watchListUser.value}
            onChange={onTextChange}
            mandatory={watchListUser.required}
            error={watchListUser.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"assignmentGroups"}
          label={"Assignment Groups"}
          options={[{ label: "None", value: "" }, ...groupsOptions]}
          value={assignmentGroups.value}
          onChange={onTextChange}
          mandatory={assignmentGroups.required}
          error={assignmentGroups.error}
        />
        <Select
          width={"100%"}
          name={"watchListGroup"}
          label={"WatchList Group"}
          isMulti={true}
          options={groupsOptions}
          value={watchListGroup.value}
          onChange={onTextChange}
          mandatory={watchListGroup.required}
          error={watchListGroup.error}
        />
        <Row>
          <Select
            width={"48%"}
            name={"urgency"}
            label={"Urgency"}
            options={getAttributValues("serviceRequestsUrgency")}
            value={urgency.value}
            onChange={onTextChange}
            mandatory={urgency.required}
            error={urgency.error}
          />
          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("serviceRequestsState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>

        <TextInput
          label='Short Description'
          type={"text"}
          width='100%'
          error={shortDescription.error}
          inputProps={{
            name: "shortDescription",
            value: shortDescription.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescription.required,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            rows: 12,
            required: description.required,
          }}
        />

        <TextInput
          label='If any blockers, please mention'
          type={"text"}
          width='100%'
          error={holdReason.error}
          inputProps={{
            name: "holdReason",
            value: holdReason.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: holdReason.required,
          }}
          textAreaProps={{}}
        />
        <Select
          width={"48%"}
          name={"stage"}
          label={"Stage"}
          options={getAttributValues("serviceRequestsStage")}
          value={stage.value}
          onChange={onTextChange}
          mandatory={stage.required}
          error={stage.error}
        />
      </React.Fragment>
    );
  }, [
    assignedTo.error,
    assignedTo.required,
    assignedTo.value,
    assignmentGroups.error,
    assignmentGroups.required,
    assignmentGroups.value,
    assignmentOptions,
    description.error,
    description.required,
    description.value,
    dueDate.error,
    dueDate.required,
    dueDate.value,
    groupsOptions,
    holdReason.error,
    holdReason.required,
    holdReason.value,
    number.error,
    number.required,
    number.value,
    onDateChange,
    onTextChange,
    priority.error,
    priority.required,
    priority.value,
    shortDescription.error,
    shortDescription.required,
    shortDescription.value,
    stage.error,
    stage.required,
    stage.value,
    state.error,
    state.required,
    state.value,
    urgency.error,
    urgency.required,
    urgency.value,
    usersOptions,
    watchListGroup.error,
    watchListGroup.required,
    watchListGroup.value,
    watchListUser.error,
    watchListUser.required,
    watchListUser.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentServiceRequests?.createdBy !== "string"
        ? currentServiceRequests?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentServiceRequests?.updatedBy !== "string"
        ? currentServiceRequests?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentServiceRequests?.createdAt}
        updatedAt={currentServiceRequests?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentServiceRequests?.createdAt,
    currentServiceRequests?.createdBy,
    currentServiceRequests?.updatedAt,
    currentServiceRequests?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.serviceRequests.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.serviceRequests.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ServiceRequestsController = memo(Controller);
