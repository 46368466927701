import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { updateEmailFilters } from "../../redux/emailtemplates/actions";

import styles from "./EmailTemplatesFilters.styles";

const sortKeys = [{ label: "Name", key: "name" }];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const EmailTemplatesFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.updateEmailFilters({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
        </h2>
      </styles.Header>
      <Input
        label={"Name"}
        type={"text"}
        name='name'
        value={props.filters.name}
        autoComplete={"off"}
        onChange={updateFilter}
        readOnly={false}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.EmailTemplatesReducer.filters,
});

const mapDispatchToProps = {
  updateEmailFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesFilters);
