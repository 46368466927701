import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getEducationDetailsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    associatedEmployee: {
      title: "Employee",
      sortSupported: true,
      key: "associatedEmployee",
      searchSupported: true,
      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    fromDate: {
      title: "From Date",
      sortSupported: true,
      key: "fromDate",
      render: (item) => {
        return (
          <text className='title'>
            {item.fromDate ? MomentDate({ date: item.fromDate, format: "YYYY-MM-DD HH:mm" }) : "-"}
          </text>
        );
      },
    },
    toDate: {
      title: "To Date",
      sortSupported: true,
      key: "toDate",
      render: (item) => {
        return (
          <text className='title'>
            {item.toDate ? MomentDate({ date: item.toDate, format: "YYYY-MM-DD HH:mm" }) : "-"}
          </text>
        );
      },
    },
    educationType: {
      title: "Education Type",
      sortSupported: true,
      key: "active",
      render: (item) => {
        return <text className='title'>{item.educationType?.value || "-"}</text>;
      },
    },
    country: {
      title: "Country",
      key: "country",
      render: (item) => {
        return <text className='title'>{item.country?.name || "-"}</text>;
      },
    },
    graduationDate: {
      title: "Graduation Date",
      sortSupported: true,
      key: "graduationDate",
      render: (item) => {
        return (
          <text className='title'>
            {item.graduationDate
              ? MomentDate({ date: item.graduationDate, format: "YYYY-MM-DD HH:mm" })
              : "-"}
          </text>
        );
      },
    },
    state: {
      title: "State/Province",
      sortSupported: true,
      key: "state",
    },
    instituteName: {
      title: "Name of the Institute",
      sortSupported: true,
      key: "instituteName",
    },
    studentId: {
      title: "Student Id",
      sortSupported: true,
      key: "studentId",
    },
    instituteAddress: {
      title: "Address of the Institute",
      sortSupported: true,
      key: "instituteAddress",
    },
    city: {
      title: "City",
      sortSupported: true,
      key: "city",
    },
    postalCode: {
      title: "Postal Zone/ZIP Code",
      sortSupported: true,
      key: "postalCode",
    },
    phone: {
      title: "Phone",
      sortSupported: true,
      key: "phone",
    },
    currentlyAttending: {
      title: "Currently Attending",
      key: "currentlyAttending",
      render: (item) => {
        return <text className='title'>{item.currentlyAttending ? "Yes" : "No"}</text>;
      },
    },
    receivedDegree: {
      title: "Did the employee graduate and receive a degree?",
      key: "receivedDegree",
      render: (item) => {
        return <text className='title'>{item.receivedDegree ? "Yes" : "No"}</text>;
      },
    },
    degreeName: {
      title: "Degree Name",
      sortSupported: true,
      key: "degreeName",
    },
    majorName: {
      title: "Major Name",
      sortSupported: true,
      key: "majorName",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
