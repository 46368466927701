import React from "react";

import { useNavigate } from "react-router-dom";

import { images, strings } from "../../../../theme";

import { Container, CardTitle, ImageContainer, Icon, Description, Button } from "./styles";

const KnowledgeCard = ({ item, index }) => {
  const navigate = useNavigate();
  const handleMore = () => {
    navigate("/ep/knowledge/all", { state: { knowledgebase: item.id } });
  };
  return (
    <Container key={index} onClick={handleMore}>
      <ImageContainer>
        <Icon src={images.epDoubleTick} />
      </ImageContainer>
      <CardTitle>{item.title}</CardTitle>
      <Description>{item.description}</Description>
      <Button>{`${item.totalArticles} ${strings.epKnowledge.articles}`}</Button>
    </Container>
  );
};

export default KnowledgeCard;
