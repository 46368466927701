import React, { useCallback, useEffect, useMemo, useRef, memo, useState } from "react";

import { useParams } from "react-router-dom";

import { EmailSchedularControllerProps } from "../../../../types/models/EmailSchedular";
import {
  ActiveSelectOptions,
  DaySelectOptions,
  FrequencySelectOptions,
  collections,
} from "../../../../utils";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useEmailSchedularController } from "./hooks";

const Controller = ({ type }: EmailSchedularControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchEmailSchedular,
    onDateChange,
    onTimeChange,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useEmailSchedularController({ type, id, attachmentsPickerRef });
  const currentEmailSchedular = data;

  const {
    workLogs,
    attachments,
    name,
    active,
    frequency,
    time,
    day,
    date,
    repeatInterval,
    starting,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchEmailSchedular();
    }
    // Do not include fetchEmailSchedular to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <Select
            name={"frequency"}
            label={"Frequency"}
            options={FrequencySelectOptions}
            value={frequency.value}
            onChange={onTextChange}
            mandatory={frequency.required}
            error={frequency.error}
          />
          {/* {frequency.value === "daily" && (
            <TextInput
              label='Time'
              type={"time"}
              width='48%'
              error={time.error}
              onTimeChange={onTimeChange}
              TimePickerProps={{
                name: "time",
                value: time.value?.$d,
                required: time.required,
              }}
            />
          )} */}
          {frequency.value === "weekly" && (
            <Select
              name={"day"}
              label={"Day"}
              options={DaySelectOptions}
              value={day.value}
              onChange={onTextChange}
              mandatory={day.required}
              error={day.error}
            />
          )}
          {frequency.value === "monthly" && (
            <TextInput
              label='Date'
              type={"text"}
              width='48%'
              error={date.error}
              inputProps={{
                name: "date",
                type: "number",
                value: date.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: date.required,
              }}
            />
            // <TextInput
            //   label='Date'
            //   type={"date"}
            //   width='48%'
            //   error={date.error}
            //   onDateChange={onDateChange}
            //   datePickerProps={{
            //     name: "date",
            //     value: date.value,
            //     required: date.required,
            //   }}
            // />
          )}
        </Row>
        <Row>
          {/* <TextInput
            label='Date'
            type={"date"}
            width='48%'
            error={date.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "date",
              value: date.value,
              required: date.required,
            }}
          /> */}
          <TextInput
            label='Starting'
            type={"date"}
            width='48%'
            error={starting.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "starting",
              value: starting.value,
              required: starting.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    date.error,
    date.required,
    date.value,
    day.error,
    day.required,
    day.value,
    frequency.error,
    frequency.required,
    frequency.value,
    name.error,
    name.required,
    name.value,
    onDateChange,
    onTextChange,
    onTimeChange,
    starting.error,
    starting.required,
    starting.value,
    time.error,
    time.required,
    time.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentEmailSchedular?.createdBy !== "string"
        ? currentEmailSchedular?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentEmailSchedular?.updatedBy !== "string"
        ? currentEmailSchedular?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentEmailSchedular?.createdAt}
        updatedAt={currentEmailSchedular?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentEmailSchedular?.createdAt,
    currentEmailSchedular?.createdBy,
    currentEmailSchedular?.updatedAt,
    currentEmailSchedular?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.emailSchedular.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.emailSchedular.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const EmailSchedularController = memo(Controller);
