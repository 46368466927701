/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CATEGORIES = "FETCH_CATEGORIES",
  FETCH_CATEGORIES_BY_ID = "FETCH_CATEGORIES_BY_ID",
  SUCCESS_FETCH_CATEGORIES = "SUCCESS_FETCH_CATEGORIES",
  ERROR_FETCH_CATEGORIES = "ERROR_FETCH_CATEGORIES",
  CREATE_CATEGORIES = "CREATE_CATEGORIES",
  UPDATE_CATEGORIES = "UPDATE_CATEGORIES",
  DELETE_CATEGORIES = "DELETE_CATEGORIES",
}

export default Types;
