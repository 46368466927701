import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";
// import styles from "../EmployeesController.styles";
// import { generateArrayAttributes } from "../../../../services/methods";

export const Tab1 = ({ formData, employees, readOnly, onTextChange }) => {
  return (
    <>
      <Input
        readOnly={readOnly}
        label={"Is POD Manager"}
        type={"checkbox"}
        name='isPODManager'
        value={formData.isPODManager}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"reportingManager"}
        label={"Manager"}
        options={[
          { label: "None", value: null },
          ...employees.map((value) => ({
            label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
            value: value._id,
          })),
        ]}
        value={formData.reportingManager}
        onChange={onTextChange}
        defaultFocused={true}
      />
      <Select
        readOnly={readOnly}
        name={"reportingHR"}
        label={"HR Manager"}
        options={[
          { label: "None", value: null },
          ...employees.map((value) => ({
            label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
            value: value._id,
          })),
        ]}
        value={formData.reportingHR}
        onChange={onTextChange}
        defaultFocused={true}
      />
      <Select
        readOnly={readOnly}
        name={"reportingPOD"}
        label={"POD Leader"}
        options={[
          { label: "None", value: null },
          ...employees
            .filter((emp) => emp.isPODManager)
            .map((value) => ({
              label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
              value: value._id,
            })),
        ]}
        value={formData.reportingPOD}
        onChange={onTextChange}
        defaultFocused={true}
      />
      <Select
        readOnly={readOnly}
        name={"reportingOperations"}
        label={"Operations Executive"}
        options={[
          { label: "None", value: null },
          ...employees.map((value) => ({
            label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
            value: value._id,
          })),
        ]}
        value={formData.reportingOperations}
        onChange={onTextChange}
        defaultFocused={true}
      />
      <Select
        readOnly={readOnly}
        name={"reportingCompany"}
        label={"Company Buddy"}
        options={[
          { label: "None", value: null },
          ...employees.map((value) => ({
            label: `${value.name} (ITN-${value?._id?.slice(-5)})`,
            value: value._id,
          })),
        ]}
        value={formData.reportingCompany}
        onChange={onTextChange}
        defaultFocused={true}
      />
    </>
  );
};
