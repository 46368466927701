import React from "react";

import { images } from "../../../../theme";

import { Container, Title, FilterIcon } from "./styles";

interface ButtonType {
  title: string;
  border?: string;
  leftArrow?: boolean;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  width?: string;
  height?: string;
  top?: string;
  attributes?: object;
  className?: string;
}

export const FilterButton = ({
  title,
  border,
  leftArrow,
  onClick,
  disabled,
  width = "76px",
  height = "38px",
  top = "0",
  attributes = {},
  className,
}: ButtonType) => {
  return (
    <Container
      className={className}
      width={width}
      top={top}
      height={height}
      border={border}
      onClick={onClick}
      disabled={disabled}
      {...attributes}
    >
      <FilterIcon src={images.filterIcon.default} />
      <Title leftArrow={leftArrow}>{title}</Title>
    </Container>
  );
};
