import React from "react";

import { Layout } from "../../../presentationals";
import { SPSSalesProfileController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSSalesProfileController type={"add"} />} admin={true} layout={"2"} />;
};

export const APSPSSalesProfileAdd = React.memo(Route);
