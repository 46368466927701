/* eslint-disable no-unused-vars */
enum Types {
  FETCH_EMPLOYMENT_HISTORY = "FETCH_EMPLOYMENT_HISTORY",
  FETCH_EMPLOYMENT_HISTORY_BY_ID = "FETCH_EMPLOYMENT_HISTORY_BY_ID",
  SUCCESS_FETCH_EMPLOYMENT_HISTORY = "SUCCESS_FETCH_EMPLOYMENT_HISTORY",
  ERROR_FETCH_EMPLOYMENT_HISTORY = "ERROR_FETCH_EMPLOYMENT_HISTORY",
  CREATE_EMPLOYMENT_HISTORY = "CREATE_EMPLOYMENT_HISTORY",
  UPDATE_EMPLOYMENT_HISTORY = "UPDATE_EMPLOYMENT_HISTORY",
  DELETE_EMPLOYMENT_HISTORY = "DELETE_EMPLOYMENT_HISTORY",
}

export default Types;
