import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ProjectHistoryList,
  AddProjectHistory,
  UpdateProjectHistory,
  FindProjectHistory,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchProjectHistoryListSuccess, fetchProjectHistoryError } from "./actions";
import Types from "./actionType";
import {
  CreateProjectHistoryuccess,
  CreateProjectHistory as CreateProjectHistoryType,
  ProjectHistorySuccess,
  UpdateProjectHistory as UpdateProjectHistoryType,
  FetchProjectHistory,
  FetchProjectHistoryById,
  ProjectHistoryByIdSuccess,
} from "./types";

function* createWorker(action: CreateProjectHistoryType) {
  try {
    const options = {
      method: "POST",
      url: AddProjectHistory,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateProjectHistoryuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* updateWorker(action: UpdateProjectHistoryType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateProjectHistory(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateProjectHistoryuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchProjectHistory) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ProjectHistoryList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ProjectHistorySuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchProjectHistoryListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchProjectHistoryError(error.response?.data.message));
  }
}

function* fetchProjectHistoryById(action: FetchProjectHistoryById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindProjectHistory(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<ProjectHistoryByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_PROJECT_HISTORY, createWorker);
  yield takeLatest(Types.UPDATE_PROJECT_HISTORY, updateWorker);
  yield takeEvery(Types.FETCH_PROJECT_HISTORY, fetchAllWorker);
  yield takeEvery(Types.FETCH_PROJECT_HISTORY_BY_ID, fetchProjectHistoryById);
}
