import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

export const ListWidgetTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  font-size: ${fs.m};
  color: ${colors.common.black};
`;

export const ListWidgetTitleContainer = styled.div`
  width: 100%;
  height: 57px;
  padding: 0 2%;
  background-color: ${colors.common.secondaryWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
`;
export const ListWidgetRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalItemsTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  cursor: pointer;
  font-size: ${fs.s};
  color: ${colors.common.black};
  margin-right: 20px;
`;

export const RefreshIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: solid 1px ${colors.common.borderColor};
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;
export const ListWidgetIcon = styled.img`
  height: 14px;
  width: 14px;
`;
