import Types from "./actionType";
import { CountryActions, CountryState } from "./types";

const initialState: CountryState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const CountryReducer = (state = initialState, action: CountryActions) => {
  switch (action.type) {
    case Types.FETCH_COUNTRY:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_COUNTRY:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
