import styled from "styled-components";

import { fonts } from "../../services/theme";

const styles = {
  MainContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    /* background-color: red; */
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 2vh;
    }
    /* justify-content: space-between; */
  `,
  Body: styled.div`
    flex: 1;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  `,
  Column: styled.div`
    flex: 1;
    justify-content: center;
    text-align: center;
    background-color: #eee;
    border-radius: 10px;
    font-family: ${fonts.font1};
    font-size: 11px;
    padding: 5px;
  `,
  MediaImageContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
  `,
  MediaImage: styled.img`
    height: 100px;
    width: 100px;
    object-fit: contain;
  `,
};

export default styles;
