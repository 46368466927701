import React from "react";

import { Layout } from "../../../presentationals";
import { SPSInsuranceList } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSInsuranceList />} admin={true} layout={"2"} />;
};

export const SPSInsuranceListing = React.memo(Route);
