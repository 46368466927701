import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AcademyMentorFeedbackList,
  AddAcademyMentorFeedback,
  UpdateAcademyMentorFeedback,
  FindAcademyBatchById,
  FindAcademyMentorFeedbackById,
  DeleteAcademyMentorFeedback,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAcademyMentorFeedbackListSuccess, fetchAcademyMentorFeedbackError } from "./actions";
import Types from "./actionType";
import {
  CreateAcademyMentorFeedbackuccess,
  CreateAcademyMentorFeedback as CreateAcademyMentorFeedbackType,
  AcademyMentorFeedbackSuccess,
  UpdateAcademyMentorFeedback as UpdateAcademyMentorFeedbackType,
  FetchAcademyMentorFeedback,
  FetchAcademyMentorFeedbackById,
  AcademyMentorFeedbackByIdSuccess,
  DeleteAcademyMentorFeedback as DeleteAcademyMentorFeedbackType,
} from "./types";

function* createWorker(action: CreateAcademyMentorFeedbackType) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyMentorFeedback,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyMentorFeedbackuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}
function* deleteWorker(action: DeleteAcademyMentorFeedbackType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAcademyMentorFeedback(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAcademyMentorFeedbackuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}
function* updateWorker(action: UpdateAcademyMentorFeedbackType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyMentorFeedback(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyMentorFeedbackuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchAcademyMentorFeedback) {
  console.log("A ::");
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AcademyMentorFeedbackList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AcademyMentorFeedbackSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchAcademyMentorFeedbackListSuccess(data.data));
    }
  } catch (e) {
    console.log("e", e);
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchAcademyMentorFeedbackError(error.response?.data.message));
  }
}

function* fetchAcademyMentorFeedbackById(action: FetchAcademyMentorFeedbackById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAcademyMentorFeedbackById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AcademyMentorFeedbackByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ACADEMY_MENTOR_FEEDBACK, createWorker);
  yield takeLatest(Types.DELETE_ACADEMY_MENTOR_FEEDBACK, deleteWorker);
  yield takeLatest(Types.UPDATE_ACADEMY_MENTOR_FEEDBACK, updateWorker);
  yield takeLatest(Types.DELETE_ACADEMY_MENTOR_FEEDBACK, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_MENTOR_FEEDBACK, fetchAllWorker);
  yield takeEvery(Types.FETCH_ACADEMY_MENTOR_FEEDBACK_BY_ID, fetchAcademyMentorFeedbackById);
}
