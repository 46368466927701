import Types from "./actionType";
import {
  FetchCatalogAdministrationsPayload,
  CreateCatalogAdministrations,
  CreateCatalogAdministrationsPayload,
  CatalogAdministrationsList,
  FetchCatalogAdministrations,
  FetchCatalogAdministrationsError,
  FetchCatalogAdministrationsSuccess,
  UpdateCatalogAdministrations,
  UpdateCatalogAdministrationsPayload,
  CatalogAdministrationsListWithMetaData,
  FetchCatalogAdministrationsByIdPayload,
  FetchCatalogAdministrationsById,
  DeleteCatalogAdministrationsPayload,
  DeleteCatalogAdministrations,
} from "./types";

export const fetchCatalogAdministrationsList = ({
  params,
  onSuccess,
  onError,
}: FetchCatalogAdministrationsPayload): FetchCatalogAdministrations => ({
  type: Types.FETCH_CATALOG_ADMINISTRATION,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCatalogAdministrationsById = ({
  id,
  onSuccess,
  onError,
}: FetchCatalogAdministrationsByIdPayload): FetchCatalogAdministrationsById => ({
  type: Types.FETCH_CATALOG_ADMINISTRATION_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCatalogAdministrationsListSuccess = (
  data: CatalogAdministrationsListWithMetaData,
): FetchCatalogAdministrationsSuccess => ({
  type: Types.SUCCESS_FETCH_CATALOG_ADMINISTRATION,
  payload: {
    data,
  },
});

export const fetchCatalogAdministrationsError = (
  error?: string,
): FetchCatalogAdministrationsError => ({
  type: Types.ERROR_FETCH_CATALOG_ADMINISTRATION,
  payload: { error },
});

export const createCatalogAdministrations = ({
  data,
  onSuccess,
  onError,
}: CreateCatalogAdministrationsPayload): CreateCatalogAdministrations => ({
  type: Types.CREATE_CATALOG_ADMINISTRATION,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCatalogAdministrations = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCatalogAdministrationsPayload): UpdateCatalogAdministrations => ({
  type: Types.UPDATE_CATALOG_ADMINISTRATION,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteCatalogAdministrations = ({
  id,
  onSuccess,
  onError,
}: DeleteCatalogAdministrationsPayload): DeleteCatalogAdministrations => ({
  type: Types.DELETE_CATALOG_ADMINISTRATION,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
