import React, { useCallback, useMemo, useRef, useState } from "react";

import { UseTableV2Props, UseTableV2ReturnType } from "../../../../types";
import { defaultTableListColumns } from "../../../../utils";

export const useTableV2 = ({
  columns,
  handleSortList,
  data,
  onClickTableRow,
  advancedList,
}: UseTableV2Props): UseTableV2ReturnType => {
  const parentRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const componentRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [hoverRow, setHoverRow] = useState<number | boolean>(false);
  const [sortColumn, setSortColumn] = useState<string | boolean>(false);

  const tableColumns = useMemo(() => {
    return defaultTableListColumns.concat(columns);
  }, [columns]);

  const handleSortPopup = useCallback((e: React.MouseEvent) => {
    const columnName = e.currentTarget.getAttribute("data-name");
    console.log(columnName);
    if (columnName?.length) {
      setSortColumn(columnName);
    }
  }, []);

  const closeSortPopup = useCallback(() => {
    setSortColumn(false);
  }, []);

  const handleSortItem = useCallback(
    (e: React.MouseEvent) => {
      const sortType = e.currentTarget.getAttribute("data-sort");
      if (handleSortList && sortType && typeof sortColumn === "string") {
        const type = sortType === "asc" ? "asc" : "desc";
        handleSortList(sortColumn, type);
      }
      setSortColumn(false);
    },
    [handleSortList, sortColumn],
  );

  const onClickRow = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const columnIndex = e.currentTarget.getAttribute("data-column");
      const index = e.currentTarget.getAttribute("data-index") || -1;
      if (
        Number(index) >= 0 &&
        onClickTableRow &&
        (advancedList ? Number(columnIndex) === 2 : Number(columnIndex) === 1)
      ) {
        const id = data[Number(index)]?._id;
        onClickTableRow(id);
      }
    },
    [data, onClickTableRow, advancedList],
  );

  const handleHoverEnterHeader = useCallback((e: React.MouseEvent) => {
    const index = e.currentTarget.getAttribute("data-index");
    setHoverRow(Number(index));
  }, []);
  const handleHoverLeaveHeader = useCallback((e: React.MouseEvent) => {
    setHoverRow(false);
  }, []);

  return {
    parentRef,
    componentRef,
    tableColumns,
    hoverRow,
    sortColumn,
    handleSortPopup,
    closeSortPopup,
    handleSortItem,
    onClickRow,
    handleHoverEnterHeader,
    handleHoverLeaveHeader,
  };
};
