import { useCallback, useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { fetchKnowledgebase } from "../../../../../redux";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";

interface RelatedList {
  type?: "user";
  value?: string;
}
interface Props {
  relatedList: RelatedList;
}

export const useKnowledgebaseConfigs = ({ relatedList }: Props) => {
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const isRelatedList = !!relatedList?.type;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [fetchKnowledgeError, setFetchKnowledgeError] = useState();

  const emptyListMessage = useMemo(() => {
    let message = strings.emptyList.noRecords;
    if (!permissions.knowledgebase_list && isRelatedList) {
      message = `${fetchKnowledgeError?.removedRecords || ""} ${strings.emptyList.noPermissions}`;
    } else if (!permissions.knowledgebase_list) {
      message = strings.emptyList.noRecords;
    }
    return message;
  }, [permissions, isRelatedList, fetchKnowledgeError]);
  const knowledgebaseFetchSuccess = useCallback(() => {
    setLoading(false);
  }, []);

  const knowledgebaseFetchError = useCallback((error) => {
    setFetchKnowledgeError(error);
    setLoading(false);
  }, []);

  const getKnowledgebase = useCallback(
    (page = 1) => {
      setLoading(true);
      const params = { page, limit: 10 };
      if (isRelatedList) {
        params[relatedList.type] = relatedList.value;
      }
      const payload = {
        isEmployee: false,
        params,
        onSuccess: knowledgebaseFetchSuccess,
        onError: knowledgebaseFetchError,
      };
      dispatch(fetchKnowledgebase(payload));
    },
    [dispatch, knowledgebaseFetchError, knowledgebaseFetchSuccess, relatedList, isRelatedList],
  );

  const onClickUser = useCallback(
    (id) => {
      navigate(`/ap/knowledgebase/${id}/edit`);
    },
    [navigate],
  );

  const handlePageChange = useCallback(
    (page) => {
      getKnowledgebase(parseInt(page));
    },
    [getKnowledgebase],
  );

  const onClickAdd = useCallback(() => navigate(`/ap/knowledgebase/add`), [navigate]);

  return {
    onClickUser,
    getKnowledgebase,
    onClickAdd,
    emptyListMessage,
    loading,
    handlePageChange,
  };
};
