import Types from "./actionType";
import {
  FetchCurrencyPayload,
  CreateCurrency,
  CreateCurrencyPayload,
  FetchCurrency,
  FetchCurrencyError,
  FetchCurrencySuccess,
  UpdateCurrency,
  UpdateCurrencyPayload,
  CurrencyListWithMetaData,
  FetchCurrencyByIdPayload,
  FetchCurrencyById,
  DeleteCurrencyPayload,
  DeleteCurrency,
} from "./types";

export const fetchCurrencyList = ({
  params,
  onSuccess,
  onError,
}: FetchCurrencyPayload): FetchCurrency => ({
  type: Types.FETCH_CURRENCY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCurrencyById = ({
  id,
  onSuccess,
  onError,
}: FetchCurrencyByIdPayload): FetchCurrencyById => ({
  type: Types.FETCH_CURRENCY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCurrencyListSuccess = (data: CurrencyListWithMetaData): FetchCurrencySuccess => ({
  type: Types.SUCCESS_FETCH_CURRENCY,
  payload: {
    data,
  },
});

export const fetchCurrencyError = (error?: string): FetchCurrencyError => ({
  type: Types.ERROR_FETCH_CURRENCY,
  payload: { error },
});

export const createCurrency = ({
  data,
  onSuccess,
  onError,
}: CreateCurrencyPayload): CreateCurrency => ({
  type: Types.CREATE_CURRENCY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCurrency = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCurrencyPayload): UpdateCurrency => ({
  type: Types.UPDATE_CURRENCY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCurrency = ({
  id,
  onSuccess,
  onError,
}: DeleteCurrencyPayload): DeleteCurrency => ({
  type: Types.DELETE_CURRENCY,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
