import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";

import Document from "../../../../../components/Document/Document";
import Toast from "../../../../../components/Toast/toast";
import {
  fetchUsers,
  addUsers,
  updateUsers,
  getUserById,
  fetchControllerConfigs,
  updateKnowledgebase,
  addAppNotification,
  removeAppNotification,
  updateGroups,
  uploadFile,
  addUsersChecklist,
  updateUsersChecklist,
} from "../../../../../redux";
import { ChecklistTemplateChecklistItem } from "../../../../../redux/ChecklistTemplate/types";
import { fetchEmployeesBasic } from "../../../../../redux/employees/actions";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import { UpdateTaskPayload } from "../../../../../redux/Task/types";
import { getProfile } from "../../../../../redux/user/actions";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  ChecklistTemplateChecklistItemForm,
  WorkLogItem,
} from "../../../../../types";
import { emailRegex } from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";
import {
  AttachmentsModalContainer,
  BrowseAttachmentContainer,
  CloseIcon,
  CurrentAttachmentContainer,
  NoAttachmentsContainer,
  ButtonsContainer,
  AttachmentListItem,
  AttachmentListItemContainer,
  AttachmentNameContainer,
  AttachmentIconsContainer,
  AttachmentIcon,
  AttachmentTitle,
  DocumentViewContainer,
} from "../../../../common/styles";
import {
  Button,
  Form,
  Header,
  MyTooltip,
  Popup,
  Select,
  TableHeader,
  TableList,
  TextInput,
  WorkLogs,
} from "../../../../components";
import { FormTitle, Row } from "../../ChecklistTemplate/styles";
import { SalaryFieldsContainer, SalaryModalContainer } from "../../Employees/styles";
import { GroupsList } from "../../Groups";
import { KnowledgebaseList } from "../../Knowledgebase";

import { AddRelatedListModal, Container } from "./styles";

const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };

const groupRoles = ["Member", "Manager", "CoManager"];

const knowledgebaseRoles = ["Member", "Manager", "Owner"];
const defaultChecklist: ChecklistTemplateChecklistItemForm = {
  name: {
    value: "",
    required: true,
    error: "",
  },
  assignedTo: {
    value: "",
    required: true,
    error: "",
  },
  value: {
    value: false,
    required: false,
    error: "",
  },
};
export const UsersController = ({ type }) => {
  const organisations = useAppSelector((state) => state.OrganisationsReducer.list);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const UserReducer = useAppSelector((state) => state.UserReducer);
  const userOrganisations = useAppSelector((state) => state.UserReducer?.organisations);
  const metaData = useAppSelector((state) => state.UsersReducer.metaData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const currentUser = useAppSelector((state) => state.UserReducer);
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { knowledgebaseOptions, groupsOptions, usersOptions } = useControllerConfigs();

  const location = useLocation();
  const defaultForm = {
    loading: false,
    message: "",
    message_type: "",
    id: "",
    name: "",
    email: "",
    organisations: [],
    attachments: [],
    checklist: [],
    workLogs: [],
    activityLogs: [],
    // admin: false,
    superAdmin: false,
    active: "",
    knowledgebases: [],
    groups: [],
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  // const location = useLocation();
  const [formData, setFormData] = useState(defaultForm);
  const [tab, setTab] = useState("0");
  const [employeesList, setEmployeesList] = useState([]);
  const [newOrganisation, setNewOrganisation] = useState({
    label: "Select..",
    value: "",
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState("");
  const [selectedOrganisationPermissions, setSelectedOrganisationPermissions] = useState({});
  const [selectedOrganisationIndex, setSelectedOrganisationIndex] = useState("");
  const [admin, setAdmin] = useState(false);
  const [organisationAdmin, setOrganisationAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [documentName, setDocumentName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const fetchEmployeesRecord = (organisations) => {
    dispatch(
      fetchEmployeesBasic({
        organisation: organisations.map((el) => el.organisation.value).join(","),
        onSuccess: (data) => {
          setEmployeesList(data);
        },
        onError: (error) => {},
      }),
    );
  };

  const updateValues = useCallback(
    ({ data, relatedList }) => {
      try {
        if (type === "edit") {
          const organisations = [];
          for (let i = 0; i < data.organisations.length; i++) {
            const el = data.organisations[i];
            if (el?.organisation?._id === UserReducer.organisation._id) {
              setAdmin(el.admin);
              setOrganisationAdmin(el.organisationAdmin);
            }
            if (el?.organisation) {
              organisations.push({
                organisation: {
                  label: el?.organisation?.name,
                  value: el?.organisation?._id,
                },
                employee: {
                  label: el?.employee?.name,
                  value: el?.employee?._id,
                },
                permissions: el.permissions,
                admin: el.admin,
                organisationAdmin: el.organisationAdmin,
              });
            }
          }
          const tempAttachments: Attachments[] = [];
          for (let i = 0; i < data?.attachments?.length; i++) {
            const tempValue = {
              id: data?.attachments?.[i].id,
              error: "",
              name: data?.attachments?.[i].name,
              extension: data?.attachments?.[i].extension,
            };
            tempAttachments.push(tempValue);
          }
          setFormData({
            ...formData,
            workLogs: data.workLogs,
            activityLogs: data.activityLogs,
            message_type: "",
            message: "",
            loading: false,
            id: data._id,
            name: data.name,
            email: data.email,
            superAdmin: data.superAdmin,
            organisations: organisations,
            active: data.active,
            knowledgebases: relatedList?.knowledgebases,
            groups: relatedList?.groups,
            attachments: tempAttachments,
            checklist: data.checklist,
          });
          setChecklistItems(data.checklist);
          fetchEmployeesRecord(organisations);
          setNewOrganisation({
            label: "Select..",
            value: "",
          });
        } else {
          setFormData(defaultForm);
          setEmployeesList([]);
          setNewOrganisation({
            label: "Select..",
            value: "",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [UserReducer.organisation._id, defaultForm, fetchEmployeesRecord, formData, type],
  );
  const [checklistTemplateModal, setChecklistTemplateModal] = useState<boolean>(false);
  const [selectedChecklistTemplate, setSelectedChecklistTemplate] = useState<string>("");
  const [selectedChecklistValues, setSelectedChecklistValues] = useState<
    ChecklistTemplateChecklistItem[]
  >([]);

  const [checklistModal, setChecklistModal] = useState<"add" | "edit" | "">("");
  const [currentChecklistId, setCurrentChecklistId] = useState<string>("");
  const [checklistFormData, setChecklistFormData] =
    useState<ChecklistTemplateChecklistItemForm>(defaultChecklist);
  const [checklistItems, setChecklistItems] = useState<ChecklistTemplateChecklistItem[]>([]);
  const [cheklistLoading, setCheklistLoading] = useState<boolean>(false);

  const clearFormData = useCallback(() => {
    const data = { ...checklistFormData };
    Object.keys(checklistFormData).map((field) => {
      const value = "";
      data[field].value = value;
      data[field].error = "";
    });
    setChecklistFormData(data);
  }, [checklistFormData]);

  const handleChecklistTemplateModal = useCallback(() => {
    setChecklistTemplateModal((value) => !value);
  }, []);

  const handleChecklistTemplate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedChecklistValues([]);
    setSelectedChecklistTemplate(value);
  }, []);
  const handleChecklistTemplateValue = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name") || "";
      const assignedTo = e.currentTarget.getAttribute("data-assignedTo") || "";
      const index = e.currentTarget.getAttribute("data-index") || "";
      const selectedValues = [...selectedChecklistValues];
      if (index) {
        const indexOfValue = selectedValues.findIndex((item) => item._id === index);
        if (indexOfValue >= 0) {
          selectedValues.splice(indexOfValue, 1);
        } else {
          selectedValues.push({
            name: name,
            assignedTo: assignedTo,
            _id: index,
          });
        }
      }
      console.log(name, assignedTo, index);
      setSelectedChecklistValues(selectedValues);
    },
    [selectedChecklistValues],
  );

  const handleChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("");
  }, [clearFormData]);

  const addChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("add");
  }, [clearFormData]);

  const rehydrateChecklistFormData = useCallback(
    (checklistTemplate) => {
      const item = checklistTemplate;
      const data = { ...checklistFormData };
      Object.keys(checklistFormData).map((field) => {
        let value = item?.[field];
        if (field === "assignedTo") {
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      setChecklistFormData(data);
    },
    [checklistFormData],
  );

  const editChecklistModal = useCallback(
    (id = "") => {
      setCurrentChecklistId(id);
      const item = checklistItems.find((item) => item._id === id);
      rehydrateChecklistFormData(item);
      setChecklistModal("edit");
    },
    [checklistItems, rehydrateChecklistFormData],
  );

  const onChecklistTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...checklistFormData,
        [name]: {
          ...checklistFormData[name],
          value,
          error: "",
        },
      };
      setChecklistFormData(tempFormData);
    },
    [checklistFormData],
  );

  const validateChecklistForm = useCallback(() => {
    let tempFormData = { ...checklistFormData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });

    setChecklistFormData(tempFormData);
    return valid;
  }, [checklistFormData]);

  const onSubmitAddChecklist = useCallback(
    (type) => {
      setLoading(true);
      const submit = validateChecklistForm();
      if (!submit && type !== "template") {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please update form",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const checklistItem = {
        name: checklistFormData.name.value,
        assignedTo: checklistFormData.assignedTo.value,
        value: checklistFormData.value?.value,
      };
      const checklists = [...formData.checklist];
      checklists.push(checklistItem);
      const data = {
        userId: params.id,
        checklist: type === "template" ? selectedChecklistValues : [checklistItem],
      };
      const payload = {
        data,
        onSuccess: (data) => {
          if (data?.checklist) {
            setFormData({
              ...formData,
              checklist: data?.checklist,
              activityLogs: data?.activityLogs,
            });
          }
          setChecklistItems(data.checklist);
          setChecklistModal("");
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Checklist added successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          setChecklistModal("");
        },
      };
      dispatch(addUsersChecklist(payload));
      // dispatch(
      //   updateUsers({
      //     id: formData.id,
      //     data: {
      //       checklist: checklists,
      //     },
      //     onSuccess: (data) => {
      //       setFormData({
      //         ...formData,
      //         loading: false,
      //       });
      //       setLoading(false);
      //       setChecklistModal("");
      //       updateValues({ data: data.data, relatedList: data.relatedList });
      //       const notification: AppNotificationsType = {
      //         id: notificationsId + 1,
      //         title: "Checklist added successfully",
      //         type: "success",
      //       };
      //       dispatch(addAppNotification({ notification }));
      //       dispatch(fetchControllerConfigs({}));
      //       setTimeout(() => {
      //         dispatch(removeAppNotification({ notification }));
      //       }, 2000);
      //     },
      //     onError: (error) => {
      //       const notification: AppNotificationsType = {
      //         id: notificationsId + 1,
      //         title: error || "",
      //         type: "error",
      //       };
      //       dispatch(addAppNotification({ notification }));
      //       setTimeout(() => {
      //         dispatch(removeAppNotification({ notification }));
      //       }, 2000);
      //       setFormData({
      //         ...formData,
      //         loading: false,
      //       });
      //     },
      //   }),
      // );
      // setChecklistModal("");
    },
    [
      validateChecklistForm,
      checklistFormData.name.value,
      checklistFormData.assignedTo.value,
      checklistFormData.value?.value,
      formData,
      params.id,
      selectedChecklistValues,
      dispatch,
      notificationsId,
    ],
  );
  const onSubmitUpdateChecklist = useCallback(() => {
    setLoading(true);
    const submit = validateChecklistForm();
    if (!submit) {
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const checklistItem = {
      name: checklistFormData.name.value,
      assignedTo: checklistFormData.assignedTo.value,
      userId: params.id,
      value: checklistFormData.value?.value,
    };
    const checklists = [...formData.checklist];
    checklists.push(checklistItem);
    const data = checklistItem;
    const payload = {
      id: currentChecklistId,
      data,
      onSuccess: (data) => {
        if (data?.checklist) {
          setFormData({
            ...formData,
            checklist: data?.checklist,
            activityLogs: data?.activityLogs,
          });
          setChecklistItems(data.checklist);
        }
        setChecklistModal("");
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Checklist added successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
        setChecklistModal("");
      },
    };
    dispatch(updateUsersChecklist(payload));
  }, [
    validateChecklistForm,
    checklistFormData.name.value,
    checklistFormData.assignedTo.value,
    checklistFormData.value?.value,
    params.id,
    formData,
    currentChecklistId,
    dispatch,
    notificationsId,
  ]);
  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = checklistFormData[name]?.value ? false : true;
        console.log(name, checklistFormData[name]?.value, value);
        const tempFormData = {
          ...checklistFormData,
          [name]: {
            ...checklistFormData[name],
            value,
            error: "",
          },
        };
        setChecklistFormData(tempFormData);
      }
    },
    [checklistFormData],
  );
  // RelatedList
  const [selectedKnowledgebase, setSelectedKnowledgebase] = useState("");
  const [KBrole, setKBRole] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupRole, setgroupRole] = useState("");

  const [relatedListModal, setRelatedListModal] = useState<"" | "knowledgebase" | "group">("");

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
      setDocumentName("");
    }
  }, [attachmentUploadProgress]);

  const onAttachmentsLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // const marginRight = e.target.
  }, []);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments];
        attachments.push({
          id: data?.id,
          name: currentAttachment?.name,
          extension: currentAttachment?.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: attachments,
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const getAttachmentFileIcon = useCallback((extension?: string) => {
    switch (extension?.toLowerCase()) {
      case "pdf":
        return images.pdfFileIcon;
      case "png":
        return images.pngFileIcon;
      case "doc":
        return images.docFileIcon;
      case "docx":
        return images.docxFileIcon;
      case "ppt":
        return images.pptFileIcon;
      case "jpeg":
        return images.jpegFileIcon;
      case "csv":
        return images.csvFileIcon;
      case "xml":
        return images.xmlFileIcon;
      case "xlsx":
        return images.xlsxFileIcon;
      case "gif":
        return images.gifFileIcon;
      case "pptx":
        return images.pptxFileIcon;
      case "jpg":
        return images.jpgFileIcon;

      case "txt":
        return images.txtFileIcon;
      case "svg":
        return images.xmlFileIcon;
      default:
        return images.defaultFileIcon;
    }
  }, []);

  const handleShowAttachment = useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
      setDocumentName(formData.attachments[index]?.name);
    },
    [formData.attachments],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
    setDocumentName("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: attachments,
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const onGetUserSuccess = useCallback(
    (user) => {
      setLoading(false);
      if (type === "edit" && params.id !== formData.id) {
        if (user.data) {
          setTab("0");
          setSelectedOrganisation("");
          setSelectedOrganisationPermissions({});
          updateValues({ data: user.data, relatedList: user.relatedList });
        }
      } else if (type === "add" && formData.id !== "") {
        setTab("0");
        setSelectedOrganisation("");
        setSelectedOrganisationPermissions({});
        updateValues({});
      }
    },
    [formData.id, params.id, type, updateValues],
  );

  const onGetUserError = useCallback((err) => {
    setLoading(false);
  }, []);
  const onTextChange = (e) => {
    const name = e.target.name;
    if (name === "name") {
      setNameError("");
    }
    if (name === "email") {
      setEmailError("");
    }
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    if (["admin", "organisationAdmin"].includes(name)) {
      if (name === "admin") {
        setAdmin(value);
      } else {
        setOrganisationAdmin(value);
      }
      const currentOrg = formData.organisations.map((item) => {
        if (item.organisation.value === UserReducer.organisation._id) {
          item[name] = value;
        }
        return item;
      });
      setFormData({
        ...formData,
        organisations: currentOrg,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const getUser = useCallback(() => {
    const id = params.id;
    if (formData.id !== id) {
      setLoading(true);
      dispatch(
        getUserById({
          id,
          onSuccess: onGetUserSuccess,
          onError: onGetUserError,
        }),
      );
    }
  }, [dispatch, onGetUserError, onGetUserSuccess, params.id]);

  useEffect(() => {
    if (type === "edit") {
      getUser();
    }
  }, [params.id, type]);

  const onOrganisationChange = useCallback(
    (e) => {
      const value = e.target.value;
      const index = formData.organisations.findIndex((el) => el.organisation.value === value);
      setSelectedOrganisation(value);
      const temp = userOrganisations.find((item) => item.organisation._id === value);
      setSelectedOrganisationPermissions(temp.organisation.permissions);
      setSelectedOrganisationIndex(index);
    },
    [formData.organisations, userOrganisations],
  );

  const onOrganisationEmployeeChange = useCallback(
    (e) => {
      const value = e.target.value;
      const index = e.target.name;
      const sampleArray = [...formData.organisations];
      sampleArray[index] = {
        ...sampleArray[index],
        employee: {
          label: "value",
          value: value,
        },
      };
      setFormData({
        ...formData,
        organisations: sampleArray,
      });
    },
    [formData],
  );

  const onOrganisationPermissionChange = useCallback(
    (e) => {
      const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
      const name = e.target.name;
      const sampleArray = [...formData.organisations];
      sampleArray[selectedOrganisationIndex] = {
        ...sampleArray[selectedOrganisationIndex],
        permissions: {
          ...sampleArray[selectedOrganisationIndex].permissions,
          [name]: value,
        },
      };
      setFormData({
        ...formData,
        organisations: sampleArray,
      });
    },
    [formData, selectedOrganisationIndex],
  );

  const onSubmit = (event) => {
    event.preventDefault();
    if (type === "edit") {
      handleOnUpdateForm();
    } else if (type === "add") {
      handleOnAddForm();
    }
  };

  const validateForm = useCallback(() => {
    let submit = false;
    console.log("Name ::", formData.name.trim());
    if (formData.name.trim()) {
      submit = true;
    } else {
      setNameError("Required field");
    }
    console.log("Email ::", !String(formData.email).match(emailRegex));
    if (!String(formData.email).match(emailRegex)) {
      submit = false;
      setEmailError("Enter valid email");
    } else {
      submit = true;
    }
    return submit;
  }, [formData]);

  const handleOnAddForm = () => {
    const valid = validateForm();
    if (!valid) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      dispatch(fetchControllerConfigs({}));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setFormData({
      ...formData,
      message_type: "",
      message: "",
      loading: true,
    });
    const attachments = formData.attachments.map((item) => ({
      name: item?.name,
      id: item?.id,
      extension: item?.extension,
    }));
    dispatch(
      addUsers({
        data: {
          name: formData.name,
          attachments: attachments,
          email: formData.email,
          // admin: formData.admin,
          superAdmin: formData.superAdmin,
          organisations: formData.organisations.map((el) => ({
            organisation: el.organisation.value,
            employee: el.employee.value,
            permissions: el.permissions,
            admin: el.admin,
            organisationAdmin: el.organisationAdmin,
          })),
        },
        onSuccess: (data) => {
          try {
            dispatch(fetchControllerConfigs({}));
            const currentPage = metaData?.currentPage || 1;
            dispatch(
              fetchUsers({
                page: currentPage,
                onSuccess: () => {
                  navigate(`/ap/users/${data.data._id}`);
                  const notification: AppNotificationsType = {
                    id: notificationsId + 1,
                    title: "User created successfully",
                    type: "success",
                  };
                  dispatch(addAppNotification({ notification }));
                  dispatch(fetchControllerConfigs({}));
                  setTimeout(() => {
                    dispatch(removeAppNotification({ notification }));
                  }, 2000);
                },
              }),
            );
          } catch (e) {
            console.log(e);
          }
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setFormData({
            ...formData,
            loading: false,
          });
        },
      }),
    );
  };

  const handleOnUpdateForm = () => {
    const valid = validateForm();
    if (!valid) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      dispatch(fetchControllerConfigs({}));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setFormData({
      ...formData,
      message_type: "",
      message: "",
      loading: true,
    });
    const attachments =
      (formData?.attachments.length &&
        formData?.attachments?.map((item) => ({
          name: item?.name,
          id: item?.id,
          extension: item?.extension,
        }))) ||
      [];
    dispatch(
      updateUsers({
        id: formData.id,
        data: {
          name: formData.name,
          attachments: attachments,
          email: formData.email,
          // admin: formData.admin,
          superAdmin: formData.superAdmin,
          organisations: formData.organisations.map((el) => ({
            organisation: el.organisation.value,
            employee: el.employee.value,
            permissions: el.permissions,
            admin: el.admin,
            organisationAdmin: el.organisationAdmin,
          })),
        },
        onSuccess: (data) => {
          const currentPage = metaData?.currentPage || 1;
          dispatch(fetchControllerConfigs({}));
          if (UserReducer.id === formData.id) {
            dispatch(getProfile());
          }
          setFormData({
            ...formData,
            loading: false,
          });
          updateValues({ data: data.data, relatedList: data.relatedList });
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "User updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          // dispatch(
          //   fetchUsers({
          //     page: currentPage,
          //     onSuccess: () => {},
          //   }),
          // );
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setFormData({
            ...formData,
            loading: false,
          });
        },
      }),
    );
  };

  const onDelete = () => {
    if (window.confirm(`Are you sure you want to revoke user access ?`)) {
      setFormData({
        ...formData,
        message_type: "",
        message: "",
        loading: true,
      });
      dispatch(
        updateUsers({
          id: formData.id,
          data: {
            sessions: [],
            active: false,
          },
          onSuccess: (data) => {
            dispatch(
              fetchUsers({
                onSuccess: () => {
                  dispatch(fetchUsers({ employee: true }));
                  if (UserReducer.id === formData.id) {
                    dispatch(getProfile());
                  }
                  setFormData({
                    ...formData,
                    message_type: "success",
                    message: "Sucessfully Updated",
                    loading: false,
                    active: false,
                  });
                },
              }),
            );
          },
          onError: (error) => {
            setFormData({
              ...formData,
              message_type: "error",
              message: error,
              loading: false,
            });
          },
        }),
      );
    }
  };

  const onActivate = () => {
    if (window.confirm(`Are you sure you want to activate user access ?`)) {
      setFormData({
        ...formData,
        message_type: "",
        message: "",
        loading: true,
      });
      dispatch(
        updateUsers({
          id: formData.id,
          data: {
            active: true,
          },
          onSuccess: (data) => {
            dispatch(
              fetchUsers({
                onSuccess: () => {
                  dispatch(fetchUsers({ employee: true }));
                  if (UserReducer.id === formData.id) {
                    dispatch(getProfile());
                  }
                  setFormData({
                    ...formData,
                    message_type: "success",
                    message: "Sucessfully Updated",
                    loading: false,
                    active: true,
                  });
                },
              }),
            );
          },
          onError: (error) => {
            setFormData({
              ...formData,
              message_type: "error",
              message: error,
              loading: false,
            });
          },
        }),
      );
    }
  };

  const onTextChangeOrganisation = useCallback(
    (e) => {
      const value = e.target.value;
      const organisation = organisations.find((el) => String(el._id) === String(value));
      const index = [...formData.organisations].find((item) => item.organisation.value == value);
      if (index) {
        alert(`Organization already added`);
        return;
      }
      setNewOrganisation({
        label: organisation.name,
        value: value,
      });
    },
    [formData.organisations, organisations],
  );

  const addOrganisation = useCallback(
    (e) => {
      const sampleArray = [...formData.organisations];
      sampleArray.push({
        organisation: {
          label: newOrganisation.label,
          value: newOrganisation.value,
        },
        employee: {
          label: "",
          value: null,
        },
        permissions: {
          organisations_list: false,
          organisations_add: false,
          organisations_edit: false,
          users_list: false,
          users_view: false,
          users_add: false,
          users_edit: false,
          employees_list: false,
          employees_view: false,
          employees_edit: false,
          employees_add: false,
          recruitment_list: false,
          recruitment_view: false,
          recruitment_edit: false,
          recruitment_add: false,
          recruitment_delete: false,
          recruitment_rollback: false,
          payrolls_view: false,
          payrolls_edit: false,
          announcements_list: false,
          announcements_add: false,
          announcements_edit: false,
          tickets: false,
          drive: false,
          jobs_list: false,
          jobs_add: false,
          jobs_edit: false,
          media_list: false,
          media_add: false,
          media_edit: false,
          groups_list: false,
          groups_add: false,
          groups_edit: false,
          categories_list: false,
          categories_add: false,
          categories_edit: false,
          modules_list: false,
          modules_add: false,
          modules_edit: false,
          knowledgebase_list: false,
          knowledgebase_add: false,
          knowledgebase_edit: false,
          emailtemplates_list: false,
          emailtemplates_edit: false,
        },
      });
      setFormData({
        ...formData,
        organisations: sampleArray,
      });
      setNewOrganisation({
        label: "",
        value: "",
      });
      fetchEmployeesRecord(sampleArray);
    },
    [fetchEmployeesRecord, formData, newOrganisation.label, newOrganisation.value],
  );

  const removeOrganisation = useCallback(
    (e) => {
      const index = e.target.getAttribute("data-index");
      window.confirm(
        `Are you sure you want to revoke '${formData.organisations?.[index]?.organisation?.label}' from user ?`,
      )
        ? setFormData({
            ...formData,
            ...formData.organisations.splice(index, 1),
          })
        : console.log("check");
    },
    [formData],
  );

  const organisationFilter = useCallback(
    (el) => {
      const organisationId = el?.organisation?.value || el?._id;
      if (UserReducer.superAdmin) {
        return true;
      } else if (
        organisationId === UserReducer?.organisation?._id &&
        UserReducer.organisationAdmin
      ) {
        return true;
      } else {
        return false;
      }
    },
    [UserReducer?.organisation?._id, UserReducer.organisationAdmin, UserReducer.superAdmin],
  );

  const getUserButtonTitle = useMemo(() => {
    let label = "";
    if (formData.loading) {
      label = "Processing";
    } else if (formData.active) {
      label = "Revoke Access";
    } else {
      label = "Activate Access";
    }
    return label;
  }, [formData.active, formData.loading]);

  const selectedPermissions = formData.organisations?.[selectedOrganisationIndex]?.permissions;
  const { id, name, email, superAdmin } = formData;
  const getUserOrganisations = useMemo(() => {
    const userOrganisations = [
      {
        readOnly: false,
        visible: true,
        type: "select",
        name: "Organisation",
        label: "Organization",
        required: true,
        width: "48%",
        value: newOrganisation.value,
        options: organisations.filter(organisationFilter).map((el) => ({
          label: el.name,
          value: el._id,
        })),
        error: "",
        onChange: onTextChangeOrganisation,
      },
      {
        type: "button",
        label: "Add",
        width: "48%",
        height: "50px",
        visible: true,
        top: "26px",
        onChange: addOrganisation,
        disabled: !newOrganisation.value,
      },
    ];
    formData.organisations.filter(organisationFilter).map((el, index) => {
      const id = {
        readOnly: true,
        visible: true,
        type: "text",
        label: "Organization",
        width: "40%",
        value: el?.organisation?.label,
        error: "",
      };
      const add = {
        type: "button",
        label: "Remove",
        width: "10%",
        height: "50px",
        top: "26px",
        visible: true,
        attributes: {
          "data-index": index,
        },
        onChange: removeOrganisation,
      };
      const employee = {
        readOnly: false,
        visible: true,
        name: index,
        type: "select",
        label: "Employee Record",
        required: true,
        width: "40%",
        value: formData.organisations?.[index]?.employee.value,
        options: employeesList
          .filter((el1) => String(el1?.organisation) === String(el.organisation?.value))
          .map((value) => ({
            label: value?.name,
            value: value?._id,
          })),
        error: "",
        onChange: onOrganisationEmployeeChange,
      };
      userOrganisations.push(id);
      userOrganisations.push(employee);
      userOrganisations.push(add);
    });
    return userOrganisations;
  }, [
    addOrganisation,
    employeesList,
    formData.organisations,
    newOrganisation.value,
    onOrganisationEmployeeChange,
    onTextChangeOrganisation,
    organisationFilter,
    organisations,
    removeOrganisation,
  ]);

  const addKnowledgebaseRelatedListModal = useCallback(() => {
    setRelatedListModal("knowledgebase");
  }, []);
  const addGroupRelatedListModal = useCallback(() => {
    setRelatedListModal("group");
  }, []);

  const closeRelatedListModal = useCallback(() => {
    setSelectedKnowledgebase("");
    setSelectedGroup("");
    setgroupRole("");
    setKBRole("");
    setRelatedListModal("");
  }, []);

  const handleRelatedListInput = useCallback((e: React.ChangeEvent) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(name, value);
    if (value) {
      switch (name) {
        case "knowledgebase":
          setSelectedKnowledgebase(value);
          break;
        case "kbRole":
          setKBRole(value);
          break;
        case "group":
          setSelectedGroup(value);
          break;
        case "groupRole":
          setgroupRole(value);
          break;
      }
    }
  }, []);

  const addRelatedKB = useCallback(() => {
    if (selectedKnowledgebase && KBrole) {
      setLoading(true);
      const payload = {
        id: selectedKnowledgebase,
        data: {
          updateType: "member",
          member: {
            id: id,
            role: KBrole,
          },
        },
        onSuccess: () => {
          closeRelatedListModal();
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "User added to Knowledgebase successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: (e) => {
          closeRelatedListModal();
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: e?.[0] || "Error in addeding to Knowledgebase",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateKnowledgebase(payload));
    }
  }, [selectedKnowledgebase, KBrole, id, dispatch, closeRelatedListModal, notificationsId]);

  const addRelatedGroup = useCallback(() => {
    if (selectedGroup && groupRole) {
      setLoading(true);
      const payload = {
        id: selectedGroup,
        data: {
          updateType: "member",
          member: {
            id: id,
            role: KBrole,
          },
        },
        onSuccess: () => {
          closeRelatedListModal();
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "User added to Group successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: (e) => {
          closeRelatedListModal();
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: e?.[0] || "Error in addeding to Group",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateGroups(payload));
    }
  }, [selectedGroup, groupRole, id, KBrole, dispatch, closeRelatedListModal, notificationsId]);

  const filteredKnowledgebaseOptions = useMemo(() => {
    const knowledgebases = knowledgebaseOptions.map((item) => {});
  }, []);

  const onPostWorklog = useCallback(
    (comment: string) => {
      console.log("Worklog ::", formData);
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs: WorkLogItem[] = [...formData.workLogs];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: currentWorklogs,
        };
        setFormData(tempFormData);
        const payload: UpdateTaskPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "Task updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateUsers(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const getFormFields = useMemo(() => {
    const usersForm = {
      overview: {
        title: "Overview",
        fields: {
          name: {
            readOnly: false,
            visible: true,
            type: "text",
            label: "Full Name",
            required: true,
            width: "48%",
            value: name,
            error: nameError,
          },
          email: {
            readOnly: false,
            visible: true,
            type: "text",
            label: "Email",
            required: true,
            width: "48%",
            value: email,
            error: emailError,
          },
          admin: {
            readOnly: false,
            visible: true,
            type: "select",
            label: "Admin",
            required: true,
            width: "48%",
            options: [
              { label: "Admin Access", value: true },
              { label: "Employee Access", value: false },
            ],
            value: admin,
            error: "",
          },
          organisationAdmin: {
            readOnly: !UserReducer.superAdmin,
            visible: UserReducer.superAdmin || UserReducer?.organisationAdmin,
            required: true,
            type: "select",
            label: "Organization Admin",
            width: "48%",
            options: [
              { label: "Enabled", value: true },
              { label: "Disabled", value: false },
            ],
            value: organisationAdmin,
            error: "",
          },
          superAdmin: {
            readOnly: false,
            visible: UserReducer.superAdmin,
            required: true,
            type: "select",
            label: "Unity Admin",
            width: "48%",
            options: [
              { label: "Enabled", value: true },
              { label: "Disabled", value: false },
            ],
            value: superAdmin,
            error: "",
          },
        },
      },
    };
    if (type === "edit") {
      usersForm["permissions"] = {
        title: "Permissions",
        fields: {
          organisation: {
            readOnly: false,
            visible: true,
            type: "select",
            label: "Organization",
            required: true,
            width: "48%",
            value: selectedOrganisation,
            options: formData.organisations.filter(organisationFilter).map((el) => ({
              label: el?.organisation?.label,
              value: el?.organisation?.value,
            })),

            error: "",
            onChange: onOrganisationChange,
          },
        },
      };

      if (selectedOrganisation) {
        usersForm["permissions"]["fields"] = {
          organisation: {
            readOnly: false,
            visible: true,
            type: "select",
            label: "Organization",
            required: true,
            width: "100%",
            value: selectedOrganisation,
            options: formData.organisations.filter(organisationFilter).map((el) => ({
              label: el?.organisation.label,
              value: el?.organisation.value,
            })),
            error: "",
            onChange: onOrganisationChange,
          },
          organisations_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.organisations,
            type: "checkbox",
            label: "Organizations List",
            width: "32%",
            value: selectedPermissions?.organisations_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          organisations_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.organisations,
            type: "checkbox",
            label: "Organizations Add",
            width: "32%",
            value: selectedPermissions?.organisations_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          organisations_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.organisations,
            type: "checkbox",
            label: "Organizations Edit",
            width: "32%",
            value: selectedPermissions?.organisations_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          users_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.users,
            type: "checkbox",
            label: "Users List",
            width: "32%",
            value: selectedPermissions?.users_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          users_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.users,
            type: "checkbox",
            label: "Users Add",
            width: "32%",
            value: selectedPermissions?.users_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          users_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.users,
            type: "checkbox",
            label: "Users Edit",
            width: "32%",
            value: selectedPermissions?.users_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          employees_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.employees,
            type: "checkbox",
            label: "Employees List",
            width: "32%",
            value: selectedPermissions?.employees_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          employees_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.employees,
            type: "checkbox",
            label: "Employees Add",
            width: "32%",
            value: selectedPermissions?.employees_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          employees_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.employees,
            type: "checkbox",
            label: "Employees Edit",
            width: "32%",
            value: selectedPermissions?.employees_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruit List",
            width: "32%",
            value: selectedPermissions?.recruitment_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruit Add",
            width: "32%",
            value: selectedPermissions?.recruitment_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruit Edit",
            width: "32%",
            value: selectedPermissions?.recruitment_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_delete: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruit List",
            width: "32%",
            value: selectedPermissions?.recruitment_delete,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_rollback: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruitment Rollback",
            width: "32%",
            value: selectedPermissions?.recruitment_rollback,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          recruitment_view: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.recruitsment,
            type: "checkbox",
            label: "Recruitment View",
            width: "32%",
            value: selectedPermissions?.recruitment_view,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          payrolls_view: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.payroll,
            type: "checkbox",
            label: "Payrolls List",
            width: "32%",
            value: selectedPermissions?.payrolls_view,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          payrolls_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.payroll,
            type: "checkbox",
            label: "Payrolls Edit",
            width: "32%",
            value: selectedPermissions?.payrolls_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          tickets: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.tickets,
            type: "checkbox",
            label: "Tickets",
            width: "32%",
            value: selectedPermissions?.tickets,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          drive: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.drive,
            type: "checkbox",
            label: "Drive",
            width: "32%",
            value: selectedPermissions?.drive,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          announcements_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.announcements,
            type: "checkbox",
            label: "Announcements List",
            width: "32%",
            value: selectedPermissions?.announcements_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          announcements_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.announcements,
            type: "checkbox",
            label: "Announcements Add",
            width: "32%",
            value: selectedPermissions?.announcements_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          announcements_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.announcements,
            type: "checkbox",
            label: "Announcements Edit",
            width: "32%",
            value: selectedPermissions?.announcements_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          jobs_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.jobs,
            type: "checkbox",
            label: "Jobs List",
            width: "32%",
            value: selectedPermissions?.jobs_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          jobs_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.jobs,
            type: "checkbox",
            label: "Jobs Add",
            width: "32%",
            value: selectedPermissions?.jobs_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          jobs_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.jobs,
            type: "checkbox",
            label: "Jobs Edit",
            width: "32%",
            value: selectedPermissions?.jobs_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          media_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.media,
            type: "checkbox",
            label: "Media List",
            width: "32%",
            value: selectedPermissions?.media_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          media_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.media,
            type: "checkbox",
            label: "Media Add",
            width: "32%",
            value: selectedPermissions?.media_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          media_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.media,
            type: "checkbox",
            label: "Media Edit",
            width: "32%",
            value: selectedPermissions?.media_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          emailtemplates_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.emailtemplates,
            type: "checkbox",
            label: "Email Templates List",
            width: "32%",
            value: selectedPermissions?.emailtemplates_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          emailtemplates_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.emailtemplates,
            type: "checkbox",
            label: "Email Templates Add",
            width: "32%",
            value: selectedPermissions?.emailtemplates_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          emailtemplates_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.emailtemplates,
            type: "checkbox",
            label: "Email Templates Edit",
            width: "32%",
            value: selectedPermissions?.emailtemplates_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          groups_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.groups,
            type: "checkbox",
            label: "Groups List",
            width: "32%",
            value: selectedPermissions?.groups_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          groups_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.groups,
            type: "checkbox",
            label: "Groups Add",
            width: "32%",
            value: selectedPermissions?.groups_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          groups_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.groups,
            type: "checkbox",
            label: "Groups Edit",
            width: "32%",
            value: selectedPermissions?.groups_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          categories_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.categories,
            type: "checkbox",
            label: "Categories List",
            width: "32%",
            value: selectedPermissions?.categories_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          categories_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.categories,
            type: "checkbox",
            label: "Categories Add",
            width: "32%",
            value: selectedPermissions?.categories_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          categories_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.categories,
            type: "checkbox",
            label: "Categories Edit",
            width: "32%",
            value: selectedPermissions?.categories_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          knowledgebase_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.knowledgebase,
            type: "checkbox",
            label: "Knowledgebase List",
            width: "32%",
            value: selectedPermissions?.knowledgebase_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          knowledgebase_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.knowledgebase,
            type: "checkbox",
            label: "Knowledgebase Add",
            width: "32%",
            value: selectedPermissions?.knowledgebase_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          knowledgebase_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.knowledgebase,
            type: "checkbox",
            label: "Knowledgebase Edit",
            width: "32%",
            value: selectedPermissions?.knowledgebase_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          modules_list: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.modules,
            type: "checkbox",
            label: "Tags List",
            width: "32%",
            value: selectedPermissions?.modules_list,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          modules_add: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.modules,
            type: "checkbox",
            label: "Tags Add",
            width: "32%",
            value: selectedPermissions?.modules_add,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
          modules_edit: {
            readOnly: false,
            visible: selectedOrganisationPermissions?.modules,
            type: "checkbox",
            label: "Tags Edit",
            width: "32%",
            value: selectedPermissions?.modules_edit,
            error: "",
            onChange: onOrganisationPermissionChange,
          },
        };
      }
      if (UserReducer?.superAdmin || UserReducer?.organisationAdmin) {
        usersForm["organisations"] = {
          title: "New Organization",
          fields: getUserOrganisations,
        };
      }
      usersForm["checklist"] = {
        title: "Checklist",
        checklist: true,
      };
      usersForm["worklogs"] = {
        title: "Worklogs",
        workLogs: () => <WorkLogs workLogs={formData.workLogs} onPostWorklog={onPostWorklog} />,
      };
      usersForm["activityLogs"] = {
        title: "Activity Logs",
        workLogs: () => <WorkLogs workLogs={formData.activityLogs} activityLogger />,
      };
      usersForm["knowledgebases"] = {
        title: "Knowledgebases",
        header: () => {
          return (
            <div
              style={{
                marginTop: "40%",
                marginBottom: "10%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  width: "30%",
                }}
              />
              <div>Related Lists</div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  width: "30%",
                }}
              />
            </div>
          );
        },
        relatedList: () => (
          <KnowledgebaseList
            relatedList={{
              type: "user",
              value: params.id || "",
              addToRelatedList: addKnowledgebaseRelatedListModal,
            }}
          />
        ),
      };
      usersForm["groups"] = {
        title: "Groups",
        relatedList: () => (
          <GroupsList
            relatedList={{
              type: "user",
              value: params.id || "",
              addToRelatedList: addGroupRelatedListModal,
            }}
          />
        ),
      };
    }
    return usersForm;
  }, [
    name,
    email,
    admin,
    nameError,
    emailError,
    UserReducer.superAdmin,
    UserReducer?.organisationAdmin,
    organisationAdmin,
    superAdmin,
    type,
    selectedOrganisation,
    formData.organisations,
    formData.workLogs,
    formData.activityLogs,
    organisationFilter,
    onOrganisationChange,
    selectedOrganisationPermissions?.organisations,
    selectedOrganisationPermissions?.users,
    selectedOrganisationPermissions?.employees,
    selectedOrganisationPermissions?.recruitsment,
    selectedOrganisationPermissions?.payroll,
    selectedOrganisationPermissions?.tickets,
    selectedOrganisationPermissions?.drive,
    selectedOrganisationPermissions?.announcements,
    selectedOrganisationPermissions?.jobs,
    selectedOrganisationPermissions?.media,
    selectedOrganisationPermissions?.emailtemplates,
    selectedOrganisationPermissions?.groups,
    selectedOrganisationPermissions?.categories,
    selectedOrganisationPermissions?.knowledgebase,
    selectedOrganisationPermissions?.modules,
    selectedPermissions?.organisations_list,
    selectedPermissions?.organisations_add,
    selectedPermissions?.organisations_edit,
    selectedPermissions?.users_list,
    selectedPermissions?.users_add,
    selectedPermissions?.users_edit,
    selectedPermissions?.employees_list,
    selectedPermissions?.employees_add,
    selectedPermissions?.employees_edit,
    selectedPermissions?.recruitment_list,
    selectedPermissions?.recruitment_add,
    selectedPermissions?.recruitment_edit,
    selectedPermissions?.recruitment_delete,
    selectedPermissions?.recruitment_rollback,
    selectedPermissions?.recruitment_view,
    selectedPermissions?.payrolls_view,
    selectedPermissions?.payrolls_edit,
    selectedPermissions?.tickets,
    selectedPermissions?.drive,
    selectedPermissions?.announcements_list,
    selectedPermissions?.announcements_add,
    selectedPermissions?.announcements_edit,
    selectedPermissions?.jobs_list,
    selectedPermissions?.jobs_add,
    selectedPermissions?.jobs_edit,
    selectedPermissions?.media_list,
    selectedPermissions?.media_add,
    selectedPermissions?.media_edit,
    selectedPermissions?.emailtemplates_list,
    selectedPermissions?.emailtemplates_add,
    selectedPermissions?.emailtemplates_edit,
    selectedPermissions?.groups_list,
    selectedPermissions?.groups_add,
    selectedPermissions?.groups_edit,
    selectedPermissions?.categories_list,
    selectedPermissions?.categories_add,
    selectedPermissions?.categories_edit,
    selectedPermissions?.knowledgebase_list,
    selectedPermissions?.knowledgebase_add,

    selectedPermissions?.knowledgebase_edit,
    selectedPermissions?.modules_list,
    selectedPermissions?.modules_add,
    selectedPermissions?.modules_edit,
    onOrganisationPermissionChange,
    getUserOrganisations,
    onPostWorklog,
    params.id,
    addKnowledgebaseRelatedListModal,
    addGroupRelatedListModal,
  ]);

  const isSubmitDisabled = useMemo(() => {
    return !formData.name || !formData.email;
  }, [formData.email, formData.name]);

  const getBreadCrumbs = useMemo(() => {
    return ["Users", name];
  }, [params.id, name]);

  return (
    <Container loading={loading}>
      <Header title={getBreadCrumbs} />
      <TableHeader
        backButtonTitle={strings.common.back}
        rightButton={permissions?.users_edit && strings.common.save}
        rightButtonBorder='0.3px'
        rightButtonPress={onSubmit}
        rightButtonDisabled={isSubmitDisabled}
        additionalButton={getUserButtonTitle}
        additionalButtonPress={formData.active ? onDelete : onActivate}
        onAttachmentsClick={handleAttachmentsModal}
        onAttachmentsIconLoad={onAttachmentsLoad}
        attachmentsBadge={formData.attachments.length}
      />
      <Toast type={formData.message_type} message={formData.message} />
      <Form
        data={getFormFields}
        onTextChange={onTextChange}
        addChecklistModal={addChecklistModal}
        checklist={formData.checklist}
        editChecklistModal={editChecklistModal}
        checklistModal={checklistModal}
        handleChecklistModal={handleChecklistModal}
        checklistFormData={checklistFormData}
        onChecklistTextChange={onChecklistTextChange}
        onSubmitAddChecklist={onSubmitAddChecklist}
        onSubmitUpdateChecklist={onSubmitUpdateChecklist}
        handleCheckbox={handleCheckbox}
        checklistTemplateModal={checklistTemplateModal}
        selectedChecklistTemplate={selectedChecklistTemplate}
        selectedChecklistValues={selectedChecklistValues}
        handleChecklistTemplateModal={handleChecklistTemplateModal}
        handleChecklistTemplate={handleChecklistTemplate}
        handleChecklistTemplateValue={handleChecklistTemplateValue}
      />
      <Popup isOpen={!!relatedListModal} closeModal={closeRelatedListModal}>
        <AddRelatedListModal>
          {relatedListModal === "knowledgebase" && (
            <>
              <Select
                width={"100%"}
                name={"knowledgebase"}
                label={"Knowledgebase"}
                options={knowledgebaseOptions}
                value={selectedKnowledgebase}
                onChange={handleRelatedListInput}
                mandatory={true}
                // error={!selectedKnowledgebase ? "Require Field" : ""}
              />
              <Select
                width={"100%"}
                name={"kbRole"}
                label={"Role"}
                options={[
                  // { label: "Select...", value: "" },
                  ...knowledgebaseRoles.map((value) => ({
                    label: value,
                    value: value.toLowerCase(),
                  })),
                ]}
                value={KBrole}
                onChange={handleRelatedListInput}
                mandatory={true}
                // error={!KBrole ? "Require Field" : ""}
              />
              <Button
                className='related-modal-save-button'
                title={strings.common.save}
                onClick={addRelatedKB}
                disabled={!KBrole || !selectedKnowledgebase}
              />
            </>
          )}
          {relatedListModal === "group" && (
            <>
              <Select
                width={"100%"}
                name={"group"}
                label={"Group"}
                options={groupsOptions}
                value={selectedGroup}
                onChange={handleRelatedListInput}
                mandatory={true}
                // error={!selectedKnowledgebase ? "Require Field" : ""}
              />
              <Select
                width={"100%"}
                name={"groupRole"}
                label={"Role"}
                options={[
                  // { label: "Select...", value: "" },
                  ...groupRoles.map((value) => ({
                    label: value,
                    value: value.toLowerCase(),
                  })),
                ]}
                value={groupRole}
                onChange={handleRelatedListInput}
                mandatory={true}
                // error={!KBrole ? "Require Field" : ""}
              />
              <Button
                className='related-modal-save-button'
                title={strings.common.save}
                onClick={addRelatedGroup}
                disabled={!groupRole || !selectedGroup}
              />
            </>
          )}
        </AddRelatedListModal>
      </Popup>
      <Popup isOpen={!!attachmentModal} closeModal={handleAttachmentsModal}>
        {documentId ? (
          <DocumentViewContainer>
            <CloseIcon src={images.closeModal} onClick={handleCloseAttachment} />
            <Document name={documentName} id={documentId} />
          </DocumentViewContainer>
        ) : (
          <AttachmentsModalContainer>
            <CloseIcon src={images.closeModal} onClick={handleAttachmentsModal} />
            {attachmentModal === "list" && (
              <>
                {formData.attachments.length ? (
                  <AttachmentListItemContainer>
                    {formData.attachments.map((attachmentItem, attachmentIndex) => {
                      const { id, name, extension } = attachmentItem;
                      let fileName = name;
                      if (extension) {
                        fileName = `${name}.${extension}`;
                      }
                      return (
                        <AttachmentListItem key={attachmentIndex}>
                          <AttachmentNameContainer>
                            <AttachmentIcon src={getAttachmentFileIcon(extension)} />
                            <AttachmentTitle>{fileName}</AttachmentTitle>
                          </AttachmentNameContainer>
                          <AttachmentIconsContainer>
                            <MyTooltip text='View Attachment'>
                              <AttachmentIcon
                                onClick={handleShowAttachment}
                                data-index={attachmentIndex}
                                src={images.viewAttachment}
                              />
                            </MyTooltip>
                            <MyTooltip text='Delete Attachment'>
                              <AttachmentIcon
                                onClick={handleDeleteAttachment}
                                data-index={attachmentIndex}
                                src={images.deleteAttachment}
                              />
                            </MyTooltip>
                          </AttachmentIconsContainer>
                        </AttachmentListItem>
                      );
                    })}
                    <AttachmentListItem className='attachments-buttons-container'>
                      <Button title={strings.common.add} onClick={onClickAddAttachment} />
                      <Button title={strings.common.ok} onClick={handleAttachmentsModal} />
                    </AttachmentListItem>
                  </AttachmentListItemContainer>
                ) : (
                  <>
                    <NoAttachmentsContainer>
                      {strings.attachments.noAttachments}
                    </NoAttachmentsContainer>
                    <Button
                      title={strings.common.add}
                      className='knowledge-attachments-add'
                      onClick={onClickAddAttachment}
                    />
                  </>
                )}
              </>
            )}
            {attachmentModal === "add" && (
              <>
                {currentAttachmentFile ? (
                  <CurrentAttachmentContainer>
                    <TextInput
                      label='Attachment'
                      type={"text"}
                      width='90%'
                      inputProps={{
                        name: "attachment",
                        onChange: handleCurrentAttachment,
                        value: currentAttachment.name,
                      }}
                      error={attachmentUploadProgress}
                    />
                    <ButtonsContainer>
                      <Button
                        title={strings.common.save}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickSaveAttachment}
                      />
                      <Button
                        title={strings.common.cancel}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickCancelAttachment}
                      />
                    </ButtonsContainer>
                  </CurrentAttachmentContainer>
                ) : (
                  <>
                    <input
                      type='file'
                      name='attachments'
                      ref={attachmentsPickerRef}
                      onChange={onAttachmentsFileChange}
                      hidden={true}
                      accept='.gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml,.txt'
                    />
                    <BrowseAttachmentContainer>
                      {strings.attachments.addAttachments}
                      <Button title={strings.common.browse} onClick={onClickBrowseAttachment} />
                    </BrowseAttachmentContainer>
                  </>
                )}
              </>
            )}
          </AttachmentsModalContainer>
        )}
      </Popup>
    </Container>
  );
};
