import React from "react";

import { Layout } from "../../../presentationals";
import { NewsletterLeadsController } from "../../../presentationals/screens/admin/NewsletterLeads/NewsletterLeadsController";

const Route = () => {
  return (
    <Layout activity={<NewsletterLeadsController type={"edit"} />} admin={true} layout={"2"} />
  );
};

export const APNewsletterLeadsEdit = React.memo(Route);
