export const strings = {
  headerTitle: {
    home: "Home",
    knowledge: "Knowledge",
    salaryPayroll: "Salary Freqency",
    emergencyContactType: "Emergency Contact Type",
    knowledgeAdd: "Create Article",
    knowledgeEdit: "Edit Article",
    knowledgeDashboard: "Knowledge",
    knowledgeDetails: "Knowledge Details",
    knowledgeRevise: "Revise Knowledge",
    jobsPosting: "Job Postings",
    payroll: "Salary Slips",
    payrolls: "Payrolls",
    attributes: "Attributes",
    tickets: "Service Tickets",
    checklistTemplate: "Checklist Template",
    newsletterResources: "Newsletter Resources",
    academyBatchState: "Academy Batch State",
    academyBatchOverallHealth: "Academy Batch Health",
    academyBatchTimeHealth: "Academy Batch Schedule Time Health",
    academyACHType: "Academy Activity Type",
    academyACHState: "Academy Activity State",
    academyACHCallDirection: "Academy Activity Call Direction",
    academyACHPurpose: "Academy Activity Purpose",
    academyACHResult: "Academy Activity Result",
    academyEnrollmentStage: "Academy Enrollment Stage",
    academyCandidateLeadStages: "Academy Candidate Leads Stages",
    academyCandidateLeadPaymentTerms: "Academy Candidate PaymentTerms",
    academyPaymentStatus: "Academy Payment Status",
    academyPaymentTerms: "Academy Payment Terms",
    academyCandidateStatus: "Academy Candidate Status",
    academyCandidateSource: "Academy Candidate Source",
    leadLists: "leadLists",
    ticketCreate: "Create Tickets",
    ticketDetails: "Ticket Details",
    ticketHeading: "Tickets",
    profile: "Profile",
    announcements: "Announcements",
    PIIDetails: "PII Details",
    bankDetails: "Bank Details",
    subscriber: "Subscriber",
    categories: "Categories",
    emailTemplates: "Email Templates",
    employees: "Employee Profile",
    editEmployees: "Edit Employee",
    groups: "Groups",
    academyCourses: "Course Offerings",
    academyACH: "Calls/Meetings Activities",
    academyBatch: "Academy Batch",
    academyStudentProfile: "Academy Learner Profile",
    academyCandidateEnrollments: "Academy Candidate Enrollments",
    academyMentorFeedback: "Mentor's Feedback",
    academyCandidates: "Contact Web Portal",
    academyCandidateLeads: "Candidates Leads",
    job: "Job",
    jobs: "Job Postings",
    employmentHistory: "Employment History",
    projectHistory: "Project Employee Allocation",
    addressHistory: "Address History",
    educationDetails: "Education Details",
    knowledgebase: "Knowledgebase",
    media: "Media Insights",
    modules: "Tags",
    organisations: "Organizations",
    organisationManage: "Manage Organization",
    recruitments: "Recruitments",
    subCategories: "Sub Categories",
    SPSAccountContact: "SPS Account Contacts",
    SPSAccount: "SPS Account",
    SPSBackgroundCheck: "SPS BackgroundCheck",
    SPSDeals: "SPS Deals",
    SPSInsurance: "SPS Insurance",
    SPSPaperWork: "SPS Paperwork Activities",
    SPSProjects: "SPS Projects",
    SPSSalesProfile: "SPS Sales Profile",
    users: "Users",
    platformModules: "Platform Modules",
    task: "Task",
    hrTask: "HR Task",
    companyTask: "Company Task",
    ACHTask: "ACH Task",
    academycareerPath: "Academy Career Path",
    dashboardAdministration: "Configs",
    dashboardWidgets: " Reports",
    dashboardTabs: "Tabs",
    filters: "Filters",
    piiDetails: "PII Details",
    overview: "Overview",
    contactUs: "Contact Us",
    members: "Members & Managers",
    countries: "Countries",
    departments: "Departments",
    designations: "Designations",
    academyStudentState: "Academy Learner Profile State",
    academyStudentScoring: "Academy Learner Profile Scoring",
    country: "Country",
    graduationDegree: "Graduation Degree",
    academyCandidateGraduationMajor: "Graduation Major",
    bankRoutingType: "Bank Routing Type",
    financialYear: "Financial Year",
    candidateCurrentStatus: "Academy Current Status",
    currency: "Currency",
    companyContact: "Company Contact",
    company: "Company",
    benefits: "Benefits",
    catalogAdministrations: "Catalog Administration",
    form16: "Form 16",
    certificationsAccreditations: "Certifications Accreditations",
    companyIndustry: "Company Industry",
    companyType: "Company Type",
    companyClassification: "Company Classification",
    benefitType: "Benefits",
    bankAccountType: "Bank Account Type",
    bankType: "Bank Type",
    companySize: "Company Size",
    piiType: "PII Type",
    certificationsAccreditationsType: "Certifications & Accreditations Type",
    educationType: "Education Type",
    projectType: "Project Type",
    technology: "Technology",
    projectStatus: "Project Status",
    projectModules: "Project Modules",
    companyServiceType: "Company Service Type",
    companyModelType: "Company Model Type",
    academyCandidateStages: "Academy Candidate Stages",
    academyCandidateDegree: "Academy Candidate Degree",
    employmentType: "Employment Type",
    jobType: "Job Type",
    corporationType: "Corporation Type",
    driveListing: "Drive Listing",
    taskType: "Task Type",
    achTaskType: "ACH Task Type",
    companyTaskType: "Company Task Type",
    taskPriority: "Task Priority",
    taskUrgency: "Task Urgency",
    taskState: "Task State",
    employmentStatus: "Employment Status",
    shirtSize: "Shirt Size",
    offboardType: "Offboard Type",
    personalMartial: "Maritial Status",
    ethnicity: "Ethnicity",
    race: "Race",
    managerLevel: "Manager Level",
    citizenship: "Nationality",
    gender: "Gender",
    spsPaperwork: "SPS Paperwork",
    spsInsurance: "SPS Insurance",
    spsBGCheckStatus: "SPS BG Check Status",
    spsBillingCycle: "SPS Billing Cycle",
    spsProjectPlatform: "SPS Project Platform",
    spsPlatform: "SPS Platform",
    spsAccountType: "SPS Account Type",
    spsAccountStatus: "SPS Account Status",
    spsAccountContact: "SPS Account Contact",
    spsSalesProfile: "SPS Sales Profile",
    spsBGCheck: "SPS BG Check",
    spsDeals: "SPS Deals",
    spsDealsType: "SPS Deals Type",
    dealSource: "SPS Deals Deals Source",
    dealsState: "SPS Deals State",
    dealsPlateform: "SPS Deals Plateform",
    bugReporting: "Bug Reporting",
    tags: "Tags",
    companyContactStatus: "Company Contact Status",
    itnServices: "ServiceNow Services",
    itnRFP: "ITN RFP",
    variableSets: "Variable Sets",
    variables: "Variables",
    emailSchedular: "Email Schedular",
    kanbanConfig: "Kanban Config",
    serviceNowSolutions: "Service Now Solutions",
    resources: "Resources",
    resourcesState: "Resources State",
    workspaces: "Workspace",
    space: "Space",
    notificationsDefinations: "Notifications Definations",
    serviceRequests: "Service Requests",
    campaignsAssets: "Campaigns Assets",
    campaigns: "Campaigns",
    newsletterLeads: "Newsletter Leads",
  },
  noData: {
    noWidgets: "No Widgets Found",
  },
  apServiceRequests: {
    search: "Search Service Requests",
  },
  common: {
    records: "Records",
    deleteWarning: "Are you sure you want to delete selected records?",
    sortAsc: "Sort Ascending",
    sortDesc: "Sort Descending",
    start: "Start",
    configure: "Configure",
    loading: "Loading",
    activate: "Activate",
    deactivate: "Deactivate",
    export: "Export",
    exportAll: "Export All",
    fileExported: "Your file has been exported successfully.",
    noWorkLogs: "No Worklogs found",
    noActivityLogs: "No activity logs found",
    welcome: "Welcome",
    relatedList: "Related Lists",
    welcomeQuote:
      "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    more: "More",
    loadMore: "Load More",
    viewAll: "View All",
    collapse: "Collapse",
    noData: "No data available",
    adminPanel: "Workspace",
    employeePanel: "Employee Panel",
    noAnnouncementsAvailable: "No Announcements Available",
    noTickets: "No Tickets Available",
    noArticles: "No Articles are there",
    noPendingApprovals: "No Pending Approvals",
    noBirthdays: "No Birthdays Available",
    of: "of",
    add: "Add",
    addNew: "Add New",
    cancel: "Cancel",
    back: "Back",
    save: "Save",
    filter: "Filter",
    noRecordsFound: "No Records Found",
    orgImage: "orgImage",
    fullName: "Full Name",
    emailForNotification: "Email for notification",
    nameForEmailNotification: "Name for email notification",
    businessOwner: "Business Owner",
    search: "Search",
    changePassword: "Change Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    raiseATicket: "Raise A Ticket",
    uploadImageText: "'Upload picture: png, jpeg'",
    browse: "Browse",
    showMore: "Show more",
    ok: "Ok",
    availableTables: "Available Tables",
    selectAll: "Select All",
    selectedTables: "Selected Tables",
    selectUser: "Select User",
    addTable: "Add Table",
    table: "Table",
    create: "Create",
    update: "Update",
    list: "List",
    all: "All",
    noContentFound: "No Content Found",
    view: "View",
    delete: "Delete",
    passwordError: "Password doesn't match",
    availableList: "Available List",
    selectedList: "Selected List",
    addCoManager: "Add Co Manager",
    addManager: "Add Manager",
    addMember: "Add Member",
    editMember: "Edit Member",
    type: "Type",
    actions: "Actions",
    addGroup: "Add Group",
    selectGroup: "Select Group",
    disabled: "Disabled",
    srno: "Sr. No.",
    required: "Field is Required",
  },
  epProfile: {
    profile: "Profile",
    changeOrganisation: "Change Organization",
    helpSupport: "Help & Support",
    logOut: "Log Out",
    salaryInfo: "Salary Info",
    designation: "Designation",
    personalEmail: "Personal Email",
    organizationEmail: "Organization Email",
    mobile: "Mobile",
    date: "Date",
    baseSalary: "Base Salary",
    bonus: "Bonus",
    frequency: "Frequency",
    bankingDetails: "Banking Details",
    contactDetails: "Contact Details",
    emergencyDetails: "Emergency Details",
    personalEducation: "Personal Education",
  },
  epHome: {
    awaitingReply: "Awaiting Reply",
    myCases: "My Cases",
    viewAll: "View all",
    upcomingBirthdays: "Upcoming Birthdays",
    myServiceTickets: "My Service Tickets",
    pendingApprovals: "My Pending Approvals",
    announcements: "Announcements",
    mostViewedArticles: "Most Viewed Articles",
    myArticles: "My Articles",
    staffPickedArticles: "Staff’s Pick Articles",
  },
  epKnowledge: {
    knowledge: "Knowledge",
    addKnowledge: "Add Knowledge",
    bodyPlaceholder: "Please enter description...",
    subtitle: "How can we help you ?",
    searchPlaceholder: "Search for knowledges...",
    mostViewedArticles: "Most Viewed Articles",
    recentlyUpdatedArticles: "Recently Updated Articles",
    myArticles: "My Articles",
    staffPickedArticles: "Staff’s Pick Articles",
    articles: "Articles",
    searchTopics: "Search for topics",
    approvals: "Approvals",
    approvalType: "Approval Type",
    allApproval: "All",
    anyoneApproval: "Any One",
    results: "Results",
    drafted: "Drafted",
    inreview: "In review",
    published: "Published",
    revised: "Revised",
    publishedArticles: "Published Articles",
    allArticles: "All Articles",
    approvalsArticles: "Knowledge Approvals",
    attachmentsIncluded: "Attachments included",
    advanceSearch: "Advance Search",
    apply: "Apply",
    appliedFilters: "Applied Filters",
    clearAll: "Clear all",
    edit: "Edit",
    actions: "Actions",
    reviewActionTitle: "Are you sure you want to move this article to Review?",
    approveActionTitle: "Are you sure you want to approve this article?",
    rejectActionTitle: "Are you sure you want to reject this article?",
    reviseActionTitle: "Are you sure you want to revise this article?",
    retireActionTitle: "Are you sure you want to retire this article?",
    removedFilters:
      "Previous applied filters have been removed as they are not associated to newly selected Knowledge Base",
  },
  apDashboardAdministration: {
    search: "Search Dashboard Configs",
    createTab: "Create Tab",
  },
  apDashboardTabs: {
    search: "Search Dashboard Tabs",
    createWidget: "Create Report",
  },
  apDashboardWidgets: {
    search: "Search Dashboard Widgets",
  },
  apKanbanConfig: {
    search: "Search Kanban Config",
  },
  apNotificationsDefinitions: {
    search: "Search Notifications Definations",
  },
  apUsers: {
    search: "Search User",
    unityAdmin: "Unity Admin",
    epAccess: "Employee Access",
    admin: "Admin",
    ogAdmin: "Organization Admin",
  },
  apEmploymentHistory: {
    search: "Search Employment History",
  },
  apProjectHistory: {
    search: "Project Employee Allocation",
  },
  apAddressHistory: {
    search: "Search Address History",
  },
  apSubscriber: {
    search: "Search Subscriber",
  },
  apServiceNowSolutions: {
    search: "Search Service Now Solutions",
  },
  apVariableSets: {
    search: "Search Variable Sets",
  },
  apResources: {
    search: "Search Resources",
  },
  apVariables: {
    search: "Search Variables",
  },
  apTags: {
    search: "Search Tags",
  },
  apWorkspace: {
    search: "Search Workspace",
  },
  apEducationDetails: {
    search: "Search Education Details",
  },
  apBenefits: {
    search: "Search Benefits",
  },
  apCatalogAdministrations: {
    search: "Search Catalog Administration",
  },
  apGroups: {
    search: "Search Groups",
  },
  apSPSPaperwork: {
    search: "Search Paperwork",
  },
  apNewsletterLeads: {
    search: "Search Newsletter Leads",
  },
  apCampaigns: {
    search: "Search Campaigns",
  },
  apCampaignsAssets: {
    search: "Search Campaigns Assets",
  },
  apLeadLists: {
    search: "Search Lead Lists",
  },
  apEmailSchedular: {
    search: "Search Email Schedular",
  },
  apContactUs: {
    search: "Search Contact Us",
  },
  apITNServices: {
    search: "Search Services",
  },
  apITNRFP: {
    search: "Search RFP",
  },
  apSPSAccountContact: {
    search: "Search Account Contact",
  },
  apSPSInsurance: {
    search: "Search Insurance",
  },
  apSPSProject: {
    search: "Search Projects",
  },
  apSPSSalesProfile: {
    search: "Search Sales Profile",
  },
  apJobsPosting: {
    search: "Search Jobs",
  },
  apSPSAccounts: {
    search: "Search Accounts",
  },
  apSPSBGCheck: {
    search: "Search BG Check",
  },
  apSPSDeals: {
    search: "Search Deals",
  },
  apAcademyCourses: {
    search: "Search Courses",
  },
  apAnnouncements: {
    search: "Search Announcements",
  },
  apAcademyBatch: {
    search: "Search Batch",
  },
  apAcademyStudentProfile: {
    search: "Search Learner Profile",
  },
  apAcademyCandidateEnrollments: {
    search: "Search Candidate",
    addPaymentDetails: "Add Payment Details",
    editPaymentDetails: "Edit Payment Details",
    taxTooltip: "IF GST/ Service Tax according to the country",
    nbfcTooltip: "Any non bank finanical charges",
  },
  apAcademyACH: {
    search: "Search ACH",
  },
  apAcademyMentorFeedback: { search: "Search Mentor's Feedback" },

  apAcademyCandidates: {
    search: "Search Candidates",
    graduationYearTooltip: "Put N/A if no graduation year",
    convertToLead: "Convert to Lead",
  },
  apAcademyCandidateLeads: {
    search: "Search Candidate Leads",
    graduationYearTooltip: "Put N/A if no graduation year",
    createEnrolment: "Create Enrollment",
    createACH: "Create Calls/Meetings",
  },
  apPlatformModules: {
    search: "Search Platform Modules",
  },
  apTask: {
    search: "Search Task",
  },
  apDepartments: {
    search: "Search Departments",
  },
  apEmployees: {
    search: "Search Employees",
    addSalary: "Add Salary",
    editSalary: "Edit Salary",
    addEmergencyContact: "Add Emergency Contact",
    editEmergencyContact: "Edit Emergency Contact",
    addAppraisalDetails: "Add Appraisal Details",
    editAppraisalDetails: "Edit Appraisal Details",
  },
  apDesignations: {
    search: "Search Designations",
  },
  apForm16: {
    search: "Search Form16",
  },
  apPIIDetails: {
    search: "Search PII",
  },
  apBankDetails: {
    search: "Search Bank",
  },
  apChecklistTemplate: {
    search: "Search Checklist",
  },
  apCountry: {
    search: "Search Country",
  },
  apCompany: {
    search: "Search Company",
  },
  apBugReporting: {
    search: "Search Bug Reporting",
  },
  apCompanyContact: {
    search: "Search Company Contact",
  },
  apCurrency: {
    search: "Search Currency",
  },
  apCategories: {
    search: "Search Categories",
  },
  apAttributes: {
    search: "Search Attributes",
  },
  apSubCategories: {
    search: "Search Sub Categories",
  },
  apSpace: {
    search: "Search Space",
  },
  apNewsletterResources: {
    search: "Search Newsletter Resources",
  },
  apKnowledge: {
    search: "Search Article",
  },
  apCertificationsAccreditations: {
    search: "Search Certifications",
  },
  emptyList: {
    noRecords: "No Records Found",
    noPermissions: "Number of rows removed from this list by Security Constraints",
  },

  apManageOrganizations: {
    packages: "Packages",
    activePackages: "Active Packages",
  },
  attachments: {
    title: "Attachments",
    noAttachments: "No Attachments Found",
    addAttachments:
      "Add attachments Supported Files:\ndoc, docx, ppt, pdf, png, jpeg, csv, xml, xslx",
  },
  filters: {
    apply: "Apply",
    close: "Close",
    clear: "Clear",
  },
};
