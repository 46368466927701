export const tableListSortIndex = {
  academyCourses: [
    "name",
    "active",
    "technology",
    "preparedBy",
    "curriculamAttached",
    "courseDuration",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  studentProfile: [
    "studentName",
    "studentID",
    "enrolledCourse",
    "studentOfficialEmail",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyCandidates: [
    "firstName",
    "lastName",
    // "active",
    "status",
    "course",
    "dob",
    "source",
    "admissionCounsellor",
    "graduationYear",
    "phone",
    "email",
    "country",
    "stage",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyCandidateLeads: [
    "firstName",
    "lastName",
    // "active",
    "status",
    "course",
    "dob",
    "source",
    "admissionCounsellor",
    "graduationYear",
    "phone",
    "email",
    "country",
    "stage",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyBatch: [
    "batchNumber",
    "courseOfferings",
    "state",
    "totalStudents",
    "plannedDuration",
    "dailyHoursEffort",
    "actualDuration",
    "plannedStartDate",
    "actualStartDate",
    "plannedEndDate",
    "actualEndDate",
    "scheduleTimeHealth",
    "overallHealth",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyCandidateEnrollments: [
    "studentName",
    "studentOfficialEmail",
    "studentID",
    "careerPath",
    "enrolledCourse",
    "candidateLead",
    "batchStartDate",
    "trainingDurations",
    "enrollmentStage",
    "paymentStatus",
    "totalFees",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  announcements: ["title", "active", "mesage"],
  academyACH: [
    "serial",
    "activityType",
    "state",
    "from",
    "to",
    "activityOwner",
    "activityDate",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyMentorFeedback: [
    "serial",
    "mentor",
    "studentProfile",
    "state",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
};

export const tableListDefaultSelectFields = {
  academyCourses: ["name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  academyCandidates: [
    "name",
    "email",
    "country",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyCandidateLeads: [
    "name",
    "email",
    "country",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyBatch: [
    "batchNumber",
    "courseOfferings",
    "organisation",
    "trainingCordinator",
    "state",
    "createdAt",
    "updatedAt",
  ],
  academyCandidateEnrollments: [
    "studentName",
    "enrolledCourse",
    "studentID",
    "createdAt",
    "updatedAt",
  ],
  announcements: [
    "title",
    "active",
    "type",
    "date",
    "order",
    "location",
    "host",
    "shortDescription",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
    "message",
  ],
  campaigns: ["campaign", "status", "createdAt", "createdBy", "updatedAt", "updatedBy", "message"],
  academyACH: ["serial", "activityType", "state", "from", "to", "activityOwner", "activityDate"],
  studentProfile: [
    "studentName",
    "studentID",
    "enrolledCourse",
    "studentOfficialEmail",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  academyMentorFeedback: [
    "serial",
    "mentor",
    "studentProfile",
    "state",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  users: ["name", "email", "organisations", "superAdmin", "active"],
  addressHistory: [
    //
    "country",
    "organisation",
    "associatedEmployee",
    "fromDate",
    "toDate",
    "postalCode",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],

  employmentHistory: [
    // "organisation",
    "associatedEmployee",
    "startDate",
    "endDate",
    "employmentType",
    "currentlyWorking",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],

  educationDetails: [
    "organisation",
    "associatedEmployee",
    "educationType",
    "fromDate",
    "toDate",
    "graduationDate",
    "country",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  form16: [
    "financialYear",
    "form16Attached",
    "associatedEmployee",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  subscriber: [
    "email",
    "active",
    "unsubscribedOnWhichResource",
    "unsubscribeTimestamp",
    "vertical",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  piiDetails: [
    "piiType",
    "number",
    "active",
    "associatedEmployee",
    "issueDate",
    "expiryDate",
    "requiredDocAttached",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  employeeBankDetails: [
    "bankName",
    "active",
    "associatedEmployee",
    "bankCountry",
    "bankCurrency",
    "accountType",
    "accountName",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  companyBankDetails: [
    "bankName",
    "active",
    "associatedCompany",
    "bankCountry",
    "bankCurrency",
    "accountType",
    "accountName",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  benefits: [
    "type",
    "associatedEmployee",
    "active",
    "amount",
    "issueDate",
    "expiryDate",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  newsletterResources: [
    "name",
    "status",
    "body",
    "subject",
    "approvalBy",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  newsletterLeads: [
    "firstName",
    "lastName",
    "email",
    "phone",
    "active",
    "unsubscribeCampaign",
    "unsubscribeTime",
    "jobTitle",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  projectHistory: [
    "projectCode",
    "associatedEmployee",
    "projectType",
    "projectTechnology",
    "startDate",
    "endDate",
    "projectStatus",
    "currentlyWorking",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  designations: [
    "name",
    "department",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  departments: [
    "name",
    "active",
    "departmentManager",
    "createdAt",
    "updatedAt",
    "createdBy",
    "updatedBy",
  ],
  employees: [
    "firstName",
    "middleName",
    "lastName",
    "personalEmail",
    "officialEmail",
    "mobile",
    "employmentStatus",
    "employmentType",
    "employmentDepartment",
    "employeeID",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsPaperwork: [
    "associatedAccount",
    "associatedDeal",
    "consultantName",
    "preferredName",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsInsurance: [
    "insuranceCompany",
    "associatedProject",
    // "associatedAccount",
    "accountTakenfor",
    "startDate",
    "renewDate",
    "amount",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsProject: [
    "projectName",
    "projectType",
    "projectCode",
    "associatedDeal",
    "platform",
    "legalName",
    "legalNameCode",
    "projectGivenTo",
    "rate",
    "billingCycle",
    "startDate",
    "expetectedLength",
    "expetectedEndDate",
    "projectStatus",
    // "modules",
    // "projectDescription",
    // "projectRole",
    // "haltReason",
    // "haltStartDate",
    // "haltEndDate",
    // "terminationReason",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsAccount: [
    "name",
    "type",
    "status",
    "industry",
    "size",
    "salesRepresentative",
    "country",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsAccountContact: [
    "associatedAccount",
    "firstName",
    "lastName",
    "role",
    "email",
    "mobile",
    "company",
    "status",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsSalesProfile: [
    "firstName",
    "lastName",
    "gmail",
    "gvoice",
    "referenceUsed",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsBGCheck: [
    "associatedAccount",
    "associatedDeal",
    "consultantName",
    "preferredName",
    "status",
    "newCompanyAppliedFor",
    "newPositionAppliedFor",
    "recruiterName",
    "email",
    "phone",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  spsDeals: [
    "SPSSalesProfile",
    "accountOwner",
    "legalAccountName",
    "preferredFullName",
    "rate",
    "type",
    "dealSource",
    "state",
    "endClient",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  // Dashboard
  dashboardAdministration: [
    "title",
    "description",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  dashboardTabs: [
    "title",
    "description",
    "active",
    "dashboard",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  dashboardWidgets: [
    "title",
    "description",
    "active",
    "type",
    "table",
    "dashboardTab",
    "dashboard",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  bugReporting: [
    "title",
    "assignedTo",
    "status",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  subCategories: [
    "name",
    "parentCategory",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  leadLists: ["name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  currency: ["_id", "name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  variableSets: [
    "_id",
    "name",
    "active",
    "order",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  variables: [
    "_id",
    "name",
    "active",
    "order",
    "type",
    "mandatory",
    "variableSets",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],

  resources: [
    "_id",
    "name",
    "shortDescription",
    "active",
    "state",
    "downloadAvailable",
    "publishedDate",
    "type",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],

  groups: ["_id", "name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  categories: ["_id", "name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  campaignsAssets: [
    "_id",
    "name",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  attributes: ["_id", "value", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  checklistTemplate: ["_id", "name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  tags: ["name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  country: ["_id", "name", "active", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  platformModules: [
    "name",
    "associatedTechnology",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  certificationsAccreditations: [
    "name",
    "type",
    "associatedEmployee",
    "number",
    "active",
    "issueDate",
    "expiryDate",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  emailSchedular: [
    "name",
    "active",
    "frequency",
    "time",
    "day",
    "date",
    "repeatInterval",
    "starting",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  companyContact: [
    "company",
    "firstName",
    "lastName",
    "role",
    "email",
    "phone",
    "status",
    "alternateEmail",
    "alternatePhone",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  jobsPosting: [
    "title",
    "summary",
    "salary",
    "type",
    "location",
    "sector",
    "contactName",
    "contactNumber",
    "contactEmail",
    "skills",
    "referenceId",
    "customDate",
    "active",
    "createdAt",
    "updatedAt",
  ],
  company: ["company", "createdAt", "createdBy", "updatedAt", "updatedBy"],
  hrTasks: [
    "associatedEmployee",
    "associatedProjectHistory",
    "number",
    "type",
    "assignedTo",
    "priority",
    "state",
    "urgency",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  achTasks: [
    "associatedCandidateLead",
    "associatedCandidateEnrolment",
    "associatedCandidateStudent",
    "associatedProjectHistory",
    "number",
    "type",
    "assignedTo",
    "priority",
    "state",
    "urgency",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  companyTasks: [
    "associatedCompany",
    "associatedProjectHistory",
    "number",
    "type",
    "assignedTo",
    "priority",
    "state",
    "urgency",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  contactUs: [
    "name",
    "email",
    "jobTitle",
    "company",
    "phone",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  itnServices: [
    "descriptionLabel",
    "description",
    "downloadButton",
    "active",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  itnRFP: [
    "fullName",
    "jobTitle",
    "businessEmail",
    "phone",
    "country",
    "companyName",
    "industry",
    "annualRevenue",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  serviceNowSolutions: [
    "name",
    "active",
    "fullName",
    "shortDescription",
    "technology",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  catalogAdministrations: [
    "title",
    "manager",
    "active",
    "categoryTitle",
    // "categoryIcon",
    "categoryCatalog",
    "categoryActive",
    "parentCategory",
    "description",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  kanbanConfig: [
    "title",
    "active",
    "description",
    "table",
    "columnField",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  workspaces: [
    "_id",
    "name",
    "description",
    "active",
    "fullAccess",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  space: [
    "_id",
    "name",
    "description",
    "active",
    "private",
    "workspace",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  notificationsDefinations: [
    "_id",
    "name",
    "description",
    "active",
    "insert",
    "update",
    "table",
    "triggered",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
  serviceRequests: [
    "number",
    "assignedTo",
    "priority",
    "state",
    "urgency",
    "description",
    "shortDescription",
    "holdReason",
    "stage",
    "dueDate",
    "createdAt",
    "createdBy",
    "updatedAt",
    "updatedBy",
  ],
};
