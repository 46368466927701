import { time } from "console";

import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createEmployee,
  // createEmployees,
  fetchControllerConfigs,
  fetchEmployees,
  fetchEmployeesById,
  // fetchEmployeesById,
  removeAppNotification,
  updateEmployee,
  updateEmployees,
  uploadFile,
} from "../../../../../redux";
import {
  CreateEmployeePayload,
  EmployeeRelatedUser,
  // CreateEmployeesPayload,
  EmployeesItem,
  FetchEmployeeByIdPayload,
  // FetchEmployeesByIdPayload,
  // CreateEmployeePayload,
} from "../../../../../redux/employees/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { daysBetweenDate } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  EmployeesForm,
  SelectOptions,
  TableHeaderButton,
  useEmployeesControllerProps,
  useEmployeesControllerReturnType,
} from "../../../../../types";
import {
  DashboardAdministrationFormDataType,
  Attachments,
  WorkLogItem,
} from "../../../../../types";
import { collections, emailRegex, phoneWithCountryCode, validatePhone } from "../../../../../utils";

interface EmploymentCompany {
  _id: string;
  company: string;
  website: string;
}

const addFormSteps = [
  "Overview",
  "Time Line Details",
  "Personal Information",
  "Reporting Details",
  "Additional Details",
];
const editFormSteps = [
  "Overview",
  "Time Line Details",
  "Personal Information",
  "Reporting Details",
  "Additional Details",
  "Salary Details",
  "Appraisals Date",
  "Emergency Contact",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "name",
  "email",
  "official_email",
  "mobile1",
  "firstName",
  "active",
  "attachments",
  "workLogs",
  "photo",
  "middleName",
  "lastName",
  "nickName",
  "personalEmail",
  "officialEmail",
  "mobile",
  "alternateMobile",
  "employmentStatus",
  "generatePayrollOffshoreServices",
  "employmentType",
  "employmentDepartment",
  "employmentCompany",
  "employeeID",
  "payrollCompany",
  "employeeIDofPayrollCompany",
  "shirtSize",
  "offboardType",
  "detailsForOffboard",
  "resumeAttached",
  "coverLettersAttached",
  "consultantProfile",
];
const timelineFields = [
  "dateJoining",
  "dateHire",
  "dateTermination",
  "probationStartDate",
  "probationEndDate",
];
const personalDetailsFields = [
  "personalMartial",
  "nationality",
  "personalDOB",
  "personalGender",
  "ethnicity",
  "race",
  "placeOfBirth",
  "countryOfBirth",
  "fatherName",
  "motherName",
  "addressLineOne",
  "addressLineTwo",
  "city",
  "state",
  "zipCode",
  "Country",
];
const reportingDetailsFields = ["managerLevel", "reportingManager", "reportingPOD", "companyBuddy"];
const additionalDetailsFields = [
  "externalFolderLink",
  "isEmployeeReferenced",
  "referralName",
  "referralMobile",
  "referralEmail",
];

export const useEmployeesController = ({
  type,
  id,
  attachmentsPickerRef,
  profilePicPickerRef,
}: useEmployeesControllerProps): useEmployeesControllerReturnType => {
  const defaultFormData: EmployeesForm = {
    firstName: {
      value: "",
      required: true,
      error: "",
    },

    middleName: {
      value: "",
      required: false,
      error: "",
    },
    lastName: {
      value: "",
      required: true,
      error: "",
    },
    nickName: {
      value: "",
      required: false,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    personalEmail: {
      value: "",
      required: true,
      error: "",
    },
    officialEmail: {
      value: "",
      required: true,
      error: "",
    },
    mobile: {
      value: "",
      required: true,
      error: "",
    },
    alternateMobile: {
      value: "",
      required: false,
      error: "",
    },
    employmentStatus: {
      value: "",
      required: false,
      error: "",
    },
    generatePayrollOffshoreServices: {
      value: false,
      required: false,
      error: "",
    },
    employmentType: {
      value: "",
      required: true,
      error: "",
    },
    employmentDepartment: {
      value: "",
      required: true,
      error: "",
    },
    photo: {
      value: {
        _id: "",
        location: "",
      },
      required: false,
      error: "",
    },
    employmentCompany: {
      value: "",
      required: true,
      error: "",
    },
    employeeID: {
      value: "",
      required: true,
      error: "",
    },
    payrollCompany: {
      value: "",
      required: false,
      error: "",
    },
    employeeIDofPayrollCompany: {
      value: "",
      required: false,
      error: "",
    },
    shirtSize: {
      value: "",
      required: false,
      error: "",
    },
    offboardType: {
      value: "",
      required: false,
      error: "",
    },
    detailsForOffboard: {
      value: "",
      required: false,
      error: "",
    },
    resumeAttached: {
      value: false,
      required: false,
      error: "",
    },
    coverLettersAttached: {
      value: false,
      required: false,
      error: "",
    },
    consultantProfile: {
      value: false,
      required: false,
      error: "",
    },
    timelineDetails: {
      dateJoining: {
        value: null,
        required: true,
        error: "",
      },
      dateHire: {
        value: null,
        required: true,
        error: "",
      },
      dateTermination: {
        value: null,
        required: false,
        error: "",
      },
      probationStartDate: {
        value: null,
        required: false,
        error: "",
      },
      probationEndDate: {
        value: null,
        required: false,
        error: "",
      },
    },
    personalDetails: {
      personalMartial: {
        value: "",
        required: false,
        error: "",
      },
      personalDOB: {
        value: null,
        required: true,
        error: "",
      },
      nationality: {
        value: "",
        required: false,
        error: "",
      },
      personalGender: {
        value: "",
        required: false,
        error: "",
      },
      ethnicity: {
        value: "",
        required: false,
        error: "",
      },
      race: {
        value: "",
        required: false,
        error: "",
      },
      placeOfBirth: {
        value: "",
        required: false,
        error: "",
      },
      countryOfBirth: {
        value: "",
        required: false,
        error: "",
      },
      fatherName: {
        value: "",
        required: false,
        error: "",
      },
      motherName: {
        value: "",
        required: false,
        error: "",
      },
      addressLineOne: {
        value: "",
        required: true,
        error: "",
      },
      addressLineTwo: {
        value: "",
        required: false,
        error: "",
      },
      city: {
        value: "",
        required: true,
        error: "",
      },
      state: {
        value: "",
        required: true,
        error: "",
      },
      zipCode: {
        value: null,
        required: true,
        error: "",
      },
      Country: {
        value: "",
        required: true,
        error: "",
      },
    },
    designations: {
      value: [],
      required: false,
      error: "",
    },
    appraisals: {
      value: [],
      required: false,
      error: "",
    },
    reportingDetails: {
      managerLevel: {
        value: "",
        required: false,
        error: "",
      },
      reportingManager: {
        value: "",
        required: false,
        error: "",
      },
      reportingPOD: {
        value: "",
        required: false,
        error: "",
      },
      companyBuddy: {
        value: "",
        required: false,
        error: "",
      },
    },
    emergencyContact: {
      value: [],
      required: false,
      error: "",
    },
    additionalDetails: {
      externalFolderLink: {
        value: "",
        required: false,
        error: "",
      },
      isEmployeeReferenced: {
        value: false,
        required: false,
        error: "",
      },
      referralName: {
        value: "",
        required: false,
        error: "",
      },
      referralMobile: {
        value: "",
        required: false,
        error: "",
      },
      referralEmail: {
        value: "",
        required: false,
        error: "",
      },
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    createdAt: {
      value: "",
      required: false,
      error: "",
    },
    updatedAt: {
      value: "",
      required: false,
      error: "",
    },
    createdBy: {
      value: {
        _id: "",
        name: "",
      },
      required: false,
      error: "",
    },
    updatedBy: {
      value: {
        _id: "",
        name: "",
      },
      required: false,
      error: "",
    },
  };

  const users = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.users,
  );
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const currentUser = useAppSelector((state) => state.UserReducer);
  const employees = useAppSelector((state) => state.EmployeesReducer.data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<EmployeesForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [photoUploading, setPhotouploading] = useState<string>("");
  const [employmentCompany, setEmploymentCompany] = useState<EmploymentCompany>({
    _id: "",
    company: "",
    website: "",
  });

  const [data, setData] = useState<EmployeesItem>();

  const [relatedUser, setRelatedUser] = useState<EmployeeRelatedUser>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    const {
      bankdetails,
      piiDetails,
      certificationsAccreditations,
      educationDetails,
      addressHistory,
      employmentHistory,
      benefits,
      projectHistory,
      form16,
      academyACH,
      academyBatch,
      academyCandidateEnrollments,
      academyCandidates,
      academyCandidateLeads,
      academyCourses,
      academyMentorFeedback,
      academyStudentProfile,
      spsAccounts,
      spsProjects,
    } = collections;
    return [
      bankdetails.label,
      piiDetails.label,
      certificationsAccreditations.label,
      educationDetails.label,
      addressHistory.label,
      employmentHistory.label,
      benefits.label,
      projectHistory.label,
      "HR Tasks",
      form16.label,
      academyACH.label,
      academyBatch.label,
      academyCandidates.label,
      academyCandidateLeads.label,
      academyCourses.label,
      academyMentorFeedback.label,
      academyStudentProfile.label,
      spsAccounts.label,
      spsProjects.label,
    ];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const clearFormData = useCallback(() => {
    setFormData(defaultFormData);
  }, [defaultFormData]);

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const employeeFullName = useMemo(() => {
    const fullName: string[] = [];
    const { firstName, middleName, lastName } = formData;
    if (firstName.value) {
      fullName.push(firstName.value);
    }
    if (middleName.value) {
      fullName.push(middleName.value);
    }
    if (lastName.value) {
      fullName.push(lastName.value);
    }
    if (fullName.length) {
      return fullName.join(" ");
    }
    return formData?.name?.value || "";
  }, [formData]);

  const emailSignature = useMemo(() => {
    const { firstName, lastName, mobile, officialEmail, designations } = formData;
    const designation = designations.value?.[designations?.value.length - 1]?.designation?.name;
    return `${firstName.value} ${lastName.value}\n${designation} | ${employmentCompany.company}\nContact: ${mobile.value} | Email: ${officialEmail.value}\n${employmentCompany.website}`;
  }, [employmentCompany.company, employmentCompany.website, formData]);

  const employmentDuration = useMemo(() => {
    const { dateJoining, dateTermination } = formData.timelineDetails;
    const currentDate = dateTermination.value ? new Date(dateTermination.value) : new Date();
    const joiningDate = new Date(dateJoining.value);
    let duration = daysBetweenDate(joiningDate, currentDate);
    if (!dateJoining.value) {
      duration = 0;
    }
    return `${duration} days`;
  }, [formData]);

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      if (personalDetailsFields.includes(name)) {
        tempFormData = {
          ...tempFormData,
          personalDetails: {
            ...tempFormData.personalDetails,
            [name]: {
              ...tempFormData[name],
              value,
              error: "",
            },
          },
        };
      } else if (reportingDetailsFields.includes(name)) {
        tempFormData = {
          ...tempFormData,
          reportingDetails: {
            ...tempFormData.reportingDetails,
            [name]: {
              ...tempFormData[name],
              value,
              error: "",
            },
          },
        };
      } else if (additionalDetailsFields.includes(name)) {
        let additionalDetails = { ...tempFormData.additionalDetails };
        if (name === "isEmployeeReferenced") {
          if (value) {
            additionalDetails = {
              ...additionalDetails,
              referralEmail: {
                ...additionalDetails.referralEmail,
                required: true,
              },
              referralMobile: {
                ...additionalDetails.referralMobile,
                required: true,
              },
              referralName: {
                ...additionalDetails.referralName,
                required: true,
              },
            };
          } else {
            additionalDetails = {
              ...additionalDetails,
              referralEmail: {
                ...additionalDetails.referralEmail,
                required: false,
                value: "",
                error: "",
              },
              referralMobile: {
                ...additionalDetails.referralMobile,
                required: false,
                value: "",
                error: "",
              },
              referralName: {
                ...additionalDetails.referralName,
                required: false,
                value: "",
                error: "",
              },
            };
          }
        }
        additionalDetails = {
          ...additionalDetails,
          ...additionalDetails,
          [name]: {
            ...additionalDetails[name],
            value,
            error: "",
          },
        };
        tempFormData.additionalDetails = additionalDetails;
      } else {
        tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        console.log(name, formData[name]?.value, value);
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      let tempFormData = { ...formData };
      if (timelineFields.includes(name)) {
        let timelineDetails = { ...tempFormData.timelineDetails };
        timelineDetails = {
          ...timelineDetails,
          [name]: {
            ...timelineDetails[name],
            value,
            error: "",
          },
        };
        console.log("Timeline ::", timelineDetails);
        tempFormData.timelineDetails = timelineDetails;
      } else if (personalDetailsFields.includes(name)) {
        tempFormData = {
          ...tempFormData,
          personalDetails: {
            ...tempFormData.personalDetails,
            [name]: {
              ...tempFormData[name],
              value,
              error: "",
            },
          },
        };
      } else {
        tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );
  const departmentManagerOptions = useMemo(() => {
    const data: SelectOptions[] = [];
    users.map((item) => {
      if (item.active) {
        data.push({
          label: item.name || "",
          value: item._id,
        });
      }
    });
    return data;
  }, [users]);

  const rehydrateFormData = useCallback(
    (item: EmployeesItem) => {
      try {
        const data = { ...formData };
        const tempAttachments: Attachments[] = [];
        Object.keys(formData).map((field) => {
          let value = item?.[field];
          if (["offboardType"].includes(field)) {
            value = item?.[field]?._id || "";
          }
          if (
            [
              "employmentStatus",
              "employmentType",
              "employmentDepartment",
              "employmentCompany",
              "payrollCompany",
              "shirtSize",
            ].includes(field)
          ) {
            value = item?.[field]?._id;
          } else if (field === "timelineDetails") {
            let timelineDetails = { ...formData.timelineDetails };
            Object.keys(item?.timelineDetails).map((childItem) => {
              timelineDetails = {
                ...timelineDetails,
                [childItem]: {
                  ...timelineDetails[childItem],
                  value: item?.timelineDetails[childItem],
                },
              };
            });
            data.timelineDetails = timelineDetails;
          } else if (field === "personalDetails") {
            let personalDetails = { ...formData.personalDetails };
            Object.keys(item?.personalDetails).map((childItem) => {
              let value = item?.personalDetails[childItem];
              if (
                [
                  "countryOfBirth",
                  "Country",
                  "Country",
                  "personalMartial",
                  "nationality",
                  "personalGender",
                  "ethnicity",
                  "race",
                ].includes(childItem)
              ) {
                value = item.personalDetails?.[childItem]?._id;
              }
              personalDetails = {
                ...personalDetails,
                [childItem]: {
                  ...personalDetails[childItem],
                  value: value,
                },
              };
            });
            data.personalDetails = personalDetails;
          } else if (field === "reportingDetails") {
            let reportingDetails = { ...formData.reportingDetails };
            Object.keys(item?.reportingDetails).forEach((childItem) => {
              reportingDetails = {
                ...reportingDetails,
                [childItem]: {
                  ...reportingDetails[childItem],
                  value: item?.reportingDetails[childItem]?._id,
                },
              };
            });
            data.reportingDetails = reportingDetails;
          } else if (field === "additionalDetails") {
            let additionalDetails = { ...formData.additionalDetails };
            Object.keys(item?.additionalDetails).map((childItem) => {
              additionalDetails = {
                ...additionalDetails,
                [childItem]: {
                  ...additionalDetails[childItem],
                  value: item?.additionalDetails[childItem],
                },
              };
            });
            data.additionalDetails = additionalDetails;
          } else if (field === "attachments") {
            for (let i = 0; i < value?.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
                extension: value[i]?.extension,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
          }
          data[field].value = value;
        });
        setEmploymentCompany({ ...item?.employmentCompany });
        setFormData(data);
      } catch (e) {
        console.log(e);
      }
    },
    [formData],
  );
  const fetchEmployeeDetails = useCallback(() => {
    setLoading(true);
    const payload: FetchEmployeeByIdPayload = {
      id: id || "",
      onSuccess: ({ data, relatedUser }) => {
        setLoading(false);
        console.log(data, relatedUser);
        setRelatedUser(relatedUser);
        console.log("ds ::", data, relatedUser);
        rehydrateFormData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchEmployeesById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (
              field === "detailsForOffboard" &&
              !formData.detailsForOffboard.value &&
              formData.offboardType.value
            ) {
              count += 1;
            }
            if (formData?.[field]?.error) {
              count += 1;
            }
          });
          break;
        case 1:
          timelineFields.map((field) => {
            if (formData.timelineDetails?.[field]?.error) {
              count += 1;
            }
          });
          break;
        case 2:
          personalDetailsFields.map((field) => {
            if (formData.personalDetails?.[field]?.error) {
              count += 1;
            }
          });
          break;
        case 4:
          additionalDetailsFields.map((field) => {
            if (formData.additionalDetails?.[field]?.error) {
              console.log(field);
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    const { coverLettersAttached, resumeAttached, attachments } = formData;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;

      if (
        currentField.required &&
        (validValue === undefined ||
          validValue === null ||
          validValue === "" ||
          (typeof validValue === "string" && validValue.trim() === ""))
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else if (
        item === "detailsForOffboard" &&
        !formData.detailsForOffboard.value &&
        formData.offboardType.value
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
      // Add other conditions as needed
    });

    // Timeline details
    let timelineDetails = { ...tempFormData.timelineDetails };
    Object.keys(tempFormData.timelineDetails).forEach((item) => {
      const currentField = tempFormData.timelineDetails[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        timelineDetails = {
          ...timelineDetails,
          [item]: {
            ...timelineDetails[item],
            error: "Required Field",
          },
        };
      }
    });
    tempFormData.timelineDetails = timelineDetails;

    // Additional details
    let additionalDetails = { ...tempFormData.additionalDetails };
    Object.keys(tempFormData.additionalDetails).forEach((item) => {
      const currentField = tempFormData.additionalDetails[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (item === "externalFolderLink" && validValue && !validator.isURL(validValue || "")) {
        valid = false;
        additionalDetails = {
          ...additionalDetails,
          [item]: {
            ...tempFormData[item],
            error: "Please enter a valid URL",
          },
        };
      }
      if (
        currentField.required &&
        item === "referralEmail" &&
        !String(validValue).match(emailRegex)
      ) {
        valid = false;
        additionalDetails = {
          ...additionalDetails,
          [item]: {
            ...additionalDetails[item],
            error: "Please enter a valid email",
          },
        };
      } else if (
        currentField.required &&
        ["referralMobile"].includes(item) &&
        !validatePhone(String(validValue))
      ) {
        valid = false;
        additionalDetails = {
          ...additionalDetails,
          [item]: {
            ...additionalDetails[item],
            error: "Please enter a valid phone number with a country code",
          },
        };
      } else if (currentField.required && !validValue) {
        valid = false;
        additionalDetails = {
          ...additionalDetails,
          [item]: {
            ...additionalDetails[item],
            error: "Required Field",
          },
        };
      }
    });
    tempFormData.additionalDetails = additionalDetails;

    // Personal details
    let personalDetails = { ...tempFormData.personalDetails };
    Object.keys(tempFormData.personalDetails).forEach((item) => {
      const currentField = tempFormData.personalDetails[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        personalDetails = {
          ...personalDetails,
          [item]: {
            ...personalDetails[item],
            error: "Required Field",
          },
        };
      }
    });
    tempFormData.personalDetails = personalDetails;

    if ((coverLettersAttached.value || resumeAttached.value) && !attachments.value.length) {
      valid = false;
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Upload the required attachment/document.",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    }

    setFormData(tempFormData);
    return valid;
  }, [dispatch, formData, notificationsId]);

  const onSubmitAdd = useCallback(() => {
    const {
      firstName,
      active,
      workLogs,
      photo,
      middleName,
      lastName,
      nickName,
      personalEmail,
      officialEmail,
      mobile,
      alternateMobile,
      employmentStatus,
      generatePayrollOffshoreServices,
      employmentType,
      employmentDepartment,
      employmentCompany,
      employeeID,
      payrollCompany,
      employeeIDofPayrollCompany,
      shirtSize,
      offboardType,
      detailsForOffboard,
      resumeAttached,
      coverLettersAttached,
      consultantProfile,
      timelineDetails,
      personalDetails,
      designations,
      appraisals,
      reportingDetails,
      additionalDetails,
    } = formData;

    const { dateJoining, dateHire, dateTermination, probationEndDate, probationStartDate } =
      timelineDetails;
    const {
      personalMartial,
      nationality,
      personalDOB,
      personalGender,
      ethnicity,
      race,
      placeOfBirth,
      countryOfBirth,
      fatherName,
      motherName,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
      Country,
    } = personalDetails;

    const { managerLevel, reportingManager, reportingPOD, companyBuddy } = reportingDetails;

    const {
      externalFolderLink,
      isEmployeeReferenced,
      referralEmail,
      referralMobile,
      referralName,
    } = additionalDetails;
    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateEmployeePayload = {
        data: {
          name: `${firstName.value || ""} ${middleName.value || ""} ${lastName.value || ""}`,
          photo: photo.value._id,
          attachments: attachments,
          firstName: firstName.value,
          middleName: middleName.value,
          lastName: lastName.value,
          nickName: nickName.value,
          active: active.value,
          personalEmail: personalEmail.value,
          officialEmail: officialEmail.value,
          mobile: mobile.value,
          alternateMobile: alternateMobile.value,
          employmentStatus: employmentStatus.value,
          generatePayrollOffshoreServices: generatePayrollOffshoreServices.value,
          employmentType: employmentType.value,
          employmentDepartment: employmentDepartment.value,
          employmentCompany: employmentCompany.value,
          employeeID: employeeID.value,
          payrollCompany: payrollCompany.value,
          employeeIDofPayrollCompany: employeeIDofPayrollCompany.value,
          shirtSize: shirtSize.value,
          offboardType: offboardType.value,
          detailsForOffboard: offboardType.value ? detailsForOffboard.value : "",
          resumeAttached: resumeAttached.value,
          coverLettersAttached: coverLettersAttached.value,
          consultantProfile: consultantProfile.value,
          timelineDetails: {
            dateJoining: dateJoining.value,
            dateHire: dateHire.value,
            dateTermination: dateTermination.value,
            probationStartDate: probationStartDate.value,
            probationEndDate: probationEndDate.value,
          },
          personalDetails: {
            personalMartial: personalMartial.value,
            personalDOB: personalDOB.value,
            nationality: nationality.value,
            personalGender: personalGender.value,
            ethnicity: ethnicity.value,
            race: race.value,
            placeOfBirth: placeOfBirth.value,
            countryOfBirth: countryOfBirth.value,
            fatherName: fatherName.value,
            motherName: motherName.value,
            addressLineOne: addressLineOne.value,
            addressLineTwo: addressLineTwo.value,
            city: city.value,
            state: state.value,
            zipCode: zipCode.value,
            Country: Country.value,
          },
          reportingDetails: {
            managerLevel: managerLevel.value,
            reportingManager: reportingManager.value,
            reportingPOD: reportingPOD.value,
            companyBuddy: companyBuddy.value,
          },
          additionalDetails: {
            externalFolderLink: externalFolderLink.value,
            isEmployeeReferenced: isEmployeeReferenced.value,
            referralName: referralName.value,
            referralMobile: referralMobile.value,
            referralEmail: referralEmail.value,
          },
        },

        onSuccess: (data: EmployeesItem) => {
          setFormData(defaultFormData);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employee created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(`/ap/employees/${data}`);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createEmployee(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, defaultFormData, navigate]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: CreateEmployeePayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "EmploymentHistory updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateEmployees(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const onSubmitUpdate = useCallback(() => {
    const {
      firstName,
      active,
      workLogs,
      photo,
      middleName,
      lastName,
      nickName,
      personalEmail,
      officialEmail,
      mobile,
      alternateMobile,
      employmentStatus,
      generatePayrollOffshoreServices,
      employmentType,
      employmentDepartment,
      employmentCompany,
      employeeID,
      payrollCompany,
      employeeIDofPayrollCompany,
      shirtSize,
      offboardType,
      detailsForOffboard,
      resumeAttached,
      coverLettersAttached,
      consultantProfile,
      timelineDetails,
      personalDetails,
      designations,
      appraisals,
      reportingDetails,
      additionalDetails,
    } = formData;

    const { dateJoining, dateHire, dateTermination, probationEndDate, probationStartDate } =
      timelineDetails;
    const {
      personalMartial,
      nationality,
      personalDOB,
      personalGender,
      ethnicity,
      race,
      placeOfBirth,
      countryOfBirth,
      fatherName,
      motherName,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
      Country,
    } = personalDetails;

    const { managerLevel, reportingManager, reportingPOD, companyBuddy } = reportingDetails;

    const {
      externalFolderLink,
      isEmployeeReferenced,
      referralEmail,
      referralMobile,
      referralName,
    } = additionalDetails;
    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateEmployeePayload = {
        id: id || "",
        data: {
          name: `${firstName.value || ""} ${middleName.value || ""} ${lastName.value || ""}`,
          photo: photo?.value?._id,
          attachments: attachments,
          firstName: firstName.value,
          middleName: middleName.value,
          lastName: lastName.value,
          nickName: nickName.value,
          active: active.value,
          personalEmail: personalEmail.value,
          officialEmail: officialEmail.value,
          mobile: mobile.value,
          alternateMobile: alternateMobile.value,
          employmentStatus: employmentStatus.value,
          generatePayrollOffshoreServices: generatePayrollOffshoreServices.value,
          employmentType: employmentType.value,
          employmentDepartment: employmentDepartment.value,
          employmentCompany: employmentCompany.value,
          employeeID: employeeID.value,
          payrollCompany: payrollCompany.value,
          employeeIDofPayrollCompany: employeeIDofPayrollCompany.value,
          shirtSize: shirtSize.value,
          offboardType: offboardType.value,
          detailsForOffboard: offboardType.value ? detailsForOffboard.value : "",
          resumeAttached: resumeAttached.value,
          coverLettersAttached: coverLettersAttached.value,
          consultantProfile: consultantProfile.value,
          timelineDetails: {
            dateJoining: dateJoining.value,
            dateHire: dateHire.value,
            dateTermination: dateTermination.value,
            probationStartDate: probationStartDate.value,
            probationEndDate: probationEndDate.value,
          },
          personalDetails: {
            personalMartial: personalMartial.value,
            personalDOB: personalDOB.value,
            nationality: nationality.value,
            personalGender: personalGender.value,
            ethnicity: ethnicity.value,
            race: race.value,
            placeOfBirth: placeOfBirth.value,
            countryOfBirth: countryOfBirth.value,
            fatherName: fatherName.value,
            motherName: motherName.value,
            addressLineOne: addressLineOne.value,
            addressLineTwo: addressLineTwo.value,
            city: city.value,
            state: state.value,
            zipCode: zipCode.value,
            Country: Country.value,
          },
          reportingDetails: {
            managerLevel: managerLevel.value,
            reportingManager: reportingManager.value,
            reportingPOD: reportingPOD.value,
            companyBuddy: companyBuddy.value,
          },
          additionalDetails: {
            externalFolderLink: externalFolderLink.value,
            isEmployeeReferenced: isEmployeeReferenced.value,
            referralName: referralName.value,
            referralMobile: referralMobile.value,
            referralEmail: referralEmail.value,
          },
        },

        onSuccess: (data: EmployeesItem) => {
          console.log("Meta", employees);
          const params = { page: employees?.metaData?.currentPage || 1, limit: 10 };
          const payload = {
            params,
          };
          dispatch(fetchEmployees(payload));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employee updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateEmployee(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, id, employees.metaData]);

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = employeeFullName;
    }
    return [strings.headerTitle.employees, title];
  }, [employeeFullName, type]);
  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef?.current?.click();
  }, [attachmentsPickerRef]);

  const onClickBrowseProfilePic = useCallback(() => {
    // profilePicPickerRef.current?.click();
  }, [profilePicPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const onProfilePicChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      console.log(file);
      const params = {
        file,
        type: "public",
        onSuccess: ({ id, location }) => {
          setPhotouploading("");
          const profilePic = { _id: id, location };
          const tempFormData = {
            ...formData,
            photo: {
              ...formData.photo,
              value: profilePic,
            },
          };
          setFormData(tempFormData);
        },
        onError: (err) => {
          setPhotouploading("");
        },
        onProgress: (progress) => {
          setPhotouploading(`${progress}%`);
        },
      };
      console.log(params);
      dispatch(uploadFile(params));
    },
    [dispatch, formData],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  return {
    clearFormData,
    relatedUser,
    handleCheckbox,
    employeeFullName,
    fetchEmployeeDetails,
    employmentDuration,
    emailSignature,
    onProfilePicChange,
    onClickBrowseProfilePic,
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    photoUploading,
    headerButtons,
  };
};
