import Types from "./actionType";
import {
  FetchBankDetailsPayload,
  CreateBankDetails,
  CreateBankDetailsPayload,
  BankDetailsList,
  FetchBankDetails,
  FetchBankDetailsError,
  FetchBankDetailsSuccess,
  UpdateBankDetails,
  UpdateBankDetailsPayload,
  BankDetailsListWithMetaData,
  FetchBankDetailsByIdPayload,
  FetchBankDetailsById,
  DeleteBankDetailsPayload,
  DeleteBankDetails,
} from "./types";

export const fetchBankDetailsList = ({
  params,
  onSuccess,
  onError,
}: FetchBankDetailsPayload): FetchBankDetails => ({
  type: Types.FETCH_BANKDETAILS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchBankDetailsById = ({
  id,
  onSuccess,
  onError,
}: FetchBankDetailsByIdPayload): FetchBankDetailsById => ({
  type: Types.FETCH_BANKDETAILS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchBankDetailsListSuccess = (
  data: BankDetailsListWithMetaData,
): FetchBankDetailsSuccess => ({
  type: Types.SUCCESS_FETCH_BANKDETAILS,
  payload: {
    data,
  },
});

export const fetchBankDetailsError = (error?: string): FetchBankDetailsError => ({
  type: Types.ERROR_FETCH_BANKDETAILS,
  payload: { error },
});

export const createBankDetails = ({
  data,
  onSuccess,
  onError,
}: CreateBankDetailsPayload): CreateBankDetails => ({
  type: Types.CREATE_BANKDETAILS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateBankDetails = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateBankDetailsPayload): UpdateBankDetails => ({
  type: Types.UPDATE_BANKDETAILS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteBankDetails = ({
  id,
  onSuccess,
  onError,
}: DeleteBankDetailsPayload): DeleteBankDetails => ({
  type: Types.DELETE_BANKDETAILS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
