import styled from "styled-components";

import { fonts } from "../../../../theme";

export const NotificationTitle = styled.div`
  font-size: 18px;
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
`;

export const CloseIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 20px;
`;
