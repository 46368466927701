import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

interface Props {
  gradientOne?: string;
  gradientTwo?: string;
}

export const ListWidgetTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  font-size: ${fs.m};
  color: ${colors.common.black};
`;

export const ListWidgetTitleContainer = styled.div`
  width: 100%;
  height: 57px;
  padding: 0 2%;
  background-color: ${colors.common.secondaryWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
`;
export const ListWidgetRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalItemsTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  cursor: pointer;
  font-size: ${fs.s};
  color: ${colors.common.black};
  margin-right: 20px;
`;

export const RefreshIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: solid 1px ${colors.common.borderColor};
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${colors.common.activeTabBackground};
  }
`;
export const ListWidgetIcon = styled.img`
  height: 14px;
  width: 14px;
`;

export const CountItemcontainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0.3px solid rgba(0, 0, 0, 0.75);
`;

export const CountItem = styled.div<Props>`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    `linear-gradient(112.28deg, ${props.gradientOne} -4.55%, ${props.gradientTwo} 115.24%)`};
  box-sizing: border-box;
  padding: 10%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const CountIcon = styled.img`
  position: absolute;
  width: 60%;
  height: 80%;
  object-fit: contain;
  top: 0;
  right: 0;
  background: green;
`;

export const Count = styled.text`
  font-family: ${fonts.OpenSans};
  font-size: ${fs.xxxl};
  color: ${colors.common.titleColor};
  margin-top: 6%;
`;

export const Icon = styled.img`
  width: 47px;
  height: 47px;
  object-fit: contain;
  margin-left: -4px;
`;

export const Title = styled.h1`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.l};
  color: ${colors.common.titleColor};
`;

export const ViewAllButton = styled.div`
  display: flex;
  background-color: ${colors.common.white};
  padding: 2% 3%;
  width: fit-content;
  font-size: ${fs.s};
  font-family: ${fonts.SourceSansPro};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  cursor: pointer;
`;
