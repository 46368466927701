import Types from "./actionType";
import {
  FetchTaskPayload,
  CreateTask,
  CreateTaskPayload,
  TaskList,
  FetchTask,
  FetchTaskError,
  FetchTaskSuccess,
  UpdateTask,
  UpdateTaskPayload,
  TaskListWithMetaData,
  FetchTaskByIdPayload,
  FetchTaskById,
} from "./types";

export const fetchTaskList = ({ params, onSuccess, onError }: FetchTaskPayload): FetchTask => ({
  type: Types.FETCH_LEAVES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchTaskById = ({
  associatedTo,
  id,
  onSuccess,
  onError,
}: FetchTaskByIdPayload): FetchTaskById => ({
  type: Types.FETCH_LEAVES_BY_ID,
  payload: {
    associatedTo,
    id,
    onSuccess,
    onError,
  },
});

export const fetchTaskListSuccess = (data: TaskListWithMetaData): FetchTaskSuccess => ({
  type: Types.SUCCESS_FETCH_LEAVES,
  payload: {
    data,
  },
});

export const fetchTaskError = (error?: string): FetchTaskError => ({
  type: Types.ERROR_FETCH_LEAVES,
  payload: { error },
});

export const createTask = ({
  associatedTo,
  data,
  onSuccess,
  onError,
}: CreateTaskPayload): CreateTask => ({
  type: Types.CREATE_LEAVES,
  payload: {
    data,
    associatedTo,
    onSuccess,
    onError,
  },
});

export const updateTask = ({
  associatedTo,
  id,
  data,
  onSuccess,
  onError,
}: UpdateTaskPayload): UpdateTask => ({
  type: Types.UPDATE_LEAVES,
  payload: {
    associatedTo,
    id,
    data,
    onSuccess,
    onError,
  },
});
