/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPS_DEALS = "FETCH_SPS_DEALS",
  FETCH_SPS_DEALS_BY_ID = "FETCH_SPS_DEALS_BY_ID",
  SUCCESS_FETCH_SPS_DEALS = "SUCCESS_FETCH_SPS_DEALS",
  ERROR_FETCH_SPS_DEALS = "ERROR_FETCH_SPS_DEALS",
  CREATE_SPS_DEALS = "CREATE_SPS_DEALS",
  UPDATE_SPS_DEALS = "UPDATE_SPS_DEALS",
  DELETE_SPS_DEALS = "DELETE_SPS_DEALS",
}

export default Types;
