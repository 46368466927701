import React from "react";

import { Layout } from "../../../presentationals";
import { ServiceRequestsController } from "../../../presentationals/screens";

export const APServiceRequestsEdit = () => {
  return (
    <Layout activity={<ServiceRequestsController type={"edit"} />} admin={true} layout={"2"} />
  );
};
