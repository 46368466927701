import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  CompanyList,
  AddCompany,
  UpdateCompany,
  FindCompany,
  DeleteCompany,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchCompanyListSuccess, fetchCompanyError } from "./actions";
import Types from "./actionType";
import {
  CreateCompanyuccess,
  CreateCompany as CreateCompanyType,
  CompanySuccess,
  UpdateCompany as UpdateCompanyType,
  FetchCompany,
  FetchCompanyById,
  CompanyByIdSuccess,
  DeleteCompany as DeleteCompanyType,
} from "./types";

function* createWorker(action: CreateCompanyType) {
  try {
    const options = {
      method: "POST",
      url: AddCompany,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCompanyuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* updateWorker(action: UpdateCompanyType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateCompany(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateCompanyuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.error.toString());
  }
}

function* fetchAllWorker(action: FetchCompany) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: CompanyList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<CompanySuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchCompanyListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchCompanyError(error.response?.data.message));
  }
}

function* fetchCompanyById(action: FetchCompanyById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindCompany(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CompanyByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "");
    }
  }
}

function* deleteWorker(action: DeleteCompanyType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteCompany(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateCompanyuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_COMPANY, createWorker);
  yield takeLatest(Types.UPDATE_COMPANY, updateWorker);
  yield takeEvery(Types.FETCH_COMPANY, fetchAllWorker);
  yield takeLatest(Types.DELETE_COMPANY, deleteWorker);
  yield takeEvery(Types.FETCH_COMPANY_BY_ID, fetchCompanyById);
}
