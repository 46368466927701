import { ControllerConfigurations } from "../../types";

export enum ApplicationActionTypes {
  ATTRIBUTES_FETCH = "ATTRIBUTES_FETCH",
  ATTRIBUTES_FETCH_SUCCESS = "ATTRIBUTES_FETCH_SUCCESS",
  SHOW_IN_APP_LOADER = "SHOW_IN_APP_LOADER",
  STORAGE_UPLOAD = "STORAGE_UPLOAD",
  STORAGE_GET = "STORAGE_GET",
  EP_ACCORDION = "EP_ACCORDION",
  AP_ACCORDION = "AP_ACCORDION",
  SET_PROFILE_MODEL = "SET_PROFILE_MODEL",
  FETCH_APP_CONFIGURATIONS = "FETCH_APP_CONFIGURATIONS",
  FETCH_APP_CONFIGURATIONS_SUCCESS = "FETCH_APP_CONFIGURATIONS_SUCCESS",
  FETCH_CONTROLLER_CONFIGURATIONS = "FETCH_CONTROLLER_CONFIGURATIONS",
  FETCH_CONTROLLER_CONFIGURATIONS_SUCCESS = "FETCH_CONTROLLER_CONFIGURATIONS_SUCCESS",
  ADD_APP_NOTIFICATION = "ADD_APP_NOTIFICATION",
  REMOVE_APP_NOTIFICATION = "REMOVE_APP_NOTIFICATION",
  UPDATE_ACTIVE_USERS = "UPDATE_ACTIVE_USERS",
  FETCH_WORKSPACES = "FETCH_WORKSPACES",
  FETCH_EMAIL_TEMPLATE = "FETCH_EMAIL_TEMPLATE",
}
