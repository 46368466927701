import Types from "./actionType";
import {
  FetchWorkspacePayload,
  CreateWorkspace,
  CreateWorkspacePayload,
  FetchWorkspace,
  FetchWorkspaceError,
  FetchWorkspaceSuccess,
  UpdateWorkspace,
  UpdateWorkspacePayload,
  WorkspaceListWithMetaData,
  FetchWorkspaceByIdPayload,
  FetchWorkspaceById,
  DeleteWorkspacePayload,
  DeleteWorkspace,
} from "./types";

export const fetchWorkspaceList = ({
  params,
  onSuccess,
  onError,
}: FetchWorkspacePayload): FetchWorkspace => ({
  type: Types.FETCH_WORKSPACE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchWorkspaceById = ({
  id,
  onSuccess,
  onError,
}: FetchWorkspaceByIdPayload): FetchWorkspaceById => ({
  type: Types.FETCH_WORKSPACE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchWorkspaceListSuccess = (
  data: WorkspaceListWithMetaData,
): FetchWorkspaceSuccess => ({
  type: Types.SUCCESS_FETCH_WORKSPACE,
  payload: {
    data,
  },
});

export const fetchWorkspaceError = (error?: string): FetchWorkspaceError => ({
  type: Types.ERROR_FETCH_WORKSPACE,
  payload: { error },
});

export const createWorkspace = ({
  data,
  onSuccess,
  onError,
}: CreateWorkspacePayload): CreateWorkspace => ({
  type: Types.CREATE_WORKSPACE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateWorkspace = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateWorkspacePayload): UpdateWorkspace => ({
  type: Types.UPDATE_WORKSPACE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteWorkspace = ({
  id,
  onSuccess,
  onError,
}: DeleteWorkspacePayload): DeleteWorkspace => ({
  type: Types.DELETE_WORKSPACE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
