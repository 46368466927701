import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getResourcesColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    name: {
      title: "Name",
      sortSupported: true,
      searchSupported: true,
      key: "name",
    },
    shortDescription: {
      title: "Short Description",
      sortSupported: true,
      key: "shortDescription",
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",
      render: (item) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
    state: {
      title: "State",
      key: "state",
      render: (item) => {
        return <text className='title'>{item.state?.value || "-"}</text>;
      },
    },

    downloadAvailable: {
      title: "Download Available",
      sortSupported: true,
      key: "downloadAvailable",
    },
    publishedDate: {
      title: "Published Date",
      sortSupported: true,
      key: "publishedDate",
    },
    type: {
      title: "Type",
      sortSupported: true,
      key: "type",
      render: (item) => {
        return (
          <div className='title'>
            {item.type === "whitepaper"
              ? "Whitepaper"
              : item.type === "blogs"
              ? "Blogs"
              : item.type === "caseStudies"
              ? "Case Studies"
              : "-"}
          </div>
        );
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
