import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  DashboardsList,
  AddDashboardWidget,
  UpdateDashboardWidget,
  FindDashboardWidgetById,
  DashboardWidgetData,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchDashboardsListSuccess, fetchDashboardWidgetDataSuccess } from "./actions";
import Types from "./actionType";
import {
  CreateDashboardsuccess,
  CreateDashboards as CreateDashboardsType,
  DashboardsSuccess,
  UpdateDashboards as UpdateDashboardsType,
  FetchDashboards,
  FetchDashboardsById,
  DashboardsByIdSuccess,
  FetchDashboardWidgetData,
  DashboardWidgetDataSuccess,
} from "./types";

function* createWorker(action: CreateDashboardsType) {
  try {
    const options = {
      method: "POST",
      url: AddDashboardWidget,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateDashboardsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* updateWorker(action: UpdateDashboardsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateDashboardWidget(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateDashboardsuccess> = yield call(axios, options);

    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* dashboardWidgetDataWorker(action: FetchDashboardWidgetData) {
  try {
    const options = {
      method: "GET",
      url: DashboardWidgetData(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<DashboardWidgetDataSuccess> = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchDashboardWidgetDataSuccess(response.data.data));
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(response.data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchDashboards) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: DashboardsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<DashboardsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchDashboardsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching academy courses");
    }
  }
}

function* fetchDashboardsById(action: FetchDashboardsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindDashboardWidgetById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<DashboardsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_DASHBOARDS, createWorker);
  yield takeLatest(Types.UPDATE_DASHBOARDS, updateWorker);
  yield takeLatest(Types.FETCH_DASHBOARD_WIDGET_DATA, dashboardWidgetDataWorker);
  yield takeEvery(Types.FETCH_DASHBOARDS, fetchAllWorker);
  yield takeEvery(Types.FETCH_DASHBOARDS_BY_ID, fetchDashboardsById);
}
