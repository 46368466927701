import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { filterRecruits } from "../../redux/recruits/actions";
import { generateArrayAttributes } from "../../services/methods";

import styles from "./RecruitmentsFilter.styles";

const sortKeys = [
  { label: "Name", key: "name" },
  { label: "Assigned To", key: "assignedToName" },
  { label: "Date", key: "dateScheduled", isDate: true },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const RecruitsFilter = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.filterRecruits({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
        </h2>
      </styles.Header>
      <Input
        label={"Full Name"}
        type={"text"}
        name='name'
        value={props.filters.name}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Input
        label={"Mobile"}
        type={"text"}
        name='mobile'
        value={props.filters.mobile}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Input
        label={"Personal Email"}
        type={"text"}
        name='email'
        value={props.filters.email}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Input
        label={"National ID"}
        type={"text"}
        name='nationalId'
        value={props.filters.nationalId}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      <Select
        name={"nationalIdType"}
        label={"National ID Type"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...generateArrayAttributes("nationalIdType").map((value) => ({
            label: value.value,
            value: value._id,
          })),
        ]}
        value={props.filters.nationalIdType}
        onChange={updateFilter}
      />
      <Select
        name={"reportingTalentAcquisition"}
        label={"Talent Acquisition"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...props.employees.map((value) => ({
            label: value?.label,
            value: value._id,
          })),
        ]}
        value={props.filters.reportingTalentAcquisition}
        onChange={updateFilter}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.RecruitsReducer.filters,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
});

const mapDispatchToProps = {
  filterRecruits,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitsFilter);
