import React from "react";

import { strings } from "../../../../theme";
import { TableControllerProps } from "../../../../types";
import { RelatedListLabel, RelatedListSeparator } from "../../../common/styles";
import { StepBadge } from "../../../common/styles";
import AttachmentModal from "../../AttachmentModal/AttachmentModal";
import Header from "../../Header/Header";
import { TableHeaderV2 } from "../../TableHeaderV2";

import {
  ActiveStepTriangle,
  Container,
  FormContainer,
  FormFieldsContainer,
  FormTitle,
  LeftContainer,
  RightContainer,
  StepContainer,
} from "./styles";

const { back, relatedList } = strings.common;

export const TableController = ({
  type,
  loading,
  headerTitle,
  headerButtons,
  onAttachmentsIconLoad,
  onAttachmentsClick,
  attachmentsBadge,
  formTitle,
  formSteps,
  formRelatedList,
  currentStepIndex,
  renderFormFields,
  goTo,
  getStepBadge,
  attachmentModal,
  handleAttachmentsModal,
  documentId,
  handleCloseAttachment,
  attachments,
  attachmentsPickerRef,
  handleShowAttachment,
  handleDeleteAttachment,
  handleCurrentAttachment,
  onClickAddAttachment,
  currentAttachmentFile,
  currentAttachment,
  attachmentUploadProgress,
  onClickSaveAttachment,
  onClickCancelAttachment,
  onClickBrowseAttachment,
  onAttachmentsFileChange,
}: TableControllerProps) => {
  return (
    <Container loading={loading}>
      <Header title={headerTitle} />
      <TableHeaderV2
        backButtonTitle={back}
        onAttachmentsClick={onAttachmentsClick}
        onAttachmentsIconLoad={onAttachmentsIconLoad}
        attachmentsBadge={attachmentsBadge}
        headerButtons={headerButtons}
      />
      <FormContainer>
        <LeftContainer>
          <FormTitle>{formTitle}</FormTitle>
          <FormFieldsContainer>{renderFormFields()}</FormFieldsContainer>
        </LeftContainer>
        <RightContainer>
          {formSteps.map((stepItem, stepIndex) => {
            const badge = getStepBadge(stepIndex);
            return (
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {stepItem}
                {!!badge && <StepBadge>{badge}</StepBadge>}
                {currentStepIndex === stepIndex && <ActiveStepTriangle />}
              </StepContainer>
            );
          })}
          {type === "edit" && !!formRelatedList.length && (
            <>
              <RelatedListLabel>
                <RelatedListSeparator />
                {relatedList}
                <RelatedListSeparator />
              </RelatedListLabel>
              {formRelatedList.map((stepItem, index) => {
                const stepIndex = formSteps.length + index;
                return (
                  <StepContainer
                    key={stepIndex}
                    data-index={stepIndex}
                    active={currentStepIndex === stepIndex}
                    onClick={goTo}
                    noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
                  >
                    {stepItem}
                    <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
                  </StepContainer>
                );
              })}
            </>
          )}
        </RightContainer>
      </FormContainer>
      <AttachmentModal
        handleAttachmentsModal={handleAttachmentsModal}
        attachmentModal={attachmentModal}
        attachments={attachments}
        handleShowAttachment={handleShowAttachment}
        handleDeleteAttachment={handleDeleteAttachment}
        onClickAddAttachment={onClickAddAttachment}
        handleCurrentAttachment={handleCurrentAttachment}
        currentAttachmentFile={currentAttachmentFile}
        currentAttachment={currentAttachment}
        attachmentUploadProgress={attachmentUploadProgress}
        onClickSaveAttachment={onClickSaveAttachment}
        onClickCancelAttachment={onClickCancelAttachment}
        attachmentsPickerRef={attachmentsPickerRef}
        onAttachmentsFileChange={onAttachmentsFileChange}
        onClickBrowseAttachment={onClickBrowseAttachment}
        documentId={documentId}
        handleCloseAttachment={handleCloseAttachment}
      />
    </Container>
  );
};
