import React from "react";

import { Container } from "./styles";

interface Props {
  data: string;
}

const EndpointCopy: React.FC<Props> = ({ data }: Props) => {
  const onClickCopy = (e: any) => {
    e.preventDefault();
  };

  return <Container onClick={onClickCopy}>{data}</Container>;
};

export default EndpointCopy;
