import React from "react";

import { Layout } from "../../../presentationals";
import { AdminDashboard } from "../../../presentationals/screens/admin";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<AdminDashboard />}
      admin={true}
      title={strings.headerTitle.home}
      layout={"2"}
    />
  );
};

export default Route;
