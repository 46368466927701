import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { EmploymentHistoryControllerProps } from "../../../../types";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useEmploymentHistoryController } from "./hooks";

export const EmploymentHistoryController = ({ type }: EmploymentHistoryControllerProps) => {
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const params = useParams();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchEmploymentHistory,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useEmploymentHistoryController({ type, id: params.id, attachmentsPickerRef });

  const currentEmploymentHistory = data;
  const { departmentOptions, countryOptions, employeesOptions } = useControllerConfigs();

  const {
    workLogs,
    attachments,
    associatedEmployee,
    employmentType,
    permissionToContactReason,
    permissionToContact,
    startDate,
    endDate,
    currentlyWorking,
    employerName,
    country,
    state,
    addressLineOne,
    addressLineTwo,
    city,
    postalCode,
    phone,
    extension,
    jobType,
    releventExperience,
    isAgency,
    agencyName,
    agencyCountry,
    agencyState,
    agencyAddressLineOne,
    agencyAddressLineTwo,
    agencyCity,
    agencyPostalCode,
    department,
    jobTitle,
    supervisorName,
    supervisorTitle,
    supervisorEmail,
    supervisorPhone,
    reasonForLeaving,
    active,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchEmploymentHistory();
    }
    // Do not include fetchEmploymentHistory to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
          <Select
            width={"48%"}
            name={"employmentType"}
            label={"Employment Type"}
            options={getAttributValues("employmentType")}
            value={formData.employmentType.value}
            onChange={onTextChange}
            mandatory={formData.employmentType.required}
            error={formData.employmentType.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Start Date'
            type={"date"}
            width='48%'
            error={startDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "startDate",
              value: startDate.value,
              required: startDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='End Date'
            type={"date"}
            width='48%'
            disabled={currentlyWorking.value}
            error={endDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "endDate",
              minDate: startDate.value ? new Date(startDate.value) : undefined,
              value: currentlyWorking.value ? null : endDate.value,
              required: endDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"currentlyWorking"}
            label={"Currently Working"}
            options={ActiveSelectOptions}
            value={currentlyWorking.value}
            onChange={onTextChange}
            mandatory={currentlyWorking.required}
            error={currentlyWorking.error}
          />
          <Select
            width={"48%"}
            name={"permissionToContact"}
            label={"Permission To Contact"}
            options={ActiveSelectOptions}
            value={permissionToContact.value}
            onChange={onTextChange}
            mandatory={permissionToContact.required}
            error={permissionToContact.error}
          />
        </Row>
        <TextInput
          label='Permission To Contact Reason'
          type={"text"}
          width='100%'
          error={permissionToContactReason.error}
          inputProps={{
            name: "permissionToContactReason",
            value: permissionToContactReason.value,
            onChange: onTextChange,
            required: permissionToContactReason.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    employeesOptions,
    associatedEmployee.value,
    associatedEmployee.required,
    associatedEmployee.error,
    onTextChange,
    formData.employmentType.value,
    formData.employmentType.required,
    formData.employmentType.error,
    startDate.error,
    startDate.value,
    startDate.required,
    onDateChange,
    currentlyWorking.value,
    currentlyWorking.required,
    currentlyWorking.error,
    endDate.error,
    endDate.value,
    endDate.required,
    permissionToContact.value,
    permissionToContact.required,
    permissionToContact.error,
    permissionToContactReason.error,
    permissionToContactReason.value,
    permissionToContactReason.required,
  ]);

  const renderAddress = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Employer Name'
            type={"text"}
            width='48%'
            error={employerName.error}
            inputProps={{
              name: "employerName",
              value: employerName.value,
              onChange: onTextChange,
              required: employerName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Address Line 1"}
            type={"text"}
            width='48%'
            error={addressLineOne.error}
            inputProps={{
              name: "addressLineOne",
              value: addressLineOne.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineOne.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Address Line 2"}
            type={"text"}
            width='48%'
            error={addressLineTwo.error}
            inputProps={{
              name: "addressLineTwo",
              value: addressLineTwo.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: addressLineTwo.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Postal Zone/ZIP Code"}
            type={"text"}
            width='48%'
            error={postalCode.error}
            inputProps={{
              name: "postalCode",
              type: "number",
              value: postalCode.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: postalCode.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Phone"}
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              // type: "number",
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label={"Extension"}
          type={"text"}
          width='48%'
          error={extension.error}
          inputProps={{
            name: "extension",
            value: extension.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: extension.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    addressLineOne.error,
    addressLineOne.required,
    addressLineOne.value,
    addressLineTwo.error,
    addressLineTwo.required,
    addressLineTwo.value,
    city.error,
    city.required,
    city.value,
    country.error,
    country.required,
    country.value,
    countryOptions,
    employerName.error,
    employerName.required,
    employerName.value,
    extension.error,
    extension.required,
    extension.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    postalCode.error,
    postalCode.required,
    postalCode.value,
    state.error,
    state.required,
    state.value,
  ]);

  const renderJobDetails = useCallback(() => {
    return (
      <>
        <Row>
          <Select
            width={"48%"}
            name={"jobType"}
            label={"Job Type"}
            options={getAttributValues("jobType")}
            value={jobType.value}
            onChange={onTextChange}
            mandatory={jobType.required}
            error={jobType.error}
          />
          <Select
            width={"48%"}
            name={"releventExperience"}
            label={"Relevent Experience"}
            options={ActiveSelectOptions}
            value={releventExperience.value}
            onChange={onTextChange}
            mandatory={releventExperience.required}
            error={releventExperience.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"isAgency"}
            label={"Is Agency"}
            options={ActiveSelectOptions}
            value={isAgency.value}
            onChange={onTextChange}
            mandatory={isAgency.required}
            error={isAgency.error}
          />
          {!!isAgency.value && (
            <>
              <TextInput
                label={"Agency Name"}
                type={"text"}
                width='48%'
                error={agencyName.error}
                inputProps={{
                  name: "agencyName",
                  value: agencyName.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyName.required,
                }}
                textAreaProps={{}}
              />
            </>
          )}
        </Row>
        {!!isAgency.value && (
          <>
            <Row>
              <TextInput
                label={"Agency's Address Line 1"}
                type={"text"}
                width='48%'
                error={agencyAddressLineOne.error}
                inputProps={{
                  name: "agencyAddressLineOne",
                  value: agencyAddressLineOne.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyAddressLineOne.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Agency's Address Line 2"}
                type={"text"}
                width='48%'
                error={agencyAddressLineTwo.error}
                inputProps={{
                  name: "agencyAddressLineTwo",
                  value: agencyAddressLineTwo.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyAddressLineTwo.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <TextInput
                label={"Agency's City"}
                type={"text"}
                width='48%'
                error={agencyCity.error}
                inputProps={{
                  name: "agencyCity",
                  value: agencyCity.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyCity.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Agency's State/Province"}
                type={"text"}
                width='48%'
                error={agencyState.error}
                inputProps={{
                  name: "agencyState",
                  value: agencyState.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyState.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <Select
                width={"48%"}
                name={"agencyCountry"}
                label={"Agency's Country/Region"}
                options={countryOptions}
                value={agencyCountry.value}
                onChange={onTextChange}
                mandatory={agencyCountry.required}
                error={agencyCountry.error}
              />
              <TextInput
                label={"Agency's Postal Code"}
                type={"text"}
                width='48%'
                error={agencyPostalCode.error}
                inputProps={{
                  name: "agencyPostalCode",
                  value: agencyPostalCode.value,
                  type: "number",
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: agencyPostalCode.required,
                }}
                textAreaProps={{}}
              />
            </Row>
          </>
        )}
        <Row>
          <TextInput
            label={"Job Title"}
            type={"text"}
            width='48%'
            error={jobTitle.error}
            inputProps={{
              name: "jobTitle",
              value: jobTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: jobTitle.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"department"}
            label={"Department"}
            options={departmentOptions}
            value={department.value}
            onChange={onTextChange}
            mandatory={department.required}
            error={department.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Supervisor Name"}
            type={"text"}
            width='48%'
            error={supervisorName.error}
            inputProps={{
              name: "supervisorName",
              value: supervisorName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: supervisorName.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Supervisor Title"}
            type={"text"}
            width='48%'
            error={supervisorTitle.error}
            inputProps={{
              name: "supervisorTitle",
              value: supervisorTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: supervisorTitle.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Supervisor Email"}
            type={"text"}
            width='48%'
            error={supervisorEmail.error}
            inputProps={{
              name: "supervisorEmail",
              value: supervisorEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: supervisorEmail.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Supervisor Phone"}
            type={"text"}
            width='48%'
            error={supervisorPhone.error}
            inputProps={{
              name: "supervisorPhone",
              value: supervisorPhone.value,
              // type: "number",
              autoComplete: "off",
              onChange: onTextChange,
              required: supervisorPhone.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <TextInput
          label={"Reason For Leaving"}
          type={"text"}
          width='100%'
          error={reasonForLeaving.error}
          inputProps={{
            name: "reasonForLeaving",
            value: reasonForLeaving.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: reasonForLeaving.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    agencyAddressLineOne.error,
    agencyAddressLineOne.required,
    agencyAddressLineOne.value,
    agencyAddressLineTwo.error,
    agencyAddressLineTwo.required,
    agencyAddressLineTwo.value,
    agencyCity.error,
    agencyCity.required,
    agencyCity.value,
    agencyCountry.error,
    agencyCountry.required,
    agencyCountry.value,
    agencyName.error,
    agencyName.required,
    agencyName.value,
    agencyPostalCode.error,
    agencyPostalCode.required,
    agencyPostalCode.value,
    agencyState.error,
    agencyState.required,
    agencyState.value,
    countryOptions,
    department.error,
    department.required,
    department.value,
    departmentOptions,
    isAgency.error,
    isAgency.required,
    isAgency.value,
    jobTitle.error,
    jobTitle.required,
    jobTitle.value,
    jobType.error,
    jobType.required,
    jobType.value,
    onTextChange,
    reasonForLeaving.error,
    reasonForLeaving.required,
    reasonForLeaving.value,
    releventExperience.error,
    releventExperience.required,
    releventExperience.value,
    supervisorEmail.error,
    supervisorEmail.required,
    supervisorEmail.value,
    supervisorName.error,
    supervisorName.required,
    supervisorName.value,
    supervisorPhone.error,
    supervisorPhone.required,
    supervisorPhone.value,
    supervisorTitle.error,
    supervisorTitle.required,
    supervisorTitle.value,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentEmploymentHistory?.createdBy !== "string"
        ? currentEmploymentHistory?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentEmploymentHistory?.updatedBy !== "string"
        ? currentEmploymentHistory?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentEmploymentHistory?.createdAt}
        updatedAt={currentEmploymentHistory?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentEmploymentHistory?.createdAt,
    currentEmploymentHistory?.createdBy,
    currentEmploymentHistory?.updatedAt,
    currentEmploymentHistory?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.employmentHistory.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.employmentHistory.name} />,
    [params.id],
  );

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const renderRelatedEmployees = useMemo(
    () => (
      <EmployeesListing
        relatedList={{
          type: "_id",
          value: associatedEmployee.value || "",
        }}
      />
    ),
    [associatedEmployee.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderAddress();
      case 2:
        return renderJobDetails();
      case 3:
        return renderChecklist;
      case 4:
        return renderActivitylogs;
      case 5:
        return renderWorklogs();
      case 6:
        return renderSystemData();
      case 7:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderRelatedEmployees,
    renderActivitylogs,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderAddress,
    renderJobDetails,
    renderChecklist,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
