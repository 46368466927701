import React from "react";

import { Layout } from "../../../presentationals";
import { PlatformModulesController } from "../../../presentationals/screens";

const Route = () => {
  return (
    <Layout activity={<PlatformModulesController type={"edit"} />} admin={true} layout={"2"} />
  );
};
export const APPlatformModulesEdit = React.memo(Route);
