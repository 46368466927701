import Types from "./actionType";
import {
  FetchCompanyContactPayload,
  CreateCompanyContact,
  CreateCompanyContactPayload,
  FetchCompanyContact,
  FetchCompanyContactError,
  FetchCompanyContactSuccess,
  UpdateCompanyContact,
  UpdateCompanyContactPayload,
  CompanyContactListWithMetaData,
  FetchCompanyContactByIdPayload,
  FetchCompanyContactById,
  DeleteCompanyContactPayload,
  DeleteCompanyContact,
} from "./types";

export const fetchCompanyContactList = ({
  params,
  onSuccess,
  onError,
}: FetchCompanyContactPayload): FetchCompanyContact => ({
  type: Types.FETCH_COMPANY_CONTACT,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCompanyContactById = ({
  id,
  onSuccess,
  onError,
}: FetchCompanyContactByIdPayload): FetchCompanyContactById => ({
  type: Types.FETCH_COMPANY_CONTACT_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCompanyContactListSuccess = (
  data: CompanyContactListWithMetaData,
): FetchCompanyContactSuccess => ({
  type: Types.SUCCESS_FETCH_COMPANY_CONTACT,
  payload: {
    data,
  },
});

export const fetchCompanyContactError = (error?: string): FetchCompanyContactError => ({
  type: Types.ERROR_FETCH_COMPANY_CONTACT,
  payload: { error },
});

export const createCompanyContact = ({
  data,
  onSuccess,
  onError,
}: CreateCompanyContactPayload): CreateCompanyContact => ({
  type: Types.CREATE_COMPANY_CONTACT,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCompanyContact = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCompanyContactPayload): UpdateCompanyContact => ({
  type: Types.UPDATE_COMPANY_CONTACT,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCompanyContact = ({
  id,
  onSuccess,
  onError,
}: DeleteCompanyContactPayload): DeleteCompanyContact => ({
  type: Types.DELETE_COMPANY_CONTACT,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
