import Types from "./actionType";
import {
  FetchResourcesPayload,
  CreateResources,
  CreateResourcesPayload,
  FetchResources,
  FetchResourcesError,
  FetchResourcesSuccess,
  UpdateResources,
  UpdateResourcesPayload,
  ResourcesListWithMetaData,
  FetchResourcesByIdPayload,
  FetchResourcesById,
  DeleteResourcesPayload,
  DeleteResources,
} from "./types";

export const fetchResourcesList = ({
  params,
  onSuccess,
  onError,
}: FetchResourcesPayload): FetchResources => ({
  type: Types.FETCH_RESOURCES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchResourcesById = ({
  id,
  onSuccess,
  onError,
}: FetchResourcesByIdPayload): FetchResourcesById => ({
  type: Types.FETCH_RESOURCES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchResourcesListSuccess = (
  data: ResourcesListWithMetaData,
): FetchResourcesSuccess => ({
  type: Types.SUCCESS_FETCH_RESOURCES,
  payload: {
    data,
  },
});

export const fetchResourcesError = (error?: string): FetchResourcesError => ({
  type: Types.ERROR_FETCH_RESOURCES,
  payload: { error },
});

export const createResources = ({
  data,
  onSuccess,
  onError,
}: CreateResourcesPayload): CreateResources => ({
  type: Types.CREATE_RESOURCES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateResources = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateResourcesPayload): UpdateResources => ({
  type: Types.UPDATE_RESOURCES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteResources = ({
  id,
  onSuccess,
  onError,
}: DeleteResourcesPayload): DeleteResources => ({
  type: Types.DELETE_RESOURCES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
