/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CHECKLIST = "FETCH_CHECKLIST",
  DELETE_CHECKLIST_BY_ID = "DELETE_CHECKLIST_BY_ID",
  SUCCESS_FETCH_CHECKLIST = "SUCCESS_FETCH_CHECKLIST",
  ERROR_FETCH_CHECKLIST = "ERROR_FETCH_CHECKLIST",
  CREATE_CHECKLIST = "CREATE_CHECKLIST",
  UPDATE_CHECKLIST = "UPDATE_CHECKLIST",
}

export default Types;
