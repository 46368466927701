import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

interface Props {
  disabled?: boolean;
  active?: boolean;
}

export const Container = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  background-color: ${colors.common.white};
  height: 54px;
  border: 0.6px solid ${colors.common.borderColor};
  padding: 0 15px;
  position: relative;
`;

export const InfoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${colors.common.lightTextColor};
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  word-spacing: 0.6rem;
  margin: 0 10px;
`;

export const PagesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationItem = styled.li<Props>`
  height: 26px;
  width: 26px;
  text-align: center;
  margin: auto 10px;
  color: ${(props) => (props.active ? colors.common.primary : colors.common.black)};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  line-height: 1.43;
  font-size: 13px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    transition: linear 0.4s;
    background-color: ${colors.common.hoverTabBackground};
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid ${colors.common.border};
      border-top: 0.12em solid ${colors.common.border};
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;

export const PaginationArrow = styled.img`
  display: flex;
  height: 10px;
  width: 20px;
  object-fit: contain;
`;

export const ShowRecordsContainer = styled.div`
  display: flex;
  margin-left: 20px;
  column-gap: 10px;
`;

export const SettingsIcon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
  position: absolute;
  left: 1.8%;
  cursor: pointer;
`;
export const TableConfigPopupContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 100;
  bottom: 100%;
  left: 1.8%;
`;
export const TableConfigPopup = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${colors.common.white};
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  padding: 10px 30px;
  row-gap: 10px;
  height: fit-content;
  align-self: flex-end;
`;

export const TableConfigChildPopup = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${colors.common.white};
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  padding: 10px 30px;
  row-gap: 10px;
  margin-left: 6px;
  height: fit-content;
`;

export const ConfigItem = styled.div<Props>`
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  color: ${(props) => (props.active ? colors.common.activeTabText : colors.common.lightTextColor)};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`;

export const ConfigTitle = styled.div`
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
`;
export const ConfigArrow = styled.img`
  margin-left: 10px;
  height: 10px;
  width: 10px;
  object-fit: contain;
`;
export const DropdownListContainer = styled.div`
  display: flex;
  min-width: 30vw;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 3px;
  padding: 20px;
  z-index: 1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const DeletePopupContainer = styled.div`
  display: flex;
  min-height: 20vh;
  justify-content: space-between;
  min-width: 30vw;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 3px;
  padding: 20px;
  z-index: 1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;
