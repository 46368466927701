import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  fetchEmployees as fetchEmployeesList,
  getProfile,
  removeAppNotification,
  updateEmployees,
  showInAppLoader,
  updateTableConfigs,
} from "../../../../../redux";
import {
  EmployeesItem,
  EmployeesListWithMetaData,
  FetchEmployeesParams,
} from "../../../../../redux/Employees/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchEmployeesProps,
  sortType,
  useEmployeesListProps,
  useEmployeesListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getEmployeesColumns } from "../../../../columns/Employees";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.employees;
const defaultFilters: QueryBuilderType[] = [];

export const useEmployeesList = ({
  relatedList,
  widgetId,
}: useEmployeesListProps): useEmployeesListReturnType => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.EmployeesReducer?.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const employees = useAppSelector((state) => state.EmployeesReducer.data.list);
  const { usersOptions, departmentOptions, companyOptions, countryOptions, employeesOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const employeesTableConfigFields = useMemo(() => {
    return tableConfiguration?.employees?.fields?.length
      ? tableConfiguration?.employees?.fields
      : tableListDefaultSelectFields.employees;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = employeesTableConfigFields;
    return getEmployeesColumns({ fields });
  }, [employeesTableConfigFields]);

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: EmployeesItem[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.employees?.fields || tableListDefaultSelectFields.employees;
      records = records?.map((item) => {
        const employeesItem = { ...item };
        if (
          userPersonalizedFields?.includes("employmentDepartment") &&
          typeof item.employmentDepartment !== "string"
        ) {
          employeesItem.employmentDepartment = item?.employmentDepartment?.name;
        }
        if (
          userPersonalizedFields?.includes("employmentStatus") &&
          typeof item.employmentStatus !== "string"
        ) {
          employeesItem.employmentStatus = item?.employmentStatus?.value;
        }
        if (
          userPersonalizedFields?.includes("employmentCompany") &&
          typeof item.employmentCompany !== "string"
        ) {
          employeesItem.employmentCompany = item?.employmentCompany?.company;
        }
        if (
          userPersonalizedFields?.includes("payrollCompany") &&
          typeof item.payrollCompany !== "string"
        ) {
          employeesItem.payrollCompany = item?.payrollCompany?.company;
        }
        if (
          userPersonalizedFields?.includes("offboardType") &&
          typeof item.offboardType !== "string"
        ) {
          employeesItem.offboardType = item?.offboardType?.value;
        }
        if (userPersonalizedFields?.includes("shirtSize") && typeof item.shirtSize !== "string") {
          employeesItem.shirtSize = item?.shirtSize?.value;
        }
        if (
          userPersonalizedFields?.includes("personalMartial") &&
          typeof item.personalMartial !== "string"
        ) {
          employeesItem.personalMartial = item?.personalDetails.personalMartial?.value;
        }
        if (
          userPersonalizedFields?.includes("nationality") &&
          typeof item.nationality !== "string"
        ) {
          employeesItem.nationality = item?.personalDetails.nationality?.value;
        }
        if (
          userPersonalizedFields?.includes("personalGender") &&
          typeof item.personalGender !== "string"
        ) {
          employeesItem.personalGender = item?.personalDetails.personalGender?.value;
        }
        if (userPersonalizedFields?.includes("ethnicity") && typeof item.ethnicity !== "string") {
          employeesItem.ethnicity = item?.personalDetails.ethnicity?.value;
        }
        if (userPersonalizedFields?.includes("race") && typeof item.race !== "string") {
          employeesItem.race = item?.personalDetails.race?.value;
        }
        if (
          userPersonalizedFields?.includes("countryOfBirth") &&
          typeof item.countryOfBirth !== "string"
        ) {
          employeesItem.countryOfBirth = item?.personalDetails.countryOfBirth?.value;
        }
        if (userPersonalizedFields?.includes("personalDOB")) {
          employeesItem.personalDOB = MomentDate({
            date: item.personalDOB || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("dateHire")) {
          employeesItem.dateHire = MomentDate({
            date: item.dateHire || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("dateJoining")) {
          employeesItem.dateJoining = MomentDate({
            date: item.dateJoining || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("probationStartDate")) {
          employeesItem.probationStartDate = MomentDate({
            date: item.probationStartDate || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("probationEndDate")) {
          employeesItem.probationEndDate = MomentDate({
            date: item.probationEndDate || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("dateTermination")) {
          employeesItem.dateTermination = MomentDate({
            date: item.dateTermination || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (
          userPersonalizedFields?.includes("employmentType") &&
          typeof item.employmentType !== "string"
        ) {
          employeesItem.employmentType = item?.employmentType?.value;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          employeesItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          employeesItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          employeesItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          employeesItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete employeesItem?._id;
        return employeesItem;
      });
      const employeesFields = tablePublicFields.employees;
      const columnHeaders = employeesFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "employees");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [tableConfiguration?.employees?.fields, dispatch, notificationsId],
  );

  // ================ Fetch Employees Success/Error Methods ================
  const employeesFetchSuccess = useCallback(
    (data: EmployeesListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const employeesFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchEmployees = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchEmployeesProps) => {
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      }
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      const currentPage = metaData?.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.employees?.limit || 10;

      const params: FetchEmployeesParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: EmployeesListWithMetaData) => employeesFetchSuccess(data, exportAll),
        onError: employeesFetchError,
      };
      dispatch(fetchEmployeesList(payload));
      setSelectedRows([]);
    },
    [
      dispatch,
      employeesFetchError,
      employeesFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
      widgetId,
      widgetsData,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = employees?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, employees, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchEmployees({ exportAll: true });
  }, [fetchEmployees]);

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchEmployees({ page });
  }, [fetchEmployees, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchEmployees({ page: parseInt(page), search });
    },
    [fetchEmployees, search],
  );

  const onClickEmployees = useCallback(
    (id: string) => {
      if (permissions?.employees_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.employees_view, notificationsId, dispatch],
  );
  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchEmployees({ page: 1, search });
  }, [fetchEmployees, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchEmployees({ page: 1 });
  }, [fetchEmployees]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchEmployees({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchEmployees],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchEmployees({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchEmployees],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = employees?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, employees]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            employees?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, employees, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const employeesConfigLimit = tableConfiguration?.employees?.limit || 10;
      const employeesConfigs = {
        table: "employees",
        fields: fields,
        limit: employeesConfigLimit,
      };
      const payload = {
        tableConfiguration: employeesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchEmployees({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employees table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.employees?.limit, dispatch, fetchEmployees, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const employeesConfigFields = tableConfiguration?.employees?.fields;
      dispatch(showInAppLoader(true));
      const employeesConfigs = {
        table: "employees",
        fields: employeesConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: employeesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchEmployees({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employees table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchEmployees],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = employees?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchEmployees({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Employees updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating employees",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateEmployees(payload));
    },
    [selectedRows, employees, dispatch, notificationsId, fetchEmployees],
  );
  // ================ Table Header Buttons ================

  const employeesHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [
      {
        title: strings.common.add,
        onClick: onClickAdd,
        visible: true,
      },
    ];
    return buttons;
  }, [onClickAdd]);

  const listActionOptions: ListActions[] = [
    {
      name: "employmentStatus",
      label: "Status",
      options: getAttributValues("employmentStatus"),
    },
    {
      name: "employmentType",
      label: "Employee Type",
      options: getAttributValues("employmentType"),
      required: true,
    },
    {
      name: "employmentDepartment",
      label: "Department",
      options: departmentOptions,
      required: true,
    },
    {
      name: "firstName",
      label: "first Name",
      type: "text",
      required: true,
    },
    {
      name: "middleName",
      label: "Middle Name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
    },
    {
      name: "nickName",
      label: "Preffered Name",
      type: "text",
    },
    {
      name: "personalEmail",
      label: "Personal Email",
      type: "text",
      required: true,
    },
    {
      name: "officialEmail",
      label: "Official Email",
      type: "text",
      required: true,
    },
    {
      name: "mobile",
      label: "Mobile",
      type: "text",
      required: true,
    },
    {
      name: "alternateMobile",
      label: "Alternate Mobile",
      type: "text",
    },
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "generatePayrollOffshoreServices",
      label: "Generate Payroll Offshore Services",
      options: ActiveSelectOptions,
    },
    {
      name: "employmentCompany",
      label: "Company",
      options: companyOptions,
      required: true,
    },
    {
      name: "employeeID",
      label: "Employee ID",
      type: "text",
      required: true,
    },

    {
      name: "personalGender",
      label: "Gender",
      options: getAttributValues("gender"),
    },
    {
      name: "ethnicity",
      label: "Ethnicity",
      options: getAttributValues("ethnicity"),
    },
    {
      name: "race",
      label: "Race",
      options: getAttributValues("race"),
    },

    {
      name: "countryOfBirth",
      label: "Country Of Birth",
      options: countryOptions,
    },

    {
      name: "addressLineOne",
      label: "Address Line 1",
      type: "text",
      required: true,
    },
    {
      name: "addressLineTwo",
      label: "Address Line 2",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
    },
    {
      name: "state",
      label: "State",
      type: "text",
      required: true,
    },
    {
      name: "zipCode",
      label: "Zipcode",
      type: "text",
      required: true,
    },
    {
      name: "Country",
      label: "Country/Region",
      options: countryOptions,
      required: true,
    },
    {
      name: "managerLevel",
      label: "Manager Level",
      options: getAttributValues("managerLevel"),
    },
    {
      name: "reportingManager",
      label: "Reporting Manager",
      options: employeesOptions,
    },
    {
      name: "companyBuddy",
      label: "Company Buddy",
      options: employeesOptions,
    },
    {
      name: "externalFolderLink",
      label: "External Folder Link",
      type: "text",
    },
    {
      name: "isEmployeeReferenced",
      label: "Is Employee Referenced",
      options: ActiveSelectOptions,
    },
    {
      name: "referralName",
      label: "Referral Name",
      type: "text",
    },
    {
      name: "referralMobile",
      label: "Referral Mobile",
      type: "text",
    },
    {
      name: "referralEmail",
      label: "Referral Email",
      type: "text",
    },

    {
      name: "placeOfBirth",
      label: "Place Of Birth",
      type: "text",
    },
    {
      name: "reportingPOD",
      label: "POD Leader",
      options: employeesOptions,
    },

    {
      name: "payrollCompany",
      label: "Payroll Company",
      options: companyOptions,
    },
    {
      name: "employeeIDofPayrollCompany",
      label: "Payroll Company's Employee Id",
      type: "text",
    },
    {
      name: "shirtSize",
      label: "Shirt Size",
      options: getAttributValues("shirtSize"),
    },
    {
      name: "offboardType",
      label: "Off board Type",
      options: getAttributValues("offboardType"),
    },
    {
      name: "dateHire",
      label: "Date Hire",
      type: "date",
    },
    {
      name: "dateJoining",
      label: "Date Joining",
      type: "date",
    },
    {
      name: "probationStartDate",
      label: "Probation Start Date",
      type: "date",
    },
    {
      name: "probationEndDate",
      label: "Probation End Date",
      type: "date",
    },
    {
      name: "personalMartial",
      label: "Personal Martial",
      options: getAttributValues("personalMartial"),
    },
    {
      name: "personalDOB",
      label: "Personal D.O.B",
      type: "date",
    },
    {
      name: "nationality",
      label: "Nationality",
      options: getAttributValues("citizenship"),
    },

    {
      name: "fatherName",
      label: "Father's Name",
      type: "text",
    },
    {
      name: "motherName",
      label: "Mother's Name",
      type: "text",
    },
    {
      name: "reportingManager",
      label: "Reporting Manager",
      options: employeesOptions,
    },
    {
      name: "reportingPOD",
      label: "POD Leader",
      options: employeesOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const employeesFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const employmentStatusFilterOptions = getFilterOptions(
      getAttributValues("employmentStatus"),
      "Status",
    );
    const employmentTypeFilterOptions = getFilterOptions(
      getAttributValues("employmentType"),
      "Employee Type",
    );
    const employmentDepartmentFilterOptions = getFilterOptions(departmentOptions, "Department");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const activeOptions = getFilterOptions(ActiveSelectOptions, "Active");
    const companyFilterOptions = getFilterOptions(companyOptions, "Company");
    const shirtSizeOptions = getFilterOptions(getAttributValues("shirtSize"), "Shirt Size");
    const offboardOptions = getFilterOptions(getAttributValues("offboardType"), "Off Board Type");
    const maritalStatusOptions = getFilterOptions(
      getAttributValues("personalMartial"),
      "Marital Status",
    );
    const citizenOptions = getFilterOptions(getAttributValues("citizenship"), "Nationality");
    const genderOptions = getFilterOptions(getAttributValues("gender"), "Gender");
    const ethnicityOptions = getFilterOptions(getAttributValues("ethnicity"), "Ethnicity");
    const raceOptions = getFilterOptions(getAttributValues("race"), "Race");
    const countryFilterOptions = getFilterOptions(countryOptions, "Race");

    const fields: Field[] = [
      {
        name: "firstName",
        label: "First Name",
        inputType: "string",
        validator,
      },
      {
        name: "middleName",
        label: "middleName",
        inputType: "string",
        validator,
      },
      {
        name: "lastName",
        label: "Last Name",
        inputType: "string",
        validator,
      },
      {
        name: "personalEmail",
        label: "Personal Email",
        inputType: "string",
        validator,
      },
      {
        name: "officialEmail",
        label: "Official Email",
        inputType: "string",
        validator,
      },
      {
        name: "mobile",
        label: "Mobile",
        inputType: "string",
        validator,
      },
      {
        name: "employeeID",
        label: "Employee ID",
        inputType: "string",
        validator,
      },
      {
        name: "employmentStatus",
        label: "Status",
        valueEditorType: "select",
        values: employmentStatusFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "employmentType",
        label: "Employee Type",
        valueEditorType: "select",
        values: employmentTypeFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "employmentDepartment",
        label: "Department",
        valueEditorType: "select",
        values: employmentDepartmentFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "nickName",
        label: "Preffered Name",
        inputType: "string",
        validator,
      },
      {
        name: "active",
        label: "Active",
        valueEditorType: "select",
        values: activeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "alternateMobile",
        label: "Alternative Phone",
        inputType: "string",
        validator,
      },
      {
        name: "generatePayrollOffshoreServices",
        label: "Generate Payroll Offshore Services",
        valueEditorType: "select",
        values: activeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "employmentCompany",
        label: "Company",
        valueEditorType: "select",
        values: companyFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "payrollCompany",
        label: "Payroll Company",
        valueEditorType: "select",
        values: companyFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "employeeIDofPayrollCompany",
        label: "Payroll Company's Employee Id",
        inputType: "string",
        validator,
      },
      {
        name: "shirtSize",
        label: "Shirt Size",
        valueEditorType: "select",
        values: shirtSizeOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "offboardType",
        label: "Shirt Size",
        valueEditorType: "select",
        values: offboardOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "dateHire",
        label: "Hire Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "dateJoining",
        label: "Joining Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "probationStartDate",
        label: "Probation Start Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "probationEndDate",
        label: "Probation End Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "dateTermination",
        label: "Termination Date",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "employmentDuration",
        label: "Employment Duration",
        inputType: "string",
        validator,
      },
      {
        name: "personalMartial",
        label: "Marital Status",
        valueEditorType: "select",
        values: maritalStatusOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "personalDOB",
        label: "D.O.B",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "nationality",
        label: "Nationality",
        valueEditorType: "select",
        values: citizenOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "personalGender",
        label: "Gender",
        valueEditorType: "select",
        values: genderOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "ethnicity",
        label: "Ethnicity",
        valueEditorType: "select",
        values: ethnicityOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "race",
        label: "Race",
        valueEditorType: "select",
        values: raceOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "placeOfBirth",
        label: "Place Of Birth",
        inputType: "string",
        validator,
      },
      {
        name: "countryOfBirth",
        label: "Country Of Birth",
        valueEditorType: "select",
        values: countryFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "fatherName",
        label: "Father's Name",
        inputType: "string",
        validator,
      },
      {
        name: "motherName",
        label: "Mother's Name",
        inputType: "string",
        validator,
      },
      {
        name: "addressLineOne",
        label: "Address Line 1",
        inputType: "string",
        validator,
      },
      {
        name: "addressLineTwo",
        label: "Address Line 2",
        inputType: "string",
        validator,
      },
      {
        name: "city",
        label: "City",
        inputType: "string",
        validator,
      },
      {
        name: "state",
        label: "State",
        inputType: "string",
        validator,
      },
      {
        name: "zipCode",
        label: "Zip Code",
        inputType: "string",
        validator,
      },
      {
        name: "Country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions, departmentOptions]);

  return {
    listActionOptions,
    employeesTableConfigFields,
    columns,
    employeesFilterFields,
    onClickEmployees,
    fetchEmployees,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    employeesHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
  };
};
