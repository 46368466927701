import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  UserLogin,
  getToken,
  UserLogout,
  UserProfile,
  UserProfileUpdate,
  UserResetPassword,
  getOrganisation,
  UserLoginSSO,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { doLoginSuccess, doLogoutSuccess, getProfileSuccess } from "./actions";
import Types from "./types";

function* doLoginFunction(action) {
  try {
    const options = {
      method: "POST",
      url: UserLogin(),
      headers: {
        Authorization: yield select(getToken),
      },
      data: {
        email: action.payload.email,
        password: action.payload.password,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(
        doLoginSuccess({
          id: response.data.data.id,
          name: response.data.data.name,
          email: response.data.data.email,
          admin: response.data.data.admin,
          superAdmin: response.data.data.superAdmin,
          timezone: response.data.data.timezone,
          organisations: response.data.data.organisations,
          sessionid: response.data.data.sessionid,
          token: response.data.data.token,
          profilePic: response.data.data.profilePic,
          tableConfiguration: response.data.data.tableConfiguration,
        }),
      );
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* doSSOFunction(action) {
  console.log(action.payload);
  try {
    const options = {
      method: "POST",
      url: UserLoginSSO(),
      data: {
        access_token: action.payload.access_token,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(
        doLoginSuccess({
          id: response.data.data.id,
          name: response.data.data.name,
          email: response.data.data.email,
          admin: response.data.data.admin,
          superAdmin: response.data.data.superAdmin,
          timezone: response.data.data.timezone,
          organisations: response.data.data.organisations,
          sessionid: response.data.data.sessionid,
          token: response.data.data.token,
          profilePic: response.data.data.profilePic,
          tableConfiguration: response.data.data.tableConfiguration,
        }),
      );
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* doResetWorker(action) {
  try {
    const options = {
      method: "POST",
      url: UserResetPassword(),
      data: {
        email: action.payload.email,
        password: action.payload.password,
        token: action.payload.token,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* doResetRequestWorker(action) {
  try {
    const options = {
      method: "GET",
      url: UserResetPassword(),
      params: {
        email: action.payload.email,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* getProfileWorker(action) {
  try {
    const options = {
      method: "GET",
      url: UserProfile(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
      yield put(
        getProfileSuccess({
          id: response.data.data.id,
          name: response.data.data.name,
          email: response.data.data.email,
          admin: response.data.data.admin,
          superAdmin: response.data.data.superAdmin,
          timezone: response.data.data.timezone,
          organisations: response.data.data.organisations,
          profilePic: response.data.data.profilePic,
          permissions: response.data.data.permissions,
          tableConfiguration: response.data.data.tableConfiguration,
        }),
      );
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateProfileWorker(action) {
  try {
    const options = {
      method: "POST",
      url: UserProfileUpdate(),
      headers: {
        Authorization: yield select(getToken),
      },
      data: {
        timezone: action.payload.timezone,
        password: action.payload.password,
        profilePic: action.payload.profileImage,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* updateTableConfigsWorker(action) {
  try {
    const options = {
      method: "POST",
      url: UserProfileUpdate(),
      headers: {
        Authorization: yield select(getToken),
      },
      data: {
        tableConfiguration: action.payload.tableConfiguration,
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}
function* doLogoutFunction(action) {
  try {
    const options = {
      method: "POST",
      url: UserLogout(),
      headers: {
        Authorization: yield select(getToken),
      },
    };
    yield put(doLogoutSuccess());
    action?.payload?.onSuccess && action.payload.onSuccess(true);
    yield call(axios, options);
  } catch (e) {
    const error = e.response?.data?.error || e;
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* UserSaga() {
  yield takeLatest(Types.USER_LOGIN, doLoginFunction);
  yield takeLatest(Types.USER_LOGIN_SSO, doSSOFunction);
  yield takeLatest(Types.USER_LOGOUT, doLogoutFunction);
  yield takeLatest(Types.USER_RESET, doResetWorker);
  yield takeLatest(Types.USER_RESET_REQUEST, doResetRequestWorker);
  yield takeLatest(Types.USER_PROFILE, getProfileWorker);
  yield takeLatest(Types.USER_PROFILE_UPDATE, updateProfileWorker);
  yield takeLatest(Types.USER_UPDATE_TABLE_CONFIGS, updateTableConfigsWorker);
}
