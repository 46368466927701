import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createEmploymentHistory,
  fetchEmploymentHistoryById,
  fetchControllerConfigs,
  removeAppNotification,
  updateEmploymentHistory,
  uploadFile,
} from "../../../../../redux";
import {
  CreateEmploymentHistoryPayload,
  EmploymentHistoryList,
  FetchEmploymentHistoryByIdPayload,
  UpdateEmploymentHistoryPayload,
} from "../../../../../redux/EmploymentHistory/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  EmploymentHistoryForm,
  SelectOptions,
  TableHeaderButton,
  WorkLogItem,
  useEmploymentHistoryControllerProps,
  useEmploymentHistoryControllerReturnType,
} from "../../../../../types";
import { DashboardAdministrationFormDataType } from "../../../../../types";
import { collections, emailRegex, phoneRegex, validatePhone } from "../../../../../utils";

const addFormSteps = ["Overview", "Address", "Job Details"];
const editFormSteps = [
  "Overview",
  "Address",
  "Job Details",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const overViewFields = [
  "associatedEmployee",
  "employmentType",
  "permissionToContactReason",
  "permissionToContact",
  "startDate",
  "endDate",
];

const addressFields = [
  "employerName",
  "country",
  "state",
  "addressLineOne",
  "addressLineTwo",
  "city",
  "postalCode",
  "phone",
  "extension",
];

const jobDetailsField = [
  "jobType",
  "releventExperience",
  "isAgency",
  "agencyName",
  "agencyCountry",
  "agencyState",
  "agencyAddressLineOne",
  "agencyAddressLineTwo",
  "agencyCity",
  "agencyPostalCode",
  "department",
  "jobTitle",
  "supervisorName",
  "supervisorTitle",
  "supervisorEmail",
  "supervisorPhone",
  "reasonForLeaving",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
export const useEmploymentHistoryController = ({
  type,
  id,
  attachmentsPickerRef,
}: useEmploymentHistoryControllerProps): useEmploymentHistoryControllerReturnType => {
  const defaultFormData: EmploymentHistoryForm = {
    associatedEmployee: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    employmentType: {
      value: "",
      required: true,
      error: "",
    },
    permissionToContactReason: {
      value: "",
      required: false,
      error: "",
    },
    permissionToContact: {
      value: false,
      required: false,
      error: "",
    },
    startDate: {
      value: null,
      required: true,
      error: "",
    },
    endDate: {
      value: null,
      required: true,
      error: "",
    },
    currentlyWorking: {
      value: false,
      required: false,
      error: "",
    },
    employerName: {
      value: "",
      required: true,
      error: "",
    },
    country: {
      value: "",
      required: true,
      error: "",
    },
    state: {
      value: "",
      required: true,
      error: "",
    },
    addressLineOne: {
      value: "",
      required: true,
      error: "",
    },
    addressLineTwo: {
      value: "",
      required: false,
      error: "",
    },
    city: {
      value: "",
      required: true,
      error: "",
    },
    postalCode: {
      value: "",
      required: true,
      error: "",
    },
    phone: {
      value: "",
      required: true,
      error: "",
    },
    extension: {
      value: "",
      required: false,
      error: "",
    },
    jobType: {
      value: "",
      required: true,
      error: "",
    },
    releventExperience: {
      value: false,
      required: false,
      error: "",
    },
    isAgency: {
      value: false,
      required: false,
      error: "",
    },
    agencyName: {
      value: "",
      required: false,
      error: "",
    },
    agencyCountry: {
      value: "",
      required: false,
      error: "",
    },
    agencyState: {
      value: "",
      required: false,
      error: "",
    },
    agencyAddressLineOne: {
      value: "",
      required: false,
      error: "",
    },
    agencyAddressLineTwo: {
      value: "",
      required: false,
      error: "",
    },
    agencyCity: {
      value: "",
      required: false,
      error: "",
    },
    agencyPostalCode: {
      value: "",
      required: false,
      error: "",
    },
    department: {
      value: "",
      required: true,
      error: "",
    },
    jobTitle: {
      value: "",
      required: true,
      error: "",
    },
    supervisorName: {
      value: "",
      required: true,
      error: "",
    },
    supervisorTitle: {
      value: "",
      required: true,
      error: "",
    },
    supervisorEmail: {
      value: "",
      required: true,
      error: "",
    },
    supervisorPhone: {
      value: "",
      required: true,
      error: "",
    },
    reasonForLeaving: {
      value: "",
      required: true,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const list = useAppSelector((state) => state.AddressHistoryReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<EmploymentHistoryForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<EmploymentHistoryList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    const { employees } = collections;
    return [employees.label];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData?.[field]?.error) {
              count += 1;
            }
          });
          break;
        case 1:
          addressFields.map((field) => {
            if (formData?.[field]?.error) {
              count += 1;
            }
          });
          break;
        case 2:
          jobDetailsField.map((field) => {
            if (formData?.[field]?.error) {
              console.log(field);
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "currentlyWorking") {
        if (value) {
          tempFormData = {
            ...tempFormData,
            endDate: {
              ...tempFormData.endDate,
              error: "",
              required: false,
            },
          };
        } else {
          tempFormData = {
            ...tempFormData,
            endDate: {
              ...tempFormData.endDate,
              required: true,
            },
          };
        }
      }
      if (name === "isAgency") {
        if (value) {
          tempFormData = {
            ...tempFormData,
            agencyName: {
              ...tempFormData.agencyName,
              required: true,
            },
            agencyAddressLineOne: {
              ...tempFormData.agencyAddressLineOne,
              required: true,
            },
            agencyCity: {
              ...tempFormData.agencyCity,
              required: true,
            },
            agencyState: {
              ...tempFormData.agencyState,
              required: true,
            },
            agencyCountry: {
              ...tempFormData.agencyCountry,
              required: true,
            },
            agencyPostalCode: {
              ...tempFormData.agencyPostalCode,
              required: true,
            },
          };
        } else {
          tempFormData = {
            ...tempFormData,
            agencyName: {
              ...tempFormData.agencyName,
              required: false,
              error: "",
            },
            agencyAddressLineOne: {
              ...tempFormData.agencyAddressLineOne,
              required: false,
              error: "",
            },
            agencyCity: {
              ...tempFormData.agencyCity,
              required: false,
              error: "",
            },
            agencyState: {
              ...tempFormData.agencyState,
              required: false,
              error: "",
            },
            agencyCountry: {
              ...tempFormData.agencyCountry,
              required: false,
              error: "",
            },
            agencyPostalCode: {
              ...tempFormData.agencyPostalCode,
              required: false,
              error: "",
            },
          };
        }
      }

      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (employmentHistory: EmploymentHistoryList) => {
      const item = employmentHistory;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["employmentType", "associatedEmployee"].includes(field)) {
          const tempValue = value._id;
          value = tempValue;
        }
        if (field === "currentlyWorking") {
          if (value) {
            data.endDate.required = false;
          } else {
            data.endDate.required = true;
          }
        }
        if (addressFields.includes(field)) {
          value = employmentHistory?.address?.[field];
          if (["country"].includes(field)) {
            value = employmentHistory?.address?.[field]?._id;
          }
        }
        if (jobDetailsField.includes(field)) {
          value = employmentHistory?.employmentJobDetails?.[field];
          if (["agencyCountry", "jobType", "agencyCountry", "department"].includes(field)) {
            value = employmentHistory?.employmentJobDetails?.[field]?._id;
          }
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchEmploymentHistory = useCallback(() => {
    setLoading(true);
    const payload: FetchEmploymentHistoryByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchEmploymentHistoryById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];
      if (!currentField.required) {
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "",
          },
        };
      }
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (["phone", "supervisorPhone"].includes(item) && !validatePhone(String(validValue))) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid phone number with country code",
          },
        };
      } else if (item === "supervisorEmail" && !String(validValue).match(emailRegex)) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid email",
          },
        };
      } else if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      attachments,
      associatedEmployee,
      employmentType,
      permissionToContactReason,
      permissionToContact,
      startDate,
      endDate,
      currentlyWorking,
      employerName,
      country,
      state,
      addressLineOne,
      addressLineTwo,
      city,
      postalCode,
      phone,
      extension,
      jobType,
      releventExperience,
      isAgency,
      agencyName,
      agencyCountry,
      agencyState,
      agencyAddressLineOne,
      agencyAddressLineTwo,
      agencyCity,
      agencyPostalCode,
      department,
      jobTitle,
      supervisorName,
      supervisorTitle,
      supervisorEmail,
      supervisorPhone,
      reasonForLeaving,
      active,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateEmploymentHistoryPayload = {
        data: {
          attachments: attachments,
          associatedEmployee: associatedEmployee.value,
          employmentType: employmentType.value,
          permissionToContactReason: permissionToContactReason.value,
          permissionToContact: permissionToContact.value,
          startDate: startDate.value,
          endDate: currentlyWorking.value ? null : endDate.value,
          currentlyWorking: currentlyWorking.value,

          address: {
            employerName: employerName.value,
            country: country.value,
            city: city.value,
            state: state.value,
            postalCode: postalCode.value,
            addressLineOne: addressLineOne.value,
            addressLineTwo: addressLineTwo.value,
            phone: phone.value,
            extension: extension.value,
          },
          employmentJobDetails: {
            jobType: jobType.value,
            releventExperience: releventExperience.value,
            isAgency: isAgency.value,
            agencyName: agencyName.value,
            agencyCountry: agencyCountry.value || null,
            agencyState: agencyState.value,
            agencyAddressLineOne: agencyAddressLineOne.value,
            agencyAddressLineTwo: agencyAddressLineTwo.value,
            agencyCity: agencyCity.value,
            agencyPostalCode: agencyPostalCode.value,
            department: department.value,
            jobTitle: jobTitle.value,
            supervisorName: supervisorName.value,
            supervisorTitle: supervisorTitle.value,
            supervisorEmail: supervisorEmail.value,
            supervisorPhone: supervisorPhone.value,
            reasonForLeaving: reasonForLeaving.value,
          },
          active: active.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "EmploymentHistory created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createEmploymentHistory(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      associatedEmployee,
      employmentType,
      permissionToContactReason,
      permissionToContact,
      startDate,
      endDate,
      currentlyWorking,
      employerName,
      country,
      state,
      addressLineOne,
      addressLineTwo,
      city,
      postalCode,
      phone,
      extension,
      jobType,
      releventExperience,
      isAgency,
      agencyName,
      agencyCountry,
      agencyState,
      agencyAddressLineOne,
      agencyAddressLineTwo,
      agencyCity,
      agencyPostalCode,
      department,
      jobTitle,
      supervisorName,
      supervisorTitle,
      supervisorEmail,
      supervisorPhone,
      reasonForLeaving,
      active,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const payload: UpdateEmploymentHistoryPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          associatedEmployee: associatedEmployee.value,
          employmentType: employmentType.value,
          permissionToContactReason: permissionToContactReason.value,
          permissionToContact: permissionToContact.value,
          startDate: startDate.value,
          endDate: currentlyWorking.value ? null : endDate.value,
          currentlyWorking: currentlyWorking.value,
          address: {
            employerName: employerName.value,
            country: country.value,
            state: state.value,
            city: city.value,
            addressLineOne: addressLineOne.value,
            postalCode: postalCode.value,
            addressLineTwo: addressLineTwo.value,
            phone: phone.value,
            extension: extension.value,
          },
          employmentJobDetails: {
            jobType: jobType.value,
            releventExperience: releventExperience.value,
            isAgency: isAgency.value,
            agencyName: agencyName.value,
            agencyCountry: agencyCountry.value || null,
            agencyState: agencyState.value,
            agencyAddressLineOne: agencyAddressLineOne.value,
            agencyAddressLineTwo: agencyAddressLineTwo.value,
            agencyCity: agencyCity.value,
            agencyPostalCode: agencyPostalCode.value,
            department: department.value,
            jobTitle: jobTitle.value,
            supervisorName: supervisorName.value,
            supervisorTitle: supervisorTitle.value,
            supervisorEmail: supervisorEmail.value,
            supervisorPhone: supervisorPhone.value,
            reasonForLeaving: reasonForLeaving.value,
          },
          active: active.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "EmploymentHistory updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateEmploymentHistory(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateEmploymentHistoryPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "EmploymentHistory updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateEmploymentHistory(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.associatedEmployee?.name || "";
    }
    return [strings.headerTitle.employmentHistory, title];
  }, [data, type]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchEmploymentHistory,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
  };
};
