import styled from "styled-components";

import { fonts, fs, colors } from "../../../../theme";

interface Props {
  gradientOne?: string;
  gradientTwo?: string;
}

export const Container = styled.div<Props>`
  position: relative;
  width: 32.35%;
  height: 228px;
  border: 0.3px solid rgba(0, 0, 0, 0.75);
  background: ${(props) =>
    `linear-gradient(112.28deg, ${props.gradientOne} -4.55%, ${props.gradientTwo} 115.24%)`};
  box-sizing: border-box;
  padding: 2%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Icon = styled.img`
  width: 47px;
  height: 47px;
  object-fit: contain;
  margin-left: -4px;
`;

export const Count = styled.text`
  font-family: ${fonts.OpenSans};
  font-size: ${fs.xxxl};
  color: ${colors.common.titleColor};
  margin-top: 6%;
`;

export const Title = styled.h1`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.l};
  color: ${colors.common.titleColor};
`;

export const Button = styled.div`
  display: flex;
  background-color: ${colors.common.white};
  padding: 2% 3%;
  font-size: ${fs.s};
  font-family: ${fonts.SourceSansPro};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  cursor: pointer;
`;
