import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

export const Container = styled.div`
  padding: 1vh 1vw;
  width: fit-content;
  background-color: #293548;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-size: 1rem;
`;
