import React from "react";

import styles from "./toast.styles";

const Toast = ({ type, message }) => {
  if (!message) {
    return null;
  } else {
    return (
      <styles.container type={type}>
        <styles.text type={type}>
          {typeof message === "string" || Array.isArray(message) ? message : "Unexpected error"}
        </styles.text>
      </styles.container>
    );
  }
};

export default Toast;
