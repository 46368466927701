import React, { useCallback, useMemo, useState } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteAcademyCourses,
  fetchAcademyCoursesList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateAcademyCourses,
  updateTableConfigs,
} from "../../../../../redux";
import {
  AcademyCoursesList,
  AcademyCoursesListWithMetaData,
  DeleteAcademyCoursesPayload,
  FetchAcademyCoursesParams,
} from "../../../../../redux/AcademyCourses/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchAcademyCoursesProps,
  sortType,
  useAcademyCoursesListProps,
  useAcademyCoursesListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getAcademyCoursesColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.academyCourses;
const defaultFilters: QueryBuilderType[] = [];

export const useAcademyCoursesList = ({
  relatedList,
  widgetId,
}: useAcademyCoursesListProps): useAcademyCoursesListReturnType => {
  const inAppLoader = useAppSelector((state) => state.ApplicationReducer.inAppLoader);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.AcademyCoursesReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const academyCourses = useAppSelector((state) => state.AcademyCoursesReducer.data.list);
  const { employeesOptions, usersOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const academyCoursesTableConfigFields = useMemo(() => {
    return tableConfiguration?.academyCourses?.fields?.length
      ? tableConfiguration?.academyCourses?.fields
      : tableListDefaultSelectFields.academyCourses;
  }, [tableConfiguration]);

  const columns = useMemo(() => {
    const fields: string[] = academyCoursesTableConfigFields;
    return getAcademyCoursesColumns({ fields });
  }, [academyCoursesTableConfigFields]);
  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: AcademyCoursesList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.academyCourses?.fields || tableListDefaultSelectFields.academyCourses;
      records = records?.map((item) => {
        const academyCourseItem = { ...item };
        if (userPersonalizedFields?.includes("technology") && typeof item.technology !== "string") {
          academyCourseItem.technology = item?.technology?.value;
        }
        if (userPersonalizedFields?.includes("preparedBy") && typeof item.preparedBy !== "string") {
          academyCourseItem.preparedBy = item?.preparedBy?.name;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          academyCourseItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          academyCourseItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          academyCourseItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          academyCourseItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete academyCourseItem?._id;
        return academyCourseItem;
      });
      const academyCoursesFields = tablePublicFields.academyCourses;
      const columnHeaders = academyCoursesFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "course_offerings");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.academyCourses?.fields],
  );

  // ================ Fetch Academy Courses Success/Error Methods ================
  const academyCoursesFetchSuccess = useCallback(
    (data: AcademyCoursesListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const academyCoursesFetchError = useCallback(
    (error: string) => {
      setLoading(false);
      if (inAppLoader) {
        dispatch(showInAppLoader(false));
      }
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, inAppLoader],
  );

  // ================ Fetch Academy Courses Method ================

  const fetchAcademyCourses = useCallback(
    ({
      page = 1,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchAcademyCoursesProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.academyCourses?.limit || 10;

      const params: FetchAcademyCoursesParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: AcademyCoursesListWithMetaData) =>
          academyCoursesFetchSuccess(data, exportAll),
        onError: academyCoursesFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchAcademyCoursesList(payload));
    },
    [
      widgetId,
      mongoQuery,
      metaData.currentPage,
      tableConfiguration?.academyCourses?.limit,
      sortKey,
      sortType,
      relatedList,
      academyCoursesFetchError,
      dispatch,
      widgetsData,
      academyCoursesFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = academyCourses?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, academyCourses, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchAcademyCourses({ exportAll: true });
    },
    [fetchAcademyCourses],
  );

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchAcademyCourses({ page });
  }, [fetchAcademyCourses, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchAcademyCourses({ page: parseInt(page), search });
    },
    [fetchAcademyCourses, search],
  );

  // ================ Add/Edit Methods ================

  const onClickAcademyCourses = useCallback(
    (id: string) => {
      if (permissions?.academyCourses_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.academyCourses_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchAcademyCourses({ page: 1, search });
  }, [fetchAcademyCourses, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchAcademyCourses({ page: 1 });
  }, [fetchAcademyCourses]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchAcademyCourses({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchAcademyCourses],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchAcademyCourses({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchAcademyCourses],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = academyCourses?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteAcademyCoursesPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchAcademyCourses({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteAcademyCourses(payload));
  }, [selectedRows, academyCourses, dispatch, notificationsId, fetchAcademyCourses]);

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = academyCourses?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, academyCourses]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      console.log(e, id);
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            academyCourses?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, academyCourses, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const academyCoursesConfigLimit = tableConfiguration?.academyCourses?.limit || 10;
      const academyCoursesConfigs = {
        table: "academyCourses",
        fields: fields,
        limit: academyCoursesConfigLimit,
      };
      const payload = {
        tableConfiguration: academyCoursesConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchAcademyCourses({});
          dispatch(getProfile({}));
          dispatch(showInAppLoader(false));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Course Offerings table fields configs saved successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          dispatch(showInAppLoader(false));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.academyCourses?.limit, dispatch, fetchAcademyCourses, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const academyCoursesConfigFields = tableConfiguration?.academyCourses?.fields;
      dispatch(showInAppLoader(true));
      const academyCoursesConfigs = {
        table: "academyCourses",
        fields: academyCoursesConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: academyCoursesConfigs,
        onSuccess: () => {
          fetchAcademyCourses({ limit: count });
          dispatch(showInAppLoader(false));
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Academy Course table fields configs saved successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          dispatch(showInAppLoader(false));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchAcademyCourses],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = academyCourses?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          dispatch(showInAppLoader(false));
          fetchAcademyCourses({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Courses updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          dispatch(showInAppLoader(false));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating courses",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateAcademyCourses(payload));
    },
    [selectedRows, academyCourses, dispatch, notificationsId, fetchAcademyCourses],
  );
  // ================ Table Header Buttons ================

  const academyCoursesHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "active",
      label: "Active",
      options: ActiveSelectOptions,
    },
    {
      name: "technology",
      label: "Technology",
      options: getAttributValues("technology"),
      required: true,
    },
    {
      name: "preparedBy",
      label: "Course Prepared By",
      options: employeesOptions,
      required: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "numberOfTopics",
      label: "Number Of Topics",
      type: "text",
    },
    {
      name: "downloadableResources",
      label: "Downloadable Resources",
      type: "text",
    },
    {
      name: "shortDescription",
      label: "Short Description",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "bigTextArea",
    },
    {
      name: "curriculamAttached",
      label: "Curriculam Attached",
      options: ActiveSelectOptions,
    },
    {
      name: "courseDuration",
      label: "Course Duration",
      type: "text",
    },
    {
      name: "prerequisites",
      label: "Prerequisites",
      type: "text",
    },
    {
      name: "anyDriveURL",
      label: "Any Drive URL",
      type: "text",
    },
    {
      name: "cardIconAttached",
      label: "Card Icon Attached",
      options: ActiveSelectOptions,
    },
    {
      name: "cardBackgroundAttached",
      label: "Card Background Attached",
      options: ActiveSelectOptions,
    },
    {
      name: "courseDetailsIconAttached",
      label: "Cource Details Icon Attached",
      options: ActiveSelectOptions,
    },
  ];

  // ================ Filter Fields Configuration ================

  const academyCoursesFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const technologyOptions = getFilterOptions(getAttributValues("technology"), "Technology");
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const userOptions = getFilterOptions(usersOptions, "Users");

    const fields: Field[] = [
      {
        name: "name",
        label: "Name",
        placeholder: "Enter name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },

      {
        name: "technology",
        label: "Technology",
        valueEditorType: "select",
        values: technologyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "preparedBy",
        label: "Prepared By",
        valueEditorType: "select",
        values: employeeyOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "curriculamAttached",
        label: "Curriculum Attached",
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "courseDuration",
        label: "Course Duration",
        placeholder: "Enter name",
        defaultOperator: "contains",
        validator,
      },
      {
        name: "shortDescription",
        label: "Short Description",
        inputType: "string",
        validator,
      },
      {
        name: "numberOfTopics",
        label: "Number Of Topics",
        inputType: "string",
        validator,
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "downloadableResources",
        label: "Downloadable Resources",
        inputType: "string",
        validator,
      },
      {
        name: "cardIconAttached",
        label: "Card Icon Attached",
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "cardBackgroundAttached",
        label: "Card Background Attached",
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "courseDetailsIconAttached",
        label: "Course Details Icon Attached",
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "prerequisites",
        label: "Prerequisites",
        inputType: "string",
        validator,
      },
      {
        name: "anyDriveURL",
        label: "Any Drive URL",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [employeesOptions, usersOptions]);

  return {
    listActionOptions,
    academyCoursesTableConfigFields,
    columns,
    academyCoursesFilterFields,
    onClickAcademyCourses,
    fetchAcademyCourses,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyCoursesHeaderButtons,
    exportSelectedRecords,
    exportAllRecords,
    onSubmitDeleteRecord,
  };
};
