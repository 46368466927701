import styled from "styled-components";

import { colors, fs } from "../../../../theme";

interface Props {
  active?: boolean;
  noBorder?: boolean;
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.common.white};
  border: 0.6px solid ${colors.common.borderColor};
  box-shadow: 0px 2px 2px ${colors.common.shadow};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;

export const FormTitle = styled.div`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const RelatedListContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const NoItemsContainer = styled.div`
  width: 100%;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${colors.common.modalTitleColor};
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;

export const PopupInsideContainer = styled.div`
  padding: 15px;
  width: 645px;
`;

export const AddTablesModalBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  height: 500px;
`;

export const TablesList = styled.div`
  width: 45%;
  border: 0.5px solid black;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const TablesListHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.table.headerBackground};
  padding: 2%;
  border-bottom: 0.4px solid black;
`;

export const TableTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${colors.common.purpleTitle};
`;

export const TableSearchBarContainer = styled.div`
  justify-content: center;
  display: flex;
  border-bottom: 0.4px solid black;
`;

export const PermissionsList = styled.div``;

export const HorizontalLine = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 0.6px solid ${colors.common.greyBorder};
`;

export const ListItem = styled.div<Props>`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.common.modalTitleColor};
  background-color: ${(props) =>
    props.selected ? colors.navigationPanel.activeTabBackground : colors.common.white};
  color: ${(props) => (props.selected ? colors.common.purpleTitle : colors.common.black)};
  padding: 12px;
  cursor: pointer;
`;

export const CenterContainer = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ArrowRight = styled.img`
  height: 24px;
  width: 24px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const ArrowLeft = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const FullAccessSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalSaveContainer = styled.div`
  margin-top: 2%;
  width: 18%;
`;

export const ListItemContainer = styled.div`
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 2px;
`;

export const TempContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const TempRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  margin-right: 10%;
  width: 33%;
`;

export const TempTitle = styled.div`
  font-size: small;
  margin-right: 7px;
`;

export const AddTableButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${colors.common.black};
  margin: 10px;
  width: 90%;
  height: 50%;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
`;

export const SearchInput = styled.input`
  border: 0;
  flex: 1;
  display: flex;
  padding: 8px;
  outline: none;
  width: 100%;
`;

export const SearchIcon = styled.img`
  margin-right: 10px;
  position: absolute;
  right: 0;
`;

export const TempRowContainer = styled.div`
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  width: 100%;
`;

export const FormChecklistContainer = styled.div`
  display: flex;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const ChecklistModalContainer = styled.div`
  display: flex;
  padding: 30px;
  width: 30vw;
  flex-direction: column;
  background-color: ${colors.common.white};
  .employee-modal-save-button {
    margin-top: 20px;
    width: fit-content;
  }
`;

export const ChecklistItemContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  height: 40vh;
  /* background: blue; */
  overflow-y: auto;
  .table-configs-checkbox {
    margin-top: 0;
  }
  .table-configs-checkbox-wrapper {
    padding: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .table-configs-checkbox-label {
    position: relative;
    margin: 0 10px;
    top: 0;
  }
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;
