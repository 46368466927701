import React from "react";

import { Layout } from "../../../presentationals";
import { GroupsController } from "../../../presentationals/screens/admin/Groups/GroupsController";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<GroupsController type='add' />}
      admin={true}
      title={strings.headerTitle.groups}
      layout={"2"}
    />
  );
};

export const APGroupsAdd = React.memo(Route);
// export default Route;
