import styled from "styled-components";

const styles = {};

styles.MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
`;

styles.Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

styles.Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
`;
styles.Column = styled.div`
  flex: 1;
`;

styles.Item = styled.div`
  display: flex;
  border: 0.5px solid #333;
  margin: 5px;
  padding: 5px;
  flex-direction: column;
`;

export default styles;
