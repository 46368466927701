import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getProjectHistoryColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    projectCode: {
      title: "Project Code",
      sortSupported: true,
      key: "projectCode",
    },
    associatedEmployee: {
      title: "Employee",
      sortSupported: true,
      key: "associatedEmployee",

      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    projectType: {
      title: "Project Type",

      key: "projectType",
      render: (item) => {
        return <text className='title'>{item.projectType?.value || "-"}</text>;
      },
    },
    currentlyWorking: {
      title: "Currently Working",
      sortSupported: true,

      key: "currentlyWorking",
      render: (item) => {
        return <div className='title'>{item.currentlyWorking ? "Yes" : "No"}</div>;
      },
    },
    projectTechnology: {
      title: "Technology",

      key: "projectTechnology",
      render: (item) => {
        return <text className='title'>{item.projectTechnology?.value || "-"}</text>;
      },
    },
    projectStatus: {
      title: "Status",

      key: "projectStatus",
      render: (item) => {
        return <text className='title'>{item.projectStatus?.value || "-"}</text>;
      },
    },
    startDate: {
      title: "Start Date",
      sortSupported: true,
      key: "startDate",

      render: (item) => {
        return (
          <text className='title'>
            {item.startDate
              ? MomentDate({
                  date: item.startDate,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    endDate: {
      title: "End Date",
      sortSupported: true,
      key: "endDate",

      render: (item) => {
        return (
          <text className='title'>
            {item.endDate
              ? MomentDate({
                  date: item.endDate,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
