import styled from "styled-components";

import { colors, fs } from "../../../../theme";

interface Props {
  border?: string;
  leftArrow?: boolean;
  disabled?: boolean;
  top?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  height: fit-content;
  padding: 0 15px;
  height: 4.5vh;
  margin-top: ${(props) => props.top};
  color: ${colors.common.white};
  border: ${(props) => props.border || "0"} solid ${colors.common.borderColor};
  background-color: ${colors.common.yellow};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  .arrow {
    &::before {
      position: relative;
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-right: 2px solid ${colors.common.white};
      border-top: 2px solid ${colors.common.white};
    }
    &.left {
      transform: rotate(-135deg) translate(-25%);
    }
  }
`;

export const Title = styled.h2<Props>`
  font-size: ${fs.m};
  font-weight: 600;
  line-height: 20px;
  margin-left: ${(props) => (props.leftArrow ? "8px" : "0")};
`;
