import React from "react";

import { Layout } from "../../../presentationals";
import { NewsletterResourcesList } from "../../../presentationals/screens/admin/NewsletterResources/NewsletterResourcesList";

const Route = () => {
  return <Layout activity={<NewsletterResourcesList />} admin={true} layout={"2"} />;
};

export const APNewsletterResourcesList = React.memo(Route);
