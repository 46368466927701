import React from "react";
import { useState, useMemo, useCallback, MouseEvent, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createAcademyACH,
  createAcademyCandidateEnrollments,
  createAcademyCandidateLeads,
  fetchAcademyCandidateLeadsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateAcademyCandidateLeads,
  uploadFile,
} from "../../../../../redux";
import { CreateAcademyACHPayload } from "../../../../../redux/AcademyACH/types";
import { CreateAcademyCandidateEnrollmentsPayload } from "../../../../../redux/AcademyCandidateEnrollments/types";
import {
  AcademyCandidateLeadsList,
  CreateAcademyCandidateLeadsData,
  CreateAcademyCandidateLeadsPayload,
  FetchAcademyCandidateLeadsByIdPayload,
  UpdateAcademyCandidateLeadsPayload,
} from "../../../../../redux/AcademyCandidateLeads/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  AcademyCandidateLeadsForm,
  SelectOptions,
  WorkLogItem,
  AcademyCandidateLeadsControllerProps,
  useAcademyCandidateLeadsControllerReturnType,
  TableHeaderButton,
} from "../../../../../types";
import { DashboardAdministrationFormDataType } from "../../../../../types";
import { collections, emailRegex, phoneRegex, validatePhone } from "../../../../../utils";

// ================ Contstants ================

const addFormSteps = ["Overview", "Sales Details"];
const editFormSteps = [
  "Overview",
  "Sales Details",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "stage",
  "associatedContact",
  "course",
  "dob",
  "status",
  "source",
  "otherSource",
  "graduationYear",
  "graduationDegree",
  "otherDegree",
  "graduationMajor",
  "linkedInURL",
  "city",
  "state",
  "country",
  "currency",
];
const salesDetailsFields = [
  "admissionCounsellor",
  "enrollmentSpecialists",
  "sourceContactDate",
  "saleDate",
  "totalFees",
  "paymentTerms",
  "anyDiscountsGiven",
];
export const useAcademyCandidateLeadsController = ({
  type,
  id,
  attachmentsPickerRef,
}: AcademyCandidateLeadsControllerProps): useAcademyCandidateLeadsControllerReturnType => {
  const defaultFormData: AcademyCandidateLeadsForm = {
    firstName: {
      value: "",
      required: true,
      error: "",
    },
    email: {
      value: "",
      required: true,
      error: "",
    },
    phone: {
      value: "",
      required: true,
      error: "",
    },
    status: {
      value: null,
      required: true,
      error: "",
    },
    source: {
      value: "",
      required: true,
      error: "",
    },
    city: {
      value: "",
      required: true,
      error: "",
    },
    state: {
      value: "",
      required: true,
      error: "",
    },
    country: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: false,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    lastName: {
      value: "",
      required: true,
      error: "",
    },
    stage: {
      value: null,
      required: true,
      error: "",
    },
    course: {
      value: "",
      required: true,
      error: "",
    },
    dob: {
      value: null,
      required: true,
      error: "",
    },
    otherSource: {
      value: "",
      required: false,
      error: "",
    },
    graduationYear: {
      value: "",
      required: false,
      error: "",
    },
    graduationDegree: {
      value: "",
      required: false,
      error: "",
    },
    otherDegree: {
      value: "",
      required: false,
      error: "",
    },
    graduationMajor: {
      value: "",
      required: false,
      error: "",
    },
    linkedInURL: {
      value: "",
      required: false,
      error: "",
    },
    admissionCounsellor: {
      value: "",
      required: true,
      error: "",
    },
    enrollmentSpecialists: {
      value: "",
      required: false,
      error: "",
    },
    sourceContactDate: {
      value: null,
      required: false,
      error: "",
    },
    saleDate: {
      value: null,
      required: false,
      error: "",
    },
    totalFees: {
      value: "",
      required: false,
      error: "",
    },
    paymentTerms: {
      value: "",
      required: false,
      error: "",
    },
    anyDiscountsGiven: {
      value: "",
      required: false,
      error: "",
    },
    associatedContact: {
      value: "",
      required: false,
      error: "",
    },
    currency: {
      value: "",
      required: false,
      error: "",
    },
  };
  const list = useAppSelector((state) => state.AcademyCandidateLeadsReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AcademyCandidateLeadsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");

  const [data, setData] = useState<AcademyCandidateLeadsList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    const { academyCourses, academyCandidates, academyCandidateEnrollments, academyACH, achTask } =
      collections;
    return [
      academyCourses.label,
      academyCandidates.label,
      academyCandidateEnrollments.label,
      academyACH.label,
      achTask.label,
    ];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        case 1:
          salesDetailsFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const handleConvertACH = useCallback(() => {
    const valid = validateForm();
    if (!valid) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setLoading(true);
    const attachments = formData.attachments.value.map((item) => ({
      name: item.name,
      id: item.id,
      extension: item.extension,
    }));
    const payload: CreateAcademyACHPayload = {
      data: {
        attachments: attachments,
        associatedCandidateLeads: id,
      },
      onSuccess: (data) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "AcademyACH created successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        navigate(`/ap/academy-ach/${data?._id}`);
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(createAcademyACH(payload));
  }, [formData.attachments.value, id, dispatch, notificationsId, navigate]);

  const handleConvertEnrolment = useCallback(() => {
    const {
      totalFees,
      paymentTerms,
      firstName,
      lastName,
      email,
      currency,
      anyDiscountsGiven,
      course,
    } = formData;
    const valid = validateForm();
    if (!valid) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    setLoading(true);
    const attachments = formData.attachments.value.map((item) => ({
      name: item.name,
      id: item.id,
      extension: item.extension,
    }));
    // const batches = batchSelected.value.map((item) => String(item.value));

    const payload: CreateAcademyCandidateEnrollmentsPayload = {
      data: {
        attachments: attachments,
        studentName: `${firstName.value} ${lastName.value}`,
        studentOfficialEmail: email.value,
        totalFees: totalFees.value,
        paymentTerms: paymentTerms.value,
        candidateLead: id,
        anyDiscountsGiven: anyDiscountsGiven.value,
        currency: currency.value,
        enrolledCourse: course.value,
      },
      onSuccess: (data) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "AcademyCandidateEnrollments created successfully",
          type: "success",
        };
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        navigate(`/ap/academy-candidate-enrollments/${data?._id}`);
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(createAcademyCandidateEnrollments(payload));
  }, [formData, notificationsId, id, dispatch, navigate]);

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (academyCandidateLeads: AcademyCandidateLeadsList) => {
      try {
        const item = academyCandidateLeads;
        const data = { ...formData };
        const tempAttachments: Attachments[] = [];
        Object.keys(formData).forEach((field) => {
          let value = item?.[field];
          if (
            [
              "status",
              "source",
              "country",
              "course",
              "stage",
              "graduationDegree",
              "graduationMajor",
              "admissionCounsellor",
              "enrollmentSpecialists",
              "paymentTerms",
              "currency",
            ].includes(field)
          ) {
            const tempValue = value?._id;
            value = tempValue;
          }
          if (field === "attachments") {
            for (let i = 0; i < value.length; i++) {
              const tempValue = {
                id: value[i].id,
                error: "",
                name: value[i].name,
                extension: value[i]?.extension,
              };
              tempAttachments.push(tempValue);
            }
            value = tempAttachments;
          }

          data[field].value = value;
        });
        setFormData(data);
      } catch (e) {
        console.log("AA ::", e);
      }
    },
    [formData],
  );

  const fetchAcademyCandidateLeads = useCallback(() => {
    setLoading(true);
    const payload: FetchAcademyCandidateLeadsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchAcademyCandidateLeadsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (["phone"].includes(item) && !validatePhone(String(validValue))) {
        // console.log(item, phoneRegex.test(validValue));
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid phone with country code",
          },
        };
      } else if (item === "email" && !String(validValue).match(emailRegex)) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid email",
          },
        };
      } else if (
        item === "linkedInURL" &&
        validValue &&
        !validator.isURL(validValue || "") &&
        !String(validValue).includes("linkedin")
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid LinkedIn URL",
          },
        };
      } else if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      attachments,
      firstName,
      lastName,
      email,
      phone,
      stage,
      associatedContact,
      course,
      dob,
      status,
      source,
      otherSource,
      graduationYear,
      graduationDegree,
      otherDegree,
      currency,
      graduationMajor,
      linkedInURL,
      city,
      state,
      country,
      active,
      admissionCounsellor,
      enrollmentSpecialists,
      sourceContactDate,
      saleDate,
      totalFees,
      paymentTerms,
      anyDiscountsGiven,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateAcademyCandidateLeadsPayload = {
        data: {
          attachments: attachments,
          associatedContact: associatedContact.value,
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          stage: stage.value,
          course: course.value,
          dob: dob.value,
          status: status.value,
          source: source.value,
          otherSource: otherSource.value,
          graduationYear: graduationYear.value,
          graduationDegree: graduationDegree.value || null,
          otherDegree: otherDegree.value,
          currency: currency.value,
          graduationMajor: graduationMajor.value || null,
          linkedInURL: linkedInURL.value,
          city: city.value,
          state: state.value,
          country: country.value,
          admissionCounsellor: admissionCounsellor.value,
          enrollmentSpecialists: enrollmentSpecialists.value,
          sourceContactDate: sourceContactDate.value,
          saleDate: saleDate.value,
          totalFees: totalFees.value,
          paymentTerms: paymentTerms.value,
          anyDiscountsGiven: anyDiscountsGiven.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "AcademyCandidateLeads created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createAcademyCandidateLeads(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      firstName,
      associatedContact,
      lastName,
      email,
      phone,
      stage,
      course,
      dob,
      status,
      source,
      otherSource,
      graduationYear,
      graduationDegree,
      otherDegree,
      currency,
      graduationMajor,
      linkedInURL,
      city,
      state,
      country,
      admissionCounsellor,
      enrollmentSpecialists,
      sourceContactDate,
      saleDate,
      totalFees,
      paymentTerms,
      anyDiscountsGiven,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const payload: UpdateAcademyCandidateLeadsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          associatedContact: associatedContact.value,
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          phone: phone.value,
          stage: stage.value,
          course: course.value,
          dob: dob.value,
          status: status.value,
          source: source.value,
          otherSource: otherSource.value,
          graduationYear: graduationYear.value,
          graduationDegree: graduationDegree.value || null,
          otherDegree: otherDegree.value,
          currency: currency.value,
          graduationMajor: graduationMajor.value || null,
          linkedInURL: linkedInURL.value,
          city: city.value,
          state: state.value,
          country: country.value,
          admissionCounsellor: admissionCounsellor.value,
          enrollmentSpecialists: enrollmentSpecialists.value,
          sourceContactDate: sourceContactDate.value,
          saleDate: saleDate.value,
          totalFees: totalFees.value,
          paymentTerms: paymentTerms.value,
          anyDiscountsGiven: anyDiscountsGiven.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "AcademyCandidateLeads updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyCandidateLeads(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateAcademyCandidateLeadsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "AcademyCandidateLeads updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateAcademyCandidateLeads(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      console.log(list);
      title = `${data?.firstName} ${data?.lastName}`;
    }
    return [strings.headerTitle.academyCandidateLeads, title];
  }, [type, list, data]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const { createEnrolment, createACH } = strings.apAcademyCandidateLeads;
    const buttons: TableHeaderButton[] = [
      {
        title: createEnrolment,
        onClick: handleConvertEnrolment,
        visible: type === "edit",
      },
      {
        title: createACH,
        onClick: handleConvertACH,
        visible: type === "edit",
      },
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [handleConvertACH, handleConvertEnrolment, onSubmitAdd, onSubmitUpdate, type]);

  return {
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCandidateLeads,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    attachmentModal,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    formRelatedList,
    formSteps,
    formTitle,
  };
};
