import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyACHColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    serial: {
      title: "Activity Number",
      sortSupported: true,
      key: "activityNumber",
      render: (item) => {
        return <text className='title'>{`ACHCRM${item?.serial}`}</text>;
      },
    },
    activityType: {
      title: "Activity Type",
      render: (item) => {
        return <text className='title'>{item?.activityType?.value || "-"}</text>;
      },
    },
    activityDate: {
      title: "Activity Date",
      sortSupported: true,
      center: true,
      render: (item) => {
        return (
          <text className='title'>
            {item?.activityDate
              ? MomentDate({ date: item.activityDate, format: "YYYY-MM-DD HH:mm" })
              : "-"}
          </text>
        );
      },
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",
      center: true,
      render: (item) => {
        return <text className='title'>{item.active ? "Yes" : "No"}</text>;
      },
    },
    state: {
      title: "State",
      key: "state",
      render: (item) => {
        return <text className='title'>{item.state?.value || "-"}</text>;
      },
    },
    from: {
      title: "From",
      sortSupported: true,
      key: "from",

      render: (item) => {
        return (
          <text className='title'>
            {item.from
              ? MomentDate({
                  date: item.from,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    to: {
      title: "To",
      sortSupported: true,
      key: "to",

      render: (item) => {
        return (
          <text className='title'>
            {item.to
              ? MomentDate({
                  date: item.to,
                  format: "YYYY-MM-DD HH:mm",
                })
              : "-"}
          </text>
        );
      },
    },
    activityOwner: {
      title: "Activity Owner",

      key: "activityOwner",
      render: (item) => {
        return (
          <text className='title'>{`${item?.activityOwner?.firstName || ""} ${
            item?.activityOwner?.lastName || ""
          }`}</text>
        );
      },
    },
    curriculamAttached: {
      title: "Curriculum Attached",
      sortSupported: true,
      key: "curriculamAttached",
      center: true,
      render: (item) => {
        return <text className='title'>{item.curriculamAttached ? "Yes" : "No"}</text>;
      },
    },
    associatedCandidateLeads: {
      title: "Associated Candidate Leads",
      sortSupported: true,
      render: (item) => {
        return <text className='title'>{item?.associatedCandidateLeads?.firstName || "-"}</text>;
      },
    },
    callDirection: {
      title: "Call Direction",
      render: (item) => {
        return <text className='title'>{item?.callDirection?.value || "-"}</text>;
      },
    },
    activityAgenda: {
      title: "Activity Agenda",
      sortSupported: true,
      key: "activityAgenda",
    },
    activityPurpose: {
      title: "Activity Purpose",
      render: (item) => {
        return <text className='title'>{item?.activityPurpose?.value || "-"}</text>;
      },
    },
    meetLocationURL: {
      title: "Meet Location URL",
      sortSupported: true,
      key: "meetLocationURL",
    },
    recordingURL: {
      title: "Recording URL",
      sortSupported: true,
      key: "recordingURL",
    },
    shortDescripiton: {
      title: "Short description",
      sortSupported: true,
      key: "shortDescripiton",
    },
    description: {
      title: "Description",
      sortSupported: true,
      key: "description",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
