import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SPSAccountContactList,
  AddSPSAccountContact,
  UpdateSPSAccountContact,
  FindSPSAccountContact,
  DeletePSAccountContact,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSPSAccountContactListSuccess, fetchSPSAccountContactError } from "./actions";
import Types from "./actionType";
import {
  CreateSPSAccountContactuccess,
  CreateSPSAccountContact as CreateSPSAccountContactType,
  SPSAccountContactSuccess,
  UpdateSPSAccountContact as UpdateSPSAccountContactType,
  FetchSPSAccountContact,
  FetchSPSAccountContactById,
  SPSAccountContactByIdSuccess,
  DeleteSPSAccountContact as DeleteSPSAccountContactType,
} from "./types";

function* createWorker(action: CreateSPSAccountContactType) {
  try {
    const options = {
      method: "POST",
      url: AddSPSAccountContact,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSAccountContactuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateSPSAccountContactType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSPSAccountContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSAccountContactuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchSPSAccountContact) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SPSAccountContactList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SPSAccountContactSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchSPSAccountContactListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchSPSAccountContactError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Account Contact");
    }
  }
}

function* fetchSPSAccountContactById(action: FetchSPSAccountContactById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSPSAccountContact(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SPSAccountContactByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Account Contact");
    }
  }
}

function* deleteWorker(action: DeleteSPSAccountContactType) {
  try {
    const options = {
      method: "DELETE",
      url: DeletePSAccountContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateSPSAccountContactuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    console.log("aa", e);
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SPS_ACCOUNT_CONTACT, createWorker);
  yield takeLatest(Types.UPDATE_SPS_ACCOUNT_CONTACT, updateWorker);
  yield takeEvery(Types.FETCH_SPS_ACCOUNT_CONTACT, fetchAllWorker);
  yield takeEvery(Types.FETCH_SPS_ACCOUNT_CONTACT_BY_ID, fetchSPSAccountContactById);
  yield takeEvery(Types.DELETE_SPS_ACCOUNT_CONTACT, deleteWorker);
}
