import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ContactUsList,
  AddContactUs,
  UpdateContactUs,
  FindContactUs,
  DeleteContactUs,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchContactUsListSuccess, fetchContactUsError } from "./actions";
import Types from "./actionType";
import {
  CreateContactUsuccess,
  CreateContactUs as CreateContactUsType,
  ContactUsSuccess,
  UpdateContactUs as UpdateContactUsType,
  FetchContactUs,
  FetchContactUsById,
  ContactUsByIdSuccess,
  DeleteContactUs as DeleteContactUsType,
} from "./types";

function* createWorker(action: CreateContactUsType) {
  try {
    const options = {
      method: "POST",
      url: AddContactUs,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateContactUsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateContactUsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateContactUs(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateContactUsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchContactUs) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ContactUsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ContactUsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchContactUsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchContactUsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Contact US");
    }
  }
}

function* fetchContactUsById(action: FetchContactUsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindContactUs(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<ContactUsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Contact US");
    }
  }
}

function* deleteWorker(action: DeleteContactUsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteContactUs(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateContactUsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_CONTACT_US, createWorker);
  yield takeLatest(Types.UPDATE_CONTACT_US, updateWorker);
  yield takeEvery(Types.FETCH_CONTACT_US, fetchAllWorker);
  yield takeEvery(Types.FETCH_CONTACT_US_BY_ID, fetchContactUsById);
  yield takeEvery(Types.DELETE_CONTACT_US, deleteWorker);
}
