/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPS_BG_CHECK = "FETCH_SPS_BG_CHECK",
  FETCH_SPS_BG_CHECK_BY_ID = "FETCH_SPS_BG_CHECK_BY_ID",
  SUCCESS_FETCH_SPS_BG_CHECK = "SUCCESS_FETCH_SPS_BG_CHECK",
  ERROR_FETCH_SPS_BG_CHECK = "ERROR_FETCH_SPS_BG_CHECK",
  CREATE_SPS_BG_CHECK = "CREATE_SPS_BG_CHECK",
  UPDATE_SPS_BG_CHECK = "UPDATE_SPS_BG_CHECK",
  DELETE_SPS_BG_CHECK = "DELETE_SPS_BG_CHECK",
}

export default Types;
