import Types from "./actionType";
import {
  FetchBugReportingPayload,
  CreateBugReporting,
  CreateBugReportingPayload,
  FetchBugReporting,
  FetchBugReportingError,
  FetchBugReportingSuccess,
  UpdateBugReporting,
  UpdateBugReportingPayload,
  BugReportingListWithMetaData,
  FetchBugReportingByIdPayload,
  FetchBugReportingById,
} from "./types";

export const fetchBugReportingList = ({
  params,
  onSuccess,
  onError,
}: FetchBugReportingPayload): FetchBugReporting => ({
  type: Types.FETCH_BUGREPORTING,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchBugReportingById = ({
  id,
  onSuccess,
  onError,
}: FetchBugReportingByIdPayload): FetchBugReportingById => ({
  type: Types.FETCH_BUGREPORTING_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchBugReportingListSuccess = (
  data: BugReportingListWithMetaData,
): FetchBugReportingSuccess => ({
  type: Types.SUCCESS_FETCH_BUGREPORTING,
  payload: {
    data,
  },
});

export const fetchBugReportingError = (error?: string): FetchBugReportingError => ({
  type: Types.ERROR_FETCH_BUGREPORTING,
  payload: { error },
});

export const createBugReporting = ({
  data,
  onSuccess,
  onError,
}: CreateBugReportingPayload): CreateBugReporting => ({
  type: Types.CREATE_BUGREPORTING,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateBugReporting = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateBugReportingPayload): UpdateBugReporting => ({
  type: Types.UPDATE_BUGREPORTING,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
