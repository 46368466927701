import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateTableConfigs,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { deleteITNRFP, fetchITNRFPList, updateITNRFP } from "../../../../../redux/RFP/actions";
import {
  ITNRFPListWithMetaData,
  FetchITNRFPParams,
  ITNRFPList,
  DeleteITNRFPPayload,
} from "../../../../../redux/RFP/types";
import {
  MomentDate,
  downloadFile,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  sortType,
} from "../../../../../types";
import {
  fetchITNRFPProps,
  useITNRFPListProps,
  useITNRFPListReturnType,
} from "../../../../../types/models/ITNRFP";
import {
  ActiveSelectOptions,
  AppRouteLinks,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";

const { add, edit } = AppRouteLinks.itnRFP;
const defaultFilters: QueryBuilderType[] = [];

export const useITNRFPList = ({ relatedList }: useITNRFPListProps): useITNRFPListReturnType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.ITNRFPReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const itnRFP = useAppSelector((state) => state.ITNRFPReducer.data.list);
  const { usersOptions, countryOptions } = useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: ITNRFPList[]) => {
      const userPersonalizedFields: string[] =
        tableConfiguration?.itnRFP?.fields || tableListDefaultSelectFields.itnRFP;
      records = records?.map((item) => {
        const itnRFPtem = { ...item };
        if (userPersonalizedFields?.includes("country") && typeof item.country !== "string") {
          itnRFPtem.country = `${item?.country?.name}`;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          itnRFPtem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          itnRFPtem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          itnRFPtem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          itnRFPtem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete itnRFPtem?._id;
        return itnRFPtem;
      });
      const itnRFPFields = tablePublicFields.itnRFP;
      const columnHeaders = itnRFPFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      downloadFile(columnHeaders, records, "itnRFP");
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId, tableConfiguration?.itnRFP?.fields],
  );

  // ================ Fetch Address History Success/Error Methods ================
  const itnRFPFetchSuccess = useCallback(
    (data: ITNRFPListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [dispatch, exportRecords],
  );

  const itnRFPFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchITNRFP = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchITNRFPProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit || tableConfiguration?.itnRFP?.limit || 10;

      const params: FetchITNRFPParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["fullName"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }
      const payload = {
        params,
        onSuccess: (data: ITNRFPListWithMetaData) => itnRFPFetchSuccess(data, exportAll),
        onError: itnRFPFetchError,
      };
      dispatch(fetchITNRFPList(payload));
    },
    [
      dispatch,
      itnRFPFetchError,
      itnRFPFetchSuccess,
      sortKey,
      sortType,
      mongoQuery,
      relatedList,
      metaData?.currentPage,
      tableConfiguration,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(
    async (type: string) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      const selectedRecords = itnRFP?.filter((item) => {
        if (item._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      exportRecords(selectedRecords);
    },
    [selectedRows, itnRFP, exportRecords, notificationsId, dispatch],
  );

  const exportAllRecords = useCallback(
    (type: string) => {
      fetchITNRFP({ exportAll: true });
    },
    [fetchITNRFP],
  );

  const refreshList = useCallback(() => {
    const page = metaData?.currentPage || 1;
    fetchITNRFP({ page });
  }, [fetchITNRFP, metaData?.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchITNRFP({ page: parseInt(page), search });
    },
    [fetchITNRFP, search],
  );

  const onClickITNRFP = useCallback(
    (id: string) => {
      if (permissions?.itnRFP_view) navigate(edit(id));
      else {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      }
    },
    [navigate, permissions?.itnRFP_view, notificationsId, dispatch],
  );

  const onClickAdd = useCallback(() => navigate(add), [navigate]);

  // ================ Search Methods ================
  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchITNRFP({ page: 1, search });
  }, [fetchITNRFP, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchITNRFP({ page: 1 });
  }, [fetchITNRFP]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchITNRFP({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchITNRFP],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchITNRFP({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchITNRFP],
  );

  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = itnRFP?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, itnRFP]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            itnRFP?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, itnRFP, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const itnRFPConfigLimit = tableConfiguration?.itnRFP?.limit || 10;
      const itnRFPConfigs = {
        table: "itnRFP",
        fields: fields,
        limit: itnRFPConfigLimit,
      };
      const payload = {
        tableConfiguration: itnRFPConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchITNRFP({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ITNRFP table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [tableConfiguration?.itnRFP?.limit, dispatch, fetchITNRFP, notificationsId],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const itnRFPConfigFields = tableConfiguration?.itnRFP?.fields;
      dispatch(showInAppLoader(true));
      const itnRFPConfigs = {
        table: "itnRFP",
        fields: itnRFPConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: itnRFPConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchITNRFP({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ITNRFP table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));

          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [dispatch, notificationsId, tableConfiguration, fetchITNRFP],
  );

  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = itnRFP?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchITNRFP({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ITNRFP updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating ITNRFP",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateITNRFP(payload));
    },
    [selectedRows, itnRFP, dispatch, notificationsId, fetchITNRFP],
  );

  // ================ Delete Method ================

  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = itnRFP?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteITNRFPPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchITNRFP({});
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteITNRFP(payload));
  }, [selectedRows, itnRFP, dispatch, notificationsId, fetchITNRFP]);

  // ================ Table Header Buttons ================

  const listActionOptions: ListActions[] = [
    {
      name: "fullName",
      label: "FullName",
      type: "text",
    },
    {
      name: "jobTitle",
      label: "Job Title",
      type: "text",
    },
    {
      name: "businessEmail",
      label: "Business Email",
      type: "text",
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
    },
    {
      name: "country",
      label: "Country",
      options: countryOptions,
    },
    {
      name: "companyName",
      label: "Comapny Name",
      type: "text",
    },
    {
      name: "industry",
      label: "Industry",
      type: "text",
    },
    {
      name: "annualRevenue",
      label: "Annual Revenue",
      type: "text",
    },
    {
      name: "description",
      label: "Description",
      type: "text",
    },
  ];

  // ================ Filter Fields Configuration ================

  const itnRFPFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const userOptions = getFilterOptions(usersOptions, "Users");
    const countryFilterOptions = getFilterOptions(countryOptions, "Country");

    const fields: Field[] = [
      {
        name: "fullName",
        label: "FullName",
        inputType: "string",
        validator,
      },
      {
        name: "jobTitle",
        label: "Job Title",
        inputType: "string",
        validator,
      },
      {
        name: "businessEmail",
        label: "Business Email",
        inputType: "string",
        validator,
      },
      {
        name: "phone",
        label: "Phone",
        inputType: "string",
        validator,
      },
      {
        name: "country",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        defaultValue: countryFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "companyName",
        label: "Comapny Name",
        inputType: "string",
        validator,
      },
      {
        name: "industry",
        label: "Industry",
        inputType: "string",
        validator,
      },
      {
        name: "annualRevenue",
        label: "Annual Revenue",
        inputType: "string",
        validator,
      },
      {
        name: "description",
        label: "Description",
        inputType: "string",
        validator,
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    return fields;
  }, [usersOptions]);

  return {
    listActionOptions,
    itnRFPFilterFields,
    onClickITNRFP,
    fetchITNRFP,
    onClickAdd,
    onSubmitDeleteRecord,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    exportAllRecords,
    exportSelectedRecords,
  };
};
