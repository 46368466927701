import Types from "./actionType";
import {
  FetchSubCategoriesPayload,
  CreateSubCategories,
  CreateSubCategoriesPayload,
  FetchSubCategories,
  FetchSubCategoriesError,
  FetchSubCategoriesSuccess,
  UpdateSubCategories,
  UpdateSubCategoriesPayload,
  SubCategoriesListWithMetaData,
  FetchSubCategoriesByIdPayload,
  FetchSubCategoriesById,
  DeleteSubCategoriesPayload,
  DeleteSubCategories,
} from "./types";

export const fetchSubCategoriesList = ({
  params,
  onSuccess,
  onError,
}: FetchSubCategoriesPayload): FetchSubCategories => ({
  type: Types.FETCH_SUB_CATEGORIES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSubCategoriesById = ({
  id,
  onSuccess,
  onError,
}: FetchSubCategoriesByIdPayload): FetchSubCategoriesById => ({
  type: Types.FETCH_SUB_CATEGORIES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSubCategoriesListSuccess = (
  data: SubCategoriesListWithMetaData,
): FetchSubCategoriesSuccess => ({
  type: Types.SUCCESS_FETCH_SUB_CATEGORIES,
  payload: {
    data,
  },
});

export const fetchSubCategoriesError = (error?: string): FetchSubCategoriesError => ({
  type: Types.ERROR_FETCH_SUB_CATEGORIES,
  payload: { error },
});

export const createSubCategories = ({
  data,
  onSuccess,
  onError,
}: CreateSubCategoriesPayload): CreateSubCategories => ({
  type: Types.CREATE_SUB_CATEGORIES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSubCategories = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSubCategoriesPayload): UpdateSubCategories => ({
  type: Types.UPDATE_SUB_CATEGORIES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSubCategories = ({
  id,
  onSuccess,
  onError,
}: DeleteSubCategoriesPayload): DeleteSubCategories => ({
  type: Types.DELETE_SUB_CATEGORIES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
