import Types from "./actionType";
import {
  FetchBenefitsPayload,
  CreateBenefits,
  CreateBenefitsPayload,
  BenefitsList,
  FetchBenefits,
  FetchBenefitsError,
  FetchBenefitsSuccess,
  UpdateBenefits,
  UpdateBenefitsPayload,
  BenefitsListWithMetaData,
  FetchBenefitsByIdPayload,
  FetchBenefitsById,
  DeleteBenefitsPayload,
  DeleteBenefits,
} from "./types";

export const fetchBenefitsList = ({
  params,
  onSuccess,
  onError,
}: FetchBenefitsPayload): FetchBenefits => ({
  type: Types.FETCH_BENEFITS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchBenefitsById = ({
  id,
  onSuccess,
  onError,
}: FetchBenefitsByIdPayload): FetchBenefitsById => ({
  type: Types.FETCH_BENEFITS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchBenefitsListSuccess = (data: BenefitsListWithMetaData): FetchBenefitsSuccess => ({
  type: Types.SUCCESS_FETCH_BENEFITS,
  payload: {
    data,
  },
});

export const fetchBenefitsError = (error?: string): FetchBenefitsError => ({
  type: Types.ERROR_FETCH_BENEFITS,
  payload: { error },
});

export const createBenefits = ({
  data,
  onSuccess,
  onError,
}: CreateBenefitsPayload): CreateBenefits => ({
  type: Types.CREATE_BENEFITS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateBenefits = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateBenefitsPayload): UpdateBenefits => ({
  type: Types.UPDATE_BENEFITS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteBenefits = ({
  id,
  onSuccess,
  onError,
}: DeleteBenefitsPayload): DeleteBenefits => ({
  type: Types.DELETE_BENEFITS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
