import React, { useState } from "react";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import UCBConfig from "../../../../package.json";
import { getFile } from "../../../redux/application/actions";
import { updateAPAccordion } from "../../../redux/application/actions";
import { useAppSelector } from "../../../redux/hooks";
import { doLogout } from "../../../redux/user/actions";
import { images, strings } from "../../../theme";
import { AppRouteLinks } from "../../../utils";
import Search from "../../components/Search";

import {
  Container,
  Separator,
  ActionsContainer,
  BasicActionItemContainer,
  NavigationLink,
  Icon,
  TextHolder,
  Accordion,
  AccordionContainer,
  AccordionChildContainer,
  AccordionLine,
  SearchContainer,
  Version,
} from "./styles";

const Admin = (props) => {
  const search = useLocation().search;
  const path = useLocation().pathname;
  const currentUserId = useAppSelector((state) => state.UserReducer.id);

  const adminActions = [
    {
      label: "Home",
      icon: images.epHome,
      activeIcon: images.epHomeActive,
      link: "/ap",
      end: true,
      visible: true,
    },

    {
      label: "Organization",
      icon: images.epSalary,
      activeIcon: images.epSalaryActive,
      link: "/ap/organisations",
      visible:
        props.organisationPermissions?.organisations && props.permissions?.organisations_list,
    },

    {
      label: "Service Tickets",
      icon: images.epTodo,
      activeIcon: images.epTodoActive,
      link: "/ap/tickets",
      visible: props.organisationPermissions?.tickets && props.permissions?.tickets,
    },

    {
      label: "Drive",
      icon: images.epTodo,
      activeIcon: images.epTodoActive,
      link: "/ap/drive",
      visible: props.organisationPermissions?.drive && props.permissions?.drive,
    },
  ];
  const adminAccordions = [
    // {
    //   label: "Notifications",
    //   icon: images.epKnowledge,
    //   activeIcon: images.epKnowledgeActive,
    //   link: "",
    //   child: [
    //     {
    //       label: "Email Schedular",
    //       link: "/ap/email-schedular",
    //       visible:
    //         props.organisationPermissions?.emailSchedular &&
    //         props?.permissions?.emailSchedular_list,
    //     },
    //   ],
    // },
    // {
    //   label: "Service Catalog",
    //   icon: images.epKnowledge,
    //   activeIcon: images.epKnowledgeActive,
    //   link: "",
    //   child: [
    //     {
    //       label: "Catalog Administration",
    //       link: "/ap/catalog-administrations",
    //       visible:
    //         props.organisationPermissions?.catalogAdministrations &&
    //         props?.permissions?.catalogAdministrations_list,
    //     },
    //     {
    //       label: "Variable Sets",
    //       link: "/ap/variable-sets",
    //       visible:
    //         props.organisationPermissions?.variableSets && props?.permissions?.variableSets_list,
    //     },
    //     {
    //       label: "Variable",
    //       link: "/ap/variables",
    //       visible: props.organisationPermissions?.variables && props?.permissions?.variables_list,
    //     },
    //     {
    //       label: "Email Schedular",
    //       link: "/ap/email-schedular",
    //       visible:
    //         props.organisationPermissions?.emailSchedular &&
    //         props?.permissions?.emailSchedular_list,
    //     },
    //   ],
    // },
    {
      label: "Website CMS",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Contact Us",
          link: "/ap/itn-contactus",
          visible: props.organisationPermissions?.itnContact && props?.permissions?.itnContact_list,
        },
        {
          label: "ServiceNow Services",
          link: "/ap/itn-services",
          visible:
            props.organisationPermissions?.itnServices && props?.permissions?.itnServices_list,
        },
        {
          label: "RFP",
          link: "/ap/itn-rfp",
          visible: props.organisationPermissions?.itnRFP && props?.permissions?.itnRFP_list,
        },
        {
          label: "Service Now Solutions",
          link: "/ap/servicenow-solutions",
          visible:
            props.organisationPermissions?.serviceNowSolutions &&
            props?.permissions?.serviceNowSolutions_list,
        },
        {
          label: "Resources",
          link: "/ap/resources",
          visible: props.organisationPermissions?.resources && props?.permissions?.resources_list,
        },
        {
          label: "Subscribers",
          link: "/ap/subscribers",
          visible:
            props.organisationPermissions?.subscribers && props?.permissions?.subscribers_list,
        },
        {
          label: "Newsletter Resources",
          link: "/ap/newsletter-resources",
          visible:
            props.organisationPermissions?.newsletterResources &&
            props?.permissions?.newsletterResources_list,
        },
        {
          label: "Newsletter Leads",
          link: "/ap/newsletter-leads",
          visible:
            props.organisationPermissions?.newsletterLeads &&
            props?.permissions?.newsletterLeads_list,
        },
        {
          label: "Campaigns",
          link: "/ap/campaigns",
          visible: props.organisationPermissions?.campaigns && props?.permissions?.campaigns_list,
        },
        {
          label: "Campaigns Assets",
          link: "/ap/campaigns-assets",
          visible:
            props.organisationPermissions?.campaignsAssets &&
            props?.permissions?.campaignsAssets_list,
        },
        {
          label: "NewsLetter Lead Lists",
          link: "/ap/newsletter-lead-lists",
          visible:
            props.organisationPermissions?.newsLetterLeadLists &&
            props?.permissions?.newsLetterLeadLists_list,
        },
      ],
    },
    {
      label: "Workspace",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Workspace",
          link: "/ap/workspaces",
          visible: props.organisationPermissions?.workspaces && props.permissions?.workspaces_list,
        },
        {
          label: "Spaces",
          link: "/ap/spaces",
          visible: props.organisationPermissions?.spaces && props.permissions?.spaces_list,
        },
      ],
    },
    {
      label: "SPS",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Accounts",
          link: "/ap/sps-accounts",
          visible:
            props.organisationPermissions?.spsAccounts && props.permissions?.spsAccounts_list,
        },
        {
          label: "Account Contacts",
          link: "/ap/sps-account-contact",
          visible:
            props.organisationPermissions?.spsAccountsContacts &&
            props.permissions?.spsAccountContact_list,
        },
        {
          label: "Sales Profile",
          link: "/ap/sps-sales-profile",
          visible:
            props.organisationPermissions?.spsAccounts && props.permissions?.spsSalesProfile_list,
        },
        // {
        //   title: "Sales Profile Credentials",
        //   path: "/ap/spssalesprofilecredentials",
        //   icon: <MdViewModule size={20} />,
        //   visible: true,
        // },
        {
          label: "Deals",
          link: "/ap/sps-deals",
          visible: props.organisationPermissions?.spsDeals && props.permissions?.spsDeals_list,
        },
        {
          label: "BGCheck",
          link: "/ap/sps-bg-check",
          visible: props.organisationPermissions?.spsBGCheck && props.permissions?.spsBGCheck_list,
        },
        {
          label: "Paper Work",
          link: "/ap/sps-paperwork",
          visible:
            props.organisationPermissions?.spsPaperwork && props.permissions?.spsPaperwork_list,
        },
        {
          label: "Projects",
          link: "/ap/sps-projects",
          visible:
            props.organisationPermissions?.spsProjects && props.permissions?.spsProjects_list,
        },
        {
          label: "Insurance",
          link: "/ap/sps-insurance",
          visible:
            props.organisationPermissions?.spsInsurance && props.permissions?.spsInsurance_list,
        },
        {
          label: "Bug Reporting",
          link: "/ap/bug-reporting",
          visible: false,
        },
      ],
    },
    {
      label: "Security Configurations",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Groups",
          link: "/ap/groups",
          visible: props.permissions?.groups_list,
        },
      ],
    },
    {
      label: "Academy Connect Hub",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Course Offerings",
          link: AppRouteLinks.academyCourses.list,
          visible:
            props.organisationPermissions?.academyCourses && props.permissions?.academyCourses_list,
        },
        {
          label: "Contact Web Portal",
          link: "/ap/academy-candidates",
          visible:
            props.organisationPermissions?.academyCandidates &&
            props.permissions?.academyCandidates_list,
        },
        {
          label: "Candidate Leads",
          link: "/ap/academy-candidate-leads",
          visible:
            props.organisationPermissions?.academyCandidateLeads &&
            props.permissions?.academyCandidateLeads_list,
        },
        {
          label: "Batch",
          link: "/ap/academy-batch",
          visible: props.permissions?.academyBatch_list,
        },
        {
          label: "Candidate Enrollments",
          link: "/ap/academy-candidate-enrollments",
          visible: props.permissions?.academyCandidateEnrollments_list,
        },
        {
          label: "Calls/Meetings Activities",
          link: "/ap/academy-ach",
          visible: props.permissions?.academyACH_list,
        },
        {
          label: "Learners Profile",
          link: "/ap/academy-learners-profile",
          visible: props.permissions?.academyStudentProfile_list,
        },
        {
          label: "Mentors Feedback",
          link: "/ap/academy-mentor-feedback",
          visible: props.permissions?.academyMentorFeedback_list,
        },
      ],
    },
    {
      label: "ACH Tasks",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "All Tasks",
          link: "/ap/task?associatedTo=ach",
          visible: props?.permissions?.achTask_list,
        },
        {
          label: "Active Tasks",
          link: "/ap/task?associatedTo=ach&active=true",
          visible: props?.permissions?.achTask_list,
        },
        {
          label: "Closed Tasks",
          link: "/ap/task?associatedTo=ach&active=false",
          visible: props?.permissions?.achTask_list,
        },
        {
          label: "Assigned To Me",
          link: `/ap/task?associatedTo=ach&assignedTo=${currentUserId}`,
          visible: props?.permissions?.achTask_list,
        },
        {
          label: "Assigned To My Group",
          link: `/ap/task?associatedTo=ach&assignedGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.achTask_list,
        },
        {
          label: "Watchlist Group/User",
          link: `/ap/task?associatedTo=ach&watchlistGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.achTask_list,
        },
      ],
    },
    {
      label: "User Administrations",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Users",
          icon: images.epTodo,
          link: "/ap/users",
          visible: props.organisationPermissions?.users && props.permissions?.users_list,
        },
        {
          label: "Departments",
          link: "/ap/departments",
          visible: props?.permissions?.departments_list,
        },
        {
          label: "Designations",
          link: "/ap/designations",
          visible: props?.permissions?.designations_list,
        },
        {
          label: "Country",
          link: "/ap/country",
          visible: props?.permissions?.country_list,
        },
      ],
    },
    {
      label: "Core Configurations",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Currency",
          link: "/ap/currency",
          visible: props?.permissions?.currency_list,
        },
        {
          label: "Platform Modules",
          link: "/ap/platform-modules",
          visible: props?.permissions?.platformModules_list,
        },
        {
          label: "Checklist Template",
          link: "/ap/checklist-template",
          visible: true,
        },
      ],
    },
    {
      label: "System Definitions",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Manage Organization",
          icon: images.epSalary,
          link: `/ap/manage/organisations/${props?.organisation?._id}/edit`,
          visible: props.OGAdmin && props?.organisation?._id,
        },
        {
          label: "Email Templates",
          icon: images.epTodo,
          link: "/ap/emailtemplates",
          visible: props.permissions?.emailtemplates_list,
        },
        {
          label: "Media Insights",
          icon: images.epTodo,
          link: "/ap/media",
          visible: props.permissions?.media_list,
        },
      ],
    },
    {
      label: "Company Definitions",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Company",
          link: "/ap/company",
          visible: props?.permissions?.company_list,
        },
        {
          label: "Company Contact",
          link: "/ap/company-contact",
          visible: props?.permissions?.companyContact_list,
        },
        {
          label: "Company Bank Details",
          link: "/ap/bank-details?type=associatedCompany",
          visible: props?.permissions?.company_list && props?.permissions?.companyBankDetails_list,
        },
      ],
    },
    {
      label: "Company Tasks",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "All Tasks",
          link: "/ap/task?associatedTo=company",
          visible: props?.permissions?.companyTask_list,
        },
        {
          label: "Active Tasks",
          link: "/ap/task?associatedTo=company&active=true",
          visible: props?.permissions?.companyTask_list,
        },
        {
          label: "Closed Tasks",
          link: "/ap/task?associatedTo=company&active=false",
          visible: props?.permissions?.companyTask_list,
        },
        {
          label: "Assigned To Me",
          link: `/ap/task?associatedTo=company&assignedTo=${currentUserId}`,
          visible: props?.permissions?.companyTask_list,
        },
        {
          label: "Assigned To My Group",
          link: `/ap/task?associatedTo=company&assignedGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.companyTask_list,
        },
        {
          label: "Watchlist Group/User",
          link: `/ap/task?associatedTo=company&watchlistGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.companyTask_list,
        },
      ],
    },
    {
      label: "HR Workspace",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Employees Old",
          icon: images.epTodo,
          activeIcon: images.epTodoActive,
          link: "/ap/employees/old",
          visible: props.organisationPermissions?.employees && props.permissions?.employees_list,
        },
        {
          label: "Employee Profile",
          icon: images.epTodo,
          link: "/ap/employees",
          visible: props.organisationPermissions?.employees && props.permissions?.employees_list,
        },

        {
          label: "PII Details",
          link: "/ap/pii-details",
          visible: props.organisationPermissions?.piiDetails && props?.permissions?.piiDetails_list,
        },
        {
          label: "Bank Details",
          link: "/ap/bank-details?type=associatedEmployee",
          visible:
            props.organisationPermissions?.employees &&
            props.permissions?.employees_list &&
            props?.permissions?.employeeBankDetails_list,
        },
        {
          label: "Benefits",
          link: "/ap/benefits",
          visible: props.organisationPermissions?.benefits && props?.permissions?.benefits_list,
        },
        {
          label: "Form 16",
          link: "/ap/form-16",
          visible: props.organisationPermissions?.form16 && props?.permissions?.form16_list,
        },
        {
          label: "Education Details",
          link: "/ap/education-details",
          visible:
            props.organisationPermissions?.educationDetails &&
            props?.permissions?.educationDetails_list,
        },
        {
          label: "Employment History",
          link: "/ap/employment-history",
          visible:
            props.organisationPermissions?.employmentHistory &&
            props?.permissions?.employmentHistory_list,
        },
        {
          label: "Address History",
          link: "/ap/address-history",
          visible:
            props.organisationPermissions?.addressHistory &&
            props?.permissions?.addressHistory_list,
        },
        // {
        //   label: "HR Tasks",
        //   link: "/ap/task",
        //   visible:
        //     props.organisationPermissions?.employees &&
        //     props.permissions?.employees_list &&
        //     props?.permissions?.task_list,
        // },

        {
          label: "Payrolls",
          icon: images.epTodo,
          link: "/ap/payrolls",
          visible: props.organisationPermissions?.payroll && props.permissions?.payrolls_view,
        },
        {
          label: "Announcements",
          icon: images.epTodo,
          activeIcon: images.epTodoActive,
          link: "/ap/announcements",
          visible:
            props.organisationPermissions?.announcements && props.permissions?.announcements_list,
        },
        {
          label: "Catalog Administration",
          link: "/ap/catalogAdministrations",
          visible:
            props.organisationPermissions?.catalogAdministrations &&
            props?.permissions?.catalogAdministrations_list,
        },
      ],
    },
    {
      label: "Recruitment Workspace",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Jobs Posting",
          icon: images.epTodo,
          link: "/ap/jobs",
          visible: props.organisationPermissions?.jobs && props.permissions?.jobs_list,
        },
        {
          label: "Recruitment",
          icon: images.epTodo,
          link: "/ap/recruitment",
          visible:
            props.organisationPermissions?.recruitsment && props.permissions?.recruitment_list,
        },
      ],
    },
    {
      label: "Service Catalog",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Service Requests",
          icon: images.epTodo,
          link: "/ap/service-requests",
          visible:
            props.organisationPermissions?.serviceRequests &&
            props.permissions?.serviceRequests_list,
        },
        {
          label: "Variable Sets",
          link: "/ap/variable-sets",
          visible:
            props.organisationPermissions?.variableSets && props?.permissions?.variableSets_list,
        },
        {
          label: "Variable",
          link: "/ap/variables",
          visible: props.organisationPermissions?.variables && props?.permissions?.variables_list,
        },
        {
          label: "Email Schedular",
          link: "/ap/email-schedular",
          visible:
            props.organisationPermissions?.emailSchedular &&
            props?.permissions?.emailSchedular_list,
        },
      ],
    },
    {
      label: "Knowledge",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Knowledgebase",
          link: "/ap/knowledgebase",
          visible: props.permissions?.knowledgebase_list,
        },
        {
          label: "Knowledge",
          link: "/ap/knowledge/",
          visible: props.permissions?.knowledge_list,
          search: "?type=admin",
        },
        {
          label: "Categories",
          link: "/ap/categories",
          visible: props.permissions?.categories_list,
        },
        {
          label: "Sub Categories",
          link: "/ap/sub-categories",
          visible: props.permissions?.subcategories_list,
        },
        {
          label: "Tags",
          link: "/ap/tags",
          visible: props.permissions?.modules_list,
          // visible: true,
        },
      ],
    },
    {
      label: "Dashboard",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Config",
          link: "/ap/dashboard-administration",
          visible:
            props.organisationPermissions?.dashboardAdministration &&
            props.permissions?.dashboardAdministration_list,
        },
        {
          label: "Tabs",
          link: "/ap/dashboard-tabs",
          visible:
            props.organisationPermissions?.dashboardWidgets && props.permissions?.dashboardTab_list,
        },
        {
          label: "Reports",
          link: "/ap/dashboard-widgets",
          visible:
            props.organisationPermissions?.dashboardTabs && props.permissions?.dashboardWidget_list,
        },
      ],
    },
    // {
    //   label: "Kanban",
    //   icon: images.epKnowledge,
    //   activeIcon: images.epKnowledgeActive,
    //   link: "",
    //   child: [
    //     {
    //       label: "Kanban-Config",
    //       link: "/ap/kanban-config",
    //       visible:
    //         props.organisationPermissions?.kanbanConfig && props.permissions?.kanbanConfig_list,
    //     },
    //   ],
    // },
    {
      label: "Notifications Definations",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Notifications Definations",
          link: "/ap/notificationsDefinations",
          visible: props?.permissions?.notificationsDefinations_list,
        },
      ],
    },
    {
      label: "HR Tasks",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "All Tasks",
          link: "/ap/task?associatedTo=hr",
          visible: props?.permissions?.task_list,
        },
        {
          label: "Active Tasks",
          link: "/ap/task?associatedTo=hr&active=true",
          visible: props?.permissions?.task_list,
        },
        {
          label: "Closed Tasks",
          link: "/ap/task?associatedTo=hr&active=false",
          visible: props?.permissions?.task_list,
        },
        {
          label: "Assigned To Me",
          link: `/ap/task?associatedTo=hr&assignedTo=${currentUserId}`,
          visible: props?.permissions?.task_list,
        },
        {
          label: "Assigned To My Group",
          link: `/ap/task?associatedTo=hr&assignedGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.task_list,
        },
        {
          label: "Watchlist Group/User",
          link: `/ap/task?associatedTo=hr&watchlistGroupOfuser=${currentUserId}`,
          visible: props?.permissions?.task_list,
        },
      ],
    },
    {
      label: "Project and Resource Workspace",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Certifications & Accreditations",
          link: "/ap/certifications-accreditations",
          visible: props?.permissions?.certificationsAccreditations_list,
        },
        {
          label: "Project Employee Allocation",
          link: "/ap/project-history",
          visible: props?.permissions?.projectHistory_list,
        },
      ],
    },
  ];
  if (props?.permissions?.attributes_list) {
    adminAccordions.push({
      label: "Attributes",
      icon: images.epKnowledge,
      activeIcon: images.epKnowledgeActive,
      link: "",
      child: [
        {
          label: "Company Industry",
          link: "/ap/attributes/companyIndustry",
          visible: true,
        },
        {
          label: "Company Size",
          link: "/ap/attributes/companySize",
          visible: true,
        },
        {
          label: "Company Classification",
          link: "/ap/attributes/companyClassification",
          visible: true,
        },
        {
          label: "Company Type",
          link: "/ap/attributes/companyType",
          visible: true,
        },
        {
          label: "Benefit Type",
          link: "/ap/attributes/benefitType",
          visible: true,
        },
        {
          label: "Bank Account Type",
          link: "/ap/attributes/bankAccountType",
          visible: true,
        },
        {
          label: "Bank Type",
          link: "/ap/attributes/bankType",
          visible: true,
        },
        {
          label: "PII Type",
          link: "/ap/attributes/piiType",
          visible: true,
        },
        {
          label: "Certifications & Accreditations Type",
          link: "/ap/attributes/certificationsAccreditationsType",
          visible: true,
        },
        {
          label: "Education Type",
          link: "/ap/attributes/educationType",
          visible: true,
        },
        {
          label: "Project Type",
          link: "/ap/attributes/projectType",
          visible: true,
        },
        {
          label: "Technology",
          link: "/ap/attributes/technology",
          visible: true,
        },
        {
          label: "Project Status",
          link: "/ap/attributes/projectStatus",
          visible: true,
        },
        {
          label: "Project Modules",
          link: "/ap/attributes/projectModules",
          visible: true,
        },
        {
          label: "Employment Type",
          link: "/ap/attributes/employmentType",
          visible: true,
        },
        {
          label: "Job Type",
          link: "/ap/attributes/jobType",
          visible: true,
        },
        {
          label: "HR Task Type",
          link: "/ap/attributes/taskType",
          visible: true,
        },
        {
          label: "ACH Task Type",
          link: "/ap/attributes/achTaskType",
          visible: true,
        },
        {
          label: "Company Task Type",
          link: "/ap/attributes/companyTaskType",
          visible: true,
        },
        {
          label: "Company Contact Status",
          link: "/ap/attributes/companyContactStatus",
          visible: true,
        },
        {
          label: "HR Task Priority",
          link: "/ap/attributes/taskPriority",
          visible: true,
        },
        {
          label: "HR Task Urgency",
          link: "/ap/attributes/taskUrgency",
          visible: true,
        },
        {
          label: "HR Task State",
          link: "/ap/attributes/taskState",
          visible: true,
        },
        {
          label: "Employment Status",
          link: "/ap/attributes/employmentStatus",
          visible: true,
        },
        {
          label: "Shirt Size",
          link: "/ap/attributes/shirtSize",
          visible: true,
        },
        {
          label: "Offboard Type",
          link: "/ap/attributes/offboardType",
          visible: true,
        },
        {
          label: "Maritial Status",
          link: "/ap/attributes/personalMartial",
          visible: true,
        },
        {
          label: "Ethnicity",
          link: "/ap/attributes/ethnicity",
          visible: true,
        },
        {
          label: "Race",
          link: "/ap/attributes/race",
          visible: true,
        },
        {
          label: "Manager Level",
          link: "/ap/attributes/managerLevel",
          visible: true,
        },
        {
          label: "Nationality",
          link: "/ap/attributes/citizenship",
          visible: true,
        },
        {
          label: "Gender",
          link: "/ap/attributes/gender",
          visible: true,
        },
        {
          label: "Salary Freqency",
          link: "/ap/attributes/salaryPayroll",
          visible: true,
        },
        {
          label: "Emergency Contact Type",
          link: "/ap/attributes/emergencyContactType",
          visible: true,
        },
        {
          label: "Academy Candidate Status",
          link: "/ap/attributes/academyCandidateStatus",
          visible: true,
        },
        {
          label: "Academy Candidate Degree",
          link: "/ap/attributes/academyCandidateDegree",
          visible: true,
        },
        {
          label: "Academy Candidate Graduation Major",
          link: "/ap/attributes/academyCandidateGraduationMajor",
          visible: true,
        },
        {
          label: "Academy Career Path",
          link: "/ap/attributes/academycareerPath",
          visible: true,
        },
        {
          label: "Academy Candidate Stages",
          link: "/ap/attributes/academyCandidateStages",
          visible: true,
        },
        {
          label: "Academy Candidate Leads Stages",
          link: "/ap/attributes/academyCandidateLeadStages",
          visible: true,
        },
        {
          label: "Academy Candidate Source",
          link: "/ap/attributes/academyCandidateSource",
          visible: true,
        },
        {
          label: "Bank Routing Type",
          link: "/ap/attributes/bankRoutingType",
          visible: true,
        },
        {
          label: "Financial Year",
          link: "/ap/attributes/financialYear",
          visible: true,
        },
        {
          label: "Corporation Type",
          link: "/ap/attributes/corporationType",
          visible: true,
        },
        {
          label: "Company Service Type",
          link: "/ap/attributes/companyServiceType",
          visible: true,
        },
        {
          label: "Company Model Type",
          link: "/ap/attributes/companyModelType",
          visible: true,
        },
        {
          label: "Academy Learner Profile State",
          link: "/ap/attributes/academyStudentState",
          visible: true,
        },
        {
          label: "Academy Learner Profile Scoring",
          link: "/ap/attributes/academyStudentScoring",
          visible: true,
        },
        {
          label: "Academy Batch State",
          link: "/ap/attributes/academyBatchState",
          visible: true,
        },
        {
          label: "Academy Batch Overall Health",
          link: "/ap/attributes/academyBatchOverallHealth",
          visible: true,
        },
        {
          label: "Academy Batch Schedule Time Health",
          link: "/ap/attributes/academyBatchTimeHealth",
          visible: true,
        },
        {
          label: "Academy Activity Type",
          link: "/ap/attributes/academyACHType",
          visible: true,
        },
        {
          label: "Academy Activity State",
          link: "/ap/attributes/academyACHState",
          visible: true,
        },
        {
          label: "Academy Activity Call Direction",
          link: "/ap/attributes/academyACHCallDirection",
          visible: true,
        },
        {
          label: "Academy Activity Purpose",
          link: "/ap/attributes/academyACHPurpose",
          visible: true,
        },
        {
          label: "Academy Activity Result",
          link: "/ap/attributes/academyACHResult",
          visible: true,
        },
        {
          label: "Academy Enrollment Stage",
          link: "/ap/attributes/academyEnrollmentStage",
          visible: true,
        },
        {
          label: "Academy Payment Status",
          link: "/ap/attributes/academyPaymentStatus",
          visible: true,
        },
        {
          label: "Academy Payment Terms",
          link: "/ap/attributes/academyPaymentTerms",
          visible: true,
        },
        {
          label: "SPS BG Check Status",
          link: "/ap/attributes/spsBGCheckStatus",
          visible: true,
        },
        {
          label: "Resources State",
          link: "/ap/attributes/resourcesState",
          visible: true,
        },
        {
          label: "Billing Cycle",
          link: "/ap/attributes/spsBillingCycle",
          visible: true,
        },
        {
          label: "SPS Platform",
          link: "/ap/attributes/spsProjectPlatform",
          visible: true,
        },
        {
          label: "SPS Account Type",
          link: "/ap/attributes/spsAccountType",
          visible: true,
        },
        {
          label: "SPS Account Status",
          link: "/ap/attributes/spsAccountStatus",
          visible: true,
        },
        {
          label: "SPS Account Contact Status",
          link: "/ap/attributes/spsAccountContact",
          visible: true,
        },
        {
          label: "SPS Deals Type",
          link: "/ap/attributes/spsDealsType",
          visible: true,
        },
        {
          label: "Variable Types",
          link: "/ap/attributes/variables",
          visible: true,
        },
        {
          label: "Deal Source",
          link: "/ap/attributes/dealSource",
          visible: true,
        },
        {
          label: "Deals State",
          link: "/ap/attributes/dealsState",
          visible: true,
        },
        {
          label: "Deals Plateform",
          link: "/ap/attributes/dealsPlateform",
          visible: true,
        },
        {
          label: "Bug Reporting Status",
          link: "/ap/attributes/bugReporting",
          visible: true,
        },
      ],
    });
  }
  const [apActions, setAPActions] = useState(adminActions);
  const [apAccordions, setAPAccordions] = useState(adminAccordions);

  const isDropDownVisible = (dropDownItems) => {
    for (let i = 0; i < dropDownItems.length; i++) {
      if (dropDownItems[i].visible) {
        return true;
      }
    }
    return false;
  };

  const handleAccordion = (e) => {
    const index = e.target.getAttribute("data-index");
    const link: string = index >= 0 ? adminAccordions[index]?.label : "";
    const apAccordion = props.apAccordion;
    console.log(index, link, apAccordion);
    if (apAccordion === link) {
      props.updateAPAccordion({
        data: "",
      });
    } else {
      props.updateAPAccordion({
        data: link,
      });
    }
  };

  const getAccordionRightBorder = (count) => {
    let height = 100 / count;
    height = height * (count - 0.5);
    return `${height}%`;
  };

  const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filteredActions = adminActions.filter((item) => {
      const label = item.label.toUpperCase();
      return label.includes(value.toUpperCase());
    });
    const filteredAccordions = adminAccordions.map((element) => {
      return {
        ...element,
        child: element.child.filter((subElement) =>
          subElement.label.toUpperCase().includes(value.toUpperCase()),
        ),
      };
    });

    if (value) {
      setAPActions(filteredActions);
      setAPAccordions(filteredAccordions);
    } else {
      setAPActions(adminActions);
      setAPAccordions(adminAccordions);
    }
  };

  return (
    <Container>
      {/* <OrgImageContainer>
        <OrgImage src={props?.OrgImage || images.defaultOrg} />
      </OrgImageContainer>
      <Separator /> */}
      <SearchContainer>
        <Search
          iconClass={"navigation-search-icon"}
          inputProps={{
            placeholder: strings.common.search,
            onChange: onSearch,
          }}
        />
      </SearchContainer>
      <ActionsContainer>
        <BasicActionItemContainer>
          {apActions.map((item, index) => {
            if (item.visible) {
              return (
                <NavigationLink
                  key={index}
                  to={item.link}
                  active={path === item.link}
                  activeclassname='active'
                  end={item.end}
                >
                  <Icon src={path === item.link ? item.activeIcon : item.icon} />
                  <TextHolder>{item.label}</TextHolder>
                </NavigationLink>
              );
            }
          })}
          <Separator top='12%' />
          {apAccordions.map((item, index) => {
            if (item.child.length > 0 && isDropDownVisible(item.child)) {
              return (
                <AccordionContainer key={index}>
                  <Accordion
                    onClick={handleAccordion}
                    data-index={index}
                    active={props.apAccordion === item.label}
                  >
                    <Icon src={props.apAccordion === item.label ? item.activeIcon : item.icon} />
                    <TextHolder onClick={handleAccordion} data-index={index}>
                      {item.label}
                    </TextHolder>
                    <Icon
                      onClick={handleAccordion}
                      data-index={index}
                      active={props.apAccordion === item.label}
                      small={true}
                      className='arrow'
                      src={images.arrowRight}
                    />
                  </Accordion>
                  {item.child.length > 0 && props.apAccordion === item.label && (
                    <AccordionChildContainer>
                      <AccordionLine height={getAccordionRightBorder(item.child.length)} />
                      {item.child.map((child, childIndex) => {
                        let link = item.link + child.link;
                        if (child.search) {
                          link += child.search;
                        }
                        if (child.visible) {
                          return (
                            <NavigationLink
                              key={childIndex}
                              to={link}
                              active={`${path}${search}` === link}
                              activeclassname='active'
                              left='0'
                            >
                              <Icon
                                src={
                                  `${path}${search}` === link
                                    ? images.accordionActive
                                    : images.accordionIcon
                                }
                              />
                              <TextHolder>{child.label}</TextHolder>
                            </NavigationLink>
                          );
                        }
                      })}
                    </AccordionChildContainer>
                  )}
                </AccordionContainer>
              );
            }
          })}
        </BasicActionItemContainer>
        {/* <Version>V {UCBConfig.version}</Version> */}
      </ActionsContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  adminAccess: state.UserReducer.admin,
  OGAdmin: state.UserReducer.organisationAdmin,
  OrgImage: state.UserReducer?.organisation?.orgImage?.location,
  permissions: state.UserReducer.permissions,
  organisationPermissions: state.UserReducer?.organisation?.permissions,
  recruitsLength: state.RecruitsReducer.recruits.length,
  apAccordion: state.ApplicationReducer.apAccordion,
  organisation: state.UserReducer.organisation,
});

const mapDispatchToProps = {
  doLogout,
  getFile,
  updateAPAccordion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
