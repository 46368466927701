/* eslint-disable no-unused-vars */
enum Types {
  FETCH_BENEFITS = "FETCH_BENEFITS",
  FETCH_BENEFITS_BY_ID = "FETCH_BENEFITS_BY_ID",
  SUCCESS_FETCH_BENEFITS = "SUCCESS_FETCH_BENEFITS",
  ERROR_FETCH_BENEFITS = "ERROR_FETCH_BENEFITS",
  CREATE_BENEFITS = "CREATE_BENEFITS",
  UPDATE_BENEFITS = "UPDATE_BENEFITS",
  DELETE_BENEFITS = "DELETE_BENEFITS",
}

export default Types;
