import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { BugReportingControllerProps } from "../../../../types/models/BugReporting";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { FormChecklist, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";

import { useBugReportingController } from "./hooks";

const Controller = ({ type }: BugReportingControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsAccountsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchBugReporting,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    checklist,
    onSubmitAddChecklist,
    onSubmitUpdateChecklist,
    deleteChecklist,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useBugReportingController({ type, id, attachmentsPickerRef });
  const currentBugReporting = data;

  const { workLogs, attachments, title, shortDescription, description, assignedTo, status } =
    formData;

  useEffect(() => {
    if (type === "edit") {
      fetchBugReporting();
    }
    // Do not include fetchBugReporting to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Title'
            type={"text"}
            width='48%'
            error={title.error}
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
          />
          <TextInput
            label='Short Description'
            type={"text"}
            width='48%'
            error={shortDescription.error}
            inputProps={{
              name: "shortDescription",
              value: shortDescription.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: shortDescription.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Description'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              rows: 6,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"assignedTo"}
            label={"Assigned To"}
            options={spsAccountsOptions}
            value={assignedTo.value}
            onChange={onTextChange}
            mandatory={assignedTo.required}
            error={assignedTo.error}
          />
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("bugReporting")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    assignedTo.error,
    assignedTo.required,
    assignedTo.value,
    description.error,
    description.required,
    description.value,
    onTextChange,
    shortDescription.error,
    shortDescription.required,
    shortDescription.value,
    spsAccountsOptions,
    status.error,
    status.required,
    status.value,
    title.error,
    title.required,
    title.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentBugReporting?.createdBy !== "string"
        ? currentBugReporting?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentBugReporting?.updatedBy !== "string"
        ? currentBugReporting?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentBugReporting?.createdAt}
        updatedAt={currentBugReporting?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentBugReporting?.createdAt,
    currentBugReporting?.createdBy,
    currentBugReporting?.updatedAt,
    currentBugReporting?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklist
        onSubmitAddChecklist={onSubmitAddChecklist}
        onSubmitUpdateChecklist={onSubmitUpdateChecklist}
        checklist={checklist}
        deleteChecklist={deleteChecklist}
      />
    ),

    [onSubmitAddChecklist, onSubmitUpdateChecklist, checklist, deleteChecklist],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderWorklogs;
      case 3:
        return renderSystemData;
      default:
        return null;
    }
  }, [currentStepIndex, renderOverview, renderChecklist, renderSystemData, renderWorklogs]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const BugReportingController = memo(Controller);
