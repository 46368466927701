import Types from "./actionType";
import {
  FetchDashboardAdministrationPayload,
  CreateDashboardAdministration,
  CreateDashboardAdministrationPayload,
  FetchDashboardAdministration,
  FetchDashboardAdministrationError,
  FetchDashboardAdministrationSuccess,
  UpdateDashboardAdministration,
  UpdateDashboardAdministrationPayload,
  DashboardAdministrationListWithMetaData,
  FetchDashboardAdministrationByIdPayload,
  FetchDashboardAdministrationById,
  DeleteDashboardAdministrationPayload,
  DeleteDashboardAdministration,
} from "./types";

export const fetchDashboardAdministrationList = ({
  params,
  onSuccess,
  onError,
}: FetchDashboardAdministrationPayload): FetchDashboardAdministration => ({
  type: Types.FETCH_DASHBOARD_ADMINISTRATION,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchDashboardAdministrationById = ({
  id,
  onSuccess,
  onError,
}: FetchDashboardAdministrationByIdPayload): FetchDashboardAdministrationById => ({
  type: Types.FETCH_DASHBOARD_ADMINISTRATION_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDashboardAdministrationListSuccess = (
  data: DashboardAdministrationListWithMetaData,
): FetchDashboardAdministrationSuccess => ({
  type: Types.SUCCESS_FETCH_DASHBOARD_ADMINISTRATION,
  payload: {
    data,
  },
});

export const fetchDashboardAdministrationError = (
  error?: string,
): FetchDashboardAdministrationError => ({
  type: Types.ERROR_FETCH_DASHBOARD_ADMINISTRATION,
  payload: { error },
});

export const createDashboardAdministration = ({
  data,
  onSuccess,
  onError,
}: CreateDashboardAdministrationPayload): CreateDashboardAdministration => ({
  type: Types.CREATE_DASHBOARD_ADMINISTRATION,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDashboardAdministration = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDashboardAdministrationPayload): UpdateDashboardAdministration => ({
  type: Types.UPDATE_DASHBOARD_ADMINISTRATION,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteDashboardAdministration = ({
  id,
  onSuccess,
  onError,
}: DeleteDashboardAdministrationPayload): DeleteDashboardAdministration => ({
  type: Types.DELETE_DASHBOARD_ADMINISTRATION,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
