import { AxiosError, AxiosResponse } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  AnnouncementsList,
  AddAnnouncements,
  UpdateAnnouncements,
  FindAnnouncementsById,
  getOrganisation,
  getToken,
  DeleteAnnouncements,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAnnouncementsListSuccess } from "./actions";
import Types from "./actionType";
import {
  AnnouncementsByIdSuccess,
  AnnouncementsSuccess,
  CreateAnnouncements,
  CreateAnnouncementsuccess,
  DeleteAnnouncements as DeleteAnnouncementsType,
  FetchAnnouncements,
  FetchAnnouncementsById,
  UpdateAnnouncements as UpdateAnnouncementsActionnType,
} from "./types";

function* deleteWorker(action: DeleteAnnouncementsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAnnouncements(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAnnouncementsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchAnnouncements) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AnnouncementsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AnnouncementsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchAnnouncementsListSuccess(data.data));
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching Announcement");
    }
  }
}

function* createWorker(action: CreateAnnouncements) {
  try {
    const options = {
      method: "POST",
      url: AddAnnouncements,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAnnouncementsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* updateWorker(action: UpdateAnnouncementsActionnType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAnnouncements(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAnnouncementsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAnnouncementById(action: FetchAnnouncementsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAnnouncementsById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AnnouncementsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* AnnouncementsSaga() {
  yield takeLatest(Types.FETCH_ANNOUNCEMENTS, fetchAllWorker);
  yield takeLatest(Types.CREATE_ANNOUNCEMENTS, createWorker);
  yield takeLatest(Types.DELETE_ANNOUNCEMENTS, deleteWorker);
  yield takeLatest(Types.UPDATE_ANNOUNCEMENTS, updateWorker);
  yield takeEvery(Types.FETCH_ANNOUNCEMENTS_BY_ID, fetchAnnouncementById);
}
