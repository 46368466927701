import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchKnowledgeDashboard } from "../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTitle, serialPrefixTickets, timeSince } from "../../../../services/methods";
import { colors, strings, images } from "../../../../theme";
import { socketInstance } from "../../../../utils/socket";
import { Row } from "../../../common/styles";
import {
  WelcomeCard,
  BirthdaysCard,
  StatsCard,
  DashboardTable,
  Accordion,
  Header,
} from "../../../components";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const knowledgeDashboard = useAppSelector((state) => state.KnowledgeReducer.dashboardData);
  const adminAccess = useAppSelector((state) => state.UserReducer.admin);
  const announcements = useAppSelector((state) =>
    state.AnnouncementsReducer.data.list.filter((item) => item.active),
  );
  const user = useAppSelector((state) => state.UserReducer);
  const knowledges = useAppSelector((state) => state.KnowledgeReducer.list);
  const ticketsList = useAppSelector((state) => state.TicketsReducer.ticketsEmployees);
  const birthdays = useAppSelector((state) => state.EmployeesReducer.birthdays);

  const activeUsers = useAppSelector((state) => state.ApplicationReducer.activeUsers);
  console.log(activeUsers);
  useTitle("Tickets");

  const [tickets, setTickets] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [mostViewedArticles, setMostViewedArticles] = useState([]);
  const [myArticles, setMyArticles] = useState([]);
  const [staffPickedArticles, setStaffPickedArticles] = useState([]);

  const getArticles = useCallback(() => {
    const mostViewedArticles = knowledgeDashboard.mostViewedArticles.map((item) => {
      return {
        id: item._id,
        title: item.name,
        createdBy: item.createdBy.name,
        createdAt: timeSince(new Date(item?.createdAt)) + " ago",
      };
    });
    const myArticles = knowledgeDashboard.myArticles.map((item) => {
      return {
        id: item._id,
        title: item.name,
        createdBy: item.createdBy.name,
        createdAt: timeSince(new Date(item?.createdAt)) + " ago",
      };
    });
    const staffPickedArticles = knowledgeDashboard.staffedPickedArticles.map((item) => {
      return {
        id: item._id,
        title: item.name,
        createdBy: item.createdBy.name,
        createdAt: timeSince(new Date(item?.createdAt)) + " ago",
      };
    });
    const approvals = knowledgeDashboard.approvals.map((item) => {
      return {
        id: item._id,
        title: item.name,
        createdBy: item.createdBy.name,
        createdAt: timeSince(new Date(item?.createdAt)) + " ago",
      };
    });
    return {
      mostViewedArticles,
      myArticles,
      staffPickedArticles,
      approvals,
    };
  }, [
    knowledgeDashboard.approvals,
    knowledgeDashboard.mostViewedArticles,
    knowledgeDashboard.myArticles,
    knowledgeDashboard.staffedPickedArticles,
  ]);

  const getTickets = useCallback(() => {
    const tickets = ticketsList;
    const data = [];
    for (let i = 0; i < tickets.length; i++) {
      const item = tickets[i];
      const title = item.subject;
      const subTitle = serialPrefixTickets(item.serial);
      const rightLabel = timeSince(new Date(item.createdAt));
      const status = item.status;
      data.push({
        id: item._id,
        title: title,
        subtitle: subTitle,
        rightLabel: rightLabel + " ago",
        status: status,
      });
    }
    return data;
  }, [ticketsList]);

  useEffect(() => {
    const dashboardTickets = getTickets();
    setTickets(dashboardTickets);
  }, [getTickets, ticketsList]);

  useEffect(() => {
    const { mostViewedArticles, myArticles, staffPickedArticles, approvals } = getArticles();
    setMostViewedArticles(mostViewedArticles);
    setMyArticles(myArticles);
    setStaffPickedArticles(staffPickedArticles);
    setApprovals(approvals);
  }, [getArticles, knowledges]);

  return (
    <>
      <Header title={strings.headerTitle.home} />
      <Row onClick={() => socketInstance.getAllClients()}>
        <div style={{ width: "66.17%" }}>
          <WelcomeCard name={user.name} />
        </div>
        <BirthdaysCard data={birthdays} title={strings.epHome.upcomingBirthdays} />
      </Row>
      <Row marginTop='31px'>
        <StatsCard
          gradientOne={colors.gradients.blueOne}
          gradientTwo={colors.gradients.blueTwo}
          count={ticketsList?.filter((ticket) => ticket.status === "awaiting_reply")?.length}
          title={strings.epHome.awaitingReply}
          image={images.blueLeftArrow}
          onClick={{
            event: navigate,
            params: "/ep/tickets/all",
          }}
        />
        <StatsCard
          gradientOne={colors.gradients.orangeOne}
          gradientTwo={colors.gradients.orangeTwo}
          count={myArticles.length}
          title={strings.epHome.myArticles}
          image={images.orangeDoubleTick}
          onClick={{
            event: navigate,
            params: "/ep/knowledge/owner",
          }}
        />
        <DashboardTable
          title={strings.epHome.pendingApprovals}
          navigateTo={"knowledge"}
          data={approvals}
          rows={3}
          rightIcon={images.epView}
          knowledgeRow={true}
          onClick={{
            event: navigate,
            params: "/ep/knowledge/approvals?type=approvals",
          }}
          noDataLabel={!approvals.length && strings.common.noPendingApprovals}
        />
      </Row>
      <Row marginTop='31px' onClick={() => console.log({ announcements })}>
        <Accordion title={strings.epHome.announcements} data={announcements} width={"66.17%"} />
        <DashboardTable
          title={strings.epHome.myServiceTickets}
          navigateTo={"tickets"}
          data={tickets}
          rows={5}
          filters={[
            { key: "awaiting_reply", value: "Awaiting Reply" },
            { key: "replied", value: "Replied" },
            { key: "closed", value: "Closed" },
          ]}
          filterKey='status'
          noDataLabel={tickets.length === 0 && strings.common.noTickets}
        />
      </Row>
      <Row marginTop='31px'>
        <DashboardTable
          title={strings.epHome.mostViewedArticles}
          data={mostViewedArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!mostViewedArticles.length && strings.common.noArticles}
        />
        <DashboardTable
          title={strings.epHome.myArticles}
          data={myArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!myArticles.length && strings.common.noArticles}
        />
        <DashboardTable
          title={strings.epHome.staffPickedArticles}
          data={staffPickedArticles}
          rows={5}
          rightIcon={images.epView}
          knowledgeRow={true}
          navigateTo={"knowledge"}
          noDataLabel={!staffPickedArticles.length && strings.common.noArticles}
        />
      </Row>
    </>
  );
};

export default Home;
