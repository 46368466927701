import React, { useCallback, useState } from "react";

import { useGoogleLogin } from "@react-oauth/google";
import { sha256 } from "js-sha256";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/InputV2";
import Toast from "../../components/Toast/toast";
import config from "../../config";
import { doLogin, doSSO, doReset, doResetRequest } from "../../redux/user/actions";
import { useTitle } from "../../services/methods";

import {
  MainContainer,
  BackgroundImage,
  Container,
  ImageContainer,
  Image,
  LoginContainer,
  LogoContainer,
  Title,
  SubTitle,
  FormContainer,
  BottomText,
} from "./styles";

const ImageLogin = require("../../assets/images/login.png");

const Login = (props) => {
  const [SSOLoading, setSSOLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const token = searchParams.get("token") || "";
  const [type, setType] = useState(email && token ? "reset" : "");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    password1: "",
    password2: "",
    message: "",
    message_type: "",
    loading: false,
  });

  useTitle("Log into portal");

  const onChangeText = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setFormData({ ...formData, message_type: "", message: "", loading: true });
    props.doLogin({
      email: formData.email,
      password: sha256(formData.password),
      onSuccess: () => {},
      onError: (error: any) => {
        setFormData({
          ...formData,
          message_type: "error",
          message: error,
          loading: false,
        });
      },
    });
  };

  const onSubmitResetPassword = (event) => {
    event.preventDefault();
    setFormData({ ...formData, message_type: "", message: "", loading: true });
    props.doReset({
      email: email,
      password: sha256(formData.password1),
      token: token,
      onSuccess: () => {
        setFormData({
          ...formData,
          message_type: "success",
          message: "Succesfully updated, redirecting to login",
          loading: false,
        });
        var timeout = setTimeout(() => {
          setType("");
          setFormData({
            ...formData,
            message_type: "",
            message: "",
            loading: false,
          });
          clearTimeout(timeout);
        }, 3000);
      },
      onError: (error: any) => {
        setFormData({
          ...formData,
          message_type: "error",
          message: error,
          loading: false,
        });
      },
    });
  };

  const onSubmitResetRequest = (event) => {
    event.preventDefault();
    setFormData({ ...formData, message_type: "", message: "", loading: true });
    props.doResetRequest({
      email: formData.email,
      onSuccess: () => {
        setFormData({
          ...formData,
          message_type: "success",
          message: "Mail Sent, Please use the link to reset password",
          loading: false,
        });
        var timeout = setTimeout(() => {
          setType("");
          setFormData({
            ...formData,
            message_type: "",
            message: "",
            loading: false,
          });
          clearTimeout(timeout);
        }, 3000);
      },
      onError: (error: any) => {
        setFormData({
          ...formData,
          message_type: "error",
          message: error,
          loading: false,
        });
      },
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      props.doSSO({
        access_token: tokenResponse.access_token,
        onError: (err) => {
          setFormData({
            ...formData,
            message_type: "error",
            message: err,
            loading: false,
          });
          setSSOLoading(false);
        },
      });
    },
    onError: (err) => {
      console.log("err", err);
      setSSOLoading(false);
    },
  });

  const handleSSOClick = useCallback(() => {
    setSSOLoading(true);
    googleLogin();
  }, [googleLogin]);

  return (
    <MainContainer>
      <BackgroundImage src={require("../../assets/images/login-bg.png")} />
      <Container>
        <ImageContainer>
          <Image src={ImageLogin} />
        </ImageContainer>
        <LoginContainer>
          {/* <TitleContianer> */}
          <LogoContainer>
            <img
              height={"25px"}
              width={"25px"}
              style={{
                objectFit: "contain",
              }}
              src='/android-chrome-512x512.png'
            />
            <Title>Powered by Unity.</Title>
          </LogoContainer>
          <SubTitle>
            {type === "reset" ? "Please input new password" : "Please Login to get started."}
          </SubTitle>
          {/* </TitleContianer> */}
          <FormContainer>
            <Toast type={formData.message_type} message={formData.message} />

            {type === "reset" && (
              <form onSubmit={onSubmitResetPassword} autoComplete='new-password'>
                <Input
                  label={"Password"}
                  type={"password"}
                  name={"password1"}
                  onChange={onChangeText}
                  value={formData.password1}
                  autoComplete='new-password'
                  color='#5C61EF'
                />
                <Input
                  label='Confirm Password'
                  type={"password"}
                  name='password2'
                  onChange={onChangeText}
                  value={formData.password2}
                  autoComplete='new-password'
                  color='#5C61EF'
                />
                <Button
                  onClick={onSubmitResetPassword}
                  label={formData.loading ? "loading" : "Reset Password"}
                  disabled={
                    !formData.password1 ||
                    !formData.password2 ||
                    formData.password1 !== formData.password2
                  }
                />
              </form>
            )}

            {type === "request" && (
              <form onSubmit={onSubmitResetRequest} autoComplete='new-password'>
                <Input
                  label={"Email"}
                  type={"text"}
                  name={"email"}
                  onChange={onChangeText}
                  value={formData.email}
                  autoComplete='new-password'
                />

                <Button
                  onClick={onSubmitResetRequest}
                  label={formData.loading ? "loading" : "Reset Password"}
                  disabled={!formData.email}
                />
                <BottomText onClick={() => setType("")}>Back To Login</BottomText>
              </form>
            )}
            {type === "" && (
              <form onSubmit={onSubmit} autoComplete='new-password'>
                <Input
                  label={"Email"}
                  type={"text"}
                  name={"email"}
                  onChange={onChangeText}
                  value={formData.email}
                  autoComplete='new-password'
                />
                <Input
                  label='Password'
                  type={"password"}
                  name='password'
                  onChange={onChangeText}
                  value={formData.password}
                  autoComplete='new-password'
                />
                <BottomText onClick={() => setType("request")}>Reset Password</BottomText>
                <div
                  style={{
                    marginTop: "4vh",
                  }}
                >
                  <Button
                    onClick={onSubmit}
                    label={formData.loading ? "loading" : "Login"}
                    disabled={!formData.email || !formData.password}
                  />
                </div>
              </form>
            )}
            {config.environment === "dev" && (
              <div style={{ cursor: "pointer" }} onClick={handleSSOClick}>
                {SSOLoading ? "Processing SSO Request" : "Google SSO 🚀 "}
              </div>
            )}
          </FormContainer>
        </LoginContainer>
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
});

const mapDispatchToProps = {
  doLogin,
  doSSO,
  doReset,
  doResetRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
