import React from "react";

import { Layout } from "../../../presentationals";
import { EmailSchedularList } from "../../../presentationals/screens/admin/EmailSchedular/EmailSchedularList";

const Route = () => {
  return <Layout activity={<EmailSchedularList />} admin={true} layout={"2"} />;
};

export const APEmailSchedularList = React.memo(Route);
