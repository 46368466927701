import Types from "./actionType";
import { ProjectHistoryActions, ProjectHistoryState } from "./types";

const initialState: ProjectHistoryState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const ProjectHistoryReducer = (state = initialState, action: ProjectHistoryActions) => {
  switch (action.type) {
    case Types.FETCH_PROJECT_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_PROJECT_HISTORY:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
