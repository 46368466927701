import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createCompany,
  fetchCompanyById,
  fetchControllerConfigs,
  removeAppNotification,
  updateCompany,
  uploadFile,
} from "../../../../../redux";
import {
  CompanyList,
  CompanyServiceDetails,
  CreateCompanyPayload,
  FetchCompanyByIdPayload,
  UpdateCompanyPayload,
} from "../../../../../redux/Company/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  SelectOptions,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  useCompanysControllerProps,
  useCompanysControllerReturnType,
  CompanyForm,
} from "../../../../../types/models/Company";
import { emailRegex, validatePhone } from "../../../../../utils";
// ================ Contstants ================

const addFormSteps = ["Overview", "Address/Location", "Additional Details", "Service Details"];
const editFormSteps = [
  "Overview",
  "Address/Location",
  "Additional Details",
  "Service Details",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "company",
  "about",
  "companyType",
  "companyClassification",
  "industry",
  "website",
  "size",
  "externalLinks",
];
const addressFields = [
  "country",
  "province",
  "addressLineOne",
  "addressLineTwo",
  "city",
  "zipCode",
  "phone",
];

const serviceDetailsFields = [
  "serviceModel",
  "serviceType",
  "startDate",
  "endDate",
  "renewDate",
  "details",
];

export const useCompanyController = ({
  type,
  id,
  attachmentsPickerRef,
}: useCompanysControllerProps): useCompanysControllerReturnType => {
  const defaultFormData: CompanyForm = {
    attachments: {
      value: [],
      error: "",
      required: false,
    },
    workLogs: {
      value: [],
      error: "",
      required: false,
    },
    company: {
      value: "",
      required: true,
      error: "",
    },
    about: {
      value: "",
      required: true,
      error: "",
    },
    companyType: {
      value: [],
      required: true,
      error: "",
    },
    companyClassification: {
      value: "",
      required: true,
      error: "",
    },
    companyOwner: {
      value: "",
      required: false,
      error: "",
    },
    existingCompany: {
      value: "",
      required: false,
      error: "",
    },
    industry: {
      value: "",
      required: true,
      error: "",
    },
    website: {
      value: "",
      required: true,
      error: "",
    },
    size: {
      value: "",
      required: true,
      error: "",
    },
    externalLinks: {
      value: "",
      required: false,
      error: "",
    },
    accountOwner: {
      value: "",
      required: false,
      error: "",
    },
    country: {
      value: "",
      required: true,
      error: "",
    },
    province: {
      value: "",
      required: true,
      error: "",
    },
    addressLineOne: {
      value: "",
      required: true,
      error: "",
    },
    addressLineTwo: {
      value: "",
      required: false,
      error: "",
    },
    city: {
      value: "",
      required: true,
      error: "",
    },
    zipCode: {
      value: "",
      required: true,
      error: "",
    },
    phone: {
      value: "",
      required: true,
      error: "",
    },
    W9: {
      value: false,
      required: false,
      error: "",
    },
    AOI: {
      value: false,
      required: false,
      error: "",
    },
    ACH: {
      value: false,
      required: false,
      error: "",
    },
    voidedCheck: {
      value: false,
      required: false,
      error: "",
    },
    GST: {
      value: false,
      required: false,
      error: "",
    },
    PAN: {
      value: false,
      required: false,
      error: "",
    },
    IEC: {
      value: false,
      required: false,
      error: "",
    },
    AOA: {
      value: false,
      required: false,
      error: "",
    },
    MOA: {
      value: false,
      required: false,
      error: "",
    },
    COITaken: {
      value: false,
      required: false,
      error: "",
    },
    corporationType: {
      value: "",
      required: false,
      error: "",
    },
    serviceDetails: {
      serviceType: {
        value: "",
        required: false,
        error: "",
      },
      serviceModel: {
        value: "",
        required: false,
        error: "",
      },
      details: {
        value: "",
        required: false,
        error: "",
      },
      startDate: {
        value: null,
        required: false,
        error: "",
      },
      endDate: {
        value: null,
        required: false,
        error: "",
      },
      renewDate: {
        value: null,
        required: false,
        error: "",
      },
    },
  };

  // ================ Reducer Values ================
  const list = useAppSelector((state) => state.CompanyReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CompanyForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<CompanyList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return ["Company Contact", "Bank Details", "Tasks"];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
        case 1:
          addressFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      let tempFormData = { ...formData };
      if (serviceDetailsFields.includes(name)) {
        let serviceDetails = { ...tempFormData.serviceDetails };
        serviceDetails = {
          ...serviceDetails,
          [name]: {
            ...serviceDetails[name],
            value,
            error: "",
          },
        };
        tempFormData.serviceDetails = serviceDetails;
      } else {
        tempFormData = {
          ...tempFormData,
          [name]: {
            ...tempFormData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      if (serviceDetailsFields.includes(name)) {
        let serviceDetails = { ...tempFormData.serviceDetails };
        serviceDetails = {
          ...serviceDetails,
          [name]: {
            ...serviceDetails[name],
            value,
            error: "",
          },
        };
        tempFormData.serviceDetails = serviceDetails;
      } else {
        tempFormData = {
          ...tempFormData,
          [name]: {
            ...tempFormData[name],
            value,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (company) => {
      const item = company;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["companyType"].includes(field)) {
          const tempValue = value.map((item) => {
            return { label: item?.value, value: item?._id };
          });
          value = tempValue;
          data[field].value = value;
        } else if (
          [
            "corporationType",
            "country",
            "size",
            "industry",
            "accountOwner",
            "companyClassification",
            "companyOwner",
            "existingCompany",
          ].includes(field)
        ) {
          value = item?.[field]?._id;
          data[field].value = value;
        } else if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
          data[field].value = value;
        } else if (field === "serviceDetails") {
          const serviceDetails = { ...data.serviceDetails };
          Object.keys(serviceDetails).map((field) => {
            const value = item?.serviceDetails?.[field];
            serviceDetails[field].value = value;
          });
          data.serviceDetails = serviceDetails;
        } else {
          data[field].value = value;
        }
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchCompanys = useCallback(() => {
    setLoading(true);
    const payload: FetchCompanyByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchCompanyById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else if (["phone"].includes(item) && !validatePhone(String(validValue))) {
        // console.log(item, phoneRegex.test(validValue));
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid phone with country code",
          },
        };
      } else if (item === "email" && !String(validValue).match(emailRegex)) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid email",
          },
        };
      } else if (
        ["externalLinks", "website"].includes(item) &&
        validValue &&
        !validator.isURL(validValue || "") &&
        (!String(validValue).includes("externalLinks") || !String(validValue).includes("website"))
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter a valid URL",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      attachments,
      company,
      corporationType,
      W9,
      AOI,
      ACH,
      voidedCheck,
      GST,
      PAN,
      IEC,
      AOA,
      MOA,
      COITaken,
      about,
      companyType,
      companyClassification,
      companyOwner,
      existingCompany,
      industry,
      website,
      size,
      externalLinks,
      accountOwner,
      country,
      province,
      addressLineOne,
      addressLineTwo,
      city,
      zipCode,
      phone,
      serviceDetails,
    } = formData;
    const { serviceModel, serviceType, startDate, endDate, renewDate, details } = serviceDetails;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const tempCompanyType = companyType.value.map((item) => String(item.value));
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
      }));
      const tempServiceDetails: CompanyServiceDetails = {
        serviceModel: serviceModel.value || null,
        serviceType: serviceType.value || null,
        details: details.value,
        startDate: startDate.value,
        endDate: endDate.value,
        renewDate: renewDate.value,
      };
      const payload: CreateCompanyPayload = {
        data: {
          attachments: attachments,
          company: company.value,
          serviceDetails: tempServiceDetails,
          corporationType: corporationType.value,
          W9: W9.value,
          AOI: AOI.value,
          ACH: ACH.value,
          voidedCheck: voidedCheck.value,
          GST: GST.value,
          PAN: PAN.value,
          IEC: IEC.value,
          AOA: AOA.value,
          MOA: MOA.value,
          COITaken: COITaken.value,
          about: about.value,
          companyType: tempCompanyType,
          companyClassification: companyClassification.value,
          companyOwner: companyOwner.value,
          existingCompany: existingCompany.value,
          industry: industry.value,
          website: website.value,
          size: size.value,
          externalLinks: externalLinks.value,
          accountOwner: accountOwner.value,
          country: country.value,
          province: province.value,
          addressLineOne: addressLineOne.value,
          addressLineTwo: addressLineTwo.value,
          city: city.value,
          zipCode: zipCode.value,
          phone: phone.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Company created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createCompany(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      attachments,
      company,
      serviceDetails,
      corporationType,
      W9,
      AOI,
      ACH,
      voidedCheck,
      GST,
      PAN,
      IEC,
      AOA,
      MOA,
      COITaken,
      about,
      companyType,
      companyClassification,
      companyOwner,
      existingCompany,
      industry,
      website,
      size,
      externalLinks,
      accountOwner,
      country,
      province,
      addressLineOne,
      addressLineTwo,
      city,
      zipCode,
      phone,
    } = formData;
    const { serviceModel, serviceType, startDate, endDate, renewDate, details } = serviceDetails;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
      }));
      setLoading(true);
      const tempCompanyType = companyType.value.map((item) => String(item.value));
      const tempServiceDetails: CompanyServiceDetails = {
        serviceModel: serviceModel.value || null,
        serviceType: serviceType.value || null,
        details: details.value,
        startDate: startDate.value,
        endDate: endDate.value,
        renewDate: renewDate.value,
      };
      const payload: UpdateCompanyPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          company: company.value,
          serviceDetails: tempServiceDetails,
          corporationType: corporationType.value,
          W9: W9.value,
          AOI: AOI.value,
          ACH: ACH.value,
          voidedCheck: voidedCheck.value,
          GST: GST.value,
          PAN: PAN.value,
          IEC: IEC.value,
          AOA: AOA.value,
          MOA: MOA.value,
          COITaken: COITaken.value,
          about: about.value,
          companyType: tempCompanyType,
          companyClassification: companyClassification.value,
          companyOwner: companyOwner.value,
          existingCompany: existingCompany.value,
          industry: industry.value,
          website: website.value,
          size: size.value,
          externalLinks: externalLinks.value,
          accountOwner: accountOwner.value,
          country: country.value,
          province: province.value,
          addressLineOne: addressLineOne.value,
          addressLineTwo: addressLineTwo.value,
          city: city.value,
          zipCode: zipCode.value,
          phone: phone.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Company updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateCompany(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateCompanyPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateCompany(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.company || "";
    }
    return [strings.headerTitle.company, title];
  }, [data, type]);

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  // ======================================================================

  const country = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.country,
  );
  const companies = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.company,
  );
  const companyContacts = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.companyContact,
  );
  const employees = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.employees,
  );

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const getAttachmentFileIcon = useCallback((extension?: string) => {
    switch (extension) {
      case "pdf":
        return images.pdfFileIcon;
      default:
        return images.defaultFileIcon;
    }
  }, []);

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchCompanys,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    handleCheckbox,
  };
};
