import React, { useCallback, MouseEvent } from "react";

import { strings } from "../../../theme";
import { DOTS } from "../../../utils";

import { usePagination } from "./hooks/usePagination";
import {
  Container,
  InfoContainer,
  PagesContainer,
  PaginationItem,
  ShowRecordsContainer,
} from "./styles";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  containerClass = "",
  handleShowItemsPerPage,
  advancedList,
}) => {
  const { paginationRange, lastPage, onPrevious, onNext, itemRange } = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    onPageChange,
  });

  const changePage = useCallback(
    (e: MouseEvent) => {
      const page = e.currentTarget.getAttribute("data-index");
      onPageChange(page);
    },
    [onPageChange],
  );

  return (
    <Container className={containerClass}>
      <InfoContainer>
        {`${itemRange} ${strings.common.of} ${totalCount}`}
        {!!handleShowItemsPerPage && advancedList && (
          <ShowRecordsContainer>
            Show
            <select value={pageSize} onChange={handleShowItemsPerPage}>
              {/* <option>1</option>
              <option>2</option>
              <option>3</option> */}
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>75</option>
              <option>100</option>
            </select>{" "}
            Items
          </ShowRecordsContainer>
        )}
      </InfoContainer>
      <PagesContainer>
        <PaginationItem onClick={onPrevious} disabled={currentPage === 1}>
          <div className='arrow left' />
        </PaginationItem>
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className='dots'>
                &#8230;
              </li>
            );
          }

          return (
            <PaginationItem
              key={index}
              active={currentPage === pageNumber}
              onClick={changePage}
              data-index={pageNumber}
            >
              {pageNumber}
            </PaginationItem>
          );
        })}

        <PaginationItem onClick={onNext} disabled={currentPage === lastPage}>
          <div className='arrow right' />
        </PaginationItem>
      </PagesContainer>
    </Container>
  );
};

export default Pagination;
