import styled from "styled-components";

import { colors, fs } from "../../../../../theme";

interface Props {
  loading?: boolean;
}
export const Container = styled.div<Props>`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;

export const BodyContainer = styled.div`
  /* display: flex; */
  height: 630px;
  width: 100%;
  /* box-sizing: border-box; */
  background: ${colors.common.white};
  border: 0.6px solid ${colors.common.borderColor};
  /* box-shadow: 0px 2px 2px ${colors.common.shadow}; */
  border-radius: 5px;
  margin-top: 20px;
  /* justify-content: space-between; */
  /* overflow: hidden; */
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const RenderContainer = styled.div`
  width: 100%;
`;

export const FormTitle = styled.div`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
`;

export const ViewContainer = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  font-weight: "600";
  font-size: 16px;
  line-height: 22px;
`;

export const DisplayImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 216px;
  width: 216px;
  box-sizing: border-box;
  border: 1px solid ${colors.common.displayPictureBorderColor};
  border-radius: 50%;
  position: relative;
  margin: auto;
  margin-bottom: 5%;
`;

export const DisplayPicture = styled.img`
  height: 150px;
  width: 150px;
  object-fit: fill;
  align-self: center;
  margin: auto;
`;

export const DisplayPictureEdit = styled.img`
  height: 36px;
  width: 36px;
  margin-top: 25px;
  position: absolute;
  bottom: -20px;
`;

export const HorizontalLine = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 0.6px solid ${colors.common.greyBorder};
  margin-bottom: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const Table = styled.table`
  width: 100%;
  border: 0.4px solid ${colors.common.black};
`;

export const TableRow = styled.tr`
  border-bottom: 0.5px solid black;
`;

export const TableHeading = styled.th`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.common.black};
  padding-top: 18px;
  padding-bottom: 18px;
  background: ${colors.table.headerBackground};
  width: 14%;
`;

export const TableContent = styled.td`
  text-align: center;
  height: 56px;
  vertical-align: middle;

  font-weight: 400;
  font-size: 16px;
  color: ${colors.common.modalTitleColor};
`;

export const FirstTableContent = styled.td`
  /* text-align: center; */
  padding-left: 2%;
  height: 56px;
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.common.modalTitleColor};
`;

export const PopupInsideContainer = styled.div`
  padding: 15px;
  width: 645px;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${colors.common.modalTitleColor};
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 288px;
  padding: 12px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalBTNContainer = styled.div`
  width: 50%;
  margin-top: 12px;
`;

export const OverViewRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ActionItem = styled.img`
  cursor: pointer;
  margin-right: 6px;
`;

export const DropDownContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

export const RelatedListContainer = styled.div`
  display: flex;
  width: 100%;
`;
