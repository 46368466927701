import React from "react";

import { Layout } from "../../../presentationals";
import { EmployeesController } from "../../../presentationals/screens/admin";
import { strings } from "../../../theme";

const Route = () => {
  return <Layout activity={<EmployeesController type={"edit"} />} admin={true} layout={"2"} />;
};

export default Route;
