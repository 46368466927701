import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";
import { generateArrayAttributes } from "../../../services/methods";
import styles from "../EmployeesController.styles";

export const Tab0 = ({
  formData,
  readOnly,
  onTextChange,
  formValidation,
  onFileChange,
  getDocument,
  actualEmploymentStatus,
}) => {
  return (
    <>
      <Input
        readOnly={readOnly}
        label={"Resume"}
        type={"file"}
        name='attachmentResume'
        message={formData.attachmentResume}
        onMessageClick={() => getDocument(formData.attachmentResume)}
        autoComplete={"off"}
        onChange={onFileChange}
        mandatory={true}
        error={formValidation?.attachmentResume?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Cover Letter"}
        type={"file"}
        name='attachmentCoverLetter'
        message={formData.attachmentCoverLetter}
        onMessageClick={() => getDocument(formData.attachmentCoverLetter)}
        autoComplete={"off"}
        onChange={onFileChange}
        error={formValidation?.attachmentCoverLetter?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Internship Certificate"}
        type={"file"}
        name='attachmentInternshipCertificate'
        message={formData.attachmentInternshipCertificate}
        onMessageClick={() => getDocument(formData.attachmentInternshipCertificate)}
        autoComplete={"off"}
        onChange={onFileChange}
        error={formValidation?.attachmentInternshipCertificate?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Consultant Profile"}
        type={"file"}
        name='attachmentConsultantProfile'
        message={formData.attachmentConsultantProfile}
        onMessageClick={() => getDocument(formData.attachmentConsultantProfile)}
        autoComplete={"off"}
        onChange={onFileChange}
        error={formValidation?.attachmentConsultantProfile?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Full Name"}
        type={"text"}
        name='name'
        value={formData.name}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={formValidation?.name?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Nick Name"}
        type={"text"}
        name='nickName'
        value={formData.nickName}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <styles.Row>
        <styles.Column>
          <Select
            readOnly={readOnly}
            name={"employeeLevel"}
            label={"Level"}
            options={generateArrayAttributes("employeeLevel").map((value, index) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.employeeLevel}
            onChange={onTextChange}
          />
        </styles.Column>
        <styles.Column>
          <Select
            readOnly={readOnly}
            name={"employeeType"}
            label={"Employee Type"}
            options={generateArrayAttributes("employeeType").map((value) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.employeeType}
            onChange={onTextChange}
          />
        </styles.Column>
      </styles.Row>
      <Input
        readOnly={readOnly}
        label={"Personal Email"}
        type={"email"}
        name='email'
        value={formData.email}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={formValidation?.email?.error}
      />
      <Input
        readOnly={readOnly}
        label={"Official Email"}
        type={"email"}
        name='official_email'
        value={formData.official_email}
        autoComplete={"off"}
        onChange={onTextChange}
        mandatory={true}
        error={formValidation?.official_email?.error}
      />
      <styles.Row>
        <styles.Column>
          <Input
            readOnly={readOnly}
            label={"Mobile"}
            type={"text"}
            name='mobile'
            value={formData.mobile}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formValidation?.mobile?.error}
          />
        </styles.Column>
        <styles.Column>
          <Input
            readOnly={readOnly}
            label={"Mobile 2"}
            type={"text"}
            name='mobile1'
            value={formData.mobile1}
            autoComplete={"off"}
            onChange={onTextChange}
          />
        </styles.Column>
      </styles.Row>
      <styles.Row>
        <styles.Column>
          <Select
            readOnly={readOnly}
            name={"employmentStatus"}
            label={"Status"}
            options={generateArrayAttributes("employmentStatus").map((value) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.employmentStatus}
            onChange={onTextChange}
          />
        </styles.Column>

        <styles.Column>
          <Select
            readOnly={readOnly}
            name={"employmentType"}
            label={"Employment Type"}
            options={generateArrayAttributes("employmentType").map((value) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.employmentType}
            onChange={onTextChange}
          />
        </styles.Column>
      </styles.Row>
      {formData.employmentStatus !== actualEmploymentStatus &&
        formData.employmentStatus === "621cefc034bfaf3118c0b624" && (
          <styles.Message>
            {
              "Employee terminated should be marked after the last payroll for employee is generated."
            }
          </styles.Message>
        )}
      {formData.employmentStatus === "621cefc034bfaf3118c0b624" && (
        <Input
          readOnly={readOnly}
          label={"Termination Reason"}
          type={"textarea"}
          name='terminationComment'
          value={formData.terminationComment}
          autoComplete={"off"}
          onChange={onTextChange}
        />
      )}
      <Select
        readOnly={readOnly}
        name={"employmentDepartment"}
        label={"Department"}
        options={generateArrayAttributes("employmentDepartment").map((value) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.employmentDepartment}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Company"}
        type={"text"}
        name='employmentCompany'
        value={formData.employmentCompany}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Referral"}
        type={"text"}
        name='referral'
        value={formData.referral}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Referral Phone"}
        type={"text"}
        name='referralMobile'
        value={formData.referralMobile}
        autoComplete={"off"}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Referral Email"}
        type={"text"}
        name='referralEmail'
        value={formData.referralEmail}
        autoComplete={"off"}
        onChange={onTextChange}
      />
    </>
  );
};
