import styled from "styled-components";

const styles = {
  MainContainer: styled.div`
    height: 100%;
    .desc {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      max-width: 40px;
    }
    .edit {
      cursor: pointer;
    }
  `,
  Header: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 400;
    }
    align-items: center;
    margin-bottom: 1vh;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Column: styled.div`
    flex: 1;
  `,
  CodeContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    b {
      width: 10vw;
      font-size: 1rem;
    }
  `,
  Status: styled.div`
    padding: 1vh 1vw;
    height: fit-content;
    background-color: ${(props) => props.color};
    border-radius: 4px;
    color: white;
  `,
};

export default styles;
