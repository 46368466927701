import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  PIIDetailsList,
  AddPIIDetails,
  UpdatePIIDetails,
  FindPIIDetails,
  DeletePIIDetails,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchPIIDetailsListSuccess, fetchPIIDetailsError } from "./actions";
import Types from "./actionType";
import {
  CreatePIIDetailsuccess,
  CreatePIIDetails as CreatePIIDetailsType,
  PIIDetailsSuccess,
  UpdatePIIDetails as UpdatePIIDetailsType,
  FetchPIIDetails,
  FetchPIIDetailsById,
  PIIDetailsByIdSuccess,
  DeletePIIDetails as DeletePIIDetailsType,
} from "./types";

function* createWorker(action: CreatePIIDetailsType) {
  try {
    const options = {
      method: "POST",
      url: AddPIIDetails,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreatePIIDetailsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* deleteWorker(action: DeletePIIDetailsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeletePIIDetails(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreatePIIDetailsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdatePIIDetailsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdatePIIDetails(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreatePIIDetailsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchPIIDetails) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: PIIDetailsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<PIIDetailsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchPIIDetailsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchPIIDetailsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching PII Details");
    }
  }
}

function* fetchPIIDetailsById(action: FetchPIIDetailsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindPIIDetails(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<PIIDetailsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching PII Details");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_PIIDETAILS, createWorker);
  yield takeLatest(Types.UPDATE_PIIDETAILS, updateWorker);
  yield takeLatest(Types.DELETE_PIIDETAILS, deleteWorker);
  yield takeEvery(Types.FETCH_PIIDETAILS, fetchAllWorker);
  yield takeEvery(Types.FETCH_PIIDETAILS_BY_ID, fetchPIIDetailsById);
}
