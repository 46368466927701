import React, { useState, InputHTMLAttributes, TextareaHTMLAttributes, useCallback } from "react";

import { TimePickerProps } from "antd";
import { DatePickerProps } from "react-date-picker";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import { validDate, validTime } from "../../../../services/methods";
import { images } from "../../../../theme";

import {
  ParentWrapper,
  Wrapper,
  Label,
  Input,
  ErrorText,
  Switch,
  TextArea,
  Date,
  TooltipIcon,
  TooltipContainer,
  Time,
} from "./styles";

interface Props {
  label: string;
  type?: "date" | "checkbox" | "textarea" | "text" | "password" | "email" | "file" | "time";
  error?: string;
  width?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  textAreaProps?: TextareaHTMLAttributes<HTMLTextAreaElement>;
  datePickerProps?: DatePickerProps;
  TimePickerProps?: TimePickerProps;
  disabled?: boolean;
  hidden?: boolean;
  onDateChange?: (value: Date, name: string) => void;
  onTimeChange?: (value: Date, name: string) => void;

  containerClass?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  tooltip?: string;
}

export const TextInput = ({
  label,
  type = "text",
  error = "",
  width = "48%",
  inputProps,
  disabled = false,
  textAreaProps,
  hidden,
  ref,
  containerClass,
  datePickerProps,
  TimePickerProps,
  onDateChange,
  onTimeChange,
  tooltip,
}: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = () => {
    !inputProps?.readOnly && setIsFocused(true);
  };

  const onBlur = () => {
    !inputProps?.readOnly && setIsFocused(false);
  };

  const handleDate = useCallback(
    (date) => {
      if (onDateChange) {
        onDateChange(date, datePickerProps?.name || "");
      }
    },
    [datePickerProps?.name, onDateChange],
  );

  const handleTime = useCallback(
    (time: string) => {
      if (onTimeChange) {
        onTimeChange(time, TimePickerProps?.name || "");
      }
    },
    [TimePickerProps?.name, onTimeChange],
  );

  // const onChange = (time, timeString) => {
  //   console.log(time, timeString);
  // };

  return (
    <ParentWrapper width={width} hidden={hidden} className={containerClass}>
      {!!tooltip && (
        <TooltipContainer>
          <Tooltip text={tooltip} element={<TooltipIcon src={images.toolTip} />} copy={false} />
        </TooltipContainer>
      )}
      <Wrapper>
        {type === "date" && (
          <>
            <Date
              dayPlaceholder='dd'
              monthPlaceholder='mm'
              yearPlaceholder='yyyy'
              onChange={handleDate}
              disabled={disabled}
              {...datePickerProps}
              value={validDate(datePickerProps?.value)}
              error={error}
            />
            <Label className={"focused"}>
              {label}
              {datePickerProps?.required && <span>{"*"}</span>}
            </Label>
          </>
        )}
        {type === "checkbox" && (
          <Switch>
            <input
              type='checkbox'
              onChange={inputProps?.onChange}
              name={inputProps?.name}
              checked={!!inputProps?.value}
              value={inputProps?.value}
            />
            <span className='slider round'></span>
            <Label className={"switch"}>
              {label}
              {inputProps?.required && <span>{"*"}</span>}
            </Label>
          </Switch>
        )}
        {!["date", "textarea", "checkbox"].includes(type) && (
          <>
            <Input
              error={error}
              autoCapitalize='none'
              onFocus={onFocus}
              ref={ref}
              onBlur={onBlur}
              accept='.gif,.jpg,.jpeg,.png,.pdf'
              {...inputProps}
            />
            <Label
              disabled={disabled}
              className={inputProps?.value || inputProps?.value == 0 || isFocused ? "focused" : ""}
            >
              {label}
              {inputProps?.required && <span>{"*"}</span>}
            </Label>
          </>
        )}
        {type === "textarea" && (
          <>
            <TextArea
              error={error}
              autoCapitalize='none'
              onFocus={onFocus}
              onBlur={onBlur}
              {...textAreaProps}
            />
            <Label className={textAreaProps?.value || isFocused ? "focused" : ""}>
              {label}
              {textAreaProps?.required && <span>{"*"}</span>}
            </Label>
          </>
        )}
        {type === "time" && (
          <>
            {/* <Time use12Hours format='h:mm a' onChange={handleTime} /> */}
            <Time
              format='h:mm a'
              onChange={handleTime}
              disabled={disabled}
              {...TimePickerProps}
              value={validTime(TimePickerProps?.value)}
              error={error}
            />
            <Label className={"focused"}>
              {label}
              {TimePickerProps?.required && <span>{"*"}</span>}
            </Label>
          </>
        )}
      </Wrapper>
      <ErrorText>{error ? error : <br />}</ErrorText>
    </ParentWrapper>
  );
};
