import Types from "./actionType";
import {
  FetchCampaignsPayload,
  CreateCampaigns,
  CreateCampaignsPayload,
  FetchCampaigns,
  FetchCampaignsError,
  FetchCampaignsSuccess,
  UpdateCampaigns,
  UpdateCampaignsPayload,
  CampaignsListWithMetaData,
  FetchCampaignsByIdPayload,
  FetchCampaignsById,
  DeleteCampaignsPayload,
  DeleteCampaigns,
} from "./types";

export const fetchCampaignsList = ({
  params,
  onSuccess,
  onError,
}: FetchCampaignsPayload): FetchCampaigns => ({
  type: Types.FETCH_CAMPAIGNS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCampaignsById = ({
  id,
  onSuccess,
  onError,
}: FetchCampaignsByIdPayload): FetchCampaignsById => ({
  type: Types.FETCH_CAMPAIGNS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCampaignsListSuccess = (
  data: CampaignsListWithMetaData,
): FetchCampaignsSuccess => ({
  type: Types.SUCCESS_FETCH_CAMPAIGNS,
  payload: {
    data,
  },
});

export const fetchCampaignsError = (error?: string): FetchCampaignsError => ({
  type: Types.ERROR_FETCH_CAMPAIGNS,
  payload: { error },
});

export const createCampaigns = ({
  data,
  onSuccess,
  onError,
}: CreateCampaignsPayload): CreateCampaigns => ({
  type: Types.CREATE_CAMPAIGNS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCampaigns = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCampaignsPayload): UpdateCampaigns => ({
  type: Types.UPDATE_CAMPAIGNS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCampaigns = ({
  id,
  onSuccess,
  onError,
}: DeleteCampaignsPayload): DeleteCampaigns => ({
  type: Types.DELETE_CAMPAIGNS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
