import moment from "moment-timezone";

import Button from "../../../components/Button/button";
import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";
import { generateArrayAttributes } from "../../../services/methods";
import styles from "../EmployeesController.styles";

export const Tab3 = ({
  designationData = [],
  setDesignationData,
  readOnly,
  onTextChange,
  onSubmitDesignationForm,
  pageType,
}) => {
  const ShowHideButton = () => {
    const newArray = [...designationData];
    newArray[0]["hidden"] = !newArray[0]["hidden"];
    setDesignationData(newArray);
  };
  return (
    <div>
      <Button
        onClick={ShowHideButton}
        block={true}
        label={designationData[0]?.hidden ? "Add New" : "Close Menu"}
      />
      {designationData.map((item, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "whitesmoke",
            marginBottom: 30,
            padding: 10,
            display: item.hidden ? "none" : "",
          }}
        >
          <Input
            readOnly={readOnly}
            label={"Date"}
            type={"date"}
            name='date'
            attributes={{ "data-index": index }}
            value={item.date ? moment(item.date).tz("Asia/Kolkata").format("YYYY-MM-DD") : ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          <Select
            readOnly={readOnly}
            name={"designation"}
            label={"Designation"}
            attributes={{ "data-index": index }}
            options={generateArrayAttributes("employeeDesignation").map((value) => ({
              label: value.value,
              value: value._id,
            }))}
            value={item.designation || ""}
            onChange={onTextChange}
          />
          {/* <styles.Row>
            <styles.Column> */}
          <Input
            readOnly={readOnly}
            label={"Base Salary"}
            type={"number"}
            name='salaryBase'
            attributes={{ "data-index": index }}
            value={item.salaryBase || ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          {/* </styles.Column>
            <styles.Column> */}
          <Input
            readOnly={readOnly}
            label={"Bonus Salary"}
            type={"number"}
            name='salaryBonus'
            attributes={{ "data-index": index }}
            value={item.salaryBonus || ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          {/* </styles.Column>
            <styles.Column> */}
          <Input
            readOnly={readOnly}
            label={"Expenses"}
            type={"number"}
            name='salaryAdditional'
            attributes={{ "data-index": index }}
            value={item.salaryAdditional || ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          {/* </styles.Column>
          </styles.Row> */}
          <styles.Row>
            <styles.Column>
              <Select
                readOnly={readOnly}
                name={"salaryCurrency"}
                label={"Currency"}
                attributes={{ "data-index": index }}
                options={generateArrayAttributes("currency").map((value) => ({
                  label: value.value,
                  value: value._id,
                }))}
                value={item.salaryCurrency || ""}
                onChange={onTextChange}
              />
            </styles.Column>
            <styles.Column>
              <Select
                readOnly={readOnly}
                name={"salaryPayroll"}
                label={"Frequency"}
                attributes={{ "data-index": index }}
                options={generateArrayAttributes("salaryFrequency").map((value) => ({
                  label: value.value,
                  value: value._id,
                }))}
                value={item.salaryPayroll || ""}
                onChange={onTextChange}
              />
            </styles.Column>
          </styles.Row>

          <Input
            readOnly={readOnly}
            label={"Comment Bonus"}
            type={"text"}
            name='commentBonus'
            attributes={{ "data-index": index }}
            value={item.commentBonus || ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          <Input
            readOnly={readOnly}
            label={"Bonus Comment"}
            type={"text"}
            name='comment'
            attributes={{ "data-index": index }}
            value={item.comment || ""}
            autoComplete={"off"}
            onChange={onTextChange}
          />
          {pageType !== "add" && (
            <Button
              disabled={item.loading ? true : false}
              attributes={{ "data-index": index }}
              onClick={onSubmitDesignationForm}
              block={true}
              label={
                index === 0 ? (item.loading ? "Adding" : "Add") : item.loading ? "Saving" : "Save"
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};
