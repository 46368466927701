import React from "react";

import { Layout } from "../../../presentationals";
import { KnowledgebaseListing, KnowledgebaseFilters } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<KnowledgebaseListing />}
      controller={<KnowledgebaseFilters />}
      admin={true}
      layout={"3"}
      title={strings.headerTitle.knowledgebase}
    />
  );
};

export default Route;
