import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { SPSBGCheckControllerProps } from "../../../../types/models/SPSBGCheck";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSAccountsList } from "../SPSAccounts/SPSAccountsList";
import { SPSDealsList } from "../SPSDeals";

import { useSPSBGCheckController } from "./hooks";

const Controller = ({ type }: SPSBGCheckControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsAccountsOptions, spsDealsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchSPSBGCheck,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSBGCheckController({ type, id, attachmentsPickerRef });
  const currentSPSBGCheck = data;

  const {
    workLogs,
    attachments,
    preferredName,
    consultantName,
    status,
    email,
    recruiterName,
    phone,
    associatedAccount,
    associatedDeal,
    newCompanyAppliedFor,
    newPositionAppliedFor,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSBGCheck();
    }
    // Do not include fetchSPSBGCheck to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Preferred Name'
            type={"text"}
            width='48%'
            error={preferredName.error}
            inputProps={{
              name: "preferredName",
              value: preferredName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: preferredName.required,
            }}
          />
          <TextInput
            label='Consultant Name'
            type={"text"}
            width='48%'
            error={consultantName.error}
            inputProps={{
              name: "consultantName",
              value: consultantName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: consultantName.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("spsBGCheckStatus")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Recruiter Name'
            type={"text"}
            width='48%'
            error={recruiterName.error}
            inputProps={{
              name: "recruiterName",
              value: recruiterName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: recruiterName.required,
            }}
          />
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"associatedAccount"}
            label={"SPS Account"}
            options={spsAccountsOptions}
            value={associatedAccount.value}
            onChange={onTextChange}
            mandatory={associatedAccount.required}
            error={associatedAccount.error}
          />
          <Select
            width={"48%"}
            name={"associatedDeal"}
            label={"Associated Deal"}
            options={spsDealsOptions}
            value={associatedDeal.value}
            onChange={onTextChange}
            mandatory={associatedDeal.required}
            error={associatedDeal.error}
          />
        </Row>
        <Row>
          <TextInput
            label='New Company Applied For'
            type={"text"}
            width='48%'
            error={newCompanyAppliedFor.error}
            inputProps={{
              name: "newCompanyAppliedFor",
              value: newCompanyAppliedFor.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: newCompanyAppliedFor.required,
            }}
          />
          <TextInput
            label='New Position Applied For'
            type={"text"}
            width='48%'
            error={newPositionAppliedFor.error}
            inputProps={{
              name: "newPositionAppliedFor",
              value: newPositionAppliedFor.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: newPositionAppliedFor.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    associatedAccount.error,
    associatedAccount.required,
    associatedAccount.value,
    associatedDeal.error,
    associatedDeal.required,
    associatedDeal.value,
    consultantName.error,
    consultantName.required,
    consultantName.value,
    email.error,
    email.required,
    email.value,
    newCompanyAppliedFor.error,
    newCompanyAppliedFor.required,
    newCompanyAppliedFor.value,
    newPositionAppliedFor.error,
    newPositionAppliedFor.required,
    newPositionAppliedFor.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
    preferredName.error,
    preferredName.required,
    preferredName.value,
    recruiterName.error,
    recruiterName.required,
    recruiterName.value,
    spsAccountsOptions,
    spsDealsOptions,
    status.error,
    status.required,
    status.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSBGCheck?.createdBy !== "string" ? currentSPSBGCheck?.createdBy?.name : "";
    const updatedBy =
      typeof currentSPSBGCheck?.updatedBy !== "string" ? currentSPSBGCheck?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentSPSBGCheck?.createdAt}
        updatedAt={currentSPSBGCheck?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSBGCheck?.createdAt,
    currentSPSBGCheck?.createdBy,
    currentSPSBGCheck?.updatedAt,
    currentSPSBGCheck?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.spsBGCheck.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsBGCheck.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  // Related Lists
  const renderSPSAccounts = useMemo(
    () => (
      <SPSAccountsList
        relatedList={{
          type: "_id",
          value:
            typeof currentSPSBGCheck.associatedAccount !== "string"
              ? currentSPSBGCheck.associatedAccount?._id
              : currentSPSBGCheck.associatedAccount,
        }}
      />
    ),
    [currentSPSBGCheck.associatedAccount],
  );
  const renderSPSDeals = useMemo(
    () => (
      <SPSDealsList
        relatedList={{
          type: "_id",
          value:
            typeof currentSPSBGCheck.associatedDeal !== "string"
              ? currentSPSBGCheck.associatedDeal?._id
              : currentSPSBGCheck.associatedDeal,
        }}
      />
    ),
    [currentSPSBGCheck.associatedDeal],
  );
  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderSPSAccounts;
      case 6:
        return renderSPSDeals;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderSPSDeals,
    renderSPSAccounts,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSBGCheckController = memo(Controller);
