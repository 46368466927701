import styled from "styled-components";

import { colors, fs } from "../../../../theme";

interface Props {
  loading?: boolean;
  active?: boolean;
  noBorder?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-direction: column;
  display: flex;
  opacity: ${(props) => (props.loading ? "0.6" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "all")};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background-color: green; */
`;

export const FormContainer = styled.div<Props>`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const RenderContainer = styled.div`
  width: 100%;
`;

export const OverViewRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const FormTitle = styled.div`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
`;

export const RelatedListContainer = styled.div`
  display: flex;
  width: 100%;
`;
