import React from "react";

import { Header } from "../../presentationals/components";

const Screen404 = ({ type }) => {
  return (
    <>
      <Header title={""} />
      <div>{type}</div>
    </>
  );
};

export default Screen404;
