/* eslint-disable no-unused-vars */
enum Types {
  EMPLOYEES_FETCH = "EMPLOYEES_FETCH",
  EMPLOYEE_DETAILS_BY_ID = "EMPLOYEE_DETAILS_BY_ID",
  EMPLOYEES_FETCH_SUCCESS = "EMPLOYEES_FETCH_SUCCESS",
  EMPLOYEES_FETCH_ERROR = "EMPLOYEES_FETCH_ERROR",

  EMPLOYEES_FETCH_BASIC = "EMPLOYEES_FETCH_BASIC",
  EMPLOYEES_FETCH_OPEN_SUCCESS = "EMPLOYEES_FETCH_OPEN_SUCCESS",

  EMPLOYEES_GET = "EMPLOYEES_GET",
  EMPLOYEES_ADD = "EMPLOYEES_ADD",
  EMPLOYEES_UPDATE = "EMPLOYEES_UPDATE",
  EMPLOYEES_UPDATE_FILTER = "EMPLOYEES_UPDATE_FILTER",

  EMPLOYEES_GET_COMMENTS = "EMPLOYEES_GET_COMMENTS",
  EMPLOYEES_ADD_COMMENTS = "EMPLOYEES_ADD_COMMENTS",
  EMPLOYEES_DELETE_COMMENTS = "EMPLOYEES_DELETE_COMMENTS",

  EMPLOYEES_ADD_DESIGNATIONS = "EMPLOYEES_ADD_DESIGNATIONS",
  EMPLOYEES_UPDATE_DESIGNATIONS = "EMPLOYEES_UPDATE_DESIGNATIONS",

  EMPLOYEES_ADD_APPRAISAL = "EMPLOYEES_ADD_APPRAISAL",
  EMPLOYEES_UPDATE_APPRAISAL = "EMPLOYEES_UPDATE_APPRAISAL",

  EMPLOYEES_ADD_EMERGENCY_CONTACT = "EMPLOYEES_ADD_EMERGENCY_CONTACT",
  EMPLOYEES_UPDATE_EMERGENCY_CONTACT = "EMPLOYEES_UPDATE_EMERGENCY_CONTACT",

  EMPLOYEES_FETCH_PAYROLLDATA = "EMPLOYEES_FETCH_PAYROLLDATA",
  EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS = "EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS",
  EMPLOYEES_FETCH_PAYROLLDATA_SELF = "EMPLOYEES_FETCH_PAYROLLDATA_SELF",
  EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS_SELF = "EMPLOYEES_FETCH_PAYROLLDATA_SUCCESS_SELF",
  EMPLOYEES_GET_PAYROLLDATA = "EMPLOYEES_GET_PAYROLLDATA",
  EMPLOYEES_ADD_PAYROLLDATA = "EMPLOYEES_ADD_PAYROLLDATA",
  EMPLOYEES_UPDATE_PAYROLLDATA = "EMPLOYEES_UPDATE_PAYROLLDATA",
  EMPLOYEES_UPDATE_PAYROLLDATA_SELF = "EMPLOYEES_UPDATE_PAYROLLDATA_SELF",
  EMPLOYEES_PAYROLLS_REHYDRATE = "EMPLOYEES_PAYROLLS_REHYDRATE",
  PAYROLLS_FILTERS = "PAYROLLS_FILTERS",
  CHILD_PAYROLLS_FILTERS = "CHILD_PAYROLLS_FILTERS",

  FETCH_EMPLOYEES_BIRTHDAYS = "FETCH_EMPLOYEES_BIRTHDAYS",
  SET_EMPLOYEES_BIRTHDAYS = "SET_EMPLOYEES_BIRTHDAYS",
}

export default Types;
