import { metaDataTypes } from "../../types";

import Types from "./types";

export const fetchUsers = (payload) => ({
  type: Types.USERS_FETCH,
  payload: payload,
});

export const fetchUsersSuccess = (list = [], isEmployee) => ({
  type: Types.USERS_FETCH_SUCCESS,
  payload: {
    data: list,
    isEmployee,
    metaData: {},
  },
});

export const setMetaData = (metaData: metaDataTypes) => ({
  type: Types.USERS_SET_META_DATA,
  payload: {
    data: metaData,
  },
});

export const fetchUsersError = (payload) => ({
  type: Types.USERS_FETCH_ERROR,
  payload: payload,
});

export const addUsers = ({ data, onSuccess, onError }) => ({
  type: Types.USERS_ADD,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateUsers = ({ id, data, onSuccess, onError }) => ({
  type: Types.USERS_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateManyUsers = ({ data, onSuccess, onError }) => ({
  type: Types.USERS_UPDATE_MANY,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const filterUsers = ({ field, value }) => ({
  type: Types.USER_FITERS_UPDATE,
  payload: {
    field: field,
    value: value,
  },
});

export const getUserById = (payload) => ({
  type: Types.GET_USER_BY_ID,
  payload,
});

export const addUsersChecklist = ({ data, onSuccess, onError }) => ({
  type: Types.USERS_ADD_CHECKLIST,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateUsersChecklist = ({ id, data, onSuccess, onError }) => ({
  type: Types.USERS_UPDATE_CHECKLIST,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});
