import React from "react";

import Skeleton from "react-loading-skeleton";

import { Container } from "./styles";

interface SkeletonProps {
  rows?: number;
}

export const CountWidgetSkeleton = ({ rows = 1 }: SkeletonProps) => {
  return (
    <Container>
      <Skeleton count={rows} className='count-widget-skeleton' />
    </Container>
  );
};
