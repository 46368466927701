import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { ContactUsControllerProps } from "../../../../types/models/ContactUs";
import { ActiveSelectOptions, collections, ITNContactUSOptions } from "../../../../utils";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, SystemData, Select } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useContactUsController } from "./hooks";

const Controller = ({ type }: ContactUsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchContactUs,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useContactUsController({ type, id, attachmentsPickerRef });
  const currentContactUS = data;

  const { workLogs, attachments, name, email, jobTitle, company, phone, description, tag, budget } =
    formData;

  useEffect(() => {
    if (type === "edit") {
      fetchContactUs();
    }
    // Do not include fetchContactUS to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Job Title'
            type={"text"}
            width='48%'
            error={jobTitle.error}
            inputProps={{
              name: "jobTitle",
              value: jobTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: jobTitle.required,
            }}
          />
          <TextInput
            label='Company'
            type={"text"}
            width='48%'
            error={company.error}
            inputProps={{
              name: "company",
              value: company.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: company.required,
            }}
          />
        </Row>

        <Row>
          <Select
            name={"type"}
            label={"Type"}
            options={ITNContactUSOptions}
            value={formData.type.value}
            onChange={onTextChange}
            mandatory={formData.type.required}
            error={formData.type.error}
          />
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
        </Row>
        {formData.type.value === "dch" && (
          <Row>
            <TextInput
              label='Budget'
              type={"text"}
              width='48%'
              error={budget.error}
              inputProps={{
                name: "budget",
                value: budget.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: budget.required,
              }}
            />
            <TextInput
              label='Tag'
              type={"text"}
              width='48%'
              error={tag.error}
              inputProps={{
                name: "tag",
                value: tag.value,
                autoComplete: "off",
                onChange: onTextChange,
                required: company.required,
              }}
            />
          </Row>
        )}
        <Row>
          <TextInput
            label='Description'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              rows: 8,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    company.error,
    company.required,
    company.value,
    description.error,
    description.required,
    description.value,
    email.error,
    email.required,
    email.value,
    jobTitle.error,
    jobTitle.required,
    jobTitle.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentContactUS?.createdBy !== "string" ? currentContactUS?.createdBy?.name : "";
    const updatedBy =
      typeof currentContactUS?.updatedBy !== "string" ? currentContactUS?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentContactUS?.createdAt}
        updatedAt={currentContactUS?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentContactUS?.createdAt,
    currentContactUS?.createdBy,
    currentContactUS?.updatedAt,
    currentContactUS?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.itnContact.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.itnContact.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  // // Related Lists
  // const renderSPSAccounts = useMemo(
  //   () => (
  //     <SPSAccountsList
  //       relatedList={{
  //         type: "_id",
  //         value:
  //           typeof currentContactUS.associatedAccount !== "string"
  //             ? currentContactUS.associatedAccount?._id
  //             : currentContactUS.associatedAccount,
  //       }}
  //     />
  //   ),
  //   [currentContactUS.associatedAccount],
  // );
  // const renderSPSDeals = useMemo(
  //   () => (
  //     <SPSDealsList
  //       relatedList={{
  //         type: "_id",
  //         value:
  //           typeof currentContactUS.associatedDeal !== "string"
  //             ? currentContactUS.associatedDeal?._id
  //             : currentContactUS.associatedDeal,
  //       }}
  //     />
  //   ),
  //   [currentContactUS.associatedDeal],
  // );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      // case 5:
      //   return renderSPSAccounts;
      // case 6:
      //   return renderSPSDeals;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ContactUSController = memo(Controller);
