export const fonts = {
  Poppins: "Poppins",
  PoppinsMedium: "PoppinsMedium",
  PoppinsBold: "PoppinsBold",
  Roboto: "Roboto",
  RobotoMedium: "RobotoMedium",
  RobotoBold: "RobotoBold",
  SourceSansPro: "Source Sans Pro",
  OpenSans: "Open Sans",
};

export const fs = {
  xxs: "9px",
  xs: "12px",
  s: "13px",
  m: "16px",
  l: "18px",
  xl: "24px",
  xxl: "32px",
  xxxl: "48px",
};
