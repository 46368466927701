import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { getOrganisation, getToken, ActivityLogsList } from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchActivityLogsListSuccess, fetchActivityLogsError } from "./actions";
import Types from "./actionType";
import {
  CreateActivityLogsuccess,
  CreateActivityLogs as CreateActivityLogsType,
  ActivityLogsSuccess,
  UpdateActivityLogs as UpdateActivityLogsType,
  FetchActivityLogs,
  FetchActivityLogsById,
  ActivityLogsByIdSuccess,
} from "./types";

// function* createWorker(action: CreateActivityLogsType) {
//   try {
//     const options = {
//       method: "POST",
//       url: AddActivityLogs,
//       headers: {
//         Authorization: yield select(getToken),
//         Organisation: yield select(getOrganisation),
//       },
//       data: action.payload.data,
//     };
//     const response: AxiosResponse<CreateActivityLogsuccess> = yield call(axios, options);
//     if (response.data.success && action.payload.onSuccess) {
//       action.payload.onSuccess(response.data.data);
//     }
//   } catch (e) {
//     const error: AxiosError<AxiosFailure> = e;
//     action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
//   }
// }

// function* updateWorker(action: UpdateActivityLogsType) {
//   try {
//     const options = {
//       method: "PUT",
//       url: UpdateActivityLogs(action.payload.id),
//       headers: {
//         Authorization: yield select(getToken),
//         Organisation: yield select(getOrganisation),
//       },
//       data: action.payload.data,
//     };
//     const response: AxiosResponse<CreateActivityLogsuccess> = yield call(axios, options);
//     if (response.data.success && action?.payload?.onSuccess) {
//       action.payload.onSuccess(response.data.data);
//     }
//   } catch (e) {
//     const error: AxiosError<AxiosFailure> = e;
//     action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
//   }
// }

function* fetchAllWorker(action: FetchActivityLogs) {
  console.log(action);
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ActivityLogsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ActivityLogsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchActivityLogsListSuccess(data.data));
    }
  } catch (e) {
    console.log(e);
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchActivityLogsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching logs");
    }
  }
}

// function* fetchActivityLogsById(action: FetchActivityLogsById) {
//   try {
//     const id = action.payload.id;
//     const options = {
//       method: "GET",
//       url: FindActivityLogs(id),
//       headers: {
//         Authorization: yield select(getToken),
//         Organisation: yield select(getOrganisation),
//       },
//     };
//     const response: AxiosResponse<ActivityLogsByIdSuccess> = yield call(axios, options);
//     const { data } = response;
//     if (data.success) {
//       if (action.payload?.onSuccess) {
//         action.payload.onSuccess(data.data);
//       }
//     }
//   } catch (e) {
//     const error: AxiosError<AxiosFailure> = e;
//     if (action.payload?.onError) {
//       action.payload.onError(error.response?.data.message || "Error in fetching logs");
//     }
//   }
// }

export default function* Saga() {
  yield takeLatest(Types.FETCH_ACTIVITY_LOGS, fetchAllWorker);
  // yield takeLatest(Types.CREATE_ACTIVITY_LOGS, createWorker);
  // yield takeLatest(Types.UPDATE_ACTIVITY_LOGS, updateWorker);
  // yield takeEvery(Types.FETCH_ACTIVITY_LOGS_BY_ID, fetchActivityLogsById);
}
