import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";

export const Tab4 = ({ formData, readOnly, onTextChange }) => {
  const trueFasle = [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ];
  return (
    <>
      <Select
        readOnly={readOnly}
        name={"accountGoogleCreated"}
        label={"Google Account Created"}
        options={trueFasle}
        value={formData.accountGoogleCreated}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"accountGoogleSuspended"}
        label={"Google Account Suspended"}
        options={trueFasle}
        value={formData.accountGoogleSuspended}
        onChange={onTextChange}
      />
      <Select
        readOnly={readOnly}
        name={"accountVeemCreated"}
        label={"Veem Account Created"}
        options={trueFasle}
        value={formData.accountVeemCreated}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Veem Reason"}
        type={"text"}
        name='accountVeemCreatedReason'
        value={formData.accountVeemCreatedReason}
        autoComplete={"off"}
        onChange={onTextChange}
        // mandatory={true}
        // error={formValidation?.name?.error}
      />
      <Select
        readOnly={readOnly}
        name={"accountDeleted"}
        label={"All Account Deleted"}
        options={trueFasle}
        value={formData.accountDeleted}
        onChange={onTextChange}
      />
      <Input
        readOnly={readOnly}
        label={"Account Deleted Comment"}
        type={"text"}
        name='accountDeletedComment'
        value={formData.accountDeletedComment}
        autoComplete={"off"}
        onChange={onTextChange}
      />
    </>
  );
};
