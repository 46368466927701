import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { StudentProfileControllerProps } from "../../../../types";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, FormChecklist, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyBatchList } from "../AcademyBatch/AcademyBatchList";
import { AcademyCoursesList } from "../AcademyCourses/AcademyCoursesList";
import { AcademyMentorFeedbackList } from "../AcademyMentorFeedback/AcademyMentorFeedbackList";
import { RelatedListContainer } from "../Departments/styles";
import { TaskList } from "../Task";

import { useAcademyStudentProfileController } from "./hooks";

export const AcademyStudentProfileController = ({ type }: StudentProfileControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyStudentProfile,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useAcademyStudentProfileController({ type, id: params.id, attachmentsPickerRef });
  const currentAcademyStudentProfile = data;

  const {
    employeesOptions,
    academyCoursesOptions,
    academBatchesOptions,
    academyCandidateEnrollmentsOptions,
  } = useControllerConfigs();

  const {
    workLogs,
    attachments,
    studentName,
    studentOfficialEmail,
    studentID,
    enrolledCourse,
    batches,
    studentTrainingCordinator,
    associatedEnrollment,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyStudentProfile();
    }
    // Do not include fetchAcademyStudentProfile to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label={`Learner's Name`}
            type={"text"}
            width='48%'
            error={studentName.error}
            inputProps={{
              name: "studentName",
              value: studentName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"associatedEnrollment"}
            label={"Associated Enrollment"}
            options={academyCandidateEnrollmentsOptions}
            value={associatedEnrollment.value}
            onChange={onTextChange}
            mandatory={associatedEnrollment.required}
            error={associatedEnrollment.error}
          />
        </Row>

        <Row>
          <TextInput
            label={`Learner's ID`}
            type={"text"}
            width='48%'
            error={studentID.error}
            inputProps={{
              name: "studentID",
              value: studentID.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentID.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={`Learner's Email`}
            type={"text"}
            width='48%'
            error={studentOfficialEmail.error}
            inputProps={{
              name: "studentOfficialEmail",
              value: studentOfficialEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: studentOfficialEmail.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Select
          width={"100%"}
          name={"enrolledCourse"}
          label={"Enrolled Course"}
          options={academyCoursesOptions}
          value={enrolledCourse.value}
          onChange={onTextChange}
          mandatory={enrolledCourse.required}
          error={enrolledCourse.error}
        />
        <Select
          width={"100%"}
          name={"batches"}
          label={"Batches"}
          options={academBatchesOptions}
          isMulti
          value={batches.value}
          onChange={onTextChange}
          mandatory={batches.required}
          error={batches.error}
        />
        <Select
          width={"48%"}
          name={"studentTrainingCordinator"}
          label={"Learner's Training Cordinator"}
          options={employeesOptions}
          value={studentTrainingCordinator.value}
          onChange={onTextChange}
          mandatory={studentTrainingCordinator.required}
          error={studentTrainingCordinator.error}
        />
      </>
    );
  }, [
    studentName.error,
    studentName.value,
    studentName.required,
    onTextChange,
    academyCandidateEnrollmentsOptions,
    associatedEnrollment.value,
    associatedEnrollment.required,
    associatedEnrollment.error,
    studentID.error,
    studentID.value,
    studentID.required,
    studentOfficialEmail.error,
    studentOfficialEmail.value,
    studentOfficialEmail.required,
    academyCoursesOptions,
    enrolledCourse.value,
    enrolledCourse.required,
    enrolledCourse.error,
    academBatchesOptions,
    batches.value,
    batches.required,
    batches.error,
    employeesOptions,
    studentTrainingCordinator.value,
    studentTrainingCordinator.required,
    studentTrainingCordinator.error,
  ]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyStudentProfile?.createdBy !== "string"
        ? currentAcademyStudentProfile?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyStudentProfile?.updatedBy !== "string"
        ? currentAcademyStudentProfile?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyStudentProfile?.createdAt}
        updatedAt={currentAcademyStudentProfile?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyStudentProfile?.createdAt,
    currentAcademyStudentProfile?.createdBy,
    currentAcademyStudentProfile?.updatedAt,
    currentAcademyStudentProfile?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.academyStudentProfile.name} recordId={params.id} />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyStudentProfile.name} />,
    [params.id],
  );
  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const relatedBatches = useCallback(() => {
    return (
      <AcademyBatchList
        relatedList={{
          type: "batches",
          value: batches.value.map((item) => item?.value).join(","),
        }}
      />
    );
  }, [batches]);
  const relatedCourses = useMemo(() => {
    return (
      <AcademyCoursesList
        relatedList={{
          type: "_id",
          value: enrolledCourse.value,
        }}
      />
    );
  }, [enrolledCourse.value]);
  const relatedMentor = useCallback(() => {
    return (
      <AcademyMentorFeedbackList
        relatedList={{
          type: "studentProfile",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const renderRelatedTasks = useCallback(() => {
    return (
      <RelatedListContainer>
        <TaskList
          relatedList={{
            type: "associatedCandidateStudent",
            value: params.id,
            associatedTo: "ach",
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs();
      case 4:
        return renderSystemData();
      case 5:
        return relatedCourses;
      // case 5:
      //   return relatedBatches();
      case 6:
        return relatedMentor();
      case 7:
        return renderRelatedTasks();
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    relatedCourses,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
    // relatedBatches,
    relatedMentor,
    renderRelatedTasks,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
