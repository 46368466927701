import React from "react";

import { Layout } from "../../../presentationals";
import { ServiceNowSolutionsController } from "../../../presentationals/screens/admin";

const Route = () => {
  return (
    <Layout activity={<ServiceNowSolutionsController type={"edit"} />} admin={true} layout={"2"} />
  );
};

export const APServiceNowSolutionsEdit = React.memo(Route);
