import React, { useEffect, useState, useMemo, useCallback } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate } from "../../../../services/methods";
import { strings } from "../../../../theme";
import OldEmployees from "../../../../utils/employees.json";
import { Popup, TableList } from "../../../components";

import {
  OldDesignationsItem,
  OldEmployeeContainer,
  OldEmployeeKey,
  OldEmployeeModal,
  OldEmployeeValue,
} from "./styles";

const columns = [
  {
    title: "Serial",
    width: "10%",
    key: "serial",
  },
  {
    title: "Name",
    width: "20%",
    key: "name",
  },
  {
    title: "Organisation",
    width: "20%",
    render: (item) => {
      return <div className='title'>{item?.organisation?.name}</div>;
    },
  },
  {
    title: "Email",
    width: "20%",
    key: "official_email",
  },
  {
    title: "Mobile",
    width: "20%",
    key: "mobile",
  },
];

const keys = {
  _id: "_id",
  name: "name",
  nickName: "nickName",
  accountVeemCreatedReason: "accountVeemCreatedReason",
  referral: "referral",
  referralMobile: "referralMobile",
  referralEmail: "referralEmail",
  isPODManager: "isPODManager",
  email: "email",
  official_email: "official_email",
  mobile: "mobile",
  mobile2: "mobile1",
  dateJoining: "dateJoining",
  dateHire: "dateHire",
  dateTrainingStart: "dateTrainingStart",
  dateTrainingEnd: "dateTrainingEnd",
  dateAppraisal: "dateAppraisal",
  dateTermination: "dateTermination",
  terminationComment: "terminationComment",
  accountGoogleCreated: "accountGoogleCreated",
  accountGoogleSuspended: "accountGoogleSuspended",
  accountDeleted: "accountDeleted",
  accountVeemCreated: "accountVeemCreated",
  accountDeletedComment: "accountDeletedComment",
  personalRelationshipName: "personalRelationshipName",
  personalAddress: "personalAddress",
  personalCity: "personalCity",
  personalState: "personalState",
  personalZip: "personalZip",
  emergencyName: "emergencyName",
  emergencyRelation: "emergencyRelation",
  emergencyMobile: "emergencyMobile",
  emergencyAddress: "emergencyAddress",
  emergency1Name: "emergency1Name",
  emergency1Relationship: "emergency1Relationship",
  emergency1Mobile: "emergency1Mobile",
  emergency1Address: "emergency1Address",
  attachmentResume: "attachmentResume",
  attachmentCoverLetter: "attachmentCoverLetter",
  attachmentInternshipCertificate: "attachmentInternshipCertificate",
  attachmentConsultantProfile: "attachmentConsultantProfile",
  nationalId: "nationalId",
};
const attributes = {
  nationalIdType: "nationalIdType",
  employeeLevel: "employeeLevel",
  employeeType: "employeeType",
  employmentStatus: "employmentStatus",
  employmentType: "employmentType",
  employmentDepartment: "employmentDepartment",
  personalGender: "personalGender",
  personalMartial: "personalMartial",
  personalRelationship: "personalRelationship",
  personalCountry: "personalCountry",
};

const nameValues = {
  organisation: "organisation",
  recruitId: "recruitId",
  reportingManager: "reportingManager",
  reportingHR: "reportingHR",
  reportingPOD: "reportingPOD",
  reportingOperations: "reportingOperations",
  "": "",
};

export const EmployeesOld = () => {
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const currentOrganisationID = useAppSelector((state) => state.UserReducer.organisation?._id);
  console.log("AA ::", currentOrganisationID);
  const onSearchChange = useCallback((e) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, []);

  const clearId = useCallback(() => {
    setId("");
  }, []);
  const employees = useMemo(() => {
    let oldEmployees = OldEmployees.filter((item) => item.organisation === currentOrganisationID);
    if (search) {
      oldEmployees = oldEmployees.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(search.toLowerCase().split(" ").join("")),
      );
    }
    return oldEmployees;
  }, [search, currentOrganisationID]);
  const onClickEmployees = useCallback((id) => {
    console.log(id);
    setId(id);
  }, []);

  const currentEmployee = useMemo(() => {
    const item = employees.find((item) => item._id == id);
    return item;
  }, [id, employees]);

  return (
    <>
      <TableList
        headerTitle={strings.headerTitle.employees}
        list={employees}
        columns={columns}
        onClickTableRow={onClickEmployees}
        //   metaData={employees?.metaData}
        searchPlaceholder={strings.apEmployees.search}
        onSearch={() => console.log("A")}
        clearSearch={handleClearSearch}
        searchValue={search}
        onSearchChange={onSearchChange}
      />
      <Popup isOpen={!!id} closeModal={clearId}>
        <OldEmployeeModal>
          {Object.keys(keys).map((item, index) => {
            return (
              <OldEmployeeContainer key={index}>
                <OldEmployeeKey>{item}</OldEmployeeKey>
                <OldEmployeeValue>{currentEmployee?.[keys[item]] || "-"}</OldEmployeeValue>
              </OldEmployeeContainer>
            );
          })}
          {Object.keys(attributes).map((item, index) => {
            return (
              <OldEmployeeContainer key={index}>
                <OldEmployeeKey>{item}</OldEmployeeKey>
                <OldEmployeeValue>
                  {currentEmployee?.[attributes[item]]?.value || "-"}
                </OldEmployeeValue>
              </OldEmployeeContainer>
            );
          })}
          {Object.keys(nameValues).map((item, index) => {
            return (
              <OldEmployeeContainer key={index}>
                <OldEmployeeKey>{item}</OldEmployeeKey>
                <OldEmployeeValue onClick={() => console.log(currentEmployee?.[nameValues[item]])}>
                  {`${currentEmployee?.[nameValues[item]]?.name || ""} : ${
                    currentEmployee?.[nameValues[item]]?.official_email || ""
                  }`}
                </OldEmployeeValue>
              </OldEmployeeContainer>
            );
          })}
          Designations
          {currentEmployee?.designations.map((item, index) => {
            return (
              <OldDesignationsItem key={index}>
                {item?._id}
                <OldEmployeeContainer>
                  <OldEmployeeKey>Date</OldEmployeeKey>
                  <OldEmployeeValue>
                    {MomentDate({ date: item?.date, format: "YYYY-MM-DD" })}
                  </OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Designation</OldEmployeeKey>
                  <OldEmployeeValue>{item?.designation?.value}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Salary Base</OldEmployeeKey>
                  <OldEmployeeValue>{item?.salaryBase}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Salary Bonus</OldEmployeeKey>
                  <OldEmployeeValue>{item?.salaryBonus}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Salary Additional</OldEmployeeKey>
                  <OldEmployeeValue>{item?.salaryAdditional}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Salary Currency</OldEmployeeKey>
                  <OldEmployeeValue>{item?.salaryCurrency?.value}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Salary Payroll</OldEmployeeKey>
                  <OldEmployeeValue>{item?.salaryPayroll?.value}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Comment Bonus</OldEmployeeKey>
                  <OldEmployeeValue>{item?.commentBonus}</OldEmployeeValue>
                </OldEmployeeContainer>

                <OldEmployeeContainer>
                  <OldEmployeeKey>Comment</OldEmployeeKey>
                  <OldEmployeeValue>{item?.comment}</OldEmployeeValue>
                </OldEmployeeContainer>
              </OldDesignationsItem>
            );
          })}
        </OldEmployeeModal>
      </Popup>
    </>
  );
};
