import Types from "./actionTypes";
import { KnowledgeActions, KnowledgeReducerType } from "./types";

const Initial: KnowledgeReducerType = {
  list: {
    data: [],
    type: "",
    searchQuery: "",
    filters: { knowledgebases: [], categories: [], subcategories: [], tags: [] },
    metaData: { totalItems: 0, limit: 0, currentPage: 0, totalPages: 0 },
  },
  currentFilters: { knowledgebase: "", categories: [], subcategories: [], tags: [] },
  loading: false,
  dashboardData: {
    approvals: [],
    myArticles: [],
    mostViewedArticles: [],
    staffedPickedArticles: [],
    knowledgebases: [],
  },
};

const KnowledgeReducer = (state = Initial, action: KnowledgeActions) => {
  switch (action.type) {
    case Types.FETCH_KNOWLEDGE_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case Types.FETCH_KNOWLEDGE_ARTICLES_SUCCESS:
      return {
        ...state,
        list: action.payload.articles,
        loading: false,
        currentFilters: action.payload.appliedFilters,
      };
    case Types.SET_KNOWLEDGEBASE_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          knowledgebase: action.payload.knowledgebase,
        },
      };
    case Types.SET_CATEGORIES_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          categories: action.payload.categories,
        },
      };
    case Types.SET_SUBCATEGORIES_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          subcategories: action.payload.subcategories,
        },
      };
    case Types.SET_TAGS_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          tags: action.payload.tags,
        },
      };
    case Types.CLEAR_ALL_FILTER:
      return {
        ...state,
        currentFilters: { knowledgebase: "", categories: [], subcategories: [], tags: [] },
      };
    case Types.KNOWLEDGE_DASHBOARD_FETCH_SUCCESS:
      console.log("Action ::", action.payload.data);
      return {
        ...state,
        dashboardData: action.payload.data,
      };
    default:
      return state;
  }
};

export default KnowledgeReducer;
