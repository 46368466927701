import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import {
  MomentDate,
  getAttributValues,
  getAttributValuesBynameId,
} from "../../../../services/methods";
import { strings } from "../../../../theme";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, Button, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { AcademyACHList } from "../AcademyACH/AcademyACHList";
import { AcademyCandidateEnrollmentsList } from "../AcademyCandidateEnrollments/AcademyCandidateEnrollmentsList";
import { AcademyCandidatesList } from "../AcademyCandidates/AcademyCandidatesList";
import { AcademyCoursesList } from "../AcademyCourses";
import { RelatedListContainer } from "../Departments/styles";
import { TaskList } from "../Task";

import { useAcademyCandidateLeadsController } from "./hooks";

const { graduationYearTooltip } = strings.apAcademyCandidateLeads;

export const AcademyCandidateLeadsController = ({ type }: { type?: "add" | "edit" }) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCandidateLeads,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useAcademyCandidateLeadsController({ type, id: params.id, attachmentsPickerRef });

  const currentAcademyCandidateLeads = data;

  const {
    workLogs,
    attachments,
    firstName,
    lastName,
    email,
    phone,
    course,
    dob,
    source,
    otherSource,
    city,
    state,
    country,
    graduationYear,
    graduationDegree,
    otherDegree,
    currency,
    graduationMajor,
    linkedInURL,
    status,
    stage,
    associatedContact,
    admissionCounsellor,
    enrollmentSpecialists,
    sourceContactDate,
    saleDate,
    totalFees,
    paymentTerms,
    anyDiscountsGiven,
  } = formData;

  const { academyCoursesOptions, employeesOptions, academyCandidatesOptions, currencyOptions } =
    useControllerConfigs();

  useEffect(() => {
    if (type === "edit") {
      fetchAcademyCandidateLeads();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Full Name'
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "fullName",
              value: `${firstName.value} ${lastName.value}`,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"associatedContact"}
            label={"Associated Contact"}
            options={academyCandidatesOptions}
            value={associatedContact.value}
            onChange={onTextChange}
            mandatory={associatedContact.required}
            error={associatedContact.error}
          />
        </Row>
        <Row>
          <TextInput
            label='First Name'
            type={"text"}
            width='48%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("academyCandidateStatus", true)}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Last Name'
            type={"text"}
            width='48%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"stage"}
            label={"Stage"}
            options={getAttributValues("academyCandidateLeadStages", true)}
            value={stage.value}
            onChange={onTextChange}
            mandatory={true}
            // mandatory={stage.required}
            error={stage.error}
          />
        </Row>

        <Row>
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label='DOB'
            type={"date"}
            width='48%'
            error={dob.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "dob",
              value: dob.value,
              maxDate: new Date(),
              required: dob.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"currency"}
            label={"Currency"}
            options={currencyOptions}
            value={currency.value}
            onChange={onTextChange}
            mandatory={currency.required}
            error={currency.error}
          />
        </Row>

        <Row>
          <Select
            width={"48%"}
            name={"course"}
            label={"Courses Interested"}
            options={academyCoursesOptions}
            value={course.value}
            onChange={onTextChange}
            mandatory={course.required}
            error={course.error}
          />
        </Row>
        <Select
          width={"48%"}
          name={"source"}
          label={"Source"}
          options={getAttributValues("academyCandidateSource")}
          value={source.value}
          onChange={onTextChange}
          mandatory={source.required}
          error={source.error}
        />

        {getAttributValuesBynameId("academyCandidateLeadSource", source.value)?.toLowerCase() ===
          "other" && (
          <TextInput
            label={"Other Source"}
            type={"text"}
            width='100%'
            error={otherSource.error}
            inputProps={{
              name: "otherSource",
              value: otherSource.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: otherSource.required,
            }}
            textAreaProps={{}}
          />
        )}
        <Row>
          <TextInput
            label={"City"}
            type={"text"}
            width='48%'
            error={city.error}
            inputProps={{
              name: "city",
              value: city.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: city.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"State/Province"}
            type={"text"}
            width='48%'
            error={state.error}
            inputProps={{
              name: "state",
              value: state.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: state.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"country"}
            label={"Country/Region"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
          <TextInput
            label={"Graduation Year"}
            type={"text"}
            width='48%'
            tooltip={graduationYearTooltip}
            error={graduationYear.error}
            inputProps={{
              name: "graduationYear",
              value: graduationYear.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: graduationYear.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        {/* <Select
          width={"48%"}
          name={"active"}
          label={"Active"}
          options={ActiveSelectOptions}
          value={active.value}
          onChange={onTextChange}
          mandatory={active.required}
          error={active.error}
        /> */}
        <Row>
          <Select
            width={"48%"}
            name={"graduationDegree"}
            label={"Graduation Degree"}
            options={getAttributValues("academyCandidateDegree", true)}
            value={graduationDegree.value}
            onChange={onTextChange}
            mandatory={graduationDegree.required}
            error={graduationDegree.error}
          />{" "}
          <Select
            width={"48%"}
            name={"graduationMajor"}
            label={"Graduation Major"}
            options={getAttributValues("academyCandidateGraduationMajor", true)}
            value={graduationMajor.value}
            onChange={onTextChange}
            mandatory={graduationMajor.required}
            error={graduationMajor.error}
          />
        </Row>
        {getAttributValuesBynameId(
          "academyCandidateDegree",
          graduationDegree.value,
        )?.toLowerCase() === "other" && (
          <TextInput
            label={"Other Degree"}
            type={"text"}
            width='100%'
            error={otherDegree.error}
            inputProps={{
              name: "otherDegree",
              value: otherDegree.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: otherDegree.required,
            }}
            textAreaProps={{}}
          />
        )}
        <TextInput
          label={"LinkedIn URL"}
          type={"text"}
          width='100%'
          error={linkedInURL.error}
          inputProps={{
            name: "linkedInURL",
            value: linkedInURL.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: linkedInURL.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    firstName.value,
    firstName.error,
    firstName.required,
    lastName.value,
    lastName.error,
    lastName.required,
    academyCandidatesOptions,
    associatedContact.value,
    associatedContact.required,
    associatedContact.error,
    onTextChange,
    status.value,
    status.required,
    status.error,
    stage.value,
    stage.error,
    email.error,
    email.value,
    email.required,
    phone.error,
    phone.value,
    phone.required,
    dob.error,
    dob.value,
    dob.required,
    onDateChange,
    currencyOptions,
    currency.value,
    currency.required,
    currency.error,
    academyCoursesOptions,
    course.value,
    course.required,
    course.error,
    source.value,
    source.required,
    source.error,
    otherSource.error,
    otherSource.value,
    otherSource.required,
    city.error,
    city.value,
    city.required,
    state.error,
    state.value,
    state.required,
    countryOptions,
    country.value,
    country.required,
    country.error,
    graduationYear.error,
    graduationYear.value,
    graduationYear.required,
    graduationDegree.value,
    graduationDegree.required,
    graduationDegree.error,
    graduationMajor.value,
    graduationMajor.required,
    graduationMajor.error,
    otherDegree.error,
    otherDegree.value,
    otherDegree.required,
    linkedInURL.error,
    linkedInURL.value,
    linkedInURL.required,
  ]);

  const renderSalesDetails = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Source Contact Date'
            type={"date"}
            width='48%'
            error={sourceContactDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "sourceContactDate",
              value: sourceContactDate.value,
              required: sourceContactDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='Sale Date'
            type={"date"}
            width='48%'
            error={saleDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "saleDate",
              value: saleDate.value,
              required: saleDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"admissionCounsellor"}
            label={"Admission Counsellor"}
            options={employeesOptions}
            value={admissionCounsellor.value}
            onChange={onTextChange}
            mandatory={admissionCounsellor.required}
            error={admissionCounsellor.error}
          />
          <Select
            width={"48%"}
            name={"enrollmentSpecialists"}
            label={"Enrollment Specialists"}
            options={employeesOptions}
            value={enrollmentSpecialists.value}
            onChange={onTextChange}
            mandatory={enrollmentSpecialists.required}
            error={enrollmentSpecialists.error}
          />
        </Row>
        <Row>
          <TextInput
            label={"Total Fees"}
            type={"text"}
            width='48%'
            error={totalFees.error}
            inputProps={{
              type: "number",
              name: "totalFees",
              value: totalFees.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: totalFees.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"paymentTerms"}
            label={"Payment Terms"}
            options={getAttributValues("academyPaymentTerms")}
            value={paymentTerms.value}
            onChange={onTextChange}
            mandatory={paymentTerms.required}
            error={paymentTerms.error}
          />
        </Row>
        <TextInput
          label={"Any Discounts Given"}
          type={"text"}
          width='48%'
          error={anyDiscountsGiven.error}
          inputProps={{
            type: "number",
            name: "anyDiscountsGiven",
            value: anyDiscountsGiven.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: anyDiscountsGiven.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    sourceContactDate.error,
    sourceContactDate.value,
    sourceContactDate.required,
    onDateChange,
    saleDate.error,
    saleDate.value,
    saleDate.required,
    employeesOptions,
    admissionCounsellor.value,
    admissionCounsellor.required,
    admissionCounsellor.error,
    onTextChange,
    enrollmentSpecialists.value,
    enrollmentSpecialists.required,
    enrollmentSpecialists.error,
    totalFees.error,
    totalFees.value,
    totalFees.required,
    paymentTerms.value,
    paymentTerms.required,
    paymentTerms.error,
    anyDiscountsGiven.error,
    anyDiscountsGiven.value,
    anyDiscountsGiven.required,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.academyCandidateLeads.name} recordId={params.id} />
    ),
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.academyCandidateLeads.name} />,
    [params.id],
  );

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const renderSystemData = useCallback(() => {
    const createdBy =
      typeof currentAcademyCandidateLeads?.createdBy !== "string"
        ? currentAcademyCandidateLeads?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentAcademyCandidateLeads?.updatedBy !== "string"
        ? currentAcademyCandidateLeads?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentAcademyCandidateLeads?.createdAt}
        updatedAt={currentAcademyCandidateLeads?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentAcademyCandidateLeads?.createdAt,
    currentAcademyCandidateLeads?.createdBy,
    currentAcademyCandidateLeads?.updatedAt,
    currentAcademyCandidateLeads?.updatedBy,
  ]);

  const relatedCourse = useMemo(() => {
    return (
      <AcademyCoursesList
        relatedList={{
          type: "_id",
          value: course.value,
        }}
      />
    );
  }, [course.value]);

  const relatedContact = useMemo(() => {
    return (
      <AcademyCandidatesList
        relatedList={{
          type: "_id",
          value: associatedContact.value,
        }}
      />
    );
  }, [associatedContact.value]);

  const relatedEnrolments = useMemo(() => {
    return (
      <AcademyCandidateEnrollmentsList
        relatedList={{
          type: "candidateLead",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const relatedACH = useMemo(() => {
    return (
      <AcademyACHList
        relatedList={{
          type: "associatedCandidateLeads",
          value: params.id,
        }}
      />
    );
  }, [params.id]);

  const renderRelatedTasks = useMemo(() => {
    return (
      <RelatedListContainer>
        <TaskList
          relatedList={{
            type: "associatedCandidateLead",
            value: params.id,
            associatedTo: "ach",
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderSalesDetails();
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs();
      case 5:
        return renderSystemData();
      case 6:
        return relatedCourse;
      case 7:
        return relatedContact;
      case 8:
        return relatedEnrolments;
      case 9:
        return relatedACH;
      case 10:
        return renderRelatedTasks;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    relatedCourse,
    renderActivitylogs,
    renderOverview,
    renderSalesDetails,
    renderSystemData,
    relatedContact,
    renderWorklogs,
    relatedEnrolments,
    relatedACH,
    renderRelatedTasks,
    renderChecklist,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
