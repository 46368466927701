import React from "react";

import { Layout } from "../../../presentationals";
import { APEmployeesPOD } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return <Layout activity={<APEmployeesPOD />} admin={true} layout={"2"} />;
};

export default Route;
