import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { ITNRFPControllerProps } from "../../../../types/models/ITNRFP";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useITNRFPController } from "./hooks";

const Controller = ({ type }: ITNRFPControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { countryOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchITNRFP,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useITNRFPController({ type, id, attachmentsPickerRef });
  const currentITNRFP = data;

  const {
    workLogs,
    attachments,
    fullName,
    jobTitle,
    businessEmail,
    phone,
    country,
    companyName,
    industry,
    annualRevenue,
    description,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchITNRFP();
    }
    // Do not include fetchITNRFP to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Full Name'
            type={"text"}
            width='48%'
            error={fullName.error}
            inputProps={{
              name: "fullName",
              value: fullName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: fullName.required,
            }}
          />
          <TextInput
            label='Job Title'
            type={"text"}
            width='48%'
            error={jobTitle.error}
            inputProps={{
              name: "jobTitle",
              value: jobTitle.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: jobTitle.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Business Email'
            type={"text"}
            width='48%'
            error={businessEmail.error}
            inputProps={{
              name: "businessEmail",
              value: businessEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: businessEmail.required,
            }}
          />
          <TextInput
            label='Phone'
            type={"text"}
            width='48%'
            error={phone.error}
            inputProps={{
              name: "phone",
              value: phone.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: phone.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"country"}
            label={"Country"}
            options={countryOptions}
            value={country.value}
            onChange={onTextChange}
            mandatory={country.required}
            error={country.error}
          />
          <TextInput
            label='Comapny Name'
            type={"text"}
            width='48%'
            error={companyName.error}
            inputProps={{
              name: "companyName",
              value: companyName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: companyName.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Industry'
            type={"text"}
            width='48%'
            error={industry.error}
            inputProps={{
              name: "industry",
              value: industry.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: industry.required,
            }}
          />
          <TextInput
            label='Annual Revenue'
            type={"text"}
            width='48%'
            error={annualRevenue.error}
            inputProps={{
              name: "annualRevenue",
              value: annualRevenue.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: annualRevenue.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Description'
            type={"textarea"}
            width='100%'
            error={description.error}
            textAreaProps={{
              name: "description",
              rows: 8,
              value: description.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: description.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    annualRevenue.error,
    annualRevenue.required,
    annualRevenue.value,
    businessEmail.error,
    businessEmail.required,
    businessEmail.value,
    companyName.error,
    companyName.required,
    companyName.value,
    country.error,
    country.required,
    country.value,
    countryOptions,
    description.error,
    description.required,
    description.value,
    fullName.error,
    fullName.required,
    fullName.value,
    industry.error,
    industry.required,
    industry.value,
    jobTitle.error,
    jobTitle.required,
    jobTitle.value,
    onTextChange,
    phone.error,
    phone.required,
    phone.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentITNRFP?.createdBy !== "string" ? currentITNRFP?.createdBy?.name : "";
    const updatedBy =
      typeof currentITNRFP?.updatedBy !== "string" ? currentITNRFP?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentITNRFP?.createdAt}
        updatedAt={currentITNRFP?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentITNRFP?.createdAt,
    currentITNRFP?.createdBy,
    currentITNRFP?.updatedAt,
    currentITNRFP?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.itnRFP.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.itnRFP.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderActivitylogs,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const ITNRFPController = memo(Controller);
