import React from "react";

import Loader from "../../../../../components/Loader/loader";
import { MomentDate } from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import { Header, Table, TableHeader } from "../../../../components";
import { useJobPostingConfigs } from "../hooks";

import { MainContainer } from "./styles";

export const MediaListing = () => {
  const { filteredData, store, onClickAdd, onClickFilter, onClickRow } = useJobPostingConfigs();

  const columns = [
    {
      title: "Serial",
      width: "5%",
      key: "serial",
    },

    {
      title: "Name",
      width: "25%",
      key: "name",
    },
    {
      title: "Active",
      width: "10%",
      key: "active",
      render: (item) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Created On",
      width: "15%",
      render: (item) => {
        return (
          <div className='title'>{MomentDate({ date: item.createdAt, format: "YYYY-MM-DD" })}</div>
        );
      },
    },
    {
      title: "Created On",
      width: "15%",
      render: (item) => {
        return <div className='title'>{item.createdBy.name}</div>;
      },
    },
    {
      title: "Updated On",
      width: "15%",
      render: (item) => {
        return (
          <div className='title'>{MomentDate({ date: item.updatedAt, format: "YYYY-MM-DD" })}</div>
        );
      },
    },
    {
      title: "Updated By",
      width: "15%",
      render: (item) => {
        return <div className='title'>{item.updatedBy.name}</div>;
      },
    },
  ];
  return (
    <MainContainer>
      <Header title={strings.headerTitle.media} />
      <TableHeader
        rightButton={store.permissions.media_add && strings.common.add}
        rightButtonPress={onClickAdd}
        rightButtonWidth='69px'
        filterButton={strings.common.filter}
        filterButtonPress={onClickFilter}
      />
      {store.loading ? (
        <Loader />
      ) : (
        <>
          <Table
            data={filteredData()}
            columns={columns}
            page={1}
            pageSize={10}
            onClickTableRow={onClickRow}
          />
        </>
      )}
    </MainContainer>
  );
};
