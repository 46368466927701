import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAddressHistoryColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    associatedEmployee: {
      title: "Employee",
      sortSupported: true,
      key: "associatedEmployee",
      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    country: {
      title: "Country",
      key: "country",
      render: (item) => {
        return <text className='title'>{item.country?.name || "-"}</text>;
      },
    },
    postalCode: { title: "Postal Code", sortSupported: true, key: "postalCode" },
    fromDate: {
      title: "From Date",
      sortSupported: true,
      key: "fromDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {item.fromDate
              ? MomentDate({ date: item.fromDate, format: "YYYY-MM-DD HH:mm" })
              : "-"}{" "}
          </text>
        );
      },
    },
    toDate: {
      title: "To Date",
      sortSupported: true,
      key: "toDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {item.toDate ? MomentDate({ date: item.toDate, format: "YYYY-MM-DD HH:mm" }) : "-"}{" "}
          </text>
        );
      },
    },
    currentlyLives: {
      title: "Currently Lives",
      sortSupported: true,
      key: "Currently Lives",
      render: (item) => {
        return <text className='title'>{item?.currentlyLives?.name || "-"}</text>;
      },
    },
    addressLineOne: {
      title: "Address Line 1",
      sortSupported: true,
      key: "addressLineOne",
    },
    addressLineTwo: {
      title: "Address Line 2",
      sortSupported: true,
      key: "addressLineTwo",
    },
    city: {
      title: "City",
      sortSupported: true,
      key: "city",
    },
    state: {
      title: "State/Province",
      sortSupported: true,
      key: "state",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
