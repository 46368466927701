import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .count-widget-skeleton {
    height: 100%;
    margin-top: 2px;
    width: 100%;
  }
  .table-list-skeleton {
    height: 40px;
    margin-top: 2px;
    width: 100%;
  }

  .table-pagination-skeleton {
    height: 40px;
    margin-top: 16px;
    width: 100%;
  }
`;
