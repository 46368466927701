import styled from "styled-components";

import { colors, fs } from "../../../../theme";

interface Props {
  loading?: boolean;
  active?: boolean;
  noBorder?: boolean;
  lastStep?: boolean;
  absolute?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  width: 100%;
  flex-direction: column;
  display: flex;
  opacity: ${(props) => (props.loading ? "0.6" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "all")};
  .ep-image-row {
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background-color: green; */
`;

export const FormContainer = styled.div<Props>`
  display: flex;
  height: 630px;
  /* height: 65vh; */
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const ProfileImageContainer = styled.div`
  height: 229px;
  width: 229px;
  justify-content: center;
  position: relative;
  display: flex;
  margin-bottom: 20px;
`;

export const ProfileImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 20rem;
  border: solid 1px #65c08e;
  object-fit: cover;
`;

export const PencilIcon = styled.img`
  height: 36px;
  width: 36px;
  object-fit: cover;
  position: absolute;
  bottom: -14px;
  cursor: pointer;
`;

export const UserNameInitial = styled.div`
  height: 229px;
  width: 229px;
  border-radius: 20rem;
  border: solid 1px #65c08e;
  background-color: ${colors.common.white};
  border: 0.6px solid black;
  text-transform: capitalize;
  font-size: 20rem;
  font-weight: bold;
  color: ${colors.common.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  min-height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-bottom: ${(props) => (props.lastStep ? "35px" : "0")};
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const RenderContainer = styled.div`
  width: 100%;
`;

export const OverViewRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const FormTitle = styled.div<Props>`
  line-height: 22px;
  font-weight: 400;
  font-size: ${fs.m};
  margin-bottom: 20px;
  position: ${(props) => (props.absolute ? "absolute" : "relative")};
`;

export const RelatedListContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SalaryModalContainer = styled.div`
  display: flex;
  padding: 30px;
  width: 40vw;
  flex-direction: column;
  background-color: ${colors.common.white};
  .employee-modal-save-button {
    margin-top: 20px;
    width: fit-content;
  }
`;

export const SalaryFieldsContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 20vh;
  z-index: 10;
`;

export const OldEmployeeModal = styled.div`
  display: flex;
  height: 95vh;
  width: 95vw;
  padding: 1%;
  flex-direction: column;
`;

export const OldEmployeeContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const OldEmployeeKey = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 20%;
  border: 0.6px solid ${colors.common.borderColor};
  font-size: 14px;
  padding-left: 10px;
  margin-right: 10px;
`;

export const OldEmployeeValue = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 80%;
  padding: 0px 20px;
  border: 0.6px solid ${colors.common.borderColor};
  font-size: 14px;
`;

export const TimeLineDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
`;

export const OldDesignationsItem = styled.div`
  /* width: 100%; */
  margin: 20px;
  border: 1px solid green;
`;
