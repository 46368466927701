import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getEmploymentHistoryColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    associatedEmployee: {
      title: "Employee",
      sortSupported: true,
      key: "associatedEmployee",
      render: (item) => {
        return <text className='title'>{item?.associatedEmployee?.name || "-"}</text>;
      },
    },
    employmentType: {
      title: "Employment Type",
      key: "employmentType",
      center: true,
      render: (item) => {
        return <text className='title'>{item.employmentType?.value || "-"}</text>;
      },
    },
    country: {
      title: "Country",
      key: "country",
      center: true,
      render: (item) => {
        return <text className='title'>{item.country?.name || "-"}</text>;
      },
    },
    currentlyWorking: {
      title: "Currently Working",
      key: "currentlyWorking",
      center: true,
      render: (item) => {
        return <text className='title'>{item.currentlyWorking ? "Yes" : "No"}</text>;
      },
    },
    startDate: {
      title: "Start Date",
      sortSupported: true,
      key: "startDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {item.startDate
              ? MomentDate({ date: item.startDate, format: "YYYY-MM-DD HH:mm" })
              : "-"}{" "}
          </text>
        );
      },
    },
    endDate: {
      title: "End Date",
      sortSupported: true,
      key: "endDate",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {item.endDate
              ? MomentDate({ date: item.endDate, format: "YYYY-MM-DD HH:mm" })
              : "-"}{" "}
          </text>
        );
      },
    },
    permissionToContact: {
      title: "Permission To Contact",
      key: "permissionToContact",
      center: true,
      render: (item) => {
        return <text className='title'>{item.permissionToContact ? "Yes" : "No"}</text>;
      },
    },
    permissionToContactReason: {
      title: "Permission To Contact Reason",
      key: "permissionToContactReason",
    },
    employerName: {
      title: "Employer Name",
      key: "employerName",
      render: (item) => {
        return <text className='title'>{item?.address?.employerName || "-"}</text>;
      },
    },
    addressLineOne: {
      title: "Address Line 1",
      key: "addressLineOne",
      render: (item) => {
        return <text className='title'>{item?.address?.addressLineOne || "-"}</text>;
      },
    },
    addressLineTwo: {
      title: "Address Line 2",
      key: "addressLineTwo",
      render: (item) => {
        return <text className='title'>{item?.address?.addressLineTwo || "-"}</text>;
      },
    },
    state: {
      title: "State/Province",
      key: "state",
      render: (item) => {
        return <text className='title'>{item?.address?.state || "-"}</text>;
      },
    },
    city: {
      title: "City",
      key: "city",
      render: (item) => {
        return <text className='title'>{item?.address?.city || "-"}</text>;
      },
    },
    postalCode: {
      title: "Postal Zone/ZIP Code",
      key: "postalCode",
      render: (item) => {
        return <text className='title'>{item?.address?.postalCode || "-"}</text>;
      },
    },
    phone: {
      title: "Phone",
      key: "phone",
      render: (item) => {
        return <text className='title'>{item?.address?.phone || "-"}</text>;
      },
    },
    extension: {
      title: "Extension",
      key: "extension",
      render: (item) => {
        return <text className='title'>{item?.address?.extension || "-"}</text>;
      },
    },
    jobType: {
      title: "Job Type",
      key: "jobType",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.jobType?.value || "-"}</text>;
      },
    },
    releventExperience: {
      title: "Relevent Experience",
      key: "releventExperience",
      center: true,
      render: (item) => {
        return (
          <text className='title'>
            {item?.employmentJobDetails?.releventExperience ? "Yes" : "No"}
          </text>
        );
      },
    },
    isAgency: {
      title: "Is Agency",
      key: "isAgency",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.isAgency ? "Yes" : "No"}</text>;
      },
    },
    jobTitle: {
      title: "Job Title",
      key: "jobTitle",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.jobTitle || "-"}</text>;
      },
    },
    department: {
      title: "Department",
      key: "department",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.department?.name || "-"}</text>;
      },
    },
    supervisorName: {
      title: "Supervisor Name",
      key: "supervisorName",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.supervisorName || "-"}</text>;
      },
    },
    supervisorTitle: {
      title: "Supervisor Title",
      key: "supervisorTitle",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.supervisorTitle || "-"}</text>;
      },
    },
    supervisorEmail: {
      title: "Supervisor Email",
      key: "supervisorEmail",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.supervisorEmail || "-"}</text>;
      },
    },
    supervisorPhone: {
      title: "Supervisor Phone",
      key: "supervisorPhone",
      center: true,
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.supervisorPhone || "-"}</text>;
      },
    },
    reasonForLeaving: {
      title: "Reason For Leaving",
      key: "reasonForLeaving",
      render: (item) => {
        return <text className='title'>{item?.employmentJobDetails?.reasonForLeaving || "-"}</text>;
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {" "}
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}{" "}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
