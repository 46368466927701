import Types from "./types";

export const doLogin = ({ email, password, onSuccess, onError }) => ({
  type: Types.USER_LOGIN,
  payload: {
    email: email,
    password: password,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const doSSO = ({ access_token, onSuccess, onError }) => ({
  type: Types.USER_LOGIN_SSO,
  payload: {
    access_token: access_token,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const doLoginSuccess = (payload) => ({
  type: Types.USER_LOGIN_SUCCESS,
  payload: payload,
});

export const doLogout = (payload) => ({
  type: Types.USER_LOGOUT,
  payload: payload,
});

export const doLogoutSuccess = (payload) => ({
  type: Types.USER_LOGOUT_SUCCESS,
  payload: payload,
});

export const doReset = ({ email, token, password, onSuccess, onError }) => ({
  type: Types.USER_RESET,
  payload: {
    email: email,
    token: token,
    password: password,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const doResetRequest = ({ email, onSuccess, onError }) => ({
  type: Types.USER_RESET_REQUEST,
  payload: {
    email: email,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const getProfile = (payload) => ({
  type: Types.USER_PROFILE,
  payload: payload,
});

export const getProfileSuccess = (payload) => ({
  type: Types.USER_PROFILE_SUCCESS,
  payload: payload,
});

export const updateProfile = ({ timezone, password, onSuccess, onError, profileImage }) => ({
  type: Types.USER_PROFILE_UPDATE,
  payload: {
    timezone,
    password: password,
    onSuccess: onSuccess,
    onError: onError,
    profileImage: profileImage,
  },
});

export const updateTableConfigs = ({ tableConfiguration, onSuccess, onError }) => ({
  type: Types.USER_UPDATE_TABLE_CONFIGS,
  payload: {
    tableConfiguration,
    onSuccess,
    onError,
  },
});

export const updateOrganisation = ({ organisation, employee }) => ({
  type: Types.USER_UPDATE_ORGANISATION,
  payload: { organisation, employee },
});

export const updateOrganisationSuccess = () => ({
  type: Types.USER_UPDATE_ORGANISATION_SUCCESS,
  payload: {},
});
