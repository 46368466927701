import type { Field, RuleType } from "react-querybuilder";
import { defaultOperators } from "react-querybuilder";

export const validator = (r: RuleType) => !!r.value;

export const userFilterFields: Field[] = [
  {
    name: "name",
    label: "Name",
    placeholder: "Enter name",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "email",
    label: "Email",
    placeholder: "Enter email",
    defaultOperator: "contains",
    validator,
  },
  {
    name: "active",
    label: "Active",
    valueEditorType: "checkbox",
    operators: defaultOperators.filter((op) => op.name === "="),
    defaultValue: false,
  },
  //   { name: "age", label: "Age", inputType: "number", validator },
  //   {
  //     name: "admin",
  //     label: "Is admin",
  //     valueEditorType: "checkbox",
  //     operators: defaultOperators.filter((op) => op.name === "="),
  //     defaultValue: false,
  //   },
  {
    name: "superAdmin",
    label: "Is unity admin",
    valueEditorType: "checkbox",
    operators: defaultOperators.filter((op) => op.name === "="),
    defaultValue: false,
  },
  //   {
  //     name: "gender",
  //     label: "Gender",
  //     operators: defaultOperators.filter((op) => op.name === "="),
  //     valueEditorType: "radio",
  //     values: [
  //       { name: "M", label: "Male" },
  //       { name: "F", label: "Female" },
  //       { name: "O", label: "Other" },
  //     ],
  //   },
  //   { name: "height", label: "Height", validator },
  //   { name: "job", label: "Job", validator },
  //   { name: "description", label: "Description", valueEditorType: "textarea" },
  //   { name: "birthdate", label: "Birth Date", inputType: "date" },
  //   { name: "datetime", label: "Show Time", inputType: "datetime-local" },
  //   { name: "alarm", label: "Daily Alarm", inputType: "time" },
  //   {
  //     name: "groupedField1",
  //     label: "Grouped Field 1",
  //     comparator: "groupNumber",
  //     groupNumber: "group1",
  //     valueSources: ["field", "value"],
  //   },
  //   {
  //     name: "groupedField2",
  //     label: "Grouped Field 2",
  //     comparator: "groupNumber",
  //     groupNumber: "group1",
  //     valueSources: ["field", "value"],
  //   },
  //   {
  //     name: "groupedField3",
  //     label: "Grouped Field 3",
  //     comparator: "groupNumber",
  //     groupNumber: "group1",
  //     valueSources: ["field", "value"],
  //   },
  //   {
  //     name: "groupedField4",
  //     label: "Grouped Field 4",
  //     comparator: "groupNumber",
  //     groupNumber: "group1",
  //     valueSources: ["field", "value"],
  //   },
];
