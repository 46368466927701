import React, { useCallback, useEffect, useRef, useMemo } from "react";

import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { getAttributValues } from "../../../../services/methods";
import { MomentDate } from "../../../../services/methods";
import { DesignationsControllerProps } from "../../../../types/models/Designations";
import { ActiveSelectOptions, collections } from "../../../../utils/constants";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { RelatedListContainer } from "../Departments/styles";
import { EmployeesListing } from "../Employees";

import { useDesignationController } from "./hooks";
import { Row } from "./styles";

export const DesignationsController = ({ type }: DesignationsControllerProps) => {
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const params = useParams();
  const steps = ["Overview"];
  const {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchDesignations,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    data,
    departmentOptions,
    headerButtons,
  } = useDesignationController({ attachmentsPickerRef, type, id: params.id });
  const currentDesignation = data;
  if (type === "edit") {
    steps.push("System Data");
  }

  const { workLogs, attachments, name, active, department } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchDesignations();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
  }, [params.id, type]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Name"}
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"department"}
          label={"Department"}
          options={departmentOptions}
          value={department.value}
          onChange={onTextChange}
          mandatory={department.required}
          error={department.error}
        />
      </>
    );
  }, [
    active.error,
    active.required,
    active.value,
    department.error,
    department.required,
    department.value,
    departmentOptions,
    name.error,
    name.required,
    name.value,
    onTextChange,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.designations.name} recordId={params.id} />,
    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.designations.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );
  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentDesignation?.createdBy !== "string" ? currentDesignation?.createdBy?.name : "";
    const updatedBy =
      typeof currentDesignation?.updatedBy !== "string" ? currentDesignation?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentDesignation?.createdAt}
        updatedAt={currentDesignation?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentDesignation?.createdAt,
    currentDesignation?.createdBy,
    currentDesignation?.updatedAt,
    currentDesignation?.updatedBy,
  ]);
  const renderRelatedEmployees = useMemo(() => {
    return (
      <RelatedListContainer>
        <EmployeesListing
          relatedList={{
            type: "designation",
            value: params.id,
          }}
        />
      </RelatedListContainer>
    );
  }, [params.id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderRelatedEmployees;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
    renderRelatedEmployees,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
