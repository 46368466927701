/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ATTRIBUTE = "FETCH_ATTRIBUTE",
  SUCCESS_FETCH_ATTRIBUTE = "SUCCESS_FETCH_ATTRIBUTE",
  ERROR_FETCH_ATTRIBUTE = "ERROR_FETCH_ATTRIBUTE",
  CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE",
  UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE",
  FETCH_ATTRIBUTE_BY_ID = "FETCH_ATTRIBUTE_BY_ID",
}

export default Types;
