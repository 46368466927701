import Types from "./actionType";
import {
  FetchAcademyMentorFeedbackPayload,
  CreateAcademyMentorFeedback,
  CreateAcademyMentorFeedbackPayload,
  FetchAcademyMentorFeedback,
  FetchAcademyMentorFeedbackError,
  FetchAcademyMentorFeedbackSuccess,
  UpdateAcademyMentorFeedback,
  UpdateAcademyMentorFeedbackPayload,
  AcademyMentorFeedbackListWithMetaData,
  FetchAcademyMentorFeedbackByIdPayload,
  FetchAcademyMentorFeedbackById,
  DeleteAcademyMentorFeedbackPayload,
  DeleteAcademyMentorFeedback,
} from "./types";

export const fetchAcademyMentorFeedbackList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyMentorFeedbackPayload): FetchAcademyMentorFeedback => ({
  type: Types.FETCH_ACADEMY_MENTOR_FEEDBACK,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyMentorFeedbackById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyMentorFeedbackByIdPayload): FetchAcademyMentorFeedbackById => ({
  type: Types.FETCH_ACADEMY_MENTOR_FEEDBACK_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyMentorFeedbackListSuccess = (
  data: AcademyMentorFeedbackListWithMetaData,
): FetchAcademyMentorFeedbackSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_MENTOR_FEEDBACK,
  payload: {
    data,
  },
});

export const fetchAcademyMentorFeedbackError = (
  error?: string,
): FetchAcademyMentorFeedbackError => ({
  type: Types.ERROR_FETCH_ACADEMY_MENTOR_FEEDBACK,
  payload: { error },
});

export const createAcademyMentorFeedback = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyMentorFeedbackPayload): CreateAcademyMentorFeedback => ({
  type: Types.CREATE_ACADEMY_MENTOR_FEEDBACK,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyMentorFeedback = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyMentorFeedbackPayload): UpdateAcademyMentorFeedback => ({
  type: Types.UPDATE_ACADEMY_MENTOR_FEEDBACK,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAcademyMentorFeedback = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyMentorFeedbackPayload): DeleteAcademyMentorFeedback => ({
  type: Types.DELETE_ACADEMY_MENTOR_FEEDBACK,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
