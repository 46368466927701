import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  EmailSchedularList,
  AddEmailSchedular,
  UpdateEmailSchedular,
  FindEmailSchedular,
  DeleteEmailSchedular,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchEmailSchedularListSuccess, fetchEmailSchedularError } from "./actions";
import Types from "./actionType";
import {
  CreateEmailSchedularuccess,
  CreateEmailSchedular as CreateEmailSchedularType,
  EmailSchedularSuccess,
  UpdateEmailSchedular as UpdateEmailSchedularType,
  FetchEmailSchedular,
  FetchEmailSchedularById,
  EmailSchedularByIdSuccess,
  DeleteEmailSchedular as DeleteEmailSchedularType,
} from "./types";

function* createWorker(action: CreateEmailSchedularType) {
  try {
    const options = {
      method: "POST",
      url: AddEmailSchedular,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateEmailSchedularuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateEmailSchedularType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateEmailSchedular(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateEmailSchedularuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchEmailSchedular) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: EmailSchedularList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<EmailSchedularSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchEmailSchedularListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchEmailSchedularError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Email Schedular");
    }
  }
}

function* fetchEmailSchedularById(action: FetchEmailSchedularById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindEmailSchedular(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<EmailSchedularByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Email Schedular");
    }
  }
}

function* deleteWorker(action: DeleteEmailSchedularType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteEmailSchedular(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateEmailSchedularuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_EMAIL_SCHEDULAR, createWorker);
  yield takeLatest(Types.UPDATE_EMAIL_SCHEDULAR, updateWorker);
  yield takeEvery(Types.FETCH_EMAIL_SCHEDULAR, fetchAllWorker);
  yield takeEvery(Types.FETCH_EMAIL_SCHEDULAR_BY_ID, fetchEmailSchedularById);
  yield takeEvery(Types.DELETE_EMAIL_SCHEDULAR, deleteWorker);
}
