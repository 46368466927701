import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { SPSAccountContactControllerProps } from "../../../../types/models/SPSAccountContact";
import { collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SPSAccountsList } from "../SPSAccounts/SPSAccountsList";

import { useSPSAccountContactController } from "./hooks";

const Controller = ({ type }: SPSAccountContactControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { spsAccountsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchSPSAccountContact,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useSPSAccountContactController({ type, id, attachmentsPickerRef });
  const currentSPSAccountContact = data;

  const {
    workLogs,
    attachments,
    associatedAccount,
    firstName,
    lastName,
    role,
    email,
    alternativeEmail,
    mobile,
    alternativeMobile,
    company,
    status,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchSPSAccountContact();
    }
    // Do not include fetchSPSAccountContact to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='FirstName'
            type={"text"}
            width='48%'
            error={firstName.error}
            inputProps={{
              name: "firstName",
              value: firstName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: firstName.required,
            }}
          />
          <TextInput
            label='LastName'
            type={"text"}
            width='48%'
            error={lastName.error}
            inputProps={{
              name: "lastName",
              value: lastName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: lastName.required,
            }}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"associatedAccount"}
            label={"SPS Account"}
            options={spsAccountsOptions}
            value={associatedAccount.value}
            onChange={onTextChange}
            mandatory={associatedAccount.required}
            error={associatedAccount.error}
          />
          <Select
            width={"48%"}
            name={"status"}
            label={"Status"}
            options={getAttributValues("spsAccountContact")}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Role'
            type={"text"}
            width='48%'
            error={role.error}
            inputProps={{
              name: "role",
              value: role.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: role.required,
            }}
          />
          <TextInput
            label='Email'
            type={"text"}
            width='48%'
            error={email.error}
            inputProps={{
              name: "email",
              value: email.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: email.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Alternative Email'
            type={"text"}
            width='48%'
            error={alternativeEmail.error}
            inputProps={{
              name: "alternativeEmail",
              value: alternativeEmail.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: alternativeEmail.required,
            }}
          />
          <TextInput
            label='Mobile'
            type={"text"}
            width='48%'
            error={mobile.error}
            inputProps={{
              name: "mobile",
              value: mobile.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: mobile.required,
            }}
          />
        </Row>
        <Row>
          <TextInput
            label='Alternative Mobile'
            type={"text"}
            width='48%'
            error={alternativeMobile.error}
            inputProps={{
              name: "alternativeMobile",
              value: alternativeMobile.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: alternativeMobile.required,
            }}
          />
          <TextInput
            label='Company'
            type={"text"}
            width='48%'
            error={company.error}
            inputProps={{
              name: "company",
              value: company.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: company.required,
            }}
          />
        </Row>
      </React.Fragment>
    );
  }, [
    alternativeEmail.error,
    alternativeEmail.required,
    alternativeEmail.value,
    alternativeMobile.error,
    alternativeMobile.required,
    alternativeMobile.value,
    associatedAccount.error,
    associatedAccount.required,
    associatedAccount.value,
    company.error,
    company.required,
    company.value,
    email.error,
    email.required,
    email.value,
    firstName.error,
    firstName.required,
    firstName.value,
    lastName.error,
    lastName.required,
    lastName.value,
    mobile.error,
    mobile.required,
    mobile.value,
    onTextChange,
    role.error,
    role.required,
    role.value,
    spsAccountsOptions,
    status.error,
    status.required,
    status.value,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentSPSAccountContact?.createdBy !== "string"
        ? currentSPSAccountContact?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentSPSAccountContact?.updatedBy !== "string"
        ? currentSPSAccountContact?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentSPSAccountContact?.createdAt}
        updatedAt={currentSPSAccountContact?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentSPSAccountContact?.createdAt,
    currentSPSAccountContact?.createdBy,
    currentSPSAccountContact?.updatedAt,
    currentSPSAccountContact?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.spsAccountContact.name} recordId={id} />,

    [id],
  );
  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.spsAccountContact.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  // Related Lists
  const renderSPSAccounts = useMemo(
    () => (
      <SPSAccountsList
        relatedList={{
          type: "_id",
          value:
            (typeof currentSPSAccountContact.associatedAccount !== "string" &&
              currentSPSAccountContact.associatedAccount?._id) ||
            "",
        }}
      />
    ),
    [currentSPSAccountContact.associatedAccount],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderSPSAccounts;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderSPSAccounts,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const SPSAccountContactController = memo(Controller);
