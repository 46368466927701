import Types from "./actionType";
import {
  FetchSubscriberPayload,
  CreateSubscriber,
  CreateSubscriberPayload,
  FetchSubscriber,
  FetchSubscriberError,
  FetchSubscriberSuccess,
  UpdateSubscriber,
  UpdateSubscriberPayload,
  SubscriberListWithMetaData,
  FetchSubscriberByIdPayload,
  FetchSubscriberById,
  DeleteSubscriberPayload,
  DeleteSubscriber,
} from "./types";

export const fetchSubscriberList = ({
  params,
  onSuccess,
  onError,
}: FetchSubscriberPayload): FetchSubscriber => ({
  type: Types.FETCH_SUBSCRIBER,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSubscriberById = ({
  id,
  onSuccess,
  onError,
}: FetchSubscriberByIdPayload): FetchSubscriberById => ({
  type: Types.FETCH_SUBSCRIBER_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSubscriberListSuccess = (
  data: SubscriberListWithMetaData,
): FetchSubscriberSuccess => ({
  type: Types.SUCCESS_FETCH_SUBSCRIBER,
  payload: {
    data,
  },
});

export const fetchSubscriberError = (error?: string): FetchSubscriberError => ({
  type: Types.ERROR_FETCH_SUBSCRIBER,
  payload: { error },
});

export const createSubscriber = ({
  data,
  onSuccess,
  onError,
}: CreateSubscriberPayload): CreateSubscriber => ({
  type: Types.CREATE_SUBSCRIBER,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSubscriber = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSubscriberPayload): UpdateSubscriber => ({
  type: Types.UPDATE_SUBSCRIBER,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSubscriber = ({
  id,
  onSuccess,
  onError,
}: DeleteSubscriberPayload): DeleteSubscriber => ({
  type: Types.DELETE_SUBSCRIBER,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
