import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import Loader from "../../../../../components/Loader/loader";
import Toast from "../../../../../components/Toast/toast";
import { useAppSelector } from "../../../../../redux/hooks";
import { useTitle } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import { Header, TableHeader, TextInput } from "../../../../components";
import { useController } from "../hooks";

import {
  ActivePackageTick,
  Container,
  DisplayImageContainer,
  DisplayPicture,
  DisplayPictureEdit,
  FormFieldsContainer,
  Heading,
  HorizontalLine,
  LeftContainer,
  LoadingContainer,
  MainContainer,
  RightContainer,
  Row,
  SmallHorizontalLine,
  StepContainer,
  SubHeading,
  SubHeadingContainer,
  Table,
  TableBGGraphic,
  TableItem,
  TableItemDesc,
  TableItemTitle,
  TableRow,
  TempSwitch,
  ViewContainer,
} from "./styles";

interface defaultDataType {
  serial: { required: boolean; type: string; error: string; value: string };
  name: { required: boolean; type: string; error: string; value: string };
  mailFrom: { required: boolean; type: string; error: string; value: string };
  mailFromName: { required: boolean; type: string; error: string; value: string };
  active: { required: boolean; type: string; error: string; value: true };
  businessOwner: { required: boolean; type: string; error: string; value: string };
  orgImage: { required: boolean; type: string; error: string; value: string };
}
interface ManageOrganizationControllerProps {
  type: string;
}

export const ManageOrganizationController = (props: ManageOrganizationControllerProps) => {
  const params = useParams();
  const [id, setId] = useState("");
  useTitle("Organization");
  const imagePicker = useRef(null);

  const organizationList = useAppSelector((state) => state.OrganisationsReducer.list);
  const orgImg = useAppSelector((state) => state.UserReducer?.organisation?.orgImage?.location);

  useEffect(() => {
    if (props.type === "edit" && params.id !== id) {
      if (organizationList.length) {
        const find = organizationList.find((organisation: any) => organisation._id === params.id);
        if (find) {
          setId(find._id);
          HydrateData(find);
          setSubmitting(false);
          setToast({ message: "", message_type: "" });
        }
      }
    } else if (props.type === "add" && id !== "") {
      setId("");
      setData(defaultData);
      setPermissionData(defaultPermissionData);
      setSubmitting(false);
      setToast({ message: "", message_type: "" });
    }
  }, [organizationList, params.id, props.type]);

  const steps = ["Overview", "Packages"];

  const {
    currentStepIndex,
    goTo,
    onTextChange,
    data,
    HydrateData,
    setData,
    defaultData,
    defaultPermissionData,
    onPermissionChange,
    onSubmitAddForm,
    onSubmitUpdateForm,
    permissionData,
    setPermissionData,
    setSubmitting,
    submitting,
    setToast,
    toast,
    onFileChange,
    orgImage,
    setOrgImage,
  } = useController(steps, id);

  const invokeImagePicker = () => {
    imagePicker.current.click();
  };

  const renderOverView = () => {
    return (
      <ViewContainer>
        <Heading>Overview</Heading>
        <DisplayImageContainer>
          <DisplayPicture onClick={invokeImagePicker} src={orgImage ? orgImage : orgImg} />
          <DisplayPictureEdit onClick={invokeImagePicker} src={images.greenPencil} />
        </DisplayImageContainer>
        <input
          accept='image/*'
          hidden={true}
          onChange={onFileChange}
          name={strings.common.orgImage}
          ref={imagePicker}
          type={"file"}
        />
        <HorizontalLine />
        <Row>
          <TextInput
            inputProps={{
              name: "name",
              value: data.name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: true,
              placeholder: undefined,
              disabled: true,
            }}
            type='text'
            label={strings.common.fullName}
            error={data?.name?.error}
            width={"46%"}
          />
          <TextInput
            inputProps={{
              name: "mailFrom",
              value: data.mailFrom.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: true,
              placeholder: undefined,
            }}
            label={strings.common.emailForNotification}
            type={"email"}
            error={data?.mailFrom?.error}
            width={"46%"}
          />
        </Row>
        <Row>
          <TextInput
            inputProps={{
              name: "mailFromName",
              value: data.mailFromName.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: true,
            }}
            label={strings.common.nameForEmailNotification}
            type={"text"}
            error={data?.mailFromName?.error}
            width={"46%"}
          />
          <TextInput
            inputProps={{
              name: "businessOwner",
              value: data.businessOwner.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: true,
            }}
            label={strings.common.businessOwner}
            type={"text"}
            error={data?.businessOwner?.error}
            width={"46%"}
          />
        </Row>
      </ViewContainer>
    );
  };

  const renderPackages = () => {
    return (
      <ViewContainer>
        <Heading>{strings.apManageOrganizations.packages}</Heading>
        <SubHeadingContainer>
          <SubHeading>{strings.apManageOrganizations.activePackages}</SubHeading>
          <SmallHorizontalLine />
        </SubHeadingContainer>
        <Table>
          {Object.keys(permissionData).map((obj) => {
            if (permissionData[obj] === true) {
              return (
                <TableItem key={obj}>
                  <TableRow>
                    <div>
                      <ActivePackageTick src={images.activePackageTick} />
                      <TableItemTitle>{obj.toUpperCase()}</TableItemTitle>
                    </div>
                    <TableBGGraphic src={images.TableGraphic} />
                  </TableRow>
                  <TempSwitch src={images.Tempswitch} />
                </TableItem>
              );
            }
          })}
        </Table>
      </ViewContainer>
    );
  };
  return (
    <>
      <TableHeader
        rightButton={strings.common.save}
        rightButtonPress={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
      />
      <Container>
        <Header title={strings.headerTitle.organisationManage} />
        <LeftContainer>
          {submitting && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
          <Toast type={toast.message_type} message={toast.message} />
          <FormFieldsContainer>
            {currentStepIndex === 0 ? renderOverView() : renderPackages()}
          </FormFieldsContainer>
        </LeftContainer>
        <RightContainer>
          {steps.map((stepItem, stepIndex) => {
            return (
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {stepItem}
                <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
              </StepContainer>
            );
          })}
        </RightContainer>
      </Container>
    </>
  );
};
