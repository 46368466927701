import styled from "styled-components";

import { colors } from "../../../../../theme";

interface Props {
  loading?: boolean;
}
export const Container = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  opacity: ${(props) => (props.loading ? "0.4" : "1")};
  .related-modal-save-button {
    display: flex;
  }
`;

export const AddRelatedListModal = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.common.white};
  min-width: 30vw;
  flex-direction: column;
  row-gap: 20px;
`;
