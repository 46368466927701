import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { NewsletterResourcesControllerProps } from "../../../../types/models/NewsletterResources";
import { NewsletterResourcesStatus, collections, sendgridSenders } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, Popup, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData, TextEditor } from "../../../components/atoms";
import AttachmentInput from "../../../components/atoms/AttachmentInput/AttachmentInput";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useNewsletterResourcesController } from "./hooks";

// const window = new JSDOM("").window;
// const DOMPurify = createDOMPurify(window);

const Controller = ({ type }: NewsletterResourcesControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { usersOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchNewsletterResources,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    onSaveAttachment,
    deleteAttachment,
    onBodyChange,
    body,
    bodyPreview,
    handleBodyPreview,
  } = useNewsletterResourcesController({ type, id, attachmentsPickerRef });
  const currentNewsletterResources = data;

  const { workLogs, attachments, name, status, bodyType, subject, approvalBy, attachment, sender } =
    formData;

  useEffect(() => {
    if (type === "edit") {
      fetchNewsletterResources();
    }
    // Do not include fetchNewsletterResources to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"status"}
            label={"Status"}
            options={NewsletterResourcesStatus}
            value={status.value}
            onChange={onTextChange}
            mandatory={status.required}
            error={status.error}
          />
        </Row>
        <Row>
          {/* <AttachmentInput
            aclType={"public"}
            attachment={attachment}
            width='48%'
            handleDeleteAttachment={deleteAttachment}
            fieldName='attachment'
            label='Attachment'
            onSave={onSaveAttachment}
            accept='.pdf'
          /> */}{" "}
          <Select
            name={"sender"}
            label={"Sender"}
            options={sendgridSenders}
            value={sender.value}
            onChange={onTextChange}
            mandatory={sender.required}
            error={sender.error}
          />
          <Select
            name={"approvalBy"}
            label={"Approval By"}
            options={usersOptions}
            value={approvalBy.value}
            onChange={onTextChange}
            mandatory={approvalBy.required}
            error={approvalBy.error}
          />
        </Row>{" "}
        <TextInput
          label='Subject'
          type={"textarea"}
          width='100%'
          error={subject.error}
          textAreaProps={{
            name: "subject",
            rows: 3,
            value: subject.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: subject.required,
          }}
        />
        {/* <Select
          name={"bodyType"}
          width='100%'
          label={"Body Type"}
          options={[
            { label: "HTML Input", value: "html" },
            { label: "HTML Editor", value: "editor" },
          ]}
          value={bodyType.value}
          onChange={onTextChange}
          mandatory={bodyType.required}
          error={bodyType.error}
        /> */}
        {bodyType.value === "html" ? (
          <TextInput
            label='Body'
            type={"textarea"}
            width='100%'
            error={formData.body.error}
            textAreaProps={{
              name: "body",
              rows: 20,
              value: formData.body.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: formData.body.required,
            }}
          />
        ) : (
          <TextEditor error={""} value={body} onChange={onBodyChange} placeholder={"Body"} />
        )}
        {/*  */}
        <Popup isOpen={bodyPreview} closeModal={handleBodyPreview}>
          {/* <HTMLPreviewContainer> */}
          {/* <iframe
              id='liveFrame'
              sandbox='allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts'
              frameBorder='1'
            ></iframe> */}
          {/* <HTMLRenderer html={formData.body.value} /> */}
          {/* {renderHTML(formData.body.value)} */}
          <div
            dangerouslySetInnerHTML={{
              __html: bodyType.value === "html" ? formData.body.value : body,
            }}
          />
          {/* </HTMLPreviewContainer> */}
        </Popup>
      </React.Fragment>
    );
  }, [
    name.error,
    name.value,
    name.required,
    onTextChange,
    status.value,
    status.required,
    status.error,
    attachment,
    deleteAttachment,
    onSaveAttachment,
    usersOptions,
    approvalBy.value,
    approvalBy.required,
    approvalBy.error,
    subject.error,
    subject.value,
    subject.required,
    bodyType.value,
    bodyType.required,
    bodyType.error,
    formData.body.error,
    formData.body.value,
    formData.body.required,
    body,
    onBodyChange,
    bodyPreview,
    handleBodyPreview,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentNewsletterResources?.createdBy !== "string"
        ? currentNewsletterResources?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentNewsletterResources?.updatedBy !== "string"
        ? currentNewsletterResources?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentNewsletterResources?.createdAt}
        updatedAt={currentNewsletterResources?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentNewsletterResources?.createdAt,
    currentNewsletterResources?.createdBy,
    currentNewsletterResources?.updatedAt,
    currentNewsletterResources?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.newsletterResources.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.newsletterResources.name} />,
    [id],
  );

  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderActivitylogs,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);
  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const NewsletterResourcesController = memo(Controller);
