import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  getToken,
  RecruitsList,
  RecruitsAdd,
  RecruitsEdit,
  RecruitsDelete,
  RecruitsRollback,
  RecruitsAssignemntAdd,
  getOrganisation,
} from "../../services/api-end-points";
import axios from "../../services/axios";

import { fetchRecruits, fetchRecruitsError, fetchRecruitsSuccess } from "./actions";
import Types from "./types";

function* fetchRecruitsWorker(action) {
  try {
    const options = {
      method: "GET",
      url: RecruitsList(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      yield put(fetchRecruitsSuccess(response.data.data));
      action?.payload?.onSuccess && action.payload.onSuccess(response.data);
    } else {
      yield put(fetchRecruitsError(response.data.error));
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* addRecruitsWorker(action) {
  try {
    const options = {
      method: "POST",
      url: RecruitsAdd(),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* updateRecruitsWorker(action) {
  try {
    const options = {
      method: "PUT",
      url: RecruitsEdit(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* deleteRecruitsWorker(action) {
  try {
    const options = {
      method: "DELETE",
      url: RecruitsDelete(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* rollbackRecruitsWorker(action) {
  try {
    const options = {
      method: "DELETE",
      url: RecruitsRollback(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

function* addRecruitsAssignmentWorker(action) {
  try {
    const options = {
      method: "POST",
      url: RecruitsAssignemntAdd(action.payload.recruitsId),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response = yield call(axios, options);
    if (response.data.success) {
      action?.payload?.onSuccess && action.payload.onSuccess(response.data.data);
    } else {
      action?.payload?.onError && action.payload.onError(response.data.error);
    }
  } catch (e) {
    const error = e.response || e;
    yield put(fetchRecruitsError(error));
    action?.payload?.onError && action.payload.onError(error);
  }
}

export default function* RecruitsSaga() {
  yield takeLatest(Types.RECRUITS_FETCH, fetchRecruitsWorker);
  yield takeLatest(Types.RECRUITS_ASSIGNMENTS_ADD, addRecruitsAssignmentWorker);
  yield takeLatest(Types.RECRUITS_ADD, addRecruitsWorker);
  yield takeLatest(Types.RECRUITS_UPDATE, updateRecruitsWorker);
  yield takeLatest(Types.RECRUITS_DELETE, deleteRecruitsWorker);
  yield takeLatest(Types.RECRUITS_ROLLBACK, rollbackRecruitsWorker);
}
