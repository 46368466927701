/* eslint-disable no-unused-vars */
enum Types {
  FETCH_KANBAN_CONFIG = "FETCH_KANBAN_CONFIG",
  SUCCESS_FETCH_KANBAN_CONFIG = "SUCCESS_FETCH_KANBAN_CONFIG",
  SET_META_DATA_KANBAN_CONFIG = "SET_META_DATA_KANBAN_CONFIG",
  ERROR_FETCH_KANBAN_CONFIG = "ERROR_FETCH_KANBAN_CONFIG",
  FETCH_KANBAN_CONFIG_BY_ID = "FETCH_KANBAN_CONFIG_BY_ID",
  CREATE_KANBAN_CONFIG = "CREATE_KANBAN_CONFIG",
  UPDATE_KANBAN_CONFIG = "UPDATE_KANBAN_CONFIG",
  UPDATE_FITERS_KANBAN_CONFIG = "UPDATE_FITERS_KANBAN_CONFIG",
  KANBAN_CONFIG_FETCH_SUCCESS = "KANBAN_CONFIG_FETCH_SUCCESS",
  DELETE_KANBAN_CONFIG = "DELETE_KANBAN_CONFIG",
}

export default Types;
