import React, { useEffect, useMemo, useState } from "react";

import moment from "moment-timezone";
import { MdArrowRight, MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import ModalComponent from "../../components/Modal/Modal";
import Select from "../../components/Select/select";
import StyledTable from "../../components/Table/tables.styles";
import { useControllerConfigs } from "../../presentationals/common";
import { fetchPayrollDataSelf, updatePayrollSelfData } from "../../redux/employees/actions";
import { AttributesValues, currencyFormat, MomentDate, useTitle } from "../../services/methods";

import styles from "./PayrollsEmployeesController.styles";

const selectStatus = [
  { label: "Select...", value: "" },
  { label: "Accept", value: "accept" },
  {
    label: "Comment & Request Review",
    value: "comment",
  },
];
const PayrollsEmployeesListingController = (props) => {
  useTitle("Payrolls List");
  const { currencyOptions } = useControllerConfigs();
  const params = useParams();
  const [id, setId] = useState();
  const [data, setData] = useState({});
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [tab, setTab] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (props.payrolls.length > 0 && id !== params.id) {
      Hydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payrolls, params.id]);

  const Hydrate = (data) => {
    const array = data?.length > 0 ? data : props.payrolls;
    const find = array.find((item) => {
      return item._id.toString() === params.id;
    });
    if (find) {
      setId(find._id);
      setData(find);
      setComment("");
      setSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    props.updatePayrollSelfData({
      id: id,
      data: data,
      onSuccess: (data) => {
        props.fetchPayrollDataSelf({
          onSuccess: (data) => {
            Hydrate(data);
          },
          onError: (error) => {},
        });
      },
      onError: (error) => {},
    });
  };

  const handleSelectStatus = (e) => {
    const data = e.target.value;
    setSelectedStatus(data);
  };

  const DisplayButton = (status) => {
    let output;
    switch (status) {
      case "published":
        output = (
          <>
            <Select
              name={"group"}
              label={"Select Option"}
              defaultFocused={true}
              options={selectStatus.map((item, index) => ({
                label: item.label,
                value: item.value,
              }))}
              // data-index={}
              value={selectedStatus}
              onChange={handleSelectStatus}
            />
            {selectedStatus == "accept" ? (
              <Button
                disabled={submitting}
                onClick={() => onSubmit({ status: "accepted" })}
                label={submitting ? "Accepting" : "Accept"}
              />
            ) : (
              selectedStatus == "comment" && (
                <>
                  <Input
                    label={"Comment"}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    type={"textarea"}
                  />
                  <Button
                    onClick={() => onSubmit({ status: "review", comment: comment })}
                    disabled={submitting}
                    label={submitting ? "Processing" : "Comment & Request Review"}
                  />
                </>
              )
            )}
            {/* <div style={{ display: "flex", flexDirection: "row", margin: 20 }}>
              <hr style={{ flex: 1 }} />
              Comments
              <hr style={{ flex: 1 }} />
            </div> */}
          </>
        );
        break;
      case "review":
        output = <Button disabled={true} label='In Review' />;
        break;
      case "accepted":
        output = <Button disabled={true} label='Accepted / Processing' />;
        break;
      case "taxed":
        output = <Button disabled={true} label='Accepted &amp; Taxed' />;
        break;
      default:
        break;
    }
    return output;
  };
  const Tabs = () => {
    const tabs = [
      {
        tab: "0",
        label: "Payroll",
      },
      {
        tab: "1",
        label: "Status",
      },
    ];
    return tabs;
  };
  const updateTab = (e) => {
    setTab(e.currentTarget.getAttribute("data-key"));
  };
  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };

  const salaryCurrency = useMemo(() => {
    let currency = currencyOptions.find((item) => item.value === data?.list?.salaryCurrency)?.label;
    if (!currency) {
      currency = AttributesValues("currency", data?.list?.salaryCurrency);
    }
    return currency;
  }, [currencyOptions, data?.list?.salaryCurrency]);

  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>Payroll Status</h2>
      </styles.Header>
      <styles.TabContainer>
        {Tabs().map((item) => (
          <styles.TabItem
            key={item.tab}
            onClick={updateTab}
            data-key={item.tab}
            data-tab={item.tab}
            active={tab === item.tab}
            // error={item.fields ? CheckForTabError(item.fields) : false}
          >
            {" "}
            {item.label}
          </styles.TabItem>
        ))}
      </styles.TabContainer>
      {tab == "0" ? (
        <>
          <styles.Row>
            <styles.Column>Days:</styles.Column>
            <styles.Column>{data.workingdays}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Hours:</styles.Column>
            <styles.Column>{data?.list?.workinghours}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime Type:</styles.Column>
            <styles.Column>{data?.list?.overtimeType}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime Hours:</styles.Column>
            <styles.Column>{data?.list?.overtimeHours}</styles.Column>
          </styles.Row>
          <hr />

          <styles.Row>
            <styles.Column>Salary:</styles.Column>
            <styles.Column>{data?.list?.salaryBase}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Bonus:</styles.Column>
            <styles.Column>{data?.list?.salaryBonus}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>6 Month Bonus:</styles.Column>
            <styles.Column>{data?.list?.salarySemiAnnualBonus}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>12 Month Bonus:</styles.Column>
            <styles.Column>{data?.list?.salaryAnnualBonus}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime:</styles.Column>
            <styles.Column>{data?.list?.salaryOvertime}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Reimbursements:</styles.Column>
            <styles.Column>{data?.list?.salaryReimbursements}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Referrals:</styles.Column>
            <styles.Column>{data?.list?.salaryReferrals}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Adjustements:</styles.Column>
            <styles.Column>{data?.list?.salaryAdjustments}</styles.Column>
          </styles.Row>
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Sub-Total</b>
            </styles.Column>
            <styles.Column>
              {currencyFormat(data?.list?.salaryPayable, salaryCurrency)}
            </styles.Column>
          </styles.Row>
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Tax Amount</b>
            </styles.Column>
            <styles.Column>
              {data?.list?.salaryTax
                ? currencyFormat(data?.list?.salaryTax, salaryCurrency)
                : "Processing"}
            </styles.Column>
          </styles.Row>
          <hr />
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Payable Amount</b>
            </styles.Column>
            <styles.Column>
              {data?.list?.salaryTax
                ? currencyFormat(data?.list?.salaryPayable - data?.list?.salaryTax, salaryCurrency)
                : "Processing"}
            </styles.Column>
          </styles.Row>
          <styles.Title>Feedback</styles.Title>
          <styles.Feedback>{data?.list?.feedback}</styles.Feedback>
          {data.list?.attachments?.map((item, index) => (
            <styles.AttachmentsContainer key={index}>
              <Input
                readOnly={true}
                label={"Attachment Name"}
                type={"text"}
                name='name'
                autoComplete={"off"}
                value={item.name}
                mandatory={true}
                width='200px'
              />
              <Button
                type={"primary"}
                onClick={() => getDocument(item.id)}
                size={"normal"}
                label={"View"}
                disabled={!item.id}
              />
            </styles.AttachmentsContainer>
          ))}
          <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
            <Document id={documentId} />
          </ModalComponent>
        </>
      ) : (
        <>
          {DisplayButton(data?.list?.status)}
          {data?.list?.comments?.map((comment, index) => (
            <div key={index}>
              {MomentDate({ date: comment.date, format: "YYYY-MM-DD HH:mm" })}
              <br />
              {comment?.from?.name}
              <br />
              {comment.message}
              <br />
              <br />
            </div>
          ))}
        </>
      )}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  payrolls: state.EmployeesReducer.payrollsSelf.filter((item) => item.list.status !== "drafted"),
});

const mapDispatchToProps = {
  fetchPayrollDataSelf,
  updatePayrollSelfData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsEmployeesListingController);
