import styles from "./styles";

export default function MetaData({ title, value }) {
  return (
    <styles.Row>
      <styles.Column>{title}</styles.Column>
      <styles.Column>{value}</styles.Column>
    </styles.Row>
  );
}
