import React, { useCallback, useMemo } from "react";

import { AnimatePresence } from "framer-motion";
import { connect } from "react-redux";

import { updateProfileModel } from "../../../redux/application/actions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Header, InAppLoader, Notification } from "../../components";
import { Employee, Admin } from "../Navigation";

import {
  MainContainer,
  NavigationPanel,
  ViewPanelContainer,
  ViewPanel,
  ControllerPanel,
  NotificationContainer,
} from "./styles";

interface PropsInterface {
  activity?: any;
  controller?: any;
  employee?: boolean;
  admin?: boolean;
  layout?: "2" | "3";
  isOnlyListingView?: boolean;
  noPadding?: boolean;
  title?: string;
}
export const Layout = ({
  activity = <div></div>,
  controller = <div></div>,
  employee,
  admin,
  layout,
  isOnlyListingView = true,
  noPadding = false,
  ...props
}: PropsInterface) => {
  const dispatch = useAppDispatch();
  const inAppLoader = useAppSelector((state) => state.ApplicationReducer.inAppLoader);
  const notifications = useAppSelector((state) => state.ApplicationReducer.notifications);
  const profileModel = useAppSelector((state) => state.ApplicationReducer.profileModel);

  const customEvent = useMemo(() => {
    const event = new CustomEvent("myCustomEvent", {
      detail: { popup: false },
    });
    return event;
  }, []);

  const dispatchCustomEvent = useCallback(() => {
    document.dispatchEvent(customEvent);
  }, [customEvent]);

  const handleOnClick = useCallback(() => {
    if (profileModel) {
      dispatch(updateProfileModel(false));
    }
    dispatchCustomEvent();
  }, [profileModel, dispatch, dispatchCustomEvent]);

  return (
    <MainContainer onClick={handleOnClick}>
      <NavigationPanel>
        {employee && <Employee />}
        {admin && <Admin />}
      </NavigationPanel>
      <ViewPanelContainer
        isOnlyListingView={isOnlyListingView}
        layout={layout}
        noPadding={noPadding}
      >
        <ViewPanel isOnlyListingView={isOnlyListingView} layout={layout} noPadding={noPadding}>
          {activity}
        </ViewPanel>
      </ViewPanelContainer>
      <ControllerPanel isOnlyListingView={isOnlyListingView} layout={layout}>
        {controller}
      </ControllerPanel>
      {!!notifications.length && (
        <NotificationContainer>
          <ul>
            <AnimatePresence initial={false}>
              {notifications.map((notification) => (
                <Notification key={notification.id} notification={notification} />
              ))}
            </AnimatePresence>
          </ul>
        </NotificationContainer>
      )}
      {inAppLoader && <InAppLoader />}
    </MainContainer>
  );
};
