import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyStudentProfileColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    studentName: {
      title: "Name",
      sortSupported: true,
      searchSupported: true,
      key: "studentName",
    },
    studentID: {
      title: "Lerner's ID",
      sortSupported: true,
      key: "studentID",
    },
    enrolledCourse: {
      title: "Enrolled Course",
      sortSupported: true,

      key: "enrolledCourse",

      render: (item) => {
        return <text className='title'>{item?.enrolledCourse?.name || "-"}</text>;
      },
    },
    studentOfficialEmail: {
      title: "Official Email",
      sortSupported: true,
      key: "studentOfficialEmail",
    },
    associatedEnrollment: {
      title: "Associated Enrollment",
      sortSupported: true,
      key: "associatedEnrollment",
      render: (item) => {
        return <text className='title'>{item?.associatedEnrollment?.studentName || "-"}</text>;
      },
    },
    studentTrainingCordinator: {
      title: "Student Training Cordinator",
      sortSupported: true,
      key: "studentTrainingCordinator",
      render: (item) => {
        return <text className='title'>{item?.studentTrainingCordinator?.name || "-"}</text>;
      },
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",

      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",

      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",

      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };
  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
