import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getCatalogAdministrationsColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    title: {
      title: "Title",
      sortSupported: true,
      key: "title",
    },
    manager: {
      title: "manager",
      key: "manager",
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",
      render: (item) => {
        return <div className='title'>{item.active ? "Yes" : "No"}</div>;
      },
    },
    categoryTitle: {
      title: "categoryTitle",
      key: "categoryTitle",
    },
    // categoryIcon: {
    //   title: "categoryIcon",
    //   key: "categoryIcon",
    // },
    categoryCatalog: {
      title: "categoryCatalog",
      key: "categoryCatalog",
    },
    categoryActive: {
      title: "categoryActive",
      key: "categoryActive",
    },
    parentCategory: {
      title: "parentCategory",
      key: "parentCategory",
    },
    description: {
      title: "description",
      key: "description",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
