import React from "react";

import { Layout } from "../../../presentationals";
import { SPSBGCheckList } from "../../../presentationals/screens/admin/SPSBGCheck/SPSBGCheckList";

const Route = () => {
  return <Layout activity={<SPSBGCheckList />} admin={true} layout={"2"} />;
};

export const APSPSBGCheckList = React.memo(Route);
