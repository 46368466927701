/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPSPAPERWORK = "FETCH_SPSPAPERWORK",
  FETCH_SPSPAPERWORK_BY_ID = "FETCH_SPSPAPERWORK_BY_ID",
  SUCCESS_FETCH_SPSPAPERWORK = "SUCCESS_FETCH_SPSPAPERWORK",
  ERROR_FETCH_SPSPAPERWORK = "ERROR_FETCH_SPSPAPERWORK",
  CREATE_SPSPAPERWORK = "CREATE_SPSPAPERWORK",
  UPDATE_SPSPAPERWORK = "UPDATE_SPSPAPERWORK",
  DELETE_SPSPAPERWORK = "DELETE_SPSPAPERWORK",
}

export default Types;
