import Types from "./actionType";
import {
  FetchCategoriesPayload,
  CreateCategories,
  CreateCategoriesPayload,
  FetchCategories,
  FetchCategoriesError,
  FetchCategoriesSuccess,
  UpdateCategories,
  UpdateCategoriesPayload,
  CategoriesListWithMetaData,
  FetchCategoriesByIdPayload,
  FetchCategoriesById,
  DeleteCategoriesPayload,
  DeleteCategories,
} from "./types";

export const fetchCategoriesList = ({
  params,
  onSuccess,
  onError,
}: FetchCategoriesPayload): FetchCategories => ({
  type: Types.FETCH_CATEGORIES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchCategoriesById = ({
  id,
  onSuccess,
  onError,
}: FetchCategoriesByIdPayload): FetchCategoriesById => ({
  type: Types.FETCH_CATEGORIES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchCategoriesListSuccess = (
  data: CategoriesListWithMetaData,
): FetchCategoriesSuccess => ({
  type: Types.SUCCESS_FETCH_CATEGORIES,
  payload: {
    data,
  },
});

export const fetchCategoriesError = (error?: string): FetchCategoriesError => ({
  type: Types.ERROR_FETCH_CATEGORIES,
  payload: { error },
});

export const createCategories = ({
  data,
  onSuccess,
  onError,
}: CreateCategoriesPayload): CreateCategories => ({
  type: Types.CREATE_CATEGORIES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateCategories = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateCategoriesPayload): UpdateCategories => ({
  type: Types.UPDATE_CATEGORIES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteCategories = ({
  id,
  onSuccess,
  onError,
}: DeleteCategoriesPayload): DeleteCategories => ({
  type: Types.DELETE_CATEGORIES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
