/* eslint-disable no-unused-vars */
enum Types {
  FETCH_DASHBOARD_WIDGETS = "FETCH_DASHBOARD_WIDGETS",
  SUCCESS_FETCH_DASHBOARD_WIDGETS = "SUCCESS_FETCH_DASHBOARD_WIDGETS",
  SET_META_DATA_DASHBOARD_WIDGETS = "SET_META_DATA_DASHBOARD_WIDGETS",
  ERROR_FETCH_DASHBOARD_WIDGETS = "ERROR_FETCH_DASHBOARD_WIDGETS",
  FETCH_DASHBOARD_WIDGETS_BY_ID = "FETCH_DASHBOARD_WIDGETS_BY_ID",
  CREATE_DASHBOARD_WIDGETS = "CREATE_DASHBOARD_WIDGETS",
  UPDATE_DASHBOARD_WIDGETS = "UPDATE_DASHBOARD_WIDGETS",
  UPDATE_FITERS_DASHBOARD_WIDGETS = "UPDATE_FITERS_DASHBOARD_WIDGETS",
  DASHBOARD_WIDGETS_FETCH_SUCCESS = "DASHBOARD_WIDGETS_FETCH_SUCCESS",
  DELETE_DASHBOARD_WIDGETS = "DELETE_DASHBOARD_WIDGETS",
}

export default Types;
