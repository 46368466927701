import Types from "./actionType";
import { Form16Actions, Form16State } from "./types";

const initialState: Form16State = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
    },
  },
  loading: false,
};

export const Form16Reducer = (state = initialState, action: Form16Actions) => {
  switch (action.type) {
    case Types.FETCH_FORM16:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_FORM16:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
