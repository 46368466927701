import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createEducationDetails,
  fetchEducationDetailsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateEducationDetails,
  uploadFile,
  deleteEducationDetails,
} from "../../../../../redux";
import {
  CreateEducationDetailsPayload,
  EducationDetailsList,
  FetchEducationDetailsByIdPayload,
  UpdateEducationDetailsPayload,
} from "../../../../../redux/EducationDetails/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getAttributValuesBynameId } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  EducationDetailsForm,
  SelectOptions,
  TableHeaderButton,
  WorkLogItem,
  useEducationDetailsControllerProps,
  useEducationDetailsControllerReturnType,
} from "../../../../../types";
import { DashboardAdministrationFormDataType } from "../../../../../types";
import { collections, validatePhone } from "../../../../../utils";

const addFormSteps = ["Overview"];
const editFormSteps = ["Overview", "Checklist", "Activity Logs", "Worklogs", "System Data"];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "associatedEmployee",
  "country",
  "state",
  "instituteName",
  "studentId",
  "instituteAddress",
  "city",
  "postalCode",
  "phone",
  "receivedDegree",
  "graduationDate",
  "currentlyAttending",
  "fromDate",
  "toDate",
  "educationType",
  "degreeName",
  "majorName",
];
export const useEducationDetailsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useEducationDetailsControllerProps): useEducationDetailsControllerReturnType => {
  const defaultFormData: EducationDetailsForm = {
    associatedEmployee: {
      value: "",
      required: true,
      error: "",
    },
    active: {
      value: true,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    country: {
      value: "",
      required: true,
      error: "",
    },
    state: {
      value: "",
      required: true,
      error: "",
    },
    instituteName: {
      value: "",
      required: true,
      error: "",
    },
    studentId: {
      value: "",
      required: true,
      error: "",
    },
    instituteAddress: {
      value: "",
      required: true,
      error: "",
    },
    city: {
      value: "",
      required: true,
      error: "",
    },
    postalCode: {
      value: null,
      required: true,
      error: "",
    },
    phone: {
      value: "",
      required: false,
      error: "",
    },
    receivedDegree: {
      value: "",
      required: false,
      error: "",
    },
    graduationDate: {
      value: null,
      required: false,
      error: "",
    },
    currentlyAttending: {
      value: false,
      required: false,
      error: "",
    },
    fromDate: {
      value: null,
      required: true,
      error: "",
    },
    toDate: {
      value: null,
      required: false,
      error: "",
    },
    educationType: {
      value: "",
      required: true,
      error: "",
    },
    degreeName: {
      value: "",
      required: true,
      error: "",
    },
    majorName: {
      value: "",
      required: true,
      error: "",
    },
  };
  // ================ Reducer Values ================
  const list = useAppSelector((state) => state.EducationDetailsReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<EducationDetailsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<EducationDetailsList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    const { employees } = collections;
    return [employees.label];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          if (formData.receivedDegree.value && !formData.graduationDate.value) {
            count += 1;
          }
          overViewFields.map((field) => {
            if (formData[field].error) {
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "receivedDegree" && !value) {
        tempFormData = {
          ...tempFormData,
          graduationDate: {
            ...tempFormData.graduationDate,
            error: "",
          },
        };
      }
      setFormData(tempFormData);
    },
    [formData],
  );

  const rehydrateFormData = useCallback(
    (educationDetails: EducationDetailsList) => {
      const item = educationDetails;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["country", "associatedEmployee", "educationType"].includes(field)) {
          const tempValue = value._id;
          value = tempValue;
        }
        if (field === "phone") {
          value = value || null;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchEducationDetails = useCallback(() => {
    setLoading(true);
    const payload: FetchEducationDetailsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchEducationDetailsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (["phone"].includes(item) && !validatePhone(String(validValue))) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid phone number with country code",
          },
        };
      } else if (formData.receivedDegree.value && !formData.graduationDate.value) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          graduationDate: {
            ...tempFormData.graduationDate,
            error: "Required Field",
          },
        };
      } else if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      attachments,
      associatedEmployee,
      country,
      state,
      instituteName,
      studentId,
      instituteAddress,
      city,
      postalCode,
      phone,
      receivedDegree,
      graduationDate,
      currentlyAttending,
      fromDate,
      toDate,
      educationType,
      degreeName,
      majorName,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateEducationDetailsPayload = {
        data: {
          attachments: attachments,
          associatedEmployee: associatedEmployee.value,
          country: country.value,
          state: state.value,
          instituteName: instituteName.value,
          studentId: studentId.value,
          instituteAddress: instituteAddress.value,
          city: city.value,
          postalCode: postalCode.value,
          phone: phone.value,
          receivedDegree: receivedDegree.value,
          graduationDate: graduationDate.value,
          currentlyAttending: currentlyAttending.value,
          fromDate: fromDate.value,
          toDate: toDate.value,
          educationType: educationType.value,
          degreeName: degreeName.value,
          majorName: majorName.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "EducationDetails created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createEducationDetails(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      associatedEmployee,
      country,
      state,
      instituteName,
      studentId,
      instituteAddress,
      city,
      postalCode,
      phone,
      receivedDegree,
      graduationDate,
      currentlyAttending,
      fromDate,
      toDate,
      educationType,
      degreeName,
      majorName,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const payload: UpdateEducationDetailsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          associatedEmployee: associatedEmployee.value,
          country: country.value,
          state: state.value,
          instituteName: instituteName.value,
          studentId: studentId.value,
          instituteAddress: instituteAddress.value,
          city: city.value,
          postalCode: postalCode.value,
          phone: phone.value,
          receivedDegree: receivedDegree.value,
          graduationDate: graduationDate.value,
          currentlyAttending: currentlyAttending.value,
          fromDate: fromDate.value,
          toDate: toDate.value,
          educationType: educationType.value,
          degreeName: degreeName.value,
          majorName: majorName.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "EducationDetails updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateEducationDetails(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateEducationDetailsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            // const notification: AppNotificationsType = {
            //   id: notificationsId + 1,
            //   title: "EducationDetails updated successfully",
            //   type: "success",
            // };
            // dispatch(addAppNotification({ notification }));
            // setTimeout(() => {
            //   dispatch(removeAppNotification({ notification }));
            // }, 2000);
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateEducationDetails(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data?.associatedEmployee?.name || "";
    }
    return [strings.headerTitle.educationDetails, title];
  }, [data, type]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );
  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchEducationDetails,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
  };
};
