/* eslint-disable no-unused-vars */
enum Types {
  FETCH_LEAD_LISTS = "FETCH_LEAD_LISTS",
  FETCH_LEAD_LISTS_BY_ID = "FETCH_LEAD_LISTS_BY_ID",
  SUCCESS_FETCH_LEAD_LISTS = "SUCCESS_FETCH_LEAD_LISTS",
  ERROR_FETCH_LEAD_LISTS = "ERROR_FETCH_LEAD_LISTS",
  CREATE_LEAD_LISTS = "CREATE_LEAD_LISTS",
  UPDATE_LEAD_LISTS = "UPDATE_LEAD_LISTS",
  DELETE_LEAD_LISTS = "DELETE_LEAD_LISTS",
}

export default Types;
