import React, { useEffect, useMemo } from "react";

import Loader from "../../../../../components/Loader/loader";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { useAppSelector } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";
import { Header, Table, TableHeader, TableListSkeleton } from "../../../../components";
import Pagination from "../../../../components/Pagination";
import { useKnowledgebaseConfigs } from "../hooks";

import { MainContainer } from "./styles";

interface RelatedList {
  type: "user";
  value: string;
  addToRelatedList?: () => void;
}
interface Props {
  relatedList: RelatedList;
}

const columns = [
  {
    title: "Serial",
    width: "10%",
    key: "serial",
    center: true,
  },
  {
    title: "Name",
    width: "35%",
    key: "title",
  },
  {
    title: "Description",
    width: "35%",
    key: "description",
  },
  {
    title: "Active",
    width: "20%",
    center: true,
    key: "active",
    render: (item) => {
      return <div className='title'>{item.active ? "Yes" : "No"}</div>;
    },
  },
];

export const KnowledgebaseList = ({ relatedList }: Props) => {
  const knowledgebases = useAppSelector((state) => state.KnowledgebaseReducer.list);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { onClickUser, getKnowledgebase, onClickAdd, emptyListMessage, handlePageChange, loading } =
    useKnowledgebaseConfigs({
      relatedList,
    });

  useEffect(() => {
    getKnowledgebase();
  }, [getKnowledgebase]);

  const isAddButtonVisible = useMemo(() => {
    if (relatedList?.addToRelatedList && permissions.knowledgebase_edit) {
      return true;
    } else if (permissions.knowledgebase_add) {
      return true;
    }
    return false;
  }, [
    relatedList?.addToRelatedList,
    permissions.knowledgebase_add,
    permissions.knowledgebase_edit,
  ]);

  return (
    <MainContainer onClick={() => console.log(knowledgebases)}>
      {!relatedList && <Header title={strings.headerTitle.knowledgebase} />}
      {loading ? (
        <TableListSkeleton />
      ) : (
        <>
          <TableHeader
            rightButton={isAddButtonVisible ? strings.common.add : ""}
            rightButtonPress={
              relatedList?.addToRelatedList ? relatedList.addToRelatedList : onClickAdd
            }
          />
          <Table
            emptyListMessage={emptyListMessage}
            data={knowledgebases.data}
            columns={columns}
            page={knowledgebases?.metaData?.page || 1}
            pageSize={knowledgebases?.metaData?.limit}
            onClickTableRow={onClickUser}
          />
          <Pagination
            currentPage={knowledgebases?.metaData?.currentPage || 1}
            totalCount={knowledgebases?.metaData?.totalItems || 0}
            pageSize={knowledgebases?.metaData?.limit}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </MainContainer>
  );
};
