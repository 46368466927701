const Types = {
  MODULES_FETCH: "MODULES_FETCH",
  MODULES_FETCH_SUCCESS: "MODULES_FETCH_SUCCESS",

  MODULES_ADD: "MODULES_ADD",
  MODULES_UPDATE: "MODULES_UPDATE",
  MODULES_FILTERS_UPDATE: "MODULES_FILTERS_UPDATE",
};

export default Types;
