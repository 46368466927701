/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CURRENCY = "FETCH_CURRENCY",
  SUCCESS_FETCH_CURRENCY = "SUCCESS_FETCH_CURRENCY",
  ERROR_FETCH_CURRENCY = "ERROR_FETCH_CURRENCY",
  CREATE_CURRENCY = "CREATE_CURRENCY",
  UPDATE_CURRENCY = "UPDATE_CURRENCY",
  DELETE_CURRENCY = "DELETE_CURRENCY",
  FETCH_CURRENCY_BY_ID = "FETCH_CURRENCY_BY_ID",
}

export default Types;
