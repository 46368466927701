import React, { useEffect } from "react";

import moment from "moment-timezone";
import { MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/button";
import StyledTable from "../../components/Table/tables.styles";
import { Header } from "../../presentationals/components";
import { getAllTickets } from "../../redux/tickets/actions";
import { useTitle } from "../../services/methods";
import { strings } from "../../theme";

import { MainContainer, OtherInfoContainer, Row, Card, Text } from "./Home.styles";
import HomeControllerAdmin from "./HomeController";

const HomeAdmin = (props) => {
  const navigate = useNavigate();
  useTitle("Admin Home");

  useEffect(() => {
    const employees = props.employees;
    if (Array.isArray(employees)) {
      employees.sort(
        (a, b) => distanceToBirthday(a.personaDOB) - distanceToBirthday(b.personalDOB),
      );
      for (let i = 0; i < employees.length; i++) {
        console.log(employees[i].nickName, employees[i].personalDOB);
      }
    }
  }, [props.employee]);

  function distanceToBirthday(date) {
    const currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    const currYear = currDate.getFullYear();

    const offset = new Date();
    offset.setHours(0, 0, 0, 0);
    offset.setFullYear(currYear + 1);

    date = new Date(date + " 00:00");
    date.setFullYear(currYear);

    const diff = date - currDate;
    return diff < 0 ? diff + offset.getTime() : diff;
  }

  return (
    <MainContainer>
      <Header title={strings.headerTitle.home} />
      {/* <Header>
        <h2>Welcome Back, {props.user.name}</h2>
        <OtherInfoContainer>
          <HomeControllerAdmin />
          <Button
            label="Employee Panel"
            size="normal"
            onClick={() => navigate("/ep")}
          />
        </OtherInfoContainer>
      </Header> */}
      <Row>
        {[
          {
            label: "Awaiting Reply",
            value: props?.tickets?.filter((ticket) => ticket.status === "awaiting_reply")?.length,
            icon: "",
            color: "#E7533D",
            textColor: "white",
          },
          {
            label: "Employees",
            value: props.employees.length,
            icon: "",
            color: "#41988E",
            textColor: "white",
          },
        ].map((item) => {
          return (
            <Card
              key={item.label}
              onClick={item.onClick}
              textColor={item.textColor}
              color={item.color}
            >
              <Text size={"10px"}>{item.label}</Text>
              <Text size={"14px"}>{item.value}</Text>
            </Card>
          );
        })}
      </Row>
      {/* <StyledTable marginTop={"4vh"} width={"100%"}>
        <thead>
          <tr>
            <th colSpan={2}>Upcoming Birthdays</th>
          </tr>
          <tr>
            <th width="50%">Name</th>
            <th>DOB</th>
          </tr>
        </thead>
        <tbody>
          {props.employees
            .filter((employee) => {
              if (employee.personalDOB) {
                var year = moment().utc().format("YYYY");
                var DOB = moment(employee.personalDOB)
                  .utc()
                  .format(`${year}-MM-DD`);
                var Days = moment().utc().format(`YYYY-MM-DD`);
                var DOBValue = parseInt(moment(DOB).utc().valueOf() / 1000);
                var DaysValue = parseInt(moment().utc().valueOf() / 1000);
                if (
                  DaysValue <= DOBValue &&
                  DaysValue + 86400 * 25 >= DOBValue
                ) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            })
            .map((employee, index) => {
              return (
                <tr key={index}>
                  <td align="center">
                    {employee.name + " (" + employee._id.slice(-5) + ")"}
                  </td>
                  <td align="center">
                    {moment(employee.personalDOB).utc().format(`${2022}-MM-DD`)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </StyledTable> */}
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.UserReducer,
  tickets: state.TicketsReducer.ticketsAdmin,
  employees: state.EmployeesReducer.employees,
});

const mapDispatchToProps = {
  // getAllTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeAdmin);
