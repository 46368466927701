import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  ServiceNowSolutionsList,
  AddServiceNowSolutions,
  UpdateServiceNowSolutions,
  FindServiceNowSolutions,
  DeletePSAccountContact,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchServiceNowSolutionsListSuccess, fetchServiceNowSolutionsError } from "./actions";
import Types from "./actionType";
import {
  CreateServiceNowSolutionsuccess,
  CreateServiceNowSolutions as CreateServiceNowSolutionsType,
  ServiceNowSolutionsSuccess,
  UpdateServiceNowSolutions as UpdateServiceNowSolutionsType,
  FetchServiceNowSolutions,
  FetchServiceNowSolutionsById,
  ServiceNowSolutionsByIdSuccess,
  DeleteServiceNowSolutions as DeleteServiceNowSolutionsType,
} from "./types";

function* createWorker(action: CreateServiceNowSolutionsType) {
  try {
    const options = {
      method: "POST",
      url: AddServiceNowSolutions,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateServiceNowSolutionsuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateServiceNowSolutionsType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateServiceNowSolutions(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateServiceNowSolutionsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchServiceNowSolutions) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: ServiceNowSolutionsList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<ServiceNowSolutionsSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchServiceNowSolutionsListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchServiceNowSolutionsError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data?.message || "Error in fetching Service Now Solutions",
      );
    }
  }
}

function* fetchServiceNowSolutionsById(action: FetchServiceNowSolutionsById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindServiceNowSolutions(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<ServiceNowSolutionsByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(
        error.response?.data.message || "Error in fetching Service Now Solutions",
      );
    }
  }
}

function* deleteWorker(action: DeleteServiceNowSolutionsType) {
  try {
    const options = {
      method: "DELETE",
      url: DeletePSAccountContact(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateServiceNowSolutionsuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SERVICE_NOW_SOLUTIONS, createWorker);
  yield takeLatest(Types.UPDATE_SERVICE_NOW_SOLUTIONS, updateWorker);
  yield takeEvery(Types.FETCH_SERVICE_NOW_SOLUTIONS, fetchAllWorker);
  yield takeEvery(Types.FETCH_SERVICE_NOW_SOLUTIONS_BY_ID, fetchServiceNowSolutionsById);
  yield takeEvery(Types.DELETE_SERVICE_NOW_SOLUTIONS, deleteWorker);
}
