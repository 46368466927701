import React, { useEffect } from "react";

import { AcademyCandidateEnrollmentPaymentDetails } from "../../../../redux/AcademyCandidateEnrollments/types";
import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { useControllerConfigs } from "../../../common";
import { CloseIcon } from "../../../common/styles";
import { Button, Checkbox, Popup, Select, TableList, TextInput } from "../../../components";

import { useAcademyPaymentDetails } from "./hooks/useAcademyPaymentDetails";
import { FormTitle, Row, FieldsContainer, ModalContainer } from "./styles";

interface Props {
  paymentDetails: AcademyCandidateEnrollmentPaymentDetails[];
  candidateEnrollmentId?: string;
  fetchPaymentDetails: () => void;
}

export const AcademyPaymentDetails = ({
  candidateEnrollmentId,
  paymentDetails,
  fetchPaymentDetails,
}: Props) => {
  const {
    onDateChange,
    onTextChange,
    handleCheckbox,
    handlePaymentDetailsModal,
    salaryModal,
    addPaymentDetailsModal,
    editPaymentDetailsModal,
    formData,
    onSubmitAdd,
    onSubmitUpdate,
  } = useAcademyPaymentDetails({ paymentDetails, fetchPaymentDetails, candidateEnrollmentId });

  const { addPaymentDetails, editPaymentDetails } = strings.apAcademyCandidateEnrollments;

  const columns = [
    {
      title: "Serial",
      width: "10%",
      key: "serial",
    },
    {
      title: "Installment Number",
      width: "22.5%",
      key: "installmentNumber",
    },
    {
      title: "Amount Paid",
      width: "22.5%",
      key: "amountPaid",
    },
    {
      title: "Invoice Number",
      width: "22.5%",
      key: "invoiceNumber",
    },
    {
      title: "Invoiced Date",
      width: "22.5%",
      render: (item) => {
        return (
          <div className='title'>
            {item?.invoicedDate
              ? MomentDate({ date: item.invoicedDate, format: "YYYY-MM-DD" })
              : "-"}
          </div>
        );
      },
    },
  ];

  const {
    installmentNumber,
    amountPaid,
    taxedAmount,
    invoiceNumber,
    invoicedDate,
    paymentDate,
    installmentPaid,
    invoiceAttached,
    paymentReceiptAttached,
  } = formData;

  return (
    <>
      <TableList
        rightButton={strings.common.addNew}
        rightButtonPress={addPaymentDetailsModal}
        loading={false}
        list={paymentDetails}
        columns={columns}
        onClickTableRow={editPaymentDetailsModal}
      />
      <Popup isOpen={!!salaryModal} closeModal={handlePaymentDetailsModal}>
        <ModalContainer>
          <CloseIcon src={images.closeModal} onClick={handlePaymentDetailsModal} />
          <FormTitle>
            {salaryModal === "add"
              ? addPaymentDetails
              : salaryModal === "edit"
              ? editPaymentDetails
              : ""}
          </FormTitle>
          <FieldsContainer>
            <Row>
              <TextInput
                label={"Installment Number"}
                type={"text"}
                width='48%'
                error={installmentNumber.error}
                inputProps={{
                  name: "installmentNumber",
                  type: "number",
                  value: installmentNumber.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: installmentNumber.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Amount Paid"}
                type={"text"}
                width='48%'
                error={amountPaid.error}
                inputProps={{
                  name: "amountPaid",
                  type: "number",
                  value: amountPaid.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: amountPaid.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <TextInput
                label={"Taxed Amount"}
                type={"text"}
                width='48%'
                error={taxedAmount.error}
                inputProps={{
                  name: "taxedAmount",
                  type: "number",
                  value: taxedAmount.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: taxedAmount.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label={"Invoice Number"}
                type={"text"}
                width='48%'
                error={invoiceNumber.error}
                inputProps={{
                  name: "invoiceNumber",
                  value: invoiceNumber.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: invoiceNumber.required,
                }}
                textAreaProps={{}}
              />
            </Row>
            <Row>
              <TextInput
                label='Invoiced Date'
                type={"date"}
                width='48%'
                error={invoicedDate.error}
                onDateChange={onDateChange}
                datePickerProps={{
                  name: "invoicedDate",
                  value: invoicedDate.value,
                  required: invoicedDate.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label='Payment Date'
                type={"date"}
                width='48%'
                error={paymentDate.error}
                onDateChange={onDateChange}
                datePickerProps={{
                  name: "paymentDate",
                  value: paymentDate.value,
                  required: paymentDate.required,
                }}
                textAreaProps={{}}
              />
              {/* <TextInput
                label={"Installment Paid"}
                type={"text"}
                width='48%'
                error={installmentPaid.error}
                inputProps={{
                  name: "installmentPaid",
                  type: "number",
                  value: installmentPaid.value,
                  autoComplete: "off",
                  onChange: onTextChange,
                  required: installmentPaid.required,
                }}
                textAreaProps={{}}
              /> */}
            </Row>

            <Row>
              <Checkbox
                label={"Invoice Attached"}
                width='48%'
                error={invoiceAttached.error}
                required={invoiceAttached.required}
                onClick={handleCheckbox}
                value={invoiceAttached.value}
                attributes={{
                  "data-name": "invoiceAttached",
                }}
              />
              <Checkbox
                label={"Payment Receipt Attached"}
                width='48%'
                error={paymentReceiptAttached.error}
                required={paymentReceiptAttached.required}
                onClick={handleCheckbox}
                value={paymentReceiptAttached.value}
                attributes={{
                  "data-name": "paymentReceiptAttached",
                }}
              />
            </Row>
          </FieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={salaryModal === "add" ? strings.common.add : strings.common.save}
            onClick={salaryModal === "add" ? onSubmitAdd : onSubmitUpdate}
          />
        </ModalContainer>
      </Popup>
    </>
  );
};
