import React from "react";

import Skeleton from "react-loading-skeleton";

import { Container } from "./styles";

interface SkeletonProps {
  rows?: number;
}

export const TableListSkeleton = ({ rows = 12 }: SkeletonProps) => {
  return (
    <Container>
      <Skeleton count={rows} className='table-list-skeleton' />
      <Skeleton count={1} className='table-pagination-skeleton' />
    </Container>
  );
};
