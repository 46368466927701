/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SERVICE_NOW_SOLUTIONS = "FETCH_SERVICE_NOW_SOLUTIONS",
  FETCH_SERVICE_NOW_SOLUTIONS_BY_ID = "FETCH_SERVICE_NOW_SOLUTIONS_BY_ID",
  SUCCESS_FETCH_SERVICE_NOW_SOLUTIONS = "SUCCESS_FETCH_SERVICE_NOW_SOLUTIONS",
  ERROR_FETCH_SERVICE_NOW_SOLUTIONS = "ERROR_FETCH_SERVICE_NOW_SOLUTIONS",
  CREATE_SERVICE_NOW_SOLUTIONS = "CREATE_SERVICE_NOW_SOLUTIONS",
  UPDATE_SERVICE_NOW_SOLUTIONS = "UPDATE_SERVICE_NOW_SOLUTIONS",
  DELETE_SERVICE_NOW_SOLUTIONS = "DELETE_SERVICE_NOW_SOLUTIONS",
}

export default Types;
