import React from "react";
import { useState, useCallback } from "react";

import {
  addAppNotification,
  fetchControllerConfigs,
  removeAppNotification,
  addAcademyPaymentDetails,
  updateAcademyPaymentDetail,
} from "../../../../../redux";
import { AcademyCandidateEnrollmentPaymentDetails } from "../../../../../redux/AcademyCandidateEnrollments/types";
import { EmployeesItem } from "../../../../../redux/employees/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  AppNotificationsType,
  AcademyCandidateEnrollmentPaymentDetails as AcademyCandidateEnrollmentPaymentDetailsForm,
} from "../../../../../types";
import { validatePhone } from "../../../../../utils";

interface useMultiStepFormType {
  onTextChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDateChange: (value: Date, name: string) => void;
  handleCheckbox: (e: React.MouseEvent) => void;
  salaryModal: "add" | "edit" | "";
  handlePaymentDetailsModal: () => void;
  addPaymentDetailsModal: () => void;
  editPaymentDetailsModal: (id: string) => void;
  formData: AcademyCandidateEnrollmentPaymentDetailsForm;
  loading: boolean;
  onSubmitAdd: () => void;
  onSubmitUpdate: () => void;
}
interface Props {
  paymentDetails: AcademyCandidateEnrollmentPaymentDetails[];
  fetchPaymentDetails: () => void;
  candidateEnrollmentId?: string;
}

const defaultAcademyCandidateEnrollmentPaymentDetails: AcademyCandidateEnrollmentPaymentDetailsForm =
  {
    installmentNumber: {
      value: 0,
      required: false,
      error: "",
    },
    amountPaid: {
      value: 0,
      required: false,
      error: "",
    },
    taxedAmount: {
      value: 0,
      required: false,
      error: "",
    },
    invoiceNumber: {
      value: "",
      required: false,
      error: "",
    },
    invoicedDate: {
      value: null,
      required: false,
      error: "",
    },
    installmentPaid: {
      value: 0,
      required: false,
      error: "",
    },
    invoiceAttached: {
      value: false,
      required: false,
      error: "",
    },
    paymentReceiptAttached: {
      value: false,
      required: false,
      error: "",
    },
    paymentDate: {
      value: null,
      required: false,
      error: "",
    },
  };

export const useAcademyPaymentDetails = ({
  paymentDetails,
  fetchPaymentDetails,
  candidateEnrollmentId,
}: Props): useMultiStepFormType => {
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const dispatch = useAppDispatch();

  const [salaryModal, setPaymentDetailsModal] = useState<"add" | "edit" | "">("");
  const [currentRecordId, setCurrentRecordId] = useState<string>("");
  const [formData, setFormData] = useState<AcademyCandidateEnrollmentPaymentDetailsForm>(
    defaultAcademyCandidateEnrollmentPaymentDetails,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const clearFormData = useCallback(() => {
    const data = { ...formData };
    Object.keys(formData).map((field) => {
      let value: number | boolean | string = 0;
      if (["invoiceAttached", "paymentReceiptAttached"].includes(field)) {
        value = false;
      } else if (field === "invoiceNumber") {
        value = "";
      }
      data[field].value = value;
    });
    setFormData(data);
  }, [formData]);

  const handlePaymentDetailsModal = useCallback(() => {
    setPaymentDetailsModal("");
    clearFormData();
  }, [clearFormData]);

  const addPaymentDetailsModal = useCallback(() => {
    clearFormData();
    setPaymentDetailsModal("add");
  }, [clearFormData]);

  const rehydrateFormData = useCallback(
    (item?: AcademyCandidateEnrollmentPaymentDetails) => {
      const data = { ...formData };
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (field === "type") {
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const editPaymentDetailsModal = useCallback(
    (id = "") => {
      setCurrentRecordId(id);
      const item = paymentDetails.find((item) => item._id === id);
      rehydrateFormData(item);
      setPaymentDetailsModal("edit");
    },
    [paymentDetails, rehydrateFormData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );
  const onDateChange = useCallback(
    (value: Date, name: string) => {
      let tempFormData = { ...formData };
      tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },

    [formData],
  );

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        console.log(name, formData[name]?.value, value);
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;
    Object.keys(tempFormData).map((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (
        ["emergencyMobile", "alternateMobile"].includes(item) &&
        !validatePhone(String(validValue))
      ) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid phone number with country code",
          },
        };
      } else if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      installmentNumber,
      amountPaid,
      taxedAmount,
      invoiceNumber,
      invoicedDate,
      paymentDate,
      installmentPaid,
      invoiceAttached,
      paymentReceiptAttached,
    } = formData;

    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const data: AcademyCandidateEnrollmentPaymentDetails = {
        installmentNumber: installmentNumber.value,
        amountPaid: amountPaid.value,
        taxedAmount: taxedAmount.value,
        invoiceNumber: invoiceNumber.value,
        invoicedDate: invoicedDate.value,
        paymentDate: paymentDate.value,
        installmentPaid: installmentPaid.value,
        invoiceAttached: invoiceAttached.value,
        paymentReceiptAttached: paymentReceiptAttached.value,
        candidateEnrollmentId: candidateEnrollmentId,
      };
      const payload = {
        data,
        onSuccess: (data: EmployeesItem) => {
          setPaymentDetailsModal("");
          fetchPaymentDetails();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation added successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          // setPaymentDetailsModal("");
        },
      };
      dispatch(addAcademyPaymentDetails(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    candidateEnrollmentId,
    fetchPaymentDetails,
  ]);

  const onSubmitUpdate = useCallback(() => {
    const {
      installmentNumber,
      amountPaid,
      taxedAmount,
      invoiceNumber,
      invoicedDate,
      paymentDate,
      installmentPaid,
      invoiceAttached,
      paymentReceiptAttached,
    } = formData;

    const submit = validateForm();

    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const payload = {
        id: currentRecordId || "",
        data: {
          installmentNumber: installmentNumber.value,
          amountPaid: amountPaid.value,
          taxedAmount: taxedAmount.value,
          invoiceNumber: invoiceNumber.value,
          invoicedDate: invoicedDate.value,
          paymentDate: paymentDate.value,
          installmentPaid: installmentPaid.value,
          invoiceAttached: invoiceAttached.value,
          paymentReceiptAttached: paymentReceiptAttached.value,
          candidateEnrollmentId: candidateEnrollmentId,
        },

        onSuccess: (data: EmployeesItem) => {
          setPaymentDetailsModal("");
          fetchPaymentDetails();
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Designation updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyPaymentDetail(payload));
    }
  }, [
    formData,
    validateForm,
    notificationsId,
    dispatch,
    currentRecordId,
    candidateEnrollmentId,
    fetchPaymentDetails,
  ]);

  return {
    onTextChange,
    onDateChange,
    handleCheckbox,
    salaryModal,
    handlePaymentDetailsModal,
    addPaymentDetailsModal,
    editPaymentDetailsModal,
    formData,
    loading,
    onSubmitAdd,
    onSubmitUpdate,
  };
};
