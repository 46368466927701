import styled from "styled-components";

const styles = {};

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  /* flex: 1; */
`;

export const ItemContainer = styled.div`
  max-width: 18vw !important;
  align-self: center;
  border-radius: 10px;
  background-color: white;
  margin-top: 4px;
  word-wrap: break-word;
  padding: 1% 4%;
`;

export const Item = styled.p`
  font-size: 1.2rem;
  span {
    font-weight: 600;
  }
`;
