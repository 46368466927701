import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import QueryBuilder, { defaultValidator } from "react-querybuilder";
import { useParams } from "react-router-dom";

import { NotificationsDefinitionsControllerProps } from "../../../../types/models/NotificationsDefinitions";
import { ActiveSelectOptions, EditUpdateAddSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { QueryBuilderContainer, Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData, Checkbox } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { FormTitle } from "../Employees/styles";

import { useNotificationsDefinitionsController } from "./hooks";

const Controller = ({ type }: NotificationsDefinitionsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { usersOptions, groupsOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchNotificationsDefinitions,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    TableList,
    handleCheckbox,
    getTableFilters,
    query,
    handleQuerychange,
  } = useNotificationsDefinitionsController({ type, id, attachmentsPickerRef });
  const currentNotificationsDefinitions = data;

  const {
    workLogs,
    attachments,
    name,
    active,
    description,
    emailSchedular,
    insert,
    table,
    triggered,
    update,
    watchListGroup,
    watchListUser,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchNotificationsDefinitions();
      console.log(fetchNotificationsDefinitions());
    }

    // Do not include fetchNotificationsDefinitions to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
  ]);

  const renderWhenToSend = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <Select
            width={"48%"}
            name={"table"}
            label={"Table"}
            options={TableList}
            value={table.value}
            onChange={onTextChange}
            mandatory={table.required}
            error={table.error}
          />
          <Select
            width={"48%"}
            name={"triggered"}
            label={"Triggered"}
            options={EditUpdateAddSelectOptions}
            value={triggered.value}
            onChange={onTextChange}
            mandatory={triggered.required}
            error={triggered.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"emailSchedular"}
          label={"Email Schedular"}
          options={usersOptions}
          isMulti
          value={emailSchedular.value}
          onChange={onTextChange}
          mandatory={emailSchedular.required}
          error={emailSchedular.error}
        />
        <Row>
          <Checkbox
            label={"Insert"}
            width='48%'
            error={insert.error}
            required={insert.required}
            onClick={handleCheckbox}
            value={insert.value}
            attributes={{
              "data-name": "insert",
            }}
          />
          <Checkbox
            label={"Update"}
            width='48%'
            error={update.error}
            required={update.required}
            onClick={handleCheckbox}
            value={update.value}
            attributes={{
              "data-name": "update",
            }}
          />
        </Row>
        <QueryBuilderContainer>
          <FormTitle>{"Condition Builder"}</FormTitle>
          <QueryBuilder
            translations={{
              addRule: {
                label: "Add Rule",
              },
              addGroup: {
                label: "Add Group",
              },
              removeRule: {
                label: "Delete Rule",
              },
              removeGroup: {
                label: "Delete Group",
              },
            }}
            fields={getTableFilters}
            query={query}
            onQueryChange={handleQuerychange}
            addRuleToNewGroups
            debugMode
            independentCombinators
            showCombinatorsBetweenRules
            validator={defaultValidator}
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          />
        </QueryBuilderContainer>
      </React.Fragment>
    );
  }, [
    TableList,
    emailSchedular.error,
    emailSchedular.required,
    emailSchedular.value,
    getTableFilters,
    handleCheckbox,
    handleQuerychange,
    insert.error,
    insert.required,
    insert.value,
    onTextChange,
    query,
    table.error,
    table.required,
    table.value,
    triggered.error,
    triggered.required,
    triggered.value,
    update.error,
    update.required,
    update.value,
    usersOptions,
  ]);
  const renderWhoWillRecieve = useMemo(() => {
    return (
      <React.Fragment>
        <Select
          width={"100%"}
          name={"watchListUser"}
          label={"Watch List User"}
          options={usersOptions}
          isMulti
          value={watchListUser.value}
          onChange={onTextChange}
          mandatory={watchListUser.required}
          error={watchListUser.error}
        />
        <Select
          width={"100%"}
          name={"watchListGroup"}
          label={"Watch List Group"}
          options={groupsOptions}
          isMulti
          value={watchListGroup.value}
          onChange={onTextChange}
          mandatory={watchListGroup.required}
          error={watchListGroup.error}
        />
      </React.Fragment>
    );
  }, [
    groupsOptions,
    onTextChange,
    usersOptions,
    watchListGroup.error,
    watchListGroup.required,
    watchListGroup.value,
    watchListUser.error,
    watchListUser.required,
    watchListUser.value,
  ]);
  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentNotificationsDefinitions?.createdBy !== "string"
        ? currentNotificationsDefinitions?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentNotificationsDefinitions?.updatedBy !== "string"
        ? currentNotificationsDefinitions?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentNotificationsDefinitions?.createdAt}
        updatedAt={currentNotificationsDefinitions?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentNotificationsDefinitions?.createdAt,
    currentNotificationsDefinitions?.createdBy,
    currentNotificationsDefinitions?.updatedAt,
    currentNotificationsDefinitions?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.notificationsDefinations.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.notificationsDefinations.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderWhenToSend;
      case 2:
        return renderWhoWillRecieve;
      case 3:
        return renderChecklist;
      case 4:
        return renderActivitylogs;
      case 5:
        return renderWorklogs;
      case 6:
        return renderSystemData;

      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderWhenToSend,
    renderWhoWillRecieve,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const NotificationsDefinitionsController = memo(Controller);
