import { ColumnsType, useTableColumnsProps } from "../../types";
import { collections } from "../../utils";
import {
  getAddressHistoryColumns,
  getBankDetailsColumns,
  getBenefitsColumns,
  getCategoriesColumns,
  getCertificationAccrediationColumns,
  getChecklistTemplateColumns,
  getCompanyColumns,
  getCompanyContactColumns,
  getCountryColumns,
  getCurrencyColumns,
  getDepartmentsColumns,
  getDesignationsColumns,
  getEducationDetailsColumns,
  getEmploymentHistoryColumns,
  getForm16Columns,
  getPIIDetailsColumns,
  getPlatformModulesColumns,
  getSubCategoriesColumns,
  getTagsColumns,
  getAcademyACHColumns,
  getAcademyBatchColumns,
  getSPSAccountsColumns,
  getSPSBGCheckColumns,
  getSPSPaperworkColumns,
  getSPSProjectsColumns,
  getAcademyCoursesColumns,
  getProjectHistoryColumns,
  getCatalogAdministrationsColumns,
  getKanbanConfigColumns,
  getSPSInsuranceColumns,
  getWorkspaceColumns,
  getSpaceColumns,
  getNotificationsDefinitionsColumns,
  getLeadListsColumns,
  getCampaignsAssetsColumns,
  getCampaignsColumns,
} from "../columns";
import { getAcademyCandidateEnrollmentsColumns } from "../columns/AcademyCandidateEnrollments";
import { getAcademyCandidateLeadsColumns } from "../columns/AcademyCandidateLeads";
import { getAcademyCandidatesColumns } from "../columns/AcademyCandidates";
import { getAcademyMentorFeedbackColumns } from "../columns/AcademyMentorFeedback";
import { getAcademyStudentProfileColumns } from "../columns/AcademyStudentProfile";
import { getEmailSchedularColumns } from "../columns/EmailSchedular";
import { getEmployeesColumns } from "../columns/Employees";
import { getNewsletterLeadsColumns } from "../columns/NewsletterLeads";
import { getNewsletterResourcesColumns } from "../columns/NewsletterResources";
import { getResourcesColumns } from "../columns/Resources";
import { getServiceNowSolutionsColumns } from "../columns/ServiceNowSolutions";
import { getSPSAccountContactColumns } from "../columns/SPSAccountContact";
import { getSPSSalesProfileColumns } from "../columns/SPSSalesProfile";
import { getSubscriberColumns } from "../columns/Subscriber";
import { getVariablesColumns } from "../columns/Variables";
import { getVariableSetsColumns } from "../columns/VariableSets";

import { getSPSDealsColumns } from "./../columns/SPSDeals";

export const useTableColumns = ({ collection, fields }: useTableColumnsProps): ColumnsType[] => {
  switch (collection) {
    case collections.employees.name:
      return getEmployeesColumns({ fields });
    case collections.academyCourses.name:
      return getAcademyCoursesColumns({ fields });
    case collections.academyACH.name:
      return getAcademyACHColumns({ fields });
    case collections.academyBatch.name:
      return getAcademyBatchColumns({ fields });
    case collections.academyCandidateEnrollments.name:
      return getAcademyCandidateEnrollmentsColumns({ fields });
    case collections.academyCandidateLeads.name:
      return getAcademyCandidateLeadsColumns({ fields });
    case collections.academyCandidates.name:
      return getAcademyCandidatesColumns({ fields });
    case collections.academyMentorFeedback.name:
      return getAcademyMentorFeedbackColumns({ fields });
    case collections.academyStudentProfile.name:
      return getAcademyStudentProfileColumns({ fields });
    case collections.spsAccounts.name:
      return getSPSAccountsColumns({ fields });
    case collections.benefits.name:
      return getBenefitsColumns({ fields });
    case collections.piiDetails.name:
      return getPIIDetailsColumns({ fields });
    case collections.bankdetails.name:
      return getBankDetailsColumns({ fields });
    case collections.form16.name:
      return getForm16Columns({ fields });
    case collections.addressHistory.name:
      return getAddressHistoryColumns({ fields });
    case collections.educationDetails.name:
      return getEducationDetailsColumns({ fields });
    case collections.employmentHistory.name:
      return getEmploymentHistoryColumns({ fields });
    case collections.company.name:
      return getCompanyColumns({ fields });
    case collections.country.name:
      return getCountryColumns({ fields });
    case collections.currency.name:
      return getCurrencyColumns({ fields });
    case collections.departments.name:
      return getDepartmentsColumns({ fields });
    case collections.platformModules.name:
      return getPlatformModulesColumns({ fields });
    case collections.companyContact.name:
      return getCompanyContactColumns({ fields });
    case collections.categories.name:
      return getCategoriesColumns({ fields });
    case collections.designations.name:
      return getDesignationsColumns({ fields });
    case collections.checklistTemplate.name:
      return getChecklistTemplateColumns({ fields });
    case collections.subcategories.name:
      return getSubCategoriesColumns({ fields });
    case collections.modules.name:
      return getTagsColumns({ fields });
    case collections.certificationsAccreditations.name:
      return getCertificationAccrediationColumns({ fields });
    case collections.spsDeals.name:
      return getSPSDealsColumns({ fields });
    case collections.spsAccountContact.name:
      return getSPSAccountContactColumns({ fields });
    case collections.spsBGCheck.name:
      return getSPSBGCheckColumns({ fields });
    case collections.spsProjects.name:
      return getSPSProjectsColumns({ fields });
    case collections.spsPaperwork.name:
      return getSPSPaperworkColumns({ fields });
    case collections.projectHistory.name:
      return getProjectHistoryColumns({ fields });
    case collections.catalogAdministrations.name:
      return getCatalogAdministrationsColumns({ fields });
    case collections.variableSets.name:
      return getVariableSetsColumns({ fields });
    case collections.variables.name:
      return getVariablesColumns({ fields });
    case collections.emailSchedular.name:
      return getEmailSchedularColumns({ fields });
    case collections.kanbanConfig.name:
      return getKanbanConfigColumns({ fields });
    case collections.serviceNowSolutions.name:
      return getServiceNowSolutionsColumns({ fields });
    case collections.spsSalesProfile.name:
      return getSPSSalesProfileColumns({ fields });
    case collections.spsInsurance.name:
      return getSPSInsuranceColumns({ fields });
    case collections.resources.name:
      return getResourcesColumns({ fields });
    case collections.workspaces.name:
      return getWorkspaceColumns({ fields });
    case collections.spaces.name:
      return getSpaceColumns({ fields });
    case collections.notificationsDefinations.name:
      return getNotificationsDefinitionsColumns({ fields });
    case collections.subscribers.name:
      return getSubscriberColumns({ fields });
    case collections.newsletterResources.name:
      return getNewsletterResourcesColumns({ fields });
    case collections.leadLists.name:
      return getLeadListsColumns({ fields });
    case collections.campaignsAssets.name:
      return getCampaignsAssetsColumns({ fields });
    case collections.campaigns.name:
      return getCampaignsColumns({ fields });
    case collections.newsletterLeads.name:
      return getNewsletterLeadsColumns({ fields });
    default:
      return [];
  }
};
