const navigationLinks = {
  knowledge: {
    details: (id) => {
      return `/ep/knowledge/${id}/details`;
    },
  },
  tickets: {
    details: (id) => {
      return `/ep/tickets/all/${id}/details`;
    },
  },
};

export default navigationLinks;
