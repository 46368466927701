import React from "react";

import { Layout } from "../../../presentationals";
import { VariablesController } from "../../../presentationals/screens/admin/Variables/VariablesController";

const Route = () => {
  return <Layout activity={<VariablesController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APVariablesEdit = React.memo(Route);
