import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
`;

export const FileContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  overflow: auto;
  margin-top: 20px;
  background: green;
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
`;
