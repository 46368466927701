/* eslint-disable no-unused-vars */
enum Types {
  FETCH_DESIGNATIONS = "FETCH_DESIGNATIONS",
  SUCCESS_FETCH_DESIGNATIONS = "SUCCESS_FETCH_DESIGNATIONS",
  ERROR_FETCH_DESIGNATIONS = "ERROR_FETCH_DESIGNATIONS",
  CREATE_DESIGNATION = "CREATE_DESIGNATION",
  UPDATE_DESIGNATION = "UPDATE_DESIGNATION",
  DELETE_DESIGNATION = "DELETE_DESIGNATION",
  FETCH_DESIGNATION_BY_ID = "FETCH_DESIGNATION_BY_ID",
}

export default Types;
