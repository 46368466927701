import React, { useEffect, useState } from "react";

import { sha256 } from "js-sha256";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Toast from "../../components/Toast/toast";
import { updateProfile } from "../../redux/user/actions";
import { useTitle, MomentDate, AttributesValues } from "../../services/methods";

import { MainContainer, Header, ItemContainer, Item } from "./RecruitmentHistory.styles";

const RecruitmentHistory = (props) => {
  useTitle("Recruitment History");
  const params = useParams();
  const [data, setData] = useState([]);
  const [infoReceivedDate, setInfoReceivedDate] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [checklist, setCheckList] = useState([]);
  useEffect(() => {
    if (props.recruits.length > 0) {
      const find = props.recruits.find((item) => item._id === params.id);
      if (find?.assignments?.length > 0) {
        setInfoReceivedDate(find.createdAt);
        setData(find.assignments);
      }
      const checklist = [
        {
          title: "Offerletter Prepared ?:",
          value: find?.checklist?.offerPrepared === true ? "Yes" : "No",
        },
        {
          title: "Offerletter Prepared Comments:\n",
          value: find?.checklist?.offerPreparedComment,
        },
        {
          title: "Offerletter Sent ?:",
          value: find?.checklist?.offerSent === true ? "Yes" : "No",
        },
        {
          title: "Offerletter Sent Comments:\n",
          value: find?.checklist?.offerSentComment,
        },
        {
          title: "Offerletter Received ?:",
          value: find?.checklist?.offerReceived,
        },
        {
          title: "Offerletter Received Comments:\n",
          value: find?.checklist?.offerReceivedComment,
        },
        {
          title: "Offer Accepted ?:",
          value: find?.checklist?.offerAccepted === true ? "Yes" : "No",
        },
        {
          title: "Feedback:\n",
          value: find?.checklist?.feedback,
        },
      ];
      setCheckList(checklist);
      const survey = [
        {
          value: find?.introductionRating,
          title: "Introduction Rating",
        },
        {
          value: find?.bindingContract,
          title: "Binding Contract",
        },
        {
          value: AttributesValues("typeCandidate", find?.typeOfCandidate),
          title: "Type of Candidate",
        },
        {
          value: AttributesValues("levelCandidate", find?.levelOfCandidate),
          title: "Level of Candidate",
        },
        {
          value: generateSkills(find?.technicalSkills),
          title: "Technical Skills",
        },
        { value: find?.softSkills, title: "Soft Skills:\n" },
        { value: find?.feedback, title: "Feedback:\n" },
      ];
      setSurvey(survey);
    }
  }, [params.id, props.recruits]);

  const generateSkills = (skills) => {
    if (!Array.isArray(skills)) {
      return "";
    }
    const skill = [];
    for (let i = 0; i < skills.length; i++) {
      skill.push(skills[i]?.label);
    }
    return skill.join(",");
  };

  const findTab = (bucket) => {
    const tab = props.tabs.find((item) => {
      return String(item.bucket) === String(bucket);
    });
    return tab;
  };

  const findEmployee = (id) => {
    const employee = props.employees.find((item) => {
      return item._id === id;
    });
    return employee;
  };

  return (
    <MainContainer>
      <Header>
        <h2>Recruitment History</h2>
      </Header>
      <hr width={"100%"} />
      {data.map((item, index) => {
        const PrevBucket = item.bucketId - 1;
        const CurrentBucket = item.bucketId;
        return (
          <div key={index}>
            {PrevBucket === 0 ? (
              <div>
                <Item>{findTab(PrevBucket)?.label}</Item>
                <ItemContainer>
                  <Item>
                    <span>Date:</span>{" "}
                    {MomentDate({
                      date: infoReceivedDate,
                      format: "MMMM-DD-YYYY",
                    })}
                  </Item>
                </ItemContainer>
                <ItemContainer>
                  <Item>
                    <span>Taken By:</span> {findEmployee(item.takenBy)?.label}
                  </Item>
                </ItemContainer>
                <ItemContainer>
                  <Item>
                    <span>Feedback:</span>
                    <br /> {item.feedback}
                  </Item>
                </ItemContainer>
                <hr />
                <Item>{findTab(CurrentBucket)?.label}</Item>
                <ItemContainer>
                  <Item>
                    <span>Date Scheduled:</span>
                    {MomentDate({
                      date: item.dateScheduled,
                      format: "MMMM-DD-YYYY",
                    })}
                  </Item>
                </ItemContainer>
                <ItemContainer>
                  <Item>
                    <span>Assigned To:</span> {findEmployee(item.assignedTo)?.label}
                  </Item>
                </ItemContainer>
                <ItemContainer>
                  <Item>
                    <span>Meeting Invite:</span> {item.meetingInvite}
                  </Item>
                </ItemContainer>
                <ItemContainer>
                  <Item>
                    <span>Taken By:</span> {findEmployee(data[index + 1]?.takenBy)?.label}
                  </Item>
                </ItemContainer>{" "}
                <ItemContainer>
                  <Item>
                    <span>Feedback:</span>
                    <br /> {data[index + 1]?.feedback}
                  </Item>
                </ItemContainer>
              </div>
            ) : (
              <div>
                <Item>{findTab(CurrentBucket)?.label}</Item>
                {CurrentBucket !== -1 && (
                  <div>
                    <ItemContainer>
                      <Item>
                        <span>Date Schdeled:</span>
                        {item.dateScheduled
                          ? MomentDate({
                              date: item.dateScheduled,
                              format: "MMMM-DD-YYYY",
                            })
                          : ""}
                      </Item>
                    </ItemContainer>
                    <ItemContainer>
                      <Item>
                        <span>Assigned To:</span> {findEmployee(item.assignedTo)?.label}
                      </Item>
                    </ItemContainer>
                    <ItemContainer>
                      <Item>
                        <span>Meeting Invite:</span> {item.meetingInvite}
                      </Item>
                    </ItemContainer>
                    <ItemContainer>
                      <Item>
                        <span>Taken By:</span> {findEmployee(data[index + 1]?.takenBy)?.label}{" "}
                      </Item>
                    </ItemContainer>
                    <ItemContainer>
                      <Item>
                        <span>Feedback:</span>
                        <br /> {data[index + 1]?.feedback}{" "}
                      </Item>
                    </ItemContainer>
                  </div>
                )}
              </div>
            )}

            <hr />
          </div>
        );
      })}
      <>
        <Header>
          <h2>Recruitment Survey</h2>
        </Header>
        <div>
          {survey.map((item, index) => {
            if (item?.value) {
              return (
                <ItemContainer>
                  <Item key={index}>
                    <span>{item.title}</span> {item.value}
                  </Item>
                </ItemContainer>
              );
            }
          })}
        </div>
      </>

      <hr width={"100%"} />
      <>
        <Header>
          <h2>Recruitment Checklist</h2>
        </Header>
        <div>
          {checklist.map((item, index) => {
            if (item?.value) {
              return (
                <ItemContainer>
                  <Item key={index}>
                    <span>{item.title}</span> {item.value}
                  </Item>
                </ItemContainer>
              );
            }
          })}
        </div>
      </>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  tabs: state.RecruitsReducer.tabs,
  recruits: state.RecruitsReducer.recruits,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
});

const mapDispatchToProps = {
  //   updateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentHistory);
