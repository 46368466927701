import React, { useEffect, useState } from "react";

import moment from "moment-timezone";
import { MdArrowRight, MdArrowRightAlt } from "react-icons/md";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import StyledTable from "../../components/Table/tables.styles";
import {
  fetchPayrollData,
  payrollsRehydrate,
  updatePayrollData,
} from "../../redux/employees/actions";
import { AttributesValues, currencyFormat, useTitle, MomentDate } from "../../services/methods";

import styles from "./PayrollsDetailsController.styles";

const PayrollsEmployeesDetailsController = (props) => {
  useTitle("Payrolls List");
  const params = useParams();
  const [id, setId] = useState();
  const [tax, setTax] = useState("");
  const [employee, setEmployee] = useState();
  const [data, setData] = useState({});
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (props.payrolls.length > 0 && (id !== params.id || employee !== params.employee)) {
      Hydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payrolls, params.id, params.employee]);

  const Hydrate = (data) => {
    const array = data?.length > 0 ? data : props.payrolls;
    const findObject = array.find((item) => {
      return item._id.toString() === params.id;
    });
    const findEmployeeObject = findObject.list.find(
      (item) => item?.employee?._id.toString() === params?.employee,
    );
    if (findEmployeeObject) {
      setId(params.id);
      setEmployee(params.employee);
      setData({
        date: findObject.date,
        workingdays: findObject.workingdays,
        list: findEmployeeObject,
      });
      if (findEmployeeObject.salaryTax) setTax(findEmployeeObject.salaryTax);
      setComment("");
      setSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    props.updatePayrollData({
      id: id,
      data: data,
      onSuccess: (data) => {
        props.fetchPayrollData({
          onSuccess: (data) => {
            Hydrate(data);
            props.payrollsRehydrate(true);
          },
          onError: (error) => {},
        });
      },
      onError: (error) => {},
    });
  };

  const DisplayButton = (status) => {
    let output;
    switch (status) {
      case "published":
        output = (
          <Button
            type='cancel'
            disabled={false}
            label='Unpublish'
            onClick={() => {
              onSubmit({
                list: [
                  {
                    status: "drafted",
                    employee: employee,
                  },
                ],
              });
            }}
          />
        );
        break;
      case "review":
        output = (
          <>
            <Input
              readOnly={!props.permissions.payrolls_edit}
              label={"Comment"}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              type={"textarea"}
            />
            <br />
            <Input
              type={"number"}
              label={"Tax"}
              value={tax}
              name='tax'
              autoComplete={"off"}
              onChange={(event) => {
                const value = event.target.value;
                setTax(value);
              }}
              mandatory={true}
            />
            <br />
            <Button
              onClick={() =>
                onSubmit({
                  status: "published",
                  comment: comment,
                  commentEmployee: employee,
                  salaryTax: tax,
                })
              }
              disabled={submitting || !props.permissions.payrolls_edit || !tax}
              label={submitting ? "Processing" : "Comment & Publish"}
            />
          </>
        );
        break;
      case "accepted":
        output = (
          <>
            <Button disabled={true} label='Accepted' />
            <center>
              Accepted On
              {MomentDate({
                date: data.list.acceptedAt,
                format: "MMMM-DD-YYYY",
              })}
            </center>
            {/* <br />
            <br />
            <br />
            <Input
              type={"number"}
              label={"Tax"}
              value={tax}
              name='tax'
              autoComplete={"off"}
              onChange={(event) => {
                const value = event.target.value;
                setTax(value);
              }}
              mandatory={true}
            /> */}
            {/* <Button
              onClick={() =>
                onSubmit({
                  list: [{ employee: employee, status: "taxed", salaryTax: tax }],
                })
              }
              disabled={submitting || tax === "" || !props.permissions.payrolls_edit}
              label={submitting ? "Processing" : "Update Tax"}
            /> */}
          </>
        );
        break;
      case "taxed":
        output = (
          <>
            <Button disabled={true} label='Accepted &amp; Taxed' />
            <center>
              Accepted On
              {MomentDate({
                date: data.list.acceptedAt,
                format: "MMMM-DD-YYYY",
              })}
            </center>
          </>
        );
        break;
      case "drafted":
        output = (
          <>
            <br />
            <Input
              type={"number"}
              label={"Tax"}
              value={tax}
              name='tax'
              autoComplete={"off"}
              onChange={(event) => {
                const value = event.target.value;
                setTax(value);
              }}
              mandatory={true}
            />
            <br />
            <br />
            <Button
              size='small'
              onClick={() => {
                onSubmit({
                  status: "published",
                  comment: comment,
                  commentEmployee: employee,
                  salaryTax: tax,
                });
              }}
              label={submitting ? "Processing" : "Publish"}
              disabled={submitting || !props.permissions.payrolls_edit || tax === ""}
            />
          </>
        );
      default:
        break;
    }
    return output;
  };

  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>Payroll Status</h2>
      </styles.Header>
      {DisplayButton(data?.list?.status)}
      {data?.list?.comments?.map((comment, index) => (
        <div key={index}>
          {MomentDate({
            date: comment.date,
            format: "MMMM-DD-YYYY",
          })}
          <br />
          {comment?.from?.name}
          <br />
          {comment?.message}
          <br />
          <br />
        </div>
      ))}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  payrolls: state.EmployeesReducer.payrolls,
  permissions: state.UserReducer.permissions,
});

const mapDispatchToProps = {
  fetchPayrollData,
  updatePayrollData,
  payrollsRehydrate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsEmployeesDetailsController);
