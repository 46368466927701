import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from "react";

import { images } from "../../../../theme";
import { Button } from "../../atoms";
import { Switch } from "../../atoms/Switch/Switch";
import Search from "../../Search";
import { Popup } from "../Popup/Popup";

import {
  ActionButtonContainer,
  ActionContainer,
  ActiveUserContainer,
  ButtonContainer,
  CloseIcon,
  CloseModalButton,
  DropdownListContainer,
  FooterContainer,
  Icon,
  ListContainer,
  ListHeadContainer,
  ListHeading,
  ListSubHeadingContainer,
  ModalHead,
  ModalHeading,
  SectionContainer,
  SubHeadingContainer,
  UserContainer,
  UsersContainer,
} from "./styles";

import "./style.css";
import { PermissionsPropsTypes, User } from "./index";

export const SlushTable = ({
  open,
  setOpen,
  AvailableList,
  SelectedList,
  fullAccessActive,
  setAvailableList,
  setSelectedList,
  noRecordMessage = "No Users Found",
  groupPermissions,
  title,
}: PermissionsPropsTypes) => {
  const [availableSelectAll, setAvailableSelectAll] = useState<number>(0);
  const [selectedSelectAll, setSelectedSelectAll] = useState<number>(0);
  const [fullAccess, setFullAccess] = useState<number>(0);
  const [selectUser, setSelectUser] = useState<User[]>([]);
  const [renderAvailableList, setRenderAvailableList] = useState(AvailableList);
  const [renderSelectedList, setRenderSelectedList] = useState(SelectedList);
  const [availableSearchInput, setAvailableSearchInput] = useState("");
  const [selectedSearchInput, setSelectedSearchInput] = useState("");

  useEffect(() => {
    setRenderAvailableList(AvailableList);
  }, [AvailableList]);
  useEffect(() => {
    setRenderSelectedList(SelectedList);
  }, [SelectedList]);

  // ================== Handle Select/Deselect User ================
  const handleUserSelect = useCallback(
    (user) => {
      if (!selectUser.includes(user)) {
        setSelectUser((prev) => [...prev, user]);
      } else {
        setSelectUser((prev) => prev.filter((selectedUser) => selectedUser !== user));
      }
    },
    [selectUser],
  );

  //=================== Handles Select/Deselect All Available List===============
  const handleAvailableSelectALL = useCallback(
    (value: number) => {
      setAvailableSelectAll((prev) => (prev ? 0 : 1));
      if (!value) {
        renderAvailableList?.map((user) => {
          if (!selectUser.includes(user)) {
            setSelectUser((prev) => [...prev, user]);
          }
        });
      } else {
        setSelectUser([]);
      }
    },
    [renderAvailableList, selectUser, setAvailableSelectAll, setSelectUser],
  );
  //=================== Handles Select/Deselect All Selected List===============
  const handleSelectedSelectALL = useCallback(
    (value: number) => {
      setSelectedSelectAll((prev) => (prev ? 0 : 1));
      if (!value) {
        renderSelectedList?.map((user) => {
          if (!selectUser.includes(user)) {
            setSelectUser((prev) => [...prev, user]);
          }
        });
      } else {
        setSelectUser([]);
      }
    },
    [renderSelectedList, selectUser, setSelectedSelectAll, setSelectUser],
  );
  // ================== Gives Full Access To Selectd List====================
  const handleFullAccess = useCallback(() => {
    setFullAccess((prev) => (prev ? 0 : 1));
    if (!fullAccess) {
      setRenderSelectedList((prev) => {
        return [...prev, ...renderAvailableList];
      });
    } else {
      setRenderAvailableList(AvailableList);
      setRenderSelectedList(SelectedList);
    }
  }, [setFullAccess, fullAccess, renderAvailableList, AvailableList, SelectedList]);

  // =================== Handle User to transfer user from available to selected ===========
  const handleAvailableToSelected = useCallback(
    (list: User[]) => {
      // Update isSelected property in the provided list
      const updatedList = list;
      // .filter((user) => !renderSelectedList.includes(user)) // Filter out users already in SelectedList
      // .map((user) => {
      //   user.isSelected = true;
      //   return user;
      // });
      setRenderSelectedList((prev) => [...prev, ...updatedList.filter(Boolean)]); // Filter out undefined values
      setSelectUser([]);
      setAvailableSelectAll(0);
      setSelectedSelectAll(0);
    },
    [setRenderSelectedList, setSelectUser, setAvailableSelectAll, setSelectedSelectAll],
  );

  // =================== Handle User to transfer user from selected to available ===========
  const handleSelectedToAvailable = useCallback(
    (list: User[]) => {
      // Update isSelected property in the provided list
      const updatedList = list
        .filter((user) => !renderAvailableList.includes(user)) // Filter out users already in AvailableList
        .map((user) => {
          user.isSelected = false;
          return user;
        });

      // Filter out selected users from SelectedList
      const remainingUsers = renderSelectedList?.filter((user) => !list.includes(user));

      // Update availableList, selectedList, and selectUser
      setRenderSelectedList(remainingUsers || []); // Ensure setSelectedList receives a valid array
      setRenderAvailableList((prev) => [...prev, ...updatedList.filter(Boolean)]); // Filter out undefined values
      setSelectUser([]);
      setAvailableSelectAll(0);
      setSelectedSelectAll(0);
    },
    [
      renderAvailableList,
      renderSelectedList,
      setRenderSelectedList,
      setRenderAvailableList,
      setSelectUser,
      setAvailableSelectAll,
      setSelectedSelectAll,
    ],
  );

  // ================== Handle Search ============================
  const handleSearch = useCallback((list, input) => {
    const regex = new RegExp(input, "i"); // Case-insensitive regex
    return list.filter((user) => regex.test(user.label));
  }, []);

  const clearAvailableSearch = useCallback(() => {
    setAvailableSearchInput("");
    setRenderAvailableList(AvailableList);
  }, [AvailableList]);

  const clearSelectedSearch = useCallback(() => {
    setSelectedSearchInput("");
    setRenderSelectedList(SelectedList);
  }, [SelectedList]);
  function removeFirstWord(inputString) {
    // Split the input string by space
    const words = inputString?.split(" ");

    // Remove the first word
    const result = words?.slice(1).join(" ");

    return result;
  }

  // ================= Reset State ==============================
  const resetStates = useCallback(() => {
    setFullAccess(0);
    setSelectUser([]);
    setAvailableSelectAll(0);
    setSelectedSelectAll(0);
    clearAvailableSearch();
    clearSelectedSearch();
  }, [clearAvailableSearch, clearSelectedSearch]);

  // ================= Handle Modal Open/Close====================
  const handleModal = useCallback(() => {
    resetStates();
    setOpen((prevOpen) => !prevOpen);
  }, [resetStates, setOpen]);

  // ====================Handle Save Data ==================================
  const handleSaveData = useCallback(async () => {
    if (fullAccess) {
      groupPermissions(renderSelectedList, true);
      // setSelectedList(renderSelectedList);
      // setAvailableList(renderAvailableList);
      handleModal();
    } else {
      // setSelectedList(renderSelectedList);
      // setAvailableList(renderAvailableList);
      groupPermissions(renderSelectedList);
      handleModal();
    }
  }, [fullAccess, groupPermissions, renderSelectedList, handleModal]);

  // Handling the Available Search
  const handleAvailableSearch = useCallback(() => {
    const searchedList = handleSearch(renderAvailableList, availableSearchInput);
    return searchedList;
  }, [availableSearchInput, handleSearch, renderAvailableList]);
  // Handling the Selected Search
  const handleSelectedSearch = useCallback(() => {
    const searchedList = handleSearch(renderSelectedList, selectedSearchInput);
    return searchedList;
  }, [handleSearch, renderSelectedList, selectedSearchInput]);
  return (
    <>
      {open && (
        <Popup isOpen={open} closeModal={handleModal}>
          <DropdownListContainer>
            {/* Modal Head Section */}
            <ModalHead>
              <ModalHeading>{title.label}</ModalHeading>
              <CloseModalButton>
                <CloseIcon src={images.closeModal} onClick={handleModal} />
              </CloseModalButton>
            </ModalHead>

            {/* Section of Modal */}
            <div>
              {fullAccessActive && (
                <SubHeadingContainer>
                  <Switch
                    height='18px'
                    width='34px'
                    value={fullAccess}
                    onPress={handleFullAccess}
                    label='Full Access'
                  />{" "}
                </SubHeadingContainer>
              )}
              <SectionContainer>
                {/* Left Container */}
                <ListContainer>
                  <ListHeadContainer>
                    <ListHeading>Available {removeFirstWord(title.label)}</ListHeading>
                    <Switch
                      height='18px'
                      width='34px'
                      value={availableSelectAll}
                      onPress={() => handleAvailableSelectALL(availableSelectAll)}
                      label='Select All'
                    />
                  </ListHeadContainer>
                  <ListSubHeadingContainer>
                    <Search
                      containerClass='search_container'
                      inputProps={{
                        placeholder: "Search",
                        value: availableSearchInput,
                        onChange: (e) => setAvailableSearchInput(e.target.value),
                        onKeyDown: () => {
                          if (
                            availableSearchInput.trim() === "" ||
                            availableSearchInput.length <= 1
                          ) {
                            // If search input is empty, show the original list
                            setRenderAvailableList(AvailableList);
                          }
                        },
                      }}
                      clearSearch={clearAvailableSearch}
                    />
                  </ListSubHeadingContainer>
                  <UsersContainer>
                    <UsersContainer>
                      {availableSearchInput ? (
                        handleAvailableSearch().map(
                          (user: User) =>
                            !renderSelectedList.includes(user) && (
                              <React.Fragment key={user?.id}>
                                {selectUser.includes(user) ? (
                                  <ActiveUserContainer onClick={() => handleUserSelect(user)}>
                                    {user?.label}
                                  </ActiveUserContainer>
                                ) : (
                                  <UserContainer onClick={() => handleUserSelect(user)}>
                                    {user?.label}
                                  </UserContainer>
                                )}
                              </React.Fragment>
                            ),
                        )
                      ) : renderAvailableList.length ? (
                        renderAvailableList.map(
                          (user: User) =>
                            !renderSelectedList.includes(user) && (
                              <React.Fragment key={user?.id}>
                                {selectUser.includes(user) ? (
                                  <ActiveUserContainer onClick={() => handleUserSelect(user)}>
                                    {user?.label}
                                  </ActiveUserContainer>
                                ) : (
                                  <UserContainer onClick={() => handleUserSelect(user)}>
                                    {user?.label}
                                  </UserContainer>
                                )}
                              </React.Fragment>
                            ),
                        )
                      ) : (
                        <UserContainer>{noRecordMessage}</UserContainer>
                      )}
                    </UsersContainer>
                  </UsersContainer>
                </ListContainer>
                {/* Middle Container */}
                <ActionContainer>
                  <ActionButtonContainer>
                    <ButtonContainer onClick={() => handleAvailableToSelected(selectUser)}>
                      <Icon src={images.arrowRight} />
                    </ButtonContainer>
                    <ButtonContainer onClick={() => handleSelectedToAvailable(selectUser)}>
                      <Icon src={images.arrowLeft} />
                    </ButtonContainer>
                  </ActionButtonContainer>
                </ActionContainer>
                {/* Right Container */}
                <ListContainer>
                  <ListHeadContainer>
                    <ListHeading>Selected {removeFirstWord(title.label)}</ListHeading>
                    <Switch
                      height='18px'
                      width='34px'
                      value={selectedSelectAll}
                      onPress={() => handleSelectedSelectALL(selectedSelectAll)}
                      label='Select All'
                    />
                  </ListHeadContainer>
                  <ListSubHeadingContainer>
                    <Search
                      containerClass='search_container'
                      inputProps={{
                        placeholder: "Search",
                        value: selectedSearchInput,
                        onChange: (e) => setSelectedSearchInput(e.target.value),
                        onKeyDown: () => {
                          if (
                            selectedSearchInput.trim() === "" ||
                            selectedSearchInput.length <= 1
                          ) {
                            setRenderSelectedList(SelectedList);
                          }
                        },
                      }}
                      clearSearch={clearSelectedSearch}
                    />
                  </ListSubHeadingContainer>
                  <UsersContainer>
                    {selectedSearchInput ? (
                      handleSelectedSearch().map((user: User) => (
                        <React.Fragment key={user?.id}>
                          {selectUser.includes(user) ? (
                            <ActiveUserContainer onClick={() => handleUserSelect(user)}>
                              {user?.label}
                            </ActiveUserContainer>
                          ) : (
                            <UserContainer onClick={() => handleUserSelect(user)}>
                              {user?.label}
                            </UserContainer>
                          )}
                        </React.Fragment>
                      ))
                    ) : renderSelectedList?.length ? (
                      renderSelectedList.map((user: User) => (
                        <React.Fragment key={user?.id}>
                          {selectUser.includes(user) ? (
                            <ActiveUserContainer onClick={() => handleUserSelect(user)}>
                              {user?.label}
                            </ActiveUserContainer>
                          ) : (
                            <UserContainer onClick={() => handleUserSelect(user)}>
                              {user?.label}
                            </UserContainer>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <UserContainer>{noRecordMessage}</UserContainer>
                    )}
                  </UsersContainer>
                </ListContainer>
              </SectionContainer>
            </div>

            {/* Footer of Modal */}
            <FooterContainer>
              <Button title='Save' onClick={handleSaveData} />
            </FooterContainer>
          </DropdownListContainer>
        </Popup>
      )}
    </>
  );
};
