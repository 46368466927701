import styled from "styled-components";

const styles = {
  MainContainer: styled.div`
    height: 100%;
  `,
  Header: styled.div`
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
  `,
  Column: styled.div`
    flex: 1;
  `,
};

export default styles;
