import styled from "styled-components";

import { colors, fs, fonts } from "../../../../theme";

export const Container = styled.div`
  width: 32.35%;
  height: 228px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background-color: #ffffff;
  position: relative;
  overflow-y: scroll;
`;

export const CardTitle = styled.div`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  font-size: ${fs.m};
  width: 96%;
  height: 25%;
  padding-left: 4%;
  color: ${colors.common.titleColor};
  background-color: ${colors.common.primary};
  display: flex;
  align-items: center;
`;

export const BirthdaysContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 228px;
  position: relative;
`;

export const ItemContainer = styled.div`
  width: 100%;
  height: 25%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 0.3px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 5.6%;
  color: ${(props) => (props.today ? colors.common.primary : colors.common.black)};
`;

export const Name = styled.div`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-size: ${fs.m};
  padding-left: 5.4%;
  display: inline-block;
  align-items: center;
  width: 60%;
  overflow: hidden;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DOB = styled.div`
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-size: ${fs.m};
  right: 5%;

  display: flex;
  align-items: center;
  position: absolute;
`;

export const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 2rem;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  object-fit: contain;
  align-self: center;
`;

export const NoData = styled.text`
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: bold;
  font-family: ${fonts.SourceSansPro};
  width: 100%;
  height: 75%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
`;
