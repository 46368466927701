import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";
import validator from "validator";

import {
  addAppNotification,
  createAcademyCourses,
  fetchAcademyCoursesById,
  fetchControllerConfigs,
  removeAppNotification,
  updateAcademyCourses,
  uploadFile,
} from "../../../../../redux";
import {
  AcademyCourseSection,
  AcademyCoursesList,
  CreateAcademyCoursesPayload,
  FetchAcademyCoursesByIdPayload,
  UpdateAcademyCoursesPayload,
} from "../../../../../redux/AcademyCourses/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  AcademyCoursesForm,
  WorkLogItem,
  useAcademyCoursesControllerReturnType,
  useAcademyCoursesControllerProps,
  TableHeaderButton,
  AcademyCoursesSectionsForm,
} from "../../../../../types";

// ================ Contstants ================

const addFormSteps = ["Overview"];
const editFormSteps = [
  "Overview",
  "Learning Points",
  "Sections",
  "Checklist",
  "Activity Logs",
  "Worklogs",
  "System Data",
];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "name",
  "description",
  "technology",
  "active",
  "curriculamAttached",
  "preparedBy",
  "courseDuration",
  "prerequisites",
  "anyDriveURL",
  "shortDescription",
  "numberOfTopics",
  "cardImage",
  "cardIcon",
  "downloadableResources",
];
export const useAcademyCoursesController = ({
  type,
  id,
  attachmentsPickerRef,
}: useAcademyCoursesControllerProps): useAcademyCoursesControllerReturnType => {
  const defaultFormData: AcademyCoursesForm = {
    name: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: false,
      error: "",
    },
    active: {
      value: false,
      required: false,
      error: "",
    },
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    technology: {
      value: "",
      required: true,
      error: "",
    },
    curriculamAttached: {
      value: false,
      required: false,
      error: "",
    },
    preparedBy: {
      value: "",
      required: true,
      error: "",
    },
    courseDuration: {
      value: "",
      required: false,
      error: "",
    },
    prerequisites: {
      value: "",
      required: false,
      error: "",
    },
    anyDriveURL: {
      value: "",
      required: false,
      error: "",
    },
    shortDescription: {
      value: "",
      required: false,
      error: "",
    },
    numberOfTopics: {
      value: "",
      required: false,
      error: "",
    },
    cardImage: {
      value: "",
      required: false,
      error: "",
    },
    cardIcon: {
      value: "",
      required: false,
      error: "",
    },
    downloadableResources: {
      value: "",
      required: false,
      error: "",
    },
    learningPoints: {
      value: [],
      required: false,
      error: "",
    },
    learningPointItem: {
      value: "",
      required: false,
      error: "",
    },
    sections: {
      value: [],
      required: false,
      error: "",
    },
    cardIconAttached: {
      value: false,
      required: false,
      error: "",
    },
    cardBackgroundAttached: {
      value: false,
      required: false,
      error: "",
    },
    courseDetailsIconAttached: {
      value: false,
      required: false,
      error: "",
    },
  };
  const defaultSectionsData: AcademyCoursesSectionsForm = {
    title: {
      value: "",
      required: false,
      error: "",
    },
    sectionDescription: {
      value: "",
      required: false,
      error: "",
    },
    sectionDuration: {
      value: "",
      required: false,
      error: "",
    },
    _id: "",
  };
  // ================ Reducer Values ================
  const list = useAppSelector((state) => state.AcademyCoursesReducer.data.list);
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;

  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AcademyCoursesForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<AcademyCoursesList>({});

  const [learningPointsModal, setLearningPointsModal] = useState<"add" | "edit" | "">("");
  const [currentLearningPointIndex, setCurrentLearningPointIndex] = useState<number>(-1);
  const [sectionsData, setSectionsData] = useState<AcademyCoursesSectionsForm>(defaultSectionsData);
  const [sectionDescription, setSectionDescription] = useState<string>("");
  const [sectionsModal, setSectionsModal] = useState<"add" | "edit" | "">("");

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [
      "Contact Web Portal",
      "Candidate Leads",
      "Batch",
      "Candidate Enrolments",
      "Lerner's Profile",
    ];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData[field].error) {
              console.log(field, formData[field].error);
              count += 1;
            }
          });
          break;
      }

      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = { ...formData, [name]: { ...formData[name], value, error: "" } };
      setFormData(tempFormData);
    },
    [formData],
  );
  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = formData[name]?.value ? false : true;
        console.log(name, formData[name]?.value, value);
        const tempFormData = {
          ...formData,
          [name]: {
            ...formData[name],
            value,
            error: "",
          },
        };
        setFormData(tempFormData);
      }
    },
    [formData],
  );
  const onSectionTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...sectionsData,
        [name]: {
          ...sectionsData[name],
          value,
          error: "",
        },
      };

      setSectionsData(tempFormData);
    },
    [sectionsData],
  );

  const onSectionDescriptionChange = useCallback((value: string) => {
    setSectionDescription(value);
  }, []);

  const rehydrateFormData = useCallback(
    (academyCourses: AcademyCoursesList) => {
      const item = academyCourses;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).forEach((field) => {
        let value = item?.[field];
        if (["technology", "preparedBy"].includes(field)) {
          const tempValue = value._id;
          value = tempValue;
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
              extension: value[i]?.extension,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchAcademyCourses = useCallback(() => {
    setLoading(true);
    const payload: FetchAcademyCoursesByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchAcademyCoursesById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (item === "name" && !String(validValue).trim()) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      } else if (item === "anyDriveURL" && validValue && !validator.isURL(validValue || "")) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Please enter valid URL",
          },
        };
      } else if (currentField.required && !validValue) {
        console.log(item, validValue);
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });

    setFormData(tempFormData);
    if (
      !formData.cardBackgroundAttached.value ||
      !formData.cardIconAttached.value ||
      !formData.courseDetailsIconAttached.value
    ) {
      valid = false;
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Upload the required attachment/document.",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    }
    return valid;
  }, [dispatch, formData, notificationsId]);

  const onSubmitAdd = useCallback(() => {
    const {
      name,
      description,
      technology,
      active,
      curriculamAttached,
      preparedBy,
      courseDuration,
      prerequisites,
      anyDriveURL,
      shortDescription,
      numberOfTopics,
      cardImage,
      cardIcon,
      cardIconAttached,
      cardBackgroundAttached,
      courseDetailsIconAttached,
      downloadableResources,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const payload: CreateAcademyCoursesPayload = {
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          technology: technology.value,
          active: active.value,
          cardIconAttached: cardIconAttached.value,
          cardBackgroundAttached: cardBackgroundAttached.value,
          courseDetailsIconAttached: courseDetailsIconAttached.value,
          curriculamAttached: curriculamAttached.value,
          preparedBy: preparedBy.value,
          courseDuration: courseDuration.value,
          prerequisites: prerequisites.value,
          anyDriveURL: anyDriveURL.value,
          shortDescription: shortDescription.value,
          numberOfTopics: numberOfTopics.value,
          cardImage: cardImage.value,
          cardIcon: cardIcon.value,
          downloadableResources: downloadableResources.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Course Offerings created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createAcademyCourses(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      name,
      description,
      technology,
      active,
      curriculamAttached,
      preparedBy,
      courseDuration,
      prerequisites,
      anyDriveURL,
      shortDescription,
      numberOfTopics,
      cardImage,
      cardIcon,
      downloadableResources,
      cardIconAttached,
      cardBackgroundAttached,
      courseDetailsIconAttached,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      setLoading(true);
      const payload: UpdateAcademyCoursesPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          name: name.value,
          description: description.value,
          technology: technology.value,
          active: active.value,
          curriculamAttached: curriculamAttached.value,
          preparedBy: preparedBy.value,
          courseDuration: courseDuration.value,
          prerequisites: prerequisites.value,
          anyDriveURL: anyDriveURL.value,
          shortDescription: shortDescription.value,
          numberOfTopics: numberOfTopics.value,
          cardImage: cardImage.value,
          cardIcon: cardIcon.value,
          downloadableResources: downloadableResources.value,
          cardIconAttached: cardIconAttached.value,
          cardBackgroundAttached: cardBackgroundAttached.value,
          courseDetailsIconAttached: courseDetailsIconAttached.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Course Offerings updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyCourses(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateAcademyCoursesPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateAcademyCourses(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      console.log(list);
      title = data?.name || "";
    }
    return [strings.headerTitle.academyCourses, title];
  }, [type, list, data]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    if (["card icon", "card background"].includes(currentAttachment.name.toLowerCase())) {
      params["type"] = "public";
    }
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  const handleLearningPointsModal = useCallback(
    (item: string) => {
      const learningPointIndex = parseInt(item);
      switch (item) {
        case "add":
          setLearningPointsModal("add");
          break;
        // case "edit":
        //   setLearningPointsModal("edit");
        //   break;
        default:
          if (learningPointIndex >= 0) {
            setCurrentLearningPointIndex(learningPointIndex);
            const item = formData.learningPoints.value[learningPointIndex];
            setFormData({
              ...formData,
              learningPointItem: {
                ...formData.learningPointItem,
                value: item,
              },
            });
            setLearningPointsModal("edit");
          } else {
            setLearningPointsModal("");
            setFormData({
              ...formData,
              learningPointItem: {
                ...formData.learningPointItem,
                value: "",
              },
            });
            setCurrentLearningPointIndex(-1);
          }
          break;
      }
    },
    [formData],
  );

  const updateLearningPoints = useCallback(() => {
    setLoading(true);
    const data = [...formData.learningPoints.value];
    if (currentLearningPointIndex >= 0) {
      data.splice(currentLearningPointIndex, 1, formData.learningPointItem.value);
    } else if (formData.learningPointItem.value) {
      data.push(formData.learningPointItem.value);
    }
    const payload: UpdateAcademyCoursesPayload = {
      id: id || "",
      data: {
        learningPoints: data,
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Learning points updated successfully",
          type: "success",
        };
        setCurrentLearningPointIndex(-1);
        setLearningPointsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setCurrentLearningPointIndex(-1);
        setLearningPointsModal("");
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateAcademyCourses(payload));
  }, [
    currentLearningPointIndex,
    dispatch,
    formData.learningPointItem.value,
    formData.learningPoints.value,
    id,
    notificationsId,
    rehydrateFormData,
  ]);

  const deleteLearningPoints = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const learningPoint = e.currentTarget?.getAttribute("data-index") || "";
      const data = [...formData.learningPoints.value];
      const index = data.findIndex((item) => item === learningPoint);
      data.splice(Number(index), 1);
      const payload: UpdateAcademyCoursesPayload = {
        id: id || "",
        data: {
          learningPoints: data,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Learning points deleted successfully",
            type: "success",
          };
          setCurrentLearningPointIndex(-1);
          setLearningPointsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setCurrentLearningPointIndex(-1);
          setLearningPointsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyCourses(payload));
    },
    [
      currentLearningPointIndex,
      dispatch,
      formData.learningPointItem.value,
      formData.learningPoints.value,
      id,
      notificationsId,
      rehydrateFormData,
    ],
  );

  const handleSectionsModal = useCallback(
    (item: string) => {
      const sectionId = typeof item === "string" ? item : "";
      switch (item) {
        case "add":
          setSectionsModal("add");
          break;
        // case "edit":
        //   setLearningPointsModal("edit");
        //   break;
        default:
          if (sectionId) {
            const sectionItem = formData.sections.value.find((item) => item._id === sectionId);
            console.log(sectionItem);
            setSectionDescription(sectionItem?.sectionDescription || "");
            setSectionsData({
              ...sectionsData,
              title: {
                ...sectionsData.title,
                value: sectionItem?.title || "",
              },
              sectionDuration: {
                ...sectionsData.sectionDuration,
                value: sectionItem?.sectionDuration || "",
              },
              sectionDescription: {
                ...sectionsData.sectionDescription,
                value: sectionItem?.sectionDescription || "",
              },
              _id: sectionItem?._id || "",
            });
            setSectionsModal("edit");
          } else {
            setSectionsModal("");
            setSectionsData(defaultSectionsData);
            setSectionsData(defaultSectionsData);
          }
          break;
      }
    },
    [defaultSectionsData, formData.sections.value, sectionsData],
  );

  const addCourseSection = useCallback(() => {
    setLoading(true);
    const courseSection: AcademyCourseSection = {
      title: sectionsData.title.value,
      sectionDuration: sectionsData.sectionDuration.value,
      sectionDescription: sectionDescription,
    };
    const payload: UpdateAcademyCoursesPayload = {
      id: id || "",
      data: {
        sectionType: "add",
        sectionItems: [courseSection],
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Course section added successfully",
          type: "success",
        };
        setSectionsData(defaultSectionsData);
        setSectionsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setSectionsModal("");
        setSectionsData(defaultSectionsData);
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateAcademyCourses(payload));
  }, [
    sectionsData.title.value,
    sectionsData.sectionDuration.value,
    sectionDescription,
    id,
    dispatch,
    rehydrateFormData,
    notificationsId,
    defaultSectionsData,
  ]);

  const updateCourseSection = useCallback(() => {
    setLoading(true);
    const courseSection: AcademyCourseSection = {
      title: sectionsData.title.value,
      sectionDuration: sectionsData.sectionDuration.value,
      sectionDescription: sectionDescription,
    };
    const payload: UpdateAcademyCoursesPayload = {
      id: id || "",
      data: {
        sectionType: "update",
        sectionId: sectionsData._id,
        updateSection: courseSection,
      },
      onSuccess: (data) => {
        rehydrateFormData(data);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Course section updated successfully",
          type: "success",
        };
        setSectionsData(defaultSectionsData);
        setSectionsModal("");
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
      onError: (error) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error || "",
          type: "error",
        };
        setSectionsData(defaultSectionsData);
        setSectionsModal("");
        dispatch(addAppNotification({ notification }));
        dispatch(fetchControllerConfigs({}));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        setLoading(false);
      },
    };
    dispatch(updateAcademyCourses(payload));
  }, [
    sectionsData.title.value,
    sectionsData.sectionDuration.value,
    sectionsData._id,
    sectionDescription,
    id,
    dispatch,
    rehydrateFormData,
    notificationsId,
    defaultSectionsData,
  ]);

  const deleteCourseSection = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const sectionId = e.currentTarget?.getAttribute("data-index") || "";
      setLoading(true);
      const payload: UpdateAcademyCoursesPayload = {
        id: id || "",
        data: {
          sectionType: "delete",
          sectionId: sectionId,
        },
        onSuccess: (data) => {
          rehydrateFormData(data);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Course section deleted successfully",
            type: "success",
          };
          setSectionsData(defaultSectionsData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          setSectionsData(defaultSectionsData);
          setSectionsModal("");
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateAcademyCourses(payload));
    },
    [id, dispatch, rehydrateFormData, notificationsId, defaultSectionsData],
  );

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchAcademyCourses,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    handleCheckbox,
    onSectionTextChange,
    deleteLearningPoints,
    sectionDescription,
    deleteCourseSection,
    onSectionDescriptionChange,
    updateCourseSection,
    handleLearningPointsModal,
    learningPointsModal,
    updateLearningPoints,
    addCourseSection,
    handleSectionsModal,
    sectionsData,
    sectionsModal,
  };
};
