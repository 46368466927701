import React, { useState, useCallback, useMemo } from "react";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { updateProfileModel } from "../../../redux/application/actions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { doLogout } from "../../../redux/user/actions";
import { updateOrganisation } from "../../../redux/user/actions";
import { AttributesValues } from "../../../services/methods";
import { images, strings } from "../../../theme";

import {
  Container,
  Title,
  RightContainer,
  PanelSwitch,
  Icon,
  ProfileImage,
  ProfileContainer,
  Name,
  Designation,
  Seperator,
  MenuContainer,
  MenuItem,
  Row,
  LeftArrow,
  ChangeOrganisationTitle,
  BreadCrumbArrow,
  BreadCrumbContainer,
  PrevTitle,
  OrgImageContainer,
  OrgImage,
  Separator,
  HeaderContainer,
} from "./styles";

const Header = ({ title }: { title: string | string[] }) => {
  const user = useAppSelector((state) => state.UserReducer);
  const adminAccess = useAppSelector((state) => state.UserReducer.admin);
  const OGAdmin = useAppSelector((state) => state.UserReducer.organisationAdmin);
  const superAdmin = useAppSelector((state) => state.UserReducer.superAdmin);
  const profileModel = useAppSelector((state) => state.ApplicationReducer.profileModel);
  const OrganisationImage = useAppSelector(
    (state) => state.UserReducer?.organisation?.orgImage?.location,
  );

  const [organisation, setOrganisation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getProfileBorderColor = useCallback(() => {
    let color = "green";
    if (superAdmin) {
      color = "red";
    } else if (OGAdmin) {
      color = "orange";
    } else if (adminAccess) {
      color = "#AA336A";
    }
    return color;
  }, [OGAdmin, adminAccess, superAdmin]);

  const path = useLocation().pathname;
  const currentPanel = path.startsWith("/ep") ? "EP" : "AP";
  const logout = (e) => {
    e.preventDefault();
    window.confirm(`Are you sure you want to logout ?`)
      ? dispatch(
          doLogout({
            onSuccess: () => {
              navigate("/");
            },
            onError: (error) => {
              navigate("/");
            },
          }),
        )
      : console.log("Logout Cancelled");
  };

  const profilePic = user?.profilePic?.location || images.defaultProfile;

  const handleProfile = () => {
    setOrganisation(false);
    dispatch(updateProfileModel({ visible: !profileModel }));
  };

  const navigateToProfile = () => {
    dispatch(updateProfileModel({ visible: false }));
    navigate("/ep/profile");
  };
  const handleChangeOrganisation = (e) => {
    e?.stopPropagation();
    setOrganisation(!organisation);
  };
  const handleOrganisation = useCallback(
    (e) => {
      const index = e.target.getAttribute("data-index");
      const organisation = user.organisations[index].organisation;
      const currentOrg = user?.organisation?._id === organisation._id;
      if (!currentOrg) {
        const employee = user.organisations[index].employee;
        dispatch(updateOrganisation({ organisation, employee }));
        dispatch(updateProfileModel({ visbile: false }));
        navigate("/ep");
      }
    },
    [dispatch, navigate, user?.organisation?._id, user.organisations],
  );
  const ProfileCard = () => {
    return (
      <ProfileContainer>
        <Name>{user.name}</Name>
        <Designation>
          {AttributesValues(
            "employeeDesignation",
            user?.employee?.designations?.[user.employee.designations.length - 1]?.designation,
          )}
        </Designation>
        <Seperator />
        <MenuContainer>
          {organisation && Array.isArray(user.organisations) ? (
            <>
              <Row>
                <LeftArrow src={images.arrowLeft} onClick={handleChangeOrganisation} />
                <ChangeOrganisationTitle>
                  {strings.epProfile.changeOrganisation}
                </ChangeOrganisationTitle>
              </Row>
              {user.organisations.map((item, index) => {
                const curretOrg =
                  item.organisation && user?.organisation?._id === item.organisation._id;
                if (item.organisation) {
                  return (
                    <MenuItem
                      key={index}
                      currentOrg={curretOrg}
                      onClick={handleOrganisation}
                      data-index={index}
                    >
                      {item.organisation.name}
                    </MenuItem>
                  );
                }
              })}
            </>
          ) : (
            <>
              <MenuItem onClick={navigateToProfile}>{strings.epProfile.profile}</MenuItem>
              <MenuItem onClick={handleChangeOrganisation}>
                {strings.epProfile.changeOrganisation}
              </MenuItem>
              <MenuItem onClick={logout}>{strings.epProfile.logOut}</MenuItem>
            </>
          )}
        </MenuContainer>
      </ProfileContainer>
    );
  };

  const handlePanelSwitch = () => {
    const path = currentPanel === "AP" ? "/ep" : "/ap";
    navigate(path);
  };

  return (
    <Container>
      <OrgImageContainer>
        <OrgImage src={OrganisationImage || images.defaultOrg} />
      </OrgImageContainer>
      <HeaderContainer>
        <BreadCrumbContainer>
          {Array.isArray(title) ? (
            title.map((element, index) => {
              if (index < title.length - 1) {
                return (
                  <BreadCrumbContainer key={element}>
                    <PrevTitle
                      onClick={() => {
                        if (index < title.length) {
                          navigate(0 - index - 1);
                        }
                      }}
                    >
                      {element}
                    </PrevTitle>
                    <BreadCrumbArrow src={images.breadCrumbArrowIcon} />
                  </BreadCrumbContainer>
                );
              } else {
                return <Title key={element}>{element}</Title>;
              }
            })
          ) : (
            <Title>{title}</Title>
          )}
        </BreadCrumbContainer>

        <RightContainer>
          {(adminAccess || user.superAdmin) && (
            <PanelSwitch onClick={handlePanelSwitch}>
              {currentPanel === "EP" ? strings.common.adminPanel : strings.common.employeePanel}
            </PanelSwitch>
          )}
          {/* <Icon src={images.epNotification} /> */}
          <ProfileImage border={getProfileBorderColor()} onClick={handleProfile} src={profilePic} />
          {profileModel && ProfileCard()}
        </RightContainer>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
