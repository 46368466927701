import { AppNotificationsType } from "../../types";

import { ApplicationActionTypes as Types } from "./actionTypes";
import { AddAppNotification, FetchControllerConfigsSuccess, RemoveAppNotification } from "./types";

export const fetchAttributes = (payload) => ({
  type: Types.ATTRIBUTES_FETCH,
  payload: payload,
});
export const updateEPAccordion = (payload) => ({
  type: Types.EP_ACCORDION,
  payload: payload,
});
export const updateAPAccordion = (payload) => ({
  type: Types.AP_ACCORDION,
  payload: payload,
});
export const updateProfileModel = (payload: boolean) => ({
  type: Types.SET_PROFILE_MODEL,
  payload: payload,
});
export const fetchAttributesSuccess = (list = []) => ({
  type: Types.ATTRIBUTES_FETCH_SUCCESS,
  payload: {
    data: list,
  },
});

export const showInAppLoader = (value) => ({
  type: Types.SHOW_IN_APP_LOADER,
  payload: value,
});

export const uploadFile = ({ file, type, onSuccess, onError, onProgress }: any) => ({
  type: Types.STORAGE_UPLOAD,
  payload: {
    file: file,
    type: type,
    onSuccess: onSuccess,
    onError: onError,
    onProgress: onProgress,
  },
});

export const getFile = ({ id, onSuccess, onError }) => ({
  type: Types.STORAGE_GET,
  payload: {
    id: id,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const fetchControllerConfigs = (payload) => ({
  type: Types.FETCH_CONTROLLER_CONFIGURATIONS,
  payload: payload,
});

export const fetchControllerConfigsSuccess = ({
  controllerConfigs,
}): FetchControllerConfigsSuccess => ({
  type: Types.FETCH_CONTROLLER_CONFIGURATIONS_SUCCESS,
  payload: {
    controllerConfigs,
  },
});

export const addAppNotification = ({
  notification,
}: {
  notification: AppNotificationsType;
}): AddAppNotification => ({
  type: Types.ADD_APP_NOTIFICATION,
  payload: notification,
});

export const removeAppNotification = ({
  notification,
}: {
  notification: AppNotificationsType;
}): RemoveAppNotification => ({
  type: Types.REMOVE_APP_NOTIFICATION,
  payload: notification,
});
