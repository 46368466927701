import React, { useCallback, useState, useMemo } from "react";

import { Field, RuleGroupTypeIC, RuleType, defaultOperators } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  deleteBankDetails,
  fetchBankDetailsList,
  getProfile,
  removeAppNotification,
  showInAppLoader,
  updateBankDetails,
  updateTableConfigs,
} from "../../../../../redux";
import {
  BankDetailsList,
  BankDetailsListWithMetaData,
  DeleteBankDetailsPayload,
  FetchBankDetailsParams,
} from "../../../../../redux/BankDetails/types";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  MomentDate,
  downloadFile,
  getAttributValues,
  getFilterOptions,
  isEqualArrays,
} from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  ListActions,
  QueryBuilderType,
  TableHeaderButton,
  fetchBankDetailsProps,
  sortType,
  useBankDetailsListProps,
  useBankDetailsListReturnType,
} from "../../../../../types";
import {
  ActiveSelectOptions,
  BankDetailsAssociatedTo,
  tableListDefaultSelectFields,
  tablePublicFields,
} from "../../../../../utils";
import { getBankDetailsColumns } from "../../../../columns";
import { useControllerConfigs } from "../../../../common";

const defaultFilters: QueryBuilderType[] = [];

export const useBankDetailsList = ({
  relatedList,
  associatedEmployee,
  associatedCompany,
  recordType,
  widgetId,
}: useBankDetailsListProps): useBankDetailsListReturnType => {
  // const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const metaData = useAppSelector((state) => state.BankDetailsReducer.data.metaData);
  const tableConfiguration = useAppSelector((state) => state.UserReducer.tableConfiguration);
  const bankDetails = useAppSelector((state) => state.BankDetailsReducer.data.list);
  const { employeesOptions, usersOptions, countryOptions, companyOptions, currencyOptions } =
    useControllerConfigs();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<QueryBuilderType[]>(defaultFilters);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [mongoQuery, setMongoQuery] = useState("");
  const [filterLogicQuery, setFilterLogicQuery] = useState<RuleGroupTypeIC>({ rules: [] });
  const [sortKey, setSortKey] = useState("name");
  const [sortType, setSortType] = useState<sortType>("asc");

  const BankeDetailsConfigTableFields = useMemo(() => {
    if (recordType === "associatedEmployee") {
      return tableConfiguration?.employeeBankDetails?.fields?.length
        ? tableConfiguration?.employeeBankDetails?.fields
        : tableListDefaultSelectFields.employeeBankDetails;
    }
    return tableConfiguration?.companyBankDetails?.fields?.length
      ? tableConfiguration?.companyBankDetails?.fields
      : tableListDefaultSelectFields.companyBankDetails;
  }, [tableConfiguration, recordType]);

  const columns = useMemo(() => {
    const fields: string[] = BankeDetailsConfigTableFields;
    return getBankDetailsColumns({ fields, recordType });
  }, [BankeDetailsConfigTableFields, recordType]);
  // ================ Export Record Method ================
  const exportRecords = useCallback(
    async (records: BankDetailsList[]) => {
      const userPersonalizedFields: string[] =
        recordType === "associatedEmployee"
          ? tableConfiguration?.employeeBankDetails?.fields ||
            tableListDefaultSelectFields.employeeBankDetails
          : tableConfiguration?.companyBankDetails?.fields ||
            tableListDefaultSelectFields.companyBankDetails;
      records = records?.map((item) => {
        const bankDetailsItem = { ...item };
        if (
          userPersonalizedFields?.includes("bankCountry") &&
          typeof item.bankCountry !== "string"
        ) {
          bankDetailsItem.bankCountry = item?.bankCountry?.name;
        }
        if (
          userPersonalizedFields?.includes("bankCurrency") &&
          typeof item.bankCurrency !== "string"
        ) {
          bankDetailsItem.bankCurrency = item?.bankCurrency?.name;
        }
        if (
          userPersonalizedFields?.includes("accountType") &&
          typeof item.accountType !== "string"
        ) {
          bankDetailsItem.accountType = item?.accountType?.value;
        }
        if (
          userPersonalizedFields?.includes("associatedEmployee") &&
          typeof item.associatedEmployee !== "string"
        ) {
          bankDetailsItem.associatedEmployee = item?.associatedEmployee?.name;
        }
        if (
          userPersonalizedFields?.includes("associatedCompany") &&
          typeof item.associatedCompany !== "string"
        ) {
          bankDetailsItem.associatedCompany = item?.associatedCompany?.company;
        }
        if (userPersonalizedFields?.includes("createdAt")) {
          bankDetailsItem.createdAt = MomentDate({
            date: item.createdAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("updatedAt")) {
          bankDetailsItem.updatedAt = MomentDate({
            date: item.updatedAt || "2023-01-01T08:54:36.357Z",
            format: "YYYY-MM-DD",
          });
        }
        if (userPersonalizedFields?.includes("createdBy") && typeof item.createdBy !== "string") {
          bankDetailsItem.createdBy = item?.createdBy?.name || "Admin";
        }
        if (userPersonalizedFields?.includes("updatedBy") && typeof item.updatedBy !== "string") {
          bankDetailsItem.updatedBy = item?.updatedBy?.name || "Admin";
        }
        delete bankDetailsItem?._id;
        return bankDetailsItem;
      });
      const bankDetailsFields =
        recordType === "associatedEmployee"
          ? tablePublicFields.employeeBankDetails
          : tablePublicFields.companyBankDetails;
      const columnHeaders = bankDetailsFields.filter((item) =>
        userPersonalizedFields.includes(item.key),
      );
      const fileName =
        recordType === "associatedEmployee" ? "employee_bank_details" : "company_bank_details";
      downloadFile(columnHeaders, records, fileName);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: strings.common.fileExported,
        type: "success",
      };
      setSelectedRows([]);
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [
      recordType,
      tableConfiguration?.companyBankDetails?.fields,
      tableConfiguration?.employeeBankDetails?.fields,
      dispatch,
      notificationsId,
    ],
  );

  // ================ Fetch Bank Details Success/Error Methods ================

  const BankDetailsFetchSuccess = useCallback(
    (data: BankDetailsListWithMetaData, exportAll?: boolean) => {
      setLoading(false);
      if (exportAll) {
        dispatch(showInAppLoader(false));
        exportRecords(data.list);
      }
    },
    [exportRecords, dispatch],
  );

  const BankDetailsFetchError = useCallback(
    (error: string) => {
      dispatch(showInAppLoader(false));
      setLoading(false);
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: error,
        type: "error",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
    },
    [dispatch, notificationsId],
  );

  const fetchBankDetails = useCallback(
    ({
      page,
      limit,
      search = "",
      mongoQueryArgument,
      sortKeyArgument,
      sortTypeArgument,
      exportAll,
    }: fetchBankDetailsProps) => {
      if (exportAll) {
        dispatch(showInAppLoader(true));
      }
      if (widgetId && !mongoQuery) {
        const currentWidgetData = widgetsData.find((item) => item._id === widgetId);
        if (currentWidgetData?.filterMongoQuery && currentWidgetData?.filter) {
          mongoQueryArgument = currentWidgetData.filterMongoQuery;
          setFilterLogicQuery(currentWidgetData?.filter);
          setMongoQuery(currentWidgetData?.filterMongoQuery);
        }
        console.log({ widgetId, currentWidgetData });
      } else {
        setLoading(true);
      }
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;
      const recoredsLimit = limit
        ? limit
        : recordType === "associatedEmployee"
        ? tableConfiguration?.employeeBankDetails?.limit || 10
        : tableConfiguration?.companyBankDetails?.limit || 10;

      const params: FetchBankDetailsParams = {
        page: itemPage,
        limit: recoredsLimit,
        mongoQuery: mongoQueryArgument
          ? mongoQueryArgument
          : mongoQueryArgument === false
          ? ""
          : mongoQuery,
        sortKey: sortKeyArgument ? sortKeyArgument : sortKey,
        sortType: sortTypeArgument ? sortTypeArgument : sortType,
      };
      if (exportAll) {
        params["exportType"] = "all";
      }
      if (search) {
        params["name"] = search;
      }
      if (relatedList?.type && relatedList?.value) {
        params["relatedListType"] = relatedList.type;
        params["relatedListValue"] = relatedList.value;
      }

      if (associatedEmployee) {
        params["associatedEmployee"] = associatedEmployee;
      }
      if (recordType) {
        params["recordType"] = recordType;
      }
      if (associatedCompany) {
        params["associatedCompany"] = associatedCompany;
      }

      const payload = {
        params,
        onSuccess: (data: BankDetailsListWithMetaData) => BankDetailsFetchSuccess(data, exportAll),
        onError: BankDetailsFetchError,
      };
      setSelectedRows([]);
      dispatch(fetchBankDetailsList(payload));
    },
    [
      widgetId,
      widgetsData,
      metaData.currentPage,
      recordType,
      tableConfiguration?.employeeBankDetails?.limit,
      tableConfiguration?.companyBankDetails?.limit,
      mongoQuery,
      sortKey,
      sortType,
      relatedList?.type,
      relatedList?.value,
      associatedEmployee,
      associatedCompany,
      BankDetailsFetchError,
      dispatch,
      BankDetailsFetchSuccess,
    ],
  );

  // ================ Export Record Methods ================

  const exportSelectedRecords = useCallback(async () => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const selectedRecords = bankDetails?.filter((item) => {
      if (item._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    exportRecords(selectedRecords);
  }, [selectedRows, bankDetails, exportRecords, notificationsId, dispatch]);

  const exportAllRecords = useCallback(() => {
    fetchBankDetails({ exportAll: true });
  }, [fetchBankDetails]);

  const refreshList = useCallback(() => {
    const page = metaData.currentPage || 1;
    fetchBankDetails({ page });
  }, [fetchBankDetails, metaData.currentPage]);

  const handlePageChange = useCallback(
    (page: string) => {
      fetchBankDetails({ page: parseInt(page), search });
    },
    [fetchBankDetails, search],
  );

  const onClickBankDetails = useCallback(
    (id: string) => {
      if (recordType === "associatedEmployee" && !permissions?.employeeBankDetails_view) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      if (recordType === "associatedCompany" && !permissions?.companyBankDetails_view) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Permission denied",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      navigate(`/ap/bank-details/${id}`);
    },
    [
      dispatch,
      navigate,
      notificationsId,
      permissions?.companyBankDetails_view,
      permissions?.employeeBankDetails_view,
      recordType,
    ],
  );

  const onClickAdd = useCallback(
    () => navigate(`/ap/bank-details/add?recordType=${recordType}`),
    [navigate, recordType],
  );

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    setSearch(value);
  }, []);

  const onSearch = useCallback(() => {
    fetchBankDetails({ page: 1, search });
  }, [fetchBankDetails, search]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
    fetchBankDetails({ page: 1 });
  }, [fetchBankDetails]);

  // ================ Filters/Sort Methods ================

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      const tempFiltersData = {
        ...filters,
        [name]: value,
      };
      setFilters(tempFiltersData);
    },
    [filters],
  );

  const onClickApplyFilter = useCallback(
    (mongoQuery: string, jsonQuery: string, filterLogicQuery: RuleGroupTypeIC) => {
      setMongoQuery(mongoQuery);
      setFilterLogicQuery(filterLogicQuery);
      fetchBankDetails({
        page: 1,
        mongoQueryArgument: mongoQuery ? mongoQuery : false,
      });
    },
    [fetchBankDetails],
  );

  const handleSortList = useCallback(
    (key: string, sortType: "asc" | "desc") => {
      setSortKey(key);
      setSortType(sortType);
      fetchBankDetails({
        page: 1,
        sortKeyArgument: key,
        sortTypeArgument: sortType,
      });
    },
    [fetchBankDetails],
  );
  const onSubmitDeleteRecord = useCallback(() => {
    if (!selectedRows.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No records selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    dispatch(showInAppLoader(true));
    const selectedRecords = bankDetails?.filter((item) => {
      if (item?._id) {
        return selectedRows.includes(item?._id);
      }
      return false;
    });
    const payload: DeleteBankDetailsPayload = {
      id: selectedRecords.map((item) => item?._id).join(","),
      onSuccess: () => {
        setLoading(false);
        fetchBankDetails({ page: 1 });
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Record deleted successfully",
          type: "success",
        };
        dispatch(showInAppLoader(false));

        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
      onError: () => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Error in deleting record",
          type: "error",
        };
        dispatch(showInAppLoader(false));
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(deleteBankDetails(payload));
  }, [selectedRows, bankDetails, dispatch, notificationsId, fetchBankDetails]);
  // ================ Row Selection Methods ================
  const allRowsSelected = useMemo(() => {
    const allRecords = bankDetails?.map((item) => item?._id);
    return isEqualArrays(allRecords, selectedRows);
  }, [selectedRows, bankDetails]);

  const onClickRowSelection = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        if (id === "all") {
          if (allRowsSelected) {
            setSelectedRows([]);
          } else {
            const allRecords: string[] = [];
            bankDetails?.forEach((item) => {
              if (item?._id) {
                allRecords.push(item?._id);
              }
            });
            setSelectedRows(allRecords);
          }
        } else {
          const currentSelectedRows = [...selectedRows];
          const findId = currentSelectedRows.findIndex((item) => item === id);
          if (findId >= 0) {
            currentSelectedRows.splice(findId, 1);
          } else {
            currentSelectedRows.push(id as string);
          }
          setSelectedRows(currentSelectedRows);
        }
      }
    },
    [selectedRows, bankDetails, allRowsSelected],
  );

  const handleTableConfigs = useCallback(
    (fields: string[]) => {
      dispatch(showInAppLoader(true));
      const bankDetailsConfigLimit =
        recordType === "associatedEmployee"
          ? tableConfiguration?.employeeBankDetails?.limit || 10
          : tableConfiguration?.companyBankDetails?.limit || 10;
      const bankDetailsConfigs = {
        table: recordType === "associatedEmployee" ? "employeeBankDetails" : "companyBankDetails",
        fields: fields,
        limit: bankDetailsConfigLimit,
      };
      const payload = {
        tableConfiguration: bankDetailsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchBankDetails({});
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateTableConfigs(payload));
    },
    [
      recordType,
      tableConfiguration?.employeeBankDetails?.limit,
      tableConfiguration?.companyBankDetails?.limit,
      dispatch,
      fetchBankDetails,
      notificationsId,
    ],
  );

  const handleShowItemsPerPage = useCallback(
    (count: number) => {
      const bankDetailsConfigFields =
        recordType === "associatedEmployee"
          ? tableConfiguration?.employeeBankDetails?.fields
          : tableConfiguration?.companyBankDetails?.fields;
      dispatch(showInAppLoader(true));
      const bankDetailsConfigs = {
        table: recordType === "associatedEmployee" ? "employeeBankDetails" : "companyBankDetails",
        fields: bankDetailsConfigFields,
        limit: count,
      };
      const payload = {
        tableConfiguration: bankDetailsConfigs,
        onSuccess: () => {
          setLoading(false);
          fetchBankDetails({ limit: count, page: 1 });
          dispatch(getProfile({}));
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details table fields configs saved successfully",
            type: "success",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating table config",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      setSelectedRows([]);
      dispatch(updateTableConfigs(payload));
    },
    [
      recordType,
      tableConfiguration?.employeeBankDetails?.fields,
      tableConfiguration?.companyBankDetails?.fields,
      dispatch,
      fetchBankDetails,
      notificationsId,
    ],
  );
  // ================ List Actions Method ================

  const handleListActions = useCallback(
    (field: string, value: any) => {
      if (!selectedRows.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "No records selected",
          type: "warning",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
        return;
      }
      dispatch(showInAppLoader(true));
      const selectedRecords = bankDetails?.filter((item) => {
        if (item?._id) {
          return selectedRows.includes(item?._id);
        }
        return false;
      });
      const payload = {
        id: selectedRecords.map((item) => item?._id).join(","),
        data: {
          [field]: value,
        },
        onSuccess: () => {
          setLoading(false);
          fetchBankDetails({});
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Bank Details updated successfully",
            type: "success",
          };
          setSelectedRows([]);
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: () => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Error in updating bank details",
            type: "error",
          };
          dispatch(showInAppLoader(false));
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(updateBankDetails(payload));
    },
    [selectedRows, bankDetails, dispatch, notificationsId, fetchBankDetails],
  );
  // ================ Table Header Buttons ================

  const bankDetailsHeaderButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [];
    return buttons;
  }, []);

  const listActionOptions: ListActions[] = [
    {
      name: "associatedRecord",
      label: "Associated To",
      options: BankDetailsAssociatedTo,
      required: true,
    },
    {
      name: "associatedEmployee",
      label: "Employee",
      options: employeesOptions,
      hide: recordType !== "associatedEmployee",
      required: true,
    },
    {
      name: "associatedCompany",
      label: "Company",
      options: companyOptions,
      hide: recordType !== "associatedCompany",
      required: true,
    },
    {
      name: "accountType",
      label: "Account Type",
      options: getAttributValues("bankAccountType"),
      required: true,
    },
    {
      name: "bankType",
      label: "Bank Type",
      options: getAttributValues("bankType"),
      required: true,
    },
    {
      name: "bankName",
      label: "Bank Name",
      type: "text",
      required: true,
    },
    {
      name: "defaultAccount",
      label: "Default Account",
      options: ActiveSelectOptions,
    },
    {
      name: "bankCountry",
      label: "Country",
      options: countryOptions,
      required: true,
    },
    {
      name: "bankCurrency",
      label: "Bank Currency",
      options: currencyOptions,
      required: true,
    },
    {
      name: "swiftBICCode",
      label: "Swift BIC Code",
      type: "text",
    },
    {
      name: "accountName",
      label: "Account Name",
      type: "text",
      required: true,
    },
    {
      name: "accountNumber",
      label: "Account Number",
      type: "text",
      required: true,
    },
    {
      name: "bankRoutingType",
      label: "Bank Routing Type",
      options: getAttributValues("bankRoutingType"),
      required: true,
    },
    {
      name: "routingNumber",
      label: "Routing Number",
      type: "text",
      required: true,
    },
  ];

  // ================ Filter Fields Configuration ================

  const bankDetailsFilterFields = useMemo(() => {
    const validator = (r: RuleType) => !!r.value;
    const countryFilterOptions = getFilterOptions(countryOptions, "Country");
    const accountTypeFilterOptions = getFilterOptions(
      getAttributValues("bankAccountType"),
      "Account Type",
    );
    const currencyFilterOptions = getFilterOptions(currencyOptions, "Currency");
    const employeeyOptions = getFilterOptions(employeesOptions, "Employees");
    const companyFilterOptions = getFilterOptions(companyOptions, "Company");
    const userOptions = getFilterOptions(usersOptions, "Users");
    const associatedRecordOptions = getFilterOptions(BankDetailsAssociatedTo, "Associated To");
    const bankTypeFilterOptions = getFilterOptions(getAttributValues("bankType"), "Bank Type");
    const activeOptions = getFilterOptions(ActiveSelectOptions, "Select");
    const bankRoutingOptions = getFilterOptions(
      getAttributValues("bankRoutingType"),
      "Bank ROuting Type",
    );

    let fields: Field[] = [];
    const commonFields: Field[] = [
      {
        name: "associatedRecord",
        label: "Associated To",
        valueEditorType: "select",
        values: associatedRecordOptions,
        defaultValue: associatedRecordOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "active",
        label: "Active",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "radio",
        values: [
          { name: "true", label: "Yes" },
          { name: "false", label: "No" },
        ],
      },
      {
        name: "bankCountry",
        label: "Country",
        valueEditorType: "select",
        values: countryFilterOptions,
        defaultValue: countryFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "bankType",
        label: "Type of the Bank",
        valueEditorType: "select",
        values: bankTypeFilterOptions,
        defaultValue: bankTypeFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "defaultAccount",
        label: "Default Account",
        valueEditorType: "select",
        values: activeOptions,
        defaultValue: activeOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "bankCurrency",
        label: "Bank Currency",
        valueEditorType: "select",
        values: currencyFilterOptions,
        defaultValue: currencyFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "accountType",
        label: "Account Type",
        valueEditorType: "select",
        values: accountTypeFilterOptions,
        defaultValue: accountTypeFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "bankName",
        label: "Bank Name",
        inputType: "string",
        validator,
      },
      {
        name: "swiftBICCode",
        label: "Swift/BIC Code",
        inputType: "string",
        validator,
      },
      {
        name: "accountName",
        label: "Account Name",
        inputType: "string",
        validator,
      },
      {
        name: "accountNumber",
        label: "Account Number",
        inputType: "string",
        validator,
      },
      {
        name: "routingNumber",
        label: "Bank Routing Number",
        inputType: "string",
        validator,
      },
      {
        name: "routingType",
        label: "Bank Routing Details",
        valueEditorType: "select",
        values: bankRoutingOptions,
        defaultValue: bankRoutingOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "createdAt",
        label: "Created On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "createdBy",
        label: "Created By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
      {
        name: "updatedAt",
        label: "Updated On",
        inputType: "date",
        operators: defaultOperators.filter((op) => [">", "<"].includes(op.name)),
      },
      {
        name: "updatedBy",
        label: "Updated By",
        valueEditorType: "select",
        values: userOptions,
        operators: defaultOperators.filter((op) => op.name === "="),
      },
    ];
    if (recordType === "associatedEmployee") {
      fields.push({
        name: "associatedEmployee",
        label: "Employee",
        valueEditorType: "select",
        values: employeeyOptions,
        defaultValue: employeeyOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      });
    } else {
      fields.push({
        name: "associatedCompany",
        label: "Company",
        valueEditorType: "select",
        values: companyFilterOptions,
        defaultValue: companyFilterOptions?.[0]?.options?.[0]?.name || "",
        operators: defaultOperators.filter((op) => op.name === "="),
      });
    }
    fields = fields.concat(commonFields);
    return fields;
  }, [employeesOptions, usersOptions, countryOptions, companyOptions, currencyOptions, recordType]);

  return {
    listActionOptions,
    bankDetailsFilterFields,
    onClickBankDetails,
    fetchBankDetails,
    onClickAdd,
    loading,
    handlePageChange,
    handleClearSearch,
    onSearch,
    onSearchChange,
    search,
    refreshList,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    bankDetailsHeaderButtons,
    exportAllRecords,
    exportSelectedRecords,
    onSubmitDeleteRecord,
    BankeDetailsConfigTableFields,
    columns,
  };
};
