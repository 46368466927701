import React, { useCallback, useMemo } from "react";

import { createSearchParams, useNavigate } from "react-router-dom";

import { TableListV3 } from "..";
import { useAppSelector } from "../../../../redux/hooks";
import { colors, images, strings } from "../../../../theme";
import { ListWidgetProps } from "../../../../types";
import { AppRouteLinks } from "../../../../utils";
import { useTableColumns } from "../../../common";
import { Row } from "../../../common/styles";
import { CountWidgetSkeleton, TableListSkeleton } from "../../atoms";
import { DashboardTable } from "../../organisms/DashboardTable/DashboardTable";

import {
  Count,
  CountIcon,
  CountItem,
  CountItemcontainer,
  Icon,
  ListWidgetIcon,
  ListWidgetRightContainer,
  ListWidgetTitle,
  ListWidgetTitleContainer,
  RefreshIconContainer,
  Title,
  ViewAllButton,
} from "./styles";

export const CountWidget = ({ widgetItem, refreshWidgetData }: ListWidgetProps) => {
  const widgetsData = useAppSelector((state) => state.DashboardsReducer.data.widgetsData);
  const loadingWidgets = useAppSelector((state) => state.DashboardsReducer.loadingWidgets);
  const navigate = useNavigate();
  const currentWidgetData = useMemo(
    () => widgetsData.find((item) => item._id === widgetItem._id),
    [widgetsData, widgetItem._id],
  );

  const isWidgetLoading = useMemo(() => {
    return !!(
      !currentWidgetData?.data ||
      (widgetItem?._id && loadingWidgets.includes(widgetItem?._id))
    );
  }, [currentWidgetData, widgetItem._id, loadingWidgets]);
  const handleOpenTable = useCallback(() => {
    if (currentWidgetData?.table && currentWidgetData._id) {
      const widgetId = currentWidgetData?._id;
      const path = AppRouteLinks[currentWidgetData?.table]?.list;
      navigate({
        pathname: path,
        search: createSearchParams({
          widgetId,
        }).toString(),
      });
    }
  }, [currentWidgetData, navigate]);

  return (
    <React.Fragment>
      <ListWidgetTitleContainer>
        <ListWidgetTitle>{widgetItem.title}</ListWidgetTitle>
        <ListWidgetRightContainer>
          <RefreshIconContainer data-id={widgetItem._id} onClick={refreshWidgetData}>
            <ListWidgetIcon src={images.refreshIcon} />
          </RefreshIconContainer>
        </ListWidgetRightContainer>
      </ListWidgetTitleContainer>
      <CountItemcontainer>
        {isWidgetLoading ? (
          <CountWidgetSkeleton />
        ) : (
          <CountItem
            gradientOne={colors.dashboard.conuntGradientOne}
            gradientTwo={colors.dashboard.conuntGradientTwo}
          >
            {/* <Icon src={images.epDoubleTick} /> */}
            <Count>{currentWidgetData?.totalItems}</Count>
            <Row marginTop='30px'>
              <Title>{strings.common.records}</Title>
              <ViewAllButton onClick={handleOpenTable}>{strings.common.viewAll}</ViewAllButton>
            </Row>
          </CountItem>
        )}
      </CountItemcontainer>
    </React.Fragment>
  );
};
