import Types from "./actionType";
import {
  FetchAcademyCoursesPayload,
  CreateAcademyCourses,
  CreateAcademyCoursesPayload,
  FetchAcademyCourses,
  FetchAcademyCoursesError,
  FetchAcademyCoursesSuccess,
  UpdateAcademyCourses,
  UpdateAcademyCoursesPayload,
  AcademyCoursesListWithMetaData,
  FetchAcademyCoursesByIdPayload,
  FetchAcademyCoursesById,
  DeleteAcademyCoursesPayload,
  DeleteAcademyCourses,
} from "./types";

export const fetchAcademyCoursesList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyCoursesPayload): FetchAcademyCourses => ({
  type: Types.FETCH_ACADEMY_COURSES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCoursesById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyCoursesByIdPayload): FetchAcademyCoursesById => ({
  type: Types.FETCH_ACADEMY_COURSES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCoursesListSuccess = (
  data: AcademyCoursesListWithMetaData,
): FetchAcademyCoursesSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_COURSES,
  payload: {
    data,
  },
});

export const fetchAcademyCoursesError = (error?: string): FetchAcademyCoursesError => ({
  type: Types.ERROR_FETCH_ACADEMY_COURSES,
  payload: { error },
});

export const createAcademyCourses = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyCoursesPayload): CreateAcademyCourses => ({
  type: Types.CREATE_ACADEMY_COURSES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyCourses = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyCoursesPayload): UpdateAcademyCourses => ({
  type: Types.UPDATE_ACADEMY_COURSES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteAcademyCourses = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyCoursesPayload): DeleteAcademyCourses => ({
  type: Types.DELETE_ACADEMY_COURSES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
