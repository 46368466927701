import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { filterTickets } from "../../redux/tickets/actions";
import { generateArrayAttributes } from "../../services/methods";

import styles from "./TicketsFilters.styles";

const sortKeys = [
  { label: "Subject", key: "subject" },
  // { label: "Assigned To", key: "assignedToName" },
  { label: "Created On", key: "createdAt", isDate: true },
  // { label: "Created By", key: "createdBy.name" },
  // { label: "Assigned To", key: "assignedTo.name" },
  // { label: "Requested For", key: "userId.name" },
  { label: "Status", key: "status" },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const TicketsFilter = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.filterTickets({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
        </h2>
      </styles.Header>
      <Select
        name={"status"}
        label={"Ticket Status"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          { label: "Replied", value: "awaiting_reply" },
          { label: "Awaiting Reply", value: "replied" },
          { label: "Ticket Closed", value: "closed" },
        ]}
        value={props?.filters?.status}
        onChange={updateFilter}
      />
      <Select
        name={"createdBy"}
        label={"Created By"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...props.users.map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ]}
        value={props?.filters?.createdBy}
        onChange={updateFilter}
      />
      <Select
        name={"requestedFor"}
        label={"Requested For"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...props.users.map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ]}
        value={props?.filters?.requestedFor}
        onChange={updateFilter}
      />
      <Select
        name={"assignee"}
        label={"Assignee"}
        defaultFocused={true}
        options={[
          { label: "All", value: "" },
          ...props.users.map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ]}
        value={props?.filters?.assignee}
        onChange={updateFilter}
      />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.TicketsReducer.filters,
  users: state.UsersReducer.openList,
});

const mapDispatchToProps = {
  filterTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsFilter);
