import React, { useEffect, useRef, useCallback } from "react";

import { connect } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// imports
import config from "../config";
import Loader from "../presentationals/components/Loader/Loader";
import { fetchControllerConfigs } from "../redux";
import { fetchAnnouncementsList } from "../redux/Announcements/actions";
import { fetchAttributes } from "../redux/application/actions";
import { fetchEmailTemplate } from "../redux/emailtemplates/actions";
import {
  fetchEmployees,
  fetchPayrollData,
  fetchPayrollDataSelf,
  fetchEmployeesBirthdays,
} from "../redux/employees/actions";
// import { fetchGroups } from "../redux/groups/actions";
// import { fetchAllJobs } from "../redux/jobs/actions";
import { fetchAllKnowledge } from "../redux/knowledge/actions";
import { fetchKnowledgebase } from "../redux/knowledgebase/actions";
import { fetchModules } from "../redux/modules/actions";
import { organisationsFetch } from "../redux/organisations/actions";
import { fetchRecruits } from "../redux/recruits/actions";
// import { fetchSPSDeals } from "../redux/spsDeals/actions";
import { getAllTickets } from "../redux/tickets/actions";
import { getProfile, updateOrganisationSuccess } from "../redux/user/actions";
import { fetchUsers } from "../redux/users/actions";
import {
  APEmployees,
  APEmployeesAdd,
  APEmployeesComments,
  APEmployeesEdit,
  APEmployeesView,
  APHome,
  APJobsList,
  APJobsAdd,
  APJobsEdit,
  APManageOrganization,
  APMedia,
  APMediaAdd,
  APMediaEdit,
  APOrganisations,
  APOrganisationsAdd,
  APOrganisationsEdit,
  APOrganisationManage,
  APPayrolls,
  APPayrollsController,
  APPayrollsDetailsController,
  APRecruitments,
  APRecruitmentsAdd,
  APRecruitmentsAssignments,
  APRecruitmentsChecklist,
  APRecruitmentsEdit,
  APRecruitmentsHistory,
  APRecruitmentsSurvey,
  APRecruitmentsView,
  APTickets,
  APTicketsAdd,
  APTicketsDetails,
  APUsers,
  APUsersAdd,
  APUsersEdit,
  ApTicketFilters,
  POD,
  APTemplatesListing,
  APTemplatesController,
  APPayrollsChildList,
  APGroupsList,
  APGroupsAdd,
  APGroupsEdit,
  // APGroupsPermissions,
  // APGroupsMembers,
  APCategoriesListing,
  APCategoriesAdd,
  APCategoriesEdit,
  APModulesListing,
  APModulesAdd,
  APModulesEdit,
  APModulesPOD,
  APKnowledgebaseListing,
  APKnowledgebaseAdd,
  APKnowledgebaseEdit,
  APKnowledgebaseMember,
  APKnowledges,
  APKnowledgeDetail,
  APSPSAccountsListing,
  APSPSAccountsAdd,
  APSPSAccountsEdit,
  APSPSAccountContactListing,
  APSPSAccountContactAdd,
  APSPSAccountContactEdit,
  APSPSSalesProfileListing,
  APSPSSalesProfileAdd,
  APSPSSalesProfileEdit,
  // APSPSAccountContactWorklog,
  // APSalesProfileCredentialsListing,
  // APSPSBGCheckListing,
  APSPSBGCheckAdd,
  APSPSBGCheckEdit,
  // APSPSBGCheckWorklog,
  APCategoriesFilter,
  APModulesFilter,
  APEmailTemplateFilters,
  APMediaFilter,
  APKnowledgesFilters,
  APDashboardAdministrationList,
  APDashboardAdministrationAdd,
  APDashboardAdministrationEdit,
  APDashboardWidgetsList,
  APDashboardWidgetsAdd,
  APDashboardWidgetsEdit,
  APDashboardTabsList,
  APDashboardTabsAdd,
  APDashboardTabsEdit,
  APFiltersList,
  APFiltersAdd,
  APFiltersEdit,
  AttributesList,
  APDesignationsList,
  APDesignationsAdd,
  APDesignationsEdit,
  APCountryList,
  APCountryAdd,
  APCountryEdit,
  APCurrencyList,
  APCurrencyAdd,
  APCurrencyEdit,
  APCompanyContactList,
  APCompanyContactAdd,
  APCompanyContactEdit,
  APCompanyList,
  APCompanyAdd,
  APCompanyEdit,
  AttributesAdd,
  AttributesEdit,
  APBenefitsList,
  APBenefitsAdd,
  APBenefitsEdit,
  APProjectHistoryList,
  APProjectHistoryEdit,
  APAddressHistoryList,
  APAddressHistoryAdd,
  APAddressHistoryEdit,
  APAcademyCandidatesAdd,
  APAcademyCandidatesEdit,
  APAcademyBatchList,
  APAcademyBatchAdd,
  APAcademyBatchEdit,
  APAcademyCandidateEnrollmentsList,
  APAcademyCandidateEnrollmentsAdd,
  APAcademyCandidateEnrollmentsEdit,
  APAcademyACHList,
  APAcademyACHAdd,
  APAcademyACHEdit,
  APAcademyStudentProfileList,
  APAcademyStudentProfileAdd,
  APAcademyStudentProfileEdit,
  APAcademyMentorFeedbackList,
  APAcademyMentorFeedbackAdd,
  APAcademyMentorFeedbackEdit,
  APChecklistTemplateList,
  APChecklistTemplateAdd,
  APChecklistTemplateEdit,
  APSPSPaperworkList,
  APSPSPaperworkAdd,
  APSPSPaperworkEdit,
  SPSInsuranceEdit,
  SPSInsuranceAdd,
  SPSInsuranceListing,
  APSPSProjectsListing,
  APSPSProjectsAdd,
  APSPSProjectsEdit,
  APSPSBGCheckList,
  APSPSDealsList,
  APSPSDealsEdit,
  APSPSDealskAdd,
  APBugReportingList,
  APBugReportingAdd,
  APBugReportingEdit,
  APCategoriesList,
  APCatalogAdministrationsAdd,
  APCatalogAdministrationsEdit,
  APCatalogAdministrationsList,
  APServiceNowSolutionsListing,
  APServiceNowSolutionsAdd,
  APServiceNowSolutionsEdit,
  APResourcesListing,
  APResourcesAdd,
  APResourcesEdit,
  APKanbanConfigAdd,
  APKanbanConfigList,
  APKanbanConfigEdit,
  APWorkspaceAdd,
  APWorkspaceEdit,
  APWorkspaceList,
  APSpaceAdd,
  APSpaceEdit,
  APSpaceList,
  APNotificationsDefinitionsList,
  APNotificationsDefinitionsEdit,
  APNotificationsDefinitionsAdd,
  APServiceRequestsAdd,
  APServiceRequestsEdit,
  APServiceRequestsList,
  APNewsletterLeadsList,
  APNewsletterLeadsAdd,
  APNewsletterLeadsEdit,
} from "../Routes/AP";
import {
  EPHome,
  EPPayrolls,
  EPPayrollsView,
  EPProfile,
  EPTickets,
  EPTicketsAdd,
  EPTicketsAssigned,
  EPTicketsAssignedDetails,
  EPTicketsDetails,
  EPKnowledgeListing,
  EPKnowledgeAdd,
  EPKnowledgeEdit,
  EPKnowledgeDetails,
  EPKnowledgeDashboard,
  EPKnowledgeRevise,
  EPAllTicketsDetails,
  EPAllTickets,
  EPInReviewKnowledgeArticles,
  EPPublishedKnowledgeArticles,
  EPMyKnowledgeArticles,
} from "../Routes/EP/index";
import { GS404, GSLogin } from "../Routes/Generic/index";
import { socketInstance } from "../utils/socket";

import {
  APAcademyCandidateLeadsAdd,
  APAcademyCandidateLeadsEdit,
  APAcademyCandidateLeadsList,
} from "./AP/AcademyCandidateLeads";
import { APAcademyCandidatesList } from "./AP/AcademyCandidates/AcademyCandidates";
import { APAcademyCoursesList } from "./AP/AcademyCourses/AcademyCourses";
import { APAcademyCoursesAdd } from "./AP/AcademyCourses/AcademyCoursesAdd";
import { APAcademyCoursesEdit } from "./AP/AcademyCourses/AcademyCoursesEdit";
import { APAnnouncementsList } from "./AP/Announcements/Announcements";
import { APAnnouncementAdd } from "./AP/Announcements/AnnouncementsAdd";
import { APAnnouncementEdit } from "./AP/Announcements/AnnouncementsEdit";
import { APBankDetailsList } from "./AP/BankDetails/BankDetails";
import { APBankDetailsAdd } from "./AP/BankDetails/BankDetailsAdd";
import { APBankDetailsEdit } from "./AP/BankDetails/BankDetailsEdit";
import { APCampaignsAdd, APCampaignsEdit, APCampaignsList } from "./AP/Campaigns";
import {
  APCampaignsAssetsAdd,
  APCampaignsAssetsEdit,
  APCampaignsAssetsList,
} from "./AP/CampaignsAssets";
import {
  APCertificationsAccreditationsAdd,
  APCertificationsAccreditationsEdit,
  APCertificationsAccreditationsList,
} from "./AP/CertificationsAccreditations";
import { APContactUsAdd, APContactUsEdit, APContactUsList } from "./AP/ContactUS";
import { APDepartmentsAdd, APDepartmentsEdit, APDepartmentsList } from "./AP/Departments";
import { APDriveList } from "./AP/Drive";
import {
  APEducationDetailsAdd,
  APEducationDetailsEdit,
  APEducationDetailsList,
} from "./AP/EducationDetails";
import {
  APEmailSchedularAdd,
  APEmailSchedularEdit,
  APEmailSchedularList,
} from "./AP/EmailSchedular";
import { EmployeesOldRoute } from "./AP/Employees/EmployeesOld";
import {
  APEmploymentHistoryAdd,
  APEmploymentHistoryEdit,
  APEmploymentHistoryList,
} from "./AP/EmploymentHistory";
import { APForm16List } from "./AP/Form16/Form16";
import { APForm16Add } from "./AP/Form16/Form16Add";
import { APForm16Edit } from "./AP/Form16/Form16Edit";
import { APITNRFPAdd, APITNRFPEdit, APITNRFPList } from "./AP/ITNRFP";
import { APITNServicesAdd, APITNServicesEdit, APITNServicesList } from "./AP/ITNServices";
import { APLeadListsAdd, APLeadListsEdit, APLeadListsList } from "./AP/LeadLists";
import {
  APNewsletterResourcesAdd,
  APNewsletterResourcesEdit,
  APNewsletterResourcesList,
} from "./AP/NewsletterResources";
import { APPIIDetailsList } from "./AP/PIIDetails/PIIDetails";
import { APPIIDetailsAdd } from "./AP/PIIDetails/PIIDetailsAdd";
import { APPIIDetailsEdit } from "./AP/PIIDetails/PIIDetailsEdit";
import { APPlatformModulesList } from "./AP/PlatformModules/PlatformModules";
import { APPlatformModulesAdd } from "./AP/PlatformModules/PlatformModulesAdd";
import { APPlatformModulesEdit } from "./AP/PlatformModules/PlatformModulesEdit";
import { APProjectHistoryAdd } from "./AP/ProjectHistory";
import { APSubCategoriesAdd, APSubCategoriesEdit, APSubCategoriesList } from "./AP/SubCategories";
import { APSubscriberAdd, APSubscriberEdit, APSubscriberList } from "./AP/Subscriber";
import { APTagsAdd, APTagsEdit, APTagsList } from "./AP/Tags";
import { APTaskList } from "./AP/Task/Task";
import { APTaskAdd } from "./AP/Task/TaskAdd";
import { APTaskEdit } from "./AP/Task/TaskEdit";
import { APVariablesAdd, APVariablesEdit, APVariablesList } from "./AP/Variables";
import { APVariableSetsAdd, APVariableSetsEdit, APVariableSetsList } from "./AP/VariableSets";

const Account = (props) => {
  const LoggedIn = useRef(false);
  const fetchAllData = () => {
    props.fetchControllerConfigs();
    // employess
    props.fetchAnnouncementsList({});
    props.fetchPayrollDataSelf({});
    props.getAllTickets({});
    props.fetchEmployeesBirthdays({});
    // props.fetchSubcategories({});
    // props.fetchEmployees({ employee: true });
    props.fetchUsers({ employee: true });
    props.fetchModules({});
    // admin
    props.fetchAttributes();
  };

  const fetchPermissionBasedData = (permissions) => {
    // if (permissions.employees_list.value) {
    //   props.fetchEmployees({});
    // }
    if (permissions.recruitment_list.value) {
      props.fetchRecruits();
    }
    if (permissions.payrolls_view.value) {
      props.fetchPayrollData();
    }
    if (permissions.tickets.value) {
      props.getAllTickets({ admin: true });
    }
    if (permissions.organisations_list.value || props.OGAdmin) {
      props.organisationsFetch({
        isOG: props.superAdmin ? false : props.OGAdmin,
      });
    }
    // if (permissions.jobs_list.value) {
    //   props.fetchAllJobs({});
    // }
    if (permissions.emailtemplates_list.value) {
      props.fetchEmailTemplate({});
    }
  };

  useEffect(() => {
    const devEnv = ["dev", "local"];
    if (!devEnv.includes(config.environment)) {
      document.addEventListener("contextmenu", (e) => e.preventDefault());
      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (ctrlShiftKey(e, "C")) return false;
      };
    }
    if ((!LoggedIn.current && props.sessionid) || (props.fetchAllData && props.sessionid)) {
      fetchAllData();
      socketInstance.userChanged({ userId: props.userId, organisationId: props.organisationId });
      // console.log(props.userId, props.organisationId)
      props.updateOrganisationSuccess();
      props.getProfile({
        loader: true,
        onSuccess: (res) => {
          if (res.data?.permissions) {
            fetchPermissionBasedData(res.data.permissions);
          }
        },
      });
      LoggedIn.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sessionid, props.fetchAllData]);

  return (
    <>
      {props.isLoading ? (
        <Loader />
      ) : (
        <Router>
          {!props.sessionid ? (
            <Routes>
              <Route path={"*"} element={GSLogin()} />
            </Routes>
          ) : (
            <Routes>
              <Route path={"/"} element={<Navigate replace to='/ep' />} />
              <Route path={"ep"}>
                <Route path={""}>
                  <Route index={true} element={EPHome()} />
                </Route>
                <Route path={"profile"}>
                  <Route index={true} element={EPProfile()} />
                </Route>

                <Route path={"payrolls"}>
                  <Route index={true} element={EPPayrolls()} />
                  <Route path={":id/view"} element={EPPayrollsView()} />
                </Route>
                <Route path={"tickets/created"}>
                  <Route index={true} element={EPTickets()} />
                  <Route path={"add"} element={EPTicketsAdd()} />
                  <Route path={":id/details"} element={EPTicketsDetails()} />
                </Route>
                <Route path={"knowledge"}>
                  <Route index={true} element={EPKnowledgeDashboard()} />
                  <Route path={"dashboard/"} element={EPKnowledgeDashboard()} />
                  <Route path={"approvals/"} element={EPInReviewKnowledgeArticles()} />
                  <Route path={"all/"} element={EPKnowledgeListing()} />
                  <Route path={"owner/"} element={EPMyKnowledgeArticles()} />
                  <Route path={"published/"} element={EPPublishedKnowledgeArticles()} />
                  <Route path={"list/"} element={EPKnowledgeListing()} />
                  <Route path={"add"} element={EPKnowledgeAdd()} />
                  <Route path={":id/edit"} element={EPKnowledgeEdit()} />
                  <Route path={":id/details"} element={EPKnowledgeDetails()} />
                  <Route path={":id/revise"} element={EPKnowledgeRevise()} />
                </Route>
                <Route path={"tickets/assigned"}>
                  <Route index={true} element={EPTicketsAssigned()} />
                  <Route path={":id/details"} element={EPTicketsAssignedDetails()} />
                </Route>
                <Route path={"tickets/all"}>
                  <Route index={true} element={EPAllTickets()} />
                  <Route path={":id/details"} element={EPAllTicketsDetails()} />
                </Route>
                {props.permissions?.todo ? (
                  <Route path={"todo"}>
                    <Route index={true} element={EPProfile()} />
                  </Route>
                ) : (
                  <Route path={"*"} element={GS404({ route: "employee", type: "permission" })} />
                )}
                <Route path={"*"} element={GS404({ route: "employee" })} />
              </Route>
              {(props.admin || props.superAdmin) && (
                <Route path={"ap"}>
                  <Route path={""}>
                    <Route index={true} element={APHome()} />
                  </Route>
                  <Route path='checklist-template'>
                    <Route index={true} element={<APChecklistTemplateList />} />
                    <Route path={"add"} element={<APChecklistTemplateAdd />} />
                    <Route path={":id"} element={<APChecklistTemplateEdit />} />
                  </Route>
                  <Route path='attributes/:name'>
                    <Route index={true} element={AttributesList()} />
                    <Route path={"add"} element={AttributesAdd()} />
                    <Route path={":id/edit"} element={AttributesEdit()} />
                  </Route>
                  <Route path='departments'>
                    <Route index={true} element={APDepartmentsList()} />
                    <Route path={"add"} element={APDepartmentsAdd()} />
                    <Route path={":id/edit"} element={APDepartmentsEdit()} />
                  </Route>
                  <Route path='designations'>
                    <Route index={true} element={APDesignationsList()} />
                    <Route path={"add"} element={APDesignationsAdd()} />
                    <Route path={":id/edit"} element={APDesignationsEdit()} />
                  </Route>
                  <Route path='country'>
                    <Route index={true} element={<APCountryList />} />
                    <Route path={"add"} element={<APCountryAdd />} />
                    <Route path={":id"} element={<APCountryEdit />} />
                  </Route>
                  {props.permissions?.currency_list ? (
                    <Route path={"currency"}>
                      <Route index={true} element={<APCurrencyList />} />
                      <Route path={"add"} element={<APCurrencyAdd />} />
                      <Route path={":id"} element={<APCurrencyEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.companyContact &&
                  props.permissions?.companyContact_list ? (
                    <Route path='company-contact'>
                      <Route index={true} element={<APCompanyContactList />} />
                      <Route path={"add"} element={<APCompanyContactAdd />} />
                      <Route path={":id"} element={<APCompanyContactEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.itnServices &&
                  props.permissions?.itnServices_list ? (
                    <Route path='itn-Services'>
                      <Route index={true} element={<APITNServicesList />} />
                      <Route path={"add"} element={<APITNServicesAdd />} />
                      <Route path={":id"} element={<APITNServicesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.itnRFP && props.permissions?.itnRFP_list ? (
                    <Route path='itn-rfp'>
                      <Route index={true} element={<APITNRFPList />} />
                      <Route path={"add"} element={<APITNRFPAdd />} />
                      <Route path={":id"} element={<APITNRFPEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path='company'>
                    <Route index={true} element={APCompanyList()} />
                    <Route path={"add"} element={APCompanyAdd()} />
                    <Route path={":id/edit"} element={APCompanyEdit()} />
                  </Route>
                  {props.organisationPermissions?.academyCourses &&
                  props.permissions?.academyCourses_list ? (
                    <Route path='academy-courses'>
                      <Route index={true} element={APAcademyCoursesList()} />
                      <Route path={"add"} element={APAcademyCoursesAdd()} />
                      <Route path={":id"} element={APAcademyCoursesEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyCandidates &&
                  props.permissions.academyCandidates_list ? (
                    <Route path='academy-candidates'>
                      <Route index={true} element={APAcademyCandidatesList()} />
                      {/* <Route path={"add"} element={APAcademyCandidatesAdd()} /> */}
                      <Route path={":id"} element={APAcademyCandidatesEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyCandidateLeads &&
                  props.permissions.academyCandidateLeads_list ? (
                    <Route path='academy-candidate-leads'>
                      <Route index={true} element={APAcademyCandidateLeadsList()} />
                      <Route path={"add"} element={APAcademyCandidateLeadsAdd()} />
                      <Route path={":id/"} element={APAcademyCandidateLeadsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyBatch &&
                  props.permissions.academyBatch_list ? (
                    <Route path='academy-batch'>
                      <Route index={true} element={APAcademyBatchList()} />
                      <Route path={"add"} element={APAcademyBatchAdd()} />
                      <Route path={":id"} element={APAcademyBatchEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyCandidateEnrollments &&
                  props.permissions.academyCandidateEnrollments_list ? (
                    <Route path='academy-candidate-enrollments'>
                      <Route index={true} element={APAcademyCandidateEnrollmentsList()} />
                      <Route path={"add"} element={APAcademyCandidateEnrollmentsAdd()} />
                      <Route path={":id"} element={APAcademyCandidateEnrollmentsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyACH &&
                  props.permissions.academyACH_list ? (
                    <Route path='academy-ach'>
                      <Route index={true} element={APAcademyACHList()} />
                      <Route path={"add"} element={APAcademyACHAdd()} />
                      <Route path={":id"} element={APAcademyACHEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyStudentProfile &&
                  props.permissions.academyStudentProfile_list ? (
                    <Route path='academy-learners-profile'>
                      <Route index={true} element={APAcademyStudentProfileList()} />
                      <Route path={"add"} element={APAcademyStudentProfileAdd()} />
                      <Route path={":id"} element={APAcademyStudentProfileEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.academyMentorFeedback &&
                  props.permissions.academyMentorFeedback_list ? (
                    <Route path='academy-mentor-feedback'>
                      <Route index={true} element={APAcademyMentorFeedbackList()} />
                      <Route path={"add"} element={APAcademyMentorFeedbackAdd()} />
                      <Route path={":id"} element={APAcademyMentorFeedbackEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.benefits && props.permissions.benefits_list ? (
                    <Route path='benefits'>
                      <Route index={true} element={APBenefitsList()} />
                      <Route path={"add"} element={APBenefitsAdd()} />
                      <Route path={":id"} element={APBenefitsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.platformModules &&
                  props.permissions.platformModules_list ? (
                    <Route path={"platform-modules"}>
                      <Route index={true} element={<APPlatformModulesList />} />
                      <Route path={":id"} element={<APPlatformModulesEdit />} />
                      <Route path={"add"} element={<APPlatformModulesAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path='task'>
                    <Route index={true} element={APTaskList()} />
                    <Route path={"add"} element={APTaskAdd()} />
                    <Route path={":id"} element={APTaskEdit()} />
                  </Route>
                  {props.organisationPermissions?.form16 && props.permissions.form16_list ? (
                    <Route path='form-16'>
                      <Route index={true} element={APForm16List()} />
                      <Route path={"add"} element={APForm16Add()} />
                      <Route path={":id"} element={APForm16Edit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {/* {props.organisationPermissions?.bankDetails &&
                  props.permissions.addressHistory_list ? ( */}
                  <Route path='bank-details'>
                    <Route index={true} element={APBankDetailsList()} />
                    <Route path={"add"} element={APBankDetailsAdd()} />
                    <Route path={":id"} element={APBankDetailsEdit()} />
                  </Route>
                  {/* ) : (
                   <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                 )} */}
                  {props.organisationPermissions?.piiDetails &&
                  props.permissions.piiDetails_list ? (
                    <Route path='pii-details'>
                      <Route index={true} element={APPIIDetailsList()} />
                      <Route path={"add"} element={APPIIDetailsAdd()} />
                      <Route path={":id"} element={APPIIDetailsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.certifications &&
                  props.permissions.certificationsAccreditations_list ? (
                    <Route path='certifications-accreditations'>
                      <Route index={true} element={<APCertificationsAccreditationsList />} />
                      <Route path={"add"} element={<APCertificationsAccreditationsAdd />} />
                      <Route path={":id"} element={<APCertificationsAccreditationsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.educationDetails &&
                  props.permissions.educationDetails_list ? (
                    <Route path='education-details'>
                      <Route index={true} element={APEducationDetailsList()} />
                      <Route path={"add"} element={APEducationDetailsAdd()} />
                      <Route path={":id"} element={APEducationDetailsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}{" "}
                  {props.organisationPermissions?.employmentHistory &&
                  props.permissions.employmentHistory_list ? (
                    <Route path='employment-history'>
                      <Route index={true} element={APEmploymentHistoryList()} />
                      <Route path={"add"} element={APEmploymentHistoryAdd()} />
                      <Route path={":id"} element={APEmploymentHistoryEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path='project-history'>
                    <Route index={true} element={APProjectHistoryList()} />
                    <Route path={"add"} element={APProjectHistoryAdd()} />
                    <Route path={":id/edit"} element={APProjectHistoryEdit()} />
                  </Route>
                  {props.organisationPermissions?.addressHistory &&
                  props.permissions.addressHistory_list ? (
                    <Route path='address-history'>
                      <Route index={true} element={APAddressHistoryList()} />
                      <Route path={"add"} element={APAddressHistoryAdd()} />
                      <Route path={":id"} element={APAddressHistoryEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions.organisations_list ? (
                    <Route path={"organisations"}>
                      <Route index={true} element={APOrganisations()} />
                      <Route path={":id/edit"} element={APOrganisationsEdit()} />
                      <Route path={"add"} element={APOrganisationsAdd()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.OGAdmin ? (
                    <Route path={"manage"}>
                      <Route path={"organisations/:id/edit"} element={APManageOrganization()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.OGAdmin ? (
                    <Route path={"manage"}>
                      <Route path={"organisations/:id/edit"} element={APManageOrganization()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path={"announcements"}>
                    <Route index={true} element={<APAnnouncementsList />} />
                    <Route path=':id' element={<APAnnouncementEdit />} />
                    <Route path='add' element={<APAnnouncementAdd />} />
                  </Route>
                  <Route path={"knowledge"}>
                    <Route index={true} element={APKnowledges()} />
                    <Route path={":id/details"} element={APKnowledgeDetail()} />
                    <Route path={"filters"} element={APKnowledgesFilters()} />
                  </Route>
                  {props.permissions.users_list ? (
                    <Route path={"users"}>
                      <Route index={true} element={APUsers()} />
                      <Route path={"add"} element={APUsersAdd()} />
                      <Route path={":id"} element={APUsersEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "employee", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.employees && props.permissions.employees_list ? (
                    <Route path={"employees"}>
                      <Route index={true} element={APEmployees()} />
                      <Route path={"old"} element={EmployeesOldRoute()} />
                      <Route path={"add"} element={APEmployeesAdd()} />
                      <Route path={"pod"} element={POD()} />
                      <Route path={":id/view"} element={APEmployeesView()} />
                      <Route path={":id"} element={APEmployeesEdit()} />
                      <Route path={":id/comments"} element={APEmployeesComments()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions.payrolls_view ? (
                    <Route path={"payrolls"}>
                      <Route index={true} element={APPayrolls()} />
                      <Route path={":id/:employee/edit"} element={APPayrollsController()} />
                      <Route path={":id/childs"} element={APPayrollsChildList()} />
                      <Route
                        path={":id/edit/:employee/details"}
                        element={APPayrollsDetailsController()}
                      />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions.recruitment_list ? (
                    <Route path={"recruitment"}>
                      <Route index={true} element={APRecruitments()} />
                      <Route path={"add"} element={APRecruitmentsAdd()} />
                      <Route path={":id/view"} element={APRecruitmentsView()} />
                      <Route path={":id/edit"} element={APRecruitmentsEdit()} />
                      <Route path={":id/history"} element={APRecruitmentsHistory()} />
                      <Route path={":id/assignment"} element={APRecruitmentsAssignments()} />
                      <Route path={":id/survey"} element={APRecruitmentsSurvey()} />
                      <Route path={":id/checklist"} element={APRecruitmentsChecklist()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.jobs_list ? (
                    <Route path={"jobs"}>
                      <Route index={true} element={<APJobsList />} />
                      <Route path={"add"} element={<APJobsAdd />} />
                      <Route path={":id"} element={<APJobsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.subcategories_list ? (
                    <Route path={"sub-categories"}>
                      <Route index={true} element={<APSubCategoriesList />} />
                      <Route path={"add"} element={<APSubCategoriesAdd />} />
                      <Route path={":id"} element={<APSubCategoriesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.groups_list && (
                    <Route path={"groups"}>
                      <Route index={true} element={<APGroupsList />} />
                      <Route path={"add"} element={<APGroupsAdd />} />
                      <Route path={":id"} element={<APGroupsEdit />} />
                      {/* <Route path={":id/permissions"} element={APGroupsPermissions()} />
                      <Route path={":id/members"} element={APGroupsMembers()} /> */}
                    </Route>
                  )}
                  {props.permissions?.knowledgebase_list ? (
                    <Route path={"knowledgebase"}>
                      <Route index={true} element={APKnowledgebaseListing()} />
                      <Route path={"add"} element={APKnowledgebaseAdd()} />
                      <Route path={":id/edit"} element={APKnowledgebaseEdit()} />
                      <Route path={":id/members"} element={APKnowledgebaseMember()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.spsAccounts_list ? (
                    <Route path={"sps-accounts"}>
                      <Route index={true} element={<APSPSAccountsListing />} />
                      <Route path={":id"} element={<APSPSAccountsEdit />} />
                      <Route path={"add"} element={<APSPSAccountsAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.spsAccountContact_list ? (
                    <Route path={"sps-account-contact"}>
                      <Route index={true} element={<APSPSAccountContactListing />} />
                      <Route path={":id"} element={<APSPSAccountContactEdit />} />
                      <Route path={"add"} element={<APSPSAccountContactAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.spsSalesProfile_list ? (
                    <Route path={"sps-sales-profile"}>
                      <Route index={true} element={<APSPSSalesProfileListing />} />
                      <Route path=':id' element={<APSPSSalesProfileEdit />} />
                      <Route path='add' element={<APSPSSalesProfileAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {/* {props.permissions?.spssalesprofilecredentials_list ? (
                    <Route path={"spssalesprofilecredentials"}>
                      <Route index={true} element={APSalesProfileCredentialsListing()} />
                      <Route path=':id/edit' element={<APSPSSalesProfileEdit />} />
                      <Route path='add' element={<APSPSSalesProfileAdd />} />
                      <Route path={":id/edit/worklog"} element={APSPSSalesProfileWorklog()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )} */}
                  {props.permissions?.spsDeals_list ? (
                    <Route path={"sps-deals"}>
                      <Route index={true} element={<APSPSDealsList />} />
                      <Route path={":id"} element={<APSPSDealsEdit />} />
                      <Route path={"add"} element={<APSPSDealskAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.spsBGCheck_list ? (
                    <Route path={"sps-bg-check"}>
                      <Route index={true} element={<APSPSBGCheckList />} />
                      <Route path={":id"} element={<APSPSBGCheckEdit />} />
                      <Route path={"add"} element={<APSPSBGCheckAdd />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.spsPaperwork_list ? (
                    <Route path='sps-paperwork'>
                      <Route index={true} element={<APSPSPaperworkList />} />
                      <Route path={"add"} element={<APSPSPaperworkAdd />} />
                      <Route path={":id"} element={<APSPSPaperworkEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.newsletterLeads_list ? (
                    <Route path='newsletter-leads'>
                      <Route index={true} element={<APNewsletterLeadsList />} />
                      <Route path={"add"} element={<APNewsletterLeadsAdd />} />
                      <Route path={":id"} element={<APNewsletterLeadsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.newsletterResources_list ? (
                    <Route path='newsletter-resources'>
                      <Route index={true} element={<APNewsletterResourcesList />} />
                      <Route path={"add"} element={<APNewsletterResourcesAdd />} />
                      <Route path={":id"} element={<APNewsletterResourcesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.leadLists_list ? (
                    <Route path='lead-lists'>
                      <Route index={true} element={<APLeadListsList />} />
                      <Route path={"add"} element={<APLeadListsAdd />} />
                      <Route path={":id"} element={<APLeadListsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.campaignsAssets_list ? (
                    <Route path='Campaigns'>
                      <Route index={true} element={<APCampaignsAssetsList />} />
                      <Route path={"add"} element={<APCampaignsAssetsAdd />} />
                      <Route path={":id"} element={<APCampaignsAssetsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.campaign_list ? (
                    <Route path='campaign'>
                      <Route index={true} element={<APCampaignsList />} />
                      <Route path={"add"} element={<APCampaignsAdd />} />
                      <Route path={":id"} element={<APCampaignsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path='subscribers'>
                    <Route index={true} element={<APSubscriberList />} />
                    <Route path={"add"} element={<APSubscriberAdd />} />
                    <Route path={":id"} element={<APSubscriberEdit />} />
                  </Route>
                  {props.organisationPermissions?.variableSets &&
                  props.permissions?.variableSets_list ? (
                    <Route path='variable-sets'>
                      <Route index={true} element={<APVariableSetsList />} />
                      <Route path={"add"} element={<APVariableSetsAdd />} />
                      <Route path={":id"} element={<APVariableSetsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.serviceNowSolutions &&
                  props.permissions?.serviceNowSolutions_list ? (
                    <Route path='servicenow-solutions'>
                      <Route index={true} element={<APServiceNowSolutionsListing />} />
                      <Route path={"add"} element={<APServiceNowSolutionsAdd />} />
                      <Route path={":id"} element={<APServiceNowSolutionsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.resources && props.permissions?.resources_list ? (
                    <Route path='resources'>
                      <Route index={true} element={<APResourcesListing />} />
                      <Route path={"add"} element={<APResourcesAdd />} />
                      <Route path={":id"} element={<APResourcesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.emailSchedular &&
                  props.permissions?.emailSchedular_list ? (
                    <Route path='email-schedular'>
                      <Route index={true} element={<APEmailSchedularList />} />
                      <Route path={"add"} element={<APEmailSchedularAdd />} />
                      <Route path={":id"} element={<APEmailSchedularEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.variables && props.permissions?.variables_list ? (
                    <Route path='variables'>
                      <Route index={true} element={<APVariablesList />} />
                      <Route path={"add"} element={<APVariablesAdd />} />
                      <Route path={":id"} element={<APVariablesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.itnContact_list ? (
                    <Route path='itn-contactus'>
                      <Route index={true} element={<APContactUsList />} />
                      <Route path={"add"} element={<APContactUsAdd />} />
                      <Route path={":id"} element={<APContactUsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {
                    <Route path='bug-reporting'>
                      <Route index={true} element={<APBugReportingList />} />
                      <Route path={"add"} element={<APBugReportingAdd />} />
                      <Route path={":id"} element={<APBugReportingEdit />} />
                    </Route>
                  }
                  <Route>
                    {props.permissions?.spsProjects_list ? (
                      <Route path='sps-projects'>
                        <Route index={true} element={<APSPSProjectsListing />} />
                        <Route path={"add"} element={<APSPSProjectsAdd />} />
                        <Route path={":id"} element={<APSPSProjectsEdit />} />
                      </Route>
                    ) : (
                      <Route
                        path={"*"}
                        element={GS404({
                          route: "admin",
                          type: "permission",
                        })}
                      />
                    )}
                  </Route>
                  <Route>
                    {props.permissions?.spsInsurance_list ? (
                      <Route path='sps-insurance'>
                        <Route index={true} element={<SPSInsuranceListing />} />
                        <Route path={"add"} element={<SPSInsuranceAdd />} />
                        <Route path={":id"} element={<SPSInsuranceEdit />} />
                      </Route>
                    ) : (
                      <Route
                        path={"*"}
                        element={GS404({
                          route: "admin",
                          type: "permission",
                        })}
                      />
                    )}
                  </Route>
                  {props.permissions?.categories_list ? (
                    <Route path={"categories"}>
                      <Route index={true} element={<APCategoriesList />} />
                      <Route path={"add"} element={<APCategoriesAdd />} />
                      <Route path={":id"} element={<APCategoriesEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.modules_list ? (
                    <Route path={"tags"}>
                      <Route index={true} element={<APTagsList />} />
                      <Route path={"add"} element={<APTagsAdd />} />
                      <Route path={":id"} element={<APTagsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {/* {props.permissions?.modules_list ? (
                    <Route path={"modules"}>
                      <Route index={true} element={APModulesListing()} />
                      <Route path={"add"} element={APModulesAdd()} />
                      <Route path={":id/edit"} element={APModulesEdit()} />
                      <Route path={"filter"} element={APModulesFilter()} />
                      <Route path='pod' element={APModulesPOD()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )} */}
                  {props.permissions?.media_list ? (
                    <Route path={"media"}>
                      <Route index={true} element={APMedia()} />
                      <Route path={"add"} element={APMediaAdd()} />
                      <Route path={":id/edit"} element={APMediaEdit()} />
                      <Route path={"filter"} element={APMediaFilter()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.tickets ? (
                    <Route path={"tickets"}>
                      <Route index={true} element={APTickets()} />
                      <Route path={"add"} element={APTicketsAdd()} />
                      <Route path={"filters"} element={ApTicketFilters()} />
                      <Route path={":id/details"} element={APTicketsDetails()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.emailtemplates_list ? (
                    <Route path={"emailtemplates"}>
                      <Route index={true} element={APTemplatesListing()} />
                      <Route path={":id/edit"} element={APTemplatesController()} />
                      <Route path={"filter"} element={APEmailTemplateFilters()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.dashboardAdministration_list ? (
                    <Route path={"dashboard-administration"}>
                      <Route index={true} element={<APDashboardAdministrationList />} />
                      <Route path={"add"} element={<APDashboardAdministrationAdd />} />
                      <Route path={":id"} element={<APDashboardAdministrationEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.dashboardWidget_list ? (
                    <Route path={"dashboard-widgets"}>
                      <Route index={true} element={<APDashboardWidgetsList />} />
                      <Route path={"add"} element={<APDashboardWidgetsAdd />} />
                      <Route path={":id"} element={<APDashboardWidgetsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.permissions?.dashboardTab_list ? (
                    <Route path={"dashboard-tabs"}>
                      <Route index={true} element={<APDashboardTabsList />} />
                      <Route path={"add"} element={<APDashboardTabsAdd />} />
                      <Route path={":id"} element={<APDashboardTabsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.catalogAdministrations &&
                  props.permissions.catalogAdministrations_list ? (
                    <Route path='catalog-administrations'>
                      <Route index={true} element={APCatalogAdministrationsList()} />
                      <Route path={"add"} element={APCatalogAdministrationsAdd()} />
                      <Route path={":id"} element={APCatalogAdministrationsEdit()} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.workspaces &&
                  props.permissions?.workspaces_list ? (
                    <Route path='workspaces'>
                      <Route index={true} element={<APWorkspaceList />} />
                      <Route path={"add"} element={<APWorkspaceAdd />} />
                      <Route path={":id"} element={<APWorkspaceEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.spaces && props.permissions?.spaces_list ? (
                    <Route path='spaces'>
                      <Route index={true} element={<APSpaceList />} />
                      <Route path={"add"} element={<APSpaceAdd />} />
                      <Route path={":id"} element={<APSpaceEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.kanbanConfig &&
                  props.permissions.kanbanConfig_list ? (
                    <Route path='kanban-config'>
                      <Route index={true} element={<APKanbanConfigList />} />
                      <Route path={"add"} element={<APKanbanConfigAdd />} />
                      <Route path={":id"} element={<APKanbanConfigEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.notificationsDefinations &&
                  props.permissions.notificationsDefinations_list ? (
                    <Route path='notificationsDefinations'>
                      <Route index={true} element={<APNotificationsDefinitionsList />} />
                      <Route path={"add"} element={<APNotificationsDefinitionsAdd />} />
                      <Route path={":id"} element={<APNotificationsDefinitionsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  {props.organisationPermissions?.serviceRequests &&
                  props.permissions.serviceRequests_list ? (
                    <Route path='service-requests'>
                      <Route index={true} element={<APServiceRequestsList />} />
                      <Route path={"add"} element={<APServiceRequestsAdd />} />
                      <Route path={":id"} element={<APServiceRequestsEdit />} />
                    </Route>
                  ) : (
                    <Route path={"*"} element={GS404({ route: "admin", type: "permission" })} />
                  )}
                  <Route path={"table/filters"}>
                    <Route index={true} element={APFiltersList()} />
                    <Route path={"add"} element={APFiltersAdd()} />
                    <Route path={":id/edit"} element={APFiltersEdit()} />
                  </Route>
                  {/* <Route path={"drive"}>
                    <Route index={true} element={APDriveList()} />
                  </Route> */}
                  <Route path={"*"} element={GS404({ route: "admin" })} />
                </Route>
              )}

              <Route path={"*"} element={GS404({ route: "employee" })} />
            </Routes>
          )}
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.UserReducer.id,
  organisationId: state.UserReducer.organisation._id,
  sessionid: state.UserReducer.sessionid,
  isLoading: state.UserReducer.isLoading,
  fetchAllData: state.UserReducer.fetchAllData,
  permissions: state.UserReducer.permissions,
  admin: state.UserReducer.admin,
  superAdmin: state.UserReducer.superAdmin,
  OGAdmin: state.UserReducer.organisationAdmin,
  organisationPermissions: state.UserReducer?.organisation?.permissions,
});

const mapDispatchToProps = {
  fetchAttributes,
  fetchEmployees,
  fetchRecruits,
  fetchUsers,
  getProfile,
  fetchPayrollData,
  // empoloyees
  getAllTickets,
  fetchPayrollDataSelf,
  organisationsFetch,
  fetchAnnouncementsList,
  updateOrganisationSuccess,
  // fetchAllJobs,
  fetchEmailTemplate,
  fetchKnowledgebase,
  // fetchGroups,
  fetchAllKnowledge,
  fetchModules,
  // fetchSPSDeals,
  fetchEmployeesBirthdays,
  fetchControllerConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
