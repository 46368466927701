/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPS_PROJECTS = "FETCH_SPS_PROJECTS",
  FETCH_SPS_PROJECTS_BY_ID = "FETCH_SPS_PROJECTS_BY_ID",
  SUCCESS_FETCH_SPS_PROJECTS = "SUCCESS_FETCH_SPS_PROJECTS",
  ERROR_FETCH_SPS_PROJECTS = "ERROR_FETCH_SPS_PROJECTS",
  CREATE_SPS_PROJECTS = "CREATE_SPS_PROJECTS",
  UPDATE_SPS_PROJECTS = "UPDATE_SPS_PROJECTS",
  DELETE_SPS_PROJECTS = "DELETE_SPS_PROJECTS",
}

export default Types;
