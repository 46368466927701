import React from "react";

import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyCoursesColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    name: {
      title: "Name",
      sortSupported: true,
      searchSupported: true,
      key: "name",
    },
    active: {
      title: "Active",
      sortSupported: true,
      key: "active",

      render: (item) => {
        return <text className='title'>{item.active ? "Yes" : "No"}</text>;
      },
    },
    technology: {
      title: "Technology",
      sortSupported: true,
      key: "technology",
      render: (item) => {
        return <text className='title'>{item.technology?.value || "-"}</text>;
      },
    },
    preparedBy: {
      title: "Prepared By",
      key: "preparedBy",
      render: (item) => {
        return <text className='title'>{item.preparedBy?.name || "-"}</text>;
      },
    },
    curriculamAttached: {
      title: "Curriculum Attached",
      sortSupported: true,
      key: "curriculamAttached",

      render: (item) => {
        return <text className='title'>{item.curriculamAttached ? "Yes" : "No"}</text>;
      },
    },
    courseDuration: {
      title: "Duration",
      sortSupported: true,
      key: "courseDuration",
    },
    shortDescription: {
      title: "Short Description",
      sortSupported: true,
      key: "shortDescription",
    },
    numberOfTopics: {
      title: "Number Of Topics",
      sortSupported: true,
      key: "numberOfTopics",
    },
    description: {
      title: "Description",
      sortSupported: true,
      key: "description",
    },
    downloadableResources: {
      title: "Downloadable Resources",
      sortSupported: true,
      key: "downloadableResources",
    },
    cardIconAttached: {
      title: "Card Icon Attached",
      sortSupported: true,
      key: "cardIconAttached",

      render: (item) => {
        return <text className='title'>{item.cardIconAttached ? "Yes" : "No"}</text>;
      },
    },
    cardBackgroundAttached: {
      title: "Card Background Attached",
      sortSupported: true,
      key: "cardBackgroundAttached",

      render: (item) => {
        return <text className='title'>{item.cardBackgroundAttached ? "Yes" : "No"}</text>;
      },
    },
    courseDetailsIconAttached: {
      title: "Course Details Icon Attached",
      sortSupported: true,
      key: "courseDetailsIconAttached",

      render: (item) => {
        return <text className='title'>{item.courseDetailsIconAttached ? "Yes" : "No"}</text>;
      },
    },
    prerequisites: {
      title: "Prerequisites",
      sortSupported: true,
      key: "prerequisites",
    },
    anyDriveURL: {
      title: "Any Drive URL",
      sortSupported: true,
      key: "anyDriveURL",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
