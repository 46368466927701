import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AcademyCoursesList,
  AddAcademyCourses,
  UpdateAcademyCourses,
  FindAcademyCourseById,
  DeleteAcademyCourses,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAcademyCoursesListSuccess, fetchAcademyCoursesError } from "./actions";
import Types from "./actionType";
import {
  CreateAcademyCoursesuccess,
  CreateAcademyCourses as CreateAcademyCoursesType,
  AcademyCoursesSuccess,
  UpdateAcademyCourses as UpdateAcademyCoursesType,
  FetchAcademyCourses,
  FetchAcademyCoursesById,
  AcademyCoursesByIdSuccess,
  DeleteAcademyCourses as DeleteAcademyCoursesType,
} from "./types";

function* createWorker(action: CreateAcademyCoursesType) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyCourses,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyCoursesuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* deleteWorker(action: DeleteAcademyCoursesType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAcademyCourses(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAcademyCoursesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateAcademyCoursesType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyCourses(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyCoursesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchAcademyCourses) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AcademyCoursesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AcademyCoursesSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchAcademyCoursesListSuccess(data.data));
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error?.response?.data?.message || "Error in fetching academy courses");
    }
  }
}

function* fetchAcademyCoursesById(action: FetchAcademyCoursesById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAcademyCourseById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AcademyCoursesByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "");
    }
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ACADEMY_COURSES, createWorker);
  yield takeLatest(Types.UPDATE_ACADEMY_COURSES, updateWorker);
  yield takeLatest(Types.DELETE_ACADEMY_COURSES, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_COURSES, fetchAllWorker);
  yield takeLatest(Types.DELETE_ACADEMY_COURSES, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_COURSES_BY_ID, fetchAcademyCoursesById);
}
