/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CERTIFICATIONS_ACCREDITATIONS = "FETCH_CERTIFICATIONS_ACCREDITATIONS",
  FETCH_CERTIFICATIONS_ACCREDITATIONS_BY_ID = "FETCH_CERTIFICATIONS_ACCREDITATIONS_BY_ID",
  SUCCESS_FETCH_CERTIFICATIONS_ACCREDITATIONS = "SUCCESS_FETCH_CERTIFICATIONS_ACCREDITATIONS",
  ERROR_FETCH_CERTIFICATIONS_ACCREDITATIONS = "ERROR_FETCH_CERTIFICATIONS_ACCREDITATIONS",
  CREATE_CERTIFICATIONS_ACCREDITATIONS = "CREATE_CERTIFICATIONS_ACCREDITATIONS",
  UPDATE_CERTIFICATIONS_ACCREDITATIONS = "UPDATE_CERTIFICATIONS_ACCREDITATIONS",
  DELETE_CERTIFICATIONS_ACCREDITATIONS = "DELETE_CERTIFICATIONS_ACCREDITATIONS",
}

export default Types;
