/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_CANDIDATE_LEADS = "FETCH_ACADEMY_CANDIDATE_LEADS",
  FETCH_ACADEMY_CANDIDATE_LEADS_BY_ID = "FETCH_ACADEMY_CANDIDATE_LEADS_BY_ID",
  SUCCESS_FETCH_ACADEMY_CANDIDATE_LEADS = "SUCCESS_FETCH_ACADEMY_CANDIDATE_LEADS",
  ERROR_FETCH_ACADEMY_CANDIDATE_LEADS = "ERROR_FETCH_ACADEMY_CANDIDATE_LEADS",
  CREATE_ACADEMY_CANDIDATE_LEADS = "CREATE_ACADEMY_CANDIDATE_LEADS",
  UPDATE_ACADEMY_CANDIDATE_LEADS = "UPDATE_ACADEMY_CANDIDATE_LEADS",
  DELETE_ACADEMY_CANDIDATE_LEADS = "DELETE_ACADEMY_CANDIDATE_LEADS",
}

export default Types;
