import Types from "./types";

const Initial = {
  id: "",
  name: "",
  email: "",
  admin: false,
  organisationAdmin: false,
  superAdmin: false,
  sessionid: "",
  token: "",
  permissions: {},
  timezone: "America/New_York",
  organisation: "",
  employee: "",
  organisations: [],
  fetchAllData: false,
  profilePic: "",
  isLoading: false,
  tableConfiguration: {},
};

const UserReducer = (state = Initial, action) => {
  let organisation = state.organisation;
  let employee = state.employee;
  let find = {};
  let admin = false;
  let organisationAdmin = false;
  switch (action.type) {
    case Types.USER_LOGIN_SUCCESS:
      organisation = state.organisation;
      if (!organisation && action.payload?.organisations[0]?.organisation) {
        organisation = action.payload?.organisations[0]?.organisation;
        admin = action.payload?.organisations[0].admin;
        organisationAdmin = action.payload?.organisations[0].organisationAdmin;
        employee = action.payload?.organisations[0]?.employee;
      } else if (organisation && action.payload?.organisations?.length > 0) {
        find = action.payload.organisations.find((el) => {
          if (el.organisation && el.organisation._id) {
            return el.organisation._id === organisation._id;
          }
        });
        organisation = find.organisation;
        admin = find.admin;
        organisationAdmin = find.organisationAdmin;
        employee = find.employee;
      }
      const loginPermissions = {};
      if (typeof action.payload.permissions === "object") {
        const permissionItem = action.payload.permissions;
        const keys = Object.keys(permissionItem);
        for (let i = 0; i < keys.length; i++) {
          const key = permissionItem[keys[i]];
          loginPermissions[key] = permissionItem[key].value;
        }
      }
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        admin: admin,
        organisationAdmin: organisationAdmin,
        superAdmin: action.payload.superAdmin,
        timezone: action.payload.timezone ?? state.timezone,
        organisation: organisation,
        employee: employee,
        organisations: action.payload.organisations,
        sessionid: action.payload.sessionid,
        token: action.payload.token,
        permissions: loginPermissions,
        profilePic: action.payload.profilePic,
        tableConfiguration: action.payload.tableConfiguration,
      };
    case Types.USER_PROFILE:
      if (action?.payload?.loader) {
        return {
          ...state,
          isLoading: true,
        };
      }
      return { ...state };
    case Types.USER_PROFILE_SUCCESS:
      organisation = state.organisation;

      if (!organisation && action.payload?.organisations[0]?.organisation) {
        organisation = action.payload?.organisations[0]?.organisation;
        admin = action.payload?.organisations[0]?.admin;
        organisationAdmin = action.payload?.organisations[0]?.organisationAdmin;
        employee = action.payload?.organisations[0]?.employee;
      } else if (organisation && action.payload?.organisations?.length > 0) {
        find = action.payload.organisations.find((el) => {
          if (el.organisation && el.organisation._id) {
            return el.organisation._id === organisation._id;
          }
        });
        organisation = find.organisation;
        admin = find.admin;
        organisationAdmin = find.organisationAdmin;
        employee = find.employee;
      }
      const userPermissions = {};
      if (typeof action.payload.permissions === "object") {
        const permissionItem = action.payload.permissions;
        const keys = Object.keys(permissionItem);
        for (let i = 0; i < keys.length; i++) {
          userPermissions[keys[i]] = permissionItem[keys[i]].value;
        }
      }
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        admin: admin,
        organisationAdmin: organisationAdmin,
        superAdmin: action.payload.superAdmin,
        timezone: action.payload.timezone ?? state.timezone,
        organisation: organisation,
        employee: employee,
        organisations: action.payload.organisations,
        permissions: userPermissions,
        profilePic: action.payload.profilePic,
        isLoading: false,
        tableConfiguration: action.payload.tableConfiguration,
      };
    case Types.USER_UPDATE_ORGANISATION:
      return {
        ...state,
        fetchAllData: true,
        organisation: action.payload.organisation,
        employee: action.payload.employee,
      };
    case Types.USER_UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        fetchAllData: false,
      };
    case Types.USER_LOGOUT_SUCCESS:
      return {
        ...Initial,
        fetchAllData: true,
      };
    default:
      return state;
  }
};

export default UserReducer;
