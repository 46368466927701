import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import QueryBuilder, { defaultValidator, formatQuery, RuleGroupTypeIC } from "react-querybuilder";
import { useNavigate } from "react-router-dom";

import { addAppNotification, removeAppNotification } from "../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { images, strings } from "../../../../theme";
import { AppNotificationsType, TableHeaderV3Props } from "../../../../types";
import { useCommonActions } from "../../../common";
import { Button } from "../../atoms/Button";
import { FilterButton } from "../../atoms/FilterButton";
import Search from "../../Search";
import { Popup } from "../Popup";

import {
  Container,
  LeftContainer,
  RightContainer,
  AttachmentsIcon,
  AttachmentsIconContainer,
  AttachmentsBadge,
  ListActionButtonsContainer,
  FilterButtonContainer,
  FilterIcon,
  FilterButonTitle,
  RefreshIconContainer,
  ListActionButtonIcon,
  SearchIconContainer,
  PersonalisedListContainer,
  FieldsListContainer,
  FieldListItem,
  FieldLisImage,
  FieldsPopup,
  FieldListTitle,
  SelectedFieldsListContainer,
  FilterContainer,
  FilterHeader,
  FilterRightHeaderContainer,
  FilterItemsContainer,
  FilterContainerNew,
} from "./styles";

const { add, filter } = strings.common;

export const TableHeaderV3 = ({
  onClickAdd,
  headerButtons,
  backButtonTitle,
  backButtonPress,
  onAttachmentsClick,
  onAttachmentsIconLoad,
  attachmentsBadge,
  onSearchPress,
  refreshList,
  handleTableConfigs,
  tablePublicFields,
  selectedConfigFields,
  disableFilter,
  filterLogicQuery,
  onClickApplyFilter,
  filters,
  isRelatedList,
}: TableHeaderV3Props) => {
  const navigate = useNavigate();
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const initialQuery: RuleGroupTypeIC = useMemo(() => {
    console.log("Filter ::", filterLogicQuery);
    return filterLogicQuery ? filterLogicQuery : { rules: [] };
  }, [filterLogicQuery]);
  const dispatch = useAppDispatch();
  const { handlePopupWrapperClick } = useCommonActions();
  const [fieldsPopop, setFieldsPopup] = useState<boolean>(false);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [fieldSearch, setFieldSearch] = useState<string>("");
  const [selectedFields, setSelectedFields] = useState<string[]>(selectedConfigFields || []);
  const [query, setQuery] = useState(initialQuery);

  const handleFilterPopup = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setFilterPopup((filter) => !filter);
  }, []);

  const handleApplyFilter = useCallback(() => {
    const jsonQuery = formatQuery(query, "json_without_ids");
    console.log("Query ::", query);
    const mongoQuery = jsonQuery === `{"rules":[]}` ? {} : formatQuery(query, "mongodb");
    setFilterPopup((filter) => !filter);
    onClickApplyFilter(mongoQuery, jsonQuery, query);
  }, [onClickApplyFilter, query]);

  const handleCloseFilter = useCallback(() => {
    setQuery(initialQuery);
    setFilterPopup(false);
  }, [initialQuery]);
  const handleClearFilter = useCallback(() => {
    setQuery({ rules: [] });
    setFilterPopup(false);
    onClickApplyFilter("", "", { rules: [] });
  }, [onClickApplyFilter]);

  const handleFieldsPopup = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setFieldsPopup((value) => !value);
  }, []);

  const clearFieldSearch = useCallback(() => {
    setFieldSearch("");
  }, []);

  const onFieldSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFieldSearch(value);
  }, []);

  const handleSelectField = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const field = e.currentTarget.getAttribute("data-item");
      if (field) {
        const fields = [...selectedFields];
        const index = fields.findIndex((item) => item === field);
        if (index >= 0) {
          fields.splice(index, 1);
        } else {
          fields.push(field);
        }
        setSelectedFields([...fields]);
      }
    },
    [selectedFields],
  );

  const handleDrop = useCallback(
    (droppedItem) => {
      if (!droppedItem.destination) return;
      const updatedList = [...selectedFields];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      setSelectedFields(updatedList);
    },
    [selectedFields],
  );

  const onSaveFields = useCallback(() => {
    if (!selectedFields?.length) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "No fields selected",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    handleTableConfigs && handleTableConfigs(selectedFields);
    setFieldsPopup(false);
    setFilterPopup(false);
    setFieldSearch("");
  }, [dispatch, handleTableConfigs, notificationsId, selectedFields]);

  const goBack = useCallback(() => {
    backButtonPress ? backButtonPress() : navigate(-1);
  }, [backButtonPress, navigate]);

  const handleSearchBar = useCallback(
    (e: React.MouseEvent) => {
      handlePopupWrapperClick(e);
      const event = new CustomEvent("tableListSearchBar", {
        detail: { popup: false },
      });

      document.dispatchEvent(event);
    },
    [handlePopupWrapperClick],
  );

  const attachmentsButton = useMemo((): React.JSX.Element => {
    return (
      <AttachmentsIconContainer>
        <AttachmentsIcon
          onLoad={onAttachmentsIconLoad}
          onClick={onAttachmentsClick}
          src={images.attachmentsIcon}
        />
        {!!attachmentsBadge && <AttachmentsBadge>{attachmentsBadge}</AttachmentsBadge>}
      </AttachmentsIconContainer>
    );
  }, [attachmentsBadge, onAttachmentsClick, onAttachmentsIconLoad]);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.type === "myCustomEvent") {
        if (event.detail.popup === false) {
          setFieldsPopup(false);
          setFilterPopup(false);
          setFieldSearch("");
        }
      }
    };

    document.addEventListener("myCustomEvent", eventListener);

    return () => {
      document.removeEventListener("myCustomEvent", eventListener);
    };
  }, []);

  const showHeaderActions = useMemo(() => {
    const isActive = !disableFilter || onSearchPress || handleTableConfigs;
    return isActive;
  }, [disableFilter, onSearchPress, handleTableConfigs]);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <Container onClick={() => console.log(query, initialQuery)}>
      <LeftContainer>
        {backButtonTitle && (
          <Button
            className='table-header-back-button'
            leftArrow
            title={backButtonTitle}
            onClick={goBack}
          />
        )}
      </LeftContainer>
      <RightContainer>
        {showHeaderActions && !isRelatedList && (
          <ListActionButtonsContainer>
            {!disableFilter && (
              <FilterButtonContainer onClick={handleFilterPopup}>
                <FilterIcon src={images.filterIcon.default} />
                <FilterButonTitle>{filter}</FilterButonTitle>
              </FilterButtonContainer>
            )}
            {onSearchPress && (
              <SearchIconContainer onClick={handleSearchBar}>
                <ListActionButtonIcon src={images.search} />
              </SearchIconContainer>
            )}
            {handleTableConfigs && (
              <PersonalisedListContainer onClick={handleFieldsPopup} active={fieldsPopop}>
                <ListActionButtonIcon src={images.personalisedListIcon} />
              </PersonalisedListContainer>
            )}
            {refreshList && (
              <RefreshIconContainer onClick={refreshList}>
                <ListActionButtonIcon src={images.refreshIcon} />
              </RefreshIconContainer>
            )}
            {fieldsPopop && (
              <FieldsPopup onClick={handlePopupWrapperClick}>
                <Search
                  containerClass={"table-field-search-container"}
                  inputClass='table-field-search-input'
                  inputProps={{
                    placeholder: strings.common.search,
                    onChange: onFieldSearch,
                    value: fieldSearch,
                  }}
                  clearSearch={clearFieldSearch}
                />
                {!!selectedFields.length && (
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId='list-container'>
                      {(provided) => (
                        <SelectedFieldsListContainer
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {selectedFields?.map((item, index) => {
                            const key = item;
                            const label = tablePublicFields?.find(
                              (item) => item.key === key,
                            )?.label;
                            if (item !== "_id") {
                              return (
                                <Draggable key={label} draggableId={label} index={index}>
                                  {(provided) => (
                                    <FieldListItem
                                      key={index}
                                      className='item-container'
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <FieldLisImage
                                        onClick={handleSelectField}
                                        data-item={key}
                                        src={images.checkedCheckbox}
                                      />
                                      <FieldListTitle className='table-list-field-drag-title'>
                                        {label}
                                      </FieldListTitle>
                                      <FieldLisImage
                                        src={images.dragIcon}
                                        className='table-list-field-drag'
                                      />
                                    </FieldListItem>
                                  )}
                                </Draggable>
                              );
                            }
                            return null;
                          })}

                          {provided.placeholder}
                        </SelectedFieldsListContainer>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <FieldsListContainer>
                  {tablePublicFields?.map((item, index) => {
                    const { label, key } = item;
                    if (
                      !selectedFields.includes(key) &&
                      ((fieldSearch !== "" && key.includes(fieldSearch)) || fieldSearch === "")
                    ) {
                      return (
                        <FieldListItem key={index}>
                          <FieldLisImage
                            onClick={handleSelectField}
                            data-item={key}
                            src={images.uncheckedCheckbox}
                          />
                          <FieldListTitle>{label}</FieldListTitle>
                        </FieldListItem>
                      );
                    }
                    return null;
                  })}
                </FieldsListContainer>
                <Button title={strings.common.save} onClick={onSaveFields} width={"100%"} />
              </FieldsPopup>
            )}
            {filterPopup && (
              <FilterContainerNew onClick={handlePopupWrapperClick}>
                <FilterContainer>
                  <FilterHeader>
                    <FilterButton
                      className='table-filter-modal-button'
                      title={strings.common.filter}
                    />
                    <FilterRightHeaderContainer>
                      <Button
                        className='table-filter-apply-button'
                        title={strings.filters.apply}
                        onClick={handleApplyFilter}
                      />
                      {/* <Button
                      className='table-filter-close-button'
                      title={strings.filters.close}
                      onClick={handleCloseFilter}
                    /> */}
                      <Button
                        className='table-filter-clear-button'
                        title={strings.filters.clear}
                        onClick={handleClearFilter}
                      />
                    </FilterRightHeaderContainer>
                  </FilterHeader>
                  <FilterItemsContainer>
                    {/* <QueryBuilderDnD> */}
                    <QueryBuilder
                      translations={{
                        addRule: {
                          label: "Add Rule",
                        },
                        addGroup: {
                          label: "Add Group",
                        },
                        removeRule: {
                          label: "Delete Rule",
                        },
                        removeGroup: {
                          label: "Delete Group",
                        },
                      }}
                      fields={filters}
                      query={query}
                      onQueryChange={(q) => setQuery(q)}
                      addRuleToNewGroups
                      debugMode
                      independentCombinators
                      showCombinatorsBetweenRules
                      validator={defaultValidator}
                      controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
                    />
                    {/* </QueryBuilderDnD> */}
                  </FilterItemsContainer>
                </FilterContainer>
              </FilterContainerNew>
            )}
          </ListActionButtonsContainer>
        )}
        {onAttachmentsClick && attachmentsButton}
        {headerButtons?.map((button, buttonIndex) => {
          const { title, onClick, visible, border, width, disabled } = button;
          if (visible) {
            return (
              <Button
                key={buttonIndex}
                disabled={disabled}
                border={border}
                title={title}
                onClick={onClick}
                width={width}
              />
            );
          }
          return null;
        })}
        {!isRelatedList && onClickAdd && <Button title={add} onClick={onClickAdd} />}
      </RightContainer>
    </Container>
  );
};
