import React from "react";

import { Layout } from "../../../presentationals";
import { SPSProjectsController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<SPSProjectsController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APSPSProjectsEdit = React.memo(Route);
