import React, { ReactNode } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

interface MyTooltipProps {
  text: string;
  children: ReactNode;
}

export const MyTooltip: React.FC<MyTooltipProps> = ({ text, children }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>} placement='top'>
      {({ ref, ...triggerHandler }) => (
        <div
          style={{
            maxWidth: "85%",
          }}
          ref={ref as React.MutableRefObject<HTMLDivElement>}
          {...triggerHandler}
        >
          {children}
        </div>
      )}
    </OverlayTrigger>
  );
};
