import styled from "styled-components";

import { colors, fonts, fs } from "../../../theme";

interface Props {
  open?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .table-header-back-button {
    border: 0.3px solid ${colors.common.white};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  column-gap: 20px;
  .search-container {
    height: 38px;
    width: 253px;
    border: 0.6px solid ${colors.common.borderColor};
    border-radius: 10px;
    margin-right: 10px;
  }
  .search-input {
    padding-left: 10px;
    font-size: ${fs.s};
    color: ${colors.common.lightTextColor};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  /* margin-right: 20px; */
`;

export const AdditionalButton = styled.div`
  /* margin-right: 60px; */
`;

export const ExtraSaveButton = styled.div`
  /* margin-left: 60px; */
`;

export const AttachmentsIconContainer = styled.div`
  display: flex;
  position: relative;
`;

export const AttachmentsIcon = styled.img`
  height: 35px;
  width: 35px;
  object-fit: contain;
  cursor: pointer;
`;

export const AttachmentsBadge = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 1rem;
  background-color: ${colors.common.yellow};
  position: absolute;
  font-size: ${fs.s};
  color: ${colors.common.white};
  font-weight: 600;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: -8px;
`;

export const RefreshButton = styled.div`
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  background: ${colors.common.white};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RefreshIcon = styled.img`
  object-fit: contain;
  width: 28px;
  height: 28px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 66%;
  height: 100%;
  position: relative;
  justify-content: space-between;
  .actions-dropdown-list {
    right: 0;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 8px 15px;
  background-color: ${colors.common.yellow};
  border-radius: 5px;
  box-sizing: border-box;
  align-items: center;
  color: ${colors.common.black};
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  font-family: ${fonts.SourceSansPro};
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const DropDownIcon = styled.img<Props>`
  height: 10px;
  width: 10px;
  object-fit: contain;
  margin-left: 20px;
  transition: 0.4s linear;
  transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
`;

export const DropdownListContainer = styled.div`
  display: flex;
  /* position: absolute; */
  min-width: 20vw;
  row-gap: 20px;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 10px;
  padding: 20px;
  z-index: 1;
  /* top: 38px; */
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const DropdownListItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.common.yellow};
  padding: 10px 100px;
  border-radius: 10px;
  font-size: 18px;
  /* line-height: 20px; */
  font-weight: 600;
  color: ${colors.common.white};
  font-family: ${fonts.SourceSansPro};
  transition: 0.4s linear;
  cursor: pointer;
  /* &:hover {
    background-color: ${colors.common.hoverTabBackground};
  } */
`;
