import React, { useCallback, useEffect, useMemo, useState } from "react";

import { TableList, TableListV2, TableListV3 } from "..";
import {
  addAppNotification,
  createChecklist,
  deleteChecklistById,
  fetchChecklistList,
  fetchControllerConfigs,
  removeAppNotification,
  updateChecklist,
} from "../../../../redux";
import {
  CommonChecklistItem,
  CreateChecklistData,
  CreateChecklistPayload,
  DeleteChecklistByIdPayload,
  FetchChecklistParams,
  FetchChecklistPayload,
  UpdateChecklistPayload,
} from "../../../../redux/Checklist/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { images, strings } from "../../../../theme";
import {
  AppNotificationsType,
  ChecklistTemplateChecklistItem,
  ChecklistTemplateChecklistItemForm,
  ColumnsType,
  IDValueType,
  SelectOptions,
  TableHeaderButton,
  metaDataTypes,
} from "../../../../types";
import { useControllerConfigs } from "../../../common";
import { AttachmentIcon, CloseIcon, FormTitle, Row } from "../../../common/styles";
import { Button, Checkbox, MyTooltip, Select, TextInput } from "../../atoms";
import { CheckboxImage } from "../../atoms/Checkbox/styles";
import { Popup } from "../../organisms/Popup";

import {
  CheckboxLabel,
  ChecklistCheckboxContainer,
  ChecklistFieldsContainer,
  ChecklistModalContainer,
  FormChecklistContainer,
} from "./styles";

interface Props {
  recordId?: string;
  collection: string;
  // onSubmitAddChecklist: (type: string, checkListItems: ChecklistTemplateChecklistItem[]) => void;
  // onSubmitUpdateChecklist: (
  //   currentChecklistId: string,
  //   checklistItem: ChecklistTemplateChecklistItem,
  // ) => void;
  // checklist: ChecklistTemplateChecklistItem[];
  // deleteChecklist: (e: React.MouseEvent) => void;
}
const defaultMetaData: metaDataTypes = {
  totalItems: 0,
  limit: 0,
  currentPage: 0,
  totalPages: 0,
  sortBy: "name",
  sortDirection: "asc",
};
export const FormChecklistV2 = ({ collection, recordId }: Props) => {
  // ================ Reducer Values ================
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const checklistTemplates = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.checklistTemplates,
  );
  // ================ Hooks ================

  const dispatch = useAppDispatch();

  const defaultChecklist: ChecklistTemplateChecklistItemForm = {
    name: {
      value: "",
      required: true,
      error: "",
    },
    assignedTo: {
      value: "",
      required: false,
      error: "",
    },
    value: {
      value: false,
      required: false,
      error: "",
    },
  };
  // ================Checklist State Values ================
  const [checklistTemplateModal, setChecklistTemplateModal] = useState<boolean>(false);
  const [checklistRecordId, setChecklistRecordId] = useState("");
  const [selectedChecklistTemplate, setSelectedChecklistTemplate] = useState<SelectOptions[]>([]);
  const [selectedChecklistValues, setSelectedChecklistValues] = useState<
    ChecklistTemplateChecklistItem[]
  >([]);
  const [checklist, setChecklist] = useState<ChecklistTemplateChecklistItem[]>([]);
  const [metaData, setMetaData] = useState<metaDataTypes>(defaultMetaData);
  const [loading, setLoading] = useState<boolean>(false);
  const [checklistModal, setChecklistModal] = useState<"add" | "edit" | "">("");
  const [currentChecklistId, setCurrentChecklistId] = useState<string>("");
  const [checklistFormData, setChecklistFormData] =
    useState<ChecklistTemplateChecklistItemForm>(defaultChecklist);
  const { usersOptions, checklistTemplatesOptions } = useControllerConfigs();

  const deleteChecklist = useCallback(
    (e: React.MouseEvent) => {
      setLoading(true);
      const checklistId = e.currentTarget?.getAttribute("data-index") || "";
      const deleteChecklistPayload: DeleteChecklistByIdPayload = {
        recordId: checklistRecordId,
        checklistId,
        onSuccess: () => {
          setLoading(false);
          // const index = checklist.findIndex((item) => item._id === checklistId);
          // if (index >= 0) {
          //   const updatedChecklist = checklist.splice(index, 1);
          //   setChecklist(updatedChecklist);
          // }
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Checklsit Deleted successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
        onError: (e) => {
          setLoading(false);
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: e,
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };

      dispatch(deleteChecklistById(deleteChecklistPayload));
    },
    [dispatch, notificationsId, checklistRecordId],
  );

  const renderDelete = useCallback(
    (item: ChecklistTemplateChecklistItem) => {
      return (
        <MyTooltip text='Delete'>
          <AttachmentIcon
            onClick={deleteChecklist}
            data-index={item._id}
            src={images.deleteAttachment}
          />
        </MyTooltip>
      );
    },
    [deleteChecklist],
  );

  const columns: ColumnsType[] = [
    {
      title: "Name",
      width: "25%",
      key: "name",
    },
    {
      title: "Assigned To",
      width: "25%",
      render: (item: ChecklistTemplateChecklistItem) => {
        return (
          <div className='title'>
            {typeof item.assignedTo !== "string" ? item?.assignedTo?.name : "-"}
          </div>
        );
      },
    },
    {
      title: "Completed",
      width: "25%",
      // center: true,
      render: (item: ChecklistTemplateChecklistItem) => {
        return <div className='title'>{item?.value ? "Yes" : "No"}</div>;
      },
    },
    {
      title: "Actions",
      width: "25%",
      // center: true,
      render: (item: ChecklistTemplateChecklistItem) => renderDelete(item),
    },
  ];

  // // ================ Checklist ================
  const clearFormData = useCallback(() => {
    const data = { ...checklistFormData };
    Object.keys(checklistFormData).forEach((field) => {
      const value = "";
      data[field].value = value;
      data[field].error = "";
    });
    setChecklistFormData(data);
  }, [checklistFormData]);

  const handleChecklistTemplateModal = useCallback(() => {
    setChecklistTemplateModal((value) => !value);
  }, []);

  const handleChecklistTemplate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedChecklistValues([]);
    setSelectedChecklistTemplate(value);
  }, []);

  const handleChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("");
  }, [clearFormData]);

  const addChecklistModal = useCallback(() => {
    clearFormData();
    setChecklistModal("add");
  }, [clearFormData]);

  const rehydrateChecklistFormData = useCallback(
    (checklistTemplate) => {
      const item = checklistTemplate;
      const data = { ...checklistFormData };
      Object.keys(checklistFormData).forEach((field) => {
        let value = item?.[field];
        if (field === "assignedTo") {
          value = item?.[field]?._id;
        }
        data[field].value = value;
      });
      setChecklistFormData(data);
    },
    [checklistFormData],
  );

  const editChecklistModal = useCallback(
    (id = "") => {
      setCurrentChecklistId(id);
      const item = checklist.find((item) => item._id === id);
      rehydrateChecklistFormData(item);
      setChecklistModal("edit");
    },
    [checklist, rehydrateChecklistFormData],
  );

  const onChecklistTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      const tempFormData = {
        ...checklistFormData,
        [name]: {
          ...checklistFormData[name],
          value,
          error: "",
        },
      };
      setChecklistFormData(tempFormData);
    },
    [checklistFormData],
  );

  const validateChecklistForm = useCallback(() => {
    let tempFormData = { ...checklistFormData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];
      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });

    setChecklistFormData(tempFormData);
    return valid;
  }, [checklistFormData]);

  const handleCheckbox = useCallback(
    (e: React.MouseEvent) => {
      const name = e.currentTarget.getAttribute("data-name");
      if (name) {
        const value = checklistFormData[name]?.value ? false : true;
        console.log(name, checklistFormData[name]?.value, value);
        const tempFormData = {
          ...checklistFormData,
          [name]: {
            ...checklistFormData[name],
            value,
            error: "",
          },
        };
        setChecklistFormData(tempFormData);
      }
    },
    [checklistFormData],
  );

  const onSubmitUpdateChecklist = useCallback(() => {
    const submit = validateChecklistForm();
    const checklistItem: CommonChecklistItem = {
      name: checklistFormData.name.value,
      assignedTo: checklistFormData.assignedTo.value,
      value: checklistFormData.value?.value,
    };
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const payload: UpdateChecklistPayload = {
      id: checklistRecordId,
      data: {
        checklistId: currentChecklistId,
        updateChecklist: checklistItem,
      },
      onSuccess: (data) => {
        if (data.checklist?.length && data.recordId) {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "Checklist updated succcessfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setChecklist(data?.checklist);
          setChecklistRecordId(data.recordId);
        }
      },
      onError: (e) => {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: e || "",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(updateChecklist(payload));
    handleChecklistModal();
  }, [
    validateChecklistForm,
    checklistFormData.name.value,
    checklistFormData.assignedTo.value,
    checklistFormData.value?.value,
    checklistRecordId,
    currentChecklistId,
    dispatch,
    handleChecklistModal,
    notificationsId,
  ]);

  const headerButtons = useMemo(() => {
    const buttons: TableHeaderButton[] = [
      {
        title: "Import from Template",
        onClick: handleChecklistTemplateModal,
        visible: true,
      },
      {
        title: strings.common.add,
        onClick: addChecklistModal,
        visible: true,
      },
    ];
    return buttons;
  }, [handleChecklistTemplateModal, addChecklistModal]);

  const fetchChecklist = useCallback(
    ({ page }) => {
      const currentPage = metaData.currentPage || 1;
      const itemPage = page || currentPage;

      const recoredsLimit = 10;

      const params: FetchChecklistParams = {
        collection,
        recordId,
        page: itemPage,
        limit: recoredsLimit,
      };
      const payload: FetchChecklistPayload = {
        params,
        onSuccess: (data) => {
          if (data.list?.[0]?.checklist?.length && data.list[0]?._id) {
            setChecklist(data.list?.[0]?.checklist);
            setChecklistRecordId(data.list[0]?._id);
            setMetaData(data.metaData);
          } else if (data.list?.[0]?.checklist?.length === 0) {
            setChecklist([]);
          }
        },
        onError: (e) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: e,
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
        },
      };
      dispatch(fetchChecklistList(payload));
    },
    [collection, metaData.currentPage, recordId, dispatch, notificationsId],
  );
  const handlePageChange = useCallback(
    (page: string) => {
      fetchChecklist({ page: parseInt(page) });
    },
    [fetchChecklist],
  );
  const onSubmitAddChecklist = useCallback(() => {
    const submit = validateChecklistForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    const checkListItem: CreateChecklistData = {
      recordId: recordId,
      collectionName: collection,
      checklistItems: [
        {
          name: checklistFormData.name.value,
          assignedTo: checklistFormData.assignedTo.value,
          value: checklistFormData.value?.value || false,
        },
      ],
    };
    const payload: CreateChecklistPayload = {
      data: checkListItem,
      onSuccess: (data) => {
        fetchChecklist({ page: metaData.currentPage });
      },
      onError: (e) => {
        console.log(e);
      },
    };
    dispatch(createChecklist(payload));
    handleChecklistModal();
  }, [
    dispatch,
    fetchChecklist,
    metaData,
    notificationsId,
    validateChecklistForm,
    checklistFormData,
    recordId,
    collection,
    handleChecklistModal,
  ]);
  const addChecklistFromTemplate = useCallback(() => {
    handleChecklistTemplateModal();
    let checklistItems: CommonChecklistItem[] = [];
    selectedChecklistTemplate.forEach((item) => {
      const id = item.value;
      const templateChecklist = checklistTemplates.find((item) => item._id === id)?.checklist;
      if (templateChecklist?.length) {
        checklistItems = [...checklistItems, ...templateChecklist];
      }
    });
    const checkListItem: CreateChecklistData = {
      recordId: recordId,
      collectionName: collection,
      checklistItems: checklistItems,
    };
    const payload: CreateChecklistPayload = {
      data: checkListItem,
      onSuccess: (data) => {
        fetchChecklist({ page: metaData.currentPage });
      },
      onError: (e) => {
        console.log(e);
      },
    };
    dispatch(createChecklist(payload));
  }, [
    handleChecklistTemplateModal,
    selectedChecklistTemplate,
    recordId,
    collection,
    dispatch,
    checklistTemplates,
    fetchChecklist,
    metaData.currentPage,
  ]);

  useEffect(() => {
    fetchChecklist({ page: 1 });
  }, [fetchChecklist]);

  return (
    <FormChecklistContainer>
      <TableListV3
        headerButtons={headerButtons}
        advancedList={false}
        disableFilter={true}
        loading={false}
        list={checklist}
        // metaData={metaData}
        columns={columns}
        onPageChange={handlePageChange}
        onClickTableRow={editChecklistModal}
      />
      <Popup isOpen={!!checklistModal} closeModal={handleChecklistModal}>
        <ChecklistModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleChecklistModal} />
          <FormTitle>
            {checklistModal === "add"
              ? "Add Checklist"
              : checklistModal === "edit"
              ? "Edit Checklist"
              : ""}
          </FormTitle>
          <ChecklistFieldsContainer>
            <Row>
              <TextInput
                label={"Name"}
                error={checklistFormData.name.error}
                inputProps={{
                  name: "name",
                  value: checklistFormData.name.value,
                  autoComplete: "off",
                  onChange: onChecklistTextChange,
                  required: checklistFormData.name.required,
                }}
                textAreaProps={{}}
              />
              <Select
                name={"assignedTo"}
                label={"Assigned To"}
                options={usersOptions}
                value={checklistFormData.assignedTo.value}
                onChange={onChecklistTextChange}
                mandatory={checklistFormData.assignedTo.required}
                error={checklistFormData.assignedTo.error}
              />
            </Row>
            <ChecklistCheckboxContainer>
              <CheckboxLabel>Completed</CheckboxLabel>
              <CheckboxImage
                onClick={handleCheckbox}
                src={
                  checklistFormData?.value?.value || false
                    ? images.checkedCheckbox
                    : images.uncheckedCheckbox
                }
                data-name='value'
              />
            </ChecklistCheckboxContainer>
          </ChecklistFieldsContainer>
          <Button
            className='checklist-modal-save-button'
            title={checklistModal === "add" ? strings.common.add : strings.common.save}
            onClick={checklistModal === "add" ? onSubmitAddChecklist : onSubmitUpdateChecklist}
          />
        </ChecklistModalContainer>
      </Popup>
      <Popup isOpen={!!checklistTemplateModal} closeModal={handleChecklistTemplateModal}>
        <ChecklistModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleChecklistTemplateModal} />
          <FormTitle>Select Checklist Template</FormTitle>
          <ChecklistFieldsContainer>
            <Select
              width={"100%"}
              name={"checklistTemplate"}
              label={"Checklist Template"}
              options={checklistTemplatesOptions}
              value={selectedChecklistTemplate}
              onChange={handleChecklistTemplate}
              mandatory={false}
              isMulti={true}
              error={""}
            />
          </ChecklistFieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={checklistModal === "add" ? strings.common.add : strings.common.save}
            onClick={addChecklistFromTemplate}
          />
        </ChecklistModalContainer>
      </Popup>
    </FormChecklistContainer>
  );
};
