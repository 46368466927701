/* eslint-disable no-unused-vars */
enum Types {
  FETCH_SPS_ACCOUNT_CONTACT = "FETCH_SPS_ACCOUNT_CONTACT",
  FETCH_SPS_ACCOUNT_CONTACT_BY_ID = "FETCH_SPS_ACCOUNT_CONTACT_BY_ID",
  SUCCESS_FETCH_SPS_ACCOUNT_CONTACT = "SUCCESS_FETCH_SPS_ACCOUNT_CONTACT",
  ERROR_FETCH_SPS_ACCOUNT_CONTACT = "ERROR_FETCH_SPS_ACCOUNT_CONTACT",
  CREATE_SPS_ACCOUNT_CONTACT = "CREATE_SPS_ACCOUNT_CONTACT",
  UPDATE_SPS_ACCOUNT_CONTACT = "UPDATE_SPS_ACCOUNT_CONTACT",
  DELETE_SPS_ACCOUNT_CONTACT = "DELETE_SPS_ACCOUNT_CONTACT",
}

export default Types;
