import React from "react";

import { Layout } from "../../../presentationals";
import { EPHome } from "../../../presentationals/screens/employee";
import { HomeControllerAdmin } from "../../../screens";
import { strings } from "../../../theme";

const Route = () => {
  return (
    <Layout
      activity={<EPHome />}
      title={strings.headerTitle.home}
      employee={true}
      layout={"2"}
    />
  );
};

export default Route;
