import Types from "./actionType";
import {
  FetchSpacePayload,
  CreateSpace,
  CreateSpacePayload,
  FetchSpace,
  FetchSpaceError,
  FetchSpaceSuccess,
  UpdateSpace,
  UpdateSpacePayload,
  SpaceListWithMetaData,
  FetchSpaceByIdPayload,
  FetchSpaceById,
  DeleteSpacePayload,
  DeleteSpace,
} from "./types";

export const fetchSpaceList = ({ params, onSuccess, onError }: FetchSpacePayload): FetchSpace => ({
  type: Types.FETCH_SPACE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSpaceById = ({
  id,
  onSuccess,
  onError,
}: FetchSpaceByIdPayload): FetchSpaceById => ({
  type: Types.FETCH_SPACE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSpaceListSuccess = (data: SpaceListWithMetaData): FetchSpaceSuccess => ({
  type: Types.SUCCESS_FETCH_SPACE,
  payload: {
    data,
  },
});

export const fetchSpaceError = (error?: string): FetchSpaceError => ({
  type: Types.ERROR_FETCH_SPACE,
  payload: { error },
});

export const createSpace = ({ data, onSuccess, onError }: CreateSpacePayload): CreateSpace => ({
  type: Types.CREATE_SPACE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSpace = ({ id, data, onSuccess, onError }: UpdateSpacePayload): UpdateSpace => ({
  type: Types.UPDATE_SPACE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSpace = ({ id, onSuccess, onError }: DeleteSpacePayload): DeleteSpace => ({
  type: Types.DELETE_SPACE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
