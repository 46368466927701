import styled from "styled-components";

import { colors, fonts } from "../../services/theme";

const styles = {};

styles.MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
`;

styles.Header = styled.div`
  /* background-color: red; */
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1vh;
  }
`;

styles.Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  column-gap: 10px;
`;
styles.Column = styled.div`
  flex: 1;
`;

export default styles;
