import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { WorkspaceControllerProps } from "../../../../types/models/Workspace";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { SpaceList } from "../Space";

import { useWorkspaceController } from "./hooks";

const Controller = ({ type }: WorkspaceControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { groupsOptions, usersOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchWorkspace,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
    TableList,
  } = useWorkspaceController({ type, id, attachmentsPickerRef });
  const currentWorkspace = data;

  const {
    workLogs,
    attachments,
    name,
    active,
    description,
    fullAccess,
    tables,
    workspaceGroups,
    workspaceOwners,
    workspaceUsers,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchWorkspace();
      console.log(fetchWorkspace());
    }

    // Do not include fetchWorkspace to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              name: "name",
              value: name.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: name.required,
            }}
          />
          <Select
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <Select
            name={"fullAccess"}
            label={"Full Access"}
            options={ActiveSelectOptions}
            value={fullAccess.value}
            onChange={onTextChange}
            mandatory={fullAccess.required}
            error={fullAccess.error}
          />
        </Row>
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            rows: 12,
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
          }}
        />
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    description.error,
    description.required,
    description.value,
    fullAccess.error,
    fullAccess.required,
    fullAccess.value,
    name.error,
    name.required,
    name.value,
    onTextChange,
  ]);

  const renderConfiguration = useMemo(() => {
    return (
      <React.Fragment>
        <Select
          width={"100%"}
          name={"workspaceOwners"}
          label={"Workspace Owners"}
          options={usersOptions}
          isMulti
          value={workspaceOwners.value}
          onChange={onTextChange}
          mandatory={workspaceOwners.required}
          error={workspaceOwners.error}
        />
        <Select
          width={"100%"}
          name={"workspaceUsers"}
          label={"Workspace Users"}
          options={usersOptions}
          isMulti
          value={workspaceUsers.value}
          onChange={onTextChange}
          mandatory={workspaceUsers.required}
          error={workspaceUsers.error}
        />
        <Select
          width={"100%"}
          name={"workspaceGroups"}
          label={"Workspace Groups"}
          options={groupsOptions}
          isMulti
          value={workspaceGroups.value}
          onChange={onTextChange}
          mandatory={workspaceGroups.required}
          error={workspaceGroups.error}
        />
        <Select
          width={"100%"}
          name={"tables"}
          label={"Tables"}
          options={TableList}
          isMulti
          value={tables.value}
          onChange={onTextChange}
          mandatory={tables.required}
          error={tables.error}
        />
      </React.Fragment>
    );
  }, [
    TableList,
    groupsOptions,
    onTextChange,
    tables.error,
    tables.required,
    tables.value,
    usersOptions,
    workspaceGroups.error,
    workspaceGroups.required,
    workspaceGroups.value,
    workspaceOwners.error,
    workspaceOwners.required,
    workspaceOwners.value,
    workspaceUsers.error,
    workspaceUsers.required,
    workspaceUsers.value,
  ]);
  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentWorkspace?.createdBy !== "string" ? currentWorkspace?.createdBy?.name : "";
    const updatedBy =
      typeof currentWorkspace?.updatedBy !== "string" ? currentWorkspace?.updatedBy?.name : "";
    return (
      <SystemData
        createdAt={currentWorkspace?.createdAt}
        updatedAt={currentWorkspace?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentWorkspace?.createdAt,
    currentWorkspace?.createdBy,
    currentWorkspace?.updatedAt,
    currentWorkspace?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.workspaces.name} recordId={id} />,
    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.workspaces.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const relatedSpacesList = useMemo(() => {
    return (
      <SpaceList
        relatedList={{
          type: "workspace",
          value: id,
        }}
      />
    );
  }, [id]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderConfiguration;
      case 2:
        return renderChecklist;
      case 3:
        return renderActivitylogs;
      case 4:
        return renderWorklogs;
      case 5:
        return renderSystemData;
      case 6:
        return relatedSpacesList;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderConfiguration,
    renderChecklist,
    renderActivitylogs,
    renderWorklogs,
    renderSystemData,
    relatedSpacesList,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const WorkspaceController = memo(Controller);
