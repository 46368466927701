/* eslint-disable no-unused-vars */
enum Types {
  USERS_FETCH = "USERS_FETCH",
  USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS",
  USERS_SET_META_DATA = "USERS_SET_META_DATA",
  USERS_FETCH_ERROR = "USERS_FETCH_ERROR",
  GET_USER_BY_ID = "GET_USER_BY_ID",
  USERS_ADD = "USERS_ADD",
  USERS_UPDATE = "USERS_UPDATE",
  USER_FITERS_UPDATE = "USERS_FILTERS_UPDATE",
  USERS_UPDATE_MANY = "USERS_UPDATE_MANY",
  USERS_ADD_CHECKLIST = "USERS_ADD_CHECKLIST",
  USERS_UPDATE_CHECKLIST = "USERS_UPDATE_CHECKLIST",
}

export default Types;
