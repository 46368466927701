/* eslint-disable no-unused-vars */
enum Types {
  FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS",
  SUCCESS_FETCH_DEPARTMENTS = "SUCCESS_FETCH_DEPARTMENTS",
  ERROR_FETCH_DEPARTMENTS = "ERROR_FETCH_DEPARTMENTS",
  CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",
  DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
  FETCH_DEPARTMENT_BY_ID = "FETCH_DEPARTMENT_BY_ID",
}

export default Types;
