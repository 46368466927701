import Types from "./actionType";
import {
  FetchAddressHistoryPayload,
  CreateAddressHistory,
  CreateAddressHistoryPayload,
  FetchAddressHistory,
  FetchAddressHistoryError,
  FetchAddressHistorySuccess,
  UpdateAddressHistory,
  UpdateAddressHistoryPayload,
  AddressHistoryListWithMetaData,
  FetchAddressHistoryByIdPayload,
  FetchAddressHistoryById,
  DeleteAddressHistoryPayload,
  DeleteAddressHistory,
} from "./types";

export const fetchAddressHistoryList = ({
  params,
  onSuccess,
  onError,
}: FetchAddressHistoryPayload): FetchAddressHistory => ({
  type: Types.FETCH_ADDRESS_HISTORY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAddressHistoryById = ({
  id,
  onSuccess,
  onError,
}: FetchAddressHistoryByIdPayload): FetchAddressHistoryById => ({
  type: Types.FETCH_ADDRESS_HISTORY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAddressHistoryListSuccess = (
  data: AddressHistoryListWithMetaData,
): FetchAddressHistorySuccess => ({
  type: Types.SUCCESS_FETCH_ADDRESS_HISTORY,
  payload: {
    data,
  },
});

export const fetchAddressHistoryError = (error?: string): FetchAddressHistoryError => ({
  type: Types.ERROR_FETCH_ADDRESS_HISTORY,
  payload: { error },
});

export const createAddressHistory = ({
  data,
  onSuccess,
  onError,
}: CreateAddressHistoryPayload): CreateAddressHistory => ({
  type: Types.CREATE_ADDRESS_HISTORY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAddressHistory = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAddressHistoryPayload): UpdateAddressHistory => ({
  type: Types.UPDATE_ADDRESS_HISTORY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAddressHistory = ({
  id,
  onSuccess,
  onError,
}: DeleteAddressHistoryPayload): DeleteAddressHistory => ({
  type: Types.DELETE_ADDRESS_HISTORY,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
