/* eslint-disable no-unused-vars */
enum Types {
  FETCH_BUGREPORTING = "FETCH_BUGREPORTING",
  FETCH_BUGREPORTING_BY_ID = "FETCH_BUGREPORTING_BY_ID",
  SUCCESS_FETCH_BUGREPORTING = "SUCCESS_FETCH_BUGREPORTING",
  ERROR_FETCH_BUGREPORTING = "ERROR_FETCH_BUGREPORTING",
  CREATE_BUGREPORTING = "CREATE_BUGREPORTING",
  UPDATE_BUGREPORTING = "UPDATE_BUGREPORTING",
}

export default Types;
