import React from "react";

import { Layout } from "../../../presentationals";
import { SubCategoriesList } from "../../../presentationals/screens/admin/Subcategories/SubCategoriesList";

const Route = () => {
  return <Layout activity={<SubCategoriesList />} admin={true} layout={"2"} />;
};

export const APSubCategoriesList = React.memo(Route);
