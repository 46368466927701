/* eslint-disable no-unused-vars */
enum Types {
  FETCH_NEWSLETTER_LEADS = "FETCH_NEWSLETTER_LEADS",
  FETCH_NEWSLETTER_LEADS_BY_ID = "FETCH_NEWSLETTER_LEADS_BY_ID",
  SUCCESS_FETCH_NEWSLETTER_LEADS = "SUCCESS_FETCH_NEWSLETTER_LEADS",
  ERROR_FETCH_NEWSLETTER_LEADS = "ERROR_FETCH_NEWSLETTER_LEADS",
  CREATE_NEWSLETTER_LEADS = "CREATE_NEWSLETTER_LEADS",
  UPDATE_NEWSLETTER_LEADS = "UPDATE_NEWSLETTER_LEADS",
  DELETE_NEWSLETTER_LEADS = "DELETE_NEWSLETTER_LEADS",
}

export default Types;
