import React from "react";

import { Layout } from "../../../presentationals";
import { ITNServicesController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<ITNServicesController type={"add"} />} admin={true} layout={"2"} />;
};

export const APITNServicesAdd = React.memo(Route);
