import React, { useEffect, useMemo } from "react";

import { useAppSelector } from "../../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import { ColumnsType, DashboardAdministrationListProps } from "../../../../../types";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../../utils";
import { TableListV3 } from "../../../../components";

import { useDashboardAdministrationList } from "./hooks";

export const DashboardAdministrationList = ({ relatedList }: DashboardAdministrationListProps) => {
  const dashboardAdministration = useAppSelector(
    (state) => state.DashboardAdministrationReducer.data,
  );
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.dashboardAdministration;
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { list, metaData } = dashboardAdministration;
  const {
    fetchDashboardAdministration,
    loading,
    handlePageChange,
    onClickDashboardAdministration,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    dashboardAdministrationHeaderButtons,
    dashboardAdministrationFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    onSubmitDeleteRecord,
  } = useDashboardAdministrationList({
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      title: {
        title: "Title",
        sortSupported: true,
        searchSupported: true,
        key: "title",
      },
      description: {
        title: "Description",
        sortSupported: true,
        key: "description",
      },
      active: {
        title: "Active",
        sortSupported: true,
        key: "active",
        render: (item) => {
          return <text className='title'>{item.active ? "Yes" : "No"}</text>;
        },
      },
      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        render: (item) => {
          return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        render: (item) => {
          return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.fields?.length
      ? tableListConfigs?.fields
      : tableListDefaultSelectFields.dashboardAdministration;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchDashboardAdministration({});
    // Do not include fetchDashboardAdministration to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      handleShowItemsPerPage={handleShowItemsPerPage}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleListActions={handleListActions}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.dashboardAdministration_delete}
      headerButtons={dashboardAdministrationHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.dashboardAdministration}
      handleTableConfigs={handleTableConfigs}
      filters={dashboardAdministrationFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.dashboardAdministration
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      isRelatedList={!!relatedList?.type}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickDashboardAdministration}
      headerTitle={strings.headerTitle.dashboardAdministration}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apDashboardAdministration.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
