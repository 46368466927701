import Types from "./actionType";
import {
  FetchVariableSetsPayload,
  CreateVariableSets,
  CreateVariableSetsPayload,
  FetchVariableSets,
  FetchVariableSetsError,
  FetchVariableSetsSuccess,
  UpdateVariableSets,
  UpdateVariableSetsPayload,
  VariableSetsListWithMetaData,
  FetchVariableSetsByIdPayload,
  FetchVariableSetsById,
  DeleteVariableSetsPayload,
  DeleteVariableSets,
} from "./types";

export const fetchVariableSetsList = ({
  params,
  onSuccess,
  onError,
}: FetchVariableSetsPayload): FetchVariableSets => ({
  type: Types.FETCH_VARIABLE_SETS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchVariableSetsById = ({
  id,
  onSuccess,
  onError,
}: FetchVariableSetsByIdPayload): FetchVariableSetsById => ({
  type: Types.FETCH_VARIABLE_SETS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchVariableSetsListSuccess = (
  data: VariableSetsListWithMetaData,
): FetchVariableSetsSuccess => ({
  type: Types.SUCCESS_FETCH_VARIABLE_SETS,
  payload: {
    data,
  },
});

export const fetchVariableSetsError = (error?: string): FetchVariableSetsError => ({
  type: Types.ERROR_FETCH_VARIABLE_SETS,
  payload: { error },
});

export const createVariableSets = ({
  data,
  onSuccess,
  onError,
}: CreateVariableSetsPayload): CreateVariableSets => ({
  type: Types.CREATE_VARIABLE_SETS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateVariableSets = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateVariableSetsPayload): UpdateVariableSets => ({
  type: Types.UPDATE_VARIABLE_SETS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteVariableSets = ({
  id,
  onSuccess,
  onError,
}: DeleteVariableSetsPayload): DeleteVariableSets => ({
  type: Types.DELETE_VARIABLE_SETS,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
