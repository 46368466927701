/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACTIVITY_LOGS = "FETCH_ACTIVITY_LOGS",
  FETCH_ACTIVITY_LOGS_BY_ID = "FETCH_ACTIVITY_LOGS_BY_ID",
  SUCCESS_FETCH_ACTIVITY_LOGS = "SUCCESS_FETCH_ACTIVITY_LOGS",
  ERROR_FETCH_ACTIVITY_LOGS = "ERROR_FETCH_ACTIVITY_LOGS",
  CREATE_ACTIVITY_LOGS = "CREATE_ACTIVITY_LOGS",
  UPDATE_ACTIVITY_LOGS = "UPDATE_ACTIVITY_LOGS",
}

export default Types;
