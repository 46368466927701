import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SPSSalesProfileList,
  AddSPSSalesProfile,
  UpdateSPSSalesProfile,
  FindSPSSalesProfile,
  DeleteSPSSalesProfile,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSPSSalesProfileListSuccess, fetchSPSSalesProfileError } from "./actions";
import Types from "./actionType";
import {
  CreateSPSSalesProfileuccess,
  CreateSPSSalesProfile as CreateSPSSalesProfileType,
  SPSSalesProfileSuccess,
  UpdateSPSSalesProfile as UpdateSPSSalesProfileType,
  FetchSPSSalesProfile,
  FetchSPSSalesProfileById,
  SPSSalesProfileByIdSuccess,
  DeleteSPSSalesProfile as DeleteSPSSalesProfileType,
} from "./types";

function* createWorker(action: CreateSPSSalesProfileType) {
  try {
    const options = {
      method: "POST",
      url: AddSPSSalesProfile,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSSalesProfileuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateSPSSalesProfileType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSPSSalesProfile(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSPSSalesProfileuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchSPSSalesProfile) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SPSSalesProfileList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SPSSalesProfileSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchSPSSalesProfileListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchSPSSalesProfileError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching SPS SalesProfile");
    }
  }
}

function* fetchSPSSalesProfileById(action: FetchSPSSalesProfileById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSPSSalesProfile(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SPSSalesProfileByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching SPS SalesProfile");
    }
  }
}

function* deleteWorker(action: DeleteSPSSalesProfileType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteSPSSalesProfile(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateSPSSalesProfileuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SPS_SALES_PROFILE, createWorker);
  yield takeLatest(Types.UPDATE_SPS_SALES_PROFILE, updateWorker);
  yield takeEvery(Types.FETCH_SPS_SALES_PROFILE, fetchAllWorker);
  yield takeEvery(Types.FETCH_SPS_SALES_PROFILE_BY_ID, fetchSPSSalesProfileById);
  yield takeEvery(Types.DELETE_SPS_SALES_PROFILE, deleteWorker);
}
