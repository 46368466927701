import Types from "./actionType";
import {
  FetchAcademyCandidatesPayload,
  CreateAcademyCandidates,
  CreateAcademyCandidatesPayload,
  AcademyCandidatesList,
  FetchAcademyCandidates,
  FetchAcademyCandidatesError,
  FetchAcademyCandidatesSuccess,
  UpdateAcademyCandidates,
  UpdateAcademyCandidatesPayload,
  AcademyCandidatesListWithMetaData,
  FetchAcademyCandidatesByIdPayload,
  FetchAcademyCandidatesById,
  DeleteAcademyCandidatesPayload,
  DeleteAcademyCandidates,
} from "./types";

export const fetchAcademyCandidatesList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyCandidatesPayload): FetchAcademyCandidates => ({
  type: Types.FETCH_ACADEMY_CANDIDATES,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidatesById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyCandidatesByIdPayload): FetchAcademyCandidatesById => ({
  type: Types.FETCH_ACADEMY_CANDIDATES_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidatesListSuccess = (
  data: AcademyCandidatesListWithMetaData,
): FetchAcademyCandidatesSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_CANDIDATES,
  payload: {
    data,
  },
});

export const fetchAcademyCandidatesError = (error?: string): FetchAcademyCandidatesError => ({
  type: Types.ERROR_FETCH_ACADEMY_CANDIDATES,
  payload: { error },
});

export const createAcademyCandidates = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyCandidatesPayload): CreateAcademyCandidates => ({
  type: Types.CREATE_ACADEMY_CANDIDATES,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyCandidates = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyCandidatesPayload): UpdateAcademyCandidates => ({
  type: Types.UPDATE_ACADEMY_CANDIDATES,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
export const deleteAcademyCandidates = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyCandidatesPayload): DeleteAcademyCandidates => ({
  type: Types.DELETE_ACADEMY_CANDIDATES,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
