import React, { useState } from "react";

import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from "suneditor-react";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import ModalComponent from "../../components/Modal/Modal";
import Select from "../../components/Select/select";
import { Header } from "../../presentationals/components";
import { uploadFile } from "../../redux/application/actions";
import { createTicket, getAllTickets } from "../../redux/tickets/actions";
import { useTitle, formValidationMethod, generateArrayAttributes } from "../../services/methods";
import "suneditor/dist/css/suneditor.min.css";
import { strings } from "../../theme";

import styles from "./TicketsAdd.styles";

const TicketsAdd = (props) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    subject: {
      required: true,
      type: "text",
      error: "",
      value: "",
    },
    topic: {
      required: true,
      type: "text",
      error: "",
      value: "",
    },
    priority: {
      required: true,
      type: "text",
      error: "",
      value: "",
    },
    description: {
      required: true,
      type: "text",
      error: "",
      value: "",
    },
  });
  const [attachments, setAttachments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const defaultValue = "";
  const [value, setValue] = useState(defaultValue);
  const HandleOnChange = (event) => {
    setValue(event);
  };
  useTitle("Tickets");

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const error = formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const addAttachment = () => {
    const newArray = [...attachments];
    newArray.push({ name: "", attachment: "" });
    setAttachments(newArray);
  };

  const removeAttachment = (index) => {
    const newArray = [...attachments];
    newArray.splice(index, 1);
    setAttachments(newArray);
  };

  const attachmentTextChange = (e) => {
    const newArray = [...attachments];
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const index = e.currentTarget.getAttribute("data-index");
    newArray[index][name] = value;
    setAttachments(newArray);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    // var name = e.target.name;
    const index = e.currentTarget.getAttribute("data-index");
    props.uploadFile({
      file: file,
      onSuccess: (data) => {
        const newArray = [...attachments];
        newArray[index]["attachment"] = data.id;
        newArray[index]["error"] = "";
        setAttachments(newArray);
      },
      onError: (err) => {
        const newArray = [...attachments];
        newArray[index]["error"] = err;
        setAttachments(newArray);
      },
      onProgress: (progress) => {
        const newArray = [...attachments];
        newArray[index]["error"] = `Upload in progress (${progress}%)`;
        setAttachments(newArray);
      },
    });
  };

  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const object = {
      subject: formData.subject.value,
      topic: formData.topic.value,
      priority: formData.priority.value,
      description: value,
      attachments: attachments.map((item) => ({
        name: item.name,
        attachment: item.attachment,
      })),
    };
    props.createTicket({
      data: object,
      onSuccess: (data) => {
        props.getAllTickets({
          onSuccess: (data) => {
            navigate("/ep/tickets/created");
          },
          onError: (err) => {
            setSubmitting(false);
            alert("Please reload page, Ticket Created!");
          },
        });
      },
      onError: (err) => {
        setSubmitting(false);
        alert("Request Failed");
      },
    });
  };

  const SubmitDisabled = () => {
    if (
      formData.subject.value &&
      formData.topic.value &&
      formData.priority.value &&
      value &&
      !submitting
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <styles.MainContainer>
      <Header title={strings.headerTitle.ticketCreate} />
      <styles.Header>
        <Link to={"/ep/tickets/created"}>
          <MdArrowBack size={"30"} />
        </Link>
        <h2>New Service Request</h2>
      </styles.Header>
      <hr />
      <styles.Row>
        <styles.Column>
          <Select
            readOnly={true}
            name={"createdBy"}
            label={"Created By"}
            options={[{ label: props.user.name, value: props.user.id }]}
            value={props.user.id}
            onChange={onTextChange}
          />
        </styles.Column>
        <styles.Column>
          <Select
            readOnly={true}
            name={"requestedFor"}
            label={"Requested For"}
            options={[{ label: props.user.name, value: props.user.id }]}
            value={props.user.id}
            onChange={onTextChange}
          />
        </styles.Column>
      </styles.Row>
      <Input
        readOnly={false}
        label={"Subject"}
        type={"text"}
        name='subject'
        autoComplete={"off"}
        onChange={onTextChange}
        value={formData.subject.value}
        mandatory={true}
        error={formData?.subject?.error}
      />
      <Select
        readOnly={false}
        name={"topic"}
        label={"Topic"}
        options={generateArrayAttributes("requestTicketTopic").map((value, index) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.topic.value}
        onChange={onTextChange}
      />
      <Select
        readOnly={false}
        name={"priority"}
        label={"Priority"}
        options={generateArrayAttributes("priority").map((value, index) => ({
          label: value.value,
          value: value._id,
        }))}
        value={formData.priority.value}
        onChange={onTextChange}
      />
      {attachments.map((item, index) => (
        <styles.Row key={index}>
          <styles.Column>
            <Input
              readOnly={false}
              label={"Attachment Name"}
              type={"text"}
              name='name'
              autoComplete={"off"}
              onChange={attachmentTextChange}
              value={item.name}
              attributes={{ "data-index": index }}
              mandatory={true}
              // error={formData?.subject?.error}
            />
          </styles.Column>
          <styles.Column>
            <Input
              readOnly={false}
              label={"Attachment"}
              type={"file"}
              name='attachment'
              message={item.attachment}
              onMessageClick={() => getDocument(item.attachment)}
              autoComplete={"off"}
              onChange={onFileChange}
              mandatory={true}
              attributes={{ "data-index": index }}
              error={item.error}
            />
          </styles.Column>
          <styles.Column>
            <Button
              type={"cancel"}
              onClick={() => removeAttachment(index)}
              size={"normal"}
              label={"Remove"}
            />
          </styles.Column>
        </styles.Row>
      ))}
      <Button
        onClick={addAttachment}
        // disabled={SubmitDisabled()}
        size={"small"}
        label={"Add Attachment"}
      />

      <SunEditor
        lang={"en"}
        placeholder='Please enter the request description here'
        height='30vh'
        defaultValue={defaultValue}
        // hideToolbar={true}
        // readOnly={true}
        onChange={HandleOnChange}
        value={value}
        // imageUploadHandler={imageUploadHandler}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize"],
            // ['paragraphStyle', 'blockquote'],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],

            ["table", "horizontalRule", "link" /*"image"*/],
            // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
            // ['imageGallery'], // You must add the "imageGalleryUrl".
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["removeFormat"],

            // ['save', 'template'],
            // '/', Line break
          ],
        }}
      />
      <Button onClick={handleSubmit} disabled={SubmitDisabled()} size={"normal"} label={"Save"} />
      <br />
      <br />
      <br />
      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <Document id={documentId} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = {
  uploadFile,
  createTicket,
  getAllTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsAdd);
