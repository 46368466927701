import React, { useState } from "react";

import { SelectOptions } from "../../../../types";

import { Container, SelectInput, Label, ErrorText } from "./styles";

interface SelectProps {
  label: string;
  value: any;
  name: string;
  options: SelectOptions[];
  onChange: any;
  readOnly?: boolean;
  attributes?: any;
  mandatory?: boolean;
  error: string;
  valueField?: any;
  isMulti?: boolean;
  defaultFocused?: boolean;
  width?: string;
}

export const Select = ({
  label,
  value,
  name,
  options = [],
  onChange,
  readOnly = false,
  attributes = {},
  mandatory,
  error,
  valueField = "value",
  isMulti = false,
  defaultFocused = false,
  width = "48%",
}: SelectProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const onSelectChange = (value) => {
    const object = {};
    object.target = {
      ...attributes,
      isMulti: isMulti,
      name: name,
      value: isMulti ? value : value[valueField],
    };
    onChange(object);
  };

  return (
    <Container width={width}>
      <SelectInput
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        classNamePrefix='Select'
        placeholder={""}
        name={name}
        isMulti={isMulti}
        onChange={onSelectChange}
        value={isMulti ? value : options.filter((option) => option?.value === value)}
        options={options}
        error={error}
        {...attributes}
        isDisabled={readOnly}
      />
      <Label className={isFocused || defaultFocused || value !== undefined ? "focused" : ""}>
        {label}
        {mandatory && <span>{"*"}</span>}
      </Label>
      <ErrorText>{error ? error : <br />}</ErrorText>
    </Container>
  );
};
