import React, { useCallback, useEffect, useMemo, useRef, memo } from "react";

import { useParams } from "react-router-dom";

import { getAttributValues } from "../../../../services/methods";
import { CertificationsAccreditationsControllerProps } from "../../../../types/models/CertificationsAccreditations";
import { ActiveSelectOptions, collections } from "../../../../utils";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select, SystemData, Checkbox } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";

import { useCertificationsAccreditationsController } from "./hooks";

const Controller = ({ type }: CertificationsAccreditationsControllerProps) => {
  const { id } = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions } = useControllerConfigs();

  const {
    currentStepIndex,
    goTo,
    formData,
    onDateChange,
    handleCheckbox,
    onTextChange,
    loading,
    headerTitle,
    fetchCertificationsAccreditations,
    onPostWorklog,
    getStepBadge,
    handleAttachmentsModal,
    attachmentModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    data,
    headerButtons,
    formTitle,
    formSteps,
    formRelatedList,
  } = useCertificationsAccreditationsController({ type, id, attachmentsPickerRef });
  const currentCertificationsAccreditations = data;

  const {
    workLogs,
    attachments,
    isAttachmentAdded,
    associatedEmployee,
    active,
    name,
    issueDate,
    expiryDate,
    number,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchCertificationsAccreditations();
    }
    // Do not include fetchCertificationsAccreditations to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const renderOverview = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <TextInput
            label='Certificate/Award Name'
            type={"text"}
            width='48%'
            error={name.error}
            inputProps={{
              autoComplete: "off",
              name: "name",
              onChange: onTextChange,
              value: name.value,
              required: name.required,
            }}
          />
          <TextInput
            label='Number'
            type={"text"}
            width='48%'
            error={number.error}
            inputProps={{
              autoComplete: "off",
              name: "number",
              onChange: onTextChange,
              value: number.value,
              required: number.required,
            }}
          />
        </Row>
        <Select
          width={"100%"}
          name={"type"}
          label={"Type"}
          options={getAttributValues("certificationsAccreditationsType")}
          value={formData.type.value}
          onChange={onTextChange}
          mandatory={formData.type.required}
          error={formData.type.error}
        />
        <Row>
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Issue Date'
            type={"date"}
            width='48%'
            error={issueDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "issueDate",
              value: issueDate.value,
              required: issueDate.required,
            }}
          />
          <TextInput
            label='Expiry Date'
            type={"date"}
            width='48%'
            error={expiryDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "expiryDate",
              minDate: issueDate.value ? new Date(issueDate.value) : undefined,
              value: expiryDate.value,
              required: expiryDate.required,
            }}
          />
        </Row>
        <Checkbox
          label={"Is Attachments Added"}
          width='48%'
          error={isAttachmentAdded.error}
          required={isAttachmentAdded.required}
          onClick={handleCheckbox}
          value={isAttachmentAdded.value}
          attributes={{
            "data-name": "isAttachmentAdded",
          }}
        />
      </React.Fragment>
    );
  }, [
    active.error,
    active.required,
    active.value,
    associatedEmployee.error,
    associatedEmployee.required,
    associatedEmployee.value,
    employeesOptions,
    expiryDate.error,
    expiryDate.required,
    expiryDate.value,
    formData.type.error,
    formData.type.required,
    formData.type.value,
    handleCheckbox,
    isAttachmentAdded.error,
    isAttachmentAdded.required,
    isAttachmentAdded.value,
    issueDate.error,
    issueDate.required,
    issueDate.value,
    name.error,
    name.required,
    name.value,
    number.error,
    number.required,
    number.value,
    onDateChange,
    onTextChange,
  ]);

  const renderSystemData = useMemo(() => {
    const createdBy =
      typeof currentCertificationsAccreditations?.createdBy !== "string"
        ? currentCertificationsAccreditations?.createdBy?.name
        : "";
    const updatedBy =
      typeof currentCertificationsAccreditations?.updatedBy !== "string"
        ? currentCertificationsAccreditations?.updatedBy?.name
        : "";
    return (
      <SystemData
        createdAt={currentCertificationsAccreditations?.createdAt}
        updatedAt={currentCertificationsAccreditations?.updatedAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
      />
    );
  }, [
    currentCertificationsAccreditations?.createdAt,
    currentCertificationsAccreditations?.createdBy,
    currentCertificationsAccreditations?.updatedAt,
    currentCertificationsAccreditations?.updatedBy,
  ]);

  const renderChecklist = useMemo(
    () => (
      <FormChecklistV2 collection={collections.certificationsAccreditations.name} recordId={id} />
    ),

    [id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={id} collection={collections.certificationsAccreditations.name} />,
    [id],
  );
  const renderWorklogs = useMemo(
    () => <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />,
    [onPostWorklog, workLogs.value],
  );

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      default:
        return null;
    }
  }, [
    currentStepIndex,
    renderActivitylogs,
    renderOverview,
    renderChecklist,
    renderSystemData,
    renderWorklogs,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};

export const CertificationsAccreditationsController = memo(Controller);
