import { ApplicationActionTypes as Types } from "./actionTypes";
import { ApplicationActions, ApplicationState } from "./types";

const initialState: ApplicationState = {
  attributes: [],
  inAppLoader: false,
  epAccordion: "",
  apAccordion: "",
  profileModel: false,
  configurations: {
    controllerConfigs: {
      userKnowledgebases: [],
      academyStudentProfile: [],
      categories: [],
      subcategories: [],
      tags: [],
      groups: [],
      users: [],
      departments: [],
      country: [],
      company: [],
      companyContact: [],
      attributes: [],
      currency: [],
      employees: [],
      designations: [],
      academyCourses: [],
      academyBatches: [],
      academyCandidateLeads: [],
      knowledgebases: [],
      academyCandidatesLeads: [],
      academyCandidates: [],
      academyCandidateEnrollments: [],
      checklistTemplates: [],
      spsAccounts: [],
      spsDeals: [],
      spsProjects: [],
      platformModules: [],
      spsSalesProfile: [],
      dashboardAdministration: [],
      dashboardTabs: [],
      dashboardWidgets: [],
      workspaces: [],
      emailTemplate: [],
    },
  },
  notifications: [],
  activeUsers: [],
};

const ApplicationReducer = (state = initialState, action: ApplicationActions) => {
  const currentNotifications = [...state.notifications];
  let removeNotificationIndex = -1;
  switch (action.type) {
    case Types.SHOW_IN_APP_LOADER:
      return {
        ...state,
        inAppLoader: action.payload ? true : false,
      };
    case Types.ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        attributes: action.payload.data,
      };
    case Types.EP_ACCORDION:
      return {
        ...state,
        epAccordion: action.payload.data,
      };
    case Types.AP_ACCORDION:
      return {
        ...state,
        apAccordion: action.payload.data,
      };
    case Types.SET_PROFILE_MODEL:
      return {
        ...state,
        profileModel: action.payload,
      };
    case Types.FETCH_CONTROLLER_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          controllerConfigs: action.payload.controllerConfigs,
        },
      };
    case Types.ADD_APP_NOTIFICATION:
      currentNotifications.push(action.payload);
      return {
        ...state,
        notifications: currentNotifications,
      };
    case Types.REMOVE_APP_NOTIFICATION:
      removeNotificationIndex = currentNotifications.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (removeNotificationIndex >= 0) {
        currentNotifications.splice(removeNotificationIndex, 1);
      }
      return {
        ...state,
        notifications: currentNotifications,
      };
    case Types.UPDATE_ACTIVE_USERS:
      return {
        ...state,
        activeUsers: action.payload,
      };
    default:
      return state;
  }
};

export default ApplicationReducer;
