const Types = {
  TICKETS_CREATE: "TICKETS_CREATE",
  TICKETS_UPDATE: "TICKETS_UPDATE",

  TICKETS_FETCH_ALL: "TICKETS_FETCH_ALL",
  TICKETS_FETCH_ALL_EMPLOYEES_SUCCESS: "TICKETS_FETCH_ALL_EMPLOYEES_SUCCESS",
  TICKETS_FETCH_ALL_ADMIN_SUCCESS: "TICKETS_FETCH_ALL_ADMIN_SUCCESS",

  TICKETS_COMMENT: "TICKETS_COMMENT",
  TICKETS_UPDATE_FILTER: "TICKETS_UPDATE_FILTER",
};

export default Types;
