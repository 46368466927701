import Types from "./actionType";
import {
  FetchChecklistTemplatePayload,
  CreateChecklistTemplate,
  CreateChecklistTemplatePayload,
  FetchChecklistTemplate,
  FetchChecklistTemplateError,
  FetchChecklistTemplateSuccess,
  UpdateChecklistTemplate,
  UpdateChecklistTemplatePayload,
  ChecklistTemplateListWithMetaData,
  FetchChecklistTemplateByIdPayload,
  FetchChecklistTemplateById,
  DeleteChecklistTemplatePayload,
  DeleteChecklistTemplate,
} from "./types";

export const fetchChecklistTemplateList = ({
  params,
  onSuccess,
  onError,
}: FetchChecklistTemplatePayload): FetchChecklistTemplate => ({
  type: Types.FETCH_CHECKLIST_TEMPLATE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchChecklistTemplateById = ({
  id,
  onSuccess,
  onError,
}: FetchChecklistTemplateByIdPayload): FetchChecklistTemplateById => ({
  type: Types.FETCH_CHECKLIST_TEMPLATE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchChecklistTemplateListSuccess = (
  data: ChecklistTemplateListWithMetaData,
): FetchChecklistTemplateSuccess => ({
  type: Types.SUCCESS_FETCH_CHECKLIST_TEMPLATE,
  payload: {
    data,
  },
});

export const fetchChecklistTemplateError = (error?: string): FetchChecklistTemplateError => ({
  type: Types.ERROR_FETCH_CHECKLIST_TEMPLATE,
  payload: { error },
});

export const createChecklistTemplate = ({
  data,
  onSuccess,
  onError,
}: CreateChecklistTemplatePayload): CreateChecklistTemplate => ({
  type: Types.CREATE_CHECKLIST_TEMPLATE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateChecklistTemplate = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateChecklistTemplatePayload): UpdateChecklistTemplate => ({
  type: Types.UPDATE_CHECKLIST_TEMPLATE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteChecklistTemplate = ({
  id,
  onSuccess,
  onError,
}: DeleteChecklistTemplatePayload): DeleteChecklistTemplate => ({
  type: Types.DELETE_CHECKLIST_TEMPLATE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
