import React, { useEffect, useMemo, memo } from "react";

import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType } from "../../../../types";
import { AttributesListProps } from "../../../../types/models/AttributesForm";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useAttributesList } from "./hooks";

export const AttributesList = memo(({ relatedList }: AttributesListProps) => {
  const Attributes = useAppSelector((state) => state.AttributesReducer.data);
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.attributes;

  const params = useParams();
  const name = params.name;

  const { list, metaData } = Attributes;
  const {
    fetchAttributes,
    loading,
    handlePageChange,
    onClickAttributes,
    onClickAdd,
    onSearch,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    attributesFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useAttributesList({
    name,
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      value: {
        title: "Name",
        sortSupported: true,
        searchSupported: true,
        key: "value",
      },
      active: {
        title: "Active",
        key: "active",
        render: (item: { active: any }) => {
          return <text className='title'>{item.active ? "Yes" : "No"}</text>;
        },
      },

      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        render: (item: { createdAt: any }) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        render: (item: { createdBy: { name: any } }) => {
          return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        render: (item: { updatedAt: any }) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        render: (item: { updatedBy: { name: any } }) => {
          return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.fields?.length
      ? tableListConfigs?.fields
      : tableListDefaultSelectFields.attributes;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchAttributes({});
    // Do not include fetchAttributes to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAttributes]);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.attributes}
      handleTableConfigs={handleTableConfigs}
      filters={attributesFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.attributes
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickAttributes}
      headerTitle={strings.headerTitle?.[name || ""]}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apAttributes.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
