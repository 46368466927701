import Types from "./actionType";
import { AcademyStudentProfileActions, AcademyStudentProfileState } from "./types";

const initialState: AcademyStudentProfileState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
    },
  },
  loading: false,
};

export const AcademyStudentProfileReducer = (
  state = initialState,
  action: AcademyStudentProfileActions,
) => {
  switch (action.type) {
    case Types.FETCH_ACADEMY_STUDENT_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_ACADEMY_STUDENT_PROFILE:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
