import React from "react";

import Document from "../../../components/Document/Document";
import { getAttachmentFileIcon } from "../../../services/methods";
import { images, strings } from "../../../theme";
import { AttachmentModalPropsTypes } from "../../../types";
import {
  AttachmentIcon,
  AttachmentIconsContainer,
  AttachmentListItem,
  AttachmentListItemContainer,
  AttachmentNameContainer,
  AttachmentTitle,
  AttachmentsModalContainer,
  BrowseAttachmentContainer,
  ButtonsContainer,
  CloseIcon,
  CurrentAttachmentContainer,
  DocumentViewContainer,
  NoAttachmentsContainer,
} from "../../common/styles";
import { Button, MyTooltip, TextInput } from "../atoms";
import { Popup } from "../organisms";

function AttachmentModal({
  handleAttachmentsModal,
  attachmentModal,
  attachments,
  handleShowAttachment,
  handleDeleteAttachment,
  onClickAddAttachment,
  handleCurrentAttachment,
  currentAttachmentFile,
  currentAttachment,
  attachmentUploadProgress,
  onClickSaveAttachment,
  onClickCancelAttachment,
  attachmentsPickerRef,
  onAttachmentsFileChange,
  onClickBrowseAttachment,
  documentId,
  handleCloseAttachment,
  multipleAttachment = true,
}: AttachmentModalPropsTypes) {
  return (
    <Popup isOpen={!!attachmentModal} closeModal={handleAttachmentsModal}>
      {documentId ? (
        <DocumentViewContainer>
          <CloseIcon src={images.closeModal} onClick={handleCloseAttachment} />
          <Document id={documentId} />
        </DocumentViewContainer>
      ) : (
        <AttachmentsModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleAttachmentsModal} />
          {attachmentModal === "list" && (
            <>
              {attachments.value?.length ? (
                <AttachmentListItemContainer>
                  {attachments.value.map((attachmentItem, attachmentIndex) => {
                    const { id, name, extension } = attachmentItem;
                    let fileName = name;
                    if (extension) {
                      fileName = `${name}.${extension}`;
                    }
                    return (
                      <AttachmentListItem key={attachmentIndex}>
                        <AttachmentNameContainer>
                          <AttachmentIcon src={getAttachmentFileIcon(extension)} />
                          <AttachmentTitle>{fileName}</AttachmentTitle>
                        </AttachmentNameContainer>
                        <AttachmentIconsContainer>
                          <MyTooltip text='View Attachment'>
                            <AttachmentIcon
                              onClick={handleShowAttachment}
                              data-index={attachmentIndex}
                              src={images.viewAttachment}
                            />
                          </MyTooltip>
                          <MyTooltip text='Delete Attachment'>
                            <AttachmentIcon
                              onClick={handleDeleteAttachment}
                              data-index={attachmentIndex}
                              src={images.deleteAttachment}
                            />
                          </MyTooltip>
                        </AttachmentIconsContainer>
                      </AttachmentListItem>
                    );
                  })}
                  <AttachmentListItem className='attachments-buttons-container'>
                    {multipleAttachment && (
                      <Button title={strings.common.add} onClick={onClickAddAttachment} />
                    )}
                    <Button title={strings.common.ok} onClick={handleAttachmentsModal} />
                  </AttachmentListItem>
                </AttachmentListItemContainer>
              ) : (
                <>
                  <NoAttachmentsContainer>
                    {strings.attachments.noAttachments}
                  </NoAttachmentsContainer>
                  <Button
                    title={strings.common.add}
                    className='knowledge-attachments-add'
                    onClick={onClickAddAttachment}
                  />
                </>
              )}
            </>
          )}
          {attachmentModal === "add" && (
            <>
              {currentAttachmentFile ? (
                <CurrentAttachmentContainer>
                  <TextInput
                    label='Attachment'
                    width='90%'
                    inputProps={{
                      name: "attachment",
                      onChange: handleCurrentAttachment,
                      value: currentAttachment.name,
                    }}
                    error={attachmentUploadProgress}
                  />
                  <ButtonsContainer>
                    <Button
                      title={strings.common.save}
                      disabled={!!attachmentUploadProgress!!}
                      onClick={onClickSaveAttachment}
                    />
                    <Button
                      title={strings.common.cancel}
                      disabled={!!attachmentUploadProgress!!}
                      onClick={onClickCancelAttachment}
                    />
                  </ButtonsContainer>
                </CurrentAttachmentContainer>
              ) : (
                <>
                  <input
                    type='file'
                    name='attachments'
                    ref={attachmentsPickerRef}
                    onChange={onAttachmentsFileChange}
                    hidden={true}
                    accept='.gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml,.txt'
                  />
                  <BrowseAttachmentContainer>
                    {strings.attachments.addAttachments}
                    <Button title={strings.common.browse} onClick={onClickBrowseAttachment} />
                  </BrowseAttachmentContainer>
                </>
              )}
            </>
          )}
        </AttachmentsModalContainer>
      )}
    </Popup>
  );
}

export default AttachmentModal;
