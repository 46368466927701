import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import { MomentDate, getAttributValues } from "../../../../services/methods";
import { ProjectHistoryControllerProps } from "../../../../types/models/ProjectHistory";
import { ActiveSelectOptions, StatusSelectOptions, collections } from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { Row } from "../../../common/styles";
import { ActivityLogs, TableController, WorkLogs } from "../../../components";
import { TextInput, Select } from "../../../components/atoms";
import { FormChecklistV2 } from "../../../components/templates/FormChecklistV2/FormChecklistV2";
import { RelatedListContainer } from "../Departments/styles";

import { useProjectHistoryController } from "./hooks";

export const ProjectHistoryController = ({ type }: ProjectHistoryControllerProps) => {
  const params = useParams();
  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { employeesOptions, modulesOptions } = useControllerConfigs();

  const steps = ["Overview"];
  const {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchProjectHistory,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
  } = useProjectHistoryController({ type, id: params.id, attachmentsPickerRef });
  const currentProjectHistory = data;
  if (type === "edit") {
    steps.push("Worklogs");
    steps.push("System Data");
  }

  const {
    workLogs,
    attachments,
    associatedEmployee,
    projectType,
    projectTechnology,
    terminationReason,
    projectModules,
    projectExternalLink,
    projectStatus,
    currentlyWorking,
    projectCode,
    startDate,
    endDate,
  } = formData;

  useEffect(() => {
    if (type === "edit") {
      fetchProjectHistory();
    }
    // Do not include fetchProjectHistory to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, type]);

  const renderProjectHistoryContact = useMemo(() => {
    return <RelatedListContainer></RelatedListContainer>;
    // Do not include fetchProjectHistory to avoid multiple rerenders form will be rehydrated only once bases on params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const renderOverview = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Project Code'
            type={"text"}
            width='48%'
            error={projectCode.error}
            inputProps={{
              name: "projectCode",
              value: projectCode.value,
              onChange: onTextChange,
              required: projectCode.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Associated Employee"}
            options={employeesOptions}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"projectType"}
            label={"Project Type"}
            options={getAttributValues("projectType")}
            value={projectType.value}
            onChange={onTextChange}
            mandatory={projectType.required}
            error={projectType.error}
          />
          <Select
            width={"48%"}
            name={"projectTechnology"}
            label={"Technology"}
            options={getAttributValues("technology")}
            value={projectTechnology.value}
            onChange={onTextChange}
            mandatory={projectTechnology.required}
            error={projectTechnology.error}
          />
        </Row>
        <Row>
          <TextInput
            label='Start Date'
            type={"date"}
            width='48%'
            error={startDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "startDate",
              value: startDate.value,
              required: startDate.required,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label='End Date'
            type={"date"}
            width='48%'
            error={endDate.error}
            onDateChange={onDateChange}
            datePickerProps={{
              name: "endDate",
              minDate: startDate.value ? new Date(startDate.value) : undefined,
              value: endDate.value,
              required: endDate.required,
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"projectStatus"}
            label={"Status"}
            options={getAttributValues("projectStatus")}
            value={projectStatus.value}
            onChange={onTextChange}
            mandatory={projectStatus.required}
            error={projectStatus.error}
          />
          <Select
            width={"48%"}
            name={"currentlyWorking"}
            label={"Currently Working"}
            options={ActiveSelectOptions}
            value={currentlyWorking.value}
            onChange={onTextChange}
            mandatory={currentlyWorking.required}
            error={currentlyWorking.error}
          />
        </Row>
        <TextInput
          label='Termination Reason'
          type={"text"}
          width='100%'
          error={terminationReason.error}
          inputProps={{
            name: "terminationReason",
            value: terminationReason.value,
            onChange: onTextChange,
            required: terminationReason.required,
          }}
          textAreaProps={{}}
        />

        <Select
          width={"100%"}
          name={"projectModules"}
          label={"Project Modules"}
          isMulti
          options={modulesOptions}
          value={projectModules.value}
          onChange={onTextChange}
          mandatory={projectModules.required}
          error={projectModules.error}
        />
        <TextInput
          label='External Link'
          type={"text"}
          width='100%'
          error={projectExternalLink.error}
          inputProps={{
            name: "projectExternalLink",
            value: projectExternalLink.value,
            onChange: onTextChange,
            required: projectExternalLink.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [
    projectCode.error,
    projectCode.value,
    projectCode.required,
    onTextChange,
    employeesOptions,
    associatedEmployee.value,
    associatedEmployee.required,
    associatedEmployee.error,
    projectType.value,
    projectType.required,
    projectType.error,
    projectTechnology.value,
    projectTechnology.required,
    projectTechnology.error,
    startDate.error,
    startDate.value,
    startDate.required,
    onDateChange,
    endDate.error,
    endDate.value,
    endDate.required,
    projectStatus.value,
    projectStatus.required,
    projectStatus.error,
    currentlyWorking.value,
    currentlyWorking.required,
    currentlyWorking.error,
    terminationReason.error,
    terminationReason.value,
    terminationReason.required,
    modulesOptions,
    projectModules.value,
    projectModules.required,
    projectModules.error,
    projectExternalLink.error,
    projectExternalLink.value,
    projectExternalLink.required,
  ]);

  const renderSystemData = useMemo(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Created On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentProjectHistory?.createdAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentProjectHistory?.updatedAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Created By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentProjectHistory?.createdBy?.name,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentProjectHistory?.updatedBy?.name,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    currentProjectHistory?.createdAt,
    currentProjectHistory?.createdBy?.name,
    currentProjectHistory?.updatedAt,
    currentProjectHistory?.updatedBy?.name,
  ]);

  const renderChecklist = useMemo(
    () => <FormChecklistV2 collection={collections.projectHistory.name} recordId={params.id} />,

    [params.id],
  );

  const renderActivitylogs = useMemo(
    () => <ActivityLogs recordId={params.id} collection={collections.projectHistory.name} />,
    [params.id],
  );

  const renderWorklogs = useMemo(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview;
      case 1:
        return renderChecklist;
      case 2:
        return renderActivitylogs;
      case 3:
        return renderWorklogs;
      case 4:
        return renderSystemData;
      case 5:
        return renderProjectHistoryContact;
      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    renderOverview,
    renderSystemData,
    renderWorklogs,
    renderActivitylogs,
    renderChecklist,
    renderProjectHistoryContact,
  ]);

  return (
    <TableController
      type={type}
      loading={loading}
      headerTitle={headerTitle}
      headerButtons={headerButtons}
      onAttachmentsClick={handleAttachmentsModal}
      attachmentsBadge={attachments.value.length}
      formTitle={formTitle}
      formSteps={formSteps}
      formRelatedList={formRelatedList}
      currentStepIndex={currentStepIndex}
      renderFormFields={renderFormFields}
      goTo={goTo}
      getStepBadge={getStepBadge}
      attachmentModal={attachmentModal}
      handleAttachmentsModal={handleAttachmentsModal}
      documentId={documentId}
      handleCloseAttachment={handleCloseAttachment}
      attachments={attachments}
      attachmentsPickerRef={attachmentsPickerRef}
      handleShowAttachment={handleShowAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
      handleCurrentAttachment={handleCurrentAttachment}
      onClickAddAttachment={onClickAddAttachment}
      currentAttachmentFile={currentAttachmentFile}
      currentAttachment={currentAttachment}
      attachmentUploadProgress={attachmentUploadProgress}
      onClickSaveAttachment={onClickSaveAttachment}
      onClickCancelAttachment={onClickCancelAttachment}
      onClickBrowseAttachment={onClickBrowseAttachment}
      onAttachmentsFileChange={onAttachmentsFileChange}
    />
  );
};
