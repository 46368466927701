import React, { useEffect, memo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { WorkspaceListProps } from "../../../../types/models/Workspace";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useWorkspaceList } from "./hooks";

export const WorkspaceList = memo(({ relatedList }: WorkspaceListProps) => {
  const Workspace = useAppSelector((state) => state.WorkspaceReducer.data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");
  const permissions = useAppSelector((state) => state.UserReducer.permissions);

  const { list, metaData } = Workspace;
  const {
    fetchWorkspace,
    loading,
    handlePageChange,
    onClickWorkspace,
    onClickAdd,
    onSearch,
    handleClearSearch,
    onSubmitDeleteRecord,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    workspaceFilterFields,
    exportSelectedRecords,
    exportAllRecords,
    columns,
    WorkspaceConfigTableFields,
  } = useWorkspaceList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchWorkspace({});
    // Do not include fetchWorkspace to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.workspaces}
      handleTableConfigs={handleTableConfigs}
      filters={workspaceFilterFields}
      selectedConfigFields={WorkspaceConfigTableFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickWorkspace}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.task_delete}
      headerTitle={strings.headerTitle.workspaces}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apWorkspace.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
