/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CATALOG_ADMINISTRATION = "FETCH_CATALOG_ADMINISTRATION",
  FETCH_CATALOG_ADMINISTRATION_BY_ID = "FETCH_CATALOG_ADMINISTRATION_BY_ID",
  SUCCESS_FETCH_CATALOG_ADMINISTRATION = "SUCCESS_FETCH_CATALOG_ADMINISTRATION",
  ERROR_FETCH_CATALOG_ADMINISTRATION = "ERROR_FETCH_CATALOG_ADMINISTRATION",
  CREATE_CATALOG_ADMINISTRATION = "CREATE_CATALOG_ADMINISTRATION",
  UPDATE_CATALOG_ADMINISTRATION = "UPDATE_CATALOG_ADMINISTRATION",
  DELETE_CATALOG_ADMINISTRATION = "DELETE_CATALOG_ADMINISTRATION",
}

export default Types;
