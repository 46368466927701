import Types from "./types";

const Initial = {
  ticketsEmployees: [],
  ticketsAdmin: [],
  filters: {
    createdBy: "",
    requestedFor: "",
    assignee: "",
    status: "",
    sortKey: {},
    sortType: "asc",
  },
};

const TicketsReducer = (state = Initial, action) => {
  switch (action.type) {
    case Types.TICKETS_FETCH_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        ticketsEmployees: action.payload,
      };
    case Types.TICKETS_FETCH_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        ticketsAdmin: action.payload,
      };
    case Types.TICKETS_UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default TicketsReducer;
