import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { colors, fs, fonts } from "../../../theme/";

interface Props {
  activeclassname?: string;
  top?: string;
  active?: boolean;
  small?: boolean;
  left?: string;
  height?: string;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.navigationPanel.background};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  position: relative;
`;
export const OrgImageContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div<Props>`
  height: 0.7px;
  width: 100%;
  background-color: ${colors.navigationPanel.separatorColor};
  margin-top: ${(props) => (props.top ? props.top : "0")};
`;

export const OrgImage = styled.img`
  height: 80px;
  width: 80%;
  object-fit: contain;
  align-self: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0px;
  .navigation-search-icon {
    left: 6.6%;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export const BasicActionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;

export const Icon = styled.img<Props>`
  height: ${(props) => (props.small ? "12px" : "16px")};
  width: ${(props) => (props.small ? "12px" : "16px")};
  object-fit: contain;
  align-self: center;
`;

export const NavigationLink = styled(NavLink)<Props>`
  height: 41px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.primaryFontColor};
  padding-left: ${(props) => (props.left ? props.left : "16px")};
  transition: linear 0.2s;
  &.active {
    color: ${(props) => props.active && colors.navigationPanel.activeTabText};
    background-color: ${(props) => props.active && colors.navigationPanel.activeTabBackground};
  }
  &:hover {
    color: ${(props) => !props.active && colors.navigationPanel.hoverTabText};
    background-color: ${(props) => !props.active && colors.navigationPanel.hoverTabBackground};
  }
`;

export const AccordionContainer = styled.div<Props>`
  width: 100%;
  padding-left: 10%;
`;

export const AccordionLine = styled.div<Props>`
  height: ${(props) => (props.height ? props.height : "100%")};
  width: 1px;
  background-color: ${colors.navigationPanel.accordionBorder};
  position: absolute;
`;

export const Accordion = styled.div<Props>`
  min-height: 41px;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: linear 0.2s;
  color: ${(props) =>
    props.active ? colors.navigationPanel.activeTabText : colors.primaryFontColor};
  .arrow {
    position: absolute;
    right: 10%;
    transition: linear 0.5s;
    transform: ${(props) => props.active && "rotate(90deg)"};
  }
`;

export const AccordionChildContainer = styled.div`
  margin-left: 2%;
  position: relative;
`;

export const IconHolder = styled.div<Props>`
  color: ${colors.grey};
`;
export const TextHolder = styled.text`
  margin-left: 5%;
  font-family: ${fonts.SourceSansPro};
  font-weight: 600;
  font-size: ${fs.m};
`;
export const EmptySpace = styled.div`
  flex: 1;
`;

export const Version = styled.div`
  width: 100%;
  font-family: ${fonts.SourceSansPro};
  font-weight: 600;
  font-size: ${fs.m};
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
