import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  .table-list-pagination {
    margin-left: 30px;
  }
`;

// export const FilterModalContainer = styled.div`
//   position: absolute;
//   display: flex;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 100vw;
//   background: rgba(0, 0, 0, 0.65);
//   z-index: 3;
// `;

// export const FilterContainer = styled.div`
//   position: absolute;
//   display: flex;
//   top: 11%;
//   right: 1%;
//   height: 80vh;
//   width: 30vw;
//   background: green;
//   padding: 30px;
//   box-sizing: border-box;
//   z-index: 2;
//   border: 0.6px solid rgba(0, 0, 0, 0.75);
// `;

export const TableConfigModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.common.white};
  /* min-height: 40vh; */
  width: 20vw;
  padding: 5%;
  box-sizing: border-box;
`;
export const TableConfigTitle = styled.div`
  display: flex;
  font-size: 18px;
  color: ${colors.primaryFontColor};
  font-family: ${fonts.OpenSans};
`;
export const TableFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  /* row-gap: 20px; */
  .table-configs-checkbox {
    margin-top: 0;
  }
  .table-configs-checkbox-wrapper {
    padding: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .table-configs-checkbox-label {
    position: relative;
    margin: 0 10px;
    top: 0;
  }
`;
export const TableFieldItem = styled.div`
  font-size: 16px;
  padding: 10px;
  font-weight: 400;
  color: ${colors.primaryFontColor};
  font-family: ${fonts.OpenSans};
  cursor: pointer;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
`;
