import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

interface Props {
  width?: string;
  height?: string;
  center?: boolean;
  horizontalScroll?: boolean;
  emptyList?: boolean;
  index?: number;
  background?: string;
  selected?: boolean;
  visible?: boolean;
  active?: boolean;
}

export const TableWrapper = styled.div`
  display: flex;
  .table-list-select-row {
    position: absolute;
    top: 0;
  }
  .table-list-select-checkbox-container {
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    border-right: 0.3px solid black;
  }
  .table-list-select-checkbox {
    padding: 0;
    height: 25px;
    width: 25px;
    object-fit: contain;
  }
  .sticky-column {
    width: 50px;
    position: sticky;
    left: 0;
    background-color: ${colors.table.dataBackground};
    z-index: 1;
  }
`;

export const TableOuterContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  height: 40vh;
  min-width: 100%;
  position: relative;
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-clip: padding-box;
    background-color: ${colors.table.scrollThumb};
  }
`;

export const TableBottomEmptyContainer = styled.div`
  height: 10px;
  width: 100%;
  background: transparent;
`;

export const TableContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled.div<Props>`
  display: flex;
  background-color: ${colors.table.headerBackground};
  min-height: 56px;
  width: 100%;
  border-bottom: 0.6px solid ${colors.table.borderColor};
  border-top: 0.6px solid ${colors.table.borderColor};
`;
export const TableHeadRow = styled.div<Props>`
  /* height: 100%; */
  min-height: 41px;
  display: flex;
  width: ${(props) => props.width || "200px"};
  background-color: ${(props) => props.background};
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  position: relative;
  text-align: ${(props) => (props.center ? "center" : "left")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  cursor: pointer;
  border-right: solid 0.3px ${colors.table.borderColor};
  border-left: solid 0.3px ${colors.table.borderColor};
  position: relative;
`;
export const TableDataRow = styled.div<Props>`
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 41px;
  padding: 0 15px;
  display: flex;
  width: ${(props) => props.width || "200px"};
  background-color: ${(props) => props.background};
  align-items: center;
  position: relative;
  text-align: ${(props) => (props.center ? "center" : "left")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  border-right: ${(props) => (props.selected ? `solid 0.3px ${colors.table.borderColor}` : "none")};

  cursor: pointer;
  .title {
    font-size: ${fs.m};
    font-weight: 400;
    content: ${(props) => props.key};
    color: ${colors.common.lightTextColor};
    line-height: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    ${(props) => props.horizontalScroll && { width: props.width }}
  }
`;
export const TableHeadActionsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .table-list-search-container {
    border-radius: 0;
    margin-top: 10px;
    height: 25px;
    width: 100%;
    border: solid 1px ${colors.common.borderColor};
    background-color: ${colors.common.white};
  }
`;
export const TableHeadTitleContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.index === 0 ? "80%" : "95%")};
`;

export const Title = styled.h2<Props>`
  ${(props) => props.horizontalScroll && { width: props.width }}
  font-size: ${fs.m};
  /* max-width: 70%; */
  color: ${colors.common.black};
  text-transform: capitalize;
  font-weight: 600;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const SelectBox = styled.div<Props>`
  display: flex;
  max-width: 20px;
  height: 20px;
  min-width: 20px !important;
  margin-right: 15px;
  border: 0.6px solid ${colors.table.borderColor};
  border-radius: 3px;
  background-color: ${(props) => (props.selected ? colors.table.selectedRow : colors.common.white)};
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

export const TableData = styled.div<Props>`
  display: flex;
  background-color: ${colors.table.dataBackground};
  width: 100%;
  flex-direction: column;
  .data-title {
    font-weight: 400;
    color: ${colors.common.lightTextColor};
    text-transform: capitalize;
    overflow: hidden !important;
  }
`;

export const TableDataRowContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  overflow: visible;
  border-bottom: 0.6px solid ${colors.table.borderColor};
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: linear 0.4s;
  background-color: ${(props) =>
    props.selected ? colors.table.selectedRowBackground : "transparent"};
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
  .clickable-row-border:hover {
    min-width: auto;
    border-bottom: 0.3px solid ${colors.common.border};
    padding: 0;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

export const EmptyList = styled.div`
  height: 64px;
  border-bottom: 0.3px solid ${colors.common.border};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const DotsIcon = styled.img`
  height: 20px;
  width: 20px;
  display: flex;
  margin-right: 20px;
  cursor: pointer;
`;

export const SortIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 10px;
  display: flex;
  cursor: pointer;
`;

export const SortPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 5px;
  filter: drop-shadow(-2px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  z-index: 10000;
  padding: 10px 20px;
  background-color: ${colors.common.white};
  top: 80%;
  left: 50%;
  row-gap: 10px;
  width: 100%;
`;

export const SortPopupItem = styled.div<Props>`
  padding: 4px;
  display: flex;
  font-family: ${fonts.SourceSansPro};
  background-color: ${(props) =>
    props.active ? `${colors.common.activeTabBackground}` : "transparent"};
  font-size: ${fs.m};
  transition: 0.4s;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
`;
