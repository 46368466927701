import styled from "styled-components";

import { colors, fonts, fs } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 228px;
  background-color: ${colors.common.primary};
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 2vw;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;
export const Name = styled.text`
  font-family: ${fonts.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: ${fs.xxl};
  color: ${colors.common.titleColor};
`;

export const Message = styled.text`
  margin-top: 2%;
  font-family: ${fonts.SourceSansPro};
  font-style: normal;
  font-size: ${fs.m};
  color: ${colors.common.titleColor};
  width: 65%;
  line-height: 20px;
`;
