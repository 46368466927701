import React, { useEffect, useState } from "react";

import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import ModalComponent from "../../components/Modal/Modal";
import { Header } from "../../presentationals/components";
import { uploadFile } from "../../redux/application/actions";
import { createComment, createTicket, getAllTickets } from "../../redux/tickets/actions";
import {
  useTitle,
  AttributesValues,
  serialPrefixTickets,
  MomentDate,
} from "../../services/methods";
import { strings } from "../../theme";


import "suneditor/dist/css/suneditor.min.css";

import styles from "./TicketsDetails.styles";


const TicketsDetails = (props) => {
  const params = useParams();
  const [id, setId] = useState("");
  const [data, setData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);

  useTitle("Tickets Details");

  useEffect(() => {
    if (props.tickets.length > 0 && params.id !== id) {
      const find = props.tickets.find((item) => String(item._id) === params.id);
      if (find) {
        setId(params.id);
        setData(find);
      }
    }
  }, [props.tickets, params.id, id]);

  const onTextChange = (e) => {
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    setComment(value);
  };

  const StatusRender = (status) => {
    let output = "";
    if (props.assigned) {
      switch (status) {
        case "awaiting_reply":
          output = "Replied";
          break;
        case "replied":
          output = "Awaiting Reply";
          break;
        case "closed":
          output = "Ticket Closed";
          break;
        default:
          break;
      }
    } else {
      switch (status) {
        case "awaiting_reply":
          output = "Awaiting Reply";
          break;
        case "replied":
          output = "Replied";
          break;
        case "closed":
          output = "Ticket Closed";
          break;
        default:
          break;
      }
    }
    return output;
  };

  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    const status = data.userId._id === props.UserReducer.id ? "awaiting_reply" : "replied";
    setSubmitting(true);
    const object = {
      id: id,
      status: status,
      comment: comment,
      onSuccess: (data) => {
        setSubmitting(false);
        setData(data.data);
        setComment("");
      },
      onError: (err) => {
        setSubmitting(false);
        alert(err);
      },
    };
    props.createComment(object);
  };

  return (
    <styles.MainContainer>
      <Header title={strings.headerTitle.ticketDetails} />
      <styles.Header>
        <Link
          to={
            props.all
              ? "/ep/tickets/all"
              : props.assigned
              ? "/ep/tickets/assigned"
              : "/ep/tickets/created"
          }
        >
          <MdArrowBack size={"30"} />
        </Link>
        <h2>Service Request Details</h2>
      </styles.Header>
      <styles.DetailsContainer>
        <styles.Container width='36%'>
          <h3>Overview</h3>
          <styles.Row header={true}>
            <styles.Column>ID</styles.Column>
            <styles.Column>{serialPrefixTickets(data?.serial)}</styles.Column>
          </styles.Row>
          {props.assigned && (
            <>
              <styles.Row>
                <styles.Column>Created By</styles.Column>
                <styles.Column>
                  {data?.createdBy?.name} ({data?.createdBy?._id?.slice(-5)})
                </styles.Column>
              </styles.Row>
              <styles.Row>
                <styles.Column>Requested For</styles.Column>
                <styles.Column>
                  {data?.userId?.name} ({data?.userId?._id?.slice(-5)})
                </styles.Column>
              </styles.Row>
            </>
          )}
          <styles.Row>
            <styles.Column>Subject</styles.Column>
            <styles.Column>{data.subject}</styles.Column>
          </styles.Row>
          <styles.Row>
            <styles.Column>Topic</styles.Column>
            <styles.Column>{AttributesValues("requestTicketTopic", data.topic)}</styles.Column>
          </styles.Row>
          <styles.Row>
            <styles.Column>Priority</styles.Column>
            <styles.Column>{AttributesValues("priority", data.priority)}</styles.Column>
          </styles.Row>
          <styles.Row>
            <styles.Column>Status</styles.Column>
            <styles.Column>{StatusRender(data.status)}</styles.Column>
          </styles.Row>
          <styles.Row>
            <styles.Column>Assignee</styles.Column>
            <styles.Column>{data?.assignee?.name}</styles.Column>
          </styles.Row>
        </styles.Container>
        <styles.Container type='desc' width='60%'>
          <h3>Description</h3>
          <div
            className='desc'
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </styles.Container>
      </styles.DetailsContainer>
      <br />
      {data?.attachments?.length > 0 && (
        <>
          <h3>Attachments</h3>
          <styles.Container>
            {data?.attachments?.map((attachment, index) => {
              return (
                <styles.attachment key={index} onClick={() => getDocument(attachment.attachment)}>
                  {attachment.name || attachment.attachment}
                </styles.attachment>
              );
            })}
          </styles.Container>
          <br />
        </>
      )}

      <br />
      {data?.comments?.length > 0 && (
        <styles.DetailsContainer type='comment'>
          <h3>Comments</h3>
          {data?.comments?.map((comment, index) => {
            return (
              <styles.Container
                othersComment={comment?.userId?._id !== data.userId._id}
                key={index}
              >
                <styles.date>
                  {MomentDate({
                    date: comment.date,
                    format: "YYYY-MM-DD HH:mm",
                  })}
                </styles.date>
                <styles.date>{comment?.userId?.name}</styles.date>
                <br />
                <styles.comment>{comment.comment}</styles.comment>
              </styles.Container>
            );
          })}
          <br />
          <br />
        </styles.DetailsContainer>
      )}

      <Input
        readOnly={false}
        label={"Comment"}
        type={"textarea"}
        name='subject'
        autoComplete={"off"}
        onChange={onTextChange}
        value={comment}
      />
      <Button
        onClick={handleSubmit}
        disabled={submitting || comment.length === 0}
        size={"normal"}
        label={data.status === "closed" ? "Comment & Reopen Ticket" : "Send Comment"}
      />
      <br />
      <br />
      <br />
      <br />

      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <Document id={documentId} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  timezone: state.UserReducer.timezone,
  UserReducer: state.UserReducer,
  tickets: state.TicketsReducer.ticketsEmployees,
});

const mapDispatchToProps = {
  uploadFile,
  createTicket,
  getAllTickets,
  createComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDetails);
