import React, { useEffect, useState, useCallback } from "react";

import StyledTable from "components/Table/tables.styles";
import moment from "moment-timezone";
import { MdArrowRight, MdOpenInBrowser } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";

import Header from "../../../components/Header";
import { useControllerConfigs } from "../../../presentationals/common";
import { Header as MainHeader } from "../../../presentationals/components";
import {
  addPayrollData,
  fetchPayrollData,
  payrollsRehydrate,
  updatePayrollData,
} from "../../../redux/employees/actions";
import { updateChildPayrollsFilters } from "../../../redux/employees/actions";
import { AttributesValues, getAttributValuesBynameId, useTitle } from "../../../services/methods";
import { strings } from "../../../theme";

import styles from "./APPayrollsChild.styles";

const PayrollsChildList = (props) => {
  const navigate = useNavigate();
  // const handleOnClick = useCallback(
  //   (id, employee) => {
  //     navigate(`/ap/payrolls/${id}/edit/${employee}/details`, {
  //       replace: true,
  //     });
  //   },
  //   [navigate]
  // );
  const { currencyOptions } = useControllerConfigs();
  const [id, setId] = useState();
  const [date, setDate] = useState(moment().utc().startOf("M").subtract(1, "M"));
  const [workingDays, setWorkingDays] = useState(0);
  const [payrollData, setPayrollData] = useState([]);
  const [payrollStatus, setPayrollStatus] = useState({});
  const params = useParams();
  // const match = useMatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if ((props.payrolls.length > 0 && params.id !== id) || props.rehydrate === true) {
      Rehydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payrolls, params.id, props.rehydrate]);

  const Rehydrate = () => {
    const find = props.payrolls.find((payroll) => payroll._id === params.id);
    if (find) {
      setId(find._id);
      employeeCalculatorRehydrate(find.list);
      setDate(find.date);
      setWorkingDays(find.workingdays);
      payrollsRehydrate(false);
    }
  };

  useTitle(`Payrolls Editing`);

  const employeeCalculatorRehydrate = (list) => {
    let serial = 1;
    const payrollStatus = {};
    const Array = list.map((payroll) => {
      if (payrollStatus[payroll.status]) {
        payrollStatus[payroll.status]["total"] += 1;
      } else {
        payrollStatus[payroll.status] = {
          title: payroll.status,
          total: 1,
        };
      }
      const object = {
        serial: serial,
        employee: payroll.employee?._id,
        status: payroll.status,
        name: payroll.employee?._id,
        salaryCurrencyDisplay: payroll.salaryCurrency || "USD",
        salaryCurrency: payroll.salaryCurrency,
        workingdays: payroll.workingdays || "0",
        workinghours: payroll.workinghours || "0",
        overtimeChoice: payroll.overtimeChoice || "0",
        overtimeHours: payroll.overtimeHours || "0",
        salaryBase: payroll.salaryBase || "0",
        salaryBonus: payroll.salaryBonus || "0",
        salarySemiAnnualBonus: payroll.salarySemiAnnualBonus || "0",
        salaryAnnualBonus: payroll.salaryAnnualBonus || "0",
        salaryOvertime: payroll.salaryOvertime || "0",
        salaryReimbursements: payroll.salaryReimbursements || "0",
        salaryReferrals: payroll.salaryReferrals || "0",
        salaryAdjustments: payroll.salaryAdjustments || "0",
        salaryTax: payroll.salaryTax || "0",
        salaryPayable: payroll.salaryPayable || "0",
        feedback: payroll.feedback || "",
        acceptedAt: payroll.acceptedAt,
      };
      if (object.salaryPayable === "NaN") {
        object.salaryPayable = parseFloat(object.salaryBase) + parseFloat(object.salaryBonus);
      }
      serial++;
      return object;
    });
    setPayrollStatus(payrollStatus);
    setPayrollData(Array);
    props.updateChildPayrollsFilters({
      field: "status",
      value: payrollStatus,
    });
  };

  const GetEmployeeInfo = (id) => {
    return props.employees.find((employee) => employee._id === id);
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.payrolls} />
      <Header
        title='Payrolls Dashboard'
        buttons={[
          {
            onPress: {
              event: navigate,
              params: `/ap/payrolls/${params.id}/childs`,
            },
            title: "Filters",
            visible: true,
          },
        ]}
      />
      <styles.TopColumn>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <styles.TopGridItem>
            <div className='title'>START</div>
            <h3>{moment(date).utc().startOf("M").format("DD-MMM-YYYY")}</h3>
          </styles.TopGridItem>
          <MdArrowRight className='icon' size={40} />
          <styles.TopGridItem>
            <div className='title'>END</div>
            <h3>{moment(date).utc().endOf("M").format("DD-MM-YYYY")}</h3>
          </styles.TopGridItem>
          <MdArrowRight className='icon' size={40} />
          <styles.TopGridItem backgroundColor='#5BC771'>
            <div className='title'>PAYDAY</div>
            <h3>{workingDays}</h3>
          </styles.TopGridItem>
        </div>
        <styles.TopDashboardContainer>
          {Object.values(payrollStatus).map((item, index) => {
            return (
              <styles.PayrollStatus backgroundColor='#41988E' short={"true"} key={index}>
                <div className='title'>{item.title?.toUpperCase()}</div>
                <h3 short={"true"}>{item.total}</h3>
              </styles.PayrollStatus>
            );
          })}
        </styles.TopDashboardContainer>
      </styles.TopColumn>

      <StyledTable width={"100%"}>
        <thead onClick={() => console.log(payrollData)}>
          <tr>
            <th align='left' width='5%'>
              #
            </th>
            <th align='left' width='30%'>
              Employee
            </th>
            <th align='left' width='20%'>
              Sub Total
            </th>
            <th align='left' width='20%'>
              Salary Payable
            </th>
            <th align='left' width='15%'>
              Status
            </th>
            <th align='left' width='15%'>
              -
            </th>
          </tr>
        </thead>
        <tbody>
          {payrollData.map((payroll, index) => {
            const employeeInfo = GetEmployeeInfo(payroll.name);
            if (
              payroll?.status?.includes(props.filters?.selectedStatus) &&
              employeeInfo?.label?.toUpperCase()?.includes(props?.filters?.name?.toUpperCase())
            ) {
              let currency = AttributesValues("currency", payroll.salaryCurrencyDisplay);
              if (!currency) {
                currency = currencyOptions.find(
                  (item) => item.value === payroll.salaryCurrencyDisplay,
                )?.label;
              }
              return (
                <tr key={index} onClick={() => console.log(currency, payroll)}>
                  <td valign='top'>{payroll.serial}</td>
                  <td valign='top'>{employeeInfo?.label}</td>
                  <td valign='top'>
                    {currency} {payroll?.salaryPayable}
                  </td>
                  <td valign='top'>
                    {currency} {parseFloat(payroll?.salaryPayable) - parseFloat(payroll?.salaryTax)}
                  </td>
                  <td valign='top'>{String(payroll.status).toUpperCase()}</td>
                  <td valign='top'>
                    <Link to={`/ap/payrolls/${params.id}/${payroll.employee}/edit`}>
                      <MdOpenInBrowser size={20} />
                    </Link>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.UserReducer.permissions,
  rehydrate: state.EmployeesReducer.payrollsRehydrate,
  payrolls: state.EmployeesReducer.payrolls,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
  filters: state.EmployeesReducer.childPayrollsFilters,
});

const mapDispatchToProps = {
  addPayrollData,
  updatePayrollData,
  fetchPayrollData,
  updateChildPayrollsFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsChildList);
