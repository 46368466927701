import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SunEditor from "suneditor-react";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { fetchEmailTemplate, emailTemplateUpdate } from "../../redux/emailtemplates/actions";
import { formValidationMethod, useTitle } from "../../services/methods";

import styles from "./styles";

import "suneditor/dist/css/suneditor.min.css";

const EmailTemplatesController = (props) => {
  const defaultData = {
    name: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    subject: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    body: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    useDefault: {
      required: false,
      type: "",
      error: "",
      value: true,
    },
  };

  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({ message: "", message_type: "" });
  const [id, setId] = useState("");
  const [formData, setFormData] = useState(defaultData);

  useTitle("Email Templates");

  useEffect(() => {
    if (id !== params.id) {
      setLoader(true);
      setReadOnly(true);
      const key = params?.id || "";
      if (key) {
        updateValues(key);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.defData, props.orgData]);

  const updateValues = (key) => {
    const object = { ...formData };
    const Def = props.defData?.[key];
    const Org = props.orgData?.[key];
    console.log("Org", props.orgData, Def);
    if (Def && Org) {
      object.name.value = Def?.name;
      object.subject.value = Org.useDefault ? Def.subject : Org.subject;
      object.body.value = Org.useDefault ? Def.body : Org.body;
      object.useDefault.value = Org.useDefault;
      setId(key);
      setLoader(false);
      setSubmitting(false);
      setFormData(object);
      setToast({ message: "", message_type: "" });
      Org.useDefault && !props.superAdmin ? setReadOnly(true) : setReadOnly(false);
    }
  };

  const updateDefaultValues = (e) => {
    const value = e.target.value;
    const object = { ...formData };
    const Def = props.defData?.[id];
    const Org = props.orgData?.[id];
    if (Def && Org) {
      if (value) {
        object.subject.value = Def.subject;
        object.body.value = Def.body;
      } else {
        object.subject.value = Org.subject;
        object.body.value = Org.body;
      }
      object.useDefault.value = value;
      setFormData(object);
      value && !props.superAdmin ? setReadOnly(true) : setReadOnly(false);
    }
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const error = formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const handleOnChange = (event) => {
    setFormData({
      ...formData,
      body: {
        ...formData["body"],
        value: event,
      },
    });
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);

    return output;
  };

  const onSubmitUpdateForm = (e) => {
    e.preventDefault();
    const submit = validateForm();
    if (submit) {
      setSubmitting(true);
      props.emailTemplateUpdate({
        key: id,
        subject: formData.subject.value,
        body: formData.body.value,
        useDefault: formData.useDefault.value,
        onSuccess: (data) => {
          props.fetchEmailTemplate({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 || submitting ? true : false;
    return value;
  };

  const CopyValue = (e) => {
    const name = e.currentTarget.getAttribute("data-type");
    const value = props.defData[id][name];
    const error = formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2 onClick={() => console.log(formData)}>{"Update"}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form onSubmit={onSubmitUpdateForm} autoComplete='off'>
        <Select
          name={"useDefault"}
          label={"Template"}
          options={[
            { label: "Use Default", value: true },
            { label: "Don't Use Default", value: false },
          ]}
          value={formData.useDefault.value}
          onChange={updateDefaultValues}
        />
        <Input
          readOnly={true}
          label={"Name"}
          type={"text"}
          name='name'
          value={formData.name.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.name?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Email Subject"}
          type={"text"}
          name='subject'
          value={formData.subject.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.subject?.error}
          attributes={{ "data-type": "subject" }}
          message={!formData.useDefault.value ? "Copy Subject From Default" : ""}
          onMessageClick={CopyValue}
        />
        {/* <Input
          readOnly={readOnly}
          label={"Email Body"}
          type={"textarea"}
          name="body"
          value={formData.body.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.body?.error}
          attributes={{ "data-type": "body" }}
          message={!formData.useDefault.value ? "Copy Body From Default" : ""}
          onMessageClick={CopyValue}
        /> */}
        <b>Email Body</b>
        <SunEditor
          lang={"en"}
          placeholder='Description'
          height='30vh'
          defaultValue={""}
          // hideToolbar={true}
          // readOnly={true}
          onChange={handleOnChange}
          setContents={formData.body.value}
          // imageUploadHandler={imageUploadHandler}
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize"],
              // ['paragraphStyle', 'blockquote'],
              ["bold", "underline", "italic", "strike", "subscript", "superscript"],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],

              ["table", "horizontalRule", "link" /*"image"*/],
              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
              // ['imageGallery'], // You must add the "imageGalleryUrl".
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["removeFormat"],

              // ['save', 'template'],
              // '/', Line break
            ],
          }}
        />
        {!formData.useDefault.value ? (
          <div
            style={{
              fontSize: 10,
              color: "red",
              margin: "10px 0px",
              cursor: "pointer",
            }}
            data-type={"body"}
            onClick={CopyValue}
          >
            Copy Body From Default
          </div>
        ) : null}
        <Button
          onClick={onSubmitUpdateForm}
          disabled={SubmitDisabled()}
          size={"normal"}
          label={"Save"}
        />
      </form>
      <br />
      <br />
      <br />
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  superAdmin: state.UserReducer.superAdmin,
  organisation: state.UserReducer?.organisation?._id,
  defData: state.EmailTemplatesReducer.defData,
  orgData: state.EmailTemplatesReducer.orgData,
});

const mapDispatchToProps = {
  fetchEmailTemplate,
  emailTemplateUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesController);
