import React from "react";
import { useState, useMemo, useCallback, MouseEvent } from "react";

import { useNavigate } from "react-router-dom";

import {
  addAppNotification,
  createServiceRequests,
  fetchServiceRequestsById,
  fetchControllerConfigs,
  removeAppNotification,
  updateServiceRequests,
  uploadFile,
} from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  ServiceRequestsList,
  CreateServiceRequestsPayload,
  FetchServiceRequestsByIdPayload,
  UpdateServiceRequestsPayload,
} from "../../../../../redux/ServiceRequests/types";
import { strings } from "../../../../../theme";
import {
  AppNotificationsType,
  Attachments,
  SelectOptions,
  TableHeaderButton,
  WorkLogItem,
} from "../../../../../types";
import {
  ServiceRequestsForm,
  useServiceRequestsControllerProps,
  useServiceRequestsControllerReturnType,
} from "../../../../../types/models/ServiceCatalog";
import { collections } from "../../../../../utils";
import { useControllerConfigs } from "../../../../common";
// ================ Contstants ================

const addFormSteps = ["Overview"];
const editFormSteps = ["Overview", "Checklist", "Activity Logs", "Worklogs", "System Data"];
const defaultCurrentAttachment: Attachments = { id: "", name: "", extension: "" };
const overViewFields = [
  "assignedTo",
  "holdReason",
  "description",
  "priority",
  "stage",
  "state",
  "shortDescription",
  "assignmentGroups",
  "watchListUser",
  "urgency",
  "watchListGroup",
  "dueDate",
];
export const useServiceRequestsController = ({
  type,
  id,
  attachmentsPickerRef,
}: useServiceRequestsControllerProps): useServiceRequestsControllerReturnType => {
  const { usersOptions } = useControllerConfigs();

  const defaultFormData: ServiceRequestsForm = {
    workLogs: {
      value: [],
      required: false,
      error: "",
    },
    attachments: {
      value: [],
      required: false,
      error: "",
    },
    assignedTo: {
      value: "",
      required: true,
      error: "",
    },
    holdReason: { value: "", required: true, error: "" },
    priority: {
      value: "",
      required: false,
      error: "",
    },
    stage: {
      value: "",
      required: false,
      error: "",
    },
    state: {
      value: "",
      required: false,
      error: "",
    },
    shortDescription: {
      value: "",
      required: true,
      error: "",
    },
    description: {
      value: "",
      required: true,
      error: "",
    },
    number: {
      value: "",
      required: false,
      error: "",
    },
    assignmentGroups: {
      value: "",
      required: false,
      error: "",
    },
    watchListUser: {
      value: [],
      required: false,
      error: "",
    },
    urgency: {
      value: "",
      required: false,
      error: "",
    },
    watchListGroup: {
      value: [],
      required: false,
      error: "",
    },
    dueDate: {
      value: "",
      required: false,
      error: "",
    },
  };

  // ================ Reducer Values ================
  const currentUser = useAppSelector((state) => state.UserReducer);
  const notificationsId = useAppSelector((state) => state.ApplicationReducer.notifications).length;
  const groups = useAppSelector(
    (state) => state.ApplicationReducer.configurations.controllerConfigs.groups,
  );
  // ================ Hooks ================

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ================ State Values ================
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ServiceRequestsForm>(defaultFormData);
  const [attachmentModal, setAttachmentModal] = useState<"" | "add" | "list">("");
  const [currentAttachmentFile, setCurrentAttachmentFile] = useState<File | null>(null);
  const [currentAttachment, setCurrentAttachment] = useState<Attachments>(defaultCurrentAttachment);
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");
  const [data, setData] = useState<ServiceRequestsList>({});

  // ================ Memoized Values ================
  const formSteps = useMemo(() => {
    return type === "edit" ? editFormSteps : addFormSteps;
  }, [type]);

  const formRelatedList = useMemo(() => {
    return [];
  }, []);

  const formTitle = useMemo(() => {
    return formSteps[currentStepIndex] || formRelatedList[currentStepIndex - formSteps.length];
  }, [formSteps, currentStepIndex, formRelatedList]);

  // ================ Form Prev/Next ================

  const next = useCallback(() => {
    setCurrentStepIndex((i) => (i >= formSteps.length - 1 ? i : i + 1));
  }, [formSteps.length]);

  const back = useCallback(() => {
    setCurrentStepIndex((i) => (i <= 0 ? i : i - 1));
  }, []);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const getStepBadge = useCallback(
    (step: number) => {
      let count = 0;

      switch (step) {
        case 0:
          overViewFields.forEach((field) => {
            if (formData[field]?.error) {
              count += 1;
            }
          });
          break;
      }
      return count;
    },
    [formData],
  );

  const onDateChange = useCallback(
    (value: Date, name: string) => {
      const tempFormData = { ...formData, [name]: { ...formData[name], value, error: "" } };
      setFormData(tempFormData);
    },
    [formData],
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      let tempFormData = {
        ...formData,
        [name]: {
          ...formData[name],
          value,
          error: "",
        },
      };
      if (name === "assignmentGroups" && formData.assignedTo.value && value) {
        const groupMembers = groups.find((item) => item._id === value)?.members;
        const currentAssignedTo = formData.assignedTo.value;
        let currentAssignedToInGroup = false;
        groupMembers?.map((item) => {
          if (item.user._id === currentAssignedTo) {
            currentAssignedToInGroup = true;
          }
        });
        if (!currentAssignedToInGroup) {
          tempFormData = {
            ...tempFormData,
            assignedTo: {
              ...formData.assignedTo,
              value: "",
              error: "",
            },
          };
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title:
              "Assigned to is not part of the assignemnt group , select the user from the selcted group",
            type: "warning",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 5000);
        }
      }
      setFormData(tempFormData);
    },
    [formData, groups, dispatch, notificationsId],
  );

  const rehydrateFormData = useCallback(
    (task) => {
      const item = task;
      const data = { ...formData };
      const tempAttachments: Attachments[] = [];
      Object.keys(formData).map((field) => {
        let value = item?.[field];
        if (["assignedTo", "priority", "urgency", "state", "assignmentGroups"].includes(field)) {
          const tempValue = value?._id;
          value = tempValue;
        }
        if (["watchListGroup", "watchListUser"].includes(field)) {
          value = item?.[field]?.map((item) => ({
            label: item.name,
            value: item._id,
          }));
        }
        if (["achAssociationType"].includes(field)) {
          if (item?.associatedCandidateLead) {
            value = "lead";
          }
          if (item?.associatedCandidateEnrolment) {
            value = "enrolment";
          }
          if (item?.associatedCandidateStudent) {
            value = "student";
          }
        }
        if (field === "attachments") {
          for (let i = 0; i < value.length; i++) {
            const tempValue = {
              id: value[i].id,
              error: "",
              name: value[i].name,
            };
            tempAttachments.push(tempValue);
          }
          value = tempAttachments;
        }

        data[field].value = value;
      });
      setFormData(data);
    },
    [formData],
  );

  const fetchServiceRequests = useCallback(() => {
    setLoading(true);
    const payload: FetchServiceRequestsByIdPayload = {
      id: id || "",
      onSuccess: (data) => {
        setLoading(false);
        rehydrateFormData(data);
        setData(data);
      },
      onError: (error) => {
        setLoading(false);
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: error,
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      },
    };
    dispatch(fetchServiceRequestsById(payload));
  }, [id, dispatch, rehydrateFormData, notificationsId]);

  const validateForm = useCallback(() => {
    let tempFormData = { ...formData };
    let valid = true;

    Object.keys(tempFormData).forEach((item) => {
      const currentField = tempFormData[item];

      const validValue = Array.isArray(currentField.value)
        ? currentField.value.length
        : currentField.value;
      if (currentField.required && !validValue) {
        valid = false;
        tempFormData = {
          ...tempFormData,
          [item]: {
            ...tempFormData[item],
            error: "Required Field",
          },
        };
      }
    });
    setFormData(tempFormData);
    return valid;
  }, [formData]);

  const onSubmitAdd = useCallback(() => {
    const {
      assignedTo,
      assignmentGroups,
      description,
      dueDate,
      holdReason,
      number,
      priority,
      shortDescription,
      stage,
      state,
      urgency,
      watchListGroup,
      watchListUser,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      setLoading(true);
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const tempWatchlistGroups: string[] = watchListGroup.value.map((item) => String(item.value));
      const tempWatchlistUser: string[] = watchListUser.value.map((item) => String(item.value));

      const payload: CreateServiceRequestsPayload = {
        data: {
          attachments: attachments,
          number: number.value,
          shortDescription: shortDescription.value,
          description: description.value,
          assignmentGroups: assignmentGroups.value,
          assignedTo: assignedTo.value,
          watchListGroup: tempWatchlistGroups,
          watchListUser: tempWatchlistUser,
          priority: priority.value,
          urgency: urgency.value,
          state: state.value,
          holdReason: holdReason.value,
          stage: stage.value,
          dueDate: dueDate.value,
        },
        onSuccess: (data) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ServiceRequests created successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
          navigate(-1);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(createServiceRequests(payload));
    }
  }, [formData, validateForm, notificationsId, dispatch, navigate]);

  const onSubmitUpdate = useCallback(() => {
    const {
      assignedTo,
      assignmentGroups,
      description,
      dueDate,
      holdReason,
      number,
      priority,
      shortDescription,
      stage,
      state,
      urgency,
      watchListGroup,
      watchListUser,
    } = formData;
    const submit = validateForm();
    if (!submit) {
      const notification: AppNotificationsType = {
        id: notificationsId + 1,
        title: "Please update the form",
        type: "warning",
      };
      dispatch(addAppNotification({ notification }));
      setTimeout(() => {
        dispatch(removeAppNotification({ notification }));
      }, 2000);
      return;
    }
    if (submit) {
      const attachments = formData.attachments.value.map((item) => ({
        name: item.name,
        id: item.id,
        extension: item.extension,
      }));
      const tempWatchlistGroups: string[] = watchListGroup.value.map((item) => String(item.value));
      const tempWatchlistUser: string[] = watchListUser.value.map((item) => String(item.value));
      setLoading(true);
      const payload: UpdateServiceRequestsPayload = {
        id: id || "",
        data: {
          attachments: attachments,
          number: number.value,
          shortDescription: shortDescription.value,
          description: description.value,
          assignmentGroups: assignmentGroups.value,
          assignedTo: assignedTo.value,
          watchListGroup: tempWatchlistGroups,
          watchListUser: tempWatchlistUser,
          priority: priority.value,
          urgency: urgency.value,
          state: state.value,
          holdReason: holdReason.value,
          stage: stage.value,
          dueDate: dueDate.value,
        },
        onSuccess: () => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: "ServiceRequests updated successfully",
            type: "success",
          };
          dispatch(addAppNotification({ notification }));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
        onError: (error) => {
          const notification: AppNotificationsType = {
            id: notificationsId + 1,
            title: error || "",
            type: "error",
          };
          dispatch(addAppNotification({ notification }));
          dispatch(fetchControllerConfigs({}));
          setTimeout(() => {
            dispatch(removeAppNotification({ notification }));
          }, 2000);
          setLoading(false);
        },
      };
      dispatch(updateServiceRequests(payload));
    }
  }, [formData, id, dispatch, notificationsId, validateForm]);

  const onPostWorklog = useCallback(
    (comment: string) => {
      if (!comment.length) {
        const notification: AppNotificationsType = {
          id: notificationsId + 1,
          title: "Please enter comment",
          type: "error",
        };
        dispatch(addAppNotification({ notification }));
        setTimeout(() => {
          dispatch(removeAppNotification({ notification }));
        }, 2000);
      } else {
        const currentWorklogs = [...formData.workLogs.value];
        const { name, profilePic } = currentUser;
        const workLogItem: WorkLogItem = {
          comment,
          date: new Date(),
          user: { _id: currentUser.id, name, profilePic },
        };
        currentWorklogs.push(workLogItem);
        const tempFormData = {
          ...formData,
          workLogs: {
            ...formData.workLogs,
            value: currentWorklogs,
          },
        };
        setFormData(tempFormData);
        const payload: UpdateServiceRequestsPayload = {
          id: id || "",
          data: {
            workLogs: [
              {
                comment,
                user: currentUser.id,
              },
            ],
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            const notification: AppNotificationsType = {
              id: notificationsId + 1,
              title: "Error in posting worklog please try again later " + error || "",
              type: "error",
            };
            dispatch(addAppNotification({ notification }));
            setTimeout(() => {
              dispatch(removeAppNotification({ notification }));
            }, 5000);
            setLoading(false);
          },
        };
        dispatch(updateServiceRequests(payload));
      }
    },
    [notificationsId, dispatch, formData, currentUser, id],
  );

  const headerTitle = useMemo(() => {
    let title = "";
    if (type === "add") {
      title = "Create";
    } else {
      title = data.name || "";
    }
    return [strings.headerTitle.serviceRequests, title];
  }, [type, data.name]);

  const handleAttachmentsModal = useCallback(() => {
    if (!attachmentUploadProgress) {
      setAttachmentModal((value) => (value ? "" : "list"));
      setDocumentId("");
    }
  }, [attachmentUploadProgress]);

  const onClickAddAttachment = useCallback(() => {
    setAttachmentModal("add");
  }, []);

  const onClickBrowseAttachment = useCallback(() => {
    attachmentsPickerRef.current?.click();
  }, [attachmentsPickerRef]);

  const onClickCancelAttachment = useCallback(() => {
    setCurrentAttachmentFile(null);
    setCurrentAttachment(defaultCurrentAttachment);
    setAttachmentUploadProgress("");
  }, []);

  const onClickSaveAttachment = useCallback(() => {
    const params = {
      file: currentAttachmentFile,
      onSuccess: (data) => {
        const attachments = [...formData.attachments.value];
        attachments.push({
          id: data.id,
          name: currentAttachment.name,
          extension: currentAttachment.extension,
        });
        const tempFormData = {
          ...formData,
          attachments: {
            ...formData.attachments,
            value: attachments,
          },
        };
        setFormData(tempFormData);
        setCurrentAttachment(defaultCurrentAttachment);
        setCurrentAttachmentFile(null);
        setAttachmentUploadProgress("");
        setAttachmentModal("list");
      },
      onError: (err) => {
        setAttachmentUploadProgress(err);
        setCurrentAttachmentFile(null);
        setCurrentAttachment(defaultCurrentAttachment);
        setAttachmentUploadProgress("");
      },
      onProgress: (progress) => {
        setAttachmentUploadProgress(`Upload in progress (${progress}%)`);
      },
    };
    dispatch(uploadFile(params));
  }, [currentAttachmentFile, currentAttachment, dispatch, formData]);

  const onAttachmentsFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      let fileName: string[] | string | undefined = file?.name?.split(".");
      const extension = fileName?.pop();
      fileName = fileName?.join(".");
      setCurrentAttachmentFile(file || null);
      const attachment = { id: currentAttachment.id, name: fileName || "", extension };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id],
  );

  const handleCurrentAttachment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const attachment = {
        id: currentAttachment.id,
        name: e.target.value,
        extension: currentAttachment.extension,
      };
      setCurrentAttachment(attachment);
    },
    [currentAttachment.id, currentAttachment.extension],
  );

  const handleShowAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      const documentId = formData.attachments.value[index].id || "";
      console.log(index, documentId);
      setDocumentId(documentId);
    },
    [formData.attachments.value],
  );

  const handleCloseAttachment = useCallback(() => {
    setDocumentId("");
  }, []);

  const handleDeleteAttachment = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const index = Number(e.currentTarget.getAttribute("data-index"));
      console.log(index);
      const attachments = [...formData.attachments.value];
      attachments.splice(index, 1);
      const tempformData = {
        ...formData,
        attachments: {
          ...formData.attachments,
          value: attachments,
        },
      };
      setFormData(tempformData);
    },
    [formData],
  );

  const headerButtons = useMemo(() => {
    const { save } = strings.common;
    const buttons: TableHeaderButton[] = [
      {
        title: save,
        onClick: type === "add" ? onSubmitAdd : onSubmitUpdate,
        visible: true,
      },
    ];
    return buttons;
  }, [onSubmitAdd, onSubmitUpdate, type]);

  const TableList = useMemo(() => {
    return Object.values(collections)
      .map((table) => {
        if (table && "ServiceRequestsSupported" in table && table.ServiceRequestsSupported) {
          return {
            label: table.label,
            value: table.name,
          };
        }
        return null; // or undefined, depending on your preference
      })
      .filter(Boolean); // to remove any null or undefined entries
  }, []);

  const assignmentOptions = useMemo(() => {
    const users: SelectOptions[] = usersOptions;
    const groupUsers: SelectOptions[] = [];
    const assignmentGroup = formData.assignmentGroups.value;
    if (formData.assignmentGroups.value) {
      const groupMembers = groups.find((item) => item._id === assignmentGroup);
      groupMembers?.members.map((item) => {
        if (item.user.active) {
          groupUsers.push({
            label: item.user.name || "",
            value: item.user._id,
          });
        }
      });
      return groupUsers;
    }
    return users;
  }, [formData.assignmentGroups.value, groups, usersOptions]);

  return {
    formSteps,
    formRelatedList,
    formTitle,
    currentStepIndex,
    goTo,
    next,
    back,
    formData,
    onTextChange,
    loading,
    headerTitle,
    fetchServiceRequests,
    onPostWorklog,
    getStepBadge,
    attachmentModal,
    handleAttachmentsModal,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachmentFile,
    currentAttachment,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    handleShowAttachment,
    documentId,
    handleCloseAttachment,
    handleDeleteAttachment,
    onDateChange,
    data,
    headerButtons,
    TableList,
    assignmentOptions,
  };
};
