import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { addEmployees, fetchEmployees } from "../../redux/employees/actions";
import { addRecruitsAssignment, fetchRecruits, updateRecruits } from "../../redux/recruits/actions";
import { formValidationMethod } from "../../services/methods";

import styles from "./RecruitmentsChecklist.styles";

const RecruitmentChecklist = (props) => {
  const defaultFormData = {
    offerPrepared: {
      required: false,
      error: "",
      value: "",
    },
    offerPreparedComment: {
      required: false,
      error: "",
      value: "",
    },
    offerSent: {
      required: false,
      error: "",
      value: "",
    },
    offerSentComment: {
      required: false,
      error: "",
      value: "",
    },
    offerReceived: {
      required: false,
      error: "",
      value: "",
    },
    offerReceivedComment: {
      required: false,
      error: "",
      value: "",
    },
    offerAccepted: {
      required: false,
      error: "",
      value: "",
    },
    takenBy: {
      required: true,
      type: "",
      error: "",
      value: props.employeeId ? props.employeeId : "",
    },
    feedback: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
  };
  const defaultToastData = {
    message: "",
    message_type: "",
  };
  const params = useParams();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [toast, setToast] = useState(defaultToastData);
  const [createEmployee, setCreateEmployee] = useState(false);
  const [recruitData, setRecruitData] = useState(null);

  useEffect(() => {
    if (params.id !== id) {
      setLoading(true);
      setSubmitting(false);
      setFormData(defaultFormData);
      setCreateEmployee(false);
      const find = props.recruits.filter((recruit) => recruit._id === params.id);
      if (find.length > 0) {
        setId(params.id);
        setLoading(false);
        setRecruitData(find[0]);
        hydrateForm(find[0].checklist);
        setCreateEmployee(validateForm(find[0].checklist));
        setToast(defaultToastData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, props.recruits]);

  const hydrateForm = (data) => {
    const newObject = { ...defaultFormData };
    Object.keys(defaultFormData).forEach((el) => {
      if (data && data[el]) {
        newObject[el] = {
          ...newObject[el],
          value: data[el],
        };
      }
    });
    setFormData(newObject);
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const error = formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const validateForm = (data) => {
    let output = true;
    const loop = { ...formData };
    if (formData.offerAccepted.value === true) {
      delete loop.takenBy;
      delete loop.feedback;
    }
    Object.keys(loop).forEach((item) => {
      const name = item;
      let value;
      if (data) {
        value = data[name];
      } else {
        value = formData[name].value;
      }
      const newformData = {
        ...formData,
        [name]: {
          ...formData[name],
          required: true,
        },
      };
      const error = formValidationMethod(name, value, newformData);
      if (error && output) {
        output = false;
      }
    });
    return output;
  };

  const generateFormObject = () => {
    const object = {};
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      object[name] = value;
    });
    return object;
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 ? true : false;
    return value;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (formData.offerAccepted.value === false) {
      window.confirm("Are you sure you want to reject this applicant ?")
        ? __handleOnSubmit()
        : console.log("cancelled");
    } else {
      __handleOnSubmit();
    }
  };

  const __handleOnSubmit = () => {
    const formDataObject = generateFormObject();
    setSubmitting(true);
    props.updateRecruits({
      id: id,
      data: { checklist: formDataObject },
      onSuccess: (data) => {
        if (formData.offerAccepted.value === false) {
          const sagaData = {
            recruitsId: id,
            data: {
              bucketId: -1,
              takenBy: formData.takenBy.value,
              feedback: formData.feedback.value,
            },
            onSuccess: (id) => {
              props.fetchRecruits({
                onSuccess: () => {
                  navigate("/ap/recruitment");
                },
              });
            },
            onError: (err) => {
              setSubmitting(false);
              alert(err);
            },
          };
          props.addRecruitsAssignment(sagaData);
        } else {
          props.fetchRecruits({
            onSuccess: () => {
              setSubmitting(false);
              setCreateEmployee(validateForm());
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
            },
            onError: () => {
              setSubmitting(false);
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
            },
          });
        }
      },
      onError: (error) => {
        setSubmitting(false);
        setToast({
          message: error,
          message_type: "error",
        });
      },
    });
  };

  const CreateEmployeeRecord = () => {
    const object = {
      recruitId: id,
      name: recruitData.name,
      email: recruitData.email,
      mobile: recruitData.mobile,
      personalDOB: recruitData.personalDOB,
      personalCountry: recruitData.personalCountry,
      employmentType: recruitData.employmentType,
      reportingHR: recruitData.reportingHR,
      attachmentResume: recruitData.attachmentResume,
      referral: recruitData.referral,
      referralMobile: recruitData.referralMobile,
      referralEmail: recruitData.referralEmail,
      nationalId: recruitData.nationalId,
      nationalIdType: recruitData.nationalIdType,
    };
    // create employee
    setSubmitting(true);
    props.addEmployees({
      data: object,
      onSuccess: (data) => {
        // onsuccess fetch employees
        props.fetchRecruits({});
        props.fetchEmployees({
          onSuccess: (data1) => {
            // onsuccess navigate to view employee
            navigate(`/ap/employees/${data.employeeId}/view`);
          },
          onError: (error1) => {},
        });
      },
      onError: (error) => {},
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>Onboarding Checklist</h2>
      </styles.Header>
      <hr style={{ width: "100%" }} />
      <Toast type={toast.message_type} message={toast.message} />

      <styles.Body>
        <form onSubmit={handleOnSubmit}>
          <Select
            name={"offerPrepared"}
            label={"Offerletter Prepared ?"}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={formData.offerPrepared.value}
            onChange={onTextChange}
            error={formData?.offerPrepared?.error}
          />
          <Input
            label={"Offerletter Prepared Comments"}
            type={"text"}
            name='offerPreparedComment'
            value={formData.offerPreparedComment.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.offerPreparedComment?.error}
          />
          <Select
            name={"offerSent"}
            label={"Offerletter Sent ?"}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={formData.offerSent.value}
            onChange={onTextChange}
            error={formData?.offerSent?.error}
          />
          <Input
            label={"Offerletter Sent Comments"}
            type={"text"}
            name='offerSentComment'
            value={formData.offerSentComment.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.offerSentComment?.error}
          />
          <Select
            name={"offerReceived"}
            label={"Offerletter Received ?"}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={formData.offerReceived.value}
            onChange={onTextChange}
            error={formData?.offerReceived?.error}
          />
          <Input
            label={"Offerletter Received Comments"}
            type={"text"}
            name='offerReceivedComment'
            value={formData.offerReceivedComment.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.offerReceivedComment?.error}
          />
          <br />
          <h3>Application Status</h3>
          <hr />
          <Select
            name={"offerAccepted"}
            label={"Offer Accepted ?"}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            value={formData.offerAccepted.value}
            onChange={onTextChange}
            error={formData?.offerAccepted?.error}
          />
          {formData.offerAccepted.value === false && (
            <>
              <Select
                name={"takenBy"}
                label={"Confirmed By"}
                options={props.employees.map((value, index) => ({
                  label: value?.label + "(ITN-" + value?._id?.slice(-5) + ")",
                  value: value._id,
                }))}
                value={formData.takenBy.value}
                onChange={onTextChange}
                error={formData?.takenBy?.error}
              />
              <Input
                label={"Feedback"}
                type={"textarea"}
                name='feedback'
                value={formData.feedback.value}
                autoComplete={"off"}
                onChange={onTextChange}
                mandatory={true}
                error={formData?.feedback?.error}
              />
            </>
          )}
          <Button
            onClick={handleOnSubmit}
            disabled={submitting || SubmitDisabled()}
            size={"normal"}
            label={"Save"}
          />
          {/* <Button
            onClick={CreateEmployeeRecord}
            disabled={submitting || !createEmployee}
            size={"normal"}
            label={"Create Employee Record"}
          /> */}
          <styles.ButtonText>fill the form &amp; save</styles.ButtonText>
        </form>
      </styles.Body>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  recruits: state.RecruitsReducer.recruits,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
  employeeId: state.UserReducer.employeeId,
});

const mapDispatchToProps = {
  fetchRecruits,
  updateRecruits,
  addEmployees,
  fetchEmployees,
  addRecruitsAssignment,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentChecklist);
