import React from "react";

import { Layout } from "../../../presentationals";
import { CampaignsAssetsController } from "../../../presentationals/screens/admin/CampaignsAssets/CampaignsAssetsController";

const Route = () => {
  return <Layout activity={<CampaignsAssetsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APCampaignsAssetsAdd = React.memo(Route);
