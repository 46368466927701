import React from "react";

import { Layout } from "../../../presentationals";
import { CatalogAdministrationsController } from "../../../presentationals/screens";

export const APCatalogAdministrationsAdd = () => {
  return (
    <Layout
      activity={<CatalogAdministrationsController type={"add"} />}
      admin={true}
      layout={"2"}
    />
  );
};
