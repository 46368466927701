import React, { useCallback, useEffect } from "react";

import { colors, strings } from "../../../../theme";
import { TableV2Props } from "../../../../types";
import { TableListSkeleton } from "../../atoms";

import { useTableV3 } from "./hooks/useTableV3";
import {
  TableContainer,
  TableHead,
  TableDataRow,
  Title,
  TableData,
  TableDataRowContainer,
  TableOuterContainer,
  TableWrapper,
  TableHeadRow,
  TableBottomEmptyContainer,
  TableHeadTitleContainer,
  TableHeadActionsContainer,
  EmptyList,
} from "./styles";

export const DashboardTable = ({
  data,
  loading,
  columns,
  horizontalScroll = false,
}: TableV2Props) => {
  const { parentRef, componentRef, handleHoverEnterHeader, handleHoverLeaveHeader } = useTableV3({
    columns,
    data,
  });

  const renderDataRow = useCallback(
    ({ column, columnIndex, dataItem, dataIndex }) => {
      const title = dataItem[column.key];
      const titleClasses = "data-title";
      if (column?.render) {
        return column?.render(dataItem);
      }
      return (
        <Title
          index={columnIndex}
          horizontalScroll={horizontalScroll}
          width={column.width}
          className={titleClasses}
        >
          {typeof title === "number" || typeof title === "boolean"
            ? String(title)
            : typeof title === "string" && title.trim() !== ""
            ? title
            : "-"}
        </Title>
      );
    },
    [horizontalScroll],
  );

  // Handling width of the table colums in case of only 2-3 columns are there.
  // Which can lead to empty space in the table UI
  useEffect(() => {
    const handleResize = () => {
      const parentScrollWidth = parentRef?.current?.scrollWidth;
      const parentClientWidth = parentRef?.current?.clientWidth;
      if (componentRef.current) {
        if (parentScrollWidth && parentClientWidth && parentScrollWidth > parentClientWidth) {
          componentRef.current.style.width = parentRef.current.scrollWidth + "px";
        } else {
          componentRef.current.style.width = "100%";
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [componentRef, parentRef]);

  useEffect(() => {
    const container = parentRef?.current;
    const hasVerticalScrollbar = container && container.scrollWidth > container.clientWidth;
    if (container) {
      container.style.borderBottom = hasVerticalScrollbar
        ? `0.3px solid ${colors.common.border}`
        : "none";
    }
  }, [parentRef]);

  return (
    <TableWrapper onClick={() => console.log("AA ::", data, columns)}>
      <TableOuterContainer ref={parentRef}>
        <TableContainer ref={componentRef}>
          <TableHead horizontalScroll={horizontalScroll}>
            {columns.map((column, columnIndex) => {
              return (
                <TableHeadRow
                  horizontalScroll={horizontalScroll}
                  id='tableHeaderHeadRowItem'
                  onMouseEnter={handleHoverEnterHeader}
                  onMouseLeave={handleHoverLeaveHeader}
                  width={column.width}
                  key={columnIndex}
                  center={column.key === "serial" ? true : column.center}
                  background={column.background}
                >
                  <TableHeadActionsContainer>
                    <TableHeadTitleContainer index={columnIndex}>
                      <Title width={column.width} horizontalScroll={horizontalScroll}>
                        {column.title}
                      </Title>
                    </TableHeadTitleContainer>
                  </TableHeadActionsContainer>
                </TableHeadRow>
              );
            })}
          </TableHead>
          {loading ? (
            <TableListSkeleton />
          ) : (
            <TableData horizontalScroll={horizontalScroll} emptyList={!data?.length}>
              {!data?.length && <EmptyList>{strings.common.noRecordsFound}</EmptyList>}
              {!!data?.length &&
                data?.map((dataItem, dataIndex) => {
                  return (
                    <TableDataRowContainer
                      horizontalScroll={horizontalScroll}
                      key={dataIndex}
                      data-index={dataIndex}
                    >
                      {columns.map((column, columnIndex) => {
                        const className = dataItem[column.className];
                        return (
                          <TableDataRow
                            horizontalScroll={horizontalScroll}
                            className={className}
                            id={`${dataIndex}`}
                            onMouseEnter={handleHoverEnterHeader}
                            onMouseLeave={handleHoverLeaveHeader}
                            width={column.width}
                            data-index={dataIndex}
                            key={columnIndex}
                            center={column.center}
                            background={column.background}
                          >
                            {renderDataRow({ dataIndex, column, columnIndex, dataItem })}
                          </TableDataRow>
                        );
                      })}
                    </TableDataRowContainer>
                  );
                })}
            </TableData>
          )}
          <TableBottomEmptyContainer />
        </TableContainer>
      </TableOuterContainer>
    </TableWrapper>
  );
};
