import React from "react";

import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../../../redux/hooks";
import { generateArrayAttributes } from "../../../../../services/methods";
import { strings } from "../../../../../theme";
import { FiltersType } from "../../../../../types";
import {
  ActiveSelectOptions,
  DashboardFilterTableOptions,
  StringFiltersFields,
  attributesFiltersFields,
} from "../../../../../utils/constants";
import { Header, TableHeader } from "../../../../components";
import { TextInput, Select, Button } from "../../../../components/atoms";
import { useController } from "../hooks";

import {
  Container,
  LeftContainer,
  RightContainer,
  StepContainer,
  FormTitle,
  Row,
  FormFieldsContainer,
} from "./styles";

export const FiltersController = ({ controllerType }: { controllerType?: "add" | "edit" }) => {
  const params = useParams();
  const steps = ["Overview", "Configuration"];
  // const steps = ["Overview", "Configuration", "System Data"];
  const {
    currentStepIndex,
    goTo,
    formData,
    onTextChange,
    saveDisabled,
    onSubmitAdd,
    onSubmitUpdate,
    loading,
    getTableFilters,
    addFilter,
    removeFilter,
    onChangeInputFilter,
    onChangeSelectFilter,
    getOperators,
    getTitle
  } = useController(steps, controllerType, params.id);
  const { title, description, active, table, filters } = formData;

  const renderOverview = () => {
    return (
      <>
        <Row>
          <TextInput
            label={"Title"}
            type={"text"}
            width='48%'
            inputProps={{
              name: "title",
              value: title.value,
              autoComplete: "off",
              onChange: onTextChange,
              required: title.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width={"48%"}
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            mandatory={active.required}
            error={active.error}
          />
        </Row>

        <TextInput
          label={"Description"}
          type={"textarea"}
          width='100%'
          textAreaProps={{
            name: "description",
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: description.required,
            rows: 12,
          }}
          inputProps={{}}
        />
      </>
    );
  };

  const renderValueField = (item: FiltersType, index: number) => {
    if (attributesFiltersFields.includes(item.field)) {
      return (
        <Select
          width={"28%"}
          name={"value"}
          label={"Value"}
          options={generateArrayAttributes(item.field).map((value) => ({
            label: value.value,
            value: value._id,
          }))}
          value={item.value}
          attributes={{
            "data-index": index,
          }}
          onChange={onChangeSelectFilter}
        />
      );
    }
    switch (item.field) {
      case "name":
      case "email":
        return (
          <TextInput
            label={"Value"}
            type={"text"}
            width='28%'
            inputProps={{
              name: "value",
              value: item.value,
              autoComplete: "off",
              "data-index": index,
              onChange: onChangeInputFilter,
              required: description.required,
            }}
          />
        );
      case "active":
        return (
          <Select
            width={"28%"}
            name={"value"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={!!item.value}
            attributes={{
              "data-index": index,
            }}
            onChange={onChangeSelectFilter}
          // mandatory={fields.required}
          // error={fields.error}
          />
        );
      default:
        return <></>;
    }
  };

  const renderConfiguration = () => {
    return (
      <>
        <Row>
          <Select
            width={"100%"}
            name={"table"}
            label={"Table"}
            options={DashboardFilterTableOptions}
            value={table.value}
            onChange={onTextChange}
            mandatory={table.required}
            error={table.error}
          />
        </Row>
        <FormTitle className='second-header'>{strings.common.filter}</FormTitle>
        {filters.value.map((item, index) => {
          return (
            <Row key={index} className='filter-row'>
              <Select
                width={"28%"}
                name={"field"}
                label={"Field"}
                options={getTableFilters}
                value={item.field}
                attributes={{
                  "data-index": index,
                }}
                onChange={onChangeSelectFilter}
              // mandatory={fields.required}
              // error={fields.error}
              />
              {getOperators(index) && (
                <Select
                  width={"28%"}
                  name={"operator"}
                  label={"Operator"}
                  options={getOperators(index)}
                  value={item.operator}
                  attributes={{
                    "data-index": index,
                  }}
                  onChange={onChangeSelectFilter}
                // mandatory={fields.required}
                // error={fields.error}
                />
              )}
              {renderValueField(item, index)}

              <Button
                title='Remove'
                attributes={{
                  "data-index": index,
                }}
                onClick={removeFilter}
              />
            </Row>
          );
        })}
        <Button
          title='Add Filter'
          className='add-filter'
          disabled={formData.filters.value.length >= 3}
          onClick={addFilter}
        />
      </>
    );
  };

  const renderFormFields = () => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderConfiguration();
      default:
        return <></>;
    }
  };
  return (
    <>
      <Header title={getTitle} />
      <TableHeader
        backButtonTitle={strings.common.back}
        rightButton={strings.common.save}
        rightButtonBorder='0.3px'
        rightButtonDisabled={saveDisabled || loading}
        rightButtonPress={controllerType === "add" ? onSubmitAdd : onSubmitUpdate}
      />
      <Container loading={loading}>
        <LeftContainer>
          <FormTitle>{steps[currentStepIndex]}</FormTitle>
          <FormFieldsContainer>{renderFormFields()}</FormFieldsContainer>
        </LeftContainer>
        <RightContainer>
          {steps.map((stepItem, stepIndex) => {
            return (
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {stepItem}
                <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
              </StepContainer>
            );
          })}
        </RightContainer>
      </Container>
    </>
  );
};
