import styled from "styled-components";

import { colors, fs } from "../../../../../theme";

interface Props {
  active?: boolean;
  noBorder?: boolean;
  loading?: boolean;
}

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  background-color: ${colors.navigationPanel.background};
`;

export const ViewContainer = styled.div`
  width: 100%;
`;
export const Header = styled.div`
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1vh;
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Column = styled.div`
  flex: 1;
`;

export const Container = styled.div<Props>`
  display: flex;
  height: 630px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.navigationPanel.background};
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  overflow: hidden;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 20px 50px 55px;
  width: 80%;
`;

export const FormFieldsContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
  width: 98%;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  border-width: 0px 0px 0px 0.6px;
  padding-top: 14px;
  border-style: solid;
  border-color: ${colors.common.border};
  flex-direction: column;
`;

export const StepContainer = styled.div<Props>`
  border-bottom: ${(props) => (props.noBorder ? "0" : "0.6px")} solid ${colors.common.black};
  height: 35px;
  color: ${colors.common.black};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  font-size: ${fs.s};
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  transition: 0.1s linear;
  position: relative;
  background-color: ${(props) => (props.active ? colors.common.secondaryWhite : "transparent")};
  z-index: 1;
  &:hover {
    background-color: ${colors.common.secondaryWhite};
    border: none;
  }
  .active {
    z-index: -1;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(-135deg) translate(-25%);
    background-color: ${colors.common.secondaryWhite};
    left: -17px;
    position: absolute;
    top: 0;
  }
`;

export const Heading = styled.div`
  font-weight: "600";
  font-size: 16px;
  line-height: 22px;
`;

export const DisplayImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 216px;
  width: 216px;
  box-sizing: border-box;
  border: 1px solid #65c08e;
  border-radius: 50%;
  position: relative;
  margin: auto;
  margin-bottom: 5%;
`;

export const DisplayPicture = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  align-self: center;
  margin: auto;
`;

export const DisplayPictureEdit = styled.img`
  height: 36px;
  width: 36px;
  margin-top: 25px;
  position: absolute;
  bottom: -20px;
`;

export const HorizontalLine = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0px;
  border: 0.6px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
`;

export const SubHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const SubHeading = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
`;

export const SmallHorizontalLine = styled.div`
  height: 1px;
  background-color: ${colors.common.black};
  flex-grow: 1;
  margin-left: 8px;
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-top: 25px;
  grid-gap: 40px;
`;

export const TableItem = styled.div`
  background: linear-gradient(to right, #09c6f9, #045de9);
  border-radius: 5px;
  font-size: 30px;
  height: 165px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
`;

export const ActivePackageTick = styled.img`
  margin-top: 20px;
  height: 33px;
  width: 33px;
`;

export const TableBGGraphic = styled.img`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
`;

export const TableItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${colors.common.white};
  margin-left: 5px;
`;

export const TableItemDesc = styled.div`
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  color: ${colors.common.white};
`;

export const TempSwitch = styled.img`
  height: 18px;
  width: 36px;
  object-fit: contain;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
