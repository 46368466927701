import React from "react";

import { AcademyBatchList as AcademyBatchListType } from "../../redux/AcademyBatch/types";
import { MomentDate, sortTableListColumns } from "../../services/methods";
import { ColumnsProps, ColumnsType } from "../../types";

export const getAcademyBatchColumns = ({ fields }: ColumnsProps) => {
  const tableColumns = {
    batchNumber: {
      title: "Batch Number",
      searchSupported: true,
      sortSupported: true,
      key: "batchNumber",
    },
    courseOfferings: {
      title: "Course",
      key: "courseOfferings",
      sortSupported: true,
      render: (item: AcademyBatchListType) => {
        const course = item?.courseOfferings;
        let title = "";
        if (typeof course !== "string" && course?.name) {
          title = course?.name;
        }
        return <text className='title'>{title || "-"}</text>;
      },
    },
    state: {
      title: "State",
      key: "state",
      sortSupported: true,
      render: (item: AcademyBatchListType) => {
        const state = item?.state;
        let title = "";
        if (typeof state !== "string" && state?.value) {
          title = state?.value;
        }
        return <text className='title'>{title || "-"}</text>;
      },
    },
    trainingCordinator: {
      title: "Training Cordinator",
      sortSupported: true,
      key: "trainingCordinator",
      render: (item: AcademyBatchListType) => {
        const trainingCordinator = item?.trainingCordinator;
        let title = "";
        if (typeof trainingCordinator !== "string" && trainingCordinator?.name) {
          title = trainingCordinator?.name;
        }
        return <text className='title'>{title || "-"}</text>;
      },
    },
    totalStudents: {
      title: "Total Students",
      sortSupported: true,
      key: "totalStudents",
    },
    plannedDuration: {
      title: "Planned Duration",
      sortSupported: true,
      key: "plannedDuration",
    },
    scheduleTimeHealth: {
      title: "Schedule Time Health",
      key: "scheduleTimeHealth",
      sortSupported: true,
      render: (item: AcademyBatchListType) => {
        const scheduleTimeHealth = item?.scheduleTimeHealth;
        let title = "";
        if (typeof scheduleTimeHealth !== "string" && scheduleTimeHealth?.value) {
          title = scheduleTimeHealth?.value;
        }
        return <text className='title'>{title || "-"}</text>;
      },
    },
    overallHealth: {
      title: "Overall Health",
      key: "overallHealth",
      sortSupported: true,
      render: (item: AcademyBatchListType) => {
        const overallHealth = item?.overallHealth;
        let title = "";
        if (typeof overallHealth !== "string" && overallHealth?.value) {
          title = overallHealth?.value;
        }
        return <text className='title'>{title || "-"}</text>;
      },
    },
    dailyHoursEffort: {
      title: "Daily Hours Effort",
      sortSupported: true,
      key: "dailyHoursEffort",
    },
    actualDuration: {
      title: "Actual Duration",
      sortSupported: true,
      key: "actualDuration",
    },
    plannedStartDate: {
      title: "Planned Start Date",
      sortSupported: true,
      key: "createdAt",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.plannedStartDate,
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    actualStartDate: {
      title: "Actual Start Date",
      sortSupported: true,
      key: "actualStartDate",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.actualStartDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    plannedEndDate: {
      title: "Planned End Date",
      sortSupported: true,
      key: "plannedEndDate",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.plannedEndDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    actualEndDate: {
      title: "Actual End Date",
      sortSupported: true,
      key: "actualEndDate",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.actualEndDate || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    shortDescription: {
      title: "Short Description",
      searchSupported: true,
      sortSupported: true,
      key: "shortDescription",
    },
    description: {
      title: "Description",
      searchSupported: true,
      sortSupported: true,
      key: "description",
    },
    createdAt: {
      title: "Created On",
      sortSupported: true,
      key: "createdAt",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.createdAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    createdBy: {
      title: "Created By",
      key: "createdBy",
      render: (item) => {
        return <text className='title'>{item?.createdBy?.name || "Admin"}</text>;
      },
    },
    updatedAt: {
      title: "Updated On",
      sortSupported: true,
      key: "updatedAt",
      render: (item: AcademyBatchListType) => {
        return (
          <text className='title'>
            {MomentDate({
              date: item.updatedAt || "2023-01-01T08:54:36.357Z",
              format: "YYYY-MM-DD HH:mm",
            })}
          </text>
        );
      },
    },
    updatedBy: {
      title: "Updated By",
      key: "updatedBy",
      render: (item) => {
        return <text className='title'>{item?.updatedBy?.name || "Admin"}</text>;
      },
    },
  };

  const selectedColumns: ColumnsType[] = [];
  fields?.forEach((item) => {
    const columnItem = tableColumns?.[item];
    if (columnItem) {
      selectedColumns.push(columnItem);
    }
  });
  const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
  return sortedColumns;
};
