/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_BATCH = "FETCH_ACADEMY_BATCH",
  FETCH_ACADEMY_BATCH_BY_ID = "FETCH_ACADEMY_BATCH_BY_ID",
  SUCCESS_FETCH_ACADEMY_BATCH = "SUCCESS_FETCH_ACADEMY_BATCH",
  ERROR_FETCH_ACADEMY_BATCH = "ERROR_FETCH_ACADEMY_BATCH",
  CREATE_ACADEMY_BATCH = "CREATE_ACADEMY_BATCH",
  UPDATE_ACADEMY_BATCH = "UPDATE_ACADEMY_BATCH",
  DELETE_ACADEMY_BATCH = "DELETE_ACADEMY_BATCH",
}

export default Types;
