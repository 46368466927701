import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  AcademyBatchList,
  AddAcademyBatch,
  UpdateAcademyBatch,
  FindAcademyCourseById,
  FindAcademyBatchById,
  DeleteAcademyBatch,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchAcademyBatchListSuccess, fetchAcademyBatchError } from "./actions";
import Types from "./actionType";
import {
  CreateAcademyBatchuccess,
  CreateAcademyBatch as CreateAcademyBatchType,
  AcademyBatchSuccess,
  UpdateAcademyBatch as UpdateAcademyBatchType,
  FetchAcademyBatch,
  FetchAcademyBatchById,
  AcademyBatchByIdSuccess,
  DeleteAcademyBatch as DeleteAcademyBatchType,
} from "./types";

function* createWorker(action: CreateAcademyBatchType) {
  try {
    const options = {
      method: "POST",
      url: AddAcademyBatch,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyBatchuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* deleteWorker(action: DeleteAcademyBatchType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteAcademyBatch(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateAcademyBatchuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateAcademyBatchType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateAcademyBatch(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateAcademyBatchuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

function* fetchAllWorker(action: FetchAcademyBatch) {
  console.log("A ::");
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: AcademyBatchList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<AcademyBatchSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      if (params.exportType !== "all") {
        yield put(fetchAcademyBatchListSuccess(data.data));
      }
    }
  } catch (e) {
    console.log("e", e);
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchAcademyBatchError(error.response?.data.message));
  }
}

function* fetchAcademyBatchById(action: FetchAcademyBatchById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindAcademyBatchById(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<AcademyBatchByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message?.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_ACADEMY_BATCH, createWorker);
  yield takeLatest(Types.UPDATE_ACADEMY_BATCH, updateWorker);
  yield takeLatest(Types.DELETE_ACADEMY_BATCH, deleteWorker);
  yield takeEvery(Types.FETCH_ACADEMY_BATCH, fetchAllWorker);
  yield takeEvery(Types.FETCH_ACADEMY_BATCH_BY_ID, fetchAcademyBatchById);
}
