/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_STUDENT_PROFILE = "FETCH_ACADEMY_STUDENT_PROFILE",
  FETCH_ACADEMY_STUDENT_PROFILE_BY_ID = "FETCH_ACADEMY_STUDENT_PROFILE_BY_ID",
  SUCCESS_FETCH_ACADEMY_STUDENT_PROFILE = "SUCCESS_FETCH_ACADEMY_STUDENT_PROFILE",
  ERROR_FETCH_ACADEMY_STUDENT_PROFILE = "ERROR_FETCH_ACADEMY_STUDENT_PROFILE",
  CREATE_ACADEMY_STUDENT_PROFILE = "CREATE_ACADEMY_STUDENT_PROFILE",
  UPDATE_ACADEMY_STUDENT_PROFILE = "UPDATE_ACADEMY_STUDENT_PROFILE",
  DELETE_ACADEMY_STUDENT_PROFILE = "DELETE_ACADEMY_STUDENT_PROFILE",
}

export default Types;
