import styled from "styled-components";

import { colors, fonts, fs } from "../../../theme";

interface Props {
  width?: string;
  center?: boolean;
  horizontalScroll?: boolean;
}

export const TableContainer = styled.div<Props>`
  display: flex;
  width: 100%;
  /* overflow-x: ${(props) => (props.horizontalScroll ? "scroll" : "hidden")};
  overflow-y: hidden; */
  /* background-color: green; */
  flex-direction: column;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    display: flex;
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
  .data-row {
    display: flex;
    flex-direction: row;
  }
`;

export const TableHead = styled.div`
  display: flex;
  background-color: ${colors.table.headerBackground};
  border: 0.3px solid ${colors.common.border};
  width: 100%;
  height: 56px;
`;

export const TableRow = styled.div<Props>`
  height: 100%;
  min-height: 41px;
  display: flex;
  width: ${(props) => props.width};
  background-color: ${(props) => props.background};
  align-items: center;
  text-align: ${(props) => (props.center ? "center" : "left")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  .title {
    padding: 0 20px;
    align: left;
    font-size: ${fs.m};
    font-weight: 400;
    color: ${colors.common.lightTextColor};
    line-height: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .table-header-title {
    width: 100%;
  }
`;

export const Title = styled.h2`
  align: left;
  padding: 0 20px;
  font-size: ${fs.m};
  color: ${colors.common.black};
  font-weight: 600;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const TableData = styled.div`
  display: flex;
  background-color: ${colors.table.dataBackground};
  width: 100%;
  flex-direction: column;
  border-left: 0.6px solid rgba(0, 0, 0, 0.7);
  border-right: 0.6px solid rgba(0, 0, 0, 0.7);
  .data-row {
    border-bottom: 0.6px solid rgba(0, 0, 0, 0.7);
    padding: 0;
    cursor: pointer;
    transition: linear 0.4s;
    &:hover {
      background-color: ${colors.common.hoverTabBackground};
    }
  }
  .data-title {
    font-weight: 400;
    color: ${colors.common.lightTextColor};
  }
`;

export const EmptyList = styled.div`
  height: 64px;
  width: 100%;
  border-bottom: 0.3px solid ${colors.common.border};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const RefreshButton = styled.div`
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  background: ${colors.common.white};
  border: 0.6px solid ${colors.common.borderColor};
  border-radius: 10px;
`;
