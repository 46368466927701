import Types from "./actionType";
import {
  FetchAcademyCandidateEnrollmentsPayload,
  CreateAcademyCandidateEnrollments,
  CreateAcademyCandidateEnrollmentsPayload,
  AcademyCandidateEnrollmentsList,
  FetchAcademyCandidateEnrollments,
  FetchAcademyCandidateEnrollmentsError,
  FetchAcademyCandidateEnrollmentsSuccess,
  UpdateAcademyCandidateEnrollments,
  UpdateAcademyCandidateEnrollmentsPayload,
  AcademyCandidateEnrollmentsListWithMetaData,
  FetchAcademyCandidateEnrollmentsByIdPayload,
  FetchAcademyCandidateEnrollmentsById,
  DeleteAcademyCandidateEnrollmentsPayload,
  DeleteAcademyCandidateEnrollments,
} from "./types";

export const fetchAcademyCandidateEnrollmentsList = ({
  params,
  onSuccess,
  onError,
}: FetchAcademyCandidateEnrollmentsPayload): FetchAcademyCandidateEnrollments => ({
  type: Types.FETCH_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidateEnrollmentsById = ({
  id,
  onSuccess,
  onError,
}: FetchAcademyCandidateEnrollmentsByIdPayload): FetchAcademyCandidateEnrollmentsById => ({
  type: Types.FETCH_ACADEMY_CANDIDATE_ENROLLMENT_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchAcademyCandidateEnrollmentsListSuccess = (
  data: AcademyCandidateEnrollmentsListWithMetaData,
): FetchAcademyCandidateEnrollmentsSuccess => ({
  type: Types.SUCCESS_FETCH_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: {
    data,
  },
});

export const fetchAcademyCandidateEnrollmentsError = (
  error?: string,
): FetchAcademyCandidateEnrollmentsError => ({
  type: Types.ERROR_FETCH_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: { error },
});

export const createAcademyCandidateEnrollments = ({
  data,
  onSuccess,
  onError,
}: CreateAcademyCandidateEnrollmentsPayload): CreateAcademyCandidateEnrollments => ({
  type: Types.CREATE_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateAcademyCandidateEnrollments = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateAcademyCandidateEnrollmentsPayload): UpdateAcademyCandidateEnrollments => ({
  type: Types.UPDATE_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const addAcademyPaymentDetails = ({ data, onSuccess, onError }) => ({
  type: Types.ACADEMY_PAYMENT_DETAILS_ADD,
  payload: {
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const updateAcademyPaymentDetail = ({ id, data, onSuccess, onError }) => ({
  type: Types.ACADEMY_PAYMENT_DETAILS_UPDATE,
  payload: {
    id: id,
    data: data,
    onSuccess: onSuccess,
    onError: onError,
  },
});

export const deleteAcademyCandidateEnrollments = ({
  id,
  onSuccess,
  onError,
}: DeleteAcademyCandidateEnrollmentsPayload): DeleteAcademyCandidateEnrollments => ({
  type: Types.DELETE_ACADEMY_CANDIDATE_ENROLLMENT,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
