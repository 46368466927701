import styled from "styled-components";

import { fonts } from "../../services/theme";

const styles = {
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  `,
  Column: styled.div`
    flex: 1;
    justify-content: center;
    text-align: center;
    background-color: #eee;
    border-radius: 10px;
    font-family: ${fonts.font1};
    font-size: 11px;
    padding: 5px;
  `,
};

export default styles;
