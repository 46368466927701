import Types from "./actionType";
import { SPSSalesProfileActions, SPSSalesProfileState } from "./types";

const initialState: SPSSalesProfileState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const SPSSalesProfileReducer = (state = initialState, action: SPSSalesProfileActions) => {
  switch (action.type) {
    case Types.FETCH_SPS_SALES_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_SPS_SALES_PROFILE:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
