import React, { useEffect, useMemo, memo } from "react";

import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, sortTableListColumns } from "../../../../services/methods";
import { strings } from "../../../../theme";
import { ColumnsType } from "../../../../types";
import { JobsPostingListProps } from "../../../../types/models/JobsPosting";
import { tableListDefaultSelectFields, tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useJobsPostingList } from "./hooks";

export const JobsPostingList = memo(({ relatedList }: JobsPostingListProps) => {
  const JobsPosting = useAppSelector((state) => state.JobsPostingReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const tableListConfigs = useAppSelector(
    (state) => state.UserReducer.tableConfiguration,
  )?.jobsPosting;

  const { list, metaData } = JobsPosting;
  const {
    fetchJobsPosting,
    loading,
    handlePageChange,
    onClickJobsPosting,
    onClickAdd,
    onSearch,
    onSubmitDeleteRecord,
    handleClearSearch,
    search,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    jobsPostingFilterFields,
    exportSelectedRecords,
    exportAllRecords,
  } = useJobsPostingList({
    relatedList,
  });

  const columns: ColumnsType[] = useMemo(() => {
    const tableColumns = {
      title: {
        title: "Title",
        sortSupported: true,
        searchSupported: true,
        key: "title",
      },
      summary: {
        title: "Summary",
        sortSupported: true,
        key: "summary",
      },
      salary: {
        title: "Salary",
        sortSupported: true,
        key: "salary",
      },
      type: {
        title: "Type",
        sortSupported: true,
        key: "type",
      },
      location: {
        title: "Location",
        sortSupported: true,
        key: "location",
      },
      sector: {
        title: "Sector",
        sortSupported: true,
        key: "sector",
      },
      contactName: {
        title: "Contact Name",
        sortSupported: true,
        key: "contactName",
      },
      contactNumber: {
        title: "Contact Number",
        sortSupported: true,
        key: "contactNumber",
      },
      contactEmail: {
        title: "Contact Email",
        sortSupported: true,
        key: "contactEmail",
      },
      skills: {
        title: "Skills",
        sortSupported: true,
        key: "skills",
      },
      referenceId: {
        title: "Reference Id",
        sortSupported: true,
        key: "referenceId",
      },
      customDate: {
        title: "Custom Date",
        sortSupported: true,
        key: "customDate",
        render: (item) => {
          return (
            <text className='title'>
              {MomentDate({
                date: item.customDate || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </text>
          );
        },
      },
      active: {
        title: "Active",
        sortSupported: true,
        key: "active",
        center: true,
        render: (item) => {
          return <text className='title'>{item.active ? "Yes" : "No"}</text>;
        },
      },
      createdAt: {
        title: "Created On",
        sortSupported: true,
        key: "createdAt",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.createdAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </div>
          );
        },
      },
      createdBy: {
        title: "Created By",
        key: "createdBy",
        render: (item) => {
          return <div className='title'>{item?.createdBy?.name || "Admin"}</div>;
        },
      },
      updatedAt: {
        title: "Updated On",
        sortSupported: true,
        key: "updatedAt",
        render: (item) => {
          return (
            <div className='title'>
              {MomentDate({
                date: item.updatedAt || "2023-01-01T08:54:36.357Z",
                format: "YYYY-MM-DD HH:mm",
              })}
            </div>
          );
        },
      },
      updatedBy: {
        title: "Updated By",
        key: "updatedBy",
        render: (item) => {
          return <div className='title'>{item?.updatedBy?.name || "Admin"}</div>;
        },
      },
    };

    const selectedColumns: ColumnsType[] = [];
    const fields: string[] = tableListConfigs?.jobsPosting?.fields?.length
      ? tableListConfigs?.jobsPosting?.fields
      : tableListDefaultSelectFields.jobsPosting;
    fields?.forEach((item) => {
      const columnItem = tableColumns?.[item];
      if (columnItem) {
        selectedColumns.push(columnItem);
      }
    });
    const sortedColumns: ColumnsType[] = sortTableListColumns(fields, selectedColumns);
    return sortedColumns;
  }, [tableListConfigs]);

  useEffect(() => {
    fetchJobsPosting({});
    // Do not include fetchJobsPosting to avoid multiple rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      advancedList={!(relatedList?.type && relatedList.value)}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.jobsPosting}
      handleTableConfigs={handleTableConfigs}
      filters={jobsPostingFilterFields}
      selectedConfigFields={
        tableListConfigs?.fields?.length
          ? tableListConfigs?.fields
          : tableListDefaultSelectFields.jobsPosting
      }
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      rightButton={strings.common.add}
      rightButtonPress={onClickAdd}
      onClickTableRow={onClickJobsPosting}
      headerTitle={strings.headerTitle.jobsPosting}
      list={list}
      columns={columns}
      metaData={metaData}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.jobs_delete}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apJobsPosting.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
});
