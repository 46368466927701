import Types from "./actionType";
import { AcademyCoursesActions, AcademyCoursesState } from "./types";

const initialState: AcademyCoursesState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
};

export const AcademyCoursesReducer = (state = initialState, action: AcademyCoursesActions) => {
  switch (action.type) {
    case Types.SUCCESS_FETCH_ACADEMY_COURSES:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
