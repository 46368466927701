import React, { useEffect, useState, useCallback, useMemo } from "react";

import { GrAttachment } from "react-icons/gr";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Document from "../../components/Document/Document";
import Input from "../../components/Input/input";
import ModalComponent from "../../components/Modal/Modal";
import ToolTip from "../../components/Tooltip/Tooltip";
import { useControllerConfigs } from "../../presentationals/common";
import { uploadFile } from "../../redux/application/actions";
import {
  addPayrollData,
  fetchPayrollData,
  payrollsRehydrate,
  updatePayrollData,
} from "../../redux/employees/actions";
import {
  AttributesValues,
  currencyFormat,
  getAttributValuesBynameId,
  useTitle,
} from "../../services/methods";
import PayrollDetailsController from "../PayrollsDetailsController/PayrollsDetailsController";

import styles from "./PayrollsController.styles";

const PayrollsController = (props) => {
  const navigate = useNavigate();
  const { currencyOptions } = useControllerConfigs();
  const handleOnClick = useCallback(
    (id, employee) => {
      navigate(`/ap/payrolls/${id}/edit/${employee}/details`, {
        replace: true,
      });
    },
    [navigate],
  );
  const [id, setId] = useState();
  const [workingDays, setWorkingDays] = useState(0);
  const [payrollData, setPayrollData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [epInfo, setEPInfo] = useState();
  const [hrInfo, sethrInfo] = useState();
  const [epDesignation, setEPDesignation] = useState();
  const [currentEmployee, setCurrentEmployee] = useState();
  const [tab, setTab] = useState("0");
  const [attachments, setAttachments] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  // const match = useMatch();
  // const navigate = useNavigate();

  useEffect(() => {
    setCurrentEmployee(params.employee);
    if (
      (props.payrolls.length > 0 && (params.id !== id || params.employee !== currentEmployee)) ||
      props.rehydrate === true
    ) {
      Rehydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payrolls, params.id, props.rehydrate, params.employee]);

  const Rehydrate = () => {
    const find = props.payrolls.find((payroll) => payroll._id === params.id);
    if (find) {
      setId(find._id);
      employeeCalculatorRehydrate(find.list);
      setWorkingDays(find.workingdays);
      payrollsRehydrate(false);
    }
  };

  useTitle(`Payrolls Editing`);

  const employeeCalculatorRehydrate = (list) => {
    const find = list?.find((ep) => ep?.employee?._id == params.employee);
    const currentDesignation = find?.employee?.designations?.map((item) => {
      if (item?.currentSalary) {
        return item?.designation?.name;
      }
    });
    const object = {
      serial: 1,
      employee: find.employee._id,
      employmentType: find?.employee?.employmentType,
      employmentCompany: find?.employee?.employmentCompany,
      currentDesignation: currentDesignation,
      status: find.status,
      name: find.employee._id,
      salaryCurrencyDisplay: find.salaryCurrency || "USD",
      salaryCurrency: find.salaryCurrency,
      workingdays: find.workingdays || "0",
      workinghours: find.workinghours || "0",
      overtimeChoice: find.overtimeChoice || "0",
      overtimeHours: find.overtimeHours || "0",
      salaryBase: find.salaryBase || "0",
      salaryBonus: find.salaryBonus || "0",
      salarySemiAnnualBonus: find.salarySemiAnnualBonus || "0",
      salaryAnnualBonus: find.salaryAnnualBonus || "0",
      salaryOvertime: find.salaryOvertime || "0",
      salaryReimbursements: find.salaryReimbursements || "0",
      salaryReferrals: find.salaryReferrals || "0",
      salaryAdjustments: find.salaryAdjustments || "0",
      salaryTax: find.salaryTax || "0",
      salaryPayable: find.salaryPayable || "0",
      feedback: find.feedback || "",
      acceptedAt: find.acceptedAt,
      attachments: find.attachments,
    };
    if (object.salaryPayable === "NaN") {
      object.salaryPayable = parseFloat(object.salaryBase) + parseFloat(object.salaryBonus);
    }
    const employeeInfo = GetEmployeeInfo(object.name);
    const HRInfo = GetEmployeeInfo(employeeInfo?.reportingHR);
    console.log(employeeInfo, params);
    const designation = employeeInfo?.designations?.slice(-1);
    setEPInfo(employeeInfo);
    sethrInfo(HRInfo);
    setEPDesignation(designation);
    if (Array.isArray(find.attachments)) {
      setAttachments(find.attachments);
    }
    setPayrollData(object);
  };

  const GetEmployeeInfo = (id) => {
    return props.employees.find((employee) => employee._id === id);
  };

  const onChangeText = (event) => {
    const name = event.target.name;
    const newArray = { ...payrollData };
    newArray[name] = event.target.value;
    setPayrollData(newArray);
  };

  const InputText = (name) => {
    return (
      <input
        readOnly={
          ["published", "accepted", "taxed"].includes(payrollData.status) ||
          !props.permissions.payrolls_edit
        }
        onChange={(event) => {
          const newObject = { ...payrollData };
          newObject[name] = event.target.value;
          newObject.salaryPayable = payableCalculation(newObject);
          setPayrollData(newObject);
        }}
        style={{ textAlign: "center" }}
        value={payrollData[name]}
        size={7}
      />
    );
  };

  const SelectText = (name, index, options) => {
    return (
      <select
        disabled={
          ["published", "accepted", "taxed"].includes(payrollData.status) ||
          !props.permissions.payrolls_edit
        }
        onChange={(event) => {
          const newArray = payrollData;
          newArray[name] = event.target.value;
          newArray.salaryPayable = payableCalculation(newArray);
          setPayrollData(newArray);
        }}
        value={payrollData.name}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    );
  };

  const payableCalculation = (payroll) => {
    const salaryBase = parseFloat(payroll.salaryBase) || 0;
    const salaryBonus = parseFloat(payroll.salaryBonus) || 0;
    const salarySemiAnnualBonus = parseFloat(payroll.salarySemiAnnualBonus) || 0;
    const salaryAnnualBonus = parseFloat(payroll.salaryAnnualBonus) || 0;
    const salaryOvertime = parseFloat(payroll.salaryOvertime) || 0;
    const salaryReimbursements = parseFloat(payroll.salaryReimbursements) || 0;
    const salaryReferrals = parseFloat(payroll.salaryReferrals) || 0;
    const salaryAdjustments = parseFloat(payroll.salaryAdjustments) || 0;
    const salaryTax = parseFloat(payroll.salaryTax) || 0;

    const deductibleSalary = salaryBase + salaryBonus;
    const sum =
      salaryBase +
      salaryBonus +
      salarySemiAnnualBonus +
      salaryAnnualBonus +
      salaryOvertime +
      salaryReimbursements +
      salaryReferrals +
      salaryAdjustments;
    const perDaySalary = deductibleSalary / workingDays;

    const nonWorkingDays = parseFloat(workingDays) - parseFloat(payroll.workingdays);
    const deducatedSalary = perDaySalary * nonWorkingDays;
    return sum - deducatedSalary;
  };

  const onSubmitDraft = () => {
    setSubmitting(true);
    const newObject = { ...payrollData };
    newObject["attachments"] = attachments.map((item) => ({
      name: item.name,
      id: item.id,
    }));
    console.log(newObject);
    props.updatePayrollData({
      id: params.id,
      data: {
        list: [newObject],
      },
      onSuccess: (data) => {
        props.fetchPayrollData({
          onSuccess: (data) => {
            props.payrollsRehydrate(true);
          },
          onError: (err) => {},
        });
        setSubmitting(false);
      },
      onError: (error) => {
        setSubmitting(false);
      },
    });
  };

  const onSubmitPublish = (e) => {
    // var employee = e.target.getAttribute("data-employee");
    const array = payrollData;
    array.status = "published";
    setPayrollData(array);
  };

  const capitalFirstLetter = (str = "") => {
    if (str.length > 0) {
      return str[0].toUpperCase() + str.substring(1);
    }
    return str;
  };
  const Tabs = () => {
    const tabs = [
      {
        tab: "0",
        label: "Payroll",
      },
      {
        tab: "1",
        label: "Status",
      },
    ];
    return tabs;
  };
  const updateTab = (e) => {
    setTab(e.currentTarget.getAttribute("data-key"));
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    // var name = e.target.name;
    const index = e.currentTarget.getAttribute("data-index");
    const newArray = [...attachments];
    if (!newArray[index]["name"]) {
      const fileName = file?.name?.split(".");
      fileName?.pop();
      newArray[index]["name"] = fileName?.join(".");
      setAttachments(newArray);
    }
    props.uploadFile({
      file: file,
      onSuccess: (data) => {
        const newArray = [...attachments];
        newArray[index]["id"] = data.id;
        newArray[index]["error"] = "";
        setAttachments(newArray);

        const newObject = { ...payrollData };
        newObject["attachments"] = newArray.map((item) => ({
          name: item.name,
          id: item.id,
        }));

        setPayrollData(newObject);
      },
      onError: (err) => {
        const newArray = [...attachments];
        newArray[index]["error"] = err;
        setAttachments(newArray);
      },
      onProgress: (progress) => {
        const newArray = [...attachments];
        newArray[index]["error"] = `Upload in progress (${progress}%)`;
        setAttachments(newArray);
      },
    });
  };
  const addAttachment = () => {
    const newArray = [...attachments];
    newArray.push({ name: "", id: "" });
    setAttachments(newArray);
  };

  const removeAttachment = (index) => {
    const newArray = [...attachments];
    newArray.splice(index, 1);
    setAttachments(newArray);
  };

  const attachmentTextChange = (e) => {
    const newArray = [...attachments];
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const index = e.currentTarget.getAttribute("data-index");
    newArray[index][name] = value;
    setAttachments(newArray);
  };
  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
  };

  const salaryCurrency = useMemo(() => {
    let currency = currencyOptions.find((item) => item.value === payrollData.salaryCurrency)?.label;
    if (!currency) {
      currency = AttributesValues("currency", payrollData.salaryCurrency);
    }
    return currency;
  }, [currencyOptions, payrollData.salaryCurrency]);

  const salaryCurrencyDisplay = useMemo(() => {
    let currency = currencyOptions.find(
      (item) => item.value === payrollData.salaryCurrencyDisplay,
    )?.label;
    if (!currency) {
      currency = AttributesValues("currency", payrollData.salaryCurrencyDisplay);
    }
    return currency;
  }, [currencyOptions, payrollData.salaryCurrencyDisplay]);

  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>Payrolls information</h2>
      </styles.Header>
      <styles.TabContainer>
        {Tabs().map((item) => (
          <styles.TabItem
            key={item.tab}
            onClick={updateTab}
            data-key={item.tab}
            data-tab={item.tab}
            active={tab === item.tab}
            // error={item.fields ? CheckForTabError(item.fields) : false}
          >
            {" "}
            {item.label}
          </styles.TabItem>
        ))}
      </styles.TabContainer>
      {tab === "1" ? (
        <PayrollDetailsController />
      ) : (
        <>
          <styles.Title>Employee Info</styles.Title>
          <styles.ItemContainer>
            <styles.Item onClick={() => console.log(payrollData)}>
              <span>Name: </span> {epInfo?.label}
            </styles.Item>
          </styles.ItemContainer>
          <styles.ItemContainer>
            <styles.Item>
              <span>Job: </span>{" "}
              {getAttributValuesBynameId("employmentType", payrollData?.employmentType)}
            </styles.Item>
          </styles.ItemContainer>
          <styles.ItemContainer>
            <styles.Item>
              <span>Designation: </span> {payrollData?.currentDesignation}
            </styles.Item>
          </styles.ItemContainer>
          {/* <styles.ItemContainer>
            <styles.Item>
              <span>HR Manager: </span> {hrInfo?.name}
            </styles.Item>
          </styles.ItemContainer> */}
          <styles.ItemContainer>
            <styles.Item>
              <span>Company: </span> {payrollData?.employmentCompany?.company}
            </styles.Item>
          </styles.ItemContainer>
          <styles.Title>Salary</styles.Title>
          <styles.Row>
            <styles.Column>Days:</styles.Column>
            <styles.Column>{InputText("workingdays")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Hours:</styles.Column>
            <styles.Column>{InputText("workinghours")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime Type:</styles.Column>
            <styles.Column>
              {SelectText("overtimeChoice", payrollData.serial, [
                { label: "Base", value: "base" },
                { label: "Bonus", value: "bonus" },
                { label: "Base + Bonus", value: "base + bonus" },
                { label: "None", value: "none" },
              ])}
            </styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime Hours:</styles.Column>
            <styles.Column>{InputText("overtimeHours")}</styles.Column>
          </styles.Row>
          <hr />

          <styles.Row>
            <styles.Column>Salary:</styles.Column>
            <styles.Column>{InputText("salaryBase")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Bonus:</styles.Column>
            <styles.Column>{InputText("salaryBonus")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>6 Month Bonus:</styles.Column>
            <styles.Column>{InputText("salarySemiAnnualBonus")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>12 Month Bonus:</styles.Column>
            <styles.Column>{InputText("salaryAnnualBonus")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Overtime:</styles.Column>
            <styles.Column>{InputText("salaryOvertime")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Reimbursements:</styles.Column>
            <styles.Column>{InputText("salaryReimbursements")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Referrals:</styles.Column>
            <styles.Column>{InputText("salaryReferrals")}</styles.Column>
          </styles.Row>

          <styles.Row>
            <styles.Column>Adjustements:</styles.Column>
            <styles.Column>{InputText("salaryAdjustments")}</styles.Column>
          </styles.Row>
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Sub-Total</b>
            </styles.Column>
            <styles.Column>
              {currencyFormat(payrollData.salaryPayable, salaryCurrency)}
            </styles.Column>
          </styles.Row>
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Tax Amount</b>
            </styles.Column>
            <styles.Column>
              {payrollData.salaryTax
                ? currencyFormat(payrollData.salaryTax, salaryCurrencyDisplay)
                : "Processing"}
            </styles.Column>
          </styles.Row>
          <hr />
          <hr />
          <styles.Row>
            <styles.Column>
              <b>Payable Amount</b>
            </styles.Column>
            <styles.Column>
              {payrollData.salaryTax
                ? currencyFormat(
                    payrollData.salaryPayable - payrollData.salaryTax,
                    salaryCurrencyDisplay,
                  )
                : "Processing"}
            </styles.Column>
          </styles.Row>
          <styles.Title>Feedback</styles.Title>
          <Input
            label={"Feedback"}
            type={"textarea"}
            name='feedback'
            attributes={{ "data-index": payrollData.serial }}
            value={payrollData.feedback}
            autoComplete={"off"}
            onChange={onChangeText}
            readOnly={!props.permissions.payrolls_edit}
          />
          <styles.AttachmentIconContainer onClick={addAttachment}>
            <ToolTip text='Add Attachment' copy={false} element={<GrAttachment size={20} />} />
          </styles.AttachmentIconContainer>
          {attachments.map((item, index) => (
            <styles.AttachmentsContainer key={index}>
              <Input
                readOnly={false}
                label={"Attachment Name"}
                type={"text"}
                name='name'
                autoComplete={"off"}
                onChange={attachmentTextChange}
                value={item.name}
                attributes={{ "data-index": index }}
                mandatory={true}
                width='200px'
                // error={formData?.subject?.error}
              />
              <Input
                readOnly={false}
                label={"Attachment"}
                type={"file"}
                name='attachment'
                message={item.attachment}
                onMessageClick={() => getDocument(item.attachment)}
                autoComplete={"off"}
                onChange={onFileChange}
                mandatory={true}
                attributes={{ "data-index": index }}
                error={item.error}
                width='200px'
              />
              <styles.AttachmentsButtons>
                <Button
                  type={"cancel"}
                  onClick={() => removeAttachment(index)}
                  size={"normal"}
                  label={"Remove"}
                />
                <Button
                  type={"primary"}
                  onClick={() => getDocument(item.id)}
                  size={"normal"}
                  label={"View"}
                  disabled={!item.id}
                />
              </styles.AttachmentsButtons>
            </styles.AttachmentsContainer>
          ))}
          <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
            <Document id={documentId} />
          </ModalComponent>
          <Button
            label={capitalFirstLetter(payrollData.status)}
            onClick={() => setTab("1")}
            size='small'
          />
        </>
      )}
      <div style={{ marginTop: "2%" }}>
        <Button
          disabled={submitting || !props.permissions.payrolls_edit}
          onClick={onSubmitDraft}
          label={submitting ? "Saving" : "Save"}
        />
      </div>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.UserReducer.permissions,
  rehydrate: state.EmployeesReducer.payrollsRehydrate,
  payrolls: state.EmployeesReducer.payrolls,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
});

const mapDispatchToProps = {
  payrollsRehydrate,
  addPayrollData,
  updatePayrollData,
  fetchPayrollData,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollsController);
