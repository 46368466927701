import React from "react";

import { Layout } from "../../../presentationals";
import { CountryController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CountryController type={"add"} />} admin={true} layout={"2"} />;
};

export const APCountryAdd = React.memo(Route);
