import React, { useEffect, useMemo } from "react";

import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { AcademyCandidatesListProps } from "../../../../types";
import { tablePublicFields } from "../../../../utils";
import { TableListV3 } from "../../../components";

import { useAcademyCandidatesList } from "./hooks";

export const AcademyCandidatesList = ({ relatedList }: AcademyCandidatesListProps) => {
  const academyCandidates = useAppSelector((state) => state.AcademyCandidatesReducer.data);
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const widgetId = searchParams.get("widgetId");

  const { list, metaData } = academyCandidates;
  const {
    fetchAcademyCandidates,
    loading,
    handlePageChange,
    onClickAcademyCandidates,
    handleClearSearch,
    search,
    columns,
    academyCandidatesTableConfigFields,
    onSearch,
    onSearchChange,
    refreshList,
    listActionOptions,
    onFilterChange,
    onClickApplyFilter,
    handleSortList,
    handleTableConfigs,
    onClickRowSelection,
    allRowsSelected,
    selectedRows,
    filterLogicQuery,
    handleListActions,
    handleShowItemsPerPage,
    academyCandidatesFilterFields,
    academyCandidatesHeaderButtons,
    exportSelectedRecords,
    exportAllRecords,
    onSubmitDeleteRecord,
  } = useAcademyCandidatesList({
    relatedList,
    widgetId,
  });

  useEffect(() => {
    fetchAcademyCandidates({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableListV3
      exportSelectedRecords={exportSelectedRecords}
      onSubmitDeleteRecord={onSubmitDeleteRecord}
      deleteRecordEnabled={permissions?.academyCandidates_delete}
      exportAllRecords={exportAllRecords}
      isRelatedList={!!relatedList?.type}
      handleShowItemsPerPage={handleShowItemsPerPage}
      handleListActions={handleListActions}
      headerButtons={academyCandidatesHeaderButtons}
      filterLogicQuery={filterLogicQuery}
      selectedRows={selectedRows}
      allRowsSelected={allRowsSelected}
      onClickRowSelection={onClickRowSelection}
      tablePublicFields={tablePublicFields.academyCandidates}
      handleTableConfigs={handleTableConfigs}
      filters={academyCandidatesFilterFields}
      selectedConfigFields={academyCandidatesTableConfigFields}
      handleSortList={handleSortList}
      onClickApplyFilter={onClickApplyFilter}
      onFilterChange={onFilterChange}
      horizontalScroll={true}
      refreshList={refreshList}
      onClickTableRow={onClickAcademyCandidates}
      headerTitle={strings.headerTitle.academyCandidates}
      list={list}
      columns={columns}
      metaData={metaData}
      onPageChange={handlePageChange}
      loading={loading}
      searchPlaceholder={strings.apAcademyCandidates.search}
      onSearch={onSearch}
      clearSearch={handleClearSearch}
      searchValue={search}
      onSearchChange={onSearchChange}
      listActionOptions={listActionOptions}
    />
  );
};
