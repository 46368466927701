import React from "react";

import { Layout } from "../../../presentationals";
import { EmploymentHistoryController } from "../../../presentationals/screens";

export const APEmploymentHistoryAdd = () => {
  return (
    <Layout activity={<EmploymentHistoryController type={"add"} />} admin={true} layout={"2"} />
  );
};
