import React, { useEffect, useRef, useState, useCallback } from "react";

// additional imports
import { MdArrowBack } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { fetchModules, updateModules, createModules } from "../../redux/modules/actions";
import { formValidationMethod, useTitle, MomentDate } from "../../services/methods";

import styles from "./ModulesController.styles";

const CategoriesController = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const readOnly = ["edit", "add"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState("");

  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const defaultData = {
    name: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    parentModule: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    active: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
  };

  useEffect(() => {
    if (props.type === "view" || props.type === "edit") {
      if (id !== params.id || viewType.current !== props.type) {
        viewType.current = props.type;
        console.log("In Default");
        setFormData(defaultData);
        setLoader(true);
        if (props.modules.length > 0) {
          const find = props.modules.find((item) => item._id === params.id);
          if (find) {
            updateValues(find);
            setLoader(false);
            setSubmitting(false);
            setId(find._id);
            setToast({
              message: "",
              message_type: "",
            });
          }
        }
      }
    } else if (props.type === "add") {
      viewType.current = props.type;
      setLoader(false);
      setSubmitting(false);
      setFormData(defaultData);
      setId("");
      setToast({
        message: "",
        message_type: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.modules]);

  const [formData, setFormData] = useState(defaultData);

  const [metaData, setMetaData] = useState({});
  const updateValues = (data) => {
    const object = { ...formData };
    console.log("data", data);
    const metaData = {
      createdBy: data?.createdBy?.name,
      createdAt: data?.createdAt,
      updatedBy: data?.updatedBy?.name,
      updatedAt: data?.updatedAt,
    };
    setMetaData(metaData);
    Object.keys(data).forEach((element) => {
      const name = element;
      const value = data[element];
      console.log("name value", name, value);
      if (formData[element] && value !== undefined) {
        object[name] = {
          ...object[name],
          value: value,
        };
      }
    });
    if (object.parentModule.value?._id) {
      object.parentModule.value = object.parentModule.value._id;
    }
    console.log("Object", object);
    setFormData(object);
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    const error = formValidationMethod(name, value, formData);

    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Tags";
      case "add":
        return "Add Tags";
      case "edit":
        return "Edit Tags";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const isModuleExists = useCallback(() => {
    const module = props.modules.find(
      (item, index) =>
        String(item.name).toUpperCase() === String(formData.name.value).toUpperCase(),
    );
    if (module) {
      setToast({
        message: "Tag already exists",
        message_type: "error",
      });
    }
    return module;
  }, [formData.name.value, props.modules]);

  const onSubmitAddForm = () => {
    const submit = validateForm();
    const generateData = {};

    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      if (!isModuleExists()) {
        setSubmitting(true);
        props.createModules({
          data: generateData,
          onSuccess: (data) => {
            props.fetchModules({
              onSuccess: () => {
                navigate("/ap/modules");
                setSubmitting(false);
              },
              onError: () => {
                setToast({
                  message: "Updated Successfully, Please reload page",
                  message_type: "warning",
                });
                setSubmitting(false);
              },
            });
          },
          onError: (error) => {
            setToast({
              message: error,
              message_type: "error",
            });
            setSubmitting(false);
          },
        });
      }
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);

    return output;
  };

  const onSubmitUpdateForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      if (!isModuleExists()) {
        setSubmitting(true);
        props.updateModules({
          id: id,
          data: generateData,
          onSuccess: (data) => {
            props.fetchModules({
              onSuccess: () => {
                setToast({
                  message: "Updated Successfully",
                  message_type: "success",
                });
                setSubmitting(false);
              },
              onError: () => {
                setToast({
                  message: "Updated Successfully, Please reload page",
                  message_type: "warning",
                });
                setSubmitting(false);
              },
            });
          },
          onError: (error) => {
            setToast({
              message: error,
              message_type: "error",
            });
            setSubmitting(false);
          },
        });
      }
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 || submitting ? true : false;
    return value;
  };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>{getTitle()}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form
        onSubmit={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        autoComplete='off'
      >
        <Input
          readOnly={readOnly}
          label={"Name"}
          type={"text"}
          name='name'
          value={formData.name.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.name?.error}
        />
        {/* <Select
          name={"parentModule"}
          label={"Parent Module"}
          options={props.modules.map((value) => ({
            label: `${value.name} (${value.serial})`,
            value: value._id,
          }))}
          value={formData.parentModule.value}
          onChange={onTextChange}
          error={formData?.parentModule?.error}
        /> */}
        <Select
          name={"active"}
          label={"Active"}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          value={formData.active.value}
          onChange={onTextChange}
          error={formData?.active?.error}
        />
        {!readOnly && (
          <Button
            onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
            disabled={SubmitDisabled()}
            size={"normal"}
            label={"Save"}
          />
        )}
      </form>
      <br />
      <styles.metaDataContainer>
        <styles.metaDataItem>Created On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.createdAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Created By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.createdBy}</styles.metaDataInfo>
      </styles.metaDataContainer>

      <styles.metaDataContainer>
        <styles.metaDataItem>Updated On</styles.metaDataItem>
        <styles.metaDataInfo>
          {MomentDate({
            date: metaData.updatedAt,
            format: "YYYY-MM-DD",
          })}
        </styles.metaDataInfo>
      </styles.metaDataContainer>
      <styles.metaDataContainer>
        <styles.metaDataItem>Updated By</styles.metaDataItem>
        <styles.metaDataInfo>{metaData.updatedBy}</styles.metaDataInfo>
      </styles.metaDataContainer>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  modules: state.ModulesReducer.list,
});

const mapDispatchToProps = {
  createModules,
  updateModules,
  fetchModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesController);
