import { metaDataTypes, filtersTypes, userTypes } from "../../types";

import Types from "./types";

const UsersInitialState: {
  loading: boolean;
  data: {
    list: userTypes[];
    metaData: metaDataTypes;
  };
  openList: object[];
  filters: filtersTypes;
} = {
  loading: false,
  data: {
    list: [],
    metaData: {
      totalItems: 0,
      limit: 0,
      currentPage: 0,
      totalPages: 0,
    },
  },
  openList: [],
  filters: {
    sortKey: { label: "Name", key: "name" },
    sortType: "asc",
  },
};

const UsersReducer = (state = UsersInitialState, action) => {
  switch (action.type) {
    case Types.USERS_FETCH:
      return {
        ...state,
        loading: true,
      };
    case "USER_UPDATE_ORGANISATION_SUCCESS":
      return {
        ...state,
        data: {
          ...state.data,
          list: [],
          metaData: {
            totalItems: 0,
            limit: 0,
            currentPage: 0,
            totalPages: 0,
          },
        },
      };
    case Types.USERS_FETCH_SUCCESS:
      if (action.payload.isEmployee) {
        return {
          ...state,
          loading: false,
          openList: action.payload.data,
        };
      }
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case Types.USERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Types.USER_FITERS_UPDATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default UsersReducer;
