import styled from "styled-components";

import { colors, fonts } from "../../../../../theme";

interface Props {
  color?: string;
  open?: boolean;
  loading?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  opacity: ${(props) => (props.loading ? "0.6" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  overflow: hidden;
  .knowledge-details-skeleton {
    margin-top: 10px;
    height: 80vh;
    width: 80vw;
  }
  table {
    border-spacing: 0;
    border: 1px solid black;
    font-size: 14px;
    font-family: ${fonts.SourceSansPro};
    thead {
      font-size: 16px;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 10px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const KnowledgeActionsContainer = styled.div`
  display: flex;
  height: 34px;
  width: 66%;
  margin-top: 30px;
  position: relative;
  justify-content: space-between;
  .actions-dropdown-list {
    right: 0;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  height: 100%;
  background: ${colors.common.white};
  border: 0.5px solid ${colors.common.black};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px;
  align-items: center;
  color: ${colors.common.black};
  font-size: 14px;
  line-height: 18px;
  font-family: ${fonts.SourceSansPro};
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const DropDownIcon = styled.img<Props>`
  height: 10px;
  width: 10px;
  object-fit: contain;
  margin-left: 20px;
  transition: 0.4s linear;
  transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
`;

export const DropdownListContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: ${colors.common.white};
  border-radius: 10px;
  padding: 10px 20px;
  z-index: 1;
  top: 38px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
`;

export const DropdownListItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.common.white};
  padding: 10px 30px;
  font-size: 14px;
  /* line-height: 20px; */
  font-weight: 600;
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  transition: 0.4s linear;
  cursor: pointer;
  &:hover {
    background-color: ${colors.common.hoverTabBackground};
  }
`;

export const RightActionsContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .revision-in-progress {
    color: ${colors.common.white};
  }
`;

export const BasicInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 616px;
  width: 66%;
  background: ${colors.common.white};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  .knowledge-rejected-status {
    right: 18%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  .approval {
    margin-top: 40px;
  }
`;

export const KnowledgeHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #000000;
  position: relative;
`;

export const KnowledgeSerial = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: ${colors.common.primary};
  font-family: ${fonts.SourceSansPro};
`;

export const KnowledgeName = styled.p`
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: ${colors.common.black};
  font-family: ${fonts.SourceSansPro};
  margin-top: 5px;
`;

export const Summary = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
  height: fit-content;
  color: ${colors.common.black};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 95%;
  margin-top: 5px;
  line-height: 25px;
`;

export const Status = styled.div<Props>`
  background-color: ${(props) => props.color};
  border-radius: 10px;
  font-size: 12px;
  color: ${colors.common.black};
  font-weight: 600;
  padding: 6px 18px;
  font-family: ${fonts.SourceSansPro};
  position: absolute;
  top: 20px;
  right: 30px;
`;

export const MetaContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const MetaRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 25%;
  align-items: center;
`;

export const MetaIcon = styled.img`
  height: 10px;
  width: 10px;
  object-fit: contain;
`;

export const MetaTitle = styled.div`
  font-size: 12px;
  color: ${colors.common.black};
  font-weight: 400;
  font-family: ${fonts.SourceSansPro};
  margin-left: 5px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const MetaSeperator = styled.div`
  height: 12px;
  width: 1px;
  background-color: ${colors.common.black};
  margin: 0px 5px;
`;

export const Body = styled.div`
  padding: 30px;
  height: 440px;
  font-size: 13px;
  overflow: hidden;
  overflow: auto;
  ::-webkit-scrollbar-track {
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    box-shadow: inset 0 0 1px ${colors.common.black};
    border-radius: 20px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    height: 10px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${colors.common.primary};
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  max-width: 80%;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`;

export const Tag = styled.div`
  border-radius: 10px;
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  background-color: ${colors.knowledge.tagsBackground};
  font-size: 12px;
  color: ${colors.common.borderColor};
  font-weight: 600;
  padding: 4px 12px;
  font-family: ${fonts.SourceSansPro};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  .attachment-header {
    width: 100%;
    justify-content: left;
    padding-left: 6%;
    box-sizing: border-box;
  }
  .attachment-row {
    width: 100%;
    cursor: pointer;
  }
`;

export const TableRowItemContainer = styled.div`
  display: flex;
  z-index: 1;
  .left {
    border-right: none;
  }
  .right {
    border-left: none;
  }
`;

export const TableRowHeader = styled.div`
  display: flex;
  height: 64px;
  width: 50%;
  align-items: center;
  justify-content: center;
  /* padding-left: 4%; */
  font-size: 16px;
  line-height: 22px;
  color: ${colors.common.black};
  font-weight: 600;
  font-family: ${fonts.OpenSans};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  background: ${colors.common.secondaryWhite};
  z-index: -1;
`;

export const TableRowItem = styled.div`
  display: flex;
  height: 57px;
  width: 50%;
  align-items: center;
  padding-left: 6%;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.common.black};
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
  border: 0.3px solid rgba(0, 0, 0, 0.5);
  background: ${colors.common.white};
`;

export const TableRowTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  max-width: 90%;
  color: ${colors.common.black};
  font-weight: 600;
  font-family: ${fonts.SourceSansPro};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const NoAttachmentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  border-width: 0.3px;
  box-sizing: border-box;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  font-family: ${fonts.OpenSans};
  color: ${colors.common.lightTextColor};
  font-size: 14px;
  z-index: 10;
`;

export const CommentTooltipIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 10px;
  cursor: pointer;
  /* position: absolute; */
`;

export const ActionsModalContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;
  width: 50vw;
  border-radius: 10px;
  background: ${colors.common.white};
  min-height: 200px;
  position: relative;
  padding: 6%;
  .knowledge-action-modal-save {
    width: fit-content;
    margin-top: 50px;
  }
`;

export const ActionsModalDescription = styled.div`
  font-family: ${fonts.SourceSansPro};
  font-size: 16px;
  color: ${colors.common.black};
  margin-bottom: 20px;
`;
