import styled from "styled-components";

import { colors } from "../../../../theme";

export const SectionModalContainer = styled.div`
  display: flex;
  width: 90vw;
  /* height: 90vh; */
  padding: 30px;
  flex-direction: column;
  background-color: ${colors.common.white};
  .learning-point-modal-save-button {
    width: fit-content;
    alignself: center;
  }
  .centerStyle {
    align-items: center;
    display: inline-flex;
    justify-content: left;
    margin-top: 2rem;
  }
`;
