import Types from "./actionType";
import { AcademyBatchActions, AcademyBatchState } from "./types";

const initialState: AcademyBatchState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
    },
  },
  loading: false,
};

export const AcademyBatchReducer = (state = initialState, action: AcademyBatchActions) => {
  switch (action.type) {
    case Types.FETCH_ACADEMY_BATCH:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_ACADEMY_BATCH:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
