import React, { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { images, strings } from "../../../theme";
import { ListActions, SelectOptions, TableHeaderButton } from "../../../types";
import { Select } from "../atoms";
import { Button } from "../atoms/Button";
import { FilterButton } from "../atoms/FilterButton";
import { Popup } from "../organisms";
import Search from "../Search";

import {
  Container,
  LeftContainer,
  RightContainer,
  SearchContainer,
  AdditionalButton,
  ExtraSaveButton,
  AttachmentsIcon,
  AttachmentsIconContainer,
  AttachmentsBadge,
  RefreshButton,
  RefreshIcon,
  ActionsContainer,
  DropDownContainer,
  DropDownIcon,
  DropdownListContainer,
  DropdownListItem,
} from "./styles";

interface ExtraButton {
  title: string;
  onPress: () => void;
}

interface ButtonType {
  headerButtons: TableHeaderButton[];
  backButtonTitle?: string;
  backButtonPress?: () => void;
  handleTableConfigs?: () => void;
  refreshList?: () => void;
  rightButtonPress?: React.MouseEventHandler;
  rightButton?: string;
  searchPlaceholderText?: string;
  rightButtonBorder?: string;
  rightButtonDisabled?: boolean;
  additionalButton?: string;
  additionalButtonPress?: React.MouseEventHandler;
  handleListActions?: (field: string, value: any) => void;
  filterButton?: string;
  filterButtonPress?: React.MouseEventHandler;
  filterButtonBorder?: string;
  filterButtonDisabled?: boolean;
  rightButtonWidth?: string;
  extraSaveButton?: string;
  extraSaveButtonPress?: React.MouseEventHandler;
  extraSaveButtonWidth?: string;
  onAttachmentsClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  onAttachmentsIconLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  attachmentsBadge?: number;
  rightButtonClass?: string;
  // Search Props
  searchValue?: string;
  onSearchPress?: () => void;
  clearSearch?: () => void;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInputName?: string;
  extraButtons?: ExtraButton[];
  listActionOptions?: ListActions[];
  advancedList?: boolean;
}

export const TableHeaderV2 = ({
  handleListActions,
  headerButtons,
  backButtonTitle,
  handleTableConfigs,
  refreshList,
  extraButtons,
  rightButton,
  backButtonPress,
  rightButtonPress,
  searchPlaceholderText = "",
  rightButtonBorder = "0px",
  rightButtonDisabled,
  additionalButton,
  additionalButtonPress,
  filterButton,
  filterButtonPress,
  filterButtonBorder = "0px",
  filterButtonDisabled,
  rightButtonWidth = "69px",
  extraSaveButtonWidth = "70px",
  extraSaveButton,
  extraSaveButtonPress,
  onAttachmentsClick,
  onAttachmentsIconLoad,
  clearSearch,
  attachmentsBadge,
  rightButtonClass,
  // Search Props
  searchValue,
  onSearchPress,
  onSearchChange,
  searchInputName = "search",
  listActionOptions,
  advancedList = true,
}: ButtonType) => {
  const navigate = useNavigate();

  const [actionsPopup, setActionsPopup] = useState(false);
  const [listActionField, setListActionField] = useState<string>("");
  const [listActionValue, setListActionValue] = useState<string>("");

  const onListActionFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setListActionField(value);
      setListActionValue("");
    },
    [],
  );

  const onListActionValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setListActionValue(value);
    },
    [],
  );

  const allListActionFields = useMemo(() => {
    const tempFields = listActionOptions?.filter((item) => item.hide !== true);
    const fields: SelectOptions[] | undefined = tempFields?.map((item) => {
      return { label: item.label, value: item.name };
    });
    return fields;
  }, [listActionOptions]);

  const selectedListActionFieldOptions = useMemo(() => {
    return listActionOptions?.find((item) => item.name === listActionField)?.options;
  }, [listActionOptions, listActionField]);

  const handleActionsPopup = useCallback(() => {
    setActionsPopup((value) => !value);
  }, []);

  const handleListAction = useCallback(() => {
    const field = listActionField;
    const value = listActionValue;
    if (handleListActions) {
      handleListActions(field, value);
    }
    setActionsPopup(false);
    setListActionField("");
    setListActionValue("");
  }, [handleListActions, listActionField, listActionValue]);

  const goBack = useCallback(() => {
    backButtonPress ? backButtonPress() : navigate(-1);
  }, [backButtonPress, navigate]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onSearchPress) {
        onSearchPress();
      }
    },
    [onSearchPress],
  );

  return (
    <Container>
      <LeftContainer>
        {backButtonTitle && (
          <Button
            className='table-header-back-button'
            leftArrow
            title={backButtonTitle}
            onClick={goBack}
          />
        )}
      </LeftContainer>
      <RightContainer>
        {onSearchPress && (
          <SearchContainer>
            <Search
              clearSearch={clearSearch}
              inputProps={{
                placeholder: searchPlaceholderText,
                onChange: onSearchChange,
                onKeyDown: onKeyDown,
                value: searchValue,
                name: searchInputName,
              }}
            />
            <Button title={strings.common.search} onClick={onSearchPress} />
          </SearchContainer>
        )}
        {onAttachmentsClick && (
          <AttachmentsIconContainer>
            <AttachmentsIcon
              onLoad={onAttachmentsIconLoad}
              onClick={onAttachmentsClick}
              src={images.attachmentsIcon}
            />
            {!!attachmentsBadge && <AttachmentsBadge>{attachmentsBadge}</AttachmentsBadge>}
          </AttachmentsIconContainer>
        )}{" "}
        {advancedList && filterButton && filterButtonPress && (
          <FilterButton
            disabled={filterButtonDisabled}
            border={filterButtonBorder}
            title={filterButton}
            onClick={filterButtonPress}
          />
        )}
        {!!listActionOptions?.length && (
          <Button title={"List Actions"} onClick={handleActionsPopup} />
        )}
        {headerButtons.map((button, buttonIndex) => {
          const { title, onClick, visible, border, width, disabled } = button;
          if (visible) {
            return (
              <Button
                key={buttonIndex}
                disabled={disabled}
                border={border}
                title={title}
                onClick={onClick}
                width={width}
              />
            );
          }
        })}
        {!!handleTableConfigs && advancedList && (
          <RefreshButton onClick={handleTableConfigs}>
            <RefreshIcon src={images.settings} />
          </RefreshButton>
        )}
        {!!refreshList && (
          <RefreshButton onClick={refreshList}>
            <RefreshIcon src={images.reloadIcon} />
          </RefreshButton>
        )}
      </RightContainer>
      <Popup isOpen={actionsPopup} closeModal={handleActionsPopup}>
        {actionsPopup && (
          <DropdownListContainer>
            <Select
              name={"listActionField"}
              label={"Field"}
              options={allListActionFields || []}
              value={listActionField}
              onChange={onListActionFieldChange}
              mandatory={true}
              error={""}
              width='100%'
            />
            <Select
              name={"listActionValue"}
              label={"Value"}
              options={selectedListActionFieldOptions || []}
              value={listActionValue}
              onChange={onListActionValueChange}
              mandatory={true}
              readOnly={!listActionField}
              error={""}
              width='100%'
            />
            <Button
              disabled={!listActionField || ["", null, undefined].includes(listActionValue)}
              title={strings.common.save}
              onClick={handleListAction}
              width={"100%"}
            />
          </DropdownListContainer>
        )}
      </Popup>
    </Container>
  );
};
