import Types from "./actionType";
import { ITNRFPActions, ITNRFPState } from "./types";

const initialState: ITNRFPState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const ITNRFPReducer = (state = initialState, action: ITNRFPActions) => {
  switch (action.type) {
    case Types.FETCH_ITNRFP:
      return { ...state, loading: true };

    case Types.SUCCESS_FETCH_ITNRFP:
      return { ...state, data: action.payload.data, loading: false };

    default:
      return state;
  }
};
