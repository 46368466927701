import Types from "./actionType";
import {
  FetchProjectHistoryPayload,
  CreateProjectHistory,
  CreateProjectHistoryPayload,
  ProjectHistoryList,
  FetchProjectHistory,
  FetchProjectHistoryError,
  FetchProjectHistorySuccess,
  UpdateProjectHistory,
  UpdateProjectHistoryPayload,
  ProjectHistoryListWithMetaData,
  FetchProjectHistoryByIdPayload,
  FetchProjectHistoryById,
} from "./types";

export const fetchProjectHistoryList = ({
  params,
  onSuccess,
  onError,
}: FetchProjectHistoryPayload): FetchProjectHistory => ({
  type: Types.FETCH_PROJECT_HISTORY,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchProjectHistoryById = ({
  id,
  onSuccess,
  onError,
}: FetchProjectHistoryByIdPayload): FetchProjectHistoryById => ({
  type: Types.FETCH_PROJECT_HISTORY_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchProjectHistoryListSuccess = (
  data: ProjectHistoryListWithMetaData,
): FetchProjectHistorySuccess => ({
  type: Types.SUCCESS_FETCH_PROJECT_HISTORY,
  payload: {
    data,
  },
});

export const fetchProjectHistoryError = (error?: string): FetchProjectHistoryError => ({
  type: Types.ERROR_FETCH_PROJECT_HISTORY,
  payload: { error },
});

export const createProjectHistory = ({
  data,
  onSuccess,
  onError,
}: CreateProjectHistoryPayload): CreateProjectHistory => ({
  type: Types.CREATE_PROJECT_HISTORY,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateProjectHistory = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateProjectHistoryPayload): UpdateProjectHistory => ({
  type: Types.UPDATE_PROJECT_HISTORY,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});
