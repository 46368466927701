/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_MENTOR_FEEDBACK = "FETCH_ACADEMY_MENTOR_FEEDBACK",
  FETCH_ACADEMY_MENTOR_FEEDBACK_BY_ID = "FETCH_ACADEMY_MENTOR_FEEDBACK_BY_ID",
  SUCCESS_FETCH_ACADEMY_MENTOR_FEEDBACK = "SUCCESS_FETCH_ACADEMY_MENTOR_FEEDBACK",
  ERROR_FETCH_ACADEMY_MENTOR_FEEDBACK = "ERROR_FETCH_ACADEMY_MENTOR_FEEDBACK",
  CREATE_ACADEMY_MENTOR_FEEDBACK = "CREATE_ACADEMY_MENTOR_FEEDBACK",
  UPDATE_ACADEMY_MENTOR_FEEDBACK = "UPDATE_ACADEMY_MENTOR_FEEDBACK",
  DELETE_ACADEMY_MENTOR_FEEDBACK = "DELETE_ACADEMY_MENTOR_FEEDBACK",
}

export default Types;
