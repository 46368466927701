import Types from "./actionType";
import {
  IDriveActionFetchPayload,
  IDriveActionFetchSuccessPayload,
  IDriveActionUploadPayload,
  IDriveActionOperations,
} from "./types";

export const DriveFetch = ({ path, onSuccess, onError }: IDriveActionFetchPayload) => ({
  type: Types.DRIVE_FETCH,
  payload: {
    path,
    onSuccess,
    onError,
  },
});

export const DriveFetchSuccess = ({ data }: IDriveActionFetchSuccessPayload) => ({
  type: Types.DRIVE_FETCH_SUCCESS,
  payload: data,
});

export const DriveUpload = ({ path, type, onSuccess, onError }: IDriveActionUploadPayload) => ({
  type: Types.DRIVE_UPLOAD,
  payload: {
    path,
    type,
    onSuccess,
    onError,
  },
});

export const uploadFileS3 = ({ file, url, onSuccess, onError, onProgress }) => ({
  type: Types.DRIVE_UPLOAD_S3,
  payload: {
    file,
    url,
    onSuccess,
    onError,
    onProgress,
  },
});

export const driveOperations = ({
  operation,
  path = "",
  newPath = "",
  onSuccess,
  onError,
}: IDriveActionOperations) => ({
  type: Types.DRIVE_OPERATIONS,
  payload: {
    operation,
    path,
    newPath,
    onSuccess,
    onError,
  },
});
