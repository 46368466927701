/* eslint-disable no-unused-vars */
enum Types {
  FETCH_VARIABLES = "FETCH_VARIABLES",
  FETCH_VARIABLES_BY_ID = "FETCH_VARIABLES_BY_ID",
  SUCCESS_FETCH_VARIABLES = "SUCCESS_FETCH_VARIABLES",
  ERROR_FETCH_VARIABLES = "ERROR_FETCH_VARIABLES",
  CREATE_VARIABLES = "CREATE_VARIABLES",
  UPDATE_VARIABLES = "UPDATE_VARIABLES",
  DELETE_VARIABLES = "DELETE_VARIABLES",
}

export default Types;
