import React from "react";

import { Link } from "react-router-dom";
import "./Dropdown.css";

interface List {
  link: string;
  label: any;
  icon: any;
  attributes: any;
  onClick: any;
}

interface Props {
  element: any;
  list: [List];
}

const Dropdown: React.FC<Props> = ({ element = "Text", list }: Props) => {
  return (
    <div className='dropdown'>
      <div className='dropbtn'>{element}</div>
      <div className='dropdown-content'>
        {list.map((item, index) =>
          item.onClick ? (
            <div {...item.attributes} className='item' key={index} onClick={item.onClick}>
              <div className='itemcontainer'>
                <div className='itemicon'>{item.icon}</div>
                <div className='itemtext'>{item.label}</div>
              </div>
            </div>
          ) : (
            <Link to={item.link} className='item' key={index}>
              <div className='itemcontainer'>
                <div className='itemicon'>{item.icon}</div>
                <div className='itemtext'>{item.label}</div>
              </div>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default Dropdown;
