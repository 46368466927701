import React, { memo } from "react";

import { Layout } from "../../../presentationals";
import { SPSDealsController } from "../../../presentationals/screens/admin/SPSDeals/SPSDealsController";

const Route = () => {
  return <Layout activity={<SPSDealsController type={"add"} />} admin={true} layout={"2"} />;
};

export const APSPSDealskAdd = React.memo(Route);
