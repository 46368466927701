import { TimePicker } from "antd";
import DatePicker from "react-date-picker";
import styled from "styled-components";

import { colors, fs, fonts } from "../../../../theme";

interface Props {
  width?: string;
  disabled?: boolean;
  error?: string;
  hidden?: boolean;
}

export const ParentWrapper = styled.div<Props>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  margin-top: 26px;
  position: relative;
  width: ${(props) => props.width};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Input = styled.input<Props>`
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  height: 50px;
  width: 100%;
  padding-left: 20px;
  color: ${colors.common.lightTextColor};
  background: ${(props) => (props.disabled ? colors.common.lightGrey : colors.common.white)};
  border: 0.6px solid ${(props) => (props.error ? colors.common.red : colors.common.black)};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

export const Switch = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 30px;
  .switch {
    font-weight: normal;
    position: relative;
    pointer-events: none;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: ${fs.m};
    top: 0;
    display: flex;
    color: ${colors.input.placeholder};
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    width: 30px;
    height: 16px;
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transition-duration: 1s;
  }

  .slider:before {
    content: "";
    height: 13px;
    width: 13px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transition-delay: 1s;
  }

  input:checked + .slider {
    background-color: #2196f3;
    transform: rotate(720deg);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Label = styled.label<Props>`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 14px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: ${fs.m};
  line-height: 20px;
  display: flex;
  color: ${colors.input.placeholder};
  transition: 0.2s ease all;
  span {
    color: ${colors.common.red};
  }
  &.focused {
    top: -10px;
    font-weight: 600;
    height: 24px;
    font-size: ${fs.s};
    color: ${colors.common.black};
    background-color: ${colors.common.white};
    padding-left: 2%;
    padding-right: 2%;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.common.error};
  margin-top: 4px;
`;
export const TextArea = styled.textarea<Props>`
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  color: ${colors.common.lightTextColor};
  font-family: ${fonts.SourceSansPro};
  background: ${colors.common.white};
  border: 0.6px solid ${(props) => (props.error ? colors.common.red : colors.common.black)};
  border-radius: 5px;
  font-weight: 400;
  font-size: ${fs.m};
  &:focus {
    outline: none;
  }
`;

export const Date = styled(DatePicker)<Props>`
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  height: 50px;
  width: 100%;
  color: ${colors.common.lightTextColor};
  .react-date-picker__wrapper {
    font-size: ${fs.m};
    font-family: ${fonts.SourceSansPro};
    font-weight: 400;
    height: 50px;
    width: 100%;
    padding-left: 20px;
    color: ${colors.common.lightTextColor};
    background: ${(props) => (props.disabled ? colors.common.lightGrey : colors.common.white)};
    border: 0.6px solid ${(props) => (props.error ? colors.common.red : colors.common.black)};
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
  .react-date-picker__inputGroup__year {
    background: none;
  }
  .react-date-picker__inputGroup__month {
    background: none;
  }
  .react-date-picker__inputGroup__day {
    background: none;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  /* left: 10px; */
  top: -23px;
`;

export const TooltipIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const Time = styled(TimePicker)<Props>``;
