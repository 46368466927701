import React, { useCallback, useEffect, useMemo, useState } from "react";

import { fetchDashboardWidgetData, fetchDashboardsList } from "../../../../redux";
import { FetchDashboardWidgetDataPayload } from "../../../../redux/Dashboard/types";
import { DashboardWidgetsList } from "../../../../redux/Dashboard/Widgets/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { strings } from "../../../../theme";
import { Button, Header, ListWidget, WelcomeCard } from "../../../components";
import { CountWidget } from "../../../components/templates/CountWidget/CountWidget";

import {
  Container,
  DashboardAdministrationItem,
  DashboardAdministrationsContainer,
  DashboardTabItem,
  DashboardTabsContainer,
  DashboardWidgetItem,
  DashboardWidgetsContainer,
  DashboardsContainer,
  EmptyListMessage,
} from "./styles";

export const AdminDashboard = () => {
  const dashboardsData = useAppSelector((state) => state.DashboardsReducer);
  const widgetsData = useAppSelector((state) => state.DashboardsReducer).data.widgetsData;
  const loadingWidgets = useAppSelector((state) => state.DashboardsReducer).loadingWidgets;
  const user = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();

  const [activeAdministration, setActiveAdministration] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("");

  const handleAdministrationClick = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id");
    if (id) {
      setActiveAdministration(id);
    }
  }, []);

  const handleTabClick = useCallback((e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("data-id");
    if (id) {
      setActiveTab(id);
    }
  }, []);

  const currentAdministrations = useMemo(() => {
    return dashboardsData.data.dashboardAdministrations;
  }, [dashboardsData.data.dashboardAdministrations]);

  const currentTabs = useMemo(() => {
    const tabs = dashboardsData?.data?.dashboardTabs?.filter(
      (item) => item.dashboard === activeAdministration,
    );
    return tabs;
  }, [activeAdministration, dashboardsData.data.dashboardTabs]);

  const currentWidgets = useMemo(() => {
    const widgets = dashboardsData?.data?.dashboardWidgets?.filter(
      (item) => item.dashboardTab === activeTab,
    );
    return widgets;
  }, [activeTab, dashboardsData.data.dashboardWidgets]);

  const refreshWidgetData = useCallback(
    (e: React.MouseEvent) => {
      const id = e.currentTarget.getAttribute("data-id");
      if (id) {
        const payload: FetchDashboardWidgetDataPayload = {
          id,
        };
        dispatch(fetchDashboardWidgetData(payload));
      }
    },
    [dispatch],
  );

  const isWidgetVisible = useCallback(
    (id: string) => {
      let visible = false;
      const existsInWidgetsData = widgetsData.find((item) => item._id === id);
      if (loadingWidgets.includes(id)) {
        visible = true;
      } else if (existsInWidgetsData?._id) {
        visible = true;
      }
      return visible;
    },
    [widgetsData, loadingWidgets],
  );

  const loadTwoWidgets = useCallback(
    (widgetOne?: string, widgetTwo?: string) => {
      if (widgetOne && !isWidgetVisible(widgetOne)) {
        const payload: FetchDashboardWidgetDataPayload = {
          id: widgetOne,
          onSuccess: () => {
            if (widgetTwo && !isWidgetVisible(widgetTwo)) {
              const payload: FetchDashboardWidgetDataPayload = {
                id: widgetTwo,
              };
              dispatch(fetchDashboardWidgetData(payload));
            }
          },
          onError: () => {
            if (widgetTwo && !isWidgetVisible(widgetTwo)) {
              const payload: FetchDashboardWidgetDataPayload = {
                id: widgetTwo,
              };
              dispatch(fetchDashboardWidgetData(payload));
            }
          },
        };
        dispatch(fetchDashboardWidgetData(payload));
      }
    },
    [dispatch, isWidgetVisible],
  );

  useEffect(() => {
    if (
      dashboardsData.data.dashboardAdministrations.length &&
      dashboardsData.data.dashboardAdministrations[0]?._id
    ) {
      setActiveAdministration(dashboardsData.data.dashboardAdministrations[0]._id);
    }
  }, [dashboardsData.data.dashboardAdministrations]);

  useEffect(() => {
    const tabsByAdministration = dashboardsData.data.dashboardTabs.filter(
      (item) => item.dashboard === activeAdministration,
    );
    if (tabsByAdministration.length && tabsByAdministration[0]?._id) {
      setActiveTab(tabsByAdministration[0]._id);
    } else {
      setActiveTab("");
    }
  }, [
    dashboardsData.data.dashboardAdministrations,
    activeAdministration,
    dashboardsData.data.dashboardTabs,
  ]);

  useEffect(() => {
    dispatch(fetchDashboardsList({}));
  }, [dispatch]);

  useEffect(() => {
    const currentWidgetDataIds: string[] = [];
    widgetsData.forEach((item, index) => {
      if (item._id) {
        currentWidgetDataIds.push(item?._id);
      }
    });
    loadTwoWidgets(currentWidgets?.[0]?._id, currentWidgets?.[1]?._id);
  }, [widgetsData, currentWidgets, dispatch, loadingWidgets, isWidgetVisible, loadTwoWidgets]);

  const currentTabWidgetsData = useMemo(() => {
    const data = widgetsData?.filter((item) => item?.dashboardTab?._id === activeTab);
    return data;
  }, [widgetsData, activeTab]);

  const renderWidgetData = useCallback(
    (widgetItem: DashboardWidgetsList) => {
      switch (widgetItem.type) {
        case "list":
          return <ListWidget widgetItem={widgetItem} refreshWidgetData={refreshWidgetData} />;
        case "count":
          return <CountWidget widgetItem={widgetItem} refreshWidgetData={refreshWidgetData} />;
        default:
          return <></>;
      }
    },
    [refreshWidgetData],
  );

  const handleLoadMore = useCallback(() => {
    const index = currentTabWidgetsData.length;
    loadTwoWidgets(currentWidgets?.[index]?._id, currentWidgets?.[index + 1]?._id);
  }, [currentTabWidgetsData, loadTwoWidgets, currentWidgets]);

  return (
    <Container>
      <Header title={strings.headerTitle.home} />
      <WelcomeCard name={user.name} />
      {!!currentAdministrations.length && (
        <DashboardsContainer
          onClick={() =>
            console.log({
              dashboardsData,
              currentWidgets,
              widgetsData,
            })
          }
        >
          <DashboardAdministrationsContainer>
            {currentAdministrations.map((administrationItem, administrationIndex) => {
              return (
                <DashboardAdministrationItem
                  key={administrationIndex}
                  active={administrationItem._id === activeAdministration}
                  onClick={handleAdministrationClick}
                  data-id={administrationItem._id}
                >
                  {administrationItem.title}
                </DashboardAdministrationItem>
              );
            })}
          </DashboardAdministrationsContainer>
          {!!currentTabs.length && (
            <DashboardTabsContainer>
              {currentTabs.map((tabItem, tabIndex) => {
                return (
                  <DashboardTabItem
                    key={tabIndex}
                    active={tabItem._id === activeTab}
                    onClick={handleTabClick}
                    data-id={tabItem._id}
                  >
                    {tabItem.title}
                  </DashboardTabItem>
                );
              })}
            </DashboardTabsContainer>
          )}
          {!currentWidgets.length && (
            <EmptyListMessage>{strings.noData.noWidgets}</EmptyListMessage>
          )}
          {!!currentWidgets.length && (
            <DashboardWidgetsContainer>
              {currentWidgets.map((widgetItem, widgetIndex) => {
                if (widgetItem._id && isWidgetVisible(widgetItem._id)) {
                  return (
                    <DashboardWidgetItem key={widgetIndex}>
                      {renderWidgetData(widgetItem)}
                    </DashboardWidgetItem>
                  );
                }
              })}
            </DashboardWidgetsContainer>
          )}
          {currentWidgets.length > currentTabWidgetsData?.length && (
            <Button
              title={strings.common.loadMore}
              className='dashboard-load-more'
              onClick={handleLoadMore}
            />
          )}
        </DashboardsContainer>
      )}
    </Container>
  );
};
