import React from "react";

import { Layout } from "../../../presentationals";
import { CampaignsList } from "../../../presentationals/screens/admin/Campaigns/CampaignsList";

export const Route = () => {
  return <Layout activity={<CampaignsList />} admin={true} layout={"2"} />;
};

export const APCampaignsList = React.memo(Route);
