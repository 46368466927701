/* eslint-disable no-unused-vars */
enum Types {
  FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS",
  FETCH_CAMPAIGNS_BY_ID = "FETCH_CAMPAIGNS_BY_ID",
  SUCCESS_FETCH_CAMPAIGNS = "SUCCESS_FETCH_CAMPAIGNS",
  ERROR_FETCH_CAMPAIGNS = "ERROR_FETCH_CAMPAIGNS",
  CREATE_CAMPAIGNS = "CREATE_CAMPAIGNS",
  UPDATE_CAMPAIGNS = "UPDATE_CAMPAIGNS",
  DELETE_CAMPAIGNS = "DELETE_CAMPAIGNS",
}

export default Types;
