import React from "react";

import { Layout } from "../../../presentationals";
import { EmailSchedularController } from "../../../presentationals/screens/admin/EmailSchedular/EmailSchedularController";

const Route = () => {
  return <Layout activity={<EmailSchedularController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APEmailSchedularEdit = React.memo(Route);
