import { AxiosResponse, AxiosError } from "axios";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getOrganisation,
  getToken,
  SubCategoriesList,
  AddSubCategories,
  UpdateSubCategories,
  FindSubCategories,
  DeleteSubCategories,
} from "../../services/api-end-points";
import axios from "../../services/axios";
import { AxiosFailure } from "../../types";

import { fetchSubCategoriesListSuccess, fetchSubCategoriesError } from "./actions";
import Types from "./actionType";
import {
  CreateSubCategoriesuccess,
  CreateSubCategories as CreateSubCategoriesType,
  SubCategoriesSuccess,
  UpdateSubCategories as UpdateSubCategoriesType,
  FetchSubCategories,
  FetchSubCategoriesById,
  SubCategoriesByIdSuccess,
  DeleteSubCategories as DeleteSubCategoriesType,
} from "./types";

function* createWorker(action: CreateSubCategoriesType) {
  try {
    const options = {
      method: "POST",
      url: AddSubCategories,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSubCategoriesuccess> = yield call(axios, options);
    if (response.data.success && action.payload.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* updateWorker(action: UpdateSubCategoriesType) {
  try {
    const options = {
      method: "PUT",
      url: UpdateSubCategories(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      data: action.payload.data,
    };
    const response: AxiosResponse<CreateSubCategoriesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

function* fetchAllWorker(action: FetchSubCategories) {
  try {
    const params = action.payload.params;
    const options = {
      method: "GET",
      url: SubCategoriesList,
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
      params,
    };
    const response: AxiosResponse<SubCategoriesSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
      yield put(fetchSubCategoriesListSuccess(data.data));
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    yield put(fetchSubCategoriesError(error.response?.data.message));
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data?.message || "Error in fetching Sub Categories");
    }
  }
}

function* fetchSubCategoriesById(action: FetchSubCategoriesById) {
  try {
    const id = action.payload.id;
    const options = {
      method: "GET",
      url: FindSubCategories(id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<SubCategoriesByIdSuccess> = yield call(axios, options);
    const { data } = response;
    if (data.success) {
      if (action.payload?.onSuccess) {
        action.payload.onSuccess(data.data);
      }
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    if (action.payload?.onError) {
      action.payload.onError(error.response?.data.message || "Error in fetching Sub Categories");
    }
  }
}

function* deleteWorker(action: DeleteSubCategoriesType) {
  try {
    const options = {
      method: "DELETE",
      url: DeleteSubCategories(action.payload.id),
      headers: {
        Authorization: yield select(getToken),
        Organisation: yield select(getOrganisation),
      },
    };
    const response: AxiosResponse<CreateSubCategoriesuccess> = yield call(axios, options);
    if (response.data.success && action?.payload?.onSuccess) {
      action.payload.onSuccess(response.data.data);
    }
  } catch (e) {
    const error: AxiosError<AxiosFailure> = e;
    action?.payload?.onError && action.payload.onError(error.response?.data?.message.toString());
  }
}

export default function* Saga() {
  yield takeLatest(Types.CREATE_SUB_CATEGORIES, createWorker);
  yield takeLatest(Types.UPDATE_SUB_CATEGORIES, updateWorker);
  yield takeLatest(Types.DELETE_SUB_CATEGORIES, deleteWorker);
  yield takeEvery(Types.FETCH_SUB_CATEGORIES, fetchAllWorker);
  yield takeEvery(Types.FETCH_SUB_CATEGORIES_BY_ID, fetchSubCategoriesById);
}
