import React from "react";

import { Layout } from "../../../presentationals";
import { SubCategoriesController } from "../../../presentationals/screens/admin/Subcategories/SubCategoriesController";

const Route = () => {
  return <Layout activity={<SubCategoriesController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APSubCategoriesEdit = React.memo(Route);
