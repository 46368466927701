import styled from "styled-components";

import { colors, fs, fonts } from "../../../../theme";

interface Props {
  width?: string;
  disabled?: boolean;
  error?: string;
  hidden?: boolean;
}

export const InputDiv = styled.div<Props>`
  font-size: ${fs.m};
  font-family: ${fonts.SourceSansPro};
  font-weight: 400;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  color: ${colors.common.lightTextColor};
  background: ${(props) => (props.disabled ? colors.common.lightGrey : colors.common.white)};
  border: 0.6px solid ${(props) => (props.error ? colors.common.red : colors.common.black)};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;
export const Label = styled.label<Props>`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 14px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: ${fs.m};
  line-height: 20px;
  display: flex;
  color: ${colors.input.placeholder};
  transition: 0.2s ease all;
  span {
    color: ${colors.common.red};
  }
  &.focused {
    top: -10px;
    font-weight: 600;
    height: 24px;
    font-size: ${fs.s};
    color: ${colors.common.black};
    background-color: ${colors.common.white};
    padding-left: 2%;
    padding-right: 2%;
  }
`;
