import Types from "./types";

const Initial = {
  list: {},
  openList: [],
  filters: {
    title: "",
    sortKey: {},
    sortType: "asc",
  },
};

const Reducer = (state = Initial, action) => {
  switch (action.type) {
    case Types.KNOWLEDGEBASE_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case Types.KNOWLEDGEBASE_FETCH_SELF_SUCCESS:
      return {
        ...state,
        openList: action.payload,
      };
    case Types.KNOWLEDGEBASE_FILTERS_UPDATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default Reducer;
