import Types from "./actionType";
import {
  FetchSPSSalesProfilePayload,
  CreateSPSSalesProfile,
  CreateSPSSalesProfilePayload,
  FetchSPSSalesProfile,
  FetchSPSSalesProfileError,
  FetchSPSSalesProfileSuccess,
  UpdateSPSSalesProfile,
  UpdateSPSSalesProfilePayload,
  SPSSalesProfileListWithMetaData,
  FetchSPSSalesProfileByIdPayload,
  FetchSPSSalesProfileById,
  DeleteSPSSalesProfilePayload,
  DeleteSPSSalesProfile,
} from "./types";

export const fetchSPSSalesProfileList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSSalesProfilePayload): FetchSPSSalesProfile => ({
  type: Types.FETCH_SPS_SALES_PROFILE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSSalesProfileById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSSalesProfileByIdPayload): FetchSPSSalesProfileById => ({
  type: Types.FETCH_SPS_SALES_PROFILE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSSalesProfileListSuccess = (
  data: SPSSalesProfileListWithMetaData,
): FetchSPSSalesProfileSuccess => ({
  type: Types.SUCCESS_FETCH_SPS_SALES_PROFILE,
  payload: {
    data,
  },
});

export const fetchSPSSalesProfileError = (error?: string): FetchSPSSalesProfileError => ({
  type: Types.ERROR_FETCH_SPS_SALES_PROFILE,
  payload: { error },
});

export const createSPSSalesProfile = ({
  data,
  onSuccess,
  onError,
}: CreateSPSSalesProfilePayload): CreateSPSSalesProfile => ({
  type: Types.CREATE_SPS_SALES_PROFILE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSSalesProfile = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSSalesProfilePayload): UpdateSPSSalesProfile => ({
  type: Types.UPDATE_SPS_SALES_PROFILE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSSalesProfile = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSSalesProfilePayload): DeleteSPSSalesProfile => ({
  type: Types.DELETE_SPS_SALES_PROFILE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
