import Types from "./actionType";
import {
  FetchDashboardsPayload,
  CreateDashboards,
  CreateDashboardsPayload,
  FetchDashboards,
  FetchDashboardsError,
  FetchDashboardsSuccess,
  UpdateDashboards,
  UpdateDashboardsPayload,
  DashboardsList,
  FetchDashboardsByIdPayload,
  FetchDashboardsById,
  FetchDashboardWidgetData,
  FetchDashboardWidgetDataPayload,
  DashboardWidgetResponse,
  FetchDashboardWidgetDataSuccess,
} from "./types";

export const fetchDashboardsList = ({
  params,
  onSuccess,
  onError,
}: FetchDashboardsPayload): FetchDashboards => ({
  type: Types.FETCH_DASHBOARDS,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchDashboardsById = ({
  id,
  onSuccess,
  onError,
}: FetchDashboardsByIdPayload): FetchDashboardsById => ({
  type: Types.FETCH_DASHBOARDS_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDashboardsListSuccess = (data: DashboardsList): FetchDashboardsSuccess => ({
  type: Types.SUCCESS_FETCH_DASHBOARDS,
  payload: {
    data,
  },
});

export const fetchDashboardsError = (error?: string): FetchDashboardsError => ({
  type: Types.ERROR_FETCH_DASHBOARDS,
  payload: { error },
});

export const createDashboards = ({
  data,
  onSuccess,
  onError,
}: CreateDashboardsPayload): CreateDashboards => ({
  type: Types.CREATE_DASHBOARDS,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateDashboards = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateDashboardsPayload): UpdateDashboards => ({
  type: Types.UPDATE_DASHBOARDS,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const fetchDashboardWidgetData = ({
  id,
  onSuccess,
  onError,
}: FetchDashboardWidgetDataPayload): FetchDashboardWidgetData => ({
  type: Types.FETCH_DASHBOARD_WIDGET_DATA,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchDashboardWidgetDataSuccess = (
  data: DashboardWidgetResponse,
): FetchDashboardWidgetDataSuccess => ({
  type: Types.SUCCESS_FETCH_DASHBOARD_WIDGET_DATA,
  payload: {
    data,
  },
});
