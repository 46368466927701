import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import DocumentViewer from "../../components/Document/Document";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import MetaData from "../../components/MetaData";
import ModalComponent from "../../components/Modal/Modal";
import Select from "../../components/Select/select";
import Toast from "../../components/Toast/toast";
import { useControllerConfigs } from "../../presentationals/common";
import { uploadFile, getFile } from "../../redux/application/actions";
import { addRecruits, fetchRecruits, updateRecruits } from "../../redux/recruits/actions";
import {
  formValidationMethod,
  generateArrayAttributes,
  useTitle,
  MomentDate,
  getAttributValues,
} from "../../services/methods";
// import moment from "moment-timezone";

import styles from "./RecruitmentsController.styles";

const RecruitmentController = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const readOnly = ["edit", "add"].includes(props.type) ? false : true;
  const viewType = useRef();
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState("");
  const { designationsOptions, currencyOptions } = useControllerConfigs();

  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const defaultData = {
    name: {
      required: true,
      type: "fullname",
      error: "",
      value: "",
    },
    email: {
      required: true,
      type: "email",
      error: "",
      value: "",
    },
    mobile: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    nationalId: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    nationalIdType: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    personalDOB: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    personalCountry: {
      required: true,
      type: "",
      error: "",
      value: "",
    },
    recruitSource: {
      required: false,
      type: "",
      error: "",
      othersfield: false,
      value: "",
      othersvalue: "",
    },
    designation: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    jobId: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    linkedIn: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    typeOfCandidate: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    employmentType: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    currentCTC: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    expectedCTC: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    reportingTalentAcquisition: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    reportingHR: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    reportingTalentCoordinator: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    reportingAssignedTo: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    bindingContract: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    bindingContractFeedback: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    referral: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    referralMobile: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    referralEmail: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    attachmentResume: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    createdBy: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    createdAt: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    updatedAt: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
    updatedBy: {
      required: false,
      type: "",
      error: "",
      value: "",
    },
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  // const [documentData, setDocumentData] = useState({});
  // const [numPages, setnumPages] = useState("-");
  useEffect(() => {
    if (props.type === "view" || props.type === "edit") {
      if (id !== params.id || viewType.current !== props.type) {
        viewType.current = props.type;
        setFormData(defaultData);
        setLoader(true);
        // setTab("0");
        if (location.state) {
          updateValues(location.state);
          setLoader(false);
          setId(params.id);
          setSubmitting(false);
          setToast({
            message: "",
            message_type: "",
          });
        } else if (props.recruits.length > 0) {
          const find = props.recruits.filter((item) => item._id === params.id);
          if (find.length > 0) {
            updateValues(find[0]);
            setLoader(false);
            setSubmitting(false);
            setId(params.id);
            setToast({
              message: "",
              message_type: "",
            });
          }
        }
      }
    } else if (props.type === "add") {
      viewType.current = props.type;
      setLoader(false);
      setSubmitting(false);
      setFormData(defaultData);
      setId("");
      setToast({
        message: "",
        message_type: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, params.id, props.recruits]);

  const [formData, setFormData] = useState(defaultData);

  const updateValues = (data) => {
    const object = { ...formData };
    Object.keys(data).forEach((element) => {
      const name = element;
      const value = data[element];
      if (formData[element]) {
        object[name] = {
          ...object[name],
          value: value,
        };
      }
    });
    setFormData(object);
  };

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
    let error = formValidationMethod(name, value, formData);
    // other field validation
    let fieldothers;
    if (e.target.getAttribute) {
      fieldothers = e.target.getAttribute("data-valuesothers");
    }
    const fieldothervalidation = e.target["data-fieldothers"];
    let JsonFieldOtherValidation = [];

    let othersfield = false;
    if (fieldothervalidation) {
      JsonFieldOtherValidation = JSON.parse(fieldothervalidation);
      othersfield = JsonFieldOtherValidation.includes(value) ? true : false;
    }
    // verifying the count if email exisitng in record
    if (name === "email") {
      const find = props.recruits.find((item) => item.email.toLowerCase() === value.toLowerCase());
      if (find) {
        error = "Recruit found with this email";
      }
    }
    // verfication ends, setting up values
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        othersfield: fieldothers ? true : othersfield,
        othersvalue: fieldothers ? value : "",
        value: fieldothers ? formData[name].value : value,
        error: error,
      },
    });
  };

  const getTitle = () => {
    switch (props.type) {
      case "view":
        return "View Recruit";
      case "add":
        return "Add Recruit";
      case "edit":
        return "Edit Recruit";
      default:
        return "";
    }
  };
  useTitle(getTitle());

  const onSubmitAddForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      setSubmitting(true);
      props.addRecruits({
        data: generateData,
        onSuccess: (data) => {
          props.fetchRecruits({
            onSuccess: () => {
              navigate("/ap/recruitment");
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please update the form");
    }
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);
    return output;
  };

  const onSubmitUpdateForm = () => {
    const submit = validateForm();
    const generateData = {};
    Object.keys(formData).forEach((el) => {
      if (formData[el].othersfield) {
        generateData[el] = formData[el].othersvalue;
      } else {
        generateData[el] = formData[el].value;
      }
    });
    if (submit) {
      setSubmitting(true);
      props.updateRecruits({
        id: id,
        data: generateData,
        onSuccess: (data) => {
          props.fetchRecruits({
            onSuccess: () => {
              setToast({
                message: "Updated Successfully",
                message_type: "success",
              });
              setSubmitting(false);
            },
            onError: () => {
              setToast({
                message: "Updated Successfully, Please reload page",
                message_type: "warning",
              });
              setSubmitting(false);
            },
          });
        },
        onError: (error) => {
          setToast({
            message: error,
            message_type: "error",
          });
          setSubmitting(false);
        },
      });
    } else {
      alert("please fill out the form");
    }
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 || submitting ? true : false;
    return value;
  };

  const getDocument = (id) => {
    setDocumentId(id);
    setIsModalOpen(true);
    // props.getFile({
    //   id: id,
    //   onSuccess: (data) => {
    //     setDocumentData(data);
    //   },
    //   onError: (err) => {
    //     alert("Document fetching failed", err);
    //   },
    // });
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    props.uploadFile({
      file: file,
      onSuccess: (data) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            value: data.id,
            error: ``,
          },
        });
      },
      onError: (err) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: err,
          },
        });
      },
      onProgress: (progress) => {
        setFormData({
          ...formData,
          [name]: {
            ...formData[name],
            error: `Upload in progress (${progress}%)`,
          },
        });
      },
    });
  };

  return loader ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>{getTitle()}</h2>
      </styles.Header>

      <div style={{ paddingBottom: "20px" }}></div>
      <Toast type={toast.message_type} message={toast.message} />
      <form
        onSubmit={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
        autoComplete='off'
      >
        <Input
          readOnly={readOnly}
          label={"Full Name"}
          type={"text"}
          name='name'
          value={formData.name.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.name?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Personal Email"}
          type={"text"}
          name='email'
          value={formData.email.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.email?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Mobile Number"}
          type={"text"}
          name='mobile'
          value={formData.mobile.value}
          autoComplete={"off"}
          onChange={onTextChange}
          mandatory={true}
          error={formData?.mobile?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Date of birth"}
          type={"date"}
          name='personalDOB'
          value={formData.personalDOB.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.personalDOB?.error}
        />
        <Input
          readOnly={readOnly}
          label={"National ID"}
          type={"text"}
          name='nationalId'
          value={formData.nationalId.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.nationalId?.error}
        />
        <Select
          readOnly={readOnly}
          name={"nationalIdType"}
          label={"National ID Type"}
          options={generateArrayAttributes("nationalIdType").map((value) => ({
            label: value.value,
            value: value._id,
          }))}
          value={formData.nationalIdType.value}
          onChange={onTextChange}
          error={formData?.nationalIdType?.error}
        />
        <Select
          readOnly={readOnly}
          name={"personalCountry"}
          label={"Country"}
          options={generateArrayAttributes("employeeCountry").map((value) => ({
            label: value.value,
            value: value._id,
          }))}
          value={formData.personalCountry.value}
          onChange={onTextChange}
          error={formData?.personalCountry?.error}
        />
        <Select
          readOnly={readOnly}
          name={"recruitSource"}
          label={"How applicant found ?"}
          options={[
            "LinkedIn",
            "Indeed",
            "Campus Drive",
            "Email",
            "Direct via Candidate",
            "Reference",
            "Others",
          ].map((value) => ({
            label: value,
            value: value,
          }))}
          attributes={{ "data-fieldothers": '["Campus Drive", "Others"]' }}
          value={formData.recruitSource.value}
          onChange={onTextChange}
        />
        {formData.recruitSource.othersfield && (
          <Input
            readOnly={readOnly}
            label={`${formData.recruitSource.value} information`}
            type={"text"}
            name='recruitSource'
            value={formData.recruitSource.othersvalue}
            attributes={{ "data-valuesothers": true }}
            autoComplete={"off"}
            onChange={onTextChange}
            error={formData?.recruitSource?.error}
          />
        )}
        <Select
          width={"48%"}
          name={"designation"}
          label={"Designation"}
          options={designationsOptions}
          value={formData.designation.value}
          onChange={onTextChange}
          mandatory={formData.designation.required}
          error={formData.designation.error}
        />
        <Input
          readOnly={readOnly}
          label={`Job ID`}
          type={"text"}
          name='jobId'
          value={formData.jobId.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.jobId?.error}
        />
        <Input
          readOnly={readOnly}
          label={`LinkedIn URL`}
          type={"text"}
          name='linkedIn'
          value={formData.linkedIn.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.linkedIn?.error}
        />
        <Select
          readOnly={readOnly}
          name={"typeOfCandidate"}
          label={"Candidate Type"}
          options={generateArrayAttributes("typeCandidate").map((value) => ({
            label: value.value,
            value: value._id,
          }))}
          value={formData.typeOfCandidate.value}
          onChange={onTextChange}
          error={formData?.typeOfCandidate?.error}
        />
        <Select
          width={"48%"}
          name={"employmentType"}
          label={"Employee Type"}
          options={getAttributValues("employmentType")}
          value={formData.employmentType.value}
          onChange={onTextChange}
          mandatory={formData.employmentType.required}
          error={formData.employmentType.error}
        />
        <Input
          readOnly={readOnly}
          label={`Current CTC`}
          type={"text"}
          name='currentCTC'
          value={formData.currentCTC.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.currentCTC?.error}
        />
        <Input
          readOnly={readOnly}
          label={`Expected CTC`}
          type={"text"}
          name='expectedCTC'
          value={formData.expectedCTC.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.expectedCTC?.error}
        />
        <Select
          readOnly={readOnly}
          name={"bindingContract"}
          label={"Binding Contract"}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          value={formData.bindingContract.value}
          onChange={onTextChange}
          error={formData?.bindingContract?.error}
        />
        <Input
          readOnly={readOnly}
          label={`Binding Contract Feedback`}
          type={"text"}
          name='bindingContractFeedback'
          value={formData.bindingContractFeedback.value}
          autoComplete={"off"}
          onChange={onTextChange}
          error={formData?.bindingContractFeedback?.error}
        />
        <Select
          readOnly={readOnly}
          name={"reportingTalentAcquisition"}
          label={"Talent Acquisition"}
          options={props.employees.map((value) => ({
            label: value?.label,
            value: value._id,
          }))}
          value={formData.reportingTalentAcquisition.value}
          onChange={onTextChange}
          error={formData?.reportingTalentAcquisition?.error}
        />
        <Select
          readOnly={readOnly}
          name={"reportingHR"}
          label={"HR Manager"}
          options={props.employees.map((value) => ({
            label: value?.label,
            value: value._id,
          }))}
          value={formData.reportingHR.value}
          onChange={onTextChange}
          error={formData?.reportingHR?.error}
        />
        <Select
          readOnly={readOnly}
          name={"reportingTalentCoordinator"}
          label={"Talent Coordinator"}
          options={props.employees.map((value) => ({
            label: value?.label,
            value: value._id,
          }))}
          value={formData.reportingTalentCoordinator.value}
          onChange={onTextChange}
          error={formData?.reportingTalentCoordinator?.error}
        />
        <Select
          readOnly={readOnly}
          name={"reportingAssignedTo"}
          label={"Assigned To"}
          options={props.employees.map((value) => ({
            label: value?.label,
            value: value._id,
          }))}
          value={formData.reportingAssignedTo.value}
          onChange={onTextChange}
          error={formData?.reportingAssignedTo?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Referral"}
          type={"text"}
          name='referral'
          value={formData.referral.value}
          onChange={onTextChange}
          error={formData?.referral?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Referral Phone"}
          type={"text"}
          name='referralMobile'
          value={formData.referralMobile.value}
          onChange={onTextChange}
          error={formData?.referralMobile?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Referral Email"}
          type={"text"}
          name='referralEmail'
          value={formData.referralEmail.value}
          onChange={onTextChange}
          error={formData?.referralEmail?.error}
        />
        <Input
          readOnly={readOnly}
          label={"Attachment Resume"}
          type={"file"}
          name='attachmentResume'
          message={formData.attachmentResume.value}
          onMessageClick={() => getDocument(formData.attachmentResume.value)}
          autoComplete={"off"}
          onChange={onFileChange}
          mandatory={true}
          error={formData?.attachmentResume?.error}
        />
        {!readOnly && (
          <Button
            onClick={props.type === "add" ? onSubmitAddForm : onSubmitUpdateForm}
            disabled={SubmitDisabled()}
            size={"normal"}
            label={"Save"}
          />
        )}
      </form>
      <br />
      <br />
      {formData.createdAt.value && (
        <MetaData
          title='Created On'
          value={MomentDate({
            date: formData.createdAt.value,
            format: "YYYY-MM-DD",
          })}
        />
      )}
      {formData.createdBy?.value?.name && (
        <MetaData title='Created By' value={formData.createdBy?.value?.name} />
      )}
      {formData.updatedAt.value && formData.createdAt.value !== formData.updatedAt.value && (
        <MetaData
          title={"Updated On"}
          value={MomentDate({
            date: formData.updatedAt.value,
            format: "YYYY-MM-DD",
          })}
        />
      )}
      {formData.updatedBy?.value?.name && (
        <MetaData title='Updated By' value={formData.updatedBy?.value?.name} />
      )}
      <br />
      <br />
      <ModalComponent isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <DocumentViewer id={documentId} />
      </ModalComponent>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  recruits: state.RecruitsReducer.recruits,
  employees: state.ApplicationReducer.configurations.controllerConfigs.employees,
});

const mapDispatchToProps = {
  addRecruits,
  updateRecruits,
  fetchRecruits,
  uploadFile,
  getFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentController);
