import React from "react";

import { Layout } from "../../../presentationals";
import { ServiceNowSolutionsList } from "../../../presentationals/screens/admin/ServiceNowSolutions/ServiceNowSolutionsList";

const Route = () => {
  return <Layout activity={<ServiceNowSolutionsList />} admin={true} layout={"2"} />;
};

export const APServiceNowSolutionsListing = React.memo(Route);
