import React, { useCallback, useEffect, useMemo } from "react";

import { images, strings } from "../../../theme";
import { TableV2Props } from "../../../types";
import { Checkbox, MyTooltip } from "../atoms";
import { Popup } from "../organisms";
import { DropdownListContainer, DropdownListItem } from "../TableHeaderV2/styles";

import { useTableV2 } from "./hooks/useTableV2";
import {
  TableContainer,
  TableHead,
  TableRow,
  Title,
  TableData,
  EmptyList,
  TableDataRowContainer,
  TableOuterContainer,
  TableWrapper,
  DotsIcon,
  SortDirectionIcon,
} from "./styles";

const { noRecordsFound } = strings.common;

export const TableV2 = ({
  data,
  selectedRows,
  columns,
  page = 1,
  pageSize = 10,
  onClickTableRow,
  onClickRowSelection,
  allRowsSelected,
  emptyListMessage = noRecordsFound,
  horizontalScroll = false,
  handleSortList,
  metaData,
  advancedList = true,
}: TableV2Props) => {
  const {
    tableColumns,
    parentRef,
    componentRef,
    hoverRow,
    sortColumn,
    handleSortPopup,
    closeSortPopup,
    handleSortItem,
    onClickRow,
    handleHoverEnterHeader,
    handleHoverLeaveHeader,
  } = useTableV2({
    columns,
    handleSortList,
    onClickTableRow,
    data,
    advancedList,
  });
  const tableColumnsV2 = useMemo(() => {
    const columns = tableColumns;
    if (!advancedList) {
      columns.splice(0, 1);
    }
    return columns;
  }, [advancedList, tableColumns]);
  const renderDataRow = useCallback(
    ({ column, columnIndex, dataItem, serial }) => {
      const title = column.key === "serial" ? serial : dataItem[column.key];
      const primaryColumn = advancedList ? columnIndex === 2 : columnIndex === 1;
      const titleClasses = primaryColumn ? "data-title clickable-row-border" : "data-title";
      if (column?.render) {
        if (primaryColumn) {
          return (
            <MyTooltip text='Open Record'>
              <div className='data-title clickable-row-border'>{column.render(dataItem)}</div>
            </MyTooltip>
          );
        }

        return column?.render(dataItem);
      }
      return (
        <>
          {primaryColumn ? (
            <MyTooltip text='Open Record'>
              <Title
                index={columnIndex}
                horizontalScroll={horizontalScroll}
                width={column.width}
                className={titleClasses}
              >
                {title}
              </Title>
            </MyTooltip>
          ) : (
            <Title
              index={columnIndex}
              horizontalScroll={horizontalScroll}
              width={column.width}
              className={titleClasses}
            >
              {title}
            </Title>
          )}
        </>
      );
    },
    [advancedList, horizontalScroll],
  );

  // Handling width of the table colums in case of only 2-3 columns are there.
  // Which can lead to empty space in the table UI
  useEffect(() => {
    const handleResize = () => {
      const parentScrollWidth = parentRef?.current?.scrollWidth;
      const parentClientWidth = parentRef?.current?.clientWidth;
      if (componentRef.current) {
        if (parentScrollWidth && parentClientWidth && parentScrollWidth > parentClientWidth) {
          componentRef.current.style.width = parentRef.current.scrollWidth + "px";
        } else {
          componentRef.current.style.width = "100%";
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TableWrapper>
      <TableOuterContainer ref={parentRef}>
        <TableContainer ref={componentRef}>
          <TableHead horizontalScroll={horizontalScroll && advancedList}>
            {tableColumnsV2.map((column, columnIndex) => {
              if (column.key === "checkbox" && advancedList) {
                return (
                  <TableRow
                    className={"sticky-column"}
                    key={columnIndex}
                    center={true}
                    data-id='all'
                    onClick={onClickRowSelection}
                  >
                    <Checkbox
                      containerClass='table-list-select-checkbox-container'
                      wrapperClass='table-list-select-checkbox'
                      value={allRowsSelected}
                      label={""}
                    />
                  </TableRow>
                );
              }
              return (
                <TableRow
                  horizontalScroll={horizontalScroll}
                  onMouseEnter={handleHoverEnterHeader}
                  onMouseLeave={handleHoverLeaveHeader}
                  data-index={columnIndex}
                  width={column.width}
                  key={columnIndex}
                  center={column.key === "serial" ? true : column.center}
                  background={column.background}
                >
                  {metaData?.sortBy === column.key && advancedList && (
                    <SortDirectionIcon
                      src={metaData?.sortDirection === "asc" ? images.ascIcon : images.descIcon}
                    />
                  )}
                  <Title
                    width={column.width}
                    horizontalScroll={horizontalScroll}
                    className={`table-header-title ${horizontalScroll && "scrollable-table"}`}
                  >
                    {column.title}
                  </Title>
                  {advancedList &&
                    (hoverRow === columnIndex || sortColumn === column.key) &&
                    column.sortSupported && (
                      <DotsIcon
                        src={images.dotsIcon}
                        data-name={column.key}
                        onClick={handleSortPopup}
                      />
                    )}
                </TableRow>
              );
            })}
          </TableHead>
          <TableData horizontalScroll={horizontalScroll && advancedList} emptyList={!data?.length}>
            {data?.length ? (
              data.map((dataItem, dataIndex) => {
                const serial = page === 1 ? dataIndex + 1 : (page - 1) * pageSize + dataIndex + 1;
                return (
                  <TableDataRowContainer
                    horizontalScroll={horizontalScroll}
                    key={dataIndex}
                    data-index={dataIndex}
                  >
                    {tableColumnsV2.map((column, columnIndex) => {
                      const className = dataItem[column.className];
                      if (column.key === "checkbox" && advancedList) {
                        const value = selectedRows?.includes(dataItem?._id);
                        return (
                          <TableRow
                            className={"sticky-column"}
                            horizontalScroll={horizontalScroll}
                            height={"41px"}
                            key={columnIndex}
                            data-id={dataItem?._id}
                            center={true}
                            onClick={onClickRowSelection}
                            data-column={columnIndex}
                            data-index={dataIndex}
                          >
                            <Checkbox
                              containerClass='table-list-select-checkbox-container'
                              wrapperClass='table-list-select-checkbox'
                              value={value}
                              label={""}
                            />
                          </TableRow>
                        );
                      }
                      return (
                        <TableRow
                          horizontalScroll={horizontalScroll}
                          className={className}
                          width={column.width}
                          data-column={columnIndex}
                          data-index={dataIndex}
                          key={columnIndex}
                          center={column.key === "serial" ? true : column.center}
                          background={column.background}
                          onClick={onClickRow}
                        >
                          {renderDataRow({ column, columnIndex, dataItem, serial })}
                        </TableRow>
                      );
                    })}
                  </TableDataRowContainer>
                );
              })
            ) : (
              <EmptyList>{emptyListMessage}</EmptyList>
            )}
          </TableData>
        </TableContainer>
      </TableOuterContainer>
      <Popup isOpen={!!sortColumn} closeModal={closeSortPopup}>
        {!!sortColumn && (
          <DropdownListContainer>
            <EmptyList>
              {"Sort By "}
              {sortColumn}
            </EmptyList>
            {[
              { label: "Ascending", value: "asc" },
              { label: "Descending", value: "desc" },
            ].map((item, index) => {
              return (
                <DropdownListItem data-sort={item.value} onClick={handleSortItem} key={index}>
                  {item.label}
                </DropdownListItem>
              );
            })}
          </DropdownListContainer>
        )}
      </Popup>
    </TableWrapper>
  );
};
