import styled from "styled-components";

import { colors } from "../../../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 7px;
  color: ${colors.common.strongViolet};
  margin-bottom: 2px;
  text-align: center;
  width: 100%;
`;

export const SwitchOn = styled.img``;

export const SwitchOff = styled.img``;
