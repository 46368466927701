import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button/button";
import Input from "../../components/Input/input";
import Loader from "../../components/Loader/loader";
import Select from "../../components/Select/select";
import { fetchRecruits, updateRecruits } from "../../redux/recruits/actions";
import { formValidationMethod, generateArrayAttributes } from "../../services/methods";

import styles from "./RecruitmentsSurvey.styles";

const RecruitmentSurvey = (props) => {
  const defaultFormData = {
    introductionRating: {
      required: true,
      error: "",
      value: "",
    },
    bindingContract: {
      required: true,
      error: "",
      value: "",
    },
    typeOfCandidate: {
      required: true,
      error: "",
      value: "",
    },
    levelOfCandidate: {
      required: true,
      error: "",
      value: "",
    },
    technicalSkills: {
      required: true,
      error: "",
      value: "",
    },
    softSkills: {
      required: true,
      error: "",
      value: "",
    },
    feedback: {
      required: false,
      error: "",
      value: "",
    },
  };
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    if (params.id !== id) {
      setLoading(true);
      setSubmitting(false);
      const find = props.recruits.filter((recruit) => recruit._id === params.id);
      if (find.length > 0) {
        setId(params.id);
        setLoading(false);
        setFormData({
          ...defaultFormData,
          typeOfCandidate: {
            ...defaultFormData.typeOfCandidate,
            value: find[0].typeOfCandidate,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, props.recruits]);

  const onTextChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const isMulti = e.target.isMulti;
    const error = isMulti ? "" : formValidationMethod(name, value, formData);
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value: value,
        error: error,
      },
    });
  };

  const validateForm = () => {
    let output = true;
    let newFormValidation = formData;
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      const error = formValidationMethod(name, value, formData);
      if (error && output) {
        output = false;
      }
      newFormValidation = {
        ...newFormValidation,
        [name]: {
          ...newFormValidation[name],
          error: error,
        },
      };
    });
    setFormData(newFormValidation);
    return output;
  };

  const generateFormObject = () => {
    const object = {};
    Object.keys(formData).forEach((item) => {
      const name = item;
      const value = formData[name].value;
      object[name] = value;
    });
    return object;
  };

  const SubmitDisabled = () => {
    const count = Object.keys(formData).filter((item) => formData[item].error);
    const value = count.length > 0 ? true : false;
    return value;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const status = validateForm();
    const formDataObject = generateFormObject();
    if (status) {
      setSubmitting(true);
      const sagaData = {
        id: id,
        data: formDataObject,
        onSuccess: (id) => {
          props.fetchRecruits({
            onSuccess: () => {
              navigate(`/ap/recruitment/${id}/assignment`);
            },
          });
        },
        onError: (err) => {
          setSubmitting(false);
          alert(err);
        },
      };
      props.updateRecruits(sagaData);
    } else {
      alert("please fill the form");
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <styles.MainContainer>
      <styles.Header>
        <h2>Initial Screening Survey</h2>
      </styles.Header>
      <hr style={{ width: "100%" }} />
      <styles.Body>
        <form onSubmit={handleOnSubmit}>
          <Select
            name={"introductionRating"}
            label={"Introduction Rating"}
            options={[...Array(10)].map((x, i) => ({
              label: i + 1,
              value: i + 1,
            }))}
            value={formData.introductionRating.value}
            onChange={onTextChange}
            error={formData?.introductionRating?.error}
          />
          <Select
            name={"bindingContract"}
            label={"Binding Contract"}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            value={formData.bindingContract.value}
            onChange={onTextChange}
            error={formData?.bindingContract?.error}
          />
          <Select
            name={"typeOfCandidate"}
            label={"Type of Candidate"}
            options={generateArrayAttributes("typeCandidate").map((value, index) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.typeOfCandidate.value}
            onChange={onTextChange}
            error={formData?.typeOfCandidate?.error}
          />
          <Select
            name={"levelOfCandidate"}
            label={"Level of Candidate"}
            options={generateArrayAttributes("levelCandidate").map((value, index) => ({
              label: value.value,
              value: value._id,
            }))}
            value={formData.levelOfCandidate.value}
            onChange={onTextChange}
            error={formData?.levelOfCandidate?.error}
          />
          <Select
            name={"technicalSkills"}
            label={"Technical Skills"}
            options={generateArrayAttributes("technicalSkills").map((value, index) => ({
              label: value.value,
              value: value._id,
            }))}
            isMulti={true}
            value={formData?.technicalSkills?.value}
            onChange={onTextChange}
            error={formData?.technicalSkills?.error}
          />
          <Input
            label={"Soft Skills"}
            type={"textarea"}
            name='softSkills'
            value={formData.softSkills.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.softSkills?.error}
          />
          <Input
            label={"Other Feedback"}
            type={"textarea"}
            name='feedback'
            value={formData.feedback.value}
            autoComplete={"off"}
            onChange={onTextChange}
            mandatory={true}
            error={formData?.feedback?.error}
          />
          <Button
            onClick={handleOnSubmit}
            disabled={SubmitDisabled() || submitting}
            size={"small"}
            label={"Save"}
          />
        </form>
      </styles.Body>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  recruits: state.RecruitsReducer.recruits,
  //   employees: state.EmployeesReducer.employees,
});

const mapDispatchToProps = {
  fetchRecruits,
  updateRecruits,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentSurvey);
