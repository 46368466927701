/* eslint-disable no-unused-vars */
enum Types {
  FETCH_ACADEMY_COURSES = "FETCH_ACADEMY_COURSES",
  FETCH_ACADEMY_COURSES_BY_ID = "FETCH_ACADEMY_COURSES_BY_ID",
  SUCCESS_FETCH_ACADEMY_COURSES = "SUCCESS_FETCH_ACADEMY_COURSES",
  ERROR_FETCH_ACADEMY_COURSES = "ERROR_FETCH_ACADEMY_COURSES",
  CREATE_ACADEMY_COURSES = "CREATE_ACADEMY_COURSES",
  UPDATE_ACADEMY_COURSES = "UPDATE_ACADEMY_COURSES",
  DELETE_ACADEMY_COURSES = "DELETE_ACADEMY_COURSES",
}

export default Types;
