import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import Input from "../../../components/Input/input";
import Select from "../../../components/Select/select";
import { updateChildPayrollsFilters } from "../../../redux/employees/actions";

import styles from "./PayrollsChildFilters.styles";

const ChildPayrollsFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.updateChildPayrollsFilters({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Filters
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Input
        label={"Full Name"}
        type={"text"}
        name='name'
        value={props.filters.name}
        autoComplete={"off"}
        onChange={updateFilter}
      />
      {Object.keys(props.filters.status).length > 0 && (
        <Select
          name={"selectedStatus"}
          label={"Type"}
          defaultFocused={true}
          options={[
            { label: "All", value: "" },
            ...Object.keys(props.filters.status).map((value) => ({
              label: value,
              value: value,
            })),
          ]}
          value={props.filters.selectedStatus}
          onChange={updateFilter}
        />
      )}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.EmployeesReducer.childPayrollsFilters,
});

const mapDispatchToProps = {
  updateChildPayrollsFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildPayrollsFilters);
