import React from "react";

import { MomentDate } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import { EmployeeAppraisals } from "../../../../types";
import { CloseIcon } from "../../../common/styles";
import { Button, Popup, TableList, TextInput } from "../../../components";

import { useEmployeeAppraisals } from "./hooks/useAppraisalDetails";
import { FormTitle, Row, SalaryFieldsContainer, SalaryModalContainer } from "./styles";

interface Props {
  firstName: string;
  lastName: string;
  appraisals: EmployeeAppraisals[];
  employeeId: string;
  fetchEmployees: () => void;
}

export const EmployeeAppraisalDetails = ({
  employeeId,
  firstName,
  lastName,
  appraisals,
  fetchEmployees,
}: Props) => {
  const {
    handleSalaryModal,
    salaryModal,
    addSalaryModal,
    editSalaryModal,
    onDateChange,
    formData,
    onSubmitAdd,
    onSubmitUpdate,
  } = useEmployeeAppraisals({ appraisals, fetchEmployees, employeeId });

  const { addAppraisalDetails, editAppraisalDetails } = strings.apEmployees;

  const columns = [
    {
      title: "Serial",
      width: "10%",
      key: "serial",
    },
    {
      title: "Name",
      width: "30%",
      render: () => {
        return <div className='title'>{`${firstName} ${lastName}`}</div>;
      },
    },
    {
      title: "Last Appraisal Date",
      width: "30%",
      render: (item) => {
        return (
          <div className='title'>
            {MomentDate({ date: item.lastAppraisalDate, format: "YYYY-MM-DD" })}
          </div>
        );
      },
    },
    {
      title: "Next Appraisal Due Date",
      width: "30%",
      render: (item) => {
        return (
          <div className='title'>
            {MomentDate({ date: item.nextAppraisalDueDate, format: "YYYY-MM-DD" })}
          </div>
        );
      },
    },
  ];
  const { lastAppraisalDate, nextAppraisalDueDate } = formData;

  return (
    <>
      <TableList
        // headerTitle={strings.headerTitle.employees}
        rightButton={strings.common.addNew}
        rightButtonPress={addSalaryModal}
        // filterButton={strings.common.filter}
        // filterButtonPress={onClickFilter}
        loading={false}
        list={appraisals}
        columns={columns}
        // onPageChange={handlePageChange}
        onClickTableRow={editSalaryModal}
        // metaData={employees?.metaData}
      />
      <Popup isOpen={!!salaryModal} closeModal={handleSalaryModal}>
        <SalaryModalContainer>
          <CloseIcon src={images.closeModal} onClick={handleSalaryModal} />
          <FormTitle>
            {salaryModal === "add"
              ? addAppraisalDetails
              : salaryModal === "edit"
              ? editAppraisalDetails
              : ""}
          </FormTitle>
          <SalaryFieldsContainer>
            <Row>
              <TextInput
                label='Last Appraisal Date'
                type={"date"}
                width='48%'
                error={lastAppraisalDate.error}
                onDateChange={onDateChange}
                datePickerProps={{
                  name: "lastAppraisalDate",
                  value: lastAppraisalDate.value,
                  required: lastAppraisalDate.required,
                }}
                textAreaProps={{}}
              />
              <TextInput
                label='Next Appraisal DueDate'
                type={"date"}
                width='48%'
                error={nextAppraisalDueDate.error}
                onDateChange={onDateChange}
                datePickerProps={{
                  name: "nextAppraisalDueDate",
                  value: nextAppraisalDueDate.value,
                  required: nextAppraisalDueDate.required,
                }}
                textAreaProps={{}}
              />
            </Row>
          </SalaryFieldsContainer>
          <Button
            className='employee-modal-save-button'
            title={salaryModal === "add" ? strings.common.add : strings.common.save}
            onClick={salaryModal === "add" ? onSubmitAdd : onSubmitUpdate}
          />
        </SalaryModalContainer>
      </Popup>
    </>
  );
};
