import React, { useState, useEffect } from "react";

import moment from "moment-timezone";
import { MdArrowRightAlt, MdEdit, MdPerson, MdSync } from "react-icons/md";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../components/Button/button";
import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import { Header as MainHeader } from "../../presentationals/components";
import { organisationsFetch } from "../../redux/organisations/actions";
import { getAllTickets } from "../../redux/tickets/actions";
import { useTitle } from "../../services/methods";
import { Sort } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./Organisations.styles";

const Organisations = (props: any) => {
  const navigate = useNavigate();
  useTitle("Organization");
  const [sortKey, setSortKey] = useState("");
  const [ascSort, setAscSort] = useState(true);
  useEffect(() => {
    props.organisationsFetch({
      isOG: props.superAdmin ? false : props.OGAdmin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSortedData = () => {
    const sortKey = props.filters.sortKey.key;
    const isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    const attribute = props.filters.sortKey.attribute;
    let data = props.data;
    if (props.data?.[0]?.[sortKey]) {
      data = Sort({
        data: props.data,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return data;
  };

  const handleOrganisationClick = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    console.log(id);
    navigate(`/ap/organisations/${id}/edit`);
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.organisations} />
      <Header
        title='Organization'
        buttons={[
          {
            visible: props.permissions.organisations_list,
            onPress: {
              event: navigate,
              params: "/ap/organisations/add",
            },
            title: "Add",
            label: "Add",
            disabled: false,
            block: false,
            attributes: {},
            inactive: false,
            type: "primary",
          },
          {
            visible: true,
            title: "Filters",
            onPress: {
              event: navigate,
              params: "/ap/organisations/",
            },
          },
        ]}
        onSync={{
          event: props.organisationsFetch,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th align='left' width={sortKey === "serial" ? "10%" : "5%"}>
              Sno.
            </th>
            <th align='left' width='70%'>
              Name
            </th>
            <th>Active </th>
            {(props.permissions.organisations_edit || props.OGAdmin) && <th>-</th>}
          </tr>
        </thead>
        <tbody>
          {getSortedData().map((organisation, index) => (
            <tr key={index}>
              <td>{organisation.serial}</td>
              <td>{organisation.name}</td>
              <td align='center'>{organisation.active ? "Yes" : "No"}</td>
              {(props.permissions.organisations_edit || props.OGAdmin) && (
                <td
                  onClick={handleOrganisationClick}
                  data-id={organisation._id}
                  width='5%'
                  align='center'
                >
                  <MdEdit size={15} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  permissions: state.UserReducer.permissions,
  data: state.OrganisationsReducer.list,
  filters: state.OrganisationsReducer.filters,
  OGAdmin: state.UserReducer.organisationAdmin,
  superAdmin: state.UserReducer.superAdmin,
});

const mapDispatchToProps = {
  organisationsFetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organisations);
