import React, { useCallback, useEffect, useRef } from "react";

import { useLocation, useParams } from "react-router-dom";

import Document from "../../../../components/Document/Document";
import { useAppSelector } from "../../../../redux/hooks";
import { MomentDate, getAttributValues } from "../../../../services/methods";
import { images, strings } from "../../../../theme";
import {
  ActiveSelectOptions,
  StatusSelectOptions,
  TaskAssociatedTo,
} from "../../../../utils/constants";
import { useControllerConfigs } from "../../../common";
import { RelatedListLabel, RelatedListSeparator } from "../../../common/styles";
import {
  AttachmentsModalContainer,
  BrowseAttachmentContainer,
  CloseIcon,
  CurrentAttachmentContainer,
  NoAttachmentsContainer,
  ButtonsContainer,
  AttachmentListItem,
  AttachmentListItemContainer,
  AttachmentNameContainer,
  AttachmentIconsContainer,
  AttachmentIcon,
  AttachmentTitle,
  DocumentViewContainer,
} from "../../../common/styles";
import { StepBadge } from "../../../common/styles";
import { Header, Popup, TableHeader, WorkLogs } from "../../../components";
import { TextInput, Select, Button } from "../../../components/atoms";
import { AcademyCandidateEnrollmentsList } from "../AcademyCandidateEnrollments/AcademyCandidateEnrollmentsList";
import { AcademyCandidateLeadsList } from "../AcademyCandidateLeads/AcademyCandidateLeadsList";
import { AcademyStudentProfileList } from "../AcademyStudentProfile/AcademyStudentProfileList";
import { CompanyList } from "../Company/CompanyList";
import { RelatedListContainer } from "../Departments/styles";
import { EmployeesListing } from "../Employees/EmployeesList";

import { useTaskController } from "./hooks";
import {
  Container,
  LeftContainer,
  RightContainer,
  StepContainer,
  FormTitle,
  Row,
  FormFieldsContainer,
  FormContainer,
} from "./styles";

export const TaskController = ({ controllerType }: { controllerType?: "add" | "edit" }) => {
  const permissions = useAppSelector((state) => state.UserReducer.permissions);
  const departments = useAppSelector((state) => state.DepartmentsReducer.data.list);
  const list = useAppSelector((state) => state.TaskReducer.data.list);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Accessing specific query parameters
  const associatedTo = searchParams.get("associatedTo");

  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);

  const params = useParams();
  const steps = ["Overview"];
  const relatedList: string[] = [];
  let associatedTaskType = "";
  switch (associatedTo) {
    case "company":
      associatedTaskType = "companyTaskType";
      relatedList.push("Company");
      break;
    case "ach":
      associatedTaskType = "achTaskType";
      relatedList.push("ACH");
      break;
    case "hr":
      associatedTaskType = "taskType";
      relatedList.push("Employee");
      break;
  }
  if (controllerType === "edit") {
    steps.push("Worklogs");
    steps.push("System Data");
  }
  const {
    currentStepIndex,
    goTo,
    assignmentOptions,
    formData,
    onTextChange,
    onSubmitAdd,
    onSubmitUpdate,
    loading,
    headerTitle,
    fetchTask,
    onPostWorklog,
    employeesAttributes,
    taskPrefix,
    getStepBadge,
    countryOptions,
    handleAttachmentsModal,
    attachmentModal,
    onAttachmentsLoad,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    getAttachmentFileIcon,
    handleShowAttachment,
    handleDeleteAttachment,
    handleCloseAttachment,
    documentId,
    onDateChange,
    data,
  } = useTaskController({
    steps,
    type: controllerType,
    id: params.id,
    attachmentsPickerRef,
    associatedTo,
  });
  const {
    companyOptions,
    groupsOptions,
    usersOptions,
    academyCandidateLeadsOptions,
    academyCandidateEnrollmentsOptions,
    academyStudentProfileOptions,
  } = useControllerConfigs();
  const currentTask = data;
  const {
    workLogs,
    attachments,
    associatedEmployee,
    associatedCandidateLead,
    associatedCandidateEnrolment,
    associatedCandidateStudent,
    number,
    type,
    active,
    associatedCompany,
    assignmentGroups,
    assignedTo,
    watchListGroup,
    achAssociationType,
    watchListUser,
    priority,
    urgency,
    holdReason,
    state,
    description,
    shortDescription,
  } = formData;

  useEffect(() => {
    if (controllerType === "edit") {
      fetchTask();
    }
    // Do not include rehydrateFormData to avoid multiple rerenders form will be rehydrated only once bases on params
  }, [params.id, controllerType]);
  const renderRelatedEmployee = useCallback(() => {
    return (
      <RelatedListContainer>
        <EmployeesListing
          relatedList={{
            type: "employee",
            value: currentTask.associatedEmployee?._id,
          }}
        />
      </RelatedListContainer>
    );
  }, [currentTask.associatedEmployee]);
  const renderRelatedACH = useCallback(() => {
    return (
      <RelatedListContainer>
        {currentTask.associatedCandidateEnrolment?._id && (
          <AcademyCandidateEnrollmentsList
            relatedList={{
              type: "academyEnrolment",
              value: currentTask.associatedCandidateEnrolment?._id,
            }}
          />
        )}
        {currentTask.associatedCandidateLead?._id && (
          <AcademyCandidateLeadsList
            relatedList={{
              type: "academyCandidateLead",
              value: currentTask.associatedCandidateLead?._id,
            }}
          />
        )}
        {currentTask.associatedCandidateStudent?._id && (
          <AcademyStudentProfileList
            relatedList={{
              type: "studentProfile",
              value: currentTask.associatedCandidateStudent?._id,
            }}
          />
        )}
      </RelatedListContainer>
    );
  }, [
    currentTask.associatedCandidateEnrolment?._id,
    currentTask.associatedCandidateLead?._id,
    currentTask.associatedCandidateStudent?._id,
  ]);
  const renderRelatedCompany = useCallback(() => {
    return (
      <RelatedListContainer>
        <CompanyList
          relatedList={{
            type: "companyID",
            value: currentTask.associatedCompany?._id,
          }}
        />
      </RelatedListContainer>
    );
  }, [currentTask.associatedCompany]);

  const renderRelatedParent = useCallback(() => {
    switch (associatedTo) {
      case "hr":
        return renderRelatedEmployee();
      case "company":
        return renderRelatedCompany();
      case "ach":
        return renderRelatedACH();
    }
  }, [renderRelatedEmployee, associatedTo, renderRelatedACH, renderRelatedCompany]);

  const renderOverview = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label='Number'
            type={"text"}
            width='48%'
            error={number.error}
            inputProps={{
              disabled: true,
              value: `${taskPrefix}${number.value}`,
              autoComplete: "off",
              onChange: onTextChange,
              required: number.required,
            }}
            textAreaProps={{}}
          />
          <Select
            width='46%'
            name={"active"}
            label={"Active"}
            options={ActiveSelectOptions}
            value={active.value}
            onChange={onTextChange}
            error={active?.error}
          />
        </Row>
        {associatedTo === "hr" && (
          <Select
            width={"48%"}
            name={"associatedEmployee"}
            label={"Employee"}
            options={employeesAttributes}
            value={associatedEmployee.value}
            onChange={onTextChange}
            mandatory={associatedEmployee.required}
            error={associatedEmployee.error}
          />
        )}
        {associatedTo === "ach" && (
          <>
            <Row>
              <Select
                width={"48%"}
                name={"achAssociationType"}
                label={"Associated Record"}
                options={[
                  { label: "Candidate Lead", value: "lead" },
                  { label: "Academy Candidate Enrollment", value: "enrolment" },
                  { label: "Lerner Profile", value: "student" },
                ]}
                value={achAssociationType.value}
                onChange={onTextChange}
                mandatory={achAssociationType.required}
                error={achAssociationType.error}
              />
              {achAssociationType.value === "lead" && (
                <Select
                  width={"48%"}
                  name={"associatedCandidateLead"}
                  label={"Candidate Lead"}
                  options={academyCandidateLeadsOptions}
                  value={associatedCandidateLead.value}
                  onChange={onTextChange}
                  mandatory={associatedCandidateLead.required}
                  error={associatedCandidateLead.error}
                />
              )}
              {achAssociationType.value === "enrolment" && (
                <Select
                  width={"48%"}
                  name={"associatedCandidateEnrolment"}
                  label={"Academy Candidate Enrollment"}
                  options={academyCandidateEnrollmentsOptions}
                  value={associatedCandidateEnrolment.value}
                  onChange={onTextChange}
                  mandatory={associatedCandidateEnrolment.required}
                  error={associatedCandidateEnrolment.error}
                />
              )}
              {achAssociationType.value === "student" && (
                <Select
                  width={"48%"}
                  name={"associatedCandidateStudent"}
                  label={"Lerner Profile"}
                  options={academyStudentProfileOptions}
                  value={associatedCandidateStudent.value}
                  onChange={onTextChange}
                  mandatory={associatedCandidateStudent.required}
                  error={associatedCandidateStudent.error}
                />
              )}
            </Row>
          </>
        )}
        {associatedTo === "company" && (
          <Select
            width={"48%"}
            name={"associatedCompany"}
            label={"Company"}
            options={companyOptions}
            value={associatedCompany.value}
            onChange={onTextChange}
            mandatory={associatedCompany.required}
            error={associatedCompany.error}
          />
        )}
        <Row>
          <Select
            width={"48%"}
            name={"priority"}
            label={"Priority"}
            options={getAttributValues("taskPriority")}
            value={priority.value}
            onChange={onTextChange}
            mandatory={priority.required}
            error={priority.error}
          />
          <Select
            width={"48%"}
            name={"type"}
            label={"Type"}
            options={getAttributValues(associatedTaskType, true)}
            value={type.value}
            onChange={onTextChange}
            mandatory={type.required}
            error={type.error}
          />
        </Row>
        <Row>
          <Select
            width={"48%"}
            name={"assignedTo"}
            label={"Assigned To"}
            options={assignmentOptions}
            value={assignedTo.value}
            onChange={onTextChange}
            mandatory={assignedTo.required}
            error={assignedTo.error}
          />
          <Select
            width={"48%"}
            name={"watchListUser"}
            label={"Watch List User"}
            options={usersOptions}
            isMulti={true}
            value={watchListUser.value}
            onChange={onTextChange}
            mandatory={watchListUser.required}
            error={watchListUser.error}
          />
        </Row>
        <Select
          width={"100%"}
          name={"assignmentGroups"}
          label={"Assignment Groups"}
          options={[{ label: "None", value: "" }, ...groupsOptions]}
          value={assignmentGroups.value}
          onChange={onTextChange}
          mandatory={assignmentGroups.required}
          error={assignmentGroups.error}
        />
        <Select
          width={"100%"}
          name={"watchListGroup"}
          label={"WatchList Group"}
          isMulti={true}
          options={groupsOptions}
          value={watchListGroup.value}
          onChange={onTextChange}
          mandatory={watchListGroup.required}
          error={watchListGroup.error}
        />
        <Row>
          <Select
            width={"48%"}
            name={"urgency"}
            label={"Urgency"}
            options={getAttributValues("taskUrgency")}
            value={urgency.value}
            onChange={onTextChange}
            mandatory={urgency.required}
            error={urgency.error}
          />
          <Select
            width={"48%"}
            name={"state"}
            label={"State"}
            options={getAttributValues("taskState")}
            value={state.value}
            onChange={onTextChange}
            mandatory={state.required}
            error={state.error}
          />
        </Row>

        <TextInput
          label='Short Description'
          type={"text"}
          width='100%'
          error={shortDescription.error}
          inputProps={{
            name: "shortDescription",
            value: shortDescription.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: shortDescription.required,
          }}
          textAreaProps={{}}
        />
        <TextInput
          label='Description'
          type={"textarea"}
          width='100%'
          error={description.error}
          inputProps={{}}
          textAreaProps={{
            name: "description",
            value: description.value,
            autoComplete: "off",
            onChange: onTextChange,
            rows: 12,
            required: description.required,
          }}
        />

        <TextInput
          label='If any blockers, please mention'
          type={"text"}
          width='100%'
          error={holdReason.error}
          inputProps={{
            name: "holdReason",
            value: holdReason.value,
            autoComplete: "off",
            onChange: onTextChange,
            required: holdReason.required,
          }}
          textAreaProps={{}}
        />
      </>
    );
  }, [formData, onTextChange]);

  const renderSystemData = useCallback(() => {
    return (
      <>
        <Row>
          <TextInput
            label={"Created On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentTask?.createdAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated On"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: MomentDate({
                date: currentTask?.updatedAt || "",
                format: "YYYY-MM-DD",
              }),
            }}
            textAreaProps={{}}
          />
        </Row>
        <Row>
          <TextInput
            label={"Created By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentTask?.createdBy?.name,
            }}
            textAreaProps={{}}
          />
          <TextInput
            label={"Updated By"}
            type={"text"}
            width='48%'
            inputProps={{
              disabled: true,
              name: "name",
              value: currentTask?.updatedBy?.name,
            }}
            textAreaProps={{}}
          />
        </Row>
      </>
    );
  }, [
    currentTask?.createdAt,
    currentTask?.createdBy?.name,
    currentTask?.updatedAt,
    currentTask?.updatedBy?.name,
  ]);

  const renderWorklogs = useCallback(() => {
    return (
      <>
        <WorkLogs workLogs={workLogs.value} onPostWorklog={onPostWorklog} />
      </>
    );
  }, [onPostWorklog, workLogs.value]);

  const renderFormFields = useCallback(() => {
    switch (currentStepIndex) {
      case 0:
        return renderOverview();
      case 1:
        return renderWorklogs();
      case 2:
        return renderSystemData();
      case 3:
        return renderRelatedParent();
      default:
        return <></>;
    }
  }, [currentStepIndex, renderOverview, renderSystemData, renderWorklogs, renderRelatedParent]);

  return (
    <Container loading={loading} onClick={() => console.log(formData)}>
      <Header title={headerTitle} />
      <TableHeader
        backButtonTitle={strings.common.back}
        rightButton={strings.common.save}
        rightButtonPress={controllerType === "add" ? onSubmitAdd : onSubmitUpdate}
        onAttachmentsClick={handleAttachmentsModal}
        onAttachmentsIconLoad={onAttachmentsLoad}
        attachmentsBadge={attachments.value.length}
      />
      <FormContainer>
        <LeftContainer>
          <FormTitle>
            {steps[currentStepIndex] || relatedList[steps.length - currentStepIndex]}
          </FormTitle>
          <FormFieldsContainer>{renderFormFields()}</FormFieldsContainer>
        </LeftContainer>
        <RightContainer>
          {steps.map((stepItem, stepIndex) => {
            const badge = getStepBadge(stepIndex);
            return (
              <StepContainer
                key={stepIndex}
                data-index={stepIndex}
                active={currentStepIndex === stepIndex}
                onClick={goTo}
                noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
              >
                {stepItem}
                {!!badge && <StepBadge>{badge}</StepBadge>}
                <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
              </StepContainer>
            );
          })}
          {controllerType === "edit" && !!relatedList.length && (
            <>
              <RelatedListLabel>
                <RelatedListSeparator />
                {strings.common.relatedList}
                <RelatedListSeparator />
              </RelatedListLabel>
              {relatedList.map((stepItem, index) => {
                const stepIndex = steps.length + index;
                return (
                  <StepContainer
                    key={stepIndex}
                    data-index={stepIndex}
                    active={currentStepIndex === stepIndex}
                    onClick={goTo}
                    noBorder={[currentStepIndex, currentStepIndex - 1].includes(stepIndex)}
                  >
                    {stepItem}
                    <div className={currentStepIndex === stepIndex ? "active" : ""}></div>
                  </StepContainer>
                );
              })}
            </>
          )}
        </RightContainer>
      </FormContainer>
      <Popup isOpen={!!attachmentModal} closeModal={handleAttachmentsModal}>
        {documentId ? (
          <DocumentViewContainer>
            <CloseIcon src={images.closeModal} onClick={handleCloseAttachment} />
            <Document id={documentId} />
          </DocumentViewContainer>
        ) : (
          <AttachmentsModalContainer>
            <CloseIcon src={images.closeModal} onClick={handleAttachmentsModal} />
            {attachmentModal === "list" && (
              <>
                {attachments.value.length ? (
                  <AttachmentListItemContainer>
                    {attachments.value.map((attachmentItem, attachmentIndex) => {
                      const { id, name, extension } = attachmentItem;
                      let fileName = name;
                      if (extension) {
                        fileName = `${name}.${extension}`;
                      }
                      return (
                        <AttachmentListItem key={attachmentIndex}>
                          <AttachmentNameContainer>
                            <AttachmentIcon src={getAttachmentFileIcon(extension)} />
                            <AttachmentTitle>{fileName}</AttachmentTitle>
                          </AttachmentNameContainer>
                          <AttachmentIconsContainer>
                            <AttachmentIcon
                              onClick={handleShowAttachment}
                              data-index={attachmentIndex}
                              src={images.viewAttachment}
                            />
                            <AttachmentIcon
                              onClick={handleDeleteAttachment}
                              data-index={attachmentIndex}
                              src={images.deleteAttachment}
                            />
                          </AttachmentIconsContainer>
                        </AttachmentListItem>
                      );
                    })}
                    <AttachmentListItem className='attachments-buttons-container'>
                      <Button title={strings.common.add} onClick={onClickAddAttachment} />
                      <Button title={strings.common.ok} onClick={handleAttachmentsModal} />
                    </AttachmentListItem>
                  </AttachmentListItemContainer>
                ) : (
                  <>
                    <NoAttachmentsContainer>
                      {strings.attachments.noAttachments}
                    </NoAttachmentsContainer>
                    <Button
                      title={strings.common.add}
                      className='knowledge-attachments-add'
                      onClick={onClickAddAttachment}
                    />
                  </>
                )}
              </>
            )}
            {attachmentModal === "add" && (
              <>
                {currentAttachmentFile ? (
                  <CurrentAttachmentContainer>
                    <TextInput
                      label='Attachment'
                      type={"text"}
                      width='90%'
                      inputProps={{
                        name: "attachment",
                        onChange: handleCurrentAttachment,
                        value: currentAttachment.name,
                      }}
                      error={attachmentUploadProgress}
                    />
                    <ButtonsContainer>
                      <Button
                        title={strings.common.save}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickSaveAttachment}
                      />
                      <Button
                        title={strings.common.cancel}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickCancelAttachment}
                      />
                    </ButtonsContainer>
                  </CurrentAttachmentContainer>
                ) : (
                  <>
                    <input
                      type='file'
                      name='attachments'
                      ref={attachmentsPickerRef}
                      onChange={onAttachmentsFileChange}
                      hidden={true}
                      accept='.gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml,.txt'
                    />
                    <BrowseAttachmentContainer>
                      {strings.attachments.addAttachments}
                      <Button title={strings.common.browse} onClick={onClickBrowseAttachment} />
                    </BrowseAttachmentContainer>
                  </>
                )}
              </>
            )}
          </AttachmentsModalContainer>
        )}
      </Popup>
    </Container>
  );
};
