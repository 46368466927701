/* eslint-disable no-unused-vars */
enum Types {
  FETCH_COMPANY_CONTACT = "FETCH_COMPANY_CONTACT",
  FETCH_COMPANY_CONTACT_BY_ID = "FETCH_COMPANY_CONTACT_BY_ID",
  SUCCESS_FETCH_COMPANY_CONTACT = "SUCCESS_FETCH_COMPANY_CONTACT",
  ERROR_FETCH_COMPANY_CONTACT = "ERROR_FETCH_COMPANY_CONTACT",
  CREATE_COMPANY_CONTACT = "CREATE_COMPANY_CONTACT",
  UPDATE_COMPANY_CONTACT = "UPDATE_COMPANY_CONTACT",
  DELETE_COMPANY_CONTACT = "DELETE_COMPANY_CONTACT",
}

export default Types;
