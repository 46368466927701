import React, { useCallback, useState, MouseEvent, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { uploadFile } from "../../../../../redux/application/actions";
import { useAppDispatch } from "../../../../../redux/hooks";
import {
  organisationsAdd,
  organisationsFetch,
  organisationsUpdate,
} from "../../../../../redux/organisations/actions";

interface defaultPermissionDataType {
  organisations: boolean;
  users: boolean;
  employees: boolean;
  payroll: boolean;
  recruitsment: boolean;
  tickets: boolean;
  announcements: boolean;
  jobs: boolean;
  media: boolean;
  emailtemplates: boolean;
  groups: boolean;
  categories: boolean;
  modules: boolean;
  knowledgebase: boolean;
}

interface defaultDataType {
  serial: { required: boolean; type: string; error: string; value: string };
  name: { required: boolean; type: string; error: string; value: string };
  mailFrom: { required: boolean; type: string; error: string; value: string };
  mailFromName: { required: boolean; type: string; error: string; value: string };
  active: { required: boolean; type: string; error: string; value: true };
  businessOwner: { required: boolean; type: string; error: string; value: string };
  orgImage: { required: boolean; type: string; error: string; value: string };
}

interface toastType {
  message: string;
  message_type: string;
}

interface useMultiStepFormType {
  goTo: (e: MouseEvent<HTMLDivElement>) => void;
  currentStepIndex: number;
  data: defaultDataType;
  onTextChange: (e: any) => void;
  HydrateData: (rehydrateData: any) => void;
  setData: (e: defaultDataType) => void;
  defaultData: defaultDataType;
  defaultPermissionData: defaultPermissionDataType;
  onPermissionChange: (e: any) => void;
  onSubmitAddForm: () => void;
  onSubmitUpdateForm: () => void;
  permissionData: defaultPermissionDataType;
  setPermissionData: (e: defaultPermissionDataType) => void;
  submitting: boolean;
  setSubmitting: (e: boolean) => void;
  setToast: (e: toastType) => void;
  toast: toastType;
  onFileChange: (e: any) => void;
  orgImage: string;
  setOrgImage: (e: string) => void;
}

export const useController = (steps: string[], id): useMultiStepFormType => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const dispatch = useAppDispatch();

  //   const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const goTo = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.getAttribute("data-index");
    setCurrentStepIndex(Number(step));
  }, []);

  const defaultData = useMemo(() => {
    return {
      serial: { required: false, type: "text", error: "", value: "" },
      name: { required: true, type: "text", error: "", value: "" },
      mailFrom: { required: true, type: "email", error: "", value: "" },
      mailFromName: { required: true, type: "text", error: "", value: "" },
      active: { required: false, type: "boolean", error: "", value: true },
      businessOwner: { required: true, type: "text", error: "", value: "" },
      orgImage: { required: false, type: "file", error: "", value: "" },
    };
  }, []);

  const defaultPermissionData = useMemo(() => {
    return {
      organisations: false,
      users: false,
      employees: false,
      payroll: false,
      recruitsment: false,
      tickets: false,
      announcements: false,
      jobs: false,
      media: false,
      emailtemplates: false,
      groups: false,
      categories: false,
      modules: false,
      knowledgebase: false,
    };
  }, []);

  const [data, setData] = useState(defaultData);
  const [permissionData, setPermissionData] = useState(defaultPermissionData);
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({
    message: "",
    message_type: "",
  });
  const [orgImage, setOrgImage] = useState("");

  const __handlerSetData = useCallback(
    (name: string, value: string) => {
      // place verifier here
      setData({
        ...data,
        [name]: {
          ...data[name],
          value: value,
        },
      });
    },
    [data],
  );

  const onTextChange = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
      __handlerSetData(name, value);
    },
    [__handlerSetData],
  );

  const HydrateData = useCallback(
    (rehydrateData) => {
      const object = { ...data };
      Object.keys(data).forEach((item) => {
        if (defaultData[item]) {
          object[item] = {
            ...object[item],
            value: rehydrateData[item],
          };
        }
      });
      setPermissionData(rehydrateData.permissions);
      setData(object);
    },
    [data, defaultData],
  );

  const onPermissionChange = useCallback(
    (e) => {
      const value = e.target.type === "checkbox" ? +e.target.checked : e.target.value;
      setPermissionData({
        ...permissionData,
        [e.target.name]: value,
      });
    },
    [permissionData],
  );

  const onSubmitAddForm = useCallback(() => {
    setSubmitting(true);
    const formData = {
      name: data?.name?.value,
      mailFrom: data.mailFrom.value,
      mailFromName: data.mailFromName.value,
      permissions: permissionData,
      businessOwner: data.businessOwner.value,
      orgImage: data?.orgImage?.value,
    };
    const payload = {
      data: formData,
      onSuccess: (wow) => {
        setSubmitting(false);
        window.location.reload();
      },
      onError: (err) => {
        setSubmitting(false);
        setToast({
          message: err,
          message_type: "error",
        });
      },
    };
    dispatch(organisationsAdd(payload));
  }, [data, dispatch, permissionData]);

  const fetchOrganisationAfterUpdate = () => {
    const payload = {
      onSuccess: (wow1) => {
        setSubmitting(false);
        setToast({
          message: "Updated Successfully",
          message_type: "success",
        });
      },
      onError: (error) => {
        setSubmitting(false);
        setToast({
          message: "Updated Successfully, Please reload page",
          message_type: "warning",
        });
      },
    };
    dispatch(organisationsFetch(payload));
  };

  const onSubmitUpdateForm = useCallback(() => {
    setSubmitting(true);
    const formData = {
      name: data.name.value,
      mailFrom: data.mailFrom.value,
      mailFromName: data.mailFromName.value,
      permissions: permissionData,
      businessOwner: data.businessOwner.value,
      orgImage: data.orgImage.value,
    };
    const payload = {
      id: id,
      data: formData,
      onSuccess: (wow) => {
        setSubmitting(false);
        fetchOrganisationAfterUpdate();
        window.location.reload();
      },
      onError: (err) => {
        setSubmitting(false);
        setToast({
          message: err,
          message_type: "error",
        });
      },
    };
    dispatch(organisationsUpdate(payload));
  }, [data, dispatch, id, permissionData]);

  const onFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      const name = e.target.name;
      const payload = {
        file: file,
        type: "public",
        onSuccess: ({ id, location }) => {
          setOrgImage(location);
          setData({
            ...data,
            [name]: {
              ...data[name],
              value: id,
              error: ``,
            },
          });
        },
        onError: (err) => {
          setData({
            ...data,
            [name]: {
              ...data[name],
              error: err,
            },
          });
        },
        onProgress: (progress) => {
          setData({
            ...data,
            [name]: {
              ...data[name],
              error: `Upload in progress (${progress}%)`,
            },
          });
        },
      };
      dispatch(uploadFile(payload));
    },
    [dispatch, data],
  );

  return {
    goTo,
    currentStepIndex,
    data,
    onTextChange,
    HydrateData,
    setData,
    defaultData,
    defaultPermissionData,
    onPermissionChange,
    onSubmitAddForm,
    onSubmitUpdateForm,
    permissionData,
    setPermissionData,
    submitting,
    setSubmitting,
    setToast,
    toast,
    onFileChange,
    orgImage,
    setOrgImage,
  };
};
