import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { Field } from "react-querybuilder";
import type { RuleGroupTypeIC } from "react-querybuilder";
import { defaultValidator, formatQuery, QueryBuilder } from "react-querybuilder";

import { Button, Checkbox, Header, Popup, TableV2, TableHeaderV2, TableListSkeleton } from "../..";
import { strings } from "../../../../theme";
import {
  ColumnsType,
  ListActions,
  SelectOptions,
  TableHeaderButton,
  TablePublicFields,
  metaDataTypes,
} from "../../../../types";
import { FilterButton } from "../../atoms/FilterButton";
import Pagination from "../../Pagination";

import "react-querybuilder/dist/query-builder.css";
import "../../../common/conditionBuilder.css";
import {
  FilterContainer,
  FilterHeader,
  FilterItemsContainer,
  FilterRightHeaderContainer,
  MainContainer,
  TableConfigModalContainer,
  TableConfigTitle,
  TableFieldsContainer,
} from "./styles";

interface Props {
  handleShowItemsPerPage?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  headerButtons: TableHeaderButton[];
  isRelatedList?: boolean;
  allRowsSelected?: boolean;
  selectedRows?: string[];
  handleListActions?: (field: string, value: any) => void;
  onClickRowSelection?: (e: React.MouseEvent) => void;
  tablePublicFields?: TablePublicFields[];
  selectedConfigFields?: string[];
  handleTableConfigs?: (fields: string[]) => void;
  tableFields?: [];
  loading: boolean;
  rightButton?: string;
  headerTitle?: string;
  rightButtonPress?: React.MouseEventHandler;
  filterButtonPress?: React.MouseEventHandler;
  list: object[];
  columns: ColumnsType[];
  metaData?: metaDataTypes;
  onPageChange?: (page: string) => void;
  onClickTableRow?: (id: string) => void;
  horizontalScroll?: boolean;
  emptyListMessage?: string;
  filterButton?: string;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  clearSearch?: () => void;
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue?: string;
  filters?: Field[];
  searchInputName?: string;
  refreshList?: () => void;
  onClickApplyFilter?: (
    mongoQuery: string,
    jsonQuery: string,
    filterLogicQuery: RuleGroupTypeIC,
  ) => void;
  onClickCloseFilter?: () => void;
  filterLogicQuery?: RuleGroupTypeIC;
  handleSortList?: (columnKey: string, sortType: "asc" | "desc") => void;
  listActionOptions?: ListActions[];
  advancedList?: boolean;
}

export const TableListV2 = ({
  handleShowItemsPerPage,
  headerButtons,
  handleListActions,
  isRelatedList,
  selectedRows,
  onClickRowSelection,
  allRowsSelected,
  tablePublicFields,
  selectedConfigFields,
  loading,
  rightButton,
  headerTitle,
  rightButtonPress,
  list,
  columns,
  metaData,
  onPageChange,
  onClickTableRow,
  horizontalScroll,
  emptyListMessage,
  onSearch,
  searchPlaceholder,
  clearSearch,
  onSearchChange,
  searchValue,
  filters,
  searchInputName,
  refreshList,
  onClickApplyFilter,
  handleTableConfigs,
  filterLogicQuery,
  handleSortList,
  advancedList = true,
  listActionOptions,
}: Props) => {
  const initialQuery: RuleGroupTypeIC = useMemo(() => {
    return filterLogicQuery ? filterLogicQuery : { rules: [] };
  }, [filterLogicQuery]);
  const [filter, setFilter] = useState<boolean>(false);
  const [tableConfigModal, setTableConfigModal] = useState<boolean>(false);
  const [configFields, setConfigFields] = useState<string[]>(selectedConfigFields || []);
  const [query, setQuery] = useState(initialQuery);

  const handleConfigField = useCallback(
    (e: React.MouseEvent) => {
      const key = e.currentTarget.getAttribute("data-key");
      if (key) {
        const fieldExists = configFields.findIndex((item) => item === key);
        if (fieldExists >= 0) {
          const newArray = [...configFields];
          newArray.splice(fieldExists, 1);
          console.log(newArray);
          setConfigFields(newArray);
        } else {
          setConfigFields([...configFields, key]);
        }
      }
    },
    [configFields],
  );

  const onConfigFieldsSave = useCallback(() => {
    if (handleTableConfigs) {
      setTableConfigModal(false);
      handleTableConfigs(configFields);
    }
  }, [handleTableConfigs, configFields]);

  const handleTableConfigModal = useCallback(() => {
    setTableConfigModal((value) => !value);
  }, []);

  const handleFilter = useCallback(() => {
    setFilter((filter) => !filter);
  }, []);

  const handleApplyFilter = useCallback(() => {
    const jsonQuery = formatQuery(query, "json_without_ids");
    const mongoQuery = jsonQuery === `{"rules":[]}` ? {} : formatQuery(query, "mongodb");
    setFilter((filter) => !filter);
    console.log(mongoQuery, jsonQuery);
    onClickApplyFilter(mongoQuery, jsonQuery, query);
  }, [onClickApplyFilter, query]);

  const handleCloseFilter = useCallback(() => {
    setQuery(initialQuery);
    setFilter(false);
  }, [initialQuery]);
  const handleClearFilter = useCallback(() => {
    setQuery({ rules: [] });
    setFilter(false);
    onClickApplyFilter("", "", { rules: [] });
  }, [onClickApplyFilter]);
  return (
    <MainContainer>
      {!isRelatedList && (
        <>
          {!!headerTitle && <Header title={headerTitle} />}
          <TableHeaderV2
            advancedList={advancedList}
            handleListActions={handleListActions}
            headerButtons={headerButtons}
            rightButton={rightButton}
            handleTableConfigs={handleTableConfigModal}
            rightButtonPress={rightButtonPress}
            filterButton={strings.common.filter}
            filterButtonPress={handleFilter}
            onSearchPress={onSearch}
            onSearchChange={onSearchChange}
            searchPlaceholderText={searchPlaceholder}
            clearSearch={clearSearch}
            searchValue={searchValue}
            searchInputName={searchInputName}
            refreshList={refreshList}
            listActionOptions={listActionOptions}
          />
        </>
      )}
      {loading ? (
        <TableListSkeleton />
      ) : (
        <>
          <TableV2
            handleSortList={handleSortList}
            data={list}
            metaData={metaData}
            selectedRows={selectedRows}
            allRowsSelected={allRowsSelected}
            onClickRowSelection={onClickRowSelection}
            columns={columns}
            page={metaData?.currentPage}
            pageSize={metaData?.limit}
            onClickTableRow={onClickTableRow}
            emptyListMessage={emptyListMessage}
            horizontalScroll={horizontalScroll}
            advancedList={advancedList}
          />
          {!!metaData && (
            <Pagination
              advancedList={advancedList}
              handleShowItemsPerPage={handleShowItemsPerPage}
              currentPage={metaData.currentPage}
              totalCount={metaData.totalItems}
              pageSize={metaData.limit}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}
      <Popup isOpen={filter} closeModal={handleFilter}>
        <FilterContainer>
          <FilterHeader>
            <FilterButton className='table-filter-modal-button' title={strings.common.filter} />
            <FilterRightHeaderContainer>
              <Button
                className='table-filter-apply-button'
                title={strings.filters.apply}
                onClick={handleApplyFilter}
              />
              <Button
                className='table-filter-close-button'
                title={strings.filters.close}
                onClick={handleCloseFilter}
              />
              <Button
                className='table-filter-clear-button'
                title={strings.filters.clear}
                onClick={handleClearFilter}
              />
            </FilterRightHeaderContainer>
          </FilterHeader>
          <FilterItemsContainer>
            {/* <QueryBuilderDnD> */}
            <QueryBuilder
              translations={{
                addRule: {
                  label: "Add Rule",
                },
                addGroup: {
                  label: "Add Group",
                },
                removeRule: {
                  label: "Delete Rule",
                },
                removeGroup: {
                  label: "Delete Group",
                },
              }}
              fields={filters}
              query={query}
              onQueryChange={(q) => setQuery(q)}
              addRuleToNewGroups
              debugMode
              independentCombinators
              showCombinatorsBetweenRules
              validator={defaultValidator}
              controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            />
            {/* </QueryBuilderDnD> */}
          </FilterItemsContainer>
        </FilterContainer>
      </Popup>
      <Popup isOpen={tableConfigModal} closeModal={handleTableConfigModal}>
        <TableConfigModalContainer>
          <TableConfigTitle>Select Fields</TableConfigTitle>
          <TableFieldsContainer>
            {tablePublicFields?.map((item, index) => {
              const { key, label } = item;
              const value = configFields.includes(key);
              return (
                <Checkbox
                  containerClass='table-configs-checkbox'
                  wrapperClass='table-configs-checkbox-wrapper'
                  labelClass='table-configs-checkbox-label'
                  key={index}
                  label={label}
                  width='80%'
                  onClick={handleConfigField}
                  value={value}
                  attributes={{
                    "data-key": key,
                  }}
                />
              );
            })}
          </TableFieldsContainer>
          <Button
            className='table-config-save-button'
            title={strings.common.save}
            onClick={onConfigFieldsSave}
          />
        </TableConfigModalContainer>
      </Popup>
    </MainContainer>
  );
};
