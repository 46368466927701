import Types from "./actionType";
import {
  FetchSPSInsurancePayload,
  CreateSPSInsurance,
  CreateSPSInsurancePayload,
  FetchSPSInsurance,
  FetchSPSInsuranceError,
  FetchSPSInsuranceSuccess,
  UpdateSPSInsurance,
  UpdateSPSInsurancePayload,
  SPSInsuranceListWithMetaData,
  FetchSPSInsuranceByIdPayload,
  FetchSPSInsuranceById,
  DeleteSPSInsurancePayload,
  DeleteSPSInsurance,
} from "./types";

export const fetchSPSInsuranceList = ({
  params,
  onSuccess,
  onError,
}: FetchSPSInsurancePayload): FetchSPSInsurance => ({
  type: Types.FETCH_SPSINSURANCE,
  payload: {
    params,
    onSuccess,
    onError,
  },
});

export const fetchSPSInsuranceById = ({
  id,
  onSuccess,
  onError,
}: FetchSPSInsuranceByIdPayload): FetchSPSInsuranceById => ({
  type: Types.FETCH_SPSINSURANCE_BY_ID,
  payload: {
    id,
    onSuccess,
    onError,
  },
});

export const fetchSPSInsuranceListSuccess = (
  data: SPSInsuranceListWithMetaData,
): FetchSPSInsuranceSuccess => ({
  type: Types.SUCCESS_FETCH_SPSINSURANCE,
  payload: {
    data,
  },
});

export const fetchSPSInsuranceError = (error?: string): FetchSPSInsuranceError => ({
  type: Types.ERROR_FETCH_SPSINSURANCE,
  payload: { error },
});

export const createSPSInsurance = ({
  data,
  onSuccess,
  onError,
}: CreateSPSInsurancePayload): CreateSPSInsurance => ({
  type: Types.CREATE_SPSINSURANCE,
  payload: {
    data,
    onSuccess,
    onError,
  },
});

export const updateSPSInsurance = ({
  id,
  data,
  onSuccess,
  onError,
}: UpdateSPSInsurancePayload): UpdateSPSInsurance => ({
  type: Types.UPDATE_SPSINSURANCE,
  payload: {
    id,
    data,
    onSuccess,
    onError,
  },
});

export const deleteSPSInsurance = ({
  id,
  onSuccess,
  onError,
}: DeleteSPSInsurancePayload): DeleteSPSInsurance => ({
  type: Types.DELETE_SPSINSURANCE,
  payload: {
    id,
    onSuccess,
    onError,
  },
});
