import React, { useEffect } from "react";

import { MdEdit, MdOpenInBrowser } from "react-icons/md";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import StyledTable from "../../components/Table/tables.styles";
import Tooltip from "../../components/Tooltip/Tooltip";
import config from "../../config";
import { Header as MainHeader } from "../../presentationals/components";
import { fetchAllKnowledge } from "../../redux/knowledge/actions";
import { useTitle, Sort, getPrefixDigit, getArticlePermissions } from "../../services/methods";
import { strings } from "../../theme";

import styles from "./Knowledge.styles";

const KnowledgeListing = (props: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const knowledge = searchParams.get("knowledge");
  useTitle("Knowledge");

  useEffect(() => {
    console.log(type, props);
    props.fetchAllKnowledge({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const filteredData = () => {
    const name = props.filters.name.split(" ").join("").toLowerCase();
    const knowledgebase = props.filters.knowledgebase;
    const category = props.filters.category;
    const tag = props.filters.tag;
    let tempData = [];
    if (knowledge) {
      tempData = props.data.filter(
        (item: { knowledgebase: { _id: string }; status: string }) =>
          item?.knowledgebase?._id === knowledge && item?.status === "published",
      );
    } else if (type === "owner") {
      tempData = props.data.filter((item) => item.createdBy._id === props.userId);
    } else if (type === "approvals") {
      tempData = props.data.filter((item) => {
        const { knowledgebaseRole, groupRole } = getArticlePermissions(item, props.userId);
        if (
          item.status === "inreview" &&
          (["manager", "comanager", "owner"].includes(knowledgebaseRole) ||
            ["manager", "comanager", "owner"].includes(groupRole))
        )
          return item;
      });
    } else if (type == "published") {
      tempData = props.data.filter((item) => item.status === "published");
    } else if (type == "admin" || type == "all") {
      tempData = props.data;
    }
    tempData = tempData.filter((item) =>
      item.name.split(" ").join("").toLowerCase().includes(name),
    );
    if (knowledgebase) {
      tempData = tempData.filter((item) => item.knowledgebase?._id === knowledgebase);
    }
    if (category) {
      tempData = tempData.filter((item) => item.category?._id === category);
    }
    if (tag) {
      tempData = tempData.filter((item) => {
        console.log(tag);
        return item.modules?.map((tag) => tag._id == tag);
      });
    }
    const sortKey = props.filters.sortKey.key;
    const isDate = props.filters.sortKey.isDate;
    const ascSort = props.filters.sortType === "asc";
    const attribute = props.filters.sortKey.attribute;
    if (tempData?.[0]?.[sortKey]) {
      tempData = Sort({
        data: tempData,
        field: sortKey,
        isDate: isDate,
        asc: ascSort,
        attributeKey: attribute,
      });
    }
    return tempData;
  };
  const getColor = (status) => {
    switch (status) {
      case "drafted":
        return "#AFB4FF";
      case "inreview":
        return "#F7B847";
      case "published":
        return "#41988E";
      default:
        return "#F7B847";
    }
  };

  const getStatus = (status) => {
    if (status === "inreview") {
      return "IN REVIEW";
    }
    return status.toUpperCase();
  };

  const isRejected = (knowledge) => {
    if (knowledge?.approvals?.length > 0) {
      for (let i = 0; i < knowledge.approvals.length; i++) {
        if (knowledge.approvals[i].status === "rejected") {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <styles.MainContainer>
      <MainHeader title={strings.headerTitle.knowledgeDashboard} />
      <Header
        title='Knowledge'
        buttons={
          type == "admin"
            ? []
            : [
                {
                  title: "Filters",
                  onPress: {
                    event: navigate,
                    params: "/ep/knowledge",
                  },
                  visible: true,
                },
                {
                  title: "Add",
                  onPress: {
                    event: navigate,
                    params: "/ep/knowledge/add",
                  },
                  visible: props.permissions?.knowledgebase_add,
                },
              ]
        }
        onSync={{
          event: props.fetchAllKnowledge,
          params: {},
        }}
      />
      <StyledTable width={"100%"}>
        <thead>
          <tr>
            <th width='2%'>Sno.</th>
            <th align='left' width='20%'>
              KB
            </th>
            <th align='left' width='13%'>
              Name
            </th>
            <th align='left' width='25%'>
              Description
            </th>
            <th align='left' width='15%'>
              Status
            </th>
            <th align='left' width='15%'>
              Category
            </th>
            {type === "owner" && (
              <th align='left' width='5%'>
                -
              </th>
            )}
            <th align='left' width='5%'>
              -
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData()?.map((knowledge, index: number) => (
            <tr
              key={index}
              style={{
                backgroundColor: isRejected(knowledge) ? "#E97777" : "transparent",
              }}
            >
              <td>{index + 1}</td>
              <td>
                <>
                  {`${config.prefix.knowledge}${getPrefixDigit(knowledge?.serial, 5)}`}

                  {/* {isRejected(knowledge) && (
                    <styles.Status color={"#EB6440"}>
                      {"Rejected"}
                    </styles.Status>
                  )} */}
                </>
              </td>
              <td>{knowledge.name}</td>
              <td className='desc'>
                {knowledge.summary}
                {knowledge?.revisedKnowledge && knowledge?.status === "published" && (
                  <styles.Status color={getColor()}>Revision In Progress</styles.Status>
                )}
              </td>
              <td>
                <styles.Status color={getColor(knowledge.status)}>
                  {getStatus(knowledge.status)}
                </styles.Status>
              </td>
              <td>{knowledge.category?.name}</td>
              {type === "owner" && (
                <td>
                  {knowledge.status === "drafted" ? (
                    <Tooltip
                      text={"Edit knowledge"}
                      copy={false}
                      element={
                        <MdEdit
                          className='edit'
                          onClick={() => navigate(`/ep/knowledge/${knowledge._id}/edit`)}
                          size={15}
                        />
                      }
                    />
                  ) : (
                    "-"
                  )}
                </td>
              )}
              <td>
                <Tooltip
                  text={"Open knowledge"}
                  copy={false}
                  element={
                    <MdOpenInBrowser
                      className='edit'
                      onClick={() => navigate(`/ep/knowledge/${knowledge._id}/details`)}
                      size={15}
                    />
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </styles.MainContainer>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.KnowledgeReducer.filters,
  data: state.KnowledgeReducer.list,
  permissions: state.UserReducer.permissions,
  userId: state.UserReducer.id,
});

const mapDispatchToProps = {
  fetchAllKnowledge,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeListing);
