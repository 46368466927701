import styled from "styled-components";

import { colors, fs } from "../../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .table-header-back-button {
    border: 0.3px solid ${colors.common.white};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;
  column-gap: 20px;
  .search-container {
    height: 38px;
    width: 253px;
    border: 0.6px solid ${colors.common.borderColor};
    border-radius: 10px;
    margin-right: 10px;
  }
  .search-input {
    padding-left: 10px;
    font-size: ${fs.s};
    color: ${colors.common.lightTextColor};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  /* margin-right: 20px; */
`;

export const AdditionalButton = styled.div`
  /* margin-right: 60px; */
`;

export const ExtraSaveButton = styled.div`
  /* margin-left: 60px; */
`;

export const AttachmentsIconContainer = styled.div`
  display: flex;
  position: relative;
`;

export const AttachmentsIcon = styled.img`
  height: 35px;
  width: 35px;
  object-fit: contain;
  cursor: pointer;
`;

export const AttachmentsBadge = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 1rem;
  background-color: ${colors.common.yellow};
  position: absolute;
  font-size: ${fs.s};
  color: ${colors.common.white};
  font-weight: 600;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: -8px;
`;
