import Types from "./actionType";
import { CurrencyActions, CurrencyState } from "./types";

const initialState: CurrencyState = {
  data: {
    list: [],
    metaData: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      limit: 0,
      sortBy: "",
      sortDirection: "asc",
    },
  },
  loading: false,
};

export const CurrencyReducer = (state = initialState, action: CurrencyActions) => {
  switch (action.type) {
    case Types.FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_FETCH_CURRENCY:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
