import React from "react";

import { Layout } from "../../../presentationals";
import { NewsletterLeadsList } from "../../../presentationals/screens/admin/NewsletterLeads/NewsletterLeadsList";

const Route = () => {
  return <Layout activity={<NewsletterLeadsList />} admin={true} layout={"2"} />;
};

export const APNewsletterLeadsList = React.memo(Route);
