import React, { useRef, useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import SunEditor from "suneditor-react";

import Document from "../../../../../components/Document/Document";
import { fetchKnowledgeDetails } from "../../../../../redux";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getDropDownOptions } from "../../../../../services/methods";
import { images, strings } from "../../../../../theme";
import { AttributesType, SubcategoriesAttributesType } from "../../../../../types";
import {
  Button,
  Header,
  Popup,
  Select,
  TableHeader,
  TextEditor,
  TextInput,
} from "../../../../components";
import { useKnowledgeController } from "../hooks/useKnowledgeController";

import {
  AttachmentsModalContainer,
  BrowseAttachmentContainer,
  CloseIcon,
  Container,
  CurrentAttachmentContainer,
  FormContainer,
  FormFieldsContainer,
  StepsContainer,
  FormTitle,
  NoAttachmentsContainer,
  Row,
  ButtonsContainer,
  AttachmentListItem,
  AttachmentListItemContainer,
  AttachmentNameContainer,
  AttachmentIconsContainer,
  AttachmentIcon,
  AttachmentTitle,
  DocumentViewContainer,
} from "./styles";

import "suneditor/dist/css/suneditor.min.css";

interface KnowledgeControllerProps {
  type: "add" | "edit";
}

export const KnowledgeController = ({ type }: KnowledgeControllerProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const controllerConfigs = useAppSelector(
    (state) => state.ApplicationReducer?.configurations?.controllerConfigs,
  );

  const attachmentsPickerRef: React.RefObject<HTMLInputElement> = useRef(null);
  const {
    knowledgeFormData,
    onTextInputChange,
    onSubmitAddForm,
    onSubmitUpdateForm,
    subCategoriesFilter,
    onBodyChange,
    handleAttachmentsModal,
    attachmentModal,
    onAttachmentsLoad,
    onClickAddAttachment,
    onClickBrowseAttachment,
    onAttachmentsFileChange,
    currentAttachment,
    currentAttachmentFile,
    handleCurrentAttachment,
    attachmentUploadProgress,
    onClickCancelAttachment,
    onClickSaveAttachment,
    getAttachmentFileIcon,
    handleShowAttachment,
    handleCloseAttachment,
    documentId,
    handleDeleteAttachment,
    fetchKnowledgeSuccess,
    fetchKnowledgeError,
    setLoading,
    loading,
    body,
    bodyError,
  } = useKnowledgeController({
    type,
    attachmentsPickerRef,
    params,
  });

  const { name, knowledgebase, category, subcategory, modules, summary, attachments } =
    knowledgeFormData;

  const userKnowledgebasesAttributes: AttributesType[] = controllerConfigs?.userKnowledgebases;
  const categoriesAttributes: AttributesType[] = controllerConfigs?.categories;
  const subcategoriesAttributes: SubcategoriesAttributesType[] = controllerConfigs?.subcategories;
  const tagsAttributes: AttributesType[] = controllerConfigs?.tags;

  useEffect(() => {
    const id = params.id;
    if (type === "edit" && id) {
      setLoading(true);
      const fetchKnowledgeParams = {
        id,
        onSuccess: fetchKnowledgeSuccess,
        onError: fetchKnowledgeError,
      };
      dispatch(fetchKnowledgeDetails(fetchKnowledgeParams));
    }
  }, [dispatch, fetchKnowledgeError, params, setLoading, type]);
  console.log("AA ::", getDropDownOptions(tagsAttributes));
  return (
    <Container loading={loading}>
      <Header
        title={
          type === "edit" ? strings.headerTitle.knowledgeEdit : strings.headerTitle.knowledgeAdd
        }
      />
      <TableHeader
        backButtonTitle={type === "edit" ? strings.common.back : ""}
        rightButton={strings.common.save}
        rightButtonPress={type === "edit" ? onSubmitUpdateForm : onSubmitAddForm}
        onAttachmentsClick={handleAttachmentsModal}
        onAttachmentsIconLoad={onAttachmentsLoad}
        attachmentsBadge={attachments.value.length}
      />
      <FormContainer>
        <FormFieldsContainer>
          <FormTitle onClick={() => console.log(knowledgeFormData)}>
            {type === "edit" ? strings.epKnowledge.edit : strings.epKnowledge.addKnowledge}
          </FormTitle>
          <Row>
            <TextInput
              label={"Name"}
              type={"text"}
              width='48%'
              error={name.error}
              inputProps={{
                name: "name",
                value: name.value,
                autoComplete: "off",
                onChange: onTextInputChange,
                required: name.required,
              }}
            />
            <Select
              width={"48%"}
              name={"knowledgebase"}
              label={"Knowledgebase"}
              options={getDropDownOptions(userKnowledgebasesAttributes)}
              value={knowledgebase.value}
              onChange={onTextInputChange}
              mandatory={knowledgebase.required}
              error={knowledgebase.error}
            />
          </Row>
          <Row>
            <Select
              width={"48%"}
              name={"category"}
              label={"Category"}
              options={getDropDownOptions(categoriesAttributes)}
              value={category.value}
              onChange={onTextInputChange}
              mandatory={category.required}
              error={category.error}
            />
            <Select
              width={"48%"}
              name={"subcategory"}
              label={"Subcategory"}
              options={getDropDownOptions(subcategoriesAttributes?.filter(subCategoriesFilter))}
              value={subcategory.value}
              onChange={onTextInputChange}
              mandatory={subcategory.required}
              error={subcategory.error}
            />
          </Row>
          <Row>
            <Select
              width={"100%"}
              name={"modules"}
              label={"Tags"}
              options={getDropDownOptions(tagsAttributes, false)}
              value={modules.value}
              onChange={onTextInputChange}
              mandatory={modules.required}
              error={modules.error}
              isMulti
            />
          </Row>
          <Row>
            <TextInput
              width='100%'
              label='Summary'
              type='textarea'
              textAreaProps={{
                value: summary.value,
                required: summary.required,
                autoComplete: "off",
                rows: 8,
                name: "summary",
                onChange: onTextInputChange,
              }}
              error={summary.error}
            />
          </Row>
          <TextEditor
            error={bodyError}
            value={body}
            onChange={onBodyChange}
            placeholder={strings.epKnowledge.bodyPlaceholder}
          />
        </FormFieldsContainer>
        <StepsContainer></StepsContainer>
      </FormContainer>
      <Popup isOpen={!!attachmentModal} closeModal={handleAttachmentsModal}>
        {documentId ? (
          <DocumentViewContainer>
            <CloseIcon src={images.closeModal} onClick={handleCloseAttachment} />
            <Document id={documentId} />
          </DocumentViewContainer>
        ) : (
          <AttachmentsModalContainer>
            <CloseIcon src={images.closeModal} onClick={handleAttachmentsModal} />
            {attachmentModal === "list" && (
              <>
                {attachments.value.length ? (
                  <AttachmentListItemContainer>
                    {attachments.value.map((attachmentItem, attachmentIndex) => {
                      const { id, name, extension } = attachmentItem;
                      let fileName = name;
                      if (extension) {
                        fileName = `${name}.${extension}`;
                      }
                      return (
                        <AttachmentListItem key={attachmentIndex}>
                          <AttachmentNameContainer>
                            <AttachmentIcon src={getAttachmentFileIcon(extension)} />
                            <AttachmentTitle>{fileName}</AttachmentTitle>
                          </AttachmentNameContainer>
                          <AttachmentIconsContainer>
                            <AttachmentIcon
                              onClick={handleShowAttachment}
                              data-index={attachmentIndex}
                              src={images.viewAttachment}
                            />
                            <AttachmentIcon
                              onClick={handleDeleteAttachment}
                              data-index={attachmentIndex}
                              src={images.deleteAttachment}
                            />
                          </AttachmentIconsContainer>
                        </AttachmentListItem>
                      );
                    })}
                    <AttachmentListItem className='attachments-buttons-container'>
                      <Button title={strings.common.add} onClick={onClickAddAttachment} />
                      <Button title={strings.common.ok} onClick={handleAttachmentsModal} />
                    </AttachmentListItem>
                  </AttachmentListItemContainer>
                ) : (
                  <>
                    <NoAttachmentsContainer>
                      {strings.attachments.noAttachments}
                    </NoAttachmentsContainer>
                    <Button
                      title={strings.common.add}
                      className='knowledge-attachments-add'
                      onClick={onClickAddAttachment}
                    />
                  </>
                )}
              </>
            )}
            {attachmentModal === "add" && (
              <>
                {currentAttachmentFile ? (
                  <CurrentAttachmentContainer>
                    <TextInput
                      label='Attachment'
                      type={"text"}
                      width='90%'
                      inputProps={{
                        name: "attachment",
                        onChange: handleCurrentAttachment,
                        value: currentAttachment.name,
                      }}
                      error={attachmentUploadProgress}
                      // onMessageClick={() => getDocument(item.attachment)}
                      // message={item.attachment}
                      // autoComplete={"off"}
                      // mandatory={true}
                      // attributes={{ "data-index": index }}
                    />
                    <ButtonsContainer>
                      <Button
                        title={strings.common.save}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickSaveAttachment}
                      />
                      <Button
                        title={strings.common.cancel}
                        disabled={!!attachmentUploadProgress!!}
                        onClick={onClickCancelAttachment}
                      />
                    </ButtonsContainer>
                  </CurrentAttachmentContainer>
                ) : (
                  <>
                    {/* <TextInput
                label='Attachments'
                type={"file"}
                // hidden={true}
                ref={(a) => console.log("Ref", a)}
                inputProps={{
                  name: "attachment",
                  // onChange: onFileChange
                }}
                // error={item.error}
                // message={item.attachment}
                // onMessageClick={() => getDocument(item.attachment)}
                // autoComplete={"off"}
                // mandatory={true}
                // attributes={{ "data-index": index }}
              /> */}
                    <input
                      type='file'
                      name='attachments'
                      ref={attachmentsPickerRef}
                      onChange={onAttachmentsFileChange}
                      hidden={true}
                      accept='.gif,.jpg,.jpeg,.png,.pdf,.docx,.xlsx,.doc,.ppt,.pptx,.csv,.xml,.txt'
                    />
                    <BrowseAttachmentContainer>
                      {strings.attachments.addAttachments}
                      <Button title={strings.common.browse} onClick={onClickBrowseAttachment} />
                    </BrowseAttachmentContainer>
                  </>
                )}
              </>
            )}
          </AttachmentsModalContainer>
        )}
      </Popup>
    </Container>
  );
};
