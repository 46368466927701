import React from "react";

import { Layout } from "../../../presentationals";
import { SPSDealsController } from "../../../presentationals/screens/admin/SPSDeals/SPSDealsController";

const Route = () => {
  return <Layout activity={<SPSDealsController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APSPSDealsEdit = React.memo(Route);
