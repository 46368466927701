import React from "react";

import { connect } from "react-redux";

import Input from "../../components/Input/input";
import Select from "../../components/Select/select";
import { updateKnowledgeFilter } from "../../redux/knowledge/actions";

import styles from "./KnowledgeFilters.styles";

const sortKeys = [
  { label: "Name", key: "name" },
  { label: "Created On", key: "createdAt", isDate: true },
  {
    label: "Description",
    key: "summary",
  },
  { label: "Serial", key: "serial" },
];

const sortType = [
  { label: "Ascending", type: "asc" },
  { label: "Descending", type: "dec" },
];

const KnowledgeBaseFilters = (props) => {
  const updateFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    props.updateKnowledgeFilter({ field: field, value: value });
  };
  return (
    <styles.MainContainer>
      <styles.Header>
        <h2>
          Sort
          <br />
          {/* <hr width={"100%"} /> */}
        </h2>
      </styles.Header>
      <Select
        name={"sortKey"}
        label={"Field"}
        defaultFocused={true}
        options={[
          ...sortKeys.map((value) => ({
            label: value.label,
            value: value,
          })),
        ]}
        value={props.filters.sortKey}
        onChange={updateFilter}
      />
      <Select
        name={"sortType"}
        label={"Type"}
        defaultFocused={true}
        options={[
          ...sortType.map((value) => ({
            label: value.label,
            value: value.type,
          })),
        ]}
        value={props.filters.sortType}
        onChange={updateFilter}
      />
      <styles.Header>
        <h2>
          Filters
          <br />
        </h2>
      </styles.Header>
      <Input
        label={"Name"}
        type={"text"}
        name='name'
        value={props.filters.name}
        autoComplete={"off"}
        onChange={updateFilter}
        readOnly={false}
      />
      <Select
        name={"knowledgebase"}
        label={"Knowledgebase"}
        options={[
          { label: "Select...", value: "" },
          ...props.knowledgebases.map((item) => ({
            value: item._id,
            label: item.title,
          })),
        ]}
        value={props.filters.knowledgebase}
        onChange={updateFilter}
      />
      <Select
        name={"category"}
        label={"Category"}
        options={[
          { label: "Select...", value: "" },
          ...props.categories.map((item) => ({
            value: item._id,
            label: item.name,
          })),
        ]}
        value={props.filters.category}
        onChange={updateFilter}
      />
      {/* <Select
        name={"tag"}
        label={"Tag"}
        options={props?.tags.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })}
        value={props.filters.tag}
        onChange={updateFilter}
      /> */}
    </styles.MainContainer>
  );
};

const mapStateToProps = (state) => ({
  filters: state.KnowledgeReducer.filters,
  knowledgebases: state.KnowledgeReducer.allKnowledgebases,
  categories: state.KnowledgeReducer.allCategories,
  tags: state.KnowledgeReducer.allTags,
});

const mapDispatchToProps = {
  updateKnowledgeFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBaseFilters);
