import React from "react";

import { Layout } from "../../../presentationals";
import { CategoriesController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<CategoriesController type={"edit"} />} admin={true} layout={"2"} />;
};

export const APCategoriesEdit = React.memo(Route);
