import React, { memo } from "react";

import { Layout } from "../../../presentationals";
import { BugReportingController } from "../../../presentationals/screens";

const Route = () => {
  return <Layout activity={<BugReportingController type={"add"} />} admin={true} layout={"2"} />;
};

export const APBugReportingAdd = React.memo(Route);
